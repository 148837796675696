
import { serializable, alias, primitive, list, object } from 'serializr';

export class Remainder {
    @serializable(alias('type', primitive()))
    type?: number;

    @serializable(alias('date', primitive()))
    remainingDate?: number;

    @serializable(alias('notes', primitive()))
    notes?: string;
}
