import { CreateReducer } from "../../shared/utils/createReducer";
import {
  SET_ACTIVE_CORPORATE,
  SET_DETAILED_ONBOARDING_ACTIVE_STEP,
  SET_REGISTER_ACTIVE_STEP,
} from "../definitions/registerConstants";
import { Corporate } from "../../models/Corporate/corporate.model";
import {
  SetActiveCorporateAction,
  SetDetailedOnboardingActiveStepAction,
  SetRegisterActiveStepAction,
} from "../actions/registerActions";

interface IRegisterState {
  registerActiveStep: number;
  detailedOnboardingActiveStep: number;
  activeCorporate?: Corporate;
}

export interface RegisterReducerProps extends IRegisterState {
  setRegisterActiveStep: (
    registerActiveStep: number
  ) => SetRegisterActiveStepAction;

  setDetailedOnboardingActiveStep: (
    detailedOnboardingActiveStep: number
  ) => SetDetailedOnboardingActiveStepAction;

  setActiveCorporate: (corporate: Corporate) => SetActiveCorporateAction;
}

const initState: IRegisterState = {
  registerActiveStep: 0,
  detailedOnboardingActiveStep: 0,
};

const registerReducer = CreateReducer(initState, {
  [SET_REGISTER_ACTIVE_STEP](state: any, action: any) {
    const { registerActiveStep } = action.payload;
    return {
      ...state,
      registerActiveStep,
    };
  },
  [SET_DETAILED_ONBOARDING_ACTIVE_STEP](state: any, action: any) {
    const { detailedOnboardingActiveStep } = action.payload;
    return {
      ...state,
      detailedOnboardingActiveStep,
    };
  },
  [SET_ACTIVE_CORPORATE](state: any, action: any) {
    const { activeCorporate } = action.payload;
    return {
      ...state,
      activeCorporate,
    };
  },
});

export default registerReducer;
