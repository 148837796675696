import Form from "antd/lib/form/Form";
import { Formik } from "formik";
import React, { FC, useEffect, useState } from "react";
import InputField from "../../../../../shared/components/InputField";
import {
  IDropdownOptions,
  MetaService,
} from "../../../../../services/Meta/meta.service";
import PracticeService from "../../../../../services/Practice/practice.service";
import { Practice } from "../../../../../models/Practice/practice.model";
import DropdownField from "../../../../../shared/components/DropdownField";
import "./resourceForm.scss";
import { Button, Col, Row } from "antd";
import { Resource } from "../../../../../models/resource/resource.model";
import ResourceService from "../../../../../services/Resource/resource.service";
import { ResourceTypeOption } from "../../../../../models/ResourceType/resourceType.model";
import { validation } from "./resourceFormValidation";
import { useResource } from "../../../../../shared/hooks/Resource/useResource";
import { PaginationDetails } from "../../../../../models/Pagination/pagination.model";
import { debounce } from "lodash";
import { uniqueObjectValue } from "../../../../../shared/utils/uniqueObjectValue";
import { SelectProp } from "../../../../../shared/Types/option";

interface ResourceFormProps {
  fromValue?: Resource | null;
  onSaveHandler: (resource: Resource, type: "add" | "edit") => void;
  closeHandler: () => void;
}

const ResourceForm: FC<ResourceFormProps> = ({
  fromValue = null,
  onSaveHandler,
  closeHandler,
}) => {
  const practiceId = localStorage.getItem("practiceId");
  const [practices, setPractices] = useState<SelectProp[]>([]);
  const [resourceTypes, setResourceTypes] = useState<ResourceTypeOption[]>([]);
  const [initialValues, setInitialValues] = useState<Resource>({
    ...new Resource(),
    practiceId: practiceId ?? "",
  });
  const [resourceTypeLoading, setResourceTypeLoading] = useState(false);
  const resources = useResource("chair", "option");
  const [pagination, setPagination] = useState<PaginationDetails>(
    new PaginationDetails()
  );

  const handleFetchPractice = debounce(() => {
    if (pagination?.nextPage) {
      PracticeService.fetchPractices(
        (practice: Practice[], pagination: PaginationDetails) => {
          setPagination(pagination);
          const practiceOptions = practice?.map(({ name, id }) => ({
            label: name,
            value: id,
          }));
          setPractices(uniqueObjectValue(practices, practiceOptions));
        },
        () => {},
        () => {},
        { page: pagination?.nextPage }
      );
    }
  }, 50);
  useEffect(() => {
    handleFetchPractice();
    setResourceTypeLoading(true);
    MetaService.fetchResouceType(
      "option",
      (resourceTypes) => {
        setResourceTypes(resourceTypes as ResourceTypeOption[]);
      },
      () => {},
      () => {
        setResourceTypeLoading(false);
      }
    );
  }, []);

  useEffect(() => {
    setInitialValues(fromValue ?? {
      ...new Resource(),
      practiceId: practiceId ?? "",
    });
  }, [fromValue]);

  const handleSubmit = (values: Resource) => {
    const resource = Object.assign(new Resource(), values);
    (fromValue
      ? ResourceService?.updateResource
      : ResourceService?.createResource)(
      resource,
      (resource) => {
        onSaveHandler(resource, fromValue ? "edit" : "add");
      },
      () => {},
      () => {}
    );
  };

  return (
    <div className="resource-form__container">
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validation}
        enableReinitialize
      >
        {({ values, setFieldValue, handleSubmit, isValid, dirty }) => {
          return (
            <Form>
              <div className="resource-form__wrapper">
                <div className="resource-form__inner">
                  <DropdownField
                    title="Practice name"
                    name="practiceId"
                    options={practices}
                    value={Number(values.practiceId)}
                    onPopupScroll={handleFetchPractice}
                    setFieldValue={setFieldValue}
                    disabled={!!fromValue?.practiceName}
                  />
                  <InputField title="Resource name" name="name" type="text" />
                  <DropdownField
                    title="Resource type"
                    name="resourceTypeId"
                    options={resourceTypes}
                    value={values.resourceTypeId}
                    setFieldValue={setFieldValue}
                    loading={resourceTypeLoading}
                  />
                  <InputField
                    title="Description"
                    name="description"
                    type="textarea"
                    rows={6}
                  />
                </div>
              </div>
              <Row
                className="resource-form__control"
                justify="end"
                gutter={[15, 15]}
              >
                <Col span={4}>
                  <Button type="default" onClick={closeHandler}>
                    Cancel
                  </Button>
                </Col>
                <Col span={4}>
                  <Button
                    type="primary"
                    disabled={!isValid || !dirty}
                    onClick={() => handleSubmit()}
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default ResourceForm;
