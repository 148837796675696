import React, { FC, useEffect, useState } from "react";
import { Button, Col, Collapse, Modal, Popover, Row } from "antd";
import { TreatmentPlanTemplateVisits } from "../../../../../../../models/TreatmentPlanTemplateVisits/treatmentPlanTemplateVisits.model";
import { CaretRightOutlined } from "@ant-design/icons";
import "./appointmentDetail.scss";
import { TreatmentPlanTemplateStatementNotes } from "../../../../../../../models/TreatmentPlanTemplateStatementNotes/treatmentPlanTemplateStatementNotes.model";
import { TreatmentPlanTemplateSteps } from "../../../../../../../models/TreatmentPlanTemplateSteps/treatmentPlanTemplateSteps.model";
import { SetStep } from "../../../../../../../models/SetStep/setStep.model";
import { ProcedureStep } from "../../../../../../../models/ProcedureStep/procedureStep.model";
import { useHistory, useLocation, useParams } from "react-router";
import TreatmentPlanContainer from "../../../../../../../store/container/TreatmentPlanContrainer";
import { TreatmentPlanReducerProps } from "../../../../../../../store/reducers/treatmentPlanReducer";
import TreatmentPlanService from "../../../../../../../services/TreatmentPlan/treatmentPlan.service";
import { TreatmentTemplateService } from "../../../../../../../services/TreatmentTemplate/treatmentTemplate.service";
import { handleRemoveLink } from "../../../../../../../shared/utils/babylonUtils";
import BabylonContainer from "../../../../../../../store/container/BabylonContainer";
import { BabylonReducerProps } from "../../../../../../../store/reducers/babylonReducer";
import { NumberSchemaConstructor } from "yup";
import { DentalSet } from "../../../../../../../models/DentalSet/dentalSet.model";
import deleteIcon from "../../../../../../../assets/images/delete1.png";
import { StatementTemplate } from "../../../../../../../models/Statement/statement.model";
import { NoteTemplateService } from "../../../../../../../services/NoteTemplate/noteTemplate.service";
import StatementNotesForm from "../../../../../../Interactions/StatementNotes/StatementNotesForm";
import StatementIcon from "../../../../../../../assets/icons/Statement.png";
import { StatementNote } from "../../../../../../../models/StatementNote/statementNote.model";
import useResponsibilities from "../../../../../../../shared/hooks/Responsibilities/useResponsibilities";
import { ResponsibilityTypes } from "../../../../../../../enums/responsebily.enum";
import CorporateContainer from "../../../../../../../store/container/CorporateContainer";
import { CorporateReducerProps } from "../../../../../../../store/reducers/corporateReducer";

const { Panel } = Collapse;

interface AppointmentDetailProps
  extends TreatmentPlanReducerProps,
    BabylonReducerProps,
    CorporateReducerProps {
  treatmentPlanTemplateVisits: TreatmentPlanTemplateVisits;
  appointmentNo: number;
  treatmentPlanId?: number;
  statementNotes: TreatmentPlanTemplateStatementNotes[];
  onSuccess: () => void;
  onAddStatement: (note: StatementNote) => void;
}

const SelfCare = (name: string, frequency: string, notes: string) => {
  const displayDetail = (title: string, value: string) => (
    <div className="content-container mb-4">
      <p className="title">{title}</p>
      <p className="value">{value}</p>
    </div>
  );
  return (
    <div className="self-care__container ml-5">
      {displayDetail("Object", name)}
      {displayDetail("Frequency", frequency)}
      {displayDetail("Notes", notes)}
    </div>
  );
};

const AppointmentDetail: FC<AppointmentDetailProps> = (props) => {
  const {
    practiceId,
    scene,
    treatmentPlanId,
    setCurrentTreatmentPlan,
    currentTreatmentPlan,
    statementNotes,
    appointmentNo,
    setCurrentAppointment,
    onSuccess,
    currentAppointment,
    onAddStatement,
    setMeshLoading,
    treatmentPlanTemplateVisits: {
      id,
      order,
      visitType,
      description,
      selfCareFrequency,
      selfCareFrequencyUnit,
      treatmentPlanTemplateSteps,
      treatmentPlanSteps,
      selfCareObjectCategoryName,
      treatmentPlanTemplateStatementNote,
    },
  } = props;

  const location = useLocation();

  const { hasAccess } = useResponsibilities();

  const [loading, setLoading] = useState(false);
  const [statementTemplates, setStatementTemplates] = useState<
    StatementTemplate[]
  >([]);
  const [statementView, setStatementView] = useState(false);

  const HAS_TREATMENT_PLAN_EDIT_ACCESS = hasAccess(
    ResponsibilityTypes.TREATMENT_PLAN_EDIT
  );

  const isTreatmentPlan = () => {
    const paths = location.pathname.split("/");
    if (paths[paths.length - 2] === "treatment-plans") {
      return true;
    }
    return false;
  };

  const RemoveVendorObject = async (
    visits: TreatmentPlanTemplateVisits[],
    actionId: number
  ) => {
    const step = visits.map((visit) => {
      if (isTreatmentPlan()) {
        return visit.treatmentPlanSteps.find((step) => step.id === actionId);
      }
      return visit.treatmentPlanTemplateSteps.find(
        (step) => step.id === actionId
      );
    });

    const removeSet = async (set: DentalSet) => {
      set.setSteps.forEach(async (step) => {
        await handleRemoveLink(step, scene);
      });
    };

    const removeActionStep = async (step: TreatmentPlanTemplateSteps) => {
      await handleRemoveLink(step, scene);
    };
    const currentSet = step?.find((s) => s?.id === actionId);
    if (step.length) {
      if (currentSet?.setId) {
        removeSet(currentSet.set);
        return;
      }

      if (currentSet?.procedure) {
        currentSet?.procedure.procedureSteps.forEach(async (step) => {
          if (step.set) {
            await removeSet(step.set);
            return;
          }
          removeActionStep(step as TreatmentPlanTemplateSteps);
        });
      }
      if (currentSet?.objectLink) {
        await removeActionStep(currentSet as TreatmentPlanTemplateSteps);
      }
    }
  };

  const Appointment = (
    order: number,
    statementTemplates: TreatmentPlanTemplateStatementNotes[],
    steps: TreatmentPlanTemplateSteps[]
  ) => {
    const Header = (
      title: string,
      note: string,
      actionId: number,
      marginTop?: boolean
    ) => {
      const handleDelete = (event: any) => {
        if (!HAS_TREATMENT_PLAN_EDIT_ACCESS) return;
        event.stopPropagation();
        Modal.confirm({
          content: `Are you sure you want to delete ${title}?`,
          okText: "Delete",
          onOk: async () => {
            if (currentTreatmentPlan) {
              const removeAction = async (
                visits: TreatmentPlanTemplateVisits[]
              ) => {
                // TODO: Since we reiterate all steps when templateSteps Changes, there is no need to explicitly remove them
                // await RemoveVendorObject(visits, actionId);
                return visits.map((visit) => {
                  return {
                    ...visit,
                    treatmentPlanSteps: visit.treatmentPlanSteps.filter(
                      (step) => actionId !== step.id
                    ),

                    treatmentPlanTemplateSteps: visit.treatmentPlanTemplateSteps.filter(
                      (step) => actionId !== step.id
                    ),
                  };
                });
              };

              setMeshLoading(true);
              (isTreatmentPlan()
                ? TreatmentPlanService
                : TreatmentTemplateService)["deleteStep"](
                {
                  treatmentPlanId: treatmentPlanId?.toString() ?? "",
                  treatmentPlanVisitId: id?.toString() ?? "",
                  id: actionId.toString() ?? "",
                },
                async () => {
                  setCurrentTreatmentPlan({
                    ...currentTreatmentPlan,
                    treatmentPlanVisits: await removeAction(
                      currentTreatmentPlan.treatmentPlanVisits
                    ),
                    treatmentPlanTemplateVisits: await removeAction(
                      currentTreatmentPlan.treatmentPlanTemplateVisits
                    ),
                  });
                },
                () => {},
                () => {
                  setMeshLoading(false);
                }
              );
            }
          },
        });
      };

      return (
        <div className={`header ${marginTop && "accordion-padding"}`}>
          <div className={`content ${marginTop && "ml-5"}`}>
            <p className="title">{title}</p>
            {note && <p className="value">{note ?? "Description content"}</p>}
          </div>
          {HAS_TREATMENT_PLAN_EDIT_ACCESS && (
            <div>
              <i
                className="icon-delete cursor-pointer"
                onClick={handleDelete}
              />
            </div>
          )}
        </div>
      );
    };

    const fetchTreatmentStatement = () => {
      setLoading(true);
      NoteTemplateService.fetchStatements(
        (statementTemplates) => {
          setStatementTemplates(statementTemplates);
        },
        () => {},
        () => {
          setLoading(false);
        }
      );
    };

    const deleteStatementTemplateNote = (
      id: number,
      treatmentPlanTemplateVisitId: number
    ) => {
      if (!HAS_TREATMENT_PLAN_EDIT_ACCESS) return;
      setLoading(true);
      TreatmentTemplateService.deleteTemplateVisitStatement(
        {
          treatmentPlanId: treatmentPlanId?.toString() ?? "",
          treatmentPlanVisitId: treatmentPlanTemplateVisitId?.toString() ?? "",
          id: id?.toString() ?? "",
        },
        () => {
          onSuccess();
        },
        () => {},
        () => {
          setLoading(false);
        }
      );
    };

    return (
      <div className="appointment-container">
        <Collapse
          bordered={false}
          defaultActiveKey={[]}
          expandIcon={({ isActive }) => (
            <CaretRightOutlined rotate={isActive ? 90 : 0} />
          )}
        >
          {statementTemplates?.length ? (
            <Panel header="Statement" key="1">
              {statementTemplates?.map(
                ({ id, description, treatmentPlanTemplateVisitId }) => {
                  return (
                    <p key={id}>
                      {description ??
                        "Description for treatment execution plan template"}{" "}
                      {HAS_TREATMENT_PLAN_EDIT_ACCESS && (
                        <img
                          onClick={() => {
                            if (
                              id &&
                              treatmentPlanTemplateVisitId &&
                              HAS_TREATMENT_PLAN_EDIT_ACCESS
                            )
                              deleteStatementTemplateNote(
                                id,
                                treatmentPlanTemplateVisitId
                              );
                          }}
                          className="appointment-container__delete-icon "
                          src={deleteIcon}
                          alt="delete"
                        />
                      )}
                    </p>
                  );
                }
              )}
            </Panel>
          ) : null}

          {steps.map(
            ({ setId, set, procedureId, procedure, note, id }, index) =>
              setId || procedureId ? (
                <Panel
                  header={Header(
                    `Action ${order}.${index + 1}`,
                    "" as string,
                    id as number
                  )}
                  key={id as number}
                >
                  {setId &&
                    set?.setSteps?.map(({ id, note }) => (
                      <p key={id} className="step-note ml-5">
                        {note ?? "Step description"}
                      </p>
                    ))}
                  {procedureId &&
                    procedure.procedureSteps?.map(
                      ({ id, note, setId, set }) => {
                        if (setId && set?.setSteps?.length) {
                          const notes = set?.setSteps?.map(({ note }) => (
                            <p key={id} className="step-note ml-5">
                              {note ?? "Step description"}
                            </p>
                          ));
                          return notes;
                        }

                        return (
                          <p key={id} className="step-note ml-5">
                            {note ?? "Step description"}
                          </p>
                        );
                      }
                    )}
                </Panel>
              ) : (
                Header(
                  `Action ${order}.${index + 1}`,
                  note as string,
                  id as number,
                  true
                )
              )
          )}
        </Collapse>
      </div>
    );
  };

  return (
    <Row gutter={[10, 10]}>
      <Col span={24}>
        <div className="appointment-detail">
          <Collapse
            accordion={true}
            bordered={false}
            activeKey={currentAppointment?.toString() ?? ""}
            expandIcon={({ isActive }) => (
              <CaretRightOutlined rotate={isActive ? 90 : 0} />
            )}
            onChange={(key) => {
              if (typeof key === "string") {
                setCurrentAppointment(parseInt(key));
              }
            }}
            className="site-collapse-custom-collapse"
            destroyInactivePanel
          >
            <Panel
              header={description}
              key={id ?? 0}
              className="site-collapse-custom-panel"
            >
              <Row align="middle">
                <Col span={20} className="text-primary">
                  {" "}
                  Statement Notes{" "}
                </Col>
                {HAS_TREATMENT_PLAN_EDIT_ACCESS && (
                  <Col span={4}>
                    {" "}
                    <Popover
                      destroyTooltipOnHide
                      visible={statementView}
                      content={
                        <StatementNotesForm
                          closeModal={() => setStatementView(false)}
                          addStatementNoteHandler={(note: StatementNote) => {
                            onAddStatement(note);
                            setStatementView(false);
                          }}
                        />
                      }
                      placement="leftBottom"
                      overlayClassName="pop-over__dark"
                      trigger="click"
                    >
                      <Button
                        type="dashed"
                        className="mb-2 btn-small btn-add-statement"
                        onClick={() => setStatementView((status) => !status)}
                      >
                        <img
                          style={{ margin: 0 }}
                          src={StatementIcon}
                          alt=""
                          height={14}
                        />
                      </Button>
                    </Popover>
                  </Col>
                )}
              </Row>
              {/* {currentTreatmentPlan?.treatmentPlanTemplateVisits
                          .length ||
                        currentTreatmentPlan?.treatmentPlanVisits.length ? ( */}

              {/* ) : null} */}
              {visitType === "physical"
                ? Appointment(
                    order as number,
                    statementNotes,
                    treatmentPlanTemplateSteps.length
                      ? treatmentPlanTemplateSteps
                      : treatmentPlanSteps
                  )
                : SelfCare(
                    selfCareObjectCategoryName ?? "",
                    `${selfCareFrequency} ${selfCareFrequencyUnit}`,
                    description ?? ""
                  )}
            </Panel>
          </Collapse>
        </div>
      </Col>
    </Row>
  );
};

export default BabylonContainer(
  CorporateContainer(TreatmentPlanContainer(AppointmentDetail))
);
