import { Button, Checkbox, Col, Row } from "antd";
import { Form, Formik } from "formik";
import React, { FC, useState } from "react";
import { useEffect } from "react";
import "./patientFilterForm.scss";
import DatePickerField from "../../../shared/components/DatePickerField";
import RadioInputField from "../../../shared/components/RadioInputField";
import { IDropdownOptions } from "../../../services/Meta/meta.service";
import { TimePeriodEnum } from "../../../enums/timePeriodType.enum";
import CheckboxField from "../../../shared/components/Checkbox";
import { Patient } from "../../../models/Patient/patient.model";
import PatientService from "../../../services/Patient/patient.service";

interface PatientFilterFormProps {
  onClose: () => void;
  onSuccess: (filteredPatient: Patient[]) => void;
}

const PatientFilterForm: FC<PatientFilterFormProps> = (props) => {
  const { onClose, onSuccess } = props;

  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState(new Patient());
  const [radioValue, setRadioValue] = useState("");

  const handleSubmit = (values: Patient) => {
    const handleFetchFilteredPatients = () => {
      setLoading(true);

      PatientService.fetchPatients(
        { status: values?.status },
        (patients: Patient[]) => {
          onSuccess(patients);
          onClose();
        },
        () => {},
        () => {
          setLoading(false);
        }
      );
    };
    handleFetchFilteredPatients();
  };
  const Options = [
    {
      label: "Current Month",
      value: TimePeriodEnum.CURRENT_MONTH,
    },
    {
      label: "Current Quarter",
      value: TimePeriodEnum.CURRENT_QUARTER,
    },
    {
      label: "Current Year",
      value: TimePeriodEnum.CURRENT_YEAR,
    },
  ];
  const checkBoxOptions = [
    {
      label: "Retention",
      value: "retension",
    },
    {
      label: "Active",
      value: "active",
    },
    {
      label: "Consultation",
      value: "consultaion",
    },
    {
      label: "New Patient",
      value: "newPatient",
    },
  ];

  return (
    <div className="patient-filter-form">
      <Formik initialValues={formValues ?? []} onSubmit={handleSubmit}>
        {({ values, setFieldValue }) => {
          return (
            <Form>
              <div className="patient-filter-form__container mt-5">
                <p className="text-primary mb-4">Status</p>
                <Row gutter={[20, 20]}>
                  <Col span={8}>
                    <Checkbox.Group
                      options={checkBoxOptions}
                      onChange={(val) => {
                        setFieldValue("status", val);
                      }}
                    />
                  </Col>
                </Row>
              </div>
              <div className="patient-filter-form__btn text-right mt-5">
                <Button type="text" onClick={onClose}>
                  Cancel
                </Button>
                <Button type="primary" htmlType="submit" loading={loading}>
                  Apply
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default PatientFilterForm;
