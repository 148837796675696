import { PaymentModeEnum } from "enums/paymentMode.enum";
import { TimePeriodEnum } from "enums/timePeriodType.enum";

export const paymentModeOptions = [
  { label: "Cash", value: PaymentModeEnum.CASH },
  { label: "Cheque", value: PaymentModeEnum.CHEQUE },
  { label: "E-Transfer", value: PaymentModeEnum.E_TRANSFER },
  { label: "Master Card", value: PaymentModeEnum.MASTER_CARD },
  { label: "Visa", value: PaymentModeEnum.VISA },
];
