import { Button, Col, Row } from "antd";
import Form from "antd/lib/form/Form";
import { Formik, FormikProps } from "formik";
import React, { FC, useRef } from "react";
import { deserialize } from "serializr";
import { InteractionSubStatuse } from "../../../../../../../models/InteractionSubStatuse/interactionSubStatuse.model";
import PatientStatusService from "../../../../../../../services/PatientStatus/PatientStatus.service";
import InputField from "../../../../../../../shared/components/InputField";
import { userFormValidation } from "../../../../../../../shared/components/UserForm/userFormValidation";
import "./subStatusForm.scss";
const initialValue = {
  name: "",
};

interface SubStatusFormProps {
  onSubmitHandler: (value: InteractionSubStatuse) => void;
  closeHandler: () => void;
  patientStatusId?: number;
  length: number;
  fromValue?: InteractionSubStatuse;
}

const SubStatusForm: FC<SubStatusFormProps> = ({
  closeHandler,
  onSubmitHandler,
  patientStatusId,
  length,
  fromValue,
}) => {
  const ref: any = useRef(null);
  const handleSubmit = (values: InteractionSubStatuse) => {
    const subStatus = Object.assign(new InteractionSubStatuse(), {
      ...values,
      order: length,
    });
    const method = fromValue ? "updatePatientStatus" : "createPatientStatus";
    PatientStatusService[method](
      patientStatusId as number,
      subStatus,
      (subStatus) => {
        ref.current?.resetForm();
        onSubmitHandler(subStatus);
        closeHandler();
      },
      () => {},
      () => {}
    );
  };
  return (
    <div className="sub-status-form__container">
      <Formik
        initialValues={fromValue || new InteractionSubStatuse()}
        onSubmit={handleSubmit}
        validationSchema={userFormValidation}
        innerRef={ref}
      >
        {({ values, resetForm, isValid, dirty }) => (
          <Form>
            <InputField title="Sub Status" type="text" name="name" />
            <InputField title="Color" type="color" name="colorCode" value={values?.colorCode} />
            <Row>
              <Col span={12} />
              <Col span={6}>
                <Button
                  type="default"
                  onClick={() => {
                    closeHandler();
                    resetForm();
                  }}
                >
                  Cancel
                </Button>
              </Col>
              <Col span={6}>
                <Button
                  type="primary"
                  disabled={isValid || !dirty}
                  onClick={() => {
                    handleSubmit(values);
                  }}
                >
                  Save
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default SubStatusForm;
