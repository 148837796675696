import React, { FC } from "react";
import { Row, Col, Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import PlaceholderImage from "../../../assets/images/globalNoteTemplateBanner.png";
import "./emptyPlaceholder.scss";

interface EmptyPlaceholder {
	title: string;
	description: string;
	image: any;
	btnText: string;
	onClickHandler: () => void;
	btnVisible?: boolean;
}

const EmptyPlaceholder: FC<EmptyPlaceholder> = (props) => {
	const { title, description, image, btnText, onClickHandler, btnVisible = true } = props;

	return (
		<div className="empty-placeholder">
			<Row>
				<Col span={8}>
					<div>
						<img src={image || PlaceholderImage} alt="" />
					</div>
				</Col>
				<Col span={16}>
					<h3>{title}</h3>
					<p>{description}</p>
					{btnVisible && <Button
						className="secondary-btn mt-3"
						onClick={onClickHandler}
					>
						<PlusOutlined className="add-icon" /> {btnText}
					</Button>}
				</Col>
			</Row>
		</div>
	);
};

export default EmptyPlaceholder;
