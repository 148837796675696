import React, { FC, useState, CSSProperties, useEffect } from "react";
import { Scene, Vector3 } from "@babylonjs/core";
import { Slider } from "antd";
import MouthClose from "../../../assets/icons/mouthClose.png";
import MouthExpand from "../../../assets/icons/mouthExpan.png";
import MouthOpen from "../../../assets/icons/mouthOpen.png";
import MouthShrink from "../../../assets/icons/mouthShrink.png";
import { openTeethPosition } from "../../../views/Settings/Configuration/Procedures/ProcedureViewer/toothPositions";
import {} from "../../../views/Settings/Configuration/TreatmentTemplate/";
import { PositionProps } from "../../Types/TeethPosition";
import "./mouthSlider.scss";
import { childTeethNames, teethTypes, teethPositions } from "./data";

let meshNames: string[] = [...childTeethNames];

teethTypes.forEach((teeth) => {
  const [name, position] = teeth.split("_");
  const getName = (pos: number) => `${name}${pos}${position}`;
  meshNames = [...meshNames, getName(1), getName(2), getName(3), getName(4)];
});

interface MouthSliderProps {
  scene: Scene;
  styles?: {
    horizontal?: CSSProperties;
    vertical?: CSSProperties;
  };
  zAxisLimit: number;
  positions: PositionProps;
  setPositions: (
    setPositions: (positions: PositionProps) => PositionProps | PositionProps
  ) => void;
  first: boolean;
  setFirst: (first: boolean) => void;
  isResetTeethPosition?: boolean;
}

const MouthSlider: FC<MouthSliderProps> = (props) => {
  const {
    scene,
    styles,
    zAxisLimit,
    positions,
    first,
    setPositions,
    setFirst,
    isResetTeethPosition,
  } = props;

  const [spreadValue, setSpreadValue] = useState(0);

  const [openValue, setOpenValue] = useState(0);

  const handleSpreadTeeth = (spreadVal: number) => setSpreadValue(spreadVal);

  const handleOpenTeeth = (openVal: number) => setOpenValue(openVal);

  const handleTeethReset = () => {
    handleSpreadTeeth(0);
    handleOpenTeeth(0);
  };

  const isMolarOrPreMolarTeeth = (teethName: string) =>
    teethName === "Premolar" || teethName === "Molar";

  const handleSlideSpreadTeeth = (spreadValue: number) => {
    const updatePositionX = (value: number, name: string) => {
      const teethName = name.slice(0, -2);
      const isMolarOrPreMolar = isMolarOrPreMolarTeeth(teethName);
      const position =
        teethName + (isMolarOrPreMolar ? name.charAt(name.length - 1) : "");
      const inc = spreadValue / teethPositions[position].x + 1;
      return value * inc;
    };

    const updatePositionZ = (value: number) => {
      const inc = ((value - zAxisLimit) / 100) * spreadValue;
      return value - inc;
    };
    meshNames.forEach((name) => {
      const mesh = scene?.getMeshByName(name);
      try {
        if (mesh && positions[mesh.name].x) {
          const { y } = mesh.position;
          const x = updatePositionX(positions[mesh.name].x, mesh.name);
          const z = updatePositionZ(positions[mesh.name].z);
          mesh.position = new Vector3(x, y, z);
        }
      } catch (error) {}
    });
  };

  const handleSliderOpenTeeth = (openValue: number) => {
    const updatePositionY = (value: number, name: string) => {
      const teethName = name.slice(0, -2);
      const isMolarOrPreMolar = isMolarOrPreMolarTeeth(teethName);
      const positionProp =
        teethName + (isMolarOrPreMolar ? name.charAt(name.length - 1) : "");
      const inc = openValue / openTeethPosition[positionProp] + 1;
      return value * inc;
    };

    for (let name of meshNames) {
      const mesh = scene?.getMeshByName(name);
      if (mesh && positions[mesh.name].y) {
        const { x, y, z } = mesh.position;
        if (first) {
          setPositions((existingPositions) => ({
            ...existingPositions,
            [name]: {
              ...existingPositions[name],
              y,
              rotateX: mesh.rotation.x,
            },
          }));
        }
        const yAxis = updatePositionY(
          first ? y : positions[mesh.name].y,
          mesh.name
        );
        mesh.position = new Vector3(x, yAxis, z);
      }
    }
    setFirst(false);
  };

  useEffect(() => handleSlideSpreadTeeth(spreadValue), [spreadValue]);
  useEffect(() => handleSliderOpenTeeth(openValue), [openValue]);
  useEffect(() => handleTeethReset(), [isResetTeethPosition]);

  return (
    <div className="mouth-slider">
      <div
        className="babylon-viewer__slider__container babylon-horizontal"
        style={styles?.horizontal}
      >
        <img src={MouthShrink} alt="" />
        <Slider
          className="babylon-viewer__slider"
          value={spreadValue}
          tooltipVisible={false}
          onChange={handleSpreadTeeth}
        />
        <img src={MouthExpand} alt="" />
      </div>
      <div
        className="babylon-viewer__slider__container babylon-vertical"
        style={styles?.vertical}
      >
        <img src={MouthClose} alt="" />
        <Slider
          reverse
          vertical
          tooltipVisible={false}
          className="babylon-viewer__slider"
          value={openValue}
          onChange={handleOpenTeeth}
        />
        <img src={MouthOpen} alt="" />
      </div>
    </div>
  );
};

export default MouthSlider;
