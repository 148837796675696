
import {serializable, alias, object, list, primitive} from 'serializr';

export class TimeZone { 

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('offset_sign', primitive()))
	offsetSign?: string;

	@serializable(alias('hours', primitive()))
	hours?: number;

	@serializable(alias('minutes', primitive()))
	minutes?: number;

	@serializable(alias('time_zone', primitive()))
	timeZone?: string;

	@serializable(alias('name', primitive()))
	name?: string;

}