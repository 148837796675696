import * as Yup from "yup";
import { validateNumber } from "../../../../../shared/utils/validatorUtils";

export const procedureSetFormValidation = Yup.object().shape({
  setId: validateNumber("SetId is required", true),
});

export const procedureLinkFormValidation = Yup.object().shape({
  destObjectId: validateNumber("Destination Object is required", true),
  corporateLinkId: validateNumber("Corporate Link is required", true),
});
