function debounce(func: Function, timeout = 300) {
  let timer: NodeJS.Timeout;
  return (...args: any) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      //@ts-ignore
      func.apply(this, args);
    }, timeout);
  };
}

function arrayMatch(arr1: any[], arr2: any[]) {
  const filteredStrArray = arr1
    .filter((value) => arr2.includes(value))
    .filter((value, index, self) => self.indexOf(value) === index);
    return filteredStrArray;
}

export const compareStringByIgnoringCase = (str1?: string, str2?: string) =>
  str1?.toLowerCase() === str2?.toLowerCase();

export default { debounce, arrayMatch };
