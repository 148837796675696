import { CreateReducer } from "../../shared/utils/createReducer";
import { Procedure } from "../../models/Procedure/procedure.model";
import { SetCurrentProcedureAction } from "../actions/procedureActions";
import { SET_CURRENT_PROCEDURE } from "../definitions/procedureConstants";

interface IProcedureState {
  currentProcedure?: Procedure;
}

export interface ProcedureReducerProps extends IProcedureState {
  setCurrentProcedure: (
    currentProcedure: Procedure
  ) => SetCurrentProcedureAction;
}

const initState: IProcedureState = {
  currentProcedure: undefined,
};

const procedureReducer = CreateReducer(initState, {
  [SET_CURRENT_PROCEDURE](state: any, action: any) {
    const { currentProcedure } = action.payload;
    return {
      ...state,
      currentProcedure,
    };
  },
});

export default procedureReducer;
