import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  Schedule,
  WeekDaySchedule,
} from "../../../models/Schedule/schedule.model";
import { Form, Formik, FormikValues } from "formik";
import { Button, Col, Row, Switch } from "antd";
import { scheduleFormValidation } from "./scheduleFormValidation";
import "./scheduleForm.scss";
import { IDropdownOptions } from "../../../services/Meta/meta.service";
import PracticeService from "../../../services/Practice/practice.service";
import { Practice } from "../../../models/Practice/practice.model";
import DropdownField from "../../../shared/components/DropdownField";
import { AvailabilityTypeEnum } from "../../../enums/availabilityType.enum";
import { DropdownModeEnum } from "../../../enums/dropdownMode.enum";
import DatePickerField from "../../../shared/components/DatePickerField";
import moment from "moment";
import { getDateOptions } from "../../../shared/utils/dateHelpers";
import TimePickerField from "../../../shared/components/TimePickerField";
import { User } from "../../../models/User/user.model";
import UserService from "../../../services/User/user.service";
import ScheduleService from "../../../services/Schedule/schedule.service";
import RadioInputField from "../../../shared/components/RadioInputField";
import { WeekDay } from "../../../enums/weekDay.enum";
import { PaginationDetails } from "../../../models/Pagination/pagination.model";
import { debounce } from "lodash";
import { uniqueObjectValue } from "../../../shared/utils/uniqueObjectValue";
import { SelectProp } from "../../../shared/Types/option";

interface ScheduleFormProps {
  onSuccess: () => void;
  onCancel: () => void;
  userId?: number;
  isMultiple?: boolean;
}

const weekDaySchedules = [
  new WeekDaySchedule(false, WeekDay.SUNDAY),
  new WeekDaySchedule(true, WeekDay.MONDAY),
  new WeekDaySchedule(true, WeekDay.TUESDAY),
  new WeekDaySchedule(true, WeekDay.WEDNESDAY),
  new WeekDaySchedule(true, WeekDay.THURSDAY),
  new WeekDaySchedule(true, WeekDay.FRIDAY),
  new WeekDaySchedule(false, WeekDay.SATURDAY),
];

function ScheduleForm({
  isMultiple,
  userId,
  onSuccess,
  onCancel,
}: ScheduleFormProps) {
  const [formValues, setFormValues] = useState({
    ...new Schedule(),
    weekDaySchedules: [...weekDaySchedules],
  });

  const [userOptions, setUserOptions] = useState<IDropdownOptions[]>([]);

  const [formLoading, setFormLoading] = useState(false);
  const [pagination, setPagination] = useState<PaginationDetails>(
    new PaginationDetails()
  );

  const [exceptionDates, setExceptionDates] = useState<IDropdownOptions[]>([]);

  const [practiceOptions, setPracticeOptions] = useState<SelectProp[]>([]);

  const appointmentTypes = [
    { label: "InPerson", value: AvailabilityTypeEnum.IN_PERSON },
    { label: "Virtual", value: AvailabilityTypeEnum.VIRTUAL },
  ];

  const handleSubmit = (values: FormikValues) => {
    const { weekDaySchedules, ...otherValues } = values;

    const schedule = Object.assign(
      new Schedule(),
      !values.isBasedOnWeekdays ? otherValues : values
    );
    setFormLoading(true);
    ScheduleService.createSchedule(
      schedule,
      () => {
        onSuccess();
        onCancel();
      },
      () => {},
      () => {
        setFormLoading(false);
      }
    );
  };
  const handleFetchPractice = debounce(() => {
    if (pagination?.nextPage) {
      PracticeService.fetchPractices(
        (practice: Practice[], pagination: PaginationDetails) => {
          setPagination(pagination);
          const data = practice?.map(({ name, id }) => ({
            label: name,
            value: id,
          }));
          setPracticeOptions(uniqueObjectValue(practiceOptions, data));
        },
        () => {},
        () => {},
        { page: pagination?.nextPage }
      );
    }
  }, 50);
  useEffect(() => {
    handleFetchPractice();

    if (isMultiple) {
      UserService.fetchUsers(
        {},
        (users: User[]) => {
          setUserOptions(
            users.map((user: User) => ({
              value: user.profileId,
              label: user.getFullName(),
            }))
          );
        },
        () => {},
        () => {}
      );
    } else {
      if (userId) {
        setFormValues({
          ...new Schedule(),
          profileIds: [userId],
          weekDaySchedules,
        });
      }
    }
  }, [isMultiple, userId]);

  return (
    <div className="schedule-form">
      <Formik
        initialValues={formValues}
        onSubmit={handleSubmit}
        enableReinitialize
        validationSchema={scheduleFormValidation}
      >
        {({
          handleSubmit,
          values,
          errors,
          isValid,
          dirty,
          setFieldValue,
          setTouched,
          touched,
        }) => {
          return (
            <Form>
              <Row gutter={[20, 0]}>
                <Col span={24} className="schedule-form__availabiltiy">
                  <span>Availability</span>
                  <Switch
                    checked={values.availability}
                    className="ml-4"
                    onChange={(checked) => {
                      setFieldValue("availability", checked);
                    }}
                  />
                </Col>
                <Col span={12}>
                  <DropdownField
                    title="Practice name"
                    name="practiceName"
                    options={practiceOptions}
                    value={values.practiceId}
                    setFieldValue={setFieldValue}
                    onPopupScroll={handleFetchPractice}
                    placeHolder="Select Practice"
                  />
                </Col>
                {isMultiple && (
                  <Col span={12}>
                    <DropdownField
                      title="Staffs"
                      name="profileIds"
                      options={userOptions}
                      mode={DropdownModeEnum.MULTIPLE}
                      value={values.profileIds}
                      setFieldValue={setFieldValue}
                      placeHolder="Select Staffs"
                      showSearch
                    />
                  </Col>
                )}
                <Col span={12}>
                  <DropdownField
                    title="Type of Appointment"
                    name="availabilityTypes"
                    options={appointmentTypes}
                    mode={DropdownModeEnum.MULTIPLE}
                    value={values.availabilityTypes.map(
                      (availabilityType) => availabilityType.type
                    )}
                    onChange={(values) => {
                      setFieldValue(
                        "availabilityTypes",
                        values?.map((value: string) => ({
                          type: value,
                        }))
                      );
                    }}
                    placeHolder="Select appointment type"
                  />
                </Col>
                <Col span={12}>
                  <DatePickerField
                    title="Start Date"
                    name="startDate"
                    placeholder=""
                    defaultValue={values.startDate}
                    disabledDate={(d) => {
                      const yesterday = moment().subtract(1, "day");
                      return d.isBefore(yesterday);
                    }}
                    onChange={(date: any) => {
                      const startDate = date.format("YYYY-MM-DD");
                      setFieldValue("startDate", startDate);
                      if (startDate && values.endDate) {
                        setExceptionDates(
                          getDateOptions(startDate, values.endDate)
                        );
                      }
                    }}
                  />
                </Col>
                <Col span={12}>
                  <DatePickerField
                    title="End Date"
                    name="endDate"
                    placeholder=""
                    disabledDate={(date) =>
                      date < moment(values.startDate, "YYYY-MM-DD")
                    }
                    defaultValue={values.endDate}
                    onChange={(date: any, dateString: any) => {
                      const endDate = date.format("YYYY-MM-DD");
                      setFieldValue("endDate", endDate);
                      if (values.startDate && endDate) {
                        setExceptionDates(
                          getDateOptions(values.startDate, endDate)
                        );
                      }
                    }}
                  />
                </Col>
                {values?.startDate && values.endDate && (
                  <Col span={12}>
                    <DropdownField
                      title="Exception Dates"
                      name="exceptionDates"
                      options={exceptionDates}
                      mode={DropdownModeEnum.MULTIPLE}
                      value={values.exceptionDates}
                      setFieldValue={setFieldValue}
                      placeHolder="Select Exception dates"
                    />
                  </Col>
                )}
              </Row>
              {values?.availability && values?.startDate && values?.endDate && (
                <>
                  <div>
                    <h4>Availability For Selected Date</h4>
                    <Row gutter={[20, 0]}>
                      <Col span={24}>
                        <RadioInputField
                          name="isBasedOnWeekdays"
                          options={[
                            {
                              label: "Provide based on week days",
                              value: true,
                            },
                            {
                              label: "Same for all selected days",
                              value: false,
                            },
                          ]}
                          setFieldValue={setFieldValue}
                          value={values?.isBasedOnWeekdays}
                        />
                      </Col>
                    </Row>
                  </div>
                  {!!values?.isBasedOnWeekdays ? (
                    values?.weekDaySchedules?.map((schedule, index) => (
                      <Row
                        className="schedule-form__availability__slots"
                        align="middle"
                        gutter={[15, 15]}
                      >
                        <Col className="text-capitalize" span={4}>
                          {schedule?.weekday}
                        </Col>
                        <Col
                          className="text-capitalize"
                          span={schedule?.isOpen ? 6 : 20}
                        >
                          <Switch
                            checked={values.weekDaySchedules[index].isOpen}
                            className="mr-4"
                            onChange={(checked) => {
                              setFieldValue(
                                `weekDaySchedules.[${index}].isOpen`,
                                checked
                              );
                            }}
                          />
                          <span className="text-capitalize">
                            {schedule?.isOpen ? "available" : "leave"}
                          </span>
                        </Col>
                        {values?.weekDaySchedules[index].isOpen && (
                          <>
                            <Col className="text-capitalize" span={5}>
                              <TimePickerField
                                format="hh:mmA"
                                name={`weekDaySchedules[${index}].startTime`}
                                value={
                                  values.weekDaySchedules[index].startTime ??
                                  undefined
                                }
                                setFieldValue={setFieldValue}
                              />
                            </Col>
                            <Col className="text-capitalize" span={5}>
                              <TimePickerField
                                name={`weekDaySchedules[${index}].endTime`}
                                value={
                                  values.weekDaySchedules[index].endTime ??
                                  undefined
                                }
                                setFieldValue={setFieldValue}
                              />
                            </Col>
                          </>
                        )}
                      </Row>
                    ))
                  ) : (
                    <Row gutter={[20, 0]}>
                      <Col span={12}>
                        <TimePickerField
                          title="Start time"
                          name="startTime"
                          value={values.startTime}
                          setFieldValue={setFieldValue}
                          touched={touched?.startTime}
                          error={errors?.startTime}
                          onTouched={() => {
                            setTouched({
                              ...touched,
                              startTime: true,
                            });
                          }}
                        />
                      </Col>
                      <Col span={12}>
                        <TimePickerField
                          title="End time"
                          name="endTime"
                          value={values.endTime}
                          setFieldValue={setFieldValue}
                          touched={touched?.endTime}
                          error={errors?.endTime}
                          onTouched={() => {
                            setTouched({
                              ...touched,
                              endTime: true,
                            });
                          }}
                        />
                      </Col>
                    </Row>
                  )}
                </>
              )}
              <div className="user-form__submit-wrapper text-right">
                <Button onClick={onCancel} type="default">
                  Cancel
                </Button>
                <Button
                  type="primary"
                  className="ml-2"
                  htmlType="submit"
                  loading={formLoading}
                  disabled={!isValid || !dirty || formLoading}
                >
                  Save
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default ScheduleForm;
