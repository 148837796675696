import { Button } from "antd";
import { Form, Formik } from "formik";
import React, { FC, useState, useRef } from "react";
import SignaturePad from "react-signature-canvas";

import "./signatureInput.scss";

interface SignatureInputProps {
  name: string;
  onUpload: (sign?: File) => Promise<void>;
  title?: string;
}

const SignatureInput: FC<SignatureInputProps> = ({ name, onUpload, title }) => {
  const signPad = useRef<SignaturePad>(null);

  const [canvasSignature, setCanvasSignature] = useState<File>();

  const parseFileFromBlob = (blob: Blob | null) => {
    if (!blob) return;

    const parsedFile = new File([blob], "signature.jpg", {
      type: "image/jpeg",
    });

    setCanvasSignature(parsedFile);
  };

  const getSignature = () =>
    signPad?.current?.getTrimmedCanvas().toBlob(parseFileFromBlob);

  const clearSignature = () => {
    setCanvasSignature(undefined);
    signPad?.current?.clear();
    onUpload();
  };

  const handleUpload = () => onUpload(canvasSignature);

  return (
    <Formik onSubmit={handleUpload} initialValues={{ name }}>
      {({ isSubmitting, setFieldValue }) => (
        <Form className="signature-input">
          <div className="signature-input__field">
            <span className="signature-input__field__title">{title}</span>
            <SignaturePad
              ref={signPad}
              onEnd={() => setFieldValue(name, getSignature())}
            />
          </div>
          <div className="signature-input__submit">
            <Button onClick={clearSignature}>Clear</Button>
            <Button
              disabled={!canvasSignature}
              loading={isSubmitting}
              type="primary"
              htmlType="submit"
            >
              Upload
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default SignatureInput;
