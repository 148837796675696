import ApiRoutes from "../../routes/routeConstants/apiRoutes";
import axiosInstance from "../../interceptor/axiosInstance";
import { CustomFormResponse } from "../../models/CustomFormResponse/customFormResponse.model";
import { deserialize, serialize } from "serializr";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import { CustomFormTypeEnum } from "../../enums/customFormType.enum";
import { CustomResponseFormSignature } from "../../models/CustomResponseFormSignature/customResponseFormSignature.model";

export default class CustomFormResponseService {
  static fetchCustomFormResponses(
    type: CustomFormTypeEnum,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void,
    id?: number
  ) {
    axiosInstance
      .get(ApiRoutes.CUSTOM_FORM_RESPONSE, {
        params: {
          requestable_type: type,
          requestable_id: id,
        },
      })
      .then((response) => {
        const customFormResponses = deserialize(
          CustomFormResponse,
          response.data["custom_form_responses"]
        );
        onSuccess(customFormResponses);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static showCustomFormResponse(
    customFormResponseId: string,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL =
      ApiRoutes.CUSTOM_FORM_RESPONSES + "/" + customFormResponseId;
    axiosInstance
      .get(API_URL)
      .then((response) => {
        const customFormResponse = deserialize(
          CustomFormResponse,
          response.data["customFormResponse"]
        );
        onSuccess(customFormResponse);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static createCustomFormResponse(
    customFormRequestId: number,
    customFormResponse: CustomFormResponse,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.CUSTOM_FORM_RESPONSES.replace(
      ":customFormRequestId",
      customFormRequestId.toString()
    );
    const customFormResponseJSON = {
      custom_form_response: serialize(customFormResponse),
    };
    axiosInstance
      .post(API_URL, customFormResponseJSON)
      .then((response) => {
        Notification({
          message: "Custom form response created!",
          type: NotificationTypes.SUCCESS,
        });
        const customFormResponse = deserialize(
          CustomFormResponse,
          response.data["custom_form_response"]
        );
        onSuccess(customFormResponse);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static updateCustomFormResponse(
    customFormResponse: CustomFormResponse,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL =
      ApiRoutes.CUSTOM_FORM_RESPONSES + "/" + customFormResponse.id;
    const customFormResponseJSON = serialize(customFormResponse);
    axiosInstance
      .put(API_URL, customFormResponseJSON)
      .then((response) => {
        const customFormResponse = deserialize(
          CustomFormResponse,
          response.data["customFormResponse"]
        );
        onSuccess(customFormResponse);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static deleteCustomFormResponse(
    customFormResponseId: string,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL =
      ApiRoutes.CUSTOM_FORM_RESPONSES + "/" + customFormResponseId;
    axiosInstance
      .delete(API_URL)
      .then((response) => {
        onSuccess();
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static async uploadSignature(
    sign?: File,
    onSuccess?: (sign: CustomResponseFormSignature) => void,
    onError?: Function,
    onFinal?: Function
  ) {
    try {
      if (!sign) return;

      const formData = new FormData();
      formData.append("customresponsesignature.signature", sign);
      const { data } = await axiosInstance.post(
        ApiRoutes.CUSTOM_FORM_RESPONSE_SIGNATURE,
        formData
      );
      const signature = deserialize(
        CustomResponseFormSignature,
        data["customresponsesignature"]
      );
      Notification({
        message: "Signature Uploaded",
        description: "",
        type: NotificationTypes.SUCCESS,
      });
      onSuccess?.(signature);
    } catch (ex) {
      Notification({
        message: "Signature Upload Failed",
        description: "",
        type: NotificationTypes.ERROR,
      });
      onError?.(ex);
    } finally {
      onFinal?.();
    }
  }
}
