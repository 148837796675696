import { User, UserPermission } from "../../models/User/user.model";
import ApiRoutes from "../../routes/routeConstants/apiRoutes";
import axiosInstance from "../../interceptor/axiosInstance";
import { deserialize, serialize } from "serializr";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import { convertJSONToFormData } from "../../shared/utils/dataFormatConverter";
import { UserRole } from "../../models/UserRole/userRole.model";
import { PaymentMethod } from "../../models/PaymentMethod/paymentMethod.model";
import { Suggestion } from "../../models/Suggestion/suggestion.model";
import { FilterParams } from "../../models/FilterParams/filterParams.model";
import { PaginationDetails } from "../../models/Pagination/pagination.model";
import AuthService from "../Auth/auth.service";
import AppRoutes from "../../routes/routeConstants/appRoutes";

type Role = "doctor" | "admin" | "assistant" | "front_desk";
export interface UserOptions {
  role_category?: Role;
}
export default class UserService {
  static fetchUsers(
    filter: UserOptions | FilterParams,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const params = serialize(FilterParams, filter);
    return axiosInstance
      .get(ApiRoutes.USERS, {
        params,
      })
      .then((response) => {
        const users = deserialize(User, response.data["users"]);
        const pagination = deserialize(
          PaginationDetails,
          response.data["meta"]
        );
        onSuccess(users, pagination);
      })
      .catch((error) => {
        onError();
      })
      .finally(onFinal);
  }
  static fetchNewUsers(
    patientId: string,
    params: UserOptions,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    return axiosInstance
      .get(ApiRoutes.NEW_USERS.replace(":patientProfileId", patientId), {
        params,
      })
      .then((response) => {
        const users = deserialize(User, response.data["users"]);
        const suggestedDoctor = deserialize(Suggestion, response.data);
        onSuccess(users, suggestedDoctor);
      })
      .catch((error) => {
        onError();
      })
      .finally(onFinal);
  }

  static fetchUserRoles(
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    return axiosInstance
      .get(ApiRoutes.USER_ROLES)
      .then((response) => {
        const userRoles = deserialize(UserRole, response.data["roles"]);
        onSuccess(userRoles);
      })
      .catch((error) => {
        onError();
      })
      .finally(onFinal);
  }

  static getCurrentUserDetails(
    onSuccess: (user: User) => void,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.CURRENT_USER_DETAILS;
    return axiosInstance
      .get(API_URL)
      .then((response) => {
        const user = deserialize(User, response.data["user"]);
        if (user.userProfiles && user.userProfiles.length > 0) {
          localStorage.setItem(
            "corporateId",
            user.userProfiles[0].id?.toString() ?? ""
          );
        }
        onSuccess(user);
      })
      .catch((error) => {
        Notification({
          message: "Unable to get User details",
          description: "",
          type: NotificationTypes.ERROR,
          onClose: () =>
            AuthService.logout(
              () => window.location.replace(AppRoutes.LOGIN),
              () => {},
              () => {}
            ),
        });
        onError();
      })
      .finally(onFinal);
  }

  static showUser(
    userId: number,
    onSuccess: (user: User) => void,
    onError: Function,
    onFinal: () => void
  ) {
    const corporateId = localStorage.getItem("corporateId");
    if (!corporateId) {
      return;
    }
    const API_URL = ApiRoutes.USERS + "/" + userId.toString();
    return axiosInstance
      .get(API_URL)
      .then((response) => {
        const user = deserialize(UserPermission, response.data["user"]);
        onSuccess(user);
      })
      .catch((error) => {
        onError();
      })
      .finally(onFinal);
  }

  static createUser(
    user: User,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const userJSON = { user: serialize(user) };
    const userFormData = convertJSONToFormData(userJSON);
    if (user.profilePicture) {
      userFormData.append("user.profile_pic", user.profilePicture);
    }
    return axiosInstance
      .post(ApiRoutes.USERS, userFormData)
      .then((response) => {
        Notification({
          message: "User created!",
          type: NotificationTypes.SUCCESS,
        });
        const user = deserialize(User, response.data["user"]);
        onSuccess(user);
      })
      .catch((error) => {
        onError();
      })
      .finally(onFinal);
  }

  static updateUser(
    user: User,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.USERS + "/" + user?.id?.toString();
    const userJSON = { user: serialize(user) };
    const userFormData = convertJSONToFormData(userJSON);
    if(!user?.isActive)
      userFormData.append("user[is_active]", `${user?.isActive}`);
    if (user.profilePicture) {
      userFormData.append("user.profile_pic", user.profilePicture);
    }
    return axiosInstance
      .put(API_URL, userFormData)
      .then((response) => {
        Notification({
          message: "User updated!",
          type: NotificationTypes.SUCCESS,
        });
        const user = deserialize(User, response.data["user"]);
        onSuccess(user);
      })
      .catch(() => {
        onError();
      })
      .finally(onFinal);
  }

  static deleteUser(
    userId: number,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.USERS + "/" + userId;
    return axiosInstance
      .delete(API_URL)
      .then((response) => {
        Notification({
          message: "User deleted!",
          type: NotificationTypes.SUCCESS,
        });
        onSuccess();
      })
      .catch((error) => {
        onError();
      })
      .finally(onFinal);
  }

  static getProfilePaymentMethods(
    onSuccess: (paymentMethods: PaymentMethod[]) => void,
    onError: () => void,
    onFinal: () => void
  ) {
    return axiosInstance
      .get(ApiRoutes.GET_PROFILE_PAYMENT_METHODS)
      .then((response) => {
        const paymentMethods = deserialize(
          PaymentMethod,
          response?.data?.payment_methods as PaymentMethod[]
        );
        onSuccess(paymentMethods);
      })
      .catch((error) => {
        onError();
      })
      .finally(onFinal);
  }
  static async validationUserPhone(phone: number, isdCode: number) {
    try {
      await axiosInstance.post(ApiRoutes.USERS_PHONE_VALIDATION, {
        mobile_number: phone,
        isd_code: isdCode,
      });
      return true;
    } catch (error) {
      return false;
    }
  }
}
