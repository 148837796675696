import { deserialize } from "serializr";
import axiosInstance from "../../interceptor/axiosInstance";
import { StatementTemplate } from "../../models/Statement/statement.model";
import ApiRoutes from "../../routes/routeConstants/apiRoutes";

class StatementNotesService {
  static fetchStatementNotes(
    interactionId: number,
    onSuccess: (statementTemplates: StatementTemplate[]) => void,
    onError: () => void,
    onFinal: () => void
  ) {
    const URL = ApiRoutes.GET_STATEMENT_NOTES.replace(
      ":interactionId",
      interactionId.toString()
    );
    return axiosInstance
      .get(URL)
      .then((response) => {
        const statementTemplates = deserialize(
          StatementTemplate,
          response.data["statement_notes"] as any[]
        );
        onSuccess(statementTemplates);
      })
      .catch(() => {
        onError();
      })
      .finally(onFinal);
  }

  static createStatementNotes(
    interactionId: number,
    statementTemplateId: number,
    onSuccess: (statementTemplate: StatementTemplate) => void,
    onError: () => void,
    onFinal: () => void
  ) {
    const URL = ApiRoutes.GET_STATEMENT_NOTES.replace(
      ":interactionId",
      interactionId.toString()
    );
    const requestJSON = {
      statement_note: {
        statement_template_id: statementTemplateId,
      },
    };
    return axiosInstance
      .post(URL, requestJSON)
      .then((response) => {
        const statementTemplate = deserialize(
          StatementTemplate,
          response.data["statement_note"]
        );
        onSuccess(statementTemplate);
      })
      .catch(() => {
        onError();
      })
      .finally(onFinal);
  }

  static deleteStatementNotes(
    id: number,
    interactionId: number,
    onSuccess: () => void,
    onError: () => void,
    onFinal: () => void
  ) {
    const URL =
      ApiRoutes.GET_STATEMENT_NOTES.replace(
        ":interactionId",
        interactionId.toString()
      ) + `/${id}`;
    return axiosInstance
      .delete(URL)
      .then(() => {
        onSuccess();
      })
      .catch(() => {
        onError();
      })
      .finally(onFinal);
  }
}

export default StatementNotesService;
