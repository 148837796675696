import {
  SET_CURRENT_SET,
  SET_DENTAL_SET_SCENE,
} from "../definitions/setConstants";
import { DentalSet } from "../../models/DentalSet/dentalSet.model";

export interface SetCurrentSetAction {
  type: typeof SET_CURRENT_SET;
  payload: { currentSet: DentalSet };
}

export interface SetDentalSetSceneAction {
  type: typeof SET_DENTAL_SET_SCENE;
  payload: { scene: any };
}

export const setCurrentSet = (currentSet: DentalSet): SetCurrentSetAction => {
  return {
    type: SET_CURRENT_SET,
    payload: {
      currentSet,
    },
  };
};

export const setDentalSetScene = (scene: any): SetDentalSetSceneAction => {
  return {
    type: SET_DENTAL_SET_SCENE,
    payload: {
      scene,
    },
  };
};
