import React, { useState } from "react";
import "./selectCorporateObjects.scss";
import { DentalObject } from "../../../../../models/DentalObject/dentalObject.model";
import { Table, Checkbox, Button, Input } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import CorporateObjectService from "../../../../../services/CorporateObject/corporateObject.service";
import { CorporateObject } from "../../../../../models/CorporateObject/corporateObject.model";
import BabylonContainer from "../../../../../store/container/BabylonContainer";
import { BabylonReducerProps } from "../../../../../store/reducers/babylonReducer";
import { LocalStorageKeys } from "../../../../../enums/localStorageKeys.enum";
import { UrlMeta } from "../../../../../models/UrlMeta/UrlMeta.model";

interface SelectCorporateObjectsProps extends BabylonReducerProps {
  dentalObjects: DentalObject[];
  selectedObjectIds: number[];
  setSelectedObjectIds: Function;
  onSuccess: () => void;
}

function SelectCorporateObjects({
  dentalObjects,
  selectedObjectIds,
  setSelectedObjectIds,
  onSuccess,
  setCorporateObjectsLoading,
  setCorporateObjects,
}: SelectCorporateObjectsProps) {
  const [formLoading, setFormLoading] = useState(false);

  const handleSubmit = () => {
    setFormLoading(true);
    CorporateObjectService.importDentalObjects(
      selectedObjectIds,
      () => {
        onSuccess();
        setCorporateObjectsLoading(true);
        CorporateObjectService.fetchCorporateObjects(
          {},
          "",
          (corporateObjects: CorporateObject[], _: any, meta: UrlMeta) => {
            setCorporateObjects(corporateObjects);
            localStorage.setItem(
              LocalStorageKeys.CORPORATE_OBJECTS,
              JSON.stringify(corporateObjects)
            );
            localStorage.setItem(
              LocalStorageKeys.CORPORATE_OBJECTS_EXPIRY,
              meta?.expiryTime || ""
            );
          },
          () => {},
          () => {
            setCorporateObjectsLoading(false);
          }
        );
      },
      () => {},
      () => {
        setFormLoading(false);
      }
    );
  };

  const handleCheckRecord = (id: number) => (e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      selectedObjectIds.push(id);
    } else {
      const objectIndex = selectedObjectIds.findIndex(
        (objectId) => objectId === id
      );
      if (objectIndex >= 0) selectedObjectIds.splice(objectIndex, 1);
    }
    setSelectedObjectIds([...selectedObjectIds]);
  };

  const columns = [
    {
      title: "Select",
      dataIndex: "id",
      key: "id",
      render: (id: number, record: DentalObject) => {
        return (
          <Checkbox
            checked={selectedObjectIds.includes(id)}
            onChange={handleCheckRecord(id)}
          >
            {record?.name}
          </Checkbox>
        );
      },
    },
    {
      title: "Object Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Object Type",
      dataIndex: "objectType",
      key: "objectType",
    },
    {
      title: "Manufacturer",
      dataIndex: "manufacturerName",
      key: "manufacturerName",
    },
    {
      title: "Color",
      dataIndex: "color",
      key: "color",
      render: (text: string, record: any, index: number) => {
        return (
          <span
            className="select-corporate-objects__color"
            style={{ backgroundColor: text }}
          />
        );
      },
    },
    {
      title: "Avg procurement cost",
      dataIndex: "avgProcurementCost",
      key: "avgProcurementCost",
      render: (text: string, record: any, index: number) => {
        return <div> {text || "-"}</div>;
      },
    },
  ];
  return (
    <div className="select-corporate-objects">
      <div className="select-corporate-objects__body">
        <Input placeholder="Search . . . " className="mb-5" />
        <h5 className="text-secondary">Filtered Objects</h5>
        <Table dataSource={dentalObjects} columns={columns} />
      </div>
      <div className="select-corporate-objects__footer">
        <Button
          type="primary"
          disabled={formLoading || selectedObjectIds.length === 0}
          onClick={handleSubmit}
          loading={formLoading}
        >
          Import
        </Button>
      </div>
    </div>
  );
}

export default BabylonContainer(SelectCorporateObjects);
