import React, { useEffect } from "react";
import DetailedOnboardingBasicDetails from "../DetailedOnboardingBasicDetails";
import DetailedOnboardingPaymentDetails from "../DetailedOnboardingPaymentDetails";
import DetailedOnboardingSuccess from "../DetailedOnboardingSuccess";
import { Button, Col, Row, Progress } from "antd";
import logo from "../../../assets/images/logoVerticalWhite.png";
import "./detailedOnboardingWrapper.scss";
import RegisterContainer from "../../../store/container/RegisterContainer";
import { LeftOutlined } from "@ant-design/icons";
import { RegisterReducerProps } from "../../../store/reducers/registerReducer";
import CorporateContainer from "../../../store/container/CorporateContainer";
import { CorporateReducerProps } from "../../../store/reducers/corporateReducer";
import { useHistory } from "react-router-dom";
import AppRoutes from "../../../routes/routeConstants/appRoutes";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { MetaService } from "../../../services/Meta/meta.service";

const stripePromise = loadStripe(
  "pk_test_51JbQV5H6CIb9bhcSTe3om1l5yq7Fnl40NfT4Ypu52ZdkjqUNXn0SjFAIOq156gVDzws5oF2khqlwL8CZ5oai1Qha00ObboxIfc"
);
interface DetailedOnboardingWrapperProps
  extends RegisterReducerProps,
    CorporateReducerProps {}

function DetailedOnboardingWrapper({
  detailedOnboardingActiveStep,
  setDetailedOnboardingActiveStep,
  currentCorporate,
}: DetailedOnboardingWrapperProps) {
  const history = useHistory();

  const onboardingComponents = [
    {
      title: "Corporate advanced details",
      component: <DetailedOnboardingBasicDetails />,
      progress: 33,
    },
    {
      title: "Subscription Payment",
      component: (
        <Elements stripe={stripePromise}>
          <DetailedOnboardingPaymentDetails />
        </Elements>
      ),
      progress: 67,
    },
    { title: "", component: <DetailedOnboardingSuccess />, progress: 100 },
  ];

  const handleBack = () =>
    setDetailedOnboardingActiveStep(detailedOnboardingActiveStep - 1);

  useEffect(() => {
    if (currentCorporate?.stepNumber) {
      if (currentCorporate.stepNumber > 5) {
        history.push(AppRoutes.DASHBOARD);
        return;
      }
      setDetailedOnboardingActiveStep(currentCorporate.stepNumber - 3);
    }
  }, [currentCorporate, history]);

  return (
    <div className="detailed-onboarding-wrapper">
      <div className="detailed-onboarding-wrapper__background-wrapper">
        <div className="detailed-onboarding-wrapper__background-content">
          <img
            src={logo}
            alt="Aligner 4D logo"
            className="detailed-onboarding-wrapper__logo"
          />
          <h3 className="detailed-onboarding-wrapper__background-header">
            Detailed Onboarding form
          </h3>
          <div className="detailed-onboarding-wrapper__progress-wrapper">
            <Row gutter={[10, 0]} align="middle">
              <Col span={6}>
                <div className="detailed-onboarding-wrapper__progress-text">
                  {onboardingComponents[detailedOnboardingActiveStep]?.progress}{" "}
                  %
                </div>
              </Col>
              <Col span={18}>
                <Progress
                  showInfo={false}
                  status="normal"
                  strokeColor="#FFFFFF"
                  trailColor="#198987"
                  percent={
                    onboardingComponents[detailedOnboardingActiveStep]?.progress
                  }
                />
              </Col>
            </Row>
          </div>
          <div className="detailed-onboarding-wrapper__stepper">
            <div>Step {detailedOnboardingActiveStep + 1} / 3</div>
            <div className="mt-5">
              {[...Array(3)].map((x, i) => (
                <span
                  key={i}
                  className={`detailed-onboarding-wrapper__step-item ${
                    i <= detailedOnboardingActiveStep ? "active" : ""
                  }`}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="detailed-onboarding-wrapper__content-wrapper">
        {detailedOnboardingActiveStep < onboardingComponents.length - 1 && (
          <div className="detailed-onboarding-wrapper__header">
            <h4 className="text-secondary">
              {detailedOnboardingActiveStep > 0 && (
                <Button
                  onClick={handleBack}
                  className="mr-3"
                  icon={<LeftOutlined />}
                />
              )}
              {onboardingComponents[detailedOnboardingActiveStep]?.title}
            </h4>
          </div>
        )}
        <div className="detailed-onboarding-wrapper__route-wrapper">
          {onboardingComponents[detailedOnboardingActiveStep]?.component}
        </div>
      </div>
    </div>
  );
}

export default CorporateContainer(RegisterContainer(DetailedOnboardingWrapper));
