import { CreateReducer } from "../../shared/utils/createReducer";
import { Corporate } from "../../models/Corporate/corporate.model";
import { SetCurrentCorporateAction } from "../actions/corporateActions";
import {
  SET_CORPORATE_ID,
  SET_CURRENT_CORPORATE,
  SET_FIRST_VISIT,
  SET_OPEN_CHECK_LIST,
  SET_PRACTICES,
  SET_PRACTICE_ID,
  SET_PRACTICE_LOADING,
} from "../definitions/corporateConstants";
import { Practice } from "../../models/Practice/practice.model";

interface ICorporateState {
  currentCorporate?: Corporate;
  openCheckList: boolean;
  firstVisit: boolean;
  practiceId?: number;
  corporateId?: number;
  practices: Practice[];
  practiceLoading?: boolean;
}

export interface CorporateReducerProps extends ICorporateState {
  setCurrentCorporate: (
    currentCorporate: Corporate
  ) => SetCurrentCorporateAction;
  setOpenCheckList: (openCheckList: boolean) => void;
  setFirstVisit: (visited: boolean) => void;
  setPracticeId: (practiceId: number) => void;
  setCorporateId: (corporateId?: number) => void;
  setPractices: (practices: Practice[]) => void;
  setPracticeLoading: (loading: boolean) => void;
}

interface Action {
  type: string;
  payload: ICorporateState;
}

const initState: ICorporateState = {
  practices: [],
  currentCorporate: undefined,
  openCheckList: false,
  firstVisit: true,
  practiceId: undefined,
  corporateId: undefined,
};

const corporateReducer = CreateReducer(initState, {
  [SET_CURRENT_CORPORATE](state: any, action: Action) {
    const { currentCorporate } = action.payload;
    return {
      ...state,
      currentCorporate,
    };
  },
  [SET_OPEN_CHECK_LIST](
    state: ICorporateState,
    action: Action
  ): ICorporateState {
    return {
      ...state,
      openCheckList: action?.payload?.openCheckList,
    };
  },
  [SET_FIRST_VISIT](state: ICorporateState, action: Action): ICorporateState {
    return {
      ...state,
      firstVisit: action?.payload?.firstVisit,
    };
  },
  [SET_PRACTICE_ID](state: ICorporateState, action: Action): ICorporateState {
    return {
      ...state,
      practiceId: action.payload?.practiceId,
    };
  },
  [SET_CORPORATE_ID](state: ICorporateState, action: Action): ICorporateState {
    return {
      ...state,
      corporateId: action.payload?.corporateId,
    };
  },
  [SET_PRACTICES]: (
    state: ICorporateState,
    action: Action
  ): ICorporateState => ({
    ...state,
    practices: action?.payload?.practices || [],
  }),
  [SET_PRACTICE_LOADING]: (
    state: ICorporateState,
    action: Action
  ): ICorporateState => ({
    ...state,
    practiceLoading: action?.payload?.practiceLoading,
  }),
});

export default corporateReducer;
