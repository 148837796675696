import { Checkbox, Col, Row } from "antd";
import React, { FC, Fragment } from "react";
import Stripe from "../../../assets/images/stripe.png";
import { ResponsibilityTypes } from "../../../enums/responsebily.enum";
import PaymentService from "../../../services/Payment/payment.service";
import useResponsibilities from "../../../shared/hooks/Responsibilities/useResponsibilities";
import CorporateContainer from "../../../store/container/CorporateContainer";
import { CorporateReducerProps } from "../../../store/reducers/corporateReducer";
import RestrictedPage from "../../RestrictedPage";
import "./paymentSettings.scss";

interface PaymentSettingsProps extends CorporateReducerProps {}

const PaymentSettings: FC<PaymentSettingsProps> = (props) => {
  const { currentCorporate } = props;

  const { hasAccess } = useResponsibilities();

  const HAS_SUBSCRIPTION_PAYMENT_CREATE_ACCESS = hasAccess(
    ResponsibilityTypes.SUBSCRIPTION_PAYMENT_CREATE
  );

  const createStripeAccount = () => {
    PaymentService.CreateStripeAccount(
      (url) => {
        window.location.replace(url);
      },
      () => {}
    );
  };

  return (
    <div className="payment-settings">
      {!currentCorporate?.stripeAccountId ? (
        HAS_SUBSCRIPTION_PAYMENT_CREATE_ACCESS ? (
          <Fragment>
            <h2>Link Payment Account</h2>
            <p>(Select one of the payment methods)</p>
          <Row className="link" gutter={[20, 10]} onClick={createStripeAccount}>
            <Col>
              <img src={Stripe} alt="" />
            </Col>
            <Col>
              <h4>Stripe</h4>
              <p>Faster & safer way to send money</p>
            </Col>
          </Row>
          <div className="terms">
            <Checkbox /> &nbsp; I accept the terms of service and privacy
            policy.
          </div>
        </Fragment>
        ): <RestrictedPage />
      ) : (
        <Fragment>
          <Row className="link mb-3" gutter={[20, 10]}>
            <Col>
              <img src={Stripe} alt="" />
            </Col>
            <Col>
              <h4>Stripe</h4>
              <p className="stripe-id">
                Stripe ID: {currentCorporate?.stripeAccountId}
              </p>
            </Col>
          </Row>

          <p className="complete-profile">
            Edit your profile by{" "}
            <span onClick={createStripeAccount}>Clicking here </span>
          </p>
        </Fragment>
      )}
    </div>
  );
};

export default CorporateContainer(PaymentSettings);
