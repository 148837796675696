import {
  serializable,
  alias,
  primitive,
} from "serializr";

export class UserProfile {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("name", primitive()))
  name?: string;
}
