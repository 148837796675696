import React, { FC, Fragment } from "react";
import { ErrorMessage, Field } from "formik";
import Error from "../Error";
import { Checkbox } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import "./checkbox.scss";

interface CheckboxProps {
  name: string;
  onChange?: (event: CheckboxChangeEvent) => void;
  setFieldValue: (field: string, value: any) => void;
  content: string;
  value: boolean;
}

const CheckboxField: FC<CheckboxProps> = ({
  name,
  setFieldValue,
  onChange,
  content,
  value,
}) => {
  return (
    <Field>
      {({ field, form: { touched, errors, setFieldTouched }, meta }: any) => (
        <div className="checkbox__container">
          <Checkbox
            name={name}
            checked={value}
            value={value}
            onChange={(event) => {
              setFieldValue(name, event.target.checked);
              onChange && onChange(event);
            }}
          />{" "}
          {"  "} <span className="checkbox-content">{content}</span>
          <ErrorMessage name={name}>
            {(message: string) => (
              <Error className={`${name}__error`} message={message} />
            )}
          </ErrorMessage>
        </div>
      )}
    </Field>
  );
};

export default CheckboxField;
