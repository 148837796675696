import React, { useEffect, useState } from "react";
import "./serviceForm.scss";
import { Service } from "../../../models/Service/service.model";
import { Form, Formik, FormikValues } from "formik";
import ServiceService from "../../../services/Service/service.service";
import { Button, Col, Row } from "antd";
import InputField from "../../../shared/components/InputField";
import { serviceFormValidation } from "./serviceFormValidation";
import DropdownField from "../../../shared/components/DropdownField";
import { getStatusOptions } from "../../../shared/utils/optionHelper";
import { IDropdownOptions } from "../../../services/Meta/meta.service";
import { ServiceCategoryService } from "../../../services/Category/serviceCategory.service";
import { ServiceCategory } from "../../../models/ServiceCategory/serviceCategory.model";
import { ServiceSubCategory } from "../../../models/ServiceSubCategory/serviceSubCategory.model";
import useAppointmentTypes from "../../../shared/hooks/AppointmentType/useAppointmentTypes";
import { TreatmentTemplateService } from "../../../services/TreatmentTemplate/treatmentTemplate.service";

interface ServiceFormProps {
  service: Service;
  onSuccess: (service: Service) => void;
  onCancel: () => void;
}

function ServiceForm({ service, onCancel, onSuccess }: ServiceFormProps) {
  const [formValues, setFormValues] = useState(new Service());

  const [formLoading, setFormLoading] = useState(false);

  const [categoryOptions, setCategoryOptions] = useState<IDropdownOptions[]>(
    []
  );

  const [treatmentTemplateOptions, setTreatmentTemplateOptions] = useState<
    IDropdownOptions[]
  >([]);

  const [subCategoryOptions, setSubCategoryOptions] = useState<
    IDropdownOptions[]
  >([]);

  const appointmentType = useAppointmentTypes("option");
  const handleSearchSubCategory = (label: string) => {
    const subCategory = subCategoryOptions.find(
      (subCategory) => subCategory.label === label
    );
    const filteredOptions = subCategoryOptions.filter(
      (option) => option.label !== option.value
    );
    if (!subCategory) {
      setSubCategoryOptions([...filteredOptions, { label, value: label }]);
    }
  };

  const handleSelectSubCategory = (
    values: FormikValues,
    value: string,
    setFieldValue: Function
  ) => {
    const subCategory: IDropdownOptions | undefined = subCategoryOptions.find(
      (subCategory) => subCategory.value === value
    );
    const subCategoryIndex: number = subCategoryOptions.findIndex(
      (subCategory) => subCategory.value === value
    );
    if (subCategory && subCategory.value !== subCategory.label) {
      setFieldValue("serviceSubCategoryId", value);
    } else {
      subCategoryOptions.splice(subCategoryIndex, 1);
      const serviceSubCategory = Object.assign(new ServiceSubCategory(), {
        name: value,
      });
      if (values.serviceCategoryId) {
        ServiceCategoryService.createServiceSubCategory(
          values.serviceCategoryId,
          serviceSubCategory,
          (serviceSubCategory: ServiceSubCategory) => {
            setFieldValue("serviceSubCategoryId", serviceSubCategory.id);
            setSubCategoryOptions([
              ...subCategoryOptions,
              {
                label: serviceSubCategory.name,
                value: serviceSubCategory.id,
              },
            ]);
          },
          () => {},
          () => {}
        );
      }
    }
  };

  const handleSubmit = (values: FormikValues) => {
    const service = Object.assign(new Service(), {
      ...values,
    });
    setFormLoading(true);
    if (values.id) {
      ServiceService.updateService(
        service,
        (service: Service) => {
          onSuccess(service);
        },
        () => {},
        () => {
          setFormLoading(false);
        }
      );
    } else {
      ServiceService.createService(
        service,
        (service: Service) => {
          onSuccess(service);
        },
        () => {},
        () => {
          setFormLoading(false);
        }
      );
    }
  };

  const handleFetchServiceSubCategories = (serviceCategoryId: number) => {
    ServiceCategoryService.fetchServiceSubCategories(
      serviceCategoryId,
      (serviceSubCategories: ServiceSubCategory[]) => {
        setSubCategoryOptions(
          serviceSubCategories.map(
            (serviceSubCategory: ServiceSubCategory) => ({
              label: serviceSubCategory.name,
              value: serviceSubCategory.id,
            })
          )
        );
      },
      () => {},
      () => {}
    );
  };

  useEffect(() => {
    ServiceCategoryService.fetchServiceCategories(
      (categories: ServiceCategory[]) => {
        setCategoryOptions(
          categories.map((category: ServiceCategory) => ({
            label: category.name,
            value: category.id,
          }))
        );
      },
      () => {},
      () => {}
    );

    TreatmentTemplateService.fetchTreatmentTemplate(
      {},
      (treatmentTemplates) => {
        setTreatmentTemplateOptions(
          treatmentTemplates?.map(({ id, name }) => ({
            label: name,
            value: id,
          }))
        );
      },
      () => {},
      () => {}
    );
  }, []);

  useEffect(() => {
    if (service) {
      setFormValues(service);
      if (service.serviceCategoryId)
        handleFetchServiceSubCategories(service.serviceCategoryId);
    }
  }, [service]);

  return (
    <div className="service-form">
      <Formik
        initialValues={formValues}
        onSubmit={handleSubmit}
        enableReinitialize
        validationSchema={serviceFormValidation}
      >
        {({ handleSubmit, values, errors, isValid, dirty, setFieldValue }) => {
          return (
            <Form>
              <Row gutter={[20, 0]}>
                <Col span={24}>
                  <InputField
                    title="Service name"
                    type="text"
                    name="name"
                    placeholder="Enter Service name"
                  />
                </Col>

                <Col span={24}>
                  <InputField
                    title="Sub-Category"
                    type="text"
                    name="subCategory"
                    placeholder="Enter Service name"
                    value={values?.subCategory}
                  />
                  {/* <DropdownField
                    title="Sub Category"
                    name="serviceSubCategoryId"
                    options={subCategoryOptions}
                    value={values.serviceSubCategoryId}
                    setFieldValue={setFieldValue}
                    placeHolder="Start typing to search sub category"
                    note="Type in and enter to create a new sub category"
                    showSearch
                    onSearch={(value) => {
                      setFieldValue("serviceSubCategoryId", value);
                      handleSearchSubCategory(value);
                    }}
                    onSelect={(value) => {
                      handleSelectSubCategory(values, value, setFieldValue);
                    }}
                  /> */}
                </Col>
                <Col span={24}>
                  <DropdownField
                    title="Default Treatment Execution Plan"
                    name="defaultTreatmentPlanTemplateId"
                    options={treatmentTemplateOptions}
                    value={values.defaultTreatmentPlanTemplateId}
                    setFieldValue={setFieldValue}
                    placeHolder="Select Execution Plan"
                  />
                </Col>
                <Col span={24}>
                  <DropdownField
                    title="Status"
                    name="status"
                    options={getStatusOptions()}
                    value={values.status}
                    setFieldValue={setFieldValue}
                    placeHolder="Select Gender"
                  />
                </Col>
                {/* <Col span={12}>
                  <InputField
                    title="Min Cost"
                    type="number"
                    name="minPrice"
                    placeholder="Enter Min Cost"
                  />
                </Col>
                <Col span={12}>
                  <InputField
                    title="Max Cost"
                    type="number"
                    name="maxPrice"
                    placeholder="Enter Max Cost"
                  />
                </Col>
                <Col span={12}>
                  <DropdownField
                    title="Default Appointment Type"
                    name="defaultAppointmentTypeId"
                    options={appointmentType}
                    value={values.defaultAppointmentTypeId}
                    setFieldValue={setFieldValue}
                    placeHolder="Select"
                  />
                </Col> */}
              </Row>
              <div className="user-form__submit-wrapper text-right">
                <Button onClick={onCancel} type="default">
                  Cancel
                </Button>
                <Button
                  type="primary"
                  className="ml-2"
                  htmlType="submit"
                  loading={formLoading}
                  disabled={!isValid || !dirty || formLoading}
                >
                  Save
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default ServiceForm;
