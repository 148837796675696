import React, { useState } from "react";
import "./detailedOnboardingPaymentDetails.scss";
import { Button, Col, Row } from "antd";
import { Form, Formik, FormikValues } from "formik";
import InputField from "../../../shared/components/InputField";
import DropdownField from "../../../shared/components/DropdownField";
import { UserCardDetails } from "../../../models/UserCardDetails/userCardDetails.model";
import DatePickerField from "../../../shared/components/DatePickerField";
import moment from "moment";
import { userCardFormValidation } from "./userCardFormValidation";
import RegisterContainer from "../../../store/container/RegisterContainer";
import { RegisterReducerProps } from "../../../store/reducers/registerReducer";
import { CheckOutlined } from "@ant-design/icons";
import { useEffect } from "react";
import { CorporateService } from "../../../services/Corporate/corporate.service";
import { Subscription } from "../../../models/Subscription/subscription.model";
import { Tenure } from "../../../enums/tenureType.enum";
import { SubscriptionFrequencie } from "../../../models/SubscriptionFrequencie/subscriptionFrequencie.model";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { User } from "../../../models/User/user.model";
import PaymentService from "../../../services/Payment/payment.service";
import AppRoutes from "../../../routes/routeConstants/appRoutes";
import { useHistory } from "react-router-dom";
import { Corporate } from "../../../models/Corporate/corporate.model";
import CorporateContainer from "../../../store/container/CorporateContainer";
import { CorporateReducerProps } from "../../../store/reducers/corporateReducer";
import UserService from "../../../services/User/user.service";
import { PaymentMethod } from "../../../models/PaymentMethod/paymentMethod.model";
import StripeCard from "../../../shared/components/StripeCard";
import { Stripe } from "@stripe/stripe-js";
import { CorporateSubscription } from "../../../models/CorporateSubscription/corporateSubscription.model";
import { ContractSubscription } from "../../../models/ContractSubscription/contractSubscription.model";
interface DetailedOnboardingPaymentDetailsProps
  extends RegisterReducerProps,
    CorporateReducerProps {}

function DetailedOnboardingPaymentDetails(
  props: DetailedOnboardingPaymentDetailsProps
) {
  const {
    detailedOnboardingActiveStep,
    setDetailedOnboardingActiveStep,
    activeCorporate,
    currentCorporate,
  } = props;

  const stripe = useStripe();

  const elements = useElements();

  const [formValues, setFormValues] = useState(new UserCardDetails());

  const [formLoading, setFormLoading] = useState(false);

  const [subscriptionType, setSubscriptionType] = useState(false);

  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);

  const [selectedCard, setSelectedCard] = useState<string>();

  // const [
  //   corporateSubscription,
  //   setCorporateSubscription,
  // ] = useState<CorporateSubscription>();

  const [cardTypes] = useState([
    { label: "Visa", value: "visa" },
    { label: "Master", value: "master" },
  ]);

  const history = useHistory();

  const [
    subscriptionFrequency,
    setSubscriptionFrequency,
  ] = useState<SubscriptionFrequencie>();

  useEffect(() => {
    const corporate = JSON.parse(localStorage.getItem("corporate") ?? "");

    if (corporate?.subscriptionFrequencyId) {
      CorporateService.subscriptionFrequencyId(
        corporate?.subscriptionFrequencyId,
        (subscriptionFrequency) => {
          setSubscriptionFrequency(subscriptionFrequency);
        },
        () => {},
        () => {}
      );
    }

    // CorporateService.getCorporateSubscription(
    //   (corporateSubscription) => {
    //     setCorporateSubscription(corporateSubscription);
    //   },
    //   () => {}
    // );

    UserService.getProfilePaymentMethods(
      (paymentMethods) => {
        setPaymentMethods(paymentMethods);
      },
      () => {},
      () => {}
    );
  }, []);

  const handleSuccess = () => {
    setDetailedOnboardingActiveStep(detailedOnboardingActiveStep + 1);
    setFormLoading(false);
    const corporate = Object.assign(new Corporate(), {
      ...currentCorporate,
      stepNumber: 6,
    });

    CorporateService.updateCorporateProfile(
      corporate,
      () => {
        setDetailedOnboardingActiveStep(detailedOnboardingActiveStep + 1);
      },
      () => {},
      () => {
        setFormLoading(false);
      }
    );
  };

  const initiatePayment = (pmId: string, stripe: Stripe, cardElement: any) => {
    const corporate = JSON.parse(localStorage.getItem("corporate") ?? "");

    const newCorporateSubscription = Object.assign(
      new CorporateSubscription(),
      {
        subscriptionFrequencyId: corporate?.subscriptionFrequencyId,
        stripePaymentMethodId: pmId,
      }
    );

    CorporateService.updateCorporateSubscription(
      newCorporateSubscription,
      () => {
        handleSuccess();
      },
      () => {
        setFormLoading(false);
      }
    );
    // const data = {
    //   payment_method: pmId ?? "",
    //   amount: subscriptionFrequency?.recurringAmount ?? 0,
    // };

    // PaymentService.createIndent(data, async (clientSecret, status) => {
    //   if (status === "requires_confirmation") {
    //     try {
    //       await stripe.confirmCardPayment(
    //         clientSecret ?? "",
    //         {
    //           // payment_method: {
    //           //   card: cardElement,
    //           // },
    //         },
    //         {
    //           handleActions: true,
    //         }
    //       );
    //       handleSuccess();
    //     } catch (error) {
    //       setFormLoading(false);
    //       return;
    //     }
    //   } else {
    //     handleSuccess();
    //   }
    // });
  };

  const onSubmitHandler = async () => {
    if (!stripe || !elements) {
      return;
    }

    setFormLoading(true);
    const cardElement: any = elements.getElement(CardElement);

    if (selectedCard) {
      initiatePayment(selectedCard, stripe, cardElement);
      return;
    }

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
      billing_details: {
        // name: values.nameOnCard ?? "",
        // email: values.email,
        // address: {
        //   line1: values.billingAddress,
        //   country: values.country?.name,
        //   postal_code: values.postalCode,
        // },
      },
    });

    if (error) {
      setFormLoading(false);
      return;
    }

    initiatePayment(paymentMethod?.id ?? "", stripe, cardElement);
  };

  return (
    <div className="detailed-onboarding-payment-details">
      <Row className={`register-payment-details-form__subscription-list`}>
        <Col
          span={24}
          className={`register-payment-details-form__subscription-item mb-5`}
        >
          <Row align="top" className="height-full">
            <Col span={12} className="register-payment-details-form__price">
              <div className="mb-2 text-black">
                {subscriptionFrequency?.subscription?.name}
              </div>
              <ul className="payment-detail">
                <li className="text-black">
                  <Button className="secondary-btn" icon={<CheckOutlined />} />
                  {subscriptionFrequency?.subscription?.maxNoOfPractices}{" "}
                  Practices
                </li>
                <li className="text-black">
                  <Button className="secondary-btn" icon={<CheckOutlined />} />
                  {subscriptionFrequency?.subscription?.maxNoOfStaffs} Staffs
                </li>
                <li className="text-black">
                  <Button className="secondary-btn" icon={<CheckOutlined />} />
                  {subscriptionFrequency?.subscription?.maxNoOfPatients}{" "}
                  Patients
                </li>
              </ul>
            </Col>
            <Col span={12} className="selected-subscription">
              <div className="detail">
                <p className="text-black">
                  ${subscriptionFrequency?.recurringAmount}/
                  {subscriptionFrequency?.subscriptionType}
                </p>
                <div className="saving text-black">
                  Save 20% by paying annually
                </div>
              </div>
            </Col>
            {/* <Col span={6} className="trial-period">
                {subscription?.trialPeriod && <div>Trial Period</div>}
              </Col> */}
          </Row>
        </Col>
      </Row>

      <div>
        <Formik
          initialValues={formValues}
          onSubmit={onSubmitHandler}
          enableReinitialize
          validationSchema={userCardFormValidation}
        >
          {({
            handleSubmit,
            handleChange,
            values,
            errors,
            isValid,
            dirty,
            setFieldValue,
          }) => {
            return (
              <Form>
                <div className="detailed-onboarding-payment-details__payment-form mb-5">
                  <h5 className="text-secondary">Payment</h5>
                  <Row gutter={[20, 0]} className="mt-5 mb-5">
                    {paymentMethods?.map(({ id, card }) => (
                      <Col span={12}>
                        <StripeCard
                          id={id}
                          key={id}
                          card={card}
                          active={id === selectedCard}
                          onSelect={(id) => {
                            if (id === selectedCard) {
                              setSelectedCard(undefined);
                            } else {
                              setSelectedCard(id);
                            }
                          }}
                        />
                      </Col>
                    ))}
                  </Row>
                  <CardElement
                    className="mt-5"
                    options={{
                      hidePostalCode: true,
                      style: {
                        base: {
                          fontSize: "16px",
                          color: "#424770",
                          "::placeholder": {
                            color: "#aab7c4",
                          },
                        },
                        invalid: {
                          color: "#9e2146",
                        },
                      },
                    }}
                  />
                </div>
                <div className="detailed-onboarding-payment-details__submit-wrapper text-right">
                  <Button type="default">Cancel</Button>
                  <Button
                    type="primary"
                    className="ml-2"
                    htmlType="submit"
                    loading={formLoading}
                    onClick={onSubmitHandler}
                    // disabled={!isValid || !dirty || formLoading}
                  >
                    Next
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}

export default RegisterContainer(
  CorporateContainer(DetailedOnboardingPaymentDetails)
);
