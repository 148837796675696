import { Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import React from "react";
import {
  SelfPayment,
  SelfPaymentSchedules,
} from "../../../../../../models/PaymentSchedule/paymentSchedule.model";
import { Field } from "../InsuranceDetail";

import "./selfPaymentDetail.scss";

interface SelfPaymentDetailProps {
  payment?: SelfPayment;
}

const SelfPaymentDetail = ({ payment }: SelfPaymentDetailProps) => {
  if (!payment) return <></>;

  const { amount, noOfDues, dueTime } = payment;

  const columns: ColumnsType<SelfPaymentSchedules> = [
    {
      title: "Due",
      dataIndex: "dueNo",
    },
    {
      title: "Date",
      dataIndex: "dueDate",
      render: (date) => moment.utc(date).format("Do MMM YYYY"),
    },
    {
      title: "Amount",
      dataIndex: "amount",
    },
  ];

  const fields: Field[] = [
    {
      label: "self payment amount",
      value: amount,
    },
    {
      label: "no of dues",
      value: noOfDues,
    },

    {
      label: "due time",
      value: dueTime,
    },
  ];

  return (
    <div className="self-payment-detail">
      <div className="self-payment-detail__left">
        <div className="mb-5 text-capitalize text-bold">self payment</div>
        <div className="self-payment-detail__left__fields">
          {fields?.map(({ label, value }) => (
            <div className="self-payment-details__left__fields__item">
              <div className="text-capitalize self-payment-detail__left__fields__item__label">
                {label}
              </div>
              <div className="self-payment-detail__left__fields__item__value">
                {value || "-"}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="self-payment-detail__right">
        <Table
          bordered={false}
          dataSource={payment?.selfPaymentSchedules}
          columns={columns}
          className="secondary-table"
          pagination={false}
        />
      </div>
    </div>
  );
};

export default SelfPaymentDetail;
