import { useEffect, useState } from "react";
import { PaginationDetails } from "../../../models/Pagination/pagination.model";
import { Procedure } from "../../../models/Procedure/procedure.model";
import ProcedureService from "../../../services/Procedure/procedure.service";
import { getDropdownOptions } from "../../utils/getDropdownOptions";
import { FilterParams } from "models/FilterParams/filterParams.model";
import { serialize } from "serializr";

interface useProceduresProps extends FilterParams {}

const useProcedures = (filters: useProceduresProps = {}) => {
  const [procedures, setProcedures] = useState<Procedure[]>([]);

  const [proceduresMeta, setProceduresMeta] = useState(new PaginationDetails());

  const [loading, setLoading] = useState(false);

  const options = getDropdownOptions(procedures, "name", "id");

  const fetchProcedures = () => {
    if (!proceduresMeta?.nextPage) return;

    setLoading(true);
    ProcedureService.fetchProcedures(
      (procedures, meta) => {
        setProcedures((existingProcedures) => [
          ...existingProcedures,
          ...procedures,
        ]);
        setProceduresMeta(meta);
      },
      () => {},
      () => {
        setLoading(false);
      },
      { ...filters, page: proceduresMeta?.nextPage }
    );
  };

  useEffect(() => {
    fetchProcedures();
  }, []);

  return { procedures, options, fetchProcedures, loading };
};

export default useProcedures;
