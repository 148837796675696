import ApiRoutes from "../../routes/routeConstants/apiRoutes";
import axiosInstance from "../../interceptor/axiosInstance";
import { Resource, Resources } from "../../models/resource/resource.model";
import { deserialize, serialize } from "serializr";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import { generatePath } from "react-router";
import { FilterParams } from "../../models/FilterParams/filterParams.model";
import { PaginationDetails } from "../../models/Pagination/pagination.model";

export default class ResourceService {
  static fetchResources(
    onSuccess: (resources: Resource[], pagination: PaginationDetails) => void,
    onError: Function,
    onFinal: () => void,
    filter?: FilterParams
  ) {
    const params = serialize(FilterParams, filter ?? new FilterParams());

    axiosInstance
      .get(ApiRoutes.RESOURCES, { params })
      .then((response) => {
        const { resources } = deserialize(Resources, response.data);
        const pagination = deserialize(
          PaginationDetails,
          response.data["meta"]
        );
        onSuccess(resources, pagination);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static fetchResourceByType(
    type: string,
    onSuccess: (resources: Resource[]) => void,
    onError: Function,
    onFinal: () => void
  ) {
    const corporateId = localStorage.getItem("corporateId") ?? 1;
    const practiceId = localStorage.getItem("practiceId") ?? 1;
    const option = {
      params: {
        resource_type_name: type,
      },
    };
    axiosInstance
      .get(
        generatePath(ApiRoutes.RESOURCES, {
          practiceId,
          corporateId,
        }),
        option
      )
      .then((response) => {
        const { resources } = deserialize(Resources, response.data);
        onSuccess(resources);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static showResource(
    resourceId: string,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.RESOURCES + "/" + resourceId;
    axiosInstance
      .get(API_URL)
      .then((response) => {
        const resource = deserialize(Resource, response.data["resource"]);
        onSuccess(resource);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static createResource(
    resource: Resource,
    onSuccess: (resource: Resource) => void,
    onError: Function,
    onFinal: () => void
  ) {
    const params = serialize(Resource, resource);

    axiosInstance
      .post(ApiRoutes.RESOURCES, { resource: { ...params } })
      .then((response) => {
        const resource = deserialize(Resource, response.data["resource"]);
        onSuccess(resource);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static updateResource(
    resource: Resource,
    onSuccess: (resource: Resource) => void,
    onError: Function,
    onFinal: () => void
  ) {
    const params = serialize(Resource, resource);
    axiosInstance
      .put(ApiRoutes.RESOURCES + "/" + resource.id, {
        resource: { ...params, resource },
      })
      .then((response) => {
        const resource = deserialize(Resource, response.data["resource"]);
        Notification({
          message: `Resource Updated`,
          type: NotificationTypes.SUCCESS,
        });
        onSuccess(resource);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static deleteResource(
    resource: Resource,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .delete(ApiRoutes.RESOURCES + "/" + resource.id)
      .then(() => {
        onSuccess();
        Notification({
          message: `Resource ${resource.name?.toLocaleLowerCase()} deleted successfully`,
          type: NotificationTypes.SUCCESS,
        });
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }
}
