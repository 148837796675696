import { deserialize, serialize } from "serializr";
import axiosInstance from "../../interceptor/axiosInstance";
import { CommentNote } from "../../models/CommentNote/commentNote.model";
import ApiRoutes from "../../routes/routeConstants/apiRoutes";

class CommentNotesService {
  static fetchCommentNotes(
    interactionId: number,
    onSuccess: (statementTemplates: CommentNote[]) => void,
    onError: () => void,
    onFinal: () => void
  ) {
    const URL = ApiRoutes.GET_COMMENT_NOTES.replace(
      ":interactionId",
      interactionId.toString()
    );
    return axiosInstance
      .get(URL)
      .then((response) => {
        const commentNotes = deserialize(
          CommentNote,
          response.data["comments"] as any[]
        );
        onSuccess(commentNotes);
      })
      .catch(() => {
        onError();
      })
      .finally(onFinal);
  }

  static createCommentNotes(
    interactionId: number,
    comment: CommentNote,
    onSuccess: (commentNote: CommentNote) => void,
    onError: () => void,
    onFinal: () => void
  ) {
    const URL = ApiRoutes.GET_COMMENT_NOTES.replace(
      ":interactionId",
      interactionId.toString()
    );
    const requestJSON = {
      comment: serialize(comment),
    };
    return axiosInstance
      .post(URL, requestJSON)
      .then((response) => {
        const commentNote = deserialize(CommentNote, response.data["comment"]);
        onSuccess(commentNote);
      })
      .catch(() => {
        onError();
      })
      .finally(onFinal);
  }

  static deleteCommentNotes(
    id: number,
    interactionId: number,
    onSuccess: () => void,
    onError: () => void,
    onFinal: () => void
  ) {
    const URL =
      ApiRoutes.GET_COMMENT_NOTES.replace(
        ":interactionId",
        interactionId.toString()
      ) + `/${id}`;
    return axiosInstance
      .delete(URL)
      .then(() => {
        onSuccess();
      })
      .catch(() => {
        onError();
      })
      .finally(onFinal);
  }
}

export default CommentNotesService;
