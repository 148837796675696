import moment from "moment";
import { serializable, alias, object, list, primitive } from "serializr";
import { AppointmentTemplateResource } from "../AppointmentTemplateResource/appointmentTemplateResource.model";
export class AppointmentTemplate {
  @serializable(alias("name", primitive()))
  templateName?: string;

  @serializable(alias("color_code", primitive()))
  colorCode?: string;

  @serializable(alias("appointment_type_id", primitive()))
  appointmentTypeId?: number;
  @serializable(alias("resource_id", primitive()))
  resourceId?: number;

  @serializable(alias("created_at", primitive()))
  createdAt?: string;

  @serializable(alias("updated_at", primitive()))
  updatedAt?: string;

  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("start_time", primitive()))
  startTime?: string;

  @serializable(alias("end_time", primitive()))
  endTime?: string;

  @serializable(alias("resources", list(object(AppointmentTemplateResource))))
  resources: AppointmentTemplateResource[] = [];

  getStartDate = () =>
    this.startTime ? moment(this.startTime).toDate() : moment().toDate();

  getEndDate = () =>
    this.endTime ? moment(this.endTime).toDate() : moment().toDate();
}
