import { Spin } from "antd";
import React, { useState } from "react";
import "./documentViewer.scss"

interface DocumentViewerProps {
  url: string;
  type: string;
}

const DocumentViewer: React.FC<DocumentViewerProps> = ({ url, type }) => {
 
  const [isLoading, setIsLoading] = useState(true);

  const handleLoad = () => {
    setIsLoading(false);
  };

  if (type === "pdf") {
    return (
      <div className="pdf-container">
        {isLoading && <Spin />}
        <iframe
          className="pdf-container__iframe"
          src={url}
          title="pdf-viewer"
          onLoad={handleLoad}
        
        />
      </div>
    );
  } else if (type==="image") {
    return (
      <div className="image-container">
        {isLoading && <Spin/> }
        <img src={url} alt="document" onLoad={handleLoad} />
      </div>
    );
  } else {
    return <div>Unsupported document type</div>;
  }
};

export default DocumentViewer;
