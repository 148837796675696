import { min } from "lodash";
import * as Yup from "yup";
import moment from "moment";
import helpers from "../../../shared/utils/helpers";
import { getDateOptions } from "../../../shared/utils/dateHelpers";

const DATE_FORMAT = "hh:mm A";

export const practiceFormValidation = Yup.object().shape({
  // schedule: Yup.array().of(
  //   Yup.object().shape({
  //     startTime: Yup.string().when("isOpen", {
  //       is: true,
  //       then: Yup.string()
  //         .required("Start time is required")
  //         .test(
  //           "startTime",
  //           "Start time cannot be after end time",
  //           function (value) {
  //             if (!this.parent?.endTime) {
  //               return true;
  //             }
  //             const startTime = moment(`${value}`, DATE_FORMAT);
  //             const endTime = moment(`${this.parent?.endTime}`, DATE_FORMAT);
  //             return startTime.isBefore(endTime);
  //           }
  //         ),
  //     }),

  //     endTime: Yup.string().when("isOpen", {
  //       is: true,
  //       then: Yup.string().required("End time is required"),
  //       /* .test(
  //           "endTime",
  //           "End time cannot be before start time",
  //           function (value) {
  //             if (!this.parent?.startTime) {
  //               return true;
  //             }
  //             const startTime = moment(
  //               `${this.parent?.startTime}`,
  //               DATE_FORMAT
  //             );
  //             const endTime = moment(`${value}`, DATE_FORMAT);
  //             return startTime.isBefore(endTime);
  //           }
  //         ), */
  //     }),
  //   })
  // ),

  name: Yup.string().required("Name is required!"),
  contactPerson: Yup.string().required("Contact person is required!"),
  email: Yup.string()
    .required("Email is required!")
    .email("Email is not valid!"),
  isdCode: Yup.string().required("Country code is required!"),
  mobileNumber: Yup.string()
    .required("Mobile number is required!")
    .min(10, "Mobile number should have 10 digits!")
    .max(10, "Mobile number should have 10 digits!"),
  timeZone: Yup.string().required("Time zone is required!"),
  chairCount: Yup.string().required("Please specify no of chairs!"),
  labCount: Yup.string().required("Please specify no of labs!"),
  roomCount: Yup.string().required("Please specify no of rooms!"),
});
