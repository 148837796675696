import * as Yup from  "yup";

export const corporateAdvancedDetailsFormValidation = Yup.object().shape({
    toothNumberingSystem: Yup.string()
        .required('Numbering system is required!'),
    openRecordsLocked: Yup.string()
        .required('Please select one record'),
    sterilizationCode: Yup.string()
        .required('Please select one sterilization code!'),
    noteSorting: Yup.boolean()
        .required('Please specify one option'),
    showMedicalAlerts: Yup.boolean()
        .required('Please specify one option'),
    elasticCompliance: Yup.boolean()
        .required('Please specify one option'),
    oralHygiene: Yup.boolean()
        .required('Please specify one option'),
    applianceRatings: Yup.boolean()
        .required('Please specify one option'),
    reqPassToChangeAssistant: Yup.boolean()
        .required('Please specify one option')
});
