import {
  AbstractMesh,
  ActionManager,
  Color3,
  ExecuteCodeAction,
  Mesh,
  MeshBuilder,
  PhysicsImpostor,
  Scene,
  SceneLoader,
  Vector3,
  StandardMaterial,
  Texture,
  HighlightLayer,
  Nullable,
} from "@babylonjs/core";
import { SetStep } from "../../models/SetStep/setStep.model";
import { ProcedureStep } from "../../models/ProcedureStep/procedureStep.model";
import { InteractionStep } from "../../models/InteractionStep/interactionStep.model";
import { CorporateObject } from "../../models/CorporateObject/corporateObject.model";
import { ToothPosition } from "../../models/ToothPosition/toothPosition.model";
import {
  Connector,
  ConnectorSteps,
  TreatmentPlanTemplateSteps,
} from "../../models/TreatmentPlanTemplateSteps/treatmentPlanTemplateSteps.model";
import Elastic from "../babylon/elastic";
import { message } from "antd";
import { CONFLICT_TYPES } from "../../enums/conflictType.enum";
import SetService from "../../services/Set/set.service";
import { DentalSet } from "../../models/DentalSet/dentalSet.model";
import ProcedureService from "../../services/Procedure/procedure.service";
import { Procedure } from "../../models/Procedure/procedure.model";
import {
  MeshTriggerHandler,
  MeshTriggerHandlerArgs,
} from "../Types/meshActions.type";

type Step =
  | SetStep
  | ProcedureStep
  | InteractionStep
  | TreatmentPlanTemplateSteps;

export const handleImportVendorObject = (
  step: Step,
  scene: any,
  highlightLayer?: any,
  corporateObjects?: CorporateObject[],
  setSourceObject?: Function,
  setShowPopover?: Function,
  setPopoverPosition?: Function,
  isMeshPickable = true,
  objectFile?: File
) =>
  new Promise(async (resolve) => {
    // if (
    //   step?.objectLink?.destAttachmentUrl &&
    //   step?.objectLink?.srcAttachmentUrl
    // ) {
      const meshURL = objectFile
        ? `data:${JSON.stringify(objectFile)}`
        : "" + step?.objectLink?.destAttachmentUrl;
      SceneLoader.ImportMesh(
        "",
        // reverse
        //   ? step?.objectLink?.srcAttachmentUrl
        // :
        // step?.objectLink?.destAttachmentUrl,
        meshURL,
        "",
        scene,
        async function (meshes, particleSystems, skeletons) {
          meshes.forEach((mesh) => {
            if (mesh.getChildren().length > 0) {
              mesh.actionManager = new ActionManager(scene);
              // const material = new StandardMaterial("myMaterial", scene);
              // material.diffuseTexture = new Texture("/texture.png", scene);
              // mesh.material = material;
              mesh.visibility = 0.15;
              const action1 = new ExecuteCodeAction(
                ActionManager.OnRightPickTrigger,
                (evt) => {
                  evt.sourceEvent?.preventDefault();
                  addHighlightLayerAndSourceObject(
                    mesh,
                    highlightLayer,
                    corporateObjects,
                    setSourceObject
                  );
                  setShowPopover?.(true);
                  setPopoverPosition?.({
                    x: evt.pointerX,
                    y: evt.pointerY,
                  });
                }
              );
              const action2 = new ExecuteCodeAction(
                ActionManager.OnLeftPickTrigger,
                () =>
                  addHighlightLayerAndSourceObject(
                    mesh,
                    highlightLayer,
                    corporateObjects,
                    setSourceObject
                  )
              );
              if (mesh && mesh?.actionManager?.registerAction) {
                // @ts-ignore
                mesh.actionManager?.registerAction(action1);
                mesh.actionManager?.registerAction(action2);
                mesh.isPickable = isMeshPickable;

                mesh.state = step.objectLink?.destLocatorName ?? "";
                mesh.metadata = {
                  corporateLinkId: step.corporateLinkId,
                  isVendorObject: true
                };
                mesh.id = step.id?.toString() ?? "0";
                if (mesh?.name === step?.objectLink?.srcObjectName) {
                  const {
                    srcObjectName,
                    srcObjectId,
                    srcLocatorId,
                    srcLocatorName,
                    destLocatorId,
                    destObjectName,
                    destObjectId,
                    destLocatorName,
                  } = step.objectLink;

                  step.objectLink.srcObjectId = destObjectId;
                  step.objectLink.srcObjectName = destObjectName;
                  step.objectLink.srcLocatorName = destLocatorName;
                  step.objectLink.srcLocatorId = destLocatorId;

                  step.objectLink.destObjectId = srcObjectId;
                  step.objectLink.destObjectName = srcObjectName;
                  step.objectLink.destLocatorName = srcLocatorName;
                  step.objectLink.destLocatorId = srcLocatorId;
                }
              }
            }
          });

          if (step.objectLink) await handleAddLink(scene, step);
          resolve(null);
        }
      );
    // }
  });

export const cleanAllVendorMeshes = (scene: Scene) =>
  scene?.meshes
    ?.slice()
    ?.forEach((mesh) => mesh?.metadata?.isVendorObject && mesh?.dispose());

export const cleanAllMeshes = (scene: Scene) =>
  scene?.meshes?.slice()?.forEach((mesh) => mesh.dispose());

export const addHighlightLayer = (
  mesh: AbstractMesh,
  highlightLayer: Nullable<HighlightLayer>
) => {
  highlightLayer?.removeAllMeshes();
  if (mesh instanceof Mesh) highlightLayer?.addMesh(mesh, Color3.White());
};
export const addSourceObject = (
  mesh: AbstractMesh,
  corporateObjects?: CorporateObject[],
  setSourceObject?: Function
) => {
  const sourceObject = corporateObjects?.find(
    ({ name }) => name === mesh?.name
  );
  if (sourceObject)
    setSourceObject?.({
      ...sourceObject,
      corporateLinkId: mesh.metadata?.corporateLinkId
    });
};

export const addHighlightLayerAndSourceObject = (
  mesh: AbstractMesh,
  highlightLayer: Nullable<HighlightLayer>,
  corporateObjects?: CorporateObject[],
  setSourceObject?: Function
) => {
  addHighlightLayer(mesh, highlightLayer);
  addSourceObject?.(mesh, corporateObjects, setSourceObject);
};

export const handleTeethLeftClick = (
  { mesh, highlightLayer }: MeshTriggerHandlerArgs,
  corporateObjects?: CorporateObject[],
  setSourceObject?: Function
) =>
  addHighlightLayerAndSourceObject(
    mesh,
    highlightLayer,
    corporateObjects,
    setSourceObject
  );

export const handleTeethRightClick = (
  props: MeshTriggerHandlerArgs,
  corporateObjects?: CorporateObject[],
  setSourceObject?: Function,
  setShowPopover?: Function,
  setPopoverPosition?: Function
) => {
  /* 
      Currently Teeth have only one child mesh by default(i.e., locator).
      If it has more than one child, it would be the vendor object(cap).
  */
  const isVendorObjectExist = props?.mesh?.getChildMeshes()?.length > 1;
  if (isVendorObjectExist) return setShowPopover?.(false);

  props?.evt?.sourceEvent?.preventDefault();
  addHighlightLayerAndSourceObject(
    props?.mesh,
    props?.highlightLayer,
    corporateObjects,
    setSourceObject
  );
  setShowPopover?.(true);
  setPopoverPosition?.({
    x: props?.evt.pointerX,
    y: props?.evt.pointerY,
  });
};

export const handleRemoveLink = (step: Step, scene: any) =>
  new Promise((resolve) => {
    const mesh = scene.getMeshByName(step.objectLink?.destObjectName);
    mesh?.dispose();
    resolve(null);
  });

export const handleAddLink = (scene: any, step: Step) =>
  new Promise((resolve) => {
    if (step.objectLink) {
      const {
        srcObjectName,
        srcLocatorName,
        destObjectName,
        destLocatorName,
      } = step.objectLink;

      if (
        srcObjectName &&
        srcLocatorName &&
        destObjectName &&
        destLocatorName
      ) {
        const sourceObject = scene.getMeshByName(srcObjectName);
        const sourceLocator = scene.getMeshByName(srcLocatorName);
        let destinationObject, destinationLocator;
        if (destObjectName === "Button") {
          destinationObject = scene.getMeshByID(step.id?.toString());
          destinationLocator = scene.getMeshByName(destinationObject.state);
          destinationObject.physicsImpostor = new PhysicsImpostor(
            destinationObject,
            PhysicsImpostor.BoxImpostor,
            { mass: 0, friction: 0.5, restitution: 0 },
            scene
          );
        } else {
          destinationObject = scene.getMeshByName(destObjectName);
          destinationLocator = scene.getMeshByName(destLocatorName);
        }

        if (
          sourceObject &&
          sourceLocator &&
          destinationObject &&
          destinationLocator
        ) {
          // destinationObject.parent = sourceLocator;
          // Make position fixed
          // sourceLocator.computeWorldMatrix(true);
          // destinationObject.scaling = Vector3.One().divide(
          //   sourceLocator.absoluteScaling
          // );
          destinationObject.setParent(sourceLocator);
          destinationObject.position = new Vector3(
            -destinationLocator.position.x,
            -destinationLocator.position.y,
            -destinationLocator.position.z
          );
        }
      }
      resolve(null);
    }
  });

export const getToothPositionId = (
  scene: Scene,
  sourceObjectName: string,
  toothPositions: ToothPosition[]
): any => {
  if (sourceObjectName) {
    const mesh = scene.getMeshByName(sourceObjectName);
    if (mesh) {
      if (mesh?.parent) {
        return getToothPositionId(scene, mesh?.parent?.name, toothPositions);
      } else {
        const toothPosition = toothPositions.find(
          (toothPosition) => toothPosition.defaultObjectName === mesh.name
        );
        return toothPosition?.id || 0;
      }
    }
  }
};

const findMeshes = (connectorSteps: ConnectorSteps[], scene: Scene) =>
  new Promise((resolve) => {
    const buttons = [];
    for (let step of connectorSteps) {
      // find tooth
      const tooth = scene.getMeshByName(step.toothPositionName ?? "");
      // find the child that is linked with button
      if (tooth && tooth.getChildren().length > 0) {
        for (let toothLink of tooth.getChildren()) {
          for (let vendorObject of toothLink.getChildren()) {
            if (vendorObject.name === "Button") {
              buttons.push({
                button: vendorObject as AbstractMesh,
                parentName: tooth.name,
                vendorObject,
              });
            }
          }
        }
      }
    }
    resolve(buttons);
  });

const findDestinationLocators = (
  buttons: {
    parentName: string;
    button: AbstractMesh;
    vendorObject: AbstractMesh;
  }[]
) =>
  new Promise((resolve) => {
    const locators: any = [];
    const vendorObjects: any = [];
    let name = "";
    for (let { button, parentName, vendorObject } of buttons) {
      for (let vendorObjectLink of button.getChildren()) {
        if (vendorObjectLink.name === "Button_Elastic_Locator") {
          name += `${parentName}-`;
          locators.push((vendorObjectLink as AbstractMesh).absolutePosition);
          vendorObjects.push(vendorObject);
        }
      }
    }
    setTimeout(() => {
      resolve({ locators, name, vendorObjects });
    }, 1000);
  });
export const handleAddElastic = async (connector: Connector, scene: Scene) => {
  const { connectorSteps } = connector;
  const buttons: any = await findMeshes(connectorSteps, scene);
  const { locators, name }: any = await findDestinationLocators(buttons);
  Elastic.drawElastic(locators, name, scene);
};

export const handleUpdateElastic = async (
  connector: Connector,
  scene: Scene
) => {
  const { connectorSteps } = connector;
  const buttons: any = await findMeshes(connectorSteps, scene);
  const { vendorObjects, name }: any = await findDestinationLocators(buttons);
  Elastic.updateElastic(vendorObjects, name, scene);
};

export const checkConflict = (
  mesh: AbstractMesh,
  srcObjectName: string,
  srcLocatorName: string,
  destObjectName: string
) =>
  new Promise((resolve, reject) => {
    let skip = false,
      hardApply = false;
    try {
      for (let childMesh of mesh.getChildren()) {
        if (mesh.name === srcObjectName && childMesh.getChildren().length) {
          for (let vendorObjMesh of childMesh.getChildren()) {
            if (destObjectName === vendorObjMesh.name) {
              message.error({
                style: {
                  marginTop: "85vh",
                  width: "70vw",
                },
                duration: 3,
                content: `There is a another vendor in the ${childMesh?.name}`,
              });
              skip = true;
            }
            hardApply = true;
          }
        }
      }
      if (skip) {
        resolve(CONFLICT_TYPES.SKIP);
        return;
      }
      if (hardApply) {
        resolve(CONFLICT_TYPES.HARD_APPLY);
        return;
      }
      resolve(null);
    } catch (error) {
      reject(error);
    }
  });

export const checkConflictSet = (setId: number, scene: Scene) =>
  new Promise((resolve, reject) => {
    try {
      let conflictType: any = null;
      SetService.showSet(
        setId,
        async (set: DentalSet) => {
          for (let { objectLink } of set.setSteps) {
            const mesh = scene?.getMeshByName(objectLink?.srcObjectName ?? "");
            if (
              mesh &&
              objectLink?.srcObjectName &&
              objectLink?.srcLocatorName &&
              objectLink?.destObjectName
            ) {
              const conflict = await checkConflict(
                mesh as AbstractMesh,
                objectLink?.srcObjectName,
                objectLink?.srcLocatorName,
                objectLink?.destObjectName
              );
              if (conflict && conflictType !== CONFLICT_TYPES.SKIP) {
                conflictType = conflict;
              }
            }
          }
          resolve(conflictType);
        },
        () => {},
        () => {}
      );
    } catch (error) {
      reject(error);
    }
  });

export const checkConflictProcedure = (procedureId: number, scene: Scene) =>
  new Promise((resolve, reject) => {
    try {
      let conflictType: any = null;
      ProcedureService.showProcedure(
        procedureId,
        async (procedure: Procedure) => {
          for (let { objectLink, setId } of procedure?.procedureSteps) {
            if (setId) {
              if (conflictType !== CONFLICT_TYPES.SKIP) {
                conflictType = await checkConflictSet(setId, scene);
              }
            } else {
              const mesh = scene?.getMeshByName(
                objectLink?.srcObjectName ?? ""
              );
              if (
                mesh &&
                objectLink?.srcObjectName &&
                objectLink?.srcLocatorName &&
                objectLink?.destObjectName
              ) {
                const conflict = await checkConflict(
                  mesh as AbstractMesh,
                  objectLink?.srcObjectName,
                  objectLink?.srcLocatorName,
                  objectLink?.destObjectName
                );
                if (conflict && conflictType !== CONFLICT_TYPES.SKIP) {
                  conflictType = conflict;
                }
              }
            }
          }
          resolve(conflictType);
        },
        () => {},
        () => {}
      );
    } catch (error) {
      reject(error);
    }
  });

// export const isReverse = (abstractMeshes: AbstractMesh[], step: Step) => {
//   const result = abstractMeshes?.find(
//     ({ name }) => name === step.objectLink?.destObjectName
//   );
//   if (result) return true;
//   return false;
// };
