import {
  SET_ACTIVE_INTERACTION_ID,
  SET_CONTRACT_INTERACTIONS,
  SET_EXPANDED_INTERACTION,
  SET_OBJECT_NOTES,
} from "../definitions/interactionConstants";
import { Interaction } from "../../models/Interaction/interaction.model";
import { ObjectNote } from "../../models/ObjectNote/objectNote.model";

export interface SetContractInteractionsAction {
  type: typeof SET_CONTRACT_INTERACTIONS;
  payload: { contractInteractions: Interaction[] };
}

export interface SetActiveInteractionIdAction {
  type: typeof SET_ACTIVE_INTERACTION_ID;
  payload: { activeInteractionId?: number };
}


export interface SetExpandedInteractionIdAction {
  type: typeof SET_EXPANDED_INTERACTION;
  payload: { expandedInteractionId?: number };
}


export interface SetObjectNotesAction {
  type: typeof SET_OBJECT_NOTES;
  payload: { objectNotes: ObjectNote[] };
}

export const setContractInteractions = (
  contractInteractions: Interaction[]
): SetContractInteractionsAction => {
  return {
    type: SET_CONTRACT_INTERACTIONS,
    payload: {
      contractInteractions,
    },
  };
};

export const setActiveInteractionId = (
  activeInteractionId?: number
): SetActiveInteractionIdAction => {
  return {
    type: SET_ACTIVE_INTERACTION_ID,
    payload: {
      activeInteractionId,
    },
  };
};

export const setObjectNotes = (
  objectNotes: ObjectNote[]
): SetObjectNotesAction => {
  return {
    type: SET_OBJECT_NOTES,
    payload: {
      objectNotes,
    },
  };
};


export const setExpandedInteractionId = (
  expandedInteractionId: number
): SetExpandedInteractionIdAction => ({
  type: SET_EXPANDED_INTERACTION,
  payload: {
    expandedInteractionId,
  },
});