import React, { useEffect, useState } from "react";
import { Button, Col, Drawer, Modal, Row, Switch, Table } from "antd";
import { CheckOutlined, CloseOutlined, PlusOutlined } from "@ant-design/icons";
import SetService from "../../../../../services/Set/set.service";
import { StatusEnum } from "../../../../../enums/status.enum";
import AppLoader from "../../../../../shared/components/AppLoader";
import { generatePath, useHistory } from "react-router-dom";
import AppRoutes from "../../../../../routes/routeConstants/appRoutes";
import { DentalSet } from "../../../../../models/DentalSet/dentalSet.model";
import "./setList.scss";
import SetForm from "../SetForm";
import Stats from "../../../../../shared/components/Stats";
import AuthContainer from "../../../../../store/container/AuthContainer";
import { AuthReducerProps } from "../../../../../store/reducers/authReducer";
import { ResponsibilityTypes } from "../../../../../enums/responsebily.enum";
import { StatsType } from "../../../../../enums/statsType.enum";
import { useMetrics } from "../../../../../shared/hooks/Metrics/useMetrics";
import MetricsService from "../../../../../services/Metrics/metrics.service";
import { Metrics } from "../../../../../models/Metrics/metrics.model";
import { PaginationDetails } from "../../../../../models/Pagination/pagination.model";
import useFilters from "../../../../../shared/hooks/useFilter/useFilters";
import { TablePaginationConfig } from "antd/lib/table";
import { FilterParams } from "../../../../../models/FilterParams/filterParams.model";
import useResponsibilities from "../../../../../shared/hooks/Responsibilities/useResponsibilities";
import CorporateContainer from "../../../../../store/container/CorporateContainer";
import { CorporateReducerProps } from "../../../../../store/reducers/corporateReducer";

interface SetListProps extends CorporateReducerProps {}

function SetList({ practiceId }: SetListProps) {
  const history = useHistory();

  const { hasAccess } = useResponsibilities();

  const [sets, setSets] = useState<DentalSet[]>([]);

  const [showSetForm, setShowSetForm] = useState(false);
  const [metrics, setMetrics] = useState<Metrics>();

  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState<PaginationDetails>();
  const { getParams, setParams } = useFilters();

  const HAS_SET_VIEW_ACCESS = hasAccess(ResponsibilityTypes.SET_VIEW);

  const HAS_SET_CREATE_ACCESS = hasAccess(ResponsibilityTypes.SET_CREATE);

  const HAS_SET_EDIT_ACCESS = hasAccess(ResponsibilityTypes.SET_EDIT);

  const handleToggleSetForm = () => setShowSetForm(!showSetForm);

  const handleSetFormSuccess = (set: DentalSet) => {
    const setIndex = sets.findIndex((dentalSet) => set.id === dentalSet.id);
    if (setIndex >= 0) {
      sets[setIndex] = set;
    } else {
      sets.push(set);
    }
    setSets([...sets]);
    handleToggleSetForm();
    history.push(
      generatePath(AppRoutes.SET_DETAIL, {
        setId: set.id,
      })
    );
  };

  const handleFetchSets = (filter: FilterParams) => {
    if (!HAS_SET_VIEW_ACCESS) return;

    setLoading(true);
    SetService.fetchSets(
      (sets: DentalSet[], pagination: PaginationDetails) => {
        setSets(sets);
        setPagination(pagination);
      },
      () => {},
      () => {
        setLoading(false);
      },
      filter
    );
  };

  const handleRowClick = (set: DentalSet) => {
    return {
      onClick: () => {
        if (!HAS_SET_VIEW_ACCESS) return;

        history.push(
          generatePath(AppRoutes.SET_DETAIL, {
            setId: set.id,
          })
        );
      },
    };
  };

  useEffect(() => {
    handleFetchSets(getParams());
    handleFetchMetrics();
  }, []);

  const [columns, setColumns] = useState<any[]>([
    {
      title: "Set Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Set Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "No of attachments",
      dataIndex: "noOfAttachments",
      key: "noOfAttachments",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text: string, record: any, index: number) => {
        return (
          <div className="text-capitalize">
            <span
              className={
                text === StatusEnum.ACTIVE ? "text-success" : "text-danger"
              }
            >
              {text}
            </span>
          </div>
        );
      },
    },
  ]);
  const handleFetchMetrics = () => {
    if (!HAS_SET_VIEW_ACCESS) return;

    MetricsService.fetchMetrics(
      "sets",
      (metrics: Metrics) => {
        setMetrics(metrics);
      },
      () => {},
      () => {}
    );
  };

  useEffect(() => {
    if (HAS_SET_EDIT_ACCESS) {
      setColumns((columns) => [
        ...columns,
        {
          title: "",
          dataIndex: "id",
          key: "id",
          render: (id: number, record: DentalSet, index: number) => {
            const handleOnCheck = (checked: boolean) => {
              Modal.confirm({
                title: `Are you sure you want to ${
                  checked ? "enable" : "disable"
                } this set?`,
                onOk: () => {
                  setLoading(true);
                  SetService.updateSet(
                    Object.assign(new DentalSet(), {
                      ...record,
                      status: checked ? StatusEnum.ACTIVE : StatusEnum.INACTIVE,
                    }),
                    (set: DentalSet) => {
                      const setIndex = sets.findIndex(
                        (dentalSet: DentalSet) => set.id === dentalSet.id
                      );
                      if (setIndex >= 0) {
                        sets[setIndex] = set;
                      }
                      setSets([...sets]);
                      handleFetchSets(getParams());
                      handleFetchMetrics();
                    },
                    () => {},
                    () => {
                      setLoading(false);
                    }
                  );
                },
              });
            };
            return (
              <span onClick={(event) => event.stopPropagation()}>
                <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  checked={record.status === StatusEnum.ACTIVE}
                  onChange={handleOnCheck}
                />
              </span>
            );
          },
        },
      ]);
    }
  }, [HAS_SET_EDIT_ACCESS]);

  const setListStatsData = [
    {
      title: "Total Sets",
      value: metrics?.totalSets ?? 0,
    },
    {
      title: "Active Sets",
      value: metrics?.activeSets ?? 0,
    },
    {
      title: "Inactive Sets",
      value: metrics?.inactiveSets ?? 0,
    },
  ];

  const handleTableChange = (pagination: TablePaginationConfig) => {
    const prevFilter = getParams();
    const updatedFilters = {
      ...prevFilter,
      page: pagination.current ?? 1,
    };
    setParams(updatedFilters);
    handleFetchSets(updatedFilters);
  };

  return (
    <div className="set-list">
      <Row>
        <Col span={12}>
          <h5 className="text-secondary"> All Sets </h5>
        </Col>
        <Col span={12} className="text-right">
          {HAS_SET_CREATE_ACCESS && (
            <Button type="primary" onClick={handleToggleSetForm}>
              <PlusOutlined /> Add Set
            </Button>
          )}
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Stats statsData={setListStatsData} statsType={StatsType.SET_LIST} />
        </Col>
      </Row>
      <Row>
        {loading ? (
          <AppLoader loading={loading} />
        ) : (
          <Table
            dataSource={sets}
            columns={columns}
            onRow={handleRowClick}
            onChange={handleTableChange}
            pagination={{
              total: pagination?.totalCount,
              current: pagination?.currentPage ?? 1,
              showTotal: (total: number, range: [number, number]) => (
                <p>
                  Showing <b>{` ${range[0]} - ${range[1]}`}</b> of{" "}
                  <b>{`${total.toLocaleString()}`}</b>
                </p>
              ),
            }}
          />
        )}
      </Row>
      <Drawer
        title={<h4>New Set</h4>}
        placement="right"
        keyboard={false}
        maskClosable={false}
        onClose={handleToggleSetForm}
        visible={HAS_SET_CREATE_ACCESS && showSetForm}
        destroyOnClose={true}
        width="60%"
      >
        <SetForm
          showPatientCategory
          set={new DentalSet()}
          onCancel={handleToggleSetForm}
          onSuccess={handleSetFormSuccess}
        />
      </Drawer>
    </div>
  );
}

export default CorporateContainer(SetList);
