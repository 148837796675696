import React, { FC, Fragment, useState } from "react";
import moment, { Moment } from "moment";
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import { AppointmentCalender } from "../../../../models/AppointmentCalender/appointmentCalender.model";
import AuthContainer from "../../../../store/container/AuthContainer";
import { AuthReducerProps } from "../../../../store/reducers/authReducer";
import { ResponsibilityTypes } from "../../../../enums/responsebily.enum";
import { generatePath, useHistory } from "react-router-dom";
import AppRoutes from "../../../../routes/routeConstants/appRoutes";
import { IDropdownOptions } from "../../../../services/Meta/meta.service";
import { Button, Popover,Typography } from "antd";
import { String } from "lodash";
import useResponsibilities from "../../../../shared/hooks/Responsibilities/useResponsibilities";

interface CellProps extends AuthReducerProps {
  date: Moment;
  appointments: AppointmentCalender[];
  setHoliday: (date: Moment, isHoliday: boolean) => void;
  handleDayMonth: (appointmentDate: string) => void;
}

const Cell: FC<CellProps> = ({
  date,
  appointments,
  setHoliday,
  handleDayMonth,
  userResponsibilities,
}) => {
  const { hasAccess } = useResponsibilities();

  const [availableApointmentSlots, setAvailableApointmentSlots] = useState<
    IDropdownOptions[]
  >([]);
  const [isVisible, setIsVisible] = useState(false);

  const HAS_HOLIDAY_VIEW_ACCESS = hasAccess(ResponsibilityTypes.HOLIDAY_VIEW);

  const HAS_HOLIDAY_CREATE_ACCESS = hasAccess(
    ResponsibilityTypes.HOLIDAY_CREATE
  );

  const HAS_HOLIDAY_DELETE_ACCESS = hasAccess(
    ResponsibilityTypes.HOLIDAY_DELETE
  );

  const getAppointmentCount = (date: Moment) => {
    for (let appointment of appointments) {
      if (moment(appointment?.date).isSame(date, "day")) {
        return appointment.noOfAppointments;
      }
    }
    return 0;
  };
  const getAppointmentAvailable = (date: Moment) => {
    for (let appointment of appointments) {
      if (moment(appointment?.date).isSame(date, "day")) {
        return appointment?.availableAppointments?.reduce(
          (sum, cur) => sum + (cur?.noOfAppointmentsAvailable ?? 0),
          0
        );
      }
    }
  };
  const getAppointmentAvailableSlots = (date: Moment) => {
    for (let appointment of appointments) {
      if (moment(appointment?.date).isSame(date, "day")) {
        const data = appointment?.availableAppointments?.map(
          ({ title, noOfAppointmentsAvailable, colorCode }) => {
            return {
              label: title,
              value: noOfAppointmentsAvailable,
              optionalValue: colorCode,
            };
          }
        );

        setAvailableApointmentSlots(data);
      }
    }
  };

  const checkHoliday = () => {
    for (let appointment of appointments) {
      if (moment(appointment?.date).isSame(date, "day")) {
        return appointment.isHoliday;
      }
    }
    return false;
  };

  let popupText = "";
  if (checkHoliday() && HAS_HOLIDAY_DELETE_ACCESS) popupText = "Remove Holiday";
  else if (!checkHoliday() && HAS_HOLIDAY_CREATE_ACCESS)
    popupText = "Add Holiday";

  const history = useHistory();

  const getAppointmentDate = (date: Moment) => {
    for (let appointment of appointments) {
      if (moment(appointment?.date).isSame(date, "day")) {
        return appointment?.date;
      }
    }
    return 0;
  };

  const handleSetHoliday = () => {
    const appointment = appointments.find((appointment) =>
      moment(appointment.date).isSame(date, "day")
    );
    setHoliday(date, appointment?.isHoliday as boolean);
  };

  const isToday = () => {
    const [d_, dmm, ddd, dyyyy] = date.toString().split(" ");
    const [c_, cmm, cdd, cyyyy] = moment().toString().split(" ");
    if (dmm === cmm && ddd === cdd && dyyyy === cyyyy) {
      return true;
    }
    return false;
  };

  return (
    <Fragment>
      <ContextMenuTrigger
        id={`holiday-cell-${date.format("MM-DD-YYYY")}`}
        disable={!HAS_HOLIDAY_VIEW_ACCESS}
      >
        <div
          className={`calender-cell__container ${
            checkHoliday() && HAS_HOLIDAY_VIEW_ACCESS
              ? "calender-cell-holiday"
              : isToday()
              ? "calender-cell-today"
              : ""
          }`}
        >
          {getAppointmentAvailable(date) ? (
            <>
              <Popover
                content={
                  <div className="appointment-popover">
                    {availableApointmentSlots?.map(
                      ({ value, label, optionalValue }) => {
                        return (
                          <p style={{ color: `${optionalValue}` }}>
                            {value} - {label}
                          </p>
                        );
                      }
                    )}
                  </div>
                }
              >
                <span
                  className="appointment-available"
                  onMouseOver={() => {
                    getAppointmentAvailableSlots(date);
                  }}
                  onClick={(e) => {
                    const appointmentDate = getAppointmentDate(
                      date
                    )?.toString();

                    handleDayMonth(appointmentDate ?? "");
                    e.stopPropagation();
                  }}
                >
                  {
                    <Typography.Paragraph ellipsis className="mb-0">
                      {getAppointmentAvailable(date)} Appointment Available
                    </Typography.Paragraph>
                  }
                </span>
              </Popover>
            </>
          ) : null}

          <p className="date">{date.date()}</p>
          <div
            className="appointments__wrapper"
            onClick={() => {
              if (getAppointmentCount(date)) {
                const appointmentDate = getAppointmentDate(date)?.toString();

                handleDayMonth(appointmentDate ?? "");
              }
            }}
          >
            {getAppointmentCount(date) ? (
              <Typography.Paragraph className="appointments" ellipsis>
                {getAppointmentCount(date)} Appointments Created
              </Typography.Paragraph>
            ) : null}
            {checkHoliday() && HAS_HOLIDAY_VIEW_ACCESS ? <p>Holiday</p> : null}
          </div>
        </div>
      </ContextMenuTrigger>
      <ContextMenu id={`holiday-cell-${date.format("MM-DD-YYYY")}`}>
        {popupText && (
          <MenuItem>
            <div className="holiday-menu-item" onClick={handleSetHoliday}>
              {popupText}
            </div>
          </MenuItem>
        )}
      </ContextMenu>
    </Fragment>
  );
};

export default AuthContainer(Cell);
