import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";
import * as BabylonActions from "../actions/babylonActions";
import { RootReducerProps } from "../reducers";

const mapStateToProps = (state: RootReducerProps, ownProps: any) => {
  const {
    scene,
    highlightLayer,
    sourceObject,
    corporateObjects,
    showPopover,
    popoverPosition,
    toothPositions,
    conflicts,
    caps,
    setCaps,
    corporateObjectsLoading,
    capsLoading,
    setCapsLoading,
    setCorporateObjectsLoading,
    meshLoading
  } = state.babylon;
  return {
    ...ownProps,
    scene,
    highlightLayer,
    sourceObject,
    corporateObjects,
    showPopover,
    popoverPosition,
    toothPositions,
    conflicts,
    caps,
    setCaps,
    capsLoading,
    setCapsLoading,
    corporateObjectsLoading,
    setCorporateObjectsLoading,
    meshLoading
  };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(BabylonActions, dispatch);

const BabylonContainer = (component: any) => {
  return connect(mapStateToProps, mapDispatchToProps)(component);
};

export default BabylonContainer;
