import React, { FC } from "react";
import { Button } from "antd";
import { Form, Formik, FormikConfig } from "formik";
import { XRayCategory } from "models/XRayCategory/xRayCategory.model";
import { NoteTemplateService } from "services/NoteTemplate/noteTemplate.service";
import InputField from "shared/components/InputField";
import { validationSchema } from "./validation";

import "./xRayCategoryForm.scss";

interface XRayCategoryFormProps {
  category?: XRayCategory;
  onSubmit?: (category: XRayCategory) => void;
  onCancel?: () => void;
}

const XRayCategoryForm: FC<XRayCategoryFormProps> = ({
  category = new XRayCategory(),
  onCancel,
  onSubmit,
}) => {
  const handleSubmit: FormikConfig<XRayCategory>["onSubmit"] = async (
    category,
    { resetForm }
  ) => {
    await NoteTemplateService?.[
      category?.id ? "updateXRayCategory" : "createXRayCategory"
    ]?.(category, (category) => {
      onSubmit?.(category);
      resetForm();
    });
  };

  return (
    <div className="x-ray-category-form">
      <Formik
        enableReinitialize
        initialValues={category}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, errors }) => (
          <Form>
            <InputField title="Name" name="name" type="text" />
            <InputField
              name="description"
              rows={8}
              title="Description"
              type="textarea"
            />
            <div className="x-ray-category-form__footer">
              <Button htmlType="reset" onClick={onCancel}>
                Cancel
              </Button>
              <Button htmlType="submit" type="primary" loading={isSubmitting}>
                Save
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default XRayCategoryForm;
