
import { TreatmentPlanTemplateSteps } from '../TreatmentPlanTemplateSteps/treatmentPlanTemplateSteps.model';

import { TreatmentPlanTemplateStatementNotes } from '../TreatmentPlanTemplateStatementNotes/treatmentPlanTemplateStatementNotes.model';

import { serializable, alias, object, list, primitive } from 'serializr';

type VisitType = "physical" | "selfcare"

export class TreatmentPlanTemplateVisits {

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('is_current_visit', primitive()))
	isCurrentVisit:boolean = false;

	@serializable(alias('self_care_object_category_name', primitive()))
	selfCareObjectCategoryName?: string;

	@serializable(alias('treatment_plan_template_statement_notes', list(object(TreatmentPlanTemplateStatementNotes))))
	treatmentPlanTemplateStatementNote: TreatmentPlanTemplateStatementNotes[] = [];

	@serializable(alias('treatment_plan_template_steps', list(object(TreatmentPlanTemplateSteps))))
	treatmentPlanTemplateSteps: TreatmentPlanTemplateSteps[] = [];

	@serializable(alias('treatment_plan_statement_notes', list(object(TreatmentPlanTemplateStatementNotes))))
	treatmentPlanStatementNote: TreatmentPlanTemplateStatementNotes[] = [];

	@serializable(alias('treatment_plan_steps', list(object(TreatmentPlanTemplateSteps))))
	treatmentPlanSteps: TreatmentPlanTemplateSteps[] = [];

	@serializable(alias('treatment_plan_template_id', primitive()))
	treatmentPlanTemplateId?: number;

	@serializable(alias('order', primitive()))
	order?: number;

	@serializable(alias('duration_in_weeks', primitive()))
	durationInWeeks?: number;

	@serializable(alias('self_care_object_category_id', primitive()))
	selfCareObjectCategoryId?: string;

	@serializable(alias('self_care_frequency', primitive()))
	selfCareFrequency?: string;

	@serializable(alias('self_care_frequency_unit', primitive()))
	selfCareFrequencyUnit?: string;

	@serializable(alias('description', primitive()))
	description?: string;

	@serializable(alias('visit_type', primitive()))
	visitType?: VisitType;
}