import axiosInstance from "../../interceptor/axiosInstance";
import ApiRoutes from "../../routes/routeConstants/apiRoutes";
import { deserialize, serialize } from "serializr";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import { Practice } from "../../models/Practice/practice.model";
import { FilterParams } from "../../models/FilterParams/filterParams.model";
import { PaginationDetails } from "../../models/Pagination/pagination.model";

export default class PracticeService {
  static fetchPractices(
    onSuccess: Function,
    onError: Function,
    onFinal: () => void,
    filter?: FilterParams
  ) {
    const params = serialize(FilterParams, filter ?? new FilterParams());
    if (localStorage.getItem("corporateId")) {
      return axiosInstance
        .get(ApiRoutes.PRACTICES, { params })
        .then((response) => {
          const practices = deserialize(Practice, response.data["practices"]);
          const pagination = deserialize(
            PaginationDetails,
            response.data["meta"]
          );
          onSuccess(practices, pagination);
        })
        .catch((error) => {
          onError();
        })
        .finally(onFinal);
    }
  }

  static createPractice(
    practice: Practice,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const practiceJSON = { practice: serialize(practice) };
    return axiosInstance
      .post(ApiRoutes.PRACTICES, practiceJSON)
      .then((response) => {
        Notification({
          message: "Practice created",
          type: NotificationTypes.SUCCESS,
        });
        const practice = deserialize(Practice, response.data["practice"]);
        onSuccess(practice);
      })
      .catch((error) => {
        onError();
      })
      .finally(onFinal);
  }

  static updatePractice(
    practice: Practice,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const practiceJSON = { practice: serialize(practice) };
    const URL = `${ApiRoutes.PRACTICES}/${practice.id}`;
    return axiosInstance
      .put(URL, practiceJSON)
      .then((response) => {
        Notification({
          message: "Practice Updated",
          type: NotificationTypes.SUCCESS,
        });
        const practice = deserialize(Practice, response.data["practice"]);
        onSuccess(practice);
      })
      .catch((error) => {
        onError();
      })
      .finally(onFinal);
  }

  static deletePractice(
    practiceId: string,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.PRACTICES + "/" + practiceId;
    return axiosInstance
      .delete(API_URL)
      .then((response) => {
        Notification({
          message: "Practice deleted",
          type: NotificationTypes.SUCCESS,
        });
        onSuccess();
      })
      .catch((error) => {
        onError();
      })
      .finally(onFinal);
  }
}
