import React, { useEffect, useState } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import { Button, Drawer, Table } from "antd";
import "./corporateObjectLinks.scss";
import CorporateLinkService from "../../../../../services/CorporateLink/corporateLink.service";
import { CorporateLink } from "../../../../../models/CorporateLink/corporateLink.model";
import AppLoader from "../../../../../shared/components/AppLoader";
import {
  ArrowLeftOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import AppRoutes from "../../../../../routes/routeConstants/appRoutes";
import { generatePath } from "react-router";
import { CorporateObject } from "../../../../../models/CorporateObject/corporateObject.model";
import CorporateObjectForm from "../CorporateObjectForm";
import GlobalNoteTemplateForm from "../GlobalNoteTemplateForm";

interface ICorporateObjectLinksParams {
  corporateObjectId: string;
}

interface CorporateObjectLinksProps {}

function CorporateObjectLinks(props: CorporateObjectLinksProps) {
  const history = useHistory();

  const params: ICorporateObjectLinksParams = useParams();

  const [showGlobalTemplateForm, setShowGlobalTemplateForm] = useState(false);

  const [activeCorporateLink, setActiveCorporateLink] = useState<
    CorporateObject | undefined
  >();

  const [loading, setLoading] = useState(false);

  const [corporateLinks, setCorporateLinks] = useState<CorporateLink[]>([]);

  const handleToggleGlobalTemplateForm = () =>
    setShowGlobalTemplateForm(!showGlobalTemplateForm);

  const handleEditCorporateLink = (corporateLink: CorporateLink) => () => {
    setActiveCorporateLink(corporateLink);
    handleToggleGlobalTemplateForm();
  };

  const handleGlobalTemplateSuccess = (corporateLink: CorporateLink) => {
    const linkIndex = corporateLinks.findIndex(
      (link) => link.id === corporateLink.id
    );
    if (linkIndex >= 0) {
      corporateLinks[linkIndex] = corporateLink;
      setCorporateLinks([...corporateLinks]);
    }
    handleToggleGlobalTemplateForm();
  };

  const columns = [
    {
      title: "Current Object Locator",
      dataIndex: "srcLocatorName",
      key: "srcLocatorName",
    },
    {
      title: "Target Object",
      dataIndex: "destObjectName",
      key: "destObjectName",
    },
    {
      title: "Target Object Locator",
      dataIndex: "destLocatorName",
      key: "destLocatorName",
    },
    {
      title: "Text Note",
      dataIndex: "textNote",
      key: "textNote",
    },
    {
      title: "",
      dataIndex: "id",
      key: "id",
      render: (text: string, corporateLink: CorporateLink, index: number) => {
        return (
          <div>
            <Button
              type="primary"
              className="mr-4"
              icon={<EditOutlined />}
              onClick={handleEditCorporateLink(corporateLink)}
            />
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    if (params.corporateObjectId) {
      setLoading(true);
      CorporateLinkService.fetchCorporateLinks(
        Number(params.corporateObjectId),
        null,
        null,
        (corporateLinks: CorporateLink[]) => {
          setCorporateLinks(corporateLinks);
        },
        () => {},
        () => {
          setLoading(false);
        }
      );
    }
  }, [params.corporateObjectId]);
  return (
    <div className="corporate-object-links">
      {loading ? (
        <AppLoader loading={loading} />
      ) : (
        <div>
          <div className="corporate-object-links__header">
            <h5 className="text-secondary">
              <Link
                to={generatePath(AppRoutes.CORPORATE_OBJECT_DETAIL, {
                  corporateObjectId: params.corporateObjectId,
                })}
              >
                <ArrowLeftOutlined className="text-secondary" />
              </Link>
              <span className="ml-2">
                {corporateLinks ? corporateLinks[0]?.srcObjectName : "-"}
              </span>
            </h5>
          </div>
          <div className="corporate-object-links__body">
            <Table dataSource={corporateLinks} columns={columns} />
          </div>
          <Drawer
            title={<h4>Global Note Template</h4>}
            placement="right"
            keyboard={false}
            maskClosable={false}
            onClose={handleToggleGlobalTemplateForm}
            visible={showGlobalTemplateForm}
            destroyOnClose={true}
            width="70%"
          >
            {activeCorporateLink && (
              <GlobalNoteTemplateForm
                onCancel={handleToggleGlobalTemplateForm}
                corporateLink={activeCorporateLink}
                onSuccess={handleGlobalTemplateSuccess}
              />
            )}
          </Drawer>
        </div>
      )}
    </div>
  );
}

export default CorporateObjectLinks;
