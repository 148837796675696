import React, { useState, Fragment, FC, useEffect } from "react";
import { Button, Col, Drawer, Popover, Row } from "antd";
import {
  PlusOutlined,
  CaretDownOutlined,
  CaretUpOutlined,
  CaretRightFilled,
  CaretRightOutlined,
} from "@ant-design/icons";
import "./treatmentTemplateNotes.scss";
import { Formik, FormikValues, Form } from "formik";
import { SelectProp } from "../../../../../../shared/Types/option";
import DropdownField from "../../../../../../shared/components/DropdownField";
import InputField from "../../../../../../shared/components/InputField";
import { TreatmentPlanReducerProps } from "../../../../../../store/reducers/treatmentPlanReducer";
import TreatmentPlanContainer from "../../../../../../store/container/TreatmentPlanContrainer";
import AppointmentDetail from "./AppointmentDetail";
import StatementIcon from "../../../../../../assets/icons/Statement.png";
import StatementNotesForm from "../../../../../Interactions/StatementNotes/StatementNotesForm";
import { StatementNote } from "../../../../../../models/StatementNote/statementNote.model";
import { TreatmentTemplateService } from "../../../../../../services/TreatmentTemplate/treatmentTemplate.service";
import { useLocation, useParams } from "react-router";
import { TreatmentPlanTemplateStatementNotes } from "../../../../../../models/TreatmentPlanTemplateStatementNotes/treatmentPlanTemplateStatementNotes.model";
import CorporateObjectService from "../../../../../../services/CorporateObject/corporateObject.service";
import { DentalObjectTypeEnum } from "../../../../../../enums/dentalObjectType.enum";
import { CorporateObject } from "../../../../../../models/CorporateObject/corporateObject.model";
import { getDropdownOptions } from "../../../../../../shared/utils/optionHelper";
import {
  IDropdownOptions,
  MetaService,
} from "../../../../../../services/Meta/meta.service";
import { TreatmentPlanTemplateVisit } from "../../../../../../models/TreatmentPlanTemplateVisit/treatmentPlanTemplateVisit.model";
import TreatmentPlanService from "../../../../../../services/TreatmentPlan/treatmentPlan.service";
import RadioInputField from "../../../../../../shared/components/RadioInputField";
import useAppointmentTypes from "../../../../../../shared/hooks/useAppointmentTypes";
import { Collapse } from "antd";
import { VisitType } from "../../../../../../enums/visitType.enum";
import useResponsibilities from "../../../../../../shared/hooks/Responsibilities/useResponsibilities";
import { ResponsibilityTypes } from "../../../../../../enums/responsebily.enum";
import CorporateContainer from "../../../../../../store/container/CorporateContainer";
import { CorporateReducerProps } from "../../../../../../store/reducers/corporateReducer";

const AppointmentTypeOptions: SelectProp[] = [
  {
    label: "Physical Appointment",
    value: "physical",
    key: "physical",
  },
  {
    label: "Selfcare",
    value: "selfcare",
    key: "selfcare",
  },
];

const FrequencyOptions: SelectProp[] = [
  {
    label: "Day",
    value: "day",
    key: "day",
  },
  {
    label: "Week",
    value: "week",
    key: "week",
  },
  {
    label: "Month",
    value: "month",
    key: "month",
  },
];

interface TreatmentTemplateNotesProps
  extends CorporateReducerProps,
    TreatmentPlanReducerProps {
  onSuccess: () => void;
}

const TreatmentTemplateNotes: FC<TreatmentTemplateNotesProps> = (props) => {
  const { Panel } = Collapse;
  const {
    currentTreatmentPlan,
    setCurrentTreatmentPlan,
    onSuccess,
    currentAppointment,
    setCurrentAppointment,
    practiceId,
  } = props;

  const { hasAccess } = useResponsibilities();

  const [statementView, setStatementView] = useState(false);

  const params: any = useParams();

  const [loading, setLoading] = useState(false);

  const [viewTreatmentSteps, setViewTreatmentSteps] = useState(false);

  const [treatmentStepDrawer, setTreatmentStepDrawer] = useState(false);

  const [vendorObjectOptions, setVendorObjectOptions] = useState<
    IDropdownOptions[]
  >([]);
  const {
    options: appointmentType,
    loading: appointmentTypeLoading,
    fetchAppointmentTypes,
  } = useAppointmentTypes({});

  const location = useLocation();

  const [statementNotes, setStatementNotes] = useState<
    Array<TreatmentPlanTemplateStatementNotes[]>
  >([]);

  const HAS_TREATMENT_PLAN_EDIT_ACCESS = hasAccess(
    ResponsibilityTypes.TREATMENT_PLAN_EDIT
  );

  useEffect(() => {
    if (currentTreatmentPlan?.treatmentPlanTemplateVisits.length) {
      setStatementNotes(
        currentTreatmentPlan?.treatmentPlanTemplateVisits.map(
          (visit) => visit.treatmentPlanTemplateStatementNote
        ) ?? []
      );
    } else {
      setStatementNotes(
        currentTreatmentPlan?.treatmentPlanVisits.map(
          (visit) => visit.treatmentPlanStatementNote
        ) ?? []
      );
    }
  }, [currentTreatmentPlan]);

  const isTreatmentPlan = () => {
    const paths = location.pathname.split("/");
    if (paths[paths.length - 2] === "treatment-plans") {
      return true;
    }
    return false;
  };

  useEffect(() => {
    MetaService.fetchAllObjectCategories(
      (vendorObjects) => {
        setVendorObjectOptions(
          getDropdownOptions(
            vendorObjects?.filter(({ objectType }) => objectType === "vendor"),
            "name",
            "id"
          )
        );
      },
      () => {},
      () => {}
    );
  }, []);

  const handleViewTreatmentSteps = () => {
    setViewTreatmentSteps((viewTreatmentSteps) => !viewTreatmentSteps);
  };

  const handleCloseTreatmentStepDrawer = () => setTreatmentStepDrawer(false);

  const handleSubmit = (values: TreatmentPlanTemplateVisit) => {
    setLoading(true);
    const treatmentPlanTemplateVisit = Object.assign(
      new TreatmentPlanTemplateVisit(),
      {
        ...values,
        order: currentTreatmentPlan?.treatmentPlanTemplateVisits.length
          ? (currentTreatmentPlan?.treatmentPlanTemplateVisits[
              currentTreatmentPlan?.treatmentPlanTemplateVisits.length - 1
            ].order as number) + 1
          : 1,
      }
    );

    TreatmentTemplateService.createTreatmentStepInformation(
      params.id,
      treatmentPlanTemplateVisit,
      () => {
        TreatmentTemplateService.getTreatmentPlanDetails(
          params.id,
          (treatmentPlanTemplate) => {
            setCurrentTreatmentPlan(treatmentPlanTemplate);
            handleCloseTreatmentStepDrawer();
          },
          () => {},
          () => {}
        );
      },
      () => {},
      () => {
        setLoading(false);
      }
    );
  };

  const updateCurrentTreatmentPlan = (
    statementNote: TreatmentPlanTemplateStatementNotes
  ) => {
    let isUpdated = false;
    if (currentTreatmentPlan) {
      currentTreatmentPlan?.treatmentPlanTemplateVisits.forEach(
        (visit, index) => {
          if (visit.visitType === "physical") {
            if (
              !isUpdated &&
              visit?.id === statementNote?.treatmentPlanTemplateVisitId
            ) {
              const updatedStatementNotes = [
                ...statementNotes[index],
                statementNote,
              ];
              statementNotes.splice(index, 1, updatedStatementNotes);
              isUpdated = true;
              setStatementNotes([...statementNotes]);
            }
          }
        }
      );
    }
  };

  const handleAddStatementNote = (statementNote: StatementNote) => {
    if (currentTreatmentPlan) {
      const options = {
        treatmentPlanTemplateId: params.id,
        treatmentPlanTemplateAppointmentId: currentAppointment?.toString(),
        statementNote,
      };

      let service;
      if (isTreatmentPlan()) {
        service = TreatmentPlanService.createStatementNote;
      } else {
        service = TreatmentTemplateService.createStatementNote;
      }
      service(
        options,
        (statementNote: TreatmentPlanTemplateStatementNotes) => {
          updateCurrentTreatmentPlan(statementNote);
        },
        () => {},
        () => {}
      );
    }
  };
  const visitTypeOptions = [
    {
      label: "In Person",
      value: "physical",
      key: "physical",
    },
    {
      label: "Self Care",
      value: "selfCare",
      key: "phic",
    },
  ];

  return (
    <div className="treatment-template-notes">
      {/* <div className="title">
        <p>Treatment Execution Plan: Bracket and Elastic</p>
      </div> */}
      <Row>
        <Col span={20}>
          <h4 className="bout-title mt-2">
            Bout {currentTreatmentPlan?.noOfBouts || 0}
          </h4>
        </Col>
        {HAS_TREATMENT_PLAN_EDIT_ACCESS && (
          <Col span={4} className="mb-3">
            <Button
              type="dashed"
              className="btn-small"
              onClick={() => {
                setTreatmentStepDrawer(true);
              }}
            >
              <PlusOutlined />
            </Button>
          </Col>
        )}
        {/* <Col span={isTreatmentPlan() ? 24 : 20}>
          <Button
            type="dashed"
            className="btn-small full-width"
            onClick={handleViewTreatmentSteps}
          >
            Treatment Steps & Actions{" "}
            {viewTreatmentSteps ? <CaretUpOutlined /> : <CaretDownOutlined />}
          </Button>
        </Col> */}

        <Col className="statement-note__collapse" span={24}>
          <div className="appointments__container">
            {currentTreatmentPlan?.treatmentPlanTemplateVisits.map(
              (visits, index) => (
                <AppointmentDetail
                  onSuccess={onSuccess}
                  appointmentNo={index + 1}
                  treatmentPlanId={currentTreatmentPlan.id}
                  treatmentPlanTemplateVisits={visits}
                  statementNotes={statementNotes[index]}
                  onAddStatement={handleAddStatementNote}
                />
              )
            )}
            {currentTreatmentPlan?.treatmentPlanVisits.map((visits, index) => (
              <AppointmentDetail
                onSuccess={onSuccess}
                appointmentNo={index + 1}
                treatmentPlanId={currentTreatmentPlan.id}
                treatmentPlanTemplateVisits={visits}
                statementNotes={statementNotes[index]}
                onAddStatement={handleAddStatementNote}
              />
            ))}
          </div>
        </Col>
      </Row>

      <Drawer
        visible={treatmentStepDrawer}
        onClose={handleCloseTreatmentStepDrawer}
        destroyOnClose
        title="Treatment Step Information"
        width={"65vw"}
        className="treatment-step-information__wrapper"
      >
        <Formik
          initialValues={new TreatmentPlanTemplateVisit()}
          onSubmit={handleSubmit}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <div className="input__wrapper">
                <Row>
                  <RadioInputField
                    name="visitType"
                    title=""
                    setFieldValue={setFieldValue}
                    options={visitTypeOptions}
                    value={values.visitType}
                  />
                </Row>
                <Row gutter={[15, 15]}>
                  <Col span={12}>
                    <InputField
                      type="text"
                      name="description"
                      title="Step Name"
                    />
                  </Col>
                </Row>
                <Row gutter={[15, 15]}>
                  <Col span={12}>
                    <DropdownField
                      title="Appointment Type"
                      options={appointmentType}
                      loading={appointmentTypeLoading}
                      onScrollEnd={fetchAppointmentTypes}
                      name="defaultAppointmentTypeId"
                      value={values.defaultAppointmentTypeId}
                      setFieldValue={setFieldValue}
                      disabled={values?.visitType !== VisitType.PHYSICAL}
                    />
                  </Col>
                </Row>
                {values.visitType === VisitType.PHYSICAL ? (
                  <Fragment>
                    <Row gutter={[15, 15]}>
                      <Col span={12}>
                        <InputField
                          type="number"
                          name="durationInWeeks"
                          title="Time interval from previous appointment (in weeks)"
                        />
                      </Col>
                    </Row>
                  </Fragment>
                ) : (
                  <Fragment>
                    <Row gutter={[15, 15]}>
                      <Col span={12}>
                        <DropdownField
                          title="Select Object"
                          options={vendorObjectOptions}
                          name="selfCareObjectCategoryId"
                          setFieldValue={setFieldValue}
                        />
                      </Col>
                    </Row>
                    <Row gutter={[15, 15]}>
                      <Col span={12}>
                        <InputField
                          type="text"
                          title="Frequency"
                          placeholder="Enter Frequency"
                          name="selfCareFrequency"
                        />
                      </Col>
                      <Col span={12}>
                        <DropdownField
                          title="Select Unit"
                          placeHolder="Select"
                          options={FrequencyOptions}
                          name="selfCareFrequencyUnit"
                          setFieldValue={setFieldValue}
                        />
                      </Col>
                    </Row>
                  </Fragment>
                )}
              </div>
              <div className="controller__wrapper">
                <Button type="text" onClick={handleCloseTreatmentStepDrawer}>
                  Cancel
                </Button>
                <Button type="primary" htmlType="submit" loading={loading}>
                  Add
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Drawer>
    </div>
  );
};

export default CorporateContainer(
  TreatmentPlanContainer(TreatmentTemplateNotes)
);
