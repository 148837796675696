import { Button, Col, Row } from "antd";
import { Form, Formik } from "formik";
import moment from "moment";
import React, { FC, useEffect, useState } from "react";
import { AvailabilityTypeEnum } from "../../../../enums/availabilityType.enum";
import { DropdownModeEnum } from "../../../../enums/dropdownMode.enum";
import { Practice } from "../../../../models/Practice/practice.model";
import { Slot } from "../../../../models/Slot/slot.model";
import { AppointmentService } from "../../../../services/Appointment/appointment.service";
import { IDropdownOptions } from "../../../../services/Meta/meta.service";
import PracticeService from "../../../../services/Practice/practice.service";
import DatePickerField from "../../../../shared/components/DatePickerField";
import DropdownField from "../../../../shared/components/DropdownField";
import scheduleSlotValidation from "./scheduleSlotValidation";
import "./scheduleSlot.scss";

interface ScheduleSlotFormProps {
  slot: Slot;
  handleEditVisibility: () => void;
  scheduleId: number;
  onSuccess?: (slot?: Slot) => void;
}

const ScheduleSlotForm: FC<ScheduleSlotFormProps> = (props) => {
  const [formValues, setFormValues] = useState<Slot>(new Slot());
  const [loading, setLoading] = useState(false);

  const [practices, setPractices] = useState<IDropdownOptions[]>([]);
  const { handleEditVisibility, slot, scheduleId, onSuccess } = props;

  useEffect(() => {
    PracticeService.fetchPractices(
      (practices: Practice[]) => {
        setPractices(
          practices?.map(({ name, id }) => ({
            label: name,
            value: id,
          }))
        );
      },
      () => {},
      () => {}
    );
    setFormValues(slot);
  }, []);

  const appointmentTypes = [
    { label: "InPerson", value: AvailabilityTypeEnum.IN_PERSON },
    { label: "Virtual", value: AvailabilityTypeEnum.VIRTUAL },
  ];

  const handleSubmit = (values: Slot) => {
    const params = {
      ...values,
      startTime: moment.utc(values?.startTime).format(),
      endTime: moment.utc(values?.endTime).format(),
    };
    setLoading(true);
    AppointmentService.updateSlot(
      scheduleId?.toString() ?? "",
      slot?.id?.toString() ?? "",
      params,
      () => {
        onSuccess?.(slot);
      },
      () => {},
      () => {
        setLoading(false);
      }
    );
  };
  return (
    <div>
      {" "}
      <Formik initialValues={slot} onSubmit={handleSubmit} validationSchema={scheduleSlotValidation}>
        {({ values, isValid, dirty, setFieldValue }) => {
          return (
            <Form className="schedule-slot-staff">
              <div className="schedule-slot-staff__form">
                <Row gutter={[20, 20]}>
                  <Col span={12}>
                    <DropdownField
                      title="Practice Name"
                      name="practiceId"
                      options={practices}
                      value={values.practiceId}
                      setFieldValue={setFieldValue}
                      placeHolder="Select Practice"
                    />
                  </Col>
                  <Col span={12}>
                    <DropdownField
                      title="Type of Appointment"
                      name="availabilityTypes"
                      options={appointmentTypes}
                      mode={DropdownModeEnum.MULTIPLE}
                      value={values.availabilityTypes.map(
                        (availabilityType) => availabilityType.type
                      )}
                      onChange={(values) => {
                        setFieldValue(
                          "availabilityTypes",
                          values?.map((value: string) => ({
                            type: value,
                          }))
                        );
                      }}
                      placeHolder="Select appointment type"
                    />
                  </Col>

                  <Col span={12}>
                    <DatePickerField
                      title="Start Time"
                      name="startTime"
                      type="time"
                      value={values?.startTime && moment(values?.startTime)}
                      format="hh:mm a"
                      onChange={(value) => {
                        setFieldValue("startTime", value && moment(value).toString());
                      }}
                    />
                  </Col>
                  <Col span={12}>
                    <DatePickerField
                      title="End Time"
                      name="endTime"
                      type="time"
                      value={values?.endTime && moment.utc(values?.endTime)}
                      format="hh:mm a"
                      onChange={(value) => {
                        setFieldValue("endTime", value && moment(value).toString());
                      }}
                    />
                  </Col>
                </Row>
              </div>
              <div className="user-form__submit-wrapper text-right mt-5">
                <Button type="default" onClick={handleEditVisibility}>
                  Cancel
                </Button>
                <Button
                  type="primary"
                  className="ml-2"
                  htmlType="submit"
                  loading={loading}
                >
                  Save
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default ScheduleSlotForm;
