import React from "react";
import "./practiceList.scss";
import { Practice } from "../../../models/Practice/practice.model";
import { Button, Modal, Table } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import PracticeService from "../../../services/Practice/practice.service";
import AuthContainer from "../../../store/container/AuthContainer";
import { AuthReducerProps } from "../../../store/reducers/authReducer";
import { ResponsibilityTypes } from "../../../enums/responsebily.enum";

interface PracticeListProps extends AuthReducerProps{
  practices: Practice[];
  onDeleteSuccess: (practiceId: string) => void;
}

function PracticeList({ practices, onDeleteSuccess, userResponsibilities }: PracticeListProps) {
  const handleDeletePractice = (practiceId: string) => {
    Modal.confirm({
      icon: null,
      content: <h5>Are you sure want to delete the practice?</h5>,
      onOk() {
        PracticeService.deletePractice(
          practiceId,
          () => {
            onDeleteSuccess(practiceId);
          },
          () => {},
          () => {}
        );
      },
      onCancel() {},
    });
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text: string, record: any, index: number) => {
        return (
          <div>
            <div className="text-bold"> {record?.name} </div>
            <div className="text-primary"> {record?.email} </div>
          </div>
        );
      },
    },
    {
      title: "Mobile",
      dataIndex: "mobileNumber",
      key: "mobileNumber",
    },
    {
      title: "Time zone",
      dataIndex: "timeZone",
      key: "timeZone",
    },
    {
      title: "",
      dataIndex: "id",
      key: "id",
      render: (text: string, record: any, index: number) => {
        if(!userResponsibilities.includes(ResponsibilityTypes.PRACTICE_DELETE)) {
          return null;
        }
        return (
          <Button
            onClick={() => handleDeletePractice(record?.id)}
            className="delete-btn"
            icon={<DeleteOutlined />}
          />
        );
      },
    },
  ];

  return (
    <div className="practice-list">
      <h5 className="text-secondary"> Practices </h5>
      <Table dataSource={practices} columns={columns} />
    </div>
  );
}

export default AuthContainer(PracticeList);
