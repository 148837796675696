import React, { FC, Fragment, useState } from "react";
import { Table, Modal } from "antd";
import { Contract } from "../../../../models/Contract/contract.model";
import moment from "moment";
import {
  useHistory,
  generatePath,
  useParams,
  useLocation,
} from "react-router-dom";
import AppRoutes from "../../../../routes/routeConstants/appRoutes";
import AuthContainer from "../../../../store/container/AuthContainer";
import { AuthReducerProps } from "../../../../store/reducers/authReducer";
import { ResponsibilityTypes } from "../../../../enums/responsebily.enum";
import ResourceService from "../../../../services/Resource/resource.service";
import deleteIcon from "../../../../assets/images/delete.png";
import reOpenIcon from "../../../../assets/images/edit.png";
import "./listContract.scss";
import ContractService from "../../../../services/Contract/Contract.service";

import CorporateContainer from "../../../../store/container/CorporateContainer";
import { CorporateReducerProps } from "../../../../store/reducers/corporateReducer";
import { StatusEnum } from "../../../../enums/status.enum";
import ContractContainer from "../../../../store/container/ContractContainer";
import { ContractReducerProps } from "../../../../store/reducers/contractReducer";
import { PaginationDetails } from "../../../../models/Pagination/pagination.model";
import { TablePaginationConfig } from "antd/lib/table";
import useFilters from "../../../../shared/hooks/useFilter/useFilters";
import { FilterParams } from "../../../../models/FilterParams/filterParams.model";
import useResponsibilities from "../../../../shared/hooks/Responsibilities/useResponsibilities";
import { ContractStatus } from "enums/contractStatus.enum";

const { confirm } = Modal;

interface ListContractProps
  extends AuthReducerProps,
    CorporateReducerProps,
    ContractReducerProps {
  contractPagination: PaginationDetails;
  contracts: Contract[];
  fetchContracts: (filter: FilterParams) => void;
  updateContact: (contracts: Contract[]) => void;
}

const ListContract: FC<ListContractProps> = ({
  contracts,
  practiceId,
  fetchContracts,
  contractPagination,
  setContract,
  updateContact,
}) => {
  const location = useLocation();
  const history = useHistory();

  const { hasAccess } = useResponsibilities();
  const { getParams, setParams } = useFilters();

  const [formLoading, setFormLoading] = useState(false);
  const [contractId, setContractId] = useState("");

  const HAS_PATIENT_CONTRACT_VIEW_ACCESS = hasAccess(
    ResponsibilityTypes.PATIENT_CONTRACT_VIEW
  );

  const HAS_PATIENT_CONTRACT_EDIT_ACCESS = hasAccess(
    ResponsibilityTypes.PATIENT_CONTRACT_EDIT
  );

  const handleRowClick = (contract: Contract, rowIndex: number | undefined) => {
    return {
      onClick: (event: any) => {
        if (!HAS_PATIENT_CONTRACT_VIEW_ACCESS) return;
        history.push(
          generatePath(AppRoutes.CONTRACT_DETAIL, { contractId: contract.id })
        );
      },
    };
  };

  const isContractEditable = (contract: Contract) => {
    const isContractInitiated = contract?.status === ContractStatus.INITIATED;

    const isContractDocumentSigned =
      contract?.status === ContractStatus.DOCUMENT_SIGNED;


    const isContractOpen =
    contract?.status === ContractStatus.ACTIVE;

    return (
      HAS_PATIENT_CONTRACT_EDIT_ACCESS &&
      (isContractInitiated || isContractDocumentSigned || isContractOpen)
    );
  } 
  const closeContract = (
    status: String,
    closeValue: Boolean,
    contractId: string,
    currentStatus?: string
  ) => {
    setFormLoading(true);

    if (practiceId) {
      ContractService.closeContract(
        practiceId,
        contractId,
        { status: status, is_force_close: closeValue },

        (contract: Contract[]) => {
          updateContact(contract);
        },
        () => {
          status === "closed" &&
            confirm({
              className: "end-contract-modal",
              content: `Contract services in this contracts are not completed yet, Do you wish to force end this contract?`,

              okText: "Yes",
              cancelText: "No",
              onOk: () => {
                closeContract("closed", true, contractId);
              },
            });
        },
        () => {
          setFormLoading(false);
        }
      );
    }
  };

  const contractsColumns = [
    {
      title: "Contract Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Practice",
      dataIndex: "practiceName",
      key: "practiceName",
    },
    {
      title: "Created Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt: string) =>
        createdAt ? moment(createdAt).format("MM/DD/YYYY") : "NA",
    },
    {
      title: "Patient",
      dataIndex: "id",
      key: "id",
      render: (text: string, record: Contract, index: number) => {
        return record?.patientFirstName && record?.patientLastName ? (
          <div>
            {record?.patientFirstName} {record?.patientLastName}
          </div>
        ) : (
          "NA"
        );
      },
    },
    {
      title: "Duration",
      dataIndex: "durationInMonths",
      key: "durationInMonths",
      render: (duration: string) => `${duration} months`,
    },
    {
      title: "No of visits",
      dataIndex: "noOfVisits",
      key: "noOfVisits",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status: string, record: any, index: number) => {
        return (
          <div className="text-capitalize">
            <span className={status}>{status?.split("_").join(" ")}</span>
          </div>
        );
      },
    },
    {
      title: "",
      dataIndex: "id",
      key: "id",
      render: (contractId: string, contract: Contract) => (
        HAS_PATIENT_CONTRACT_EDIT_ACCESS && contract?.status !== ContractStatus.CANCELLED && <Fragment>
          <div className="list-contract__icons ">
            {(contract?.status === StatusEnum.ACTIVE ||
              contract?.status === StatusEnum.CLOSED) && (
              <img
                alt=""
                src={
                  contract?.status === StatusEnum.ACTIVE
                    ? deleteIcon
                    : contract?.status === StatusEnum.CLOSED
                    ? reOpenIcon
                    : ""
                }
                className="delete-btn"
                onClick={(event) => {
                  event.stopPropagation();
                  setContractId(contract?.id?.toString() ?? "0");
                  confirm({
                    className: "end-contract-modal",
                    content:
                      contract?.status === StatusEnum.CLOSED
                        ? `Do you wish to open this ${contract?.name} contract?`
                        : `Are you sure you want to end this ${contract?.name} contract?`,
                    okText: "Yes",
                    cancelText: "No",
                    onOk: () => {
                      closeContract(
                        contract?.status === StatusEnum.CLOSED
                          ? "active"
                          : "closed",
                        false,
                        contractId,
                        contract?.status
                      );
                    },
                  });
                }}
              />
            )}
              {isContractEditable(contract) && <i
                className="icon-edit resource-edit-icon ml-2"
                onClick={(event) => {
                  event.stopPropagation();
                  history.push(
                    generatePath(AppRoutes.EDIT_CONTRACT, { contractId })
                  );
                }}
              />}
          </div>
        </Fragment>
      ),
    },
  ];
  const handleTableChange = (pagination: TablePaginationConfig) => {
    const prevFilter = getParams();
    const updatedFilters = {
      ...prevFilter,
      page: pagination.current,
    };

    setParams(updatedFilters);
    fetchContracts(updatedFilters);
    // fetchContracts(pagination?.current ?? 1);
  };

  return (
    <div className="list-contract">
      <Table
        dataSource={contracts}
        columns={contractsColumns}
        onRow={handleRowClick}
        onChange={handleTableChange}
        pagination={{
          total: contractPagination?.totalCount,
          current: contractPagination?.currentPage,
          showTotal: (total: number, range: [number, number]) => (
            <p>
              Showing <b>{` ${range[0]} - ${range[1]}`}</b> of{" "}
              <b>{`${total.toLocaleString()}`}</b>
            </p>
          ),
        }}
      />
    </div>
  );
};

export default AuthContainer(
  CorporateContainer(ContractContainer(ListContract))
);
