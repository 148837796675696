import { Button, Col, Collapse, Divider, Popover, Row } from "antd";
import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { Appointment } from "../../../../models/Appointment/appointment.model";
import { ContractVisit } from "../../../../models/ContractVisit/contractVisit.model";
import { XRayImage } from "../../../../models/XRayImage/xRayImage.model";
import { PostVisitService } from "../../../../services/PostVisit/postVisit.service";
import { PreVisitService } from "../../../../services/PreVisit/previsit.service";
import InteractionContainer from "../../../../store/container/InteractionContainer";
import { InteractionReducerProps } from "../../../../store/reducers/interactionReducer";
import StatementNotesForm from "../../StatementNotes/StatementNotesForm";
import StatementNotesIcon from "../../../../assets/icons/Statement.png";
import CommentNotesIcon from "../../../../assets/icons/Comment.png";
import XRayNoteIcon from "../../../../assets/icons/XRayNote.png";
import XRayImageIcon from "../../../../assets/icons/xRayImage.png";
import "./appointmentDetail.scss";
import { handleRemoveLink } from "../../../../shared/utils/babylonUtils";
import CommentNotesForm from "../../CommentNotes/CommentNotesForm";
import { InteractionStep } from "../../../../models/InteractionStep/interactionStep.model";
import { Modal } from "antd";
import InteractionStepService from "../../../../services/InteractionStep/interactionStep.service";
import BabylonContainer from "../../../../store/container/BabylonContainer";
import { BabylonReducerProps } from "../../../../store/reducers/babylonReducer";
import { Interaction } from "../../../../models/Interaction/interaction.model";
import { InteractionService } from "../../../../services/Interaction/Interaction.service";
import Note from "../../../../shared/components/Note";
import StatementNotes from "../../StatementNotes";
import CommentNotes from "../../CommentNotes";
import XRayNotes from "../../XRayNotes";
import ObjectNotes from "../../ObjectNotes";
import { CaretRightOutlined, DeleteOutlined } from "@ant-design/icons";
import { ProcedureStep } from "../../../../models/ProcedureStep/procedureStep.model";
import useResponsibilities from "../../../../shared/hooks/Responsibilities/useResponsibilities";
import { ResponsibilityTypes } from "../../../../enums/responsebily.enum";

const { Panel } = Collapse;

export type XRayType = "notes" | "images";

interface InteractionDetailParams {
  patientProfileId: string;
  interactionId: string;
}

interface AppointmentDetailProps
  extends ContractVisit,
    InteractionReducerProps,
    BabylonReducerProps {
  currentInteractionId: number;
  updateXrayNote?: boolean;
  updateXrayImage?: boolean;
  updateStatementNotes: number;
  updateCommentNotes: number;
}

const AppointmentDetail: FC<AppointmentDetailProps> = (props) => {
  const {
    id,
    visitNo,
    contractInteractions,
    setContractInteractions,
    activeInteractionId,
    currentInteractionId,
    scene,
    updateXrayNote,
    updateXrayImage,
    updateStatementNotes,
    updateCommentNotes,
    setMeshLoading,
  } = props;

  const params: InteractionDetailParams = useParams();

  const { hasAccess } = useResponsibilities();

  // const [updateStatementNotes, setUpdateStateNotes] = useState(0);

  // const [updateCommentNotes, setUpdateCommentNotes] = useState(0);

  const [preVisitContent, setPreVisitContent] = useState(false);

  const [postVisitContent, setPostVisitContent] = useState(false);

  const fileRef = useRef<HTMLInputElement>(null);

  const HAS_INTERACTION_EDIT_ACCESS = hasAccess(
    ResponsibilityTypes.INTERACTION_EDIT
  );

  const filteredInteractions = contractInteractions.filter(
    (interaction) =>
      Number(interaction?.id) <= Number(currentInteractionId)
  );

  const removeStep = async (step: InteractionStep | ProcedureStep) => {
    if (step?.set) {
      step?.set?.setSteps?.forEach(async (setStep) => {
        await handleRemoveLink(setStep, scene);
      });
    } else {
      await handleRemoveLink(step, scene);
    }
  };

  const handleDeleteInteractionStep = (
    interactionStep: InteractionStep
  ) => () => {
    Modal.confirm({
      title: `Are you sure you want to remove this step from the interaction?`,
      onOk: () => {
        if (currentInteractionId && interactionStep?.id) {
          setMeshLoading(true);
          InteractionStepService.deleteInteractionStep(
            currentInteractionId,
            interactionStep.id,
            async () => {
              const interactionIndex = contractInteractions.findIndex(
                (interaction) => interaction.id === currentInteractionId
              );
              if (interactionIndex >= 0) {
                const interactionStepIndex = contractInteractions[
                  interactionIndex
                ].interactionSteps.findIndex(
                  (step) => step.id === interactionStep.id
                );
                if (interactionStepIndex >= 0) {
                  contractInteractions[
                    interactionIndex
                  ].interactionSteps?.splice(interactionStepIndex, 1);
                  setContractInteractions([...contractInteractions]);
                  if (scene) {
                    if (interactionStep?.procedure) {
                      interactionStep.procedure?.procedureSteps?.forEach(
                        async (procedureStep) => {
                          await removeStep(procedureStep);
                        }
                      );
                    } else {
                      await removeStep(interactionStep);
                    }
                  }
                }
              }
            },
            () => {},
            () => {
              setMeshLoading(false);
            }
          );
        }
      },
    });
  };

  useEffect(() => {
    if (preVisitContent) {
      setPostVisitContent(false);
    }
  }, [preVisitContent]);

  useEffect(() => {
    if (postVisitContent) {
      setPreVisitContent(false);
    }
  }, [postVisitContent]);


  const renderNotesSet = (
    interactionStep: InteractionStep | ProcedureStep,
    updateInteractionStep: InteractionStep,
    id: number
  ) => {
    return interactionStep?.set ? (
      <Collapse ghost>
        <Panel
          header={
            <Row align="middle" className="interaction-detail__set-header-row">
              <Col span={22}>{interactionStep?.set?.name}</Col>
              {HAS_INTERACTION_EDIT_ACCESS && <Col span={2}>
                {!(interactionStep instanceof ProcedureStep) &&
                  activeInteractionId === id && (
                    <DeleteOutlined
                      onClick={handleDeleteInteractionStep(
                        updateInteractionStep
                      )}
                    />
                  )}
              </Col>}
            </Row>
          }
          key={interactionStep?.id ?? 0}
        >
          {interactionStep?.set?.setSteps?.map((setStep, j) => (
            <Row className="interaction__set-step-item">
              <Col span={24}>
                {j + 1}. {setStep?.note || "NA"}
              </Col>
              {interactionStep?.set?.setSteps?.length !== j + 1 && <Divider />}
            </Row>
          ))}
        </Panel>
      </Collapse>
    ) : (
      <Row className="interaction-detail__link-item" align="middle">
        <Col span={22}>{interactionStep?.note}</Col>
        {HAS_INTERACTION_EDIT_ACCESS && <Col span={2}>
          {!(interactionStep instanceof ProcedureStep) &&
            activeInteractionId === id && (
              <DeleteOutlined
                onClick={handleDeleteInteractionStep(updateInteractionStep)}
              />
            )}
        </Col>}
      </Row>
    );
  };

  return (
    <div className="appointment-detail">
      <Collapse ghost>
        <Panel header="Notes" key="notes">
          <div className="interaction-detail__all-notes">
            {filteredInteractions.map((interaction) => {
              return interaction.interactionSteps.map((interactionStep, i) =>
                interactionStep.procedure ? (
                  <Collapse ghost>
                    <Panel
                      header={
                        <Row
                          align="middle"
                          className="interaction-detail__set-header-row"
                        >
                          <Col span={22}>
                            {interactionStep?.procedure?.name ?? ""}
                          </Col>
                          {HAS_INTERACTION_EDIT_ACCESS && <Col span={2}>
                            {activeInteractionId === interaction.id && (
                              <DeleteOutlined
                                onClick={handleDeleteInteractionStep(
                                  interactionStep
                                )}
                              />
                            )}
                          </Col>}
                        </Row>
                      }
                      key={interactionStep?.id ?? 0}
                    >
                      {interactionStep.procedure.procedureSteps.map(
                        (procedureStep) =>
                          renderNotesSet(
                            procedureStep,
                            interactionStep,
                            interaction?.id as number
                          )
                      )}
                    </Panel>
                  </Collapse>
                ) : (
                  renderNotesSet(
                    interactionStep,
                    interactionStep,
                    interaction?.id as number
                  )
                )
              );
            })}
          </div>
        </Panel>
        <Panel
          className="statement-notes"
          header={"Statement Notes"}
          key="statement-notes"
        >
          <StatementNotes reload={updateStatementNotes} interactionId={id} />
        </Panel>
        <Panel
          className="comment-notes"
          header={"Comment Notes"}
          key="comment-notes"
        >
          <CommentNotes reload={updateCommentNotes} interactionId={id}/>
        </Panel>
        <Panel
          className="x-ray-notes"
          header={"X-ray Notes & Images"}
          key="x-ray-notes"
        >
          <XRayNotes
            visitNo={visitNo}
            interactionId={id ? id?.toString() : "0"}
            updateXrayNote={updateXrayNote}
            updateXrayImage={updateXrayImage}
          />
        </Panel>
        <Panel header={"Object Notes"} key="object-notes">
          <ObjectNotes interactionId={id} />
        </Panel>
      </Collapse>
    </div>
  );
};

export default InteractionContainer(BabylonContainer(AppointmentDetail));
