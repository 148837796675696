import React, { useState } from "react";
import { Button, Checkbox, Col, Row } from "antd";
import InputField from "../../../shared/components/InputField";
import DropdownField from "../../../shared/components/DropdownField";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { FieldArray, useField, useFormikContext } from "formik";
import { CustomField } from "../../../models/CustomField/customField.model";
import { CustomOption } from "../../../models/CustomOption/customOption.model";
import { CustomFormInputEnum } from "../../../enums/customFormInput.enum";
import { CustomForm } from "../../../models/CustomForm/customForm.model";

interface CustomFieldsWrapperProps {
  name: string;
}

function CustomFieldsWrapper({ name }: CustomFieldsWrapperProps) {
  const { setFieldValue } = useFormikContext();

  const [{ value }] = useField<CustomForm["customFields"]>(name);

  const [fieldTypeOptions] = useState([
    { label: "Textbox", value: CustomFormInputEnum.TEXTBOX },
    { label: "Textarea", value: CustomFormInputEnum.TEXTAREA },
    { label: "Boolean", value: CustomFormInputEnum.BOOLEAN },
    { label: "Single select", value: CustomFormInputEnum.SINGLE_SELECT },
    { label: "Multi select", value: CustomFormInputEnum.MULTI_SELECT },
    { label: "Signature", value: CustomFormInputEnum.SIGNATURE },
  ]);

  const showCustomOptions = (type?: string) => {
    return (
      type === CustomFormInputEnum.BOOLEAN ||
      type === CustomFormInputEnum.SINGLE_SELECT ||
      type === CustomFormInputEnum.MULTI_SELECT
    );
  };

  return (
    <FieldArray
      name={name}
      render={({ push, remove }) => (
        <div className="custom-form-wrapper__fields">
          {value?.map((_, fieldIndex) => (
            <Row key={fieldIndex} className="custom-form-wrapper__field-item">
              <Col span={20} offset={1}>
                <Row gutter={[20, 0]}>
                  <Col span={12}>
                    <InputField
                      title="Field name"
                      type="text"
                      name={`${name}[${fieldIndex}].name`}
                      placeholder="Enter Field name"
                    />
                  </Col>
                  <Col span={12}>
                    <DropdownField
                      title="Type"
                      name={`${name}[${fieldIndex}].type`}
                      options={fieldTypeOptions}
                      value={value?.[fieldIndex]?.type}
                      setFieldValue={setFieldValue}
                      placeHolder="Select Type"
                    />
                  </Col>
                  <Col span={24}>
                    <Checkbox
                      checked={!!value?.[fieldIndex]?.isMandatory}
                      onChange={(e) =>
                        setFieldValue(
                          `${name}[${fieldIndex}].isMandatory`,
                          e.target.checked
                        )
                      }
                    >
                      <span className="text-secondary">
                        Is the field Mandatory?
                      </span>
                    </Checkbox>
                  </Col>
                  {showCustomOptions(value?.[fieldIndex]?.type) && (
                    <FieldArray
                      name={`${name}[${fieldIndex}].customOptions`}
                      render={(arrayHelpers) => (
                        <div className="custom-form-wrapper__options">
                          {value?.[fieldIndex]?.customOptions?.map(
                            (_, optionIndex) => {
                              return (
                                <Row
                                  gutter={[20, 0]}
                                  align="middle"
                                  key={optionIndex}
                                  className="custom-form-wrapper__option-item"
                                >
                                  <Col span={21}>
                                    <InputField
                                      title="Option name"
                                      type="text"
                                      name={`${name}[${fieldIndex}].customOptions[${optionIndex}].name`}
                                      placeholder="Enter Option name"
                                    />
                                  </Col>
                                  <Col span={3} className="text-right">
                                    <Button
                                      onClick={() =>
                                        arrayHelpers.remove(optionIndex)
                                      }
                                      className="delete-btn"
                                      icon={<DeleteOutlined />}
                                    />
                                  </Col>
                                </Row>
                              );
                            }
                          )}
                          {!(
                            value?.[fieldIndex].type ===
                              CustomFormInputEnum.BOOLEAN &&
                            Number(
                              value?.[fieldIndex]?.customOptions?.length
                            ) >= 2
                          ) && (
                            <div>
                              <Button
                                type="link"
                                className="text-secondary"
                                htmlType="button"
                                onClick={() =>
                                  arrayHelpers?.push(new CustomOption())
                                }
                              >
                                <PlusOutlined /> Add Option
                              </Button>
                            </div>
                          )}
                        </div>
                      )}
                    />
                  )}
                </Row>
              </Col>
              <Col span={3} className="text-right">
                <Button
                  onClick={() => remove(fieldIndex)}
                  className="delete-btn"
                  icon={<DeleteOutlined />}
                />
              </Col>
            </Row>
          ))}
          <div>
            <Button
              className="secondary-btn"
              htmlType="button"
              onClick={() => push(new CustomField())}
            >
              <PlusOutlined /> Add Field
            </Button>
          </div>
        </div>
      )}
    />
  );
}

export default CustomFieldsWrapper;
