import React, { useState } from "react";
import { Button, Col, Input, Row, Tooltip } from "antd";
import SetForm from "../SetForm";
import { DentalSet } from "../../../../../models/DentalSet/dentalSet.model";
import { FormActionEnum } from "../../../../../enums/formAction.enum";
import { EditOutlined, CopyOutlined } from "@ant-design/icons";
import SetContainer from "../../../../../store/container/SetContainer";
import { SetReducerProps } from "../../../../../store/reducers/setReducer";
import useResponsibilities from "../../../../../shared/hooks/Responsibilities/useResponsibilities";
import { ResponsibilityTypes } from "../../../../../enums/responsebily.enum";

interface AlignerActionsProps extends SetReducerProps {
  onUpdateSuccess: (dentalSet: DentalSet) => void;
  onCloneSuccess: (dentalSet: DentalSet) => void;
}

function AlignerActions({
  currentSet,
  setCurrentSet,
  onUpdateSuccess,
  onCloneSuccess,
}: AlignerActionsProps) {
  const { hasAccess } = useResponsibilities();

  const [activeAction, setActiveAction] = useState<
    FormActionEnum | undefined
  >();

  const HAS_SET_CREATE_ACCESS = hasAccess(ResponsibilityTypes.SET_CREATE);

  const HAS_SET_EDIT_ACCESS = hasAccess(ResponsibilityTypes.SET_EDIT);

  const handleCancel = () => setActiveAction(undefined);

  return (
    <div className="aligner-actions">
      <h5 className="text-primary">Aligner Set</h5>
      {!currentSet ? null : activeAction === FormActionEnum.EDIT ? (
        <SetForm
          set={currentSet}
          onSuccess={onUpdateSuccess}
          onCancel={handleCancel}
        />
      ) : activeAction === FormActionEnum.CLONE ? (
        <SetForm
          set={currentSet}
          action={activeAction}
          onSuccess={onCloneSuccess}
          onCancel={handleCancel}
        />
      ) : (
        <Row justify="space-between" align="middle">
          <Col flex="1">
            <Input value={currentSet?.name} readOnly width="100%" />
          </Col>
          {HAS_SET_EDIT_ACCESS && <Col span={3}>
            <Tooltip placement="topRight" title="Edit Set">
              <Button
                icon={<EditOutlined />}
                onClick={() => setActiveAction(FormActionEnum.EDIT)}
              />
            </Tooltip>
          </Col>}
          {HAS_SET_CREATE_ACCESS && <Col span={3}>
            <Tooltip placement="topRight" title="Clone Set">
              <Button
                icon={<CopyOutlined />}
                onClick={() => setActiveAction(FormActionEnum.CLONE)}
              />
            </Tooltip>
          </Col>}
        </Row>
      )}
    </div>
  );
}

export default SetContainer(AlignerActions);
