import ApiRoutes from "../../routes/routeConstants/apiRoutes";
import axiosInstance from "../../interceptor/axiosInstance";
import {
  Contract,
  Contractmetrics,
} from "../../models/Contract/contract.model";
import { serialize, deserialize } from "serializr";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import { convertJSONToFormData } from "../../shared/utils/dataFormatConverter";
import { PaymentMethod } from "../../models/PaymentMethod/paymentMethod.model";
import { ContractSubscription } from "../../models/ContractSubscription/contractSubscription.model";
import { generatePath } from "react-router-dom";
import { StatusEnum } from "../../enums/status.enum";
import { PaymentSchedule } from "../../models/PaymentSchedule/paymentSchedule.model";
import { InsuranceProvider } from "../../models/InsuranceProvider/insuranceProvider.model";
import { PaginationDetails } from "../../models/Pagination/pagination.model";
import { FilterParams } from "../../models/FilterParams/filterParams.model";
import { Metrics } from "../../models/Metrics/metrics.model";
import { ContractTermTemplate } from "models/ContractTermsTemplate/contractTermsTemplate.model";

export default class ContractService {
  static fetchContracts(
    filter: FilterParams,
    onSuccess: (
      contracts: Contract[],
      metrics: Contractmetrics,
      pagination: PaginationDetails
    ) => void,
    onError: Function,
    onFinal: () => void,
    params?: object
  ) {
    const param = serialize(FilterParams, filter);
    axiosInstance
      .get(ApiRoutes.CONTRACTS, { params: param })
      .then((response) => {
        const contracts = deserialize(
          Contract,
          response.data["contracts"] as any[]
        ) as Contract[];
        const contractPagination = deserialize(
          PaginationDetails,
          response.data["meta"]
        );
        const metrics = deserialize(Contractmetrics, response.data["metrics"]);
        onSuccess(contracts, metrics, contractPagination);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static fetchPatientContracts(
    patientId: number,
    onSuccess: (contracts: Contract[], pagination?: PaginationDetails) => void,
    onError: Function,
    onFinal: () => void,
    filters?: FilterParams
  ) {
    const API_URL = ApiRoutes.PATIENT_CONTRACTS.replace(
      ":patientId",
      patientId.toString()
    );
    axiosInstance
      .get(API_URL, {
        params: filters ? serialize(FilterParams, filters) : undefined,
      })
      .then((response) => {
        const contracts = deserialize(
          Contract,
          response.data["contracts"] as any[]
        ) as Contract[];
        const pagination = deserialize(
          PaginationDetails,
          response.data["meta"]
        );
        onSuccess(contracts, pagination);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static fetchContractMetrics(
    metricsType: string,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .get(ApiRoutes.METRICS_PRAC.replace(":metricsType", metricsType))
      .then((response) => {
        const metrics = deserialize(Metrics, response.data["metrics"]);
        onSuccess(metrics);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static showContract(
    contractId: number,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.CONTRACTS + "/" + contractId;
    axiosInstance
      .get(API_URL)
      .then((response) => {
        const contract = deserialize(Contract, response.data["contract"]);
        onSuccess(contract);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static createContract(
    contract: Contract,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const contractJSON = { contract: serialize(contract) };
    const formData = convertJSONToFormData(contractJSON);
    if (contract.patientSignature)
      formData.append(
        "contract.patient_signature",
        contract.patientSignature as File
      );
    axiosInstance
      .post(ApiRoutes.CONTRACTS, formData)
      .then((response) => {
        const contract = deserialize(Contract, response.data["contract"]);
        onSuccess(contract);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static updateContract(
    contract: Contract,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.CONTRACTS + "/" + contract.id;
    const contractJSON = { contract: serialize(Contract, contract) };
    const formData = convertJSONToFormData(contractJSON);
    if (contract.patientSignature)
      formData.append(
        "contract.patient_signature",
        contract.patientSignature as File
      );
    axiosInstance
      .put(API_URL, formData)
      .then((response) => {
        const contract = deserialize(Contract, response.data["contract"]);
        onSuccess(contract);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }
  /* Payment Schedule */
  static addContractInsuranceProvider(
    contractId: number,
    patientProfileId: number,
    insuranceProvider: InsuranceProvider,

    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const insuranceJSON = serialize(InsuranceProvider, insuranceProvider);
    const API_URL = ApiRoutes.ADD_INSURANCE_PROVIDER.replace(
      ":patientProfileId",
      patientProfileId.toString()
    ).replace(":contractId", contractId.toString());

    axiosInstance
      .post(API_URL, {
        insurance_provider: { ...insuranceJSON },
      })
      .then((response) => {
        const insuranceProvider = deserialize(
          InsuranceProvider,
          response.data["insurance_provider"]
        );
        onSuccess(insuranceProvider);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }
  static updateContractInsuranceProvider(
    contractId: number,
    patientProfileId: number,
    insuranceProvider: InsuranceProvider,
    id: number,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const insuranceJSON = serialize(InsuranceProvider, insuranceProvider);
    const API_URL = ApiRoutes.UPDATE_INSURANCE_PROVIDER.replace(
      ":patientProfileId",
      patientProfileId.toString()
    )
      .replace(":contractId", contractId.toString())
      .replace(":id", id.toString());

    axiosInstance
      .put(API_URL, {
        insurance_provider: insuranceJSON,
      })
      .then((response) => {
        const insuranceProvider = deserialize(
          InsuranceProvider,
          response.data["insurance_providers"]
        );
        onSuccess(insuranceProvider);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static deleteContractInsuranceProvider(
    contractId: number,
    patientProfileId: number,
    id: number,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.DELETE_INSURANCE_PROVIDER.replace(
      ":patientProfileId",
      patientProfileId.toString()
    )
      .replace(":contractId", contractId.toString())
      .replace(":id", id.toString());
    axiosInstance
      .delete(API_URL)
      .then((response) => {
        const insuranceProvider = deserialize(
          InsuranceProvider,
          response.data["insurance_provider"]
        );
        onSuccess(insuranceProvider);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static getPaymentScheduleList(
    contractId: number,
    onSuccess: (services: PaymentSchedule[]) => void,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.CONTRACT_PAYMENT_SCHEDULE.replace(
      ":id",
      contractId.toString()
    );

    axiosInstance
      .get(API_URL)
      .then((response) => {
        const data = deserialize(
          PaymentSchedule,
          response.data["payment_schedules"] as any[]
        );

        // response?.data?.payment_schedules as PaymentSchedule[]

        onSuccess(data);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static updateContractPaymentSchedule(
    paymentSchedule: PaymentSchedule,
    contractId: number,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const contractJSON = {
      payment_schedule: serialize(PaymentSchedule, paymentSchedule),
    };

    const API_URL = ApiRoutes.CONTRACT_PAYMENT_SCHEDULE.replace(
      ":id",
      contractId.toString()
    );

    axiosInstance
      .put(API_URL, contractJSON)
      .then((response) => {
        onSuccess();
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static deleteContract(
    contractId: number,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.CONTRACTS + "/" + contractId;
    axiosInstance
      .delete(API_URL)
      .then((response) => {
        onSuccess();
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static fetchInsuranceProvidersList(
    onSuccess: (list: InsuranceProvider[]) => void,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .get(ApiRoutes.INSURANCE_PROVIDER_LIST)
      .then((response) => {
        const data = deserialize(
          InsuranceProvider,
          response?.data["insurance_providers"] as any[]
        );

        onSuccess(data);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }
  static fetchPatientInsuranceProvidersList(
    contractId: number,
    patientProfileId: number,
    onSuccess: (list: InsuranceProvider[]) => void,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .get(
        ApiRoutes.PATIENT_INSURANCE_PROVIDER_LIST.replace(
          ":contractId",
          contractId.toString()
        ).replace(":patientProfileId", patientProfileId.toString())
      )
      .then((response) => {
        const data = deserialize(
          InsuranceProvider,
          response?.data["insurance_providers"] as any[]
        );

        onSuccess(data);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static async requestPatientToSign(contract: Contract) {
    try {
      const API_URL =
        ApiRoutes.CONTRACTS + "/" + contract?.id + "/request_patient_sign";
      const contractJSON = { contract: serialize(contract) };
      const formData = convertJSONToFormData(contractJSON);
      formData.append(
        "contract.patient_signature",
        contract.patientSignature as File
      );
      axiosInstance.put(API_URL, formData);
      return true;
    } catch (error) {}
  }

  static getPaymentMethods(
    patientId: string,
    onSuccess: (paymentMethods: PaymentMethod[]) => void,
    onError: () => void,
    onFinal: () => void
  ) {
    return axiosInstance
      .get(
        ApiRoutes.GET_PATIENT_PAYMENT_METHODS.replace(":patientId", patientId)
      )
      .then((response) => {
        const paymentMethods = deserialize(
          PaymentMethod,
          response?.data?.payment_methods as PaymentMethod[]
        );
        onSuccess(paymentMethods);
      })
      .catch((error) => {
        onError();
      })
      .finally(onFinal);
  }

  static getPaymentMethods1(
    contractId: string,
    onSuccess: Function,
    onError: () => void,
    onFinal: () => void
  ) {
    return axiosInstance
      .get(ApiRoutes.CONTRACT_PAYMENT_SCHEDULE.replace(":id", contractId))
      .then((response) => {
        const payment = deserialize(
          PaymentSchedule,
          response?.data["payment_schedules"] as any[]
        );

        onSuccess(payment);
      })
      .catch((error) => {
        onError();
      })
      .finally(onFinal);
  }

  static getContractSubscription(
    practiceId: number,
    contractId: number,
    onSuccess: (contractSubscription: ContractSubscription) => void,
    onError: () => void,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.CONTRACT_SUBSCRIPTION?.replace(
      ":practiceId",
      practiceId.toString()
    ).replace(":contractId", contractId?.toString());

    return axiosInstance
      .get(API_URL)
      .then((response) => {
        const contractSubscription = deserialize(
          ContractSubscription,
          response?.data?.contract_subscription
        );
        onSuccess(contractSubscription);
      })
      .catch((error) => {
        onError();
      })
      .finally(onFinal);
  }

  static createContractSubscription(
    practiceId: number,
    contractId: number,
    contractSubscription: ContractSubscription,
    onSuccess: (contractSubscription: ContractSubscription) => void,
    onError: () => void,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.CONTRACT_SUBSCRIPTION?.replace(
      ":practiceId",
      practiceId.toString()
    ).replace(":contractId", contractId?.toString());

    const contractSubscriptionJSON = {
      contract_subscription: serialize(contractSubscription),
    };

    return axiosInstance
      .post(API_URL, contractSubscriptionJSON)
      .then((response) => {
        const contractSubscription = deserialize(
          ContractSubscription,
          response?.data?.contract_subscription
        );
        onSuccess(contractSubscription);
      })
      .catch((error) => {
        onError();
      })
      .finally(onFinal);
  }

  static updateContractSubscription(
    practiceId: number,
    contractId: number,
    contractSubscription: ContractSubscription,
    onSuccess: (contractSubscription: ContractSubscription) => void,
    onError: () => void,
    onFinal: () => void
  ) {
    const API_URL =
      ApiRoutes.CONTRACT_SUBSCRIPTION?.replace(
        ":practiceId",
        practiceId.toString()
      ).replace(":contractId", contractId?.toString()) +
      "/" +
      contractSubscription?.id;

    const contractSubscriptionJSON = {
      contract_subscription: serialize(contractSubscription),
    };

    return axiosInstance
      .put(API_URL, contractSubscriptionJSON)
      .then((response) => {
        const contractSubscription = deserialize(
          ContractSubscription,
          response?.data?.contract_subscription
        );
        onSuccess(contractSubscription);
      })
      .catch((error) => {
        onError();
      })
      .finally(onFinal);
  }
  static closeContract(
    practiceId: number,
    contractId: string,
    params: { status: String; is_force_close: Boolean },
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.CONTRACT_CLOSE.replace(
      ":practiceId",
      practiceId.toString()
    ).replace(":id", contractId);
    axiosInstance
      .put(API_URL, params)
      .then((response) => {
        const contract = deserialize(Contract, response.data["contract"]);
        if (response?.data?.success === false) onError();
        else {
          Notification({
            message: "Contract Successfully Updated!",
            type: NotificationTypes.SUCCESS,
          });
          onSuccess(contract);
        }
      })

      .catch((error) => {
        if (error?.prompt_force_close) onError();
      })
      .finally(() => {
        onFinal();
      });
  }
  static completeContractService(
    corporateId: number,
    contractId: number,
    contractServiceid: number,
    params: { status: String; is_force_close: Boolean },
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.CONTRACT_CLOSE_SERVICE.replace(
      ":corporateId",
      corporateId.toString()
    )
      .replace(":contractId", contractId.toString())
      .replace(":contractServiceId", contractServiceid.toString());

    axiosInstance
      .put(API_URL, params)
      .then((response) => {
        const contract = deserialize(Contract, response.data["contract"]);
        if (response?.data?.success === false) onError();
        else {
          Notification({
            message: "Contract Service Updated! ",
            type: NotificationTypes.SUCCESS,
          });
          onSuccess(contract);
        }
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static downloadContractTerm = async (
    contract: Contract,
    contractTerms: ContractTermTemplate[],
    onSuccess: (contractUrl: string) => void,
    onError?: () => void,
    onFinal?: () => void
  ) => {
    try {
      const contractTermIds = contractTerms?.map(({ id }) => id);

      const ENDPOINT = ApiRoutes.DOWNLOAD_CONTRACT_TERM.replaceAll(
        ":contractId",
        contract?.id?.toString()!
      );

      const { data } = await axiosInstance.post(ENDPOINT, {
        contract_pdf_request: { contract_term_ids: contractTermIds },
      });

      onSuccess(data["contract_pdf_url"]);
    } catch (ex) {
      onError?.();
    } finally {
      onFinal?.();
    }
  };

  static downloadInitialClaimForm(
    contractId: string,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .get(
        ApiRoutes.CONTRACT_INSURANCE_CLAIM_FORM.replace(
          ":contractId",
          contractId
        )
      )
      .then((response) => {
        const url = response?.data?.insurance_claim_forms_url;
        Notification({
          message: "Insurance Claim Form Generated",
          type: NotificationTypes.SUCCESS,
        });
        onSuccess(url);
      })
      .catch((error) => {
        Notification({
          message: "Unable to Generate Insurance Claim Form",
          description: (error as Error)?.message,
          type: NotificationTypes.ERROR,
        });
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }
}
