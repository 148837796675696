
import { alias, primitive, serializable, list, object } from "serializr";
import { Interaction } from "../Interaction/interaction.model";

export class WorkItem {
  @serializable(alias("id", primitive()))
  id?: string;

  @serializable(alias("type", primitive()))
  type?: string;

  @serializable(alias("description", primitive()))
  description?: string;

  @serializable(alias("is_emergency", primitive()))
  isEmergency?: boolean;

  @serializable(alias("visit_count", primitive()))
  visitCount?: string;

  @serializable(alias("start_time", primitive()))
  startTime?: string;

  @serializable(alias("end_time", primitive()))
  endTime?: string;

  @serializable(alias("date", primitive()))
  date?: string;

  @serializable(alias("assignable_id", primitive()))
  assignableId?: string;

  @serializable(alias("assignable_type", primitive()))
  assignableType?: string;

  @serializable(alias("assigned_user_id", primitive()))
  assignedUserId?: string;

  @serializable(alias("assinged_user_type", primitive()))
  assingedUserType?: string;

  @serializable(alias("assignment_status", primitive()))
  assignmentStatus?: string;

  @serializable(alias("status", primitive()))
  status?: string;

  @serializable(alias("reject_reason", primitive()))
  rejectReason?: string;

  @serializable(alias("patient_first_name", primitive()))
  patientFirstName?: string;

  @serializable(alias("patient_last_name", primitive()))
  patientLastName?: string;

  @serializable(alias("patient_profile_id", primitive()))
  patientProfileId?: number;

  @serializable(alias("patient_profile_pic_url", primitive()))
  patientProfilePicUrl?: string;

  @serializable(alias("interaction", object(Interaction)))
  interaction?: Interaction;
  
}

export class WorkItems  {
    @serializable(alias("work_items", list(object(WorkItem))))
    workItems: WorkItem[] = [];
}

export class WorkItemsFilters {
  @serializable(alias("from_date", primitive()))
  startDate?: string;

  @serializable(alias("to_date", primitive()))
  endDate?: string;
}