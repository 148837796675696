import { alias, custom, primitive, serializable } from "serializr";
import { PatientCategory } from "../../enums/patientCategory.enum";
import { serializePatientCategory } from "../../shared/utils/patientCategories";

export class TreatmentPlan {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("name", primitive()))
  name?: string;

  @serializable(alias("no_of_bouts", primitive()))
  noOfBouts?: number;

  @serializable(alias("no_of_appointments", primitive()))
  noOfAppointments?: number;

  @serializable(alias("description", primitive()))
  description?: string;

  @serializable(alias("duration_in_months", primitive()))
  durationInMonths?: string;

  @serializable(
    alias(
      "patient_category",
      custom(serializePatientCategory, serializePatientCategory)
    )
  )
  patientCategory?= PatientCategory.ADULT;
}
