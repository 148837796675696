import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";
import * as InteractionActions from "../actions/interactionActions";
import { RootReducerProps } from "../reducers";

const mapStateToProps = ({ interaction }: RootReducerProps, ownProps: any) => {
  const {
    contractInteractions,
    activeInteractionId,
    objectNotes,
    expandedInteractionId
  } = interaction;
  return {
    contractInteractions,
    activeInteractionId,
    objectNotes,
    expandedInteractionId,
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(InteractionActions, dispatch);

const InteractionContainer = (component: any) => {
  return connect(mapStateToProps, mapDispatchToProps)(component);
};

export default InteractionContainer;
