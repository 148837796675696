import { alias, primitive, serializable } from "serializr";

export class AppointmentTypeCategory {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("id", primitive()))
  value?: number;

  @serializable(alias("name", primitive()))
  label?: string;

  @serializable(alias("name", primitive()))
  name?: string;
}
