import React from "react";
import { CustomFormResponse } from "../../../models/CustomFormResponse/customFormResponse.model";
import "./customFormResponseDetail.scss";
import { Row, Col, Button } from "antd";
import { CloseOutlined } from "@ant-design/icons";

interface CustomFormResponseDetailProps {
  customFormResponse: CustomFormResponse;
  onCancel?: () => void;
}

function CustomFormResponseDetail({
  customFormResponse,
  onCancel,
}: CustomFormResponseDetailProps) {
  return (
    <div className="custom-form-response-detail">
      <div className="custom-form-response-detail__header">
        <Row align="middle">
          <Col span={22}>
            <h4>{customFormResponse?.customFormName}</h4>
          </Col>
          <Col span={2} className="text-right">
            {onCancel && <Button
              onClick={onCancel}
              icon={<CloseOutlined />}
              className="custom-form-response-detail__close-btn"
            />}
          </Col>
        </Row>
      </div>
      <div className="custom-form-response-detail__body">
        <div className="custom-form-response-detail__form-overview">
          <Row gutter={[20, 20]}>
            <Col span={8}>
              <div className="custom-form-response-detail__label">
                Associated Type
              </div>
              <div className="custom-form-response-detail__value text-capitalize">
                {customFormResponse?.customFormType?.split("_").join(" ")}
              </div>
            </Col>
            <Col span={8}>
              <div className="custom-form-response-detail__label">
                Form filled by
              </div>
              <div className="custom-form-response-detail__value">
                {customFormResponse?.customFormRequestCreatedByName}
              </div>
            </Col>
            <Col span={24}>
              <div className="custom-form-response-detail__label">
                Description
              </div>
              <div className="custom-form-response-detail__value">
                {customFormResponse?.customFormDescription}
              </div>
            </Col>
          </Row>
        </div>
        <div className="custom-form-response-detail__form-response">
          {customFormResponse?.customResponseFields?.map(
            (customFormResponseField, i) => (
              <div className="custom-form-response-detail__form-item" key={i}>
                <div className="custom-form-response-detail__label">
                  {i + 1}. {customFormResponseField?.customFieldName}
                </div>
                <div className="custom-form-response-detail__value">
                  {customFormResponseField?.customResponses?.map(
                    (customResponse, i) => (
                      <div
                        className="custom-form-response-detail__response-item"
                        key={i}
                      >
                        {customResponse?.signatureUrl ? (
                          <img
                            className="custom-form-response-detail__response-item__sign"
                            height={150}
                            src={customResponse?.signatureUrl}
                            alt="Signature"
                          />
                        ) : (
                          customResponse?.customOptionName ||
                          customResponse?.responseText
                        )}
                      </div>
                    )
                  )}
                </div>
              </div>
            )
          )}
        </div>
        {customFormResponse?.groupSections()?.map((section) => (
          <div className="custom-form-response-detail__section">
            <div className="custom-form-response-detail__section__title">
              {section?.name}
            </div>
            <div className="custom-form-response-detail__form-item custom-form-response-detail__section__content">
              {section?.customFields?.map((customFormResponseField, i) => (
                <>
                  <div className="custom-form-response-detail__label">
                    {i + 1}. {customFormResponseField?.customFieldName}
                  </div>
                  <div className="custom-form-response-detail__value">
                    {customFormResponseField?.customResponses?.map(
                      (customResponse, i) => (
                        <div
                          className="custom-form-response-detail__response-item"
                          key={i}
                        >
                          {customResponse?.signatureUrl ? (
                            <img
                              className="custom-form-response-detail__response-item__sign"
                              src={customResponse?.signatureUrl}
                              alt="Signature"
                            />
                          ) : (
                            customResponse?.customOptionName ||
                            customResponse?.responseText
                          )}
                        </div>
                      )
                    )}
                  </div>
                </>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default CustomFormResponseDetail;
