import React, { FC, useEffect, useState } from "react";
import ApexChart from "react-apexcharts";
import "./chart.scss";

const experience = ["", "Poor", "Average", "Fair", "Good", "Excellent"];
const colors = ["#FF503C", "#F67413", "#2B65F0", "#00AE89"];

const options = {
	chart: {
		height: 350,
		type: "line",
		zoom: {
			enabled: false,
		},
	},
	colors: ["#546E7A"],
	dataLabels: {
		enabled: false,
	},
	stroke: {
		width: [1],
		curve: "straight",
		dashArray: [5],
	},
	xaxis: {
		categories: [],
		title: {
			text: "Visits",
		},
	},
	yaxis: {
		labels: {
			formatter: function (value: any) {
				return experience[value];
			},
		},
		tickAmount: 5,
		forceNiceScale: true,
		min: 0,
		max: 5,
	},
	markers: {
		size: 5,
		strokeWidth: 7,
		strokeOpacity: 0.3,
		hover: {
			size: 5,
		},
	},
};

const updateData = (y: number, i: number) => {
	let fillColor = colors[3];
	if (y < 4) {
		fillColor = colors[2];
	}
	if (y < 3) {
		fillColor = colors[1];
	}
	if (y < 2) {
		fillColor = colors[0];
	}
	return {
		x: i + 1,
		y,
		fillColor,
		strokeColor: fillColor,
	};
};

interface ChartProps {
	points: number[];
}

const Chart: FC<ChartProps> = (props) => {
	const { points } = props;
	const [series, setSeries] = useState<any>([
		{
			data: []
		},
	]);

	useEffect(() => {
		setSeries([
			{
				data: [...points].map(updateData),
			},
		]);
	}, [points]);

	return (
		<div className="chart">
			<ApexChart options={options as any} series={series} type="line" />
		</div>
	);
};

export default Chart;
