import { Button, Col, Row } from "antd";
import { Form, Formik, FormikValues } from "formik";
import React, { FC, useEffect, useState } from "react";
import { InteractionStep } from "../../../models/InteractionStep/interactionStep.model";
import InteractionStepService from "../../../services/InteractionStep/interactionStep.service";
import { IDropdownOptions } from "../../../services/Meta/meta.service";
import ProcedureService from "../../../services/Procedure/procedure.service";
import DropdownField from "../../../shared/components/DropdownField";
import {
	checkConflictProcedure,
	handleImportVendorObject,
} from "../../../shared/utils/babylonUtils";
import BabylonContainer from "../../../store/container/BabylonContainer";
import InteractionContainer from "../../../store/container/InteractionContainer";
import { BabylonReducerProps } from "../../../store/reducers/babylonReducer";
import { InteractionReducerProps } from "../../../store/reducers/interactionReducer";
import { Scene } from "@babylonjs/core";
import "./procedureForm.scss";
import { CONFLICT_TYPES } from "../../../enums/conflictType.enum";
import { getDropdownOptions } from "../../../shared/utils/optionHelper";
import { procedureFormValidation } from "../../Settings/Configuration/Procedures/ProcedureForm/procedureFormValidation";
import useProcedures from "../../../shared/hooks/useProcedures";

interface ProcedureFormProps
	extends BabylonReducerProps,
		InteractionReducerProps {
	onSuccess?: () => void;
	setOpenConflict: (open: boolean) => void;
	createInteractionStep: (step: InteractionStep, complete: () => void) => void;
}

const ProcedureForm: FC<ProcedureFormProps> = (props) => {
	const {
		setMeshLoading,
		activeInteractionId,
		highlightLayer,
		scene,
		contractInteractions,
		corporateObjects,
		setContractInteractions,
		setSourceObject,
		setShowPopover,
		setPopoverPosition,
		onSuccess,
		pushConflict,
		setOpenConflict,
		createInteractionStep,
	} = props;

	const { options: procedureOptions, fetchProcedures, loading: proceduresLoading} = useProcedures({});

	const saveInteractionStep = async (
		interactionStep: InteractionStep,
		conflict?: CONFLICT_TYPES
	) => {
		return new Promise((resolve) => {
		if (conflict) {
			setShowPopover(false);
		}
		if (conflict === CONFLICT_TYPES.HARD_APPLY) {
			pushConflict(interactionStep);
			setOpenConflict(true);
		}

		if (!conflict) {
			createInteractionStep(interactionStep, () => {
				setMeshLoading(false);
				resolve(null)
			});
		}else{
			resolve(null);
		}
	});
	};

	const handleSubmit = async (values: FormikValues) => {
		try{
		setMeshLoading(true);
		const interactionStep = Object.assign(new InteractionStep(), values);
		if (interactionStep?.procedureId) {
			const conflict: any = await checkConflictProcedure(
				interactionStep.procedureId,
				scene as Scene
			);
			await saveInteractionStep(interactionStep, conflict);
		}
	}catch(ex){}finally{
		setMeshLoading(false);
	}
	};

	const formikProps = {
		initialValues: {
			procedureId: undefined,
		},
		onSubmit: handleSubmit,
	};

	return (
		<Formik {...formikProps}>
			{({ values, setFieldValue, isSubmitting, isValid, dirty }) => (
				<Form>
					<div className="procedure-form mt-4">
						<Row gutter={[15, 15]}>
							<Col span={18}>
								<DropdownField
									name="procedureId"
									placeHolder="Select"
									showSearch
									darkmode
									loading={proceduresLoading}
									onScrollEnd={fetchProcedures}
									options={procedureOptions}
									value={values.procedureId}
									onChange={(value, procedure) => {
										setFieldValue("procedureId", value);
										setFieldValue("procedure", procedure);
									}}
								/>
							</Col>
							<Col span={6}>
								<Button loading={isSubmitting} type="primary" htmlType="submit">
									Add
								</Button>
							</Col>
						</Row>
					</div>
				</Form>
			)}
		</Formik>
	);
};

export default InteractionContainer(BabylonContainer(ProcedureForm));
