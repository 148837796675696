import { Button, Col, Row } from "antd";
import { Formik, Form } from "formik";
import React, { FC } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Comment } from "../../../../models/Comment/comment.model";
import { Support } from "../../../../models/Support/support.model";
import InputField from "../../../../shared/components/InputField";
import UserPlaceholder from "../../../../assets/images/userPlaceholder.png";
import moment from "moment";
import "./comments.scss";
import AuthContainer from "../../../../store/container/AuthContainer";
import { AuthReducerProps } from "../../../../store/reducers/authReducer";
import SupportService from "../../../../services/Support/support.service";
import useResponsibilities from "../../../../shared/hooks/Responsibilities/useResponsibilities";
import { ResponsibilityTypes } from "../../../../enums/responsebily.enum";

type LoaderType = "list" | "add" | "delete";
interface Loader {
  list: boolean;
  add: boolean;
  delete: number;
}
interface CommentsProps extends AuthReducerProps {
  support?: Support;
}

const Comments: FC<CommentsProps> = (props) => {
  const { user, support } = props;

  const { hasAccess } = useResponsibilities();

  const [initialValues, setInitialValues] = useState<Comment>(new Comment());

  const [comments, setComments] = useState<Comment[]>([]);

  const [loading, setLoading] = useState<Loader>({
    list: false,
    add: false,
    delete: 0,
  });

  const HAS_ISSUE_EDIT_ACCESS = hasAccess(ResponsibilityTypes.ISSUE_EDIT);

  useEffect(() => {
    handleLoader("list", true);
    SupportService.fetchComments(
      support?.id as number,
      (comments) => {
        setComments(comments);
      },
      () => {
        handleLoader("list", false);
      }
    );
  }, []);

  const handleLoader = (type: LoaderType, value: boolean | number) => {
    setLoading((loaders) => ({
      ...loaders,
      [type]: value,
    }));
  };

  const handleDeleteComment = (id?: number) => {
    handleLoader("delete", id ?? 0);
    SupportService.deleteComments(
      support?.id ?? 0,
      id ?? 0,
      () => {
        const updatedComment = comments.filter((comment) => comment?.id !== id);
        setComments(updatedComment);
      },
      () => {
        handleLoader("delete", 0);
      }
    );
  };

  const handleEditComment = (comment: Comment) => {
    setInitialValues(comment);
  };

  const handleSubmit = (values: Comment) => {};

  const formikProps = {
    initialValues,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  };

  return (
    <div className="comments">
      {HAS_ISSUE_EDIT_ACCESS && <Formik {...formikProps}>
        {({ values, resetForm }) => {
          const handleUpdateComment = () => {
            handleLoader("add", true);
            const comment = Object.assign(new Comment(), {
              ...values,
              commentorFirstName: user?.firstName,
              commentorLastName: user?.lastName,
              createdAt: new Date().toISOString(),
            });
            SupportService[values?.id ? "updateComments" : "addComments"](
              support?.id ?? 0,
              comment,
              (comment) => {
                if (values?.id) {
                  const commentIndex = comments.findIndex(
                    ({ id }) => id === comment?.id
                  );
                  const updatedComment = [...comments];
                  updatedComment.splice(commentIndex, 1, comment);
                  setComments(updatedComment);
                } else {
                  setComments((comments) => [...comments, comment]);
                }
                setInitialValues(new Comment());
                resetForm();
              },
              () => {
                handleLoader("add", false);
              }
            );
          };
          return (
            <Form>
              <Row align="middle" gutter={[20, 10]}>
                <Col span={16}>
                  <InputField
                    title="Your comments"
                    type="text"
                    name="comment"
                    placeholder="Enter your comments..."
                  />
                </Col>
                <Col>
                  <Button
                    onClick={handleUpdateComment}
                    loading={loading.add}
                    className="secondary-btn"
                  >
                    {values?.id ? "Edit comment" : "Post comment"}
                  </Button>
                </Col>
              </Row>
            </Form>
          );
        }}
      </Formik>}

      <div className="comments">
        <p className="title">Comments</p>
        {loading.list ? (
          <p>Loading comments . . .</p>
        ) : (
          comments?.map((comment, index) => (
            <Row className="comment mb-3" key={index}>
              <Col span={2}>
                <img src={UserPlaceholder} alt="" />
              </Col>
              <Col span={19}>
                <Row className="user-detail" align="middle">
                  <Col className="name">{`${comment?.commentorFirstName} ${
                    comment?.commentorLastName ?? ""
                  }`}</Col>
                  <Col className="date">
                    {moment(comment?.createdAt).format(
                      "MMMM DD,YYYY [at] hh:mm a"
                    )}
                  </Col>
                </Row>
                <p className="description">{comment?.comment}</p>
              </Col>
              {comment?.id !== loading.delete && HAS_ISSUE_EDIT_ACCESS && (
                <Col>
                  <i
                    className="icon-edit mr-2 cursor-pointer"
                    onClick={() => handleEditComment(comment)}
                  />
                  <i
                    className="icon-delete cursor-pointer"
                    onClick={() => handleDeleteComment(comment?.id)}
                  />
                </Col>
              )}
              {comment?.id === loading.delete && (
                <div className="deleting">Deleting your comment . . .</div>
              )}
            </Row>
          ))
        )}
      </div>
    </div>
  );
};

export default AuthContainer(Comments);
