import { serializable, alias, object, list, primitive } from "serializr";
import { AppointmentStatus } from "../../enums/appointmentStatus.enum";
import { AppointmentTiming } from "../AppointmentTiming/appointmentTiming.model";
import { AppointmentType } from "../AppointmentType/appointmentType.model";
import { InteractionStatus } from "../../enums/interactionStatus.enum";

export class Appointment {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("date", primitive()))
  date?: string;
  @serializable(alias("contract_name", primitive()))
  contractName?: string;

  @serializable(alias("appointment_schedule_slot_id", primitive()))
  appointmentScheduleSlotId?: number;

  @serializable(alias("appointment_type_procedure_code", primitive()))
  procedureCode?: string;

  @serializable(alias("patient_profile_id", primitive()))
  patientProfileId?: string;

  @serializable(alias("appointment_type", object(AppointmentType)))
  interactionType?: AppointmentType;

  @serializable(alias("appointment_type_id", primitive()))
  appointmentTypeId?: number;

  @serializable(alias("appointment_type_name", primitive()))
  appointmentTypeName?: string;

  @serializable(alias("doctor_first_name", primitive()))
  doctorFirstName?: string;

  @serializable(alias("doctor_last_name", primitive()))
  doctorLastName?: string;

  @serializable(alias("doctor_profile_pic_url", primitive()))
  doctorProfilePicUrl?: string;

  @serializable(alias("doctor_profile_id", primitive()))
  doctorProfileId?: string;

  @serializable(alias("chair_name", primitive()))
  chairName?: string;

  @serializable(alias("chair_id", primitive()))
  chairId?: string;

  @serializable(alias("start_time", primitive()))
  startTime?: string;

  @serializable(alias("end_time", primitive()))
  endTime?: string;

  @serializable(alias("visit_type", primitive()))
  visitType? = "in_person";

  @serializable(alias("emergency_visit", primitive()))
  emergencyVisit?: string;

  @serializable(alias("payment_status", primitive()))
  paymentStatus?: string;

  @serializable(alias("interaction_id", primitive()))
  interactionId?: string;

  @serializable(alias("interaction_status", primitive()))
  interactionStatus?: string;

  @serializable(alias("is_emergency_visit", primitive()))
  isEmergencyVisit?: boolean = false;

  @serializable(alias("is_medical_alert_present", primitive()))
  isMedicalAlertPresent?: boolean = false;

  @serializable(alias("notes", primitive()))
  notes?: string;

  @serializable(alias("patient_first_name", primitive()))
  patientFirstName?: string;

  @serializable(alias("patient_last_name", primitive()))
  patientLastName?: string;

  @serializable(alias("patient_mobile_number", primitive()))
  patientMobileNumber?: string;

  @serializable(alias("patient_profile_pic_url", primitive()))
  patientProfilePicUrl?: string;

  @serializable(alias("status", primitive()))
  status?: string;

  @serializable(alias("appointment_type_color_code", primitive()))
  appointmentTypeColorCode?: string;

  @serializable(alias("appointment_status_color_code", primitive()))
  appointmentStatusColorCode?: string;

  @serializable(alias("session", primitive()))
  session?: string;

  isDoctorRequired?: boolean;

  static updateAppointment = (
    appointments: Appointment[],
    newAppointment: Appointment
  ) =>
    appointments?.map((appointment) =>
      appointment?.id === newAppointment?.id ? newAppointment : appointment
    );

  static isAppointmentInteractionCompleted = (appointment: Appointment) =>
    appointment?.interactionStatus === InteractionStatus.COMPLETED;

  static mapAppointmentStatus = (
    status: string,
    separator = "_",
    joiner = " "
  ) => status?.replaceAll(separator, joiner);
}

export class Appointments {
  @serializable(alias("appointments", list(object(Appointment))))
  appointments: Appointment[] = [];
}

export class InteractionAppointment extends Appointment {
  @serializable(alias("amount", primitive()))
  amount?: string;

  @serializable(alias("scheduleAppointment", primitive()))
  scheduleAppointment?: boolean = false;

  @serializable(alias("confirm_date", primitive()))
  confirmDate?: string = "Yes";

  @serializable(alias("remaining_date", primitive()))
  remainingDate?: string;

  @serializable(alias("contract_id", primitive()))
  contractId?: string;

  @serializable(alias("contract_service_id", primitive()))
  contractServiceId?: string;

  @serializable(alias("available_timings", list(object(AppointmentTiming))))
  appointmentTiming: AppointmentTiming[] = [];
}

export const appointmentStatusOptions = Object.values(
  AppointmentStatus
).map((status) => ({ label: status?.replaceAll("_", " "), value: status }));
