import React, { useEffect, useState } from 'react'
import { InteractionStatus } from '../../../../../models/InteractionStatuse/interactionStatuse.model';
import InteractionStatusService from '../../../../../services/InteractionStatus/interactionStatus.service';
import "./appointmentStatus.scss";
import InteractionSubStatus from './InterationSubStatus';

const AppointmentStatus = () => {
    const [interactionStatuses, setInteractionStatuses] = useState<InteractionStatus[]>();

    useEffect(() => {
        InteractionStatusService.fetchInteractionStatus((interactionStatuses) => {
            setInteractionStatuses(interactionStatuses);
        }, () => {

        }, () => {

        })
    }, []);

    return (
        <div className="interaction-status__container">
            {
                interactionStatuses?.map(interactionStatus => <InteractionSubStatus {...interactionStatus}/>)
            }
        </div>
    )
}

export default AppointmentStatus