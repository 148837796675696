import { Button, Checkbox, Col, Row } from "antd";
import { Form, Formik } from "formik";
import React, { FC, useState } from "react";
import { useEffect } from "react";
import "./contractFilterForm.scss";
import DatePickerField from "../../../../shared/components/DatePickerField";
import RadioInputField from "../../../../shared/components/RadioInputField";
import { IDropdownOptions } from "../../../../services/Meta/meta.service";
import { TimePeriodEnum } from "../../../../enums/timePeriodType.enum";
import CheckboxField from "../../../../shared/components/Checkbox";
import { Contract, Contractmetrics } from "../../../../models/Contract/contract.model";
import ContractService from "../../../../services/Contract/Contract.service";
import { StatusEnum } from "../../../../enums/status.enum";
import { FilterParams } from "../../../../models/FilterParams/filterParams.model";
import { PaginationDetails } from "../../../../models/Pagination/pagination.model";

interface ContractFilterFormProps {
  onClose: () => void;
  onSuccess: (contract: Contract[], metrics?: Contractmetrics, pagination?: PaginationDetails ) => void;
}

const ContractFilterForm: FC<ContractFilterFormProps> = (props) => {
  const { onClose, onSuccess } = props;

  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState<Contract>(new Contract());
  const [radioValue, setRadioValue] = useState("");

  const handleSubmit = (values: Contract) => {
    const params: FilterParams = {
      fromDate: values?.fromDate,
      toDate: values?.toDate,
      status: values?.status,
      timePeriod: values?.timePeriod,
    };
    const handleFetchContracts = () => {
      setLoading(true);
      ContractService.fetchContracts(
        params,
        (contracts, metrics, pagination) => {
          onSuccess(contracts, metrics, pagination);
          onClose();
        },
        () => {},
        () => {
          setLoading(false);
        }
      );
    };
    handleFetchContracts();
  };
  const Options = [
    {
      label: "Current Month",
      value: TimePeriodEnum.CURRENT_MONTH,
    },
    {
      label: "Current Quarter",
      value: TimePeriodEnum.CURRENT_QUARTER,
    },
    {
      label: "Current Year",
      value: TimePeriodEnum.CURRENT_YEAR,
    },
  ];
  const checkboxValue = [
    { label: "Active", value: StatusEnum.ACTIVE },
    // { label: "Onhold", value: StatusEnum.ON_HOLD },
    { label: "Inactive", value: StatusEnum.INACTIVE },
  ];

  return (
    <div className="contract-filter-form">
      <Formik initialValues={formValues ?? []} onSubmit={handleSubmit}>
        {({ values, setFieldValue }) => {
          return (
            <Form>
              <div className="contract-filter-form__container">
                <p className="text-primary mb-4">Date Range</p>
                <Row gutter={[20, 0]}>
                  <Col span={12}>
                    <DatePickerField
                      title="Date From"
                      format="MM-DD-YYYY"
                      name="fromDate"
                      setFieldValue={setFieldValue}
                    />
                  </Col>
                  <Col span={12}>
                    <DatePickerField
                      title="Date to"
                      format="MM-DD-YYYY"
                      name="toDate"
                      setFieldValue={setFieldValue}
                    />
                  </Col>
                </Row>
              </div>
              <div className="contract-filter-form__container mt-5">
                <p className="text-primary mb-4">Time Period</p>
                <Row gutter={[20, 0]}>
                  <Col span={24}>
                    <RadioInputField
                      name={"timePeriod"}
                      value={values?.timePeriod}
                      setFieldValue={setFieldValue}
                      options={Options}
                    />
                  </Col>
                </Row>
              </div>
              <div className="contract-filter-form__container mt-5">
                <p className="text-primary mb-4">Status</p>
                <Row gutter={[20, 0]}>
                  <Col span={24}>
                    {/* <Checkbox.Group
                      options={checkboxValue}
                      onChange={(val) => {
                        setFieldValue("status", val);
                      }}
                    /> */}
                    <Checkbox
                      className="ml-4"
                      value={values?.status}
                      name="active"
                      onChange={(value) => {
                        setFieldValue("status", value.target.name);
                      }}
                    >
                      Active
                    </Checkbox>
                    <Checkbox
                      className="ml-4"
                      value={values?.status}
                      name="inactive"
                      onChange={(value) => {
                        setFieldValue("status", value.target.name);
                      }}
                    >
                      Inactive
                    </Checkbox>
                  </Col>
                </Row>
              </div>
              <div className="contract-filter-form__btn text-right mt-5">
                <Button type="text" onClick={onClose}>
                  Cancel
                </Button>
                <Button type="primary" htmlType="submit" loading={loading}>
                  Apply
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default ContractFilterForm;
