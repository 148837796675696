import { Scene } from "@babylonjs/core";
import { objectActionEnum } from "../../enums/objectActionType.enum";
import {
  CorporateObject,
  CorporateObjectWithBabylonFile,
} from "../../models/CorporateObject/corporateObject.model";
import { Interaction } from "../../models/Interaction/interaction.model";
import { InteractionStep } from "../../models/InteractionStep/interactionStep.model";
import { ProcedureStep } from "../../models/ProcedureStep/procedureStep.model";
import { SetStep } from "../../models/SetStep/setStep.model";
import { TreatmentPlanTemplateSteps } from "../../models/TreatmentPlanTemplateSteps/treatmentPlanTemplateSteps.model";
import { handleImportVendorObject, handleRemoveLink } from "./babylonUtils";

interface ISetVendorObject {
  caps?: CorporateObjectWithBabylonFile[];
  corporateObjects: CorporateObject[];
  isMeshPickable?: boolean;
  scene?: Scene;
  setPopoverPosition?: Function;
  setShowPopover?: Function;
  setSourceObject?: Function;
  step: SetStep;
}

type ISetVendorObjectWithoutStep = Omit<ISetVendorObject, "step">;

interface IUpdateTreatmentSetSteps extends ISetVendorObjectWithoutStep {
  steps?: SetStep[];
}

interface IUpdateTreatmentProcedureSteps extends ISetVendorObjectWithoutStep {
  steps?: ProcedureStep[];
}

interface IUpdateTreatmentStep extends ISetVendorObject {
  step: ProcedureStep;
}

interface IIntegrateInteraction extends ISetVendorObjectWithoutStep {
  contractInteractions?: Interaction[];
  activeInteractionId?: number;
}

interface IIntegrateTemplateSteps extends ISetVendorObjectWithoutStep {}

const isAddAction = (step: SetStep) => step.actionType === objectActionEnum.ADD;

const isRemoveAction = (step: SetStep) =>
  step.actionType === objectActionEnum.REMOVE;

/**
 * Set / Remove the vendor object to/from the anatomy object based on the action type.
 * @param props - values needed to load the vendor object to the scene.
 */
const setVendorObject = async (props: ISetVendorObject) => {
  const {
    caps,
    corporateObjects,
    step,
    scene,
    setPopoverPosition,
    setShowPopover,
    setSourceObject,
    isMeshPickable,
  } = props;

  const isVendorObjExist = scene?.getMeshByName(
    step?.objectLink?.destObjectName || ""
  );

  const highlightLayer = scene?.getHighlightLayerByName("hl1");

  if (isAddAction(step) && isVendorObjExist) return;

  if (isRemoveAction(step)) return handleRemoveLink(step, scene);

  const cap = caps?.find(
    (cap) => cap?.corporateObject?.name === step?.objectLink?.destObjectName
  )?.babylonFile;

  return (
    step &&
    handleImportVendorObject(
      step,
      scene,
      highlightLayer,
      corporateObjects,
      setSourceObject,
      setShowPopover,
      setPopoverPosition,
      !!isMeshPickable,
      cap
    )
  );
};

/**
 * Iterate Over the steps in Set and add that step to the anatomy object in the scene.
 * @param props - values needed to load the set to the scene.
 */
export const updateTreatmentSetSteps = async (
  props: IUpdateTreatmentSetSteps
) => {
  for (let step of props?.steps || [])
    await setVendorObject({ ...props, step });
};

/**
 * Iterate Over the Treatment Steps(procedure / set / object link) and load them to anatomy object in the 3D Scene.
 * @param props - values needed to load the procedure / set / object link to the scene.
 */
export const updateTreatmentStep = async (props: IUpdateTreatmentStep) => {
  const { step, ...otherProps } = props;

  if (step?.set)
    await updateTreatmentSetSteps({
      ...otherProps,
      steps: step?.set?.setSteps,
    });
  else await setVendorObject({ ...props, step });
};

/**
 * Iterate Over the steps in Procedure and add that step to the anatomy object in the scene
 * @param props - values needed to load the procedure to the scene.
 */
export const updateTreatmentProcedureSteps = async (
  props: IUpdateTreatmentProcedureSteps
) => {
  for (let step of props?.steps || [])
    await updateTreatmentStep({ ...props, step });
};

/**
 * Iterate Over the interaction / template steps and add them to anatomy object
 * @param props - values needed to load the step to the scene.
 * @param steps - steps required to iterate.
 */
export const iterateSteps = async <
  T extends InteractionStep | TreatmentPlanTemplateSteps
>(
  props: T extends InteractionStep
    ? IIntegrateInteraction
    : IIntegrateTemplateSteps,
  steps?: T[]
) => {
  if (!steps?.length) return;

  for (let step of steps) {
    if (step?.procedure?.procedureSteps)
      await updateTreatmentProcedureSteps({
        ...props,
        steps: step.procedure.procedureSteps,
      });
    else await updateTreatmentStep({ ...props, step });
  }
};

/**
 * Iterate Over the interactions till activeInteraction and integrate the interaction treatment steps to the anatomy object in the scene
 * @param {Object} props - Props need to load the vendor object to the scene
 */
export const integrateInteractions = async (props: IIntegrateInteraction) => {
  const { contractInteractions, activeInteractionId } = props;

  const filteredInteractions = contractInteractions
    ?.filter(
      (interaction) => Number(interaction?.id) <= Number(activeInteractionId)
    )
    ?.sort((a, b) => Number(a?.visitNo) - Number(b?.visitNo));

  for (const { interactionSteps } of filteredInteractions || [])
    await iterateSteps<InteractionStep>(props, interactionSteps);
};
