import React, { useEffect } from "react";
import "@babylonjs/loaders";
import "./corporateObjectPreview.scss";
import CustomBabylonScene from "../../../../../shared/components/CustomBabylonScene";
import useToggle from "../../../../../shared/hooks/useToggle/useToggler";
import { MeshType } from "../../../../../enums/meshType.enum";
import { CorporateObject } from "../../../../../models/CorporateObject/corporateObject.model";
import BabylonContainer from "../../../../../store/container/BabylonContainer";
import { BabylonReducerProps } from "../../../../../store/reducers/babylonReducer";
import { ObjectCategoryName } from "../../../../../enums/objectCategoryName.enum";

interface CorporateObjectPreviewProps
  extends BabylonReducerProps {
  corporateObject?: CorporateObject;
}

function CorporateObjectPreview({
  caps,
  corporateObject,
  scene,
}: CorporateObjectPreviewProps) {
  const isLoadMeshToScene = !!corporateObject;

  const getMeshType = (meshCategoryName?: string) => {
    switch (meshCategoryName?.toLowerCase()) {
      case MeshType.CORPORATE_OBJECT_ALIGNERS.toLowerCase():
        return MeshType.CORPORATE_OBJECT_ALIGNERS;
      case MeshType.CORPORATE_OBJECT_TEETH.toLowerCase():
        return MeshType.CORPORATE_OBJECT_TEETH;
      default:
        return "";
    }
  };

  const getMeshFile = (corporateObject?: CorporateObject) => {
    const cap = caps?.find(
      (cap) => cap?.corporateObject?.name === corporateObject?.name
    );

    return cap ? cap?.babylonFile : corporateObject?.attachmentUrl;
  };

  return (
    <div className="dental-object-preview">
      <CustomBabylonScene
        loadMeshToScene={isLoadMeshToScene}
        meshType={getMeshType(corporateObject?.objectCategoryName)}
        meshBabylonFile={getMeshFile(corporateObject)}
      />
    </div>
  );
}

export default BabylonContainer(CorporateObjectPreview);
