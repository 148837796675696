import ApiRoutes from "../../routes/routeConstants/apiRoutes";
import axiosInstance from "../../interceptor/axiosInstance";
import { deserialize, serialize } from "serializr";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import { DentalSet } from "../../models/DentalSet/dentalSet.model";
import { SetStep } from "../../models/SetStep/setStep.model";
import { FilterParams } from "../../models/FilterParams/filterParams.model";
import { PaginationDetails } from "../../models/Pagination/pagination.model";

export default class SetService {
  static fetchSets(
    onSuccess: Function,
    onError: Function,
    onFinal: () => void,
    filter?: FilterParams
  ) {
    const params = serialize(FilterParams, filter ?? new FilterParams());
    axiosInstance
      .get(ApiRoutes.SETS, { params })
      .then((response) => {
        const sets = deserialize(DentalSet, response.data["sets"]);
        const pagination = deserialize(
          PaginationDetails,
          response.data["meta"]
        );
        onSuccess(sets, pagination);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static showSet(
    setId: number,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.SETS + "/" + setId;
    axiosInstance
      .get(API_URL)
      .then((response) => {
        const set = deserialize(DentalSet, response.data["set"]);
        onSuccess(set);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static createSet(
    set: DentalSet,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const setJSON = { set: serialize(set) };
    axiosInstance
      .post(ApiRoutes.SETS, setJSON)
      .then((response) => {
        const set = deserialize(DentalSet, response.data["set"]);
        Notification({
          message: "Set created!",
          type: NotificationTypes.SUCCESS,
        });
        onSuccess(set);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static updateSet(
    set: DentalSet,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.SETS + "/" + set.id;
    const setJSON = { set: serialize(set) };
    axiosInstance
      .put(API_URL, setJSON)
      .then((response) => {
        const set = deserialize(DentalSet, response.data["set"]);
        Notification({
          message: "Set updated!",
          type: NotificationTypes.SUCCESS,
        });
        onSuccess(set);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static cloneSet(
    set: DentalSet,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.CLONE_SET.replace(
      ":setId",
      set?.id ? set.id.toString() : ""
    );
    const setJSON = { set: serialize(set) };
    axiosInstance
      .post(API_URL, setJSON)
      .then((response) => {
        const set = deserialize(DentalSet, response.data["set"]);
        Notification({
          message: "Set cloned!",
          type: NotificationTypes.SUCCESS,
        });
        onSuccess(set);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static createSetStep(
    setStep: SetStep,
    setId: number,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const setStepJSON = {
      set_step: serialize(setStep),
    };
    const API_URL = ApiRoutes.SET_STEPS.replace(":setId", setId.toString());
    axiosInstance
      .post(API_URL, setStepJSON)
      .then((response) => {
        const setStep = deserialize(SetStep, response.data["set_step"]);
        onSuccess(setStep);
        Notification({
          message: "Step added to the set!",
          type: NotificationTypes.SUCCESS,
        });
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static deleteSetSteps(
    setId: number,
    setStepId: number,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL =
      ApiRoutes.SET_STEPS.replace(":setId", setId.toString()) + "/" + setStepId;
    axiosInstance
      .delete(API_URL)
      .then((response) => {
        onSuccess();
        Notification({
          message: "Step removed from the set!",
          type: NotificationTypes.SUCCESS,
        });
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }
}
