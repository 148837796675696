import {
  serializable,
  alias,
  primitive,
  object,
  list,
  custom,
} from "serializr";
import { PatientCategory } from "../../enums/patientCategory.enum";

export class LinkedService {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("name", primitive()))
  name?: string;

  @serializable(alias("min_price", primitive()))
  minPrice?: number;

  @serializable(alias("max_price", primitive()))
  maxPrice?: number;
}

export class TreatmentTemplate {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("name", primitive()))
  name?: string;

  @serializable(
    alias("treatment_plan_template_services", list(object(LinkedService)))
  )
  treatmentPlanTemplateServices: LinkedService[] = [];

  @serializable(
    alias(
      "treatment_plan_template_service_ids",
      custom(
        (value) => value,
        (value) => value ?? []
      )
    )
  )
  treatmentPlanTemplateServiceIds?: number[] = [];

  @serializable(alias("description", primitive()))
  description?: string;

  @serializable(alias("plan_type", primitive()))
  planType?: string;
  @serializable(alias("status", primitive()))
  status?: string;
  @serializable(alias("practice_id", primitive()))
  practiceId?: number;
  @serializable(alias("no_of_bouts", primitive()))
  noOfBout?: number;

  @serializable(alias("duration_in_months", primitive()))
  durationInMonths?: string;

  @serializable(alias("no_of_visits", primitive()))
  noOfVisits?: number;

  @serializable(alias("no_of_appointments", primitive()))
  noOfAppointments?: number;

  @serializable(
    alias(
      "patient_category",
      custom(
        (value) => value,
        (value) => value || PatientCategory.ADULT
      )
    )
  )
  patientCategory?= PatientCategory.ADULT;
}
