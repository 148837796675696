import ApiRoutes from "../../routes/routeConstants/apiRoutes";
import axiosInstance from "../../interceptor/axiosInstance";
import {
  AppointmentType,
  AppointmentTypes,
} from "../../models/AppointmentType/appointmentType.model";
import { deserialize, serialize } from "serializr";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import { Suggestion } from "../../models/Suggestion/suggestion.model";
import { FilterParams } from "../../models/FilterParams/filterParams.model";
import { PaginationDetails } from "../../models/Pagination/pagination.model";
import { AppointmentTypeCategory } from "../../models/AppointmentTypeCategories/appointmentTypeCategories.model";
import { AppointmentTypeProcedureCode } from "../../models/AppointmentTypeProcedureCodes/appointmentTypeProcedureCodes.model";
export default class AppointmentTypeService {
  static fetchAppointmentTypes(
    onSuccess: (
      appointmentTypes: AppointmentType[],
      pagination: PaginationDetails
    ) => void,
    onError: Function,
    onFinal: () => void,
    filter?: FilterParams
  ) {
    const params = serialize(FilterParams, filter ?? new FilterParams());
    axiosInstance
      .get(ApiRoutes.APPOINTMENT_TYPES, { params })
      .then((response) => {
        const { appointmentTypes } = deserialize(
          AppointmentTypes,
          response.data
        );
        const pagination = deserialize(
          PaginationDetails,
          response.data["meta"]
        );
        onSuccess(appointmentTypes, pagination);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }
  static fetchNewAppointmentTypes(
    patientId: string,
    onSuccess: (
      appointmentTypes: AppointmentType[],
      suggestedDoctor: Suggestion
    ) => void,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .get(
        ApiRoutes.NEW_APPOINTMENT_TYPES.replace(":patientProfileId", patientId)
      )
      .then((response) => {
        const { appointmentTypes } = deserialize(
          AppointmentTypes,
          response.data
        );
        const suggestedDoctor = deserialize(Suggestion, response.data);
        onSuccess(appointmentTypes, suggestedDoctor);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static showAppointmentType(
    appointmentTypeId: string,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.APPOINTMENT_TYPES + "/" + appointmentTypeId;
    axiosInstance
      .get(API_URL)
      .then((response) => {
        const appointmentType = deserialize(
          AppointmentType,
          response.data["appointmentType"]
        );
        onSuccess(appointmentType);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static createAppointmentType(
    appointmentType: AppointmentType,
    onSuccess: (appointmentType: AppointmentType) => void,
    onError: Function,
    onFinal: () => void
  ) {
    const appointmentTypeJSON = serialize(appointmentType);
    axiosInstance
      .post(ApiRoutes.APPOINTMENT_TYPES, {
        appointment_type: appointmentTypeJSON,
      })
      .then((response) => {
        const appointmentType = deserialize(
          AppointmentType,
          response.data["appointment_type"]
        );
        Notification({
          message: "Appointment Created! ",
          type: NotificationTypes.SUCCESS,
        });
        onSuccess(appointmentType);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static updateAppointmentType(
    appointmentType: AppointmentType,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.APPOINTMENT_TYPES + "/" + appointmentType?.id;
    const appointmentTypeJSON = serialize(appointmentType);
    axiosInstance
      .put(API_URL, { appointment_type: appointmentTypeJSON })
      .then((response) => {
        const appointmentType = deserialize(
          AppointmentType,
          response.data["appointment_type"]
        );
        Notification({
          message: "Appointment Updated! ",
          type: NotificationTypes.SUCCESS,
        });
        onSuccess(appointmentType);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static async fetchCategories(
    onSuccess: (categories: AppointmentTypeCategory[]) => void,
    onError: Function,
    onFinal: Function
  ) {
    try {
      const { data } = await axiosInstance.get(
        ApiRoutes.APPOINTMENT_TYPE_CATEGORIES
      );
      const categories = deserialize(
        AppointmentTypeCategory,
        data["appointment_type_categories"] as AppointmentTypeCategory[]
      );
      onSuccess(categories);
    } catch (ex) {
      onError();
    } finally {
      onFinal();
    }
  }

  static async fetchProcedureCodes(
    categoryId: number,
    onSuccess: (procedureCodes: AppointmentTypeProcedureCode[]) => void,
    onError?: Function,
    onFinal?: Function
  ) {
    try {
      const ENDPOINT = ApiRoutes.APPOINTMENT_TYPE_PROCEDURE_CODES?.replaceAll(
        ":categoryId",
        categoryId?.toString()
      );

      const { data } = await axiosInstance.get(ENDPOINT);
      const procedureCodes = deserialize(
        AppointmentTypeProcedureCode,
        data["procedure_codes"] as AppointmentTypeProcedureCode[]
      );
      onSuccess(procedureCodes);
    } catch (ex) {
      onError?.();
    } finally {
      onFinal?.();
    }
  }

  static deleteAppointmentType(
    appointmentTypeId: number,
    onSuccess: () => void,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.APPOINTMENT_TYPES + "/" + appointmentTypeId;
    axiosInstance
      .delete(API_URL)
      .then((response) => {
        Notification({
          message: "Appointment Deleted! ",
          type: NotificationTypes.SUCCESS,
        });
        onSuccess();
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }
}
