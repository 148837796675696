import { Contract } from "models/Contract/contract.model";
import { FilterParams } from "models/FilterParams/filterParams.model";
import { PaginationDetails } from "models/Pagination/pagination.model";
import { useState } from "react";
import ContractService from "services/Contract/Contract.service";
import { getDropdownOptions } from "shared/utils/optionHelper";

export const useContracts = () => {
  const [patientContracts, setPatientContracts] = useState<Contract[]>([]);

  const [patientContractPagination, setPatientContractPagination] = useState(
    new PaginationDetails()
  );

  const [patientContractsLoading, setPatientContractsLoading] = useState(false);

  const patientContractsOptions = getDropdownOptions(
    patientContracts,
    "name",
    "id"
  );

  const fetchPatientContracts = (
    patientProfileId: number,
    params?: FilterParams,
    filters = patientContractPagination
  ) => {
    if (!filters?.nextPage) return;

    const { nextPage } = filters;

    setPatientContractsLoading(true);

    ContractService.fetchPatientContracts(
      +patientProfileId,
      (contracts, pagination) => {
        setPatientContracts((existingContracts) =>
          params?.page === 1 ? contracts : [...existingContracts, ...contracts]
        );
        if (pagination) setPatientContractPagination(pagination);
      },
      () => {},
      () => {
        setPatientContractsLoading(false);
      },
      { page: nextPage, ...params }
    );
  };

  return {
    patientContracts,
    fetchPatientContracts,
    patientContractsLoading,
    patientContractsOptions,
  };
};
