import { combineReducers } from "redux";
import authReducer from "./authReducer";
import registerReducer from "./registerReducer";
import metaReducer from "./metaReducer";
import corporateReducer, { CorporateReducerProps } from "./corporateReducer";
import ContractReducer, { ContractReducerProps } from "./contractReducer";
import setReducer, { SetReducerProps } from "./setReducer";
import babylonReducer, { BabylonReducerProps } from "./babylonReducer";
import procedureReducer, { ProcedureReducerProps } from "./procedureReducer";
import interactionReducer, {
  InteractionReducerProps,
} from "./interactionReducer";
import TreatmentPlanReducer, {
  TreatmentPlanReducerProps,
} from "./treatmentPlanReducer";
export interface RootReducerProps {
  auth: any;
  register: any;
  meta: any;
  corporate: CorporateReducerProps;
  contract: ContractReducerProps;
  set: SetReducerProps;
  procedure: ProcedureReducerProps;
  interaction: InteractionReducerProps;
  babylon: BabylonReducerProps;
  treatmentPlan: TreatmentPlanReducerProps;
}

const RootReducer = combineReducers<RootReducerProps>({
  auth: authReducer,
  register: registerReducer,
  meta: metaReducer,
  corporate: corporateReducer,
  contract: ContractReducer,
  set: setReducer,
  procedure: procedureReducer,
  interaction: interactionReducer,
  babylon: babylonReducer,
  treatmentPlan: TreatmentPlanReducer,
});

export default RootReducer;
