import { Button, Drawer } from "antd";
import React, { FC } from "react";
import "./rolesAndResponsibility.scss";
import UserResponsibility from "./UserResponsibility";
import { PlusOutlined } from "@ant-design/icons";
import { useState } from "react";
import UserRoleForm from "./UserRoleForm";
import { useEffect } from "react";
import { RoleService } from "../../../services/Role/role.service";
import { Role } from "../../../models/Role/role.model";
import AppLoader from "../../../shared/components/AppLoader";
import AppNotification from "../../../shared/components/AppHeader/AppNotification";
import AuthContainer from "../../../store/container/AuthContainer";
import { AuthReducerProps } from "../../../store/reducers/authReducer";
import { compareStringByIgnoringCase } from "../../../shared/utils/helpers";
import { UserRoles } from "../../../enums/userRoles.enum";
import useResponsibilities from "../../../shared/hooks/Responsibilities/useResponsibilities";
import { ResponsibilityTypes } from "../../../enums/responsebily.enum";
import { USER_CATEGORY } from "../../../enums/userCategory.enum";

interface RolesAndResponsibilityProps extends AuthReducerProps {}

const RolesAndResponsibility: FC<RolesAndResponsibilityProps> = (props) => {
  const { user } = props;

  const { hasAccess } = useResponsibilities();

  const [showUser, setShowUser] = useState(false);

  const [roles, setRoles] = useState<Role[]>([]);

  const [loading, setLoading] = useState(false);

  const HAS_ROLE_CREATE_ACCESS = hasAccess(ResponsibilityTypes.ROLE_CREATE);

  useEffect(() => {
    setLoading(true);
    RoleService.getRoles(
      (roles) => {
        setRoles(
          roles.filter((role) => {
            const isAdmin = compareStringByIgnoringCase(
              role?.category,
              USER_CATEGORY.ADMIN
            );
            const isSameRole = user?.roleId === role.id;

            // For Admin Allow all roles. For Other Users, hide their own role
            return isAdmin || !isSameRole;
          })
        );
      },
      () => {
        setLoading(false);
      }
    );
  }, []);

  const handleOpen = () => {
    setShowUser(true);
  };

  const handleClose = () => setShowUser(false);

  const handleAddRole = (role: Role) => {
    setRoles((roles) => [...roles, role]);
    handleClose();
  };

  const handleUpdateRole = (role: Role) => {
    const updatedRole = Object.assign(new Role(), role);
    RoleService.updateRole(
      updatedRole,
      (updatedRole) => {
        setRoles((roles) =>
          roles.map((role) => {
            if (role.id === updatedRole.id) {
              return updatedRole;
            }
            return role;
          })
        );
      },
      () => {}
    );
  };

  return (
    <div className="roles-and-responsibility">
      <div className="head mb-5">
        <p>All Roles</p>
        {HAS_ROLE_CREATE_ACCESS && (
          <Button type="primary" onClick={handleOpen}>
            <PlusOutlined /> Add Role
          </Button>
        )}
      </div>
      {loading ? (
        <AppLoader loading />
      ) : (
        roles.map((role) => (
          <UserResponsibility
            role={role}
            updateRoleHandler={handleUpdateRole}
          />
        ))
      )}
      <Drawer
        visible={HAS_ROLE_CREATE_ACCESS && showUser}
        destroyOnClose
        onClose={handleClose}
        width="45vw"
        title="User Role"
      >
        <UserRoleForm
          successHandler={handleAddRole}
          closeHandler={handleClose}
        />
      </Drawer>
    </div>
  );
};

export default AuthContainer(RolesAndResponsibility);
