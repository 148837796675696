import { Engine, Scene } from "react-babylonjs";
import {
  ArcRotateCamera,
  Scene as SceneModel,
  Color3,
  Database,
  HDRCubeTexture,
  HemisphericLight,
  HighlightLayer,
  PointerEventTypes,
  SceneLoader,
  StandardMaterial,
  Texture,
  Vector3,
  ActionManager,
  ExecuteCodeAction,
  AbstractMesh,
  PointerInfo,
} from "@babylonjs/core";
import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { addHighlightLayer } from "../../utils/babylonUtils";
import "./customBabylonScene.scss";
import { MeshType } from "../../../enums/meshType.enum";
import BabylonLoader from "../BabylonLoader";
import MouthSlider from "../MouthSlider";
import { PositionProps } from "../../Types/TeethPosition";
import { MeshActions } from "../../Types/meshActions.type";
import { cleanAllMeshes } from "../../utils/babylonUtils";
import BabylonContainer from "../../../store/container/BabylonContainer";
import { BabylonReducerProps } from "../../../store/reducers/babylonReducer";

interface CustomBabylonSceneProps extends BabylonReducerProps {
  actions?: MeshActions;
  loadMeshToScene: boolean;
  meshBabylonFile?: File | string;
  meshType: MeshType;
  mouthSliderVisible?: boolean;
  onMeshLoad?: () => Promise<void>;
  customScene?: {
    scene: SceneModel;
    setScene: Dispatch<SetStateAction<SceneModel | undefined>>;
    meshLoading: boolean;
    setMeshLoading: (loading: boolean) => void;
  };
}

const CustomBabylonScene: FC<CustomBabylonSceneProps> = (props) => {
  const {
    customScene,
    actions,
    loadMeshToScene,
    meshType,
    meshBabylonFile,
    mouthSliderVisible,
    onMeshLoad,
    setHighlightLayer,
    setSourceObject,
    setShowPopover,
  } = props;

  const [positions, setPositions] = useState<PositionProps>({});

  const [isResetTeeth, setIsResetTeeth] = useState(true);

  const [first, setFirst] = useState(true);

  const [zAxisLimit, setZAxisLimit] = useState(0);

  const isFullTeeth = !!(
    meshType === MeshType.ADULT_TEETH || meshType === MeshType.CHILD_TEETH
  );

  const scene = customScene ? customScene?.scene : props?.scene;

  const setScene = customScene ? customScene?.setScene : props?.setScene;

  const meshLoading = customScene
    ? customScene?.meshLoading
    : props?.meshLoading;

  const setMeshLoading = customScene
    ? customScene?.setMeshLoading
    : props?.setMeshLoading;

  const showMouthSlider = isFullTeeth && !!mouthSliderVisible;

  // CONSTANTS
  const CAMERA_NAME = "camera1";

  const HIGHLIGHT_LAYER_NAME = "hl1";

  const LIGHT_NAME = "light1";

  const MATERIAL_NAME = "myMaterial";

  const handleDefaultEvent: React.DOMAttributes<HTMLElement>[
    | "onContextMenu"
    | "onContextMenuCapture"] = (e) => {
    e?.preventDefault();
    e?.stopPropagation();
  };

  const handleClicksOtherThanMesh = ({ type, pickInfo }: PointerInfo) => {
    const highlightLayer = scene?.getHighlightLayerByName(HIGHLIGHT_LAYER_NAME);
    const isClickedOutsideMesh = !pickInfo?.pickedMesh;
    const isPointerTap = type === PointerEventTypes.POINTERTAP;

    if (isPointerTap && highlightLayer && isClickedOutsideMesh) {
      highlightLayer.removeAllMeshes();
      setSourceObject(undefined);
      setShowPopover(false);
    }
  };

  const isStringType = (file?: string | File) => typeof file === "string";

  const getMeshRootURL = (file = meshBabylonFile): string =>
    isStringType(file) ? "" : "/";

  const getMeshFileName = (type = meshType): string => {
    switch (type) {
      case MeshType.CHILD_TEETH:
        return "childTeethSet.babylon";

      case MeshType.ADULT_TEETH:
        return "fullTeethSet.babylon";

      case MeshType.CORPORATE_OBJECT_TEETH:
      case MeshType.CORPORATE_OBJECT_ALIGNERS: {
        return isStringType(meshBabylonFile)
          ? "" + meshBabylonFile
          : `data:${JSON.stringify(meshBabylonFile)}`;
      }

      default:
        return "";
    }
  };

  const setupMesh = (mesh: AbstractMesh, scene: SceneModel) => {
    const highlightLayer = scene.getHighlightLayerByName(HIGHLIGHT_LAYER_NAME);

    if (mesh.getChildren().length) {
      const material = new StandardMaterial(MATERIAL_NAME, scene);
      material.diffuseTexture = new Texture("/texture.png", scene);
      mesh.material = material;

      if (actions) {
        mesh.actionManager = new ActionManager(scene);
        mesh.isPickable = true;

        Object.entries(actions)?.forEach(([trigger, actionHandler]) => {
          const action = new ExecuteCodeAction(+trigger, (evt) => {
            addHighlightLayer(mesh, highlightLayer);
            actionHandler({ evt, highlightLayer, mesh, scene });
          });

          mesh?.actionManager?.registerAction(action);
        });
      }
    }
  };

  const importMesh = async (scene: SceneModel) => {
    try {
      setMeshLoading?.(true);
      setIsResetTeeth(true);

      cleanAllMeshes(scene);
      scene?.onPointerObservable?.add(handleClicksOtherThanMesh);

      const { meshes } = await SceneLoader.ImportMeshAsync(
        null,
        getMeshRootURL(),
        getMeshFileName(),
        scene
      );

      let currentPositions = {};
      meshes?.forEach((mesh) => {
        setupMesh(mesh, scene);
        if (isFullTeeth) {
          if (mesh.name === "Lower_cavity" || mesh.name === "Upper_cavity")
            mesh.visibility = 0;

          const { _x, _y, _z } = mesh.position;
          currentPositions = {
            ...currentPositions,
            [mesh.name]: {
              x: _x,
              y: _y,
              z: _z,
              rotateX: mesh.rotation.x,
            },
          };

          const material = new StandardMaterial(MATERIAL_NAME, scene);
          material.diffuseTexture = new Texture("/texture.png", scene);
          mesh.material = material;

          if (mesh.name === "CentralIncisor11") setZAxisLimit(mesh.position.z);
        } else {
          mesh.position = Vector3.Zero();
          if (meshType === MeshType.CORPORATE_OBJECT_ALIGNERS) {
            mesh.material = null;
            mesh.visibility = 0.25;
          }
        }
      });

      setPositions(currentPositions);

      await onMeshLoad?.();
    } catch (ex) {
    } finally {
      setMeshLoading?.(false);
      setIsResetTeeth(false);
    }
  };

  useEffect(() => {
    if (scene && loadMeshToScene) importMesh(scene);
  }, [scene, loadMeshToScene]);

  useEffect(() => {
    return () => {
      setScene?.(undefined);
      setMeshLoading?.(false);
    };
  }, []);

  return (
    <div
      className="babylon-scene"
      onContextMenu={handleDefaultEvent}
      onContextMenuCapture={handleDefaultEvent}
    >
      {meshLoading && (
        <div className="babylon-scene__loader">
          <BabylonLoader loader={meshLoading} />
        </div>
      )}
      {scene && showMouthSlider && (
        <MouthSlider
          isResetTeethPosition={isResetTeeth}
          scene={scene}
          first={first}
          positions={positions}
          setFirst={setFirst}
          setPositions={setPositions}
          zAxisLimit={zAxisLimit}
        />
      )}
      <Engine
        antialias
        adaptToDeviceRatio
        canvasId="babylon-scene__canvas"
        width={500}
      >
        <Scene
          onSceneMount={(e) => {
            Database.IDBStorageEnabled = true;
            const { canvas, scene } = e;
            //@ts-ignore
            scene.clearColor = new Color3.Black();
            setHighlightLayer(new HighlightLayer(HIGHLIGHT_LAYER_NAME, scene));
            const hdrTexture = new HDRCubeTexture(
              "/studio_small_09_1k.hdr",
              scene,
              128,
              false,
              true,
              false,
              true
            );
            scene.environmentTexture = hdrTexture;
            setScene?.(scene);

            const camera = new ArcRotateCamera(
              CAMERA_NAME,
              -Math.PI / 2,
              Math.PI / 2,
              4,
              Vector3.Zero(),
              scene
            );

            camera.setTarget(Vector3.Zero());

            camera.attachControl(canvas, false);

            camera.lowerRadiusLimit = 4;

            camera.upperRadiusLimit = 10;

            const light = new HemisphericLight(
              LIGHT_NAME,
              new Vector3(0, 1, 0),
              scene
            );
            light.intensity = 0.5;
            scene.getEngine().runRenderLoop(() => {
              if (scene) {
                scene.render();
              }
            });
          }}
        >
          <arcRotateCamera
            name={CAMERA_NAME}
            target={Vector3.Zero()}
            alpha={Math.PI / 2}
            beta={Math.PI / 4}
            radius={8}
          />
          <hemisphericLight
            name={LIGHT_NAME}
            intensity={0.7}
            direction={Vector3.Up()}
          />
        </Scene>
      </Engine>
    </div>
  );
};

export default BabylonContainer(CustomBabylonScene);
