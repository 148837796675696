import { Button } from "antd";
import { Formik, Form } from "formik";
import React, { FC, useState } from "react";
import { Appointment } from "../../../../../models/Appointment/appointment.model";
import { Interaction } from "../../../../../models/Interaction/interaction.model";
import { InteractionService } from "../../../../../services/Interaction/Interaction.service";
import DropdownField from "../../../../../shared/components/DropdownField";
import { useResource } from "../../../../../shared/hooks/Resource/useResource";
import { useDoctors } from "../../../../../shared/hooks/User/useDoctors";
import { visitTypeOptions } from "../../../../Appointments/AppointmentForm";
import "./interactionForm.scss";

interface InteractionFormProps {
  appointment: Appointment;
  onCloseHandler: () => void;
  onFetchAppointment?: () => void;
}

const InteractionForm: FC<InteractionFormProps> = (props) => {
  const { appointment, onCloseHandler, onFetchAppointment } = props;

  const [loading, setLoading] = useState(false);

  const doctors = useDoctors("option");
  const resources = useResource("chair", "option");

  const handleSubmit = (values: Appointment) => {
    setLoading(true);
    const interaction = Object.assign(new Interaction(), {
      ...values,
      appointmentId: appointment.id,
    });
    InteractionService.createInteractions(
      interaction,
      () => {
        onFetchAppointment?.();
        onCloseHandler();
      },
      () => {},
      () => {
        setLoading(false);
      }
    );
  };

  return (
    <div className="interaction-form__container">
      <Formik initialValues={appointment} onSubmit={handleSubmit}>
        {({ values, setFieldValue, handleSubmit }) => (
          <Form>
            <div className="interaction-form__wrapper">
              <DropdownField
                title="Doctor"
                name="doctorProfileId"
                setFieldValue={setFieldValue}
                options={doctors}
                value={values.doctorProfileId}
              />
              <DropdownField
                title="visit Type"
                name="visitType"
                setFieldValue={setFieldValue}
                options={visitTypeOptions}
                value={values.visitType}
              />
              <DropdownField
                title="Resources"
                name="chairId"
                placeHolder="Select Resource types"
                setFieldValue={setFieldValue}
                options={resources}
                value={values.chairId}
              />
            </div>
            <div className="interaction-form__control">
              <Button type="text" onClick={onCloseHandler}>
                Cancel
              </Button>
              <Button
                type="primary"
                onClick={() => handleSubmit()}
                loading={loading}
              >
                Save
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default InteractionForm;
