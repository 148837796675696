import * as Yup from "yup";

const customFieldValidation = Yup.object().shape({
  name: Yup.string().required("Field name is required!"),
  type: Yup.string().required("Field type is required!"),
  customOptions: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required("Option name is required!"),
    })
  ),
});

export const customFormValidation = Yup.object().shape({
  name: Yup.string().required("Name is required!"),
  type: Yup.string().required("Type is required!"),
  description: Yup.string().required("Description is required!"),
  customFields: Yup.array().of(customFieldValidation),
  sections: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required("Section name is required"),
      customFields: Yup.array().of(customFieldValidation),
    })
  ),
});
