import {serializable, alias, primitive} from 'serializr';

export class Zipcode {

    @serializable(alias('id', primitive()))
    id?: string;

    @serializable(alias('code', primitive()))
    code?: string;

}
