import { CustomOption } from "../CustomOption/customOption.model";

import { serializable, alias, object, list, primitive } from "serializr";
import { IDropdownOptions } from "../../services/Meta/meta.service";

export class CustomField {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("name", primitive()))
  name?: string;

  @serializable(alias("type", primitive()))
  type?: string;

  @serializable(alias("is_mandatory", primitive()))
  isMandatory?: boolean = true;

  @serializable(alias("custom_options", list(object(CustomOption))))
  customOptions?: CustomOption[] = [];

  customDropdownOptions?: IDropdownOptions[] = [];

  getCustomDropdownOptions(): IDropdownOptions[] {
    if (this.customOptions) {
      return this.customOptions.map((customOption) => ({
        label: customOption.name,
        value: customOption.id,
      }));
    } else return [];
  }
}
