import { Tabs } from "antd";
import React, { FC, useEffect, useState } from "react";
import Dashboard from "../Dashboard";
import PaymentReceipt from "./PaymentReceipt";
import UnderDevelopment from "../../shared/components/UnderDevelopment";
import Reports from "./Reports";
import { ResponsibilityTypes } from "../../enums/responsebily.enum";
import useResponsibilities from "../../shared/hooks/Responsibilities/useResponsibilities";
interface PaymentWrapperProps {}

const PaymentWrapper: FC<PaymentWrapperProps> = (props) => {
  const { TabPane } = Tabs;

  const { hasAccess } = useResponsibilities();

  const [tab, setTab] = useState<string>("0");
  const {} = props;

  const HAS_PAYMENT_INVOICES_VIEW_ACCESS = hasAccess(
    ResponsibilityTypes.PAYMENT_INVOICES_VIEW
  );

  const HAS_PAYMENT_REPORTS_VIEW_ACCESS = hasAccess(
    ResponsibilityTypes.PAYMENT_REPORTS_VIEW
  );

  const handleChangeTab = (value: string) => {
    setTab(value);
  };

  const paymentTabs = [
    {
      title: "Payment Receipt",
      component: <PaymentReceipt />,
      hasAccess: HAS_PAYMENT_INVOICES_VIEW_ACCESS,
    },
    {
      title: "Reports",
      component: <Reports />,
      hasAccess: HAS_PAYMENT_REPORTS_VIEW_ACCESS,
    },
  ];

  return (
    <div className="payment-wrapper">
      <Tabs
        defaultActiveKey={tab}
        size="small"
        style={{ marginBottom: 32 }}
        onChange={handleChangeTab}
      >
        {paymentTabs.map(
          ({ title, component, hasAccess }, i) =>
            hasAccess && (
              <TabPane tab={title} key={i}>
                {component}
              </TabPane>
            )
        )}
      </Tabs>
    </div>
  );
};

export default PaymentWrapper;
