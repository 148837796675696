import React, { useState } from "react";
import "./interactionTimeline.scss";
import InteractionContainer from "../../../store/container/InteractionContainer";
import { InteractionReducerProps } from "../../../store/reducers/interactionReducer";
import { Interaction } from "../../../models/Interaction/interaction.model";
import { Col, Popover, Row } from "antd";
import InteractionVisitDetails from "../InteractionDetail/InteractionVisitDetails";
import { integrateInteractions } from "../../../shared/utils/integrateTreatmentSteps";
import useToggle from "../../../shared/hooks/useToggle/useToggler";
import BabylonContainer from "../../../store/container/BabylonContainer";
import { BabylonReducerProps } from "../../../store/reducers/babylonReducer";
import CorporateContainer from "../../../store/container/CorporateContainer";
import CustomBabylonScene from "../../../shared/components/CustomBabylonScene";
import { MeshType } from "../../../enums/meshType.enum";
import { Scene } from "@babylonjs/core";
import TimelineInteraction from "./TimelineInteraction";

interface InteractionTimelineProps
  extends InteractionReducerProps,
    BabylonReducerProps {
  meshType: MeshType;
}

function InteractionTimeline({
  activeInteractionId,
  contractInteractions,
  meshType,
}: InteractionTimelineProps) {
  const leftWidth = 100 / (contractInteractions?.length - 1);

  return (
    <div className="interaction-timeline">
      <div className="interaction-timeline__track" />
      <div className="interaction-timeline__items">
        {[...contractInteractions]
          ?.sort(
            (interaction1, interaction2) =>
              Number(interaction1?.visitNo) - Number(interaction2?.visitNo)
          )
          ?.map((interaction, i) => (
            <Popover
              placement="bottomRight"
              overlayClassName="current-interaction__detail"
              content={() => (
                <TimelineInteraction
                  meshType={meshType}
                  interaction={interaction}
                />
              )}
              key={interaction?.id}
            >
              <span
                className={`interaction-timeline__track-item ${
                  activeInteractionId === interaction?.id ? "active" : ""
                }`}
                style={{
                  left:
                    i === 0
                      ? 0
                      : i === contractInteractions?.length - 1
                      ? "auto"
                      : `${i * leftWidth}%`,
                  right: i === contractInteractions?.length - 1 ? "0" : "auto",
                }}
              />
            </Popover>
          ))}
      </div>
    </div>
  );
}

export default BabylonContainer(
  CorporateContainer(InteractionContainer(InteractionTimeline))
);
