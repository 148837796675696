import {
    Scene,
    Vector3,
    StandardMaterial,
    Color3,
    Curve3,
    MeshBuilder,
    PhysicsImpostor,
    AbstractMesh,
    Mesh,
} from "@babylonjs/core";

export default class Elastic {
    static generatePath = (locators: Vector3[]) => {
        const leftLine = locators.map(
            (locator) => new Vector3(locator.x - 0.1, locator.y, locator.z)
        );
        const upperCurve = Curve3.CreateQuadraticBezier(
            leftLine[0],
            new Vector3(
                leftLine[0].x + 0.1,
                leftLine[0].y + 0.15,
                leftLine[0].z
            ),
            new Vector3(leftLine[0].x + 0.2, leftLine[0].y, leftLine[0].z),
            25
        );

        const rightLine = leftLine.map(
            (locator) => new Vector3(locator.x + 0.2, locator.y, locator.z)
        );
        const lowerCurve = Curve3.CreateQuadraticBezier(
            rightLine[1],
            new Vector3(
                rightLine[1].x - 0.1,
                rightLine[1].y - 0.15,
                rightLine[1].z
            ),
            leftLine[1],
            60
        );

        return [
            ...rightLine,
            ...lowerCurve.getPoints(),
            ...leftLine,
            ...upperCurve.getPoints(),
        ];
    };

    static getShape = () => {
        const myShape = [];
        const radius = 0.05;
        for (let i = 0; i < 2 * Math.PI + 0.01; i += Math.PI / 8) {
            myShape.push(
                new Vector3(Math.cos(i), Math.sin(i), 0).scale(radius)
            );
        }
        return myShape;
    };

    static drawElastic = (locators: Vector3[], name: string, scene: Scene) => {
        if (locators.length) {
            const nbPoints = 30;
            const myPoints = [];
            for (let i = 0; i < nbPoints; i++) {
                myPoints.push(
                    new Vector3(-20, 15, 0),
                    new Vector3(20 + (10 * i) / nbPoints, 15, 0)
                );
            }


            const elasticMat = new StandardMaterial(`${name}-material`, scene);
            elasticMat.diffuseColor = Color3.Red();

            const path = Elastic.generatePath(locators);

            const elastic = MeshBuilder.ExtrudeShape(
                name,
                { shape: Elastic.getShape(), path, updatable: true },
                scene
            );
            elastic.material = elasticMat;
        }
    };
    static updateElastic = (
        vendorObjects: AbstractMesh[],
        name: string,
        scene: Scene
    ) => {
        try {
            const [toothName1, toothName2] = name.split("-");
            const elastic = scene.getMeshByName(name);
            const tooth1 = scene.getMeshByName(toothName1);
            const tooth2 = scene.getMeshByName(toothName2);
            if(vendorObjects.length) {
                const path = Elastic.generatePath(vendorObjects.map(vendorObject => vendorObject.absolutePosition));
                MeshBuilder.ExtrudeShape(name, {
                    shape: Elastic.getShape(),
                    path,
                    instance: elastic as Mesh,
                });

            }
        } catch (error) {
        }
    };
}
