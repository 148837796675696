import ApiRoutes from "../../routes/routeConstants/apiRoutes";
import axiosInstance from "../../interceptor/axiosInstance";
import { Service, Servicemetrics } from "../../models/Service/service.model";
import { deserialize, serialize } from "serializr";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import { PaginationDetails } from "../../models/Pagination/pagination.model";
import { FilterParams } from "../../models/FilterParams/filterParams.model";

export default class ServiceService {
  static fetchServices(
    onSuccess: (
      services: Service[],
      metrics: Servicemetrics,
      pagination: PaginationDetails
    ) => void,
    onError: Function,
    onFinal: () => void,
    filter?: FilterParams
  ) {
    const params = serialize(FilterParams, filter ?? new FilterParams());
    axiosInstance
      .get(ApiRoutes.SERVICES, { params })
      .then((response) => {
        const services = deserialize(
          Service,
          response.data["services"] as any[]
        );
        const metrics = deserialize(Servicemetrics, response.data["metrics"]);
        const pagination = deserialize(
          PaginationDetails,
          response.data["meta"]
        );
        onSuccess(services, metrics, pagination);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static fetchServiceCategories(
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .get(ApiRoutes.SERVICE_CATEGORIES)
      .then((response) => {
        const services = deserialize(Service, response.data["services"]);
        onSuccess(services);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static showService(
    serviceId: string,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.SERVICES + "/" + serviceId;
    axiosInstance
      .get(API_URL)
      .then((response) => {
        const service = deserialize(Service, response.data["service"]);
        onSuccess(service);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static createService(
    service: Service,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const serviceJSON = { service: serialize(service) };
    axiosInstance
      .post(ApiRoutes.SERVICES, serviceJSON)
      .then((response) => {
        Notification({
          message: "Service created!",
          type: NotificationTypes.SUCCESS,
        });
        const service = deserialize(Service, response.data["service"]);
        onSuccess(service);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static updateService(
    service: Service,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.SERVICES + "/" + service.id;
    const serviceJSON = { service: serialize(service) };
    axiosInstance
      .put(API_URL, serviceJSON)
      .then((response) => {
        Notification({
          message: "Service updated!",
          type: NotificationTypes.SUCCESS,
        });
        const service = deserialize(Service, response.data["service"]);
        onSuccess(service);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static deleteService(
    serviceId: number,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.SERVICES + "/" + serviceId;
    axiosInstance
      .delete(API_URL)
      .then((response) => {
        Notification({
          message: "Service deleted!",
          type: NotificationTypes.SUCCESS,
        });
        onSuccess();
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }
}
