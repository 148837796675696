import { alias, list, object, primitive, serializable } from "serializr";
import { CustomField } from "../CustomField/customField.model";

export class CustomSection {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("name", primitive()))
  name?: string;

  @serializable(alias("custom_fields", list(object(CustomField))))
  customFields?: CustomField[] = [];
}
