import * as Yup from "yup";

const validation = Yup.object().shape({
  availabilityTypes: Yup.array()
    .min(1, "Appointment Type is Required")
    .label("Appointment Type"),
  practiceId: Yup.number().required(),
  startTime: Yup.string().required().label("Start time"),
  endTime: Yup.string().required().label("End time").nullable(),
});

export default validation;
