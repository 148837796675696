
import {serializable, alias, object, list, primitive} from 'serializr';
import { CorporateLink } from '../CorporateLink/corporateLink.model';
import { DentalSet } from '../DentalSet/dentalSet.model';
import { Procedure } from "../Procedure/procedure.model";

export class ConnectorSteps {
	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('connector_id', primitive()))
	connectorId?: number;

	@serializable(alias('object_locator_id', primitive()))
	objectLocatorId?: number;

	@serializable(alias('order', primitive()))
	order?: number;

	@serializable(alias('tooth_position_id', primitive()))
	toothPositionId?: number;

	@serializable(alias('tooth_position_name', primitive()))
	toothPositionName?: string;
}

export class Connector { 

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('diameter', primitive()))
	diameter?: number;

	@serializable(alias('color', primitive()))
	color?: string;

	@serializable(alias('object_type', primitive()))
	objectType?: string;

	@serializable(alias('connector_steps', list(object(ConnectorSteps))))
	connectorSteps: ConnectorSteps[] = [];
}


export class TreatmentPlanTemplateSteps { 

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('note', primitive()))
	note?: string;

	@serializable(alias('object_link', object(CorporateLink)))
	objectLink?: CorporateLink;

	@serializable(alias('set', object(DentalSet)))
	set: DentalSet = new DentalSet();

	@serializable(alias('procedure', object(Procedure)))
	procedure: Procedure = new Procedure();

	@serializable(alias('tooth_position_name', primitive()))
	toothPositionName?: string;

	@serializable(alias('dental_object', primitive()))
	dentalObject?: string;

	@serializable(alias('corporate_link_id', primitive()))
	corporateLinkId?: number;

	@serializable(alias('action_type', primitive()))
	actionType?: string = "link_add";

	@serializable(alias('procedure_id', primitive()))
	procedureId?: number;

	@serializable(alias('set_id', primitive()))
	setId?: number;

	@serializable(alias('tooth_position_id', primitive()))
	toothPositionId?: number;

	@serializable(alias('tooth_object_id', primitive()))
	toothObjectId?: string;

	@serializable(alias('connector_id', primitive()))
	connectorId?: string;

	@serializable(alias('connector', object(Connector)))
	connector?: Connector;

	@serializable(alias('order', primitive()))
	order?: number;

	@serializable(alias('dest_object_id', primitive()))
	destObjectId?: number;
}