import ApiRoutes from "../../routes/routeConstants/apiRoutes";
import axiosInstance from "../../interceptor/axiosInstance";
import { Schedule } from "../../models/Schedule/schedule.model";
import { deserialize, serialize } from "serializr";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import { User } from "../../models/User/user.model";

export default class ScheduleService {
  static fetchSchedules(
    startDate: string,
    endDate: string,
    roleId: number,
    pageSize: number,
    pageIndex: number,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .get(ApiRoutes.PRACTICE_SCHEDULES, {
        params: {
          start_date: startDate,
          end_date: endDate,
          role_id: roleId ? roleId.toString() : "",
          // page_size: pageSize.toString(),
          // page_index: pageIndex.toString(),
        },
      })
      .then((response) => {
        try {
          const users = deserialize(User, response.data["profiles"]);
          onSuccess(users);
        } catch (error) {}
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static showUserSchedule(
    userId: number,
    startDate: string,
    endDate: string,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.USER_SCHEDULES.replace(
      ":userId",
      userId.toString()
    );
    axiosInstance
      .get(API_URL, { params: { start_date: startDate, end_date: endDate } })
      .then((response) => {
        const user = deserialize(User, response.data["profile"]);
        onSuccess(user);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static createSchedule(
    schedule: Schedule,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const scheduleJSON = { schedule: serialize(schedule) };
    axiosInstance
      .post(ApiRoutes.PRACTICE_SCHEDULES, scheduleJSON)
      .then((response) => {
        // const schedule = deserialize(Schedule, response.data["schedule"]);
        Notification({
          message: "New Schedule Created!",
          type: NotificationTypes.SUCCESS,
        });
        onSuccess();
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static updateSchedule(
    schedule: Schedule,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.PRACTICE_SCHEDULES + "/" + schedule.id;
    const scheduleJSON = serialize(schedule);
    axiosInstance
      .put(API_URL, scheduleJSON)
      .then((response) => {
        const schedule = deserialize(Schedule, response.data["schedule"]);
        onSuccess(schedule);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static deleteSchedule(
    scheduleId: string,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.PRACTICE_SCHEDULES + "/" + scheduleId;
    axiosInstance
      .delete(API_URL)
      .then((response) => {
        onSuccess();
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }
}
