import {
  serializable,
  alias,
  object,
  list,
  primitive,
  custom,
} from "serializr";

export class InsuranceSchedule {
  @serializable(alias("due_no", primitive()))
  dueNo?: number;
  @serializable(alias("due_date", primitive()))
  dueDate?: string;
  @serializable(alias("claim_amount", primitive()))
  claimAmount?: number;
  @serializable(alias("expected_amount", primitive()))
  expectedAmount?: number;
  @serializable(alias("procedure_code", primitive()))
  procedureCode?: string;
}

export class InsuranceProvider {
  @serializable(alias("id", primitive()))
  id?: number;
  @serializable(alias("name", primitive()))
  name?: string;
  @serializable(alias("insurance_provider_id", primitive()))
  insuranceProviderId?: number;
  @serializable(alias("certificate_number", primitive()))
  certificateNumber?: number;
  @serializable(alias("group_number", primitive()))
  groupNumber?: string;
  @serializable(alias("policy_number", primitive()))
  policyNumber?: number;
  @serializable(alias("category", primitive()))
  category?: string;
  @serializable(alias("amount", primitive()))
  amount?: number;
  @serializable(alias("status", primitive()))
  status?: string;
  @serializable(alias("insurance_provider_name", primitive()))
  insuranceProviderName?: string;
  @serializable(alias("lifetime_max_amount", primitive()))
  lifetimeMaxAmount?: number;
  @serializable(alias("payout_ratio", primitive()))
  payoutRatio?: number;
  @serializable(alias("overall_claim_amount", primitive()))
  overallClaimAmount?: number;
  @serializable(alias("overall_expected_amount", primitive()))
  overallExpectedAmount?: number;
  @serializable(alias("initial_claim_amount", primitive()))
  initialClaimAmount?: number;
  @serializable(alias("initial_expected_amount", primitive()))
  initialExpectedAmount?: number;
  @serializable(alias("no_of_dues", primitive()))
  noOfDues?: number;
  @serializable(alias("due_time", primitive()))
  dueTime?: string;
  @serializable(alias("insurance_schedules", list(object(InsuranceSchedule))))
  insuranceSchedule?: InsuranceSchedule[] = [];
  @serializable(alias("remaining_claim_amount", primitive()))
  remainingClaimAmount?: number;
  @serializable(alias("remaining_expected_amount", primitive()))
  remainingExpectedAmount?: number;
  @serializable(alias("subscriber_name", primitive()))
  subscriberName?: string;
  @serializable(alias("subscriber_relation_to_patient", primitive()))
  subscriberRelationToPatient?: string;
  @serializable(alias("subscriber_dob", primitive()))
  subscriberDob?: string;

  insurancePayoutRatio?: number;
}
