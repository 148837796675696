import { serializable, alias, object, list, primitive } from "serializr";

export class Clock {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("user_profile_id", primitive()))
  userProfileId?: number;

  @serializable(alias("date", primitive()))
  date?: string;

  @serializable(alias("start_time", primitive()))
  startTime?: string;

  @serializable(alias("total_clock_time", primitive()))
  totalClockTime?: string;

  @serializable(alias("commentor_first_name", primitive()))
  commentorFirstName?: string;

  @serializable(alias("end_time", primitive()))
  endTime?: string;

  @serializable(alias("last_action_performed", primitive()))
  lastActionPerformed?: string;
}
