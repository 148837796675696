import { serializable, alias, object, list, primitive } from "serializr";

export class StatementTemplateCategory {
    @serializable(alias("id", primitive()))
    id?: number;

    @serializable(alias("name", primitive()))
    name?: string;

    @serializable(alias("id", primitive()))
    key?: number;

    @serializable(alias("id", primitive()))
    value?: number;

    @serializable(alias("name", primitive()))
    label?: string;

}

export class StatementTemplateCategories {
    @serializable(alias("statement_template_categories", list(object(StatementTemplateCategory))))
    statementTemplateCategories: StatementTemplateCategory[] = [];
}