import { CustomResponse } from "../CustomResponse/customResponse.model";

import { serializable, alias, object, list, primitive } from "serializr";

export class CustomResponseField {
  @serializable(alias("custom_field_id", primitive()))
  customFieldId?: number;

  @serializable(alias("custom_field_name", primitive()))
  customFieldName?: string;

  @serializable(alias("custom_field_type", primitive()))
  customFieldType?: string;

  @serializable(alias("custom_responses", list(object(CustomResponse))))
  customResponses?: CustomResponse[] = [];

  isMandatory?: boolean;
}
