import { Modal } from "antd";
import React from "react";
import { ResponsibilityTypes } from "../../../../../../enums/responsebily.enum";
import { AppointmentService } from "../../../../../../services/Appointment/appointment.service";
import useResponsibilities from "../../../../../../shared/hooks/Responsibilities/useResponsibilities";
import { CalendarSlot } from "../../../../../../shared/Types/CalendarSlot.type";
import { formatTime } from "../../../../../../shared/utils/time";

import "./unAllocatedSlotPopup.scss";

interface UnAllocatedSlotPopupProps {
  slot: CalendarSlot;
  onChangeAppointmentTypeVisibleChange?: () => void;
  onCreateAppointmentVisibleChange?: () => void;
  onUnAllocatedSlotVisibleChange?: () => void;
  onSlotDelete?: (oldSlotId: number, resourceId: number) => void;
}

const UnAllocatedSlotPopup = ({
  slot: { schedule, templateId, resourceId },
  onChangeAppointmentTypeVisibleChange,
  onCreateAppointmentVisibleChange,
  onUnAllocatedSlotVisibleChange,
  onSlotDelete,
}: UnAllocatedSlotPopupProps) => {
  const { hasAccess } = useResponsibilities();

  const HAS_APPOINTMENT_CREATE_ACCESS = hasAccess(
    ResponsibilityTypes.APPOINTMENT_CREATE
  );

  const HAS_STATUS_APPOINTMENT_TYPE_EDIT_ACCESS = hasAccess(
    ResponsibilityTypes.STATUS_APPOINTMENT_TYPE_EDIT
  );

  const { startTime, endTime, appointmentTypeTitle, id } = schedule;

  const handleSlotDelete = () => {
    AppointmentService.deleteAppointmentDayScheduleSlot(
      templateId ? String(templateId) : "",
      schedule.id ? String(schedule.id) : "",
      () => {
        onSlotDelete?.(Number(id), +resourceId);
      },
      () => {},
      () => {}
    );
  };

  const handleCreateAppointment: React.DOMAttributes<HTMLElement>["onClick"] = (
    evt
  ) => {
    if (!HAS_APPOINTMENT_CREATE_ACCESS) return;

    evt?.stopPropagation();
    onCreateAppointmentVisibleChange?.();
    onUnAllocatedSlotVisibleChange?.();
  };

  const handleChangeAppointmentType: React.DOMAttributes<HTMLElement>["onClick"] = (
    evt
  ) => {
    if (!HAS_STATUS_APPOINTMENT_TYPE_EDIT_ACCESS) return;

    evt?.stopPropagation();
    onUnAllocatedSlotVisibleChange?.();
    onChangeAppointmentTypeVisibleChange?.();
  };

  const handleDeleteClick: React.DOMAttributes<HTMLElement>["onClick"] = (
    evt
  ) => {
    evt?.stopPropagation();
    onUnAllocatedSlotVisibleChange?.();
    Modal.confirm({
      title: `Are you sure, do you want to delete ${appointmentTypeTitle} appointment type from slot ${formatTime(
        startTime
      )} - ${formatTime(endTime)}`,
      centered: true,
      onOk: handleSlotDelete,
    });
  };

  return (
    <div className="un-allocated-slot-popup">
      {HAS_APPOINTMENT_CREATE_ACCESS && (
        <div onClick={handleCreateAppointment}>Create Appointment</div>
      )}
      {HAS_STATUS_APPOINTMENT_TYPE_EDIT_ACCESS && (
        <div onClick={handleChangeAppointmentType}>Change Appointment Type</div>
      )}
      <div onClick={handleDeleteClick}>Delete Appointment Slot</div>
    </div>
  );
};

export default UnAllocatedSlotPopup;
