export const getDropdownOptions = <T>(
  dataList: T[],
  labelKey: keyof T,
  valueKey: keyof T
) =>
  dataList?.map((data) => ({
    ...data,
    label: data[labelKey],
    value: data[valueKey],
  }));
