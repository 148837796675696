import { PaymentModeEnum } from "enums/paymentMode.enum";
import { serializable, alias, primitive } from "serializr";

export class PaymentFilters {
  @serializable(alias("from_date", primitive()))
  fromDate?: string;
  @serializable(alias("to_date", primitive()))
  toDate?: string;
  @serializable(alias("month", primitive()))
  month?: string;
  @serializable(alias("start_month", primitive()))
  startMonth?: string;
  @serializable(alias("end_month", primitive()))
  endMonth?: string;

  @serializable(alias("payment_type", primitive()))
  paymentType?: string;
  @serializable(alias("payment_mode", primitive()))
  paymentMode?: PaymentModeEnum;
  @serializable(alias("status", primitive()))
  status?: string;
  @serializable(alias("page_index", primitive()))
  page?: string;

  @serializable(alias("patient_profile_id", primitive()))
  patientProfileId?: number | string;
}
export class Payment {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("payment_type", primitive()))
  paymentType?: string;

  @serializable(alias("patient_id", primitive()))
  patientId?: number;

  @serializable(alias("contract_id", primitive()))
  contractId?: number;

  @serializable(alias("payment_date", primitive()))
  paymentDate?: number;
}
