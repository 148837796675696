import { alias, primitive, serializable } from "serializr";
import moment from "moment";

export class DoctorAppointmentDate {
  @serializable(alias("date", primitive()))
  date?: string;

  @serializable(alias("no_of_appointments", primitive()))
  noOfAppointments?: number;

  parseDate = (format = "MM/DD/YYYY") =>
    this.date ? moment.utc(this.date).format(format) : "-";
}

export class DoctorAppointmentDateParams {
  constructor(doctorProfileId: number) {
    this.doctorProfileId = doctorProfileId;
  }

  @serializable(alias("doctor_profile_id", primitive()))
  doctorProfileId: number;
}
