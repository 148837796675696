import React, { FC, useEffect, useState } from "react";
import { Formik, Form } from "formik";
import "./statementNotesForm.scss";
import InputField from "../../../../shared/components/InputField";
import { Button, Radio } from "antd";
import { useParams } from "react-router";
import { CommentNote } from "../../../../models/CommentNote/commentNote.model";
import CommentNotesService from "../../../../services/CommentNotes/commentNotes.service";

interface CommentNotesFormProps {
  update: () => void;
  onClose?: () => void;
}

const options = ["Patient", "Visit", "Treatment"];

const CommentNotesForm: FC<CommentNotesFormProps> = (props) => {
  const { update,onClose } = props;

  const params: { interactionId: string } = useParams();

  const handleSubmit = (values: CommentNote) => {
    const comment = Object.assign(new CommentNote(), values);
    CommentNotesService.createCommentNotes(
      Number(params.interactionId),
      comment,
      () => {
        update();
        onClose?.();
      },
      () => {},
      () => {}
    );
  };

  return (
    <div className="comment-notes-form">
      <h5>Add comment</h5>
      <Formik initialValues={new CommentNote()} onSubmit={handleSubmit}>
        {({ setFieldValue }) => (
          <Form>
            <div className="mb-5">
              <Radio.Group
                className="dark__radio"
                onChange={(event) => {
                  setFieldValue("type", event.target.value);
                }}
              >
                {options?.map((value, index) => (
                  <Radio value={value?.toLowerCase()} key={index}>
                    {value}
                  </Radio>
                ))}
              </Radio.Group>
            </div>
            <InputField
              darkmode
              type="text"
              placeholder="Add text"
              name="notes"
            />
            <div className="controller">
              <Button htmlType="submit" type="primary">
                Add
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CommentNotesForm;
