import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "antd";
import "./registerWrapper.scss";
import logo from "../../../../assets/images/logoVerticalWhite.png";
import RegisterContainer from "../../../../store/container/RegisterContainer";
import { useHistory } from "react-router-dom";
import RegisterBasicDetailsForm from "../RegisterBasicDetailsForm";
import RegisterBusinessDetailsForm from "../RegisterBusinessDetailsForm";
import RegisterPaymentDetailsForm from "../RegisterPaymentDetailsForm";
import { LeftOutlined } from "@ant-design/icons";
import { RegisterReducerProps } from "../../../../store/reducers/registerReducer";
import AuthContainer from "../../../../store/container/AuthContainer";
import AppRoutes from "../../../../routes/routeConstants/appRoutes";
import { AuthReducerProps } from "../../../../store/reducers/authReducer";
import RegisterSuccessPage from "../RegisterSuccessPage";

interface RegisterWrapperProps extends RegisterReducerProps, AuthReducerProps {}

function RegisterWrapper({
  registerActiveStep,
  setRegisterActiveStep,
  authenticated,
}: RegisterWrapperProps) {
  const history = useHistory();

  const { pathname } = history.location;

  const handleBack = () => setRegisterActiveStep(registerActiveStep - 1);

  const registerComponents = [
    { title: "Corporate Details", component: <RegisterBasicDetailsForm /> },
    {
      title: "Corporate Documents",
      component: <RegisterBusinessDetailsForm />,
    },
    { title: "Subscription", component: <RegisterPaymentDetailsForm /> },
    { title: "Register success", component: <RegisterSuccessPage /> },
  ];

  useEffect(() => {
    if (authenticated) {
      history.push(AppRoutes.DASHBOARD);
    }
  }, [authenticated, history]);

  return (
    <Row className="register-wrapper">
      <Col span={7} className="register-wrapper__background-wrapper">
        <div className="register-wrapper__background-content">
          <h3>SIGN UP</h3>
          <img
            src={logo}
            alt="Aligner 4D logo"
            className="register-wrapper__logo"
          />
        </div>
      </Col>
      <Col span={17} className="register-wrapper__content-wrapper">
        <div className="register-wrapper__header">
          <h4 className="text-secondary">
            {registerActiveStep > 0 && (
              <Button
                onClick={handleBack}
                className="mr-3"
                icon={<LeftOutlined />}
              />
            )}
            {registerComponents[registerActiveStep].title}
          </h4>
          <div className="register-wrapper__stepper">
            <span className="mr-3"> Step {registerActiveStep + 1} / 3 </span>
            {[...Array(3)].map((x, i) => (
              <span
                key={i}
                className={`register-wrapper__step-item ${
                  i <= registerActiveStep ? "active" : ""
                }`}
              />
            ))}
          </div>
        </div>
        <div className="register-wrapper__route-wrapper">
          {registerComponents[registerActiveStep].component}
        </div>
      </Col>
    </Row>
  );
}

export default AuthContainer(RegisterContainer(RegisterWrapper));
