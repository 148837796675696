import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";
import * as ContractActions from "../actions/contractAction";
import { RootReducerProps } from "../reducers";

const mapStateToProps = (state: RootReducerProps, ownProps: any) => {
  return {
    ...ownProps,
    contract: state.contract.contract,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(ContractActions, dispatch);

const ContractContainer = (component: any) => {
  return connect(mapStateToProps, mapDispatchToProps)(component);
};

export default ContractContainer;
