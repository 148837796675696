import { deserialize, serialize } from "serializr";
import { NotificationTypes } from "../../enums/notificationTypes";
import axiosInstance from "../../interceptor/axiosInstance";
import { FilterParams } from "../../models/FilterParams/filterParams.model";
import { PaginationDetails } from "../../models/Pagination/pagination.model";
import { StatementNote } from "../../models/StatementNote/statementNote.model";
import { TreatmentTemplate } from "../../models/TreamentTemplate/treatmentTemplate.model";
import { TreatmentPlanTemplate } from "../../models/TreatmentPlanTemplate/treatmentPlanTemplate.model";
import { TreatmentPlanTemplateStatementNotes } from "../../models/TreatmentPlanTemplateStatementNotes/treatmentPlanTemplateStatementNotes.model";
import { TreatmentPlanTemplateSteps } from "../../models/TreatmentPlanTemplateSteps/treatmentPlanTemplateSteps.model";
import { TreatmentPlanTemplateVisit } from "../../models/TreatmentPlanTemplateVisit/treatmentPlanTemplateVisit.model";
import { TreatmentPlanTemplateVisits } from "../../models/TreatmentPlanTemplateVisits/treatmentPlanTemplateVisits.model";
import ApiRoutes from "../../routes/routeConstants/apiRoutes";
import Notification from "../../shared/components/Notification";
export class TreatmentTemplateService {
  static fetchTreatmentTemplate(
    filter: FilterParams,
    onSuccess: (
      treatmentTemplates: TreatmentTemplate[],
      pagination: PaginationDetails
    ) => void,
    onError: (error: Error) => void,
    onFinal: () => void
  ) {
    const params = serialize(FilterParams, filter);
    return axiosInstance
      .get(ApiRoutes.TREATMENT_TEMPLATE, { params })
      .then((response) => {
        const treatmentTemplates = deserialize(
          TreatmentTemplate,
          response.data["treatment_plan_templates"] as any[]
        );
        const pagination = deserialize(
          PaginationDetails,
          response.data["meta"]
        );
        onSuccess(treatmentTemplates, pagination);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(onFinal);
  }

  static createTreatmentPlan(
    treatmentTemplate: TreatmentTemplate,
    onSuccess: (treatmentTemplate: TreatmentTemplate) => void,
    onError: (error: Error) => void,
    onFinal: () => void
  ) {
    const TreatmentPlanJONResponse = {
      treatment_plan_template: serialize(treatmentTemplate),
    };

    return axiosInstance
      .post(ApiRoutes.TREATMENT_TEMPLATE, TreatmentPlanJONResponse)
      .then((response) => {
        const treatmentTemplates = deserialize(
          TreatmentTemplate,
          response.data["treatment_plan_template"]
        );
        onSuccess(treatmentTemplates);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(onFinal);
  }

  static cloneTreatmentPlan(
    treatmentTemplate: TreatmentTemplate,
    onSuccess: (treatmentTemplate: TreatmentTemplate) => void,
    onError: (error: Error) => void,
    onFinal: () => void
  ) {
    const TreatmentPlanJONResponse = {
      treatment_plan_template: serialize(treatmentTemplate),
    };
    const URL = ApiRoutes.TREATMENT_PLAN_TEMPLATE_CLONE.replace(
      ":treatmentTemplateId",
      (treatmentTemplate?.id as number).toString()
    );
    return axiosInstance
      .post(URL, TreatmentPlanJONResponse)
      .then((response) => {
        const treatmentTemplates = deserialize(
          TreatmentTemplate,
          response.data["treatment_plan_template"]
        );
        onSuccess(treatmentTemplates);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(onFinal);
  }

  static updateTreatmentPlan(
    treatmentTemplate: TreatmentTemplate,
    onSuccess: (treatmentTemplate: TreatmentTemplate) => void,
    onError: (error: Error) => void,
    onFinal: () => void
  ) {
    const TreatmentPlanJONResponse = {
      treatment_plan_template: serialize(TreatmentTemplate, treatmentTemplate),
    };
    const URL = `${ApiRoutes.TREATMENT_TEMPLATE}/${treatmentTemplate.id}`;

    return axiosInstance
      .put(URL, TreatmentPlanJONResponse)
      .then((response) => {
        const treatmentTemplates = deserialize(
          TreatmentTemplate,
          response.data["treatment_plan_template"]
        );
        onSuccess(treatmentTemplates);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(onFinal);
  }

  static getTreatmentPlanDetails(
    treatmentTemplateId: string,
    onSuccess: (treatmentPlanTemplate: TreatmentPlanTemplate) => void,
    onError: (error: Error) => void,
    onFinal: () => void
  ) {
    // const mock = JSON.parse(JSON.stringify(mockData));
    // const treatmentPlanTemplate = deserialize(
    // 	TreatmentPlanTemplate,
    // 	mock
    // );
    // setTimeout(() => {
    // 	onSuccess(treatmentPlanTemplate);

    // }, 500)
    const URL = `${ApiRoutes.TREATMENT_TEMPLATE}/${treatmentTemplateId}`;
    return axiosInstance
      .get(URL)
      .then((response) => {
        const treatmentPlanTemplate = deserialize(
          TreatmentPlanTemplate,
          response.data?.["treatment_plan_template"]
        );
        onSuccess(treatmentPlanTemplate);
      })
      .catch(onError)
      .finally(onFinal);
  }

  static createStatementNote(
    payload: {
      treatmentPlanTemplateId: string;
      treatmentPlanTemplateAppointmentId: string;
      statementNote: StatementNote;
    },
    onSuccess: (statementNote: TreatmentPlanTemplateStatementNotes) => void,
    onError: (error: Error) => void,
    onFinal: () => void
  ) {
    const {
      treatmentPlanTemplateId,
      treatmentPlanTemplateAppointmentId,
      statementNote,
    } = payload;
    const URL = ApiRoutes.TREATMENT_PLAN_TEMPLATE_STATEMENT_NOTES.replace(
      ":treatmentPlanTemplateId",
      treatmentPlanTemplateId
    ).replace(":id", treatmentPlanTemplateAppointmentId);
    const StatementNoteJSON = {
      treatment_plan_template_statement_note: {
        statement_template_id: statementNote.statementTemplateId,
      },
    };
    return axiosInstance
      .post(URL, StatementNoteJSON)
      .then((response) => {
        const statementNote = deserialize(
          TreatmentPlanTemplateStatementNotes,
          response.data["treatment_plan_template_statement_note"]
        );
        onSuccess(statementNote);
        Notification({
          message: "Statement Note created",
          type: NotificationTypes.SUCCESS,
        });
      })
      .catch(onError)
      .finally(onFinal);
  }

  static createTreatmentStepInformation(
    id: string,
    treatmentPlanTemplateVisit: TreatmentPlanTemplateVisit,
    onSuccess: () => void,
    onError: (error: Error) => void,
    onFinal: () => void
  ) {
    const treatmentStepJSON = {
      treatment_plan_template_visit: serialize(treatmentPlanTemplateVisit),
    };
    const URL = ApiRoutes.TREATMENT_PLAN_TEMPLATE_STEPS.replace(":id", id);
    return axiosInstance
      .post(URL, treatmentStepJSON)
      .then((response) => {
        Notification({
          message: "Treatment Step Updated!",
          type: NotificationTypes.SUCCESS,
        });
        onSuccess();
      })
      .catch(onError)
      .finally(onFinal);
  }

  static createStep(
    options: {
      treatmentPlanTemplateId: string;
      id: string;
    },
    payload: TreatmentPlanTemplateSteps,
    onSuccess: (treatmentPlanTemplateSteps: TreatmentPlanTemplateSteps) => void,
    onError: () => void,
    onFinal: () => void
  ) {
    const treatmentPlanTemplatePayload = {
      treatment_plan_template_step: serialize(payload),
    };
    const URL = ApiRoutes.INTERACTION_TREATMENT_PLAN_STEP.replace(
      ":treatmentPlanTemplateId",
      options.treatmentPlanTemplateId
    ).replace(":id", options.id);
    return axiosInstance
      .post(URL, treatmentPlanTemplatePayload)
      .then((response) => {
        const treatmentPlanTemplateStep = deserialize(
          TreatmentPlanTemplateSteps,
          response.data["treatment_plan_template_step"]
        );

        onSuccess(treatmentPlanTemplateStep);
      })
      .catch((error) => {
        onError();
      })
      .finally(onFinal);
  }

  static deleteStep(
    options: {
      treatmentPlanId: string;
      treatmentPlanVisitId: string;
      id: string;
    },
    onSuccess: () => void,
    onError: (error: Error) => void,
    onFinal: () => void
  ) {
    const URL = ApiRoutes.REMOVE_TREATMENT_PLAN_TEMPLATE_STEP.replace(
      ":treatmentPlanId",
      options.treatmentPlanId
    )
      .replace(":treatmentPlanVisitId", options.treatmentPlanVisitId)
      .replace(":id", options.id);

    return axiosInstance
      .delete(URL)
      .then(() => {
        onSuccess();
      })
      .catch(onError)
      .finally(onFinal);
  }
  static deleteTemplateVisitStatement(
    options: {
      treatmentPlanId: string;
      treatmentPlanVisitId: string;
      id: string;
    },
    onSuccess: () => void,
    onError: (error: Error) => void,
    onFinal: () => void
  ) {
    const URL = ApiRoutes.REMOVE_TEMPLATE_VISIT_STATEMENT.replace(
      ":treatmentPlanTemplateId",
      options.treatmentPlanId
    )
      .replace(
        ":treatmentPlanTemplateAppointmentId",
        options.treatmentPlanVisitId
      )
      .replace(":id", options.id);

    return axiosInstance
      .delete(URL)
      .then(() => {
        Notification({
          message: "Statement Note deleted! ",
          type: NotificationTypes.SUCCESS,
        });
        onSuccess();
      })
      .catch(onError)
      .finally(onFinal);
  }
}
