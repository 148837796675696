import React, { FC, useEffect, useState } from "react";
import { Button, Drawer, Switch, Table } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import "./statementList.scss";
import { NoteTemplateService } from "../../../../../services/NoteTemplate/noteTemplate.service";
import { StatementTemplate } from "../../../../../models/Statement/statement.model";
import { NoteTemplate } from "..";
import { XRayTemplate } from "../../../../../models/XRayTemplate/xRayTemplate.model";
import NoteTemplateForm from "../NoteTemplateForm";
import { ResponsibilityTypes } from "../../../../../enums/responsebily.enum";
import { PaginationDetails } from "../../../../../models/Pagination/pagination.model";
import useFilters from "../../../../../shared/hooks/useFilter/useFilters";
import { TablePaginationConfig } from "antd/lib/table";
import { FilterParams } from "../../../../../models/FilterParams/filterParams.model";
import useResponsibilities from "../../../../../shared/hooks/Responsibilities/useResponsibilities";
import { ColumnsType } from "antd/lib/table";

interface NoteListProps {
  type: NoteTemplate;
  statementTemplates: StatementTemplate[] | XRayTemplate[];
  setStatementTemplates: any;
  setXRayTemplates: any;
  setStatementTemplateVisibility: any;
  fetchStatement?: (filter: FilterParams) => void;
  fetchXRay?: (filter: FilterParams) => void;
  handleUpdate: (value: StatementTemplate | XRayTemplate, type: string) => void;
  pagination?: PaginationDetails;
}

interface Loaders {
  [key: string]: boolean;
}

const NoteList: FC<NoteListProps> = (props) => {
  const {
    type,
    statementTemplates,
    setStatementTemplates,
    setXRayTemplates,
    setStatementTemplateVisibility,
    handleUpdate,
    fetchStatement,
    fetchXRay,

    pagination,
  } = props;

  const { hasAccess } = useResponsibilities();

  const [formVisibility, setFormVisibility] = useState(false);

  const [template, setTemplate] = useState<StatementTemplate | XRayTemplate>();

  const { getParams, setParams } = useFilters();
  const HAS_NOTE_TEMPLATE_CREATE_ACCESS = hasAccess(
    ResponsibilityTypes.NOTE_TEMPLATE_CREATE
  );

  const HAS_NOTE_TEMPLATE_EDIT_ACCESS = hasAccess(
    ResponsibilityTypes.NOTE_TEMPLATE_EDIT
  );

  const noteTemplateColumns = [
    {
      title: "Category",
      dataIndex: "statementTemplateCategoryName",
      key: "statementTemplateCategoryName",
      width: "20%",
    },
    {
      title: "Sub Category",
      dataIndex: "subCategory",
      key: "subCategory",
      width: "20%",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: "40%",
    },
  ];

  const XRayTemplateColumn = [
    {
      title: "Category",
      dataIndex: "xRayTemplateCategoryName",
      key: "xRayTemplateCategoryName",
      width: "20%",
    },
    ...[...noteTemplateColumns].splice(2, 3),
  ];

  const [columns, setColumn] = useState<
    ColumnsType<StatementTemplate | XRayTemplate>
  >(XRayTemplateColumn);

  useEffect(() => {
    const columns =
      type === "Statement" ? noteTemplateColumns : XRayTemplateColumn;

    setColumn([
      ...columns,
      ...(HAS_NOTE_TEMPLATE_EDIT_ACCESS
        ? [
            {
              title: "",
              dataIndex: "status",
              key: "status",
              width: "10%",
              render: (
                status: string,
                record: StatementTemplate | XRayTemplate,
                index: number
              ) => {
                return (
                  <Switch
                    checked={status === "active"}
                    onChange={(checked) => {
                      if (!HAS_NOTE_TEMPLATE_EDIT_ACCESS) return;

                      const statementTemplate = Object.assign(
                        type === "Statement"
                          ? new StatementTemplate()
                          : new XRayTemplate(),
                        {
                          ...record,
                          status: checked ? "active" : "inactive",
                        }
                      );
                      NoteTemplateService[
                        type === "Statement"
                          ? "updateStatement"
                          : "updateXRayTemplate"
                      ](
                        statementTemplate,
                        (
                          statementTemplate: XRayTemplate | StatementTemplate
                        ) => {
                          (type === "Statement"
                            ? setStatementTemplates
                            : setXRayTemplates)(
                            (
                              existingStatementTemplates: StatementTemplate[]
                            ) => {
                              existingStatementTemplates.splice(
                                index,
                                1,
                                statementTemplate
                              );
                              return [...existingStatementTemplates];
                            }
                          );
                        },
                        () => {},
                        () => {}
                      );
                    }}
                  />
                );
              },
            },
            {
              title: "",
              dataIndex: "",
              key: "",
              width: "10%",
              render: (_: null, record: StatementTemplate | XRayTemplate) => {
                return (
                  <i
                    className="icon-edit resource-edit-icon"
                    onClick={(event) => {
                      if (!HAS_NOTE_TEMPLATE_EDIT_ACCESS) return;

                      event.stopPropagation();
                      setTemplate(record);
                      setFormVisibility(true);
                    }}
                  />
                );
              },
            },
          ]
        : []),
    ]);
  }, [HAS_NOTE_TEMPLATE_EDIT_ACCESS, type]);

  const handleCloseDrawer = () => {
    setFormVisibility(false);
  };

  const handleTableChange = (pagination: TablePaginationConfig) => {
    const prevFilter = getParams();
    const updatedFilters = {
      ...prevFilter,
      page: pagination.current ?? 1,
    };
    setParams(updatedFilters);
    if (type === "Statement") fetchStatement?.(updatedFilters);
    else fetchXRay?.(updatedFilters);
  };

  return (
    <div className="statement-list__container">
      <div className="statement-list__title__wrapper">
        <p className="text-secondary">{type}</p>
        {HAS_NOTE_TEMPLATE_CREATE_ACCESS && (
          <Button
            type="primary"
            onClick={() => {
              setStatementTemplateVisibility(true);
            }}
          >
            <PlusOutlined /> Add Template
          </Button>
        )}
      </div>

      <Table
        dataSource={statementTemplates}
        columns={columns}
        onChange={handleTableChange}
        pagination={{
          total: pagination?.totalCount,
          current: pagination?.currentPage ?? 1,
          showTotal: (total: number, range: [number, number]) => (
            <p>
              Showing <b>{` ${range[0]} - ${range[1]}`}</b> of{" "}
              <b>{`${total.toLocaleString()}`}</b>
            </p>
          ),
        }}
      />

      <Drawer
        visible={HAS_NOTE_TEMPLATE_CREATE_ACCESS && formVisibility}
        closable
        destroyOnClose
        onClose={handleCloseDrawer}
        width={"75vw"}
        title="Create Template"
      >
        <NoteTemplateForm
          onClose={handleCloseDrawer}
          formValue={template}
          type={type}
          onUpdateHandler={(value, type) => {
            handleCloseDrawer();
            handleUpdate(value, type);
          }}
        />
      </Drawer>
    </div>
  );
};

export default NoteList;
