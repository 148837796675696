export const responsibilities = [
  {
    id: 1,
    title: "Corporate & practice management",
    responsibility: [
      {
        id: 10,
        title: "Corporate basic details & branding",
        approval: [
          // "corporate_create",
          "corporate_view",
          "corporate_edit",
          // "corporate_delete",
        ],
      },
      {
        id: 11,
        title: "Practice management",
        approval: [
          "practice_create",
          "practice_view",
          "practice_edit",
          // "practice_delete",
        ],
      },
      {
        id: 12,
        title: "Roles & permissions",
        approval: [
          "role_create",
          "role_view",
          "role_edit",
          // "role_delete"
        ],
      },
      {
        id: 13,
        title: "Subscription & subscription payment",
        approval: [
          "subscription_payment_create",
          "subscription_payment_view",
          // "subscription_payment_edit",
          // "subscription_delete",
        ],
      },
      {
        id: 14,
        title: "Custom form management",
        approval: [
          "custom_form_create",
          "custom_form_view",
          "custom_form_edit",
          // "custom_form_delete",
        ],
      },
      {
        id: 14,
        title: "Subscription Management",
        approval: [
          // "subscription_create",
          "subscription_view",
          // "subscription_edit",
          // "subscription_delete",
        ],
      },
      {
        id: 14,
        title: "Support Management",
        approval: [
          "issue_view",
          "issue_create",
          "issue_edit",
          // "issue_delete"
        ],
      },
    ],
  },
  {
    id: 2,
    title: "User & Document management",
    responsibility: [
      {
        id: 20,
        title: "Staff management",
        approval: [
          "staff_create",
          "staff_view",
          "staff_edit",
          // "staff_delete"
        ],
      },
      {
        id: 12,

        title: "Schedule management",
        approval: [
          "schedule_create",
          "schedule_view",
          "schedule_edit",
          "schedule_delete",
        ],
      },
      {
        id: 23,
        title: "Patient management",
        approval: [
          "patient_create",
          "patient_view",
          "patient_edit",
          // "patient_delete",
        ],
      },
      {
        id: 24,
        title: "Patient document",
        approval: [
          "patient_doc_create",
          "patient_doc_view",
          "patient_doc_edit",
          // "patient_doc_delete",
        ],
      },
    ],
  },
  {
    id: 3,
    title: "Treatment & Procedure",
    responsibility: [
      {
        id: 30,
        title: "Service management",
        approval: [
          "service_create",
          "service_view",
          "service_edit",
          "service_delete",
        ],
      },
      {
        id: 31,
        title: "Resource management",
        approval: [
          "resource_create",
          "resource_view",
          "resource_edit",
          "resource_delete",
        ],
      },
      {
        id: 32,

        title: "3D object management",
        approval: [
          "dental_obj_create",
          "dental_obj_view",
          "dental_obj_edit",
          // "dental_obj_delete",
        ],
      },
      {
        id: 33,

        title: "Status & appointment type management",
        approval: [
          "status_appointment_type_create",
          "status_appointment_type_view",
          "status_appointment_type_edit",
          "status_appointment_type_delete",
        ],
      },
      {
        id: 34,

        title: "Note template management",
        approval: [
          "note_template_create",
          "note_template_view",
          "note_template_edit",
          // "note_template_delete",
        ],
      },
      {
        id: 35,

        title: "Sets",
        approval: [
          "set_create",
          "set_view",
          "set_edit",
          // "set_delete"
        ],
      },
      {
        id: 36,

        title: "Procedure",
        approval: [
          "procedure_create",
          "procedure_view",
          "procedure_edit",
          // "procedure_delete",
        ],
      },
      {
        id: 37,

        title: "Treatment execution plan template",
        approval: [
          "treatment_plan_create",
          "treatment_plan_view",
          "treatment_plan_edit",
          // "treatment_plan_delete",
        ],
      },
      {
        id: 38,

        title: "Contract terms",
        approval: [
          "contract_term_create",
          "contract_term_view",
          "contract_term_edit",
          "contract_term_delete",
        ],
      },
      {
        id: 39,

        title: "Patient contract management",
        approval: [
          "patient_contract_create",
          "patient_contract_view",
          "patient_contract_edit",
          // "patient_contract_delete",
        ],
      },
    ],
  },
  {
    id: 4,
    title: "Appointment & Interaction management",
    responsibility: [
      {
        id: 46,

        title: "Appointment Template Management",
        approval: [
          "appointment_template_create",
          "appointment_template_view",
          "appointment_template_edit",
          // "appointment_template_delete",
        ],
      },
      {
        id: 40,

        title: "Appointment management",
        approval: [
          "appointment_create",
          "appointment_view",
          "appointment_edit",
          "appointment_delete",
        ],
      },
      {
        id: 41,

        title: "Holiday management for practice",
        approval: [
          "holiday_create",
          "holiday_view",
          // "holiday_edit",
          "holiday_delete",
        ],
      },
      {
        id: 42,

        title: "Interaction management",
        approval: [
          "interaction_create",
          "interaction_view",
          "interaction_edit",
          // "interaction_delete",
        ],
      },
      {
        id: 43,
        title: "Start interaction (based on clinical staff role)",
        approval: [
          "start_interaction_create",
          // "start_interaction_view",
          // "start_interaction_edit",
          // "start_interaction_delete",
        ],
      },
      {
        id: 44,

        title: "Stop interaction",
        approval: [
          "stop_interaction_create",
          // "stop_interaction_view",
          // "stop_interaction_edit",
          // "stop_interaction_delete",
        ],
      },
      {
        id: 45,

        title: "Complete interaction",
        approval: [
          "complete_interaction_create",
          // "complete_interaction_view",
          // "complete_interaction_edit",
          // "complete_interaction_delete",
        ],
      },
    ],
  },
  {
    id: 5,
    title: "Payment Management",
    responsibility: [
      {
        id: 51,

        title: "Payment Invoice Management",
        approval: [
          "payment_invoices_create",
          "payment_invoices_view",
          "payment_invoices_edit",
          // "payment_invoices_delete",
        ],
      },
      {
        id: 52,

        title: "Payment Report Management",
        approval: ["payment_report_view"],
      },
      {
        id: 53,

        title: "Offline Payment Management",
        approval: [
          "offline_payments_create",
          // "offline_payments_delete",
          // "offline_payments_edit",
          // "offline_payments_view",
        ],
      },
    ],
  },
];
