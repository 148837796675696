import React, { useEffect } from "react";
import "./treatmentTemplateViewer.scss";
import { ActionManager, Scene as SceneModel } from "@babylonjs/core";
import "@babylonjs/loaders";
import { Popover } from "antd";
import { SetStep } from "../../../../../models/SetStep/setStep.model";
import {
  cleanAllVendorMeshes,
  handleTeethLeftClick,
  handleTeethRightClick,
} from "../../../../../shared/utils/babylonUtils";
import BabylonContainer from "../../../../../store/container/BabylonContainer";
import { BabylonReducerProps } from "../../../../../store/reducers/babylonReducer";
import { TreatmentPlanReducerProps } from "../../../../../store/reducers/treatmentPlanReducer";
import TreatmentPlanContainer from "../../../../../store/container/TreatmentPlanContrainer";
import { TreatmentPlanTemplateSteps } from "../../../../../models/TreatmentPlanTemplateSteps/treatmentPlanTemplateSteps.model";
import TreatmentTemplateStepForm from "../TreatmentTemplateStepForm";
import CustomBabylonScene from "../../../../../shared/components/CustomBabylonScene";
import { MeshTriggerHandler } from "../../../../../shared/Types/meshActions.type";
import { MeshType } from "../../../../../enums/meshType.enum";
import { TreatmentPlanTemplateVisits } from "../../../../../models/TreatmentPlanTemplateVisits/treatmentPlanTemplateVisits.model";
import { iterateSteps } from "../../../../../shared/utils/integrateTreatmentSteps";
import { InteractionStepFormTypeEnum } from "../../../../../enums/interactionStepFormType.enum";
import useResponsibilities from "../../../../../shared/hooks/Responsibilities/useResponsibilities";
import { ResponsibilityTypes } from "../../../../../enums/responsebily.enum";
import CorporateContainer from "../../../../../store/container/CorporateContainer";
import { CorporateReducerProps } from "../../../../../store/reducers/corporateReducer";
import { compareStringByIgnoringCase } from "../../../../../shared/utils/helpers";
import { PatientCategory } from "../../../../../enums/patientCategory.enum";
import { getMeshType } from "../../../../../shared/utils/patientCategories";
interface TreatmentTemplateViewerProps
  extends TreatmentPlanReducerProps,
    BabylonReducerProps,
    CorporateReducerProps {
  onAddSetStep: (setStep: SetStep) => void;
  onSuccess: () => void;
}

function TreatmentTemplateViewer({
  currentTreatmentPlan,
  scene,
  caps,
  corporateObjects,
  highlightLayer,
  showPopover,
  setShowPopover,
  popoverPosition,
  setPopoverPosition,
  onSuccess,
  setSourceObject,
  resetBabylon,
  setMeshLoading,
  corporateObjectsLoading,
  capsLoading,
  practiceId,
}: TreatmentTemplateViewerProps) {
  const { hasAccess } = useResponsibilities();

  const HAS_TREATMENT_PLAN_EDIT_ACCESS = hasAccess(
    ResponsibilityTypes.TREATMENT_PLAN_EDIT
  );

  const handleAddStepSuccess = (setStep: SetStep) => {
    setShowPopover(false);
    setSourceObject(undefined);
    onSuccess();
  };

  const MESH_TYPE = currentTreatmentPlan
    ? getMeshType(currentTreatmentPlan?.patientCategory)
    : "";

  const IS_LOAD_MESH_TO_SCENE =
    !!scene &&
    !corporateObjectsLoading &&
    !!currentTreatmentPlan &&
    !capsLoading;

  const handleMeshLeftClick: MeshTriggerHandler = (props) =>
    handleTeethLeftClick(props, corporateObjects, setSourceObject);

  const handleMeshRightClick: MeshTriggerHandler = (props) => {
    if (!HAS_TREATMENT_PLAN_EDIT_ACCESS) return;

    handleTeethRightClick(
      props,
      corporateObjects,
      setSourceObject,
      setShowPopover,
      setPopoverPosition
    );
  };

  const iterateVisits = async (
    visits?: TreatmentPlanTemplateVisits[],
    isPlan = false
  ) => {
    if (!visits?.length) return;

    for (const visit of visits)
      await iterateSteps<TreatmentPlanTemplateSteps>(
        {
          caps,
          corporateObjects,
          isMeshPickable: false,
          scene,
          setPopoverPosition,
          setShowPopover,
          setSourceObject,
        },
        visit?.[isPlan ? "treatmentPlanSteps" : "treatmentPlanTemplateSteps"]
      );
  };
  const handleLoadedMesh = async () => {
    if (!scene || !IS_LOAD_MESH_TO_SCENE) return;

    try {
      setMeshLoading(true);
      cleanAllVendorMeshes(scene);
      await iterateVisits(currentTreatmentPlan?.treatmentPlanTemplateVisits);
      await iterateVisits(currentTreatmentPlan?.treatmentPlanVisits, true);
    } catch (ex) {
    } finally {
      setMeshLoading(false);
    }
  };

  useEffect(() => {
    handleLoadedMesh();
  },[currentTreatmentPlan]);

  useEffect(() => {
    setMeshLoading(true);
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
      resetBabylon();
    };
  }, []);

  return (
    <div className="treatment-viewer">
      <Popover
        overlayClassName="set-viewer__popover"
        content={
          showPopover && (
            <TreatmentTemplateStepForm
              type={InteractionStepFormTypeEnum.OBJECT_LINK}
              onSuccess={handleAddStepSuccess}
            />
          )
        }
        visible={showPopover}
        arrowContent={null}
        overlayInnerStyle={{
          position: "fixed",
          top: `calc(${popoverPosition.y}px + 10rem)`,
          left: popoverPosition.x + "px",
        }}
      />
      <CustomBabylonScene
        actions={{
          [ActionManager.OnLeftPickTrigger]: handleMeshLeftClick,
          [ActionManager.OnRightPickTrigger]: handleMeshRightClick,
        }}
        loadMeshToScene={IS_LOAD_MESH_TO_SCENE}
        meshType={MESH_TYPE}
        mouthSliderVisible
        onMeshLoad={handleLoadedMesh}
      />
    </div>
  );
}

export default BabylonContainer(
  CorporateContainer(TreatmentPlanContainer(TreatmentTemplateViewer))
);
