import { deserialize, serialize } from "serializr";
import { NotificationTypes } from "../../enums/notificationTypes";
import axiosInstance from "../../interceptor/axiosInstance";
import { StatementNote } from "../../models/StatementNote/statementNote.model";
import { TreatmentTemplate } from "../../models/TreamentTemplate/treatmentTemplate.model";
import { TreatmentPlanTemplate } from "../../models/TreatmentPlanTemplate/treatmentPlanTemplate.model";
import { TreatmentPlanTemplateStatementNotes } from "../../models/TreatmentPlanTemplateStatementNotes/treatmentPlanTemplateStatementNotes.model";
import { TreatmentPlanTemplateSteps } from "../../models/TreatmentPlanTemplateSteps/treatmentPlanTemplateSteps.model";
import ApiRoutes from "../../routes/routeConstants/apiRoutes";
import Notification from "../../shared/components/Notification";

export default class TreatmentPlanService {
  static fetchTreatmentTemplate(
    id: string,
    onSuccess: (treatmentPlan: TreatmentPlanTemplate) => void,
    onError: (error: Error) => void,
    onFinal: () => void
  ) {
    return axiosInstance
      .get(ApiRoutes.TREATMENT_PLAN.replace(":id", id))
      .then((response) => {
        const treatmentPlan = deserialize(
          TreatmentPlanTemplate,
          response.data["treatment_plan"]
        );
        onSuccess(treatmentPlan);
      })
      .catch(onError)
      .finally(onFinal);
  }

  static createStep(
    options: {
      treatmentPlanTemplateId: string;
      id: string;
    },
    payload: TreatmentPlanTemplateSteps,
    onSuccess: (treatmentPlanTemplateSteps: TreatmentPlanTemplateSteps) => void,
    onError: () => void,
    onFinal: () => void
  ) {
    const URL = ApiRoutes.TREATMENT_PLAN_STEP.replace(
      ":treatmentPlanId",
      options.treatmentPlanTemplateId
    ).replace(":id", options.id);
    const treatmentPlanPayload = {
      treatment_plan_step: serialize(payload),
    };
    return axiosInstance
      .post(URL, treatmentPlanPayload)
      .then((response) => {
        const treatmentPlanTemplateStep = deserialize(
          TreatmentPlanTemplateSteps,
          response.data["treatment_plan_step"]
        );
        onSuccess(treatmentPlanTemplateStep);
      })
      .catch((error) => {
        onError();
      })
      .finally(onFinal);
  }
  static createStatementNote(
    payload: {
      treatmentPlanTemplateId: string;
      treatmentPlanTemplateAppointmentId: string;
      statementNote: StatementNote;
    },
    onSuccess: (statementNote: TreatmentPlanTemplateStatementNotes) => void,
    onError: (error: Error) => void,
    onFinal: () => void
  ) {
    const {
      treatmentPlanTemplateId,
      treatmentPlanTemplateAppointmentId,
      statementNote,
    } = payload;
    const URL = ApiRoutes.TREATMENT_PLAN_STATEMENT_NOTES.replace(
      ":treatmentPlanId",
      treatmentPlanTemplateId
    ).replace(":id", treatmentPlanTemplateAppointmentId);
    const StatementNoteJSON = {
      treatment_plan_template_statement_note: {
        statement_template_id: statementNote.statementTemplateId,
      },
    };
    return axiosInstance
      .post(URL, StatementNoteJSON)
      .then((response) => {
        const statementNote = deserialize(
          TreatmentPlanTemplateStatementNotes,
          response.data["treatment_plan_template_statement_note"]
        );
        onSuccess(statementNote);
        Notification({
          message: "Statement Note created",
          type: NotificationTypes.SUCCESS,
        });
      })
      .catch(onError)
      .finally(onFinal);
  }

  static updateTreatmentPlanVisit(
    options: {
      treatmentPlanId: string;
      id: string;
    },
    onSuccess: () => void,
    onError: (error: Error) => void,
    onFinal: () => void
  ) {
    const treatmentPlanVisitJSON = {
      treatment_plan_visit: { is_current_visit: true },
    };
    const URL = ApiRoutes.TREATMENT_PLAN_TEMPLATE_VISIT.replace(
      ":treatmentPlanId",
      options.treatmentPlanId
    ).replace(":id", options.id);
    return axiosInstance
      .put(URL, treatmentPlanVisitJSON)
      .then(() => {
        onSuccess();
      })
      .catch(onError)
      .finally(onFinal);
  }

  static deleteStep(
    options: {
      treatmentPlanId: string;
      treatmentPlanVisitId: string;
      id: string;
    },
    onSuccess: () => void,
    onError: (error: Error) => void,
    onFinal: () => void
  ) {
    const URL = ApiRoutes.REMOVE_TREATMENT_PLAN_STEP.replace(
      ":treatmentPlanId",
      options.treatmentPlanId
    )
      .replace(":treatmentPlanVisitId", options.treatmentPlanVisitId)
      .replace(":id", options.id);

    return axiosInstance
      .delete(URL)
      .then(() => {
        onSuccess();
      })
      .catch(onError)
      .finally(onFinal);
  }
}
