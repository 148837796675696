import React, { FC, useEffect, useState } from "react";
import { Modal } from "antd";
import { useParams } from "react-router";
import Note from "../../../shared/components/Note";
import "./commentNotes.scss";
import CommentNotesService from "../../../services/CommentNotes/commentNotes.service";
import { CommentNote } from "../../../models/CommentNote/commentNote.model";
import useResponsibilities from "../../../shared/hooks/Responsibilities/useResponsibilities";
import { ResponsibilityTypes } from "../../../enums/responsebily.enum";

const { warning } = Modal;

interface StatementNotesProps {
  reload?: number;
  interactionId?: number;
}

const CommentNotes: FC<StatementNotesProps> = (props) => {
  const { reload, interactionId } = props;

  const { hasAccess } = useResponsibilities();

  const [comments, setComments] = useState<CommentNote[]>([]);

  const HAS_INTERACTION_EDIT_ACCESS = hasAccess(
    ResponsibilityTypes.INTERACTION_EDIT
  );

  useEffect(() => {
    CommentNotesService.fetchCommentNotes(
      Number(interactionId),
      (comments) => {
        setComments(comments);
      },
      () => {},
      () => {}
    );
  }, [interactionId, reload]);

  const handleDelete = (index: number, id?: number, title?: string) => {
    warning({
      content: `Are you sure you want to delete ${title}`,
      maskClosable: true,
      okText: "Delete",
      okType: "danger",
      onOk: () => {
        CommentNotesService.deleteCommentNotes(
          id ?? 0,
          Number(interactionId),
          () => {
            setComments((comments) => [
              ...comments?.filter((comment) => comment?.id !== id),
            ]);
          },
          () => {},
          () => {}
        );
      },
    });
  };

  return (
    <div className="comment-notes">
      {comments.map(({ id, type, notes, createdAt }, index) => (
        <Note
          title={type}
          description={notes}
          date={createdAt}
          showDivider={comments.length - 1 !== index}
          hasDeleteAccess={HAS_INTERACTION_EDIT_ACCESS}
          onDeleteHandler={() => handleDelete(index, id, type)}
          currentInteractionId={interactionId}
        />
      ))}
    </div>
  );
};

export default CommentNotes;
