export const getBaseURL = (endpoint?: string) => {
  let baseURL = "";
  try {
    if (endpoint) {
      const url = new URL(endpoint);
      baseURL = url?.origin;
    }
  } catch (ex) {
    baseURL = "";
  } finally {
    return baseURL;
  }
};
