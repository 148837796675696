import { Button, Col, Row } from "antd";
import { Formik, Form } from "formik";
import React, { FC, useEffect, useState } from "react";
import { DropdownModeEnum } from "../../../../../enums/dropdownMode.enum";
import { Service } from "../../../../../models/Service/service.model";
import { TreatmentTemplate } from "../../../../../models/TreamentTemplate/treatmentTemplate.model";
import { IDropdownOptions } from "../../../../../services/Meta/meta.service";
import ServiceService from "../../../../../services/Service/service.service";
import { TreatmentTemplateService } from "../../../../../services/TreatmentTemplate/treatmentTemplate.service";
import DropdownField from "../../../../../shared/components/DropdownField";
import InputField from "../../../../../shared/components/InputField";
import { patientCategories } from "../../../../../shared/utils/patientCategories";
import "./treatmentTemplateForm.scss";
import { treatmentTemplateValidationForm } from "./treatmentTemplateValidationForm";

interface TreatmentTemplateFormProps {
  formValues?: TreatmentTemplate;
  formType: "Add" | "Edit" | "Clone";
  successHandler: (treatmentTemplate: TreatmentTemplate) => void;
  closeHandler: () => void;
}

const options: IDropdownOptions[] = [
  {
    label: "Aligner",
    value: "Aligner",
  },
  {
    label: "fixed appliances",
    value: "fixed appliances",
  },
];

const TreatmentTemplateForm: FC<TreatmentTemplateFormProps> = (props) => {
  const { formValues, formType, successHandler, closeHandler } = props;

  const [services, setServices] = useState<IDropdownOptions[]>([]);

  useEffect(() => {
    ServiceService.fetchServices(
      (services) => {
        setServices(
          services?.map(({ id, name }) => ({
            label: name,
            value: id,
          }))
        );
      },
      () => {},
      () => {}
    );
  }, []);

  const handleSubmit = (values: TreatmentTemplate) => {
    const treatmentTemplate = Object.assign(new TreatmentTemplate(), values);
    TreatmentTemplateService[
      formType === "Clone"
        ? "cloneTreatmentPlan"
        : formValues
        ? "updateTreatmentPlan"
        : "createTreatmentPlan"
    ](
      treatmentTemplate,
      (treatmentTemplate) => {
        successHandler(treatmentTemplate);
      },
      () => {},
      () => {}
    );
  };

  const HandleBoutOptions = () => {
    return [...Array(5)].map((val, index) => {
      return { label: index + 1, value: index + 1 };
    });
  };

  console.log(formValues);
  return (
    <div className="treatment-template-form">
      <Formik
        initialValues={formValues ?? new TreatmentTemplate()}
        onSubmit={handleSubmit}
        validationSchema={treatmentTemplateValidationForm}
        enableReinitialize
      >
        {({ values, setFieldValue, isValid, dirty }) => {
          return (
            <Form>
              <div className="treatment-template-form__wrapper">
                <Row gutter={[20, 0]}>
                  <Col span={12}>
                    <InputField title="Plan Name" name="name" type="text" />
                  </Col>
                  <Col span={12}>
                    <DropdownField
                      disabled={formType !== "Add"}
                      title="Patient Category"
                      name="patientCategory"
                      placeHolder="Select Patient Category"
                      setFieldValue={setFieldValue}
                      options={patientCategories}
                      value={values.patientCategory}
                    />
                  </Col>
                  <Col span={12}>
                    <DropdownField
                      title="Number of Bouts"
                      name="noOfBout"
                      // options={getStatusOptions()}
                      options={HandleBoutOptions()}
                      value={values.noOfBout}
                      setFieldValue={setFieldValue}
                      placeHolder="Select No of Bouts"
                      onChange={(value) => {
                        setFieldValue("noOfBout", value);
                        setFieldValue("noOfAppointments", value * 6);
                      }}
                    />
                  </Col>
                  <Col span={12}>
                    <InputField
                      title="Duration (months)"
                      name="durationInMonths"
                      type="number"
                      disabled={formType === "Clone"}
                    />
                  </Col>

                  <Col span={12}>
                    <InputField
                      title="No of Appointments"
                      value={values.noOfAppointments}
                      name="noOfAppointments"
                      placeholder="0"
                      type="number"
                      disabled
                    />
                  </Col>

                  <Col span={24}>
                    <InputField
                      title="Description"
                      name="description"
                      type="textarea"
                      rows={6}
                      disabled={formType === "Clone"}
                    />
                  </Col>
                </Row>
              </div>
              <div className="treatment-template-form__controller">
                <Button type="ghost" onClick={closeHandler}>
                  Cancel
                </Button>

                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={!isValid || !dirty}
                >
                  Next
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default TreatmentTemplateForm;
