import { useEffect, useState } from "react";
import { AppointmentType } from "../../../models/AppointmentType/appointmentType.model";
import { PaginationDetails } from "../../../models/Pagination/pagination.model";
import AppointmentTypeService from "../../../services/AppointmentType/appointmentType.service";
import { getDropdownOptions } from "../../utils/optionHelper";

interface useAppointmentTypesProps {}

const useAppointmentTypes = ({}: useAppointmentTypesProps) => {
  const [appointmentTypes, setAppointmentTypes] = useState<AppointmentType[]>(
    []
  );

  const [appointmentTypesPageMeta, setAppointmentTypesPageMeta] = useState(
    new PaginationDetails()
  );

  const [loading, setLoading] = useState(false);

  const options = getDropdownOptions(appointmentTypes, "nickName", "id");

  const fetchAppointmentTypes = () => {
    if (!appointmentTypesPageMeta?.nextPage) return;

    setLoading(true);
    AppointmentTypeService.fetchAppointmentTypes(
      (appointmentTypes, meta) => {
        setAppointmentTypes((existingAppointmentTypes) => [
          ...existingAppointmentTypes,
          ...appointmentTypes,
        ]);
        setAppointmentTypesPageMeta(meta);
      },
      () => {},
      () => {
        setLoading(false);
      },
      { page: appointmentTypesPageMeta?.nextPage }
    );
  };

  useEffect(() => {
    fetchAppointmentTypes();
  }, []);

  return {
    appointmentTypes,
    fetchAppointmentTypes,
    loading,
    options,
  };
};

export default useAppointmentTypes;
