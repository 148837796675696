import { SetStep } from "../SetStep/setStep.model";
import { serializable, alias, object, list, primitive } from "serializr";
import { StatusEnum } from "../../enums/status.enum";
import { PatientCategory } from "../../enums/patientCategory.enum";

export class DentalSet {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("name", primitive()))
  name?: string;

  @serializable(alias("type", primitive()))
  type?: string;

  @serializable(alias("description", primitive()))
  description?: string;

  @serializable(alias("no_of_attachments", primitive()))
  noOfAttachments?: number;

  @serializable(alias("status", primitive()))
  status?: StatusEnum = StatusEnum.ACTIVE;

  @serializable(alias("practice_id", primitive()))
  practiceId?: string;

  @serializable(alias("practice_name", primitive()))
  practiceName?: string;

  @serializable(alias("set_steps", list(object(SetStep))))
  setSteps: SetStep[] = [];

  @serializable(alias("patient_category", primitive()))
  patientCategory = PatientCategory.ADULT;
}
