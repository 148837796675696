import React, { useEffect } from "react";
import "./patientDetail.scss";
import { Row, Col } from "antd";
import { Patient } from "../../../models/Patient/patient.model";
import { getYearsAndMonths } from "../../../shared/utils/dateHelpers";
import { capitalize } from "lodash";

interface PatientDetailProps {
  patient: Patient;
}

function PatientDetail({ patient }: PatientDetailProps) {
  const isTruthyField = (label: string, value?: string) =>
    value ? [{ label, value }] : [];

  const patientDetails = [
    { label: "Name", value: patient?.firstName },
    { label: "Surname", value: patient?.lastName },
    { label: "Gender", value: patient?.gender },
    {
      label: "DOB",
      value: patient?.dob ? getYearsAndMonths(patient?.dob) : "NA",
    },
    ...isTruthyField("Email", patient?.email),
    ...isTruthyField("Mobile number", patient?.mobileNumber),
    ...isTruthyField("Country", capitalize(patient?.countryName)),
    ...isTruthyField("Province", capitalize(patient?.provinceName)),
    ...isTruthyField("City", capitalize(patient?.cityName)),
    ...isTruthyField("Nationality", capitalize(patient?.nationalityName)),
    ...isTruthyField("Street", patient?.street),
    ...isTruthyField("Building", patient?.building),
    ...isTruthyField("Postal code", patient?.zipcode),
  ];
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);

  return (
    <div className="patient-detail">
      <Row gutter={[20, 30]}>
        {patientDetails.map((patientDetail) => (
          <Col span={8}>
            <label className="patient-detail__label">
              {patientDetail?.label}
            </label>
            <label className="patient-detail__value">
              {patientDetail?.value}
            </label>
          </Col>
        ))}
      </Row>
    </div>
  );
}

export default PatientDetail;
