import React, { useEffect, useState } from "react";
import "./treatmentSummaryNotes.scss";
import { Col, Collapse, Row } from "antd";
import { InteractionStep } from "../../../../models/InteractionStep/interactionStep.model";
import InteractionStepService from "../../../../services/InteractionStep/interactionStep.service";
import AppLoader from "../../../../shared/components/AppLoader";

interface TreatmentSummaryNotesProps {
  interactionId: number;
}

function TreatmentSummaryNotes({ interactionId }: TreatmentSummaryNotesProps) {
  const [interactionSteps, setInteractionSteps] = useState<InteractionStep[]>(
    []
  );

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    InteractionStepService.showInteractionSteps(
      interactionId,
      (interactionSteps: InteractionStep[]) => {
        setInteractionSteps(interactionSteps);
      },
      () => {},
      () => {
        setLoading(false);
      }
    );
  }, [interactionId]);

  return (
    <div className="treatment-summary-notes">
      {loading ? (
        <AppLoader loading={loading} />
      ) : (
        <div>
          {interactionSteps.map((interactionStep, i) =>
            interactionStep?.set ? (
              <Collapse
                expandIconPosition="right"
                className="treatment-summary-notes__set-item"
              >
                <Collapse.Panel
                  header={
                    <Row
                      align="middle"
                      className="treatment-summary-notes__set-header-row"
                    >
                      <Col span={22}>{interactionStep?.set?.name}</Col>
                    </Row>
                  }
                  key={i}
                >
                  {interactionStep?.set?.setSteps?.map((setStep, j) => (
                    <Row className="treatment-summary-notes__set-step-item">
                      <Col span={24}>{setStep?.note || "NA"}</Col>
                    </Row>
                  ))}
                </Collapse.Panel>
              </Collapse>
            ) : (
              <Row
                className="treatment-summary-notes__link-item"
                align="middle"
              >
                <Col span={22}>{interactionStep?.note}</Col>
              </Row>
            )
          )}
          {interactionSteps?.length === 0 ? "No notes available" : ""}
        </div>
      )}
    </div>
  );
}

export default TreatmentSummaryNotes;
