export const SET_SCENE = "set_scene";
export const SET_HIGHLIGHT_LAYER = "set_highlight_layer";
export const SET_SOURCE_OBJECT = "set_corporate_object";
export const SET_CORPORATE_OBJECTS = "set_corporate_objects";
export const SET_POPOVER_POSITION = "set_popover_position";
export const SET_SHOW_POPOVER = "set_show_popover";
export const SET_TOOTH_POSITIONS = "set_tooth_positions";
export const RESET_BABYLON = "reset_babylon";
export const PUSH_CONFLICT = "push_conflict";
export const RESET_CONFLICT = "reset_conflict";
export const SET_SOURCE_OBJECTS = "set_source_objects";
export const SET_CAPS = "set_caps";
export const SET_CORPORATE_OBJECTS_LOADING = "set_corporate_objects_loading";
export const SET_MESH_LOADING = "set_mesh_loading";
export const SET_CAPS_LOADING = "set_caps_loading";
