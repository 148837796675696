import { Button, Col, Form, Row } from "antd";
import { Formik } from "formik";
import React, { FC, useEffect, useState } from "react";
import { Contract } from "../../../../../../models/Contract/contract.model";
import { InsuranceProvider } from "../../../../../../models/InsuranceProvider/insuranceProvider.model";
import { PaymentSchedule } from "../../../../../../models/PaymentSchedule/paymentSchedule.model";
import ContractService from "../../../../../../services/Contract/Contract.service";
import { IDropdownOptions } from "../../../../../../services/Meta/meta.service";
import DropdownField from "../../../../../../shared/components/DropdownField";
import InputField from "../../../../../../shared/components/InputField";
import DatePickerField from "shared/components/DatePickerField";
import moment from "moment";

const initialInsurance = {
  dueTime: undefined,
  initialExpectedAmount: undefined,
  insuranceSchedule: [],
  lifetimeMaxAmount: undefined,
  noOfDues: undefined,
  overallClaimAmount: undefined,
  overallExpectedAmount: undefined,
  payoutRatio: undefined,
  initialClaimAmount: undefined,
};

interface InsuranceProviderUpdateProps {
  formValues: PaymentSchedule;
  currentFormValues: InsuranceProvider;
  handleUpdateProviderVisibility: () => void;
  categoryOption: IDropdownOptions[];
  statusOptions: IDropdownOptions[];
  insuraneProviderOptions: any[];
  contract: Contract;
  onSuccess: (updatedValue: InsuranceProvider) => void;
  currentIndex: number;
}

const InsuranceProviderUpdate: FC<InsuranceProviderUpdateProps> = (props) => {
  const {
    formValues,
    handleUpdateProviderVisibility,
    categoryOption,
    insuraneProviderOptions,
    contract,
    onSuccess,
    currentFormValues,

    currentIndex,
    statusOptions,
  } = props;
  const [loading, setLoading] = useState(false);

  const handleSubmit = (values: InsuranceProvider) => {
    formValues?.insuranceProviders?.map((data, index) => {
      onSuccess({
        ...values,
        ...(values?.status === "inactive" ? initialInsurance : {}),
      });

      handleUpdateProviderVisibility();
    });
  };
  return (
    <div>
      {" "}
      <Formik
        initialValues={currentFormValues}
        onSubmit={() => {}}
        enableReinitialize
        submitting
      >
        {({ values, errors, isValid, dirty, setFieldValue }) => {
          return (
            <Form className="contract-payment-schedule__form title">
              <Row gutter={[20, 0]}>
                <Col span={24}>
                  <h4 className="text-secondary mb-2">
                    Update Insurance Provider
                  </h4>
                </Col>
                <Col span={6}>
                  <DropdownField
                    title="Category"
                    name="category"
                    options={categoryOption}
                    value={values?.category}
                    setFieldValue={setFieldValue}
                    placeHolder="Select Category"
                    disabled
                  />
                </Col>
                <Col span={6}>
                  <DropdownField
                    title="Insurance Provider"
                    name="insuranceProviderId"
                    options={insuraneProviderOptions}
                    value={values?.insuranceProviderId}
                    setFieldValue={setFieldValue}
                    placeHolder="Select Insurance Provider"
                  />
                </Col>
                <Col span={6}>
                  <InputField
                    title="Group Number"
                    type="text"
                    name="groupNumber"
                    placeholder="Enter Group Number "
                  />
                </Col>
                <Col span={6}>
                  <InputField
                    title="Certificate Number"
                    type="text"
                    name="certificateNumber"
                    placeholder="Enter Certificate Number"
                  />
                </Col>
                <Col span={6}>
                  <InputField
                    title="Subscriber Name"
                    type="text"
                    name="subscriberName"
                    placeholder="Enter Subscriber Name"
                  />
                </Col>
                <Col span={6} className="mt-1">
                  <DatePickerField
                    title="Subscriber DOB"
                    name="subscriberDob"
                    value={moment(values?.subscriberDob)}
                    setFieldValue={setFieldValue}
                  />
                </Col>
                <Col span={6}>
                  <InputField
                    title="Relationship"
                    type="text"
                    name="subscriberRelationToPatient"
                    placeholder="Enter Relation"
                  />
                </Col>

                <Col span={6}>
                  <DropdownField
                    title="Status"
                    name="status"
                    options={statusOptions}
                    value={values?.status}
                    setFieldValue={setFieldValue}
                    placeHolder="Select Category"
                  />
                </Col>

                <Col span={24}>
                  <div className="mt-5 text-right">
                    <Button
                      type="default"
                      onClick={handleUpdateProviderVisibility}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="ml-2"
                      onClick={() => handleSubmit(values)}
                      loading={loading}
                    >
                      Save
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default InsuranceProviderUpdate;
