import * as Yup from  "yup";

export const registerBusinessDetailsFormValidation = Yup.object().shape({
    logo: Yup.mixed().required('Logo is required')
        .test('fileFormat', 'Images only', (value) => {
            return value && ['image/jpeg', 'image/png', 'image/jpg'].includes(value.type);
        }),
    license: Yup.mixed().required('Business document is required')
        .test('fileFormat', 'PDF only', (value) => {
            return value && ['application/pdf'].includes(value.type);
        }),
});
