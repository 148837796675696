import { Scene } from "@babylonjs/core";
import { Col, Row } from "antd";
import React, { useState } from "react";
import { MeshType } from "../../../../enums/meshType.enum";
import { Interaction } from "../../../../models/Interaction/interaction.model";
import CustomBabylonScene from "../../../../shared/components/CustomBabylonScene";
import useToggle from "../../../../shared/hooks/useToggle/useToggler";
import { integrateInteractions } from "../../../../shared/utils/integrateTreatmentSteps";
import BabylonContainer from "../../../../store/container/BabylonContainer";
import CorporateContainer from "../../../../store/container/CorporateContainer";
import InteractionContainer from "../../../../store/container/InteractionContainer";
import { BabylonReducerProps } from "../../../../store/reducers/babylonReducer";
import { CorporateReducerProps } from "../../../../store/reducers/corporateReducer";
import { InteractionReducerProps } from "../../../../store/reducers/interactionReducer";
import InteractionVisitDetails from "../../InteractionDetail/InteractionVisitDetails";

interface TimelineInteractionProps
  extends BabylonReducerProps,
    CorporateReducerProps,
    InteractionReducerProps {
  interaction: Interaction;
  meshType?: MeshType;
}

const TimelineInteraction = ({
  interaction,
  caps,
  contractInteractions,
  corporateObjects,
  setPopoverPosition,
  setShowPopover,
  setSourceObject,
  meshType,
  capsLoading,
  corporateObjectsLoading
}: TimelineInteractionProps) => {
  const { toggle: meshLoading, updateToggle: setMeshLoading } = useToggle({
    initialToggleState: true,
  });

  const [scene, setScene] = useState<Scene>();

  const currentInteractionId = interaction?.id;

  const isLoadMeshToScene =
    !!meshType &&
    !!scene &&
    !!currentInteractionId &&
    !capsLoading &&
    !corporateObjectsLoading;

  const handleLoadedMesh = async () => {
    if (!isLoadMeshToScene) return;

    try {
      setMeshLoading?.(true);
      await integrateInteractions({
        activeInteractionId: currentInteractionId,
        caps,
        contractInteractions,
        corporateObjects,
        scene,
        setPopoverPosition,
        setShowPopover,
        setSourceObject,
      });
    } catch (ex) {
    } finally {
      setMeshLoading?.(false);
    }
  };

  return (
    <div className="display-interaction__wrapper">
      <Row gutter={[8, 0]}>
        <Col span={8}>
          <InteractionVisitDetails
            displayStacked
            interactionId={currentInteractionId as number}
          />
        </Col>
        <Col span={16}>
          <CustomBabylonScene
            loadMeshToScene={isLoadMeshToScene}
            meshType={meshType}
            onMeshLoad={handleLoadedMesh}
            customScene={{ scene, setScene, meshLoading, setMeshLoading }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default BabylonContainer(
  CorporateContainer(InteractionContainer(TimelineInteraction))
);
