import { MeshType } from "../../enums/meshType.enum";
import { PatientCategory } from "../../enums/patientCategory.enum";

export const patientCategories = [
  {
    label: "Adult",
    value: PatientCategory.ADULT,
  },
  {
    label: "Child",
    value: PatientCategory.CHILD,
  },
];

export const serializePatientCategory = (val: any) =>
  val || PatientCategory.ADULT;

export const getMeshType = (patientCategory?: PatientCategory) => {
  switch (patientCategory) {
    case PatientCategory.CHILD:
      return MeshType.CHILD_TEETH;
    case PatientCategory.ADULT:
    default:
      return MeshType.ADULT_TEETH;
  }
};
