import React, { FC, useEffect } from "react";
import "./authWrapper.scss";
import { useHistory, useLocation } from "react-router-dom";
import AuthService from "../../../services/Auth/auth.service";
import AppRoutes from "../../../routes/routeConstants/appRoutes";
import UserService from "../../../services/User/user.service";
import { CorporateService } from "../../../services/Corporate/corporate.service";
import { Corporate } from "../../../models/Corporate/corporate.model";
import RegisterContainer from "../../../store/container/RegisterContainer";
import { RegisterReducerProps } from "../../../store/reducers/registerReducer";
import CorporateContainer from "../../../store/container/CorporateContainer";
import { CorporateReducerProps } from "../../../store/reducers/corporateReducer";
interface AuthWrapperProps
  extends RegisterReducerProps,
    CorporateReducerProps {}

const AuthWrapper: FC<AuthWrapperProps> = (props) => {
  const { setDetailedOnboardingActiveStep, setCorporateId } = props;

  const history = useHistory();

  useEffect(() => {
    const code = new URLSearchParams(history.location.search).get("code") || "";
    if (code) {
      AuthService.login(
        code,
        () => {
          UserService.getCurrentUserDetails(
            (user) => {
              setCorporateId(
                user?.userProfiles?.length ? user.userProfiles[0]?.id : 0
              );
              CorporateService.showCorporateProfile(
                (corporate: Corporate) => {
                  if (corporate?.stepNumber && corporate.stepNumber <= 5) {
                    setDetailedOnboardingActiveStep(corporate.stepNumber - 3);
                    history.push(AppRoutes.DETAILED_ONBOARDING);
                  } else {
                    history.push(AppRoutes.APPOINTMENTS);
                  }
                },
                () => {},
                () => {}
              );
            },
            () => {},
            () => {}
          );
        },
        () => {},
        () => {}
      );
    } else {
      window.location.replace(AppRoutes.LOGIN)
    }
  }, [history]);
  return <div className="auth-wrapper">Loading...</div>;
};

export default CorporateContainer(RegisterContainer(AuthWrapper));
