
import {TreatmentPlanTemplateVisits} from '../TreatmentPlanTemplateVisits/treatmentPlanTemplateVisits.model';

import {serializable, alias, object, list, primitive, custom} from 'serializr';
import { PatientCategory } from '../../enums/patientCategory.enum';
import { serializePatientCategory } from '../../shared/utils/patientCategories';

export class TreatmentPlanTemplate { 

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('treatment_plan_template_visits', list(object(TreatmentPlanTemplateVisits))))
	treatmentPlanTemplateVisits: TreatmentPlanTemplateVisits[] = [];

	@serializable(alias('treatment_plan_visits', list(object(TreatmentPlanTemplateVisits))))
	treatmentPlanVisits: TreatmentPlanTemplateVisits[] = [];
	
	@serializable(alias('name', primitive()))
	name?: string;

	@serializable(alias('practice_id', primitive()))
	practiceId?: number;

	@serializable(alias('duration_in_months', primitive()))
	durationInMonths?: number;

	@serializable(alias('description', primitive()))
	description?: string;

	@serializable(alias('plan_type', primitive()))
	planType?: string;

	@serializable(alias('treatment_plan_template_service_ids', primitive()))
	treatmentPlanTemplateServiceIds ?: string;

	@serializable(alias("no_of_bouts", primitive()))
	noOfBouts?: number;

	@serializable(alias("status", primitive()))
	status?: number;

	@serializable(
		alias(
		  "patient_category",
		  custom(serializePatientCategory, serializePatientCategory)
		)
	  )
	patientCategory? = PatientCategory.ADULT;
}