import React, { useState, useEffect, useCallback, FC, Children } from "react";
import {
  Calendar,
  EventPropGetter,
  momentLocalizer,
  SlotInfo,
} from "react-big-calendar";
import moment from "moment";

import "react-big-calendar/lib/css/react-big-calendar.css";
import "./appointmentTemplateAssignDate.scss";
import { Button, Col, Popover, Row, Select } from "antd";
import DropdownField from "../../../../shared/components/DropdownField";
import { IDropdownOptions } from "../../../../services/Meta/meta.service";
import { SelectProp } from "../../../../shared/Types/option";
import { AppointmentTemplate } from "../../../../models/AppointmentTemplate/appointmentTemplate.model";
import AppoitmentTemplateService from "../../../../services/AppointmentTemplate/appointmentTemplate.service";
import { AppointmentTemplateSchedules } from "../../../../models/AppointmentTemplateSchedules/appointmentTemplateSchedules.model";
import useResponsibilities from "../../../../shared/hooks/Responsibilities/useResponsibilities";
import { ResponsibilityTypes } from "../../../../enums/responsebily.enum";

interface EventProps {
  title?: string;
  id?: number;
  start?: Date | string;
  end?: Date | string;
  colorCode?: string;
}
interface AppointmentTemplateAssignDateProps {
  appointmentTemplateData: AppointmentTemplate;
}

const localizer = momentLocalizer(moment);

const AppointmentTemplateAssignDate: FC<AppointmentTemplateAssignDateProps> = (
  props
) => {
  const { hasAccess } = useResponsibilities();

  const [loading, setLoading] = useState(false);
  const [eventsData, setEventsData] = useState<EventProps[]>([]);
  const [selectedDate, setSeletedDate] = useState<string[]>([]);
  const [month, setMonth] = useState(moment().format("MMMM"));
  const [year, setYear] = useState(moment().format("YYYY"));
  const [templateId, setTemplateId] = useState<number>();
  const [monthOptions, setMonthOptions] = useState<SelectProp[]>([]);
  const [yearOptions, setYearOptions] = useState<SelectProp[]>([]);
  const [myDate, setMyDate] = useState<any>();

  const [
    appointmentTemplatesOptions,
    setAppointmentTemplatesOptions,
  ] = useState<SelectProp[]>([]);
  const [dateValue, setDateValue] = useState<any>(moment());

  const HAS_APPOINTMENT_TEMPLATE_EDIT_ACCESS = hasAccess(ResponsibilityTypes.APPOINTMENT_TEMPLATE_EDIT);

  const handleFetchAppointmentTemplateList = () => {
    AppoitmentTemplateService.fetchAppointmentTemplate(
      (appointmentTemplate: AppointmentTemplate[]) => {
        setAppointmentTemplatesOptions(
          appointmentTemplate?.map(({ templateName, id }) => {
            return { label: templateName, value: id };
          })
        );
      },
      () => {},
      () => {}
    );

    AppoitmentTemplateService.fetchAppointmentTemplateSchedules(
      (appointmentTemplate: AppointmentTemplateSchedules[]) => {
        setLoading(true);

        setEventsData(
          appointmentTemplate?.map(
            ({ appointmentTemplateName, id, date, colorCode }) => {
              return {
                id: id,
                title: appointmentTemplateName,
                start: date,
                end: date,
                colorCode: colorCode,
              };
            }
          )
        );
      },
      () => {},
      () => {
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    setMonthOptions(
      moment
        .months()
        .map((month, index) => ({ label: month, value: index + 1 }))
    );

    const year = Array.from(Array(new Date().getFullYear() - 1949), (_, i) =>
      (i + moment().year()).toString()
    );
    setYearOptions(year.map((year, index) => ({ label: year, value: year })));
    handleFetchAppointmentTemplateList();
  }, []);

  const handleSelect = (slotInfo: SlotInfo) => {
    const { start, end } = slotInfo;
    const selected = moment(start).format("YYYY-MM-DD");
    if (moment(start).isSameOrAfter(moment(), "date")) {
      if (!selectedDate.includes(selected)) {
        setSeletedDate((selectedDate) => [...selectedDate, selected]);
      } else {
        setSeletedDate((selectedDate) =>
          selectedDate.filter((date) => date !== selected)
        );
      }
    }

    // setEventsData([
    //   ...eventsData,
    //   {
    //     start,
    //     end,
    //   },
    // ]);
  };
  const handleOnSubmit = () => {
    AppoitmentTemplateService.assignAppointmentTemplate(
      templateId ?? 0,
      selectedDate,
      () => {
        handleFetchAppointmentTemplateList();
        setSeletedDate([]);
      },
      () => {},
      () => {}
    );
  };

  const handleDeleteAppointmentTemplate = (id: number) => {
    AppoitmentTemplateService.deleteAppointmentTemplateSchedule(
      id?.toString() ?? "",
      () => {
        handleFetchAppointmentTemplateList();
      },
      () => {},
      () => {}
    );
  };

  // const ColoredDateCellWrapper = ({ children, value, start }: any) => {
  //   let selDate = mydate;
  //   let valueDay =
  //     value?.getFullYear() + "/" + value?.getMonth() + "/" + value?.getDate();
  //   let selDay = "";
  //   if (selDate !== "") {
  //     selDay =
  //       selDate?.getFullYear() +
  //       "/" +
  //       selDate?.getMonth() +
  //       "/" +
  //       selDate?.getDate();
  //   }

  //   let cellStyle = React.cloneElement(Children.only(children), {
  //     style: {
  //       ...children.style,
  //       backgroundColor: valueDay === selDay ? "#CBE0FD" : "",
  //     },
  //   });
  //   return cellStyle;
  // };

  const handleSlotSelection = (date: any) => {
    setMyDate(new Date(date.start));
    selectedDate.push(moment(date.start).format("YYYY-MM-DD"));
  };
  const MonthEvent = ({ event }: any) => (
    <Popover
      destroyTooltipOnHide
      content={
        <div>
          <span
            className="appointment-popover"
            onClick={() => {
              handleDeleteAppointmentTemplate(event.id);
            }}
          >
            delete
          </span>
        </div>
      }
      placement="top"
      trigger="hover"
    >
      <div
        onContextMenu={(e) => {
          e.preventDefault();
        }}
        style={{ background: event.colorCode ?? "#7CD5C2" }}
        className="event-title"
      >
        {event.title}
      </div>
    </Popover>
  );
  return (
    <>
      <Row className="mb-5 appointment-template__dropdown">
        <Col span={4}>
          {" "}
          <Select
            options={yearOptions}
            defaultValue={moment().format("YYYY")}
            onChange={(value) => {
              setYear(String(value));
            }}
            placeholder="Select Year"
          />
        </Col>
        <Col span={12}>
          {" "}
          <Select
            options={monthOptions}
            defaultValue={moment().format("MMMM")}
            onChange={(value) => {
              setMonth(String(value));
            }}
            placeholder="Select Month"
          />
        </Col>
        {HAS_APPOINTMENT_TEMPLATE_EDIT_ACCESS && <><Col span={4} className="mr-5">
          {" "}
          <Select
            options={appointmentTemplatesOptions}
            onChange={(value) => {
              setTemplateId(Number(value));
            }}
            placeholder="Select Template"
          />
        </Col>
        <Col className="ml-3" span={3}>
          {" "}
          <Button
            type="primary"
            onClick={handleOnSubmit}
            disabled={!selectedDate.length}
          >
            Assign
          </Button>
        </Col></>}
      </Row>
      <div className="appointment-template-date">
        <Calendar
          dayPropGetter={(date) => {
            const updatedValue = moment(date).format("YYYY-MM-DD");

            let className = moment(date).isBefore(moment(), "date")
              ? "date-in-past"
              : selectedDate.includes(updatedValue)
              ? "select"
              : "";

            return { className };
          }}
          views={["month"]}
          components={{
            month: { event: MonthEvent },
          }}
          formats={{
            dayFormat: "YYYY-MMMM-DD",
          }}
          selectable
          localizer={localizer}
          defaultView="month"
          events={eventsData}
          style={{ height: "70vh" }}
          date={year + " " + month}
          onSelectSlot={handleSelect}

          // onSelectSlot={handleSlotSelection}
        />
      </div>
    </>
  );
};
export default AppointmentTemplateAssignDate;
