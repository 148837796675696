import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";
import * as TreatmentPlanActions from "../actions/treatmentPlanAction";
import { RootReducerProps } from "../reducers";

const mapStateToProps = (state: RootReducerProps, ownProps: any) => {
  return {
    ...ownProps,
    currentTreatmentPlan: state.treatmentPlan.currentTreatmentPlan,
    currentAppointment: state.treatmentPlan.currentAppointment
  };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(TreatmentPlanActions, dispatch);

const TreatmentPlanContainer = (component: any) => {
  return connect(mapStateToProps, mapDispatchToProps)(component);
};

export default TreatmentPlanContainer;
