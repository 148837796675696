import React, { useEffect, useState } from "react";
import { Row, Col, Button, Modal } from "antd";
import { InteractionStepFormTypeEnum } from "../../../enums/interactionStepFormType.enum";
import InteractionStepForm from "../InteractionStepForm";
import "./interactionObjectActions.scss";
import ObjectNoteForm from "../ObjectNoteForm";
import { ObjectNote } from "../../../models/ObjectNote/objectNote.model";
import InteractionContainer from "../../../store/container/InteractionContainer";
import { InteractionReducerProps } from "../../../store/reducers/interactionReducer";
import BabylonContainer from "../../../store/container/BabylonContainer";
import { BabylonReducerProps } from "../../../store/reducers/babylonReducer";
import { InteractionStep } from "../../../models/InteractionStep/interactionStep.model";
import InteractionStepService from "../../../services/InteractionStep/interactionStep.service";
import { handleRemoveLink } from "../../../shared/utils/babylonUtils";
import { objectActionEnum } from "../../../enums/objectActionType.enum";
import { CorporateObject } from "../../../models/CorporateObject/corporateObject.model";
import CorporateObjectNoteForm from "../CorporateObjectNoteForm";
interface InteractionObjectActionsProps
	extends InteractionReducerProps,
		BabylonReducerProps {
	objectNotePopover: boolean;
	setObjectNotePopover?: (visible?: boolean) => void;
	onSuccess: () => void;
	setOpenConflict: (open: boolean) => void;
	createInteractionStep: (step: InteractionStep, complete?: () => void) => void;
}

function InteractionObjectActions({
	sourceObject,
	contractInteractions,
	activeInteractionId,
	scene,
	highlightLayer,
	setContractInteractions,
	setShowPopover,
	onSuccess,
	setOpenConflict,
	createInteractionStep,
	objectNotePopover,
	setObjectNotePopover,
	setMeshLoading
}: InteractionObjectActionsProps) {
	const removeObject = async (values: InteractionStep) => new Promise((resolve) => {
		const interactionStep = Object.assign(new InteractionStep(), values);
		if (activeInteractionId) {
			setMeshLoading(true);
			InteractionStepService.createInteractionStep(
				activeInteractionId,
				interactionStep,
				async (interactionStep: InteractionStep) => {
					if (highlightLayer) highlightLayer.removeAllMeshes();
					const interactionIndex = contractInteractions.findIndex(
						(interaction) => interaction.id === activeInteractionId
					);
					if (interactionIndex >= 0) {
						contractInteractions[
							interactionIndex
						].interactionSteps.push(interactionStep);
						setContractInteractions([...contractInteractions]);
						if (interactionStep?.set) {
							interactionStep?.set?.setSteps?.forEach(
								async (setStep) => {
									await handleRemoveLink(setStep, scene);
								}
							);
						} else {
							await handleRemoveLink(interactionStep, scene);
						}
					}
					onSuccess?.();
				},
				() => {},
				() => {
					resolve(null);
					setObjectNotePopover?.();
					setMeshLoading(false);
				}
			);
		}
	});

	const handleRemoveObjectLink = () => {
		if((sourceObject as CorporateObject)?.corporateLinkId)
			return removeObject({
				...new InteractionStep(),
				corporateLinkId: (sourceObject as CorporateObject)?.corporateLinkId,
				actionType: objectActionEnum.REMOVE,
			});
		const interaction = contractInteractions.find(
		(interaction) => interaction.id === activeInteractionId
		);
		for (const { objectLink, ...values } of interaction?.interactionSteps || []) {
			if (
				objectLink?.destObjectId ===
				(sourceObject as CorporateObject)?.dentalObjectId
			) {
				removeObject({ ...values, actionType: objectActionEnum.REMOVE });
				break;
			}
		}
	};

	const handlePopoverClose = () =>
		setShowPopover(false);

	const handleConfirmRemoveObjectLink = () => {
		Modal.confirm({
			title: `Are you sure you want to remove ${(sourceObject as CorporateObject)?.name || ""}`,
			onOk: handleRemoveObjectLink,
		});
	};
	
	return (
		<div className="interaction-object-actions">
			{!objectNotePopover && <><div className="interaction-object-actions__header">
				<Row>
					<Col span={12}></Col>
					<Col span={12} className="text-right">
						<Button
							className="ant-btn-icon-only"
							onClick={handlePopoverClose}
						>
							<i className="icon-remove" />
						</Button>
						<Button
							className="ant-btn-icon-only ml-2"
							onClick={() => setObjectNotePopover?.()}
						>
							<i className="icon-Statement-notes" />
						</Button>
					</Col>
				</Row>
			</div>
			<div className="interaction-object-actions__body">
				<InteractionStepForm
					type={InteractionStepFormTypeEnum.OBJECT_LINK}
					setOpenConflict={setOpenConflict}
					createInteractionStep={createInteractionStep}
					onSuccess={onSuccess}
				/>
			</div></>}
			<Modal
				maskClosable={false}
				keyboard={false}
				className="interaction-object-actions__object-note-modal"
				centered
				width="35%"
				destroyOnClose
				closable={false}
				visible={objectNotePopover}
				onCancel={() => setObjectNotePopover?.(false)}
			>
				<CorporateObjectNoteForm
					onDelete={handleConfirmRemoveObjectLink}
					onSuccess={() => setObjectNotePopover?.(false)}
					onClose={() => setObjectNotePopover?.(false)}
					objectNote={new ObjectNote()}
				/>
			</Modal>
		</div>
	);
}

export default InteractionContainer(BabylonContainer(InteractionObjectActions));
