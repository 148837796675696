import React, { Fragment, useEffect, useState } from "react";
import { Button, Col, DatePicker, Drawer, Row, TimePicker } from "antd";
import MonthCalender from "./MonthCalender";
import moment from "moment";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import "./appointments.scss";
import DayCalender from "./DayCalender";
import DropdownField from "../../shared/components/DropdownField";
import { Formik, Form } from "formik";
import { Appointment } from "../../models/Appointment/appointment.model";
import { PlusOutlined } from "@ant-design/icons";
import AppointmentForm from "./AppointmentForm";
import { AppointmentService } from "../../services/Appointment/appointment.service";
import AppLoader from "../../shared/components/AppLoader";
import UserService from "../../services/User/user.service";
import { User } from "../../models/User/user.model";
import AuthContainer from "../../store/container/AuthContainer";
import { AuthReducerProps } from "../../store/reducers/authReducer";
import { FC } from "react";
import { ResponsibilityTypes } from "../../enums/responsebily.enum";
import { useResource } from "../../shared/hooks/Resource/useResource";
import AppointmentTemplate from "./AppointmentTemplate";
import { generatePath, useHistory, useLocation } from "react-router";
import AppRoutes from "../../routes/routeConstants/appRoutes";
import CreateAppointmentTable from "./AppointmentTemplate/CreateAppointmentTable";
import { AppointmentTemplate as AppointmentTemplateModel } from "../../models/AppointmentTemplate/appointmentTemplate.model";
import AppointmentDayView from "./AppointmentDayView";
import { AppointmentDayCalender } from "../../models/AppointmentDay/appointmentDay.model";
import { Moment } from "moment";
import {
  isActualTimeMaximum,
  isActualTimeMinimum,
} from "../../shared/utils/time";
import useResponsibilities from "../../shared/hooks/Responsibilities/useResponsibilities";

const { RangePicker } = TimePicker;

type Type = "day" | "month";
export interface DayFilterType {
  doctorName: string;
  title: string;
}

interface AppointmentProps {}

const Appointments: FC<AppointmentProps> = (props) => {
  const history = useHistory();

  const { pathname, search } = useLocation();

  const searchParams = new URLSearchParams(search);

  const { hasAccess } = useResponsibilities();

  // const [currentDate, setAppointmentDate] = useState(moment());

  const [appointments, setAppointments] = useState<Appointment[]>([]);
  const [appointmentDate, setAppointmentDate] = useState(searchParams?.get("date") ? moment(searchParams?.get("date"), "MM-DD-YYYY") : moment());

  const [appointmentFormVisibility, setAppointmentFormVisibility] = useState(
    false
  );

  const [selectedResource, setSelectedResource] = useState<number>();

  const [optionDoctors, setOptionDoctors] = useState<any>([]);

  const [startTime, setStartTime] = useState(moment("0001-01-01T08:00:00"));

  const [endTime, setEndTime] = useState(moment("0001-01-01T17:00:00"));

  const [selectedDoctor, setSelectedDoctor] = useState<number | undefined>(
    searchParams?.get("doctorProfileId") &&
      !isNaN(+searchParams?.get("doctorProfileId")!)
      ? +searchParams?.get("doctorProfileId")!
      : undefined
  );

  const [loading, setLoading] = useState(false);

  const [type, setType] = useState<Type>("day");

  const HAS_APPOINTMENT_CREATE_ACCESS = hasAccess(
    ResponsibilityTypes.APPOINTMENT_CREATE
  );

  const HAS_APPOINTMENT_TEMPLATE_VIEW_ACCESS = hasAccess(
    ResponsibilityTypes.APPOINTMENT_TEMPLATE_VIEW
  );

  const handleAppointmentUpdate = (appointment: Appointment) =>
    setAppointments((appointments) => [
      ...appointments.filter(
        (existingAppointment) => existingAppointment?.id !== appointment?.id
      ),
      appointment,
    ]);

  const updateTime = (time: string, type: "start" | "end") => {
    switch (type) {
      case "start": {
        if (isActualTimeMinimum("08:00am", time)) setStartTime(moment(time));
        else setStartTime(moment(time).set({ h: 8, m: 0, s: 0 }));
        break;
      }
      case "end": {
        if (isActualTimeMaximum("05:00pm", time)) setEndTime(moment(time));
        else setEndTime(moment(time).set({ h: 17, m: 0, s: 0 }));
      }
    }
  };

  const updateTimeFilter = (newTime: Moment, type: "start" | "end") => {
    (type === "end" ? setEndTime : setStartTime)((currentTime) => {
      const time = moment(currentTime);

      time.set({
        hours: newTime.get("hours"),
        minute: newTime.get("minutes"),
      });

      return time;
    });
  };

  useEffect(() => {
    setLoading(true);
    AppointmentService.fetchAppointment(
      {},
      (appointments) => {
        setAppointments(appointments);
      },
      () => {},
      () => {
        setLoading(false);
      }
    );

    UserService.fetchUsers(
      {},
      (users: User[]) => {
        let doctors: any = [];
        users.forEach(({ id, firstName, lastName, roleName, profileId }) => {
          if (roleName === "Doctor") {
            doctors = [
              ...doctors,
              {
                label: `${firstName} ${lastName}`,
                value: profileId,
              },
            ];
          }
        });
        setOptionDoctors(doctors);
      },
      () => {},
      () => {}
    );
  }, []);

  const handleDayMonth = (type: "day" | "month") => {
    setType(type);
  };

  const handleChangeMonth = (type: "add" | "subtract") => {
    setAppointmentDate((date) => moment(date)[type](1, "months"));

    searchParams.set("date", moment(appointmentDate)[type](1, "months").format("MM-DD-YYYY"));

    history.replace({
      pathname,
      search: searchParams.toString(),
    });
  };
  const handleChangeDay = (type: "add" | "subtract") => {
    setAppointmentDate((date) => moment(date)[type](1, "days"));

    searchParams.set("date", moment(appointmentDate)[type](1, "days").format("MM-DD-YYYY"));

    history.replace({
      pathname,
      search: searchParams.toString(),
    });
  };

  const handleDateSelect = (date: Moment) => {
    setAppointmentDate(date);

    searchParams.set("date", moment(date).format("MM-DD-YYYY"));

    history.replace({
      pathname,
      search: searchParams.toString(),
    });
  }

  const handleDoctorSelect = (doctorProfileId: number) => {
    setSelectedDoctor(doctorProfileId);
    
    searchParams.set("doctorProfileId", doctorProfileId?.toString());

    history.replace({
      pathname,
      search: searchParams.toString(),
    });
  };

  
  const resources = useResource("chair", "option");

  const handleCloseAppointmentForm = () => {
    setAppointmentFormVisibility(false);
  };

  const handleUpdateAppointment = (
    appointment: Appointment,
    type: "add" | "update"
  ) => {
    if (type === "add") {
      setAppointments((existingAppointments) => [
        ...existingAppointments,
        appointment,
      ]);
      handleCloseAppointmentForm();
      return;
    }
    setAppointments((existingAppointments) =>
      existingAppointments?.map((oldAppointment) => {
        if (oldAppointment.id === appointment.id) {
          return appointment;
        }
        handleCloseAppointmentForm();
        return oldAppointment;
      })
    );
  };

  return (
    <div className="appointments__container">
      <div className="appointments__container__header">
        <div className="date-picker__wrapper">
          <Fragment>
            <div
              className="date-change__icons"
              onClick={() =>
                type === "month"
                  ? handleChangeMonth("subtract")
                  : handleChangeDay("subtract")
              }
            >
              <LeftOutlined />
            </div>
            <DatePicker
              className="date-picker"
              picker={type == "month" ? "month" : "week"}
              format={type == "month" ? "MMMM YYYY" : "DD MMM"}
              value={moment(appointmentDate)}
              suffixIcon={null}
              clearIcon={null}
              onSelect={handleDateSelect}
            />

            <div
              className="date-change__icons"
              onClick={() =>
                type === "month"
                  ? handleChangeMonth("add")
                  : handleChangeDay("add")
              }
            >
              <RightOutlined />
            </div>
          </Fragment>
        </div>
          {type === "day" && (
            <Formik initialValues={{}} onSubmit={() => {}}>
              <Form className="filter-day__Wrapper">
                <RangePicker
                  picker="time"
                  suffixIcon={null}
                  clearIcon={null}
                  className="calender-range-picker"
                  bordered={false}
                  value={[startTime, endTime]}
                  format={"hh:mm a"}
                  onChange={([startTime, endTime]: any) => {
                    updateTimeFilter(startTime, "start");
                    updateTimeFilter(endTime, "end");
                  }}
                />
                <DropdownField
                  allowClear
                  name="doctor"
                  options={optionDoctors}
                  onChange={handleDoctorSelect}
                  value={selectedDoctor}
                  placeHolder="Select Doctor"
                />
                <DropdownField
                  allowClear
                  name="title"
                  placeHolder="Select Resource"
                  options={resources}
                  onChange={(resourceId: number) =>
                    setSelectedResource(resourceId)
                  }
                />
              </Form>
            </Formik>
          )}
        <div className="type__wrapper">
          {HAS_APPOINTMENT_TEMPLATE_VIEW_ACCESS && <Button
            className="secondary-btn "
            onClick={() => {
              history.push(generatePath(AppRoutes.APPOINTMENT_TEMPLATE));
            }}
          >
            Manage Appointment Template
          </Button>}
          <Button
            type={type === "day" ? "primary" : "text"}
            onClick={() => handleDayMonth("day")}
          >
            Day
          </Button>
          <Button
            type={type === "month" ? "primary" : "text"}
            onClick={() => handleDayMonth("month")}
          >
            Month
          </Button>
        </div>
      </div>

      {loading ? (
        <AppLoader loading />
      ) : type === "month" ? (
        <MonthCalender
          currentDate={appointmentDate}
          appointments={appointments}
          handleDayMonth={(appointmentDate: string) => {
            handleDayMonth("day");

            setAppointmentDate(moment(appointmentDate));
          }}
        />
      ) : (
        <AppointmentDayView
          appointments={appointments}
          filters={{
            date: appointmentDate,
            doctor: Number(selectedDoctor),
            endTime,
            resource: Number(selectedResource),
            startTime,
          }}
          onTimeUpdate={updateTime}
        />
      )}
      <Drawer
        title="New Appointment"
        width="75vw"
        visible={appointmentFormVisibility}
        closable
        onClose={handleCloseAppointmentForm}
        destroyOnClose
        push={false}
      >
        <AppointmentForm
          updateHandler={handleUpdateAppointment}
          closeHandler={handleCloseAppointmentForm}
          handleAppointmentUpdate={handleAppointmentUpdate}
          independentDrawer
        />
      </Drawer>
      {HAS_APPOINTMENT_CREATE_ACCESS && (
        <div
          className="appointment-create"
          onClick={() => setAppointmentFormVisibility(true)}
        >
          <PlusOutlined />
        </div>
      )}
    </div>
  );
};

export default Appointments;
