import { CreateReducer } from "../../shared/utils/createReducer";
import { Interaction } from "../../models/Interaction/interaction.model";
import {
  SetActiveInteractionIdAction,
  SetContractInteractionsAction,
  SetExpandedInteractionIdAction,
  SetObjectNotesAction,
} from "../actions/interactionActions";
import {
  SET_ACTIVE_INTERACTION_ID,
  SET_CONTRACT_INTERACTIONS,
  SET_EXPANDED_INTERACTION,
  SET_OBJECT_NOTES,
} from "../definitions/interactionConstants";
import { ObjectNote } from "../../models/ObjectNote/objectNote.model";

interface IInteractionState {
  contractInteractions: Interaction[];
  activeInteractionId?: number;
  objectNotes: ObjectNote[];
  expandedInteractionId?: number
}

export interface InteractionReducerProps extends IInteractionState {
  setContractInteractions: (
    contractInteractions: Interaction[]
  ) => SetContractInteractionsAction;
  setActiveInteractionId: (
    activeInteractionId?: number
  ) => SetActiveInteractionIdAction;
  setObjectNotes: (objectNotes: ObjectNote[]) => SetObjectNotesAction;
  setExpandedInteractionId: (interactionId: number) => SetExpandedInteractionIdAction;
}

const initState: IInteractionState = {
  contractInteractions: [],
  activeInteractionId: undefined,
  objectNotes: [],
  expandedInteractionId: undefined
};

const interactionReducer = CreateReducer(initState, {
  [SET_CONTRACT_INTERACTIONS](
    state: IInteractionState,
    action: SetContractInteractionsAction
  ) {
    const { contractInteractions } = action.payload;
    return {
      ...state,
      contractInteractions,
    };
  },
  [SET_ACTIVE_INTERACTION_ID](
    state: IInteractionState,
    action: SetActiveInteractionIdAction
  ) {
    const { activeInteractionId } = action.payload;
    return {
      ...state,
      activeInteractionId,
    };
  },
  [SET_OBJECT_NOTES](state: IInteractionState, action: SetObjectNotesAction) {
    const { objectNotes } = action.payload;
    return {
      ...state,
      objectNotes,
    };
  },
  [SET_EXPANDED_INTERACTION]: (
    state: IInteractionState,
    action: SetExpandedInteractionIdAction
  ) => ({
    ...state,
    expandedInteractionId: action.payload.expandedInteractionId,
  }),
});

export default interactionReducer;
