import React, { FC, useEffect, useState } from "react";
import { Formik, Form } from "formik";
import { Button } from "antd";
import { XRayNote } from "../../../../models/XRayNote/xRayNote.model";
import DropdownField from "../../../../shared/components/DropdownField";
import InputField from "../../../../shared/components/InputField";
import "./xRayNotesForm.scss";
import XrayNotesService from "../../../../services/XRayNotes/xRayNotes.service";
import { useParams } from "react-router";
import { XRayType } from "../../../../models/XRayType/xRayType.model";
import {
  IDropdownOptions,
  MetaService,
} from "../../../../services/Meta/meta.service";
import { getDropdownOptions } from "../../../../shared/utils/optionHelper";
import { NoteTemplateService } from "services/NoteTemplate/noteTemplate.service";

interface XRayNotesFormProps {
  update: () => void;
}

const XRayNotesForm: FC<XRayNotesFormProps> = (props) => {
  const { update } = props;

  const params: { interactionId: string } = useParams();

  const [formLoading, setFormLoading] = useState(false);

  const [xRayTypeOptions, setXRayTypeOptions] = useState<IDropdownOptions[]>(
    []
  );

  const [categoriesLoading, setCategoriesLoading] = useState(false);

  useEffect(() => {
    setCategoriesLoading(true);
    NoteTemplateService.fetchXRayCategory(
      (categories) => {
        setXRayTypeOptions(getDropdownOptions(categories, "name", "id"));
      },
      () => {},
      () => {
        setCategoriesLoading(false);
      }
    );
  }, []);

  const handleSubmit = (values: XRayNote) => {
    const xRayNote = Object.assign(new XRayNote(), values);
    setFormLoading(true);
    XrayNotesService.createXrayNote(
      Number(params.interactionId),
      xRayNote,
      () => {
        update();
      },
      () => {},
      () => {
        setFormLoading(false);
      }
    );
  };

  return (
    <div className="x-ray-notes-form">
      <h5>Add XRay notes</h5>
      <Formik initialValues={new XRayNote()} onSubmit={handleSubmit}>
        {({ values, setFieldValue }) => (
          <Form>
            <DropdownField
              darkmode
              placeHolder="X-ray Category"
              name="xRayTemplateCategoryId"
              value={values.xRayTemplateCategoryId}
              options={xRayTypeOptions}
              setFieldValue={setFieldValue}
              loading={categoriesLoading}
            />
            <InputField
              darkmode
              type="text"
              placeholder="Add notes"
              name="notes"
            />
            <div className="controller">
              <Button htmlType="submit" type="primary" loading={formLoading}>
                Add
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default XRayNotesForm;
