import { useEffect, useState } from "react";
import { User } from "../../../models/User/user.model";
import UserService, { UserOptions } from "../../../services/User/user.service";
import { SelectProp } from "../../Types/option";

export const useDoctors = (type?: "option", userOptions?: UserOptions) => {
  const [users, setUsers] = useState<User[] | SelectProp[]>([]);
  useEffect(() => {
    UserService.fetchUsers(
      userOptions ?? {},
      (users: User[]) => {
        if (type) {
          const options = users.map(({ profileId, firstName, lastName }) => ({
            label: `${firstName} ${lastName}` ?? "",
            key: profileId ?? "",
            value: profileId ?? "",
          }));
          setUsers(options);
          return;
        }
        setUsers(users);
      },
      () => {},
      () => {}
    );
  }, []);
  return users;
};
