export enum SIGNAL_R_NOTIFICATION {
  APPOINTMENT = "NewAppointment", 
  INTERACTION = "NewInteraction", 
  SCHEDULE = "NewSchedule", 
  REVIEW_NOTES = "ReviewNotes", 
  SERVICE = "NewService",
  TERM_OF_SERVICE = "NewTermsOfService",
  STAFF = "NewStaff", 
  DENTAL_OBJECT = "New3DObject", 
  SUBSCRIBE = "subscribe",
}
