import axiosInstance from "../../interceptor/axiosInstance";
import ApiRoutes from "../../routes/routeConstants/apiRoutes";
import { deserialize } from "serializr";
import {
  XRayAttachment,
  XRayImage,
} from "../../models/XRayImage/xRayImage.model";

class XrayImagesService {
  static fetchXrayImages(
    options: { practiceId: string; interactionId: string },
    onSuccess: (xRayImages: XRayImage[]) => void,
    onError: () => void,
    onFinal: () => void
  ) {
    const URL = ApiRoutes.GET_X_RAY_IMAGES.replace(
      ":id",
      options.interactionId
    ).replace(":PracticeId", options.practiceId);

    return axiosInstance
      .get(URL)
      .then((response) => {
        const xRayImages = deserialize(
          XRayImage,
          response.data["x_ray_images"] as any[]
          );
          onSuccess(xRayImages);
        })
      .catch((error) => {
        console.log(error?.message);
        
        onError();
      })
      .finally(onFinal);
  }

  static fetchXrayImage(
    options: { practiceId: string; interactionId: string },
    imageId: number,
    onSuccess: (xRayImages: XRayAttachment) => void,
    onError: () => void,
    onFinal: () => void
  ) {
    const URL =
      ApiRoutes.GET_X_RAY_IMAGES.replace(":id", options.interactionId).replace(
        ":PracticeId",
        options.practiceId
      ) + `/${imageId}`;

    return axiosInstance
      .get(URL)
      .then((response) => {
        const xRayImages = deserialize(
          XRayAttachment,
          response.data["x_ray_image"]
        );
        onSuccess(xRayImages);
      })
      .catch(() => {
        onError();
      })
      .finally(onFinal);
  }
  static createXrayImage(
    options: { practiceId: string; interactionId: string },
    xRayImage: any,
    onSuccess: (xRayImage: XRayImage) => void,
    onError: () => void,
    onFinal: () => void
  ) {
    const URL = ApiRoutes.GET_X_RAY_IMAGES.replace(
      ":id",
      options.interactionId
    ).replace(":PracticeId", options.practiceId);

    const formData = new FormData();

    formData.append("x_ray_image.attachment", xRayImage);

    return axiosInstance
      .post(URL, formData)
      .then((response) => {
        const xRayImage = deserialize(XRayImage, response.data["x_ray_image"]);
        onSuccess(xRayImage);
      })
      .catch(() => {
        onError();
      })
      .finally(onFinal);
  }

  static deleteXrayImage(
    id: number,
    options: { practiceId: string; interactionId: string },
    onSuccess: () => void,
    onError: () => void,
    onFinal: () => void
  ) {
    const URL =
      ApiRoutes.GET_X_RAY_IMAGES.replace(":id", options.interactionId).replace(
        ":PracticeId",
        options.practiceId
      ) + `/${id}`;
    return axiosInstance
      .delete(URL)
      .then(() => {
        onSuccess();
      })
      .catch(() => {
        onError();
      })
      .finally(onFinal);
  }
}

export default XrayImagesService;
