import React from "react";
import "./userList.scss";
import { User } from "../../../models/User/user.model";
import { Button, Modal, Table } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import UserService from "../../../services/User/user.service";
import { AuthReducerProps } from "../../../store/reducers/authReducer";
import AuthContainer from "../../../store/container/AuthContainer";
import { ResponsibilityTypes } from "../../../enums/responsebily.enum";

interface UserListProps extends AuthReducerProps {
  users: User[];
  onDeleteSuccess: (userId: number) => void;
}

function UserList({
  users,
  onDeleteSuccess,
  userResponsibilities,
}: UserListProps) {
  const handleDeleteUser = (userId: number) => {
    Modal.confirm({
      icon: null,
      content: <h5>Are you sure want to remove the user?</h5>,
      onOk() {
        UserService.deleteUser(
          userId,
          () => {
            onDeleteSuccess(userId);
          },
          () => {},
          () => {}
        );
      },
      onCancel() {},
    });
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text: string, record: any, index: number) => {
        return (
          <div>
            <div className="text-bold">
              {" "}
              {record?.firstName} {record?.lastName}{" "}
            </div>
            <div className="text-primary"> {record?.email} </div>
          </div>
        );
      },
    },
    {
      title: "Mobile",
      dataIndex: "mobileNumber",
      key: "mobileNumber",
      render: (text: string, record: any, index: number) => {
        return (
          <div>
            {record?.mobileNumber && (
              <div>
                {" "}
                (+{record?.isdCode}) {record?.mobileNumber}{" "}
              </div>
            )}
          </div>
        );
      },
    },
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
      render: (text: string, record: any, index: number) => {
        return <div className="text-capitalize"> {record?.gender}</div>;
      },
    },
    {
      title: "Role",
      dataIndex: "roleName",
      key: "roleName",
      render: (roleName: string, record: any, index: number) => {
        return (
          <div className="text-capitalize"> {roleName ?? record?.userRole}</div>
        );
      },
    },
    // {
    //   title: "Country",
    //   dataIndex: "country",
    //   key: "country",
    // },
    {
      title: "",
      dataIndex: "id",
      key: "id",
      render: (text: string, record: any, index: number) => {
        if (!userResponsibilities.includes(ResponsibilityTypes.STAFF_DELETE)) {
          return null;
        }
        return (
          <Button
            onClick={() => handleDeleteUser(record?.id)}
            className="delete-btn"
            icon={<DeleteOutlined />}
          />
        );
      },
    },
  ];

  return (
    <div className="user-list">
      <h5 className="text-secondary"> Users </h5>
      <Table dataSource={users} columns={columns} />
    </div>
  );
}

export default AuthContainer(UserList);
