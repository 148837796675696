import React, { useEffect, useState } from "react";
import { Menu, Row, Col } from "antd";
import "./appSidebar.scss";
import { useHistory } from "react-router-dom";
import AppRoutes from "../../../routes/routeConstants/appRoutes";
import avatarPlaceholder from "../../../assets/images/userPlaceholder.png";
import Badge from "../Badge";
import { AuthReducerProps } from "../../../store/reducers/authReducer";
import AuthContainer from "../../../store/container/AuthContainer";
import UserResponsibility from "../../../views/Settings/RolesAndResponsibility/UserResponsibility";
import { ResponsibilityTypes } from "../../../enums/responsebily.enum";
import { sidebarMenu } from "./data";
import { User } from "../../../models/User/user.model";
import useResponsibilities from "../../hooks/Responsibilities/useResponsibilities";

interface AppSidebarProps extends AuthReducerProps {
  user: User;
  collapsed: boolean;
}

function AppSidebar({ authenticated, collapsed, user }: AppSidebarProps) {
  const history = useHistory();

  const [hoveredItem, setHoveredItem] = useState("");

  const { hasAccess } = useResponsibilities();

  const [pathname, setPathname] = useState<string>(history.location.pathname);

  const handleClick = ({ key }: any) => {
    if (key !== "profile") history.push(key);
  };

  const handleLogout = () => {};

  useEffect(() => {
    return history.listen((location) => {
      setPathname(location.pathname);
    });
  }, [history]);

  const checkPermission = (permissions: ResponsibilityTypes[]) => {
    const permissionExist = permissions.length;

    const hasPermission = permissions.some((permission) =>
      hasAccess(permission)
    );

    return !permissionExist || hasPermission;
  };

  return authenticated && pathname !== AppRoutes.DETAILED_ONBOARDING ? (
    <div className={`app-sidebar ${collapsed ? "collapsed" : "expanded"}`}>
      <Menu
        className="app-sidebar__menubar"
        onClick={handleClick}
        defaultSelectedKeys={["dashboard"]}
        selectedKeys={[pathname]}
        mode="vertical"
      >
        {sidebarMenu.map(
          ({ icon: Icon, activeIcon: ActiveIcon, ...menuItem }) => {
            if (menuItem?.subMenuItems?.length) {
              return (
                <Menu.SubMenu
                  key={menuItem.path}
                  disabled={!checkPermission(menuItem.responsibility)}
                  className="app-sidebar__submenu"
                  title={
                    <div className="sub-menu__content-wrapper">
                      <div className="screen-over" />
                      {hoveredItem === menuItem.path ||
                      menuItem.path === pathname ||
                      menuItem?.subMenuItems?.some(
                        ({ path }) => pathname === path
                      ) ? (
                        <ActiveIcon
                          className="app-sidebar__menu icon"
                          width={18}
                          height={18}
                        />
                      ) : (
                        <Icon
                          className="app-sidebar__menu icon"
                          width={18}
                          height={18}
                        />
                      )}
                      <div className="app-sidebar__menu-title name">
                        {menuItem.label}
                      </div>
                    </div>
                  }
                  onTitleMouseEnter={() => setHoveredItem(menuItem.path)}
                  onTitleMouseLeave={() => setHoveredItem("")}
                >
                  {menuItem.subMenuItems.map(
                    ({
                      icon: Icon,
                      activeIcon: ActiveIcon,
                      path,
                      ...subMenuItem
                    }) => (
                      <Menu.Item
                        className="app-sidebar__submenu-item"
                        disabled={!checkPermission(subMenuItem.responsibility)}
                        key={path}
                        onMouseEnter={() => setHoveredItem(path)}
                        onMouseLeave={() => setHoveredItem("")}
                      >
                        <div className="screen-over" />
                        {path === hoveredItem || path === pathname ? (
                          <ActiveIcon className="icon" width={18} height={18} />
                        ) : (
                          <Icon className="icon" width={18} height={18} />
                        )}
                        <div className="app-sidebar__menu-title name">
                          {subMenuItem.label}
                        </div>
                      </Menu.Item>
                    )
                  )}
                </Menu.SubMenu>
              );
            }
            return (
              <Menu.Item
                disabled={!checkPermission(menuItem.responsibility)}
                key={menuItem.path}
                onMouseEnter={() => setHoveredItem(menuItem.path)}
                onMouseLeave={() => setHoveredItem("")}
              >
                <div className="screen-over" />
                {hoveredItem === menuItem.path || menuItem.path === pathname ? (
                  <ActiveIcon className="icon" width={18} height={18} />
                ) : (
                  <Icon className="icon" width={18} height={18} />
                )}
                <div className="app-sidebar__menu-title name">
                  {menuItem.label}
                </div>
              </Menu.Item>
            );
          }
        )}
        <Menu.Item className="app-sidebar__profile" key="profile">
          <Row gutter={[10, 0]}>
            <Col span={6}>
              <img
                src={avatarPlaceholder}
                className="app-sidebar__profile-img"
                alt="User Placeholder"
              />
            </Col>
            <Col span={18}>
              <div className="app-sidebar__profile-info">
                <h5 className="text-capitalize">{`${user?.firstName ?? ""} ${
                  user?.lastName ?? ""
                }`}</h5>
                <Badge>{user?.roleName}</Badge>
              </div>
            </Col>
          </Row>
        </Menu.Item>
      </Menu>
    </div>
  ) : null;
}

export default AuthContainer(AppSidebar);
