import { Button, Col, Modal, Row } from "antd";
import React, { FC, useEffect, useState } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import ContractContainer from "../../../../store/container/ContractContainer";
import { ContractReducerProps } from "../../../../store/reducers/contractReducer";
import "./contractForm.scss";
import SelectService from "./SelectService";
import { Contract } from "../../../../models/Contract/contract.model";
import ContractVisitFrequency from "./ContractVisitFrequency";
import ContractPaymentSchedule from "./ContractPaymentSchedule";
import ContractTermsAndConditions from "./ContractTermsAndConditions";
import ContractCustomForm from "./ContractCustomForm";
import ContractCardDetails from "./ContractCardDetails";
import ContractService from "../../../../services/Contract/Contract.service";
import AppLoader from "../../../../shared/components/AppLoader";
import { LeftOutlined } from "@ant-design/icons";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import AppRoutes from "routes/routeConstants/appRoutes";
import { ContractStatus } from "enums/contractStatus.enum";

const stripePromise = loadStripe(
  "pk_test_51JbQV5H6CIb9bhcSTe3om1l5yq7Fnl40NfT4Ypu52ZdkjqUNXn0SjFAIOq156gVDzws5oF2khqlwL8CZ5oai1Qha00ObboxIfc"
);

interface ContractFormProps extends ContractReducerProps {}

const ContractForm: FC<ContractFormProps> = ({ contract, setContract }) => {
  const history = useHistory();

  const location = useLocation();

  const query = new URLSearchParams(location.search);

  const patientId = query.get("patientId");

  const [isContractCancelling, setIsContractCancelling] = useState(false);

  const { contractId } = useParams();

  const { stepNo } = contract;

  const [loading, setLoading] = useState(false);

  const [contractStep, setContractStep] = useState(0);

  const navigateToContracts = () => history.push({
    pathname: AppRoutes.SERVICES,
    hash: "contract"
  })

  const cancelContract = (cancel: Function) => {
    if (contract?.id) {
      setIsContractCancelling(true);

      ContractService.updateContract(
        {
          ...contract,
          contractService: undefined,
          status: ContractStatus.CANCELLED
        },
        () => {
          navigateToContracts();
          cancel();
        },
        () => {},
        () => 
          setIsContractCancelling(false)
        
      );
    } else {
      navigateToContracts();
      cancel();
    }
  }

  const handleCancel = () => {
    Modal.confirm({
      centered: true,
      content: (
        <p className="mt-3">
          Are you sure, do you want to cancel the
          {contract?.name || "Contract"}
        </p>
      ),
      onOk: cancelContract,
      okButtonProps: { loading: isContractCancelling }
    });
  };

  const contractFormSteps = [
    <SelectService onCancel={handleCancel} />,
    // <ContractVisitFrequency onCancel={handleCancel} />,
    <ContractPaymentSchedule onContractStepUpdate={setContractStep} onCancel={handleCancel} />,
    <ContractTermsAndConditions onContractStepUpdate={setContractStep} contractStep={contractStep} onCancel={handleCancel} />,
    <ContractCustomForm onCancel={handleCancel} />,
    // <Elements stripe={stripePromise}>
    //   <ContractCardDetails onCancel={handleCancel} />
    // </Elements>,
  ];

  useEffect(() => {
    if (contractId) {
      setLoading(true);
      ContractService.showContract(
        contractId,
        (contract: Contract) => {
          setContract({
            ...contract,
            stepNo: contract.stepNo > 4 ? 0 : contract.stepNo,
          });
          setContractStep(contract?.stepNo);
        },
        () => {},
        () => {
          setLoading(false);
        }
      );
    } else {
      setContract({
        ...contract,
        patientProfileId: patientId ? Number(patientId) : undefined,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractId]);

  return (
    <div className="contract-form__container">
      <Row>
        <Col span={12}>
          <h4>
            {stepNo > 0 && (
              <Button
                onClick={navigateToContracts}
                className="mr-3"
                icon={<LeftOutlined />}
              />
            )}
            {contractId ? "Edit" : "New"} Contract
          </h4>
        </Col>
        <Col span={12} className="stepper-wrapper">
          <span>Step {stepNo + 1}/4</span>{" "}
          {Array(4)
            .fill("")
            .map((_, index) => (
              <div className={`dot ${index <= stepNo && "dot-active"}`} />
            ))}
        </Col>
      </Row>
      <div className="contract-form__component-wrapper">
        {loading ? <AppLoader loading={loading} /> : contractFormSteps[stepNo]}
      </div>
    </div>
  );
};
export default ContractContainer(ContractForm);
