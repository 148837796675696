import { Col, Popover, Row, Modal } from "antd";
import React, { FC, useEffect, useRef, useState } from "react";
import { InteractionStatus } from "../../../../../../models/InteractionStatuse/interactionStatuse.model";
import { InteractionSubStatuse } from "../../../../../../models/InteractionSubStatuse/interactionSubStatuse.model";
import { EllipsisOutlined, PlusOutlined } from "@ant-design/icons";
import "./interactionSubStatus.scss";
import SubStatusForm from "./SubStatusForm";
import { PatientStatuse } from "../../../../../../models/PatientStatuse/patientStatuse.model";
import { PatientSubStatuse } from "../../../../../../models/PatientSubStatuse/patientSubStatuse.model";
//@ts-ignore
import { SortableItem, swapArrayPositions } from "react-sort-list";
import PatientService from "../../../../../../services/Patient/patient.service";
import PatientStatusService from "../../../../../../services/PatientStatus/PatientStatus.service";
import useResponsibilities from "../../../../../../shared/hooks/Responsibilities/useResponsibilities";
import { ResponsibilityTypes } from "../../../../../../enums/responsebily.enum";
const { confirm } = Modal;

interface PatientSubStatusProps extends PatientStatuse {}

const colors = [
  "#EF4242",
  "#7ACC3B",
  "#00A99D",
  "#93989D",
  "#D0C421",
  "#4F4F4F",
  "#C52F2F",
];

const getColor = () => colors[Math.floor(Math.random() * colors.length)];
interface SubStatusProps extends InteractionSubStatuse {
  index: number;
  onDeleteHandler: (id?: number) => void;
  patientStatusId?: number;
  onEditHandler: (interactionSubStatuse: InteractionSubStatuse) => void;
}

const SubStatus: FC<SubStatusProps> = ({
  id,
  name,
  order,
  colorCode,
  isDefault,
  index,
  patientStatusId,
  onDeleteHandler,
  onEditHandler,
}) => {
  const { hasAccess } = useResponsibilities();

  const [visible, setVisible] = useState(false);

  const HAS_STATUS_APPOINTMENT_TYPE_EDIT_ACCESS = hasAccess(ResponsibilityTypes.STATUS_APPOINTMENT_TYPE_EDIT);

  const HAS_STATUS_APPOINTMENT_TYPE_DELETE_ACCESS = hasAccess(ResponsibilityTypes.STATUS_APPOINTMENT_TYPE_DELETE);

  const handleDeleteSubStatus = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    if(!HAS_STATUS_APPOINTMENT_TYPE_DELETE_ACCESS)  return;

    event.stopPropagation();
    confirm({
      content: `Are you sure you  want to delete ${name}`,
      onOk: () => {
        onDeleteHandler(id);
      },
      onCancel: () => {},
      okText: "Yes",
      cancelText: "No",
    });
  };

  const handleClose = () => {
    setVisible(false);
  };
  return (
    <Row className="sub-status__wrapper">
      <Col span={1} />
      <Col span={2}>
        <div
          className="gen-color"
          style={{
            backgroundColor: colorCode ?? getColor(),
          }}
        />
      </Col>
      <Col span={15}>{name}</Col>
      <Col span={2} className="delete__wrapper">
        <Popover
          placement="rightTop"
          title={"Appointment/interaction status"}
          content={
            <SubStatusForm
              onSubmitHandler={onEditHandler}
              closeHandler={handleClose}
              length={index}
              patientStatusId={patientStatusId}
              fromValue={{
                id,
                isDefault,
                name,
                order,
                colorCode
              }}
            />
          }
          trigger="click"
          visible={visible}
        >
          {!isDefault && HAS_STATUS_APPOINTMENT_TYPE_EDIT_ACCESS &&
            ((
              <i className="icon-edit" onClick={() => setVisible(true)} />
            ) as any)}
        </Popover>
      </Col>
      <Col span={2} className="delete__wrapper">
        {!isDefault && HAS_STATUS_APPOINTMENT_TYPE_DELETE_ACCESS &&
          ((
            <i className="icon-delete" onClick={handleDeleteSubStatus} />
          ) as any)}
      </Col>
      <Col span={2}>
        <EllipsisOutlined className="ellipsis-icon" />
      </Col>
    </Row>
  );
};

const PatientSubStatus: FC<PatientSubStatusProps> = ({
  id,
  name,
  patientSubStatuses,
}) => {
  const { hasAccess } = useResponsibilities();

  const [visible, setVisible] = useState(false);
  const [subStatuses, setSubStatuses] = useState<PatientSubStatuse[]>([]);

  const HAS_STATUS_APPOINTMENT_TYPE_EDIT_ACCESS = hasAccess(ResponsibilityTypes.STATUS_APPOINTMENT_TYPE_EDIT);

  const HAS_STATUS_APPOINTMENT_TYPE_CREATE_ACCESS = hasAccess(ResponsibilityTypes.STATUS_APPOINTMENT_TYPE_CREATE);

  useEffect(() => {
    setSubStatuses(patientSubStatuses);
  }, [patientSubStatuses]);

  const handleClose = () => {
    setVisible(false);
  };

  const handleOnSubmit = (interactionSubStatus: PatientSubStatuse) => {
    setSubStatuses((existingSubStatuses) => [
      ...existingSubStatuses,
      interactionSubStatus,
    ]);
    handleClose();
  };

  const swap = (dragIndex: number, dropIndex: number) => {
    const substatus = Object.assign(new PatientSubStatuse(), {
      ...subStatuses[dragIndex],
      order: dropIndex,
    });
    PatientStatusService.updatePatientStatus(
      id as number,
      substatus,
      () => {},
      () => {},
      () => {}
    );
    let swappedSubStatus = swapArrayPositions(
      subStatuses,
      dragIndex,
      dropIndex
    );
    setSubStatuses([...swappedSubStatus]);
  };

  const handleEdit = (
    InteractionSubStatuse: InteractionSubStatuse,
    index: number
  ) => {
    if(!HAS_STATUS_APPOINTMENT_TYPE_EDIT_ACCESS)return;

    setSubStatuses((subStatuses) => {
      subStatuses.splice(index, 1, InteractionSubStatuse);
      return [...subStatuses];
    });
  };

  return (
    <div className="interaction-sub-status__container mt-4">
      <h5 className="title">{name}</h5>
      <div className="interaction-sub-status__wrapper">
        {subStatuses?.map((patientStatus, index) => (
          <SortableItem
            items={subStatuses}
            id={patientStatus.id}
            key={patientStatus.id}
            swap={swap}
          >
            <SubStatus
              {...patientStatus}
              patientStatusId={id}
              key={index}
              index={index}
              onEditHandler={(interactionSubStatuse) =>
                handleEdit(interactionSubStatuse, index)
              }
              onDeleteHandler={(subStatusId) => {
                if (subStatusId) {
                  PatientStatusService.deletePatientStatus(
                    id as number,
                    subStatusId,
                    () => {
                      setSubStatuses((subStatuses) => {
                        return subStatuses?.filter(
                          (status) => status?.id !== subStatusId
                        );
                      });
                    },
                    () => {},
                    () => {}
                  );
                }
              }}
            />
          </SortableItem>
        ))}
        {HAS_STATUS_APPOINTMENT_TYPE_CREATE_ACCESS && <Popover
          placement="rightTop"
          title={"Appointment/interaction status"}
          content={
            <SubStatusForm
              onSubmitHandler={handleOnSubmit}
              closeHandler={handleClose}
              patientStatusId={id}
              length={subStatuses.length}
            />
          }
          trigger="click"
          visible={visible}
        >
          <span
            className="add-sub-status__wrapper"
            onClick={() => setVisible(true)}
          >
            <PlusOutlined /> Add Sub Status
          </span>
        </Popover>}
      </div>
    </div>
  );
};

export default PatientSubStatus;
