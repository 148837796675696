import * as Yup from "yup";

export const AppointmentFormValidation = Yup.object().shape({
  date: Yup.string().required("Date field is required"),
  session: Yup.string().required("Session is required"),
  startTime: Yup.string().required("start time required"),
  patientProfileId: Yup.string().required("Patient field is required"),
  appointmentTypeId: Yup.string().required(
    "Appointment type field is required"
  ),
  doctorProfileId: Yup.string().label("Doctor").when("isDoctorRequired", {
    is: (val) => !!val,
    then: (schema: Yup.ObjectSchema) => schema?.required(),
    otherwise:(schema: Yup.ObjectSchema) => schema?.nullable()?.notRequired()
  }),

  visitType: Yup.string().required("Visit type is required"),
  notes: Yup.string().nullable(),
});
