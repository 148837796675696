
import {serializable, alias, object, list, primitive} from 'serializr';

export class PatientSubStatuse { 

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('name', primitive()))
	name?: string;

	@serializable(alias('is_default', primitive()))
	isDefault?: boolean = false;

	@serializable(alias('order', primitive()))
	order?: number;

	@serializable(alias('color_code', primitive()))
	colorCode?: string;
}