import React, { useEffect, useRef, useState } from "react";
import { Form, Formik, FormikValues } from "formik";
import { registerBasicDetailsFormValidation } from "./registerBasicDetailsFormValidation";
import InputField from "../../../../shared/components/InputField";
import { Button, Row, Col } from "antd";
import DropdownField from "../../../../shared/components/DropdownField";
import RegisterContainer from "../../../../store/container/RegisterContainer";
import { Corporate } from "../../../../models/Corporate/corporate.model";
import { IDropdownOptions } from "../../../../services/Meta/meta.service";
import "./registerBasicDetailsForm.scss";
import { RegistrationService } from "../../../../services/Registration/registration.service";
import { RegisterReducerProps } from "../../../../store/reducers/registerReducer";
import AddressField from "../../../../shared/components/AddressField";
import MobileNumberField from "../../../../shared/components/MobileNumberField";
import { debounce } from "throttle-debounce";
import { CorporateService } from "../../../../services/Corporate/corporate.service";
import UserService from "../../../../services/User/user.service";

interface RegisterBasicDetailsFormProps extends RegisterReducerProps {}

function RegisterBasicDetailsForm(props: RegisterBasicDetailsFormProps) {
  const [formLoading, setFormLoading] = useState(false);

  const [formValues, setFormValues] = useState(new Corporate());

  const {
    registerActiveStep,
    setRegisterActiveStep,
    setActiveCorporate,
    activeCorporate,
  } = props;

  const [taxOptions] = useState([
    { label: "HST", value: "HST" },
    { label: "VAT", value: "VAT" },
    { label: "PST", value: "PST" },
    { label: "GST", value: "GST" },
    { label: "Other", value: "Other" },
  ]);

  const [isdCodeOptions, setIsdCodeOptions] = useState<IDropdownOptions[]>([]);

  const handleSubmit = (values: FormikValues) => {
    const corporate = Object.assign(new Corporate(), {
      ...values,
    });
    setFormLoading(true);
    if (activeCorporate && activeCorporate.id) {
      RegistrationService.updateCorporate(
        corporate,
        (corporate: Corporate) => {
          setActiveCorporate(corporate);
          setRegisterActiveStep(registerActiveStep + 1);
        },
        () => {},
        () => {
          setFormLoading(false);
        }
      );
    } else {
      corporate.stepNumber = 1;
      RegistrationService.createCorporate(
        corporate,
        (corporate: Corporate) => {
          setActiveCorporate(corporate);
          setRegisterActiveStep(registerActiveStep + 1);
        },
        () => {},
        () => {
          setFormLoading(false);
        }
      );
    }
  };

  useEffect(() => {
    if (activeCorporate) {
      setFormValues(activeCorporate);
    }
  }, [activeCorporate]);

  return (
    <div className="register-basic-details-form">
      <Formik
        initialValues={formValues}
        onSubmit={handleSubmit}
        enableReinitialize
        validationSchema={registerBasicDetailsFormValidation}
      >
        {({ values, isValid, dirty, setFieldValue, setFieldTouched }) => {
          return (
            <Form>
              <Row gutter={[20, 0]}>
                <Col span={12}>
                  <InputField
                    title="Corporate Name"
                    type="text"
                    name="name"
                    placeholder="Enter Corporate name"
                  />
                </Col>
                <Col span={12}>
                  <InputField
                    title="Contact person"
                    type="text"
                    name="contactPerson"
                    placeholder="Enter Contact person "
                  />
                </Col>
                <Col span={12}>
                  <InputField
                    title="Corporate register number"
                    type="text"
                    name="registerNumber"
                    placeholder="Enter Register number "
                  />
                </Col>
                <Col span={12}>
                  <DropdownField
                    title="Tax"
                    name="tax"
                    options={taxOptions}
                    value={values.tax}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    placeHolder="Select tax"
                  />
                </Col>
                <Col span={12}>
                  <InputField
                    title="No of practices"
                    type="number"
                    name="noOfPractices"
                    placeholder="Enter no of practices "
                  />
                </Col>
                <Col span={12}>
                  <InputField
                    title="No of staffs"
                    type="number"
                    name="noOfStaffs"
                    placeholder="Enter no of staffs "
                  />
                </Col>
                <Col span={12}>
                  <InputField
                    title="Primary Email"
                    type="email"
                    name="primaryEmail"
                    placeholder="Enter primary email"
                    hint="This would be your username while you login after registration"
                    validateSchema={CorporateService.validateEmail}
                    errorMessage="This Email is already taken"
                  />
                </Col>
                <Col span={12}>
                  <InputField
                    title="Secondary Email"
                    type="email"
                    name="secondaryEmail"
                    placeholder="Enter secondary email"
                  />
                </Col>
                <MobileNumberField
                  isdCodeKeyName="isdCode"
                  name="mobileNumber"
                  setFieldValue={setFieldValue}
                  value={values.isdCode}
                  validateSchema={UserService.validationUserPhone}
                  errorMessage={"Phone number is already taken"}
                />
                <Col span={12}>
                  <InputField
                    title="Address Line 1"
                    type="text"
                    name="addressLine1"
                    placeholder="Enter address line 1"
                  />
                </Col>
                <Col span={12}>
                  <InputField
                    title="Address Line 2"
                    type="text"
                    name="addressLine2"
                    placeholder="Enter address line 2"
                  />
                </Col>
                <AddressField
                  values={values}
                  setFieldValue={setFieldValue}
                  countryKey="countryId"
                  countryValue={values.countryId}
                  cityKey="cityId"
                  cityValue={values.cityId}
                  provinceKey="provinceId"
                  provinceValue={values.provinceId}
                  zipcodeKey="zipcode"
                />
                <Col span={12}>
                  <InputField
                    title="Website URL"
                    type="text"
                    name="websiteUrl"
                    placeholder="Enter Website URL"
                  />
                </Col>
                <Col span={12}>
                  <InputField
                    title="Facebook URL"
                    type="text"
                    name="facebookUrl"
                    placeholder="Enter Facebook URL"
                  />
                </Col>
                <Col span={12}>
                  <InputField
                    title="Twitter URL"
                    type="text"
                    name="twitterUrl"
                    placeholder="Enter Twitter URL"
                  />
                </Col>
                <Col span={12}>
                  <InputField
                    title="Linkedin URL"
                    type="text"
                    name="linkedinUrl"
                    placeholder="Enter Linkedin URL"
                  />
                </Col>
              </Row>
              <h4 className="text-secondary"> Create Password </h4>
              <Row gutter={[20, 0]}>
                <Col span={12}>
                  <InputField
                    title="Password"
                    type="password"
                    name="password"
                    placeholder="Enter Password"
                  />
                </Col>
                <Col span={12}>
                  <InputField
                    title="Confirm Password"
                    type="password"
                    name="confirmPassword"
                    placeholder="Confirm Password"
                  />
                </Col>
              </Row>
              <div className="register-basic-details-form__submit-wrapper text-right">
                <Button type="default">Cancel</Button>
                <Button
                  type="primary"
                  className="ml-2"
                  htmlType="submit"
                  loading={formLoading}
                  disabled={!isValid || !dirty || formLoading}
                >
                  Next
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default RegisterContainer(RegisterBasicDetailsForm);
