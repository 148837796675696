import { Scene } from "@babylonjs/core";
import { Collapse } from "antd";
import Checkbox, { CheckboxChangeEvent } from "antd/lib/checkbox/Checkbox";
import React, { FC, useEffect, useState } from "react";
import { CONFLICT_TYPES } from "../../../../enums/conflictType.enum";
import { objectActionEnum } from "../../../../enums/objectActionType.enum";
import { DentalSet } from "../../../../models/DentalSet/dentalSet.model";
import { InteractionStep } from "../../../../models/InteractionStep/interactionStep.model";
import { ProcedureStep } from "../../../../models/ProcedureStep/procedureStep.model";
import { SetStep } from "../../../../models/SetStep/setStep.model";
import { TreatmentPlanTemplate } from "../../../../models/TreatmentPlanTemplate/treatmentPlanTemplate.model";
import { TreatmentPlanTemplateVisit } from "../../../../models/TreatmentPlanTemplateVisit/treatmentPlanTemplateVisit.model";
import { TreatmentPlanTemplateVisits } from "../../../../models/TreatmentPlanTemplateVisits/treatmentPlanTemplateVisits.model";
import { InteractionService } from "../../../../services/Interaction/Interaction.service";
import InteractionStepService from "../../../../services/InteractionStep/interactionStep.service";
import TreatmentPlanService from "../../../../services/TreatmentPlan/treatmentPlan.service";
import {
	handleImportVendorObject,
	handleRemoveLink,
	checkConflictProcedure,
	checkConflict,
	checkConflictSet,
} from "../../../../shared/utils/babylonUtils";
import BabylonContainer from "../../../../store/container/BabylonContainer";
import InteractionContainer from "../../../../store/container/InteractionContainer";
import { BabylonReducerProps } from "../../../../store/reducers/babylonReducer";
import { InteractionReducerProps } from "../../../../store/reducers/interactionReducer";
import "./treatmentAppointments.scss";

const { Panel } = Collapse;
interface TreatmentAppointmentsProps
	extends BabylonReducerProps,
		InteractionReducerProps {
	treatmentPlanId: number;
	isCurrent: boolean;
	treatmentPlanTemplateVisit: TreatmentPlanTemplateVisits;
	currentUpdate: (id: number) => void;
	setOpenConflict: (open: boolean) => void;
	createInteractionStep: (step: InteractionStep) => void;
	onSuccess: () => void;
}

const TreatmentAppointments: FC<TreatmentAppointmentsProps> = (props) => {
	const {
		treatmentPlanTemplateVisit: {
			treatmentPlanStatementNote,
			treatmentPlanSteps,
			id,
			visitType,
		},
		treatmentPlanId,
		currentUpdate,
		scene,
		setShowPopover,
		isCurrent,
		setOpenConflict,
		createInteractionStep,
		pushConflict,
		setMeshLoading
	} = props;

	const [checked, setChecked] = useState<boolean[] | string[]>([]);

	const [isCurrentLoader, setIsCurrentLoader] = useState(false);

	useEffect(() => {
		setChecked(treatmentPlanSteps.map(() => false));
	}, [treatmentPlanSteps]);

	const saveInteractionStep = (
		interactionStep: InteractionStep,
		conflict?: CONFLICT_TYPES
	) => {
		if (conflict) {
			setShowPopover(false);
		}
		if (conflict === CONFLICT_TYPES.HARD_APPLY) {
			pushConflict(interactionStep);
			setOpenConflict(true);
		}

		if (!conflict) {
			createInteractionStep(interactionStep);
		}
	};

	const addVendorObject = async (values: InteractionStep, index: number) => {
		const interactionStep = Object.assign(new InteractionStep(), values);
		try{
		setMeshLoading?.(true);
		let conflict: any;
		if (interactionStep?.procedureId) {
			conflict = await checkConflictProcedure(
				interactionStep.procedureId,
				scene as Scene
			);
			saveInteractionStep(interactionStep, conflict);
		} else if (interactionStep?.setId) {
			conflict = await checkConflictSet(
				interactionStep?.setId,
				scene as Scene
			);
			saveInteractionStep(interactionStep, conflict);
		} else if (interactionStep?.objectLink) {
			const {
				srcObjectName,
				srcLocatorName,
				destObjectName,
			} = interactionStep?.objectLink;
			const mesh = scene?.getMeshByName(srcObjectName ?? "");
			if (mesh && srcObjectName && srcLocatorName && destObjectName) {
				conflict = await checkConflict(
					mesh,
					srcObjectName,
					srcLocatorName,
					destObjectName
				);
				saveInteractionStep(interactionStep, conflict);
			}
		}
		if (!conflict || conflict === CONFLICT_TYPES.HARD_APPLY) {
			setChecked((checked) => {
				checked.splice(index, 1, true);
				return checked;
			});
		} else {
			setChecked((checked) => {
				checked.splice(index, 1, false);
				return checked;
			});
		}
		}catch(ex){
			console.log(ex);
		}finally{
			setMeshLoading?.(false);
		}
	};

	const handleMarkAsCurrent = (event: CheckboxChangeEvent) => {
		if (event.target.checked) {
			setIsCurrentLoader(true);
			TreatmentPlanService.updateTreatmentPlanVisit(
				{
					treatmentPlanId: treatmentPlanId.toString(),
					id: id?.toString() ?? "",
				},
				() => {
					currentUpdate(id as number);
				},
				() => {},
				() => {
					setIsCurrentLoader(false);
				}
			);
		}
	};

	return (
		<div className="treatment-appointments">
			{!isCurrent && visitType === "physical" && (
				<Checkbox
					className="ml-4"
					onChange={handleMarkAsCurrent}
					disabled={isCurrentLoader}
				>
					Make as current visit
				</Checkbox>
			)}
			<Collapse ghost>
				<Panel header="Statement Notes" key="statement-notes">
					{treatmentPlanStatementNote?.map(({ description }) => (
						<p>{description}</p>
					))}
				</Panel>
				<Panel header="Notes" key="notes" className="notes">
					{treatmentPlanSteps?.map(
						(
							{ id, set, procedure, objectLink, corporateLinkId },
							index
						) => {
							const handleOnAdd = ({
								target: { checked },
							}: CheckboxChangeEvent) => {
								setChecked((checked) => {
									checked.splice(index, 1, "loading");
									return checked;
								});
								if (checked) {
									const vendorProps: InteractionStep = {
										actionType: checked
											? "link_add"
											: "link_remove",
										id,
									};
									if (set) {
										vendorProps.setId = set?.id;
										vendorProps.set = set;
									} else if (procedure) {
										vendorProps.procedureId = procedure?.id;
										vendorProps.procedure = procedure;
									} else {
										vendorProps.corporateLinkId = corporateLinkId;
										vendorProps.objectLink = objectLink;
									}
									addVendorObject(vendorProps, index);
								}
							};
							if (set) {
								return isCurrent ? (
									<Checkbox
										onChange={handleOnAdd}
										disabled={
											checked[index] === "loading"
												? true
												: false
										}
										checked={
											(typeof checked[index] === "boolean"
												? checked[index]
												: false) as boolean
										}
									>
										{set.name}
									</Checkbox>
								) : (
									<p className="ml-4">{set.name}</p>
								);
							}

							if (procedure) {
								return isCurrent ? (
									<Checkbox
										onChange={handleOnAdd}
										disabled={
											checked[index] === "loading"
												? true
												: false
										}
										checked={
											(typeof checked[index] === "boolean"
												? checked[index]
												: false) as boolean
										}
									>
										{procedure.name}
									</Checkbox>
								) : (
									<p className="ml-4">{procedure.name}</p>
								);
							}
							return isCurrent ? (
								<Checkbox
									disabled={
										checked[index] === "loading"
											? true
											: false
									}
									checked={
										(typeof checked[index] === "boolean"
											? checked[index]
											: false) as boolean
									}
									onChange={handleOnAdd}
								>
									{objectLink?.destObjectName}
								</Checkbox>
							) : (
								<p className="ml-4">
									{objectLink?.destObjectName}
								</p>
							);
						}
					)}
				</Panel>
			</Collapse>
		</div>
	);
};

export default BabylonContainer(InteractionContainer(TreatmentAppointments));
