import axiosInstance from "../../interceptor/axiosInstance";
import ApiRoutes from "../../routes/routeConstants/apiRoutes";
import moment from "moment";
import { deserialize, serialize } from "serializr";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import {
  Appointment,
  Appointments,
} from "../../models/Appointment/appointment.model";
import {
  AppointmentCalender,
  AppointmentCalenderStat,
} from "../../models/AppointmentCalender/appointmentCalender.model";
import {
  AppointmentDayCalender,
  DaySchedule,
} from "../../models/AppointmentDay/appointmentDay.model";
import { Remainder } from "../../models/Remainder/remainder.model";
import { AppointmentTiming } from "../../models/AppointmentTiming/appointmentTiming.model";
import { AppointmentTemplateSlotCreation } from "../../models/AppointmentTemplate/AppointmentTemplateCreateSlot/appointmentTemplateSlotCreation.model";
import { AppointmentTemplateSchedules } from "../../models/AppointmentTemplateSchedules/appointmentTemplateSchedules.model";
import { Slot } from "../../models/Slot/slot.model";
import { PaginationDetails } from "../../models/Pagination/pagination.model";
import { FilterParams } from "../../models/FilterParams/filterParams.model";
import {
  DoctorAppointmentDate,
  DoctorAppointmentDateParams,
} from "models/AppointmentPractice/appointmentPractice.model";
export class AppointmentService {
  static fetchAppointment(
    filters: {},
    onSuccess: (
      appointments: Appointment[],
      pagination: PaginationDetails
    ) => void,
    onError: () => void,
    onFinal: () => void,
    status?: string
  ) {
    const params = serialize(FilterParams, filters);
    return axiosInstance
      .get(status ? ApiRoutes.COMPLETED_APPOINTMENTS : ApiRoutes.APPOINTMENTS, {
        params,
      })
      .then((response) => {
        const { appointments } = deserialize(Appointments, response.data);
        const appointmentPagination = deserialize(
          PaginationDetails,
          response.data["meta"]
        );
        onSuccess(appointments, appointmentPagination);
      })
      .catch((error) => {
        onError();
      })
      .finally(onFinal);
  }
  static fetchPatientAppointment(
    patientProfileId: string,
    onSuccess: (appointments: Appointment[], meta: PaginationDetails) => void,
    onError: () => void,
    onFinal: () => void,
    status?: string,
    filters?: FilterParams
  ) {
    // const params = {
    // start_date: moment().clone().startOf("month").format("YYYY-MM-DD"),
    // end_date: moment().clone().endOf("month").format("YYYY-MM-DD"),
    // };
    return axiosInstance
      .get(status ? ApiRoutes.COMPLETED_APPOINTMENTS : ApiRoutes.APPOINTMENTS, {
        params: { ...(filters? serialize(FilterParams, filters) : {}), patient_profile_id: patientProfileId, },
      })
      .then((response) => {
        const meta = deserialize(PaginationDetails, response.data["meta"]);
        const { appointments } = deserialize(Appointments, response.data);
        onSuccess(appointments, meta);
      })
      .catch((error) => {
        onError();
      })
      .finally(onFinal);
  }

  static createAppointment(
    appointment: Appointment,
    onSuccess: (appointment: Appointment) => void,
    onError: (error: Error) => void,
    onFinal: () => void
  ) {
    const payload = { appointment: serialize(Appointment, appointment) };
    return axiosInstance
      .post(ApiRoutes.APPOINTMENTS, payload)
      .then((response) => {
        const appointment = deserialize(
          Appointment,
          response.data?.appointment
        );

        Notification({
          message: "New Appointment Created! ",
          type: NotificationTypes.SUCCESS,
        });
        onSuccess(appointment);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(onFinal);
  }

  static updateAppointment(
    appointment: Appointment,
    onSuccess: (appointment: Appointment) => void,
    onError: (error: Error) => void,
    onFinal: () => void,
    appointmentId?: number
  ) {
    const payload = { appointment: serialize(Appointment, appointment) };
    return axiosInstance
      .put(
        `${ApiRoutes.APPOINTMENTS}/${appointmentId ?? appointment?.id}`,
        payload
      )
      .then((response) => {
        const appointment = deserialize(
          Appointment,
          response.data?.appointment
        );
        Notification({
          message: "Appointment Updated! ",
          type: NotificationTypes.SUCCESS,
        });

        onSuccess(appointment);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(onFinal);
  }

  static deleteAppointment(
    appointment: Appointment,
    onSuccess: () => void,
    onError: (error: Error) => void,
    onFinal: () => void
  ) {
    return axiosInstance
      .delete(`${ApiRoutes.APPOINTMENTS}/${appointment?.id}`)
      .then(() => {
        Notification({
          message: "Appointment Deleted! ",
          type: NotificationTypes.SUCCESS,
        });
        onSuccess();
      })
      .catch((error) => {
        onError(error);
      })
      .finally(onFinal);
  }

  static fetchAppointmentCalender(
    params: { start_date: string; end_date: string },
    onSuccess: (appointmentCalender: AppointmentCalender[]) => void,
    onError: () => void,
    onFinal: () => void
  ) {
    return axiosInstance
      .get(ApiRoutes.APPOINTMENTS_CALENDER, { params })
      .then((response) => {
        const appointmentCalenderStat = deserialize(
          AppointmentCalenderStat,
          response.data
        );
        onSuccess(appointmentCalenderStat.appointmentsCalendar);
      })
      .catch((error) => {
        onError();
      })
      .finally(onFinal);
  }

  static addAsHoliday(
    date: string,
    onSuccess: () => void,
    onError: () => void,
    onFinal: () => void
  ) {
    const holidayJSON = {
      practice_holiday: {
        date,
      },
    };
    return axiosInstance
      .post(ApiRoutes.APPOINTMENT_CALENDER_HOLIDAY, holidayJSON)
      .then((response) => {
        onSuccess();
      })
      .catch((error) => {
        onError();
      })
      .finally(onFinal);
  }
  static removeHoliday(
    date: string,
    onSuccess: () => void,
    onError: () => void,
    onFinal: () => void
  ) {
    return axiosInstance
      .delete(`${ApiRoutes.APPOINTMENT_CALENDER_HOLIDAY}/date/${date}`)
      .then((response) => {
        onSuccess();
      })
      .catch((error) => {
        onError();
      })
      .finally(onFinal);
  }

  static createRemainder(
    remainder: Remainder,
    onSuccess: (remainder: Remainder) => void,
    onFinal: () => void
  ) {
    const remainderJSON = {
      reminder: serialize(remainder),
    };
    return axiosInstance
      .post(ApiRoutes.CREATE_REMAINDER, remainderJSON)
      .then((response) => {
        const remainder = deserialize(Remainder, response.data?.remainder);
        onSuccess(remainder);
      })
      .catch((error) => {})
      .finally(onFinal);
  }
  static fetchAppointmentSessionSlot(
    practiceId: string,
    appointmentTypeId: string,
    params: {},
    onSuccess: (appoitmentTiming: AppointmentTiming[]) => void,
    onError: () => void,
    onFinal: () => void
  ) {
    return axiosInstance
      .get(
        ApiRoutes.APPOINTMENT_SESSION_SLOT.replace(
          ":practiceId",
          practiceId
        ).replace(":appointmentTypeId", appointmentTypeId),
        { params }
      )
      .then((response) => {
        const practice = deserialize(
          AppointmentTiming,
          response.data["available_timings"] as AppointmentTiming[]
        );
        onSuccess(practice);
      })
      .catch((error) => {
        onError();
      })
      .finally(onFinal);
  }

  static deleteSlot(
    scheduleId: string,
    slotId: string,
    onSuccess: () => void,
    onError: () => void,
    onFinal: () => void
  ) {
    return axiosInstance
      .delete(
        ApiRoutes.REMOVE_APPOINTMENT_SLOT.replace(
          ":scheduleId",
          scheduleId
        ).replace(":slotId", slotId)
      )
      .then((response) => {
        onSuccess();
      })
      .catch((error) => {
        onError();
      })
      .finally(onFinal);
  }

  static updateSlot(
    scheduleId: string,
    slotId: string,
    param: Slot,
    onSuccess: () => void,
    onError: () => void,
    onFinal: () => void
  ) {
    const value = serialize(Slot, param);

    return axiosInstance
      .put(
        ApiRoutes.REMOVE_APPOINTMENT_SLOT.replace(
          ":scheduleId",
          scheduleId
        ).replace(":slotId", slotId),
        { slot: value }
      )
      .then((response) => {
        onSuccess();
      })
      .catch((error) => {
        onError();
      })
      .finally(onFinal);
  }

  static async fetchAppointmentDayView(
    date: string,
    onSuccess: (res: AppointmentDayCalender) => void,
    onError: () => void,
    onFinal: () => void
  ) {
    return axiosInstance
      .get(ApiRoutes.APPOINTMENT_CALENDAR_DAY_VIEW.replace(":date", date))
      .then((response) => {
        const deserializedAppointment = deserialize(
          AppointmentDayCalender,
          response.data
        );
        onSuccess(deserializedAppointment);
      })
      .catch((err) => {
        onError();
      })
      .finally(() => onFinal());
  }

  static updateAppointmentDayScheduleSlot(
    appointmentScheduleId: string,
    appointmentScheduleSlotId: string,
    appointmentType: AppointmentTemplateSlotCreation,
    onSuccess: (val: AppointmentDayCalender) => void,
    onError: Function,
    onFinal: Function
  ) {
    const serializedAppointmentType = serialize(
      AppointmentTemplateSlotCreation,
      appointmentType
    );
    return axiosInstance
      .put(
        ApiRoutes.UPDATE_APPOINTMENT_TYPE.replace(
          ":appointmentScheduleId",
          appointmentScheduleId
        ).replace(":appointmentScheduleSlotId", appointmentScheduleSlotId),
        {
          appointment_schedule_slot: serializedAppointmentType,
        }
      )
      .then((res) => {
        const deserializedSlots = deserialize(AppointmentDayCalender, res.data);
        onSuccess(deserializedSlots);
        Notification({
          message: `${res.data.slots_created ?? ""} Slots are created`,
          type: NotificationTypes.SUCCESS,
        });
      })
      .then((err) => {
        onError(err);
      })
      .finally(() => onFinal());
  }

  static deleteAppointmentDayScheduleSlot(
    appointmentScheduleId: string,
    appointmentScheduleSlotId: string,
    onSuccess: Function,
    onError: Function,
    onFinal: Function
  ) {
    return axiosInstance
      .delete(
        ApiRoutes.APPOINTMENT_CALENDAR_DAY_VIEW_DELETE.replace(
          ":appointmentScheduleId",
          appointmentScheduleId
        ).replace(":appointmentScheduleSlotId", appointmentScheduleSlotId)
      )
      .then(() => {
        onSuccess();
        Notification({
          message: "Appointment Slot Deleted Successfully",
          type: NotificationTypes.SUCCESS,
        });
      })
      .catch((err) => onError(err))
      .finally(() => onFinal());
  }

  static deleteAppointmentSchedule(
    appointmentScheduleId: string,
    onSuccess: Function,
    onError: Function,
    onFinal: Function
  ) {
    return axiosInstance
      .delete(
        ApiRoutes.DELETE_DAY_VIEW_APPOINTMENT_SCHEDULE.replace(
          ":appointmentScheduleId",
          appointmentScheduleId
        )
      )
      .then(() => {
        onSuccess();
        Notification({
          message: "Appointment Deleted Successfully",
          type: NotificationTypes.SUCCESS,
        });
      })
      .catch((err) => onError(err))
      .finally(() => onFinal());
  }

  static async fetchDoctorAppointments(
    params: DoctorAppointmentDateParams,
    onSuccess: (dates: DoctorAppointmentDate[]) => void,
    onError?: (ex: Error) => void,
    onFinal?: () => void
  ) {
    try {
      const { data } = await axiosInstance.get(ApiRoutes.DOCTOR_APPOINTMENTS, {
        params: serialize(DoctorAppointmentDateParams, params),
      });
      const appointmentDates = deserialize(
        DoctorAppointmentDate,
        data["appointment_dates"] as unknown[]
      );
      onSuccess(appointmentDates);
    } catch (ex) {
      onError?.(ex as Error);
    } finally {
      onFinal?.();
    }
  }
}
