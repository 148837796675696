import React, { useCallback, useEffect, useState } from "react";
import { Row, Col, Button, Tabs, Drawer } from "antd";
import { EditOutlined, LeftOutlined } from "@ant-design/icons";
import "./patientDetailTabs.scss";
import PatientDocuments from "../PatientDocuments";
import PatientForm from "../PatientForm";
import { Patient } from "../../../models/Patient/patient.model";
import PatientService from "../../../services/Patient/patient.service";
import { useHistory, useLocation, useParams } from "react-router-dom";
import PatientDetailWrapper from "../PatientDetailWrapper";
import UnderDevelopment from "../../../shared/components/UnderDevelopment";
import PatientContracts from "../PatientContracts";
import { ResponsibilityTypes } from "../../../enums/responsebily.enum";
import AuthContainer from "../../../store/container/AuthContainer";
import { AuthReducerProps } from "../../../store/reducers/authReducer";
import PaymentInvoices from "../PaymentInvoices";
import Visits from "../Visits";
import Tickets from "../Tickets";
import useFilters from "../../../shared/hooks/useFilter/useFilters";
import useResponsibilities from "../../../shared/hooks/Responsibilities/useResponsibilities";
import PatientNotes from "../PatientNotes";

const { TabPane } = Tabs;

interface PatientDetailTabsProps extends AuthReducerProps {}

function PatientDetailTabs(props: PatientDetailTabsProps) {
  const location = useLocation();
  const history = useHistory();

  const params: any = useParams();
  const { hasAccess } = useResponsibilities();

  const { getParams, setParams } = useFilters();
  const [patient, setPatient] = useState<Patient>();

  const [showPatientForm, setShowPatientForm] = useState(false);

  const HAS_PATIENT_EDIT_ACCESS = hasAccess(ResponsibilityTypes.PATIENT_EDIT);

  const HAS_PATIENT_DOC_VIEW_ACCESS = hasAccess(
    ResponsibilityTypes.PATIENT_DOC_VIEW
  );

  const HAS_CUSTOM_FORM_VIEW_ACCESS = hasAccess(
    ResponsibilityTypes.CUSTOM_FORM_VIEW
  );
  const handleShowPatientForm = () => setShowPatientForm(true);

  const handleClosePatientForm = () => setShowPatientForm(false);
  const [tab, setTab] = useState("0");

  const handleFormSuccess = (patient: Patient) => {
    setPatient(patient);
    handleClosePatientForm();
  };

  const handleShowPatient = useCallback(() => {
    if (params?.patientId) {
      PatientService.showPatient(
        params?.patientId,
        (patient: Patient) => {
          setPatient(patient);
        },
        () => {},
        () => {}
      );
    }
  }, [params]);

  useEffect(() => {
    handleShowPatient();
  }, [handleShowPatient]);

  const patientDetailTabDetails = [
    {
      key: "0",
      title: (
        <div>
          <i className="icon-patint-details" />
          <span>Patient Details</span>
        </div>
      ),
      component: patient ? (
        <PatientDetailWrapper tab={tab} patient={patient} />
      ) : null,
      hash: "patient-details",
    },
    {
      key: "1",
      title: (
        <div>
          <i className="icon-contract" />
          Contracts
        </div>
      ),
      component: <PatientContracts patient={patient} />,
      hash: "contracts",
    },
    {
      key: "2",
      title: (
        <div>
          <i className="icon-payments" />
          Payments & Invoices
        </div>
      ),
      component: <PaymentInvoices />,
      hash: "payments-invoices",
    },
    {
      key: "3",
      title: (
        <div>
          <i className="icon-visits" />
          Visits
        </div>
      ),
      component: <Visits />,
      hash: "visits",
    },
    ...(HAS_PATIENT_DOC_VIEW_ACCESS || HAS_CUSTOM_FORM_VIEW_ACCESS
      ? [
          {
            key: "4",
            title: (
              <div>
                <i className="icon-documents" />
                Documents
              </div>
            ),
            component: patient ? <PatientDocuments patient={patient} /> : null,
            hash: "documents",
          },
        ]
      : []),
    {
      key: "5",
      title: (
        <div>
          <i className="icon-your-tickets" />
          Your Tickets
        </div>
      ),
      component: <Tickets />,
      hash: "ticket",
    },
    {
      key: "6",
      title: <div>Patient Notes</div>,
      component: <PatientNotes />,
      hash: "notes",
    },
  ];
  useEffect(() => {
    const tabFromHash = patientDetailTabDetails.find(
      (tab) => tab.hash === location.hash.replace("#", "")
    );
    if (tabFromHash) setTab(tabFromHash?.key);
  }, [location.hash]);

  const handleTabChange = (activeKey: string) => {
    const hash = patientDetailTabDetails?.find(
      (tab, index) => tab?.key === activeKey
    )?.hash;

    setParams({}, hash);
    setTab(activeKey);
  };

  return (
    <div className="patient-detail-tabs">
      <Row align="middle">
        <Col span={12}>
          <Button
            onClick={() => history.goBack()}
            className="mr-3"
            icon={<LeftOutlined />}
          />
          Patient List /{" "}
          <span className="text-secondary">{patient?.getFullName()}</span>
        </Col>
        <Col span={12} className="patient-detail-tabs__edit-wrapper">
          {HAS_PATIENT_EDIT_ACCESS && (
            <Button type="primary" onClick={handleShowPatientForm}>
              <EditOutlined /> Edit Patient
            </Button>
          )}
        </Col>
      </Row>
      <div className="patient-detail-tabs__tab-wrapper">
        <Tabs
          activeKey={tab}
          onChange={handleTabChange}
          defaultActiveKey="0"
          size="small"
          style={{ marginBottom: 32 }}
        >
          {patientDetailTabDetails.map((patientDetailTab, i) => (
            <TabPane tab={patientDetailTab.title} key={i}>
              {patientDetailTab.component}
            </TabPane>
          ))}
        </Tabs>
      </div>
      {HAS_PATIENT_EDIT_ACCESS && (
        <Drawer
          title={
            <div>
              <h4>Edit Patient</h4>
            </div>
          }
          keyboard={false}
          placement="right"
          maskClosable={false}
          onClose={handleClosePatientForm}
          visible={showPatientForm}
          destroyOnClose={true}
          width="60%"
        >
          {patient && (
            <PatientForm
              patient={patient}
              onSuccess={handleFormSuccess}
              onCancel={handleClosePatientForm}
            />
          )}
        </Drawer>
      )}
    </div>
  );
}

export default AuthContainer(PatientDetailTabs);
