import {
  RESET_BABYLON,
  SET_CORPORATE_OBJECTS,
  SET_HIGHLIGHT_LAYER,
  SET_POPOVER_POSITION,
  SET_SCENE,
  SET_SHOW_POPOVER,
  SET_SOURCE_OBJECT,
  SET_TOOTH_POSITIONS,
  PUSH_CONFLICT,
  RESET_CONFLICT,
  SET_SOURCE_OBJECTS,
  SET_CAPS,
  SET_CORPORATE_OBJECTS_LOADING,
  SET_MESH_LOADING,
  SET_CAPS_LOADING,
} from "../definitions/babylonConstants";
import { HighlightLayer, Scene } from "@babylonjs/core";
import { CorporateObject, CorporateObjectWithBabylonFile } from "../../models/CorporateObject/corporateObject.model";
import { IPopoverPosition } from "../reducers/babylonReducer";
import { ToothPosition } from "../../models/ToothPosition/toothPosition.model";
import { SetStep } from "../../models/SetStep/setStep.model";
export interface SetSceneAction {
  type: typeof SET_SCENE;
  payload: { scene: Scene };
}

export interface SetHighlightLayerAction {
  type: typeof SET_HIGHLIGHT_LAYER;
  payload: { highlightLayer: HighlightLayer };
}

export interface SetSourceObjectAction {
  type: typeof SET_SOURCE_OBJECT;
  payload: { sourceObject?: CorporateObject };
}

export interface SetCorporateObjectsAction {
  type: typeof SET_CORPORATE_OBJECTS;
  payload: { corporateObjects: CorporateObject[] };
}

export interface SetShowPopoverAction {
  type: typeof SET_SHOW_POPOVER;
  payload: { showPopover: boolean };
}

export interface SetPopoverPositionAction {
  type: typeof SET_POPOVER_POSITION;
  payload: { popoverPosition: IPopoverPosition };
}

export interface SetToothPositionsAction {
  type: typeof SET_TOOTH_POSITIONS;
  payload: { toothPositions: ToothPosition[] };
}

export interface SetPushConflict {
  type: typeof PUSH_CONFLICT;
  payload: {
    conflict: SetStep,
  };
}

export interface SetCapsAction {
  type: typeof SET_CAPS;
  payload: { caps: CorporateObjectWithBabylonFile[] };
}

export interface SetMeshLoadingAction {
  type: typeof SET_MESH_LOADING;
  payload: { loading: boolean };
}

export const setScene = (scene: Scene): SetSceneAction => {
  return {
    type: SET_SCENE,
    payload: {
      scene,
    },
  };
};

export const setHighlightLayer = (
  highlightLayer: HighlightLayer
): SetHighlightLayerAction => {
  return {
    type: SET_HIGHLIGHT_LAYER,
    payload: {
      highlightLayer,
    },
  };
};

export const setSourceObject = (
  sourceObject: CorporateObject | undefined
): SetSourceObjectAction => {
  return {
    type: SET_SOURCE_OBJECT,
    payload: {
      sourceObject,
    },
  };
};

export const setCorporateObjects = (
  corporateObjects: CorporateObject[]
): SetCorporateObjectsAction => {
  return {
    type: SET_CORPORATE_OBJECTS,
    payload: {
      corporateObjects,
    },
  };
};

export const setShowPopover = (showPopover: boolean): SetShowPopoverAction => {
  return {
    type: SET_SHOW_POPOVER,
    payload: {
      showPopover,
    },
  };
};

export const setPopoverPosition = (
  popoverPosition: IPopoverPosition
): SetPopoverPositionAction => {
  return {
    type: SET_POPOVER_POSITION,
    payload: {
      popoverPosition,
    },
  };
};

export const setToothPositions = (
  toothPositions: ToothPosition[]
): SetToothPositionsAction => {
  return {
    type: SET_TOOTH_POSITIONS,
    payload: {
      toothPositions,
    },
  };
};

export const pushConflict = (
  conflict: SetStep
): SetPushConflict => {
  return {
    type: PUSH_CONFLICT,
    payload: {
      conflict,
    },
  };
};

export const setSourceObjects = (
  sourceObject: CorporateObject
) => {
  return {
    type: SET_SOURCE_OBJECTS,
    payload: {
      sourceObject
    }
  }
}

export const resetConflict = () => ({
  type: RESET_CONFLICT
})

export const resetBabylon = () => ({
  type: RESET_BABYLON,
})

export const setCaps = (
  caps: CorporateObjectWithBabylonFile[]
): SetCapsAction => ({
  type: SET_CAPS,
  payload: {
    caps,
  },
});

export const setCorporateObjectsLoading = (loading: boolean) => ({
  type: SET_CORPORATE_OBJECTS_LOADING,
  payload: {
    loading
  }
});

export const setCapsLoading = (loading: boolean) => ({
  type: SET_CAPS_LOADING,
  payload: {
    loading
  }
});

export const setMeshLoading = (loading: boolean) => ({
  type: SET_MESH_LOADING,
  payload: {
    loading,
  }
})