import { serializable, alias, object, list, primitive } from "serializr";

export class TreatmentPlanTemplateVisit {
  @serializable(alias("duration_in_weeks", primitive()))
  durationInWeeks?: number;

  @serializable(alias("description", primitive()))
  description?: string;

  @serializable(alias("order", primitive()))
  order?: number;

  @serializable(alias("visit_type", primitive()))
  visitType?: string = "physical";

  @serializable(alias("self_care_object_category_id", primitive()))
  selfCareObjectCategoryId?: string;

  @serializable(alias("self_care_frequency", primitive()))
  selfCareFrequency?: string;
  @serializable(alias("default_appointment_type_id", primitive()))
  defaultAppointmentTypeId?: string;

  @serializable(alias("self_care_frequency_unit", primitive()))
  selfCareFrequencyUnit?: string;
}
