import React, { useEffect, useState } from "react";
import { AppointmentType } from "../../../models/AppointmentType/appointmentType.model";
import { Suggestion } from "../../../models/Suggestion/suggestion.model";
import AppointmentTypeService from "../../../services/AppointmentType/appointmentType.service";
import { SelectProp } from "../../Types/option";

const NewAppointmentTypes = () => {
  const [actualAppointmentTypes, setActualAppointmentTypes] = useState<AppointmentType[]>([]);

  const [appointmentTypes, setAppointmentTypes] = useState<
    AppointmentType[] | SelectProp[]
  >([]);
  const [
    suggestedAppointment,
    setsuggestedAppointment,
  ] = useState<Suggestion>();
  const [appointmentLoading, setAppointmentLoading] = useState(false);

  const handleNewAppointmentTypes = (patientId: string): Promise<AppointmentType[]> => {
    return new Promise((resolve, reject) => {
    setAppointmentLoading(true);
    AppointmentTypeService.fetchNewAppointmentTypes(
      patientId,
      (appointmentTypes, suggestedAppointment) => {
        const options = appointmentTypes.map(({ id, nickName }) => ({
          label: nickName ?? "",
          key: id ?? "",
          value: id ?? "",
        }));
        setAppointmentTypes(options);
        setActualAppointmentTypes(appointmentTypes);
        setsuggestedAppointment(suggestedAppointment);
        resolve(appointmentTypes)
      },
      (er: Error) => {
        reject(er);
      },
      () => {
        setAppointmentLoading(false);
      }
    );
  })
  };

  return {
    appointmentTypes,
    handleNewAppointmentTypes,
    suggestedAppointment,
    appointmentLoading,
    actualAppointmentTypes
  };
};

export default NewAppointmentTypes;
