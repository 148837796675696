import { CustomField } from "../CustomField/customField.model";

import { serializable, alias, object, list, primitive } from "serializr";
import { CustomSection } from "../CustomSection/customSection.model";

export class CustomForm {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("name", primitive()))
  name?: string;

  @serializable(alias("type", primitive()))
  type?: string;

  @serializable(alias("description", primitive()))
  description?: string;

  @serializable(alias("is_applicable_to_response", primitive()))
  isApplicableToResponse?: boolean = true;

  @serializable(alias("no_of_responses", primitive()))
  noOfResponses?: number = 0;

  @serializable(alias("custom_fields", list(object(CustomField))))
  customFields?: CustomField[] = [];

  @serializable(alias("custom_sections", list(object(CustomSection))))
  sections: CustomSection[] = [];

  getCustomFields? = (): CustomField[] => {
    const fields: CustomField[] = [...(this.customFields || [])];

    this.sections?.forEach(({ customFields }) => {
      if (!customFields?.length) return;

      fields?.push(...customFields);
    });

    return fields;
  };
}
