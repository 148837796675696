import { useEffect, useState } from "react";
import { StatusType } from "../../../enums/statusType.enum";
import { InteractionStatus } from "../../../models/InteractionStatuse/interactionStatuse.model";
import InteractionStatusService from "../../../services/InteractionStatus/interactionStatus.service";
import { InteractionSubStatuse } from "../../../models/InteractionSubStatuse/interactionSubStatuse.model";
import { getDropdownOptions } from "../../utils/optionHelper";

export const useInteractionStatus = (type?: StatusType) => {
  const [loading, setLoading] = useState(false);

  const [statuses, setStatuses] = useState<InteractionStatus[]>([]);

  const updateStatusLoading = (loading: boolean) => setLoading(loading);

  useEffect(() => {
    setLoading(true);
    InteractionStatusService.fetchInteractionStatus(
      (statuses) => {
        setStatuses(statuses);
      },
      () => {},
      () => {
        setLoading(false);
      },
      type
    );
  }, []);

  const getOptions = (subStatuses: InteractionSubStatuse[] = []) =>
    getDropdownOptions(subStatuses, "name", "name");

  return {
    getOptions,
    loading,
    statuses,
    updateStatusLoading,
  };
};
