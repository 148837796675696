import { serializable, alias, primitive } from "serializr";

export class XRayCategory {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("description", primitive()))
  description?: string;

  @serializable(alias("name", primitive()))
  name?: string;
}
