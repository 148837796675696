import React, { useCallback, useEffect, useState } from "react";
import { Button, Col, Drawer, Popover, Row, Select } from "antd";
import { LeftOutlined, PlusOutlined, RightOutlined } from "@ant-design/icons";
import moment from "moment";
import { DateProps } from "../UserSchedule";
import ScheduleService from "../../../services/Schedule/schedule.service";
import { User } from "../../../models/User/user.model";
import AppLoader from "../../../shared/components/AppLoader";
import ScheduleForm from "../ScheduleForm";
import "./overallUserSchedules.scss";
import UserService from "../../../services/User/user.service";
import { UserRole } from "../../../models/UserRole/userRole.model";
import avatarPlaceholder from "../../../assets/images/userPlaceholder.png";
import ScheduleSlot from "../ScheduleSlot";
import { useHistory } from "react-router-dom";
import useResponsibilities from "../../../shared/hooks/Responsibilities/useResponsibilities";
import { ResponsibilityTypes } from "../../../enums/responsebily.enum";
import { Schedule } from "models/Schedule/schedule.model";

interface OverallUserSchedulesProps {}

function OverallUserSchedules(props: OverallUserSchedulesProps) {
  const history = useHistory();

  const { hasAccess } = useResponsibilities();

  const hadScheduleCreateAccess = hasAccess(
    ResponsibilityTypes.SCHEDULE_CREATE
  );

  const [loading, setLoading] = useState(false);

  const [users, setUsers] = useState<User[]>([]);
  const [currentRoleId, setCurrentRoleId] = useState<number>();

  const [userRoleOptions, setUserRoleOptions] = useState<any[]>([]);

  const [showScheduleForm, setShowScheduleForm] = useState(false);

  const [weekDays] = useState<string[]>([
    "Sun",
    "Mon",
    "Tue",
    "Wed",
    "Thu",
    "Fri",
    "Sat",
  ]);

  const [date, setDate] = useState<DateProps>({
    currentDate: new Date(),
  });

  const handleToggleScheduleForm = () => setShowScheduleForm(!showScheduleForm);

  const handleScheduleFormSuccess = () => {
    if (date.startDate && date.endDate) {
      handleFetchSchedules(date.startDate, date.endDate, currentRoleId);
    }
    setShowScheduleForm(false);
  };

  const handleFetchSchedules = useCallback(
    (startDate, endDate, roleId?: number) => {
      setLoading(true);
      ScheduleService.fetchSchedules(
        startDate,
        endDate,
        roleId ?? 0,
        10,
        1,
        (users: User[]) => {
          const updatedUsers = users?.map((user) => ({
            ...user,
            schedules: user?.schedules?.map((schedule) => ({
              ...schedule,
              slots: schedule?.slots?.filter((slot) => slot.type !== "break"),
            })),
          }));
          setUsers(updatedUsers as User[]);
        },
        () => {},
        () => {
          setLoading(false);
        }
      );
    },
    []
  );

  const handleNavigateNextWeek = () => {
    const startDate = moment(date.startDate, "YYYY-MM-DD")
      .add(7, "days")
      .format("YYYY-MM-DD");
    const endDate = moment(date.endDate, "YYYY-MM-DD")
      .add(7, "days")
      .format("YYYY-MM-DD");
    setDate({
      ...date,
      startDate,
      endDate,
      currentWeek: moment(startDate).week(),
      currentYear: moment(endDate).year(),
    });
    handleFetchSchedules(startDate, endDate, currentRoleId);
  };

  const handleNavigatePreviousWeek = () => {
    const startDate = moment(date.startDate, "YYYY-MM-DD")
      .subtract(7, "days")
      .format("YYYY-MM-DD");
    const endDate = moment(date.endDate, "YYYY-MM-DD")
      .subtract(7, "days")
      .format("YYYY-MM-DD");
    setDate({
      ...date,
      startDate,
      endDate,
      currentWeek: moment(startDate).week(),
      currentYear: moment(endDate).year(),
    });
    handleFetchSchedules(startDate, endDate, currentRoleId);
  };

  const handleFetchUserRoleOptions = () => {
    UserService.fetchUserRoles(
      (userRoles: UserRole[]) => {
        setUserRoleOptions(
          userRoles.map((userRole) => ({
            label: userRole.name,
            value: userRole.id,
          }))
        );
      },
      () => {},
      () => {}
    );
  };

  useEffect(() => {
    handleFetchUserRoleOptions();
    const startDate = moment(date.currentDate)
      .startOf("week")
      .format("YYYY-MM-DD");
    const endDate = moment(date.currentDate).endOf("week").format("YYYY-MM-DD");
    setDate({
      ...date,
      currentWeek: moment(date.currentDate).week(),
      currentYear: moment(date.currentDate).year(),
      startDate,
      endDate,
    });
    handleFetchSchedules(startDate, endDate, currentRoleId);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSelectRole = (roleId: number) => {
    setCurrentRoleId(roleId);
    handleFetchSchedules(date.startDate, date.endDate, roleId);
  };

  return (
    <div className="overall-user-schedules">
      <Row align="middle">
        <Col span={2}>
          <Button
            onClick={() => history.goBack()}
            className="mr-3"
            icon={<LeftOutlined />}
          />
        </Col>
        <Col span={12} className="overall-user-schedules__navigation-wrapper">
          <Button
            icon={<LeftOutlined />}
            onClick={handleNavigatePreviousWeek}
          />
          <Button>
            {moment(date?.startDate).format("MMM DD") +
              " - " +
              moment(date?.endDate).format("MMM DD") +
              " , " +
              moment(date?.startDate).format("YYYY")}
          </Button>
          <Button icon={<RightOutlined />} onClick={handleNavigateNextWeek} />
        </Col>
        <Col span={10} className="text-right">
          {hadScheduleCreateAccess && (
            <Button
              onClick={handleToggleScheduleForm}
              icon={<PlusOutlined />}
              className="user-schedule__add-button"
            />
          )}
          {/* 
          TODO: Need to be handled
          <Button>
            <i className="icon-print mr-2" />
            Print
          </Button>
          <Button type="primary" className="ml-2">
            <i className="icon-publish mr-2" /> Publish
          </Button> */}
        </Col>
      </Row>

      <div className="overall-user-schedules__calendar">
        <Row align="middle">
          <Col span={3}>
            <h5 className="text-center">All Staffs</h5>
            <Select
              options={userRoleOptions}
              placeholder="Select Role"
              onChange={handleSelectRole}
            />
          </Col>
          {weekDays.map((weekDay, i) => (
            <Col span={3} className="user-schedule__label">
              {weekDay}{" "}
              {moment(date?.startDate, "YYYY-MM-DD")
                .add(i, "days")
                .format("DD")}
            </Col>
          ))}
        </Row>
        {loading ? (
          <AppLoader loading />
        ) : (
          <div className="overall-user-schedules__schedules">
            {users?.map((user, i) => (
              <Row key={i} className="overall-user-schedules__user-schedules">
                <Col span={3}>
                  <div className="overall-user-schedules__user-details">
                    <div>
                      <img
                        src={user?.profilePictureUrl || avatarPlaceholder}
                        alt="User Profile"
                        className="overall-user-schedules__user-profile-picture"
                      />
                      <h5>{user?.firstName + " " + user?.lastName}</h5>
                    </div>
                  </div>
                </Col>
                {Schedule.sortSchedules?.(user?.schedules)?.map((schedule, i) => {
                  const more = [...schedule?.slots]
                    ?.slice(1)
                    ?.filter((slot) => slot?.type !== "break");
                  return schedule?.slots && schedule?.slots?.length > 0 ? (
                    <Col
                      span={3}
                      key={i}
                      className="overall-user-schedules__schedule-col"
                    >
                      <div className="overall-user-schedules__schedule-item">
                        {" "}
                        <ScheduleSlot
                          user={user}
                          slot={schedule?.slots[0]}
                          size="small"
                          scheduleId={schedule?.id}
                          onSlotUpdate={() => handleScheduleFormSuccess()}
                        />
                        <Popover
                          placement="leftTop"
                          title="Other slots"
                          content={
                            <div>
                              {[...schedule?.slots]
                                ?.slice(1)
                                .map((slot, index) =>
                                  slot.type === "break" ? null : (
                                    <ScheduleSlot
                                      user={user}
                                      slot={slot}
                                      scheduleId={schedule?.id}
                                      key={index}
                                      size="small"
                                      onSlotUpdate={() =>
                                        handleScheduleFormSuccess()
                                      }
                                    />
                                  )
                                )}
                            </div>
                          }
                          trigger="click"
                        >
                          <div className="cursor-pointer">
                            {more?.length ? `+${more?.length} More` : null}{" "}
                          </div>
                        </Popover>
                      </div>
                    </Col>
                  ) : (
                    <Col
                      span={3}
                      key={i}
                      className="overall-user-schedules__schedule-col"
                    >
                      <div className="overall-user-schedules__empty-slot">
                        Unavailable
                      </div>
                    </Col>
                  );
                })}
              </Row>
            ))}
          </div>
        )}
        {hadScheduleCreateAccess && <Drawer
            visible={showScheduleForm}
            title={<h4>New Shift Schedule</h4>}
            destroyOnClose
            maskClosable={false}
            keyboard={false}
            onClose={handleToggleScheduleForm}
            width="60%"
          >
            <ScheduleForm
              isMultiple
              onSuccess={handleScheduleFormSuccess}
              onCancel={handleToggleScheduleForm}
            />
        </Drawer>}
      </div>
    </div>
  );
}

export default OverallUserSchedules;
