import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { CustomFormRequests } from "../../../models/CustomFormRequests/customFormRequests.model";
import CustomFormRequestService from "../../../services/CustomFormRequest/customFormRequest.service";
import CustomFormResponseForm from "../../Patients/CustomFormResponseForm";
import CustomFormResponseDetail from "../../Patients/CustomFormResponseDetail";
import { CustomFormResponse } from "../../../models/CustomFormResponse/customFormResponse.model";
import { CustomFormTypeEnum } from "../../../enums/customFormType.enum";
import CustomFormResponseService from "../../../services/CustomFormResponse/customFormResponse.service";
import AppLoader from "../../../shared/components/AppLoader";

import "./interactionCustomForm.scss";

const InteractionCustomForm = () => {
  const { interactionId } = useParams<{
    interactionId: string;
  }>();

  const [loading, setLoading] = useState(false);

  const [
    customFormRequest,
    setCustomFormRequest,
  ] = useState<CustomFormRequests>();

  const [
    customFormResponse,
    setCustomFormResponse,
  ] = useState<CustomFormResponse>();

  const handleCustomFormSubmit = (response: CustomFormResponse) =>
    setCustomFormResponse(response);

  const fetchCustomFormData = async () => {
    setLoading(true);
    const customFormRequests = await CustomFormRequestService.fetchCustomFormRequest(
      {
        requestableType: CustomFormTypeEnum.INTERACTION,
        requestableId: interactionId,
      },
      (customFormRequests) => setCustomFormRequest(customFormRequests?.[0]),
      () => {},
      () => setLoading(false)
    );

    if (!customFormRequests) return;

    const isCustomFormFilled = !!customFormRequests?.reverse()?.[0]
      ?.customFormResponseId;

    if (!isCustomFormFilled) return;

    setLoading(true);
    CustomFormResponseService.fetchCustomFormResponses(
      CustomFormTypeEnum.INTERACTION,
      (response: CustomFormResponse[]) => setCustomFormResponse(response?.[0]),
      () => {},
      () => setLoading(false),
      +interactionId
    );
  };

  useEffect(() => {
    fetchCustomFormData();
  }, []);

  return (
    <div className="interaction-custom-form">
      {loading ? (
        <AppLoader loading={loading} />
      ) : customFormResponse ? (
        <CustomFormResponseDetail customFormResponse={customFormResponse} />
      ) : customFormRequest?.customFormId ? (
        <CustomFormResponseForm
          resourceId={+interactionId}
          customFormId={customFormRequest?.customFormId}
          onSuccess={handleCustomFormSubmit}
          onCancel={() => {}}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default InteractionCustomForm;
