import { UserRoles } from "../../enums/userRoles.enum";
import { User } from "../../models/User/user.model";
import { compareStringByIgnoringCase } from "./helpers";

export const isCorporateAdmin = (user?: User | null) =>
  compareStringByIgnoringCase(user?.roleName, UserRoles.CORPORATE_ADMIN);

export const isDoctor = (user?: User | null) =>
  compareStringByIgnoringCase(user?.roleName, UserRoles.DOCTOR);

export const isFrontDeskPerson = (user?: User | null) =>
  compareStringByIgnoringCase(user?.roleName, UserRoles.FRONT_DESK);
