import { Button, Drawer } from "antd";
import React, { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Stats from "../../../shared/components/Stats";
import ListContract from "./ListContract";
import { PlusOutlined, FilterFilled } from "@ant-design/icons";
import {
  Contract,
  Contractmetrics,
} from "../../../models/Contract/contract.model";
import ContractService from "../../../services/Contract/Contract.service";
import { useHistory } from "react-router";
import AppRoutes from "../../../routes/routeConstants/appRoutes";
import AppLoader from "../../../shared/components/AppLoader";
import ContractContainer from "../../../store/container/ContractContainer";
import { ContractReducerProps } from "../../../store/reducers/contractReducer";
import AuthContainer from "../../../store/container/AuthContainer";
import { AuthReducerProps } from "../../../store/reducers/authReducer";
import { ResponsibilityTypes } from "../../../enums/responsebily.enum";
import { StatsType } from "../../../enums/statsType.enum";
import ContractFilterForm from "./ContractFilterForm";
import { getFileIcon } from "../../../shared/utils/getFileIcon";
import "./contract.scss";
import { PaginationDetails } from "../../../models/Pagination/pagination.model";
import { FilterParams } from "../../../models/FilterParams/filterParams.model";
import useFilters from "../../../shared/hooks/useFilter/useFilters";
import MetricsService from "../../../services/Metrics/metrics.service";
import { Metrics } from "../../../models/Metrics/metrics.model";
import useResponsibilities from "../../../shared/hooks/Responsibilities/useResponsibilities";

interface ContractsProps extends ContractReducerProps, AuthReducerProps {}

const ContractTermsTemplate: FC<ContractsProps> = ({ resetContract }) => {
  const { getParams, setParams } = useFilters();
  const history = useHistory();

  const { hasAccess } = useResponsibilities();

  const [contracts, setContracts] = useState<Contract[]>([]);

  const [isFilterFormVisible, setIsFilterFormVisible] = useState(false);
  const [contractMetrics, setContractMetrics] = useState<Metrics>();
  const [
    contractPagination,
    setContactPagination,
  ] = useState<PaginationDetails>();
  const [currentPage, setCurrentPage] = useState<number>(1);

  const handleFilterFormVisibility = () => {
    setIsFilterFormVisible(!isFilterFormVisible);
  };

  const [loading, setLoading] = useState(false);

  const HAS_PATIENT_CONTRACT_CREATE_ACCESS = hasAccess(
    ResponsibilityTypes.PATIENT_CONTRACT_CREATE
  );

  const handleUpdateContract = (updatedContract: Contract[]) => {
    handleFetchContracts(getParams());
  };

  const handleFetchContracts = (filter?: FilterParams) => {
    setLoading(true);

    ContractService.fetchContracts(
      filter ?? new FilterParams(),
      (contracts, metrics, pagination) => {
        setContracts([...contracts]);
        // setContractMetrics(metrics);
        setContactPagination(pagination);
      },
      () => {},
      () => {
        setLoading(false);
      }
    );
  };
  useEffect(() => {
    handleFetchContracts(getParams());
    ContractService.fetchContractMetrics(
      "contracts",
      (metrics: Metrics) => {
        setContractMetrics(metrics);
      },
      () => {},
      () => {}
    );
  }, []);

  const ContractStatsData = [
    {
      title: "Total Contracts",
      value: contractMetrics?.totalNoOfContracts ?? 0,
      total: contractMetrics?.totalValue ?? 0,
    },
    {
      title: "Active Contracts",
      value: contractMetrics?.totalNoOfActiveContracts ?? 0,
      total: contractMetrics?.totalValueOfActiveContracts ?? 0,
    },
    {
      title: "Completed Contracts",
      value: contractMetrics?.totalNoOfCompletedContracts ?? 0,
      total: contractMetrics?.totalValueOfCompletedContracts ?? 0,
    },
  ];

  return (
    <div>
      <div className="service-list__add-wrapper">
        {/* <div className="service-list__download-icon"> */}
        {/*   <img
          src={getFileIcon("pdf")}
          alt="File icon"
          className="file-detail__file-icon mr-5"
        />
        <img
          src={getFileIcon("xls")}
          alt="File icon"
          className="file-detail__file-icon mr-5"
        /> */}

        <Button className="secondary-btn" onClick={handleFilterFormVisibility}>
          <FilterFilled height={20} />
          Filter
        </Button>
        {HAS_PATIENT_CONTRACT_CREATE_ACCESS && (
          <Link
            to={AppRoutes.ADD_CONTRACT}
            onClick={() => {
              resetContract();
            }}
          >
            <Button type="primary" className="ml-3">
              <PlusOutlined />
              Add Contract
            </Button>
          </Link>
        )}
      </div>

      {loading ? (
        <AppLoader loading={loading} />
      ) : (
        <>
          {" "}
          <Stats statsData={ContractStatsData} statsType={StatsType.CONTRACT} />
          <ListContract
            contracts={contracts}
            updateContact={handleUpdateContract}
            contractPagination={contractPagination}
            fetchContracts={(filter: FilterParams, hash?: string) => {
              handleFetchContracts(filter);
            }}
          />
        </>
      )}
      <Drawer
        title="Contracts"
        width="50vw"
        visible={isFilterFormVisible}
        // onClose={handleCloseNextAppointment}
        closable
        // destroyOnClose
        onClose={handleFilterFormVisibility}
      >
        <ContractFilterForm
          onClose={handleFilterFormVisibility}
          onSuccess={(contract, _, pagination) => {
            setContracts(contract);
            setContactPagination(pagination);
          }}
        />
      </Drawer>
    </div>
  );
};

export default AuthContainer(ContractContainer(ContractTermsTemplate));
