import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";
import * as ProcedureActions from "../actions/procedureActions";
import { RootReducerProps } from "../reducers";

const mapStateToProps = (state: RootReducerProps, ownProps: any) => {
  return {
    currentProcedure: state.procedure.currentProcedure,
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(ProcedureActions, dispatch);

const ProcedureContainer = (component: any) => {
  return connect(mapStateToProps, mapDispatchToProps)(component);
};

export default ProcedureContainer;
