import React, { useEffect, useState } from "react";
import { PatientStatuse } from "../../../../../models/PatientStatuse/patientStatuse.model";
import PatientStatusService from "../../../../../services/PatientStatus/PatientStatus.service";
import PatientSubStatus from "./InterationSubStatus";
import "./patientStatus.scss";
const PatientStatus = () => {
    const [patientStatuses, setPatientStatuses] = useState<PatientStatuse[]>();

	useEffect(() => {
		PatientStatusService.fetchPatientStatuss(
			(patientStatuses) => {
                setPatientStatuses(patientStatuses);
			},
			() => {},
			() => {}
		);
	}, []);

	return (
		<div className="patient-status__container">
			{patientStatuses?.map((patientStatus) => (
				<PatientSubStatus {...patientStatus} />
			))}
		</div>
	);
};

export default PatientStatus;
