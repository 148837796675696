import { ResponsibilityTypes } from "../../../enums/responsebily.enum";
import AppRoutes from "../../../routes/routeConstants/appRoutes";
import { ReactComponent as Analytics } from "assets/icons/analytics.svg";
import { ReactComponent as ActiveAnalytics } from "assets/icons/analytics-1.svg";
import { ReactComponent as Appointments } from "assets/icons/appointments.svg";
import { ReactComponent as ActiveAppointments } from "assets/icons/appointments-1.svg";
import { ReactComponent as Config } from "assets/icons/config.svg";
import { ReactComponent as ActiveConfig } from "assets/icons/config-1.svg";
import { ReactComponent as CustomForms } from "assets/icons/custom-forms.svg";
import { ReactComponent as ActiveCustomForms } from "assets/icons/custom-forms-1.svg";
import { ReactComponent as Dashboard } from "assets/icons/dashboard.svg";
import { ReactComponent as ActiveDashboard } from "assets/icons/dashboard-1.svg";
import { ReactComponent as Patients } from "assets/icons/patients.svg";
import { ReactComponent as ActivePatients } from "assets/icons/patients-1.svg";
import { ReactComponent as Payments } from "assets/icons/payments.svg";
import { ReactComponent as ActivePayments } from "assets/icons/payments-1.svg";
import { ReactComponent as Practice } from "assets/icons/practice.svg";
import { ReactComponent as ActivePractice } from "assets/icons/practice-1.svg";
import { ReactComponent as Profile } from "assets/icons/profile.svg";
import { ReactComponent as ActiveProfile } from "assets/icons/profile-1.svg";
import { ReactComponent as Roles } from "assets/icons/roles.svg";
import { ReactComponent as ActiveRoles } from "assets/icons/roles-1.svg";
import { ReactComponent as Services } from "assets/icons/services.svg";
import { ReactComponent as ActiveServices } from "assets/icons/services-1.svg";
import { ReactComponent as Settings } from "assets/icons/settings.svg";
import { ReactComponent as ActiveSettings } from "assets/icons/settings-1.svg";
import { ReactComponent as Staff } from "assets/icons/staff.svg";
import { ReactComponent as ActiveStaff } from "assets/icons/staff-1.svg";
import { ReactComponent as Support } from "assets/icons/support.svg";
import { ReactComponent as ActiveSupport } from "assets/icons/support-1.svg";

export const sidebarMenu = [
  {
    label: "Appointments",
    icon: Appointments,
    activeIcon: ActiveAppointments,
    path: AppRoutes.APPOINTMENTS,
    responsibility: [ResponsibilityTypes.APPOINTMENT_VIEW],
  },
  {
    label: "Job List",
    icon: Dashboard,
    activeIcon: ActiveDashboard,
    path: AppRoutes.DASHBOARD,
    responsibility: [
      // ResponsibilityTypes.APPOINTMENT_VIEW,
      // ResponsibilityTypes.INTERACTION_VIEW,
    ],
  },
  {
    label: "Services",
    icon: Services,
    activeIcon: ActiveServices,
    path: AppRoutes.SERVICES,
    responsibility: [
      ResponsibilityTypes.SERVICE_VIEW,
      ResponsibilityTypes.CONTRACT_TERM_VIEW,
      ResponsibilityTypes.PATIENT_CONTRACT_VIEW,
    ],
  },

  {
    label: "Patients",
    icon: Patients,
    activeIcon: ActivePatients,
    path: AppRoutes.PATIENTS,
    responsibility: [ResponsibilityTypes.PATIENT_VIEW],
  },
  {
    label: "Payments",
    icon: Payments,
    activeIcon: ActivePayments,
    path: AppRoutes.PAYMENTS,
    responsibility: [ResponsibilityTypes.PAYMENT_INVOICES_VIEW],
  },
  {
    label: "Support",
    icon: Support,
    activeIcon: ActiveSupport,
    path: AppRoutes.SUPPORT,
    responsibility: [ResponsibilityTypes.ISSUE_VIEW],
  },
  {
    label: "Settings",
    icon: Settings,
    activeIcon: ActiveSettings,
    path: AppRoutes.SETTINGS,
    responsibility: [],
    subMenuItems: [
      {
        label: "Staffs",
        icon: Staff,
        activeIcon: ActiveStaff,
        path: AppRoutes.STAFFS,
        responsibility: [ResponsibilityTypes.STAFF_VIEW],
      },
      {
        label: "Custom forms",
        icon: CustomForms,
        activeIcon: ActiveCustomForms,
        path: AppRoutes.CUSTOM_FORMS,
        responsibility: [ResponsibilityTypes.CUSTOM_FORM_VIEW],
      },
      {
        label: "Practices",
        icon: Practice,
        activeIcon: ActivePractice,
        path: AppRoutes.PRACTICES,
        responsibility: [ResponsibilityTypes.PRACTICE_VIEW],
      },
      {
        label: "Configuration",
        icon: Config,
        activeIcon: ActiveConfig,
        path: AppRoutes.CONFIGURATION,
        responsibility: [],
      },
      {
        label: "Roles & Responsibility",
        icon: Roles,
        activeIcon: ActiveRoles,
        path: AppRoutes.ROLES,
        responsibility: [ResponsibilityTypes.ROLE_VIEW],
      },
      {
        label: "Profile Settings",
        icon: Profile,
        activeIcon: ActiveProfile,
        path: AppRoutes.CORPORATE_DETAILS,
        responsibility: [
          ResponsibilityTypes.CORPORATE_VIEW,
          ResponsibilityTypes.SUBSCRIPTION_VIEW,
        ],
      },
    ],
  },
  {
    label: "Analytics",
    icon: Analytics,
    activeIcon: ActiveAnalytics,
    path: AppRoutes.ANALYTICS,
    responsibility: [],
  },
];
