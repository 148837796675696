import React, { useEffect, useState } from "react";
import { Button, Col, Drawer, Modal, Row, Switch, Table } from "antd";
import {
  CheckOutlined,
  CloseOutlined,
  PlusOutlined,
  CalendarOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { User } from "../../../models/User/user.model";
import UserService from "../../../services/User/user.service";
import UserForm from "../../../shared/components/UserForm";
import "./userList.scss";
import AppLoader from "../../../shared/components/AppLoader";
import AppRoutes from "../../../routes/routeConstants/appRoutes";
import { Link, generatePath, useHistory } from "react-router-dom";
import { TableColumn } from "../../../shared/Types/tableColumn";
import { ResponsibilityTypes } from "../../../enums/responsebily.enum";
import Stats from "../../../shared/components/Stats";
import { StatsType } from "../../../enums/statsType.enum";
import { useMetrics } from "../../../shared/hooks/Metrics/useMetrics";
import { PaginationDetails } from "../../../models/Pagination/pagination.model";
import { TablePaginationConfig } from "antd/lib/table";
import useFilters from "../../../shared/hooks/useFilter/useFilters";
import { FilterParams } from "../../../models/FilterParams/filterParams.model";
import useResponsibilities from "../../../shared/hooks/Responsibilities/useResponsibilities";
import { isCorporateAdmin } from "../../../shared/utils/roleUtils";
import Searchbar from "../../../shared/components/Searchbar";
import DoctorAppointments from "./DoctorAppointments";
import { UserRoles } from "enums/userRoles.enum";

interface UserListProps {}

function UserList(props: UserListProps) {
  const { getParams, setParams } = useFilters();

  const { hasAccess } = useResponsibilities();

  const history = useHistory();

  const [isUserUpdating, setIsUserUpdating] = useState(false);

  const [users, setUsers] = useState<User[]>([]);
  const [userOptions, setUserOptions] = useState<User[]>([]);
  const [pagination, setPagination] = useState<PaginationDetails>();

  const [loading, setLoading] = useState(false);

  const [isCompleteProfile, setCompleteProfile] = useState(false);

  const [activeUser, setActiveUser] = useState<User>(new User());

  const [showUserForm, setShowUserForm] = useState(false);

  const practiceId = localStorage.getItem("practiceId") ?? undefined;

  const { metrics, handleFetchMetrics } = useMetrics("users", { practiceId });

  const [
    doctorProfileIdToDisable,
    setDoctorProfileIdToDisable,
  ] = useState<User>();

  const [columns, setColumns] = useState<TableColumn[]>([
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text: string, record: User, index: number) => {
        return (
          <div>
            <div className="text-bold">
              {record?.firstName ?? ""} {record?.lastName ?? ""}
            </div>
            <div className="text-primary"> {record?.email} </div>
          </div>
        );
      },
    },
    {
      title: "Mobile",
      dataIndex: "mobileNumber",
      key: "mobileNumber",
      render: (text: string, record: User, index: number) => {
        return (
          <div>
            {record?.mobileNumber && (
              <div>
                (+{record?.isdCode}) {record?.mobileNumber}{" "}
              </div>
            )}
          </div>
        );
      },
    },
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
      render: (text: string, record: User, index: number) => {
        return <div className="text-capitalize"> {record?.gender}</div>;
      },
    },
    {
      title: "Role",
      dataIndex: "roleName",
      key: "roleName",
    },
    {
      title: "Default Practice",
      dataIndex: "defaultPracticeName",
      key: "defaultPracticeName",
    },
  ]);

  const HAS_CORPORATE_EDIT_ACCESS = hasAccess(
    ResponsibilityTypes.CORPORATE_EDIT
  );

  const HAS_STAFF_VIEW_ACCESS = hasAccess(ResponsibilityTypes.STAFF_VIEW);

  const HAS_STAFF_CREATE_ACCESS = hasAccess(ResponsibilityTypes.STAFF_CREATE);

  const HAS_STAFF_EDIT_ACCESS = hasAccess(ResponsibilityTypes.STAFF_EDIT);

  const HAS_SCHEDULE_VIEW_ACCESS = hasAccess(ResponsibilityTypes.SCHEDULE_VIEW);

  const handleShowUserForm = () => {
    if (showUserForm) {
      setActiveUser(new User());
      if (isCompleteProfile) setCompleteProfile(false);
    }
    setShowUserForm(!showUserForm);
  };

  const handleUserFormSuccess = (user: User) => {
    const userIndex = users.findIndex(
      (existingUser) => existingUser.id === user.id
    );
    if (userIndex >= 0) {
      users[userIndex] = user;
    } else {
      users.unshift(user);
    }
    setUsers([...users]);
    setShowUserForm(false);
    handleFetchMetrics();
  };

  const handleRow = (user: User, rowIndex: number | undefined) => {
    return {
      onClick: (event: any) => {
        if (!HAS_STAFF_VIEW_ACCESS) return;
        history.push(
          generatePath(AppRoutes.STAFF_DETAIL, {
            staffId: user.id,
          })
        );
      },
    };
  };

  const handleEditUser = (user: User, isCompleteProfile?: boolean) => () => {
    if (isCompleteProfile) setCompleteProfile(true);
    setActiveUser(user);
    setShowUserForm(true);
  };

  const handleUser = (params: FilterParams, type?: string) => {
    setLoading(true);
    UserService.fetchUsers(
      type == "all"
        ? { ...params, practiceId }
        : { ...params, practiceId, page: params?.page ?? 1, pageSize: 10 },
      (users: User[], pagination: PaginationDetails) => {
        if (type == "all") {
          setUserOptions(users);
          return;
        }
        setUsers(users);
        setPagination(pagination);
      },
      () => {},
      () => {
        setLoading(false);
      }
    );
  };

  const handleActiveStatusChange = (user: User, isActive: boolean) => {
    Modal.confirm({
      centered: true,
      content: `Are you sure, you want to make ${user?.getFullName()} inactive`,
      okButtonProps: { loading: isUserUpdating },
      onOk: (close) => {
        const updatedUser = Object.assign(new User(), {
          ...user,
          isActive,
        });
        setIsUserUpdating(true);
        UserService.updateUser(
          updatedUser,
          (user: User) => {
            setUsers((users) =>
              users?.map((existingUser) =>
                existingUser?.id === user?.id ? user : existingUser
              )
            );
          },
          () => {
            const isDoctor = user?.roleName === UserRoles.DOCTOR;

            if (isDoctor) setDoctorProfileIdToDisable(user);
          },
          () => {
            setIsUserUpdating(false);
            close();
          }
        );
      },
    });
  };

  useEffect(() => {
    handleUser(getParams());

    setColumns((columns) => {
      return [
        ...columns,
        {
          title: "",
          dataIndex: "id",
          key: "id",
          render: (text: string, user: User) => {
            const isCurrentUserAdmin = isCorporateAdmin(user);

            const HAS_STAFF_EDIT_ACCESS_BASED_ON_ROLE =
              HAS_STAFF_EDIT_ACCESS &&
              (!isCurrentUserAdmin || HAS_CORPORATE_EDIT_ACCESS);

            return (
              <div
                className="text-right"
                onClick={(e) => e.stopPropagation()}
                style={{ width: "350px" }}
              >
                {HAS_STAFF_EDIT_ACCESS_BASED_ON_ROLE && (
                  <Button
                    className={`secondary-btn ${
                      isProfileCompleted(user) ? "present-but-not" : ""
                    }`}
                    onClick={handleEditUser(user, true)}
                  >
                    Complete profile
                  </Button>
                )}
                {HAS_STAFF_EDIT_ACCESS_BASED_ON_ROLE && (
                  <Switch
                    className="ml-4"
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    checked={user?.isActive}
                    onChange={(isActive) =>
                      handleActiveStatusChange(user, isActive)
                    }
                    loading={isUserUpdating}
                  />
                )}
                {/* Add 0 for non profile user for restricting to schedule page */}
                {HAS_SCHEDULE_VIEW_ACCESS && (
                  <Link
                    to={generatePath(AppRoutes.STAFF_SCHEDULE, {
                      staffId: user?.profileId ?? 0,
                    })}
                  >
                    <Button
                      className="secondary-btn ml-4"
                      icon={<CalendarOutlined />}
                    />
                  </Link>
                )}
                {HAS_STAFF_EDIT_ACCESS_BASED_ON_ROLE && (
                  <Button
                    type="primary"
                    className="ml-4"
                    icon={<EditOutlined />}
                    onClick={handleEditUser(user)}
                  />
                )}
              </div>
            );
          },
        },
      ];
    });
    handleUser({}, "all");
  }, []);

  const isProfileCompleted = (user: User) => {
    if (
      user?.countryId &&
      user?.cityId &&
      user?.provinceId &&
      user?.zipcode &&
      user.nationalityId
    ) {
      return true;
    }
    return false;
  };
  const staffStatsData = [
    { title: "Total Staffs of Practices", value: metrics?.totalStaffs ?? 0 },
    {
      title: "Total Doctors",
      value: metrics?.noOfDoctors ?? 0,
    },
    {
      title: "Total Assistants",
      value: metrics?.noOfAssistant ?? 0,
    },
  ];

  const handleTableChange = (pagination: TablePaginationConfig) => {
    const prevFilter = getParams();
    const updatedFilters = {
      ...prevFilter,
      page: pagination.current ?? 1,
    };
    setParams(updatedFilters);
    handleUser(updatedFilters);
  };
  const handleSearch = (value: string) => {};
  const options = userOptions?.map((user) => {
    const content = (
      <Row className="user-option__wrapper">
        <Col span={8}>
          <Row>
            <Col span={24}>
              {user?.firstName}
              {user?.lastName}
            </Col>
            <Col span={24} className="user-email">
              {user?.email}
            </Col>
          </Row>
        </Col>
        <Col span={8}>{user?.mobileNumber}</Col>
        <Col span={8}>{user?.email}</Col>
      </Row>
    );
    return {
      value: `${user.firstName} ${user.lastName}`,
      label: content,
      key: user.id,
    };
  });

  return (
    <div className="staff-list">
      <Row align="middle">
        <Col span={12}>
          <Searchbar
            searchHandler={handleSearch}
            selectHandler={(selectedValue) => {
              history.push(
                generatePath(AppRoutes.STAFF_DETAIL, {
                  staffId: selectedValue,
                })
              );
            }}
            options={options}
          />
        </Col>
        <Col span={12} className="text-right">
          {HAS_SCHEDULE_VIEW_ACCESS && (
            <Link to={AppRoutes.OVERALL_STAFF_SCHEDULES}>
              <Button className="secondary-btn">
                <CalendarOutlined />
                Manage Schedule
              </Button>
            </Link>
          )}
          {HAS_STAFF_CREATE_ACCESS && (
            <Button
              type="primary"
              className="ml-3"
              onClick={handleShowUserForm}
            >
              <PlusOutlined />
              Add Staff
            </Button>
          )}
        </Col>
      </Row>
      <div>
        {loading ? (
          <AppLoader loading={loading} />
        ) : (
          <>
            {" "}
            <Stats
              statsData={staffStatsData}
              statsType={StatsType.STAFF}
            />{" "}
            <Table
              dataSource={users}
              columns={columns}
              onChange={handleTableChange}
              pagination={{
                total: pagination?.totalCount,
                current: pagination?.currentPage ?? 1,
                showTotal: (total: number, range: [number, number]) => (
                  <p>
                    Showing <b>{` ${range[0]} - ${range[1]}`}</b> of{" "}
                    <b>{`${total.toLocaleString()}`}</b>
                  </p>
                ),
              }}
              onRow={handleRow}
            />
          </>
        )}
      </div>
      <Drawer
        title={
          <div>
            <h4>
              {isCompleteProfile
                ? "Complete Profile"
                : activeUser?.id
                ? "Edit User"
                : "Add User"}
            </h4>
          </div>
        }
        placement="right"
        keyboard={false}
        maskClosable={false}
        onClose={handleShowUserForm}
        visible={showUserForm}
        destroyOnClose={true}
        width="60%"
      >
        <UserForm
          isAdvanced
          user={activeUser}
          onCancel={handleShowUserForm}
          onSuccess={handleUserFormSuccess}
        />
      </Drawer>
      <Drawer
        destroyOnClose
        onClose={() => setDoctorProfileIdToDisable(undefined)}
        title={`Future appointments of Dr. ${doctorProfileIdToDisable?.getFullName()}`}
        visible={!!doctorProfileIdToDisable?.profileId}
        width="60%"
      >
        <DoctorAppointments
          doctorProfileId={+doctorProfileIdToDisable?.profileId!}
        />
      </Drawer>
    </div>
  );
}

export default UserList;
