import React, { FC } from "react";
import { Tabs } from "antd";
import AppRoutes from "../../../../routes/routeConstants/appRoutes";
import { useHistory, useLocation } from "react-router";
import { RouteProps } from "../../../../routes";
import AppointmentStatus from "./AppointmentStatus";
import AppointmentType from "./AppointmentType";
import PatientStatus from "./PatientStatus";
import { Switch, Route, Redirect } from "react-router-dom";
import "./statusManagement.scss";
import AuthContainer from "../../../../store/container/AuthContainer";
import { AuthReducerProps } from "../../../../store/reducers/authReducer";
import { ResponsibilityTypes } from "../../../../enums/responsebily.enum";
import { useEffect } from "react";
import { useState } from "react";
import { TabProps } from "../../../../shared/Types/tab.type";

const { TabPane } = Tabs;

const tabs = [
  {
    title: "Appointment / Interaction Status",
    path: AppRoutes.APPOINTMENT_STATUS,
    key: ResponsibilityTypes.STATUS_APPOINTMENT_TYPE_VIEW,
  },
  {
    title: "Appointment Type",
    path: AppRoutes.APPOINTMENT_TYPE,
    key: ResponsibilityTypes.STATUS_APPOINTMENT_TYPE_VIEW,
  },
  {
    title: "Patient Status",
    path: AppRoutes.PATIENT_STATUS,
    key: ResponsibilityTypes.STATUS_APPOINTMENT_TYPE_VIEW,
  },
];

const StatusManagementRoutes: RouteProps[] = [
  {
    exact: true,
    component: AppointmentStatus,
    path: AppRoutes.APPOINTMENT_STATUS,
  },
  {
    exact: true,
    component: AppointmentType,
    path: AppRoutes.APPOINTMENT_TYPE,
  },
  {
    exact: true,
    component: PatientStatus,
    path: AppRoutes.PATIENT_STATUS,
  },
];

interface StatusManagementProps extends AuthReducerProps {}

const StatusManagement: FC<StatusManagementProps> = ({
  userResponsibilities,
}) => {
  const location = useLocation();

  const history = useHistory();

  const [statusManagementTabs, setStatusManagementTabs] = useState<TabProps[]>(
    []
  );

  const [activeTab, setActiveTab] = useState("0");

  useEffect(() => {
    const statusManagementTabs: TabProps[] = [];
    tabs.forEach((tab) => {
      if (hasPermission(tab.key)) {
        statusManagementTabs.push(tab);
      }
    });
    setStatusManagementTabs(statusManagementTabs);
    findActiveKey();
  }, [userResponsibilities]);

  const handleChangeTab = (key: string) => {
    setActiveTab(key);
    history.push(StatusManagementRoutes[parseInt(key) as number]?.path);
  };

  const findActiveKey = () => {
    for (let i in statusManagementTabs) {
      if (statusManagementTabs[i].path === location.pathname) {
        setActiveTab((parseInt(i) - 1).toString());
      }
    }
  };

  const hasPermission = (role: string) => {
    if (userResponsibilities.includes(role) || !role) {
      return true;
    }
    return false;
  };

  return (
    <div className="status-management__container">
      <h5 className="appointment-title">
        Appointment / Interaction & Patient Status Management
      </h5>
      <Tabs
        type="card"
        onChange={handleChangeTab}
        className="status-tabs"
        activeKey={activeTab}
      >
        {statusManagementTabs?.map(({ title, path, key }, i) => (
          <TabPane tab={title} key={i} active={location.pathname === path} />
        ))}
      </Tabs>
      <Switch>
        {StatusManagementRoutes?.map((routeProps) => (
          <Route {...routeProps} />
        ))}
        <Redirect to={AppRoutes.APPOINTMENT_STATUS} />
      </Switch>
    </div>
  );
};

export default AuthContainer(StatusManagement);
