import { serializable, alias, object, list, primitive } from "serializr";
import { SIGNAL_R_NOTIFICATION } from "../../enums/signalRNotification.enum";

class JsonData {
  @serializable(alias("patient_profile_id", primitive()))
  patientProfileId?: string = "";

  @serializable(alias("interaction_id", primitive()))
  interactionId?: string = "";

  @serializable(alias("staff_id", primitive()))
  staffId?: string = "";

  @serializable(alias("work_item_id", primitive()))
  workItemId?: string = "";

  @serializable(alias("service_id", primitive()))
  serviceId?: string = "";

  @serializable(alias("term_of_service_id", primitive()))
  termOfServiceId?: string = "";

  @serializable(alias("corporate_object_id", primitive()))
  corporateObjectId?: string = "";
}
export class Notification {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("title", primitive()))
  title?: string = "";

  @serializable(alias("content", primitive()))
  content?: string = "";

  @serializable(alias("action_by_type", primitive()))
  actionByType?: string = "";

  @serializable(alias("action_by_id", primitive()))
  actionById?: string = "";

  @serializable(alias("created_at", primitive()))
  createdAt?: string = "";

  @serializable(alias("due_at", primitive()))
  dueAt?: string = "";

  @serializable(alias("profile_pic_url", primitive()))
  profilePicUrl?: string = "";

  @serializable(alias("type", primitive()))
  type?: SIGNAL_R_NOTIFICATION;

  @serializable(alias("category", primitive()))
  category?: string;

  @serializable(alias("is_viewed", primitive()))
  isViewed?: boolean = true;

  @serializable(alias("json_data", object(JsonData)))
  jsonData?: JsonData;
}
