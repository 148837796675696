import React, { useEffect, useState } from "react";
import "./medicalAllergyList.scss";
import MedicalAlertService from "../../../services/MedicalAlert/medicalAlert.service";
import { MedicalAlert } from "../../../models/MedicalAlert/medicalAlert.model";
import { Button, Col, Modal, Row, Table } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import moment from "moment";
import AppLoader from "../../../shared/components/AppLoader";
import { Form, Formik, FormikValues } from "formik";
import InputField from "../../../shared/components/InputField";

interface MedicalAllergyListProps {
  patientProfileId: number;
  updateMedicalAlertStatus?: (status: boolean) => void;
}

function MedicalAllergyList({ patientProfileId, updateMedicalAlertStatus }: MedicalAllergyListProps) {
  const [activeMedicalAlert, setActiveMedicalAlert] = useState(
    new MedicalAlert()
  );

  const [medicalAlerts, setMedicalAlerts] = useState<MedicalAlert[]>([]);

  const [showMedicalAlertForm, setShowMedicalAlertForm] = useState(false);

  const [loading, setLoading] = useState(false);

  const [formLoading, setFormLoading] = useState(false);

  const handleCancel = () => setShowMedicalAlertForm(false);

  const handleSubmit = (values: FormikValues) => {
    const medicalAlert = Object.assign(new MedicalAlert(), values);
    setFormLoading(true);
    if (medicalAlert.id) {
      MedicalAlertService.updateMedicalAlert(
        patientProfileId,
        medicalAlert,
        (medicalAlert: MedicalAlert) => {
          const alertIndex = medicalAlerts.findIndex(
            (alert) => alert.id === medicalAlert.id
          );
          if (alertIndex >= 0) medicalAlerts[alertIndex] = medicalAlert;
          setMedicalAlerts([...medicalAlerts]);
          setShowMedicalAlertForm(false);
        },
        () => {},
        () => {
          setFormLoading(false);
        }
      );
    } else {
      MedicalAlertService.createMedicalAlert(
        patientProfileId,
        medicalAlert,
        (medicalAlert: MedicalAlert) => {
          medicalAlerts.unshift(medicalAlert);
          setMedicalAlerts([...medicalAlerts]);
          setShowMedicalAlertForm(false);
        },
        () => {},
        () => {
          setFormLoading(false);
        }
      );
    }
  };

  const handleAddAlert = () => {
    setActiveMedicalAlert(new MedicalAlert());
    setShowMedicalAlertForm(true);
  };

  const handleEditMedicalAlert = (medicalAlert: MedicalAlert) => () => {
    setActiveMedicalAlert(medicalAlert);
    setShowMedicalAlertForm(true);
  };

  const handleDeleteMedicalAlert = (medicalAlertId: number) => () => {
    setShowMedicalAlertForm(false);
    Modal.confirm({
      title: "Confirm",
      icon: null,
      content: "Are you sure want to delete the medical alert?",
      onOk: () => {
        MedicalAlertService.deleteMedicalAlert(
          patientProfileId,
          medicalAlertId,
          () => {
            const medicalAlertIndex = medicalAlerts.findIndex(
              (medicalAlert) => medicalAlertId === medicalAlert.id
            );
            if (medicalAlertIndex >= 0)
              medicalAlerts.splice(medicalAlertIndex, 1);
            setMedicalAlerts([...medicalAlerts]);
          },
          () => {},
          () => {}
        );
      },
    });
  };

  useEffect(() => {
    setLoading(true);
    MedicalAlertService.fetchMedicalAlerts(
      patientProfileId,
      (medicalAlerts: MedicalAlert[]) => {
        setMedicalAlerts(medicalAlerts);
      },
      () => {},
      () => {
        setLoading(false);
      }
    );
  }, [patientProfileId]);

  useEffect(() => {
    updateMedicalAlertStatus?.(!!medicalAlerts?.length)
  },[medicalAlerts]);

  const columns = [
    {
      title: "Note",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Added By",
      dataIndex: "id",
      key: "id",
      render: (text: string, record: MedicalAlert, index: number) => {
        return <div>{record?.getFullName()}</div>;
      },
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date: string, record: MedicalAlert, index: number) => {
        return <div>{moment(date).format("MM/DD/YYYY")}</div>;
      },
    },
    {
      title: "",
      dataIndex: "id",
      key: "id",
      render: (text: string, record: any, index: number) => {
        return (
          <div>
            <Button
              type="primary"
              className="mr-4"
              icon={<EditOutlined />}
              onClick={handleEditMedicalAlert(record)}
            />
            <Button
              onClick={handleDeleteMedicalAlert(record.id)}
              className="delete-btn"
              icon={<DeleteOutlined />}
            />
          </div>
        );
      },
    },
  ];
  return (
    <div className="medical-allergy-list">
      {loading ? (
        <AppLoader loading={loading} />
      ) : (
        <Table dataSource={medicalAlerts} columns={columns} />
      )}
      <Button className="secondary-btn" onClick={handleAddAlert}>
        Add Alert
      </Button>
      {showMedicalAlertForm && (
        <div className="medical-allergy-list__form">
          <Formik
            initialValues={activeMedicalAlert}
            enableReinitialize
            onSubmit={handleSubmit}
          >
            {({
              handleSubmit,
              values,
              errors,
              isValid,
              dirty,
              setFieldValue,
            }) => {
              return (
                <Form>
                  <Row gutter={[20, 0]}>
                    <Col span={24}>
                      <InputField
                        title="Description"
                        type="textarea"
                        name="description"
                      />
                    </Col>
                  </Row>
                  <div className="medical-allergy-list__submit-wrapper text-right">
                    <Button onClick={handleCancel} type="default">
                      Cancel
                    </Button>
                    <Button
                      type="primary"
                      className="ml-2"
                      htmlType="submit"
                      loading={formLoading}
                      disabled={!values?.description || formLoading}
                    >
                      Save
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      )}
    </div>
  );
}

export default MedicalAllergyList;
