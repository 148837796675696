import * as Yup from "yup";

export const serviceFormValidation = Yup.object().shape({
  name: Yup.string().required("Service name is required!"),
  // serviceCategoryId: Yup.string().required("ServiceCategory is required!"),
  // serviceSubCategoryId: Yup.string().required(
  //   "Sub ServiceCategory is required!"
  // ),
  status: Yup.string().required("Status is required!"),
  // minPrice: Yup.number()
  //   .integer("Only integers are allowed")
  //   .min(0, "Min Price should be atleast 0")
  //   .required("Min Price is required!"),
  // maxPrice: Yup.number()
  //   .integer("Only integers are allowed")
  //   .min(0, "Max Price should be atleast 0")
  //   .required("Max Price is required!"),
});
