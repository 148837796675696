import { useEffect, useState } from "react";
import { Resource } from "../../../models/resource/resource.model";
import ResourceService from "../../../services/Resource/resource.service";
import { SelectProp } from "../../Types/option";

export const useResource = (resourceType: string, type: "option") => {
	const [resources, setResources] = useState<Resource[] | SelectProp[]>([]);

	useEffect(() => {
		ResourceService.fetchResourceByType(
			resourceType,
			(resources) => {
				if (type === "option") {
					const options = resources.map(({ id, name }) => ({
						value: id,
						key: id,
						label: name,
					}));
					setResources(options);
					return;
				}
				setResources(resources);
			},
			() => {},
			() => {}
		);
	}, []);
	return resources;
};
