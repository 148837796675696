import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Row,
  Col,
  Button,
  Drawer,
  Collapse,
  Popover,
  Modal,
  Tabs,
  Select,
} from "antd";
import { useParams, Link } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import "./interactionDetail.scss";
import AppRoutes from "../../../routes/routeConstants/appRoutes";
import MedicalAllergyList from "../MedicalAllergyList";
import PatientBasicDetails from "../PatientBasicDetails";
import InteractionVisitDetails from "./InteractionVisitDetails";
import InteractionCustomForm from "../InteractionCustomForm";
import { CaretRightOutlined } from "@ant-design/icons";
import StatementNotesForm from "../StatementNotes/StatementNotesForm";
import CommentNotesForm from "../CommentNotes/CommentNotesForm";
import InteractionStepService from "../../../services/InteractionStep/interactionStep.service";
import InteractionViewer from "../InteractionViewer";
import InteractionContainer from "../../../store/container/InteractionContainer";
import { InteractionReducerProps } from "../../../store/reducers/interactionReducer";
import { Interaction } from "../../../models/Interaction/interaction.model";
import InteractionTimeline from "../InteractionTimeline";
import { InteractionStep } from "../../../models/InteractionStep/interactionStep.model";
import { BabylonReducerProps } from "../../../store/reducers/babylonReducer";
import BabylonContainer from "../../../store/container/BabylonContainer";
import InteractionActions from "../InteractionActions";
import { PreVisitService } from "../../../services/PreVisit/previsit.service";
import {
  XRayAttachment,
  XRayImage,
} from "../../../models/XRayImage/xRayImage.model";
import Note from "../../../shared/components/Note";
import { PostVisitService } from "../../../services/PostVisit/postVisit.service";
import TreatmentSummary from "../TreatmentSummary";
import { InteractionService } from "../../../services/Interaction/Interaction.service";
import StatementNotesIcon from "../../../assets/icons/Statement.png";
import CommentNotesIcon from "../../../assets/icons/Comment.png";
import XRayNoteIcon from "../../../assets/icons/XRayNote.png";
import XRayImageIcon from "../../../assets/icons/xRayImage.png";
import { ContractVisit } from "../../../models/ContractVisit/contractVisit.model";
import AppointmentDetail from "./AppointmentDetail";
import XRayNotesForm from "../XRayNotes/XRayNotesForm";
import XrayImagesService from "../../../services/XRayImages/xRayImages.service";
import { InteractionActionEnum } from "../../../enums/interactionActionEnum.enum";
import TreatmentAppointments from "./TreatmentAppointments";
import { TreatmentPlanTemplate } from "../../../models/TreatmentPlanTemplate/treatmentPlanTemplate.model";
import { TreatmentPlanTemplateVisits } from "../../../models/TreatmentPlanTemplateVisits/treatmentPlanTemplateVisits.model";
import ConflictManagement from "../../../shared/components/ConflictManagement";
import { CONFLICT_TYPES } from "../../../enums/conflictType.enum";
import AuthContainer from "../../../store/container/AuthContainer";
import { AuthReducerProps } from "../../../store/reducers/authReducer";
import { ResponsibilityTypes } from "../../../enums/responsebily.enum";
import CreateAppointment from "../CreateAppointment";
import useResponsibilities from "../../../shared/hooks/Responsibilities/useResponsibilities";
import { getMeshType } from "../../../shared/utils/patientCategories";
import { MeshType } from "../../../enums/meshType.enum";
import { useInteractionStatus } from "../../../shared/hooks/useInteractionStatus/useInteractionStatus";
import { StatusType } from "../../../enums/statusType.enum";
import { InteractionSubStatuse } from "../../../models/InteractionSubStatuse/interactionSubStatuse.model";
import { formatDate } from "shared/utils/dateFormatter";
import useToggle from "shared/hooks/useToggle/useToggler";

const { TabPane } = Tabs;
const { Panel } = Collapse;

export type XRayType = "notes" | "images";

interface InteractionDetailProps
  extends InteractionReducerProps,
    BabylonReducerProps,
    AuthReducerProps {}

interface InteractionDetailParams {
  patientProfileId: string;
  interactionId: string;
}

function InteractionDetail({
  user,
  contractInteractions,
  setContractInteractions,
  activeInteractionId,
  setActiveInteractionId,
  resetConflict,
  highlightLayer,

  setExpandedInteractionId,
  setMeshLoading,
}: InteractionDetailProps) {
  const { hasAccess } = useResponsibilities();

  const [key, setKey] = useState(["x-ray-notes"]);

  const [showMedicalAllergies, setShowMedicalAllergies] = useState(false);

  const [showTreatmentSummary, setShowTreatmentSummary] = useState(false);

  const [showCustomForms, setShowCustomForms] = useState(false);

  const params: InteractionDetailParams = useParams();

  const [updateStatementNotes, setUpdateStateNotes] = useState(0);

  const [updateCommentNotes, setUpdateCommentNotes] = useState(0);

  const [xrayType, setXrayType] = useState<XRayType>("notes");

  const [preVisitImages, setPreVisitImages] = useState<XRayImage[]>([]);

  const [postVisitImages, setPostVisitImages] = useState<XRayImage[]>([]);

  const [preVisitContentKey, setPreVisitContentKey] = useState("0");

  const [uploading, setUploading] = useState(false);

  const [preVisitContent, setPreVisitContent] = useState(false);

  const [postVisitContent, setPostVisitContent] = useState(false);

  const [interaction, setInteraction] = useState<Interaction>();

  const [interactions, setInteractions] = useState<ContractVisit[]>();

  const [updateXrayNote, setUpdateXrayNote] = useState(false);

  const [updateXrayImage, setUpdateXrayImage] = useState(false);

  const [reload, setReload] = useState(false);

  const [createAppointment, setCreateAppointment] = useState(false);

  const [statementView, setStatementView] = useState(false);

  const [commentsView, setCommentsView] = useState(false);

  const [interactionKey, setInteractionKey] = useState<string>("2");
  const {
    statuses,
    loading: statusesLoading,
    getOptions,
    updateStatusLoading,
  } = useInteractionStatus(StatusType.INTERACTION);

  const [
    treatmentPlanTemplate,
    setTreatmentPlanTemplate,
  ] = useState<TreatmentPlanTemplate>();

  const preVisitFileRef = useRef<HTMLInputElement>(null);

  const postVisitFileRef = useRef<HTMLInputElement>(null);

  const inputRef = useRef<HTMLInputElement>(null);

  const [openConflict, setOpenConflict] = useState(false);

  const {
    toggle: hasMedicalAlert,
    updateToggle: updateHasMedicalAlert,
  } = useToggle({ initialToggleState: !!interaction?.isMedicalAlertPresent });

  const patientProfileId = Number(params.patientProfileId);

  const HAS_INTERACTION_EDIT_ACCESS = hasAccess(
    ResponsibilityTypes.INTERACTION_EDIT
  );

  const HAS_COMPLETE_INTERACTION_CREATE_ACCESS = hasAccess(
    ResponsibilityTypes.COMPLETE_INTERACTION_CREATE
  );

  const HAS_CUSTOM_FORM_VIEW_ACCESS = hasAccess(
    ResponsibilityTypes.CUSTOM_FORM_VIEW
  );

  const HAS_SET_VIEW_ACCESS = hasAccess(ResponsibilityTypes.SET_VIEW);

  const HAS_PROCEDURE_VIEW_ACCESS = hasAccess(
    ResponsibilityTypes.PROCEDURE_VIEW
  );

  const [meshType, setMeshType] = useState<MeshType>();

  const fetchPreVisit = useCallback(() => {
    const options = {
      practiceId: "1",
      interactionId: params.interactionId,
    };
    PreVisitService.fetchPreVisit(
      options,
      (preVisitImages) => {
        setPreVisitImages([...preVisitImages]);
      },
      () => {},
      () => {}
    );
  }, [params.interactionId]);

  const updateVisitImages = (
    type: "pre-visit" | "post-visit",
    attachment: XRayAttachment
  ) => {
    (type === "pre-visit" ? setPreVisitImages : setPostVisitImages)(
      (visitImages) => {
        const images = [...visitImages];
        const currentVisit = { ...images?.[0] };

        if (currentVisit?.attachments)
          currentVisit.attachments = [...currentVisit.attachments, attachment];

        images[0] = currentVisit;

        return images;
      }
    );
  };

  const deleteVisitImage = (
    type: "pre-visit" | "post-visit",
    attachmentId: number
  ) => {
    (type === "pre-visit" ? setPreVisitImages : setPostVisitImages)(
      (visitImages) => {
        const images = [...visitImages];
        const currentVisit = { ...images?.[0] };

        if (currentVisit?.attachments)
          currentVisit.attachments = currentVisit.attachments.filter(
            (attachment) => attachment.id !== attachmentId
          );

        images[0] = currentVisit;

        return images;
      }
    );
  };

  const fetchPostVisit = useCallback(() => {
    const options = {
      practiceId: "1",
      interactionId: params.interactionId,
    };
    PostVisitService.fetchPostVisit(
      options,
      (postVisitImages) => {
        setPostVisitImages([...postVisitImages]);
      },
      () => {},
      () => {}
    );
  }, [params.interactionId]);

  useEffect(() => {
    fetchPreVisit();
    fetchPostVisit();
  }, [fetchPostVisit, fetchPreVisit]);

  const handleToggleMedicalAllergies = () =>
    setShowMedicalAllergies(!showMedicalAllergies);

  const handleToggleTreatmentSummary = () =>
    setShowTreatmentSummary(!showTreatmentSummary);

  const handleFillCustomForms = () => {
    setShowCustomForms((state) => !state);
  };

  const handleCreateAppointment = () => setCreateAppointment((state) => !state);

  const handleUploadXRayImage = () => {
    inputRef.current?.click();
  };

  const handleInteractionUpdate = (status: StatusType) => {
    if (!interaction || !HAS_INTERACTION_EDIT_ACCESS) return;

    updateStatusLoading(true);
    InteractionService.updateInteraction(
      Object.assign(interaction, { status }),
      (interaction: Interaction) => {
        setInteraction(interaction);
      },
      () => {},
      () => {
        updateStatusLoading(false);
      }
    );
  };

  const handleUploadFile = (type: "pre-visit" | "post-visit") => {
    switch (type) {
      case "pre-visit": {
        preVisitFileRef.current?.click();
        break;
      }
      case "post-visit": {
        postVisitFileRef.current?.click();
      }
    }
  };

  const handleUpload = (event: any) => {
    XrayImagesService.createXrayImage(
      {
        practiceId: "1",
        interactionId: params.interactionId,
      },
      event.target.files[0],
      () => {
        setUpdateXrayImage((state) => !state);
      },
      () => {},
      () => {}
    );
  };

  const AppointmentHeader = (
    <div className="collapse__header">
      <div>Appointment</div>
      <div
        className="icon-image__wrapper ml-3 mr-3"
        onClick={(e) => e.stopPropagation()}
      >
        <Popover
          destroyTooltipOnHide
          visible={commentsView}
          content={
            <CommentNotesForm
              onClose={() => setCommentsView(false)}
              update={() => {
                setUpdateCommentNotes((cur) => cur + 1);
              }}
            />
          }
          placement="leftBottom"
          overlayClassName="pop-over__dark"
          trigger="click"
        >
          <img
            src={CommentNotesIcon}
            className="icon-image"
            onClick={() => setCommentsView((visible) => !visible)}
          />
        </Popover>
      </div>
      <div
        className="icon-image__wrapper mr-3"
        onClick={(e) => e.stopPropagation()}
      >
        <Popover
          destroyTooltipOnHide
          visible={statementView}
          content={
            <StatementNotesForm
              closeModal={() => setStatementView(false)}
              update={() => {
                setUpdateStateNotes((cur) => cur + 1);
              }}
            />
          }
          placement="leftBottom"
          overlayClassName="pop-over__dark"
          trigger="click"
        >
          <img
            src={StatementNotesIcon}
            className="icon-image"
            onClick={() => setStatementView((status) => !status)}
          />
        </Popover>
      </div>
      <div className="mr-3" onClick={(e) => e.stopPropagation()}>
        <Popover
          destroyTooltipOnHide
          content={
            <XRayNotesForm
              update={() => {
                setUpdateXrayNote((state) => !state);
              }}
            />
          }
          placement="leftBottom"
          overlayClassName="pop-over__dark"
          trigger="click"
          visible={updateXrayNote}
        >
          <img
            src={XRayNoteIcon}
            alt=""
            onClick={() => setUpdateXrayNote((visible) => !visible)}
            className="icon-image"
          />
        </Popover>
      </div>
      <div onClick={(e) => e.stopPropagation()}>
        <input
          type="file"
          name=""
          id=""
          onChange={handleUpload}
          hidden
          ref={inputRef}
        />
        <img
          src={XRayImageIcon}
          className="icon-image"
          onClick={handleUploadXRayImage}
        />
      </div>
    </div>
  );

  useEffect(() => {
    if (preVisitContent) {
      setPostVisitContent(false);
    }
  }, [preVisitContent]);

  useEffect(() => {
    if (postVisitContent) {
      setPreVisitContent(false);
    }
  }, [postVisitContent]);

  useEffect(() => {
    if (params.interactionId) {
      InteractionStepService.fetchContractInteractions(
        Number(params.interactionId),
        (contractInteractions: Interaction[]) => {
          setContractInteractions(contractInteractions);
          setActiveInteractionId(Number(params.interactionId));
        },
        () => {},
        () => {}
      );
    }
  }, [params.interactionId, setActiveInteractionId, setContractInteractions]);

  useEffect(() => {
    if (params.interactionId) {
      InteractionService.fetchInteractionDetails(
        params.interactionId,
        (interaction) => {
          setInteraction(interaction);
          setMeshType(getMeshType(interaction?.patientCategory));
        },
        () => {},
        () => {}
      );

      InteractionService.fetchAllInteractions(
        params.interactionId,
        (interactions) => {
          setInteractions(interactions);
        },
        () => {},
        () => {}
      );
    }
  }, [reload]);

  useEffect(() => {
    updateHasMedicalAlert(interaction?.isMedicalAlertPresent);
    if (!interaction?.contractId) return;

    InteractionService.fetchAppointmentInteractionDetails(
      params.interactionId,
      (treatmentPlanTemplate) => {
        setTreatmentPlanTemplate(treatmentPlanTemplate);
      },
      () => {},
      () => {}
    );
  }, [interaction]);

  const visitContent = (type: "pre-visit" | "post-visit") => {
    const VisitHeader = (
      index: number,
      visitNo?: number,
      imageCount?: number
    ) => {
      const options = {
        practiceId: "1",
        interactionId: params.interactionId,
      };
      const onImageUpload = (event: any) => {
        setUploading(true);
        const image = event.target.files[0];
        (type === "pre-visit" ? PreVisitService : PostVisitService).uploadImage(
          options,
          image,
          (attachment) => {
            if (attachment) updateVisitImages(type, attachment);
          },
          () => {},
          () => {
            setUploading(false);
          }
        );
      };

      return (
        <Row justify="space-between" className="visit-header">
          <Col className="no">Visit {visitNo}</Col>
          {index === 0 ? (
            <Col onClick={(event) => event.stopPropagation()}>
              <input
                type="file"
                ref={type === "pre-visit" ? preVisitFileRef : postVisitFileRef}
                hidden
                onChange={(e) => {
                  onImageUpload(e);
                  if (e.currentTarget?.value) e.currentTarget.value = "";
                }}
              />
              <Button
                className="secondary-btn"
                onClick={() => handleUploadFile(type)}
              >
                {uploading ? "Uploading . . ." : "Add Image"}
              </Button>
            </Col>
          ) : (
            <Col>
              <span className="closed">
                {Number(imageCount) > 0 ? imageCount : 0} Images
              </span>
            </Col>
          )}
        </Row>
      );
    };
    return (
      <div>
        <Collapse
          style={{
            width: 300,
          }}
          className="visit-header__container"
          defaultActiveKey={[preVisitContentKey]}
          destroyInactivePanel
          expandIcon={({ isActive }) => (
            <CaretRightOutlined rotate={isActive ? 90 : 0} />
          )}
          ghost
          accordion
          onChange={(key) => {
            setPreVisitContentKey(key as string);
          }}
        >
          {(type === "pre-visit" ? preVisitImages : postVisitImages)?.map(
            ({ visitNo, attachments }, index) => (
              <Panel
                header={VisitHeader(index, visitNo, attachments?.length)}
                key={index.toString()}
              >
                {attachments?.map(({ attachmentName, attachmentUrl, id }) => (
                  <Note
                    imageName={attachmentName}
                    imageUrl={attachmentUrl}
                    key={id}
                    imageId={id}
                    type={type}
                    onImageOpen={() => {
                      // setPreVisitContent(false);
                      // setPostVisitContent(false);
                    }}
                    onDeleteHandler={() => {
                      setPreVisitContent(false);
                      setPostVisitContent(false);
                      Modal.confirm({
                        title: `Are you sure you want to delete ${attachmentName}`,
                        onOk: () => {
                          const options = {
                            practiceId: "1",
                            interactionId: params.interactionId,
                          };
                          (type === "pre-visit"
                            ? PreVisitService
                            : PostVisitService
                          ).deleteVisit(
                            options,
                            id as number,
                            () => {
                              deleteVisitImage(type, id as number);
                            },
                            () => {},
                            () => {}
                          );
                        },
                      });
                    }}
                  />
                ))}
              </Panel>
            )
          )}
        </Collapse>
      </div>
    );
  };

  const Actuals = (
    <Collapse
      expandIconPosition="right"
      accordion
      activeKey={key}
      onChange={(key) => {
        setKey([key as string]);
      }}
      className="interaction-detail__collapse no-scroll"
    >
      <Panel header="Visit Details" key="visitDetails">
        <InteractionVisitDetails interactionId={Number(params.interactionId)} />
      </Panel>
      <Panel header={AppointmentHeader} key="appointments">
        <Collapse
          accordion
          destroyInactivePanel
          ghost
          expandIcon={({ isActive }) => (
            <CaretRightOutlined rotate={isActive ? 90 : 0} />
          )}
          onChange={(key) => {
            if (Array.isArray(key)) return;

            setExpandedInteractionId(Number(key));
          }}
        >
          {interactions?.map(
            ({ id, visitNo, appointmentDate, appointmentTypeName }) => (
              <Panel
                header={
                  <span className="text-capitalize">{`${appointmentTypeName} (${formatDate(
                    appointmentDate
                  )})`}</span>
                }
                key={String(id)}
              >
                <AppointmentDetail
                  currentInteractionId={id}
                  id={id}
                  visitNo={visitNo}
                  updateStatementNotes={updateStatementNotes}
                  updateCommentNotes={updateCommentNotes}
                  updateXrayNote={updateXrayNote}
                  updateXrayImage={updateXrayImage}
                />
              </Panel>
            )
          )}
        </Collapse>
      </Panel>
    </Collapse>
  );

  const TreatmentPlan = () => {
    return (
      <div className="treatment-plan__accordion">
        <Collapse
          expandIconPosition="right"
          accordion
          activeKey={key}
          onChange={(key) => {
            setKey([key as string]);
          }}
          className="interaction-detail__collapse no-scroll"
        >
          <Panel header="Appointments" key="Appointments">
            <Collapse
              ghost
              expandIcon={({ isActive }) => (
                <CaretRightOutlined rotate={isActive ? 90 : 0} />
              )}
            >
              {treatmentPlanTemplate?.treatmentPlanVisits?.map(
                (treatmentPlanTemplateVisit, i: number) => (
                  <Panel
                    header={
                      <p className="treatment-plan__title">
                        {treatmentPlanTemplateVisit?.description}
                        {treatmentPlanTemplateVisit.isCurrentVisit && (
                          <span className="text-primary">Current visit</span>
                        )}
                      </p>
                    }
                    key={`appointment-${i}`}
                  >
                    <TreatmentAppointments
                      treatmentPlanId={treatmentPlanTemplate.id}
                      isCurrent={treatmentPlanTemplateVisit.isCurrentVisit}
                      treatmentPlanTemplateVisit={treatmentPlanTemplateVisit}
                      setOpenConflict={setOpenConflict}
                      createInteractionStep={createInteractionStep}
                      onSuccess={() => {
                        setReload((state) => !state);
                      }}
                      currentUpdate={(id: number) => {
                        const getUpdatedVisit = (
                          visits: TreatmentPlanTemplateVisits[]
                        ) => {
                          return visits.map((visit) => {
                            if (visit.id === id) {
                              return {
                                ...visit,
                                isCurrentVisit: true,
                              };
                            }
                            return {
                              ...visit,
                              isCurrentVisit: false,
                            };
                          });
                        };
                        const treatmentPlanVisits = getUpdatedVisit(
                          treatmentPlanTemplate.treatmentPlanVisits
                        );

                        const treatmentPlanTemplateVisits = getUpdatedVisit(
                          treatmentPlanTemplate.treatmentPlanTemplateVisits
                        );

                        setTreatmentPlanTemplate({
                          ...treatmentPlanTemplate,
                          treatmentPlanVisits,
                          treatmentPlanTemplateVisits,
                        });
                      }}
                    />
                  </Panel>
                )
              )}
            </Collapse>
          </Panel>
        </Collapse>
      </div>
    );
  };

  const createInteractionStep = (
    interactionStep: InteractionStep,
    complete?: () => void
  ) => {
    if (activeInteractionId) {
      delete interactionStep.set;
      delete interactionStep.procedure;
      setMeshLoading(true);
      InteractionStepService.createInteractionStep(
        activeInteractionId,
        interactionStep,
        (interactionStep: InteractionStep) => {
          if (highlightLayer) highlightLayer.removeAllMeshes();
          const interactionIndex = contractInteractions.findIndex(
            (interaction) => interaction.id === activeInteractionId
          );
          if (interactionIndex >= 0) {
            contractInteractions[interactionIndex].interactionSteps.push(
              interactionStep
            );
            setContractInteractions([...contractInteractions]);
          }
        },
        () => {},
        () => {
          complete?.();
          setMeshLoading(false);
        }
      );
    }
  };

  const handleCloseConflict = () => {
    resetConflict();
    setOpenConflict(false);
  };

  const handleSubmitConflict = (data: any[]) => {
    data.forEach((interactionStep: any) => {
      if (interactionStep?.conflict_type === CONFLICT_TYPES.HARD_APPLY) {
        delete interactionStep.toothLink;
        delete interactionStep.conflict_type;
        const step = Object.assign(new InteractionStep(), interactionStep);
        createInteractionStep(step);
      }
    });
    handleCloseConflict();
  };

  const handleNextAppointment = () => {
    handleCreateAppointment();
    handleToggleTreatmentSummary();
  };

  useEffect(() => {
    return () => {
      setActiveInteractionId(undefined);
    };
  }, []);

  const handleTabChange = (key: string) => setInteractionKey(key);

  return (
    <div className="interaction-detail">
      <ConflictManagement
        visible={openConflict}
        onSubmit={handleSubmitConflict}
        onClose={handleCloseConflict}
      />
      <Row className="interaction-detail__header" gutter={[4, 0]}>
        <Col>
          <Link to={AppRoutes.DASHBOARD}>
            <Button className="secondary-btn">
              <ArrowLeftOutlined /> Back
            </Button>
          </Link>
        </Col>
        <Col>
          <Button className="icon-btn ant-btn-icon-only dark-btn p-0">
            <PatientBasicDetails patientProfileId={patientProfileId} />
          </Button>
        </Col>
        {HAS_INTERACTION_EDIT_ACCESS && (
          <>
            <Col className="visit-btn">
              <Popover
                placement="bottomLeft"
                title="Pre Visit Images"
                content={visitContent("pre-visit")}
                visible={preVisitContent}
                destroyTooltipOnHide
                overlayClassName="visit-btn__popover"
              >
                <Button
                  className="icon-btn ant-btn-icon-only dark-btn"
                  onClick={() => {
                    setPreVisitContent((cur) => !cur);
                  }}
                >
                  <i className="icon-pre-visit" />
                  Pre Visit
                </Button>
              </Popover>
            </Col>
            <Col className="visit-btn">
              <Popover
                placement="bottomLeft"
                title="Post Visit Images"
                content={visitContent("post-visit")}
                visible={postVisitContent}
                destroyTooltipOnHide
                overlayClassName="visit-btn__popover"
              >
                <Button
                  className="icon-btn ant-btn-icon-only dark-btn"
                  onClick={() => {
                    setPostVisitContent((cur) => !cur);
                  }}
                >
                  <i className="icon-post-visit" />
                  Post Visit
                </Button>
              </Popover>
            </Col>
          </>
        )}
        <Col className="is-medical-emergency">
          <Button
            className={`ant-btn-icon-only delete-btn`}
            onClick={handleToggleMedicalAllergies}
          >
            <span
              className={`w-100 icon-btn__highlight icon-btn__highlight--danger ${
                hasMedicalAlert ? "btn-blink" : ""
              }`}
            >
              <i className="icon-medical-emergency"></i>
            </span>
          </Button>
        </Col>
        {HAS_CUSTOM_FORM_VIEW_ACCESS && (
          <Col>
            <Button
              className="ant-btn-icon-only secondary-btn"
              onClick={handleFillCustomForms}
            >
              <span className="w-100 icon-btn__highlight icon-btn__highlight--secondary">
                <i className="icon-documents"></i>
              </span>
            </Button>
          </Col>
        )}
        <Col className="icon-btn--grp">
          {/* TODO: Need to be handled once Procedure are used */}
          {/* Add procedure */}
          {/* {HAS_INTERACTION_EDIT_ACCESS && HAS_PROCEDURE_VIEW_ACCESS && <Popover
            destroyTooltipOnHide
            className="interaction-detail__popover"
            overlayClassName="interaction-detail__popover"
            content={
              <div className="interaction-detail__popover-content">
                <InteractionActions
                  type={InteractionActionEnum.PROCEDURE}
                  setOpenConflict={setOpenConflict}
                  createInteractionStep={createInteractionStep}
                />
              </div>
            }
            title={null}
            trigger="click"
            placement="bottomLeft"
          >
            <Button className="icon-btn ant-btn-icon-only dark-btn">
              <i className="icon-visit-details" />
            </Button>
          </Popover>} */}
          {HAS_INTERACTION_EDIT_ACCESS && HAS_SET_VIEW_ACCESS && (
            <Popover
              destroyTooltipOnHide
              className="interaction-detail__popover"
              overlayClassName="interaction-detail__popover"
              content={
                <div className="interaction-detail__popover-content">
                  <InteractionActions
                    type={InteractionActionEnum.ALIGNER}
                    setOpenConflict={setOpenConflict}
                    createInteractionStep={createInteractionStep}
                    meshType={meshType}
                  />
                </div>
              }
              title={null}
              trigger="click"
              placement="bottomLeft"
            >
              <Button className="icon-btn ant-btn-icon-only dark-btn">
                <i className="icon-aligners" />
              </Button>
            </Popover>
          )}
          {/*  <Popover
            destroyTooltipOnHide
            className="interaction-detail__popover"
            overlayClassName="interaction-detail__popover"
            content={
              <div className="interaction-detail__popover-content">
                <InteractionActions
                  type={InteractionActionEnum.BUTTON}
                  setOpenConflict={setOpenConflict}
                  createInteractionStep={createInteractionStep}
                />
              </div>
            }
            title={null}
            trigger="click"
            placement="bottomLeft"
          >
            <Button className="icon-btn ant-btn-icon-only dark-btn">
              <img className="icon-btn" src={ICONS.BUTTON} alt="" />
            </Button>
          </Popover> */}
          {/* <Popover
            destroyTooltipOnHide
            className="interaction-detail__popover"
            overlayClassName="interaction-detail__popover"
            content={
              <div className="interaction-detail__popover-content">
                <InteractionActions
                  type={InteractionActionEnum.ELASTIC}
                  setOpenConflict={setOpenConflict}
                  createInteractionStep={createInteractionStep}
                />
              </div>
            }
            title={null}
            trigger="click"
            placement="bottomLeft"
          >
            <Button className="icon-btn ant-btn-icon-only dark-btn">
              <i className="icon-elastic"></i>
            </Button>
          </Popover> */}
        </Col>
        <Col flex="auto"></Col>
        <Col>
          <Select
            className="interaction-detail__status-toggle"
            dropdownMatchSelectWidth={false}
            loading={statusesLoading}
            onSelect={handleInteractionUpdate}
            options={getOptions(
              InteractionSubStatuse.filterInitialAndCompletionStatus(
                statuses?.[0]?.interactionSubStatuses
              )
            )}
            placeholder="Select Status"
            value={interaction?.status as StatusType}
          />
        </Col>
        <Col>
          {HAS_COMPLETE_INTERACTION_CREATE_ACCESS && (
            <Button
              type="primary"
              className="btn-danger"
              onClick={
                user?.roleName === "Doctor"
                  ? handleCreateAppointment
                  : handleToggleTreatmentSummary
              }
            >
              {user?.roleName === "Doctor"
                ? "Stop Doctor Interaction"
                : "Complete Interaction"}
            </Button>
          )}
        </Col>
      </Row>
      <Row gutter={[20, 0]} className="interaction-detail__viewer-wrapper">
        <Col span={16}>
          <InteractionTimeline meshType={meshType} />
          <InteractionViewer
            meshType={meshType}
            contractInteractions={contractInteractions}
            setOpenConflict={setOpenConflict}
            createInteractionStep={createInteractionStep}
          />
        </Col>
        <Col span={8}>
          <div className="interaction-detail__notes">
            <Tabs
              className="primary-tab"
              activeKey={interactionKey}
              onChange={handleTabChange}
            >
              <TabPane tab="Actuals" key="1">
                {Actuals}
              </TabPane>
              {interaction?.contractId && (
                <TabPane tab="Treatment Execution Plan" key="2">
                  {TreatmentPlan()}
                </TabPane>
              )}
            </Tabs>
          </div>
        </Col>
      </Row>
      <Drawer
        title={<h4>Treatment Summary</h4>}
        placement="right"
        keyboard={false}
        maskClosable={false}
        onClose={handleToggleTreatmentSummary}
        visible={showTreatmentSummary}
        destroyOnClose={true}
        width="75%"
      >
        <TreatmentSummary interactionId={Number(params.interactionId)} />
      </Drawer>
      <Drawer
        title={<h4>Medical Allergies</h4>}
        placement="right"
        keyboard={false}
        maskClosable={false}
        onClose={handleToggleMedicalAllergies}
        visible={showMedicalAllergies}
        destroyOnClose={true}
        width="60%"
      >
        <MedicalAllergyList
          updateMedicalAlertStatus={updateHasMedicalAlert}
          patientProfileId={patientProfileId}
        />
      </Drawer>
      <Drawer
        title={<h4>Custom Form</h4>}
        placement="right"
        keyboard={false}
        maskClosable={false}
        onClose={handleFillCustomForms}
        visible={HAS_CUSTOM_FORM_VIEW_ACCESS && showCustomForms}
        destroyOnClose={true}
        width="60%"
      >
        <InteractionCustomForm />
      </Drawer>
      <Drawer
        visible={createAppointment}
        width="60%"
        title="Complete Interaction"
        destroyOnClose
        onClose={handleCreateAppointment}
      >
        <CreateAppointment
          patientProfileId={patientProfileId}
          interaction={interaction}
          closeHandler={handleCreateAppointment}
          onSuccessHandler={handleNextAppointment}
        />
      </Drawer>
    </div>
  );
}

export default AuthContainer(
  InteractionContainer(BabylonContainer(InteractionDetail))
);
