import React, { FC, useEffect, useState } from "react";
import { Row, Col, Button } from "antd";
import "./registerPaymentDetailsForm.scss";
import { CheckOutlined } from "@ant-design/icons";
import { Corporate } from "../../../../models/Corporate/corporate.model";
import { RegistrationService } from "../../../../services/Registration/registration.service";
import { RegisterReducerProps } from "../../../../store/reducers/registerReducer";
import { useHistory } from "react-router-dom";
import RegisterContainer from "../../../../store/container/RegisterContainer";
import { CorporateService } from "../../../../services/Corporate/corporate.service";
import AppRoutes from "../../../../routes/routeConstants/appRoutes";
import { Tenure } from "../../../../enums/tenureType.enum";
import { Subscription } from "../../../../models/Subscription/subscription.model";
import { SubscriptionFrequencie } from "../../../../models/SubscriptionFrequencie/subscriptionFrequencie.model";

const SubscriptionControl: FC<{
  subscriptionId?: number;
  subscriptionFrequency?: SubscriptionFrequencie[];
  onSelect: (value: { [name: string]: Tenure }) => void;
}> = ({ subscriptionId, subscriptionFrequency, onSelect }) => {
  const [selected, setSelected] = useState<Tenure>(
    subscriptionFrequency?.find(
      (freq) => freq.subscriptionType === Tenure.MONTHLY
    )
      ? Tenure.MONTHLY
      : Tenure.YEARLY
  );

  return (
    <div className="day-control">
      {subscriptionFrequency?.map((freq) =>
        freq.subscriptionType === Tenure.MONTHLY ? (
          <div
            onClick={() => {
              setSelected(Tenure.MONTHLY);
              onSelect({ [subscriptionId ?? ""]: Tenure.MONTHLY });
            }}
            className={
              selected === Tenure.MONTHLY ? "subscription-type__selected" : ""
            }
          >
            ${freq.recurringAmount}
            <span>/monthly</span>
          </div>
        ) : null
      )}
      {subscriptionFrequency?.map((freq) =>
        freq.subscriptionType === Tenure.YEARLY ? (
          <div
            onClick={() => {
              setSelected(Tenure.YEARLY);
              onSelect({ [subscriptionId ?? ""]: Tenure.YEARLY });
            }}
            className={
              selected === Tenure.YEARLY ? "subscription-type__selected" : ""
            }
          >
            ${freq.recurringAmount}
            <span>/yearly</span>
          </div>
        ) : null
      )}
    </div>
  );
};

interface RegisterPaymentDetailsFormProps extends RegisterReducerProps {}

function RegisterPaymentDetailsForm({
  activeCorporate,
  setRegisterActiveStep,
}: RegisterPaymentDetailsFormProps) {
  const [subscriptionPackage, setSubscriptionPackage] = useState<
    number
  >();

  const [formLoading, setFormLoading] = useState(false);

  const [selectedSubscription, setSelectedSubscription] = useState<{
    [name: string]: Tenure;
  }>({});

  const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);

  useEffect(() => {
    CorporateService.mySubscriptions(
      (subscriptions) => {
        setSubscriptions(subscriptions);
      },
      () => {},
      () => {}
    );
  }, []);

  const history = useHistory();

  const getFreqId = () => {
    const subscription = subscriptions.find(
      ({ id }) => id === subscriptionPackage
    );
    return subscription?.subscriptionFrequencies?.length
      ? subscription?.subscriptionFrequencies[0]?.id
      : null;
  };

  const handleSubmit = () => {
    // if (subscriptionPackage) {
    //   const corporate = Object.assign(new Corporate(), {
    //     id: activeCorporate?.id,
    //     subscriptionPackage,
    //     stepNumber: 3,
    //   });
    //   RegistrationService.updateSubscriptionPackage(
    //     corporate,
    //     (corporate: Corporate) => {
    // window.location.href = AppRoutes.LOGIN;
    //     },
    //     () => {},
    //     () => {}
    //   );
    // }

    const subscription = subscriptions.find(
      (subscription) => subscription?.id === subscriptionPackage
    );

    const type = selectedSubscription[subscription?.id ?? ""];

    const frequency = subscription?.subscriptionFrequencies?.filter(
      ({ subscriptionType }) => subscriptionType === type
    );

    const corporate = Object.assign(new Corporate(), {
      ...activeCorporate,
      subscriptionId: subscription?.id,
      subscriptionFrequencyId: frequency?.length
        ? frequency[0]?.id
        : getFreqId(),
      stepNumber: 3,
    });

    CorporateService.updateCorporateProfile(
      corporate,
      (corporate: Corporate) => {
        setRegisterActiveStep(3);
      },
      () => {},
      () => {}
    );
  };

  useEffect(() => {
    if (
      activeCorporate &&
      activeCorporate.id &&
      activeCorporate.subscriptionPackage
    ) {
      setSubscriptionPackage(activeCorporate?.subscriptionId);
      
    }
  }, [activeCorporate]);

  return (
    <div className="register-payment-details-form">
      <Row className={`register-payment-details-form__subscription-list`}>
        {subscriptions.map((subscription, i) => (
          <Col
            key={i}
            span={24}
            onClick={() => {
              setSubscriptionPackage(subscription?.id);
            }}
            className={`register-payment-details-form__subscription-item mb-5 ${
              subscriptionPackage === subscription.id &&
              "register-payment-details-selected"
            }`}
          >
            <Row align="top" className="height-full">
              <Col span={8} className="register-payment-details-form__price">
                <div className="mb-2">{subscription?.name}</div>
                <ul className="payment-detail">
                  <li>
                    <Button
                      className="secondary-btn"
                      icon={<CheckOutlined />}
                    />
                    {subscription.maxNoOfPractices} Practices
                  </li>

                  <li>
                    <Button
                      className="secondary-btn"
                      icon={<CheckOutlined />}
                    />
                    {subscription.maxNoOfStaffs} Staffs
                  </li>

                  <li>
                    <Button
                      className="secondary-btn"
                      icon={<CheckOutlined />}
                    />
                    {subscription.maxNoOfPatients} Patients
                  </li>
                </ul>
              </Col>

              <Col span={10} className="toggle-control">
                <div className="detail">
                  <div className="saving">Save 20% by paying annually</div>
                  <SubscriptionControl
                    subscriptionId={subscription?.id}
                    subscriptionFrequency={
                      subscription?.subscriptionFrequencies
                    }
                    onSelect={(selected) => {
                      setSelectedSubscription((selectedSubscription) => ({
                        ...selectedSubscription,
                        ...selected,
                      }));
                    }}
                  />
                </div>
              </Col>
              {subscription.trialPeriod && (
                <Col span={6} className="trial-period">
                  <div>Trial Period</div>
                </Col>
              )}
              <Col
                span={2}
                className="register-payment-details-form__active-btn__wrapper"
              >
                {subscriptionPackage === subscription?.id && (
                  <Button
                    className="register-payment-details-form__active-btn"
                    type="primary"
                    icon={<CheckOutlined />}
                  />
                )}
              </Col>
            </Row>
          </Col>
        ))}
        <Col
          span={24}
          className="register-payment-details-form__submit-wrapper text-right"
        >
          <Button
            type="primary"
            className="ml-2"
            onClick={handleSubmit}
            loading={formLoading}
            disabled={formLoading || !subscriptionPackage}
          >
            Save
          </Button>
        </Col>
      </Row>
    </div>
  );
}

export default RegisterContainer(RegisterPaymentDetailsForm);
