import React, { FC, useEffect, useState } from "react";
import { Modal } from "antd";
import StatementNotesService from "../../../services/StatementNotes/statementNotes.service";
import { StatementTemplate } from "../../../models/Statement/statement.model";
import { useParams } from "react-router";
import Note from "../../../shared/components/Note";
import "./statementNotes.scss";
import useResponsibilities from "../../../shared/hooks/Responsibilities/useResponsibilities";
import { ResponsibilityTypes } from "../../../enums/responsebily.enum";

const { warning } = Modal;

interface StatementNotesProps {
  reload: number;
  interactionId?: number;
}

const StatementNotes: FC<StatementNotesProps> = (props) => {
  const { reload, interactionId } = props;

  const { hasAccess } = useResponsibilities();

  const [statementTemplates, setStatementTemplates] = useState<
    StatementTemplate[]
  >([]);

  const HAS_INTERACTION_EDIT_ACCESS = hasAccess(
    ResponsibilityTypes.INTERACTION_EDIT
  );

  useEffect(() => {
    StatementNotesService.fetchStatementNotes(
      Number(interactionId),
      (statementTemplates) => {
        setStatementTemplates(statementTemplates);
      },
      () => {},
      () => {}
    );
  }, [interactionId, reload]);

  const handleDelete = (index: number, id?: number, title?: string) => {
    warning({
      content: `Are you sure you want to delete ${title}`,
      maskClosable: true,
      okText: "Delete",
      okType: "danger",
      onOk: () => {
        StatementNotesService.deleteStatementNotes(
          id ?? 0,
          Number(interactionId),
          () => {
            setStatementTemplates((statementTemplates) => [
              ...statementTemplates.filter(
                (_, templateIndex) => index !== templateIndex
              ),
            ]);
          },
          () => {},
          () => {}
        );
      },
    });
  };

  return (
    <div className="statement-notes">
      {statementTemplates.map(
        ({ description, id, statementTemplateCategoryName }, index) => (
          <Note
            hasDeleteAccess={HAS_INTERACTION_EDIT_ACCESS}
            title={statementTemplateCategoryName}
            description={description}
            showDivider={statementTemplates.length - 1 !== index}
            onDeleteHandler={() =>
              handleDelete(index, id, statementTemplateCategoryName)
            }
            currentInteractionId={interactionId}
          />
        )
      )}
    </div>
  );
};

export default StatementNotes;
