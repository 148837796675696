import React, { useEffect, useState } from "react";
import "./nationalityField.scss";
import DropdownField from "../DropdownField";
import {
  IDropdownOptions,
  MetaService,
} from "../../../services/Meta/meta.service";
import { Nationality } from "../../../models/Nationality/nationality.model";

interface NationalityFieldProps {
  setFieldValue: Function;
  value?: number;
  name: string;
}

function NationalityField({
  name,
  value,
  setFieldValue,
}: NationalityFieldProps) {
  const [nationalityOptions, setNationalityOptions] = useState<
    IDropdownOptions[]
  >([]);

  useEffect(() => {
    MetaService.fetchNationalities(
      (nationalities: Nationality[]) => {
        setNationalityOptions(
          nationalities.map((nationality) => ({
            label: nationality.name,
            value: nationality.id,
          }))
        );
      },
      () => {},
      () => {}
    );
  }, []);
  return (
    <div className="nationality-field">
      <DropdownField
        title="Nationality"
        name={name || "nationalityId"}
        options={nationalityOptions}
        value={value}
        setFieldValue={setFieldValue}
        placeHolder="Select nationality"
      />
    </div>
  );
}

export default NationalityField;
