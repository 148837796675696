import { SET_CURRENT_PROCEDURE } from "../definitions/procedureConstants";
import { Procedure } from "../../models/Procedure/procedure.model";

export interface SetCurrentProcedureAction {
  type: typeof SET_CURRENT_PROCEDURE;
  payload: { currentProcedure: Procedure };
}

export const setCurrentProcedure = (
  currentProcedure: Procedure
): SetCurrentProcedureAction => {
  return {
    type: SET_CURRENT_PROCEDURE,
    payload: {
      currentProcedure,
    },
  };
};
