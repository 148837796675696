import React, { useEffect, useState } from "react";
import "./corporateObjectList.scss";
import { Row, Col, Button, Table, Switch, Modal, Drawer, Input } from "antd";
import { CheckOutlined, CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { useHistory, generatePath } from "react-router-dom";
import CorporateObjectService from "../../../../../services/CorporateObject/corporateObject.service";
import { CorporateObject } from "../../../../../models/CorporateObject/corporateObject.model";
import AppLoader from "../../../../../shared/components/AppLoader";
import { Resource } from "../../../../../models/resource/resource.model";
import { StatusEnum } from "../../../../../enums/status.enum";
import CorporateObjectForm from "../CorporateObjectForm";
import AppRoutes from "../../../../../routes/routeConstants/appRoutes";
import AuthContainer from "../../../../../store/container/AuthContainer";
import { AuthReducerProps } from "../../../../../store/reducers/authReducer";
import { ResponsibilityTypes } from "../../../../../enums/responsebily.enum";
import BabylonContainer from "../../../../../store/container/BabylonContainer";
import { BabylonReducerProps } from "../../../../../store/reducers/babylonReducer";
import { PaginationDetails } from "../../../../../models/Pagination/pagination.model";
import { FilterParams } from "../../../../../models/FilterParams/filterParams.model";
import useFilters from "../../../../../shared/hooks/useFilter/useFilters";
import { TablePaginationConfig } from "antd/lib/table";
import useResponsibilities from "../../../../../shared/hooks/Responsibilities/useResponsibilities";

interface CorporateObjectListProps
  extends AuthReducerProps,
    BabylonReducerProps {}

function CorporateObjectList({}: CorporateObjectListProps) {
  const { hasAccess } = useResponsibilities();

  const [corporateObjects, setCorporateObjects] = useState<CorporateObject[]>(
    []
  );

  const { getParams, setParams } = useFilters();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState<PaginationDetails>();

  const [showCorporateObjectForm, setShowCorporateObjectForm] = useState(false);

  const HAS_DENTAL_OBJ_VIEW_ACCESS = hasAccess(
    ResponsibilityTypes.DENTAL_OBJ_VIEW
  );

  const HAS_DENTAL_OBJ_CREATE_ACCESS = hasAccess(
    ResponsibilityTypes.DENTAL_OBJ_CREATE
  );

  const HAS_DENTAL_OBJ_EDIT_ACCESS = hasAccess(
    ResponsibilityTypes.DENTAL_OBJ_EDIT
  );

  const handleToggleCorporateObjectForm = () =>
    setShowCorporateObjectForm(!showCorporateObjectForm);

  const handleFetchObjects = (filter: FilterParams) => {
    setLoading(true);
    CorporateObjectService.fetchCorporateObjects(
      { ...filter, page: filter?.page ?? 1, pageSize: 10 },
      "",
      (corporateObjects: CorporateObject[], pagination: PaginationDetails) => {
        setCorporateObjects(corporateObjects);
        setPagination(pagination);
      },
      () => {},
      () => {
        setLoading(false);
      }
    );
  };

  const handleImportSuccess = () => {
    handleFetchObjects(getParams());
    handleToggleCorporateObjectForm();
  };

  const handleRowClick = (corporateObject: CorporateObject) => {
    return {
      onClick: () => {
        if (!HAS_DENTAL_OBJ_VIEW_ACCESS) return;

        history.push(
          generatePath(AppRoutes.CORPORATE_OBJECT_DETAIL, {
            corporateObjectId: corporateObject.id,
          })
        );
      },
    };
  };

  const columns = [
    {
      title: "Object Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Manufacturer",
      dataIndex: "manufacturerName",
      key: "manufacturerName",
    },
    {
      title: "Color",
      dataIndex: "color",
      key: "color",
      render: (text: string, record: any, index: number) => {
        return (
          <span
            className="corporate-object-list__color"
            style={{ backgroundColor: text }}
          />
        );
      },
    },
    {
      title: "Category",
      dataIndex: "objectCategoryName",
      key: "objectCategoryName",
    },
    {
      title: "Avg procurement cost",
      dataIndex: "avgProcurementCost",
      key: "avgProcurementCost",
      render: (text: string, record: any, index: number) => {
        return <div> {text || "-"}</div>;
      },
    },
    {
      title: "",
      dataIndex: "id",
      key: "id",
      render: (id: number, record: Resource, index: number) => {
        const handleOnCheck = (checked: boolean) => {
          Modal.confirm({
            title: `Are you sure you want to ${
              checked ? "enable" : "disable"
            } this object?`,
            onOk: () => {
              const corporateObject = Object.assign(new CorporateObject(), {
                ...record,
                status: checked ? "enabled" : "disabled",
              });
              CorporateObjectService.updateCorporateObject(
                corporateObject,
                (corporateObject: CorporateObject) => {
                  const objectIndex = corporateObjects.findIndex(
                    (object) => object.id === corporateObject.id
                  );
                  if (objectIndex >= 0) {
                    corporateObjects[objectIndex] = corporateObject;
                    setCorporateObjects([...corporateObjects]);
                  }
                },
                () => {},
                () => {}
              );
            },
          });
        };
        return (
          <span
            onClick={(event) => event.stopPropagation()}
            className={`${!HAS_DENTAL_OBJ_EDIT_ACCESS && "display-none"}`}
          >
            <Switch
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              checked={record.status === "enabled"}
              onChange={handleOnCheck}
            />
          </span>
        );
      },
    },
  ];

  useEffect(() => {
    handleFetchObjects(getParams());
  }, []);

  const handleTableChange = (pagination: TablePaginationConfig) => {
    const prevFilter = getParams();
    const updatedFilters = {
      ...prevFilter,
      page: pagination.current ?? 1,
    };
    setParams(updatedFilters);
    handleFetchObjects(updatedFilters);
  };

  return (
    <div className="corporate-object-list">
      <Row align="middle">
        <Col span={12}>
          <h5 className="text-secondary">All Objects</h5>
        </Col>
        <Col span={12} className="text-right">
          {HAS_DENTAL_OBJ_CREATE_ACCESS && (
            <Button
              type="primary"
              className="ml-2"
              onClick={handleToggleCorporateObjectForm}
            >
              <PlusOutlined />
              Import Objects
            </Button>
          )}
        </Col>
      </Row>
      <Drawer
        title={<h4>Add Objects</h4>}
        placement="right"
        keyboard={false}
        maskClosable={false}
        onClose={handleToggleCorporateObjectForm}
        visible={showCorporateObjectForm}
        destroyOnClose={true}
        width="70%"
      >
        <CorporateObjectForm onSuccess={handleImportSuccess} />
      </Drawer>
      {loading ? (
        <AppLoader loading={loading} />
      ) : (
        <Table
          dataSource={corporateObjects}
          columns={columns}
          onChange={handleTableChange}
          onRow={handleRowClick}
          pagination={{
            total: pagination?.totalCount,
            current: pagination?.currentPage ?? 1,
            showTotal: (total: number, range: [number, number]) => (
              <p>
                Showing <b>{` ${range[0]} - ${range[1]}`}</b> of{" "}
                <b>{`${total.toLocaleString()}`}</b>
              </p>
            ),
          }}
        />
      )}
    </div>
  );
}

export default AuthContainer(BabylonContainer(CorporateObjectList));
