import ApiRoutes from "../../routes/routeConstants/apiRoutes";
import axiosInstance from "../../interceptor/axiosInstance";
import { ObjectNote } from "../../models/ObjectNote/objectNote.model";
import { deserialize, serialize } from "serializr";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";

export default class ObjectNoteService {
  static fetchObjectNotes(
    interactionId: number,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.OBJECT_NOTES.replace(
      ":interactionId",
      interactionId.toString()
    );
    axiosInstance
      .get(API_URL)
      .then((response) => {
        const objectNotes = deserialize(
          ObjectNote,
          response.data["object_notes"]
        );
        onSuccess(objectNotes);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static showObjectNote(
    objectNoteId: number,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.OBJECT_NOTES + "/" + objectNoteId;
    axiosInstance
      .get(API_URL)
      .then((response) => {
        const objectNote = deserialize(
          ObjectNote,
          response.data["object_note"]
        );
        onSuccess(objectNote);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static createObjectNote(
    interactionId: number,
    objectNote: ObjectNote,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.OBJECT_NOTES.replace(
      ":interactionId",
      interactionId.toString()
    );
    const objectNoteJSON = { object_note: serialize(objectNote) };
    axiosInstance
      .post(API_URL, objectNoteJSON)
      .then((response) => {
        Notification({
          message: "Object note created",
          type: NotificationTypes.SUCCESS,
        });
        const objectNote = deserialize(
          ObjectNote,
          response.data["object_note"]
        );
        onSuccess(objectNote);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static updateObjectNote(
    interactionId: number,
    objectNote: ObjectNote,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL =
      ApiRoutes.OBJECT_NOTES.replace(
        ":interactionId",
        interactionId.toString()
      ) +
      "/" +
      objectNote.id;
    const objectNoteJSON = { object_note: serialize(objectNote) };
    axiosInstance
      .put(API_URL, objectNoteJSON)
      .then((response) => {
        Notification({
          message: "Object note updated",
          type: NotificationTypes.SUCCESS,
        });
        const objectNote = deserialize(
          ObjectNote,
          response.data["object_note"]
        );
        onSuccess(objectNote);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static deleteObjectNote(
    interactionId: number,
    objectNoteId: number,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL =
      ApiRoutes.OBJECT_NOTES.replace(
        ":interactionId",
        interactionId.toString()
      ) +
      "/" +
      objectNoteId;
    axiosInstance
      .delete(API_URL)
      .then((response) => {
        Notification({
          message: "Object note removed",
          type: NotificationTypes.SUCCESS,
        });
        onSuccess();
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }
}
