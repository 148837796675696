import {
  serializable,
  alias,
  primitive,
  list,
  object,
  serialize,
} from "serializr";
import AllAppointments from "../../views/Dashboard/AllAppointments";
import { TreatmentPlan } from "../TreatmentPlan";

export class ContractService {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("contract_id", primitive()))
  contractId?: number;

  @serializable(alias("service_id", primitive()))
  serviceId?: number;

  @serializable(alias("service_name", primitive()))
  serviceName?: string;

  @serializable(alias("status", primitive()))
  status?: string;

  @serializable(alias("service_visit_count", primitive()))
  serviceVisitCount?: number;

  @serializable(alias("service_min_cost", primitive()))
  serviceMinCost?: number;

  @serializable(alias("service_max_cost", primitive()))
  serviceMaxCost?: number;

  @serializable(alias("treatment_plan_id", primitive()))
  treatmentPlanId?: number;

  @serializable(alias("treatment_plan_name", primitive()))
  treatmentPlanName?: string = "Treatment plan name";

  @serializable(alias("treatment_plan_template_id", primitive()))
  treatmentPlanTemplateId?: number;

  @serializable(alias("avg_visit_frequency_in_weeks", primitive()))
  avgVisitFrequencyInWeeks?: number;

  @serializable(alias("max_no_to_utilise", primitive()))
  maxNoToUtilise?: number;

  @serializable(alias("duration_in_months", primitive()))
  durationInMonths?: number;

  @serializable(alias("service_period_in_months", primitive()))
  servicePeriodInMonths?: number;
  @serializable(alias("bout_id", primitive()))
  boutId?: number;
  @serializable(alias("service_period", primitive()))
  servicePeriod?: number;
  @serializable(alias("no_of_appointments", primitive()))
  noOfAppointments?: number;
  @serializable(alias("default_treatment_plan_id", primitive()))
  defaultTreatmentPlanId?: number;

  @serializable(alias("treatment_plans", list(object(TreatmentPlan))))
  treatmentPlans?: TreatmentPlan[];

  @serializable(alias("treatment_plan_template_ids", list(primitive())))
  treatmentPlanTemplateIds?: number[] = [];
}
