import React, { useEffect, useState } from "react";
import "./customFormWrapper.scss";
import { Form, Formik, FormikValues } from "formik";
import { Button, Checkbox, Col, Row } from "antd";
import InputField from "../../../shared/components/InputField";
import { CustomForm } from "../../../models/CustomForm/customForm.model";
import DropdownField from "../../../shared/components/DropdownField";
import CustomFieldsWrapper from "../CustomFieldsWrapper";
import CustomFormService from "../../../services/CustomForm/customForm.service";
import { customFormValidation } from "./customFormValidation";
import useResponsibilities from "../../../shared/hooks/Responsibilities/useResponsibilities";
import { ResponsibilityTypes } from "../../../enums/responsebily.enum";
import CustomSectionWrapper from "../CustomSectionWrapper";

interface CustomFormProps {
  customForm: CustomForm;
  onSuccess: (customForm: CustomForm) => void;
  onCancel: () => void;
}

function CustomFormWrapper({
  customForm,
  onSuccess,
  onCancel,
}: CustomFormProps) {
  const { hasAccess } = useResponsibilities();

  const [formLoading, setFormLoading] = useState(false);

  const [formValues, setFormValues] = useState(new CustomForm());

  const [associatedTypeOptions] = useState([
    { label: "Patient", value: "patient" },
    { label: "Interaction", value: "interaction" },
    { label: "Contract", value: "contract" },
  ]);

  const HAS_CUSTOM_FORM_CREATE_ACCESS = hasAccess(
    ResponsibilityTypes.CUSTOM_FORM_CREATE
  );

  const HAS_CUSTOM_FORM_VIEW_ACCESS = hasAccess(
    ResponsibilityTypes.CUSTOM_FORM_VIEW
  );

  const HAS_CUSTOM_FORM_EDIT_ACCESS = hasAccess(
    ResponsibilityTypes.CUSTOM_FORM_EDIT
  );

  const handleSubmit = (values: FormikValues) => {
    const customForm = Object.assign(new CustomForm(), values);
    setFormLoading(true);
    if (values.id) {
      CustomFormService.updateCustomForm(
        customForm,
        (customForm: CustomForm) => {
          onSuccess(customForm);
        },
        () => {},
        () => {
          setFormLoading(false);
        }
      );
    } else {
      CustomFormService.createCustomForm(
        customForm,
        (customForm: CustomForm) => {
          onSuccess(customForm);
        },
        () => {},
        () => {
          setFormLoading(false);
        }
      );
    }
  };

  useEffect(() => {
    if (customForm.id) {
      CustomFormService.showCustomForm(
        customForm.id,
        (customForm: CustomForm) => {
          setFormValues(customForm);
        },
        () => {},
        () => {}
      );
    } else {
      setFormValues(customForm);
    }
  }, [customForm]);

  return HAS_CUSTOM_FORM_VIEW_ACCESS ? (
    <div className="custom-form-wrapper">
      <div className="custom-form-wrapper__body">
        <Formik
          initialValues={formValues}
          onSubmit={handleSubmit}
          enableReinitialize
          validationSchema={customFormValidation}
        >
          {({
            handleSubmit,
            handleChange,
            values,
            errors,
            isValid,
            dirty,
            setFieldValue,
          }) => {
            return (
              <Form>
                <Row
                  gutter={[20, 0]}
                  className="custom-form-wrapper__basic-details"
                >
                  <Col span={12}>
                    <InputField
                      title="Form name"
                      type="text"
                      name="name"
                      placeholder="Enter Form name"
                    />
                  </Col>
                  <Col span={12}>
                    <DropdownField
                      title="Associated Type"
                      name="type"
                      options={associatedTypeOptions}
                      value={values.type}
                      setFieldValue={setFieldValue}
                      placeHolder="Select Type"
                    />
                  </Col>
                  <Col span={24}>
                    <InputField
                      title="Description"
                      type="textarea"
                      name="description"
                      placeholder="Enter Description"
                    />
                  </Col>
                  <Col span={24}>
                    <Checkbox
                      checked={values.isApplicableToResponse}
                      onChange={(e) =>
                        setFieldValue(
                          "isApplicableToResponse",
                          e.target.checked
                        )
                      }
                    >
                      <span className="text-secondary">
                        Applicable to get response from patient?
                      </span>
                    </Checkbox>
                  </Col>
                </Row>
                <CustomFieldsWrapper name="customFields" />
                <CustomSectionWrapper name="sections" />
                <div className="custom-form-wrapper__submit-wrapper text-right">
                  <Button onClick={onCancel} type="default">
                    Cancel
                  </Button>
                  {((!customForm?.id && HAS_CUSTOM_FORM_CREATE_ACCESS) || (customForm?.id && HAS_CUSTOM_FORM_EDIT_ACCESS)) && <Button
                    type="primary"
                    className="ml-2"
                    htmlType="submit"
                    loading={formLoading}
                    disabled={!isValid || !dirty || formLoading}
                  >
                    Save
                  </Button>}
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  ) : <></>;
}

export default CustomFormWrapper;
