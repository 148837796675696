import ApiRoutes from "../../routes/routeConstants/apiRoutes";
import axiosInstance from "../../interceptor/axiosInstance";
import { CustomFormRequest } from "../../models/CustomFormRequest/customFormRequest.model";
import { deserialize, serialize } from "serializr";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import { CustomForm } from "../../models/CustomForm/customForm.model";
import { CustomFormRequests } from "../../models/CustomFormRequests/customFormRequests.model";

export default class CustomFormRequestService {
	static fetchCustomFormRequests(
		onSuccess: Function,
		onError: Function,
		onFinal: () => void
	) {
		axiosInstance
			.get(ApiRoutes.CUSTOM_FORM_REQUEST)
			.then((response) => {
				const customFormRequests = deserialize(
					CustomFormRequest,
					response.data["customFormRequest"]
				);
				onSuccess(customFormRequests);
			})
			.catch((error) => {
				onError();
			})
			.finally(() => {
				onFinal();
			});
	}

	static showCustomFormRequest(
		customFormRequestId: string,
		onSuccess: Function,
		onError: Function,
		onFinal: () => void
	) {
		const API_URL =
			ApiRoutes.CUSTOM_FORM_REQUEST + "/" + customFormRequestId;
		axiosInstance
			.get(API_URL)
			.then((response) => {
				const customFormRequest = deserialize(
					CustomFormRequest,
					response.data["customFormRequest"]
				);
				onSuccess(customFormRequest);
			})
			.catch((error) => {
				onError();
			})
			.finally(() => {
				onFinal();
			});
	}

	static createCustomFormRequest(
		customFormId: number,
		customFormRequest: CustomFormRequest,
		onSuccess: Function,
		onError: Function,
		onFinal: () => void
	) {
		console.log({customFormRequest, customFormId});
		
		const API_URL = ApiRoutes.CUSTOM_FORM_REQUEST.replace(
			":customFormId",
			customFormId.toString()
		);
		const customFormRequestJSON = {
			custom_form_request: serialize(customFormRequest),
		};
		axiosInstance
			.post(API_URL, customFormRequestJSON)
			.then((response) => {
				const customFormRequest = deserialize(
					CustomFormRequest,
					response.data["custom_form_request"]
				);
				onSuccess(customFormRequest);
			})
			.catch((error) => {
				onError();
			})
			.finally(() => {
				onFinal();
			});
	}

	static updateCustomFormRequests(
		interactionId: string,
		customForms: number[],
		onSuccess: () => void,
		onError: () => void,
		onFinal: () => void
	) {
		const URL = ApiRoutes.CUSTOM_FORM_REQUESTS.replace(
			":interactionId",
			interactionId
		);
		const customFormRequestSON = {
			custom_form_ids: customForms,
		};
		return axiosInstance
			.post(URL, customFormRequestSON)
			.then(() => {
				onSuccess();
			})
			.catch((error) => {
				Notification({
					message: error?.message,
					type: NotificationTypes.ERROR,
				});
				onError();
			})
			.finally(() => {
				onFinal();
			});
	}
	static fetchCustomFormRequest(
		params: {
			requestableType: string;
			requestableId: string;
		},
		onSuccess: (customFormRequests: CustomFormRequests[]) => void,
		onError: () => void,
		onFinal: () => void
	) {
		return axiosInstance
			.get(ApiRoutes.GET_CUSTOM_FORM_REQUESTS, {
				params: {
					requestable_type: params?.requestableType,
					requestable_id: params?.requestableId,
				},
			})
			.then((response) => {
				const customFormRequests = deserialize(
					CustomFormRequests,
					response.data["custom_form_requests"] as any[]
				);
				onSuccess(customFormRequests);
				return customFormRequests;
			})
			.catch((error) => {
				onError();
			})
			.finally(onFinal);
	}
}
