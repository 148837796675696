import { Button, Modal } from "antd";
import React, { useState } from "react";
import { Appointment } from "../../../../../../models/Appointment/appointment.model";
import { DaySchedule } from "../../../../../../models/AppointmentDay/appointmentDay.model";
import { AppointmentService } from "../../../../../../services/Appointment/appointment.service";
import { formatTime } from "../../../../../../shared/utils/time";
import useResponsibilities from "../../../../../../shared/hooks/Responsibilities/useResponsibilities";
import { ResponsibilityTypes } from "../../../../../../enums/responsebily.enum";
import { Form, Formik, FormikConfig } from "formik";
import DropdownField from "../../../../../../shared/components/DropdownField";
import { AppointmentStatus } from "../../../../../../enums/appointmentStatus.enum";
import { scheduleMappedAppointment } from "../../../../../../shared/utils/mappedAppointment";

import "./allocatedSlotPopup.scss";
import { useInteractionStatus } from "../../../../../../shared/hooks/useInteractionStatus/useInteractionStatus";
import { StatusType } from "../../../../../../enums/statusType.enum";

interface AllocatedSlotPopupProps {
  date?: string;
  schedule: DaySchedule;
  onAppointmentFetch?: () => void;
  onOutsideSlotAppointmentEdit?: () => void;
  onSlotAppointmentEdit?: () => void;
  onVisibleChange?: () => void;
}

const AllocatedSlotPopup = ({
  date,
  schedule,
  onAppointmentFetch,
  onVisibleChange,
  onOutsideSlotAppointmentEdit,
  onSlotAppointmentEdit,
}: AllocatedSlotPopupProps) => {
  const { hasAccess } = useResponsibilities();

  const {
    appointmentTypeTitle,
    appointmentId,
    endTime,
    id,
    startTime,
    status,
  } = schedule;

  const { statuses, loading: statusesLoading, getOptions } = useInteractionStatus(
    StatusType.VISIT_SCHEDULED
  );

  const [statusToggle, setStatusToggle] = useState(false);

  const HAS_APPOINTMENT_EDIT_ACCESS = hasAccess(
    ResponsibilityTypes.APPOINTMENT_EDIT
  );

  const HAS_APPOINTMENT_DELETE_ACCESS = hasAccess(
    ResponsibilityTypes.APPOINTMENT_DELETE
  );

  const closeStatusToggle = () => setStatusToggle(false);

  const handleAppointmentStatusChange: FormikConfig<DaySchedule>["onSubmit"] = ({
    resourceId,
    ...schedule
  }) => {
    if (!HAS_APPOINTMENT_EDIT_ACCESS) return;

    AppointmentService.updateAppointment(
      {
        ...scheduleMappedAppointment(
          { ...schedule, appointmentDate: date },
          {
            isDateRequired: true,
            resourceId: resourceId ? parseInt(resourceId) : undefined,
          }
        ),
      },
      () => onAppointmentFetch?.(),
      () => {},
      () => onVisibleChange?.()
    );
  };

  const handleAppointmentDelete = () => {
    if (!HAS_APPOINTMENT_DELETE_ACCESS) return;

    AppointmentService.deleteAppointment(
      { ...new Appointment(), id: appointmentId },
      () => {
        onAppointmentFetch?.();
      },
      () => {},
      () => {}
    );
  };

  const handleEditClick = () => {
    if (!HAS_APPOINTMENT_EDIT_ACCESS) return;

    onVisibleChange?.();
    if (id) onSlotAppointmentEdit?.();
    else onOutsideSlotAppointmentEdit?.();
  };

  const handleDeleteClick: React.DOMAttributes<HTMLElement>["onClick"] = (
    evt
  ) => {
    if (!HAS_APPOINTMENT_DELETE_ACCESS) return;

    evt?.stopPropagation();
    onVisibleChange?.();
    Modal.confirm({
      title: `Are you sure, do you want to delete ${appointmentTypeTitle} appointment from slot ${formatTime(
        startTime
      )} - ${formatTime(endTime)}`,
      centered: true,
      onOk: handleAppointmentDelete,
    });
  };

  const handleChangeStatus: React.DOMAttributes<HTMLElement>["onClick"] = (
    evt
  ) => {
    if (!HAS_APPOINTMENT_EDIT_ACCESS) return;

    evt?.stopPropagation();
    setStatusToggle(true);
  };

  return (
    <>
      <div className="allocated-slot-popup__content">
        {HAS_APPOINTMENT_EDIT_ACCESS && (
          <>
            <span onClick={handleChangeStatus}>Change Status</span>
            <span onClick={handleEditClick}>Edit</span>
          </>
        )}
        {HAS_APPOINTMENT_DELETE_ACCESS && (
          <span onClick={handleDeleteClick}>Delete</span>
        )}
      </div>
      <Modal
        centered
        destroyOnClose
        onCancel={closeStatusToggle}
        title="Change Appointment Status"
        visible={statusToggle}
        className="allocated-slot__status-popup"
      >
        <Formik
          initialValues={schedule}
          onSubmit={handleAppointmentStatusChange}
        >
          {({ values, setFieldValue, isSubmitting }) => (
            <Form>
              <DropdownField
                name="status"
                options={getOptions(statuses?.[0]?.interactionSubStatuses)}
                loading={statusesLoading}
                showArrow
                title="Status"
                setFieldValue={setFieldValue}
                value={values?.status || undefined}
              />
              <div className="allocated-slot__status-popup__btn">
                <Button
                  htmlType="reset"
                  onClick={closeStatusToggle}
                  type="default"
                >
                  Cancel
                </Button>
                <Button type="primary" htmlType="submit" loading={isSubmitting}>
                  Save
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default AllocatedSlotPopup;
