import * as Yup from "yup";

export const userCardFormValidation = Yup.object().shape({
  cardNumber: Yup.string().required("Card number is required!").nullable(),
  cardType: Yup.string().required("Card type is required").nullable(),
  cardHolderName: Yup.string()
    .required("Card holder name is required")
    .nullable(),
  expiryDate: Yup.date()
    .required("Expiry Date is required")
    .nullable()
    .min(new Date(), "Please select any future dates"),
});
