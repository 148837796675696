import React from "react";
import logo from "../../../assets/images/logoVerticalWhite.png";

import "./splashScreen.scss";

const SplashScreen = () => {
  return (
    <div className="splash-screen">
      <img src={logo} />
    </div>
  );
};

export default SplashScreen;
