import axiosInstance from "../../interceptor/axiosInstance";
import ApiRoutes from "../../routes/routeConstants/apiRoutes";
import { deserialize, serialize } from "serializr";
import { Corporate } from "../../models/Corporate/corporate.model";
import { convertJSONToFormData } from "../../shared/utils/dataFormatConverter";

export class RegistrationService {
  static createCorporate(
    corporate: Corporate,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const corporateJSON = { corporate: serialize(corporate) };
    return axiosInstance
      .post(ApiRoutes.CORPORATE_SIGN_UP, corporateJSON)
      .then((response) => {
        const corporate = deserialize(Corporate, response.data["corporate"]);
        onSuccess(corporate);
      })
      .catch((error) => {
        onError();
      })
      .finally(onFinal);
  }

  static updateCorporate(
    corporate: Corporate,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.CORPORATE_PROFILE.replace(
      ":corporateId",
      corporate.id
    );
    const corporateJSON = { corporate: serialize(corporate) };
    return axiosInstance
      .put(API_URL, corporateJSON)
      .then((response) => {
        const corporate = deserialize(Corporate, response.data["corporate"]);
        onSuccess(corporate);
      })
      .catch((error) => {
        onError();
      })
      .finally(onFinal);
  }

  static updateBrandingDetails(
    corporate: Corporate,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.CORPORATE_BRANDING_DETAILS.replace(
      ":corporateId",
      corporate.id || ""
    );
    const corporateJSON = serialize(corporate);
    const corporateForm = convertJSONToFormData(corporateJSON);
    return axiosInstance
      .put(API_URL, corporateForm)
      .then((response) => {
        const corporate = deserialize(Corporate, response.data["corporate"]);
        onSuccess(corporate);
      })
      .catch((error) => {
        onError();
      })
      .finally(onFinal);
  }

  static updateSubscriptionPackage(
    corporate: Corporate,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.CORPORATE_SUBSCRIPTION_PACKAGE.replace(
      ":corporateId",
      corporate.id
    );
    const corporateJSON = { corporate: serialize(corporate) };
    return axiosInstance
      .put(API_URL, corporateJSON)
      .then((response) => {
        const corporate = deserialize(Corporate, response.data["corporate"]);
        onSuccess(corporate);
      })
      .catch((error) => {
        onError();
      })
      .finally(onFinal);
  }

  static updateAdditionalDetails(
    corporate: Corporate,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const corporateJSON = { corporate: serialize(Corporate, corporate) };
    return axiosInstance
      .put(ApiRoutes.CORPORATE_ADDITIONAL_DETAILS, corporateJSON)
      .then((response) => {
        const corporate = deserialize(Corporate, response.data["corporate"]);
        localStorage.setItem("corporate", JSON.stringify(corporate));
        onSuccess(corporate);
      })
      .catch((error) => {
        onError();
      })
      .finally(onFinal);
  }
}
