import ApiRoutes from "../../routes/routeConstants/apiRoutes";
import axiosInstance from "../../interceptor/axiosInstance";
import {
  AppointmentType,
  AppointmentTypes,
} from "../../models/AppointmentType/appointmentType.model";
import { deserialize, serialize } from "serializr";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import { Suggestion } from "../../models/Suggestion/suggestion.model";
import { Metrics } from "../../models/Metrics/metrics.model";
import { TabsEnum } from "../../enums/tabs.enum";
import { FilterParams } from "../../models/FilterParams/filterParams.model";
export default class MetricsService {
  static fetchMetrics(
    metricsType: string,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void,
    filters = new FilterParams()
  ) {
    const params = serialize(FilterParams, filters);
    axiosInstance
      .get(ApiRoutes.METRICS.replace(":metricsType", metricsType), {
        params,
      })
      .then((response) => {
        const metrics = deserialize(Metrics, response.data);
        onSuccess(metrics);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }
  static fetchMetricsWithPractice(
    metricsType: string,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .get(ApiRoutes.METRICS_PRAC.replace(":metricsType", metricsType))
      .then((response) => {
        const metrics = deserialize(Metrics, response.data);
        onSuccess(metrics);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }
}
