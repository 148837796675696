import { serializable, alias, primitive, list, object } from "serializr";
import moment from "moment";

export class ContractVisit {
  @serializable
  id?: number;
  @serializable(alias("appointment_date", primitive()))
  appointmentDate?: string;
  @serializable(alias("appointment_type_name", primitive()))
  appointmentTypeName?: string;

  @serializable(alias("visit_no", primitive()))
  visitNo?: number;
}
