import { Drawer, Popover } from "antd";
import moment, { Moment } from "moment";
import React from "react";
import { UserRoles } from "../../../../../enums/userRoles.enum";
import {
  AppointmentDayCalender,
  ProfileAvailability,
} from "../../../../../models/AppointmentDay/appointmentDay.model";
import { AppointmentTemplateSlotCreation } from "../../../../../models/AppointmentTemplate/AppointmentTemplateCreateSlot/appointmentTemplateSlotCreation.model";
import { AppointmentService } from "../../../../../services/Appointment/appointment.service";
import useToggle from "../../../../../shared/hooks/useToggle/useToggler";
import { CalendarSlot } from "../../../../../shared/Types/CalendarSlot.type";
import AssignAppointmentSlotForm from "../../../AppointmentTemplate/AssignAppointmentSlotForm";
import NewAppointmentForm from "../../../AppointmentTemplate/NewAppointmentForm";
import UnAllocatedSlotPopup from "./UnAllocatedSlotPopup";

import "./unAllocatedSlot.scss";
import useResponsibilities from "../../../../../shared/hooks/Responsibilities/useResponsibilities";
import { ResponsibilityTypes } from "../../../../../enums/responsebily.enum";

interface UnAllocatedSlotProps {
  date: Moment;
  resource: string;
  slot: CalendarSlot;
  onAppointmentFetch?: () => void;
  onAppointmentSlotDelete?: (oldSlotId: number, resourceId: number) => void;
  onScheduleSlotUpdate: (
    schedules: AppointmentDayCalender,
    oldSlotId: number
  ) => void;
}

const UnAllocatedSlot = ({
  date,
  resource,
  slot,
  onAppointmentFetch,
  onAppointmentSlotDelete,
  onScheduleSlotUpdate,
}: UnAllocatedSlotProps) => {
  const {
    toggle: isEmptySlotClicked,
    updateToggle: toggleEmptySlotPopoverVisibility,
  } = useToggle({});

  const {
    toggle: createAppointmentVisible,
    updateToggle: toggleCreateAppointmentVisibility,
  } = useToggle({});

  const {
    toggle: changeAppointmentType,
    updateToggle: toggleChangeAppointmentTypeVisibility,
  } = useToggle({});

  const { hasAccess } = useResponsibilities();

  const { start, end, schedule, templateId } = slot;

  const HAS_APPOINTMENT_CREATE_ACCESS = hasAccess(
    ResponsibilityTypes.APPOINTMENT_CREATE
  );

  const HAS_STATUS_APPOINTMENT_TYPE_EDIT_ACCESS = hasAccess(
    ResponsibilityTypes.STATUS_APPOINTMENT_TYPE_EDIT
  );

  const filterProfiles = (role: keyof typeof UserRoles) =>
    slot?.schedule?.profileAvailabilities.filter(
      (profile: ProfileAvailability) =>
        profile.roleName?.toLowerCase() === UserRoles[role].toLowerCase()
    ) || [];

  const doctorProfiles = filterProfiles("DOCTOR");

  const assistantProfiles = filterProfiles("ASSISTANT");

  const isPastDate = moment(date).isBefore(moment(), "date");

  const handleAppointmentScheduleSlotUpdate = (
    values: AppointmentTemplateSlotCreation
  ) => {
    AppointmentService.updateAppointmentDayScheduleSlot(
      templateId?.toString() || "",
      schedule?.id?.toString() || "",
      { appointmentTypeId: values.appointmentTypeId },
      (res) => onScheduleSlotUpdate(res, Number(schedule?.id)),
      () => {},
      () => toggleChangeAppointmentTypeVisibility()
    );
  };

  return (
    <>
      <Popover
        content={
          <UnAllocatedSlotPopup
            slot={slot}
            onChangeAppointmentTypeVisibleChange={
              toggleChangeAppointmentTypeVisibility
            }
            onCreateAppointmentVisibleChange={toggleCreateAppointmentVisibility}
            onSlotDelete={onAppointmentSlotDelete}
            onUnAllocatedSlotVisibleChange={toggleEmptySlotPopoverVisibility}
          />
        }
        destroyTooltipOnHide
        onVisibleChange={toggleEmptySlotPopoverVisibility}
        overlayClassName="un-allocated-slot-popover"
        style={{ width: 500 }}
        trigger={isPastDate ? [] : ["contextMenu"]}
        visible={isEmptySlotClicked}
      >
        <div
          className="un-allocated-slot"
          onContextMenu={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          <div className="un-allocated-slot__container">
            <span className="un-allocated-slot__container__title">
              {slot?.title}
            </span>
            <span className="un-allocated-slot__container__profiles">
              <span className="un-allocated-slot__container__profiles__doctor">
                {doctorProfiles?.length}
              </span>
              <span className="un-allocated-slot__container__profiles__assistant">
                {assistantProfiles?.length}
              </span>
            </span>
          </div>
        </div>
      </Popover>
      <Drawer
        title="Create Appointment"
        width={900}
        visible={HAS_APPOINTMENT_CREATE_ACCESS && createAppointmentVisible}
        closable
        onClose={() => toggleCreateAppointmentVisibility(false)}
        destroyOnClose
        push={false}
      >
        <NewAppointmentForm
          doctorProfiles={doctorProfiles}
          currentDate={moment(slot?.date)}
          appointmentCreateEditVisibility={toggleCreateAppointmentVisibility}
          eventSchedule={slot?.schedule}
          type="createAppointment"
          currentResource={resource}
          // @ts-ignore
          resourceId={slot?.resourceId}
          handleAppointmentUpdate={onAppointmentFetch}
        />
      </Drawer>
      <Drawer
        title="Change Appointment Type"
        visible={
          HAS_STATUS_APPOINTMENT_TYPE_EDIT_ACCESS && changeAppointmentType
        }
        destroyOnClose
        onClose={() => toggleChangeAppointmentTypeVisibility(false)}
        width={"60vw"}
        className="appointmentTemplate"
      >
        <AssignAppointmentSlotForm
          existingFormValues={{
            startTime: start
              ? moment.utc(start.toString()).format("HH:mm:ss a")
              : "",
            endTime: end ? moment.utc(end.toString()).format("HH:mm:ss a") : "",
            appointmentTypeId: schedule?.appointmentTypeId,
          }}
          slotId={schedule?.id ? String(schedule?.id) : ""}
          handleAppointmentSlotVisibility={
            toggleChangeAppointmentTypeVisibility
          }
          currentResourceId={slot?.resourceId?.toString() || ""}
          templateId={Number(templateId)}
          onUpdateAppointmentSlot={handleAppointmentScheduleSlotUpdate}
        />
      </Drawer>
    </>
  );
};

export default UnAllocatedSlot;
