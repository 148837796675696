import { RESET_CONTRACT, SET_CONTRACT } from "../definitions/contractConstants";
import { Action } from "../reducers/contractReducer";
import { Contract } from "../../models/Contract/contract.model";

export const setContract = (contract: Contract): Action => ({
  type: SET_CONTRACT,
  payload: contract,
});

export const resetContract = (): Action => ({
  type: RESET_CONTRACT,
})