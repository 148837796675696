import { Button, Col, Drawer, Row } from "antd";
import { Form, Formik, FormikProps } from "formik";
import moment from "moment";
import React, { FC, useEffect, useRef, useState } from "react";
import { Appointment } from "../../../../models/Appointment/appointment.model";
import {
  DaySchedule,
  ProfileAvailability,
} from "../../../../models/AppointmentDay/appointmentDay.model";
import { Patient } from "../../../../models/Patient/patient.model";
import { User } from "../../../../models/User/user.model";
import { AppointmentService } from "../../../../services/Appointment/appointment.service";
import { IDropdownOptions } from "../../../../services/Meta/meta.service";
import PatientService from "../../../../services/Patient/patient.service";
import UserService from "../../../../services/User/user.service";
import CheckboxField from "../../../../shared/components/Checkbox";
import DatePickerField from "../../../../shared/components/DatePickerField";
import DropdownField from "../../../../shared/components/DropdownField";
import InputField from "../../../../shared/components/InputField";
import RadioInputField from "../../../../shared/components/RadioInputField";
import TimePickerField from "../../../../shared/components/TimePickerField";
import useAppointmentTypes from "../../../../shared/hooks/useAppointmentTypes";
import { useResource } from "../../../../shared/hooks/Resource/useResource";
import "./newAppointmentForm.scss";
import { Moment } from "moment";
import AppointmentForm from "../../AppointmentForm";
import NewAppointmentFormValidation from "./NewAppointmentForm.validation";
import { scheduleMappedAppointment } from "../../../../shared/utils/mappedAppointment";
import { AppointmentType } from "../../../../models/AppointmentType/appointmentType.model";
import AppointmentTypeService from "../../../../services/AppointmentType/appointmentType.service";
import { PaginationDetails } from "../../../../models/Pagination/pagination.model";
import { AppointmentStatus } from "../../../../enums/appointmentStatus.enum";
import { useInteractionStatus } from "../../../../shared/hooks/useInteractionStatus/useInteractionStatus";
import { StatusType } from "../../../../enums/statusType.enum";

interface NewAppointmentFormProps {
  doctorProfiles?: ProfileAvailability[];
  resourceId?: string;
  appointmentCreateEditVisibility: () => void;
  currentResource?: string;
  currentDate?: Moment;
  type?: string;
  eventSchedule?: any;
  handleAppointmentUpdate?: (appointment: Appointment) => void;
  independentDrawer?: boolean;
}

const NewAppointmentForm: FC<NewAppointmentFormProps> = (props) => {
  const {
    appointmentCreateEditVisibility,
    currentResource,
    doctorProfiles,
    type,
    eventSchedule,
    handleAppointmentUpdate,
    currentDate,
    resourceId,
    independentDrawer,
  } = props;

  const { statuses, loading: statusesLoading, getOptions } = useInteractionStatus(StatusType.VISIT_SCHEDULED);

  const {
    options: appointmentTypes,
    fetchAppointmentTypes,
    loading: appointmentTypesLoading,
  } = useAppointmentTypes({});

  const resources = useResource("chair", "option");
  const [patientOptions, setPatientOptions] = useState<IDropdownOptions[]>([]);
  const [formValues, setFormValues] = useState<Appointment>(new Appointment());
  const [appointmentFormVisibility, setAppointmentFormVisibility] = useState(
    false
  );
  const formikRef = useRef<FormikProps<Appointment>>(null);

  const [doctorOptions, setDoctorOptions] = useState<IDropdownOptions[]>(
    () =>
      doctorProfiles?.map((doctor) => ({
        label: doctor.getFullName() ?? "",
        value: doctor.profileId ?? "",
      })) ?? []
  );
  const [loading, setLoading] = useState(false);
  const [visitTypeOption, setvisitTypeOption] = useState<IDropdownOptions[]>([
    { label: "Virtual Consultation", value: "virtual_consultation" },
    { label: "In Person", value: "in_person" },
  ]);

  const handleSubmit = (values: Appointment) => {
    const updatedValue = {
      ...values,
      startTime:
        type != "createAppointment" && !values.appointmentScheduleSlotId
          ? moment(values?.startTime).format("HH:mm:ss")
          : undefined,
      endTime:
        type != "createAppointment" && !values.appointmentScheduleSlotId
          ? moment(values?.endTime).format("HH:mm:ss")
          : undefined,
      date: moment(values?.date).format("YYYY-MM-DD"),
    };
    Object.assign(Appointment, updatedValue);
    const appointmentId = updatedValue.id;
    delete updatedValue?.id;
    if (type == "editAppointment" && appointmentId) {
      setLoading(true);
      AppointmentService.updateAppointment(
        updatedValue,
        (appointment) => {
          handleAppointmentUpdate?.(appointment);
          appointmentCreateEditVisibility();
        },
        () => {},
        () => {
          setLoading(false);
        },
        appointmentId
      );
    } else {
      setLoading(true);

      AppointmentService.createAppointment(
        updatedValue,
        (appointment: Appointment) => {
          handleAppointmentUpdate?.(appointment);
          appointmentCreateEditVisibility();
        },
        () => {},
        () => {
          setLoading(false);
        }
      );
    }
  };

  const handleFetchPatients = () => {
    PatientService.fetchPatients(
      {},
      (patients: Patient[]) => {
        const options = patients?.map((val) => {
          return {
            label: val?.firstName + " " + val?.lastName,
            value: val?.id,
          };
        });
        setPatientOptions(options);
      },
      () => {},
      () => {}
    );
  };
  const handleFetchUsers = () => {
    UserService.fetchUsers(
      { isActive: true },
      (users: User[]) => {
        setDoctorOptions(
          users.map((user: User) => ({
            label: user.getFullName(),
            value: user.profileId,
          }))
        );
      },
      () => {},
      () => {}
    );
  };

  const toggleAppointmentForm = () =>
    setAppointmentFormVisibility((visible) => !visible);

  const closeAppointmentForm = () => {
    setAppointmentFormVisibility(false);
    appointmentCreateEditVisibility();
  };

  const handleInitializeValue = () => {
    if (type == "editAppointment") {
      setFormValues((formValues) => ({
        ...formValues,
        appointmentScheduleSlotId: eventSchedule?.id,
        startTime: (eventSchedule?.startTime
          ? moment(eventSchedule?.startTime)
          : undefined) as any,
        endTime: (eventSchedule?.endTime
          ? moment(eventSchedule?.endTime)
          : undefined) as any,
        id: eventSchedule?.appointmentId,
        chairId: resourceId ?? currentResource,
        date: currentDate as any,
        patientProfileId:
          eventSchedule?.appointmentPatientProfileId ??
          eventSchedule?.patientProfileId,
        doctorProfileId:
          eventSchedule?.appointmentDoctorProfileId ??
          eventSchedule?.doctorProfileId,
        notes: eventSchedule?.notes,
        visitType: eventSchedule?.visitType || "in_person",
        appointmentTypeId: eventSchedule?.appointmentTypeId,
        isMedicalAlertPresent: undefined,
        isDoctorRequired: eventSchedule?.isDoctorRequired,
        status: eventSchedule?.status,
      }));
    } else if (type == "createAppointment") {
      setFormValues({
        ...formValues,
        ...eventSchedule,
        date: currentDate as any,
        appointmentTypeId: eventSchedule?.appointmentTypeId,
        appointmentScheduleSlotId: eventSchedule?.id,
        startTime: moment(eventSchedule?.startTime) as any,
        endTime: moment(eventSchedule?.endTime) as any,
        chairId: resourceId,
        isDoctorRequired: eventSchedule?.isDoctorRequired,
        status: eventSchedule?.status,
        visitType: eventSchedule?.visitType || "in_person"
      });
    } else if (type == "addAppointment") {
      setFormValues({ chairId: currentResource, date: currentDate as any, visitType: "in_person" });
    }
  };

  useEffect(() => {
    handleInitializeValue();
    if (!doctorProfiles) handleFetchUsers();
    handleFetchPatients();
  }, []);

  return (
    <div className="new-appointment-form ">
      {" "}
      <Formik
        innerRef={formikRef}
        initialValues={formValues}
        onSubmit={handleSubmit}
        enableReinitialize
        validationSchema={NewAppointmentFormValidation}
      >
        {({ values, setValues, setFieldValue }) => {
          return (
            <Form>
              <Row gutter={[20, 0]}>
                <Col span={12}>
                  <DatePickerField
                    title="Date"
                    name="date"
                    disabled={type == "createAppointment"}
                    value={values.date}
                    format="MM/DD/YYYY"
                    // setFieldValue={setFieldValue}
                    disabledDate={(d) => {
                      const yesterday = moment().subtract(1, "day");
                      return d.isBefore(yesterday);
                    }}
                    onChange={(value, dateString) => {
                      setFieldValue("date", value);
                    }}
                  />
                </Col>
                <Col span={12}>
                  <DropdownField
                    showSearch
                    title="Patient"
                    name="patientProfileId"
                    options={patientOptions}
                    setFieldValue={setFieldValue}
                    placeHolder="Select Patient"
                    value={values?.patientProfileId}
                  />
                </Col>
                <Col span={12}>
                  <DropdownField
                    title="Appointment Type"
                    name="appointmentTypeId"
                    options={appointmentTypes}
                    onScrollEnd={fetchAppointmentTypes}
                    loading={appointmentTypesLoading}
                    onChange={(
                      appointmentTypeId,
                      { isDoctorRequired }: AppointmentType
                    ) => {
                      setValues({
                        ...values,
                        appointmentTypeId,
                        isDoctorRequired,
                      });
                    }}
                    placeHolder="Select Appointment Type"
                    value={values?.appointmentTypeId}
                    disabled={type == "createAppointment"}
                  />
                </Col>
                <Col span={12} className="mt-3">
                  <RadioInputField
                    name="visitType"
                    title="Visit Type"
                    setFieldValue={setFieldValue}
                    options={visitTypeOption}
                    value={values?.visitType}
                  />
                </Col>
                <Col span={12}>
                  <DropdownField
                    allowClear
                    showSearch
                    title="Doctor"
                    name="doctorProfileId"
                    options={doctorOptions}
                    setFieldValue={setFieldValue}
                    placeHolder="Select Doctor"
                    value={values?.doctorProfileId}
                  />
                </Col>
                <Col span={12}>
                  <DropdownField
                    title="Resources"
                    name="chairId"
                    value={values?.chairId}
                    placeHolder="Select Resource types"
                    setFieldValue={setFieldValue}
                    options={resources}
                    disabled={type !== "editAppointment" && !!values?.chairId}
                  />
                </Col>
                <Col span={12}>
                  <DatePickerField
                    title="Start Time"
                    name={`startTime`}
                    type="time"
                    value={values?.startTime}
                    format="hh:mm a"
                    disabled={type == "createAppointment"}
                    onChange={(value, dateString) => {
                      setFieldValue("startTime", value);
                      setFieldValue("appointmentScheduleSlotId", undefined);
                    }}
                  />
                </Col>
                <Col span={12}>
                  <DatePickerField
                    title="End Time"
                    name={`endTime`}
                    type="time"
                    value={values?.endTime}
                    format="hh:mm a"
                    disabled={type == "createAppointment"}
                    onChange={(value, dateString) => {
                      setFieldValue("endTime", value);
                      setFieldValue("appointmentScheduleSlotId", undefined);
                    }}
                  />
                </Col>
                <Col span={12}>
                  <DropdownField
                    className="appointment-form__status"
                    name="status"
                    options={getOptions(statuses?.[0]?.interactionSubStatuses)}
                    loading={statusesLoading}
                    showArrow
                    title="Status"
                    setFieldValue={setFieldValue}
                    value={values?.status}
                  />
                </Col>
                <Col span={24}>
                  <div className="new-appointment-form__note">
                    <span className="text-capitalize new-appointment-form__note__label">
                      note
                    </span>{" "}
                    {independentDrawer && (
                      <span
                        onClick={toggleAppointmentForm}
                        className="text-capitalize text-primary text-underline new-appointment-form__note__link"
                      >
                        choose start time and end time in slot
                      </span>
                    )}
                  </div>
                </Col>
                <Col span={24}>
                  <InputField
                    type="textarea"
                    name="notes"
                    rows={6}
                    value={values?.notes}
                  />
                </Col>
                <Col span={24}>
                  <CheckboxField
                    name="isEmergencyVisit"
                    content="Emergency visit"
                    setFieldValue={setFieldValue}
                    value={values?.isEmergencyVisit ?? false}
                  />
                </Col>
              </Row>
              <div className="user-form__submit-wrapper text-right">
                <Button
                  onClick={() => {
                    appointmentCreateEditVisibility();
                    handleInitializeValue();
                  }}
                  type="default"
                >
                  Cancel
                </Button>
                <Button
                  type="primary"
                  className="ml-2"
                  htmlType="submit"
                  loading={loading}
                >
                  Save
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
      <Drawer
        title="Edit Appointment"
        width="75vw"
        visible={appointmentFormVisibility}
        closable
        onClose={closeAppointmentForm}
        destroyOnClose
        push={false}
      >
        <AppointmentForm
          edit
          formValue={{
            date: currentDate,
            id: eventSchedule?.appointmentId,
            session: "morning",
            patientProfileId: formikRef?.current?.values?.patientProfileId,
            startTime: moment(formikRef?.current?.values?.startTime).toString(),
            endTime: moment(formikRef?.current?.values?.endTime).toString(),
          }}
          updateHandler={handleAppointmentUpdate}
          closeHandler={closeAppointmentForm}
          handleAppointmentUpdate={handleAppointmentUpdate}
        />
      </Drawer>
    </div>
  );
};

export default NewAppointmentForm;
