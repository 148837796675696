import React, { useEffect, useState } from "react";
import { Row, Col, Tabs, Button, Modal } from "antd";
import {
  Link,
  generatePath,
  useParams,
  useHistory,
  useLocation,
} from "react-router-dom";
import { Contract } from "../../../../models/Contract/contract.model";
import ContractDetailServices from "./ContractDetailServices";
import ContractDetailVisitFrequency from "./ContractDetailVisitFrequency";
import ContractDetailPaymentSchedule from "./ContractDetailPaymentSchedule";
import ContractDetailTerms from "./ContractDetailTerms";
import ContractDetailCardDetails from "./ContractDetailCardDetails";
import "./contractDetail.scss";
import ContractService from "../../../../services/Contract/Contract.service";
import AppLoader from "../../../../shared/components/AppLoader";
import {
  EditOutlined,
  DeleteOutlined,
  ArrowLeftOutlined,
  LeftOutlined,
} from "@ant-design/icons";
import AppRoutes from "../../../../routes/routeConstants/appRoutes";
import { ContractInsurance } from "../../../../models/ContractInsurance/contractInsurance.model";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import deleteIcon from "../../../../assets/images/delete.png";
import CorporateContainer from "../../../../store/container/CorporateContainer";
import { CorporateReducerProps } from "../../../../store/reducers/corporateReducer";
import { StatusEnum } from "../../../../enums/status.enum";
import reOpenIcon from "../../../../assets/images/edit.png";
import useResponsibilities from "../../../../shared/hooks/Responsibilities/useResponsibilities";
import { ResponsibilityTypes } from "../../../../enums/responsebily.enum";
import { ContractStatus } from "enums/contractStatus.enum";
import ContractTermTemplateService from "services/ContractTermTemplate/ContractTermTemplate.service";
import { ContractTermTemplate } from "models/ContractTermsTemplate/contractTermsTemplate.model";
import saveAs from "file-saver";

const stripePromise = loadStripe(
  "pk_test_51JbQV5H6CIb9bhcSTe3om1l5yq7Fnl40NfT4Ypu52ZdkjqUNXn0SjFAIOq156gVDzws5oF2khqlwL8CZ5oai1Qha00ObboxIfc"
);

const { TabPane } = Tabs;
const { confirm } = Modal;

interface ContractDetailProps extends CorporateReducerProps {}

function ContractDetail(props: ContractDetailProps) {
  const history = useHistory();

  const { hasAccess } = useResponsibilities();

  const [contract, setContract] = useState<Contract>(new Contract());
  const { practiceId } = props;
  const [loading, setLoading] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [contractLoading, setContractLoading] = useState(false);
  const [contractStatus, setContractStatus] = useState("");

  const [contractTerms, setContractTerms] = useState<ContractTermTemplate[]>(
    []
  );

  const [contractTermsLoading, setContractTermsLoading] = useState(false);

  const [isContractPdfGenerating, setIsContractPdfGenerating] = useState(false);

  const [activeKey, setActiveKey] = useState("0");

  const { contractId } = useParams();

  const location = useLocation();

  const HAS_PATIENT_CONTRACT_EDIT_ACCESS = hasAccess(
    ResponsibilityTypes.PATIENT_CONTRACT_EDIT
  );

  const isContractInitiated = contract?.status === ContractStatus.INITIATED;

  const isContractDocumentSigned =
    contract?.status === ContractStatus.DOCUMENT_SIGNED;

  const isContractOpen = contract?.status === ContractStatus.ACTIVE;

  const isContractEditable =
    HAS_PATIENT_CONTRACT_EDIT_ACCESS &&
    (isContractInitiated || isContractDocumentSigned || isContractOpen);

  const handleFetchContractTerm = () => {
    setContractTermsLoading(true);
    ContractTermTemplateService.fetchContractTerms(
      contractId,
      (term) => {
        setContractTerms(term);
      },
      () => {},
      () => {
        setContractTermsLoading(false);
      }
    );
  };

  const generateContractTermPdf = () => {
    setIsContractPdfGenerating(true);
    ContractService.downloadContractTerm(
      contract,
      contractTerms,
      (contractTermPdfUrl) => {
        setContract((contract) => ({ ...contract, contractTermPdfUrl }));
        saveAs(contractTermPdfUrl, contract?.name!);
      },
      () => {},
      () => {
        setIsContractPdfGenerating(false);
      }
    );
  };

  const downloadContractTermPdf = () => {
    if (contract?.contractTermPdfUrl)
      saveAs(contract?.contractTermPdfUrl, contract?.name!);
    else generateContractTermPdf();
  };

  const handleShowContract = () => {
    if (contractId) {
      setLoading(true);
      ContractService.showContract(
        contractId,
        (contract: Contract) => {
          setContract(contract);
        },
        () => {},
        () => {
          setLoading(false);
        }
      );
    }
  };

  const handleContractSuccess = () => {
    handleShowContract();
  };
  useEffect(() => {
    handleShowContract();
    handleFetchContractTerm();
  }, [contractId]);

  const closeContract = (
    status: String,
    closeValue: Boolean
    // contractId: string
  ) => {
    setContractLoading(true);

    if (practiceId) {
      ContractService.closeContract(
        practiceId,
        contractId,
        { status: status, is_force_close: closeValue },

        (contract: Contract) => {
          setContractStatus(contract?.status || "");
          handleShowContract();
        },
        () => {
          confirm({
            className: "end-contract-modal",
            content: `Contract services in this contract are not yet completed, Do you wish to force close this contract?`,
            okText: "Yes",
            cancelText: "No",
            onOk: () => {
              closeContract("closed", true);
            },
          });
        },
        () => {
          setContractLoading(false);
        }
      );
    }
  };
  const contractTabDetails = [
    {
      title: (
        <span>
          <i className="icon-services" /> Services
        </span>
      ),
      component: (
        <ContractDetailServices
          handleContractSuccess={handleContractSuccess}
          contract={contract}
          contractId={contractId}
          contractStatus={contract?.status}
        />
      ),
    },
    /*  {
      title: (
        <span>
          <i className="icon-visits" /> Visits frequency
        </span>
      ),
      component: <ContractDetailVisitFrequency contract={contract} />,
    }, */
    {
      title: (
        <span>
          <i className="icon-payments" /> Payment Schedule
        </span>
      ),
      component: <ContractDetailPaymentSchedule contract={contract} />,
    },
    {
      title: (
        <span>
          <i className="icon-documents" /> Terms & Conditions
        </span>
      ),
      component: (
        <ContractDetailTerms
          contractTerm={contractTerms}
          loading={contractTermsLoading}
        />
      ),
    },
  ];

  const handleDownloadInsuranceForm = () => {
    setFormLoading(true);
    ContractService.downloadInitialClaimForm(
      contractId,
      (url: string) => {
        saveAs(url, contract?.name + " Insurance Claim Form");
      },
      () => {},
      () => {
        setFormLoading(false);
      }
    );
  };

  return (
    <div className="contract-detail">
      {loading ? (
        <AppLoader loading={loading} />
      ) : (
        <React.Fragment>
          <Row justify="space-between">
            <Col span={12}>
              <Button
                onClick={() => history.push(AppRoutes.SERVICES + "#contract")}
                className="mr-3"
                icon={<LeftOutlined />}
              />
              Contracts /{" "}
              <span className="text-secondary"> {contract?.name} </span>
            </Col>
            {HAS_PATIENT_CONTRACT_EDIT_ACCESS &&
              contract?.status !== ContractStatus.CANCELLED && (
                <>
                  <Col span={12} className="text-right contract-detail__btn ">
                    {contract?.status === StatusEnum.ACTIVE && (
                      <Button
                        loading={formLoading}
                        className="mr-5 secondary-btn contract-detail__edit-btn"
                        onClick={handleDownloadInsuranceForm}
                      >
                        Generate Insurance Claim Form
                      </Button>
                    )}
                    <Button
                      type="primary"
                      className="btn-danger contract-detail__btn-modifier mr-5"
                      onClick={() => {
                        confirm({
                          cancelText: "No",
                          okText: "Yes",
                          className: "end-contract-modal",
                          content:
                            contract?.status === StatusEnum.CLOSED ||
                            contract?.status === StatusEnum.PAYMENT_PENDING ||
                            contract?.status ===
                              ContractStatus.DOCUMENT_SIGNED ||
                            contract?.status === StatusEnum.INITIATED
                              ? `Do you wish to open this ${contract?.name} contract?`
                              : `Are you sure you want to end this ${contract?.name} contract?`,
                          onOk: () => {
                            closeContract(
                              contract?.status === StatusEnum.ACTIVE
                                ? "closed"
                                : "active",
                              false
                            );
                          },
                        });
                      }}
                    >
                      <img
                        src={
                          contract?.status === StatusEnum.ACTIVE
                            ? deleteIcon
                            : reOpenIcon
                        }
                        className="contract-detail__btn-icon"
                      />
                      {contract?.status === StatusEnum.ACTIVE
                        ? "End Contract"
                        : "Open Contract"}
                    </Button>
                    {isContractEditable && (
                      <Link
                        to={generatePath(AppRoutes.EDIT_CONTRACT, {
                          contractId,
                        })}
                      >
                        <Button
                          type="primary"
                          className="contract-detail__edit-btn"
                        >
                          <EditOutlined />
                          Edit Contract
                        </Button>
                      </Link>
                    )}
                  </Col>
                </>
              )}
          </Row>
          <Row className="mt-5">
            <Tabs
              defaultActiveKey={activeKey}
              size="small"
              style={{ marginBottom: 32 }}
              className="contract-detail__tabs"
              onChange={(activeKey) => setActiveKey(activeKey)}
              tabBarExtraContent={
                activeKey === "2" && (
                  <Button
                    disabled={!contractTerms?.length}
                    type="primary"
                    onClick={downloadContractTermPdf}
                    loading={isContractPdfGenerating}
                  >
                    Download PDF
                  </Button>
                )
              }
            >
              {contractTabDetails.map((contractTabDetail, i) => (
                <TabPane tab={contractTabDetail.title} key={i}>
                  {contractTabDetail.component}
                </TabPane>
              ))}
            </Tabs>
          </Row>
        </React.Fragment>
      )}
    </div>
  );
}

export default CorporateContainer(ContractDetail);
