import ApiRoutes from "../../routes/routeConstants/apiRoutes";
import axiosInstance from "../../interceptor/axiosInstance";
import { deserialize, serialize } from "serializr";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import { ContractTermTemplate } from "../../models/ContractTermsTemplate/contractTermsTemplate.model";
import { PaginationDetails } from "../../models/Pagination/pagination.model";
import { FilterParams } from "../../models/FilterParams/filterParams.model";
import { generatePath } from "react-router-dom";
import AppRoutes from "../../routes/routeConstants/appRoutes";

export default class ContractTermTemplateService {
  static fetchContractTermTemplates(
    onSuccess: (
      contractTermsTemplates: ContractTermTemplate[],
      pagination: PaginationDetails
    ) => void,
    onError: Function,
    onFinal: () => void,
    filter?: FilterParams
  ) {
    const params = serialize(FilterParams, filter ?? new FilterParams());
    axiosInstance
      .get(ApiRoutes.CONTRACT_TERM_TEMPLATES, { params })
      .then((response) => {
        const contractTermTemplates = deserialize(
          ContractTermTemplate,
          response.data["term_templates"] as any[]
        );
        const pagination = deserialize(
          PaginationDetails,
          response.data["meta"]
        );
        onSuccess(contractTermTemplates, pagination);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }
  static fetchContractTerms(
    contractId: number,
    onSuccess: (contractTermsTemplates: ContractTermTemplate[]) => void,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .get(
        ApiRoutes.CONTRACT_TERMS.replace(":contractId", contractId.toString())
      )
      .then((response) => {
        const term = deserialize(
          ContractTermTemplate,
          response.data["contract_terms"] as any[]
        );
        onSuccess(term);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }
  static fetchDefaultTermTemplates(
    onSuccess: (defaultTermsTemplates: ContractTermTemplate[]) => void,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .get(ApiRoutes.DEFAULT_CONTRACT_TERM_TEMPLATES)
      .then((response) => {
        const defaultTermsTemplates = deserialize(
          ContractTermTemplate,
          response.data["term_templates"] as any[]
        );
        onSuccess(defaultTermsTemplates);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static uploadTermTemplateSignature(
    termId: Number,
    image: File,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const attachmentBody = new FormData();
    attachmentBody.append("document", image);

    axiosInstance
      .put(
        ApiRoutes.TERM_TEMPLATE_UPLOAD_DATA.replace(
          ":termId",
          termId.toString()
        ),
        attachmentBody
      )
      .then((response) => {
        const term = deserialize(
          ContractTermTemplate,
          response.data["term_templates"]
        );
        Notification({
          message: "Agreement Signature uploaded",
          type: NotificationTypes.SUCCESS,
        });
        onSuccess(term);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static fetchDefaultTermTemplatesOrder(
    onSuccess: (defaultTermsTemplates: ContractTermTemplate[]) => void,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .get(ApiRoutes.DEFAULT_CONTRACT_TERM_TEMPLATES_ORDERS)
      .then((response) => {
        const defaultTermsTemplates = deserialize(
          ContractTermTemplate,
          response.data["default_template_order"] as any[]
        );
        onSuccess(defaultTermsTemplates);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static showContractTermTemplates(
    contractTermsTemplateId: number,
    onSuccess: (contractTermsTemplate: ContractTermTemplate) => void,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .get(ApiRoutes.CONTRACT_TERM_TEMPLATES + "/" + contractTermsTemplateId)
      .then((response) => {
        const contractTermTemplate = deserialize(
          ContractTermTemplate,
          response.data["term_template"]
        );
        onSuccess(contractTermTemplate);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }
  static createContractTerms(
    contractId: number,
    contractTermTemplate: Object,
    onSuccess: (contractTermTemplate: ContractTermTemplate) => void,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .post(
        ApiRoutes.CONTRACT_TERMS.replace(":contractId", contractId.toString()),
        { contract_terms: contractTermTemplate }
      )
      .then((response) => {
        const contractTermTemplate = deserialize(
          ContractTermTemplate,
          response.data["contract_terms"]
        );
        onSuccess(contractTermTemplate);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static createContractTermTemplate(
    contractTermTemplate: ContractTermTemplate,
    onSuccess: (contractTermTemplate: ContractTermTemplate) => void,
    onError: Function,
    onFinal: () => void
  ) {
    const contractTermTemplateJSON = {
      term_template: serialize(contractTermTemplate),
    };
    axiosInstance
      .post(ApiRoutes.CONTRACT_TERM_TEMPLATES, contractTermTemplateJSON)
      .then((response) => {
        const contractTermTemplate = deserialize(
          ContractTermTemplate,
          response.data["term_template"]
        );
        onSuccess(contractTermTemplate);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static updateContractTermTemplate(
    contractTermTemplate: ContractTermTemplate,
    onSuccess: (contractTermTemplate: ContractTermTemplate) => void,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL =
      ApiRoutes.CONTRACT_TERM_TEMPLATES + "/" + contractTermTemplate?.id;
    const contractTermTemplateJSON = {
      term_template: serialize(contractTermTemplate),
    };
    axiosInstance
      .put(API_URL, contractTermTemplateJSON)
      .then((response) => {
        const contractTermTemplate = deserialize(
          ContractTermTemplate,
          response.data["term_template"]
        );
        onSuccess(contractTermTemplate);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static deleteContractTermTemplate(
    contractTermTemplateId: number,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL =
      ApiRoutes.CONTRACT_TERM_TEMPLATES + "/" + contractTermTemplateId;
    axiosInstance
      .delete(API_URL)
      .then(() => {
        onSuccess();
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static fetchCustomFormVariables = async (
    onSuccess: (variables: string[]) => void,
    onError?: Function,
    onFinal?: () => void
  ) => {
    try {
      const { data } = await axiosInstance.get(ApiRoutes.CUSTOM_FORM_VARIABLES);
      const variables = data["custom_form_variables"];
      onSuccess(variables);
    } catch (ex) {
      onError?.(ex);
    } finally {
      onFinal?.();
    }
  };

  static sendContractLink = async (
    contractTermId: string,
    onSuccess?: () => void,
    onError?: Function,
    onFinal?: () => void
  ) => {
    try {
      const EMAIL_APP_ROUTE = generatePath(AppRoutes.EMAIL_APP_CONTRACT, {
        contractTermId,
      });
      const ENDPOINT = `${ApiRoutes.CONTRACT_TERM_LINK?.replaceAll(
        ":contractTermId",
        contractTermId
      )}?redirect_url=${EMAIL_APP_ROUTE}`;

      await axiosInstance.post(ENDPOINT);

      Notification({
        type: NotificationTypes.SUCCESS,
        message: "Contract Term sent to the patient email",
      });
      onSuccess?.();
    } catch (ex) {
      onError?.();
    } finally {
      onFinal?.();
    }
  };
}
