import React, { useState } from "react";
import { useCallback } from "react";

interface useTogglerProps {
  initialToggleState?: boolean;
}

const useToggle = (props: useTogglerProps) => {
  const { initialToggleState = false } = props;

  const [toggle, setToggle] = useState(initialToggleState);

  const updateToggle = useCallback((loading?: boolean) => {
    if (typeof loading === "boolean") return setToggle(loading);

    setToggle((loader) => !loader);
  }, []);

  return {
    toggle,
    setToggle,
    updateToggle,
  };
};

export default useToggle;
