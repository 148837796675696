import { PlusOutlined } from "@ant-design/icons";
import { Button, Drawer, Table } from "antd";
import { ResponsibilityTypes } from "enums/responsebily.enum";
import { XRayCategory } from "models/XRayCategory/xRayCategory.model";
import React, { FC, useEffect, useState } from "react";
import { NoteTemplateService } from "services/NoteTemplate/noteTemplate.service";
import useResponsibilities from "shared/hooks/Responsibilities/useResponsibilities";
import { getColumns } from "./columns";
import "./xRayCategories.scss";
import XRayCategoryForm from "./XRayCategoryForm";

interface XRayCategoriesProps {}

const XRayCategories: FC<XRayCategoriesProps> = ({}) => {
  const { hasAccess } = useResponsibilities();

  const [loading, setLoading] = useState(false);

  const [categories, setCategories] = useState<XRayCategory[]>([]);

  const [chosenCategory, setChosenCategory] = useState<XRayCategory>();

  const HAS_NOTE_TEMPLATE_CREATE_ACCESS = hasAccess(
    ResponsibilityTypes.NOTE_TEMPLATE_CREATE
  );

  const HAS_NOTE_TEMPLATE_EDIT_ACCESS = hasAccess(
    ResponsibilityTypes.NOTE_TEMPLATE_EDIT
  );

  const handleEditClick = (category: XRayCategory) => {
    if (!HAS_NOTE_TEMPLATE_EDIT_ACCESS) return;

    setChosenCategory(category);
  };

  const closeXRayCategoryForm = () => setChosenCategory(undefined);

  const handleCategorySubmit = (category: XRayCategory) => {
    if (chosenCategory?.id) {
      setCategories((categories) =>
        categories?.map((existingCategory) =>
          category?.id === existingCategory?.id ? category : existingCategory
        )
      );
    } else {
      setCategories((categories) => [...categories, category]);
    }
    closeXRayCategoryForm();
  };

  useEffect(() => {
    setLoading(true);
    NoteTemplateService.fetchXRayCategory(
      (categories) => {
        setCategories(categories);
      },
      () => {},
      () => {
        setLoading(false);
      }
    );
  }, []);

  return (
    <>
      <div className="x-ray-categories">
        <div className="x-ray-categories__header">
          <p className="text-secondary">X Ray Categories</p>
          {HAS_NOTE_TEMPLATE_CREATE_ACCESS && (
            <Button
              type="primary"
              onClick={() => {
                setChosenCategory(new XRayCategory());
              }}
            >
              <PlusOutlined /> Add X Ray Category
            </Button>
          )}
        </div>
        <Table
          dataSource={categories}
          columns={getColumns(handleEditClick)}
          loading={loading}
        />
      </div>
      <Drawer
        onClose={closeXRayCategoryForm}
        title="X Ray Category"
        visible={!!chosenCategory}
        width="75vw"
      >
        <XRayCategoryForm
          category={chosenCategory}
          onCancel={closeXRayCategoryForm}
          onSubmit={handleCategorySubmit}
        />
      </Drawer>
    </>
  );
};

export default XRayCategories;
