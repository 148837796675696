import React, { useEffect, useState } from "react";
import { FormikValues } from "formik";
import { Col } from "antd";
import DropdownField from "../DropdownField";
import {
  IDropdownOptions,
  MetaService,
} from "../../../services/Meta/meta.service";
import { Country } from "../../../models/Country/country.model";
import { Province } from "../../../models/Province/province.model";
import { City } from "../../../models/City/city.model";
import { Zipcode } from "../../../models/Zipcode/zipcode.model";
import InputField from "../InputField";
import { firstLetterCapital } from "../../utils/textHelpers";

interface AddressFieldProps {
  values: FormikValues;
  setFieldValue: Function;
  countryKey: string;
  countryValue?: number;
  cityKey: string;
  cityValue?: number;
  provinceKey: string;
  provinceValue?: number;
  zipcodeKey: string;
  zipcodeValue?: number;
}

function AddressField({
  values,
  setFieldValue,
  countryKey,
  countryValue,
  provinceKey,
  provinceValue,
  cityKey,
  cityValue,
  zipcodeKey,
  zipcodeValue,
}: AddressFieldProps) {
  const [countryOptions, setCountryOptions] = useState<IDropdownOptions[]>([]);

  const [provinceOptions, setProvinceOptions] = useState<IDropdownOptions[]>(
    []
  );

  const [cityOptions, setCityOptions] = useState<IDropdownOptions[]>([]);

  const [zipcodeOptions, setZipcodeOptions] = useState<IDropdownOptions[]>([]);

  const handleFetchCountries = () => {
    MetaService.fetchCountries(
      (countries: Country[]) => {
        setCountryOptions(
          countries.map((country: Country) => ({
            label: firstLetterCapital(country?.name ?? ""),
            value: country.id,
          }))
        );
      },
      () => {},
      () => {}
    );
  };

  const handleFetchProvinces = (countryId: number) => {
    MetaService.fetchProvinces(
      countryId,
      (provinces: Province[]) => {
        const data = provinces.map((province: Province) => ({
          label: firstLetterCapital(province?.name ?? ""),
          value: province.id,
        }));
        data?.sort((a, b) => a?.label?.localeCompare(b?.label));
        setProvinceOptions(data);
      },
      () => {},
      () => {}
    );
  };

  const handleFetchCities = (countryId: number, provinceId: number) => {
    MetaService.fetchCities(
      countryId,
      provinceId,
      (cities: City[]) => {
        const city = cities.map((city: City) => ({
          label: firstLetterCapital(city?.name ?? ""),
          value: city.id,
        }));
        city?.sort((a, b) => a?.label?.localeCompare(b?.label));
        setCityOptions(city);
      },
      () => {},
      () => {}
    );
  };

  const handleFetchZipcodes = (countryId: number, provinceId: number) => {
    MetaService.fetchZipcodes(
      countryId,
      provinceId,
      (zipcodes: Zipcode[]) => {
        setZipcodeOptions(
          zipcodes.map((zipcode: Zipcode) => ({
            label: zipcode.code,
            value: zipcode.id,
          }))
        );
      },
      () => {},
      () => {}
    );
  };

  useEffect(() => {
    handleFetchCountries();
    if (countryValue) {
      handleFetchProvinces(countryValue);
    }
    if (countryValue && provinceValue) {
      handleFetchCities(countryValue, provinceValue);
      handleFetchZipcodes(countryValue, provinceValue);
    }
  }, [countryValue, provinceValue]);

  return (
    <>
      <Col span={12}>
        <DropdownField
          title="Country"
          name={countryKey}
          showSearch
          onChange={(countryId: number) => {
            setFieldValue(countryKey, countryId);
            setFieldValue(provinceKey, undefined);
            setFieldValue(cityKey, undefined);
            setFieldValue(zipcodeKey, undefined);
            handleFetchProvinces(countryId);
          }}
          options={countryOptions}
          value={countryValue}
          placeHolder="Select Country"
        />
      </Col>
      <Col span={12}>
        <DropdownField
          title="Province"
          name={provinceKey}
          showSearch
          options={provinceOptions?.sort()}
          value={provinceValue}
          onChange={(provinceId: number) => {
            setFieldValue(provinceKey, provinceId);
            setFieldValue(cityKey, undefined);
            setFieldValue(zipcodeKey, undefined);
            if (countryValue) {
              handleFetchCities(countryValue, provinceId);
              handleFetchZipcodes(countryValue, provinceId);
            }
          }}
          placeHolder="Select Province"
        />
      </Col>
      <Col span={12}>
        <DropdownField
          title="City"
          name={cityKey}
          showSearch
          options={cityOptions}
          value={cityValue}
          setFieldValue={setFieldValue}
          placeHolder="Select City"
        />
      </Col>
      <Col span={12}>
        <InputField
          type="text"
          title="Zipcode"
          name={zipcodeKey}
          placeholder="Enter Zip Code"
        />
      </Col>
    </>
  );
}

export default AddressField;
