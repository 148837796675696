import React, { useEffect } from "react";
import { DatePicker, TimePicker } from "antd";
import { ErrorMessage, Field } from "formik";
import Error from "../Error";
import "./datePickerField.scss";
import moment, { Moment } from "moment";

interface DatePickerFieldProps {
  name: string;
  title?: string;
  setFieldValue?: Function;
  placeholder?: string;
  defaultValue?: string;
  disabled?: boolean;
  value?: any;
  onChange?: (date: any, dateString: string) => void;
  onBlur?: () => void;
  disabledDate?: (date: Moment) => boolean;
  picker?: any;
  format?: string;
  type?: "time" | "date";
  error?: string;
}

function DatePickerField(props: DatePickerFieldProps) {
  const {
    title,
    name,
    format,
    picker,
    disabledDate,
    setFieldValue,
    defaultValue,
    onChange,
    value,
    onBlur,
    disabled,
    type,
    error = "",
  } = props;

  const handleChange = (date: any, dateString: any) => {
    if (setFieldValue) {
      type === "date"
        ? setFieldValue(name, date?.format("YYYY-MM-DD"))
        : setFieldValue(name, date?.format("YYYY-MM-DD HH:mm:ss"));
    }
  };

  return (
    <Field name={name}>
      {({ field, form: { touched, errors }, meta }: any) => (
        <div className="date-picker-field">
          {title && <div className="date-picker-field__title">{title}</div>}
          {type === "time" ? (
            <TimePicker
              className={`date-picker-${name}`}
              disabled={disabled}
              onChange={onChange || handleChange}
              disabledDate={disabledDate ? disabledDate : undefined}
              value={
                value ? value : defaultValue ? moment(defaultValue) : undefined
              }
              format={format || "hh:mm:ss a"}
              name={name}
              onBlur={onBlur}
              getPopupContainer={(elem) => elem.parentElement || elem}
            />
          ) : (
            <DatePicker
              className={`date-picker-${name}`}
              disabled={disabled}
              picker={picker}
              onChange={onChange || handleChange}
              disabledDate={disabledDate ? disabledDate : undefined}
              value={
                value ? value : defaultValue ? moment(defaultValue) : undefined
              }
              format={format || "MM/DD/YYYY"}
              name={name}
              onBlur={onBlur}
              getPopupContainer={(elem) => elem.parentElement || elem}
            />
          )}
          <ErrorMessage name={name}>
            {(message: string) => {
              console.log(message);
              return <Error message={message} />;
            }}
          </ErrorMessage>
        </div>
      )}
    </Field>
  );
}

export default DatePickerField;
