import { serializable, alias, object, list, primitive } from "serializr";
import { AppointmentType } from "../AppointmentType/appointmentType.model";
import { Resource, Resources } from "../resource/resource.model";
import moment from "moment";

class AvailableAppointments {
  @serializable(alias("id", primitive()))
  id?: number;
  @serializable(alias("title", primitive()))
  title?: string;
  @serializable(alias("color_code", primitive()))
  colorCode?: string;
  @serializable(alias("no_of_appointments_available", primitive()))
  noOfAppointmentsAvailable?: number;
}
export class AppointmentCalender {
  @serializable(alias("date", primitive()))
  date?: string;

  @serializable(alias("is_holiday", primitive()))
  isHoliday?: boolean;

  @serializable(alias("is_weekoff", primitive()))
  isWeekoff?: boolean;

  @serializable(alias("no_of_appointments", primitive()))
  noOfAppointments?: number;

  @serializable(
    alias("available_appointments", list(object(AvailableAppointments)))
  )
  availableAppointments: AvailableAppointments[] = [];
}

export class AppointmentCalenderStat {
  @serializable(alias("total_appointment", primitive()))
  totalAppointment?: number;

  @serializable(alias("completed_appointment", primitive()))
  completedAppointment?: number;

  @serializable(alias("pending_appointment", primitive()))
  pendingAppointment?: number;

  @serializable(
    alias("appointment_calendar", list(object(AppointmentCalender)))
  )
  appointmentsCalendar: AppointmentCalender[] = [];
}
