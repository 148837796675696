
import {SubscriptionFrequencie} from '../SubscriptionFrequencie/subscriptionFrequencie.model';

import {serializable, alias, object, list, primitive} from 'serializr';

export class Subscription { 

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('name', primitive()))
	name?: string;

	@serializable(alias('max_no_of_patients', primitive()))
	maxNoOfPatients?: number;

	@serializable(alias('max_no_of_practices', primitive()))
	maxNoOfPractices?: number;

	@serializable(alias('max_no_of_staffs', primitive()))
	maxNoOfStaffs?: number;

	@serializable(alias('description', primitive()))
	description?: string;

	@serializable(alias('is_enabled', primitive()))
	isEnabled?: string;

	@serializable(alias('trial_period', primitive()))
	trialPeriod?: string;

	@serializable(alias('subscription_frequencies', list(object(SubscriptionFrequencie))))
	subscriptionFrequencies?: SubscriptionFrequencie[] = [];

}