import { Button, Col, Row } from "antd";
import { Form, Formik } from "formik";
import React, { FC, useState } from "react";
import { useEffect } from "react";
import ContractService from "../../../services/Contract/Contract.service";
import { Patient } from "../../../models/Patient/patient.model";
import { Payment } from "../../../models/Payment/payment.model";
import PatientService from "../../../services/Patient/patient.service";
import DatePickerField from "../../../shared/components/DatePickerField";
import DropdownField from "../../../shared/components/DropdownField";
import InputField from "../../../shared/components/InputField";
import SearchBar from "../../../shared/components/Searchbar";
import "./paymentForm.scss";
import { IDropdownOptions } from "../../../services/Meta/meta.service";
import PaymentService from "../../../services/Payment/payment.service";
import { PaymentIntent } from "../../../models/PaymentIntent/paymentIntent.model";
import { PaymentType } from "../../../enums/PaymentType.enum";
import { ContractSubscription } from "../../../models/ContractSubscription/contractSubscription.model";
import CurrencyField from "../../../shared/components/CurrencyField";
import { validation } from "./paymentFormValidation";
import { useContracts } from "shared/hooks/useContracts/useContracts";

interface PaymentFormProps {
  fromValue: PaymentIntent | boolean;
  successHandler: (paymentIntent: PaymentIntent) => void;
  closeHandler: () => void;
}

const PaymentOptions = [
  {
    title: "Cheque",
    value: PaymentType.CHEQUE,
  },
  {
    title: "Cash",
    value: PaymentType.CASH,
  },
  {
    title: "Card",
    value: PaymentType.CARD,
  },
  {
    label: "Self Payment",
    value: PaymentType.SELF,
  },
];

const PaymentForm: FC<PaymentFormProps> = (props) => {
  const { fromValue, successHandler, closeHandler } = props;

  const [patients, setPatients] = useState<Patient[]>([]);

  const [loading, setLoading] = useState(false);

  const {
    fetchPatientContracts,
    patientContractsOptions,
    patientContractsLoading,
  } = useContracts();

  const handleFetchPatients = () => {
    PatientService.fetchPatients(
      {},
      (patients: Patient[]) => {
        setPatients(patients);
      },
      () => {},
      () => {}
    );
  };

  const handleSubmit = (values: PaymentIntent) => {
    setLoading(true);
    const paymentIntent = Object.assign(new PaymentIntent(), values);
    PaymentService.createPaymentInvoice(
      paymentIntent,
      ([paymentIntent]) => {
        successHandler(paymentIntent);
      },
      () => {},
      () => {
        setLoading(false);
      }
    );
  };

  const options = patients?.map((patient) => {
    const content = (
      <Row className="patient-option__wrapper">
        <Col span={8}>
          <Row>
            <Col span={24}>
              {patient?.firstName}
              {patient?.lastName}
            </Col>
            <Col span={24} className="patient-email">
              {patient?.email}
            </Col>
          </Row>
        </Col>
        <Col span={8}>{patient?.mobileNumber}</Col>
        <Col span={8}>{patient?.email}</Col>
      </Row>
    );
    return {
      value: `${patient.firstName} ${patient.lastName}`,
      label: content,
      key: patient.id,
    };
  });

  useEffect(() => {
    handleFetchPatients();
  }, []);

  const formikProps = {
    initialValues:
      typeof fromValue === "boolean"
        ? { ...new PaymentIntent(), paymentType: PaymentType.SELF }
        : fromValue,
    enableReinitialize: true,
    onSubmit: handleSubmit,
    validationSchema: validation,
  };

  const handleSearch = (search: string) => {};

  return (
    <div className="payment-form">
      <Formik {...formikProps}>
        {({ values, setFieldValue }) => (
          <Form>
            <Row gutter={20} className="content-wrapper-shadow">
              <Col span={12}>
                <DropdownField
                  title="Payment type"
                  options={PaymentOptions}
                  value={values.paymentType}
                  name="paymentType"
                  setFieldValue={setFieldValue}
                  disabled
                />
              </Col>
              <Col span={12}>
                <CurrencyField
                  title="Amount"
                  name="amount"
                  onValueChange={(amount) => setFieldValue("amount", amount)}
                />
              </Col>
              <Col span={12}>
                <label htmlFor="">Patient name</label>
                <SearchBar
                  searchHandler={handleSearch}
                  selectHandler={(selectedValue) => {
                    if (!selectedValue) return;

                    setFieldValue("patientProfileId", selectedValue);
                    setFieldValue("contractId", undefined);
                    fetchPatientContracts(+selectedValue, { page: 1 });
                  }}
                  options={options}
                  value={values.patientProfileId?.toString()}
                />
              </Col>
              <Col span={12}>
                <DropdownField
                  disabled={!values?.patientProfileId}
                  title="Contract"
                  options={patientContractsOptions}
                  loading={patientContractsLoading}
                  name="contractId"
                  value={values?.contractId}
                  setFieldValue={setFieldValue}
                  onScrollEnd={() =>
                    values?.patientProfileId &&
                    fetchPatientContracts(values?.patientProfileId)
                  }
                />
              </Col>
              <Col span={12}>
                <DatePickerField
                  title="Payment date"
                  name="invoiceDate"
                  defaultValue={values?.paymentDate}
                  onChange={(date: moment.Moment) =>
                    setFieldValue("invoiceDate", date?.format("MM-DD-YYYY"))
                  }
                />
              </Col>
            </Row>
            <Row justify="end" className="mt-5">
              <Col>
                <Button type="text" onClick={closeHandler}>
                  Cancel
                </Button>
              </Col>
              <Col>
                <Button htmlType="submit" type="primary" loading={loading}>
                  Submit
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default PaymentForm;
