import { serializable, alias, object, list, primitive } from "serializr";

export class XRayTemplate {
    @serializable(alias("id", primitive()))
    id?: number;

    @serializable(alias("x_ray_template_category_id", primitive()))
    xRayTemplateCategoryId?: number;

    @serializable(alias("x_ray_template_category_name", primitive()))
    xRayTemplateCategoryName?: number;

    @serializable(alias("description", primitive()))
    description?: string;

    @serializable(alias("status", primitive()))
    status?: string = "active";
}

export class XRayTemplates {
    @serializable(alias("x_ray_templates", list(object(XRayTemplate))))
    XRayTemplate: XRayTemplate[] = [];
}

