import { serializable, alias, primitive } from "serializr";

export class ContractTermTemplate {
  @serializable(alias("id", primitive()))
  id?: number;
  @serializable(alias("order_no", primitive()))
  orderNo?: number;
  @serializable(alias("index", primitive()))
  index?: number;
  @serializable(alias("is_default", primitive()))
  isDefault?: boolean;
  @serializable(alias("document_url", primitive()))
  documentUrl?: string;

  @serializable(alias("name", primitive()))
  name?: string;
  @serializable(alias("is_default", primitive()))
  defaultTemplateId?: number;

  @serializable(alias("no_of_variables", primitive()))
  noOfVariables?: number;
  @serializable(alias("description", primitive()))
  description?: string = "";

  @serializable(alias("created_at", primitive()))
  createdAt?: string;

  @serializable(alias("updated_at", primitive()))
  updatedAt?: string;

  @serializable(alias("profile_id", primitive()))
  profileId?: number;

  @serializable(alias("profile_name", primitive()))
  profileName?: string;

  @serializable(alias("profile_pic_url", primitive()))
  profilePicUrl?: string;

  @serializable(alias("created_by_first_name",primitive()))
  createdByFirstName?: string;

  @serializable(alias("created_by_last_name",primitive()))
  createdByLastName?: string;

  getCreatedByFullName = () => `${this.createdByFirstName || ""} ${this.createdByLastName || ""}`.trim();
}


export interface ContractTermSendLinkHandler {
  onSendLink: () => Promise<number | void>;
}