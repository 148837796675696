import React, { FC, Fragment } from "react";
import { useState } from "react";
import { UpOutlined, DownOutlined } from "@ant-design/icons";
import { responsibilities } from "./data";
import "./userResponsibility.scss";
import { Button, Checkbox, Col, Row, Tooltip } from "antd";
import { Role } from "../../../../models/Role/role.model";
import useResponsibilities from "../../../../shared/hooks/Responsibilities/useResponsibilities";
import { ResponsibilityTypes } from "../../../../enums/responsebily.enum";
interface UserResponsibilityProps {
  role: Role;
  updateRoleHandler: (role: Role) => void;
}

const UserResponsibility: FC<UserResponsibilityProps> = (props) => {
  const { role, updateRoleHandler } = props;

  const { hasAccess } = useResponsibilities();

  const [userRole, setUserRole] = useState<Role>(role);

  const [selectedCard, setSelectedCard] = useState(0);

  const [hasUpdate, setHasUpdate] = useState(false);

  const [content, setContent] = useState<
    { id: number; title: string; approval: string[] }[]
  >(responsibilities[0].responsibility);

  const [className, setClassName] = useState<"content-open" | "content-close">(
    "content-close"
  );

  const isExpanded = className === "content-open";

  const HAS_ROLE_EDIT_ACCESS = hasAccess(ResponsibilityTypes.ROLE_EDIT);

  const toggleCollapse = () => 
    setClassName((className) => className === "content-open"? "content-close" : "content-open");

  const handleUpdateResponsibility = (checked: boolean, permission: string) => {
    let updatedRole: any = {};
    if (checked) {
      updatedRole = {
        ...userRole,
        permissions: [...userRole.permissions, permission],
      };
    } else {
      const updatedPermissions = [...userRole.permissions];
      const index = userRole.permissions.findIndex((p) => p === permission);
      updatedPermissions.splice(index, 1);
      updatedRole = {
        ...userRole,
        permissions: updatedPermissions,
      };
    }
    setUserRole(updatedRole);
    setHasUpdate(true);
  };

  const handleUpdateUserResponsibility = () => {
    updateRoleHandler(userRole as Role);
    setHasUpdate(false);
  };

  const isPresent = (
    approvals: string[],
    hasPermission: "create" | "view" | "edit" | "delete"
  ) => {
    for (let approval of approvals) {
      if (approval.includes(hasPermission)) {
        return (
          <Checkbox
            disabled={!HAS_ROLE_EDIT_ACCESS}
            defaultChecked={userRole?.permissions?.includes(approval)}
            onChange={(e) =>
              handleUpdateResponsibility(e.target.checked, approval)
            }
          />
        );
      }
    }
    return (
      <Tooltip title="Not Applicable">
        <Checkbox disabled />
      </Tooltip>
    );
  };

  return (
    <div className="user-responsibility">
      <div className="cursor-pointer head">
        <span>
          <span className="title">{userRole?.name}</span>
          {isExpanded && <p className="mt-2 description">{userRole?.description}</p>}
        </span>
        <span onClick={toggleCollapse}>
          {isExpanded ? <UpOutlined /> : <DownOutlined />}
        </span>
      </div>
      {isExpanded && <><div className="content">
        <Row gutter={[15, 0]}>
          <Col span={6}>
            {responsibilities.map(({ title, responsibility, id }, index) => {
              const handleSelect = () => {
                setContent(responsibility);
                setSelectedCard(index);
              };
              return (
                <div
                  className={`card ${
                    selectedCard === index && "card-selected"
                  }`}
                  key={`${id}-${title}`}
                  onClick={handleSelect}
                >
                  {title}
                </div>
              );
            })}
          </Col>
          <Col span={18}>
            <Row justify="space-between" className="mb-5">
              <Col span={8}></Col>
              <Col span={4}>Create</Col>
              <Col span={4}>View</Col>
              <Col span={4}>Edit</Col>
              <Col span={4}>Delete</Col>
            </Row>
            {content.map(({ id, title, approval }) => {
              return (
                <Row
                  justify="space-between"
                  className="mb-5"
                  key={`${id}-${title}`}
                >
                  <Col span={8}>{title}</Col>
                  <Col span={4}>{isPresent(approval, "create")}</Col>
                  <Col span={4}>{isPresent(approval, "view")}</Col>
                  <Col span={4}>{isPresent(approval, "edit")}</Col>
                  <Col span={4}>{isPresent(approval, "delete")}</Col>
                </Row>
              );
            })}
          </Col>
        </Row>
      </div>
      {hasUpdate && HAS_ROLE_EDIT_ACCESS && (
        <div className="btn-wrapper mt-5">
          <Button
            className="secondary-btn text-right"
            onClick={handleUpdateUserResponsibility}
          >
            Update Permission
          </Button>
        </div>
      )}</>}
    </div>
  );
};

export default UserResponsibility;
