import { alias, primitive, serializable } from "serializr";

export class AppointmentTypeProcedureCode {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("procedure_code", primitive()))
  procedureCode?: string;

  @serializable(alias("appointment_type_category_id", primitive()))
  categoryId?: string;

  @serializable(alias("procedure_code", primitive()))
  label?: string;

  @serializable(alias("id", primitive()))
  value?: string;
}
