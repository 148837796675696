import { serializable, alias, primitive, custom } from "serializr";

export class Corporate {
  @serializable(alias("id", primitive()))
  id: string = "";

  @serializable(alias("corporate_id", primitive()))
  corporateId: string = "";

  @serializable(alias("name", primitive()))
  name?: string;

  @serializable(alias("contact_person", primitive()))
  contactPerson?: string;

  @serializable(alias("register_number", primitive()))
  registerNumber?: string;

  @serializable(alias("tax", primitive()))
  tax?: string;

  @serializable(alias("no_of_practice", primitive()))
  noOfPractices?: string;

  @serializable(alias("no_of_staff", primitive()))
  noOfStaffs?: string;

  @serializable(alias("primary_email", primitive()))
  primaryEmail?: string;

  @serializable(alias("secondary_email", primitive()))
  secondaryEmail?: string;

  @serializable(alias("isd_code", primitive()))
  isdCode?: string;

  @serializable(alias("mobile_number", primitive()))
  mobileNumber?: number;

  @serializable(alias("address_line_1", primitive()))
  addressLine1?: string;

  @serializable(alias("address_line_2", primitive()))
  addressLine2?: string;

  @serializable(alias("country_id", primitive()))
  countryId?: number;

  @serializable(alias("country_name", primitive()))
  countryName?: string;

  @serializable(alias("province_id", primitive()))
  provinceId?: number;

  @serializable(alias("province_name", primitive()))
  provinceName?: string;

  @serializable(alias("city_id", primitive()))
  cityId?: number;

  @serializable(alias("city_name", primitive()))
  cityName?: string;

  @serializable(alias("zip_code", primitive()))
  zipcode?: string;

  @serializable(
    alias(
      "logo",
      custom(
        (file) => file,
        (json) => {}
      )
    )
  )
  logo?: File;

  @serializable(
    alias(
      "license",
      custom(
        (file) => file,
        (json) => {}
      )
    )
  )
  license?: File;

  @serializable(alias("website_url", primitive()))
  websiteUrl?: string;

  @serializable(alias("facebook_url", primitive()))
  facebookUrl?: string;

  @serializable(alias("twitter_url", primitive()))
  twitterUrl?: string;

  @serializable(alias("linkedin_url", primitive()))
  linkedinUrl?: string;

  @serializable(alias("step_no", primitive()))
  stepNumber?: number;

  @serializable(alias("appliance_ratings", primitive()))
  applianceRatings?: boolean = true;

  @serializable(alias("elastic_compliance", primitive()))
  elasticCompliance?: boolean = true;

  @serializable(alias("business_license_validity", primitive()))
  businessLicenseValidity?: boolean = true;

  @serializable(alias("license_url", primitive()))
  licenseUrl?: string;

  @serializable(alias("logo_url", primitive()))
  logoUrl?: string;

  @serializable(alias("note_sorting", primitive()))
  noteSorting?: boolean = false;

  @serializable(alias("open_records_locked", primitive()))
  openRecordsLocked?: string;

  @serializable(alias("oral_hygiene", primitive()))
  oralHygiene?: boolean = false;

  @serializable(alias("physical_presence", primitive()))
  physicalPresence?: boolean = false;

  @serializable(alias("req_pass_to_change_assistant", primitive()))
  reqPassToChangeAssistant?: boolean = false;

  @serializable(alias("show_medical_alerts", primitive()))
  showMedicalAlerts?: boolean = false;

  @serializable(alias("social_presence", primitive()))
  socialPresence?: boolean = false;

  @serializable(alias("sterilization_code", primitive()))
  sterilizationCode?: string;

  @serializable(alias("subscription_package", primitive()))
  subscriptionPackage?: string;

  @serializable(alias("tooth_numbering_system", primitive()))
  toothNumberingSystem?: string;

  @serializable(alias("password", primitive()))
  password?: string;

  @serializable(alias("subscription_id", primitive()))
  subscriptionId?: number;

  @serializable(alias("subscription_frequency_id", primitive()))
  subscriptionFrequencyId?: number;

  @serializable(alias("stripe_account_id", primitive()))
  stripeAccountId?: number;

  @serializable(alias("status", primitive()))
  status?: string;

  @serializable(alias("is_appointment_template_completed", primitive()))
  isAppointmentTemplateCompleted?: boolean;
  @serializable(alias("is_practice_availability_completed", primitive()))
  isPracticeAvailabilityCompleted?: boolean;
  @serializable(alias("is_resource_creation_completed", primitive()))
  isResourceCreationCompleted?: boolean;
  @serializable(alias("is_service_creation_completed", primitive()))
  isServiceCreationCompleted?: boolean;
  @serializable(alias("is_staff_profile_completed", primitive()))
  isStaffProfileCompleted?: boolean;
  @serializable(alias("is_statement_note_template_completed", primitive()))
  isStatementNoteTemplateCompleted?: boolean;
  @serializable(alias("is_treatment_template_completed", primitive()))
  isTreatmentTemplateCompleted?: boolean;
}
