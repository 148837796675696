import React, { useEffect, useState } from "react";
import { Col } from "antd";
import "./mobileNumberField.scss";
import DropdownField from "../DropdownField";
import InputField from "../InputField";
import {
  IDropdownOptions,
  MetaService,
} from "../../../services/Meta/meta.service";
import { IsdCode } from "../../../models/IsdCode/isdCode.model";
import { Field, ErrorMessage } from "formik";
import { Input } from "antd";
import Error from "../Error";

interface MobileNumberFieldProps {
  setFieldValue: Function;
  value?: string;
  name?: string;
  isdCodeKeyName?: string;
  errorMessage?: string;

  validateSchema?: (phoneNumber: number, isdCode: number) => Promise<boolean>;
}

function MobileNumberField({
  name,
  isdCodeKeyName,
  value,
  setFieldValue,
  validateSchema,
  errorMessage = "",
}: MobileNumberFieldProps) {
  const [isdCodeOptions, setIsdCodeOptions] = useState<IDropdownOptions[]>([]);

  useEffect(() => {
    MetaService.fetchIsdCodes(
      (isdCodes: IsdCode[]) => {
        setIsdCodeOptions(
          isdCodes.map((isdCode: IsdCode) => ({
            label: "+" + isdCode.isdCode,
            value: isdCode.isdCode,
          }))
        );
        setFieldValue(
          isdCodeKeyName ?? "isdCode",
          isdCodes.length ? isdCodes[0].id : undefined
        );
      },
      () => {},
      () => {}
    );
  }, []);
  const [valid, setValid] = useState(true);

  const handleKeyUp = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    setFieldValue(name, event.currentTarget.value);
    console.log(event.currentTarget.value);
    if (event.currentTarget.value && validateSchema) {
      const isValid = await validateSchema(
        Number(event.currentTarget.value),
        Number(value)
      );
      setValid(isValid);
    }
  };

  return (
    <Col span={12} className="mobile-number-field">
      <InputField
        addonBefore={
          <DropdownField
            name={isdCodeKeyName || "isdCode"}
            options={isdCodeOptions}
            value={value}
            setFieldValue={setFieldValue}
          />
        }
        title="Mobile number"
        type="number"
        name={name || "mobileNumber"}
        placeholder="Enter Mobile number"
        onChange={handleKeyUp}
      />
      <span>
        {" "}
        {!valid && (
          <Error className={`${name}__error`} message={errorMessage} />
        )}
      </span>
    </Col>
  );
}

export default MobileNumberField;
