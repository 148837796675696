import { Tabs } from "antd";
import React, { FC } from "react";
import Notification from "./Notification";
import { Notification as NotificationModel } from "../../../../models/Notification/notification.model";
import WorkItem from "./WorkItem";
import AuthContainer from "../../../../store/container/AuthContainer";
import { AuthReducerProps } from "../../../../store/reducers/authReducer";
import "./appNotification.scss";
import UserNotification from "../../../../services/UserNotification/userNotificatoin.service";

const { TabPane } = Tabs;

interface AppNotificationProps extends AuthReducerProps {
  notifications: NotificationModel[];
  workItems: NotificationModel[];
  removeNotification: () => void;
}

const AppNotification: FC<AppNotificationProps> = (props) => {
  const { notifications, workItems, removeNotification } = props;

  const { clearNotification } = UserNotification();

  const handleClearAllNotification = async () => {
    const cleared = await clearNotification();
    if (removeNotification) cleared && removeNotification();
  };

  return (
    <div className="app-notification">
      <p className="clear-all">
        <span onClick={handleClearAllNotification}>
          <i className="icon-remove" /> Clear All
        </span>
      </p>
      <Tabs defaultActiveKey="1" type="line">
        <TabPane tab={`Notification (${notifications.length})`} key="1">
          {notifications.map((notification) => (
            <Notification notification={notification} />
          ))}
        </TabPane>
        <TabPane tab={`Work item (${workItems.length})`} key="2">
          {workItems.map((workItem) => (
            <WorkItem workItem={workItem} />
          ))}
        </TabPane>
      </Tabs>
    </div>
  );
};

export default AuthContainer(AppNotification);
