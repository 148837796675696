import React, { FC, useEffect, useState } from "react";
import { Button, Col, Drawer, Row, Tabs } from "antd";
import AllAppointments from "./AllAppointments";
import "./dashboard.scss";
import WorkItems from "./WorkItems";
import DatePickerField from "../../shared/components/DatePickerField";
import { Formik, Form } from "formik";
import moment from "moment";
import Searchbar from "../../shared/components/Searchbar";
import { Patient } from "../../models/Patient/patient.model";
import { generatePath, useHistory, useLocation } from "react-router";
import AppRoutes from "../../routes/routeConstants/appRoutes";
import PatientService from "../../services/Patient/patient.service";
import CheckList from "../../shared/components/CheckList";
import CorporateContainer from "../../store/container/CorporateContainer";
import { CorporateReducerProps } from "../../store/reducers/corporateReducer";
import RegisterPaymentDetailsForm from "../Auth/Register/RegisterPaymentDetailsForm";
import DentalModelService from "../../services/DentalModel/DentalModel.service";
import { useMetrics } from "../../shared/hooks/Metrics/useMetrics";
import useFilters from "../../shared/hooks/useFilter/useFilters";
import useResponsibilities from "../../shared/hooks/Responsibilities/useResponsibilities";
import { ResponsibilityTypes } from "../../enums/responsebily.enum";
import { filterValidation } from "./filterValidation";
const { TabPane } = Tabs;
export interface Filter {
  startDate: string | undefined;
  endDate: string | undefined;
}

interface DashboardProps extends CorporateReducerProps {}

const Dashboard: FC<DashboardProps> = (props) => {
  const { getParams, setParams } = useFilters();
  const { hasAccess, IS_LOGGED_IN_USER_FRONT_DESK } = useResponsibilities();

  const { setOpenCheckList, firstVisit, setFirstVisit } = props;

  const history = useHistory();
  const currentLocation = useLocation();

  const location: {
    state: { openChecklist: boolean; workItem: boolean; interactionId: string };
  } = useLocation();

  const [filterVisibility, setFilterVisibility] = useState(false);

  const [tab, setTab] = useState<string>("0");

  const [patients, setPatients] = useState<Patient[]>([]);

  const [loading, setLoading] = useState(false);

  const [filter, setFilter] = useState<Filter>({
    startDate: undefined,
    endDate: undefined,
  });

  const HAS_APPOINTMENT_VIEW_ACCESS = hasAccess(
    ResponsibilityTypes.APPOINTMENT_VIEW
  );

  useEffect(() => {
    const openCheckList = localStorage.getItem("openCheckList");

    // DentalModelService.downloadModel();
    if (openCheckList && firstVisit) {
      setOpenCheckList(true);
      setFirstVisit(false);
      localStorage.removeItem("openCheckList");
    }
    if (location.state?.workItem) {
      setTab("1");
    }
    setLoading(true);
    PatientService.fetchPatients(
      {},
      (patients: Patient[]) => {
        setPatients(patients);
      },
      () => {},
      () => {
        setLoading(false);
      }
    );
  }, []);

  useEffect(() => {
    const tabFromHash = configurationTabs.find(
      (tab) =>
        tab.hash === currentLocation.hash.replace("#", "") && tab.hasAccess
    );

    if (tabFromHash) {
      setTab(tabFromHash?.key);
    } else {
      const firstTabWithAccess = configurationTabs.find(
        ({ hasAccess }) => hasAccess
      );
      setTab("" + firstTabWithAccess?.key);
    }
  }, [currentLocation?.hash]);

  const handleCloseFilter = () => {
    setFilterVisibility(false);
  };

  const getWorkItemTab = () => {
    if (IS_LOGGED_IN_USER_FRONT_DESK) return [];

    return [
      {
        key: "1",
        title: "Work Items",
        component: <WorkItems tab={tab} filters={filter} />,
        hash: "work-items",
        hasAccess: true,
      },
    ];
  };

  const configurationTabs = [
    {
      key: "0",
      title: "All Appointments",
      component: <AllAppointments filter={filter} tab={tab} />,
      hash: "all-appoitnments",
      hasAccess: HAS_APPOINTMENT_VIEW_ACCESS,
    },
    ...getWorkItemTab(),
  ];

  const handleChangeTab = (value: string) => {
    const hash = configurationTabs?.find((tab, index) => tab?.key === value)
      ?.hash;

    setParams({}, hash);
    setTab(value);
  };

  const options = patients?.map((patient) => {
    const content = (
      <Row className="patient-option__wrapper">
        <Col span={8}>
          <Row>
            <Col span={24}>
              {patient?.firstName}
              {patient?.lastName}
            </Col>
            <Col span={24} className="patient-email">
              {patient?.email}
            </Col>
          </Row>
        </Col>
        <Col span={8}>{patient?.mobileNumber}</Col>
        <Col span={8}>{patient?.email}</Col>
      </Row>
    );
    return {
      value: `${patient.firstName} ${patient.lastName}`,
      label: content,
      key: patient.id,
    };
  });

  const handleSearch = (value: string) => {};

  return (
    <div className="dashboard__container">
      <div className="dashboard-filter">
        <div>
          <Searchbar
            searchHandler={handleSearch}
            selectHandler={(selectedValue) => {
              history.push(
                generatePath(AppRoutes.PATIENT_DETAIL, {
                  patientId: selectedValue,
                })
              );
            }}
            options={options}
          />
        </div>
        <div>
          <Button type="primary" onClick={() => setFilterVisibility(true)}>
            {" "}
            <i className="icon-filter" />
            Filter
          </Button>
        </div>
      </div>

      <Tabs
        size="small"
        activeKey={tab}
        style={{ marginBottom: 32 }}
        onChange={handleChangeTab}
      >
        {configurationTabs.map(
          ({ title, component, hasAccess }, i) =>
            hasAccess && (
              <TabPane tab={title} key={i}>
                {component}
              </TabPane>
            )
        )}
      </Tabs>
      <Drawer
        title="Filter"
        visible={filterVisibility}
        destroyOnClose
        onClose={handleCloseFilter}
        width={"40vw"}
        className="dashboard-filter"
      >
        <Formik
          initialValues={filter}
          enableReinitialize
          validationSchema={filterValidation}
          onSubmit={(values) => {
            setParams({ ...getParams(), ...values });
            setFilter(values);
            handleCloseFilter();
          }}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <div className="date__wrapper">
                <Row>
                  <Col span={24}>
                    {" "}
                    <DatePickerField
                      title="From Date"
                      name="startDate"
                      format="MM-DD-YYYY"
                      defaultValue={values?.startDate}
                      placeholder="Choose"
                      onChange={(date) => {
                        setFieldValue("startDate", date?.format("YYYY-MM-DD"));
                      }}
                    />
                  </Col>
                </Row>
                <Col span={24}>
                  <DatePickerField
                    title="To Date"
                    name="endDate"
                    format="MM-DD-YYYY"
                    defaultValue={values?.endDate}
                    disabledDate={(date) =>
                      date?.isBefore(moment(values?.startDate, "YYYY-MM-DD"))
                    }
                    placeholder="Choose"
                    onChange={(date) => {
                      setFieldValue("endDate", date?.format("YYYY-MM-DD"));
                    }}
                  />
                </Col>
              </div>
              <div className="date-controller">
                <Button type="text" onClick={handleCloseFilter}>
                  Cancel
                </Button>
                <Button type="primary" htmlType="submit">
                  Apply
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Drawer>
    </div>
  );
};

export default CorporateContainer(Dashboard);
