import React, { FC, useEffect, useState } from "react";
import { Button, Drawer, Modal, Table } from "antd";
import PatientService from "../../../services/Patient/patient.service";
import { useParams } from "react-router-dom";
import { PatientNotes as PatientNotesModel } from "models/PatientNotes/patientNotes.model";
import { PlusOutlined } from "@ant-design/icons";
import PatientNotesForm from "./PatientNotesForm";
import { ColumnsType } from "antd/lib/table";
import { formatDate } from "shared/utils/dateFormatter";

interface PatientNotesProps {
  isViewMode?: boolean;
}

const PatientNotes: FC<PatientNotesProps> = (props) => {
  const { isViewMode } = props;
  const [loading, setLoading] = useState(false);
  const params = useParams<{ patientId: string }>();
  const [patientNotes, setPatientNotes] = useState<PatientNotesModel[]>([]);
  const [
    selectedPatientNote,
    setSelectedPatientNote,
  ] = useState<PatientNotesModel>();
  const [isPatientNotesFormVisible, setIsPatientNotestFormVisible] = useState(
    false
  );
  useEffect(() => {
    handleFetchPatientNotes();
  }, []);

  const handleFetchPatientNotes = () => {
    setLoading(true);

    PatientService.fetchPatientNotes(
      params.patientId,
      (notes) => setPatientNotes(notes),
      () => {},
      () => setLoading(false)
    );
  };

  const handleDeletePatientNote = (value: PatientNotesModel) => {
    setLoading(true);
    PatientService.deletePatientNote(
      params.patientId,
      String(value?.id),
      () =>
        setPatientNotes(patientNotes.filter((note) => note.id !== value.id)),
      () => {},
      () => setLoading(false)
    );
  };
  const columns: ColumnsType<PatientNotesModel> = [
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      width: "20%",
      render: (date) => formatDate(date),
    },
    {
      title: "Notes",
      dataIndex: "notes",
      key: "notes",
    },

    {
      title: "",
      dataIndex: "update",
      key: "update",
      width: "10%",
      render: (_, record) => (
        <>
          {isViewMode ? (
            <i
              className="icon-view resource-view-icon"
              onClick={(event) => {
                event.stopPropagation();
                setSelectedPatientNote(record);
                setIsPatientNotestFormVisible(true);
              }}
            />
          ) : (
            <>
              <i
                className="icon-edit resource-edit-icon"
                onClick={(event) => {
                  event.stopPropagation();
                  setSelectedPatientNote(record);
                  setIsPatientNotestFormVisible(true);
                }}
              />
              <i
                className="icon-delete resource-delete-icon"
                onClick={(event) => {
                  event.stopPropagation();
                  Modal.confirm({
                    content: `Are you sure you want to delete this note`,
                    okText: "Yes",
                    cancelText: "No",
                    onOk: () => handleDeletePatientNote(record),
                  });
                }}
              />
            </>
          )}
        </>
      ),
    },
  ];

  const handlePatientNotesFormVisibility = () => {
    setIsPatientNotestFormVisible((visible) => !visible);
    setSelectedPatientNote(undefined);
  };

  const handleOnSuccess = (updatedNote: PatientNotesModel) => {
    setPatientNotes((prevNotes) => [
      ...prevNotes.filter((note) => note.id !== updatedNote.id),
      updatedNote,
    ]);
    setIsPatientNotestFormVisible(false);
  };

  return (
    <div className="patient-notes">
      {!isViewMode && (
        <div className="text-right">
          <Button
            type="primary"
            className="ml-3"
            onClick={handlePatientNotesFormVisibility}
          >
            <PlusOutlined />
            Add Note
          </Button>
        </div>
      )}
      <Table
        dataSource={isViewMode ? patientNotes.slice(-3) : patientNotes}
        columns={columns}
        loading={loading}
        pagination={!isViewMode && { hideOnSinglePage: !isViewMode }}
      />
      <Drawer
        title="Patient Notes"
        visible={isPatientNotesFormVisible}
        destroyOnClose
        onClose={handlePatientNotesFormVisibility}
        width={"45vw"}
        push={false}
      >
        <PatientNotesForm
          patientId={params.patientId}
          formValue={selectedPatientNote}
          onSuccess={handleOnSuccess}
          onClose={handlePatientNotesFormVisibility}
        />
      </Drawer>
    </div>
  );
};

export default PatientNotes;
