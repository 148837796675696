import ApiRoutes from "../../routes/routeConstants/apiRoutes";
import axiosInstance from "../../interceptor/axiosInstance";
import { CorporateLink } from "../../models/CorporateLink/corporateLink.model";
import { deserialize, serialize } from "serializr";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import { CorporateObject } from "../../models/CorporateObject/corporateObject.model";
import { Corporate } from "../../models/Corporate/corporate.model";

export default class CorporateLinkService {
  static fetchCorporateLinks(
    corporateObjectId: number | null = null,
    srcObjectId: number | null = null,
    destObjectId: number | null = null,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .get(ApiRoutes.CORPORATE_LINKS, {
        params: {
          corporate_object_id: corporateObjectId || "",
          src_object_id: srcObjectId || "",
          dest_object_id: destObjectId || "",
        },
      })
      .then((response) => {
        const corporateLinks = deserialize(
          CorporateLink,
          response.data["corporate_links"]
        );
        onSuccess(corporateLinks);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static showCorporateLink(
    corporateLinkId: number,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.CORPORATE_LINKS + "/" + corporateLinkId;
    axiosInstance
      .get(API_URL)
      .then((response) => {
        const corporateLink = deserialize(
          CorporateLink,
          response.data["corporate_link"]
        );
        onSuccess(corporateLink);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static updateCorporateLink(
    corporateLink: CorporateLink,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.CORPORATE_LINKS + "/" + corporateLink.id;
    const corporateLinkJSON = { corporate_link: serialize(corporateLink) };
    axiosInstance
      .put(API_URL, corporateLinkJSON)
      .then((response) => {
        Notification({
          message: "Corporate link updated!",
          type: NotificationTypes.SUCCESS,
        });
        const corporateLink = deserialize(
          CorporateLink,
          response.data["corporate_link"]
        );
        onSuccess(corporateLink);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }
}
