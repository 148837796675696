import React, { Fragment, useEffect, useRef, useState } from "react";
import "./detailedOnboardingBasicDetails.scss";
import { Form, Formik, FormikValues } from "formik";
import { Button, Col, Drawer, Row, Tooltip } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import DropdownField from "../../../shared/components/DropdownField";
import { Practice } from "../../../models/Practice/practice.model";
import PracticeService from "../../../services/Practice/practice.service";
import { User } from "../../../models/User/user.model";
import UserService from "../../../services/User/user.service";
import PracticeList from "../PracticeList";
import UserList from "../UserList";
import PracticeForm from "../PracticeForm";
import { corporateAdvancedDetailsFormValidation } from "./corporateAdvancedDetailsFormValidation";
import UserForm from "../../../shared/components/UserForm";
import RegisterContainer from "../../../store/container/RegisterContainer";
import { Corporate } from "../../../models/Corporate/corporate.model";
import { RegistrationService } from "../../../services/Registration/registration.service";
import { RegisterReducerProps } from "../../../store/reducers/registerReducer";
import CorporateContainer from "../../../store/container/CorporateContainer";
import { CorporateReducerProps } from "../../../store/reducers/corporateReducer";
import AuthContainer from "../../../store/container/AuthContainer";
import { AuthReducerProps } from "../../../store/reducers/authReducer";
import { ResponsibilityTypes } from "../../../enums/responsebily.enum";
import { MetaService } from "../../../services/Meta/meta.service";

interface DetailedOnboardingBasicDetailsProps
  extends RegisterReducerProps,
    CorporateReducerProps,
    AuthReducerProps {}

function DetailedOnboardingBasicDetails({
  detailedOnboardingActiveStep,
  setDetailedOnboardingActiveStep,
  setCurrentCorporate,
  userResponsibilities,
  authenticated,
  setPracticeId,
}: DetailedOnboardingBasicDetailsProps) {
  const userRef = useRef<HTMLDivElement | null>(null);

  const practiceRef = useRef<HTMLDivElement | null>(null);

  const [formValues, setFormValues] = useState(new Corporate());

  const [formLoading, setFormLoading] = useState(false);

  const [showPracticeForm, setShowPracticeForm] = useState(false);

  const [showUserForm, setShowUserForm] = useState(false);

  const [practices, setPractices] = useState<Practice[]>([]);

  const [users, setUsers] = useState<User[]>([]);

  const [toothNumberingOptions, setToothNumberingOptions] = useState([
    { label: "Universal", value: "Universal" },
    { label: "FDI", value: "FDI" },
    { label: "Palmer", value: "palmer" },
  ]);

  const [recordOptions, setRecordOptions] = useState([
    { label: "Option 1", value: "Option 1" },
  ]);

  const [sterilizationOptions, setSterilizationOptions] = useState([
    { label: "Option 1", value: "Option 1" },
  ]);

  const [yesOrNoOptions, setYesOrNoOptions] = useState([
    { label: "Yes", value: true },
    { label: "No", value: false },
  ]);

  const handleShowPracticeForm = () => setShowPracticeForm(true);

  const handleShowUserForm = () => setShowUserForm(true);

  const handleClosePracticeForm = () => setShowPracticeForm(false);

  const handleCloseUserForm = () => setShowUserForm(false);

  const handleSubmit = (values: FormikValues) => {
    const corporate = Object.assign(new Corporate(), {
      ...values,
      stepNumber: 4,
    });
    setFormLoading(true);
    RegistrationService.updateAdditionalDetails(
      corporate,
      (corporate: Corporate) => {
        setCurrentCorporate(corporate);
        setDetailedOnboardingActiveStep(detailedOnboardingActiveStep + 1);
      },
      () => {},
      () => {
        setFormLoading(false);
      }
    );
  };

  const handleFetchPractices = () => {
    PracticeService.fetchPractices(
      (practices: Practice[]) => {
        setPractices(practices);
      },
      () => {},
      () => {}
    );
  };

  const handleFetchUsers = () => {
    UserService.fetchUsers(
      {},
      (users: User[]) => {
        setUsers(users);
      },
      () => {},
      () => {}
    );
  };

  const handleUserFormSuccess = (user: User) => {
    users.unshift(user);
    setUsers([...users]);
    setShowUserForm(false);
    if (userRef && userRef.current) {
      userRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  const handlePracticeFormSuccess = (practice: Practice) => {
    practices.unshift(practice);
    setPractices([...practices]);
    setShowPracticeForm(false);
    if (practiceRef && practiceRef.current) {
      practiceRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  const handleUserDeleteSuccess = (userId: number) => {
    const userIndex = users.findIndex((user: User) => user.id === userId);
    if (userIndex >= 0) {
      users.splice(userIndex, 1);
      setUsers([...users]);
    }
  };

  const handlePracticeDeleteSuccess = (practiceId: string) => {
    const practiceIndex = practices.findIndex(
      (practice: Practice) => practice.id === practiceId
    );
    if (practiceIndex >= 0) {
      practices.splice(practiceIndex, 1);
      setPractices([...practices]);
    }
  };

  useEffect(() => {
    if (authenticated) {
      handleFetchPractices();
      handleFetchUsers();
    }
  }, [authenticated]);

  return (
    <div className="detailed-onboarding-basic-details">
      <div className="detailed-onboarding-basic-details__add-wrapper">
        {userResponsibilities.includes(ResponsibilityTypes.STAFF_CREATE) && (
          <Tooltip
            title="Add min one practice to add a user"
            placement="bottom"
            overlayStyle={{
              visibility: practices.length ? "hidden" : "visible",
            }}
          >
            <Button
              className="mr-3 secondary-btn"
              onClick={handleShowUserForm}
              disabled={!practices.length}
            >
              <PlusOutlined />
              Add User
            </Button>
          </Tooltip>
        )}
        {/* {userResponsibilities.includes(ResponsibilityTypes.PRACTICE_CREATE) && ( */}
        <Button type="primary" onClick={handleShowPracticeForm}>
          <PlusOutlined />
          Add Practice
        </Button>
        {/* )} */}
      </div>
      <Formik
        initialValues={formValues}
        onSubmit={handleSubmit}
        enableReinitialize
        validationSchema={corporateAdvancedDetailsFormValidation}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          errors,
          isValid,
          dirty,
          setFieldValue,
        }) => {
          return (
            <Form>
              <div className="detailed-onboarding-basic-details__form">
                <Row gutter={[20, 0]}>
                  <Col span={12}>
                    <DropdownField
                      title="Tooth numbering system"
                      name="toothNumberingSystem"
                      options={toothNumberingOptions}
                      value={values.toothNumberingSystem}
                      setFieldValue={setFieldValue}
                      placeHolder="Select Tooth numbering system"
                    />
                  </Col>
                  <Col span={12}>
                    <DropdownField
                      title="Opening records locked"
                      name="openRecordsLocked"
                      options={recordOptions}
                      value={values.openRecordsLocked}
                      setFieldValue={setFieldValue}
                      placeHolder="Select Records"
                    />
                  </Col>
                  <Col span={12}>
                    <DropdownField
                      title="Sterilization Code"
                      name="sterilizationCode"
                      options={sterilizationOptions}
                      value={values.sterilizationCode}
                      setFieldValue={setFieldValue}
                      placeHolder="Select sterilization code"
                    />
                  </Col>
                  <Col span={12}>
                    <DropdownField
                      title="Note sorting"
                      name="noteSorting"
                      options={yesOrNoOptions}
                      value={values.noteSorting}
                      setFieldValue={setFieldValue}
                      placeHolder="Select note sorting"
                    />
                  </Col>
                  <Col span={12}>
                    <DropdownField
                      title="Show Medical alerts"
                      name="showMedicalAlerts"
                      options={yesOrNoOptions}
                      value={values.showMedicalAlerts}
                      setFieldValue={setFieldValue}
                      placeHolder="Select show medical alerts"
                    />
                  </Col>
                  <Col span={12}>
                    <DropdownField
                      title="Elastic compliance"
                      name="elasticCompliance"
                      options={yesOrNoOptions}
                      value={values.elasticCompliance}
                      setFieldValue={setFieldValue}
                      placeHolder="Select elastic compliance"
                    />
                  </Col>
                  <Col span={12}>
                    <DropdownField
                      title="Oral hygiene"
                      name="oralHygiene"
                      options={yesOrNoOptions}
                      value={values.oralHygiene}
                      setFieldValue={setFieldValue}
                      placeHolder="Select oral hygiene"
                    />
                  </Col>
                  <Col span={12}>
                    <DropdownField
                      title="Appliance ratings"
                      name="applianceRatings"
                      options={yesOrNoOptions}
                      value={values.applianceRatings}
                      setFieldValue={setFieldValue}
                      placeHolder="Select appliance rating"
                    />
                  </Col>
                  <Col span={12}>
                    <DropdownField
                      title="Require password to change assistant"
                      name="reqPassToChangeAssistant"
                      options={yesOrNoOptions}
                      value={values.reqPassToChangeAssistant}
                      setFieldValue={setFieldValue}
                      placeHolder="Select Yes/No"
                    />
                  </Col>
                </Row>
                <div>
                  <h5>Note:</h5>
                  <div>
                    This will be set as default for all the practices for now.
                    Later, each practice can be configured individually from
                    settings.
                  </div>
                </div>
              </div>
              {userResponsibilities.includes(
                ResponsibilityTypes.PRACTICE_VIEW
              ) && (
                <div
                  className="detailed-onboarding-basic-details__practice-list"
                  ref={practiceRef}
                >
                  <PracticeList
                    practices={practices}
                    onDeleteSuccess={handlePracticeDeleteSuccess}
                  />
                </div>
              )}
              {userResponsibilities.includes(
                ResponsibilityTypes.STAFF_VIEW
              ) && (
                <div
                  className="detailed-onboarding-basic-details__staff-list"
                  ref={userRef}
                >
                  <UserList
                    users={users}
                    onDeleteSuccess={handleUserDeleteSuccess}
                  />
                </div>
              )}
              <div className="detailed-onboarding-basic-details__action-wrapper text-right">
                <Button type="default">Cancel</Button>
                <Button
                  type="primary"
                  className="ml-2"
                  htmlType="submit"
                  loading={formLoading}
                  disabled={
                    !isValid || !dirty || formLoading || practices?.length === 0
                  }
                >
                  Next
                </Button>
              </div>
              {practices?.length === 0 && (
                <p className="text-right text-danger">
                  At least one practice should be added
                </p>
              )}
            </Form>
          );
        }}
      </Formik>
      <Drawer
        title={
          <div>
            <h4>Add Practice</h4>
          </div>
        }
        keyboard={false}
        placement="right"
        maskClosable={false}
        onClose={handleClosePracticeForm}
        visible={showPracticeForm}
        destroyOnClose={true}
        width="60%"
      >
        <PracticeForm
          onCancel={handleClosePracticeForm}
          onSuccess={handlePracticeFormSuccess}
        />
      </Drawer>
      <Drawer
        title={
          <div>
            <h4>Add Staff</h4>
          </div>
        }
        keyboard={false}
        placement="right"
        maskClosable={false}
        onClose={handleCloseUserForm}
        visible={showUserForm}
        destroyOnClose={true}
        width="60%"
      >
        <UserForm
          onCancel={handleCloseUserForm}
          onSuccess={handleUserFormSuccess}
        />
      </Drawer>
    </div>
  );
}

export default AuthContainer(
  CorporateContainer(RegisterContainer(DetailedOnboardingBasicDetails))
);
