import React, { useCallback, useEffect, useState } from "react";
import UserService from "../../../services/User/user.service";
import { User } from "../../../models/User/user.model";
import { useParams, useHistory } from "react-router-dom";
import { Button, Col, Row, Popover, Menu, Dropdown, Drawer } from "antd";
import { LeftOutlined, PlusOutlined, RightOutlined } from "@ant-design/icons";
import "./userSchedule.scss";
import moment from "moment";
import ScheduleService from "../../../services/Schedule/schedule.service";
import avatarPlaceholder from "../../../assets/images/userPlaceholder.png";

import ScheduleForm from "../ScheduleForm";
import { Schedule } from "../../../models/Schedule/schedule.model";
import AppLoader from "../../../shared/components/AppLoader";
import { ResponsibilityTypes } from "../../../enums/responsebily.enum";

import ScheduleSlotForm from "../ScheduleSlot/ScheduleSlotForm";
import { Slot } from "../../../models/Slot/slot.model";
import { AppointmentService } from "../../../services/Appointment/appointment.service";
import useResponsibilities from "../../../shared/hooks/Responsibilities/useResponsibilities";

interface UserScheduleParams {
  staffId: string;
}

export interface DateProps {
  startDate?: string;
  endDate?: string;
  currentWeek?: number;
  currentYear?: number;
  currentDate: Date;
}

interface UserScheduleProps {}

function UserSchedule(props: UserScheduleProps) {
  const history = useHistory();
  const params: UserScheduleParams = useParams();

  const [loading, setLoading] = useState(false);

  const { hasAccess } = useResponsibilities();

  const [user, setUser] = useState<User | undefined>();
  const [isEditVisible, setIsEditVisible] = useState(false);

  const [showScheduleForm, setShowScheduleForm] = useState(false);

  const [currentSlot, setCurrentSlot] = useState<Slot>();
  const [currentScheduleId, setCurrentScheduleId] = useState<number>();

  const [weekDays] = useState<string[]>([
    "Sun",
    "Mon",
    "Tue",
    "Wed",
    "Thu",
    "Fri",
    "Sat",
  ]);

  const [date, setDate] = useState<DateProps>({
    currentDate: new Date(),
  });

  const HAS_SCHEDULE_CREATE_ACCESS = hasAccess(
    ResponsibilityTypes.SCHEDULE_CREATE
  );

  const handleToggleScheduleForm = () => setShowScheduleForm(!showScheduleForm);

  const handleScheduleFormSuccess = () => {
    if (date.startDate && date.endDate) {
      handleShowUserSchedules(date.startDate, date.endDate);
    }
    setIsEditVisible(false);
    setShowScheduleForm(false);
  };

  const handleShowUserSchedules = useCallback(
    (startDate, endDate: string) => {
      if (params?.staffId) {
        ScheduleService.showUserSchedule(
          Number(params.staffId),
          startDate,
          endDate,
          (user: User) => {
            setUser(user);
          },
          () => {},
          () => {
            setLoading(false);
          }
        );
      }
    },
    [params]
  );

  const handleNavigateNextWeek = () => {
    const startDate = moment(date.startDate, "YYYY-MM-DD")
      .add(7, "days")
      .format("YYYY-MM-DD");
    const endDate = moment(date.endDate, "YYYY-MM-DD")
      .add(7, "days")
      .format("YYYY-MM-DD");
    setDate({
      ...date,
      startDate,
      endDate,
      currentWeek: moment(startDate).week(),
      currentYear: moment(endDate).year(),
    });
    handleShowUserSchedules(startDate, endDate);
  };

  const handleNavigatePreviousWeek = () => {
    const startDate = moment(date.startDate, "YYYY-MM-DD")
      .subtract(7, "days")
      .format("YYYY-MM-DD");
    const endDate = moment(date.endDate, "YYYY-MM-DD")
      .subtract(7, "days")
      .format("YYYY-MM-DD");
    setDate({
      ...date,
      startDate,
      endDate,
      currentWeek: moment(startDate).week(),
      currentYear: moment(endDate).year(),
    });
    handleShowUserSchedules(startDate, endDate);
  };
  const deleteSlots = (scheduleId: number, SlotId: number) => {
    AppointmentService.deleteSlot(
      scheduleId?.toString() ?? "",
      SlotId?.toString() ?? "",
      () => {
        handleScheduleFormSuccess();
      },
      () => {},
      () => {}
    );
  };
  const handleEditVisibility = () => {
    setIsEditVisible(!isEditVisible);
  };
  useEffect(() => {
    handleSlots();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  const handleSlots = () => {
    const startDate = moment(date.currentDate)
      .startOf("week")
      .format("YYYY-MM-DD");
    const endDate = moment(date.currentDate).endOf("week").format("YYYY-MM-DD");
    setDate({
      ...date,
      currentWeek: moment(date.currentDate).week(),
      currentYear: moment(date.currentDate).year(),
      startDate,
      endDate,
    });
    handleShowUserSchedules(startDate, endDate);
  };

  return (
    <div className="user-schedule">
      <Row align="middle">
        <Col span={2}>
          <Button
            onClick={() => history.goBack()}
            className="mr-3"
            icon={<LeftOutlined />}
          />
        </Col>
        <Col span={4}>
          Staffs / <span className="text-secondary">{user?.getFullName()}</span>
        </Col>
        <Col span={8} className="user-schedule__navigation-wrapper">
          <Button
            icon={<LeftOutlined />}
            onClick={handleNavigatePreviousWeek}
          />
          <Button>
            {moment(date?.startDate).format("MMM DD") +
              " - " +
              moment(date?.endDate).format("MMM DD") +
              " , " +
              moment(date?.startDate).format("YYYY")}
          </Button>
          <Button icon={<RightOutlined />} onClick={handleNavigateNextWeek} />
        </Col>
        <Col span={10} className="text-right">
          {HAS_SCHEDULE_CREATE_ACCESS && (
            <Button
              onClick={handleToggleScheduleForm}
              icon={<PlusOutlined />}
              className="user-schedule__add-button"
            />
          )}
          {/* 
          TODO: Need to be handled.
          <Button>
            <i className="icon-print mr-2" />
            Print
          </Button>
          <Button type="primary" className="ml-2">
            <i className="icon-publish mr-2" /> Publish
          </Button> */}
        </Col>
      </Row>
      {loading ? (
        <AppLoader loading={loading} />
      ) : (
        <div className="user-schedule__calendar">
          <Row>
            <Col span={3}></Col>
            {weekDays.map((weekDay, i) => (
              <Col span={3} className="user-schedule__label">
                {weekDay}{" "}
                {moment(date?.startDate, "YYYY-MM-DD")
                  .add(i, "days")
                  .format("DD")}
              </Col>
            ))}
          </Row>
          <Row className="user-schedule__schedules">
            <Col span={3} className="user-schedule__user-details-wrapper">
              <div className="user-schedule__user-details">
                <div>
                  <img
                    src={user?.profilePictureUrl || avatarPlaceholder}
                    alt="User Profile Picture"
                    className="user-schedule__user-profile-picture"
                  />
                  <h5>{user?.getFullName()}</h5>
                  <div className="user-schedule__user-role">
                    {user?.roleName}
                  </div>
                </div>
              </div>
            </Col>
            {Schedule.sortSchedules?.(user?.schedules)?.map((schedule, i) =>
              schedule?.slots && schedule?.slots?.length > 0 ? (
                <Col span={3} key={i} className="user-schedule__schedule-col">
                  <div className="user-schedule__schedule-item">
                    {schedule?.slots?.map((slot, j) => (
                      <div className="user-schedule__slot-item">
                        <Dropdown
                          arrow
                          trigger={["click"]}
                          placement="bottomRight"
                          overlayClassName="user-schedule__actions-menu"
                          overlay={
                            <Menu>
                              <Menu.Item
                                onClick={() => {
                                  setCurrentSlot(slot);
                                  setCurrentScheduleId(schedule?.id);
                                  handleEditVisibility();
                                }}
                              >
                                <i className="icon-edit mr-2" /> Edit
                              </Menu.Item>
                              <Menu.Item
                                onClick={() => {
                                  deleteSlots(schedule?.id ?? 0, slot?.id ?? 0);
                                }}
                              >
                                <i className="icon-delete mr-2" /> Delete
                              </Menu.Item>
                            </Menu>
                          }
                        >
                          <div className="user-schedule-slot-item-body">
                            <div>{slot?.practiceName}</div>
                            <div className="user-schedule__slot-time">
                              <i>
                                {moment(slot?.startTime).format("hh:mm a")} -
                                {moment(slot?.endTime).format("hh:mm a")}
                              </i>
                            </div>
                            <div className="user-schedule__availability-types">
                              {slot?.availabilityTypes?.map(
                                (availabilityType, index) => (
                                  <div
                                    key={index}
                                    className="text-capitalize user-schedule__availability-type"
                                  >
                                    <i className="icon-calendar mr-2"></i>
                                    {availabilityType.type}
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                        </Dropdown>
                      </div>
                    ))}
                  </div>
                </Col>
              ) : (
                <Col span={3} key={i} className="user-schedule__schedule-col">
                  <div className="user-schedule__empty-slot">Unavailable</div>
                </Col>
              )
            )}
          </Row>
          <Drawer
            visible={showScheduleForm}
            title={<h4>New Shift Schedule</h4>}
            destroyOnClose
            maskClosable={false}
            keyboard={false}
            onClose={handleToggleScheduleForm}
            width="60%"
          >
            {user?.id && (
              <ScheduleForm
                userId={user?.id}
                onSuccess={handleScheduleFormSuccess}
                onCancel={handleToggleScheduleForm}
              />
            )}
          </Drawer>
          <Drawer
            visible={isEditVisible}
            width={"65vw"}
            title="Edit Staff Slot"
            closable
            onClose={handleEditVisibility}
            destroyOnClose
          >
            <ScheduleSlotForm
              onSuccess={handleScheduleFormSuccess}
              slot={currentSlot ?? new Slot()}
              handleEditVisibility={handleEditVisibility}
              scheduleId={currentScheduleId ?? 0}
            />
          </Drawer>
        </div>
      )}
    </div>
  );
}

export default UserSchedule;
