import { useEffect, useState } from "react";
import { DentalSet } from "../../../models/DentalSet/dentalSet.model";
import { PaginationDetails } from "../../../models/Pagination/pagination.model";
import SetService from "../../../services/Set/set.service";
import { getDropdownOptions } from "../../utils/getDropdownOptions";
import { FilterParams } from "models/FilterParams/filterParams.model";
import { serialize } from "serializr";

interface useSetsProps extends FilterParams {}

const useSets = (filters: useSetsProps = {}) => {
  const [sets, setSets] = useState<DentalSet[]>([]);

  const [setsMeta, setSetsMeta] = useState(new PaginationDetails());

  const [loading, setLoading] = useState(false);

  const options = getDropdownOptions(sets, "name", "id");

  const fetchSets = () => {
    if (!setsMeta?.nextPage) return;

    setLoading(true);
    SetService.fetchSets(
      (sets: DentalSet[], meta: PaginationDetails) => {
        setSets((existingSets) => [...existingSets, ...sets]);
        setSetsMeta(meta);
      },
      () => {},
      () => {
        setLoading(false);
      },
      { ...filters, page: setsMeta?.nextPage }
    );
  };

  useEffect(() => {
    fetchSets();
  }, []);

  return { sets, fetchSets, loading, options };
};

export default useSets;
