export enum StatusEnum {
  INACTIVE = "inactive",
  ACTIVE = "active",
  COMPLETED = "completed",
  PAYMENT_PENDING = "payment_pending",
  CLOSED = "closed",
  ON_HOLD = "on_hold",
  INITIATED = "initiated",
  WRITTEN_OFF = "written_off",
  INVALID = "invalid",
  PAST_DUE = "past_due",
}
