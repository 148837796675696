import React from "react";
import InputField from "../../../shared/components/InputField";
import { CustomFormInputEnum } from "../../../enums/customFormInput.enum";
import { CustomField } from "../../../models/CustomField/customField.model";
import { FormikValues } from "formik";
import DropdownField from "../../../shared/components/DropdownField";
import { DropdownModeEnum } from "../../../enums/dropdownMode.enum";
import RadioInputField from "../../../shared/components/RadioInputField";
import { CustomFormResponse } from "../../../models/CustomFormResponse/customFormResponse.model";
import { CustomResponseField } from "../../../models/CustomResponseField/customResponseField.model";
import { CustomResponse } from "../../../models/CustomResponse/customResponse.model";
import SignatureInput from "../../../shared/components/SignatureInput";
import { CustomResponseFormSignature } from "../../../models/CustomResponseFormSignature/customResponseFormSignature.model";
import CustomFormResponseService from "../../../services/CustomFormResponse/customFormResponse.service";

export const getCustomFormInput = (
  values: FormikValues,
  setFieldValue: Function,
  customField: CustomField
) => {
  switch (customField.type) {
    case CustomFormInputEnum.TEXTBOX:
    case CustomFormInputEnum.TEXTAREA: {
      return (
        <InputField
          placeholder="Type your answer here"
          title={customField.name}
          type={
            customField?.type === CustomFormInputEnum.TEXTAREA
              ? "textarea"
              : "text"
          }
          name={customField?.id?.toString() || ""}
        />
      );
    }
    case CustomFormInputEnum.BOOLEAN: {
      return (
        <RadioInputField
          options={customField.getCustomDropdownOptions()}
          value={
            values && customField?.id ? values[customField?.id] : undefined
          }
          setFieldValue={setFieldValue}
          title={customField.name}
          name={customField?.id?.toString() || ""}
        />
      );
    }
    case CustomFormInputEnum.SINGLE_SELECT:
    case CustomFormInputEnum.MULTI_SELECT: {
      return (
        <DropdownField
          mode={
            customField?.type === CustomFormInputEnum.MULTI_SELECT
              ? DropdownModeEnum.MULTIPLE
              : undefined
          }
          title={customField.name}
          name={customField?.id?.toString() || ""}
          options={customField.getCustomDropdownOptions()}
          value={
            values && customField?.id ? values[customField?.id] : undefined
          }
          setFieldValue={setFieldValue}
          placeHolder={
            customField?.type === CustomFormInputEnum.MULTI_SELECT
              ? "Select multiple options"
              : "Select one option"
          }
        />
      );
    }
    case CustomFormInputEnum.SIGNATURE:
      return (
        <SignatureInput
          name={customField?.id?.toString() || ""}
          title={customField.name}
          onUpload={(sign) =>
            sign ? new Promise((res, rej) =>
              CustomFormResponseService.uploadSignature(
                sign,
                (sign) => {
                  setFieldValue(customField.id, sign);
                  res();
                },
                rej
              )
            ) : setFieldValue(customField.id, undefined)
          }
        />
      );
  }
};

export const getCustomFormResponseFromObject = (values: FormikValues) => {
  const customFormResponse = new CustomFormResponse();
  customFormResponse.customResponseFields = Object.keys(values).map((key) => {
    const customResponseField = new CustomResponseField();
    customResponseField.customFieldId = Number(key);
    if (values[key] instanceof CustomResponseFormSignature) {
      customResponseField.customResponses = [
        Object.assign(new CustomResponse(), {
          signatureId: values[key].id,
          signatureUrl: values[key].url,
        }),
      ];
    } else if (Array.isArray(values[key])) {
      customResponseField.customResponses = values[key].map((value: number) =>
        Object.assign(new CustomResponse(), {
          customOptionId: value,
        })
      );
    } else {
      if (typeof values[key] === "string") {
        customResponseField.customResponses = [
          Object.assign(new CustomResponse(), {
            customOptionId: null,
            responseText: values[key],
          }),
        ];
      } else {
        customResponseField.customResponses = [
          Object.assign(new CustomResponse(), {
            customOptionId: values[key],
          }),
        ];
      }
    }
    return customResponseField;
  });
  return customFormResponse;
};
