import { serializable, alias, primitive } from "serializr";

export class ContractInsurance {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("insurance_provider", primitive()))
  insuranceProvider?: string;

  @serializable(alias("policy_number", primitive()))
  policyNumber?: string;

  @serializable(alias("id_number", primitive()))
  idNumber?: string;

  @serializable(alias("policy_holder_name", primitive()))
  policyHolderName?: string;

  @serializable(alias("dob", primitive()))
  dob?: string;

  @serializable(alias("eligible_percentage", primitive()))
  eligiblePercentage?: number;
}
