import { PatientCategory } from "../../enums/patientCategory.enum";
import { serializable, alias, object, list, primitive } from "serializr";
import { IssueCategory } from "../../enums/issueCategory.enum";

export class FilterParams {
  @serializable(alias("search_text", primitive()))
  search?: string;
  @serializable(alias("id", primitive()))
  id?: number;
  @serializable(alias("appointment_type_id", primitive()))
  appointmentTypeId?: number;
  @serializable(alias("statement_template_category_id", primitive()))
  statementTemplateCategoryId?: string;
  @serializable(alias("role_caregory", primitive()))
  roleCategory?: string;
  @serializable(alias("type", primitive()))
  type?: string;
  @serializable(alias("patient_profile_id", primitive()))
  patientProfileId?: string;
  @serializable(alias("is_associated_with_slot", primitive()))
  isAssociatedWithSlot?: boolean;
  @serializable(alias("role_category", primitive()))
  role_category?: string;
  @serializable(alias("start_date", primitive()))
  startDate?: string;
  @serializable(alias("from_date", primitive()))
  fromDate?: string;
  @serializable(alias("to_date", primitive()))
  toDate?: string;
  @serializable(alias("time_period", primitive()))
  timePeriod?: string;
  @serializable(alias("status", primitive()))
  status?: string;
  @serializable(alias("end_date", primitive()))
  endDate?: string;
  @serializable(alias("is_without_future_appointment", primitive()))
  isWithoutFutureAppointment?: boolean;
  @serializable(alias("patient_category", primitive()))
  patientCategory?: PatientCategory;

  @serializable(alias("page_index", primitive()))
  page?: number;
  @serializable(alias("page_size", primitive()))
  pageSize?: number;
  @serializable(alias("issue_raised_by", primitive()))
  issueCategory?: IssueCategory;
  @serializable(alias("practice_id", primitive()))
  practiceId?: string;
  @serializable(alias("is_active", primitive()))
  isActive?: boolean;
}
