import React, { FC, useState, useEffect } from "react";
import "./contractDetailServices.scss";
import { Contract } from "../../../../../models/Contract/contract.model";
import { Row, Col, Table, Button, Modal } from "antd";
import moment from "moment";
import CorporateContainer from "../../../../../store/container/CorporateContainer";
import { CorporateReducerProps } from "../../../../../store/reducers/corporateReducer";
import ContractServices from "../../../../../services/Contract/Contract.service";
import { StatusEnum } from "../../../../../enums/status.enum";
import { ContractService } from "../../../../../models/ContractService/contractService.model";

import deleteIcon from "../../../../../assets/images/delete.png";
import reOpenIcon from "../../../../../assets/images/edit.png";
import { PaymentSchedule } from "../../../../../models/PaymentSchedule/paymentSchedule.model";
import { getNumber } from "shared/utils/getNumber";

interface ContractDetailServicesProps extends CorporateReducerProps {
  contract: Contract;
  contractId: number;
  contractStatus: string;
  handleContractSuccess: () => void;
}

const { confirm } = Modal;

const ContractDetailServices: FC<ContractDetailServicesProps> = (props) => {
  const {
    contract,
    contract: { contractService },
    practiceId,
    contractId,
    corporateId,
    contractStatus,
    handleContractSuccess,
  } = props;
  const [loading, setLoading] = useState(false);
  const [
    paymentScheduleList,
    setPaymentScheduleList,
  ] = useState<PaymentSchedule>();
  const [contractServiceId, setContractServiceId] = useState<number>();
  const [currentStatus, setCurrentStatus] = useState<string>();

  const columns = [
    {
      title: "Service name",
      dataIndex: "serviceName",
      key: "serviceName",
    },
    {
      title: "Treatment Execution Template",
      dataIndex: "treatmentPlanName",
      key: "treatmentPlanName",
    },
    {
      title: "Max no of times can be utilized",
      dataIndex: "maxNoToUtilise",
      key: "maxNoToUtilise",
    },
    {
      title: "Service period",
      dataIndex: "durationInMonths",
      key: "durationInMonths",
    },
    {
      title: "Cost Range",
      dataIndex: "serviceMinCost",
      key: "serviceMinCost",
      render: (text: string, record: any, index: number) => {
        return record?.serviceMinCost && record?.serviceMaxCost ? (
          <div>
            ${record?.serviceMinCost} - ${record?.serviceMaxCost}
          </div>
        ) : (
          "NA"
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status: string, service: ContractService) => (
        <span
          className={`text-capitalize ${
            status === StatusEnum.ACTIVE ? "success-text" : "text-danger"
          }`}
        >
          {status || "--"}
        </span>
      ),
    },
    {
      title: "",
      dataIndex: "id",
      key: "id",
      render: (status: string, service: ContractService) => (
        <React.Fragment>
          <div className="list-contract__icons ">
            <img
              className="contract-detail__btn-icon"
              src={
                contractStatus === "active"
                  ? service?.status === StatusEnum.ACTIVE
                    ? deleteIcon
                    : service?.status === StatusEnum.CLOSED || "null"
                    ? reOpenIcon
                    : ""
                  : ""
              }
              onClick={() => {
                confirm({
                  cancelText: "No",
                  okText: "Yes",
                  className: "complete-contract-modal",
                  content: (
                    <div>
                      <p>
                        {service?.status === StatusEnum.ACTIVE
                          ? "Mark this service as Completed ?"
                          : "Do you wish to open this service?"}
                      </p>
                    </div>
                  ),
                  onOk: () => {
                    completeContract(
                      service?.status === StatusEnum.ACTIVE
                        ? "closed"
                        : "active",
                      false,
                      service?.id ?? 0
                    );
                  },
                });
              }}
            />
          </div>
        </React.Fragment>
      ),
    },
  ];

  useEffect(() => {
    if (contract?.contractService?.status === "active") {
      setContractServiceId(contract?.contractService?.serviceId);
      setCurrentStatus(contract?.contractService?.status);
    }
    // contract?.contractServices.map((service) => {
    //   if (contract?.contractService?.status == "active") {
    //     setContractServiceId(contract?.contractService?.serviceId);
    //     setCurrentStatus(contract?.contractService?.status);
    //   }
    // });
  }, []);
  useEffect(() => {
    setLoading(true);
    ContractServices.getPaymentScheduleList(
      contractId,
      (paymentSchedule: PaymentSchedule[]) => {
        if (paymentSchedule.length > 0) {
          const length = paymentSchedule?.length - 1;
          const currentPayment = paymentSchedule[length];
          setPaymentScheduleList(currentPayment);
        }
      },
      () => {},
      () => {
        setLoading(false);
      }
    );
  }, []);
  const contractFields = [
    { label: "Contract name", value: contract.name },
    { label: "Total Visits", value: contract.noOfVisits },
    {
      label: "Total Fee",
      value: `$${getNumber(paymentScheduleList?.contractCost)}`,
    },
    {
      label: "Responsible doctor",
      value: `${contract.doctorFirstName} ${contract.doctorLastName}`,
    },
    { label: "Next visit on", value: "-" },
    {
      label: "Last modified at",
      value: contract.updatedAt
        ? moment(contract.updatedAt).format("MM/DD/YYYY")
        : "-",
    },
  ];
  const completeContract = (
    status: String,
    closeValue: Boolean,
    contractServiceId: number
    // contractId: string
  ) => {
    setLoading(true);

    ContractServices.completeContractService(
      corporateId ?? 0,
      contractId,
      contractServiceId ?? 0,
      { status: status, is_force_close: closeValue },

      () => {
        handleContractSuccess();
      },
      () => {
        confirm({
          className: "end-contract-modal",
          content: (
            <div>
              {` No appointments are created in this contract service so far. Do
                you wish to force end this contract service?`}
            </div>
          ),
          okText: "Yes",
          cancelText: "No",
          onOk: () => {
            if (contractServiceId)
              completeContract("closed", true, contractServiceId);
          },
        });
      },
      () => {
        setLoading(false);
      }
    );
  };

  return (
    <div className="contract-detail-services">
      <Row gutter={[10, 10]}>
        {contractFields.map((contractField, i: number) => (
          <Col
            span={(i + 1) % 3 === 0 ? 12 : 6}
            className="contract-detail-services__fields"
          >
            <div className="contract-detail-services__label">
              {contractField.label}
            </div>
            <div className="contract-detail-services__value">
              {contractField.value || "NA"}
            </div>
          </Col>
        ))}
      </Row>
      {contractService?.treatmentPlans?.length && (
        <div className="contract-detail-services__service-table">
          <Row>
            <Col span={24}>
              <h5 className="text-secondary">Added Services</h5>
            </Col>
            <Col span={8}>
              <div className="text-center contract-detail-services__service-table__title">
                Treatment Execution Plan
              </div>
            </Col>
            <Col span={8}>
              <div className="text-center contract-detail-services__service-table__title">
                Service Period
              </div>
            </Col>
            <Col span={8}>
              <div className="text-center contract-detail-services__service-table__title">
                No of appointments
              </div>
            </Col>
            {contractService?.treatmentPlans?.map((plan) => (
              <Row className="contract-detail-services__service-table__val">
                <Col span={8}>
                  <div className="text-center">
                    {plan?.noOfBouts} Bout Service
                  </div>
                </Col>
                <Col span={8}>
                  <div className="text-center">
                    {plan?.durationInMonths} months
                  </div>
                </Col>
                <Col span={8}>
                  <div className="text-center">
                    {plan?.noOfAppointments} Appointments
                  </div>
                </Col>
              </Row>
            ))}
            {/* 
          <Col span={12} className="text-right">
            <Button
              type="primary"
              onClick={() => {
                confirm({
                  cancelText: "No",
                  okText: "Yes",
                  className: "complete-contract__noteAdded",
                  content: (
                    <div>
                      <p>Do you wish to move to next service ?</p>
                      <div className="note">
                        <span className="text-danger">Note:</span>
                        <div>
                          You could select No to create new appointments in the
                          same service.
                        </div>
                      </div>
                    </div>
                  ),
                  onOk: () => {
                    completeContract("closed", false);
                  },
                });
              }}
            >
              Mark as Complete
            </Button>
          </Col> */}
          </Row>

          {/* <Table
          dataSource={contract.contractService}
          columns={columns}
          className="secondary-table"
        /> */}
        </div>
      )}
    </div>
  );
};

export default CorporateContainer(ContractDetailServices);
