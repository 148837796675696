import React from "react";
import "./responsiblePersonDetail.scss";
import { Row, Col } from "antd";
import { ResponsiblePerson } from "../../../models/ResponsiblePerson/responsiblePerson.model";
import moment from "moment";
import { capitalize } from "lodash";
interface ResponsiblePersonDetailProps {
  responsiblePerson: ResponsiblePerson;
}

function ResponsiblePersonDetail({
  responsiblePerson,
}: ResponsiblePersonDetailProps) {
  const responsiblePersonFields = [
    {
      label: "Name",
      value: responsiblePerson?.firstName,
    },
    {
      label: "Surname",
      value: responsiblePerson?.lastName,
    },
    {
      label: "Gender",
      value: responsiblePerson?.gender,
    },
    {
      label: "DOB",
      value: moment(responsiblePerson?.dob).format("MM/DD/YYYY"),
    },
    {
      label: "Email",
      value: responsiblePerson?.email,
    },
    {
      label: "Mobile",
      value: responsiblePerson?.mobileNumber,
    },
    {
      label: "Country",
      value: capitalize(responsiblePerson?.countryName),
    },
    {
      label: "Province",
      value: capitalize(responsiblePerson?.provinceName),
    },
    {
      label: "City",
      value: capitalize(responsiblePerson?.cityName),
    },
    {
      label: "Nationality",
      value: capitalize(responsiblePerson?.nationalityName),
    },
    {
      label: "Street",
      value: responsiblePerson?.street,
    },
    {
      label: "Building",
      value: responsiblePerson?.building,
    },
    {
      label: "Postal code",
      value: responsiblePerson?.zipcode,
    },
  ];
  return (
    <div className="responsible-person-detail">
      <Row gutter={[20, 20]}>
        {responsiblePersonFields.map((field, i) => (
          <Col span={8} key={i}>
            <label className="responsible-person-detail__label">
              {field?.label}
            </label>
            <div className="responsible-person-detail__value">
              {field?.value || "NA"}
            </div>
          </Col>
        ))}
      </Row>
    </div>
  );
}

export default ResponsiblePersonDetail;
