import React, { useEffect, useState } from "react";
import { Row, Col, Button } from "antd";
import "./globalNoteTemplateForm.scss";
import { CorporateLink } from "../../../../../models/CorporateLink/corporateLink.model";
import { NoteTemplate } from "../../../../../models/NoteTemplate/noteTemplate.model";
import {
  IDropdownOptions,
  MetaService,
} from "../../../../../services/Meta/meta.service";
import { Form, Formik, FormikValues } from "formik";
import { getDropdownOptions } from "../../../../../shared/utils/optionHelper";
import RadioInputField from "../../../../../shared/components/RadioInputField";
import CorporateLinkService from "../../../../../services/CorporateLink/corporateLink.service";
import globalTemplateBanner from "../../../../../assets/images/globalNoteTemplateBanner.png";

interface GlobalNoteTemplateFormProps {
  corporateLink: CorporateLink;
  onSuccess: (corporateLink: CorporateLink) => void;
  onCancel: () => void;
}

function GlobalNoteTemplateForm({
  corporateLink,
  onSuccess,
  onCancel,
}: GlobalNoteTemplateFormProps) {
  const [noteTemplateOptions, setNoteTemplateOptions] = useState<
    IDropdownOptions[]
  >([]);

  const [formLoading, setFormLoading] = useState(false);

  const [formValues] = useState(corporateLink);

  const handleSubmit = (values: FormikValues) => {
    const corporateLink = Object.assign(new CorporateLink(), values);
    setFormLoading(true);
    CorporateLinkService.updateCorporateLink(
      corporateLink,
      (corporateLink: CorporateLink) => {
        onSuccess(corporateLink);
      },
      () => {},
      () => {
        setFormLoading(false);
      }
    );
  };

  useEffect(() => {
    MetaService.fetchGlobalNoteTemplates(
      (noteTemplates: NoteTemplate[]) => {
        setNoteTemplateOptions(
          getDropdownOptions(noteTemplates, "noteSample", "id")
        );
      },
      () => {},
      () => {}
    );
  }, []);

  return (
    <div className="global-note-template-form">
      <div>
        <Formik
          initialValues={formValues}
          onSubmit={handleSubmit}
          enableReinitialize
          validationSchema={undefined}
        >
          {({ values, isValid, dirty, setFieldValue }) => {
            return (
              <Form>
                <div className="global-note-template-form__body">
                  <Row gutter={[20, 0]}>
                    <Col span={5}>
                      <div>
                        <img
                          src={globalTemplateBanner}
                          alt="Global Note template Banner"
                          className="global-note-template-form__banner"
                        />
                      </div>
                    </Col>
                    <Col
                      span={17}
                      className="global-note-template-form__note-template-fields"
                    >
                      <h5 className="text-secondary">
                        Customizing Note Templates
                      </h5>
                      <p>
                        This will be used to capture the actions done during
                        treatment interaction
                      </p>
                      <RadioInputField
                        value={values.noteTemplateId}
                        name="noteTemplateId"
                        setFieldValue={setFieldValue}
                        options={noteTemplateOptions}
                      />
                    </Col>
                  </Row>
                </div>
                <div className="global-note-template-form__footer">
                  <Button onClick={onCancel} type="default">
                    Cancel
                  </Button>
                  <Button
                    type="primary"
                    className="ml-2"
                    htmlType="submit"
                    loading={formLoading}
                    disabled={!values.noteTemplateId || !dirty || formLoading}
                  >
                    Save
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}

export default GlobalNoteTemplateForm;
