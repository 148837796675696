import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";
import * as SetActions from "../actions/setActions";
import { RootReducerProps } from "../reducers";

const mapStateToProps = (state: RootReducerProps, ownProps: any) => {
  return {
    ...ownProps,
    currentSet: state.set.currentSet,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(SetActions, dispatch);

const SetContainer = (component: any) => {
  return connect(mapStateToProps, mapDispatchToProps)(component);
};

export default SetContainer;
