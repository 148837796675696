import React, { useEffect, useState } from "react";

import { Patient } from "../../../models/Patient/patient.model";
import { Button, Drawer, Table } from "antd";
import moment from "moment";
import { useHistory, generatePath } from "react-router-dom";
import AppRoutes from "../../../routes/routeConstants/appRoutes";
import AuthContainer from "../../../store/container/AuthContainer";
import { AuthReducerProps } from "../../../store/reducers/authReducer";
import { ResponsibilityTypes } from "../../../enums/responsebily.enum";
import Stats from "../../../shared/components/Stats";
import { StatsType } from "../../../enums/statsType.enum";
import ContractFilterForm from "../../Services/Contracts/ContractFilterForm";
import { MenuOutlined } from "@ant-design/icons";
import { useMetrics } from "../../../shared/hooks/Metrics/useMetrics";
import PatientService from "../../../services/Patient/patient.service";
import SupportService from "../../../services/Support/support.service";
import { Support as SupportModel } from "../../../models/Support/support.model";
import { StatusEnum } from "../../../enums/status.enum";
import { useParams } from "react-router-dom";

interface TicketsProps extends AuthReducerProps {
  patients: Patient[];
}

function Tickets({ patients, userResponsibilities }: TicketsProps) {
  const [loading, setLoading] = useState(false);
  const params = useParams<{ patientId: string }>();

  const [supportTickets, setSupportTickets] = useState<SupportModel[]>([]);

  useEffect(() => {
    setLoading(true);
    SupportService.fetchPatientSubscription(
      params.patientId,
      (supports) => {
        setSupportTickets(supports);
      },
      () => {
        setLoading(false);
      }
    );
  }, []);

  const columns = [
    {
      title: "Issue Type",
      dataIndex: "issueType",
      key: "issueType",
    },
    {
      title: "Issue Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Raised Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date: string) => moment(date).format("MM-DD-YYYY"),
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status: string, support: SupportModel) => (
        <span
          className={`text-capitalize ${
            status === StatusEnum.ACTIVE
              ? "success-text"
              : status === StatusEnum.INACTIVE
              ? "text-danger"
              : "text-warning"
          }`}
        >
          {status || "--"}
        </span>
      ),
    },
  ];

  return (
    <div className="patient-table">
      <Table dataSource={supportTickets} columns={columns} loading={loading} />
    </div>
  );
}

export default AuthContainer(Tickets);
