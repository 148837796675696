import React, { Fragment, useState } from "react";
import "./treatmentSummary.scss";
import { Button, Col, Row, Tabs, Modal } from "antd";
import TreatmentSummaryOverview from "./TreatmentSummaryOverview";
import TreatmentSummaryNotes from "./TreatmentSummaryNotes";
import TreatmentSummaryStatementNotes from "./TreatmentSummaryStatementNotes";
import TreatmentSummaryXRayNotes from "./TreatmentSummaryXRayNotes";
import TreatmentSummaryObjectNotes from "./TreatmentSummaryObjectNotes";
import TreatmentSummaryCommentNotes from "./TreatmentSummaryCommentNotes";
import { Interaction } from "../../../models/Interaction/interaction.model";
import Checkbox, { CheckboxChangeEvent } from "antd/lib/checkbox/Checkbox";
import { InteractionService } from "../../../services/Interaction/Interaction.service";
import { useHistory } from "react-router";
import AppRoutes from "../../../routes/routeConstants/appRoutes";
import { WorkItemService } from "../../../services/WorkItem/workItem.service";
import AuthContainer from "../../../store/container/AuthContainer";
import { AuthReducerProps } from "../../../store/reducers/authReducer";
import { ResponsibilityTypes } from "../../../enums/responsebily.enum";
import "../../Services/Contracts/ListContract/listContract.scss";
import CorporateContainer from "../../../store/container/CorporateContainer";
import { CorporateReducerProps } from "../../../store/reducers/corporateReducer";
import ContractService from "../../../services/Contract/Contract.service";
import useResponsibilities from "../../../shared/hooks/Responsibilities/useResponsibilities";
import { MeshType } from "../../../enums/meshType.enum";
const { TabPane } = Tabs;
const { confirm } = Modal;

interface TreatmentSummaryProps
  extends AuthReducerProps,
    CorporateReducerProps {
  interactionId: number;
  completeInteraction?: boolean;
  workItemId?: string;
  isVisit?: boolean;
  closeHandler?: () => void;
}

interface StopInteraction {
  success?: boolean;
  is_last_appointment?: boolean;
}

function TreatmentSummary({
  interactionId,
  completeInteraction,
  workItemId,
  closeHandler,
  corporateId,
  isVisit,
}: TreatmentSummaryProps) {
  const { hasAccess } = useResponsibilities();

  const HAS_STOP_INTERACTION_CREATE_ACCESS = hasAccess(
    ResponsibilityTypes.STOP_INTERACTION_CREATE
  );

  const summaryTabDetails = [
    {
      title: "Summary",
      component: (
        <TreatmentSummaryOverview
          interactionId={interactionId}
          handleContractService={(serviceId: string, contractId: string) => {
            setcontractServiceId(serviceId);
            setcontractId(contractId);
          }}
        />
      ),
    },
    {
      title: "Notes",
      component: <TreatmentSummaryNotes interactionId={interactionId} />,
    },
    {
      title: "Statement Notes",
      component: (
        <TreatmentSummaryStatementNotes interactionId={interactionId} />
      ),
    },
    {
      title: "Comment Notes",
      component: <TreatmentSummaryCommentNotes interactionId={interactionId} />,
    },
    {
      title: "X-Ray Notes",
      component: <TreatmentSummaryXRayNotes interactionId={interactionId} />,
    },
    {
      title: "Object Notes",
      component: <TreatmentSummaryObjectNotes interactionId={interactionId} />,
    },
  ];

  const [contractServiceId, setcontractServiceId] = useState<string>();
  const [contractId, setcontractId] = useState<string>();
  const [reviewLater, setReviewLater] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const handleReviewLater = ({ target: { checked } }: CheckboxChangeEvent) => {
    setReviewLater(checked);
  };

  const completeContract = (
    status: String,
    closeValue: Boolean,
    contractServiceId: string,
    isLastAppointment: boolean
  ) => {
    setLoading(true);
    if (contractId && contractServiceId) {
      ContractService.completeContractService(
        corporateId ?? 0,
        Number(contractId),
        Number(contractServiceId),
        { status: status, is_force_close: closeValue },

        () => {
          history.push({
            pathname: AppRoutes.DASHBOARD,
            state: {
              workItem: true,
            },
          });
        },
        () => {},
        () => {
          setLoading(false);
        }
      );
    }
  };

  const handleStopInteraction = () => {
    setLoading(true);
    InteractionService.stopInteraction(
      interactionId.toString(),
      reviewLater,
      (response: StopInteraction) => {
        if (response?.is_last_appointment) {
          confirm({
            cancelText: "No",
            okText: "Yes",
            className: "complete-contract__noteAdded",
            content: (
              <div>
                <p>
                  All the planned appointments from this service is completed.
                  Do you wish to move to next service ?
                </p>
                <div className="note">
                  <span className="text-danger">Note:</span>
                  <div>
                    You could select No to create new appointments in the same
                    service.
                  </div>
                </div>
              </div>
            ),
            onOk: () => {
              if (response?.is_last_appointment) {
                completeContract(
                  "closed",
                  false,
                  contractServiceId ?? "",
                  response?.is_last_appointment
                );
              }
            },
          });
        } else
          history.push({
            pathname: AppRoutes.DASHBOARD,
            state: {
              workItem: true,
            },
          });
      },
      () => {},
      () => {
        setLoading(false);
      }
    );
  };

  const handleCompleteReview = () => {
    if (workItemId) {
      WorkItemService.completeWorkItem(
        workItemId as string,
        () => {
          closeHandler && closeHandler();
        },
        () => {},
        () => {}
      );
    }
  };

  return (
    <div className="treatment-summary">
      <Tabs
        tabPosition={isVisit ? "left" : "top"}
        defaultActiveKey="0"
        size="small"
        className={`${isVisit && "treatment-summary__tab"}`}
        style={{ marginBottom: 32 }}
      >
        {summaryTabDetails.map((summaryTabDetail, i) => (
          <>
            <TabPane tab={summaryTabDetail.title} key={i}>
              {summaryTabDetail.component}
            </TabPane>
          </>
        ))}
      </Tabs>
      {completeInteraction ? (
        <div className="interaction-summary__controller">
          <Button type="text">Cancel</Button>
          <Button
            type="primary"
            onClick={handleCompleteReview}
            loading={loading}
          >
            Complete Review
          </Button>
        </div>
      ) : (
        <Fragment>
          {!isVisit && (
            <Checkbox checked={reviewLater} onChange={handleReviewLater}>
              Review Later
            </Checkbox>
          )}
          <Checkbox
            className={`check-list__warning-note ${
              !reviewLater && "visibility-hidden"
            }`}
          >
            Note: Within 24hours automatically it will closed.
          </Checkbox>
          {!isVisit && (
            <div className="interaction-summary__controller">
              <Button type="text">Cancel</Button>
              {HAS_STOP_INTERACTION_CREATE_ACCESS && (
                <Button
                  type="primary"
                  onClick={handleStopInteraction}
                  loading={loading}
                >
                  Stop Interaction
                </Button>
              )}
            </div>
          )}
        </Fragment>
      )}
    </div>
  );
}

export default AuthContainer(CorporateContainer(TreatmentSummary));
