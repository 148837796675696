import React, { useEffect, useState } from "react";
import { Row, Col } from "antd";
import "./treatmentSummaryOverview.scss";
import { Interaction } from "../../../../models/Interaction/interaction.model";
import { InteractionService } from "../../../../services/Interaction/Interaction.service";
import AppLoader from "../../../../shared/components/AppLoader";
import InteractionStepService from "../../../../services/InteractionStep/interactionStep.service";
import CustomBabylonScene from "../../../../shared/components/CustomBabylonScene";
import useToggle from "../../../../shared/hooks/useToggle/useToggler";
import { Scene } from "@babylonjs/core";
import { integrateInteractions } from "../../../../shared/utils/integrateTreatmentSteps";
import BabylonContainer from "../../../../store/container/BabylonContainer";
import { BabylonReducerProps } from "../../../../store/reducers/babylonReducer";
import useResponsibilities from "../../../../shared/hooks/Responsibilities/useResponsibilities";
import { ResponsibilityTypes } from "../../../../enums/responsebily.enum";
import { getMeshType } from "../../../../shared/utils/patientCategories";
import { MeshType } from "../../../../enums/meshType.enum";
import { removeUnderscore } from "shared/utils/removeUnderscore";

interface TreatmentSummaryOverviewProps extends BabylonReducerProps {
  interactionId: number;
  handleContractService: (
    contractServiceId: string,
    contractId: string
  ) => void;
}

function TreatmentSummaryOverview({
  interactionId,
  handleContractService,
  corporateObjects,
  caps,
  setPopoverPosition,
  setShowPopover,
  setSourceObject,
  capsLoading,
}: TreatmentSummaryOverviewProps) {
  const { hasAccess } = useResponsibilities();

  const [contractInteractions, setContractInteractions] = useState<
    Interaction[]
  >([]);

  const [meshType, setMeshType] = useState<MeshType>();

  const [loading, setLoading] = useState(false);
  const [scene, setScene] = useState<Scene>();

  const loadMeshToScene =
    !!meshType &&
    !!interactionId &&
    !!contractInteractions?.length &&
    !!caps &&
    !capsLoading;

  const { toggle: meshLoading, updateToggle: setMeshLoading } = useToggle({
    initialToggleState: true,
  });

  const HAS_INTERACTION_VIEW_ACCESS = hasAccess(
    ResponsibilityTypes.INTERACTION_VIEW
  );

  useEffect(() => {
    if (interactionId && HAS_INTERACTION_VIEW_ACCESS) {
      setLoading(true);
      InteractionService.showInteraction(
        interactionId,
        (interaction: Interaction) => {
          setInteraction(interaction);
          setMeshType(getMeshType(interaction?.patientCategory));
          handleContractService(
            interaction?.contractServiceId ?? "",
            interaction?.contractId ?? ""
          );
        },
        () => {},
        () => {
          setLoading(false);
        }
      );
      InteractionStepService.fetchContractInteractions(
        Number(interactionId),
        (contractInteractions: Interaction[]) => {
          setContractInteractions(contractInteractions);
        },
        () => {},
        () => {}
      );
    }
  }, [interactionId]);

  const [interaction, setInteraction] = useState<Interaction>();

  const interactionDetails = [
    { label: "Date", value: interaction?.date },
    {
      label: "Visit Type",
      value: removeUnderscore(interaction?.visitType ?? "--"),
    },
    { label: "Doctor", value: interaction?.getDoctorFullName() },
    { label: "Assistant", value: "" },
    {
      label: "Oral Hygiene",
      value: (
        <div>
          {[...Array(5)].map((x, i) => (
            <span
              className={`treatment-summary-overview__rate-icons ${
                interaction?.oralHygiene && interaction?.oralHygiene >= i + 1
                  ? "active"
                  : ""
              }`}
            >
              <i className={"icon-oral-hygine"} />
            </span>
          ))}
        </div>
      ),
    },
  ];

  const handleLoadedMesh = async () => {
    if (!scene || !loadMeshToScene) return;

    try {
      setMeshLoading?.(true);
      await integrateInteractions({
        activeInteractionId: interactionId,
        caps,
        contractInteractions,
        corporateObjects,
        scene,
        setPopoverPosition,
        setShowPopover,
        setSourceObject,
      });
    } catch (ex) {
    } finally {
      setMeshLoading?.(false);
    }
  };

  return (
    <div className="treatment-summary-overview">
      {loading ? (
        <AppLoader loading={loading} />
      ) : (
        <Row gutter={[20, 0]}>
          <Col span={6}>
            {interactionDetails?.map((interactionDetail, i) => (
              <div className="treatment-summary-overview__field" key={i}>
                <div className="treatment-summary-overview__label">
                  {interactionDetail?.label}
                </div>
                <div className="treatment-summary-overview__value">
                  {interactionDetail?.value}
                </div>
              </div>
            ))}
          </Col>
          <Col span={18}>
            <CustomBabylonScene
              loadMeshToScene={loadMeshToScene}
              meshType={meshType}
              onMeshLoad={handleLoadedMesh}
              customScene={{ scene, setScene, meshLoading, setMeshLoading }}
            />
          </Col>
        </Row>
      )}
    </div>
  );
}

export default BabylonContainer(TreatmentSummaryOverview);
