import React, { FC } from "react";
import { WorkItem as WorkItemModel } from "../../../../../models/WorkItem/workItem.model";
import UserPlaceHolder from "../../../../../assets/images/userPlaceholder.png";
import "./workItem.scss";
import { Button } from "antd";
import { timeDifference } from "../../../../utils/time";
import moment from "moment";
import { Interaction } from "../../../../../models/Interaction/interaction.model";
import { Notification } from "../../../../../models/Notification/notification.model";
import { SIGNAL_R_NOTIFICATION } from "../../../../../enums/signalRNotification.enum";
import { useHistory } from "react-router-dom";
import AppRoutes from "../../../../../routes/routeConstants/appRoutes";

const mockTime = moment().subtract("minute", 5).toISOString();

interface WorkItemProps {
  workItem: Notification;
}

const WorkItem: FC<WorkItemProps> = (props) => {
  const { workItem } = props;

  const history = useHistory();

  const handleWorkItemClick = () => {
    switch (workItem?.type) {
      case SIGNAL_R_NOTIFICATION.INTERACTION:
        return history.push({
          pathname: AppRoutes.DASHBOARD,
          state: {
            workItem: true,
          },
        });

      case SIGNAL_R_NOTIFICATION.REVIEW_NOTES:
        return history.push({
          pathname: AppRoutes.DASHBOARD,
          state: {
            workItem: true,
            interactionId: workItem?.jsonData?.interactionId,
            workItemId: workItem?.jsonData?.workItemId,
          },
        });
      default:
        return;
    }
  };

  return (
    <div className="work-item">
      <div className="time">
        <div className="dot" />
        {timeDifference(moment(), moment(workItem?.createdAt))}
      </div>
      <div className="view-content">
        <div className="img-wrapper">
          <img src={UserPlaceHolder} />
        </div>
        <div className="content">
          <p className="name">{workItem?.title}</p>
          <p className="message">{workItem?.content}</p>
        </div>
      </div>
      <div className="view-details__wrapper">
        <Button className="secondary-btn" onClick={handleWorkItemClick}>
          View Details
        </Button>
      </div>
    </div>
  );
};

export default WorkItem;
