
import {serializable, alias, object, list, primitive} from 'serializr';
import { compareStringByIgnoringCase } from '../../shared/utils/helpers';
import { InteractionStatus } from '../../enums/interactionStatus.enum';

export class InteractionSubStatuse { 

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('name', primitive()))
	name?: string;

	@serializable(alias('is_default', primitive()))
	isDefault?: boolean = false;

	@serializable(alias('order_no', primitive()))
	order?: number;

	@serializable(alias('color_code', primitive()))
	colorCode?: string;

	static filterInitialAndCompletionStatus = (statuses: InteractionSubStatuse[]) =>
		statuses?.filter(
		({ name }) =>
			!compareStringByIgnoringCase(name, InteractionStatus.INTERACTION_STARTED) &&
			!compareStringByIgnoringCase(name, InteractionStatus.INTERACTION_COMPLETED)
		);
}