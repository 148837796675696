import { serializable, alias, object, list, primitive } from "serializr";
import { Appointment } from "../Appointment/appointment.model";

export class AppointmentTemplateSchedules {
  @serializable(alias("start_time", primitive()))
  startTime?: string;
  @serializable(alias("appointment_template_color_code", primitive()))
  colorCode?: string;

  @serializable(alias("end_time", primitive()))
  endTime?: string;

  @serializable(alias("appointment_type_id", primitive()))
  appointmentTypeId?: number;

  @serializable(alias("appointment_type", primitive()))
  appointmentType?: string;

  @serializable(alias("appointment_type_color_code", primitive()))
  appointmentTypeColorCode?: string;

  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("appointment_template_name", primitive()))
  appointmentTemplateName?: string;

  @serializable(alias("appointment_template_id", primitive()))
  appointmentTemplateId?: number;

  @serializable(alias("date", primitive()))
  date?: string;

  @serializable(alias("resource_id", primitive()))
  resourceId?: string;

  @serializable(alias("resource_name", primitive()))
  resourceName?: string;

  @serializable(alias("doctors_available", primitive()))
  doctorsAvailable?: number;

  @serializable(alias("assistants_available", primitive()))
  assistantsAvailable?: number;
}
