import React, { FC, Fragment, useEffect, useState } from "react";
import { Appointment } from "../../../../models/Appointment/appointment.model";
import { AppointmentService } from "../../../../services/Appointment/appointment.service";
import moment from "moment";
import UserPlaceholder from "../../../../assets/images/userPlaceholder.png";
import { Button, Col, Drawer, Row, Select, Table } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import "./onGoingAppointment.scss";
import InteractionForm from "./InteractionForm";
import { ResponsibilityTypes } from "../../../../enums/responsebily.enum";
import useResponsibilities from "../../../../shared/hooks/Responsibilities/useResponsibilities";
import { useInteractionStatus } from "shared/hooks/useInteractionStatus/useInteractionStatus";
import { InteractionStatus } from "models/InteractionStatuse/interactionStatuse.model";
import { StatusType } from "enums/statusType.enum";
import { ColumnsType } from "antd/lib/table";

interface OnGoingAppointmentsProps {
  appointment: Appointment;
  closeHandler: () => void;
  onAppointmentFetch?: () => void;
}

const OnGoingAppointments: FC<OnGoingAppointmentsProps> = (props) => {
  const { appointment, closeHandler, onAppointmentFetch } = props;

  const { hasAccess } = useResponsibilities();

  const [statusLoading, setStatusLoading] = useState<{
    [x: string]: boolean;
  }>({});

  const HAS_INTERACTION_CREATE_ACCESS = hasAccess(
    ResponsibilityTypes.INTERACTION_CREATE
  );

  const HAS_APPOINTMENT_EDIT_ACCESS = hasAccess(
    ResponsibilityTypes.APPOINTMENT_EDIT
  );

  const {
    statuses,
    loading: statusesLoading,
    getOptions,
  } = useInteractionStatus();

  const scheduledVisitStatus = getOptions(
    InteractionStatus.getTypeSpecificStatus(
      statuses,
      StatusType.VISIT_SCHEDULED
    )?.interactionSubStatuses
  );

  const completedVisitStatus = getOptions(
    InteractionStatus.getTypeSpecificStatus(statuses, StatusType.VISIT_COMPLETE)
      ?.interactionSubStatuses
  );

  const columns: ColumnsType<Appointment> = [
    {
      title: "Patient",
      dataIndex: "patient",
      key: "patientFirstName",
      ellipsis: true,
      render: (_: string, { patientFirstName, patientLastName }: Appointment) =>
        patientFirstName + " " + patientLastName,
      width: "12%",
    },
    {
      title: "Doctor",
      dataIndex: "doctorFirstName",
      key: "doctorFirstName",
      render: (
        _: string,
        { doctorFirstName, doctorLastName, doctorProfilePicUrl }: Appointment
      ) => {
        return (
          <Row className="user-profile__wrapper" gutter={[10, 10]}>
            <Col span={8}>
              <img src={doctorProfilePicUrl ?? UserPlaceholder} alt="" />
            </Col>
            <Col span={16}>{`${doctorFirstName ?? "-"} ${
              doctorLastName ?? "-"
            }`}</Col>
          </Row>
        );
      },
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      ellipsis: true,
      width: "10%",
      render: (date: string) => moment(date).format("MM/DD/YYYY"),
    },
    {
      title: "Time",
      dataIndex: "startTime",
      key: "startTime",
      ellipsis: true,
      width: "15%",
      render: (_: string, appointment: Appointment) => {
        return `${moment(appointment.startTime).format("hh:mm a")} - ${moment(
          appointment.endTime
        ).format("hh:mm a")}`;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      ellipsis: true,
      width: "17%",
      render: (status: string, appointment, index) => {
        const IS_PAST_APPOINTMENT = moment
          .utc(appointment?.date)
          .isBefore(moment.utc(), "date");

        const IS_FUTURE_APPOINTMENT = moment
          .utc(appointment?.date)
          .isAfter(moment.utc(), "date");

        const IS_DROPDOWN_DISABLED =
          !HAS_APPOINTMENT_EDIT_ACCESS ||
          IS_PAST_APPOINTMENT ||
          IS_FUTURE_APPOINTMENT;

        const options = Appointment.isAppointmentInteractionCompleted(
          appointment
        )
          ? completedVisitStatus
          : scheduledVisitStatus;

        return IS_DROPDOWN_DISABLED ? (
          status
        ) : (
          <Select
            dropdownMatchSelectWidth
            placeholder="select visit status"
            defaultValue={status}
            value={status}
            className="visit-status__dropdown"
            options={options}
            loading={statusesLoading || statusLoading[index]}
            dropdownClassName="visit-status__dropdown__items"
            onClick={(event) => {
              event.stopPropagation();
            }}
            disabled={IS_DROPDOWN_DISABLED}
            onChange={(status) => {
              setStatusLoading({
                [index]: true,
              });
              const updatedAppointment = Object.assign(new Appointment(), {
                ...appointment,
                status,
              });
              AppointmentService.updateAppointment(
                updatedAppointment,
                (appointment) => {
                  setCurrentAppointments((appointments) =>
                    Appointment.updateAppointment(appointments, appointment)
                  );
                  setPastAppointments((appointments) =>
                    Appointment.updateAppointment(appointments, appointment)
                  );
                },
                () => {},
                () => {
                  setStatusLoading({ [index]: false });
                }
              );
            }}
          />
        );
      },
    },
    {
      title: "Notes",
      dataIndex: "notes",
      key: "notes",
      width: "15%",
    },
  ];
  const PastColumns = [
    ...columns,
    {
      title: "",
      dataIndex: "",
      key: "",
      width: "19%",
    },
  ];
  const currentColumns = [
    ...columns,
    {
      title: "",
      dataIndex: "",
      key: "",
      width: "19%",
      render: (_: any, appointment: Appointment) => {
        const IS_PAST_APPOINTMENT = moment
          .utc(appointment?.date)
          .isBefore(moment.utc(), "date");

        const IS_FUTURE_APPOINTMENT = moment
          .utc(appointment?.date)
          .isAfter(moment.utc(), "date");

        if (
          !HAS_INTERACTION_CREATE_ACCESS ||
          IS_FUTURE_APPOINTMENT ||
          IS_PAST_APPOINTMENT
        )
          return;

        const handleAddInteraction = () => {
          // closeHandler();
          setSelectedAppointment(appointment);
          setInteractionFormVisibility(true);
        };
        return (
          <Button
            className="secondary-btn interaction-btn"
            onClick={handleAddInteraction}
          >
            <PlusOutlined /> Add Interaction
          </Button>
        );
      },
    },
  ];
  const [futureAppointments, setFutureAppointments] = useState<Appointment[]>(
    []
  );

  const [currentAppointments, setCurrentAppointments] = useState<Appointment[]>(
    []
  );

  const [interactionFormVisibility, setInteractionFormVisibility] = useState(
    false
  );

  const [pastAppointments, setPastAppointments] = useState<Appointment[]>([]);

  const [selectedAppointment, setSelectedAppointment] = useState<Appointment>();

  const appointments = [
    {
      option: {
        // start_date: moment().format("YYYY-MM-DD"),
        // start_time: moment().format("YYYY-MM-DD HH:mm:ss.SSS"),
        status: "upcoming",
        is_current_included: false,
        page_size: 1,
        page_index: 1,
      },
      setAppointments: setFutureAppointments,
    },
    {
      option: {
        status: "in_progress",
        is_current_included: true,
      },
      setAppointments: setCurrentAppointments,
    },
    {
      option: {
        // end_date: moment().format("YYYY-MM-DD"),
        // end_time: moment().format("YYYY-MM-DD HH:mm:ss.SSS"),
        status: "completed",
        is_current_included: false,
        page_size: 3,
        page_index: 1,
      },
      setAppointments: setPastAppointments,
    },
  ];

  useEffect(() => {
    appointments.forEach(({ option, setAppointments }) => {
      AppointmentService.fetchAppointment(
        { ...option, patientProfileId: appointment.patientProfileId },
        (appointments) => {
          setAppointments(appointments);
        },
        () => {},
        () => {}
      );
    });
  }, []);

  const handleCloseInteractionForm = () => {
    setInteractionFormVisibility(false);
    setSelectedAppointment(undefined);
    closeHandler();
  };

  return (
    <div className="ongoing-appointments__container">
      {futureAppointments?.length ? (
        <Table
          dataSource={futureAppointments}
          columns={currentColumns}
          pagination={false}
        />
      ) : null}
      {currentAppointments?.length ? (
        <Table
          dataSource={currentAppointments}
          columns={PastColumns}
          className="table-no-title"
          pagination={false}
        />
      ) : null}
      {pastAppointments?.length ? (
        <Fragment>
          <h4>Previous Appointments</h4>
          <Table
            dataSource={pastAppointments}
            columns={PastColumns}
            className="table-no-title"
            pagination={false}
          />
        </Fragment>
      ) : null}

      <Drawer
        width="75vw"
        title="Add Interaction"
        visible={interactionFormVisibility}
        onClose={handleCloseInteractionForm}
        destroyOnClose
      >
        <InteractionForm
          appointment={selectedAppointment ?? new Appointment()}
          onCloseHandler={handleCloseInteractionForm}
          onFetchAppointment={onAppointmentFetch}
        />
      </Drawer>
    </div>
  );
};

export default OnGoingAppointments;
