import React, { FC, useState } from "react";
import { Button, Col, Row } from "antd";
import { Formik, Form } from "formik";
import { PatientPreDeterminationForm } from "models/PatientPreDeterminationForm/patientPreDeterminationForm.model";
import InputField from "shared/components/InputField";
import RadioInputField from "shared/components/RadioInputField";
import { timePeriodOptions } from "shared/options/timePeriod";
import { preDeterminationFormValidation } from "./preDeterminationFormValidation";
import PatientService from "services/Patient/patient.service";
import { TimePeriodEnum } from "enums/timePeriodType.enum";
import saveAs from "file-saver";
import { Patient } from "models/Patient/patient.model";

interface PreDeterminationFormProps {
  patient: Patient;
  closeHandler: () => void;
}

const PreDeterminationForm: FC<PreDeterminationFormProps> = (props) => {
  const { closeHandler, patient } = props;
  const [formLoading, setFormLoading] = useState(false);

  const handleSubmit = (values: PatientPreDeterminationForm) => {
    const formValues = Object.assign(new PatientPreDeterminationForm(), {
      ...values,
      isMonthly: values.period === TimePeriodEnum.MONTHLY,
      isQuarterly: values.period === TimePeriodEnum.QUATERLY,
    });

    setFormLoading(true);
    PatientService.downloadPreDeterminationForm(
      String(patient?.id),
      formValues,
      (url: string) => {
        saveAs(url, patient?.getFullName() + " PreDetermination Form");
        closeHandler();
      },
      () => {},
      () => {
        setFormLoading(false);
      }
    );
  };

  return (
    <div className="pre-determination-form">
      <Formik
        initialValues={new PatientPreDeterminationForm()}
        onSubmit={handleSubmit}
        validationSchema={preDeterminationFormValidation}
        enableReinitialize
      >
        {({ values, setFieldValue, isValid, dirty }) => {
          return (
            <Form>
              <div className="pre-determination-form__wrapper">
                <Row gutter={[20, 0]}>
                  <Col span={12}>
                    <InputField
                      title="Insurance Name"
                      name="insuranceName"
                      type="text"
                    />
                  </Col>
                  <Col span={12}>
                    <InputField
                      title="Subsciber name"
                      name="subscriberName"
                      type="text"
                    />
                  </Col>
                </Row>
                <Row gutter={[20, 0]}>
                  <Col span={12}>
                    <InputField title="Address" name="address" type="text" />
                  </Col>
                  <Col span={12}>
                    <InputField
                      title="Group Policy"
                      name="groupPolicy"
                      type="text"
                    />
                  </Col>
                </Row>
                <Row gutter={[20, 0]}>
                  <Col span={12}>
                    <InputField
                      title="Certificate Number"
                      name="certificateNumber"
                      type="text"
                    />
                  </Col>
                  <Col span={12}>
                    <InputField
                      title="Relationship"
                      name="relationshipToTheSubscriber"
                      type="text"
                    />
                  </Col>
                </Row>
                <Row gutter={[20, 0]}>
                  <Col span={12}>
                    <InputField
                      title="Initial Payment"
                      name="initialPayment"
                      type="number"
                    />
                  </Col>
                  <Col span={12} className="mt-5">
                    <div className="mt-5 ml-5">
                      {" "}
                      <RadioInputField
                        name="period"
                        value={values.period}
                        setFieldValue={setFieldValue}
                        options={timePeriodOptions}
                      />
                    </div>
                  </Col>
                </Row>
                <Row gutter={[20, 0]}>
                  <Col span={12}>
                    <InputField
                      title="No of Dues"
                      name="noOfDues"
                      type="number"
                    />
                  </Col>
                  <Col span={12}>
                    <InputField
                      title="Due Amount"
                      name="dueAmount"
                      type="number"
                    />
                  </Col>
                </Row>
              </div>
              <div className="pre-determination-form__submit-wrapper text-right">
                <Button type="default" onClick={closeHandler}>
                  Cancel
                </Button>
                <Button
                  type="primary"
                  className="ml-2"
                  htmlType="submit"
                  loading={formLoading}
                  disabled={!isValid || !dirty || formLoading}
                >
                  Download
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default PreDeterminationForm;
