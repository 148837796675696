import React, { useEffect, useState } from "react";
import "./setViewer.scss";
import { ActionManager } from "@babylonjs/core";
import "@babylonjs/loaders";
import { Popover } from "antd";
import SetStepForm from "../SetStepForm";
import { SetStep } from "../../../../../models/SetStep/setStep.model";
import SetContainer from "../../../../../store/container/SetContainer";
import { SetReducerProps } from "../../../../../store/reducers/setReducer";
import {
  getToothPositionId,
  handleTeethLeftClick,
  handleTeethRightClick,
} from "../../../../../shared/utils/babylonUtils";
import BabylonContainer from "../../../../../store/container/BabylonContainer";
import { BabylonReducerProps } from "../../../../../store/reducers/babylonReducer";
import ConflictManagement from "../../../../../shared/components/ConflictManagement";
import SetService from "../../../../../services/Set/set.service";
import { DentalSet } from "../../../../../models/DentalSet/dentalSet.model";
import { CONFLICT_TYPES } from "../../../../../enums/conflictType.enum";
import { CorporateObject } from "../../../../../models/CorporateObject/corporateObject.model";
import CustomBabylonScene from "../../../../../shared/components/CustomBabylonScene";
import { MeshTriggerHandler } from "../../../../../shared/Types/meshActions.type";
import useToggle from "../../../../../shared/hooks/useToggle/useToggler";
import { MeshType } from "../../../../../enums/meshType.enum";
import { updateTreatmentStep } from "../../../../../shared/utils/integrateTreatmentSteps";
import useResponsibilities from "../../../../../shared/hooks/Responsibilities/useResponsibilities";
import { ResponsibilityTypes } from "../../../../../enums/responsebily.enum";
import CorporateContainer from "../../../../../store/container/CorporateContainer";
import { CorporateReducerProps } from "../../../../../store/reducers/corporateReducer";
import { PatientCategory } from "../../../../../enums/patientCategory.enum";
import { getMeshType } from "../../../../../shared/utils/patientCategories";

interface SetViewerProps
  extends SetReducerProps,
    BabylonReducerProps,
    CorporateReducerProps {
  onAddSetStep: (setStep: SetStep) => void;
}

function SetViewer({
  currentSet,
  scene,
  caps,
  corporateObjects,
  highlightLayer,
  showPopover,
  setShowPopover,
  popoverPosition,
  setPopoverPosition,
  setSourceObject,
  resetBabylon,
  resetConflict,
  toothPositions,
  sourceObject,
  setCurrentSet,
  setMeshLoading,
  corporateObjectsLoading,
  capsLoading,
  practiceId,
}: SetViewerProps) {
  const { hasAccess } = useResponsibilities();

  const IS_ACTIVE_PRACTICE_SAME =
    Number(practiceId) === Number(currentSet?.practiceId);

  const HAS_SET_EDIT_ACCESS =
    hasAccess(ResponsibilityTypes.SET_EDIT) && IS_ACTIVE_PRACTICE_SAME;

  const handleAddStepSuccess = (setStep: SetStep) => {
    setShowPopover(false);
    setSourceObject(undefined);
  };
  const MESH_TYPE = currentSet ? getMeshType(currentSet?.patientCategory) : "";

  const isLoadMeshToScene =
    !!MESH_TYPE && !!scene && !!corporateObjects?.length && !!caps?.length && !corporateObjectsLoading && !capsLoading;

  const [openConflict, setOpenConflict] = useState(false);

  const handleMeshLeftClick: MeshTriggerHandler = (props) =>
    handleTeethLeftClick(props, corporateObjects, setSourceObject);

  const handleMeshRightClick: MeshTriggerHandler = (props) => {
    if (!HAS_SET_EDIT_ACCESS) return;

    handleTeethRightClick(
      props,
      corporateObjects,
      setSourceObject,
      setShowPopover,
      setPopoverPosition
    );
  };

  const handleLoadedMesh = async () => {
    if (!isLoadMeshToScene) return;

    try {
      setMeshLoading(true);
      scene?.meshes.forEach(({ name }) => {
        currentSet?.setSteps?.forEach((step) => {
          if (name !== step?.objectLink?.destObjectName) return;

          const tempDestAttachmentUrl = step?.objectLink?.destAttachmentUrl;
          step.objectLink.destAttachmentUrl =
            step?.objectLink?.srcAttachmentUrl;
          step.objectLink.srcAttachmentUrl = tempDestAttachmentUrl;
        });
      });
      for (let step of currentSet?.setSteps || [])
        await updateTreatmentStep({
          step,
          scene,
          corporateObjects,
          setSourceObject,
          setShowPopover,
          setPopoverPosition,
          caps,
        });
    } catch (ex) {
    } finally {
      setMeshLoading(false);
    }
  };

  useEffect(() => {
    setMeshLoading(true);
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
      resetBabylon();
    };
  }, []);

  const createSetStep = (setStep: SetStep, complete?: () => void) => {
    if (scene && (sourceObject as CorporateObject)?.name) {
      setStep.toothPositionId = getToothPositionId(
        scene,
        (sourceObject as CorporateObject)?.name ?? "",
        toothPositions
      );
    }
    if (currentSet && currentSet.id) {
      setMeshLoading(true);
      SetService.createSetStep(
        setStep,
        currentSet.id,
        async (setStep: SetStep) => {
          setMeshLoading(true);
          if (currentSet) {
            const set = Object.assign(new DentalSet(), {
              ...currentSet,
            });
            set.setSteps.unshift(setStep);
            setCurrentSet(set);
            handleAddStepSuccess(setStep);
            await updateTreatmentStep({
              caps,
              corporateObjects,
              isMeshPickable: false,
              scene,
              setPopoverPosition,
              setShowPopover,
              setSourceObject,
              step: setStep,
            });
            highlightLayer?.removeAllMeshes();
          }
        },
        () => {},
        () => {
          complete?.();
          setMeshLoading(false);
        }
      );
    }
  };

  const handleCloseConflict = () => {
    resetConflict();
    setOpenConflict(false);
  };

  const handleSubmitConflict = (data: any[]) => {
    data.map((setStep: any) => {
      if (setStep?.conflict_type === CONFLICT_TYPES.HARD_APPLY) {
        delete setStep.toothLink;
        delete setStep.conflict_type;
        const step = Object.assign(new SetStep(), setStep);
        createSetStep(step);
      }
    });
    handleCloseConflict();
  };

  return (
    <div className="set-viewer">
      <ConflictManagement
        visible={openConflict}
        onSubmit={handleSubmitConflict}
        onClose={handleCloseConflict}
      />
      <Popover
        overlayClassName="set-viewer__popover"
        content={
          showPopover && (
            <SetStepForm
              setOpenConflict={setOpenConflict}
              createSetStep={createSetStep}
            />
          )
        }
        visible={showPopover}
        arrowContent={null}
        overlayInnerStyle={{
          position: "fixed",
          top: `calc(${popoverPosition.y}px + 10rem)`,
          left: popoverPosition.x + "px",
        }}
      />
      <CustomBabylonScene
        actions={{
          [ActionManager.OnLeftPickTrigger]: handleMeshLeftClick,
          [ActionManager.OnRightPickTrigger]: handleMeshRightClick,
        }}
        loadMeshToScene={isLoadMeshToScene}
        meshType={MESH_TYPE}
        mouthSliderVisible
        onMeshLoad={handleLoadedMesh}
      />
    </div>
  );
}

export default BabylonContainer(CorporateContainer(SetContainer(SetViewer)));
