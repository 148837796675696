import { Tabs } from "antd";
import React, { FC, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { IssueCategory } from "../../enums/issueCategory.enum";
import CorporateIssues from "./CorporateIssues";
import PatientIsssues from "./PatientsIssue";
interface PaymentWrapperProps {}

const PaymentWrapper: FC<PaymentWrapperProps> = (props) => {
  const { TabPane } = Tabs;

  const history = useHistory();

  const location = useLocation();

  const {} = props;

  const tab = location?.hash?.replace("#", "") || IssueCategory.CORPORATE;

  const handleChangeTab = (value: string) =>
    history?.replace({
      pathname: location?.pathname,
      hash: value,
    });

  const paymentTabs = [
    {
      title: "Corporate Issues ",
      tabKey: IssueCategory.CORPORATE,
      component: <CorporateIssues />,
    },
    {
      title: "Patient Issues",
      tabKey: IssueCategory.PATIENT,
      component: <PatientIsssues />,
    },
  ];

  return (
    <div className="support-wrapper">
      <Tabs activeKey={tab} size="small" onChange={handleChangeTab}>
        {paymentTabs.map(({ title, component, tabKey }) => (
          <TabPane tab={title} key={tabKey}>
            {component}
          </TabPane>
        ))}
      </Tabs>
    </div>
  );
};

export default PaymentWrapper;
