import React, { useEffect, useState } from "react";
import "./treatmentSummaryXRayNotes.scss";
import { XRayNote } from "../../../../models/XRayNote/xRayNote.model";
import XrayNotesService from "../../../../services/XRayNotes/xRayNotes.service";
import AppLoader from "../../../../shared/components/AppLoader";

interface TreatmentSummaryXRayNotesProps {
  interactionId: number;
}

function TreatmentSummaryXRayNotes({
  interactionId,
}: TreatmentSummaryXRayNotesProps) {
  const [loading, setLoading] = useState(false);

  const [xRayNotes, setXRayNotes] = useState<XRayNote[]>([]);

  useEffect(() => {
    setLoading(true);
    XrayNotesService.fetchXrayNotes(
      interactionId,
      (xRayNotes: XRayNote[]) => {
        setXRayNotes(xRayNotes);
      },
      () => {},
      () => {
        setLoading(false);
      }
    );
  }, [interactionId]);

  return (
    <div className="treatment-summary-x-ray-notes">
      {loading ? (
        <AppLoader loading={loading} />
      ) : (
        <div>
          {xRayNotes?.map((xRayNote) => (
            <div
              className="treatment-summary-x-ray-notes__item"
              key={xRayNote.id}
            >
              {xRayNote?.notes}
            </div>
          ))}
          {xRayNotes?.length === 0 && <div>No X-Ray notes found</div>}
        </div>
      )}
    </div>
  );
}

export default TreatmentSummaryXRayNotes;
