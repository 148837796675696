import React, { useEffect, useState } from "react";
import "./patientTable.scss";
import { Patient } from "../../../models/Patient/patient.model";
import { Button, Drawer, Table } from "antd";
import moment from "moment";
import { useHistory, generatePath } from "react-router-dom";
import AppRoutes from "../../../routes/routeConstants/appRoutes";
import AuthContainer from "../../../store/container/AuthContainer";
import { AuthReducerProps } from "../../../store/reducers/authReducer";
import { ResponsibilityTypes } from "../../../enums/responsebily.enum";
import Stats from "../../../shared/components/Stats";
import { StatsType } from "../../../enums/statsType.enum";
import ContractFilterForm from "../../Services/Contracts/ContractFilterForm";
import { MenuOutlined } from "@ant-design/icons";
import { useMetrics } from "../../../shared/hooks/Metrics/useMetrics";
import PatientService from "../../../services/Patient/patient.service";
import { PaginationDetails } from "../../../models/Pagination/pagination.model";
import { TablePaginationConfig } from "antd/lib/table";
import useFilters from "../../../shared/hooks/useFilter/useFilters";
import { FilterParams } from "../../../models/FilterParams/filterParams.model";

interface PatientTableProps extends AuthReducerProps {
  patients: Patient[];
  onSuccess: (filter: FilterParams) => void;
  pagination: PaginationDetails;
}

function PatientTable({
  patients,
  userResponsibilities,
  onSuccess,
  pagination,
}: PatientTableProps) {
  const history = useHistory();
  const [isAppointmentVisible, setisAppointmentVisible] = useState(false);

  const handlFutureAppointmentForm = () => {
    setisAppointmentVisible(!isAppointmentVisible);
  };

  const { metrics } = useMetrics("patients");
  const [patientsWithoutAppointment, setPatientsWithoutAppointment] = useState<
    Patient[]
  >([]);

  const [loading, setLoading] = useState(false);
  const { getParams, setParams } = useFilters();

  const handleRow = (patient: Patient, rowIndex: number | undefined) => {
    return {
      onClick: (event: any) => {
        if (userResponsibilities.includes(ResponsibilityTypes.PATIENT_VIEW)) {
          history.push(
            generatePath(AppRoutes.PATIENT_DETAIL, { patientId: patient.id })
          );
        }
      },
    };
  };
  const handleFetchPatientsWithoutAppointment = (filter: FilterParams) => {
    setLoading(true);
    PatientService.fetchPatients(
      { isWithoutFutureAppointment: true, ...filter, page: filter?.page ?? 1 },
      (patients: Patient[]) => {
        setPatientsWithoutAppointment(patients);
      },
      () => {},
      () => {
        setLoading(false);
      }
    );
  };
  useEffect(() => {
    handleFetchPatientsWithoutAppointment(getParams());
  }, []);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text: string, record: any, index: number) => {
        return (
          <div>
            <div className="text-bold">
              {record?.firstName} {record?.lastName}
            </div>
            <div className="text-primary"> {record?.email} </div>
          </div>
        );
      },
    },
    {
      title: "Mobile",
      dataIndex: "mobileNumber",
      key: "mobileNumber",
      render: (text: string, record: any, index: number) => {
        return (
          <div>
            {record?.mobileNumber ? (
              <div>
                (+{record?.isdCode}) {record?.mobileNumber}{" "} 
              </div>
            ) : "NA"}
          </div>
        );
      },
    },
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
      render: (text: string, record: any, index: number) => {
        return <div className="text-capitalize"> {record?.gender}</div>;
      },
    },
    {
      title: "Responsible person",
      dataIndex: "responsiblePerson",
      key: "responsiblePerson",
      render: (text: string, record: any, index: number) => {
        return <div> {record?.responsiblePerson?.getFullName() || "NA"}</div>;
      },
    },
    {
      title: "Age",
      dataIndex: "dob",
      key: "dob",
      render: (text: string, record: any, index: number) => {
        const date = moment(text, "YYYY-MM-DD");
        const age = moment.duration(moment().diff(date));
        return <div> {age.years() + " years " + age.months() + " months"}</div>;
      },
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      render: (text: string, record: any, index: number) => {
        return (
          <div className="text-capitalize">
            {" "}
            {record?.cityName}, {record?.countryName}
          </div>
        );
      },
    },
    {
      title: isAppointmentVisible ? "Manage Schedule" : "",
      dataIndex: "manageSchedule",
      key: "manageSchedule",

      render: (text: string, record: any, index: number) => {
        return isAppointmentVisible ? (
          <Button
            className="secondary-btn patient-table__calender-btn "
            icon={<i className="icon-calendar" />}
            onClick={() => {
              history.push(AppRoutes.APPOINTMENTS);
            }}
          />
        ) : (
          ""
        );
      },
    },
  ];

  const patientStatsData = [
    {
      title: "Number Of Patients",
      value: metrics?.totalNoOfPatients ?? 0,
    },
    {
      title: "Active Patients In Contract",
      value: metrics?.activePatients ?? 0,
    },
    {
      title: StatsType.PATIENT_WITHOUT_FUTURE_APPOINTMENT,
      value: metrics?.patientsWithoutFutureAppointments ?? 0,
    },
    {
      title: "Inactive Patients (Discharged)",
      value: metrics?.inactivePatients ?? 0,
    },
  ];

  const handleTableChange = (pagination: TablePaginationConfig) => {
    const prevFilter = getParams();
    const updatedFilters = {
      ...prevFilter,
      page: pagination.current ?? 1,
    };
    setParams(updatedFilters);
    onSuccess(updatedFilters);
  };
  return (
    <div className="patient-table">
      <Stats
        statsData={patientStatsData}
        statsType={StatsType.PATIENTS}
        handleVisibility={handlFutureAppointmentForm}
      />

      <Table
        dataSource={patients}
        columns={columns}
        onRow={handleRow}
        pagination={{
          total: pagination?.totalCount,
          current: pagination?.currentPage ?? 1,
          showTotal: (total: number, range: [number, number]) => (
            <p>
              Showing <b>{` ${range[0]} - ${range[1]}`}</b> of{" "}
              <b>{`${total.toLocaleString()}`}</b>
            </p>
          ),
        }}
        onChange={handleTableChange}
      />
      <Drawer
        title="Active Patients without future appointments"
        width="90vw"
        visible={isAppointmentVisible}
        // onClose={handleCloseNextAppointment}
        closable
        destroyOnClose
        onClose={handlFutureAppointmentForm}
      >
        <Table
          dataSource={patientsWithoutAppointment}
          columns={columns}
          onRow={handleRow}
        />
      </Drawer>
    </div>
  );
}

export default AuthContainer(PatientTable);
