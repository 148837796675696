import React, { Fragment, useEffect, useState } from "react";
import { IDropdownOptions } from "../../../../../services/Meta/meta.service";
import CorporateObjectService from "../../../../../services/CorporateObject/corporateObject.service";
import { CorporateObject } from "../../../../../models/CorporateObject/corporateObject.model";
import { getDropdownOptions } from "../../../../../shared/utils/optionHelper";
import { Form, Formik, FormikValues } from "formik";
import { Button, Col, Row } from "antd";
import DropdownField from "../../../../../shared/components/DropdownField";
import SetService from "../../../../../services/Set/set.service";
import { DentalObjectTypeEnum } from "../../../../../enums/dentalObjectType.enum";
import CorporateLinkService from "../../../../../services/CorporateLink/corporateLink.service";
import { CorporateLink } from "../../../../../models/CorporateLink/corporateLink.model";
import { DentalSet } from "../../../../../models/DentalSet/dentalSet.model";
import {
  checkConflict,
  checkConflictProcedure,
  checkConflictSet,
  getToothPositionId,
} from "../../../../../shared/utils/babylonUtils";
import { BabylonReducerProps } from "../../../../../store/reducers/babylonReducer";
import BabylonContainer from "../../../../../store/container/BabylonContainer";
import "./treatmentTemplateStepForm.scss";
import { useLocation, useParams } from "react-router";
import {
  TreatmentPlanTemplateSteps,
} from "../../../../../models/TreatmentPlanTemplateSteps/treatmentPlanTemplateSteps.model";
import TreatmentPlanContainer from "../../../../../store/container/TreatmentPlanContrainer";
import { TreatmentPlanReducerProps } from "../../../../../store/reducers/treatmentPlanReducer";
import { TreatmentTemplateService } from "../../../../../services/TreatmentTemplate/treatmentTemplate.service";
import { Scene } from "@babylonjs/core";
import { InteractionActionEnum } from "../../../../../enums/interactionActionEnum.enum";
import { ICONS } from "../../../../../shared/icons";
import ProcedureService from "../../../../../services/Procedure/procedure.service";
import TreatmentPlanService from "../../../../../services/TreatmentPlan/treatmentPlan.service";
import Elastic from "../../Elastic";
import { iterateSteps } from "../../../../../shared/utils/integrateTreatmentSteps";
import { InteractionStepFormTypeEnum } from "../../../../../enums/interactionStepFormType.enum";
import useResponsibilities from "../../../../../shared/hooks/Responsibilities/useResponsibilities";
import { ResponsibilityTypes } from "../../../../../enums/responsebily.enum";
import useSets from "../../../../../shared/hooks/useSets";
import useProcedures from "../../../../../shared/hooks/useProcedures";

interface TreatmentTemplateStepFormProps
  extends BabylonReducerProps,
    TreatmentPlanReducerProps {
  interactionAction?: InteractionActionEnum;
  type?: InteractionStepFormTypeEnum;
  onSuccess?: () => void;
}

function TreatmentTemplateStepForm({
  caps,
  sourceObject,
  scene,
  highlightLayer,
  corporateObjects,
  toothPositions,
  interactionAction,
  currentTreatmentPlan,
  onSuccess,
  setSourceObject,
  setShowPopover,
  setPopoverPosition,
  currentAppointment,
  type,
  setMeshLoading
}: TreatmentTemplateStepFormProps) {
  const {hasAccess} = useResponsibilities();

  const [formValues, setFormValues] = useState(
    new TreatmentPlanTemplateSteps()
  );

  const [formLoading, setFormLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const [vendorObjectOptions, setVendorObjectOptions] = useState<
    IDropdownOptions[]
  >([]);

  const [linkOptions, setLinkOptions] = useState<IDropdownOptions[]>([]);

  const { fetchSets, options: dentalSetOptions, loading: setsLoading } = useSets({
    patientCategory: currentTreatmentPlan?.patientCategory
  });

  const { fetchProcedures, options: procedureOptions, loading: proceduresLoading } = useProcedures({
    patientCategory: currentTreatmentPlan?.patientCategory
  });

  const params = useParams<{ id: string }>();

  const location = useLocation();

  const HAS_SET_VIEW_ACCESS = hasAccess(ResponsibilityTypes.SET_VIEW);
  
  const HAS_PROCEDURE_VIEW_ACCESS = hasAccess(ResponsibilityTypes.PROCEDURE_VIEW);

  const isTreatmentPlan = () => {
    const paths = location.pathname.split("/");
    if (paths[paths.length - 2] === "treatment-plans") {
      return true;
    }
    return false;
  };

  const handleFetchLinks = (srcObjectId?: number, destObjectId?: number) => {
    if (srcObjectId && destObjectId) {
      CorporateLinkService.fetchCorporateLinks(
        null,
        srcObjectId,
        destObjectId,
        (corporateLinks: CorporateLink[]) => {
          setLinkOptions(
            corporateLinks.map((link) => ({
              ...link,
              label: link.srcLocatorName + " - " + link.destLocatorName,
              value: link.id,
            }))
          );
        },
        () => {},
        () => {}
      );
    }
  };

  const isConflictHappens = async (values: FormikValues) => {
    if (!scene) return;

    let conflict: any = "";

    try{
      setMeshLoading(true);
    if (values?.setId) conflict = await checkConflictSet(values?.setId, scene);
    else if (values?.procedureId)
      conflict = await checkConflictProcedure(values?.procedureId, scene);
    else {
      const mesh = scene?.getMeshByName(
        (sourceObject as CorporateObject)?.name ?? ""
      );
      if (mesh && values?.toothLink)
        conflict = await checkConflict(
          mesh,
          values.toothLink?.srcObjectName,
          values.toothLink?.srcLocatorName,
          values.toothLink?.destObjectName
        );
    }
    }catch(ex){}finally{
      setMeshLoading(false);
    }

    return conflict;
  };

  const handleSubmit = async (values: FormikValues) => {
    if (values.connector) {
      values.set = undefined;
      values.procedure = undefined;
    }
    values?.procedureId ? setFormLoading(true) : setLoading(true);

    const treatmentPlanTemplateSteps = Object.assign(
      new TreatmentPlanTemplateSteps(),
      values
    );

    let conflict = await isConflictHappens(values);

    if (conflict) {
      setLoading(false);
      setFormLoading(false);
      return;
    }

    if (scene && (sourceObject as CorporateObject)?.name) {
      treatmentPlanTemplateSteps.toothPositionId = getToothPositionId(
        scene,
        (sourceObject as CorporateObject)?.name ?? "",
        toothPositions
      );
    }
    if (currentAppointment) {
      setMeshLoading(true);
      let service;
      if (isTreatmentPlan()) {
        service = TreatmentPlanService.createStep;
      } else {
        service = TreatmentTemplateService.createStep;
      }
      service(
        {
          id: currentAppointment.toString(),
          treatmentPlanTemplateId: params.id,
        },
        treatmentPlanTemplateSteps,
        async (treatmentPlanTemplateStep) => {
          await iterateSteps<TreatmentPlanTemplateSteps>(
            {
              caps,
              corporateObjects,
              isMeshPickable: false,
              scene,
              setPopoverPosition,
              setShowPopover,
              setSourceObject,
            },
            [treatmentPlanTemplateStep]
          );
          highlightLayer?.removeAllMeshes();
          onSuccess?.(); 
        },
        () => {},
        () => {
          setLoading(false);
          setFormLoading(false);
          setMeshLoading(false);
        }
      );
    }
  };

  useEffect(() => {
    CorporateObjectService.fetchCorporateObjects(
      {},
      DentalObjectTypeEnum.VENDOR,
      (corporateObjects: CorporateObject[]) => {
        setVendorObjectOptions(
          getDropdownOptions(corporateObjects, "name", "id")
        );
      },
      () => {},
      () => {},
      {
        //  Future problem Array problem
        dental_object_id: Array.isArray(sourceObject)
          ? undefined
          : sourceObject?.dentalObjectId,
      }
    );
  }, [sourceObject]);

  return (
    <div className="set-step-form">
      {interactionAction && (
        <Fragment>
          {HAS_SET_VIEW_ACCESS && <><h5 className="text-primary">
            {interactionAction === InteractionActionEnum.ALIGNER ? (
              <i className="icon-aligners mr-2" />
            ) : interactionAction === InteractionActionEnum.BUTTON ? (
              <img className="icon-btn mr-2" src={ICONS.BUTTON} alt="" />
            ) : interactionAction === InteractionActionEnum.ELASTIC ? (
              <img className="icon-btn mr-2" src={ICONS.ELASTIC} alt="" />
            ) : (
              <i className="icon-aligners mr-2" />
            )}{" "}
            {interactionAction} Sets
          </h5>
          <Formik
            initialValues={new TreatmentPlanTemplateSteps()}
            onSubmit={handleSubmit}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <Row
                  className="treatment-template__set__wrapper mb-5"
                  gutter={[10, 10]}
                >
                  <Col span={15}>
                    <DropdownField
                      name="setId"
                      options={dentalSetOptions}
                      setFieldValue={setFieldValue}
                      loading={setsLoading}
                      onScrollEnd={fetchSets}
                    />
                  </Col>
                  <Col span={9}>
                    <Button
                      disabled={!values.setId || loading}
                      loading={loading}
                      htmlType="submit"
                      type="primary"
                    >
                      Add Set
                    </Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik></>}
          {/* TODO: Need to be handled once Procedures come to use */}
          {/* {HAS_PROCEDURE_VIEW_ACCESS && <h5 className="text-primary">
            {interactionAction === InteractionActionEnum.ALIGNER ? (
              <i className="icon-aligners mr-2" />
            ) : interactionAction === InteractionActionEnum.BUTTON ? (
              <img className="icon-btn mr-2" src={ICONS.BUTTON} alt="" />
            ) : interactionAction === InteractionActionEnum.ELASTIC ? (
              <img className="icon-btn mr-2" src={ICONS.ELASTIC} alt="" />
            ) : (
              <i className="icon-aligners mr-2" />
            )}{" "}
            {interactionAction}{" "}
            {interactionAction !== InteractionActionEnum.ELASTIC && "Procedure"}
          </h5>}
          {interactionAction === InteractionActionEnum.ELASTIC ? (
            <Elastic
              onSelectHandler={(color) => {
                if (Array.isArray(sourceObject)) {
                  const payload = {
                    connector: {
                      color,
                      objectType: "elastic",
                      connectorSteps: sourceObject.map((object, index) => {
                        const toothPositionId = getToothPositionId(
                          scene as Scene,
                          object?.name ?? "",
                          toothPositions
                        );
                        return {
                          order: index + 1,
                          toothPositionId,
                          connectorId: object?.dentalObjectId,
                          objectLocatorId: object?.dentalObjectId,
                        };
                      }),
                    },
                  };
                  handleSubmit(payload);
                }
              }}
            />
          ) : HAS_PROCEDURE_VIEW_ACCESS && (
            <Formik
              initialValues={new TreatmentPlanTemplateSteps()}
              onSubmit={handleSubmit}
            >
              {({ values, setFieldValue }) => (
                <Form>
                  <Row
                    className="treatment-template__set__wrapper mb-5"
                    gutter={[10, 10]}
                  >
                    <Col span={15}>
                      <DropdownField
                        name="procedureId"
                        options={procedureOptions}
                        setFieldValue={setFieldValue}
                        loading={proceduresLoading}
                        onScrollEnd={fetchProcedures}
                      />
                    </Col>
                    <Col span={9}>
                      <Button
                        disabled={!values.procedureId || formLoading}
                        loading={formLoading}
                        htmlType="submit"
                        type="primary"
                      >
                        Add Procedure
                      </Button>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          )} */}
        </Fragment>
      )}
      {sourceObject && type===InteractionStepFormTypeEnum.OBJECT_LINK && (
        <Formik
          initialValues={formValues}
          onSubmit={handleSubmit}
          enableReinitialize
          validationSchema={null}
        >
          {({ values, isValid, dirty, setFieldValue, isSubmitting }) => {
            return (
              <Form>
                <div className="set-step-form__form-body">
                  <Row gutter={[20, 20]}>
                    <Col span={24}>
                      <h5 className="text-primary"> Vendor Object</h5>
                      <DropdownField
                        name="destObjectId"
                        placeHolder="Select Vendor Object"
                        showSearch
                        onChange={(value) => {
                          setFieldValue("destObjectId", value);
                          handleFetchLinks(
                            (sourceObject as CorporateObject)?.id,
                            value
                          );
                        }}
                        options={vendorObjectOptions}
                        value={values.destObjectId}
                      />
                    </Col>
                    <Col span={24}>
                      <h5 className="text-primary">
                        Source Locator - Destination Locator
                      </h5>
                      <DropdownField
                        name="corporateLinkId"
                        placeHolder="Select Link"
                        showSearch
                        onChange={(_, values) => {
                          setFieldValue("corporateLinkId", values?.value);
                          setFieldValue("toothLink", values);
                        }}
                        options={linkOptions}
                        value={values.corporateLinkId}
                      />
                    </Col>
                  </Row>
                </div>
                <div className="set-step-form__form-body__submit-wrapper text-right">
                  <Button
                    type="primary"
                    className="ml-2"
                    htmlType="submit"
                    loading={isSubmitting || formLoading}
                    disabled={!isValid || !dirty || formLoading}
                  >
                    Add
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      )}
    </div>
  );
}

export default BabylonContainer(TreatmentPlanContainer(TreatmentTemplateStepForm));
