import React, { useEffect, useState } from "react";
import "./contractDetailTerms.scss";
import { Contract } from "../../../../../models/Contract/contract.model";
import ContractTermTemplateService from "../../../../../services/ContractTermTemplate/ContractTermTemplate.service";
import { ContractTermTemplate } from "../../../../../models/ContractTermsTemplate/contractTermsTemplate.model";
import { Carousel, Modal } from "antd";
import rightArrow from "../../../../../assets/images/right_arrow.png";
import leftArrow from "../../../../../assets/images/left_arrow.png";
import AppLoader from "../../../../../shared/components/AppLoader";
import logoImage from "./assets/logo.png";
import { isPdf } from "shared/utils/getFileExtensionFromUrl";

// Import the main component
import { Worker, Viewer } from "@react-pdf-viewer/core";

// Import the styles
import "@react-pdf-viewer/core/lib/styles/index.css";


interface ContractDetailTermsProps {
  contractTerm: ContractTermTemplate[];
  loading: boolean;
}

function ContractDetailTerms({ loading, contractTerm }: ContractDetailTermsProps) {
  const [currentSlide, setCurrentSlide] = useState<number>(1);
  const carouselRef: any = React.createRef();

  const [pdfPreview, setPdfPreview] = useState("");

  return (
    <div className="contract-detail-terms">
      {loading ? (
        <AppLoader loading />
      ) : (
        <div className="contract-detail-terms__details">
          <div className="contract-detail-terms__details__navigator">
          <span className="text-primary mr-2">{contractTerm?.[currentSlide - 1]?.name || ""}</span>
          {loading ? (
            ""
          ) : (
            <span className="mr-2 text-italic text-primary">
              {`(${currentSlide}/${contractTerm.length} forms)`}
            </span>
          )}
          <img
            onClick={() => {
              carouselRef.current.prev();
              setCurrentSlide((slide) => (--slide % (contractTerm?.length + 1)) || contractTerm?.length);
            }}
            src={leftArrow}
            alt="left"
            className="arrow cursor-pointer"
          />
          <img
            src={rightArrow}
            className="cursor-pointer ml-3 arrow"
            onClick={() => {
              carouselRef.current.next();
              setCurrentSlide((slide) => (++slide % (contractTerm?.length + 1)) || 1);
            }}
            alt=""
          />
          </div>
          <Carousel
            adaptiveHeight
            dots={false}
            ref={carouselRef}
            className="agreement__details mt-5"
          >
            {contractTerm?.map((term, index) => (
              <div className="pointer-none">
                <div
                  dangerouslySetInnerHTML={{
                    __html: term?.description ?? "",
                  }}
                />
              </div>
            ))}
          </Carousel>
        </div>
      )}
      {contractTerm?.map((sign, index) => {
        return (
          currentSlide === index + 1 &&
          sign?.documentUrl && (
            <div key={index} className="mt-5 agreement__signature ">
              {isPdf(sign?.documentUrl || "") ? (
            <div className="cursor-pointer" onClick={() => {
              if(!sign?.documentUrl) return;

              setPdfPreview(sign?.documentUrl);
            }}>
                <iframe
                  className="pointer-none"
                  src={sign?.documentUrl}
                  title={sign?.name}
                />
              </div>
            ) : (
              <img src={sign?.documentUrl} alt={sign?.name} />
            )}
            </div>
          )
        );
      })}
      <Modal
        visible={!!pdfPreview}
        onCancel={() => setPdfPreview("")}
        className="plain-view"
        destroyOnClose
        closable
        width="50vw"
        bodyStyle={{
          maxHeight: "75vh",
          overflow: "scroll"
        }}
      >
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
          <Viewer fileUrl={pdfPreview} />
        </Worker>
      </Modal>
    </div>
  );
}

export default ContractDetailTerms;
