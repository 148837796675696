import { serializable, alias, primitive, object } from "serializr";
import { SetStepActionEnum } from "../../enums/setStepAction.enum";
import { CorporateLink } from "../CorporateLink/corporateLink.model";
import { DentalSet } from "../DentalSet/dentalSet.model";

export class ProcedureStep {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("corporate_link_id", primitive()))
  corporateLinkId?: number;

  destObjectId?: number;

  @serializable(alias("action_type", primitive()))
  actionType?: string = SetStepActionEnum.ADD;

  @serializable(alias("order", primitive()))
  order?: number = 1;

  @serializable(alias("procedure_id", primitive()))
  procedureId?: number;

  @serializable(alias("setid", primitive()))
  setId?: number;

  @serializable(alias("note", primitive()))
  note?: string;

  @serializable(alias("set", object(DentalSet)))
  set?: DentalSet;

  @serializable(alias("object_link", object(CorporateLink)))
  objectLink?: CorporateLink;
}
