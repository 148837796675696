import ApiRoutes from "../../routes/routeConstants/apiRoutes";
import axiosInstance from "../../interceptor/axiosInstance";
import { deserialize, serialize } from "serializr";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import { Patient } from "../../models/Patient/patient.model";
import { ResponsiblePerson } from "../../models/ResponsiblePerson/responsiblePerson.model";
import { PatientDocument } from "../../models/PatientDocument/patientDocument.model";
import { convertJSONToFormData } from "../../shared/utils/dataFormatConverter";
import { ContractSubscription } from "../../models/ContractSubscription/contractSubscription.model";
import { FilterParams } from "../../models/FilterParams/filterParams.model";
import { PaginationDetails } from "../../models/Pagination/pagination.model";
import { PatientPreDeterminationForm } from "models/PatientPreDeterminationForm/patientPreDeterminationForm.model";
import { PatientNotes } from "models/PatientNotes/patientNotes.model";
export default class PatientService {
  static fetchPatients(
    filter: FilterParams,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const params = serialize(FilterParams, filter);
    axiosInstance
      .get(ApiRoutes.PATIENTS, { params })
      .then((response) => {
        const patients = deserialize(Patient, response.data["patients"]);
        const pagination = deserialize(
          PaginationDetails,
          response.data["meta"]
        );
        onSuccess(patients, pagination);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }
  static fetchPatientsAppointment(
    onSuccess: Function,
    onError: Function,
    onFinal: () => void,
    params?: object
  ) {
    axiosInstance
      .get(ApiRoutes.PATIENTS_APPOINTMENT, { params })
      .then((response) => {
        const patients = deserialize(Patient, response.data["appointments"]);
        onSuccess(patients);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static fetchResponsiblePersons(
    searchText: string,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .get(ApiRoutes.RESPONSIBLE_PERSONS, {
        params: { search_text: searchText },
      })
      .then((response) => {
        const responsiblePersons = deserialize(
          ResponsiblePerson,
          response.data["patients"]
        );
        onSuccess(responsiblePersons);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static showPatient(
    patientId: string,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.PATIENTS + "/" + patientId;
    axiosInstance
      .get(API_URL)
      .then((response) => {
        const patient = deserialize(Patient, response.data["patient"]);
        onSuccess(patient);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static downloadTemplate(
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.PATIENTS_DOWNLOAD_TEMPLATE;
    axiosInstance
      .get(API_URL)
      .then((response) => {
        const patient = deserialize(Patient, response.data["patient"]);
        onSuccess(patient);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static createPatient(
    patient: Patient,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const serializedPatient = { patient: serialize(patient) };
    const patientFormData = convertJSONToFormData(serializedPatient);
    if (patient.profilePic) {
      patientFormData.append("patient.profile_pic", patient.profilePic);
    }
    axiosInstance
      .post(ApiRoutes.PATIENTS, patientFormData)
      .then((response) => {
        const patient = deserialize(Patient, response.data["patient"]);
        Notification({
          message: "Patient record created",
          type: NotificationTypes.SUCCESS,
        });
        onSuccess(patient);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static uploadPatientData(
    file: File,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const formData = new FormData();
    formData.append("file", file);
    axiosInstance
      .post(ApiRoutes.PATIENT_UPLOAD_DATA, formData)
      .then((response) => {
        const patient = deserialize(Patient, response.data["patient"]);
        Notification({
          message: "Patient records uploaded",
          type: NotificationTypes.SUCCESS,
        });
        onSuccess(patient);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static updatePatient(
    patient: Patient,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.PATIENTS + "/" + patient.id;
    const serializedPatient = { patient: serialize(patient) };
    const patientFormData = convertJSONToFormData(serializedPatient);
    if (patient.profilePic) {
      patientFormData.append("patient.profile_pic", patient.profilePic);
    }
    axiosInstance
      .put(API_URL, patientFormData)
      .then((response) => {
        Notification({
          message: "Patient record updated",
          type: NotificationTypes.SUCCESS,
        });
        const patient = deserialize(Patient, response.data["patient"]);
        onSuccess(patient);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static fetchPatientDocuments(
    patientId: number,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.PATIENT_DOCUMENTS.replace(
      ":patientId",
      patientId.toString()
    );
    axiosInstance
      .get(API_URL)
      .then((response) => {
        const patientDocuments = deserialize(
          PatientDocument,
          response.data["patient_documents"]
        );
        onSuccess(patientDocuments);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static uploadPatientDocuments(
    patientId: number,
    patientDocuments: PatientDocument,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.PATIENT_DOCUMENTS.replace(
      ":patientId",
      patientId.toString()
    );
    const patientDocumentsJSON = {
      patient_documents: serialize(patientDocuments),
    };
    const patientDocumentsFormData = convertJSONToFormData(
      patientDocumentsJSON,
      true
    );
    axiosInstance
      .post(API_URL, patientDocumentsFormData)
      .then((response) => {
        Notification({
          message: "Patient documents uploaded",
          type: NotificationTypes.SUCCESS,
        });
        const patientDocuments = deserialize(
          PatientDocument,
          response.data["patient_documents"]
        );
        onSuccess(patientDocuments);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static deletePatient(
    patientId: string,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.PATIENTS + "/" + patientId;
    axiosInstance
      .delete(API_URL)
      .then((response) => {
        onSuccess();
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static fetchPatientXray(
    id: string,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .get(ApiRoutes.PATIENT_XRAY.replace(":id", id))
      .then((response) => {
        const patients = deserialize(
          PatientDocument,
          response.data["x_ray_images"]
        );
        const pagination = deserialize(
          PaginationDetails,
          response.data["meta"]
        );
        onSuccess(patients, pagination);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }
  static fetchPreVisitImages(
    id: string,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .get(ApiRoutes.PATIENT_PRE_VISIT_IMAGES.replace(":id", id))
      .then((response) => {
        const patients = deserialize(
          PatientDocument,
          response.data["pre_visit_images"]
        );
        const pagination = deserialize(
          PaginationDetails,
          response.data["meta"]
        );
        onSuccess(patients, pagination);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }
  static fetchPostVisitImages(
    id: string,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .get(ApiRoutes.PATIENT_POST_VISIT_IMAGES.replace(":id", id))
      .then((response) => {
        const patients = deserialize(
          PatientDocument,
          response.data["post_visit_images"]
        );
        const pagination = deserialize(
          PaginationDetails,
          response.data["meta"]
        );
        onSuccess(patients, pagination);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static async fetchSubscriptions(
    patientId: string,
    onSuccess: (contractSubscriptions: ContractSubscription[]) => void,
    onFinal: () => void
  ) {
    try {
      const { data } = await axiosInstance.get(
        ApiRoutes.PATIENT_SUBSCRIPTIONS.replace(":patientId", patientId)
      );
      const contractSubscriptions = deserialize(
        ContractSubscription,
        data?.contract_subscriptions as any[]
      );
      onSuccess(contractSubscriptions);
    } catch (error) {
    } finally {
      onFinal();
    }
  }
  static downloadPreDeterminationForm(
    patientId: string,
    formValues: PatientPreDeterminationForm,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const serializedData = {
      patient_predetermination_form_request: serialize(formValues),
    };
    axiosInstance
      .post(
        ApiRoutes.PATIENT_PRE_DETERMINATION_FORM.replace(":id", patientId),
        serializedData
      )
      .then((response) => {
        const url = response?.data?.pre_determination_form_url;
        Notification({
          message: "Pre Determination Form Generate",
          type: NotificationTypes.SUCCESS,
        });
        onSuccess(url);
      })
      .catch((error) => {
        Notification({
          message: "Unable to Generate Pre Determination Form",
          description: (error as Error)?.message,
          type: NotificationTypes.ERROR,
        });
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static async validationEmail(email: string) {
    try {
      await axiosInstance.post(ApiRoutes.CORPORATE_PATIENT_EMAIL_VALIDATION, {
        email,
      });
      return true;
    } catch (error) {
      return false;
    }
  }
  static async validationPhone(phone: number, isdCode: number) {
    try {
      await axiosInstance.post(ApiRoutes.PATIENT_PHONE_VALIDATION, {
        mobile_number: phone,
        isd_code: isdCode,
      });
      return true;
    } catch (error) {
      return false;
    }
  }

  static fetchPatientNotes(
    patientId: string,
    onSuccess: (notes: PatientNotes[]) => void,
    onError: Function,
    onFinal: Function
  ) {
    axiosInstance
      .get(ApiRoutes.PATIENT_NOTES.replace(":id", patientId))
      .then((response) => {
        const notes = deserialize(
          PatientNotes,
          response.data["patient_notes"] as unknown[]
        );
        onSuccess(notes);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }
  static addPatientNotes(
    patientId: string,
    values: PatientNotes,
    onSuccess: (notes: PatientNotes) => void,
    onError: Function,
    onFinal: Function
  ) {
    const params = serialize(PatientNotes, values);

    axiosInstance
      .post(ApiRoutes.PATIENT_NOTES.replace(":id", patientId), {
        patient_note: params,
      })
      .then((response) => {
        const notes = deserialize(PatientNotes, response.data["patient_note"]);
        Notification({
          message: "Patient note added",
          type: NotificationTypes.SUCCESS,
        });
        onSuccess(notes);
      })
      .catch((error) => {
        Notification({
          message: "Unable to add patient note ",
          description: (error as Error).message,
          type: NotificationTypes.ERROR,
        });
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }
  static updatePatientNote(
    patientId: string,
    values: PatientNotes,
    onSuccess: (notes: PatientNotes) => void,
    onError: Function,
    onFinal: Function
  ) {
    const patientNote = serialize(PatientNotes, values);

    axiosInstance
      .put(
        ApiRoutes.PATIENT_NOTES.replace(":id", patientId) + `/${values?.id}`,
        {
          patient_note: patientNote,
        }
      )
      .then((response) => {
        const notes = deserialize(PatientNotes, response.data["patient_note"]);
        Notification({
          message: "Patient note updated",
          type: NotificationTypes.SUCCESS,
        });
        onSuccess(notes);
      })
      .catch((error) => {
        Notification({
          message: "Unable to update patient note",
          description: (error as Error).message,
          type: NotificationTypes.ERROR,
        });
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }
  static deletePatientNote(
    patientId: string,
    noteId: string,
    onSuccess: () => void,
    onError: Function,
    onFinal: Function
  ) {
    axiosInstance
      .delete(ApiRoutes.PATIENT_NOTES.replace(":id", patientId) + `/${noteId}`)
      .then(() => {
        Notification({
          message: "Patient note deleted",
          type: NotificationTypes.SUCCESS,
        });
        onSuccess();
      })
      .catch((error) => {
        Notification({
          message: "Unable to delete patient note ",
          description: (error as Error).message,
          type: NotificationTypes.ERROR,
        });
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }
}
