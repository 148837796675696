import React, { useState } from "react";
import AppRoutes from "./routes";
import { Provider } from "react-redux";
import { store } from "./store";
import "antd/dist/antd.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./styles/_main.scss";
import { ConfirmationDialogProvider } from "./shared/components/HOC/ConfirmationDialog";
import {
  AUTHENTICATED,
  UNAUTHENTICATED,
} from "./store/definitions/authConstants";
import { SET_CURRENT_CORPORATE } from "./store/definitions/corporateConstants";
import { useEffect } from "react";
import ErrorBoundary from "./shared/components/ErrorBoundary";
import SplashScreen from "./shared/components/SplashScreen";
import { LocalStorageKeys } from "./enums/localStorageKeys.enum";
import { SET_CORPORATE_OBJECTS } from "./store/definitions/babylonConstants";
import ScrollToTop from "./shared/utils/scrollToTop";
// const stripe = require("stripe")("sk_test_tR3PYbcVNZZ796tH88S4VQ2u");

const token = localStorage.getItem("token");
if (token) {
  store.dispatch({
    type: AUTHENTICATED,
    payload: {
      authenticated: true,
    },
  });
} else {
  store.dispatch({
    type: UNAUTHENTICATED,
    payload: {
      authenticated: false,
    },
  });
}

const corporate = JSON.parse(localStorage.getItem("corporate") || "{}");

if (corporate && corporate.id) {
  store.dispatch({
    type: SET_CURRENT_CORPORATE,
    payload: {
      currentCorporate: corporate,
    },
  });
}

const App = () => {
  const [showScreen, setShowScreen] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowScreen(false);
    }, 2000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <Provider store={store}>
      <ConfirmationDialogProvider>
        {showScreen && <SplashScreen />}
        <div className="App">
          <AppRoutes />
        </div>
      </ConfirmationDialogProvider>
    </Provider>
  );
};

export default App;
