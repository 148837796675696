import {serializable, alias, object, list, primitive} from 'serializr';

export class TermOfService { 
	@serializable(alias('created_by', primitive()))
	createdBy?: number;

	@serializable(alias('created_by_first_name', primitive()))
	createdByFirstame?: string;

	@serializable(alias('created_by_last_name', primitive()))
	createdByLastName?: string;

	@serializable(alias('created_at', primitive()))
	createdAt?: number;
}