import { useField } from "formik";
import React from "react";
import CurrencyInput, { CurrencyInputProps } from "react-currency-input-field";
import ErrorMessage from "../Error";

import "./currencyField.scss";

interface CurrencyFieldProps extends CurrencyInputProps {
  name: string;
  error?: string;
  hint?: string;
}

const CurrencyField = ({
  name,
  decimalsLimit = 2,
  title,
  className,
  error,
  hint,
  ...props
}: CurrencyFieldProps) => {
  const [{ value }] = useField(name);

  return (
    <div className="currency-field">
      {title && <span className="currency-field__label">{title}</span>}
      <CurrencyInput
        allowNegativeValue={false}
        className={`currency-field__input ${className}`}
        decimalsLimit={decimalsLimit}
        disableAbbreviations
        disableGroupSeparators
        name={name}
        value={value}
        {...props}
      />
      {hint && (
        <span className="hint">
          <span>*</span>
          <span>{hint}</span>
        </span>
      )}
      {error && <ErrorMessage message={error} />}
    </div>
  );
};

export default CurrencyField;
