import { generatePath } from "react-router";
import { deserialize, serialize } from "serializr";
import { NotificationTypes } from "../../enums/notificationTypes";
import axiosInstance from "../../interceptor/axiosInstance";
import { WorkItem, WorkItems, WorkItemsFilters } from "../../models/WorkItem/workItem.model";
import ApiRoutes from "../../routes/routeConstants/apiRoutes";
import Notification from "../../shared/components/Notification";

export class WorkItemService {
    static fetchWorkItems(
        params: WorkItemsFilters,
        onSuccess: (workItem: WorkItem[]) => void,
        onError: () => void,
        onFinal: () => void
    ) {
        return axiosInstance
            .get(ApiRoutes.WORK_ITEM, {
                params: serialize(WorkItemsFilters, params)
                // params: {
                //     page_index: 1,
                //     page_size: 10,
                // }
            })
            .then((response) => {
                const { workItems } = deserialize(WorkItems, response.data);
                onSuccess(workItems);
            })
            .catch((error) => {
                onError();
            })
            .finally(onFinal);
    }

    static acceptWorkItems(
        workItem: WorkItem,
        onSuccess: () => void,
        onError: () => void,
        onFinal: () => void
    ) {
        return axiosInstance
            .post(`${ApiRoutes.WORK_ITEM}/${workItem.id}/accept`, {})
            .then((response) => {
                onSuccess();
                Notification({
                    message: "Work Item Accepted",
                    type: NotificationTypes.SUCCESS,
                });
            })
            .catch((error) => {
                onError();
            })
            .finally(onFinal);
    }

    static rejectWorkItems(
        reason: string,
        workItem: WorkItem,
        onSuccess: () => void,
        onError: () => void,
        onFinal: () => void
    ) {
        const payload = {
            work_item: {
                reject_reason: reason,
            },
        };
        return axiosInstance
            .post(`${ApiRoutes.WORK_ITEM}/${workItem.id}/reject`, payload)
            .then((response) => {
                Notification({
                    message: "Work Item Rejected",
                    type: NotificationTypes.SUCCESS,
                });
                onSuccess();
            })
            .catch((error) => {
                onError();
            })
            .finally(onFinal);
    }

    static completeWorkItem(
        id: string,
        onSuccess: () => void,
        onError: () => void,
        onFinal: () => void
    ) {
        const completeWorkItemPayload = {};
        return axiosInstance
            .post(
                ApiRoutes.COMPLETE_INTERACTION.replace(":id", id),
                completeWorkItemPayload
            )
            .then(() => {
                onSuccess();
            })
            .catch(() => {
                onError();
            })
            .finally(onFinal);
    }
}
