import { serializable, alias, object, list, primitive } from "serializr";

export class PaymentInvoiceMetrics {
  @serializable(alias("this_month_payments", primitive()))
  thisMonthPayments?: number;
  @serializable(alias("total_amount", primitive()))
  totalAmount?: number;
}
export class PaymentIntent {
  @serializable(alias("id", primitive()))
  id?: number;
  @serializable(alias("transaction_id", primitive()))
  transactionId?: string;
  @serializable(alias("ids", list(primitive())))
  ids?: number[];
  @serializable(alias("patient_profile_id", primitive()))
  patientProfileId?: number;
  @serializable(alias("contract_name", primitive()))
  contractName?: string;
  @serializable(alias("invoice_date", primitive()))
  invoiceDate?: string;
  @serializable(alias("due_date", primitive()))
  dueDate?: string;
  @serializable(alias("status", primitive()))
  status?: string;
  @serializable(alias("insurance_provider", primitive()))
  insuranceProvider?: string;
  @serializable(alias("patient_first_name", primitive()))
  patientFirstName?: string;
  @serializable(alias("patient_last_name", primitive()))
  patientLastName?: string;

  @serializable(alias("stripe_payment_intent_id", primitive()))
  stripePaymentIntentId?: string;

  @serializable(alias("stripe_payment_intent_status", primitive()))
  stripePaymentIntentStatus?: string;

  @serializable(alias("stripe_payment_intent_amount", primitive()))
  stripePaymentIntentAmount?: number;

  @serializable(alias("stripe_subscription_id", primitive()))
  stripeSubscriptionId?: string;

  @serializable(alias("stripe_user_type", primitive()))
  stripeUserType?: string;

  @serializable(alias("stripe_user_id", primitive()))
  stripeUserId?: string;

  @serializable(alias("contract_subscription_id", primitive()))
  contractSubscriptionId?: number;

  @serializable(alias("contract_patient_id", primitive()))
  contractPatientId?: number;

  @serializable(alias("contract_patient_first_name", primitive()))
  contractPatientFirstName?: string;

  @serializable(alias("contract_patient_last_name", primitive()))
  contractPatientLastName?: string;

  @serializable(alias("user_type", primitive()))
  userType?: string;

  @serializable(alias("user_id", primitive()))
  userId?: number;

  @serializable(alias("contract_id", primitive()))
  contractId?: number;

  @serializable(alias("created_at", primitive()))
  createdAt?: string;

  @serializable(alias("payment_type", primitive()))
  paymentType?: string;

  @serializable(alias("payment_date", primitive()))
  paymentDate?: string;

  @serializable(alias("amount", primitive()))
  amount?: string;
  @serializable(alias("comment", primitive()))
  comment?: string;
  @serializable(alias("payment_mode", primitive()))
  paymentMode?: string;
}
