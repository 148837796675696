import React, { useState } from "react";
import "./corporateObjectForm.scss";
import SelectCorporateObjects from "../SelectCorporateObjects";
import CorporateObjectFilters from "../CorporateObjectFilters";
import { CorporateObjectFilterParams } from "../../../../../models/CorporateObjectFilterParams/corporateObjectFilterParams.model";
import { DentalObject } from "../../../../../models/DentalObject/dentalObject.model";

interface CorporateObjectFormProps {
  onSuccess: () => void;
}

function CorporateObjectForm({ onSuccess }: CorporateObjectFormProps) {
  const [selectedObjectIds, setSelectedObjectIds] = useState<number[]>([]);

  const [showSelectObjects, setShowSelectObjects] = useState(false);

  const [dentalObjects, setDentalObjects] = useState<DentalObject[]>([]);

  const [filterParams, setFilterParams] = useState(
    new CorporateObjectFilterParams()
  );

  const handleSubmitFilters = (dentalObjects: DentalObject[]) => {
    setDentalObjects(dentalObjects);
    setShowSelectObjects(true);
  };

  const handleImportSuccess = () => {
    onSuccess();
  };

  return (
    <div className="corporate-object-form">
      {showSelectObjects ? (
        <SelectCorporateObjects
          onSuccess={handleImportSuccess}
          selectedObjectIds={selectedObjectIds}
          setSelectedObjectIds={setSelectedObjectIds}
          dentalObjects={dentalObjects}
        />
      ) : (
        <CorporateObjectFilters
          filterParams={filterParams}
          setFilterParams={setFilterParams}
          onNext={handleSubmitFilters}
        />
      )}
    </div>
  );
}

export default CorporateObjectForm;
