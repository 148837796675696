import React, { FC, useEffect, useState } from "react";
import Stats from "../../../shared/components/Stats";
import { Calendar } from "antd";
import Cell from "./Cell";
import moment, { Moment } from "moment";
import "./monthCalender.scss";
import { AppointmentService } from "../../../services/Appointment/appointment.service";
import { AppointmentCalender } from "../../../models/AppointmentCalender/appointmentCalender.model";
import { Appointment } from "../../../models/Appointment/appointment.model";
import { divide } from "lodash";
import { StatsType } from "../../../enums/statsType.enum";
import { useMetrics } from "../../../shared/hooks/Metrics/useMetrics";
interface MonthCalenderProps {
  currentDate: Moment;
  appointments: Appointment[];

  handleDayMonth: (appointmentDate: string) => void;
}
const MonthCalender: FC<MonthCalenderProps> = ({
  currentDate,
  appointments,
  handleDayMonth,
}) => {
  const [appointmentCalenders, setAppointmentCalenders] = useState<
    AppointmentCalender[]
  >([]);
  const metrics = useMetrics("appointments");
  useEffect(() => {
    const date = moment(currentDate.toISOString());
    const start_date = date.startOf("month").format("YYYY-MM-DD");
    const end_date = date.endOf("month").format("YYYY-MM-DD");
    AppointmentService.fetchAppointmentCalender(
      { start_date, end_date },
      (appointmentCalender) => {
        setAppointmentCalenders(appointmentCalender);
      },
      () => {},
      () => {}
    );
  }, [currentDate, appointments]);

  return (
    <div className="month-calender__container">
      <Calendar
        value={currentDate}
        className="month-calender"
        dateFullCellRender={(data) => [
          <Cell
            date={data}
            appointments={appointmentCalenders}
            setHoliday={(day: Moment, isHoliday: boolean) => {
              AppointmentService[isHoliday ? "removeHoliday" : "addAsHoliday"](
                day.format("YYYY-MM-DD"),
                () => {
                  setAppointmentCalenders(
                    appointmentCalenders.map((calender) => {
                      if (moment(calender.date).isSame(day, "day")) {
                        return {
                          ...calender,
                          isHoliday: !calender.isHoliday,
                        };
                      }
                      return calender;
                    })
                  );
                },
                () => {},
                () => {}
              );
            }}
            handleDayMonth={(appointmentDate: string) => {
              handleDayMonth(appointmentDate);
            }}
          />,
        ]}
      />
    </div>
  );
};

export default MonthCalender;
