import React, { useEffect, useState } from "react";
import "./customFormResponseForm.scss";
import CustomFormService from "../../../services/CustomForm/customForm.service";
import { CustomForm } from "../../../models/CustomForm/customForm.model";
import { CustomFormResponse } from "../../../models/CustomFormResponse/customFormResponse.model";
import { Form, Formik, FormikValues } from "formik";
import { Button, Col, Row } from "antd";
import {
  getCustomFormInput,
  getCustomFormResponseFromObject,
} from "./customFormRenderHelper";
import CustomFormResponseService from "../../../services/CustomFormResponse/customFormResponse.service";
import { CustomFormRequest } from "../../../models/CustomFormRequest/customFormRequest.model";
import CustomFormRequestService from "../../../services/CustomFormRequest/customFormRequest.service";
import AppLoader from "../../../shared/components/AppLoader";
import * as Yup from "yup";
import useResponsibilities from "../../../shared/hooks/Responsibilities/useResponsibilities";
import { ResponsibilityTypes } from "../../../enums/responsebily.enum";
import { CustomFormInputEnum } from "../../../enums/customFormInput.enum";

interface CustomFormResponseFormProps {
  customFormId: number;
  resourceId: number;
  onCancel: () => void;
  onSuccess: (customFormResponse: CustomFormResponse) => void;
}

function CustomFormResponseForm({
  resourceId,
  customFormId,
  onCancel,
  onSuccess,
}: CustomFormResponseFormProps) {
  const { hasAccess } = useResponsibilities();

  const [customForm, setCustomForm] = useState<CustomForm | undefined>();

  const [formValues, setFormValues] = useState<Object>({});

  const [formValidation, setFormValidation] = useState<any>(undefined);

  const [dataLoading, setDataLoading] = useState(false);

  const [formLoading, setFormLoading] = useState(false);

  const HAS_CUSTOM_FORM_VIEW_ACCESS = hasAccess(ResponsibilityTypes.CUSTOM_FORM_VIEW);

  const handleSubmit = (values: FormikValues) => {
    const customFormResponse = getCustomFormResponseFromObject(values);
    if (resourceId) {
      const customFormRequest = new CustomFormRequest();
      customFormRequest.requestableId = resourceId;
      setFormLoading(true);
      CustomFormRequestService.createCustomFormRequest(
        customFormId,
        customFormRequest,
        (customFormRequest: CustomFormRequest) => {
          if (customFormRequest.id) {
            setFormLoading(true);
            CustomFormResponseService.createCustomFormResponse(
              customFormRequest.id,
              customFormResponse,
              (customFormResponse: CustomFormResponse) => {
                onSuccess(customFormResponse);
              },
              () => {},
              () => {
                setFormLoading(false);
              }
            );
          }
        },
        () => {},
        () => {
          setFormLoading(false);
        }
      );
    }
  };

  useEffect(() => {
    if(!HAS_CUSTOM_FORM_VIEW_ACCESS)  return;
    
    setDataLoading(true);
    CustomFormService.showCustomForm(
      customFormId,
      (customForm: CustomForm) => {
        if (customForm.customFields && customForm.customFields.length > 0) {
          let validationFields: any = {};
          customForm?.getCustomFields?.()?.forEach(({id, isMandatory, type, name}) => {
            if (isMandatory && id) {
              validationFields[id.toString()] =
                customForm.type === CustomFormInputEnum.SIGNATURE
                  ? Yup.object()
                      .required()
                      .label(customForm.name || "Field")
                  : Yup.string()
                      [isMandatory ? "required" : "notRequired"]()
                      .label(name || "Field");
            }
          });
          setFormValidation(Yup.object().shape(validationFields));
        }
        setCustomForm(customForm);
      },
      () => {},
      () => {
        setDataLoading(false);
      }
    );
  }, [customFormId]);

  return HAS_CUSTOM_FORM_VIEW_ACCESS ?  (
    <div className="custom-form-response-form">
      <AppLoader loading={dataLoading} />
      <Formik
        initialValues={formValues}
        onSubmit={handleSubmit}
        validationSchema={formValidation}
        enableReinitialize
      >
        {({ values, isValid, dirty, setFieldValue }) => {
          return (
            <Form>
              {customForm?.customFields?.length ? (
                <div className="mb-3 text-capitalize text-primary">
                  Individual Questions
                </div>
              ) : (
                <></>
              )}
              <Row>
                {customForm?.customFields?.map((customField) => (
                  <Col className="custom-form-response-form__field" span={24}>
                    {getCustomFormInput(values, setFieldValue, customField)}
                  </Col>
                ))}
              </Row>
              {customForm?.sections?.map((section) => (
                <>
                  <div className="mb-3 text-capitalize text-primary">
                    {section?.name || "Group Questions"}
                  </div>
                  <Row className="custom-form-response-form__section">
                    {section?.customFields?.map((field) => (
                      <Col span={24}>
                        {getCustomFormInput(values, setFieldValue, field)}
                      </Col>
                    ))}
                  </Row>
                </>
              ))}
              <div className="user-form__submit-wrapper text-right">
                <Button onClick={onCancel} type="default">
                  Cancel
                </Button>
                <Button
                  type="primary"
                  className="ml-2"
                  htmlType="submit"
                  loading={formLoading}
                  disabled={!isValid || !dirty || formLoading}
                >
                  Save
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  ) : <></>;
}

export default CustomFormResponseForm;
