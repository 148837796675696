const issueTypes = ["Appointment",
"Interaction",
"Patient management",
"Staff management",
"Configuration",
"Service",
"Contracts",
"Payment"
]

const priorities = ["high", "medium", "low"]

const genOption = (value: string) => ({
    label: value,
    value,
})


export const issueTypeOptions = issueTypes.map(genOption);

export const priorityOptions = priorities.map(genOption);