import { serializable, alias, primitive, list, object } from "serializr";
import { InsuranceProvider } from "../InsuranceProvider/insuranceProvider.model";

export class SelfPaymentSchedules {
  @serializable(alias("due_no", primitive()))
  dueNo?: number;
  @serializable(alias("due_date", primitive()))
  dueDate?: string;
  @serializable(alias("amount", primitive()))
  amount?: number;
}
export class SelfPayment {
  @serializable(alias("no_of_dues", primitive()))
  noOfDues?: number;
  @serializable(alias("due_type", primitive()))
  dueType?: string;

  @serializable(alias("due_time", primitive()))
  dueTime?: string;
  @serializable(alias("amount", primitive()))
  amount?: number;
  @serializable(
    alias("self_payment_schedules", list(object(SelfPaymentSchedules)))
  )
  selfPaymentSchedules?: SelfPaymentSchedules[] = [];
}
export class PaymentSchedule {
  @serializable(alias("id", primitive()))
  id?: number;
  @serializable(alias("contract_id", primitive()))
  contractId?: number;
  @serializable(alias("created_at", primitive()))
  createdAt?: string;

  @serializable(alias("contract_cost", primitive()))
  contractCost?: number;
  @serializable(alias("deposit", primitive()))
  deposit?: number;
  @serializable(alias("total_payment_schedule_cost", primitive()))
  totalPaymentScheduleCost?: number;
  @serializable(alias("pending_contract_amount", primitive()))
  pendingContractAmount?: number;

  @serializable(alias("discount", primitive()))
  discount?: number;
  @serializable(alias("invoice_raised_for", primitive()))
  invoiceRaisedFor?: number;

  @serializable(alias("paid_amount", primitive()))
  paidAmount?: number;

  @serializable(alias("invalidate_unpaid_invoices", primitive()))
  invalidateUnpaidInvoices?: boolean;
  @serializable(alias("insurance", primitive()))
  insuranceAmount?: number;
  @serializable(alias("pending_due", primitive()))
  pendingDue?: number;
  @serializable(alias("status", primitive()))
  status?: string;

  @serializable(alias("self_payment", object(SelfPayment)))
  selfPayment?: SelfPayment;
  @serializable(alias("insurance_providers", list(object(InsuranceProvider))))
  insuranceProviders?: InsuranceProvider[] = [];
}
