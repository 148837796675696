
import {Card} from '../Card/card.model';

import {serializable, alias, object, list, primitive} from 'serializr';

export class PaymentMethod { 

	@serializable(alias('id', primitive()))
	id?: string;

	@serializable(alias('card', object(Card)))
	card?: Card;

}