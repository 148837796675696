import React, { useEffect, useState } from "react";
import { Row, Col, Button } from "antd";
import {
  generatePath,
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom";
import "./selectService.scss";
import {
  FieldArray,
  FieldArrayRenderProps,
  Form,
  Formik,
  FormikBag,
  FormikHandlers,
  FormikHelpers,
} from "formik";
import InputField from "../../../../../shared/components/InputField";
import DropdownField from "../../../../../shared/components/DropdownField";
import { Contract } from "../../../../../models/Contract/contract.model";
import { IDropdownOptions } from "../../../../../services/Meta/meta.service";
import UserService from "../../../../../services/User/user.service";
import { User } from "../../../../../models/User/user.model";
import { contractServiceFormValidation } from "./contractServiceFormValidation";
import ServiceService from "../../../../../services/Service/service.service";
import { Service } from "../../../../../models/Service/service.model";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { ContractService } from "../../../../../models/ContractService/contractService.model";
import ContractServiceMethods from "../../../../../services/Contract/Contract.service";
import ContractContainer from "../../../../../store/container/ContractContainer";
import { ContractReducerProps } from "../../../../../store/reducers/contractReducer";
import AppRoutes from "../../../../../routes/routeConstants/appRoutes";
import PatientService from "../../../../../services/Patient/patient.service";
import { Patient } from "../../../../../models/Patient/patient.model";
import { TreatmentTemplateService } from "../../../../../services/TreatmentTemplate/treatmentTemplate.service";
import ApiRoutes from "../../../../../routes/routeConstants/apiRoutes";
import externalLinkIcon from "../../../../../assets/images/externalLink.png";
import { TreatmentPlan } from "../../../../../models/TreatmentPlan";
import { TreatmentTemplate } from "../../../../../models/TreamentTemplate/treatmentTemplate.model";
import AppLoader from "../../../../../shared/components/AppLoader";
import { PaginationDetails } from "../../../../../models/Pagination/pagination.model";
import { Metrics } from "../../../../../models/Metrics/metrics.model";
import { debounce } from "lodash";
import { uniqueObjectValue } from "../../../../../shared/utils/uniqueObjectValue";
import { SelectProp } from "../../../../../shared/Types/option";
import useResponsibilities from "../../../../../shared/hooks/Responsibilities/useResponsibilities";
import { ResponsibilityTypes } from "../../../../../enums/responsebily.enum";

interface SelectServiceProps extends ContractReducerProps {
  onCancel: () => void;
}

const SelectService = ({
  onCancel,
  contract,
  setContract,
}: SelectServiceProps) => {
  const history = useHistory();

  const location = useLocation();

  const { hasAccess } = useResponsibilities();

  const [formValues, setFormValues] = useState<Contract>(new Contract());

  const [patientId, setPatientId] = useState(false);

  const [doctorOptions, setDoctorOptions] = useState<IDropdownOptions[]>([]);

  const [patientOptions, setPatientOptions] = useState<IDropdownOptions[]>([]);

  const [serviceOptions, setServiceOptions] = useState<SelectProp[]>([]);

  const [treatmentTemplates, setTreatmentTemplates] = useState<
    TreatmentTemplate[]
  >([]);
  const [treatmentTemplateOptions, setTreatmentTemplateOptions] = useState<
    IDropdownOptions[]
  >([]);
  const [pagination, setPagination] = useState<PaginationDetails>({
    nextPage: 1,
  });

  const [services, setServices] = useState<Service[]>([]);
  const [isPAthEdit, setIsPathEdit] = useState(false);

  const [formLoading, setFormLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const HAS_TREATMENT_PLAN_VIEW_ACCESS = hasAccess(
    ResponsibilityTypes.TREATMENT_PLAN_VIEW
  );

  const HAS_PATIENT_CONTRACT_EDIT_ACCESS = hasAccess(
    ResponsibilityTypes.PATIENT_CONTRACT_EDIT
  );

  const handleSubmit = (values: Contract) => {
    setFormLoading(true);
    if (contract.id) {
      if (
        values?.contractService?.treatmentPlanTemplateIds?.[0] === -1 &&
        values?.contractService?.serviceId ===
          formValues?.contractService?.serviceId
      )
        values.contractService = undefined;
      else {
        values.contractService = {
          ...(values?.contractService?.serviceId !==
            formValues?.contractService?.serviceId && {
            serviceId: values?.contractService?.serviceId,
          }),
          serviceId: values?.contractService?.serviceId,
          ...(!values?.contractService?.treatmentPlanTemplateIds?.includes(-1)
            ? {
                treatmentPlanTemplateIds:
                  values?.contractService?.treatmentPlanTemplateIds,
              }
            : {
                treatmentPlanTemplateIds: values?.contractService
                  ?.treatmentPlans?.[0]?.id
                  ? [values?.contractService?.treatmentPlans?.[0]?.id]
                  : [],
              }),
        };
      }
      ContractServiceMethods.updateContract(
        Object.assign(new Contract(), { ...values, stepNo: 1 }),
        (contract: Contract) => {
          setContract(contract);
        },
        () => {},
        () => {
          setFormLoading(false);
        }
      );
    } else {
      ContractServiceMethods.createContract(
        Object.assign(new Contract(), { ...values, stepNo: 1 }),
        (contract: Contract) => {
          history.push(
            generatePath(AppRoutes.EDIT_CONTRACT, {
              contractId: contract.id,
            })
          );
          setContract(contract);
        },
        () => {},
        () => {
          setFormLoading(false);
        }
      );
    }
  };
  useEffect(() => {
    const paths = location.pathname.split("/");
    if (paths[paths.length - 1] === "edit") {
      setIsPathEdit(true);
    }
  }, []);

  const handleAddContractService = (arrayHelpers: FieldArrayRenderProps) => {
    arrayHelpers.push("");
  };

  const getTreatmentPlanOptions = (): IDropdownOptions[] => [
    ...treatmentTemplates?.map((template) => ({
      label: template?.name,
      value: template?.id,
    })),
  ];

  const getServiceAmount = (serviceId?: number): string => {
    if (serviceId) {
      const service = services.find(
        (service: Service) => serviceId === service.id
      );
      if (service) return `$${service.minPrice} - $${service.maxPrice}`;
      else return "NA";
    } else return "NA";
  };

  const getTreatmentTemplate = (
    templateId?: number,
    templates = treatmentTemplates
  ) => {
    if (templateId)
      return templates?.find((template) => template?.id === templateId);
  };

  const handleFetchService = debounce(() => {
    if (pagination?.nextPage)
      ServiceService.fetchServices(
        (services: Service[], metrics, pagination) => {
          setServices(services);
          setPagination(pagination);

          const data = services.map((service) => ({
            value: service.id,
            label: service.name,
          }));

          setServiceOptions(uniqueObjectValue(data, serviceOptions));
        },
        () => {},
        () => {},
        { page: pagination?.nextPage }
      );
  }, 200);

  useEffect(() => {
    UserService.fetchUsers(
      {},
      (users: User[]) => {
        const doctors = users?.filter((doctor) => {
          return doctor?.roleName === "Doctor";
        });
        setDoctorOptions(
          doctors?.map((user: User) => ({
            label: user.getFullName(),
            value: user.profileId,
          }))
        );
      },
      () => {},
      () => {}
    );
    handleFetchService();

    setLoading(true);

    PatientService.fetchPatients(
      {},
      (patients: Patient[]) => {
        setPatientOptions(
          patients.map((patient) => ({
            label: patient.getFullName(),
            value: patient.id,
          }))
        );
      },
      () => {},
      () => {
        setLoading(false);
      }
    );

    TreatmentTemplateService.fetchTreatmentTemplate(
      {},
      (treatmentTemplates) => {
        setTreatmentTemplates([
          ...treatmentTemplates,
          ...(contract?.contractService?.treatmentPlans?.length
            ? [
                {
                  id: -1,
                  durationInMonths:
                    contract?.contractService?.treatmentPlans?.[0]
                      ?.durationInMonths,
                  name: contract?.contractService?.treatmentPlans?.[0]?.name,
                  noOfAppointments:
                    contract?.contractService?.treatmentPlans?.[0]
                      ?.noOfAppointments,
                  noOfBout:
                    contract?.contractService?.treatmentPlans?.[0]?.noOfBouts,
                  treatmentPlanTemplateServices: [],
                },
              ]
            : []),
        ]);
        setTreatmentTemplateOptions(
          treatmentTemplates?.map(({ id, name }) => ({
            label: name,
            value: id,
          }))
        );
      },
      () => {},
      () => {}
    );
  }, []);
  const sampleBots = [
    {
      label: "1 Bout Service",
      value: 1,
    },
    {
      label: "2 Bout Service",
      value: 2,
    },
    {
      label: "3 Bout Service",
      value: 3,
    },
  ];

  useEffect(() => {
    if (contract) {
      setFormValues(contract);
    }
    const patientId = new URLSearchParams(location.search).get("patientId");
    if (patientId) {
      setPatientId(true);
    }
    if (contract?.contractService?.treatmentPlans?.length) {
      setTreatmentTemplates((templates) => [
        ...templates,
        ...(contract?.contractService?.treatmentPlans?.length
          ? [
              {
                id: -1,
                name: contract?.contractService?.treatmentPlans?.[0]?.name,
                durationInMonths:
                  contract?.contractService?.treatmentPlans?.[0]
                    ?.durationInMonths,
                noOfAppointments:
                  contract?.contractService?.treatmentPlans?.[0]
                    ?.noOfAppointments,
                noOfBout:
                  contract?.contractService?.treatmentPlans?.[0]?.noOfBouts,
                treatmentPlanTemplateServices: [],
              },
            ]
          : []),
      ]);
    }
  }, [contract]);

  const handleCustomizeTreatmentPlan = (treatmentPlanId?: number) => {
    if (!HAS_TREATMENT_PLAN_VIEW_ACCESS) return;
    history.push(
      generatePath(AppRoutes.TREATMENT_PLAN_DETAILS, {
        id: treatmentPlanId,
      })
    );
  };

  const isEdit = (index: number) => {
    if (Number(contract?.contractService?.treatmentPlans?.length) - 1 < index) {
      return false;
    }

    const paths = location.pathname.split("/");
    if (paths[paths.length - 1] === "edit") {
      return true;
    }
    return false;
  };
  const getDefaultTreatmentPlan = (serviceId?: number): Service | undefined =>
    services?.find((service) => service?.id === serviceId);

  // const handleContractDelete = (fieldIndex: number) => {
  //   const data = contract?.contractServices.filter((service, index) => {
  //     return index != fieldIndex;
  //   });

  //   setContract({ ...contract, contractServices: data });
  //   setFormValues({ ...formValues, contractServices: data });
  // };
  return (
    <div className="select-service">
      <Row>
        <Formik
          initialValues={
            {
              ...formValues,
              name: formValues?.name ?? "Contract Sample",
              contractService: {
                ...formValues?.contractService,
                treatmentPlanTemplateIds: Number(
                  formValues?.contractService?.treatmentPlans?.length
                )
                  ? [-1]
                  : [],
              },
            } as Contract
          }
          onSubmit={handleSubmit}
          enableReinitialize
          validationSchema={contractServiceFormValidation}
        >
          {({ values, errors, isValid, dirty, setFieldValue }) => {
            return (
              <>
                {loading ? (
                  <AppLoader loading />
                ) : (
                  <Form className="select-service__form">
                    <div className="contract-form__card">
                      <div className="title">
                        <h4>{1}</h4>
                        <h4>Select Service</h4>
                      </div>

                      <Row gutter={[20, 0]}>
                        <Col span={8}>
                          <DropdownField
                            onPopupScroll={handleFetchService}
                            showSearch
                            title="Select Service"
                            name="contractService.serviceId"
                            options={serviceOptions}
                            value={values?.contractService?.serviceId}
                            onChange={(val) => {
                              setFieldValue("contractService.serviceId", val);
                              const defaultTreatmentPlan = getDefaultTreatmentPlan(
                                val
                              );
                              setFieldValue(
                                "contractService.treatmentPlanTemplateIds",
                                defaultTreatmentPlan?.defaultTreatmentPlanTemplateId
                                  ? [
                                      defaultTreatmentPlan?.defaultTreatmentPlanTemplateId,
                                    ]
                                  : []
                              );
                            }}
                            setFieldValue={setFieldValue}
                            placeHolder="Select Service"
                          />
                        </Col>

                        <Col span={8}>
                          <DropdownField
                            showSearch
                            title="Responsible Doctor"
                            name="doctorProfileId"
                            options={doctorOptions}
                            value={values.doctorProfileId}
                            setFieldValue={setFieldValue}
                            placeHolder="Select Doctor"
                          />
                        </Col>
                        <Col span={8}>
                          <DropdownField
                            showSearch
                            title="Patient"
                            loading={loading}
                            name="patientProfileId"
                            options={patientOptions}
                            value={values.patientProfileId}
                            setFieldValue={setFieldValue}
                            disabled={isPAthEdit}
                            placeHolder="Select Patient"
                          />
                        </Col>
                      </Row>
                      <h5 className="text-secondary">
                        Treatment Execution Plan
                      </h5>
                      <div className="select-service__services">
                        <Row
                          className="select-service__service-header mb-5 ml-3"
                          gutter={[20, 10]}
                        >
                          <Col span={6}>
                            <div className="text-center">
                              Treatment Execution Plan
                            </div>
                          </Col>
                          <Col span={6}>
                            <div className="text-center">Service Period</div>
                          </Col>
                          <Col span={6}>
                            <div className="text-center">
                              No of appointments
                            </div>
                          </Col>
                        </Row>
                        <div className="select-service__service-body">
                          <FieldArray
                            name="contractService.treatmentPlanTemplateIds"
                            render={(arrayHelpers) => (
                              <div className="select-service__contract-services">
                                {values.contractService &&
                                  Number(
                                    values.contractService
                                      ?.treatmentPlanTemplateIds?.length
                                  ) > 0 &&
                                  values.contractService?.treatmentPlanTemplateIds?.map(
                                    (
                                      contractService: any,
                                      fieldIndex: number
                                    ) => {
                                      return (
                                        <Row
                                          key={fieldIndex}
                                          gutter={[20, 10]}
                                          className="select-service__service-field"
                                        >
                                          <Col span={6}>
                                            <DropdownField
                                              name={`contractService.treatmentPlanTemplateIds[${fieldIndex}]`}
                                              // options={(() => {
                                              //   const selectedServices = values.contractServices.map(
                                              //     ({ serviceId }) => serviceId
                                              //   );
                                              //   selectedServices.splice(
                                              //     fieldIndex,
                                              //     1
                                              //   );
                                              //   const filteredOptions = serviceOptions.filter(
                                              //     ({ value }) => {
                                              //       return !selectedServices.includes(
                                              //         value as number
                                              //       );
                                              //     }
                                              //   );
                                              //   return filteredOptions;
                                              // })()}
                                              options={getTreatmentPlanOptions()}
                                              // value={
                                              //   values.contractServices &&
                                              //   values.contractServices[
                                              //     fieldIndex
                                              //   ]
                                              //     ? values.contractServices[
                                              //         fieldIndex
                                              //       ].serviceId
                                              //     : undefined
                                              // }
                                              value={
                                                values.contractService
                                                  ?.treatmentPlanTemplateIds?.[
                                                  fieldIndex
                                                ]
                                              }
                                              setFieldValue={setFieldValue}
                                              placeHolder="Select Treatment Plan"
                                            />
                                          </Col>
                                          {/* <Col span={5}>
                                        {isEdit(fieldIndex) ? (
                                          <p>
                                            {
                                              values.contractServices[
                                                fieldIndex
                                              ].treatmentPlanName
                                            }
                                          </p>
                                        ) : (
                                          <DropdownField
                                            name={`contractServices[${fieldIndex}].treatmentPlanTemplateId`}
                                            options={treatmentTemplateOptions}
                                            value={
                                              values.contractServices &&
                                              values.contractServices[
                                                fieldIndex
                                              ]
                                                ? values.contractServices[
                                                    fieldIndex
                                                  ].treatmentPlanTemplateId
                                                : undefined
                                            }
                                            disabled={
                                              !!(
                                                values &&
                                                values.contractServices &&
                                                values.contractServices[
                                                  fieldIndex
                                                ] &&
                                                values.contractServices[
                                                  fieldIndex
                                                ].maxNoToUtilise
                                              )
                                            }
                                            setFieldValue={setFieldValue}
                                            placeHolder="Select Template"
                                          />
                                        )}
                                      </Col> */}
                                          <Col span={6}>
                                            <div className="text-bold text-center">
                                              {getTreatmentTemplate(
                                                values?.contractService
                                                  ?.treatmentPlanTemplateIds?.[
                                                  fieldIndex
                                                ]
                                              )
                                                ? Number(
                                                    getTreatmentTemplate(
                                                      values?.contractService
                                                        ?.treatmentPlanTemplateIds?.[
                                                        fieldIndex
                                                      ]
                                                    )?.durationInMonths
                                                  ) + " months"
                                                : "-"}
                                            </div>
                                          </Col>
                                          <Col span={6}>
                                            <div className="text-bold text-center">
                                              {getTreatmentTemplate(
                                                values?.contractService
                                                  ?.treatmentPlanTemplateIds?.[
                                                  fieldIndex
                                                ]
                                              )?.noOfAppointments ?? "-"}
                                            </div>
                                          </Col>
                                          {/* <Col span={6}>
                                        <InputField
                                          type="number"
                                          name={`contractService.treatmentPlans[${fieldIndex}].noOfBouts`}
                                          placeholder="Enter Service Period"
                                        />
                                      </Col>
                                      <Col span={6}>
                                        <InputField
                                          type="number"
                                          name={`contractService.treatmentPlans[${fieldIndex}].noOfAppointments`}
                                          placeholder="Enter Number of Appointments"
                                        />
                                      </Col> */}
                                          {/* <Col span={3}>
                                        {values.contractServices &&
                                        values.contractServices[fieldIndex]
                                          ? getServiceAmount(
                                              values.contractServices[
                                                fieldIndex
                                              ].serviceId
                                            )
                                          : "NA"}
                                      </Col> */}
                                          <Col span={5}>
                                            <Button
                                              // className="edit-btn"
                                              type="primary"
                                              // icon={<i className="icon-edit" />}
                                            />
                                            {values?.contractService
                                              ?.treatmentPlanTemplateIds?.[
                                              fieldIndex
                                            ] === -1 && HAS_TREATMENT_PLAN_VIEW_ACCESS && HAS_PATIENT_CONTRACT_EDIT_ACCESS && (
                                              <img
                                                alt=""
                                                className="edit-btn"
                                                src={externalLinkIcon}
                                                onClick={() =>
                                                  handleCustomizeTreatmentPlan(
                                                    values.contractService
                                                      ?.treatmentPlans?.[
                                                      fieldIndex
                                                    ]?.id
                                                  )
                                                }
                                              />
                                            )}

                                            {HAS_PATIENT_CONTRACT_EDIT_ACCESS && <Button
                                              onClick={() => {
                                                const templates =
                                                  values?.contractService?.treatmentPlanTemplateIds?.filter(
                                                    (_, index) =>
                                                      index !== fieldIndex
                                                  ) ?? [];
                                                setFieldValue(
                                                  "treatmentPlanTemplateIds",
                                                  templates
                                                );
                                                arrayHelpers.remove(fieldIndex);
                                              }}
                                              className="delete-btn"
                                              icon={<DeleteOutlined />}
                                            />}
                                          </Col>
                                        </Row>
                                      );
                                    }
                                  )}
                                <div>
                                  {values?.contractService?.serviceId &&
                                    Number(
                                      values?.contractService
                                        ?.treatmentPlanTemplateIds?.length
                                    ) < 1 && (
                                      <Button
                                        className="secondary-btn ml-3"
                                        htmlType="button"
                                        onClick={() => {
                                          handleAddContractService(
                                            arrayHelpers
                                          );
                                        }}
                                      >
                                        <PlusOutlined /> Add Treatment Plan
                                      </Button>
                                    )}
                                </div>
                              </div>
                            )}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="select-service__submit-wrapper text-right mt-5">
                      <Button type="default" onClick={onCancel}>
                        Cancel
                      </Button>
                      <Button
                        type="primary"
                        className="ml-2"
                        htmlType="submit"
                        loading={formLoading}
                        disabled={!isValid || formLoading}
                      >
                        Next
                      </Button>
                    </div>
                  </Form>
                )}
              </>
            );
          }}
        </Formik>
      </Row>
    </div>
  );
};

export default ContractContainer(SelectService);
