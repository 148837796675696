import { AutoComplete, Input } from "antd";
import React, { FC, Fragment, useState } from "react";
import { SelectProp } from "../../Types/option";
import "./searchbar.scss";
interface SearchbarProps {
	title?: string;
	options: SelectProp[];
	fullWidth?: boolean;
	value?: string;
	edit?: boolean;
	selectHandler: (value: number | string) => void;
	searchHandler: (value: string) => void;
	loading?: boolean;
}

const Searchbar: FC<SearchbarProps> = ({
	title,
	searchHandler,
	options,
	selectHandler,
	fullWidth = false,
	value,
	edit,
	loading
}) => {
	const [classes, setClasses] = useState(false);

	const handleSearchFocus = () => {
		setClasses((state) => !state);
	};
	const handleSelect = (_: string, option: any) => {
		selectHandler(option?.key);
	};

	if (edit && !value) {
		return <div className="auto-complete__title mb-2">{title}</div>;
	}

	return (
		<Fragment>
			{title && <div className="auto-complete__title mb-2">{title}</div>}
			<AutoComplete
				className={`search-autocomplete ${
					classes && "dashboard__search__focused"
				} ${fullWidth && "full-width"}`}
				options={options}
				dropdownMatchSelectWidth={700}
				filterOption={(inputValue, option) =>
					option!.value
						.toUpperCase()
						.indexOf(inputValue.toUpperCase()) !== -1
				}
				onSelect={handleSelect}
				defaultValue={value}
			>
				<Input.Search
					type="search"
					placeholder="Search"
					onSearch={searchHandler}
					loading={loading}
					style={{
						width: fullWidth ? "100% !important" : 250,
						border: fullWidth ? "1px solid #bebebe" : "none",
						borderRadius: 8,
					}}
					onFocus={handleSearchFocus}
					onBlur={handleSearchFocus}
					allowClear
				/>
			</AutoComplete>
		</Fragment>
	);
};

export default Searchbar;
