import React, { Fragment, useEffect, useState, FC } from "react";
import { Table, Switch, Button, Drawer } from "antd";
import { Resource } from "../../../../models/resource/resource.model";
import { CheckOutlined, CloseOutlined, PlusOutlined } from "@ant-design/icons";
import ResourceService from "../../../../services/Resource/resource.service";
import "./resource.scss";
import ResourceForm from "./ResourceForm";
import ViewResource from "./ResourceDetails";
import { Modal } from "antd";
import { StatusEnum } from "../../../../enums/status.enum";
import EmptyPlaceholder from "../../../../shared/components/EmptyPlaceholder";
import { AuthReducerProps } from "../../../../store/reducers/authReducer";
import AuthContainer from "../../../../store/container/AuthContainer";
import { ResponsibilityTypes } from "../../../../enums/responsebily.enum";
import { TableColumn } from "../../../../shared/Types/tableColumn";
import Stats from "../../../../shared/components/Stats";
import { StatsType } from "../../../../enums/statsType.enum";
import { useMetrics } from "../../../../shared/hooks/Metrics/useMetrics";
import AppLoader from "../../../../shared/components/AppLoader";
import { removeUnderscore } from "../../../../shared/utils/removeUnderscore";
import MetricsService from "../../../../services/Metrics/metrics.service";
import { Metrics } from "../../../../models/Metrics/metrics.model";
import { PaginationDetails } from "../../../../models/Pagination/pagination.model";
import useFilters from "../../../../shared/hooks/useFilter/useFilters";
import { TablePaginationConfig } from "antd/lib/table";
import { FilterParams } from "../../../../models/FilterParams/filterParams.model";
import useResponsibilities from "../../../../shared/hooks/Responsibilities/useResponsibilities";

const { confirm } = Modal;

interface ResourceProps extends AuthReducerProps {}

const ResourceList: FC<ResourceProps> = ({}) => {
  const practiceId = localStorage.getItem("practiceId");
  const { hasAccess } = useResponsibilities();
  const [resources, setResources] = useState<Resource[]>([]);
  const [resourceVisiblity, setResourceVisiblity] = useState(false);
  const [showResource, setShowResource] = useState(false);
  const [metrics, setMetrics] = useState<Metrics>();
  const [pagination, setPagination] = useState<PaginationDetails>();
  const { getParams, setParams } = useFilters();
  const [resource, setResource] = useState<Resource>();
  const [editResource, setEditResource] = useState<{
    resource: Resource;
    index: number;
  } | null>(null);
  const [loader, setLoader] = useState(false);

  const HAS_RESOURCE_VIEW_ACCESS = hasAccess(ResponsibilityTypes.RESOURCE_VIEW);

  const HAS_RESOURCE_CREATE_ACCESS = hasAccess(
    ResponsibilityTypes.RESOURCE_CREATE
  );

  const HAS_RESOURCE_EDIT_ACCESS = hasAccess(ResponsibilityTypes.RESOURCE_EDIT);

  const HAS_RESOURCE_DELETE_ACCESS = hasAccess(
    ResponsibilityTypes.RESOURCE_DELETE
  );

  const handleFetchResources = (filter: FilterParams) => {
    setLoader(true);
    ResourceService.fetchResources(
      (resources, pagination) => {
        setResources(resources);
        setPagination(pagination);
        handleFetchMetrics();
      },
      () => {},
      () => {
        setLoader(false);
      },
      filter
    );
  };

  const handleTableChange = (pagination: TablePaginationConfig) => {
    const prevFilter = getParams();
    const updatedFilters = {
      ...prevFilter,
      page: pagination.current ?? 1,
    };
    setParams(updatedFilters);
    handleFetchResources(updatedFilters);
  };
  const handleFetchMetrics = () => {
    setLoader(true);
    MetricsService.fetchMetricsWithPractice(
      "resources",
      (metrics: Metrics) => {
        setMetrics(metrics);
      },
      () => {},
      () => {
        setLoader(false);
      }
    );
  };
  useEffect(() => {
    handleFetchResources(getParams());
    handleFetchMetrics();
  }, []);

  const handleEditResource = (resource: Resource, index: number) => {
    setEditResource({
      resource,
      index,
    });
    handleFetchMetrics();
    setResourceVisiblity(true);
  };

  const [columns, setColumns] = useState<TableColumn[]>([
    {
      title: "Resource Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Practice Name",
      dataIndex: "practiceName",
      key: "practiceName",
    },
    {
      title: "Type",
      dataIndex: "resourceTypeName",
      key: "resourceTypeName",
      render: (type: string, resource: Resource) => {
        return removeUnderscore(type);
      },
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "",
      dataIndex: "edit",
      key: "edit",
      render: (_: null, record: Resource, index: number) => {
        return (
          <Fragment>
            <i
              className={`icon-edit resource-edit-icon ${
                !HAS_RESOURCE_EDIT_ACCESS && "display-none"
              }`}
              onClick={(event) => {
                if (!HAS_RESOURCE_EDIT_ACCESS) return;
                event.stopPropagation();
                handleEditResource(record, index);
              }}
            />
            <i
              className={`icon-delete resource-delete-icon  ${
                !HAS_RESOURCE_DELETE_ACCESS && "display-none"
              }`}
              onClick={(event) => {
                if (!HAS_RESOURCE_DELETE_ACCESS) return;

                event.stopPropagation();
                confirm({
                  content: `Are you sure you want to delete ${record.name}`,
                  okText: "Yes",
                  cancelText: "No",
                  onOk: () => {
                    ResourceService.deleteResource(
                      record,
                      () => {
                        handleFetchResources(getParams());
                      },
                      () => {},
                      () => {}
                    );
                    handleFetchResources(getParams());
                    handleFetchMetrics();
                  },
                });
              }}
            />
          </Fragment>
        );
      },
    },
    {
      title: "",
      dataIndex: "status",
      key: "status",
      render: (status: boolean, record: Resource, index: number) => {
        const handleOnCheck = (checked: boolean) => {
          confirm({
            title: `Are you sure you want to ${
              checked ? "enable" : "disable"
            } this resource?`,
            onOk: () => {
              record.status = checked ? StatusEnum.ACTIVE : StatusEnum.INACTIVE;
              setLoader(true);
              ResourceService.updateResource(
                record,
                (resource) => {
                  setResources((resources) => {
                    resources.splice(index, 1, resource);
                    return [...resources];
                  });
                  handleFetchResources(getParams());
                  handleFetchMetrics();
                },
                () => {},
                () => {
                  setLoader(false);
                }
              );
            },
          });
        };
        return (
          <span
            onClick={(event) => event.stopPropagation()}
            className={`${!HAS_RESOURCE_EDIT_ACCESS && "display-none"}`}
          >
            {HAS_RESOURCE_EDIT_ACCESS && (
              <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                checked={record.status === "active"}
                onChange={handleOnCheck}
              />
            )}
          </span>
        );
      },
    },
  ]);

  const handleSave = (resource: Resource, type: string) => {
    handleFetchMetrics();
    handleFetchResources(getParams());
    // if (type === "add" && resource?.id === Number(practiceId)) {
    //   setResources((resources) => [...resources, resource]);
    //   handleCloseResourceVisiblity();
    //   return;
    // }
    // setResources((resources) => {
    //   resources.splice(editResource?.index || 0, 1, resource);
    //   return [...resources];
    // });
    handleCloseResourceVisiblity();
  };

  const handleCloseResourceVisiblity = () => {
    setEditResource(null);
    setResourceVisiblity(false);
  };

  const handleOnRowClick = (resource: Resource, index: number | undefined) => {
    return {
      onClick: () => {
        setResource(resource);
        setEditResource({ resource, index: index as number });
        setShowResource(true);
      },
    };
  };

  const handleCloseShowResource = () => {
    setShowResource(false);
    setResource(undefined);
    setEditResource(null);
  };
  const resourcesStatsData = [
    {
      title: "Total Chairs",
      value: metrics?.totalChairs ?? 0,
    },
    {
      title: "Total Rooms",
      value: metrics?.totalRooms ?? 0,
    },
    {
      title: "Total Scan Facilities",
      value: metrics?.totalScanFacility ?? 0,
    },
  ];

  return (
    <div className="resource__container">
      <Drawer
        visible={HAS_RESOURCE_EDIT_ACCESS && resourceVisiblity}
        title={editResource ? "Edit Resource" : "Add Resource"}
        destroyOnClose
        onClose={handleCloseResourceVisiblity}
        width={"75vw"}
      >
        <ResourceForm
          fromValue={editResource?.resource}
          onSaveHandler={(resource: Resource, type: string) => {
            handleSave(resource, type);
          }}
          closeHandler={handleCloseResourceVisiblity}
        />
      </Drawer>

      <Drawer
        title="View Resources"
        width={"75vw"}
        visible={HAS_RESOURCE_VIEW_ACCESS && showResource}
        destroyOnClose
        onClose={handleCloseShowResource}
      >
        <ViewResource
          resource={resource || new Resource()}
          editResourceHandler={() => {
            setShowResource(false);
            setResourceVisiblity(true);
          }}
          onResourceChange={(resource) => {
            setResources((existingResource) =>
              existingResource.map((oldResource) => {
                if (oldResource?.id === resource?.id) return resource;
                return oldResource;
              })
            );
            setResource(resource);
          }}
        />
      </Drawer>

      <div className="resource-header__wrapper">
        <h4>All Resources</h4>
        {HAS_RESOURCE_CREATE_ACCESS && (
          <Button
            type="primary"
            onClick={() => {
              setResourceVisiblity(true);
            }}
          >
            <PlusOutlined className="add-resource-icon" /> Add Resource
          </Button>
        )}
      </div>
      {loader ? (
        <AppLoader loading />
      ) : (
        <Stats statsData={resourcesStatsData} statsType={StatsType.RESOURCES} />
      )}
      {resources.length ? (
        <Table
          onRow={handleOnRowClick}
          dataSource={resources}
          columns={columns}
          onChange={handleTableChange}
          pagination={{
            total: pagination?.totalCount,
            current: pagination?.currentPage ?? 1,
            showTotal: (total: number, range: [number, number]) => (
              <p>
                Showing <b>{` ${range[0]} - ${range[1]}`}</b> of{" "}
                <b>{`${total.toLocaleString()}`}</b>
              </p>
            ),
          }}
        />
      ) : (
        <EmptyPlaceholder
          title="Resources"
          description="Add resources description here"
          image=""
          btnText="Add Resource"
          btnVisible={HAS_RESOURCE_CREATE_ACCESS}
          onClickHandler={() => {
            setResourceVisiblity(true);
          }}
        />
      )}
    </div>
  );
};

export default AuthContainer(ResourceList);
