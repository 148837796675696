import React, { useEffect } from "react";
import "./procedureViewer.scss";
import ProcedureContainer from "../../../../../store/container/ProcedureContainer";
import BabylonContainer from "../../../../../store/container/BabylonContainer";
import { BabylonReducerProps } from "../../../../../store/reducers/babylonReducer";
import { ProcedureReducerProps } from "../../../../../store/reducers/procedureReducer";
import { ActionManager } from "@babylonjs/core";
import {
  handleTeethLeftClick,
  handleTeethRightClick,
} from "../../../../../shared/utils/babylonUtils";
import { Popover } from "antd";
import ProcedureStepForm from "../ProcedureStepForm";
import { ProcedureSetFormTypeEnum } from "../../../../../enums/procedureSetFormType.enum";
import { ProcedureStep } from "../../../../../models/ProcedureStep/procedureStep.model";
import CustomBabylonScene from "../../../../../shared/components/CustomBabylonScene";
import { MeshTriggerHandler } from "../../../../../shared/Types/meshActions.type";
import { updateTreatmentProcedureSteps } from "../../../../../shared/utils/integrateTreatmentSteps";
import useResponsibilities from "../../../../../shared/hooks/Responsibilities/useResponsibilities";
import { ResponsibilityTypes } from "../../../../../enums/responsebily.enum";
import CorporateContainer from "../../../../../store/container/CorporateContainer";
import { CorporateReducerProps } from "../../../../../store/reducers/corporateReducer";
import { getMeshType } from "../../../../../shared/utils/patientCategories";

interface ProcedureViewerProps
  extends BabylonReducerProps,
    CorporateReducerProps,
    ProcedureReducerProps {
  setOpenConflict: (open: boolean) => void;
  createProcedureStep: (step: ProcedureStep, complete?: () => void) => void;
}

function ProcedureViewer({
  currentProcedure,
  caps,
  scene,
  setSourceObject,
  corporateObjects,
  showPopover,
  setShowPopover,
  popoverPosition,
  setPopoverPosition,
  resetBabylon,
  createProcedureStep,
  setOpenConflict,
  setMeshLoading,
  corporateObjectsLoading,
  capsLoading,
  practiceId,
}: ProcedureViewerProps) {
  const { hasAccess } = useResponsibilities();

  const IS_ACTIVE_PRACTICE_SAME =
    Number(practiceId) === Number(currentProcedure?.practiceId);

  const HAS_PROCEDURE_EDIT_ACCESS =
    hasAccess(ResponsibilityTypes.PROCEDURE_EDIT) && IS_ACTIVE_PRACTICE_SAME;

  const MESH_TYPE = currentProcedure
    ? getMeshType(currentProcedure?.patientCategory)
    : "";

  const handleAddStepSuccess = () => setShowPopover(false);

  const isLoadMeshToScene =
    !!MESH_TYPE && !!scene && !!corporateObjects?.length && !!caps.length && !corporateObjectsLoading && !capsLoading;

  const handleMeshLeftClick: MeshTriggerHandler = (props) =>
    handleTeethLeftClick(props, corporateObjects, setSourceObject);

  const handleMeshRightClick: MeshTriggerHandler = (props) => {
    if (!HAS_PROCEDURE_EDIT_ACCESS) return;

    handleTeethRightClick(
      props,
      corporateObjects,
      setSourceObject,
      setShowPopover,
      setPopoverPosition
    );
  };

  const handleLoadedMesh = async () => {
    if (!scene || !isLoadMeshToScene) return;

    try {
      setMeshLoading(true);
      await updateTreatmentProcedureSteps({
        caps,
        corporateObjects,
        scene,
        setPopoverPosition,
        setShowPopover,
        setSourceObject,
        steps: currentProcedure?.procedureSteps,
      });
    } catch (ex) {
    } finally {
      setMeshLoading(false);
    }
  };

  useEffect(() => {
    setMeshLoading(true);
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
      resetBabylon();
    };
  }, []);

  return (
    <div className="procedure-viewer">
      <Popover
        overlayClassName="procedure-viewer__popover"
        content={
          showPopover && (
            <ProcedureStepForm
              type={ProcedureSetFormTypeEnum.OBJECT_LINK}
              setOpenConflict={setOpenConflict}
              onSuccess={handleAddStepSuccess}
              createProcedureStep={createProcedureStep}
            />
          )
        }
        visible={showPopover}
        arrowContent={null}
        overlayInnerStyle={{
          position: "fixed",
          top: `calc(${popoverPosition.y}px + 10rem)`,
          left: popoverPosition.x + "px",
        }}
      />
      <CustomBabylonScene
        actions={{
          [ActionManager.OnLeftPickTrigger]: handleMeshLeftClick,
          [ActionManager.OnRightPickTrigger]: handleMeshRightClick,
        }}
        loadMeshToScene={isLoadMeshToScene}
        meshType={MESH_TYPE}
        mouthSliderVisible
        onMeshLoad={handleLoadedMesh}
      />
    </div>
  );
}

export default BabylonContainer(
  CorporateContainer(ProcedureContainer(ProcedureViewer))
);
