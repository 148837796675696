import { Drawer, Table } from "antd";
import React, { FC, useEffect, useState } from "react";
import {
  WorkItem,
  WorkItemsFilters,
} from "../../../models/WorkItem/workItem.model";
import { WorkItemService } from "../../../services/WorkItem/workItem.service";
import moment from "moment";
import "./workItems.scss";
import ShowWorkItem from "./ShowWorkItem";
import StartInteractionForm from "./StartInteractionForm";
import RejectInteractionForm from "./RejectInteractionForm";
import AppLoader from "../../../shared/components/AppLoader";
import UserPlaceholder from "../../../assets/images/userPlaceholder.png";
import TreatmentSummary from "../../Interactions/TreatmentSummary";
import AuthContainer from "../../../store/container/AuthContainer";
import { AuthReducerProps } from "../../../store/reducers/authReducer";
import { ResponsibilityTypes } from "../../../enums/responsebily.enum";
import { generatePath, useHistory, useLocation } from "react-router-dom";
import AppRoutes from "../../../routes/routeConstants/appRoutes";
import { formatTime } from "../../../shared/utils/time";
import useResponsibilities from "../../../shared/hooks/Responsibilities/useResponsibilities";
import { compareStringByIgnoringCase } from "../../../shared/utils/helpers";
import { InteractionStatus } from "../../../enums/interactionStatus.enum";
import { Filter } from "..";
import useFilters from "shared/hooks/useFilter/useFilters";
interface WorkItemsProps extends AuthReducerProps {
  tab?: string;
  filters: Filter;
}
const WorkItems: FC<WorkItemsProps> = ({
  tab,
  userResponsibilities,
  filters,
}) => {
  const location: {
    state: {
      openChecklist: boolean;
      workItem: boolean;
      interactionId: string;
      workItemId: string;
    };
  } = useLocation();

  const { getParams } = useFilters();

  const history = useHistory();

  const { hasAccess } = useResponsibilities();

  const [loader, setLoader] = useState(false);

  const [workItems, setWorkItems] = useState<WorkItem[]>([]);

  const [workItem, setWorkItem] = useState<WorkItem>();

  const [workItemVisibility, setWorkItemVisibility] = useState(false);

  const [completeReview, setCompleteReview] = useState(false);

  const [startInteractionVisibility, setStartInteractionVisibility] = useState(
    false
  );

  const [currentPage, setCurrentPage] = useState(1);

  const [
    rejectInteractionVisibility,
    setRejectInteractionVisibility,
  ] = useState(false);

  const HAS_INTERACTION_VIEW_ACCESS = hasAccess(
    ResponsibilityTypes.INTERACTION_VIEW
  );

  const HAS_DENTAL_OBJ_VIEW_ACCESS = hasAccess(
    ResponsibilityTypes.DENTAL_OBJ_VIEW
  );

  const HAS_START_INTERACTION_ACCESS = hasAccess(
    ResponsibilityTypes.START_INTERACTION_CREATE
  );

  const fetchWorkItems = (newFilters = filters || getParams()) => {
    if (tab == "1") {
      setLoader(true);
      WorkItemService.fetchWorkItems(
        newFilters,
        (workItems) => {
          setWorkItems(workItems);
        },
        () => {},
        () => {
          setLoader(false);
        }
      );
    }
  };

  useEffect(() => {
    if (location.state?.interactionId) {
      setCompleteReview(true);
    }
  }, []);

  useEffect(() => {
    fetchWorkItems();
  }, [tab, filters]);

  const columns = [
    {
      title: "Work Item Type",
      dataIndex: "type",
      key: "type",
      render: (type: string, workItem: WorkItem) => {
        if (!workItem.startTime) return "--";
        const time = moment(workItem.endTime).diff(
          moment(workItem.startTime),
          "minutes"
        );
        return (
          <div className="work-item__type">
            <p className="text-capitalize text-secondary">
              {type?.split("_")?.join(" ")}
            </p>
            <p>
              Due for <i className="icon-time work-item__timer" /> {time}{" "}
              Minutes
            </p>
          </div>
        );
      },
    },
    {
      title: "Work Item ID",
      dataIndex: "id",
      key: "id",
      render: (id: number) => `KHDFKW${id}`,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (description: string, workItem: WorkItem) => {
        if (!description) return "--";
        const getCount = (visit: number) => {
          switch (visit) {
            case 1:
              return `${visit}st`;
            case 2:
              return `${visit}nd`;

            case 3:
              return `${visit}rd`;
            default:
              return `${visit}th`;
          }
        };
        return (
          <div className="work-item__description">
            <p>{workItem.description}</p>{" "}
            {workItem.visitCount && (
              <p>{getCount(parseInt(workItem.visitCount as string))}</p>
            )}
            {workItem.isEmergency ? (
              <i className="icon-flag work-item__flag" />
            ) : null}
          </div>
        );
      },
    },
    {
      title: "Patient",
      dataIndex: "Working Item",
      key: "paymentStatus",
      render: (_: null, workItem: WorkItem) => {
        if (!workItem.patientFirstName) return "--";
        return (
          <span className="patient-details__table-cell">
            <img
              className="image"
              src={workItem.patientProfilePicUrl ?? UserPlaceholder}
              alt=""
            />
            <p>{`${workItem.patientFirstName} ${workItem.patientLastName}`} </p>
          </span>
        );
      },
    },
    {
      title: "Date & Time",
      dataIndex: "date",
      key: "date",
      render: (_: any, workItem: WorkItem) => {
        return (
          <div>
            <span>{moment(workItem.date).format("MM/DD/YYYY")}</span>{" "}
            &nbsp;&nbsp;
            <span>
              {formatTime(workItem.startTime)} - {formatTime(workItem.endTime)}
            </span>
          </div>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status: string) => (
        <span
          className={`text-capitalize ${
            status === "completed" ? "success-text" : "warning-text"
          }`}
        >
          {status}
        </span>
      ),
    },
  ];
  const handleOnRow = (workItem: WorkItem) => {
    return {
      onClick: () => {
        setWorkItem(workItem);
        const IS_INTERACTION_IN_PROGRESS =
          workItem?.interaction?.status &&
          compareStringByIgnoringCase(
            workItem?.interaction?.status,
            InteractionStatus.IN_PROGRESS
          );

        if (IS_INTERACTION_IN_PROGRESS) {
          return (
            HAS_INTERACTION_VIEW_ACCESS &&
            HAS_DENTAL_OBJ_VIEW_ACCESS &&
            history.push(
              generatePath(AppRoutes.INTERACTION, {
                patientProfileId: workItem?.patientProfileId,
                interactionId: workItem?.assignableId,
              })
            )
          );
        }
        if (workItem.type === "review_interaction") {
          setCompleteReview(true);
          return;
        }
        setWorkItemVisibility(true);
      },
    };
  };

  const handleCloseShowWorkItem = () => {
    setWorkItemVisibility(false);
  };

  const handleCloseStartInteraction = () => {
    fetchWorkItems();
    setStartInteractionVisibility(false);
  };

  const handleCloseRejectInteraction = () => {
    fetchWorkItems();
    setRejectInteractionVisibility(false);
  };

  const handleAccept = () => {
    WorkItemService.acceptWorkItems(
      workItem as WorkItem,
      () => {
        handleCloseShowWorkItem();
        if (HAS_START_INTERACTION_ACCESS) setStartInteractionVisibility(true);
      },
      () => {},
      () => {}
    );
  };

  const handleReject = () => {
    handleCloseShowWorkItem();
    setRejectInteractionVisibility(true);
  };

  const handleCloseCompleteReview = () => {
    fetchWorkItems();
    setCompleteReview(false);
  };

  return (
    <div className="work-item__container">
      {loader ? (
        <AppLoader loading />
      ) : (
        <Table dataSource={workItems} columns={columns} onRow={handleOnRow} />
      )}

      <Drawer
        title="Work Item"
        visible={workItemVisibility}
        width={"75vw"}
        destroyOnClose
        onClose={handleCloseShowWorkItem}
      >
        <ShowWorkItem
          workItem={workItem ?? new WorkItem()}
          acceptHandler={handleAccept}
          rejectHandler={handleReject}
        />
      </Drawer>
      <Drawer
        title="Start Interaction"
        visible={startInteractionVisibility}
        width={"75vw"}
        destroyOnClose
        onClose={handleCloseStartInteraction}
      >
        <StartInteractionForm
          workItem={workItem ?? new WorkItem()}
          closeHandler={handleCloseStartInteraction}
        />
      </Drawer>
      <Drawer
        title="Reject Interaction"
        visible={rejectInteractionVisibility}
        width={"75vw"}
        destroyOnClose
        onClose={handleCloseRejectInteraction}
      >
        <RejectInteractionForm
          workItem={workItem ?? new WorkItem()}
          closeHandler={handleCloseRejectInteraction}
        />
      </Drawer>
      <Drawer
        title="Treatment Summary"
        visible={completeReview}
        width={"75vw"}
        destroyOnClose
        onClose={handleCloseCompleteReview}
      >
        <TreatmentSummary
          workItemId={workItem?.id ?? location.state?.workItemId}
          interactionId={
            (workItem?.interaction?.id as number) ??
            location.state?.interactionId
          }
          completeInteraction
          closeHandler={handleCloseCompleteReview}
        />
      </Drawer>
    </div>
  );
};

export default AuthContainer(WorkItems);
