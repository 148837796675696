import React, {
  ChangeEventHandler,
  FC,
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useParams } from "react-router";
import { XRayNote } from "../../../models/XRayNote/xRayNote.model";
import { NoteTemplateService } from "../../../services/NoteTemplate/noteTemplate.service";
import XrayNotesService from "../../../services/XRayNotes/xRayNotes.service";
import Note from "../../../shared/components/Note";
import { Button, Modal, Popover } from "antd";
import "./xRayNotes.scss";
import XRayNotesForm from "./XRayNotesForm";
import { XRayType } from "../InteractionDetail";
import XrayImagesService from "../../../services/XRayImages/xRayImages.service";
import { XRayImage } from "../../../models/XRayImage/xRayImage.model";
import CorporateContainer from "../../../store/container/CorporateContainer";
import { CorporateReducerProps } from "../../../store/reducers/corporateReducer";
import useResponsibilities from "../../../shared/hooks/Responsibilities/useResponsibilities";
import { ResponsibilityTypes } from "../../../enums/responsebily.enum";

const { warning } = Modal;

interface XRayNotesProps extends CorporateReducerProps {
  interactionId: string;
  updateXrayNote?: boolean;
  updateXrayImage?: boolean;
  visitNo?: number;
}

const XRayNotes: FC<XRayNotesProps> = (props) => {
  const {
    interactionId,
    updateXrayImage,
    updateXrayNote,
    practiceId,
    visitNo,
  } = props;

  const { hasAccess } = useResponsibilities();

  const [xRayTemplates, setXRayTemplates] = useState<XRayNote[]>([]);

  const [xRayImages, setXRayImages] = useState<XRayImage[]>([]);

  const HAS_INTERACTION_EDIT_ACCESS = hasAccess(
    ResponsibilityTypes.INTERACTION_EDIT
  );

  const fetchXrayImages = () => {
    XrayImagesService.fetchXrayImages(
      {
        practiceId: practiceId?.toString() ?? "",
        interactionId: interactionId,
      },
      (xRayImages) => {
        setXRayImages(xRayImages);
      },
      () => {},
      () => {}
    );
  };

  const updateXrayNotes = useCallback(() => {
    XrayNotesService.fetchXrayNotes(
      Number(interactionId),
      (xRayNotes) => {
        setXRayTemplates(xRayNotes);
      },
      () => {},
      () => {}
    );
  }, [interactionId]);

  useEffect(() => {
    updateXrayNotes();
  }, [updateXrayNotes, updateXrayNote]);

  useEffect(() => {
    fetchXrayImages();
  }, [updateXrayImage]);

  const handleDelete = (index: number, id?: number, title?: string) => {
    warning({
      content: `Are you sure you want to delete ${title}`,
      maskClosable: true,
      okText: "Delete",
      okType: "danger",
      onOk: () => {
        XrayNotesService.deleteXrayNote(
          id ?? 0,
          Number(interactionId),
          () => {
            setXRayTemplates((xRayNotes) => {
              xRayNotes.splice(index, 1);
              return [...xRayNotes];
            });
          },
          () => {},
          () => {}
        );
      },
    });
  };

  const getXrayImage = () => {
    const xRayImage = xRayImages?.find(
      (xRayImages) => xRayImages.visitNo === visitNo
    );
    return xRayImage ? xRayImage : xRayImages?.length ? xRayImages[0] : null;
  };

  return (
    <div className="x-ray-notes">
      {xRayTemplates.map(({ id, notes, xRayTypeName, createdAt }, index) => (
        <Note
          hasDeleteAccess={HAS_INTERACTION_EDIT_ACCESS}
          title={xRayTypeName}
          description={notes}
          date={createdAt}
          onDeleteHandler={() => {
            handleDelete(index, id, xRayTypeName);
          }}
          currentInteractionId={interactionId}
        />
      ))}{" "}
      {xRayImages?.length
        ? getXrayImage()?.attachments?.map(({ id, attachmentName }) => (
            <Note
              hasDeleteAccess={HAS_INTERACTION_EDIT_ACCESS}
              key={id}
              className="mb-4"
              imageId={id}
              imageName={attachmentName}
              currentInteractionId={interactionId}
              onDeleteHandler={() => {
                Modal.confirm({
                  content: `Are you sure you want to delete ${attachmentName}`,
                  onOk: () => {
                    XrayImagesService.deleteXrayImage(
                      id as number,
                      {
                        practiceId: "1",
                        interactionId: interactionId,
                      },
                      () => {
                        fetchXrayImages();
                      },
                      () => {},
                      () => {}
                    );
                  },
                });
              }}
            />
          ))
        : null}
    </div>
  );
};

export default CorporateContainer(XRayNotes);
