import { Drawer, Popover } from "antd";
import moment from "moment";
import React from "react";
import useToggle from "../../../../../shared/hooks/useToggle/useToggler";
import { CalendarSlot } from "../../../../../shared/Types/CalendarSlot.type";
import { scheduleMappedAppointment } from "../../../../../shared/utils/mappedAppointment";
import AppointmentForm from "../../../AppointmentForm";
import NewAppointmentForm from "../../../AppointmentTemplate/NewAppointmentForm";
import AllocatedSlotPopup from "./AllocatedSlotPopup";
import { Moment } from "moment";

import "./allocatedSlot.scss";
import useResponsibilities from "../../../../../shared/hooks/Responsibilities/useResponsibilities";
import { ResponsibilityTypes } from "../../../../../enums/responsebily.enum";
import SlotDetails from "./SlotDetails";
import { Appointment } from "models/Appointment/appointment.model";
import { getFirstCharacters } from "shared/utils/stringUtils";

interface AllocatedSlotProps {
  date?: Moment;
  slot: CalendarSlot;
  currentResource: string;
  onAppointmentFetch?: () => void;
}

const AllocatedSlot = ({
  currentResource,
  date,
  onAppointmentFetch,
  slot,
}: AllocatedSlotProps) => {
  const {
    toggle: editAppointmentVisible,
    updateToggle: handleEditAppointmentVisible,
  } = useToggle({});

  const {
    toggle: editAppointmentOutsideSlotVisible,
    updateToggle: handleEditAppointmentOutsideSlotVisible,
  } = useToggle({});

  const {
    toggle: isBookedSlotClicked,
    updateToggle: updateBookedSlotVisibility,
  } = useToggle({});

  const {
    toggle: isSlotDetailsVisible,
    updateToggle: updateSlotDetailsVisibility,
  } = useToggle({});

  const { hasAccess } = useResponsibilities();

  const { schedule, resourceId, resourceName } = slot;

  const {
    patientFirstName,
    patientLastName,
    interactionStatus,
    doctorFirstName,
    doctorLastName,
    status,
    appointmentStatusColorCode,
  } = schedule;

  const HAS_APPOINTMENT_EDIT_ACCESS = hasAccess(
    ResponsibilityTypes.APPOINTMENT_EDIT
  );

  const HAS_APPOINTMENT_DELETE_ACCESS = hasAccess(
    ResponsibilityTypes.APPOINTMENT_DELETE
  );

  const isPastDate = moment(date).isBefore(moment(), "date");

  const showMoreOption =
    !interactionStatus &&
    !isPastDate &&
    (HAS_APPOINTMENT_EDIT_ACCESS || HAS_APPOINTMENT_DELETE_ACCESS);

  const patientName = `${patientFirstName || ""} ${
    patientLastName || ""
  }`?.trim();

  const appointmentStatus = status
    ? Appointment.mapAppointmentStatus(status)
    : "";

  const doctorName = `${doctorFirstName || ""} ${doctorLastName || ""}`?.trim();

  const stopEventPropagationAndDefaultBehavior = (e: React.SyntheticEvent) => {
    e?.stopPropagation();
    e?.preventDefault();
  };

  const handleIsBookedSlotClicked = (visible?: boolean) => {
    updateBookedSlotVisibility(!!visible);
    updateSlotDetailsVisibility(false);
  };

  const withSlotDetails = (component: React.ReactElement) => (
    <Popover
      content={<SlotDetails slot={slot} schedule={schedule} />}
      onVisibleChange={updateSlotDetailsVisibility}
      style={{ width: 180 }}
      visible={isSlotDetailsVisible}
    >
      {component}
    </Popover>
  );

  const withPopover = (component: React.ReactElement) =>
    showMoreOption ? (
      <Popover
        content={
          <AllocatedSlotPopup
            date={date?.format("YYYY-MM-DD")}
            onAppointmentFetch={onAppointmentFetch}
            onOutsideSlotAppointmentEdit={
              handleEditAppointmentOutsideSlotVisible
            }
            onSlotAppointmentEdit={handleEditAppointmentVisible}
            onVisibleChange={handleIsBookedSlotClicked}
            schedule={schedule}
          />
        }
        destroyTooltipOnHide
        onVisibleChange={handleIsBookedSlotClicked}
        overlayClassName="allocated-slot-popup"
        style={{ width: 500 }}
        trigger={["click"]}
        visible={isBookedSlotClicked}
      >
        {component}
      </Popover>
    ) : (
      component
    );

  return (
    <>
      <div
        onContextMenu={stopEventPropagationAndDefaultBehavior}
        className="allocated-slot"
      >
        {withSlotDetails(
          withPopover(
            <div className="allocated-slot__info">
              <img
                className="allocated-slot__info__pic"
                src={schedule?.patientProfilePicUrl}
                alt=""
              />
              <div className="allocated-slot__info__container">
                <span className="text-bold">
                  {patientName} - Dr. {doctorName}
                </span>
                <span
                  style={{
                    backgroundColor: appointmentStatusColorCode,
                  }}
                  className="text-upper allocated-slot__info__container__status"
                >
                  {getFirstCharacters(appointmentStatus)}
                </span>
              </div>
            </div>
          )
        )}
      </div>
      <Drawer
        title="Edit Appointment"
        width="75vw"
        visible={HAS_APPOINTMENT_EDIT_ACCESS && editAppointmentVisible}
        onClose={() => handleEditAppointmentVisible(false)}
        closable
        destroyOnClose
        push={false}
      >
        <AppointmentForm
          edit
          formValue={{
            ...schedule,
            ...scheduleMappedAppointment(schedule, {
              resourceId,
              resourceName,
            }),
            date,
          }}
          updateHandler={onAppointmentFetch}
          closeHandler={handleEditAppointmentVisible}
          independentDrawer
        />
      </Drawer>
      <Drawer
        title="Edit Appointment"
        width={900}
        visible={
          HAS_APPOINTMENT_EDIT_ACCESS && editAppointmentOutsideSlotVisible
        }
        closable
        onClose={() => handleEditAppointmentOutsideSlotVisible(false)}
        destroyOnClose
        push={false}
      >
        <NewAppointmentForm
          currentDate={moment(slot?.date)}
          appointmentCreateEditVisibility={
            handleEditAppointmentOutsideSlotVisible
          }
          type="editAppointment"
          eventSchedule={schedule}
          currentResource={currentResource}
          // @ts-ignore
          resourceId={resourceId}
          handleAppointmentUpdate={onAppointmentFetch}
          independentDrawer
        />
      </Drawer>
    </>
  );
};

export default AllocatedSlot;
