import React, { useCallback, useEffect, useState } from "react";
import { Row, Col, Button, Collapse, Modal, Popover } from "antd";
import { Link, useHistory, useParams, generatePath } from "react-router-dom";
import { ArrowLeftOutlined, DeleteOutlined } from "@ant-design/icons";
import "./setDetail.scss";
import { DentalSet } from "../../../../../models/DentalSet/dentalSet.model";
import SetService from "../../../../../services/Set/set.service";
import AppLoader from "../../../../../shared/components/AppLoader";
import { SetStep } from "../../../../../models/SetStep/setStep.model";
import { CorporateObject } from "../../../../../models/CorporateObject/corporateObject.model";
import SetViewer from "../SetViewer";
import AppRoutes from "../../../../../routes/routeConstants/appRoutes";
import AlignerActions from "../AlignerActions";
import CorporateObjectService from "../../../../../services/CorporateObject/corporateObject.service";
import SetContainer from "../../../../../store/container/SetContainer";
import { SetReducerProps } from "../../../../../store/reducers/setReducer";
import BabylonContainer from "../../../../../store/container/BabylonContainer";
import { BabylonReducerProps } from "../../../../../store/reducers/babylonReducer";
import { handleRemoveLink } from "../../../../../shared/utils/babylonUtils";
import ConflictManagement from "../../../../../shared/components/ConflictManagement";
import useResponsibilities from "../../../../../shared/hooks/Responsibilities/useResponsibilities";
import { ResponsibilityTypes } from "../../../../../enums/responsebily.enum";
import CorporateContainer from "../../../../../store/container/CorporateContainer";
import { CorporateReducerProps } from "../../../../../store/reducers/corporateReducer";
const { Panel } = Collapse;

interface SetDetailProps extends SetReducerProps, BabylonReducerProps, CorporateReducerProps {}

interface SetDetailParams {
  setId: string;
}

function SetDetail({
  currentSet,
  setCurrentSet,
  scene,
  setMeshLoading,
  practiceId
}: SetDetailProps) {
  const history = useHistory();

  const { hasAccess } = useResponsibilities();

  const [loading, setLoading] = useState(false);

  const params: SetDetailParams = useParams();


  const HAS_SET_CREATE_ACCESS = hasAccess(ResponsibilityTypes.SET_CREATE);

  const HAS_SET_EDIT_ACCESS = hasAccess(ResponsibilityTypes.SET_EDIT);

  const handleUpdateSuccess = (dentalSet: DentalSet) =>
    setCurrentSet(dentalSet);

  const handleCloneSuccess = (dentalSet: DentalSet) => {
    if (!HAS_SET_CREATE_ACCESS) return;

    history.push(
      generatePath(AppRoutes.SET_DETAIL, {
        setId: dentalSet.id,
      })
    );
  };

  const handleDeleteSetStep = (setStep: SetStep) => () => {
    if (!HAS_SET_EDIT_ACCESS) return;
    Modal.confirm({
      title: `Are you sure you want to remove this step from the set?`,
      onOk: () => {
        if (currentSet?.id && setStep?.id) {
          setMeshLoading(true);
          SetService.deleteSetSteps(
            currentSet?.id,
            setStep.id,
            async (corporateObject: CorporateObject) => {
              const setStepIndex = currentSet?.setSteps.findIndex(
                (step) => step.id === setStep.id
              );
              if (setStepIndex >= 0) {
                currentSet?.setSteps?.splice(setStepIndex, 1);
                setCurrentSet(
                  Object.assign(new DentalSet(), {
                    ...currentSet,
                  })
                );
                if (scene) {
                  await handleRemoveLink(setStep, scene);
                }
              }
            },
            () => {},
            () => {
              setMeshLoading(false);
            }
          );
        }
      },
    });
  };

  const setBasicFields = [
    { label: "Set Type", value: currentSet?.type },
    { label: "Set Name", value: currentSet?.name },
    { label: "Description", value: currentSet?.description },
  ];

  const handleShowSet = useCallback(() => {
    setLoading(true);
    SetService.showSet(
      Number(params.setId),
      (set: DentalSet) => {
        setCurrentSet(set);
      },
      () => {},
      () => {
        setLoading(false);
      }
    );
  }, [params.setId, setCurrentSet]);

  useEffect(() => {
    if (params.setId) {
      handleShowSet();
    }
  }, [handleShowSet, params.setId]);

  return (
    <div className="set-detail">
      <Row className="set-detail__header" gutter={[20, 20]}>
        <Col span={12}>
          <Link to={AppRoutes.SETS}>
            <Button className="secondary-btn">
              <ArrowLeftOutlined /> Back
            </Button>
          </Link>
          <Popover
            className="set-detail__popover"
            overlayClassName="set-detail__popover"
            content={
              <div className="set-detail__popover-content">
                {currentSet && (
                  <AlignerActions
                    onUpdateSuccess={handleUpdateSuccess}
                    onCloneSuccess={handleCloneSuccess}
                  />
                )}
              </div>
            }
            title={null}
            trigger="click"
            placement="bottomLeft"
          >
            <Button className="icon-btn">
              <i className="icon-aligners" />
            </Button>
          </Popover>
        </Col>
        <Col span={12}></Col>
      </Row>
      {loading ? (
        <AppLoader loading={loading} />
      ) : (
        <Row gutter={[20, 20]} className="set-detail__actions-row">
          <Col span={16}>
            <div className="set-detail__actions-wrapper">
              {currentSet && <SetViewer />}
            </div>
          </Col>
          <Col span={8}>
            <div className="set-detail__notes-wrapper">
              <Collapse
                expandIconPosition="right"
                defaultActiveKey={["setDetails", "setNotes"]}
              >
                <Panel header="Set Details" key="setDetails">
                  <div className="set-detail__overview">
                    {setBasicFields?.map((field, i) => (
                      <div className="set-detail__field" key={i}>
                        <div className="set-detail__label">{field?.label}</div>
                        <div className="set-detail__value">
                          {field?.value || "NA"}
                        </div>
                      </div>
                    ))}
                  </div>
                </Panel>
                <Panel header="Notes" key="setNotes">
                  <div className="set-detail__notes">
                    {currentSet?.setSteps?.map((step, i) => (
                      <Row className="set-detail__step-item">
                        <Col span={22}>{step?.note}</Col>
                        {HAS_SET_EDIT_ACCESS && (
                          <Col span={2} className="text-right">
                            <DeleteOutlined
                              onClick={handleDeleteSetStep(step)}
                            />
                          </Col>
                        )}
                      </Row>
                    ))}
                  </div>
                </Panel>
              </Collapse>
            </div>
          </Col>
        </Row>
      )}
    </div>
  );
}

export default BabylonContainer(CorporateContainer(SetContainer(SetDetail)));
