import { Slot } from "../Slot/slot.model";

import { serializable, alias, object, list, primitive } from "serializr";
import { WeekDay } from "../../enums/weekDay.enum";
import { cloneDeep } from "lodash";
import moment from "moment";

class AvailabilityType {
  @serializable(alias("type", primitive()))
  type?: string;
}

export class WeekDaySchedule {
  @serializable(alias("is_open", primitive()))
  isOpen?: boolean;

  @serializable(alias("weekday", primitive()))
  weekday?: WeekDay;

  @serializable(alias("start_time", primitive()))
  startTime?: string | null;

  @serializable(alias("end_time", primitive()))
  endTime?: string | null;

  constructor(
    isOpen: boolean,
    weekday: WeekDay,
    startTime: string | null = "09:00:00",
    endTime: string | null = "17:00:00"
  ) {
    this.isOpen = isOpen;
    this.weekday = weekday;
    this.startTime = startTime;
    this.endTime = endTime;
  }
}

export class Schedule {
  @serializable(alias("id", primitive()))
  id?: number;

  // @serializable(alias("date", primitive()))
  // date?: string;

  @serializable(alias("day", primitive()))
  day?: string;

  @serializable(alias("practice_id", primitive()))
  practiceId?: number;

  @serializable(alias("availability", primitive()))
  availability?: boolean = true;

  @serializable(alias("description", primitive()))
  description?: string;

  @serializable(alias("availability_types", list(object(AvailabilityType))))
  availabilityTypes: AvailabilityType[] = [];

  @serializable(alias("start_date", primitive()))
  startDate?: string;

  @serializable(alias("end_date", primitive()))
  endDate?: string;

  @serializable(alias("exception_dates", list(primitive())))
  exceptionDates?: string[] = [];

  @serializable(alias("start_time", primitive()))
  startTime?: string;

  @serializable(alias("end_time", primitive()))
  endTime?: string;

  @serializable(alias("weekday_schedules", list(object(WeekDaySchedule))))
  weekDaySchedules?: WeekDaySchedule[];

  @serializable(alias("is_based_on_weekdays", primitive()))
  isBasedOnWeekdays? = true;

  @serializable(alias("profile_ids", list(primitive())))
  profileIds?: number[] = [];

  @serializable(alias("slots", list(object(Slot))))
  slots: Slot[] = [];

  static sortSchedules? = (schedules: Schedule[] = []): Schedule[] => {
    const clonedSchedules = cloneDeep(schedules);

    clonedSchedules?.sort((schedule1, schedule2) => {
      const day1 = moment(schedule1?.day);

      const day2 = moment(schedule2?.day);

      return day1.diff(day2);
    });

    return clonedSchedules;
  };
}
