import React, { useCallback, useEffect, useState } from "react";
import "./procedureDetail.scss";
import { Button, Col, Collapse, Modal, Popover, Row } from "antd";
import { Link, useHistory, useParams } from "react-router-dom";
import AppRoutes from "../../../../../routes/routeConstants/appRoutes";
import { ArrowLeftOutlined, DeleteOutlined } from "@ant-design/icons";
import ProcedureContainer from "../../../../../store/container/ProcedureContainer";
import { ProcedureReducerProps } from "../../../../../store/reducers/procedureReducer";
import ProcedureActions from "../ProcedureActions";
import ProcedureService from "../../../../../services/Procedure/procedure.service";
import { Procedure } from "../../../../../models/Procedure/procedure.model";
import AppLoader from "../../../../../shared/components/AppLoader";
import { ProcedureStep } from "../../../../../models/ProcedureStep/procedureStep.model";
import ProcedureViewer from "../ProcedureViewer";
import {
	handleImportVendorObject,
	handleRemoveLink,
} from "../../../../../shared/utils/babylonUtils";
import BabylonContainer from "../../../../../store/container/BabylonContainer";
import { BabylonReducerProps } from "../../../../../store/reducers/babylonReducer";
import { ProcedureActionEnum } from "../../../../../enums/procedureAction.enum";
import { ICONS } from "../../../../../shared/icons";
import ConflictManagement from "../../../../../shared/components/ConflictManagement";
import { CONFLICT_TYPES } from "../../../../../enums/conflictType.enum";
import { updateTreatmentProcedureSteps } from "../../../../../shared/utils/integrateTreatmentSteps";
import useResponsibilities from "../../../../../shared/hooks/Responsibilities/useResponsibilities";
import { ResponsibilityTypes } from "../../../../../enums/responsebily.enum";
import CorporateContainer from "../../../../../store/container/CorporateContainer";
import { CorporateReducerProps } from "../../../../../store/reducers/corporateReducer";
const { Panel } = Collapse;

interface ProcedureDetailProps
	extends ProcedureReducerProps,
		BabylonReducerProps, CorporateReducerProps {}

interface ProcedureDetailParams {
	procedureId: string;
}

function ProcedureDetail({
	currentProcedure,
	caps,
	setCurrentProcedure,
	scene,
	highlightLayer,
	resetConflict,
	corporateObjects,
	setSourceObject,
	setShowPopover,
	setPopoverPosition,
	setMeshLoading,
	practiceId
}: ProcedureDetailProps) {
	const procedureBasicFields = [
		{ label: "Procedure Name", value: currentProcedure?.name },
		{ label: "Procedure Code", value: currentProcedure?.code },
		{ label: "Description", value: currentProcedure?.description },
	];

	const { hasAccess } = useResponsibilities();

	const [loading, setLoading] = useState(false);

	const [openConflict, setOpenConflict] = useState(false);

	const params: ProcedureDetailParams = useParams();


	const HAS_SET_VIEW_ACCESS = hasAccess(ResponsibilityTypes.SET_VIEW);

	const HAS_PROCEDURE_EDIT_ACCESS = hasAccess(ResponsibilityTypes.PROCEDURE_EDIT);

	const handleDeleteProcedureStep = (procedureStep: ProcedureStep) => () => {
		if(!HAS_PROCEDURE_EDIT_ACCESS)	return;
		Modal.confirm({
			title: `Are you sure you want to remove this step from the procedure?`,
			onOk: () => {
				if (currentProcedure?.id && procedureStep?.id) {
					setMeshLoading(true);
					ProcedureService.deleteProcedureStep(
						currentProcedure.id,
						procedureStep.id,
						async () => {
							const procedureStepIndex = currentProcedure?.procedureSteps.findIndex(
								(step) => step.id === procedureStep.id
							);
							if (procedureStepIndex >= 0) {
								currentProcedure?.procedureSteps?.splice(
									procedureStepIndex,
									1
								);
								setCurrentProcedure(
									Object.assign(new Procedure(), {
										...currentProcedure,
									})
								);
								if (scene) {
									if (procedureStep?.set) {
										procedureStep?.set?.setSteps?.forEach(
											async (setStep) => {
												await handleRemoveLink(
													setStep,
													scene
												);
											}
										);
									} else {
										await handleRemoveLink(
											procedureStep,
											scene
										);
									}
								}
							}
						},
						() => {},
						() => {
							setMeshLoading(false);
						}
					);
				}
			},
		});
	};

	const handleShowProcedure = useCallback(() => {
		if (params.procedureId) {
			setLoading(true);
			ProcedureService.showProcedure(
				Number(params.procedureId),
				(procedure: Procedure) => {
					setCurrentProcedure(procedure);
				},
				() => {},
				() => {
					setLoading(false);
				}
			);
		}
	}, [params.procedureId, setCurrentProcedure]);

	useEffect(() => {
		handleShowProcedure();
	}, [handleShowProcedure]);

	const createProcedureStep = (procedureStep: ProcedureStep, complete?: () => void) => {
		if (currentProcedure?.id) {
			delete procedureStep.set;
			setMeshLoading(true);
			ProcedureService.createProcedureStep(
				currentProcedure.id,
				procedureStep,
				async (procedureStep: ProcedureStep) => {
					// setFormValues(new Procedure());
					try{
					setMeshLoading(true);
					if (highlightLayer) highlightLayer.removeAllMeshes();
					if (currentProcedure) {
						currentProcedure.procedureSteps.unshift(procedureStep);
						setCurrentProcedure(
							Object.assign(new ProcedureStep(), currentProcedure)
						);
						await updateTreatmentProcedureSteps({
							corporateObjects,
							setPopoverPosition,
							setShowPopover,
							setSourceObject,
							caps,
							isMeshPickable: false,
							scene,
							steps: [procedureStep]
						});
					}}catch(ex){}finally{
						setMeshLoading(false);
					}
				},
				() => {},
				() => {
					complete?.();
					setMeshLoading(false);
				}
			);
		}
	};

	const handleCloseConflict = () => {
		resetConflict();
		setOpenConflict(false);
	};

	const handleSubmitConflict = (data: any[]) => {
		data.map((procedureStep: any) => {
			if (procedureStep?.conflict_type === CONFLICT_TYPES.HARD_APPLY) {
				delete procedureStep.toothLink;
				delete procedureStep.conflict_type;
				const step = Object.assign(new ProcedureStep(), procedureStep);
				createProcedureStep(step);
			}
		});
		handleCloseConflict();
	};

	return (
		<div className="procedure-detail">
			<ConflictManagement
				visible={openConflict}
				onSubmit={handleSubmitConflict}
				onClose={handleCloseConflict}
				createProcedureStep={createProcedureStep}
			/>
			<Row className="procedure-detail__header" gutter={[20, 20]}>
				<Col span={12}>
					<Link to={AppRoutes.PROCEDURES}>
						<Button className="secondary-btn">
							<ArrowLeftOutlined /> Back
						</Button>
					</Link>
					{HAS_PROCEDURE_EDIT_ACCESS && HAS_SET_VIEW_ACCESS && <Popover
						className="procedure-detail__popover"
						overlayClassName="procedure-detail__popover"
						content={
							<div className="procedure-detail__popover-content">
								{currentProcedure && (
									<ProcedureActions
										type={ProcedureActionEnum.ALIGNER}
										setOpenConflict={setOpenConflict}
										createProcedureStep={createProcedureStep}
									/>
								)}
							</div>
						}
						title={null}
						trigger="click"
						placement="bottomLeft"
					>
						<Button className="icon-btn">
							<i className="icon-aligners" />
						</Button>
					</Popover>}
					{/* <Popover
						className="procedure-detail__popover"
						overlayClassName="procedure-detail__popover"
						content={
							<div className="procedure-detail__popover-content">
								{currentProcedure && (
									<ProcedureActions
										type={ProcedureActionEnum.BUTTON}
										setOpenConflict={setOpenConflict}
										createProcedureStep={createProcedureStep}
									/>
								)}
							</div>
						}
						title={null}
						trigger="click"
						placement="bottomLeft"
					>
						<Button className="icon-btn">
							<img
								className="icon-btn"
								src={ICONS.BUTTON}
								alt=""
							/>
						</Button>
					</Popover>
					<Popover
						className="procedure-detail__popover"
						overlayClassName="procedure-detail__popover"
						content={
							<div className="procedure-detail__popover-content">
								{currentProcedure && (
									<ProcedureActions
										type={ProcedureActionEnum.ELASTIC}
										setOpenConflict={setOpenConflict}
										createProcedureStep={createProcedureStep}
									/>
								)}
							</div>
						}
						title={null}
						trigger="click"
						placement="bottomLeft"
					>
						<Button className="icon-btn">
							<img
								className="icon-btn"
								src={ICONS.ELASTIC}
								alt=""
							/>
						</Button>
					</Popover> */}
				</Col>
				<Col span={12}></Col>
			</Row>
			{loading ? (
				<AppLoader loading={loading} />
			) : (
				<Row
					gutter={[20, 20]}
					className="procedure-detail__actions-row"
				>
					<Col span={16}>
						<div className="procedure-detail__actions-wrapper">
							<ProcedureViewer
								setOpenConflict={setOpenConflict}
								createProcedureStep={createProcedureStep}
							/>
						</div>
					</Col>
					<Col span={8}>
						<div className="procedure-detail__notes-wrapper">
							<Collapse
								expandIconPosition="right"
								defaultActiveKey={["procedureDetails"]}
							>
								<Panel
									header="Procedure Details"
									key="procedureDetails"
								>
									<div className="procedure-detail__overview">
										{procedureBasicFields?.map(
											(field, i) => (
												<div
													className="procedure-detail__field"
													key={i}
												>
													<div className="procedure-detail__label">
														{field?.label}
													</div>
													<div className="procedure-detail__value">
														{field?.value || "NA"}
													</div>
												</div>
											)
										)}
									</div>
								</Panel>
								<Panel header="Notes" key="procedureNotes">
									<div className="procedure-detail__notes">
										{currentProcedure?.procedureSteps?.map(
											(procedureStep, i) =>
												procedureStep?.set ? (
													<Collapse
														expandIconPosition="right"
														className="procedure-detail__set-item"
													>
														<Panel
															header={
																<Row
																	align="middle"
																	className="procedure-detail__set-header-row"
																>
																	<Col
																		span={
																			22
																		}
																	>
																		{
																			procedureStep
																				?.set
																				?.name
																		}
																	</Col>
																	{HAS_PROCEDURE_EDIT_ACCESS && <Col
																		span={2}
																	>
																		<DeleteOutlined
																			onClick={handleDeleteProcedureStep(
																				procedureStep
																			)}
																		/>
																	</Col>}
																</Row>
															}
															key={i}
														>
															{procedureStep?.set?.setSteps?.map(
																(
																	setStep,
																	j
																) => (
																	<Row className="procedure-detail__set-step-item">
																		<Col
																			span={
																				24
																			}
																		>
																			{j +
																				1}
																			.{" "}
																			{setStep?.note ||
																				"NA"}
																		</Col>
																	</Row>
																)
															)}
														</Panel>
													</Collapse>
												) : (
													<Row
														className="procedure-detail__link-item"
														align="middle"
													>
														<Col span={22}>
															{
																procedureStep?.note
															}
														</Col>
														{HAS_PROCEDURE_EDIT_ACCESS && <Col span={2}>
															<DeleteOutlined
																onClick={handleDeleteProcedureStep(
																	procedureStep
																)}
															/>
														</Col>}
													</Row>
												)
										)}
									</div>
								</Panel>
							</Collapse>
						</div>
					</Col>
				</Row>
			)}
		</div>
	);
}

export default BabylonContainer(CorporateContainer(ProcedureContainer(ProcedureDetail)));
