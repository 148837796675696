import React, { useEffect, useState } from "react";
import "./procedureList.scss";
import { Button, Col, Drawer, Modal, Row, Switch, Table, Tooltip } from "antd";
import {
  CheckOutlined,
  CloseOutlined,
  CopyOutlined,
  EditOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import Stats from "../../../../../shared/components/Stats";
import AppLoader from "../../../../../shared/components/AppLoader";
import { Procedure } from "../../../../../models/Procedure/procedure.model";
import ProcedureService from "../../../../../services/Procedure/procedure.service";
import ProcedureForm from "../ProcedureForm";
import { StatusEnum } from "../../../../../enums/status.enum";
import { generatePath, useHistory } from "react-router-dom";
import AppRoutes from "../../../../../routes/routeConstants/appRoutes";
import { FormActionEnum } from "../../../../../enums/formAction.enum";
import AuthContainer from "../../../../../store/container/AuthContainer";
import { AuthReducerProps } from "../../../../../store/reducers/authReducer";
import { ResponsibilityTypes } from "../../../../../enums/responsebily.enum";
import { TableColumn } from "../../../../../shared/Types/tableColumn";
import { StatsType } from "../../../../../enums/statsType.enum";
import { useMetrics } from "../../../../../shared/hooks/Metrics/useMetrics";
import MetricsService from "../../../../../services/Metrics/metrics.service";
import { Metrics } from "../../../../../models/Metrics/metrics.model";
import { TablePaginationConfig } from "antd/lib/table";
import { PaginationDetails } from "../../../../../models/Pagination/pagination.model";
import useFilters from "../../../../../shared/hooks/useFilter/useFilters";
import { FilterParams } from "../../../../../models/FilterParams/filterParams.model";
import useResponsibilities from "../../../../../shared/hooks/Responsibilities/useResponsibilities";
import CorporateContainer from "../../../../../store/container/CorporateContainer";
import { CorporateReducerProps } from "../../../../../store/reducers/corporateReducer";

interface ProcedureListProps extends AuthReducerProps, CorporateReducerProps {}

function ProcedureList({ practiceId }: ProcedureListProps) {
  const history = useHistory();

  const { hasAccess } = useResponsibilities();

  const [formAction, setFormAction] = useState<FormActionEnum>(
    FormActionEnum.ADD
  );

  const [showProcedureForm, setShowProcedureForm] = useState(false);

  const [loading, setLoading] = useState(false);

  const [activeProcedure, setActiveProcedure] = useState(new Procedure());

  const [procedures, setProcedures] = useState<Procedure[]>([]);
  const [metrics, setMetrics] = useState<Metrics>();
  const [pagination, setPagination] = useState<PaginationDetails>();
  const { getParams, setParams } = useFilters();

  const HAS_PROCEDURE_CREATE_ACCESS = hasAccess(
    ResponsibilityTypes.PROCEDURE_CREATE
  );

  const HAS_PROCEDURE_EDIT_ACCESS = hasAccess(
    ResponsibilityTypes.PROCEDURE_EDIT
  );

  const handleToggleProcedureForm = () =>
    setShowProcedureForm(!showProcedureForm);

  const handleFetchProcedures = (filter: FilterParams) => {
    setLoading(true);
    ProcedureService.fetchProcedures(
      (procedures: Procedure[], pagination) => {
        setProcedures(procedures);
        setPagination(pagination);
      },
      () => {},
      () => {
        setLoading(false);
      },
      filter
    );
  };

  const handleAddProcedureForm = () => {
    if (!HAS_PROCEDURE_CREATE_ACCESS) return;

    setActiveProcedure(new Procedure());
    setFormAction(FormActionEnum.ADD);
    handleToggleProcedureForm();
  };

  const handleCloneProcedure = (procedure: Procedure) => () => {
    if (!HAS_PROCEDURE_CREATE_ACCESS) return;

    setFormAction(FormActionEnum.CLONE);
    setActiveProcedure(procedure);
    handleToggleProcedureForm();
  };

  const handleEditProcedure = (procedure: Procedure) => () => {
    if (!HAS_PROCEDURE_EDIT_ACCESS) return;

    setFormAction(FormActionEnum.EDIT);
    setActiveProcedure(procedure);
    handleToggleProcedureForm();
  };

  const handleRowClick = (procedure: Procedure) => {
    return {
      onClick: () => {
        history.push(
          generatePath(AppRoutes.PROCEDURE_DETAIL, {
            procedureId: procedure.id,
          })
        );
      },
    };
  };

  const handleProcedureFormSuccess = (procedure: Procedure) => {
    const procedureIndex = procedures.findIndex(
      (procedureItem) => procedureItem.id === procedure.id
    );
    if (procedureIndex >= 0) {
      procedures[procedureIndex] = procedure;
    } else {
      procedures.unshift(procedure);
    }
    setProcedures([...procedures]);
    handleToggleProcedureForm();
    if (procedureIndex < 0) {
      history.push(
        generatePath(AppRoutes.PROCEDURE_DETAIL, {
          procedureId: procedure.id,
        })
      );
    }
  };

  const handleFetchMetrics = () => {
    MetricsService.fetchMetrics(
      "procedures",
      (metrics: Metrics) => {
        setMetrics(metrics);
      },
      () => {},
      () => {}
    );
  };

  useEffect(() => {
    handleFetchProcedures(getParams());
    handleFetchMetrics();
  }, []);

  const [columns, setColumns] = useState<TableColumn[]>([
    {
      title: "Procedure Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Procedure Code",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "No of objects",
      dataIndex: "noOfObjects",
      key: "noOfObjects",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text: string, record: any, index: number) => {
        return (
          <div className="text-capitalize">
            <span
              className={
                text === StatusEnum.ACTIVE ? "text-success" : "text-danger"
              }
            >
              {text}
            </span>
          </div>
        );
      },
    },
    {
      title: "",
      dataIndex: "id",
      key: "id",
      render: (id: number, procedure: Procedure, index: number) => {
        const handleOnCheck = (checked: boolean) => {
          Modal.confirm({
            title: `Are you sure you want to ${
              checked ? "enable" : "disable"
            } this procedure?`,
            onOk: () => {
              setLoading(true);
              ProcedureService.updateProcedure(
                Object.assign(new Procedure(), {
                  ...procedure,
                  status: checked ? StatusEnum.ACTIVE : StatusEnum.INACTIVE,
                }),
                (procedure: Procedure) => {
                  const procedureIndex = procedures.findIndex(
                    (procedureItem: Procedure) =>
                      procedure.id === procedureItem.id
                  );
                  if (procedureIndex >= 0) {
                    procedures[procedureIndex] = procedure;
                  }
                  setProcedures([...procedures]);
                  handleFetchProcedures(getParams());
                  handleFetchMetrics();
                },
                () => {},
                () => {
                  setLoading(false);
                }
              );
            },
          });
        };
        return (
          <div onClick={(event) => event.stopPropagation()}>
            {HAS_PROCEDURE_CREATE_ACCESS && (
              <Tooltip placement="topRight" title="Clone Procedure">
                <Button
                  type="primary"
                  className="mr-4"
                  icon={<CopyOutlined />}
                  onClick={handleCloneProcedure(procedure)}
                />
              </Tooltip>
            )}
            {HAS_PROCEDURE_EDIT_ACCESS && (
              <Tooltip placement="topRight" title="Edit Procedure">
                <Button
                  type="primary"
                  className="mr-4"
                  icon={<EditOutlined />}
                  onClick={handleEditProcedure(procedure)}
                />
              </Tooltip>
            )}
            {HAS_PROCEDURE_EDIT_ACCESS && (
              <span>
                <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  checked={procedure.status === StatusEnum.ACTIVE}
                  onChange={handleOnCheck}
                />
              </span>
            )}
          </div>
        );
      },
    },
  ]);

  const treatmentExecutionStatsData = [
    {
      title: "Total Procedures",
      value: metrics?.totalProcedures ?? 0,
    },
    {
      title: "Active Procedures",
      value: metrics?.activeProcedures ?? 0,
    },
    {
      title: "Inactive Procedures",
      value: metrics?.inactiveProcedures ?? 0,
    },
  ];
  const handleTableChange = (pagination: TablePaginationConfig) => {
    const prevFilter = getParams();
    const updatedFilters = {
      ...prevFilter,
      page: pagination.current ?? 1,
    };
    setParams(updatedFilters);
    handleFetchProcedures(updatedFilters);
  };

  return (
    <div className="procedure-list">
      <Row>
        <Col span={12}>
          <h5 className="text-secondary"> All Procedures </h5>
        </Col>
        <Col span={12} className="text-right">
          {HAS_PROCEDURE_CREATE_ACCESS && (
            <Button type="primary" onClick={handleAddProcedureForm}>
              <PlusOutlined /> Add Procedure
            </Button>
          )}
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Stats
            statsData={treatmentExecutionStatsData}
            statsType={StatsType.PROCEDURE}
          />
        </Col>
      </Row>
      <Row>
        {loading ? (
          <AppLoader loading={loading} />
        ) : (
          <Table
            dataSource={procedures}
            columns={columns}
            onRow={handleRowClick}
            onChange={handleTableChange}
            pagination={{
              total: pagination?.totalCount,
              current: pagination?.currentPage ?? 1,
              showTotal: (total: number, range: [number, number]) => (
                <p>
                  Showing <b>{` ${range[0]} - ${range[1]}`}</b> of{" "}
                  <b>{`${total.toLocaleString()}`}</b>
                </p>
              ),
            }}
          />
        )}
      </Row>
      <Drawer
        title={
          <h4>
            {formAction === FormActionEnum.ADD
              ? "New Procedure"
              : formAction === FormActionEnum.EDIT
              ? "Edit Procedure"
              : "Clone Procedure"}
          </h4>
        }
        placement="right"
        keyboard={false}
        maskClosable={false}
        onClose={handleToggleProcedureForm}
        visible={
          (HAS_PROCEDURE_CREATE_ACCESS || HAS_PROCEDURE_EDIT_ACCESS) &&
          showProcedureForm
        }
        destroyOnClose={true}
        width="60%"
      >
        <ProcedureForm
          showPatientCategory={formAction === FormActionEnum.ADD}
          action={formAction}
          procedure={activeProcedure}
          onCancel={handleToggleProcedureForm}
          onSuccess={handleProcedureFormSuccess}
        />
      </Drawer>
    </div>
  );
}

export default AuthContainer(CorporateContainer(ProcedureList));
