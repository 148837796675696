import React, { useState } from "react";
import { Form, Formik, FormikValues } from "formik";
import { Button, Col, Row } from "antd";
import "./setForm.scss";
import { DentalSet } from "../../../../../models/DentalSet/dentalSet.model";
import { setFormValidation } from "./setFormValidation";
import InputField from "../../../../../shared/components/InputField";
import DropdownField from "../../../../../shared/components/DropdownField";
import SetService from "../../../../../services/Set/set.service";
import { FormActionEnum } from "../../../../../enums/formAction.enum";
import useResponsibilities from "../../../../../shared/hooks/Responsibilities/useResponsibilities";
import { ResponsibilityTypes } from "../../../../../enums/responsebily.enum";
import { patientCategories } from "../../../../../shared/utils/patientCategories";

interface SetFormProps {
  action?: FormActionEnum;
  set: DentalSet;
  onCancel?: () => void;
  onSuccess: (set: DentalSet) => void;
  showPatientCategory?: boolean;
}

function SetForm({
  action,
  set,
  onCancel,
  onSuccess,
  showPatientCategory,
}: SetFormProps) {
  const { hasAccess } = useResponsibilities();

  const [formValues] = useState<DentalSet>(set);

  const [formLoading, setFormLoading] = useState(false);

  const HAS_SET_CREATE_ACCESS = hasAccess(ResponsibilityTypes.SET_CREATE);

  const HAS_SET_EDIT_ACCESS = hasAccess(ResponsibilityTypes.SET_EDIT);

  const setTypes = [
    { label: "Aligner", value: "aligner" },
    // TODO: To be handled when implementing respective vendor objects
    // { label: "Bracket", value: "bracket" },
    // { label: "Elastic", value: "elastic" },
    // { label: "Bend", value: "bend" },
    // { label: "Button", value: "button" },
  ];

  const handleSubmit = (values: FormikValues) => {
    const set = Object.assign(new DentalSet(), values);
    setFormLoading(true);
    if (set.id) {
      if (HAS_SET_CREATE_ACCESS && action === FormActionEnum.CLONE) {
        SetService.cloneSet(
          set,
          (set: DentalSet) => {
            onSuccess(set);
            onCancel?.();
          },
          () => {},
          () => {
            setFormLoading(false);
          }
        );
      } else {
        if (!HAS_SET_EDIT_ACCESS) return;
        SetService.updateSet(
          set,
          (set: DentalSet) => {
            onSuccess(set);
            onCancel?.();
          },
          () => {},
          () => {
            setFormLoading(false);
          }
        );
      }
    } else {
      if (!HAS_SET_CREATE_ACCESS) return;
      SetService.createSet(
        set,
        (set: DentalSet) => {
          onSuccess(set);
          onCancel?.();
        },
        () => {},
        () => {
          setFormLoading(false);
        }
      );
    }
  };

  return (
    <div className="set-form">
      <Formik
        initialValues={{ ...formValues, type: formValues?.type || "aligner" }}
        onSubmit={handleSubmit}
        enableReinitialize
        validationSchema={setFormValidation}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          errors,
          isValid,
          dirty,
          setFieldValue,
        }) => {
          return (
            <Form>
              <div className="set-form__form-body">
                <Row gutter={[20, 0]}>
                  <Col span={24}>
                    <InputField
                      title="Set Name"
                      type="text"
                      name="name"
                      placeholder="Enter set name"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <DropdownField
                      title="Type"
                      name="type"
                      placeHolder="Select Type"
                      setFieldValue={setFieldValue}
                      options={setTypes}
                      value={values.type}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <DropdownField
                      disabled={!showPatientCategory}
                      title="Patient Category"
                      name="patientCategory"
                      placeHolder="Select Patient Category"
                      setFieldValue={setFieldValue}
                      options={patientCategories}
                      value={values.patientCategory}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <InputField
                      title="Description"
                      type="textarea"
                      name="description"
                      placeholder="Enter Description"
                    />
                  </Col>
                </Row>
              </div>
              <div className="set-form__submit-wrapper text-right">
                {onCancel && (
                  <Button type="default" onClick={onCancel}>
                    Cancel
                  </Button>
                )}
                <Button
                  type="primary"
                  className="ml-2"
                  htmlType="submit"
                  loading={formLoading}
                  disabled={!isValid || !dirty || formLoading}
                >
                  {action === FormActionEnum.CLONE
                    ? "Clone"
                    : values?.id
                    ? "Edit"
                    : "Add"}
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default SetForm;
