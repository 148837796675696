import { Button, Col, Row } from "antd";
import React, { FC } from "react";
import { ResponsibilityTypes } from "../../../../../../enums/responsebily.enum";
import { AppointmentType } from "../../../../../../models/AppointmentType/appointmentType.model";
import useResponsibilities from "../../../../../../shared/hooks/Responsibilities/useResponsibilities";
import "./appointmentTypeDetails.scss";

interface AppointmentTypeProps {
  appointmentType: AppointmentType;
  editHandler: (appointmentType: AppointmentType) => void;
  closeHandler: () => void;
}

const AppointmentTypeDetails: FC<AppointmentTypeProps> = ({
  appointmentType,
  editHandler
}) => {
  const { hasAccess } = useResponsibilities();

  const HA_STATUS_APPOINTMENT_TYPE_EDIT_ACCESS = hasAccess(ResponsibilityTypes.STATUS_APPOINTMENT_TYPE_EDIT)

  const handleEdit = () => {
    editHandler(appointmentType);
  };

  return (
    <div className="appointment-type-details__container">
      <div className="appointment-type-details__wrapper">
        {HA_STATUS_APPOINTMENT_TYPE_EDIT_ACCESS && (
          <Row justify="end">
            <Col span={4} className="edit-btn__wrapper" onClick={handleEdit}>
              <Button>
                <i className="icon-edit" />
                Edit
              </Button>
            </Col>
          </Row>
        )}
        <div className="field__wrapper mt-5">
          <p className="title">Appointment Title</p>
          <p className="value">{appointmentType.title}</p>
        </div>
        <div className="field__wrapper mt-5">
          <p className="title">Nick Name</p>
          <p className="value">{appointmentType.nickName}</p>
        </div>
        <div className="field__wrapper mt-5">
          <p className="title">Procedure Code</p>
          <p className="value">{appointmentType.procedureCode}</p>
        </div>
        <div className="field__wrapper mt-5">
          <p className="title">Duration</p>
          <p className="value">{appointmentType.duration}</p>
        </div>
        <div className="field__wrapper mt-5">
          <p className="title">Color</p>
          <div
            className="value-color"
            style={{ background: appointmentType.treatmentColor }}
          />
        </div>
        <div className="field__wrapper mt-5">
          <p className="title">Description</p>
          <p className="value">{appointmentType.description}</p>
        </div>
      </div>
    </div>
  );
};

export default AppointmentTypeDetails;
