import React, { useEffect, useState } from "react";
import { InteractionStepFormTypeEnum } from "../../../enums/interactionStepFormType.enum";
import BabylonContainer from "../../../store/container/BabylonContainer";
import { BabylonReducerProps } from "../../../store/reducers/babylonReducer";
import { InteractionReducerProps } from "../../../store/reducers/interactionReducer";
import InteractionContainer from "../../../store/container/InteractionContainer";
import { Form, Formik, FormikValues } from "formik";
import { Button, Col, Row } from "antd";
import DropdownField from "../../../shared/components/DropdownField";
import { InteractionStep } from "../../../models/InteractionStep/interactionStep.model";
import { IDropdownOptions } from "../../../services/Meta/meta.service";
import CorporateLinkService from "../../../services/CorporateLink/corporateLink.service";
import { CorporateLink } from "../../../models/CorporateLink/corporateLink.model";
import SetService from "../../../services/Set/set.service";
import { DentalSet } from "../../../models/DentalSet/dentalSet.model";
import { getDropdownOptions } from "../../../shared/utils/optionHelper";
import CorporateObjectService from "../../../services/CorporateObject/corporateObject.service";
import { DentalObjectTypeEnum } from "../../../enums/dentalObjectType.enum";
import { CorporateObject } from "../../../models/CorporateObject/corporateObject.model";
import {
  checkConflict,
  checkConflictProcedure,
  checkConflictSet,
  handleImportVendorObject,
} from "../../../shared/utils/babylonUtils";
import InteractionStepService from "../../../services/InteractionStep/interactionStep.service";
import {
  interactionLinkFormValidation,
  interactionSetFormValidation,
} from "./interactionStepFormValidation";
import "./interactionStepForm.scss";
import { InteractionActionEnum } from "../../../enums/interactionActionEnum.enum";
import { PointerEventTypes, Scene } from "@babylonjs/core";
import { CONFLICT_TYPES } from "../../../enums/conflictType.enum";
import useSets from "../../../shared/hooks/useSets";
import { MeshType } from "enums/meshType.enum";
import { PatientCategory } from "enums/patientCategory.enum";

interface InteractionStepFormProps
  extends BabylonReducerProps,
    InteractionReducerProps {
  type: InteractionStepFormTypeEnum;
  interactionType: InteractionActionEnum;
  setOpenConflict: (open: boolean) => void;
  createInteractionStep: (step: InteractionStep, complete?: () => void) => void;
  onSuccess: () => void;
  meshType?: MeshType;
}

function InteractionStepForm({
  type,
  onSuccess,
  highlightLayer,
  scene,
  sourceObject,
  corporateObjects,
  interactionType,
  setSourceObject,
  setShowPopover,
  setOpenConflict,
  pushConflict,
  createInteractionStep,
  setMeshLoading,
  meshType,
}: InteractionStepFormProps) {
  const [formLoading, setFormLoading] = useState(false);

  const [formValues, setFormValues] = useState<InteractionStep>(
    new InteractionStep()
  );
  const getPatientCategory = () =>
    meshType === MeshType.ADULT_TEETH
      ? PatientCategory.ADULT
      : PatientCategory.CHILD;

  const { fetchSets, loading, options: dentalSetOptions } = useSets({
    patientCategory: getPatientCategory(),
  });

  const [vendorObjectOptions, setVendorObjectOptions] = useState<
    IDropdownOptions[]
  >([]);

  const [linkOptions, setLinkOptions] = useState<IDropdownOptions[]>([]);

  const handleFetchLinks = (srcObjectId?: number, destObjectId?: number) => {
    if (srcObjectId && destObjectId) {
      CorporateLinkService.fetchCorporateLinks(
        null,
        srcObjectId,
        destObjectId,
        (corporateLinks: CorporateLink[]) => {
          setLinkOptions(
            corporateLinks.map((link) => ({
              ...link,
              label: link.srcLocatorName + " - " + link.destLocatorName,
              value: link.id,
            }))
          );
        },
        () => {},
        () => {}
      );
    }
  };
  const saveInteractionStep = (
    interactionStep: InteractionStep,
    conflict?: CONFLICT_TYPES
  ) => {
    return new Promise((resolve) => {
      if (conflict) {
        setShowPopover(false);
      }
      if (conflict === CONFLICT_TYPES.HARD_APPLY) {
        pushConflict(interactionStep);
        setOpenConflict(true);
      }

      if (!conflict) {
        setFormLoading(true);
        setMeshLoading(true);
        createInteractionStep(interactionStep, () => {
          setFormLoading(false);
          onSuccess?.();
          setMeshLoading(false);
          resolve(null);
        });
      } else {
        resolve(null);
      }
    });
  };

  const handleSubmit = async (values: FormikValues) => {
    try {
      setMeshLoading(true);
      setFormValues({ ...values });
      const interactionStep = Object.assign(new InteractionStep(), values);
      if (interactionStep?.setId) {
        const conflict: any = await checkConflictSet(
          interactionStep?.setId,
          scene as Scene
        );
        await saveInteractionStep(interactionStep, conflict);
      } else {
        const mesh = scene?.getMeshByName(
          (sourceObject as CorporateObject)?.name ?? ""
        );
        if (mesh && values?.toothLink) {
          const conflict: any = await checkConflict(
            mesh,
            values.toothLink?.srcObjectName,
            values.toothLink?.srcLocatorName,
            values.toothLink?.destObjectName
          );
          await saveInteractionStep(interactionStep, conflict);
        }
      }
    } catch (ex) {
    } finally {
      setMeshLoading(false);
    }
  };

  useEffect(() => {
    CorporateObjectService.fetchCorporateObjects(
      {},
      DentalObjectTypeEnum.VENDOR,
      (corporateObjects: CorporateObject[]) => {
        setVendorObjectOptions(
          getDropdownOptions(corporateObjects, "name", "id")
        );
      },
      () => {},
      () => {},
      {
        //  Future problem Array problem
        dental_object_id: Array.isArray(sourceObject)
          ? undefined
          : sourceObject?.dentalObjectId,
      }
    );
  }, [sourceObject]);

  useEffect(() => {
    if (scene && corporateObjects.length > 0) {
      scene.onPointerObservable.add(({ type, event, pickInfo }: any) => {
        switch (type) {
          case PointerEventTypes.POINTERTAP: {
            if (highlightLayer && !pickInfo?.pickedMesh) {
              setSourceObject(undefined);
              setShowPopover(false);
              highlightLayer.removeAllMeshes();
            }
            break;
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scene, corporateObjects.length]);

  return (
    <div className="interaction-step-form">
      {type === InteractionStepFormTypeEnum.SET ? (
        <Formik
          initialValues={formValues}
          onSubmit={handleSubmit}
          enableReinitialAize
          validationSchema={interactionSetFormValidation}
        >
          {({ values, isValid, dirty, isSubmitting, setFieldValue }) => {
            return (
              <Form>
                <div className="interaction-step-form__form-body">
                  <Row gutter={[20, 20]} align="middle">
                    <Col span={16}>
                      <DropdownField
                        name="setId"
                        placeHolder="Select Set"
                        showSearch
                        options={dentalSetOptions}
                        loading={loading}
                        onScrollEnd={fetchSets}
                        value={values.setId}
                        onChange={(value, dentalSet) => {
                          setFieldValue("setId", value);
                          setFieldValue("set", dentalSet);
                        }}
                      />
                    </Col>
                    <Col span={8}>
                      <Button
                        type="primary"
                        className="ml-2"
                        htmlType="submit"
                        loading={isSubmitting || formLoading}
                      >
                        Add Set
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Form>
            );
          }}
        </Formik>
      ) : (
        <Formik
          initialValues={new InteractionStep()}
          onSubmit={handleSubmit}
          enableReinitialAize
          validationSchema={interactionLinkFormValidation}
        >
          {({ values, isValid, isSubmitting, dirty, setFieldValue }) => {
            return (
              <Form>
                <div className="interaction-step-form__form-body">
                  <Row gutter={[20, 20]}>
                    <Col span={24}>
                      <h5 className="text-primary">
                        {" "}
                        {interactionType} Objects
                      </h5>
                      <DropdownField
                        name="destObjectId"
                        placeHolder="Select Vendor Object"
                        showSearch
                        onChange={(value) => {
                          setFieldValue("destObjectId", value);
                          handleFetchLinks(
                            (sourceObject as CorporateObject)?.id,
                            value
                          );
                        }}
                        options={vendorObjectOptions}
                        value={values.destObjectId}
                      />
                    </Col>
                    <Col span={24}>
                      <h5 className="text-primary">
                        Source Locator - Destination Locator
                      </h5>
                      <DropdownField
                        name="corporateLinkId"
                        placeHolder="Select Link"
                        showSearch
                        onChange={(_, values) => {
                          setFieldValue("corporateLinkId", values.value);
                          setFieldValue("toothLink", values);
                        }}
                        options={linkOptions}
                        value={values.corporateLinkId}
                      />
                    </Col>
                  </Row>
                  <div className="interaction-step-form__form-body__submit-wrapper text-right">
                    <Button
                      type="primary"
                      className="ml-2"
                      htmlType="submit"
                      loading={isSubmitting || formLoading}
                      disabled={!isValid || !dirty || formLoading}
                    >
                      Add
                    </Button>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      )}
    </div>
  );
}

export default InteractionContainer(BabylonContainer(InteractionStepForm));
