export enum ResponsibilityTypes {
  APPOINTMENT_CREATE = "appointment_create",
  APPOINTMENT_DELETE = "appointment_delete",
  APPOINTMENT_EDIT = "appointment_edit",
  APPOINTMENT_STATUS = "appointment_status",
  APPOINTMENT_VIEW = "appointment_view",

  APPOINTMENT_TEMPLATE_CREATE = "appointment_template_create",
  APPOINTMENT_TEMPLATE_DELETE = "appointment_template_delete",
  APPOINTMENT_TEMPLATE_EDIT = "appointment_template_edit",
  APPOINTMENT_TEMPLATE_VIEW = "appointment_template_view",

  COMPLETE_INTERACTION_CREATE = "complete_interaction_create",
  COMPLETE_INTERACTION_DELETE = "complete_interaction_delete",
  COMPLETE_INTERACTION_EDIT = "complete_interaction_edit",
  COMPLETE_INTERACTION_VIEW = "complete_interaction_view",

  CONTRACT_TERM_CREATE = "contract_term_create",
  CONTRACT_TERM_DELETE = "contract_term_delete",
  CONTRACT_TERM_EDIT = "contract_term_edit",
  CONTRACT_TERM_VIEW = "contract_term_view",

  CORPORATE_CREATE = "corporate_create",
  CORPORATE_DELETE = "corporate_delete",
  CORPORATE_EDIT = "corporate_edit",
  CORPORATE_VIEW = "corporate_view",

  CUSTOM_FORM_CREATE = "custom_form_create",
  CUSTOM_FORM_DELETE = "custom_form_delete",
  CUSTOM_FORM_EDIT = "custom_form_edit",
  CUSTOM_FORM_VIEW = "custom_form_view",

  DENTAL_OBJ_CREATE = "dental_obj_create",
  DENTAL_OBJ_DELETE = "dental_obj_delete",
  DENTAL_OBJ_EDIT = "dental_obj_edit",
  DENTAL_OBJ_VIEW = "dental_obj_view",

  HOLIDAY_CREATE = "holiday_create",
  HOLIDAY_DELETE = "holiday_delete",
  HOLIDAY_EDIT = "holiday_edit",
  HOLIDAY_VIEW = "holiday_view",

  INTERACTION_CREATE = "interaction_create",
  INTERACTION_DELETE = "interaction_delete",
  INTERACTION_EDIT = "interaction_edit",
  INTERACTION_VIEW = "interaction_view",

  ISSUE_CREATE = "issue_create",
  ISSUE_DELETE = "issue_delete",
  ISSUE_EDIT = "issue_edit",
  ISSUE_VIEW = "issue_view",

  NOTE_TEMPLATE_CREATE = "note_template_create",
  NOTE_TEMPLATE_DELETE = "note_template_delete",
  NOTE_TEMPLATE_EDIT = "note_template_edit",
  NOTE_TEMPLATE_VIEW = "note_template_view",

  OFFLINE_PAYMENTS_CREATE = "offline_payments_create",
  OFFLINE_PAYMENTS_DELETE = "offline_payments_delete",
  OFFLINE_PAYMENTS_EDIT = "offline_payments_edit",
  OFFLINE_PAYMENTS_VIEW = "offline_payments_view",

  PATIENT_CONTRACT_CREATE = "patient_contract_create",
  PATIENT_CONTRACT_DELETE = "patient_contract_delete",
  PATIENT_CONTRACT_EDIT = "patient_contract_edit",
  PATIENT_CONTRACT_VIEW = "patient_contract_view",

  PATIENT_CREATE = "patient_create",
  PATIENT_DELETE = "patient_delete",
  PATIENT_EDIT = "patient_edit",
  PATIENT_VIEW = "patient_view",

  PAYMENT_INVOICES_CREATE = "payment_invoices_create",
  PAYMENT_INVOICES_EDIT = "payment_invoices_edit",
  PAYMENT_INVOICES_VIEW = "payment_invoices_view",

  PAYMENT_REPORTS_VIEW = "payment_report_view",

  PATIENT_DOC_CREATE = "patient_doc_create",
  PATIENT_DOC_DELETE = "patient_doc_delete",
  PATIENT_DOC_EDIT = "patient_doc_edit",
  PATIENT_DOC_VIEW = "patient_doc_view",

  PRACTICE_CREATE = "practice_create",
  PRACTICE_DELETE = "practice_delete",
  PRACTICE_EDIT = "practice_edit",
  PRACTICE_VIEW = "practice_view",

  PROCEDURE_CREATE = "procedure_create",
  PROCEDURE_DELETE = "procedure_delete",
  PROCEDURE_EDIT = "procedure_edit",
  PROCEDURE_VIEW = "procedure_view",

  RESOURCE_CREATE = "resource_create",
  RESOURCE_DELETE = "resource_delete",
  RESOURCE_EDIT = "resource_edit",
  RESOURCE_VIEW = "resource_view",

  ROLE_CREATE = "role_create",
  ROLE_DELETE = "role_delete",
  ROLE_EDIT = "role_edit",
  ROLE_VIEW = "role_view",

  SCHEDULE_CREATE = "schedule_create",
  SCHEDULE_DELETE = "schedule_delete",
  SCHEDULE_EDIT = "schedule_edit",
  SCHEDULE_VIEW = "schedule_view",

  SERVICE_CREATE = "service_create",
  SERVICE_DELETE = "service_delete",
  SERVICE_EDIT = "service_edit",
  SERVICE_VIEW = "service_view",

  SET_CREATE = "set_create",
  SET_DELETE = "set_delete",
  SET_EDIT = "set_edit",
  SET_VIEW = "set_view",

  STAFF_CREATE = "staff_create",
  STAFF_DELETE = "staff_delete",
  STAFF_EDIT = "staff_edit",
  STAFF_VIEW = "staff_view",

  START_INTERACTION_CREATE = "start_interaction_create",
  START_INTERACTION_DELETE = "start_interaction_delete",
  START_INTERACTION_EDIT = "start_interaction_edit",
  START_INTERACTION_VIEW = "start_interaction_view",

  STATUS_APPOINTMENT_TYPE_CREATE = "status_appointment_type_create",
  STATUS_APPOINTMENT_TYPE_DELETE = "status_appointment_type_delete",
  STATUS_APPOINTMENT_TYPE_EDIT = "status_appointment_type_edit",
  STATUS_APPOINTMENT_TYPE_VIEW = "status_appointment_type_view",

  STOP_INTERACTION_CREATE = "stop_interaction_create",
  STOP_INTERACTION_DELETE = "stop_interaction_delete",
  STOP_INTERACTION_EDIT = "stop_interaction_edit",
  STOP_INTERACTION_VIEW = "stop_interaction_view",

  SUBSCRIPTION_CREATE = "subscription_create",
  SUBSCRIPTION_DELETE = "subscription_delete",
  SUBSCRIPTION_EDIT = "subscription_edit",
  SUBSCRIPTION_VIEW = "subscription_view",

  SUBSCRIPTION_PAYMENT_CREATE = "subscription_payment_create",
  SUBSCRIPTION_PAYMENT_VIEW = "subscription_payment_view",

  TREATMENT_PLAN_CREATE = "treatment_plan_create",
  TREATMENT_PLAN_DELETE = "treatment_plan_delete",
  TREATMENT_PLAN_EDIT = "treatment_plan_edit",
  TREATMENT_PLAN_VIEW = "treatment_plan_view",
}
