import { Button, Col, Row } from "antd";
import { Formik, Form, FormikValues } from "formik";
import React from "react";
import { DentalObjectTypeEnum } from "../../../enums/dentalObjectType.enum";
import { CorporateObject } from "../../../models/CorporateObject/corporateObject.model";
import { ObjectNote } from "../../../models/ObjectNote/objectNote.model";
import ObjectNoteService from "../../../services/ObjectNote/objectNote.service";
import InputField from "../../../shared/components/InputField";
import { getToothPositionId } from "../../../shared/utils/babylonUtils";
import BabylonContainer from "../../../store/container/BabylonContainer";
import InteractionContainer from "../../../store/container/InteractionContainer";
import { BabylonReducerProps } from "../../../store/reducers/babylonReducer";
import { InteractionReducerProps } from "../../../store/reducers/interactionReducer";

import "./corporateObjectNoteForm.scss";
import { corporateObjectFormValidation } from "./corporateObjectNoteValidation";

interface CorporateObjectNoteFormProps
  extends BabylonReducerProps,
    InteractionReducerProps {
  objectNote: ObjectNote;
  onSuccess?: () => void;
  onClose?: () => void;
  onDelete?: () => void;
}

const CorporateObjectNoteForm = (props: CorporateObjectNoteFormProps) => {
  const {
    objectNote,
    onSuccess,
    toothPositions,
    scene,
    sourceObject,
    objectNotes,
    setObjectNotes,
    activeInteractionId,
    onClose,
    onDelete,
    expandedInteractionId,
  } = props;

  const isUpdateObjectNote = Boolean(objectNote?.id);

  const isVendorObject =
    (sourceObject as CorporateObject)?.objectType ===
    DentalObjectTypeEnum.VENDOR;

  const isDeleteBtnVisible =
    isVendorObject && !isUpdateObjectNote && !!onDelete;

  const getTitle = () => {
    let title = "";

    if (!isUpdateObjectNote && !Array.isArray(sourceObject))
      title = sourceObject?.friendlyName || "";
    else if (isUpdateObjectNote) title = objectNote?.corporateObjectName || "";

    return title;
  };

  const handleSubmit = async (values: FormikValues) =>
    new Promise((resolve) => {
      const objectNote = Object.assign(new ObjectNote(), values);
      objectNote.corporateObjectId = (sourceObject as CorporateObject)?.id;
      if (scene) {
        objectNote.toothPositionId = getToothPositionId(
          scene,
          (sourceObject as CorporateObject)?.name || "",
          toothPositions
        );
      }
      if (activeInteractionId) {
        if (isUpdateObjectNote) {
          ObjectNoteService.updateObjectNote(
            activeInteractionId,
            objectNote,
            (objectNote: ObjectNote) => {
              const objectNoteIndex = objectNotes.findIndex(
                (note) => objectNote.id === note.id
              );
              if (objectNoteIndex >= 0) {
                objectNotes[objectNoteIndex] = objectNote;
              }
              setObjectNotes([...objectNotes]);
              onSuccess?.();
            },
            () => {},
            () => {
              onClose?.();
              resolve(null);
            }
          );
        } else {
          ObjectNoteService.createObjectNote(
            activeInteractionId,
            objectNote,
            (objectNote: ObjectNote) => {
            if(activeInteractionId !== expandedInteractionId) return;

              objectNotes.push(objectNote);
              setObjectNotes([...objectNotes]);
              onSuccess?.();
            },
            () => {},
            () => {
              onClose?.();
              resolve(null);
            }
          );
        }
      }
    });

  return (
    <div className="corporate-object-note-form">
      {sourceObject && (
        <div className="corporate-object-note-form__header">
          <span className="text-capitalize corporate-object-note-form__header__title">
            {getTitle()}
          </span>
          {isDeleteBtnVisible && (
            <Button
              type="primary"
              className="btn-danger corporate-object-note-form__header__delete"
              onClick={onDelete}
            >
              <i className="icon-delete"></i>
              <span className="ml-2 text-capitalize">delete</span>
            </Button>
          )}
        </div>
      )}
      <div className="corporate-object-note-form__content">
        <Formik
          initialValues={objectNote}
          onSubmit={handleSubmit}
          enableReinitialAize
          validationSchema={corporateObjectFormValidation}
        >
          {({ isSubmitting, isValid, dirty, setFieldValue }) => {
            return (
              <Form>
                <Row gutter={[20, 20]} align="middle">
                  <Col span={24}>
                    <InputField
                      rows={5}
                      title="Object Notes"
                      type="textarea"
                      name="notes"
                      placeholder="Enter Object Notes"
                      darkmode
                    />
                  </Col>
                  <Col
                    className="corporate-object-note-form__content__footer"
                    span={24}
                  >
                    <Button
                      type="primary"
                      className="text-capitalize corporate-object-note-form__content__footer__submit"
                      htmlType="submit"
                      loading={isSubmitting}
                    >
                      {isUpdateObjectNote ? "edit notes" : "add notes"}
                    </Button>
                    <Button
                      type="primary"
                      className="text-capitalize ml-2 corporate-object-note-form__content__footer__close"
                      htmlType="reset"
                      onClick={onClose}
                    >
                      cancel
                    </Button>
                  </Col>
                </Row>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default BabylonContainer(InteractionContainer(CorporateObjectNoteForm));
