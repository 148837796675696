import React from "react";
import "./procedureActions.scss";
import ProcedureStepForm from "../ProcedureStepForm";
import { ProcedureSetFormTypeEnum } from "../../../../../enums/procedureSetFormType.enum";
import { BabylonReducerProps } from "../../../../../store/reducers/babylonReducer";
import BabylonContainer from "../../../../../store/container/BabylonContainer";
import { ProcedureActionEnum } from "../../../../../enums/procedureAction.enum";
import { InteractionActionEnum } from "../../../../../enums/interactionActionEnum.enum";
import { ICONS } from "../../../../../shared/icons";
import { ProcedureStep } from "../../../../../models/ProcedureStep/procedureStep.model";

interface ProcedureActionsProps extends BabylonReducerProps {
	type: ProcedureActionEnum;
	setOpenConflict: (open: boolean) => void;
	createProcedureStep: (step: ProcedureStep) => void;
}

function ProcedureActions(props: ProcedureActionsProps) {
	const { type, sourceObject, setOpenConflict, createProcedureStep } = props;

	return (
		<div className="procedure-actions">
			<h5 className="text-primary">
				{type === ProcedureActionEnum.ALIGNER ? (
					<i className="icon-aligners mr-2" />
				) : type === ProcedureActionEnum.BUTTON ? (
					<img className="icon-btn mr-2" src={ICONS.BUTTON} alt="" />
				) : (
					<img className="icon-btn mr-2" src={ICONS.ELASTIC} alt="" />
				)}
				{type} Sets
			</h5>
			<ProcedureStepForm
				type={ProcedureSetFormTypeEnum.SET}
				setOpenConflict={setOpenConflict}
				procedureType={type}
				createProcedureStep={createProcedureStep}
			/>
		</div>
	);
}

export default BabylonContainer(ProcedureActions);
