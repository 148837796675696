import moment, { Moment } from "moment";

export const utcToLocalTime = (time: string) => {
  return moment
    .utc(time ?? moment())
    .local()
    .format("h:mm A");
};

export const utcToLocalDate = (date: string) => {
  return moment
    .utc(date ?? moment())
    .local()
    .format("MM-DD-YYYY");
};
