import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";
import {
  setRegisterActiveStep,
  setDetailedOnboardingActiveStep,
  setActiveCorporate,
} from "../actions/registerActions";

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    ...ownProps,
    registerActiveStep: state.register.registerActiveStep,
    detailedOnboardingActiveStep: state.register.detailedOnboardingActiveStep,
    activeCorporate: state.register.activeCorporate,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setRegisterActiveStep,
      setDetailedOnboardingActiveStep,
      setActiveCorporate,
    },
    dispatch
  );

const RegisterContainer = (component: any) => {
  return connect(mapStateToProps, mapDispatchToProps)(component);
};

export default RegisterContainer;
