
import {serializable, alias, object, list, primitive} from 'serializr';

export class CorporateSubscription { 

	@serializable(alias('subscription_frequency_id', primitive()))
	subscriptionFrequencyId?: number;

	@serializable(alias('stripe_payment_method_id', primitive()))
	stripePaymentMethodId?: string;

	@serializable(alias('stripe_subscription_id', primitive()))
	stripeSubscriptionId?: string;

}