import axiosInstance from "../../interceptor/axiosInstance";
import ApiRoutes from "../../routes/routeConstants/apiRoutes";
import { deserialize } from "serializr";
import { Country } from "../../models/Country/country.model";
import { Province } from "../../models/Province/province.model";
import { City } from "../../models/City/city.model";
import { Zipcode } from "../../models/Zipcode/zipcode.model";
import { ReactNode } from "react";
import { IsdCode } from "../../models/IsdCode/isdCode.model";
import { Nationality } from "../../models/Nationality/nationality.model";
import {
  ResourceTypeOption,
  ResourceType,
  ResourceTypes,
} from "../../models/ResourceType/resourceType.model";
import { Contract } from "../../models/Contract/contract.model";
import ContractService from "../Contract/Contract.service";
import { Manufacturer } from "../../models/Manufacturer/manufacturer.model";
import { NotificationTypes } from "../../enums/notificationTypes";
import Notification from "../../shared/components/Notification";
import { ObjectCategory } from "../../models/ObjectCategory/objectCategory.model";
import { ObjectModel } from "../../models/ObjectModel/objectModel.model";
import { NoteTemplate } from "../../models/NoteTemplate/noteTemplate.model";
import { XRayType } from "../../models/XRayType/xRayType.model";
import { ToothPosition } from "../../models/ToothPosition/toothPosition.model";
import { TimeZone } from "../../models/TimeZone/timeZone.model";
import { firstLetterCapital } from "../../shared/utils/textHelpers";

export interface IDropdownOptions {
  label?: string;
  value?: string | number | boolean;
  optionalLabel?: string | ReactNode;
  optionalValue?: string | number;
}

export class MetaService {
  static fetchCountries(
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    return axiosInstance
      .get(ApiRoutes.COUNTRIES)
      .then((response) => {
        const countries = deserialize(Country, response.data["countries"]);
        onSuccess(countries);
      })
      .catch((error) => {
        onError();
      })
      .finally(onFinal);
  }

  static fetchNationalities(
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    return axiosInstance
      .get(ApiRoutes.NATIONALITIES)
      .then((response) => {
        const nationalities = deserialize(
          Nationality,
          response.data["nationalities"]
        );
        onSuccess(nationalities);
      })
      .catch((error) => {
        onError();
      })
      .finally(onFinal);
  }

  static fetchIsdCodes(
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    return axiosInstance
      .get(ApiRoutes.ISD_CODES)
      .then((response) => {
        const isdCodes = deserialize(IsdCode, response.data["isd_codes"]);
        onSuccess(isdCodes);
      })
      .catch((error) => {
        onError();
      })
      .finally(onFinal);
  }

  static fetchProvinces(
    countryId: number,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.PROVINCES.replace(
      ":countryId",
      countryId.toString()
    );
    return axiosInstance
      .get(API_URL)
      .then((response) => {
        const provinces = deserialize(Province, response.data["provinces"]);
        onSuccess(provinces);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(onFinal);
  }

  static fetchToothPositions(
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .get(ApiRoutes.TOOTH_POSITIONS)
      .then((response) => {
        const toothPositions = deserialize(
          ToothPosition,
          response.data["tooth_positions"]
        );
        onSuccess(toothPositions);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(onFinal);
  }

  static fetchCities(
    countryId: number,
    provinceId: number,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.CITIES.replace(
      ":countryId",
      countryId.toString()
    ).replace(":provinceId", provinceId.toString());
    return axiosInstance
      .get(API_URL)
      .then((response) => {
        const cities = deserialize(City, response.data["cities"]);
        onSuccess(cities);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(onFinal);
  }

  static fetchZipcodes(
    countryId: number,
    provinceId: number,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.ZIPCODES.replace(
      ":countryId",
      countryId.toString()
    ).replace(":provinceId", provinceId.toString());
    return axiosInstance
      .get(API_URL)
      .then((response) => {
        const zipcodes = deserialize(Zipcode, response.data["zipcodes"]);
        onSuccess(zipcodes);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(onFinal);
  }

  static fetchResouceType(
    type: "data" | "option",
    onSuccess: (resourceTypes: ResourceTypeOption[] | ResourceType[]) => void,
    onError: (error: Error) => void,
    onFinal: () => void
  ) {
    return axiosInstance
      .get(ApiRoutes.RESOURCE_TYPE)
      .then((response) => {
        const { resourceTypeOptions, resourceTypes } = deserialize(
          ResourceTypes,
          response?.data
        );
        onSuccess(
          type === "option"
            ? resourceTypeOptions?.map(({ label, ...rest }) => ({
                ...rest,
                label: firstLetterCapital(label?.replace(/_/g, " ") ?? ""),
              }))
            : resourceTypes
        );
      })
      .catch((error) => {
        onError(error);
      })
      .finally(onFinal);
  }

  static fetchResouceTypeByName(
    name: string,
    type: "data" | "option",
    onSuccess: (resourceTypes: ResourceTypeOption[] | ResourceType[]) => void,
    onError: (error: Error) => void,
    onFinal: () => void
  ) {
    return axiosInstance
      .get(ApiRoutes.RESOURCE_TYPE, {
        params: {
          resource_type_name: name,
        },
      })
      .then((response) => {
        const { resourceTypeOptions, resourceTypes } = deserialize(
          ResourceTypes,
          response?.data
        );
        onSuccess(type === "option" ? resourceTypeOptions : resourceTypes);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(onFinal);
  }

  static fetchPracticeContract(
    onSuccess: (contract: Contract[]) => void,
    onError: () => void,
    onFinal: () => void
  ) {
    return axiosInstance
      .get(ApiRoutes.PRACTICE_CONTRACT)
      .then((response) => {
        const contracts = deserialize(
          Contract,
          response.data["contracts"] as any[]
        );
        onSuccess(contracts);
      })
      .catch((error) => {
        onError();
      })
      .finally(onFinal);
  }

  static fetchContractService(
    id: number,
    onSuccess: (contract: ContractService[]) => void,
    onError: () => void,
    onFinal: () => void
  ) {
    return axiosInstance
      .get(`${ApiRoutes.PRACTICE_CONTRACT}/${id}/contract_services`)
      .then((response) => {
        const contracts = deserialize(
          ContractService,
          response.data["contract_services"]
        );
        onSuccess(contracts as ContractService[]);
      })
      .catch((error) => {
        onError();
      })
      .finally(onFinal);
  }

  static fetchManufacturers(
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .get(ApiRoutes.MANUFACTURERS)
      .then((response) => {
        const manufacturers = deserialize(
          Manufacturer,
          response.data["manufacturers"]
        );
        onSuccess(manufacturers);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static fetchXRayTypes(
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .get(ApiRoutes.X_RAY_TYPES)
      .then((response) => {
        const xRayTypes = deserialize(XRayType, response.data["x_ray_types"]);
        onSuccess(xRayTypes);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static fetchGlobalNoteTemplates(
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .get(ApiRoutes.NOTE_TEMPLATES)
      .then((response) => {
        const noteTemplates = deserialize(
          NoteTemplate,
          response.data["note_templates"]
        );
        onSuccess(noteTemplates);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static fetchObjectCategories(
    objectType: string,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .get(ApiRoutes.OBJECT_CATEGORIES, {
        params: {
          object_types: [objectType],
        },
      })
      .then((response) => {
        const objectCategories = deserialize(
          ObjectCategory,
          response.data["object_categories"]
        );
        onSuccess(objectCategories);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static fetchObjectModels(
    objectCategoryId: number,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .get(ApiRoutes.OBJECT_MODELS, {
        params: {
          object_category_ids: [objectCategoryId],
        },
      })
      .then((response) => {
        const objectModels = deserialize(
          ObjectModel,
          response.data["object_models"]
        );
        onSuccess(objectModels);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static fetchAllObjectCategories(
    onSuccess: (vendorObjects: ObjectCategory[]) => void,
    onError: (error: Error) => void,
    onFinal: () => void
  ) {
    return axiosInstance
      .get(ApiRoutes.ALL_OBJECT_CATEGORIES)
      .then((response) => {
        const vendorObjects = deserialize(
          ObjectCategory,
          response.data["object_categories"] as any[]
        );
        onSuccess(vendorObjects);
      })
      .catch(onError)
      .finally(onFinal);
  }

  static async getTimeZone() {
    try {
      const { data } = await axiosInstance.get(ApiRoutes.TIME_ZONE);
      const timeZones = deserialize(TimeZone, data?.time_zones as TimeZone[]);
      return timeZones;
    } catch (error) {
      return [];
    }
  }
}
