import { serializable, alias, object, list, primitive } from "serializr";

export class MonthlyPaymentReports {
  @serializable(alias("month", primitive()))
  month?: string;
  @serializable(alias("year", primitive()))
  year?: string;
  @serializable(alias("value", primitive()))
  value?: string;
}
export class PaymentReports {
  @serializable(alias("completed_insurance_payment", primitive()))
  completedInsurancePayment?: number;
  @serializable(alias("status", primitive()))
  status?: string;
  @serializable(alias("contract_cost_yet_to_be_invoiced", primitive()))
  contractCostYetToBeInvoiced?: number;
  @serializable(alias("completed_self_payment", primitive()))
  completedSelfPayment?: number;

  @serializable(alias("outstanding_insurance_payment", primitive()))
  outstandingInsurancePayment?: number;

  @serializable(alias("outstanding_self_payment", primitive()))
  outstandingSelfPayment?: number;

  @serializable(alias("total", primitive()))
  total?: number;
  /* Monthly payment reports detailed view table */
  @serializable(alias("patient_name", primitive()))
  patientName?: string;
  @serializable(alias("insurance_provider", primitive()))
  insuranceProvider?: string;
  @serializable(alias("contract_name", primitive()))
  contractName?: string;
  @serializable(alias("payment_due_date", primitive()))
  paymentDueDate?: string;

  @serializable(alias("amount", primitive()))
  amount?: number;
}
