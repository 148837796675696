import React, { useEffect, useState } from "react";
import { Row, Col, Collapse, Table } from "antd";
import "./contractDetailPaymentSchedule.scss";
import { Contract } from "../../../../../models/Contract/contract.model";
import { CaretRightOutlined } from "@ant-design/icons";
import { ContractInsurance } from "../../../../../models/ContractInsurance/contractInsurance.model";
import { ContractSubscription } from "../../../../../models/ContractSubscription/contractSubscription.model";
import ContractService from "../../../../../services/Contract/Contract.service";
import { StatusEnum } from "../../../../../enums/status.enum";
import { PaymentSchedule } from "../../../../../models/PaymentSchedule/paymentSchedule.model";
import AppLoader from "../../../../../shared/components/AppLoader";
import InsuranceDetail from "./InsuranceDetail";
import SelfPaymentDetail from "./SelfPaymentDetail";
import { formatDate } from "shared/utils/dateFormatter";
const { Panel } = Collapse;

interface ContractDetailPaymentScheduleProps {
  contract: Contract;
}

function ContractDetailPaymentSchedule({
  contract,
}: ContractDetailPaymentScheduleProps) {
  const [
    contractSubscription,
    setContractSubscription,
  ] = useState<ContractSubscription>();
  const [
    paymentScheduleList,
    setPaymentScheduleList,
  ] = useState<PaymentSchedule>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    ContractService.getPaymentScheduleList(
      contract?.id ?? 0,
      (paymentSchedule: PaymentSchedule[]) => {
        if (paymentSchedule.length > 0) {
          const length = paymentSchedule?.length - 1;
          const currentPayment = paymentSchedule[length];
          setPaymentScheduleList(currentPayment);
        }
      },
      () => {},
      () => {
        setLoading(false);
      }
    );
  }, []);

  const selfPaymentData = [
    {
      label: "No of Dues",
      Value: paymentScheduleList?.selfPayment?.noOfDues,
    },

    {
      label: "Due Time",
      Value: paymentScheduleList?.selfPayment?.dueTime,
    },
    {
      label: "Amount",
      Value: `$ ${paymentScheduleList?.selfPayment?.amount ?? 0}`,
    },
  ];
  const columns = [
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "Insurance Provider",
      dataIndex: "insuranceProviderName",
      key: "insuranceProviderName",
    },
    {
      title: "Group No",
      dataIndex: "groupNumber",
      key: "groupNumber",
    },
    {
      title: "Certificate No",
      dataIndex: "certificateNumber",
      key: "certificateNumber",
    },
    {
      title: "Subscriber Name",
      dataIndex: "subscriberName",
      key: "subscriberName",
    },
    {
      title: "Subscriber DOB",
      dataIndex: "subscriberDob",
      key: "subscriberDob",
      render: (name: string) => formatDate(name),
    },
    {
      title: "Relationship",
      dataIndex: "subscriberRelationToPatient",
      key: "subscriberRelationToPatient",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status: string, service: ContractService) => (
        <span
          className={`text-capitalize ${
            status === StatusEnum.ACTIVE ? "success-text" : "text-danger"
          }`}
        >
          {status || "--"}
        </span>
      ),
    },
  ];

  return (
    <div className="contract-detail-payment-schedule">
      {loading ? (
        <AppLoader loading />
      ) : (
        <Row gutter={[20, 0]} justify="space-between">
          <Col span={14}>
            <h5 className="text-primary"> Self Payment</h5>
            <div className="contract-detail-payment-schedule__self-payment">
              <div
                style={{
                  display: "flex",
                }}
              >
                {selfPaymentData?.map((val, index) => {
                  return (
                    <Col span={8}>
                      <p className="contract-detail-payment-schedule__label">
                        {val.label}
                      </p>
                    </Col>
                  );
                })}
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                {selfPaymentData?.map((val, index) => {
                  return (
                    <Col span={8}>
                      <p className="contract-detail-payment-schedule__value">
                        {val.Value}
                      </p>
                    </Col>
                  );
                })}
              </div>
            </div>
          </Col>

          <Col span={5}>
            <h5 className="text-primary"> Deposit</h5>
            <div className="contract-detail-payment-schedule__self-payment ">
              <p className="contract-detail-payment-schedule__label">
                Deposit Amount
              </p>
              <p className="contract-detail-payment-schedule__value">
                {`$ ${paymentScheduleList?.deposit ?? 0}`}
              </p>
            </div>
          </Col>
          <Col span={5}>
            <h5 className="text-primary"> Discount</h5>
            <div className="contract-detail-payment-schedule__self-payment ">
              {" "}
              <p className="contract-detail-payment-schedule__label">
                Discount Amount
              </p>
              <p className="contract-detail-payment-schedule__value">
                {`$ ${paymentScheduleList?.discount ?? 0}`}
              </p>
            </div>
          </Col>
        </Row>
      )}

      <h5 className="text-primary mt-4"> Insurance Provider</h5>
      <Row gutter={[20, 0]}>
        <Col span={24}>
          <Table
            dataSource={paymentScheduleList?.insuranceProviders}
            columns={columns}
            className="secondary-table"
            pagination={false}
          />
        </Col>
      </Row>
      {paymentScheduleList?.insuranceProviders?.map((provider) => (
        <InsuranceDetail insurance={provider} />
      ))}
      <SelfPaymentDetail payment={paymentScheduleList?.selfPayment} />
    </div>
  );
}

export default ContractDetailPaymentSchedule;
