import { serializable, alias, primitive, list, object } from 'serializr';

export class Resource {
    @serializable(alias('id', primitive()))
    id?: number;

    @serializable(alias('name', primitive()))
    name?: string;

    @serializable(alias('practice_id', primitive()))
    practiceId?: string;

    @serializable(alias('practice_name', primitive()))
    practiceName?: string;

    @serializable(alias('resource_type_id', primitive()))
    resourceTypeId?: string;
    
    @serializable(alias('resource_type_name', primitive()))
    resourceTypeName?: string;

    @serializable(alias('description', primitive()))
    description?: string;

    @serializable(alias('status', primitive()))
    status?: string = "active";
}

export class Resources {
    @serializable(alias('resources', list(object(Resource))))
    resources: Resource[] =  [];
}