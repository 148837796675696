import React, { useEffect, useState } from "react";
import "./procedureStepForm.scss";
import { ProcedureSetFormTypeEnum } from "../../../../../enums/procedureSetFormType.enum";
import { Form, Formik, FormikValues } from "formik";
import { Button, Col, Row } from "antd";
import DropdownField from "../../../../../shared/components/DropdownField";
import { ProcedureStep } from "../../../../../models/ProcedureStep/procedureStep.model";
import { DentalSet } from "../../../../../models/DentalSet/dentalSet.model";
import SetService from "../../../../../services/Set/set.service";
import { IDropdownOptions } from "../../../../../services/Meta/meta.service";
import { getDropdownOptions } from "../../../../../shared/utils/optionHelper";
import {
  procedureLinkFormValidation,
  procedureSetFormValidation,
} from "./procedureStepFormValidation";
import ProcedureContainer from "../../../../../store/container/ProcedureContainer";
import { ProcedureReducerProps } from "../../../../../store/reducers/procedureReducer";
import ProcedureService from "../../../../../services/Procedure/procedure.service";
import { Procedure } from "../../../../../models/Procedure/procedure.model";
import BabylonContainer from "../../../../../store/container/BabylonContainer";
import { BabylonReducerProps } from "../../../../../store/reducers/babylonReducer";
import {
  checkConflict,
  checkConflictSet,
  handleImportVendorObject,
} from "../../../../../shared/utils/babylonUtils";
import CorporateObjectService from "../../../../../services/CorporateObject/corporateObject.service";
import { DentalObjectTypeEnum } from "../../../../../enums/dentalObjectType.enum";
import { CorporateObject } from "../../../../../models/CorporateObject/corporateObject.model";
import CorporateLinkService from "../../../../../services/CorporateLink/corporateLink.service";
import { CorporateLink } from "../../../../../models/CorporateLink/corporateLink.model";
import { ProcedureActionEnum } from "../../../../../enums/procedureAction.enum";
import { CONFLICT_TYPES } from "../../../../../enums/conflictType.enum";
import { Scene } from "@babylonjs/core";
import useSets from "../../../../../shared/hooks/useSets";

interface ProcedureStepFormProps
  extends ProcedureReducerProps,
    BabylonReducerProps {
  type: ProcedureSetFormTypeEnum;
  procedureType: ProcedureActionEnum;
  setOpenConflict: (show: boolean) => void;
  onSuccess?: () => void;
  createProcedureStep: (step: ProcedureStep, complete?: () => void) => void;
}

function ProcedureStepForm({
  type,
  createProcedureStep,
  currentProcedure,
  sourceObject,
  procedureType,
  scene,
  setShowPopover,
  pushConflict,
  setOpenConflict,
  setMeshLoading,
}: ProcedureStepFormProps) {
  const {
    fetchSets,
    loading: dentalSetLoading,
    options: dentalSetOptions,
  } = useSets({ patientCategory: currentProcedure?.patientCategory });
  
  const [formLoading, setFormLoading] = useState(false);

  const [vendorObjectLoading, setVendorObjectLoading] = useState(false);

  const [linkLoading, setLinkLoading] = useState(false);

  const [formValues, setFormValues] = useState<ProcedureStep>(
    new ProcedureStep()
  );

  const [vendorObjectOptions, setVendorObjectOptions] = useState<
    IDropdownOptions[]
  >([]);

  const [linkOptions, setLinkOptions] = useState<IDropdownOptions[]>([]);

  const handleFetchLinks = (srcObjectId?: number, destObjectId?: number) => {
    setLinkLoading(true);
    if (srcObjectId && destObjectId) {
      CorporateLinkService.fetchCorporateLinks(
        null,
        srcObjectId,
        destObjectId,
        (corporateLinks: CorporateLink[]) => {
          setLinkOptions(
            corporateLinks.map((link) => ({
              ...link,
              label: link.srcLocatorName + " - " + link.destLocatorName,
              value: link.id,
            }))
          );
        },
        () => {},
        () => {
          setLinkLoading(false);
        }
      );
    }
  };

  useEffect(() => {
    setVendorObjectLoading(true);
    CorporateObjectService.fetchCorporateObjects(
      {},
      DentalObjectTypeEnum.VENDOR,
      (corporateObjects: CorporateObject[]) => {
        setVendorObjectOptions(
          getDropdownOptions(corporateObjects, "name", "id")
        );
      },
      () => {},
      () => {
        setVendorObjectLoading(false);
      },
      {
        //  Future problem Array problem
        dental_object_id: Array.isArray(sourceObject)
          ? undefined
          : sourceObject?.dentalObjectId,
      }
    );
  }, [sourceObject]);

  const SaveProcedureStep = async (
    procedureStep: ProcedureStep,
    conflict?: CONFLICT_TYPES
  ) => {
    if (conflict) {
      setShowPopover(false);
    }
    if (conflict === CONFLICT_TYPES.HARD_APPLY) {
      pushConflict(procedureStep);
      setOpenConflict(true);
    }

    if (!conflict) {
      setFormLoading(true);
      await new Promise((resolve) => {
        createProcedureStep(procedureStep, () => {
          setFormLoading(false);
          setShowPopover(false);
          resolve(null);
          setMeshLoading(false);
        });
      });
    }
  };

  const handleSubmit = async (values: FormikValues) => {
    try{
    setMeshLoading(true);
    setFormValues({ ...values });
    const procedureStep = Object.assign(new ProcedureStep(), values);
    if (procedureStep.setId) {
      const conflict: any = await checkConflictSet(
        procedureStep.setId as number,
        scene as Scene
      );
      await SaveProcedureStep(procedureStep, conflict);
    } else {
      const mesh = scene?.getMeshByName(
        (sourceObject as CorporateObject)?.name ?? ""
      );
      if (mesh && values?.toothLink) {
        const conflict: any = await checkConflict(
          mesh,
          values.toothLink?.srcObjectName,
          values.toothLink?.srcLocatorName,
          values.toothLink?.destObjectName
        );
        await SaveProcedureStep(procedureStep, conflict);
      }
    }
  }catch(ex){}finally{
    setMeshLoading(false);
  }
  };

  return (
    <div className="procedure-step-form">
      {type === ProcedureSetFormTypeEnum.SET ? (
        <Formik
          initialValues={formValues}
          onSubmit={handleSubmit}
          enableReinitialAize
          validationSchema={procedureSetFormValidation}
        >
          {({ values, isSubmitting, isValid, dirty, setFieldValue }) => {
            return (
              <Form>
                <div className="procedure-step-form__form-body">
                  <Row gutter={[20, 20]} align="middle">
                    <Col span={16}>
                      <DropdownField
                        name="setId"
                        placeHolder="Select Set"
                        showSearch
                        onChange={(value, dentalSet) => {
                          setFieldValue("setId", value);
                          setFieldValue("set", dentalSet);
                        }}
                        loading={dentalSetLoading}
                        options={dentalSetOptions}
                        onScrollEnd={fetchSets}
                        value={values.setId}
                      />
                    </Col>
                    <Col span={8}>
                      <Button
                        type="primary"
                        className="ml-2"
                        htmlType="submit"
                        loading={isSubmitting || formLoading}
                      >
                        Add Set
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Form>
            );
          }}
        </Formik>
      ) : (
        <Formik
          initialValues={{ ...new ProcedureStep(), toothLink: {} }}
          onSubmit={handleSubmit}
          enableReinitialAize
          validationSchema={procedureLinkFormValidation}
        >
          {({ values, isValid, dirty, setFieldValue }) => {
            return (
              <Form>
                <div className="procedure-step-form__form-body">
                  <Row gutter={[20, 20]}>
                    <Col span={24}>
                      <h5 className="text-primary"> {procedureType} Objects</h5>
                      <DropdownField
                        name="destObjectId"
                        placeHolder="Select Vendor Object"
                        showSearch
                        loading={vendorObjectLoading}
                        onChange={(value) => {
                          setFieldValue("destObjectId", value);
                          handleFetchLinks(
                            (sourceObject as CorporateObject)?.id,
                            value
                          );
                        }}
                        options={vendorObjectOptions}
                        value={values.destObjectId}
                      />
                    </Col>
                    <Col span={24}>
                      <h5 className="text-primary">
                        Source Locator - Destination Locator
                      </h5>
                      <DropdownField
                        name="corporateLinkId"
                        placeHolder="Select Link"
                        showSearch
                        onChange={(_, values) => {
                          setFieldValue("corporateLinkId", values.value);
                          setFieldValue("toothLink", values);
                        }}
                        loading={linkLoading}
                        options={linkOptions}
                        value={values.corporateLinkId}
                      />
                    </Col>
                  </Row>
                  <div className="procedure-step-form__form-body__submit-wrapper text-right">
                    <Button
                      type="primary"
                      className="ml-2"
                      htmlType="submit"
                      loading={formLoading}
                      disabled={!isValid || !dirty || formLoading}
                    >
                      Add
                    </Button>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      )}
    </div>
  );
}

export default BabylonContainer(ProcedureContainer(ProcedureStepForm));
