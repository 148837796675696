import React from "react";
import { FieldArray, useField } from "formik";
import { CustomForm } from "../../../models/CustomForm/customForm.model";
import { Button, Col, Row } from "antd";
import InputField from "../../../shared/components/InputField";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { CustomSection } from "../../../models/CustomSection/customSection.model";
import CustomFieldsWrapper from "../CustomFieldsWrapper";

interface CustomSectionWrapperProps {
  name: "sections";
}

const CustomSectionWrapper = ({ name }: CustomSectionWrapperProps) => {
  const [{ value }] = useField<CustomForm["sections"]>(name);

  return (
    <FieldArray
      name="sections"
      render={({ push, remove }) => (
        <div className="mt-5 custom-form-wrapper__sections">
          {value?.map((_, index) => {
            return (
              <Row key={index} className="custom-form-wrapper__sections__item">
                <Col span={20}>
                  <InputField
                    title="Section name"
                    type="text"
                    name={`sections[${index}].name`}
                    placeholder="Enter Section name"
                  />
                </Col>
                <Col offset={1} span={3} className="text-right">
                  <Button
                    onClick={() => remove(index)}
                    className="delete-btn"
                    icon={<DeleteOutlined />}
                  />
                </Col>
                <Col span={24}>
                  <CustomFieldsWrapper
                    name={`sections[${index}].customFields`}
                  />
                </Col>
              </Row>
            );
          })}
          <div className="mt-5">
            <Button
              className="secondary-btn"
              htmlType="button"
              onClick={() => push(new CustomSection())}
            >
              <PlusOutlined /> Add Section
            </Button>
          </div>
        </div>
      )}
    />
  );
};

export default CustomSectionWrapper;
