export enum PaymentType {
  STRIPE = "Stripe",
  CASH = "cash",
  CHEQUE = "cheque",
  CARD = "card",
  SELF = "self_payment",
  INSURANCE = "insurance",
  DEPOSIT = "deposit",
  INSURANCE_DEPOSIT = "insurance_deposit"
}
