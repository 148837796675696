import { Divider } from "antd";
import Checkbox, { CheckboxChangeEvent } from "antd/lib/checkbox/Checkbox";
import React, { FC, useEffect } from "react";
import { useHistory } from "react-router";
import CorporateContainer from "../../../../store/container/CorporateContainer";
import { CorporateReducerProps } from "../../../../store/reducers/corporateReducer";
import "./listItem.scss";

interface ListItemProps extends CorporateReducerProps {
  text: string;
  isCompleted: boolean;
  link?: string;
  linkText?: string;
  hasDivider?: boolean;
  index: number;
  loading?: boolean;
  visitHandler: (checked: boolean, index: number) => void;
}

const ListItem: FC<ListItemProps> = (props) => {
  const {
    text,
    isCompleted,
    loading,
    link,
    linkText,
    index,
    hasDivider,
    visitHandler,
    setOpenCheckList,
  } = props;

  const history = useHistory();

  const handleRedirectLink = () => {
    link && history.push(link);
    setTimeout(() => {
      setOpenCheckList(false);
    }, 500);
  };

  const handleChecked = (event: CheckboxChangeEvent) => {
    visitHandler(event.target.checked, index);
  };
  return (
    <div className="list-item">
      <div className="check-list">
        <Checkbox
          onChange={handleChecked}
          checked={isCompleted}
          disabled={loading}
        />
        <div className="content">
          <p className={`text ${isCompleted && "text-strike-through"}`}>
            {text}
          </p>
          {link && !isCompleted && (
            <p className="link" onClick={handleRedirectLink}>
              {linkText}
            </p>
          )}
        </div>
      </div>
      {hasDivider && <Divider />}
    </div>
  );
};

export default CorporateContainer(ListItem);
