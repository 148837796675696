import { serializable, alias, primitive, optional } from "serializr";
import { StatusEnum } from "../../enums/status.enum";
import { DentalObject } from "../DentalObject/dentalObject.model";

export class CorporateObject extends DentalObject {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("status", primitive()))
  status?: string = StatusEnum.ACTIVE;

  @serializable(alias("dental_object_id", primitive()))
  dentalObjectId?: number;

  @serializable(optional(alias("corporate_link_id", primitive())))
  corporateLinkId?: number
}

export class CorporateObjectWithBabylonFile {
  corporateObject?: CorporateObject;

  babylonFile?: File;
}
