import { deserialize } from "serializr";
import axiosInstance from "../../interceptor/axiosInstance";
import { XRayAttachment, XRayImage } from "../../models/XRayImage/xRayImage.model";
import ApiRoutes from "../../routes/routeConstants/apiRoutes";

interface Option {
	practiceId: string | number;
	interactionId: string | number;
}
export class PreVisitService {
	static getUrl(option: Option) {
		const URL = ApiRoutes.PRE_VISIT.replace(
			":practiceId",
			option.practiceId.toString()
		).replace(":interactionId", option.interactionId.toString());
		return URL;
	}

	static fetchPreVisit(
		option: Option,
		onSuccess: (preVisitImages: XRayImage[]) => void,
		onError: (error: Error) => void,
		onFinal: () => void
	) {
		const URL = this.getUrl(option);
		return axiosInstance
			.get(URL)
			.then((response) => {
				const preVisitImages = deserialize(XRayImage, response.data["pre_visit_images"] as any[]);
				onSuccess(preVisitImages);
			})
			.catch(onError)
			.finally(onFinal);
	}

	static uploadImage(
		option: Option,
		image: any,
		onSuccess: (attachment?: XRayAttachment) => void,
		onError: (error: Error) => void,
		onFinal: () => void
	) {
		const URL = this.getUrl(option);
		const payload = new FormData();
		payload.append("pre_visit_image.attachment", image);
		return axiosInstance
			.post(URL, payload)
			.then((attachment) => {
				const deserializedAttachment = deserialize(XRayAttachment,attachment?.data?.["pre_visit_image"]);
				onSuccess(deserializedAttachment);
			})
			.catch(onError)
			.finally(onFinal);
	}

	static openImage(
		option: Option,
		imageId: number | string,
		onSuccess: (xRayImage: XRayAttachment) => void,
		onError: (error: Error) => void,
		onFinal: () => void
	) {
		const URL = ApiRoutes.PRE_VISIT.replace(
			":practiceId",
			option.practiceId.toString()
		).replace(":interactionId", option.interactionId.toString());
		return axiosInstance
			.get(`${URL}/${imageId}`)
			.then((response) => {
				const preVisitImage = deserialize(XRayAttachment, response.data["pre_visit_image"]);
				onSuccess(preVisitImage);
			})
			.catch(onError)
			.finally(onFinal);
	}

	static deleteVisit(
		option: Option,
		imageId: number | string,
		onSuccess: () => void,
		onError: (error: Error) => void,
		onFinal: () => void
	) {
		const URL = this.getUrl(option);
		return axiosInstance
			.delete(`${URL}/${imageId}`)
			.then((response) => {
				onSuccess();
			})
			.catch(onError)
			.finally(onFinal);
	}
}
