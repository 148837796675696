import ApiRoutes from "../../routes/routeConstants/apiRoutes";
import axiosInstance from "../../interceptor/axiosInstance";
import { Procedure } from "../../models/Procedure/procedure.model";
import { deserialize, serialize } from "serializr";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import { ProcedureStep } from "../../models/ProcedureStep/procedureStep.model";
import { FilterParams } from "../../models/FilterParams/filterParams.model";
import { PaginationDetails } from "../../models/Pagination/pagination.model";

export default class ProcedureService {
  static fetchProcedures(
    onSuccess: (procedures: Procedure[], pagination: PaginationDetails) => void,
    onError: Function,
    onFinal: () => void,
    filter?: FilterParams
  ) {
    const params = serialize(FilterParams, filter ?? new FilterParams());
    axiosInstance
      .get(ApiRoutes.PROCEDURES, { params })
      .then((response) => {
        const procedures = deserialize(
          Procedure,
          response.data["procedures"] as any[]
        );
        const pagination = deserialize(
          PaginationDetails,
          response.data["meta"]
        );
        onSuccess(procedures, pagination);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static showProcedure(
    procedureId: number,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.PROCEDURES + "/" + procedureId;
    axiosInstance
      .get(API_URL)
      .then((response) => {
        const procedure = deserialize(Procedure, response.data["procedure"]);
        onSuccess(procedure);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static createProcedure(
    procedure: Procedure,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const procedureJSON = { procedure: serialize(procedure) };
    axiosInstance
      .post(ApiRoutes.PROCEDURES, procedureJSON)
      .then((response) => {
        Notification({
          message: "Procedure created",
          type: NotificationTypes.SUCCESS,
        });
        const procedure = deserialize(Procedure, response.data["procedure"]);
        onSuccess(procedure);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static updateProcedure(
    procedure: Procedure,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.PROCEDURES + "/" + procedure.id;
    const procedureJSON = { procedure: serialize(procedure) };
    axiosInstance
      .put(API_URL, procedureJSON)
      .then((response) => {
        Notification({
          message: "Procedure updated",
          type: NotificationTypes.SUCCESS,
        });
        const procedure = deserialize(Procedure, response.data["procedure"]);
        onSuccess(procedure);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static cloneProcedure(
    procedure: Procedure,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.CLONE_PROCEDURE.replace(
      ":procedureId",
      procedure?.id ? procedure.id.toString() : ""
    );
    const procedureJSON = { procedure: serialize(procedure) };
    axiosInstance
      .post(API_URL, procedureJSON)
      .then((response) => {
        Notification({
          message: "Procedure cloned",
          type: NotificationTypes.SUCCESS,
        });
        const procedure = deserialize(Procedure, response.data["procedure"]);
        onSuccess(procedure);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static deleteProcedure(
    procedureId: number,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.PROCEDURES + "/" + procedureId;
    axiosInstance
      .delete(API_URL)
      .then((response) => {
        onSuccess();
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static createProcedureStep(
    procedureId: number,
    procedureStep: ProcedureStep,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.PROCEDURE_STEPS.replace(
      ":procedureId",
      procedureId.toString()
    );
    const procedureStepJSON = { procedure_step: serialize(procedureStep) };
    axiosInstance
      .post(API_URL, procedureStepJSON)
      .then(async (response) => {
        Notification({
          message: "Procedure step added",
          type: NotificationTypes.SUCCESS,
        });
        const procedureStep = deserialize(
          ProcedureStep,
          response.data["procedure_step"]
        );
        await onSuccess(procedureStep);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static deleteProcedureStep(
    procedureId: number,
    procedureStepId: number,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL =
      ApiRoutes.PROCEDURE_STEPS.replace(
        ":procedureId",
        procedureId.toString()
      ) +
      "/" +
      procedureStepId;
    axiosInstance
      .delete(API_URL)
      .then((response) => {
        Notification({
          message: "Procedure step removed",
          type: NotificationTypes.SUCCESS,
        });
        onSuccess();
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }
}
