import React, { useState, useEffect, FC } from "react";
import { Pie } from "react-chartjs-2";
import { Chart, ArcElement, Tooltip, Legend } from "chart.js";
import {
  MonthlyPaymentReports,
  PaymentReports,
} from "../../../../models/PaymentReports/paymentReports.model";
import { IDropdownOptions } from "../../../../services/Meta/meta.service";
import { toLowerUnderScoreText } from "../../../../shared/utils/lowerunderscoreText";
Chart.register(ArcElement, Tooltip);

interface PiCharttProps {
  paymentType: string;
  paymentReports?: PaymentReports;
  monthlyPaymentReports?: MonthlyPaymentReports[];
  onSelect?: (type: string, paymentType?: string) => void;
  onPaymentChange?: () => void;
}

const PiChart: FC<PiCharttProps> = (props) => {
  const {
    paymentType,
    monthlyPaymentReports,
    paymentReports,
    onSelect,
    onPaymentChange,
  } = props;
  const [graph, setGraph] = useState<any>({
    labels: [],
    data: [],
  });

  const monthlyReport = monthlyPaymentReports?.map((val) => {
    return {
      label: `${val?.month},${val?.year}`,
      value: Number(val?.value),
    };
  });
  const completedPayment = [
    {
      label: "Insurance",
      value: paymentReports?.completedInsurancePayment ?? 0,
    },
    {
      label: "Self Payment",
      value: paymentReports?.completedSelfPayment ?? 0,
    },
    {
      label: "Outstanding Insurance",
      value: paymentReports?.outstandingInsurancePayment ?? 0,
    },
    {
      label: "Outstanding Self payment",
      value: paymentReports?.outstandingSelfPayment ?? 0,
    },
  ];

  const handlePaymentReport = () => {
    const labels: any[] = [];
    const data: any[] = [];

    const options =
      paymentType == "completed" ? completedPayment : monthlyReport;

    options?.map((v: any) => {
      labels.push(v?.label);
      data.push(v?.value);
    });

    setGraph({
      labels: labels,
      data: data,
    });
  };

  useEffect(() => {
    handlePaymentReport();
  }, [paymentReports, monthlyPaymentReports]);

  const data = {
    labels: graph.labels,

    datasets: [
      {
        label: "# of Votes",
        data: graph.data,
        backgroundColor: ["#E2CEFD", "#D5B6FD", "#A5DCAC", "#91CC98"],

        borderWidth: 1,
      },
    ],
  };
  const handlePayment = (_: any, chartElements: any) => {
    if (chartElements) {
      const index = chartElements[0]?.index;
      const options: any =
        paymentType === "completed" ? completedPayment : monthlyReport;

      onSelect &&
        onSelect(
          toLowerUnderScoreText(options?.[index]?.label),
          options?.[index]?.label
        );
    }
  };

  return (
    <>
      <div className="PiChart">
        <div style={{ height: "248px", width: "248px", margin: "0 auto" }}>
          <Pie
            data={data}
            options={{
              onClick: handlePayment,
            }}
          />
        </div>
      </div>
    </>
  );
};

export default PiChart;
