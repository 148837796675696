
import {InteractionSubStatuse} from '../InteractionSubStatuse/interactionSubStatuse.model';

import {serializable, alias, object, list, primitive} from 'serializr';
import { StatusType } from '../../enums/statusType.enum';

export class InteractionStatus { 
	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('name', primitive()))
	name?: string;

	@serializable(alias('interaction_sub_statuses', list(object(InteractionSubStatuse))))
	interactionSubStatuses: InteractionSubStatuse[] = [];

	static getTypeSpecificStatus = (
		statuses: InteractionStatus[],
		type: StatusType
	  ) => statuses?.find(({ name }) => name === type);
}

export class InteractionStatuses { 
	@serializable(alias('interaction_statuses', list(object(InteractionStatus))))
	interactionStatuses: InteractionStatus[] = [];
}