import { Button, Checkbox, Col, Row } from "antd";
import { Form, Formik } from "formik";
import React, { FC, useState } from "react";
import { useEffect } from "react";
import "./paymentFilterForm.scss";
import DatePickerField from "../../../shared/components/DatePickerField";
import RadioInputField from "../../../shared/components/RadioInputField";
import { IDropdownOptions } from "../../../services/Meta/meta.service";
import { TimePeriodEnum } from "../../../enums/timePeriodType.enum";
import CheckboxField from "../../../shared/components/Checkbox";
import DropdownField from "../../../shared/components/DropdownField";
import { PaymentFilters } from "../../../models/Payment/payment.model";
import PaymentService from "../../../services/Payment/payment.service";
import { PaymentIntent } from "../../../models/PaymentIntent/paymentIntent.model";
import PatientService from "../../../services/Patient/patient.service";
import { Patient } from "../../../models/Patient/patient.model";
import { SelectProp } from "../../../shared/Types/option";
import { StatusEnum } from "../../../enums/status.enum";
import { PaymentType } from "../../../enums/PaymentType.enum";
import { paymentModeOptions } from "shared/options/paymentModelOptions";

interface PaymentFilterFormProps {
  onClose: () => void;
  onSuccess: (updatedPaymentList: PaymentIntent[]) => void;
}

const PaymentFilterForm: FC<PaymentFilterFormProps> = (props) => {
  const { onClose, onSuccess } = props;

  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState<PaymentFilters>(
    new PaymentFilters()
  );
  const [patientOptions, setpatientOptions] = useState<IDropdownOptions[]>();

  const handleSubmit = (values: PaymentFilters) => {
    setLoading(true);
    PaymentService.getPaymentInvoices(
      values,
      (paymentIntents: PaymentIntent[]) => {
        onSuccess(paymentIntents);
        onClose();
      },
      () => {},
      () => {
        setLoading(false);
      }
    );
  };
  const Options = [
    {
      label: "Self",
      value: PaymentType.SELF,
    },
    {
      label: "Deposit",
      value: PaymentType.DEPOSIT,
    },
    {
      label: "Insurance",
      value: PaymentType.INSURANCE,
    },
    {
      label: "Insurance Deposit",
      value: PaymentType.INSURANCE_DEPOSIT,
    },
  ];

  const statusOptions: SelectProp[] = [
    {
      label: "Completed",
      value: StatusEnum.COMPLETED,
    },
    {
      label: "Initiated",
      value: StatusEnum.INITIATED,
    },
    {
      label: "Past Due",
      value: StatusEnum.PAST_DUE,
    },

    {
      label: "Invalid",
      value: StatusEnum.INVALID,
    },
    {
      label: "Written Off",
      value: StatusEnum.WRITTEN_OFF,
    },
  ];
  useEffect(() => {
    PatientService.fetchPatients(
      {},
      (patients: Patient[]) => {
        const options = patients?.map((val) => {
          return {
            label: val?.firstName + " " + val?.lastName,
            value: val?.id,
          };
        });
        setpatientOptions(options);
      },
      () => {},
      () => {}
    );
  }, []);
  return (
    <div className="payment-filter-form">
      <Formik initialValues={formValues ?? []} onSubmit={handleSubmit}>
        {({ values, setFieldValue }) => (
          <Form>
            <div className="payment-filter-form__container">
              <p className="text-primary mb-4">Invoice Date Range</p>
              <Row gutter={[20, 0]}>
                <Col span={12}>
                  <DatePickerField
                    title="Date From"
                    format="MM-DD-YYYY"
                    name="fromDate"
                    setFieldValue={setFieldValue}
                    onChange={(value) => {
                      setFieldValue(`fromDate`, value?.format("YYYY-MM-DD"));
                    }}
                  />
                </Col>
                <Col span={12}>
                  <DatePickerField
                    title="Date to"
                    name="toDate"
                    format="MM-DD-YYYY"
                    setFieldValue={setFieldValue}
                    onChange={(value) => {
                      setFieldValue(`toDate`, value?.format("YYYY-MM-DD"));
                    }}
                  />
                </Col>
              </Row>
              <div>
                <p className="text-primary mb-4">Payment Type</p>
                <Row gutter={[20, 0]}>
                  <Col span={24}>
                    <RadioInputField
                      name="paymentType"
                      value={values?.paymentType}
                      setFieldValue={setFieldValue}
                      options={Options}
                    />
                  </Col>
                </Row>
              </div>
              <div>
                <p className="text-primary mb-4">Status</p>
                <Row gutter={[20, 0]}>
                  <Col span={24}>
                    <RadioInputField
                      name="status"
                      value={values?.status}
                      setFieldValue={setFieldValue}
                      options={statusOptions}
                    />
                  </Col>
                  <p className="text-primary mb-4">Payment Mode</p>
                  <Col span={24}>
                    <RadioInputField
                      name="paymentMode"
                      value={values?.paymentMode}
                      setFieldValue={setFieldValue}
                      options={paymentModeOptions}
                    />
                  </Col>

                  <Col span={12}>
                    {" "}
                    <DropdownField
                      title="Patient"
                      name="patientProfileId"
                      options={patientOptions}
                      setFieldValue={setFieldValue}
                      // value={activeCustomFormId}

                      placeHolder="Select "
                    />
                  </Col>
                </Row>
              </div>
            </div>
            <div className="mt-3 text-right ">
              <Button type="text" onClick={onClose}>
                Cancel
              </Button>
              <Button type="primary" htmlType="submit" loading={loading}>
                Apply
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default PaymentFilterForm;
