import { serializable, alias, primitive } from "serializr";

export class ToothPosition {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("iso_numbering_system_name", primitive()))
  isoNumberingSystemName?: number;

  @serializable(alias("palmer_notation_system_name", primitive()))
  palmerNotationSystemName?: string;

  @serializable(alias("universal_numbering_system_name", primitive()))
  universalNumberingSystemName?: string;

  @serializable(alias("default_object_name", primitive()))
  defaultObjectName?: string;
}
