import React from "react";
import { ColumnProps } from "antd/lib/table";
import { XRayCategory } from "models/XRayCategory/xRayCategory.model";

export const getColumns = (
  onEdit?: (category: XRayCategory) => void
): ColumnProps<XRayCategory>[] => [
  {
    title: "Category",
    dataIndex: "name",
    width: "20%",
  },
  {
    title: "Description",
    dataIndex: "description",
  },
  {
    title: "",
    dataIndex: "",
    key: "",
    width: "10%",
    render: (_, category) => {
      return (
        <i
          className="icon-edit resource-edit-icon"
          onClick={(event) => {
            event.stopPropagation();
            onEdit?.(category);
          }}
        />
      );
    },
  },
];
