import { serializable, alias, object, list, primitive } from "serializr";

export class PatientPreDeterminationForm {
  @serializable(alias("insurance_name", primitive()))
  insuranceName?: string;

  @serializable(alias("subscriber_name", primitive()))
  subscriberName?: string;
  @serializable(alias("address", primitive()))
  address?: string;
  @serializable(alias("group_policy", primitive()))
  groupPolicy?: string;
  @serializable(alias("certificate_number", primitive()))
  certificateNumber?: string;
  @serializable(alias("relationship_to_the_subscriber", primitive()))
  relationshipToTheSubscriber?: string;
  @serializable(alias("initial_payment", primitive()))
  initialPayment?: number;
  @serializable(alias("no_of_dues", primitive()))
  noOfDues?: number;
  @serializable(alias("due_amount", primitive()))
  dueAmount?: number;
  @serializable(alias("is_monthly", primitive()))
  isMonthly?: boolean;
  @serializable(alias("is_quarterly", primitive()))
  isQuarterly?: boolean;

  period?: string;
  @serializable(alias("pre_determination_form_url", primitive()))
  formUrl?: string;
}
