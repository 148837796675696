import { IDropdownOptions } from "../../services/Meta/meta.service";
import { SelectProp } from "../Types/option";

export const uniqueObjectValue = (
  array1: SelectProp[],
  array2: SelectProp[]
) => {
  return [...array1, ...array2].filter(
    (curr, index, array) =>
      array?.findIndex((v2) => v2?.value === curr?.value) === index
  );
};
