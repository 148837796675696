import { Col, Row } from "antd";
import React, { FC } from "react";
import { StatsType } from "../../../enums/statsType.enum";
import { hexAToRGBA } from "../../utils/hexToRgb";
import { numberWithCommas } from "../../utils/numberWithCommas";
import "./stats.scss";

interface StatsData {
  title: string;
  value: number | string;
  total?: number;
}
interface StatProps {
  statsData: StatsData[];
  statsType?: StatsType;
  handleVisibility?: () => void;
}
const colors = [
  { light: "#CED9FD", dark: "#3E4B78" },
  { light: "#F9CBA1", dark: "#AC682A" },
  { light: "#BDDBA6", dark: "#536B40" },
  { light: "#DBA6A6", dark: "#6B4040" },
];

const Stats: FC<StatProps> = (props) => {
  const { statsData, statsType, handleVisibility } = props;
  return (
    <Row className="stats__container mt-3" gutter={[15, 15]}>
      {statsData?.map((data, index) => (
        <Col
          span={
            statsType === StatsType.SERVICE ||
            statsType === StatsType.CUSTOM_FORMS ||
            statsType === StatsType.PAYMENT ||
            statsType === StatsType.REPORTS
              ? 12
              : statsType === StatsType.PATIENTS
              ? 6
              : 8
          }
        >
          <Row
            className="stat__container"
            style={{ backgroundColor: colors[index].light }}
          >
            <i
              className="icon-services float-icon"
              style={{
                color: hexAToRGBA(colors[index].dark, 0.1),
              }}
            />

            <Col span={8} className="stat-icon__wrapper">
              <i
                className="icon-services"
                style={{
                  backgroundColor: hexAToRGBA(colors[index].dark, 0.5),
                  color: colors[index].dark,
                }}
              />
            </Col>
            <Col span={16} className="stat-content__wrapper">
              <p className="">{data?.title}</p>
              <h2 className="stat-content__value">
                {numberWithCommas(Number(data?.value))}
              </h2>
              {StatsType.CONTRACT === statsType ? (
                <p>$ {numberWithCommas(Number(data?.total))}</p>
              ) : (
                ""
              )}
              {StatsType.PATIENTS === statsType &&
              data?.title === StatsType.PATIENT_WITHOUT_FUTURE_APPOINTMENT ? (
                <a onClick={handleVisibility}>view All</a>
              ) : (
                ""
              )}
            </Col>
          </Row>
        </Col>
      ))}
    </Row>
  );
};

export default Stats;
