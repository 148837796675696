export const openTeethPosition: any = {
  Canine: 250,
  CentralIncisor: 150,
  LateralIncisor: 170,
  Molar4: 320,
  Molar5: 350,
  Molar6: 400,
  Molar7: 470,
  Molar8: 500,
  Premolar4: 320,
  Premolar5: 350,
};
