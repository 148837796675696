import React, { useMemo, useState } from "react";
import {
  Switch,
  Route,
  Redirect,
  useHistory,
  useLocation,
} from "react-router-dom";
import AppRoutes from "../../../routes/routeConstants/appRoutes";
import Resource from "./Resources";
import { RouteProps } from "../../../routes";
import { Tabs } from "antd";
import StatusManagement from "./StatusManagement";
import NoteTemplate from "./NoteTemplate";
import CorporateObjectList from "./CorporateObjects/CorporateObjectList";
import CorporateObjectDetail from "./CorporateObjects/CorporateObjectDetail";
import CorporateObjectLinks from "./CorporateObjects/CorporateObjectLinks";
import SetList from "./Sets/SetList";
import ProcedureList from "./Procedures/ProcedureList";
import TreatmentTemplate from "./TreatmentTemplate";
import TreatmentTemplateDetails from "./TreatmentTemplate/TreatmentTemplateDetails";
import { useEffect } from "react";
import AuthContainer from "../../../store/container/AuthContainer";
import { AuthReducerProps } from "../../../store/reducers/authReducer";
import { FC } from "react";
import { ResponsibilityTypes } from "../../../enums/responsebily.enum";
import { TabProps } from "../../../shared/Types/tab.type";
import requireAuth from "../../../shared/components/HOC/requireAuth";

const { TabPane } = Tabs;

const configurationRoutes: RouteProps[] = [
  {
    exact: true,
    component: requireAuth(Resource, ResponsibilityTypes.RESOURCE_VIEW),
    path: AppRoutes.RESOURCES,
  },
  {
    exact: true,
    component: requireAuth(SetList, ResponsibilityTypes.SET_VIEW),
    path: AppRoutes.SETS,
  },
  {
    exact: true,
    component: requireAuth(ProcedureList, ResponsibilityTypes.PROCEDURE_VIEW),
    path: AppRoutes.PROCEDURES,
  },
  {
    exact: true,
    component: requireAuth(
      CorporateObjectList,
      ResponsibilityTypes.DENTAL_OBJ_VIEW
    ),
    path: AppRoutes.CORPORATE_OBJECTS,
  },
  {
    exact: true,
    component: CorporateObjectDetail,
    path: AppRoutes.CORPORATE_OBJECT_DETAIL,
  },
  {
    exact: true,
    component: CorporateObjectLinks,
    path: AppRoutes.CORPORATE_OBJECT_LINKS,
  },
  {
    exact: false,
    component: requireAuth(
      StatusManagement,
      ResponsibilityTypes.STATUS_APPOINTMENT_TYPE_VIEW
    ),
    path: AppRoutes.STATUS_MANAGEMENT,
  },
  {
    exact: false,
    component: requireAuth(
      NoteTemplate,
      ResponsibilityTypes.NOTE_TEMPLATE_VIEW
    ),
    path: AppRoutes.NOTE_TEMPLATE,
  },
  {
    exact: true,
    component: requireAuth(
      TreatmentTemplate,
      ResponsibilityTypes.TREATMENT_PLAN_VIEW
    ),
    path: AppRoutes.TREATMENT_PLANS,
  },
];

interface ConfigurationProps extends AuthReducerProps {}

const Configuration: FC<ConfigurationProps> = ({ userResponsibilities }) => {
  const history = useHistory();

  const location = useLocation();

  const [activeTab, setActiveTab] = useState("0");

  const [configurationTabs, setConfigurationTabs] = useState<TabProps[]>([]);

  const tabs = useMemo(
    () => [
      {
        title: "Treatment Execution Plans",
        path: AppRoutes.TREATMENT_PLANS,
        key: ResponsibilityTypes.TREATMENT_PLAN_VIEW,
      },
      // TODO: Need to be handled once Procedure is used
      // {
      //   title: "Procedures",
      //   path: AppRoutes.PROCEDURES,
      //   key: ResponsibilityTypes.PROCEDURE_VIEW,
      // },
      {
        title: "Sets",
        path: AppRoutes.SETS,
        key: ResponsibilityTypes.SET_VIEW,
      },
      // TODO: Need to be handled once Procedure is used
      // {
      //   title: "3D Object",
      //   path: AppRoutes.CORPORATE_OBJECTS,
      //   key: ResponsibilityTypes.DENTAL_OBJ_VIEW,
      // },
      {
        title: "Note Template",
        path: AppRoutes.NOTE_TEMPLATE,
        key: ResponsibilityTypes.NOTE_TEMPLATE_VIEW,
      },
      {
        title: "Status Management",
        path: AppRoutes.STATUS_MANAGEMENT,
        key: ResponsibilityTypes.STATUS_APPOINTMENT_TYPE_VIEW,
      },
      {
        title: "Resources",
        path: AppRoutes.RESOURCES,
        key: ResponsibilityTypes.RESOURCE_VIEW,
      },
    ],
    []
  );

  useEffect(() => {
    const updatedConfigurationTabs: TabProps[] = [];
    tabs.forEach((tab) => {
      if (checkHasPermission(tab?.key)) {
        updatedConfigurationTabs.push(tab);
      }
    });
    if (
      updatedConfigurationTabs.length &&
      location.pathname.split("/").length === 2
    ) {
      history.push(updatedConfigurationTabs[0]?.path ?? "");
    }
    setConfigurationTabs(updatedConfigurationTabs);
    findActiveKey();
  }, [tabs, userResponsibilities]);

  const handleChangeTab = (key: string) => {
    setActiveTab(key);
    if (configurationTabs[parseInt(key) as number]?.path) {
      history.push(configurationTabs[parseInt(key) as number]?.path as string);
    }
  };

  const findActiveKey = () => {
    for (let i in tabs) {
      if (tabs[i].path === location.pathname) {
        setActiveTab(parseInt(i).toString());
      }
    }
  };

  const checkHasPermission = (role: string) => {
    if (userResponsibilities.includes(role) || !role) {
      return true;
    }
    return false;
  };

  return (
    <div className="configuration__container">
      <Tabs
        size="small"
        onChange={handleChangeTab}
        activeKey={activeTab}
      >
        {configurationTabs.map((tab, i) => (
          <TabPane
            tab={tab.title}
            key={i}
            active={location.pathname.includes(tab?.path ?? "0")}
          />
        ))}
      </Tabs>
      <Switch>
        {configurationRoutes?.map(({ ...props }, i) => (
          <Route key={i} {...props} />
        ))}
      </Switch>
    </div>
  );
};

export default AuthContainer(Configuration);
