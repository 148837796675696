import React, { useEffect, useState } from "react";
import "./treatmentSummaryCommentNotes.scss";
import { CommentNote } from "../../../../models/CommentNote/commentNote.model";
import CommentNotesService from "../../../../services/CommentNotes/commentNotes.service";
import AppLoader from "../../../../shared/components/AppLoader";
interface TreatmentSummaryCommentNotesProps {
  interactionId: number;
}

function TreatmentSummaryCommentNotes({
  interactionId,
}: TreatmentSummaryCommentNotesProps) {
  const [loading, setLoading] = useState(false);

  const [commentNotes, setCommentNotes] = useState<CommentNote[]>([]);

  useEffect(() => {
    setLoading(true);
    CommentNotesService.fetchCommentNotes(
      interactionId,
      (commentNotes: CommentNote[]) => {
        setCommentNotes(commentNotes);
      },
      () => {},
      () => {
        setLoading(false);
      }
    );
  }, [interactionId]);

  return (
    <div className="treatment-summary-comment-notes">
      {loading ? (
        <AppLoader loading={loading} />
      ) : (
        <div>
          {commentNotes?.map((commentNote) => (
            <div
              className="treatment-summary-comment-notes__item"
              key={commentNote.id}
            >
              {commentNote?.notes}
            </div>
          ))}
          {commentNotes?.length === 0 && <div>No comment notes found</div>}
        </div>
      )}
    </div>
  );
}

export default TreatmentSummaryCommentNotes;
