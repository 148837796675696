import React from "react";
import { ErrorMessage, Field } from "formik";
import { DatePicker, TimePicker } from "antd";
import moment from "moment";
import Error from "../Error";
import "./timePickerField.scss";

interface TimePickerFieldProps {
  name: string;
  title?: string;
  value?: string;
  onChange?: (time?: moment.Moment | null) => void;
  format?: string;
  setFieldValue?: Function;
  onTouched?: () => void;
  touched?: boolean;
  error?: string;
  disabled?: boolean;
}

function TimePickerField({
  name,
  title,
  value,
  onChange,
  setFieldValue,
  format,
  onTouched,
  touched = false,
  error = "",
  disabled,
}: TimePickerFieldProps) {
  return (
    <Field name={name}>
      {({ field, form: { errors }, meta }: any) => {
        const handleChange = (
          value: moment.Moment | null,
          dateString: string
        ) => {
          onTouched && onTouched();
          if (value && setFieldValue)
            setFieldValue(name, value.format("HH:mm:ss"));
        };

        return (
          <div className="time-picker-field">
            {title && <div className="time-picker-field__title">{title}</div>}
            <TimePicker
              className={`time-picker-field-${name}`}
              onChange={onChange || handleChange}
              value={value ? moment(value, "HH:mm:ss") : undefined}
              format="hh:mm a"
              name={name}
              onBlur={field?.onBlur}
              disabled={disabled}
              getPopupContainer={elem => elem.parentElement || elem}
            />
            {touched && <Error message={error} />}
          </div>
        );
      }}
    </Field>
  );
}

export default TimePickerField;
