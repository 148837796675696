import * as Yup from "yup";
import { CorporateService } from "../../../../services/Corporate/corporate.service";
import { CANADIAN_ZIP_CODE } from "../../../../shared/Regex";
import { debounce } from "throttle-debounce";

export const registerBasicDetailsFormValidation = Yup.object().shape({
  name: Yup.string()
    .required("Name is required!")
    .min(3, "Name should be at least 3 characters!")
    .max(50, "Name should not exceed 50 characters!"),
  contactPerson: Yup.string()
    .required("Contact Person is required!")
    .min(5, "Contact Person should be at least 5 characters!")
    .max(30, "Contact Person should not exceed 30 characters!"),
  registerNumber: Yup.string()
    .required("Registration number is required!")
    .max(20, "Registration number should not exceed 20 characters!"),
  tax: Yup.string().required("Tax is required!"),
  noOfPractices: Yup.number()
    .required("No of practices is required!")
    .typeError("You must specify a number!")
    .min(1, "Please enter values from 1 to 50!")
    .max(50, "Please enter values from 1 to 50!"),
  noOfStaffs: Yup.string().required("No of staffs is required!"),
  primaryEmail: Yup.string()
    .required("Primary Email is required!")
    .email("Email is not valid!"),
  secondaryEmail: Yup.string()
    .required("Secondary Email is required!")
    .email("Email is not valid!"),
  isdCode: Yup.string().required("ISD is required!"),
  mobileNumber: Yup.string()
    .required("Mobile number is required!")
    .min(10, "Mobile number should be 10 digits!")
    .max(10, "Mobile number should be 10 digits!"),
  addressLine1: Yup.string()
    .required("Address line 1 is required!")
    .max(40, "Address line 1 should not exceed 40 digits!"),
  addressLine2: Yup.string().max(
    40,
    "Address line 2 should not exceed 20 digits!"
  ),
  countryId: Yup.string().required("Country is required!"),
  provinceId: Yup.string().required("Province is required!"),
  cityId: Yup.string().required("City is required!"),
  zipcode: Yup.string()
    .matches(CANADIAN_ZIP_CODE, "Enter a Valid zipcode")
    .required("Zipcode is required!"),
  websiteUrl: Yup.string().matches(
    /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
    "Enter valid url!"
  ),
  twitterUrl: Yup.string().matches(
    /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
    "Enter valid url!"
  ),
  facebookUrl: Yup.string().matches(
    /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
    "Enter valid url!"
  ),
  linkedinUrl: Yup.string().matches(
    /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
    "Enter valid url!"
  ),
  password: Yup.string().required("Password is required!"),
  confirmPassword: Yup.string()
    .required("Password confirmation is required!")
    .oneOf([Yup.ref("password"), ""], "Passwords must match!"),
});
