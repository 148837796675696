import React, { useState } from "react";
import { Slot } from "../../../models/Slot/slot.model";
import "./scheduleSlot.scss";
import { Drawer, Dropdown, Menu } from "antd";
import moment from "moment";
import { AppointmentService } from "../../../services/Appointment/appointment.service";
import ScheduleSlotForm from "./ScheduleSlotForm";
import useResponsibilities from "../../../shared/hooks/Responsibilities/useResponsibilities";
import { ResponsibilityTypes } from "../../../enums/responsebily.enum";
import { User } from "../../../models/User/user.model";
import { isCorporateAdmin } from "../../../shared/utils/roleUtils";

interface ScheduleSlotProps {
  user?: User;
  slot: Slot;
  size?: string;
  scheduleId?: number;
  onSlotUpdate: () => void;
}

function ScheduleSlot({
  user,
  slot,
  size,
  scheduleId,
  onSlotUpdate,
}: ScheduleSlotProps) {
  const { hasAccess } = useResponsibilities();

  const [isEditVisible, setIsEditVisible] = useState(false);

  const isAdmin = isCorporateAdmin(user);

  const HAS_CORPORATE_EDIT_ACCESS = hasAccess(ResponsibilityTypes.CORPORATE_EDIT);

  const HAS_SCHEDULE_EDIT_ACCESS =
    hasAccess(ResponsibilityTypes.SCHEDULE_EDIT) &&
    (!isAdmin || HAS_CORPORATE_EDIT_ACCESS);

  const HAS_CORPORATE_DELETE_ACCESS = hasAccess(
    ResponsibilityTypes.CORPORATE_DELETE
  );

  const HAS_SCHEDULE_DELETE_ACCESS =
    hasAccess(ResponsibilityTypes.SCHEDULE_DELETE) &&
    (!isAdmin || HAS_CORPORATE_DELETE_ACCESS);

  const isDropdownOptionVisible =
    HAS_SCHEDULE_EDIT_ACCESS || HAS_SCHEDULE_DELETE_ACCESS;

  const dropdownOverlayClassName =
    "schedule-slot__actions-menu" + (isDropdownOptionVisible ? "" : " d-none");

  const deleteSlots = () => {
    AppointmentService.deleteSlot(
      scheduleId?.toString() ?? "",
      slot?.id?.toString() ?? "",
      () => {
        onSlotUpdate();
      },
      () => {},
      () => {}
    );
  };

  const handleEditVisibility = () => {
    setIsEditVisible(!isEditVisible);
  };

  return (
    <div className={`schedule-slot ${size}`}>
      <Dropdown
        arrow
        trigger={["click"]}
        placement="bottomRight"
        overlayClassName={dropdownOverlayClassName}
        overlay={
          isDropdownOptionVisible ? (
            <Menu>
              {HAS_SCHEDULE_EDIT_ACCESS && (
                <Menu.Item onClick={handleEditVisibility}>
                  <i className="icon-edit mr-2" /> Edit
                </Menu.Item>
              )}
              {HAS_SCHEDULE_DELETE_ACCESS && (
                <Menu.Item onClick={deleteSlots}>
                  <i className="icon-delete mr-2" /> Delete
                </Menu.Item>
              )}
            </Menu>
          ) : (
            <></>
          )
        }
      >
        <div className="schedule-slot__slot-item-body">
          <div>{slot?.practiceName}</div>
          <div className="schedule-slot__slot-time">
            <i>
              {moment(slot?.startTime).format("hh:mm a")} -
              {moment(slot?.endTime).format("hh:mm a")}
            </i>
          </div>
          <div className="schedule-slot__availability-types">
            {slot?.availabilityTypes?.map((availabilityType, index) => (
              <div
                key={index}
                className="text-capitalize schedule-slot__availability-type"
              >
                <i className="icon-calendar mr-2"></i>
                {availabilityType?.type}
              </div>
            ))}
          </div>
        </div>
      </Dropdown>
      <Drawer
        visible={HAS_SCHEDULE_EDIT_ACCESS && isEditVisible}
        width={"65vw"}
        title="Edit Staff Slot"
        closable
        onClose={handleEditVisibility}
      >
        <ScheduleSlotForm
          slot={slot}
          handleEditVisibility={handleEditVisibility}
          scheduleId={scheduleId ?? 0}
          onSuccess={onSlotUpdate}
        />
      </Drawer>
    </div>
  );
}

export default ScheduleSlot;
