import { Button, Col, Row } from "antd";
import { Formik, Form } from "formik";
import { PatientNotes } from "models/PatientNotes/patientNotes.model";
import React, { FC, useState } from "react";
import PatientService from "services/Patient/patient.service";
import InputField from "shared/components/InputField";
interface PatientNotesFormProps {
  patientId: string;
  formValue?: PatientNotes;
  onSuccess: (notes: PatientNotes) => void;
  onClose: () => void;
}
const PatientNotesForm: FC<PatientNotesFormProps> = (props) => {
  const { onClose, formValue, patientId, onSuccess } = props;
  const [loading, setLoading] = useState(false);
  const handleSubmit = (values: PatientNotes) => {
    setLoading(true);
    PatientService[formValue ? "updatePatientNote" : "addPatientNotes"](
      patientId,
      values,
      (notes) => onSuccess(notes),
      () => {},
      () => setLoading(false)
    );
  };
  return (
    <div>
      <Formik
        initialValues={Object.assign(new PatientNotes(), formValue)}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        <Form>
          <div className="patient-notes">
            <Row gutter={[20, 0]}>
              <Col span={24}>
                <InputField
                  title="Notes"
                  type="textarea"
                  name="notes"
                  rows={6}
                />
              </Col>
            </Row>
          </div>
          <div className="patient-notes__submit-wrapper text-right ">
            <Button onClick={onClose} type="default">
              Cancel
            </Button>
            <Button
              type="primary"
              className="ml-2"
              htmlType="submit"
              loading={loading}
            >
              {formValue ? "Update" : "Add"}
            </Button>
          </div>
        </Form>
      </Formik>
    </div>
  );
};

export default PatientNotesForm;
