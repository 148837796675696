export function convertJSONToFormData(data: any, dotNotation?: boolean) {
  const buildFormData = (formData: any, data: any, parentKey?: any) => {
    if (
      data &&
      typeof data === "object" &&
      !(data instanceof Date) &&
      !(data instanceof File)
    ) {
      Object.keys(data).forEach((key) => {
        buildFormData(
          formData,
          data[key],
          parentKey
            ? dotNotation
              ? `${parentKey}.${key}`
              : `${parentKey}[${key}]`
            : key
        );
      });
    } else {
      // const value = data == null ? "" : data;
      // formData.append(parentKey, value);
      if (data) {
        formData.append(parentKey, data);
      }
    }
  };

  const formData = new FormData();

  buildFormData(formData, data);

  return formData;
}
