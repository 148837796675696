import { Appointment } from "../../models/Appointment/appointment.model";
import moment from "moment";
import { DaySchedule } from "../../models/AppointmentDay/appointmentDay.model";

interface ScheduleWithSlot extends DaySchedule {
  appointmentScheduleSlotId?: number;
  status?: string;
}
export const appointmentMappedSchedule = (
  appointment: Appointment
): ScheduleWithSlot => ({
  appointmentDoctorProfileId: appointment.doctorProfileId
    ? Number(appointment.doctorProfileId)
    : null,

  notes: appointment?.notes,
  appointmentId: appointment?.id,

  visitType: appointment?.visitType,
  appointmentPatientProfileId: appointment?.patientProfileId
    ? Number(appointment.patientProfileId)
    : null,
  appointmentTypeColorCode: appointment?.appointmentTypeColorCode,
  appointmentStatusColorCode: appointment?.appointmentStatusColorCode,
  appointmentTypeId: appointment?.appointmentTypeId,
  appointmentTypeTitle: appointment?.appointmentTypeName,
  doctorFirstName: appointment?.doctorFirstName,
  doctorLastName: appointment?.doctorLastName,
  doctorProfilePicUrl: appointment?.doctorProfilePicUrl,
  endTime: moment
    .utc("0001-01-01T17:00:00")
    .set({
      hour: moment.utc(appointment.endTime).get("h"),
      minute: moment.utc(appointment.endTime).get("m"),
      second: 0,
    })
    .format("YYYY-MM-DDTHH:mm:ss"),
  patientFirstName: appointment?.patientFirstName,
  patientLastName: appointment?.patientLastName,
  patientProfilePicUrl: appointment?.patientProfilePicUrl,
  resourceId: appointment?.chairId,
  startTime: moment
    .utc("0001-01-01T08:00:00")
    .set({
      hour: moment.utc(appointment?.startTime).get("h"),
      minute: moment.utc(appointment?.startTime).get("m"),
      second: 0,
    })
    .format("YYYY-MM-DDTHH:mm:ss"),
  profileAvailabilities: [],
  appointmentScheduleSlotId: appointment?.appointmentScheduleSlotId,
  isDoctorRequired: Boolean(appointment?.isDoctorRequired),
  status: appointment?.status,
  interactionStatus: appointment?.interactionStatus,
  procedureCode: appointment?.procedureCode
});

export const scheduleMappedAppointment = (
  schedule: DaySchedule,
  {
    resourceId,
    resourceName,
    session = "morning",
    isDateRequired,
  }: {
    resourceId?: number;
    resourceName?: string;
    session?: string;
    isDateRequired?: boolean;
  }
): Appointment => ({
  id: schedule?.appointmentId,
  patientProfileId: schedule?.appointmentPatientProfileId as any,
  doctorProfileId: schedule?.appointmentDoctorProfileId as any,
  appointmentScheduleSlotId: schedule?.id,
  startTime: schedule?.startTime,
  endTime: schedule?.endTime,
  appointmentTypeId: schedule?.appointmentTypeId,
  appointmentTypeName: schedule?.appointmentTypeTitle,
  appointmentTypeColorCode: schedule?.appointmentTypeColorCode,
  appointmentStatusColorCode: schedule?.appointmentStatusColorCode,
  visitType: schedule?.visitType ?? "in_person",
  notes: schedule?.notes,
  chairId: resourceId as any,
  chairName: resourceName,
  session: session,
  status: schedule?.status,
  interactionStatus: schedule?.interactionStatus,
  date:
    isDateRequired && schedule?.appointmentDate
      ? moment.utc(schedule?.appointmentDate).format("YYYY-MM-DD")
      : undefined,
});
