import React, { FC, useEffect } from "react";
// @ts-ignore
import {CKEditor} from "@ckeditor/ckeditor5-react";
// @ts-ignore
import ClassicEditor from "@rootquotient/ckeditor5-with-ghs";
import { getHeaders } from "../../../interceptor/axiosInstance";
import Error from "../Error"
import "./editor.scss";

interface Writer {
  insertText: (text: string, position: object) => void;
}

type EditorChange = (writer: Writer) => void;
export interface Editor {
  model: {
    change: (callback: EditorChange) => void;
    document: {
      selection: {
        getFirstPosition: () => object;
      };
    };
  };
}
interface EditorProps {
	onChangeHandler?: (content: string) => void;
	value: string;
	error?: string;
	edit?: boolean;
	onReady?: (editor: any) => void;
}

const headers = getHeaders();

const Editor: FC<EditorProps> = ({
	onReady,
	value,
	onChangeHandler,
	error,
	edit = false
}) => {

	if(!value && edit) return null;
	return (
		<div className="App">
			<CKEditor
				edit={!!edit}
				editor={ClassicEditor}
				data={value}
				config={{
					htmlSupport: {
						allow: [
						  {
							name: "input",
							attributes: true,
						  },
						],
					  },
				}}
				onChange={(event: any, editor: any) => {
					const data = editor.getData();
					onChangeHandler?.(data);
				}}
				onFileUploadRequest
				onReady={onReady}
			/>
			<Error message={error ?? ""}/>
		</div>
	);
};

export default Editor;
