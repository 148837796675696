import React, { useEffect, useState } from "react";
import "./patientDashboard.scss";
import { Row, Col, Input, Button, Drawer } from "antd";
import { FilterFilled, PlusOutlined } from "@ant-design/icons";
import { Patient } from "../../../models/Patient/patient.model";
import PatientTable from "../PatientTable";
import PatientService from "../../../services/Patient/patient.service";
import PatientForm from "../PatientForm";
import PatientBulkUploadForm from "../PatientBulkUploadForm";
import AppLoader from "../../../shared/components/AppLoader";
import Searchbar from "../../../shared/components/Searchbar";
import { generatePath, useHistory } from "react-router";
import AppRoutes from "../../../routes/routeConstants/appRoutes";
import { ResponsibilityTypes } from "../../../enums/responsebily.enum";
import PatientFilterForm from "../PatientFilterForm";
import { FilterParams } from "../../../models/FilterParams/filterParams.model";
import useFilters from "../../../shared/hooks/useFilter/useFilters";
import { PaginationDetails } from "../../../models/Pagination/pagination.model";
import useResponsibilities from "../../../shared/hooks/Responsibilities/useResponsibilities";

interface PatientDashboardProps {}

function PatientDashboard(props: PatientDashboardProps) {
  const history = useHistory();

  const { hasAccess } = useResponsibilities();

  const [patients, setPatients] = useState<Patient[]>([]);
  const [patientOptions, setPatientOptions] = useState<Patient[]>([]);

  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState<PaginationDetails>();

  const [showPatientForm, setShowPatientForm] = useState(false);

  const [showBulkUploadForm, setShowBulkUploadForm] = useState(false);

  const HAS_PATIENT_CREATE_ACCESS = hasAccess(
    ResponsibilityTypes.PATIENT_CREATE
  );

  const HAS_PATIENT_DOC_CREATE_ACCESS = hasAccess(
    ResponsibilityTypes.PATIENT_DOC_CREATE
  );

  const handleFetchPatients = (filter: FilterParams, type?: string) => {
    setLoading(true);

    const updatedFilter = { ...filter, page: filter?.page ?? 1, pageSize: 10 };
    PatientService.fetchPatients(
      updatedFilter,
      (patients: Patient[], pagination: PaginationDetails) => {
        setPatients(patients);
        setPagination(pagination);
      },
      () => {},
      () => {
        setLoading(false);
      }
    );
  };

  const handleSearch = (value: string) => {};
  const { getParams, setParams } = useFilters();

  const handleShowPatientForm = () => setShowPatientForm(true);

  const handleClosePatientForm = () => setShowPatientForm(false);

  const [isFilterFormVisible, setIsFilterFormVisible] = useState(false);

  const handleFilterFormVisibility = () => {
    setIsFilterFormVisible(!isFilterFormVisible);
  };

  const handleShowBulkUploadForm = () =>
    setShowBulkUploadForm(!showBulkUploadForm);

  const handleFormSuccess = (patient: Patient) => {
    patients.push(patient);
    setPatients([...patients]);
    handleClosePatientForm();
  };

  const handleUploadSuccess = () => {
    handleShowBulkUploadForm();
    handleFetchPatients(getParams());
  };

  useEffect(() => {
    handleFetchPatients(getParams());
    handleFetchAllPatients();
  }, []);

  const handleFetchAllPatients = () => {
    setLoading(true);

    PatientService.fetchPatients(
      {},
      (patients: Patient[]) => {
        setPatientOptions(patients);
      },
      () => {},
      () => {
        setLoading(false);
      }
    );
  };

  const options = patientOptions?.map((patient) => {
    const content = (
      <Row className="patient-option__wrapper">
        <Col span={8}>
          <Row>
            <Col span={24}>
              {patient?.firstName}
              {patient?.lastName}
            </Col>
            <Col span={24} className="patient-email">
              {patient?.email}
            </Col>
          </Row>
        </Col>
        <Col span={8}>{patient?.mobileNumber}</Col>
        <Col span={8}>{patient?.email}</Col>
      </Row>
    );
    return {
      value: `${patient.firstName} ${patient.lastName}`,
      label: content,
      key: patient.id,
    };
  });

  return (
    <div className="patient-dashboard">
      <Row>
        <Col span={12} className="patient-search__wrapper">
          <Searchbar
            searchHandler={handleSearch}
            selectHandler={(selectedValue) => {
              history.push(
                generatePath(AppRoutes.PATIENT_DETAIL, {
                  patientId: selectedValue,
                })
              );
            }}
            options={options}
          />
        </Col>
        <Col span={12} className="text-right">
          {/*  <Button
            className="secondary-btn mr-3"
            onClick={handleFilterFormVisibility}
          >
            <FilterFilled height={20} />
            Filter
          </Button> */}

          {/* ToDo: Need to enable once BE completes the task*/}

          {/*
          HAS_PATIENT_DOC_CREATE_ACCESS && (
            <Button
              className="secondary-btn"
              onClick={handleShowBulkUploadForm}
            >
              Upload Excel
            </Button>
          )
          */}
          {HAS_PATIENT_CREATE_ACCESS && (
            <Button
              type="primary"
              className="ml-3"
              onClick={handleShowPatientForm}
            >
              <PlusOutlined />
              Add Patient
            </Button>
          )}
        </Col>
      </Row>
      {patients?.length === 0 && !loading ? (
        <div className="patient-dashboard__add-wrapper">
          <Row>
            <Col span={8}></Col>
            <Col span={13} offset={1}>
              <h4>Patient Creation</h4>
              <div>
                Vestibulum commodo sapien non elit porttitor, vitae volutpat
                nibh mollis. Nulla porta risus id neque tempor, in efficitur
                justo imperdiet.
              </div>
              <div className="mt-5">
                {HAS_PATIENT_CREATE_ACCESS && (
                  <Button
                    className="secondary-btn"
                    onClick={handleShowPatientForm}
                  >
                    <PlusOutlined />
                    Add Patient
                  </Button>
                )}
              </div>
            </Col>
          </Row>
        </div>
      ) : loading ? (
        <AppLoader loading={loading} />
      ) : (
        <div className="patient-dashboard__table-wrapper">
          <PatientTable
            patients={patients}
            onSuccess={(filter: FilterParams) => handleFetchPatients(filter)}
            pagination={pagination}
          />
        </div>
      )}
      {HAS_PATIENT_CREATE_ACCESS && (
        <Drawer
          title={
            <div>
              <h4>Add Patient</h4>
            </div>
          }
          keyboard={false}
          placement="right"
          maskClosable={false}
          onClose={handleClosePatientForm}
          visible={showPatientForm}
          destroyOnClose={true}
          width="60%"
        >
          <PatientForm
            patient={new Patient()}
            onSuccess={handleFormSuccess}
            onCancel={handleClosePatientForm}
          />
        </Drawer>
      )}
      <Drawer
        title={
          <div>
            <h4>Bulk Upload</h4>
          </div>
        }
        keyboard={false}
        placement="right"
        maskClosable={false}
        onClose={handleShowBulkUploadForm}
        visible={HAS_PATIENT_DOC_CREATE_ACCESS && showBulkUploadForm}
        destroyOnClose={true}
        width="60%"
      >
        <PatientBulkUploadForm onSuccess={handleUploadSuccess} />
      </Drawer>
      <Drawer
        title="Patients"
        width="50vw"
        visible={isFilterFormVisible}
        // onClose={handleCloseNextAppointment}
        closable
        destroyOnClose
        onClose={handleFilterFormVisibility}
      >
        <PatientFilterForm
          onClose={handleFilterFormVisibility}
          onSuccess={(filteredPatient) => setPatients(filteredPatient)}
        />
      </Drawer>
    </div>
  );
}

export default PatientDashboard;
