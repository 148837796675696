import { serializable, alias, object, list, primitive } from "serializr";

class AvailabilityType {
  @serializable(alias("type", primitive()))
  type?: string;
  @serializable(alias("id", primitive()))
  id?: string;
}
export class Slot {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("practice_id", primitive()))
  practiceId?: number;

  @serializable(alias("practice_name", primitive()))
  practiceName?: string;

  @serializable(alias("type", primitive()))
  type?: string;

  @serializable(alias("start_time", primitive()))
  startTime?: string;

  @serializable(alias("end_time", primitive()))
  endTime?: string;

  @serializable(alias("status", primitive()))
  status?: string;

  @serializable(alias("availability_types", list(object(AvailabilityType))))
  availabilityTypes: AvailabilityType[] = [];
}
