import { Button, Col, Popover, Row } from "antd";
import React, { FC, useEffect } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import { ArrowLeftOutlined } from "@ant-design/icons";
import "./treatmentTemplateDetails.scss";
import { ICONS } from "../../../../../shared/icons";
import TreatmentTemplateNotes from "./TreatmentTemplateNotes";
import TreatmentTemplateViewer from "../TreatmentTemplateViewer";
import { TreatmentTemplateService } from "../../../../../services/TreatmentTemplate/treatmentTemplate.service";
import BabylonContainer from "../../../../../store/container/BabylonContainer";
import CorporateObjectService from "../../../../../services/CorporateObject/corporateObject.service";
import { CorporateObject } from "../../../../../models/CorporateObject/corporateObject.model";
import { BabylonReducerProps } from "../../../../../store/reducers/babylonReducer";
import TreatmentPlanContainer from "../../../../../store/container/TreatmentPlanContrainer";
import { TreatmentPlanReducerProps } from "../../../../../store/reducers/treatmentPlanReducer";
import { MetaService } from "../../../../../services/Meta/meta.service";
import TreatmentTemplateStepForm from "../TreatmentTemplateStepForm";
import { InteractionActionEnum } from "../../../../../enums/interactionActionEnum.enum";
import TreatmentPlanService from "../../../../../services/TreatmentPlan/treatmentPlan.service";
import useResponsibilities from "../../../../../shared/hooks/Responsibilities/useResponsibilities";
import { ResponsibilityTypes } from "../../../../../enums/responsebily.enum";
import CorporateContainer from "../../../../../store/container/CorporateContainer";
import { CorporateReducerProps } from "../../../../../store/reducers/corporateReducer";

interface TreatmentTemplateDetailProps
  extends BabylonReducerProps,
    CorporateReducerProps,
    TreatmentPlanReducerProps {}

const TreatmentTemplateDetails: FC<TreatmentTemplateDetailProps> = (props) => {
  const {
    currentTreatmentPlan,
    setCurrentTreatmentPlan,
    currentAppointment,
    setCurrentAppointment,
    practiceId,
  } = props;

  const history = useHistory();

  const params: { id: string } = useParams();

  const { hasAccess } = useResponsibilities();

  const HAS_SET_VIEW_ACCESS = hasAccess(ResponsibilityTypes.SET_VIEW);

  const HAS_PROCEDURE_VIEW_ACCESS = hasAccess(
    ResponsibilityTypes.PROCEDURE_VIEW
  );

  const HAS_TREATMENT_PLAN_EDIT_ACCESS = hasAccess(
    ResponsibilityTypes.TREATMENT_PLAN_EDIT
  );

  const handleGoBack = () => history.goBack();

  const location = useLocation();

  const isTreatmentPlan = () => {
    const paths = location.pathname.split("/");
    if (paths[paths.length - 2] === "treatment-plans") {
      return true;
    }
    return false;
  };

  const fetchTreatmentPlan = () => {
    if (isTreatmentPlan()) {
      TreatmentPlanService.fetchTreatmentTemplate(
        params.id,
        (treatmentPlanTemplate) => {
          setCurrentTreatmentPlan(treatmentPlanTemplate);

          if (currentAppointment) return;
          const appointment =
            treatmentPlanTemplate?.treatmentPlanTemplateVisits?.[0]?.id ||
            treatmentPlanTemplate?.treatmentPlanVisits?.[0]?.id;
          setCurrentAppointment(appointment);
        },
        () => {},
        () => {}
      );
    } else {
      TreatmentTemplateService.getTreatmentPlanDetails(
        params.id,
        (treatmentPlanTemplate) => {
          setCurrentTreatmentPlan(treatmentPlanTemplate);

          if (currentAppointment) return;
          const appointment =
            treatmentPlanTemplate?.treatmentPlanTemplateVisits?.[0]?.id ||
            treatmentPlanTemplate?.treatmentPlanVisits?.[0]?.id;
          setCurrentAppointment(appointment);
        },
        () => {},
        () => {}
      );
    }
  };

  useEffect(() => {
    fetchTreatmentPlan();
    return () => {
      setCurrentTreatmentPlan();
      setCurrentAppointment();
    };
  }, []);

  return (
    <div className="treatment-template-details">
      <Row
        className="treatment-template-details__controllers"
        justify="space-between"
      >
        <Row>
          <Col>
            <Button className="secondary-btn" onClick={handleGoBack}>
              <ArrowLeftOutlined /> Back
            </Button>
          </Col>
          {(HAS_SET_VIEW_ACCESS || HAS_PROCEDURE_VIEW_ACCESS) && HAS_TREATMENT_PLAN_EDIT_ACCESS && (
            <Col>
              <Popover
                overlayClassName="treatment-plan-viewer__popover"
                placement="bottom"
                destroyTooltipOnHide
                trigger={["click"]}
                content={
                  <TreatmentTemplateStepForm
                    interactionAction={InteractionActionEnum.ALIGNER}
                    onSuccess={() => {
                      fetchTreatmentPlan();
                    }}
                  />
                }
              >
                <Button className="icon-btn">
                  <i className="icon-aligners" />
                </Button>
              </Popover>
            </Col>
          )}
          {/* <Col>
            <Popover
              overlayClassName="treatment-plan-viewer__popover"
              placement="bottom"
              destroyTooltipOnHide
              content={
                <TreatmentTemplateStepForm
                  interactionAction={InteractionActionEnum.BUTTON}
                  onSuccess={() => {
                    fetchTreatmentPlan();
                  }}
                />
              }
            >
              <Button className="icon-btn">
                <img src={ICONS.BUTTON} alt="" />
              </Button>
            </Popover>
          </Col>
          <Col>
            <Popover
              overlayClassName="treatment-plan-viewer__popover"
              placement="bottom"
              destroyTooltipOnHide
              content={
                <TreatmentTemplateStepForm
                  interactionAction={InteractionActionEnum.ELASTIC}
                  onSuccess={() => {
                    fetchTreatmentPlan();
                  }}
                />
              }
            >
              <Button className="icon-btn">
                <img src={ICONS.ELASTIC} alt="" />
              </Button>
            </Popover>
          </Col> */}
        </Row>
        {/* <Row gutter={[10, 0]}>
					<Col>
						<Button type="dashed">Cancel</Button>
					</Col>
					<Col>
						<Button type="primary">Save</Button>
					</Col>
				</Row> */}
      </Row>
      <Row gutter={[30, 20]} className="mt-5">
        <Col span={17}>
          <TreatmentTemplateViewer onSuccess={fetchTreatmentPlan} />
        </Col>
        <Col span={7}>
          <TreatmentTemplateNotes onSuccess={fetchTreatmentPlan} />
        </Col>
      </Row>
    </div>
  );
};

export default TreatmentPlanContainer(
  BabylonContainer(CorporateContainer(TreatmentTemplateDetails))
);
