
import {serializable, alias, list, primitive} from 'serializr';

export class Role { 

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('name', primitive()))
	name?: string;

	@serializable(alias('category', primitive()))
	category?: string;

	@serializable(alias('description', primitive()))
	description?: string;

	@serializable(alias('is_default', primitive()))
	isDefault?: boolean;

	@serializable(alias('permissions', list(primitive())))
	permissions: string[] = [];

}