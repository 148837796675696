import React, { useCallback, useEffect, useState } from "react";
import "./patientFormResponseWrapper.scss";
import { CustomFormResponse } from "../../../models/CustomFormResponse/customFormResponse.model";
import CustomFormResponseService from "../../../services/CustomFormResponse/customFormResponse.service";
import { Row, Col, Modal } from "antd";
import CustomFormResponseDetail from "../CustomFormResponseDetail";
import { CustomFormTypeEnum } from "../../../enums/customFormType.enum";
import { ResponsibilityTypes } from "../../../enums/responsebily.enum";
import useResponsibilities from "../../../shared/hooks/Responsibilities/useResponsibilities";
import AppLoader from "../../../shared/components/AppLoader";

interface PatientFormResponseWrapperProps {
  patientId: number;
  refreshData: boolean;
  onRefreshComplete: () => void;
}

function PatientFormResponseWrapper({
  patientId,
  refreshData,
  onRefreshComplete,
}: PatientFormResponseWrapperProps) {
  const [loading, setLoading] = useState(false);

  const { hasAccess } = useResponsibilities();

  const [customFormResponses, setCustomFormResponses] = useState<
    CustomFormResponse[]
  >([]);

  const [activeCustomFormResponse, setActiveCustomFormResponse] = useState<
    CustomFormResponse | undefined
  >();

  const [showCustomFormDetail, setShowCustomFormDetail] = useState(false);

  const HAS_CUSTOM_FORM_VIEW_ACCESS = hasAccess(
    ResponsibilityTypes.CUSTOM_FORM_VIEW
  );

  const handleClickResponse = (
    customFormResponse?: CustomFormResponse
  ) => () => {
    if (customFormResponse) {
      setActiveCustomFormResponse(customFormResponse);
      setShowCustomFormDetail(true);
    }
  };

  const handleToggleCustomFormDetail = () =>
    setShowCustomFormDetail(!showCustomFormDetail);

  const handleFetchCustomFormResponses = useCallback(() => {
    if (HAS_CUSTOM_FORM_VIEW_ACCESS) {
      setLoading(true);
      CustomFormResponseService.fetchCustomFormResponses(
        CustomFormTypeEnum.PATIENT,
        (customFormResponses: CustomFormResponse[]) => {
          setCustomFormResponses(customFormResponses);
          onRefreshComplete();
        },
        () => {},
        () => {
          setLoading(false);
        },
        patientId
      );
    }
  }, [HAS_CUSTOM_FORM_VIEW_ACCESS, onRefreshComplete, patientId]);

  useEffect(() => {
    handleFetchCustomFormResponses();
  }, [handleFetchCustomFormResponses]);

  useEffect(() => {
    if (refreshData) handleFetchCustomFormResponses();
  }, [handleFetchCustomFormResponses, refreshData]);
  return (
    <div className="patient-form-response-wrapper">
      <h4>Custom form responses</h4>
      {loading ? (
        <AppLoader loading={loading} />
      ) : customFormResponses?.length > 0 ? (
        <Row gutter={[20, 20]}>
          {customFormResponses?.map((customFormResponse, i) => (
            <Col span={8}>
              <div
                className="patient-form-response-wrapper__form-tile"
                onClick={handleClickResponse(customFormResponse)}
              >
                {customFormResponse?.customFormName}
              </div>
            </Col>
          ))}
        </Row>
      ) : (
        <div>No responses found. </div>
      )}

      <Modal
        className="primary-modal patient-form-response-wrapper__detail-modal"
        visible={showCustomFormDetail}
        closable={false}
        width="70%"
        destroyOnClose
        footer={null}
      >
        {activeCustomFormResponse && (
          <CustomFormResponseDetail
            onCancel={handleToggleCustomFormDetail}
            customFormResponse={activeCustomFormResponse}
          />
        )}
      </Modal>
    </div>
  );
}

export default PatientFormResponseWrapper;
