import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import {
  Calendar,
  DateFormatFunction,
  EventPropGetter,
  momentLocalizer,
} from "react-big-calendar";
import {
  AppointmentDayCalender,
  DayResource,
  DaySchedule,
} from "../../../models/AppointmentDay/appointmentDay.model";
import { hexAToRGBA } from "../../../shared/utils/hexToRgb";
import {
  compareUTCTime,
  formatTimestamp,
  manipulateUTCTime,
} from "../../../shared/utils/time";

import "./appointmentDayView.scss";
import { Moment } from "moment";
import { AppointmentService } from "../../../services/Appointment/appointment.service";
import AppLoader from "../../../shared/components/AppLoader";
import Slot from "./Slot";
import { Drawer } from "antd";
import NewAppointmentForm from "../AppointmentTemplate/NewAppointmentForm";
import { Appointment } from "../../../models/Appointment/appointment.model";
import { appointmentMappedSchedule } from "../../../shared/utils/mappedAppointment";
import { Resource } from "../../../models/resource/resource.model";
import ResourceService from "../../../services/Resource/resource.service";
import useToggle from "../../../shared/hooks/useToggle/useToggler";
import { CalendarSlot } from "../../../shared/Types/CalendarSlot.type";

interface AppointmentFilters {
  date: Moment;
  doctor: number;
  endTime: Moment;
  resource: number;
  startTime: Moment;
}
interface AppointmentDayViewProps {
  appointments: Appointment[];
  filters: AppointmentFilters;
  onTimeUpdate?: (time: string, type: "start" | "end") => void;
}

const localizer = momentLocalizer(moment);

const AppointmentDayView = ({
  appointments,
  filters: { date, doctor, endTime, startTime, resource },
  onTimeUpdate,
}: AppointmentDayViewProps) => {
  const [appointmentsInSlot, setAppointmentsInSlot] = useState(
    new AppointmentDayCalender()
  );

  const {
    toggle: addAppointmentOpen,
    updateToggle: handleAddAppointmentOpen,
  } = useToggle({});

  const [currentResource, setCurrentResource] = useState("");

  const [currentSchedule, setCurrentSchedule] = useState<DaySchedule[]>([]);

  const [loading, setLoading] = useState(false);

  const [resources, setResources] = useState<Resource[]>([]);

  const DEFAULT_END_TIME = "0001-01-01T17:00:00";

  const DEFAULT_START_TIME = "0001-01-01T08:00:00";

  const getSpecificResourceSchedules = (resourceId: number) =>
    appointmentsInSlot?.resources?.find(({ id }) => Number(id) === resourceId)
      ?.schedules;

  /**
   * Map Appointments Outside of Schedule to Resources in Schedule
   * @param appointments Appointments Outside of Schedule
   * @returns HashMap with Resource Id as Key & Appointments as Value
   */
  const mapAppointmentsToResource = (
    appointments: Appointment[]
  ): Map<number, Appointment[]> => {
    const map = new Map<number, Appointment[]>();

    appointments.forEach((appointment) => {
      if (!appointment?.chairId) return;

      const addedAppointments = map.get(+appointment?.chairId) || [];
      addedAppointments?.push(appointment);
      map.set(+appointment?.chairId, addedAppointments);
    });

    return map;
  };

  /**
   * Integrate Appointments outside Slot to resource.
   * @param appointments -  appointments outside of schedule.
   * @param resources - resources and schedules associated with that to integrate.
   * @param startTime - Start Time of Schedule. Required to find whether appointments outside of slot could alter these value.
   * @param endTime - End Time of Schedule. Required to find whether appointments outside of slot could alter these value.
   * @return - Manipulated resources and updated start time & end time.
   */
  const integrateAppointmentsToResource = useCallback(
    (
      appointments: Appointment[],
      resources: DayResource[],
      startTime: string,
      endTime: string
    ): {
      resources: DayResource[];
      startTime: string;
      endTime: string;
    } => {
      const integratedResource: DayResource[] = [];
      const resourceMappedAppointments = mapAppointmentsToResource(
        appointments
      );
      let minStartTime = startTime;
      let maxEndTime = endTime;

      resources.forEach((resource) => {
        const appointmentsInResource =
          resourceMappedAppointments.get(Number(resource?.id)) || [];

        const newSchedules: DaySchedule[] = [];

        const templateMappedAppointments: DaySchedule[] = [];

        appointmentsInResource.forEach((appointment) => {
          const { id, startTime, endTime } = appointment;

          const isAppointmentExistInSchedule = !!resource?.schedules?.find(
            ({ appointmentId }) => appointmentId === id
          );
          const isAppointmentStartTimeLessThanScheduleStartTime =
            compareUTCTime(startTime, minStartTime) < 0;

          const isAppointmentEndTimeMoreThanScheduleEndTime =
            compareUTCTime(endTime, maxEndTime) > 0;

          if (isAppointmentExistInSchedule) return;

          if (isAppointmentStartTimeLessThanScheduleStartTime)
            minStartTime = manipulateUTCTime(minStartTime, {
              h: moment(startTime).get("h"),
              m: moment(startTime).get("m"),
            });

          if (isAppointmentEndTimeMoreThanScheduleEndTime)
            maxEndTime = manipulateUTCTime(maxEndTime, {
              h: moment(endTime).get("h"),
              m: moment(endTime).get("m"),
            });

          templateMappedAppointments.push(
            appointmentMappedSchedule(appointment)
          );
        });

        newSchedules.push(
          ...templateMappedAppointments,
          ...(resource?.schedules || [])
        );
        integratedResource.push({ ...resource, schedules: newSchedules });
      });

      return {
        resources: integratedResource,
        endTime: maxEndTime,
        startTime: minStartTime,
      };
    },
    []
  );

  const handleAppointmentIntegration = useCallback(
    (
      appointmentsInSlot: AppointmentDayCalender,
      appointmentsOutsideSlot: Appointment[]
    ) => {
      const scheduleStartTime =
        appointmentsInSlot?.startTime || DEFAULT_START_TIME;

      const scheduleEndTime = appointmentsInSlot?.endTime || DEFAULT_END_TIME;

      const resourcesInSchedule = appointmentsInSlot?.resources?.length
        ? appointmentsInSlot?.resources
        : resources.map(({ id, name }) => ({
            id: String(id),
            name: String(name),
            schedules: [],
          }));

      const {
        resources: integratedResources,
        endTime,
        startTime,
      } = integrateAppointmentsToResource(
        appointmentsOutsideSlot,
        resourcesInSchedule,
        scheduleStartTime,
        scheduleEndTime
      );
      onTimeUpdate?.(startTime, "start");
      onTimeUpdate?.(endTime, "end");

      setAppointmentsInSlot((appointments) => ({
        ...appointments,
        startTime,
        endTime,
        resources: integratedResources,
      }));
    },
    [integrateAppointmentsToResource, resources]
  );

  const handleFetchDayView = useCallback(async () => {
    if (!resources.length) return;

    setLoading(true);
    const formattedCurrentDate = formatTimestamp(date);
    let appointmentsInSlot = new AppointmentDayCalender();
    let appointmentOutsideSlot: Appointment[] = [];

    await AppointmentService.fetchAppointmentDayView(
      formattedCurrentDate,
      (fetchedAppointments) => {
        appointmentsInSlot = fetchedAppointments;
        setAppointmentsInSlot(fetchedAppointments);
      },
      () => {
        setAppointmentsInSlot(new AppointmentDayCalender());
      },
      () => {
        setLoading(false);
      }
    );
    setLoading(true);
    await AppointmentService.fetchAppointment(
      {
        isAssociatedWithSlot: false,
        startDate: formattedCurrentDate,
        endDate: formattedCurrentDate,
        pageSize: 50,
      },
      (fetchedAppointments) => {
        appointmentOutsideSlot = fetchedAppointments;
      },
      () => {},
      () => {
        setLoading(false);
      }
    );

    handleAppointmentIntegration(appointmentsInSlot, appointmentOutsideSlot);
  }, [resources, date, handleAppointmentIntegration]);

  useEffect(() => {
    handleFetchDayView();
  }, [handleFetchDayView, appointments]);

  useEffect(() => {
    setLoading(true);
    ResourceService.fetchResourceByType(
      "chair",
      (resources) => setResources(resources),
      () => {},
      () => {
        setLoading(false);
      }
    );
  }, []);

  const getEvents = () => {
    const events: CalendarSlot[] = [];

    appointmentsInSlot?.resources?.forEach((resource) => {
      resource?.schedules?.forEach((schedule) => {
        const isSelectedDoctorHasAppointment =
          schedule?.appointmentDoctorProfileId === doctor;

        const isSelectedDoctorExist = schedule?.profileAvailabilities?.some(
          (profile) => profile.profileId === doctor
        );
        if (
          doctor &&
          !(isSelectedDoctorHasAppointment || isSelectedDoctorExist)
        )
          return;

        events.push({
          date,
          end: moment(schedule?.endTime).toDate(),
          allDay: false,
          resourceId: Number(resource?.id),
          resourceName: resource?.name || "",
          schedule: schedule,
          start: moment(schedule?.startTime).toDate(),
          templateId: Number(appointmentsInSlot?.templateId),
          templateName: appointmentsInSlot?.templateName || "",
          title: schedule?.appointmentTypeTitle,
        });
      });
    });

    return events;
  };

  const getTimeGutterFormat: DateFormatFunction = (date) => {
    const momentDate = moment(date);
    const isHour = momentDate.minute() === 0;
    const HOUR_TIME_FORMAT = "hh:mm A";
    const MINUTE_TIME_FORMAT = ":mm";

    return isHour
      ? formatTimestamp(momentDate, HOUR_TIME_FORMAT)
      : formatTimestamp(momentDate, MINUTE_TIME_FORMAT);
  };

  const handleEventContainerRightClick = (
    evt: React.MouseEvent<HTMLDivElement, MouseEvent>,
    resourceId: string
  ) => {
    if (moment(date).isBefore(moment(), "date")) return;

    let schedules = getSpecificResourceSchedules(+resourceId);

    evt?.preventDefault();
    evt?.stopPropagation();
    setCurrentResource(resourceId);
    setCurrentSchedule(schedules || []);
    handleAddAppointmentOpen();
  };

  const getEventPropGetter: EventPropGetter<any> = (event) => ({
    style: {
      backgroundColor: hexAToRGBA(
        event?.schedule?.appointmentTypeColorCode,
        0.2
      ),
      borderColor: String(event?.schedule?.appointmentTypeColorCode),
      borderRadius: 0,
    },
  });

  const handleUpdateScheduleSlot = (
    dayAppointments: AppointmentDayCalender,
    oldSlotId: number
  ) => {
    setAppointmentsInSlot((appointments) => {
      let resources = [...appointments?.resources];
      const newResources: DayResource[] = [];

      resources?.forEach((existingResource) => {
        const newSchedules =
          existingResource?.schedules?.filter(
            (schedule) => schedule?.id !== oldSlotId
          ) ?? [];
        newSchedules?.push(
          ...(dayAppointments?.resources?.find(
            (resource) => resource?.id === existingResource?.id
          )?.schedules ?? [])
        );
        newResources?.push({ ...existingResource, schedules: newSchedules });
      });

      return { ...appointments, resources: newResources };
    });
  };

  const handleDeleteScheduleSlot = (oldSlotId: number, resourceId: number) => {
    setAppointmentsInSlot((data) => {
      const resources = [...data.resources];
      const modifiedResourceIndex = resources.findIndex(
        (resource) => Number(resource.id) === resourceId
      );
      resources[modifiedResourceIndex].schedules = resources[
        modifiedResourceIndex
      ].schedules?.filter((schedule) => schedule?.id !== oldSlotId);

      return { ...data, resources };
    });
  };

  const getResources = () =>
    (resource ? [resources.find(({ id }) => id === resource)] : resources).map(
      (resource) => ({
        id: resource?.id,
        title: resource?.name,
        resource,
      })
    );

  return loading ? (
    <AppLoader loading />
  ) : (
    <div className="appointment-day-view">
      <Calendar
        components={{
          event: ({ event }) => (
            <Slot
              currentDate={date}
              onScheduleSlotUpdate={handleUpdateScheduleSlot}
              onAppointmentSlotDelete={handleDeleteScheduleSlot}
              slot={event}
              currentResource={currentResource}
              currentSchedule={currentSchedule}
              onAppointmentFetch={handleFetchDayView}
            />
          ),
          eventContainerWrapper: (props: any) => (
            <div
              onContextMenu={(e) =>
                handleEventContainerRightClick(e, props?.resource)
              }
            >
              {props.children}
            </div>
          ),
        }}
        date={appointmentsInSlot.startTime}
        defaultView="day"
        events={getEvents()}
        eventPropGetter={getEventPropGetter}
        formats={{
          eventTimeRangeFormat: () => "",
          timeGutterFormat: getTimeGutterFormat,
        }}
        localizer={localizer}
        min={startTime.toDate()}
        max={endTime.toDate()}
        onSelecting={() => false}
        resources={getResources()}
        selectable
        showAllEvents
        step={20}
        style={{ height: "75vh" }}
        toolbar={false}
        views={["day"]}
        timeslots={1}
      />
      <Drawer
        title="Add Appointment"
        visible={addAppointmentOpen}
        destroyOnClose
        onClose={() => handleAddAppointmentOpen(false)}
        width={"65vw"}
        className="appointmentTemplate"
        push={false}
      >
        <NewAppointmentForm
          type="addAppointment"
          currentResource={currentResource}
          appointmentCreateEditVisibility={handleAddAppointmentOpen}
          handleAppointmentUpdate={handleFetchDayView}
          currentDate={date}
        />
      </Drawer>
    </div>
  );
};

export default AppointmentDayView;
