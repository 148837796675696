import ApiRoutes from "../../routes/routeConstants/apiRoutes";
import axiosInstance from "../../interceptor/axiosInstance";
import { InteractionStep } from "../../models/InteractionStep/interactionStep.model";
import { deserialize, serialize } from "serializr";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import { Interaction } from "../../models/Interaction/interaction.model";
import { InteractionVisit } from "../../models/InteractionVisit/interactionVisit.model";
import { objectActionEnum } from "../../enums/objectActionType.enum";

export default class InteractionStepService {
  static fetchContractInteractions(
    interactionId: number,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.CONTRACT_INTERACTIONS.replace(
      ":interactionId",
      interactionId.toString()
    );
    axiosInstance
      .get(API_URL)
      .then((response) => {
        const interactions = deserialize(
          Interaction,
          response.data["interactions"]
        );
        onSuccess(interactions);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static fetchContractVisits(
    interactionId: number,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.CONTRACT_VISITS.replace(
      ":interactionId",
      interactionId.toString()
    );
    axiosInstance
      .get(API_URL)
      .then((response) => {
        const interactions = deserialize(
          Interaction,
          response.data["interactions"]
        );
        onSuccess(interactions);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static showInteractionSteps(
    interactionId: number,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.INTERACTION_STEPS.replace(
      ":interactionId",
      interactionId.toString()
    );
    axiosInstance
      .get(API_URL)
      .then((response) => {
        const interactionSteps = deserialize(
          InteractionStep,
          response.data["interaction_steps"]
        );
        onSuccess(interactionSteps);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static createInteractionStep(
    interactionId: number,
    interactionStep: InteractionStep,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.INTERACTION_STEPS.replace(
      ":interactionId",
      interactionId.toString()
    );
    const interactionStepJSON = {
      interaction_step: serialize(interactionStep),
    };
    axiosInstance
      .post(API_URL, interactionStepJSON)
      .then((response) => {
        const interactionStep = deserialize(
          InteractionStep,
          response.data["interaction_step"]
        );
        // Notification({
        //   message: `Step ${interactionStep?.actionType === objectActionEnum.ADD ? "added" : "removed"} to the interaction`,
        //   type: NotificationTypes.SUCCESS,
        // });
        onSuccess(interactionStep);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static deleteInteractionStep(
    interactionId: number,
    interactionStepId: number,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL =
      ApiRoutes.INTERACTION_STEPS.replace(
        ":interactionId",
        interactionId.toString()
      ) +
      "/" +
      interactionStepId;
    axiosInstance
      .delete(API_URL)
      .then((response) => {
        Notification({
          message: "Step removed from the interaction",
          type: NotificationTypes.SUCCESS,
        });
        onSuccess();
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }
}
