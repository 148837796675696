import {serializable, alias, object, list, primitive} from 'serializr';

export class NoteTemplate { 

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('note_sample', primitive()))
	noteSample?: string;

	@serializable(alias('is_default', primitive()))
	isDefault?: string;

}
