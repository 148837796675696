import ApiRoutes from "../../routes/routeConstants/apiRoutes";
import axiosInstance from "../../interceptor/axiosInstance";
import { InteractionStatus, InteractionStatuses } from "../../models/InteractionStatuse/interactionStatuse.model";
import { deserialize, serialize } from "serializr";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import { InteractionSubStatuse } from "../../models/InteractionSubStatuse/interactionSubStatuse.model";
import { generatePath } from "react-router";
import { StatusType } from "../../enums/statusType.enum";

export default class InteractionStatusService {

    static fetchInteractionStatus(
        onSuccess: (interactionStatus: InteractionStatus[]) => void,
        onError: Function,
        onFinal: () => void,
        type?: StatusType
    ) {
        axiosInstance
            .get(ApiRoutes.INTERACTION_STATUS, {
                params: type && {
                    status_type: type
                }
            })
            .then((response) => {
                const { interactionStatuses } = deserialize(InteractionStatuses, response.data);
                onSuccess(interactionStatuses);
            })
            .catch((error) => {
                onError();
            })
            .finally(() => {
                onFinal();
            });
    }

    static createInteractionSubStatus(
        interactionStatusId: number,
        interactionStatus: InteractionSubStatuse,
        onSuccess: (interactionSubStatuse: InteractionSubStatuse) => void,
        onError: Function,
        onFinal: () => void
    ) {
		const corporateId = localStorage.getItem("corporateId") ?? 1;
        const InteractionStatusJSON = { interaction_sub_status: serialize(interactionStatus) };
        axiosInstance
            .post(generatePath(ApiRoutes.INTERACTION_SUB_STATUS, {
                interactionStatusId,
                corporateId
            }), InteractionStatusJSON)
            .then((response) => {
                const interactionStatus = deserialize(InteractionSubStatuse, response.data["interaction_sub_status"]);
                onSuccess(interactionStatus);
            })
            .catch((error) => {
                onError();
            })
            .finally(() => {
                onFinal();
            });
    }

    static updateInteractionStatus(
        interactionStatusId: number,
        interactionStatus: InteractionSubStatuse,
        onSuccess: Function,
        onError: Function,
        onFinal: () => void
    ) {
        const corporateId = localStorage.getItem("corporateId") ?? 1;
        const API_URL = generatePath(ApiRoutes.INTERACTION_SUB_STATUS + '/' + interactionStatus.id, {
            interactionStatusId,
                corporateId
        });
        const InteractionStatusJSON = {interaction_sub_status: serialize(interactionStatus)};
        axiosInstance
            .put(API_URL, InteractionStatusJSON)
            .then((response) => {
                const interactionStatus = deserialize(InteractionSubStatuse, response.data["interaction_sub_status"]);
                onSuccess(interactionStatus);
            })
            .catch((error) => {
                onError();
            })
            .finally(() => {
                onFinal();
            });
    }

    static deleteInteractionStatus(
        interactionStatusId: number,
        InteractionSubStatusId: number,
        onSuccess: Function,
        onError: Function,
        onFinal: () => void
    ) {
		const corporateId = localStorage.getItem("corporateId") ?? 1;
        const API_URL = ApiRoutes.INTERACTION_SUB_STATUS + '/' + InteractionSubStatusId;
        axiosInstance
            .delete(generatePath(API_URL, {
                interactionStatusId,
                corporateId
            }))
            .then(() => {
                onSuccess();
            })
            .catch((error) => {
                onError();
            })
            .finally(() => {
                onFinal();
            });
    }

}
