import React, { useState } from "react";
import { Form, Formik, FormikValues } from "formik";
import { Button } from "antd";
import AttachmentUpload from "../../../shared/components/AttachmentUpload";
import "./patientDocumentUploadForm.scss";
import { PatientDocument } from "../../../models/PatientDocument/patientDocument.model";
import PatientService from "../../../services/Patient/patient.service";

interface PatientDocumentUploadFormProps {
  onSuccess: () => void;
  patientId: number;
}

interface IPatientDocumentProps {
  files?: File[];
}

const initialValues: IPatientDocumentProps = {
  files: [],
};

function PatientDocumentUploadForm({
  onSuccess,
  patientId,
}: PatientDocumentUploadFormProps) {
  const [formLoading, setFormLoading] = useState(false);

  const handleSubmit = (values: FormikValues) => {
    const patientDocuments = values.files.map((file: File) => {
      const patientDocument = new PatientDocument();
      patientDocument.attachment = file;
      return patientDocument;
    });
    setFormLoading(true);
    PatientService.uploadPatientDocuments(
      patientId,
      patientDocuments[0],
      () => {
        onSuccess();
      },
      () => {},
      () => {
        setFormLoading(false);
      }
    );
  };

  return (
    <div className="patient-document-upload-form">
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({
          handleSubmit,
          handleChange,
          values,
          errors,
          isValid,
          dirty,
          setFieldValue,
        }) => {
          return (
            <Form>
              <div className="patient-document-upload-form__upload-wrapper">
                <AttachmentUpload
                  accept="image/jpeg,image/png,image/jpg,application/pdf"
                  name="files"
                  multiple
                  placeholder="Upload Document"
                  newAttachments={values.files || []}
                  attachments={[]}
                  setFieldValue={setFieldValue}
                />
              </div>
              <div className="mt-4 text-right">
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={formLoading}
                  loading={formLoading}
                >
                  Upload
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default PatientDocumentUploadForm;
