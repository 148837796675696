import { Button, Drawer, Table, Switch, Modal } from "antd";
import React, { FC, useEffect, useState } from "react";
import { CheckOutlined, CloseOutlined, PlusOutlined } from "@ant-design/icons";
import Stats from "../../../../shared/components/Stats";
import "./treatmentTemplate.scss";
import {
  LinkedService,
  TreatmentTemplate as TreatmentTemplateModel,
} from "../../../../models/TreamentTemplate/treatmentTemplate.model";
import { TreatmentTemplateService } from "../../../../services/TreatmentTemplate/treatmentTemplate.service";
import { CopyOutlined, EditOutlined } from "@ant-design/icons";
import TreatmentTemplateForm from "./TreatmentTemplateForm";
import { generatePath, useHistory } from "react-router";
import AppRoutes from "../../../../routes/routeConstants/appRoutes";
import CorporateObjectService from "../../../../services/CorporateObject/corporateObject.service";
import { CorporateObject } from "../../../../models/CorporateObject/corporateObject.model";
import { BabylonReducerProps } from "../../../../store/reducers/babylonReducer";
import BabylonContainer from "../../../../store/container/BabylonContainer";
import AuthContainer from "../../../../store/container/AuthContainer";
import { AuthReducerProps } from "../../../../store/reducers/authReducer";
import { ResponsibilityTypes } from "../../../../enums/responsebily.enum";
import { StatsType } from "../../../../enums/statsType.enum";
import { useMetrics } from "../../../../shared/hooks/Metrics/useMetrics";
import AppLoader from "../../../../shared/components/AppLoader";
import { PaginationDetails } from "../../../../models/Pagination/pagination.model";
import { TablePaginationConfig } from "antd/lib/table";
import useFilters from "../../../../shared/hooks/useFilter/useFilters";
import { FilterParams } from "../../../../models/FilterParams/filterParams.model";
import useResponsibilities from "../../../../shared/hooks/Responsibilities/useResponsibilities";
import { CorporateReducerProps } from "../../../../store/reducers/corporateReducer";
import CorporateContainer from "../../../../store/container/CorporateContainer";
import { StatusEnum } from "../../../../enums/status.enum";
import { TabsEnum } from "../../../../enums/tabs.enum";

interface TreatmentTemplateProps extends CorporateReducerProps {}
const checkIsPresent = (content: string) => content ?? "--";

const ServiceCard: FC<{ content: string; color: string }> = ({
  content,
  color,
}) => (
  <div className="service-card" style={{ background: color }}>
    {content}
  </div>
);

const TreatmentTemplate: FC<TreatmentTemplateProps> = ({ practiceId }) => {
  const history = useHistory();

  const { hasAccess } = useResponsibilities();

  const [treatmentTemplates, setTreatmentTemplates] = useState<
    TreatmentTemplateModel[]
  >([]);
  const { getParams, setParams } = useFilters();

  const [editTreatment, setEditTreatment] = useState<TreatmentTemplateModel>();
  const [pagination, setPagination] = useState<PaginationDetails>();

  const [formVisibility, setFormVisibility] = useState(false);
  const [loading, setLoading] = useState(false);

  const [formType, setFormType] = useState<"Add" | "Edit" | "Clone">("Add");
  const { metrics, handleFetchMetricsWithPractice } = useMetrics(TabsEnum.TREATMENT_PLAN_TEMPLATES);

  const HAS_TREATMENT_PLAN_VIEW_ACCESS = hasAccess(
    ResponsibilityTypes.TREATMENT_PLAN_VIEW
  );

  const HAS_TREATMENT_PLAN_CREATE_ACCESS = hasAccess(
    ResponsibilityTypes.TREATMENT_PLAN_CREATE
  );

  const HAS_TREATMENT_PLAN_EDIT_ACCESS = hasAccess(
    ResponsibilityTypes.TREATMENT_PLAN_EDIT
  );

  const handleTreatmentTemplates = (filter: FilterParams) => {
    setLoading(true);

    TreatmentTemplateService.fetchTreatmentTemplate(
      { ...filter, page: filter?.page ?? 1, pageSize: 10 },
      (treatmentTemplates, pagination) => {
        setTreatmentTemplates(treatmentTemplates);
        setPagination(pagination);
      },
      () => {},
      () => {
        setLoading(false);
      }
    );
  };
  useEffect(() => {
    handleTreatmentTemplates(getParams());
  }, []);

  const setUpdateTreatmentPlan = (
    type: "Edit" | "Clone",
    treatmentTemplate: TreatmentTemplateModel
  ) => {
    setFormVisibility(true);
    setFormType(type);
    setEditTreatment({
      ...treatmentTemplate,
      treatmentPlanTemplateServiceIds: treatmentTemplate.treatmentPlanTemplateServices?.map(
        ({ id }) => id as number
      ),
    });
  };

  const columns = [
    {
      title: "Plan Name",
      dataIndex: "name",
      key: "name",
      render: checkIsPresent,
    },

    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: checkIsPresent,
    },
    {
      title: "Duration",
      dataIndex: "durationInMonths",
      key: "durationInMonths",
      render: (content: string) => (content ? `${content} Months` : "--"),
    },
    {
      title: "No of visits",
      dataIndex: "noOfVisits",
      key: "noOfVisits",
      render: checkIsPresent,
    },
    {
      title: "",
      dataIndex: "",
      key: "",
      render: (_: any, treatmentTemplate: TreatmentTemplateModel) => {
        const handleOnCheck = (checked: boolean) => {
          Modal.confirm({
            title: `Are you sure you want to ${
              checked ? "enable" : "disable"
            } this Treatment Execution Plan?`,
            onOk: () => {
              TreatmentTemplateService.updateTreatmentPlan(
                {
                  ...treatmentTemplate,
                  status: checked ? StatusEnum.ACTIVE : StatusEnum.INACTIVE,
                },
                (treatmentTemplate) => {
                  setTreatmentTemplates(
                    treatmentTemplates?.map((obj) => {
                      if (obj.id === treatmentTemplate?.id) {
                        return treatmentTemplate;
                      }

                      return obj;
                    })
                  );
                  handleFetchMetricsWithPractice();
                },
                () => {},
                () => {}
              );
            },
          });
        };
        return (
          <div
            className="treatment-template__control"
            onClick={(event) => event.stopPropagation()}
          >
            {HAS_TREATMENT_PLAN_CREATE_ACCESS && (
              <Button
                className="secondary-btn mr-4"
                icon={<CopyOutlined />}
                onClick={(event) => {
                  if (!HAS_TREATMENT_PLAN_CREATE_ACCESS) return;

                  event.stopPropagation();
                  setUpdateTreatmentPlan("Clone", treatmentTemplate);
                }}
              />
            )}
            {HAS_TREATMENT_PLAN_EDIT_ACCESS && (
              <>
                <Button
                  type="primary"
                  className="mr-4"
                  icon={<EditOutlined />}
                  onClick={(event) => {
                    if (!HAS_TREATMENT_PLAN_EDIT_ACCESS) return;

                    event.stopPropagation();
                    setUpdateTreatmentPlan("Edit", treatmentTemplate);
                  }}
                />
                <span>
                  <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    checked={treatmentTemplate.status === StatusEnum.ACTIVE}
                    onChange={handleOnCheck}
                  />
                </span>
              </>
            )}
          </div>
        );
      },
    },
  ];

  const handleCloseForm = () => {
    setEditTreatment(undefined);
    setFormVisibility(false);
  };

  const moveToDetailsPage = (treatmentTemplate: TreatmentTemplateModel) => {
    history.push(
      generatePath(AppRoutes.TREATMENT_PLAN_TEMPLATE_DETAILS, {
        id: treatmentTemplate.id,
      })
    );
  };

  const handleSuccess = (treatmentTemplate: TreatmentTemplateModel) => {
    handleCloseForm();
    moveToDetailsPage(treatmentTemplate);
  };

  const handleRedirectToDetailView = (
    treatmentTemplate: TreatmentTemplateModel
  ) => {
    return {
      onClick: () => {
        if (!HAS_TREATMENT_PLAN_VIEW_ACCESS) return;
        moveToDetailsPage(treatmentTemplate);
      },
    };
  };
  const treatmentExecutionStatsData = [
    {
      title: "Total Treatment  Plans",
      value: metrics?.totalTreatmentPlans ?? 0,
    },
    {
      title: "Active Treatment Plans",
      value: metrics?.activeTreatmentPlans ?? 0,
    },
    {
      title: "Inactive Treatment Plans",
      value: metrics?.inactiveTreatmentPlans ?? 0,
    },
  ];

  const handleTableChange = (pagination: TablePaginationConfig) => {
    const prevFilter = getParams();
    const updatedFilters = {
      ...prevFilter,
      page: pagination.current ?? 1,
    };
    setParams(updatedFilters);
    handleTreatmentTemplates(updatedFilters);
  };

  return (
    <div className="treatment-template">
      <div className="treatment-template__title">
        <p className="text-secondary">All Treatment Execution Plans</p>
        {HAS_TREATMENT_PLAN_CREATE_ACCESS && (
          <Button
            type="primary"
            onClick={() => {
              if (!HAS_TREATMENT_PLAN_CREATE_ACCESS) return;
              setFormVisibility(true);
              setFormType("Add");
            }}
          >
            {" "}
            <PlusOutlined /> Add Template
          </Button>
        )}
      </div>
      {loading ? (
        <AppLoader loading />
      ) : (
        <>
          <Stats
            statsData={treatmentExecutionStatsData}
            statsType={StatsType.TREATMENT_EXECUTION_PLANS}
          />
          <Table
            dataSource={treatmentTemplates}
            columns={columns}
            onChange={handleTableChange}
            onRow={handleRedirectToDetailView}
            pagination={{
              total: pagination?.totalCount,
              current: pagination?.currentPage ?? 1,
              showTotal: (total: number, range: [number, number]) => (
                <p>
                  Showing <b>{` ${range[0]} - ${range[1]}`}</b> of{" "}
                  <b>{`${total.toLocaleString()}`}</b>
                </p>
              ),
            }}
          />
        </>
      )}

      <Drawer
        title={`${formType} Treatment Execution plan`}
        width={"75%"}
        visible={formVisibility}
        destroyOnClose
        onClose={handleCloseForm}
      >
        <TreatmentTemplateForm
          formValues={editTreatment}
          formType={formType}
          successHandler={handleSuccess}
          closeHandler={handleCloseForm}
        />
      </Drawer>
    </div>
  );
};

export default CorporateContainer(TreatmentTemplate);
