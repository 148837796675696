import React, { FC, useEffect, useState } from "react";
import { ObjectNote } from "../../../../models/ObjectNote/objectNote.model";
import ObjectNoteService from "../../../../services/ObjectNote/objectNote.service";
import AppLoader from "../../../../shared/components/AppLoader";
import "./treatmentSummaryObjectNotes.scss";

interface TreatmentSummaryObjectNotesProps {
	interactionId: number;
}

const TreatmentSummaryObjectNotes: FC<TreatmentSummaryObjectNotesProps> = (
	props
) => {
	const { interactionId } = props;

	const [loading, setLoading] = useState(false);

	const [objectNotes, setObjectNotes] = useState<ObjectNote[]>([]);

	useEffect(() => {
		setLoading(true);
		ObjectNoteService.fetchObjectNotes(
			interactionId,
			(objectNotes: ObjectNote[]) => {
				setObjectNotes(objectNotes);
			},
			() => {},
			() => {
				setLoading(false);
			}
		);
	}, [interactionId]);

	return (
		<div className="treatment-summary-object-notes">
			{loading ? (
				<AppLoader loading={loading} />
			) : (
				<div>
					{objectNotes?.map((objectNote) => (
						<div
							className="treatment-summary-object-notes__item"
							key={objectNote?.id}
						>
							{objectNote?.notes}
						</div>
					))}
					{objectNotes?.length === 0 && (
						<div>No object notes found</div>
					)}
				</div>
			)}
		</div>
	);
};

export default TreatmentSummaryObjectNotes;
