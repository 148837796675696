import React, { useEffect, useState } from "react";
import "./userForm.scss";
import { Form, Formik, FormikValues } from "formik";
import { Button, Col, Row, Select } from "antd";
import InputField from "../InputField";
import DropdownField from "../DropdownField";
import { User } from "../../../models/User/user.model";
import { userFormValidation } from "./userFormValidation";
import DatePickerField from "../DatePickerField";
import {
  IDropdownOptions,
  MetaService,
} from "../../../services/Meta/meta.service";
import { Country } from "../../../models/Country/country.model";
import { IsdCode } from "../../../models/IsdCode/isdCode.model";
import UserService from "../../../services/User/user.service";
import AttachmentUpload from "../AttachmentUpload";
import AddressField from "../AddressField";
import NationalityField from "../NationalityField";
import moment from "moment";
import MobileNumberField from "../MobileNumberField";
import PracticeService from "../../../services/Practice/practice.service";
import { Practice } from "../../../models/Practice/practice.model";
import { getDropdownOptions } from "../../utils/optionHelper";
import { UserRole } from "../../../models/UserRole/userRole.model";
import { CorporateService } from "../../../services/Corporate/corporate.service";
import { PaginationDetails } from "../../../models/Pagination/pagination.model";
import { uniqueObjectValue } from "../../utils/uniqueObjectValue";
import { SelectProp } from "../../Types/option";
import { debounce } from "lodash";

interface UserFormProps {
  onCancel: () => void;
  onSuccess: (user: User) => void;
  isAdvanced?: boolean;
  user?: User;
}

function UserForm({ onCancel, onSuccess, isAdvanced, user }: UserFormProps) {
  const [formValues, setFormValues] = useState(new User());
  const [pagination, setPagination] = useState<PaginationDetails>(
    new PaginationDetails()
  );

  const [formLoading, setFormLoading] = useState(false);

  const [genderOptions, setGenderOptions] = useState<IDropdownOptions[]>([
    { label: "Male", value: "male" },
    { label: "Female", value: "female" },
    { label: "Prefer not to say", value: "NA" },
  ]);

  const [roleOptions, setRoleOptions] = useState<IDropdownOptions[]>([]);

  const [practiceOptions, setPracticeOptions] = useState<SelectProp[]>([]);

  const handleSubmit = (values: FormikValues) => {
    const user = Object.assign(new User(), values);
    setFormLoading(true);
    if (values.id) {
      UserService.updateUser(
        user,
        (user: User) => {
          onSuccess(user);
        },
        () => {},
        () => {
          setFormLoading(false);
        }
      );
    } else {
      UserService.createUser(
        user,
        (user: User) => {
          onSuccess(user);
        },
        () => {},
        () => {
          setFormLoading(false);
        }
      );
    }
  };

  useEffect(() => {
    if (user) setFormValues(user);
  }, [user]);

  const handleFetchPractice = debounce(() => {
    if (pagination?.nextPage)
      PracticeService.fetchPractices(
        (practices: Practice[], pagination: PaginationDetails) => {
          setPagination(pagination);
          const data = getDropdownOptions(practices, "name", "id");

          setPracticeOptions(uniqueObjectValue(practiceOptions, data));
        },
        () => {},
        () => {},
        { page: pagination?.nextPage }
      );
  }, 200);

  useEffect(() => {
    handleFetchPractice();

    UserService.fetchUserRoles(
      (userRoles: UserRole[]) => {
        setRoleOptions(getDropdownOptions(userRoles, "name", "id"));
      },
      () => {},
      () => {}
    );
  }, []);

  return (
    <div className="user-form">
      <Formik
        initialValues={formValues}
        onSubmit={handleSubmit}
        enableReinitialize
        validationSchema={userFormValidation}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          errors,
          isValid,
          dirty,
          setFieldValue,
          setFieldError,
        }) => {
          console.log(values);
          return (
            <Form>
              <Row gutter={[20, 0]}>
                <Col span={12}>
                  <InputField
                    title="First name"
                    type="text"
                    name="firstName"
                    placeholder="Enter First name"
                  />
                </Col>
                <Col span={12}>
                  <InputField
                    title="Surname"
                    type="text"
                    name="lastName"
                    placeholder="Enter Surname "
                  />
                </Col>
                <Col span={12}>
                  <DropdownField
                    title="Gender"
                    name="gender"
                    options={genderOptions}
                    value={values.gender}
                    setFieldValue={setFieldValue}
                    placeHolder="Select Gender"
                  />
                </Col>
                <Col span={12}>
                  <DatePickerField
                    title="DOB"
                    name="dob"
                    placeholder=""
                    format="MM/DD/YYYY"
                    disabledDate={(d) => {
                      const age = moment.duration(moment().diff(d));
                      return age.years() < 18;
                    }}
                    defaultValue={values.dob}
                    setFieldValue={setFieldValue}
                  />
                </Col>
                <Col span={12}>
                  <InputField
                    title="Email"
                    type="email"
                    name="email"
                    placeholder="Enter email"
                    validateSchema={CorporateService.validateEmail}
                    errorMessage="Email is already taken"
                  />
                </Col>

                <MobileNumberField
                  isdCodeKeyName="isdCode"
                  name="mobileNumber"
                  setFieldValue={setFieldValue}
                  value={values.isdCode}
                  validateSchema={UserService.validationUserPhone}
                  errorMessage={"Phone number is already taken"}
                />
                <Col span={12}>
                  <DropdownField
                    title="Role"
                    name="roleId"
                    options={roleOptions}
                    value={values.roleId}
                    setFieldValue={setFieldValue}
                    placeHolder="Select Role"
                  />
                </Col>
                {isAdvanced ? (
                  <>
                    <AddressField
                      values={values}
                      setFieldValue={setFieldValue}
                      countryKey="countryId"
                      countryValue={values.countryId}
                      cityKey="cityId"
                      cityValue={values.cityId}
                      provinceKey="provinceId"
                      provinceValue={values.provinceId}
                      zipcodeKey="zipcode"
                    />
                    <Col span={12}>
                      <NationalityField
                        setFieldValue={setFieldValue}
                        value={values.nationalityId}
                        name="nationalityId"
                      />
                    </Col>
                    <Col span={12}>
                      <InputField
                        title="Street"
                        type="text"
                        name="street"
                        placeholder="Enter Street"
                      />
                    </Col>
                    <Col span={12}>
                      <InputField
                        title="Building"
                        type="text"
                        name="building"
                        placeholder="Enter Building"
                      />
                    </Col>
                    <Col span={12}>
                      <DropdownField
                        title="Default Practice"
                        name="defaultPracticeId"
                        options={practiceOptions}
                        value={values.defaultPracticeId}
                        setFieldValue={setFieldValue}
                        placeHolder="Select Practice"
                        onPopupScroll={handleFetchPractice}
                      />
                    </Col>
                    <Col span={24}>
                      <AttachmentUpload
                        accept="image/*"
                        name="profilePicture"
                        placeholder="Upload Picture"
                        newAttachments={
                          values.profilePicture ? [values.profilePicture] : []
                        }
                        attachments={values?.profilePicUrl ? [values?.profilePicUrl] : []}
                        setFieldValue={setFieldValue}
                      />
                    </Col>
                  </>
                ) : (
                  <Col span={12}>
                    <DropdownField
                      title="Default Practice"
                      name="defaultPracticeId"
                      options={practiceOptions}
                      value={values.defaultPracticeId}
                      setFieldValue={setFieldValue}
                      placeHolder="Select Practice"
                    />
                  </Col>
                )}
              </Row>

              <div className="user-form__submit-wrapper text-right">
                <Button onClick={onCancel} type="default">
                  Cancel
                </Button>
                <Button
                  type="primary"
                  className="ml-2"
                  htmlType="submit"
                  loading={formLoading}
                  disabled={!isValid || !dirty || formLoading}
                >
                  {isAdvanced ? "Save" : "Next"}
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default UserForm;
