import { Col, Popover, Row, Modal } from "antd";
import React, { FC, useEffect, useRef, useState } from "react";
import {
  InteractionStatus,
  InteractionStatuses,
} from "../../../../../../models/InteractionStatuse/interactionStatuse.model";
import { InteractionSubStatuse } from "../../../../../../models/InteractionSubStatuse/interactionSubStatuse.model";
import { EllipsisOutlined, PlusOutlined } from "@ant-design/icons";
import "./interactionSubStatus.scss";
import SubStatusForm from "./SubStatusForm";
//@ts-ignore
import { SortableItem, swapArrayPositions } from "react-sort-list";
import InteractionStatusService from "../../../../../../services/InteractionStatus/interactionStatus.service";
import useResponsibilities from "../../../../../../shared/hooks/Responsibilities/useResponsibilities";
import { ResponsibilityTypes } from "../../../../../../enums/responsebily.enum";
const { confirm } = Modal;

interface InteractionSubStatusProps extends InteractionStatus {}

const colors = [
  "#EF4242",
  "#7ACC3B",
  "#00A99D",
  "#93989D",
  "#D0C421",
  "#4F4F4F",
  "#C52F2F",
];

interface SubStatusProps extends InteractionSubStatuse {
  index: number;
  onDeleteHandler: (id?: number) => void;
  onEditHandler: (interactionSubStatus: InteractionSubStatuse) => void;
  interactionStatusId?: number;
}

const getColor = () => colors[Math.floor(Math.random() * colors.length)];

const SubStatus: FC<SubStatusProps> = ({
  id,
  name,
  colorCode,
  order,
  isDefault,
  index,
  onDeleteHandler,
  onEditHandler,
  interactionStatusId,
}) => {
  const [visible, setVisible] = useState(false);

  const { hasAccess } = useResponsibilities();

  const HAS_STATUS_APPOINTMENT_TYPE_DELETE_ACCESS = hasAccess(
    ResponsibilityTypes.STATUS_APPOINTMENT_TYPE_DELETE
  );

  const HAS_STATUS_APPOINTMENT_TYPE_EDIT_ACCESS = hasAccess(
    ResponsibilityTypes.STATUS_APPOINTMENT_TYPE_EDIT
  );

  const handleDeleteSubStatus = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    if(!HAS_STATUS_APPOINTMENT_TYPE_DELETE_ACCESS)  return;
    
    event.stopPropagation();
    confirm({
      content: `Are you sure you  want to delete ${name}`,
      onOk: () => {
        onDeleteHandler(id);
      },
      onCancel: () => {},
      okText: "Yes",
      cancelText: "No",
    });
  };

  const handleClose = () => {
    setVisible(false);
  };

  const handleEdit = (status: InteractionSubStatuse) => {
    onEditHandler(status);
    setVisible(false);
  }

  return (
    <Row className="sub-status__wrapper">
      <Col span={1} />
      <Col span={2} className="sub-status__color">
        <div
          className="gen-color"
          style={{
            backgroundColor: colorCode ?? getColor(),
          }}
        />
      </Col>
      <Col span={15} className="sub-status__name">
        {name}
      </Col>
      <Col span={2} className="delete__wrapper">
        <Popover 
          placement="rightTop"
          title={"Appointment/interaction status"}
          content={
            <SubStatusForm
              onSubmitHandler={handleEdit}
              closeHandler={handleClose}
              length={index}
              interactionStatusId={interactionStatusId}
              formValue={{
                id,
                isDefault,
                name,
                order,
                colorCode
              }}
            />
          }
          trigger="click"
          visible={visible}
          destroyTooltipOnHide
        >
          {!isDefault && HAS_STATUS_APPOINTMENT_TYPE_EDIT_ACCESS
            ? ((
                <i className="icon-edit" onClick={() => setVisible(true)} />
              ) as any)
            : null}
        </Popover>
      </Col>
      <Col span={2} className="delete__wrapper">
        {!isDefault && HAS_STATUS_APPOINTMENT_TYPE_DELETE_ACCESS && (
          <i className="icon-delete" onClick={handleDeleteSubStatus} />
        )}
      </Col>
      <Col span={2}>
        <EllipsisOutlined className="ellipsis-icon" />
      </Col>
    </Row>
  );
};

const InteractionSubStatus: FC<InteractionSubStatusProps> = ({
  id,
  name,
  interactionSubStatuses,
}) => {
  const { hasAccess } = useResponsibilities();

  const [visible, setVisible] = useState(false);

  const [subStatuses, setSubStatuses] = useState<InteractionSubStatuse[]>([]);

  const HAS_STATUS_APPOINTMENT_TYPE_CREATE_ACCESS = hasAccess(
    ResponsibilityTypes.STATUS_APPOINTMENT_TYPE_CREATE
  );

  const HAS_STATUS_APPOINTMENT_TYPE_EDIT_ACCESS = hasAccess(
    ResponsibilityTypes.STATUS_APPOINTMENT_TYPE_CREATE
  );

  useEffect(() => {
    setSubStatuses(interactionSubStatuses);
  }, [interactionSubStatuses]);
  const handleClose = () => {
    setVisible(false);
  };

  const handleOnSubmit = (interactionSubStatus: InteractionSubStatuse) => {
    setSubStatuses((existingSubStatuses) => [
      ...existingSubStatuses,
      interactionSubStatus,
    ]);
    handleClose();
  };

  const swap = (dragIndex: number, dropIndex: number) => {
    const substatus = Object.assign(new InteractionSubStatuse(), {
      ...subStatuses[dragIndex],
      order: dropIndex,
    });
    InteractionStatusService.updateInteractionStatus(
      id as number,
      substatus,
      () => {},
      () => {},
      () => {}
    );
    let swappedSubStatus = swapArrayPositions(
      subStatuses,
      dragIndex,
      dropIndex
    );
    setSubStatuses([...swappedSubStatus]);
  };

  const handleEditSubStatus = (
    interactionSubStatus: InteractionSubStatuse,
    index: number
  ) => {
    if(!HAS_STATUS_APPOINTMENT_TYPE_EDIT_ACCESS)  return;
    
    setSubStatuses((subStatus) =>
      subStatus?.map((status) =>
        status?.id === interactionSubStatus?.id ? interactionSubStatus : status
      )
    );

    setVisible(false);
  };

  return (
    <div className="interaction-sub-status__container mt-4">
      <h5 className="title">{name}</h5>
      <div className="interaction-sub-status__wrapper">
        {subStatuses?.map((interactionSubStatus, index) => (
          <SortableItem
            items={subStatuses}
            id={interactionSubStatus.id}
            key={interactionSubStatus.id}
            swap={swap}
          >
            <SubStatus
              key={interactionSubStatus.order}
              index={index}
              interactionStatusId={id}
              onEditHandler={(interactionSubStatus) =>
                handleEditSubStatus(interactionSubStatus, index)
              }
              {...interactionSubStatus}
              onDeleteHandler={(subStatusId) => {
                if (subStatusId) {
                  InteractionStatusService.deleteInteractionStatus(
                    id as number,
                    subStatusId,
                    () => {
                      setSubStatuses((subStatuses) =>
                        subStatuses?.filter(
                          (status) => status?.id !== subStatusId
                        )
                      );
                    },
                    () => {},
                    () => {}
                  );
                }
              }}
            />
          </SortableItem>
        ))}
        {HAS_STATUS_APPOINTMENT_TYPE_CREATE_ACCESS && (<Popover
          placement="rightTop"
          title={"Appointment/interaction status"}
          content={
            <SubStatusForm
              onSubmitHandler={handleOnSubmit}
              closeHandler={handleClose}
              length={subStatuses?.length}
              interactionStatusId={id}
            />
          }
          trigger="click"
          visible={visible}
          destroyTooltipOnHide
        >
          <span
            className="add-sub-status__wrapper"
            onClick={() => setVisible(true)}
          >
            <PlusOutlined /> Add Sub Status
          </span>
        </Popover>)}
      </div>
    </div>
  );
};

export default InteractionSubStatus;
