import { Col, Row } from "antd";
import React, { FC } from "react";
import StripeImage from "../../../assets/images/stripe.png";
import VisaImage from "../../../assets/images/visa.png";
import { Card } from "../../../models/Card/card.model";
import "./stripeCard.scss";

interface StripeCardProps {
  id?: string;
  card?: Card;
  active?: boolean;
  onSelect: (id: string) => void;
}

const StripeCard: FC<StripeCardProps> = (props) => {
  const { id, card, active = false, onSelect } = props;

  const handleSelect = () => {
      if(id) {
          onSelect(id);
      }
  }

  return (
    <Row className={`stripe-card ${active && "stripe-card-selected"}`} onClick={handleSelect}>
      <Col span={24} className="name">
        Stripe
      </Col>
      <Col span={24} className="mb-3">
        <p className="title">Card Number</p>
        <p className="value">XXX XXX XXXX {card?.last4}</p>
      </Col>
      <Col>
        <p className="title">Exp Date</p>
        <p className="value">
          {('0' + card?.expMonth).slice(-2)}/{card?.expYear?.toString().substr(-2)}
        </p>
      </Col>
      <Col span={24} className="mt-2">
      <Row justify="space-between" >
        <Col className="stripe-img">
          <img src={StripeImage} alt="stripe.png" />
        </Col>
        <Col>
          <img src={VisaImage} className="visa" alt="stripe.png" />
        </Col>
      </Row>
      </Col>
    </Row>
  );
};

export default StripeCard;
