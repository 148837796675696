import { XRayCategory } from "models/XRayCategory/xRayCategory.model";
import { deserialize, serialize } from "serializr";
import axiosInstance from "../../interceptor/axiosInstance";
import { FilterParams } from "../../models/FilterParams/filterParams.model";
import { PaginationDetails } from "../../models/Pagination/pagination.model";
import {
  StatementTemplate,
  StatementTemplates,
} from "../../models/Statement/statement.model";
import {
  StatementTemplateCategories,
  StatementTemplateCategory,
} from "../../models/StatementCategory/statementCategory.model";
import {
  XRayTemplate,
  XRayTemplates,
} from "../../models/XRayTemplate/xRayTemplate.model";
import ApiRoutes from "../../routes/routeConstants/apiRoutes";

export class NoteTemplateService {
  static getStatementCategories(
    onSuccess: (statementTemplates: StatementTemplateCategory[]) => void,
    onError: () => void,
    onFinal: () => void
  ) {
    return axiosInstance
      .get(ApiRoutes.STATEMENT_TEMPLATE_CATEGORIES)
      .then((response) => {
        const { statementTemplateCategories } = deserialize(
          StatementTemplateCategories,
          response?.data
        );
        onSuccess(statementTemplateCategories);
      })
      .catch((error) => {
        onError();
      })
      .finally(onFinal);
  }

  // Statements
  static fetchStatements(
    onSuccess: (
      statementTemplates: StatementTemplate[],
      pagination: PaginationDetails
    ) => void,
    onError: (error: Error) => void,
    onFinal: () => void,
    statementTemplateCategoryId?: number,
    filter?: FilterParams
  ) {
    const para = serialize(FilterParams, filter ?? new FilterParams());
    return axiosInstance
      .get(ApiRoutes.NOTE_TEMPLATE_STATEMENTS, {
        params: {
          ...para,
          statement_template_category_id: statementTemplateCategoryId,
        },
      })
      .then((response) => {
        const { statementTemplates } = deserialize(
          StatementTemplates,
          response?.data
        );
        const pagination = deserialize(
          PaginationDetails,
          response.data["meta"]
        );
        onSuccess(statementTemplates, pagination);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(onFinal);
  }

  static createStatement(
    statementTemplate: StatementTemplate,
    onSuccess: (statementTemplates: StatementTemplate) => void,
    onError: (error: Error) => void,
    onFinal: () => void
  ) {
    const payload = {
      statement_template: serialize(statementTemplate),
    };
    return axiosInstance
      .post(ApiRoutes.NOTE_TEMPLATE_STATEMENTS, payload)
      .then((response) => {
        const statementTemplate = deserialize(
          StatementTemplate,
          response?.data["statement_template"]
        );
        onSuccess(statementTemplate);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(onFinal);
  }

  static updateStatement(
    statementTemplate: StatementTemplate,
    onSuccess: (statementTemplates: StatementTemplate) => void,
    onError: (error: Error) => void,
    onFinal: () => void
  ) {
    const payload = {
      statement_template: serialize(statementTemplate),
    };
    return axiosInstance
      .put(
        `${ApiRoutes.NOTE_TEMPLATE_STATEMENTS}/${statementTemplate?.id}`,
        payload
      )
      .then((response) => {
        const statementTemplate = deserialize(
          StatementTemplate,
          response?.data["statement_template"]
        );
        onSuccess(statementTemplate);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(onFinal);
  }

  static fetchXRayCategory(
    onSuccess: (statementTemplates: StatementTemplateCategory[]) => void,
    onError: () => void,
    onFinal: () => void
  ) {
    return axiosInstance
      .get(ApiRoutes.X_RAY_CATEGORIES)
      .then((response) => {
        const categories = deserialize(
          XRayCategory,
          response?.data["x_ray_template_categories"] as XRayCategory[]
        );
        onSuccess(categories);
      })
      .catch((error) => {
        onError();
      })
      .finally(onFinal);
  }

  static async createXRayCategory(
    category: XRayCategory,
    onSuccess: (category: XRayCategory) => void,
    onError?: () => void,
    onFinal?: () => void
  ) {
    try {
      const { data } = await axiosInstance.post(ApiRoutes.X_RAY_CATEGORIES, {
        x_ray_template_category: serialize(XRayCategory, category),
      });
      const newCategory = deserialize(
        XRayCategory,
        data?.["x_ray_template_category"]
      );
      onSuccess(newCategory);
    } catch (ex) {
      onError?.();
    } finally {
      onFinal?.();
    }
  }

  static async updateXRayCategory(
    { id, ...category }: XRayCategory,
    onSuccess: (category: XRayCategory) => void,
    onError?: () => void,
    onFinal?: () => void
  ) {
    try {
      const ENDPOINT = ApiRoutes.X_RAY_CATEGORY.replaceAll(
        ":categoryId",
        id?.toString()!
      );

      const { data } = await axiosInstance.put(ENDPOINT, {
        x_ray_template_category: serialize(XRayCategory, category),
      });

      const updatedCategory = deserialize(
        XRayCategory,
        data?.["x_ray_template_category"]
      );
      onSuccess(updatedCategory);
    } catch (ex) {
      onError?.();
    } finally {
      onFinal?.();
    }
  }

  // X-Ray
  static fetchXRay(
    onSuccess: (
      statementTemplates: XRayTemplate[],
      pagination: PaginationDetails
    ) => void,
    onError: (error: Error) => void,
    onFinal: () => void,
    filter?: FilterParams
  ) {
    const para = serialize(FilterParams, filter ?? new FilterParams());
    return axiosInstance
      .get(ApiRoutes.NOTE_TEMPLATE_X_RAY, {
        params: {
          ...para,
        },
      })
      .then((response) => {
        const { XRayTemplate } = deserialize(XRayTemplates, response?.data);
        const pagination = deserialize(
          PaginationDetails,
          response.data["meta"]
        );
        onSuccess(XRayTemplate, pagination);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(onFinal);
  }

  static createXRayTemplate(
    xRayTemplate: XRayTemplate,
    onSuccess: (xRayTemplates: XRayTemplate) => void,
    onError: (error: Error) => void,
    onFinal: () => void
  ) {
    const payload = {
      x_ray_template: serialize(xRayTemplate),
    };
    return axiosInstance
      .post(ApiRoutes.NOTE_TEMPLATE_X_RAY, payload)
      .then((response) => {
        const xRayTemplate = deserialize(
          XRayTemplate,
          response?.data["x_ray_template"]
        );
        onSuccess(xRayTemplate);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(onFinal);
  }

  static updateXRayTemplate(
    xRayTemplate: XRayTemplate,
    onSuccess: (xRayTemplates: XRayTemplate) => void,
    onError: (error: Error) => void,
    onFinal: () => void
  ) {
    const payload = {
      x_ray_template: serialize(xRayTemplate),
    };
    return axiosInstance
      .put(`${ApiRoutes.NOTE_TEMPLATE_X_RAY}/${xRayTemplate?.id}`, payload)
      .then((response) => {
        const xRayTemplate = deserialize(
          XRayTemplate,
          response?.data["x_ray_template"]
        );
        onSuccess(xRayTemplate);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(onFinal);
  }
}
