import React, { useEffect, useState } from "react";
import "./serviceList.scss";
import ServiceService from "../../../services/Service/service.service";
import { Service, Servicemetrics } from "../../../models/Service/service.model";
import { Button, Drawer, Modal, Table } from "antd";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import AppLoader from "../../../shared/components/AppLoader";
import ServiceForm from "../ServiceForm";
import { AuthReducerProps } from "../../../store/reducers/authReducer";
import AuthContainer from "../../../store/container/AuthContainer";
import { ResponsibilityTypes } from "../../../enums/responsebily.enum";
import { TableColumn } from "../../../shared/Types/tableColumn";
import ServiceDetail from "./ServiceDetail";
import { useLocation } from "react-router-dom";
import Stats from "../../../shared/components/Stats";
import { StatsType } from "../../../enums/statsType.enum";
import { TablePaginationConfig } from "antd/lib/table";
import useFilters from "../../../shared/hooks/useFilter/useFilters";
import { removeUnderscore } from "../../../shared/utils/removeUnderscore";
import { PaginationDetails } from "../../../models/Pagination/pagination.model";
import { FilterParams } from "../../../models/FilterParams/filterParams.model";
import useResponsibilities from "../../../shared/hooks/Responsibilities/useResponsibilities";

interface LocationProps {
  serviceId: string;
}

interface ServiceListProps extends AuthReducerProps {}

function ServiceList({ userResponsibilities }: ServiceListProps) {
  const location = useLocation<LocationProps>();

  const [loading, setLoading] = useState(false);

  const [services, setServices] = useState<Service[]>([]);
  const [servicesMetrics, setServicesMetrics] = useState<Servicemetrics>();

  const [activeService, setActiveService] = useState<Service>(new Service());

  const [showServiceForm, setShowServiceForm] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const [viewService, setViewService] = useState(0);
  const [pagination, setPagination] = useState<PaginationDetails>();

  const { hasAccess } = useResponsibilities();

  const { getParams, setParams } = useFilters();

  const HAS_SERVICE_CREATE_ACCESS = hasAccess(
    ResponsibilityTypes.SERVICE_CREATE
  );

  const HAS_SERVICE_VIEW_ACCESS = hasAccess(ResponsibilityTypes.SERVICE_VIEW);

  const HAS_SERVICE_EDIT_ACCESS = hasAccess(ResponsibilityTypes.SERVICE_EDIT);

  const HAS_SERVICE_DELETE_ACCESS = hasAccess(
    ResponsibilityTypes.SERVICE_DELETE
  );

  const [columns, setColumns] = useState<TableColumn[]>([
    {
      title: "Service Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Sub Category",
      dataIndex: "subCategory",
      key: "subCategory",
    },
    {
      title: "Treatment Execution Plan",
      dataIndex: "defaultTreatmentPlanTemplateName",
      key: "defaultTreatmentPlanTemplateName",
      render: (val: string) => val ?? "--",
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status: string, record: any, index: number) => {
        return (
          <span className={"service-list__service-status " + status}>
            {removeUnderscore(status ?? "")}
          </span>
        );
      },
    },
    {
      title: "",
      dataIndex: "id",
      key: "id",
      render: (text: string, record: any, index: number) => {
        return (
          <div>
            <Button
              type="primary"
              className={`mr-4 ${!HAS_SERVICE_EDIT_ACCESS && "display-none"}`}
              icon={<EditOutlined />}
              onClick={(e) => {
                if (!HAS_SERVICE_EDIT_ACCESS) return;

                e?.stopPropagation();
                handleEditService(record)();
              }}
            />
            <Button
              onClick={(e) => {
                if (!HAS_SERVICE_DELETE_ACCESS) return;

                e?.stopPropagation();
                handleDeleteService(record.id);
              }}
              className={`delete-btn ${
                !HAS_SERVICE_DELETE_ACCESS && "display-none"
              }`}
              icon={<DeleteOutlined />}
            />
          </div>
        );
      },
    },
  ]);

  const handleFetchService = (params: FilterParams) => {
    setLoading(true);
    ServiceService.fetchServices(
      (services: Service[], metrics, pagination) => {
        setServices(services);
        setServicesMetrics(metrics);
        setPagination(pagination);
      },
      () => {},
      () => {
        setLoading(false);
      },
      params
    );
  };

  useEffect(() => {
    handleFetchService(getParams());

    if (location.state?.serviceId) {
      setViewService(parseInt(location.state?.serviceId));
    }
  }, []);

  const handleToggleServiceForm = () => {
    if (showServiceForm) {
      setActiveService(new Service());
    }
    setShowServiceForm(!showServiceForm);
  };

  const handleEditService = (service: Service) => () => {
    setActiveService(service);
    handleToggleServiceForm();
  };

  const handleDeleteService = (serviceId: number) => {
    Modal.confirm({
      title: "Confirm",
      icon: null,
      content: "Are you sure want to delete the service?",
      onOk: () => {
        ServiceService.deleteService(
          serviceId,
          () => {
            setLoading(true);
            ServiceService.fetchServices(
              (services: Service[]) => {
                setServices(services);
              },
              () => {},
              () => {
                setLoading(false);
              }
            );
          },
          () => {},
          () => {}
        );
      },
    });
  };

  const handleServiceFormSuccess = (service: Service) => {
    const serviceIndex = services.findIndex(
      (existingService) => existingService.id === service.id
    );
    if (serviceIndex >= 0) {
      services[serviceIndex] = service;
    } else {
      services.unshift(service);
    }
    setServices([...services]);
    handleToggleServiceForm();
    handleFetchService(getParams());
  };

  const handleCloseViewService = () => setViewService(0);

  const handleClickRow = (service: Service) => {
    return {
      onClick: () => {
        if (!HAS_SERVICE_VIEW_ACCESS) return;
        setViewService(service?.id ?? 0);
      },
    };
  };

  const serviceStatsData = [
    {
      title: "Number of Services",
      value: servicesMetrics?.totalNoOfServices ?? 0,
    },
    {
      title: "Average Service Value",
      value: servicesMetrics?.averageServiceValue ?? 0,
    },
  ];
  const handleTableChange = (pagination: TablePaginationConfig) => {
    const prevFilter = getParams();
    const updatedFilters = {
      ...prevFilter,
      page: pagination.current ?? 1,
    };
    setParams(updatedFilters);
    handleFetchService(updatedFilters);
  };

  return (
    <div className="service-list">
      <div>
        <div className="service-list__add-wrapper">
          {HAS_SERVICE_CREATE_ACCESS && (
            <Button
              type="primary"
              className="ml-3"
              onClick={handleToggleServiceForm}
            >
              <PlusOutlined />
              Add Service
            </Button>
          )}
        </div>

        {loading ? (
          <AppLoader loading={loading} />
        ) : (
          <>
            {" "}
            <Stats
              statsData={serviceStatsData}
              statsType={StatsType.SERVICE}
            />{" "}
            <Table
              dataSource={services}
              columns={columns}
              onRow={handleClickRow}
              onChange={handleTableChange}
              pagination={{
                total: pagination?.totalCount,
                current: pagination?.currentPage ?? 1,
                showTotal: (total: number, range: [number, number]) => (
                  <p>
                    Showing <b>{` ${range[0]} - ${range[1]}`}</b> of{" "}
                    <b>{`${total.toLocaleString()}`}</b>
                  </p>
                ),
              }}
            />
          </>
        )}
        <Drawer
          title={<h4>{activeService?.id ? "Edit Service" : "Add Service"}</h4>}
          placement="right"
          keyboard={false}
          maskClosable={false}
          onClose={handleToggleServiceForm}
          visible={showServiceForm}
          destroyOnClose={true}
          width="35%"
        >
          <ServiceForm
            service={activeService}
            onCancel={handleToggleServiceForm}
            onSuccess={handleServiceFormSuccess}
          />
        </Drawer>
        <Drawer
          title="View Service"
          visible={viewService !== 0}
          onClose={handleCloseViewService}
          destroyOnClose
          width={"60%"}
        >
          <ServiceDetail serviceId={viewService} />
        </Drawer>
      </div>
    </div>
  );
}

export default AuthContainer(ServiceList);
