import { Button, Col, Row, Switch } from "antd";
import React, { FC } from "react";
import { Resource } from "../../../../../models/resource/resource.model";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import "./resourceDetails.scss";
import ResourceService from "../../../../../services/Resource/resource.service";
import { StatusEnum } from "../../../../../enums/status.enum";
import useResponsibilities from "../../../../../shared/hooks/Responsibilities/useResponsibilities";
import { ResponsibilityTypes } from "../../../../../enums/responsebily.enum";
const { confirm } = Modal;
interface ResourceDetailsProps {
	resource: Resource;
	editResourceHandler: () => void;
	onResourceChange: (resource: Resource) => void;
}

const ResourceDetails: FC<ResourceDetailsProps> = ({
	resource,
	editResourceHandler,
	onResourceChange,
}) => {
	const { hasAccess } = useResponsibilities();

	const HAS_RESOURCE_EDIT_ACCESS = hasAccess(ResponsibilityTypes.RESOURCE_EDIT);
  
	return (
		<div className="resource-details__container">
			{HAS_RESOURCE_EDIT_ACCESS && <Row>
				<Col span={16} />
				<Col span={4} className="resource-details__status">
					<label htmlFor="">Enable/Disable</label>{" "}
					<Switch
						checkedChildren={<CheckOutlined />}
						unCheckedChildren={<CloseOutlined />}
						checked={resource?.status === "active"}
						onChange={(checked) => {
							if(!HAS_RESOURCE_EDIT_ACCESS)	return;
							confirm({
								title: `Are you sure you want to ${
									checked ? "enable" : "disable"
								} this resource?`,
								onOk: () => {
									const serilizedResource = Object.assign(
										new Resource(),
										{
											...resource,
											status: checked
												? StatusEnum.ACTIVE
												: StatusEnum.INACTIVE,
										}
									);
									ResourceService.updateResource(
										serilizedResource,
										(resource) => {
											onResourceChange(resource);
										},
										() => {},
										() => {}
									);
								},
							});
						}}
					/>
				</Col>
				<Col span={4} className="resource-details__edit">
					<Button
						type="primary"
						onClick={() => {
							if(!HAS_RESOURCE_EDIT_ACCESS)	return;
							
							editResourceHandler();}}
					>
						<i className="icon-edit" />
						Edit resource
					</Button>
				</Col>
			</Row>}
			<div className="resource-details__wrapper">
				<p className="resource-details__topic">Practice Name</p>
				<p className="resource-details__value">
					{resource.practiceName}
				</p>
			</div>
			<div className="resource-details__wrapper">
				<p className="resource-details__topic">Resource Name</p>
				<p className="resource-details__value">{resource.name}</p>
			</div>
			<div className="resource-details__wrapper">
				<p className="resource-details__topic">Type</p>
				<p className="resource-details__value">
					{resource.resourceTypeName}
				</p>
			</div>
			<div className="resource-details__wrapper">
				<p className="resource-details__topic">Issue Description</p>
				<p className="resource-details__value">
					{resource.description}
				</p>
			</div>
		</div>
	);
};

export default ResourceDetails;
