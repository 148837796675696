import { Button, Col, Radio, Row } from "antd";
import { Formik, Form } from "formik";
import React, { FC, useEffect, useState } from "react";
import { Interaction } from "../../../../models/Interaction/interaction.model";
import { WorkItem } from "../../../../models/WorkItem/workItem.model";
import DropdownField from "../../../../shared/components/DropdownField";
import InputField from "../../../../shared/components/InputField";
import useAppointmentTypes from "../../../../shared/hooks/useAppointmentTypes";
import { useDoctors } from "../../../../shared/hooks/User/useDoctors";
import moment from "moment";
import "./startInteractionForm.scss";
import { DropdownModeEnum } from "../../../../enums/dropdownMode.enum";
import { IDropdownOptions } from "../../../../services/Meta/meta.service";
import { Contract } from "../../../../models/Contract/contract.model";
import { ContractService as ContractServiceModel } from "../../../../models/ContractService/contractService.model";
import CustomFormService from "../../../../services/CustomForm/customForm.service";
import { CustomForm } from "../../../../models/CustomForm/customForm.model";
import { VisitOptions } from "../../../../shared/options/Visits";
import { InteractionService } from "../../../../services/Interaction/Interaction.service";
import ContractService from "../../../../services/Contract/Contract.service";
import { startInteractionFormValidation } from "./startInteractionForm";
import { useHistory, generatePath } from "react-router";
import AppRoutes from "../../../../routes/routeConstants/appRoutes";
import { CustomFormTypeEnum } from "../../../../enums/customFormType.enum";
import CustomFormRequestService from "../../../../services/CustomFormRequest/customFormRequest.service";
import { AuthReducerProps } from "../../../../store/reducers/authReducer";
import AuthContainer from "../../../../store/container/AuthContainer";
import { ResponsibilityTypes } from "../../../../enums/responsebily.enum";
import { USER_CATEGORY } from "../../../../enums/userCategory.enum";
import { UserRoles } from "../../../../enums/userRoles.enum";
import useResponsibilities from "../../../../shared/hooks/Responsibilities/useResponsibilities";
import { patientCategories } from "shared/utils/patientCategories";
import { FilterParams } from "models/FilterParams/filterParams.model";

interface StartInteractionFormProps extends AuthReducerProps {
  workItem: WorkItem;
  closeHandler: () => void;
}

interface ContractProps extends IDropdownOptions, Contract {}

const StartInteractionForm: FC<StartInteractionFormProps> = (props) => {
  const { user, workItem, closeHandler } = props;

  const { hasAccess } = useResponsibilities();

  const history = useHistory();

  const [loader, setLoader] = useState(false);

  const [valid, setValid] = useState(false);

  const [formValues, setFormValues] = useState<Interaction>(new Interaction());

  const [contracts, setContracts] = useState<ContractProps[]>([]);

  const [
    contractService,
    setContractService,
  ] = useState<ContractServiceModel>();

  const [customForms, setCustomForms] = useState<number[]>([]);

  const [customFormOptions, setCustomFormOptions] = useState<
    IDropdownOptions[]
  >([]);

  const {
    fetchAppointmentTypes,
    loading,
    options: appointmentTypes,
  } = useAppointmentTypes({});

  const doctors = useDoctors("option", { role_category: "doctor" });

  const assistants = useDoctors("option", { role_category: "assistant" });

  const HAS_INTERACTION_VIEW_ACCESS = hasAccess(
    ResponsibilityTypes.INTERACTION_CREATE
  );

  const HAS_DENTAL_OBJ_VIEW_ACCESS = hasAccess(
    ResponsibilityTypes.DENTAL_OBJ_VIEW
  );

  const HAS_CUSTOM_FORM_VIEW_ACCESS = hasAccess(
    ResponsibilityTypes.CUSTOM_FORM_VIEW
  );

  const validator = async () => {
    try {
      if (workItem?.interaction) {
        await startInteractionFormValidation.validate(formValues);
        setValid(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    let updateInteraction = Object.assign(
      new Interaction(),
      workItem?.interaction
    );
    setFormValues({
      ...updateInteraction,
      getDoctorFullName: () =>
        `${updateInteraction?.doctorFirstName} ${updateInteraction?.doctorLastName}`,
      assistantProfileId:
        user?.roleName === UserRoles.ASSISTANT ? user?.profileId : undefined,
    });

    if (workItem?.interaction?.contractId) {
      const contract = contracts.find(
        (contract) => contract.id === workItem?.interaction?.contractId
      );
      if (contract?.contractService) {
        setContractService(contract?.contractService);
      }
    }
    validator();
  }, [workItem, contracts]);

  useEffect(() => {
    ContractService.fetchPatientContracts(
      workItem.patientProfileId as number,
      (contracts) => {
        const contractOptions = contracts.map((contract) => {
          return {
            ...contract,
            label: contract.name,
            value: contract.id,
            contractService: {
              ...contract?.contractService,
              label: contractService?.serviceName,
              value: contractService?.id,
            },
          };
        });
        setContracts(contractOptions);
      },
      () => {},
      () => {},
      { ...new FilterParams(), status: "active" }
    );

    if (HAS_CUSTOM_FORM_VIEW_ACCESS) {
      CustomFormService.fetchCustomForms(
        CustomFormTypeEnum.INTERACTION,
        (customForms: CustomForm[]) => {
          setCustomFormOptions(
            customForms.map((customForm) => ({
              label: customForm.name,
              value: customForm.id,
            }))
          );
        },
        () => {},
        () => {}
      );
    }
  }, []);

  const handleSubmit = (values: Interaction) => {
    setLoader(true);
    const interaction = Object.assign(new Interaction(), values);
    InteractionService.startInteraction(
      workItem?.assignableId as string,
      interaction,
      (interaction: Interaction) => {
        CustomFormRequestService.updateCustomFormRequests(
          workItem?.assignableId as string,
          customForms,
          () => {
            closeHandler();
            if (!HAS_INTERACTION_VIEW_ACCESS || !HAS_DENTAL_OBJ_VIEW_ACCESS)
              return;

            history.push(
              generatePath(AppRoutes.INTERACTION, {
                patientProfileId: workItem.patientProfileId,
                interactionId: interaction.id,
              })
            );
          },
          () => {},
          () => {}
        );
      },
      () => {},
      () => {
        setLoader(false);
      }
    );
  };

  return (
    <div className="start-interaction-form__container">
      <Formik
        initialValues={formValues}
        validationSchema={startInteractionFormValidation}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ values, isValid, dirty, setFieldValue, handleSubmit }) => {
          return (
            <Form>
              <div className="start-interaction-form__wrapper">
                <Row gutter={[15, 15]}>
                  <Col span={12}>
                    <DropdownField
                      title="Appointment Type"
                      name="appointmentTypeId"
                      value={values.appointmentTypeId}
                      options={appointmentTypes}
                      loading={loading}
                      onScrollEnd={fetchAppointmentTypes}
                      setFieldValue={setFieldValue}
                    />
                  </Col>
                  <Col span={12}>
                    <InputField
                      title="Duration"
                      name="time"
                      type="text"
                      disabled
                      value={`${moment(workItem.endTime)
                        .diff(moment(workItem.startTime), "minutes")
                        .toString()}min`}
                    />
                  </Col>
                </Row>
                <Row gutter={[15, 15]}>
                  <Col span={12}>
                    <DropdownField
                      title="Doctor"
                      name="doctorProfileId"
                      value={values.doctorProfileId}
                      options={doctors}
                      setFieldValue={setFieldValue}
                    />
                  </Col>
                  <Col span={12}>
                    <DropdownField
                      title="Assistant"
                      name="assistantProfileId"
                      value={values.assistantProfileId}
                      options={assistants}
                      setFieldValue={setFieldValue}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <InputField title="Notes" type="textarea" name="notes" />
                  </Col>
                </Row>
                <Row gutter={[15, 15]}>
                  <Col span={12}>
                    <DropdownField
                      title="Contract"
                      name="contractId"
                      options={contracts}
                      value={values.contractId}
                      onChange={(value) => {
                        const [contract] = contracts.filter(
                          (contract) => contract?.id === value
                        );
                        setFieldValue("contractId", value);
                        setFieldValue("contractServiceId", "");
                        setContractService(
                          contract.contractService || undefined
                        );
                      }}
                    />
                  </Col>
                  <Col span={12}>
                    <DropdownField
                      title="Service"
                      name="contractServiceId"
                      value={
                        values?.contractId
                          ? values.contractServiceId
                          : undefined
                      }
                      options={
                        contractService
                          ? [
                              {
                                label: contractService?.serviceName,
                                value: contractService?.id,
                              },
                            ]
                          : []
                      }
                      setFieldValue={setFieldValue}
                      disabled={!values?.contractId}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <p>Appointment Mode</p>
                    <Radio.Group
                      onChange={({ target: { value } }) => {
                        setFieldValue("visitType", value);
                      }}
                      value={values.visitType}
                    >
                      {VisitOptions.map(({ label, ...props }) => (
                        <Radio {...props}>{label}</Radio>
                      ))}
                    </Radio.Group>
                  </Col>
                  {!contracts?.length && (
                    <Col span={12}>
                      <DropdownField
                        title="Patient Category"
                        name="patientCategory"
                        placeHolder="Select Patient Category"
                        setFieldValue={setFieldValue}
                        options={patientCategories}
                        value={values.patientCategory}
                      />
                    </Col>
                  )}
                </Row>
                {HAS_CUSTOM_FORM_VIEW_ACCESS && (
                  <Row>
                    <Col span={12}>
                      <DropdownField
                        mode={DropdownModeEnum.MULTIPLE}
                        title="Custom interaction forms"
                        name="customForms"
                        options={customFormOptions}
                        onChange={(customFormIds) => {
                          setCustomForms(customFormIds);
                        }}
                      />
                    </Col>
                  </Row>
                )}
              </div>
              <div className="start-interaction-form__control">
                <Button type="text" onClick={closeHandler}>
                  Cancel
                </Button>
                <Button
                  loading={loader}
                  disabled={!isValid || (!dirty && !valid)}
                  type="primary"
                  onClick={() => handleSubmit()}
                >
                  Create Interaction
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default AuthContainer(StartInteractionForm);
