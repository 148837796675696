import React, { Fragment, useEffect, useState } from "react";
import "./interactionVisitDetails.scss";
import { Row, Col, Drawer } from "antd";
import { Interaction } from "../../../../models/Interaction/interaction.model";
import { InteractionService } from "../../../../services/Interaction/Interaction.service";
import AppLoader from "../../../../shared/components/AppLoader";
import moment from "moment";
import { LineChartOutlined } from "@ant-design/icons";
import HistoryChart from "../HistoryChart";
import { Appointment } from "../../../../models/Appointment/appointment.model";
import { ContractVisit } from "../../../../models/ContractVisit/contractVisit.model";
import useResponsibilities from "../../../../shared/hooks/Responsibilities/useResponsibilities";
import { ResponsibilityTypes } from "../../../../enums/responsebily.enum";
import { VisitOptions } from "../../../../shared/options/Visits";

interface InteractionVisitDetailsProps {
  interactionId: number;
  displayStacked?: boolean;
}

type ratingKeys = "elasticCompliance" | "oralHygiene";

function InteractionVisitDetails({
  interactionId,
  displayStacked,
}: InteractionVisitDetailsProps) {
  const { hasAccess } = useResponsibilities();

  const [interaction, setInteraction] = useState<Interaction>(
    new Interaction()
  );

  const [loading, setLoading] = useState(false);

  const [showHistory, setShowHistory] = useState(false);

  const HAS_INTERACTION_VIEW_ACCESS = hasAccess(
    ResponsibilityTypes.INTERACTION_VIEW
  );

  const HAS_INTERACTION_EDIT_ACCESS = hasAccess(
    ResponsibilityTypes.INTERACTION_EDIT
  );

  const handleUpdate = (keyName: ratingKeys, rating: number) => () => {
    if (!HAS_INTERACTION_EDIT_ACCESS) return;

    interaction.id = interactionId;
    interaction[keyName] = rating;
    InteractionService.updateInteraction(
      interaction,
      (interaction: Interaction) => {
        setInteraction(interaction);
      },
      () => {},
      () => {}
    );
  };

  useEffect(() => {
    if (interactionId && HAS_INTERACTION_VIEW_ACCESS) {
      setLoading(true);
      InteractionService.showInteraction(
        interactionId,
        (interaction: Interaction) => {
          setInteraction(interaction);
        },
        () => {},
        () => {
          setLoading(false);
        }
      );
    }
  }, [interactionId]);

  const visitDetailFields = [
    {
      label: "Appointment Name",
      value: interaction?.appointmentTypeName || "-",
    },
    {
      label: "Visit Number",
      value: interaction?.visitNo || "1",
    },
    {
      label: "Seated",
      value: moment(interaction?.startTime).format("MM/DD/YYYY hh:mm A"),
    },
    {
      label: "Visit Type",
      value:
        VisitOptions?.find(({ value }) => interaction?.visitType === value)
          ?.label || interaction?.visitType,
    },
    {
      label: "Duration",
      value: "30 min",
    },
    {
      label: "Emergency",
      value: interaction?.isEmergencyVisit ? "Yes" : "No",
    },
    {
      label: "Doctor",
      value: interaction?.doctorFirstName + " " + interaction?.doctorLastName,
    },
    {
      label: "Oral hygiene",
      value: (
        <div>
          {[...Array(5)].map((x, i) => (
            <span
              onClick={handleUpdate("oralHygiene", i + 1)}
              className={`interaction-visit-details__rate-icons ${
                interaction?.oralHygiene === i + 1 ? "active" : ""
              }`}
            >
              <i className={"icon-oral-hygine"} />
            </span>
          ))}
        </div>
      ),
    },
    {
      label: "Elastic Compliance",
      value: (
        <div>
          {[...Array(5)].map((x, i) => (
            <span
              onClick={handleUpdate("elasticCompliance", i + 1)}
              className={`interaction-visit-details__rate-icons ${
                interaction?.elasticCompliance === i + 1 ? "active" : ""
              }`}
            >
              <i className={"icon-oral-hygine"} />
            </span>
          ))}
        </div>
      ),
    },
  ];

  const handleOpenHistory = () => {
    setShowHistory(true);
  };

  const handleCloseShowHistory = () => {
    setShowHistory(false);
  };

  return (
    <div
      className={`interaction-visit-details ${
        displayStacked && "interaction-visit-details__stacked"
      }`}
    >
      {loading ? (
        <AppLoader loading={loading} />
      ) : (
        <Fragment>
          {!displayStacked && (
            <div className="chart__trigger" onClick={handleOpenHistory}>
              <LineChartOutlined />
            </div>
          )}
          {visitDetailFields?.map((field, i) => (
            <Row
              align="middle"
              gutter={[10, 8]}
              key={i}
              className="interaction-visit-details__field mb-5"
            >
              <Col
                span={displayStacked ? 24 : 12}
                className="interaction-visit-details__label"
              >
                {field?.label}
              </Col>
              <Col
                span={displayStacked ? 24 : 12}
                className="interaction-visit-details__value"
              >
                {field?.value}
              </Col>
            </Row>
          ))}
        </Fragment>
      )}
      <Drawer
        title="History"
        visible={showHistory}
        onClose={handleCloseShowHistory}
        destroyOnClose
        width={"65vw"}
      >
        <HistoryChart interaction={interaction} />
      </Drawer>
    </div>
  );
}

export default InteractionVisitDetails;
