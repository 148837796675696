import { Button, Col, Divider, Drawer, Radio, Row } from "antd";
import { Form, Formik, FormikValues } from "formik";
import React, { FC, useEffect } from "react";
import { DentalSet } from "../../../models/DentalSet/dentalSet.model";
import { InteractionStep } from "../../../models/InteractionStep/interactionStep.model";
import { ProcedureStep } from "../../../models/ProcedureStep/procedureStep.model";
import { TreatmentPlanTemplateSteps } from "../../../models/TreatmentPlanTemplateSteps/treatmentPlanTemplateSteps.model";
import { IDropdownOptions } from "../../../services/Meta/meta.service";
import BabylonContainer from "../../../store/container/BabylonContainer";
import RadioInputField from "../../../shared/components/RadioInputField";
import { BabylonReducerProps } from "../../../store/reducers/babylonReducer";
import "./conflictManagement.scss";
import { CONFLICT_TYPES } from "../../../enums/conflictType.enum";

interface ConflictManagementProps extends BabylonReducerProps {
	visible: boolean;
	onSubmit?: (data: any) => void;
	onClose?: () => void;
}

const Options: IDropdownOptions[] = [
	{
		label: "Hard Apply",
		value: CONFLICT_TYPES.HARD_APPLY,
	},
	{
		label: "Skip",
		value: CONFLICT_TYPES.SKIP,
	},
];

const ConflictManagement: FC<ConflictManagementProps> = (props) => {
	const { onSubmit, onClose, conflicts, ...rest } = props;

	const handleSubmit = (values: FormikValues) => {
		const payload = conflicts.map((conflict, index) => {
			return {
				...conflict,
				conflict_type: values.conflictResponse[index],
			};
		});
		onSubmit && onSubmit(payload);
	};

	return (
		<Drawer
			className="conflict-management"
			{...rest}
			width="65vw"
			title="Conflicts"
			destroyOnClose
			onClose={onClose}
		>
			<Formik
				initialValues={{
					conflictResponse: conflicts.map(() => undefined),
				}}
				enableReinitialize
				onSubmit={handleSubmit}
			>
				{({ values, setFieldValue }) => (
					<Form>
						<div className="conflict__wrapper">
							<Row>
								<Col span={12} className="light-color">
									Tooth Name
								</Col>
								<Col span={12} className="light-color">
									Actions
								</Col>
							</Row>
							<Divider />
							{conflicts.map((step: any, index: number) => {
								return (
									<Row>
										<Col span={12}>
											{step?.set
												? step?.set?.name
												: step?.procedure
												? step?.procedure?.name
												: step?.toothLink
														?.srcObjectName}
										</Col>
										<Col span={12}>
											<RadioInputField
												name={`conflictResponse[${index}]`}
												value={
													values?.conflictResponse[
														index
													]
												}
												setFieldValue={setFieldValue}
												options={Options}
											/>
										</Col>
									</Row>
								);
							})}
						</div>
						<div className="conflict-controller">
							<Button type="text" onClick={onClose}>
								Cancel
							</Button>
							<Button type="primary" htmlType="submit">
								Apply
							</Button>
						</div>
					</Form>
				)}
			</Formik>
		</Drawer>
	);
};

export default BabylonContainer(ConflictManagement);
