import React, { FC } from "react";
import "./customModal.scss";
import alertIcon from "../../../../src/assets/images/alertIcon.png";
import { Button, Col, Modal, Row } from "antd";

interface CustomModalProps {
  title: string;
  visible?: boolean;
  type?: string;
  footer?: string;
  closeHandler?: () => void;
  okHandler?: () => void;
  buttonLoading?: boolean;
  okText?: string;
  cancelText?: string;
  isCancelButton?: boolean;
}

const CustomModal: FC<CustomModalProps> = (props) => {
  const {
    title,
    visible,
    type,
    okText,
    cancelText,
    okHandler,
    closeHandler,
    buttonLoading,
    isCancelButton = true,
  } = props;

  return (
    <Modal
      footer={null}
      visible={visible ?? true}
      closeIcon={false}
      closable={false}
      onCancel={closeHandler}
      className="modal-field"
    >
      <div className="custom-form">
        <Row>
          <Col span={24}>
            {" "}
            <img className="alert-icon" src={alertIcon} alt="alert" />
          </Col>
          <Col span={24} className="custom-form__content ">
            {title}
          </Col>
        </Row>
        <Row justify="end">
          {isCancelButton && (
            <Col span={12}>
              <Button onClick={closeHandler}>{cancelText ?? "Cancel"}</Button>
            </Col>
          )}
          <Col span={12}>
            <Button type="primary" onClick={okHandler}>
              {okText ?? "OK"}
            </Button>
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

export default CustomModal;
