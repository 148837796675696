export const childTeethNames = [
  "Canine53",
  "Canine63",
  "Canine73",
  "Canine83",
  "CentralIncisor51",
  "CentralIncisor61",
  "CentralIncisor71",
  "CentralIncisor81",
  "LateralIncisor52",
  "LateralIncisor62",
  "LateralIncisor72",
  "LateralIncisor82",
  "Molar54",
  "Molar55",
  "Molar64",
  "Molar65",
  "Molar74",
  "Molar75",
  "Molar84",
  "Molar85",
];

export const teethTypes = [
  "CentralIncisor_1",
  "LateralIncisor_2",
  "Canine_3",
  "Premolar_4",
  "Premolar_5",
  "Molar_6",
  "Molar_7",
  "Molar_8",
];

export const teethPositions: any = {
  Molar4: {
    x: 220,
    y: 55,
  },
  Molar5: {
    x: 130,
    y: 55,
  },
  Molar8: {
    x: 55,
    y: 55,
  },
  Molar7: {
    x: 70,
    y: 55,
  },
  Molar6: {
    x: 100,
    y: 55,
  },
  Premolar5: {
    x: 130,
    y: 55,
  },
  Premolar4: {
    x: 220,
    y: 55,
  },
  Canine: {
    x: 600,
    y: 55,
  },
  LateralIncisor: {
    x: 1800,
    y: 55,
  },
  CentralIncisor: {
    x: 2000,
    y: 55,
  },
};
