import { SET_CURRENT_APPOINTMENT, SET_CURRENT_TREATMENT_PLAN } from "../definitions/treatmentPlanConstants";
import { TreatmentPlanTemplate } from "../../models/TreatmentPlanTemplate/treatmentPlanTemplate.model";

export interface TreatmentTemplateAction {
    type: string;
    payload?: { currentTreatmentPlan?: TreatmentPlanTemplate, currentAppointment?: number };
}

export const setCurrentTreatmentPlan = (currentTreatmentPlan: TreatmentPlanTemplate): TreatmentTemplateAction => {
    return {
        type: SET_CURRENT_TREATMENT_PLAN,
        payload: {
            currentTreatmentPlan,
        },
    };
};


export const setCurrentAppointment = (currentAppointment: number) => {
    return {
        type: SET_CURRENT_APPOINTMENT,
        payload: {
            currentAppointment
        }
    }
}