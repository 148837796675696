import React, { useEffect, useState } from "react";
import "./patientDetailWrapper.scss";
import { Patient } from "../../../models/Patient/patient.model";
import PatientDetail from "../PatientDetail";
import PatientNotes from "../PatientNotes";

interface PatientDetailWrapperProps {
  patient: Patient | null;
  tab: string;
}

function PatientDetailWrapper({ patient, tab }: PatientDetailWrapperProps) {
  const [notesUpdated, setNotesUpdated] = useState(false);
  useEffect(() => setNotesUpdated(!notesUpdated), [tab]);
  return (
    <div className="patient-detail-wrapper">
      <div className="patient-detail-wrapper__basic-details">
        <h5>Patient Details</h5>
        {patient && <PatientDetail patient={patient} />}
        <h5>Patient Notes</h5>
        <PatientNotes key={`notes-${notesUpdated}`} isViewMode />
      </div>
      {patient?.responsiblePerson?.id && (
        <div className="patient-detail-wrapper__responsible-person">
          <h5>Responsible Person</h5>
          <PatientDetail patient={patient.responsiblePerson} />
        </div>
      )}
    </div>
  );
}

export default PatientDetailWrapper;
