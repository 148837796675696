import * as Yup from "yup";
import moment from "moment";
import helpers from "../../../shared/utils/helpers";
import { getDateOptions } from "../../../shared/utils/dateHelpers";

const DATE_FORMAT = "MM/DD/YYYY hh:mm:ss";

export const scheduleFormValidation = Yup.object().shape({
  practiceName: Yup.string().required("Practice name is required!"),
  profileIds: Yup.string().required("Staff is required!"),
  availabilityTypes: Yup.array().required("Type of appointment is required!"),
  startDate: Yup.string().required("Start date is required!"),
  endDate: Yup.string().required("End date is required!"),
  startTime: Yup.string().when("isBasedOnWeekdays", {
    is: false,
    then: (schema: any) => schema.required("Start time is required"),
    // .test(
    //   "startTime",
    //   "Start time cannot be after end time",
    //   function (value: string) {
    //     if (!this.parent?.endTime) {
    //       return true;
    //     }
    //     const date = moment().format("MM/DD/YYYY");
    //     const startTime = moment(`${date} ${value}`, DATE_FORMAT);
    //     const endTime = moment(
    //       `${date} ${this.parent?.endTime}`,
    //       DATE_FORMAT
    //     );
    //     return startTime.isBefore(endTime);
    //   }
    // ),
    otherwise: (schema: any) => schema.notRequired().nullable(),
  }),
  endTime: Yup.string().when("isBasedOnWeekdays", {
    is: false,
    then: (schema: any) => schema.required("End time is required"),
    // .test(
    //   "endTime",
    //   "End time cannot be before start time",
    //   function (value: string) {
    //     if (!this.parent?.startTime) {
    //       return true;
    //     }
    //     const date = moment().format("MM/DD/YYYY");
    //     const startTime = moment(
    //       `${date} ${this.parent?.startTime}`,
    //       DATE_FORMAT
    //     );
    //     const endTime = moment(`${date} ${value}`, DATE_FORMAT);
    //     return startTime.isBefore(endTime);
    //   }
    // ),
    otherwise: (schema: any) => schema.notRequired(),
  }),
});
