import { Button } from "antd";
import React, { FC, useState } from "react";
import "./subscription.scss";
import { CheckOutlined } from "@ant-design/icons";
import { useEffect } from "react";
import { CorporateService } from "../../../services/Corporate/corporate.service";
import { Subscription as SubscriptionModel } from "../../../models/Subscription/subscription.model";
import { Tenure } from "../../../enums/tenureType.enum";
import CorporateContainer from "../../../store/container/CorporateContainer";
import { CorporateReducerProps } from "../../../store/reducers/corporateReducer";
import { SubscriptionFrequencie } from "../../../models/SubscriptionFrequencie/subscriptionFrequencie.model";
import AppLoader from "../../../shared/components/AppLoader";

interface SubscriptionProps extends CorporateReducerProps {}

const Subscription: FC<SubscriptionProps> = (props) => {
  const { currentCorporate } = props;

  const [loading, setLoading] = useState(false);

  const [
    subscriptionFrequency,
    setSubscriptionFrequency,
  ] = useState<SubscriptionFrequencie>();

  useEffect(() => {
    setLoading(true);
    CorporateService.subscriptionFrequencyId(
      currentCorporate?.subscriptionFrequencyId ?? 0,
      (subscriptionFrequency) => {
        setSubscriptionFrequency(subscriptionFrequency);
      },
      () => {},
      () => {
        setLoading(false);
      }
    );
  }, []);

  if (loading) {
    return <AppLoader loading />;
  }

  return (
    <div className="subscriptions">
      <div className="subscription">
        <p>{subscriptionFrequency?.subscription?.name}</p>

        <h1>
          {subscriptionFrequency?.subscription?.trialPeriod
            ? "Trial Period"
            : `$${subscriptionFrequency?.recurringAmount ?? "0"}/${
                subscriptionFrequency?.subscriptionType
              }`}

          <span className="trial-period">
            {subscriptionFrequency?.subscription?.trialPeriod} days
          </span>
        </h1>
        <ul className="benefits">
          <li>
            <CheckOutlined />{" "}
            {subscriptionFrequency?.subscription?.maxNoOfPractices} Practices
          </li>
          <li>
            <CheckOutlined />{" "}
            {subscriptionFrequency?.subscription?.maxNoOfStaffs} Staffs
          </li>
          <li>
            <CheckOutlined />{" "}
            {subscriptionFrequency?.subscription?.maxNoOfPatients} Patients
          </li>
        </ul>
        <p className="feature">Feature description</p>
        <ul className="features-list">
          <li>{subscriptionFrequency?.subscription?.description}</li>
        </ul>
      </div>
    </div>
  );
};

export default CorporateContainer(Subscription);
