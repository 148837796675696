import { serializable, alias, object, list, primitive } from "serializr";

class AppointmentResourceType {
  @serializable(alias("resource_type_id", primitive()))
  resourceTypeId?: number;
}

export class AppointmentType {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("title", primitive()))
  title?: string;

  @serializable(alias("nick_name", primitive()))
  nickName?: string;

  @serializable(alias("procedure_code", primitive()))
  procedureCode?: string;
  @serializable(alias("completed_appointments_count", primitive()))
  appointmentCount?: number;

  @serializable(alias("duration_in_mins", primitive()))
  duration?: string;

  @serializable(alias("resource_required", primitive()))
  resourceRequired?: boolean;

  @serializable(alias("color_code", primitive()))
  treatmentColor?: string = "#000000";

  @serializable(alias("description", primitive()))
  description?: string;
  @serializable(alias("is_doctor_required", primitive()))
  isDoctorRequired?: boolean;
  @serializable(
    alias("appointment_resource_types", list(object(AppointmentResourceType)))
  )
  appointmentResourceTypes: AppointmentResourceType[] = [];

  @serializable(alias("appointment_type_category_id", primitive()))
  categoryId?: number;
}

export class AppointmentTypes {
  @serializable(alias("appointment_types", list(object(AppointmentType))))
  appointmentTypes: AppointmentType[] = [];
}
