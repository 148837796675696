import ApiRoutes from "../../routes/routeConstants/apiRoutes";
import axiosInstance from "../../interceptor/axiosInstance";
import { CustomForm } from "../../models/CustomForm/customForm.model";
import { deserialize, serialize } from "serializr";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import { CustomFormTypeEnum } from "../../enums/customFormType.enum";
import { PaginationDetails } from "../../models/Pagination/pagination.model";
import { FilterParams } from "../../models/FilterParams/filterParams.model";

export default class CustomFormService {
  static fetchCustomForms(
    type: CustomFormTypeEnum,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void,
    filter?: FilterParams
  ) {
    console.log({ filter });
    const params = serialize(
      FilterParams,
      { ...filter, type: type } ?? new FilterParams()
    );
    axiosInstance
      .get(ApiRoutes.CUSTOM_FORMS, {
        params,
      })
      .then((response) => {
        const customForms = deserialize(
          CustomForm,
          response.data["custom_forms"]
        );
        const pagination = deserialize(
          PaginationDetails,
          response.data["meta"]
        );
        onSuccess(customForms, pagination);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static fetchPatientDetailForms(
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .get(ApiRoutes.PATIENT_DETAIL_FORMS)
      .then((response) => {
        const customForms = deserialize(
          CustomForm,
          response.data["custom_forms"]
        );
        onSuccess(customForms);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static showCustomForm(
    customFormId: number,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.CUSTOM_FORMS + "/" + customFormId;
    axiosInstance
      .get(API_URL)
      .then((response) => {
        const customForm = deserialize(
          CustomForm,
          response.data["custom_form"]
        );
        onSuccess(customForm);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static createCustomForm(
    customForm: CustomForm,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const customFormJSON = { custom_form: serialize(customForm) };
    axiosInstance
      .post(ApiRoutes.CUSTOM_FORMS, customFormJSON)
      .then((response) => {
        Notification({
          message: "Custom form created!",
          type: NotificationTypes.SUCCESS,
        });
        const customForm = deserialize(
          CustomForm,
          response.data["custom_form"]
        );
        onSuccess(customForm);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static updateCustomForm(
    customForm: CustomForm,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.CUSTOM_FORMS + "/" + customForm.id;
    const customFormJSON = { custom_form: serialize(customForm) };
    axiosInstance
      .put(API_URL, customFormJSON)
      .then((response) => {
        Notification({
          message: "Custom form updated!",
          type: NotificationTypes.SUCCESS,
        });
        const customForm = deserialize(
          CustomForm,
          response.data["custom_form"]
        );
        onSuccess(customForm);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static deleteCustomForm(
    customFormId: string,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.CUSTOM_FORMS + "/" + customFormId;
    axiosInstance
      .delete(API_URL)
      .then((response) => {
        onSuccess();
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }
}
