import { Tabs } from "antd";
import React, { FC } from "react";
import CorporateInformation from "./CorporateInformation";
import Subscription from "./Subscription";
import "./corporateDetails.scss";
import PaymentSettings from "./PaymentSettings";
import requireAuth from "../../shared/components/HOC/requireAuth";
import { ResponsibilityTypes } from "../../enums/responsebily.enum";
import { Route } from "react-router-dom";
import useResponsibilities from "../../shared/hooks/Responsibilities/useResponsibilities";

const { TabPane } = Tabs;

interface CorporateDetailsProps {}

const CorporateDetails: FC<CorporateDetailsProps> = (props) => {
  const {} = props;

  const { hasAccess } = useResponsibilities();

  const HAS_CORPORATE_VIEW_ACCESS = hasAccess(
    ResponsibilityTypes.CORPORATE_VIEW
  );
  const HAS_SUBSCRIPTION_VIEW_ACCESS = hasAccess(
    ResponsibilityTypes.SUBSCRIPTION_VIEW
  );
  const HAS_SUBSCRIPTION_PAYMENT_VIEW_ACCESS = hasAccess(
    ResponsibilityTypes.SUBSCRIPTION_PAYMENT_VIEW
  );

  const corporateTabs = [
    {
      title: "Corporate Information",
      component: requireAuth(
        CorporateInformation,
        ResponsibilityTypes.CORPORATE_VIEW
      ),
      hasAccess: HAS_CORPORATE_VIEW_ACCESS,
    },
    {
      title: "Subscription",
      component: requireAuth(
        Subscription,
        ResponsibilityTypes.SUBSCRIPTION_VIEW
      ),
      hasAccess: HAS_SUBSCRIPTION_VIEW_ACCESS,
    },
    {
      title: "Payment Settings",
      component: requireAuth(
        PaymentSettings,
        ResponsibilityTypes.SUBSCRIPTION_PAYMENT_VIEW
      ),
      hasAccess: HAS_SUBSCRIPTION_PAYMENT_VIEW_ACCESS,
    },
  ];

  return (
    <div className="corporate-details">
      <Tabs size="small" defaultActiveKey={"0"}>
        {corporateTabs.map(
          ({ title, component, hasAccess }, index) =>
            hasAccess && (
              <TabPane tab={title} key={index}>
                <Route component={component} />
              </TabPane>
            )
        )}
      </Tabs>
    </div>
  );
};

export default CorporateDetails;
