import React, { useEffect, useState } from "react";
import { Button, Col, Drawer, Row } from "antd";
import DropdownField from "../DropdownField";
import { Formik, FormikValues } from "formik";
import CustomFormService from "../../../services/CustomForm/customForm.service";
import { CustomForm } from "../../../models/CustomForm/customForm.model";
import { IDropdownOptions } from "../../../services/Meta/meta.service";
import CustomFormResponseForm from "../../../views/Patients/CustomFormResponseForm";
import { CustomFormTypeEnum } from "../../../enums/customFormType.enum";
import CustomFormRequestService from "../../../services/CustomFormRequest/customFormRequest.service";
import { CustomFormRequest } from "../../../models/CustomFormRequest/customFormRequest.model";
import useResponsibilities from "../../hooks/Responsibilities/useResponsibilities";
import { ResponsibilityTypes } from "../../../enums/responsebily.enum";

interface CustomFormRequestFormProps {
  customFormTypeEnum?: CustomFormTypeEnum;
  resourceId: number;
  disableRequestToFill?: boolean;
  onSuccess: () => void;
  requestPatientToFillSuccess?: () => void;
}

function CustomFormRequestForm({
  resourceId,
  customFormTypeEnum,
  disableRequestToFill,
  onSuccess,
  requestPatientToFillSuccess
}: CustomFormRequestFormProps) {
  const { hasAccess } = useResponsibilities();

  const [customFormOptions, setCustomFormOptions] = useState<
    IDropdownOptions[]
  >([]);

  const [activeCustomFormId, setActiveCustomFormId] = useState<
    number | undefined
  >();

  const [showCustomFormResponseForm, setShowCustomFormResponseForm] = useState(
    false
  );

  const HAS_CUSTOM_FORM_VIEW_ACCESS = hasAccess(ResponsibilityTypes.CUSTOM_FORM_VIEW);

  const handleSubmit = (values: FormikValues) => {};

  const handleManualComplete = () => {
    handleToggleCustomFormResponseForm();
  };

  const handleToggleCustomFormResponseForm = () =>
    setShowCustomFormResponseForm(!showCustomFormResponseForm);

  const handleCustomFormResponseSuccess = () => {
    handleToggleCustomFormResponseForm();
    onSuccess();
  };

  useEffect(() => {
    if(!HAS_CUSTOM_FORM_VIEW_ACCESS)  return;

    CustomFormService.fetchCustomForms(
      customFormTypeEnum ?? CustomFormTypeEnum.CONTRACT,
      (customForms: CustomForm[]) => {
        setCustomFormOptions(
          customForms.map((customForm) => ({
            label: customForm.name,
            value: customForm.id,
          }))
        );
      },
      () => {},
      () => {}
    );
  }, []);

  const handleRequestPatientToFill = () => {
    const customFormRequest = new CustomFormRequest(activeCustomFormId);
    customFormRequest.requestableId = resourceId;
    customFormRequest.customFormFilledBy = "patient";
    CustomFormRequestService.createCustomFormRequest(
      activeCustomFormId as number,
      customFormRequest,
      (customFormRequest: CustomFormRequest) => {
        requestPatientToFillSuccess && requestPatientToFillSuccess();
      },
      () => {},
      () => {
      }
    );
  }

  return HAS_CUSTOM_FORM_VIEW_ACCESS ? (
    <Formik
      initialValues={{ formType: undefined }}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      <Row gutter={[20, 0]} align="middle">
        <Col span={10}>
          <DropdownField
            title="Form Type"
            name="formType"
            options={customFormOptions}
            value={activeCustomFormId}
            onChange={(value) => {
              setActiveCustomFormId(value);
            }}
            placeHolder="Select Form Type"
          />
        </Col>
        <Col span={14}>
          {!disableRequestToFill && (
            <Button type="primary" disabled={!activeCustomFormId} onClick={handleRequestPatientToFill}>
              Request patient to fill
            </Button>
          )}
          <Button
            disabled={!activeCustomFormId}
            className="ml-2 secondary-btn"
            htmlType="button"
            onClick={handleManualComplete}
          >
            Complete it manually
          </Button>
        </Col>
        <Drawer
          title={
            <div>
              <h4>Custom Form Response</h4>
            </div>
          }
          keyboard={false}
          placement="right"
          maskClosable={false}
          onClose={handleToggleCustomFormResponseForm}
          visible={showCustomFormResponseForm}
          destroyOnClose={true}
          width="60%"
        >
          {activeCustomFormId && resourceId && (
            <CustomFormResponseForm
              resourceId={resourceId}
              customFormId={activeCustomFormId}
              onSuccess={handleCustomFormResponseSuccess}
              onCancel={handleToggleCustomFormResponseForm}
            />
          )}
        </Drawer>
      </Row>
    </Formik>
  ) : <></>;
}

export default CustomFormRequestForm;
