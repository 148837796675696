import React from "react";
import { Table } from "antd";
import { currencyFormatter } from "../../../../../../shared/utils/formatter";
import moment from "moment";
import { InsuranceProvider } from "../../../../../../models/InsuranceProvider/insuranceProvider.model";

import "./insuranceDetail.scss";

interface InsuranceDetailProps {
  insurance?: InsuranceProvider;
}

export interface Field {
  label: string;
  value?: string | number;
}
const InsuranceDetail = ({ insurance }: InsuranceDetailProps) => {
  if (!insurance) return <></>;

  const {
    lifetimeMaxAmount,
    overallClaimAmount,
    initialClaimAmount,
    noOfDues,
    payoutRatio,
    overallExpectedAmount,
    initialExpectedAmount,
    dueTime,
    insuranceProviderName,
    remainingClaimAmount,
    remainingExpectedAmount,
  } = insurance;

  const firstColumnFields: Field[] = [
    {
      label: "Insurance lifetime max amount",
      value: lifetimeMaxAmount,
    },
    {
      label: "Total Fee (net of discount)",
      value: overallClaimAmount,
    },
    {
      label: "Initial insurance claim amount",
      value: initialClaimAmount,
    },
    {
      label: "No of insurance dues",
      value: noOfDues,
    },
  ];

  const secondColumnFields: Field[] = [
    {
      label: "lifetime insurance payout ratio",
      value: payoutRatio ? `${payoutRatio}%` : "",
    },
    {
      label: "Overall expected insurance amount",
      value: overallExpectedAmount,
    },
    {
      label: "Initial insurance payout amount",
      value: initialExpectedAmount,
    },
    {
      label: "due time",
      value: dueTime,
    },
  ];

  const scheduleColumns = [
    {
      title: "Due",
      dataIndex: "dueNo",
    },
    {
      title: "Date",
      dataIndex: "dueDate",
      render: (date: string) => moment(date).format("Do MMM YYYY"),
    },
    {
      title: "Claim Amount",
      dataIndex: "claimAmount",
    },
    {
      title: "Expected Amount",
      dataIndex: "expectedAmount",
    },
    {
      title: "Procedure Code",
      dataIndex: "procedureCode",
    },
  ];

  const getFields = (fields: Field[]) =>
    fields.map(({ label, value }) => (
      <div className="insurance-detail__field">
        <span className="text-capitalize insurance-detail__field__label">
          {label}
        </span>
        <span className="insurance-detail__field__value">{value || "-"}</span>
      </div>
    ));

  return (
    <div className="insurance-detail">
      <div className="insurance-detail__inline-start">
        <div className="insurance-detail__inline-start__header">
          <span className="text-bold">Insurance</span>
          <span className="insurance-detail__inline-start__header__title">
            {insuranceProviderName || ""}
          </span>
        </div>
        <div className="insurance-detail__inline-start__grid">
          <div className="insurance-detail__inline-start__grid__left">
            {getFields(firstColumnFields)}
          </div>
          <div className="insurance-detail__inline-start__grid__right">
            {getFields(secondColumnFields)}
          </div>
        </div>
      </div>
      <div className="insurance-detail__inline-end">
        <Table
          bordered={false}
          columns={scheduleColumns}
          dataSource={insurance?.insuranceSchedule}
          pagination={false}
        />
        <div className="insurance-detail__inline-end__summary">
          <span className="text-capitalize insurance-detail__inline-end__summary__label">
            remaining Claim Amount
          </span>
          <span className="insurance-detail__inline-end__summary__value">
            {remainingClaimAmount
              ? currencyFormatter.format(remainingClaimAmount)
              : ""}
          </span>
          <span className="text-capitalize insurance-detail__inline-end__summary__label">
            Remaining Expected Amount
          </span>
          <span className="insurance-detail__inline-end__summary__value">
            {remainingExpectedAmount
              ? currencyFormatter.format(remainingExpectedAmount)
              : ""}
          </span>
        </div>
      </div>
    </div>
  );
};

export default InsuranceDetail;
