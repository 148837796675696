import React, { useEffect, useState } from "react";
import "./patientForm.scss";
import { Patient } from "../../../models/Patient/patient.model";
import { Form, Formik, FormikValues } from "formik";
import { Button, Checkbox, Col, Row, Radio } from "antd";
import InputField from "../../../shared/components/InputField";
import { patientFormValidation } from "./patientFormValidation";
import DropdownField from "../../../shared/components/DropdownField";
import DatePickerField from "../../../shared/components/DatePickerField";
import AddressField from "../../../shared/components/AddressField";
import MobileNumberField from "../../../shared/components/MobileNumberField";
import moment from "moment";
import ResponsiblePersonFields from "../ResponsiblePersonFields";
import PatientService from "../../../services/Patient/patient.service";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import NationalityField from "../../../shared/components/NationalityField";
import { ResponsiblePerson } from "../../../models/ResponsiblePerson/responsiblePerson.model";
import AttachmentUpload from "../../../shared/components/AttachmentUpload";
import CustomFormRequestService from "../../../services/CustomFormRequest/customFormRequest.service";
import CustomFormResponseService from "../../../services/CustomFormResponse/customFormResponse.service";
import { CustomFormTypeEnum } from "../../../enums/customFormType.enum";
import { CustomFormResponse } from "../../../models/CustomFormResponse/customFormResponse.model";
import CustomFormService from "../../../services/CustomForm/customForm.service";
import { CustomForm } from "../../../models/CustomForm/customForm.model";
import { IDropdownOptions } from "../../../services/Meta/meta.service";
import CustomFormRequestForm from "../../../shared/components/CustomFormRequestForm";
import CorporateContainer from "../../../store/container/CorporateContainer";
import { CorporateReducerProps } from "../../../store/reducers/corporateReducer";
import useResponsibilities from "../../../shared/hooks/Responsibilities/useResponsibilities";
import { ResponsibilityTypes } from "../../../enums/responsebily.enum";

interface PatientFormProps extends CorporateReducerProps {
  patient: Patient;
  onCancel: () => void;
  onSuccess: (patient: Patient) => void;
}

const customFormOptions = [
  { label: "Complete it manually ", value: "Complete it manually " },
  { label: "	Request patient to fill", value: "	Request patient to fill" },
];

function PatientForm({
  patient,
  onSuccess,
  onCancel,
  practiceId,
}: PatientFormProps) {
  const { hasAccess } = useResponsibilities();

  const [formLoading, setFormLoading] = useState(false);

  const [isChild, setChild] = useState<boolean | undefined>();

  const [formValues, setFormValues] = useState<Patient>(new Patient());

  const [genderOptions, setGenderOptions] = useState([
    { label: "Male", value: "male" },
    { label: "Female", value: "female" },
    { label: "Prefer not to disclose", value: "prefer_not_to_disclose" },
  ]);

  const HAS_CUSTOM_FORM_VIEW_ACCESS = hasAccess(
    ResponsibilityTypes.CUSTOM_FORM_VIEW
  );

  const handleSubmit = (values: FormikValues) => {
    const patient = Object.assign(new Patient(), { ...values, practiceId });
    if (patient.responsiblePerson && !patient.responsiblePerson?.firstName) {
      patient.responsiblePerson = null;
    }
    setFormLoading(true);
    if (patient.id) {
      PatientService.updatePatient(
        patient,
        (patient: Patient) => {
          onSuccess(patient);
        },
        () => {},
        () => {
          setFormLoading(false);
        }
      );
    } else {
      PatientService.createPatient(
        patient,
        (patient: Patient) => {
          onSuccess(patient);
        },
        () => {},
        () => {
          setFormLoading(false);
        }
      );
    }
  };

  const handleEnableResponsiblePerson = (
    values: FormikValues,
    setValues: Function
  ) => (e: CheckboxChangeEvent) => {
    setValues({
      ...values,
      enableResponsiblePerson: e.target.checked,
      responsiblePerson: e.target.checked
        ? values?.responsiblePerson || new ResponsiblePerson()
        : new ResponsiblePerson(),
    });
  };

  useEffect(() => {
    if (patient) {
      if (patient.dob) {
        const dob = moment(patient.dob).format("YYYY-MM-DD");
        const age = moment.duration(moment().diff(dob));
        setChild(age.years() < 18);
      }
      setFormValues(
        Object.assign(new Patient(), {
          ...patient,
          enableResponsiblePerson: !!patient?.responsiblePerson?.id,
        })
      );
    }
  }, [patient]);

  return (
    <div className="patient-form">
      <Formik
        initialValues={formValues}
        onSubmit={handleSubmit}
        enableReinitialize
        validationSchema={patientFormValidation}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          errors,
          isValid,
          dirty,
          setFieldValue,
          setValues,
          setFieldError,
        }) => {
          return (
            <Form>
              <div className="patient-form__basic-details-wrapper">
                <h4 className="text-secondary">
                  Patient Details
                  {isChild === undefined ? "" : isChild ? "(Child)" : "(Adult)"}
                </h4>
                <Row gutter={[20, 0]}>
                  <Col span={12}>
                    <InputField
                      title="First name"
                      type="text"
                      name="firstName"
                      placeholder="Enter First name"
                    />
                  </Col>
                  <Col span={12}>
                    <InputField
                      title="Last name"
                      type="text"
                      name="lastName"
                      placeholder="Enter Last name"
                    />
                  </Col>
                  <Col span={12}>
                    <DropdownField
                      title="Gender"
                      name="gender"
                      options={genderOptions}
                      value={values.gender}
                      setFieldValue={setFieldValue}
                      placeHolder="Select Gender"
                    />
                  </Col>
                  <Col span={12}>
                    <DatePickerField
                      title="DOB"
                      format="MM-DD-YYYY"
                      name="dob"
                      onChange={(date: any, dateString: any) => {
                        const age = moment.duration(moment().diff(date));
                        const isMinor = age.years() < 18;
                        setFieldValue("dob", date.format("YYYY-MM-DD"));
                        setFieldValue(
                          "enableResponsiblePerson",
                          values?.enableResponsiblePerson || isMinor
                        );
                        setChild(age.years() < 18);
                      }}
                      disabledDate={(d) => {
                        return d > moment(new Date());
                      }}
                      placeholder=""
                      defaultValue={values.dob}
                      setFieldValue={setFieldValue}
                    />
                  </Col>
                  <Col span={12}>
                    <InputField
                      title="Email"
                      type="email"
                      name="email"
                      placeholder="Enter Email"
                      validateSchema={PatientService.validationEmail}
                      errorMessage={"Email is already taken"}
                    />
                  </Col>
                  <MobileNumberField
                    name="mobileNumber"
                    setFieldValue={setFieldValue}
                    value={values.isdCode}
                    validateSchema={PatientService.validationPhone}
                    errorMessage={"Phone is already taken"}
                  />
                  <AddressField
                    values={values}
                    setFieldValue={setFieldValue}
                    countryKey="countryId"
                    provinceKey="provinceId"
                    cityKey="cityId"
                    zipcodeKey="zipcode"
                    countryValue={values.countryId}
                    provinceValue={values.provinceId}
                    cityValue={values.cityId}
                  />
                  <Col span={12}>
                    <NationalityField
                      setFieldValue={setFieldValue}
                      value={values.nationalityId}
                      name="nationalityId"
                    />
                  </Col>
                  <Col span={12}>
                    <InputField
                      title="Street"
                      type="text"
                      name="street"
                      placeholder="Enter Street"
                    />
                  </Col>
                  <Col span={12}>
                    <InputField
                      title="Building"
                      type="text"
                      name="building"
                      placeholder="Enter Building"
                    />
                  </Col>

                  <Col span={24}>
                    <AttachmentUpload
                      accept="image/*"
                      name="profilePic"
                      placeholder="Upload Picture"
                      newAttachments={
                        values.profilePic ? [values.profilePic] : []
                      }
                      attachments={[patient.profilePicUrl as string]}
                      setFieldValue={setFieldValue}
                    />
                  </Col>
                </Row>
                {!isChild && (
                  <div className="patient-form__responsible-person-request-wrapper">
                    <Checkbox
                      checked={values.enableResponsiblePerson}
                      onChange={handleEnableResponsiblePerson(
                        values,
                        setValues
                      )}
                    >
                      Do you want to add/link a responsible person?
                    </Checkbox>
                  </div>
                )}
              </div>
              {(isChild || values.enableResponsiblePerson) && (
                <div className="patient-form__responsible-person-wrapper">
                  <ResponsiblePersonFields
                    values={values}
                    setFieldValue={setFieldValue}
                    genderOptions={genderOptions}
                  />
                </div>
              )}
              {/* <div className="patient-form__custom-form-wrapper">
                <h5 className="text-secondary">
                  Custom form applicable for the interaction?
                </h5>
                <Row gutter={[20, 0]} align="middle">
                  <Col span={24}>
                    <DropdownField
                      title="Form Type"
                      name="formType"
                      options={formTypeOptions}
                      value={values.formType}
                      setFieldValue={setFieldValue}
                      placeHolder="Select Form Type"
                    />
                  </Col>
                  <Col span={24}>
                    <Button type="primary">
											Request patient to fill
										</Button>
										<Button
											className="ml-2 secondary-btn"
											htmlType="button"
										>
											Complete it manually 
										</Button>
                    <Radio.Group
                      options={customFormOptions}
                      defaultValue={customFormOptions[0].value}
                      optionType="button"
                      buttonStyle="solid"
                    />
                  </Col>
                </Row>
              </div> */}
              {patient?.id && HAS_CUSTOM_FORM_VIEW_ACCESS ? (
                <CustomFormRequestForm
                  disableRequestToFill
                  resourceId={patient?.id}
                  customFormTypeEnum={CustomFormTypeEnum.PATIENT}
                  onSuccess={() => {}}
                />
              ) : null}
              <div className="patient-form__submit-wrapper text-right">
                <Button type="default" onClick={onCancel}>
                  Cancel
                </Button>
                <Button
                  type="primary"
                  className="ml-2"
                  htmlType="submit"
                  loading={formLoading}
                  disabled={!isValid || formLoading}
                >
                  Save
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default CorporateContainer(PatientForm);
