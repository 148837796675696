import React, { useEffect, useState } from "react";
import { Form, Formik, FormikValues } from "formik";
import { Button, Col, Row, Switch, TimePicker } from "antd";
import InputField from "../../../shared/components/InputField";
import DropdownField from "../../../shared/components/DropdownField";
import { Practice, Schedule } from "../../../models/Practice/practice.model";
import { practiceFormValidation } from "./practiceFormValidation";
import "./practiceForm.scss";
import {
  IDropdownOptions,
  MetaService,
} from "../../../services/Meta/meta.service";
import { IsdCode } from "../../../models/IsdCode/isdCode.model";
import { User } from "../../../models/User/user.model";
import UserService from "../../../services/User/user.service";
import PracticeService from "../../../services/Practice/practice.service";
import MobileNumberField from "../../../shared/components/MobileNumberField";
import DatePickerField from "../../../shared/components/DatePickerField";
import moment from "moment";

const weekDays = [
  {
    weekday: "Sunday",
    isOpen: false,
  },
  {
    weekday: "Monday",
    isOpen: true,
  },
  {
    weekday: "Tuesday",
    isOpen: true,
  },
  {
    weekday: "Wednesday",

    isOpen: true,
  },
  {
    weekday: "Thursday",
    isOpen: true,
  },
  {
    weekday: "Friday",
    isOpen: true,
  },
  {
    weekday: "Saturday",
    isOpen: false,
  },
];

interface PracticeFormProps {
  practice?: Practice;
  onCancel: () => void;
  onSuccess: (practice: Practice) => void;
  onEditSuccess?: (practice: Practice) => void;
}

function PracticeForm({
  practice,
  onSuccess,
  onEditSuccess,
  onCancel,
}: PracticeFormProps) {
  const [isdCodeOptions, setIsdCodeOptions] = useState<IDropdownOptions[]>([]);

  const [formValues, setFormValues] = useState<Practice>({
    ...new Practice(),
    schedule: weekDays,
  });

  const [formLoading, setFormLoading] = useState(false);

  const [timezoneOptions, setTimezoneOptions] = useState<any>([]);

  const getTimeZone = async () => {
    const timeZones = await MetaService.getTimeZone();
    const timeZoneOptions = timeZones.map(({ name, timeZone, id }) => ({
      label: `${timeZone}(${name})`,
      value: `${id}`,
    }))

    setTimezoneOptions(timeZoneOptions);
  };

  useEffect(() => {
    getTimeZone();
  }, []);

  const handleSubmit = (values: Practice) => {
    values?.schedule.forEach((value) => {
      if (!value.isOpen) {
        delete value?.startTime;
        delete value?.endTime;
      }
    });

    const practice = Object.assign(new Practice(), values);

    setFormLoading(true);
    PracticeService[practice?.id ? "updatePractice" : "createPractice"](
      practice,
      (updatedPractice: Practice) => {
        if (practice?.id) {
          onEditSuccess && onEditSuccess(updatedPractice);
          return;
        }
        onSuccess(updatedPractice);
      },
      () => {},
      () => {
        setFormLoading(false);
      }
    );
  };

  useEffect(() => {
    MetaService.fetchIsdCodes(
      (isdCodes: IsdCode[]) => {
        setIsdCodeOptions(
          isdCodes.map((isdCode: IsdCode) => ({
            label: isdCode.isdCode,
            value: isdCode.id,
          }))
        );
      },
      () => {},
      () => {}
    );
  }, []);

  useEffect(() => {
    if (practice?.id) {
      setFormValues(practice);
    }
  }, [practice]);

  /* useEffect(() => {
    const scheduleinitialValue = weekDays.map((day) => {
      return {
        weekday: day?.value,
        isOpen: day?.value != "sunday" && day?.value != "saturday",
      };
    });
    setFormValues((practice) => ({
      ...practice,
      schedule: scheduleinitialValue,
    }));
  }, []); */

  return (
    <div className="practice-form">
      <Formik
        initialValues={formValues}
        onSubmit={handleSubmit}
        enableReinitialize
        validationSchema={practiceFormValidation}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          errors,
          isValid,
          dirty,
          setFieldValue,
        }) => {
          return (
            <Form>
              <Row gutter={[20, 0]}>
                <Col span={12}>
                  <InputField
                    title="Practices Name"
                    type="text"
                    name="name"
                    placeholder="Enter Practice name"
                  />
                </Col>
                <Col span={12}>
                  <InputField
                    title="Location"
                    type="text"
                    name="location"
                    placeholder="Enter Location"
                  />
                </Col>
                <Col span={12}>
                  <InputField
                    title="Email"
                    type="email"
                    name="email"
                    placeholder="Enter email "
                  />
                </Col>

                <MobileNumberField
                  isdCodeKeyName="isdCode"
                  name="mobileNumber"
                  setFieldValue={setFieldValue}
                  value={values.isdCode}
                />

                <Col span={12}>
                  <InputField
                    title="Contact person"
                    type="text"
                    name="contactPerson"
                    placeholder="Enter Contact person "
                  />
                </Col>

                <Col span={12}>
                  <DropdownField
                    title="Time zone"
                    name="timeZone"
                    options={timezoneOptions}
                    value={values.timeZone}
                    setFieldValue={setFieldValue}
                    placeHolder="Select Time Zone"
                  />
                </Col>
                <Col span={12}>
                  <InputField
                    title="Chair"
                    type="number"
                    name="chairCount"
                    placeholder="Enter no of chairs"
                  />
                </Col>
                <Col span={12}>
                  <InputField
                    title="Lab"
                    type="number"
                    name="labCount"
                    placeholder="Enter no of labs"
                  />
                </Col>
                <Col span={12}>
                  <InputField
                    title="Room"
                    type="number"
                    name="roomCount"
                    placeholder="Enter no of rooms"
                  />
                </Col>
                <Col span={12}></Col>
                <Col span={24}>
                  <h4>Practice availibility</h4>
                  {weekDays?.map((day, index) => {
                    return (
                      <Row>
                        <Col span={4} className="mt-5">
                          <span className="practice-form__day-label">
                            {day?.weekday}
                          </span>
                        </Col>
                        <Col span={4} className="text-left">
                          <Switch
                            className="practice-form__day-label "
                            key={day?.weekday}
                            checked={values?.schedule[index]?.isOpen}
                            onChange={(value) => {
                              setFieldValue(
                                `schedule[${index}].weekday`,
                                day?.weekday?.toLowerCase()
                              );
                              setFieldValue(`schedule[${index}].isOpen`, value);
                            }}
                          />
                        </Col>
                        {values?.schedule[index]?.isOpen ? (
                          <Col span={4}>
                            <DatePickerField
                              name={`schedule[${index}].startTime`}
                              setFieldValue={setFieldValue}
                              type="time"
                              key={day?.weekday}
                              format={"hh:mm A"}
                              defaultValue={values?.schedule[index]?.startTime}
                            />
                          </Col>
                        ) : (
                          ""
                        )}

                        {values?.schedule[index]?.isOpen ? (
                          <Col span={4} className="ml-5">
                            {" "}
                            <DatePickerField
                              name={`schedule[${index}].endTime`}
                              setFieldValue={setFieldValue}
                              type="time"
                              format={"hh:mm A"}
                              key={day?.weekday}
                              defaultValue={values?.schedule[index]?.endTime}
                            />
                          </Col>
                        ) : (
                          ""
                        )}
                      </Row>
                    );
                  })}
                </Col>
              </Row>
              <div className="practice-form__submit-wrapper text-right">
                <Button onClick={onCancel} type="default">
                  Cancel
                </Button>
                <Button
                  type="primary"
                  className="ml-2"
                  htmlType="submit"
                  loading={formLoading}
                  disabled={!isValid || !dirty || formLoading}
                >
                  Save
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default PracticeForm;

/* Yup.array()
    .of(
      Yup.object().shape({
        dayOfWeek: Yup.string(),
        checked: Yup.boolean(),
      })
    ) */
