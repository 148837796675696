import * as Yup from "yup";

export const contractServiceFormValidation = Yup.object().shape({
  // name: Yup.string().required("Contract name is required!"),
  doctorProfileId: Yup.string().required("Please select a doctor!"),
  patientProfileId: Yup.string().required("Please select a patient!"),
  // contractServices: Yup.array().of(
  //   Yup.object().shape({
  //     serviceId: Yup.string().required("Please select any service!"),
  //     // treatmentPlanTemplateId: Yup.number().required("Please select a Treatment Execution Template!"),
  //     durationInMonths: Yup.number()
  //       .required("Duration is required!")
  //       .nullable()
  //       .min(1, "Value should be atleast 1!"),
  //     maxNoToUtilise: Yup.string()
  //       .min(1, "Value should be atleast 1!")
  //       .required("Max no of utilise is required!"),
  //   })
  // ),
});
