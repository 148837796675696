import Form from "antd/lib/form/Form";
import { Formik } from "formik";
import React, { FC, Fragment, useEffect, useState } from "react";
import { StatementTemplate } from "../../../../../models/Statement/statement.model";
import { NoteTemplate } from "../index";
import { Button, Col, Row } from "antd";
import InputField from "../../../../../shared/components/InputField";
import { NoteTemplateService } from "../../../../../services/NoteTemplate/noteTemplate.service";
import { StatementTemplateCategory } from "../../../../../models/StatementCategory/statementCategory.model";
import DropdownField from "../../../../../shared/components/DropdownField";
import "./statementTemplateForm.scss";
import { XRayTemplate } from "../../../../../models/XRayTemplate/xRayTemplate.model";
import { StateFormValidation } from "./StatementFormValidation";
import { XRayFormValidation } from "./xRayFormValidation";

interface NoteTemplateFormProps {
	onClose?: () => void;
	formValue?: StatementTemplate | XRayTemplate;
	type: NoteTemplate;
	onUpdateHandler: (
		value: StatementTemplate | XRayTemplate,
		type: "add" | "update"
	) => void;
}

const NoteTemplateForm: FC<NoteTemplateFormProps> = (props) => {
	const { type, formValue, onUpdateHandler, onClose } = props;

	const [loader, setLoader] = useState(false);

	const [statementCategories, setStatementCategories] = useState<
		StatementTemplateCategory[]
	>([]);

	const [loading, setLoading] = useState<{
		dropdown?: boolean;
		form?: boolean;
	}>({});

	useEffect(() => {
		setLoading((loaders) => ({
			...loaders,
			dropdown: true,
		}));
		NoteTemplateService[
			type === "Statement"
				? "getStatementCategories"
				: "fetchXRayCategory"
		](
			(statementCategories) => {
				setStatementCategories(statementCategories);
			},
			() => {},
			() => {
				setLoading((loaders) => ({
					...loaders,
					dropdown: false,
				}));
			}
		);
	}, []);

	const submitStatement = (value: StatementTemplate) => {
		setLoader(true);
		const statementTemplate = Object.assign(new StatementTemplate(), value);
		NoteTemplateService[formValue ? "updateStatement" : "createStatement"](
			statementTemplate,
			(statementTemplate) => {
				onUpdateHandler(
					statementTemplate,
					formValue ? "update" : "add"
				);
			},
			() => {},
			() => {
				setLoader(false);
			}
		);
	};

	const submitXRayTemplate = (value: XRayTemplate) => {
		setLoader(true);
		const xRayTemplate = Object.assign(new XRayTemplate(), value);
		NoteTemplateService[
			formValue ? "updateXRayTemplate" : "createXRayTemplate"
		](
			xRayTemplate,
			(statementTemplate) => {
				onUpdateHandler(
					statementTemplate,
					formValue ? "update" : "add"
				);
			},
			() => {},
			() => {
				setLoader(false);
			}
		);
	};

	const handleSubmit = (values: StatementTemplate | XRayTemplate) => {
		if (type === "Statement") {
			submitStatement(values as StatementTemplate);
			return;
		}
		submitXRayTemplate(values as XRayTemplate);
	};

	return (
		<div className="statement-template__container">
			<Formik
				enableReinitialize
				validationSchema={
					type === "Statement"
						? StateFormValidation
						: XRayFormValidation
				}
				initialValues={
					formValue
						? formValue
						: type === "Statement"
						? new StatementTemplate()
						: new XRayTemplate()
				}
				onSubmit={handleSubmit}
			>
				{({ values, isValid, dirty, setFieldValue, handleSubmit }) => (
					<Form>
						<div className="statement-template__wrapper">
							<Row>
								<Col span={12}>
									<InputField
										type="text"
										title="Template type"
										name="name"
										value={type}
										disabled
									/>
								</Col>
							</Row>
							{type === "Statement" ? (
								<Fragment>
									<Row>
										<Col span={12}>
											<DropdownField
												title="Category"
												name="statementTemplateCategoryId"
												setFieldValue={setFieldValue}
												options={statementCategories}
												loading={loading["dropdown"]}
												value={
													(values as StatementTemplate)
														?.statementTemplateCategoryId
												}
											/>
										</Col>
									</Row>
									<Row>
										<Col span={12}>
											<InputField
												type="text"
												title="Sub-Category"
												name="subCategory"
											/>
										</Col>
									</Row>
								</Fragment>
							) : (
								<Row>
									<Col span={12}>
										<DropdownField
											title="Category"
											name="xRayTemplateCategoryId"
											setFieldValue={setFieldValue}
											options={statementCategories}
											loading={loading["dropdown"]}
											value={
												(values as XRayTemplate)
													?.xRayTemplateCategoryId
											}
											note="Type in and enter to create a new category"
											showSearch
											onKeyDown={(value) => {
												if (value?.keyCode === 13) {
													setLoading((loaders) => ({
														...loaders,
														dropdown: true,
													}));
													const category = {
														name:
															value?.target
																?.value,
													};
													NoteTemplateService.createXRayCategory(
														category,
														(category) => {
															setStatementCategories(
																(
																	categories
																) => [
																	...categories,
																	category,
																]
															);
															setFieldValue(
																"xRayTemplateCategoryId",
																category.id
															);
														},
														() => {},
														() => {
															setLoading(
																(loaders) => ({
																	...loaders,
																	dropdown: false,
																})
															);
														}
													);
												}
											}}
										/>
									</Col>
								</Row>
							)}

							<Row>
								<Col span={24}>
									<InputField
										type="textarea"
										title="Description"
										name="description"
										rows={8}
									/>
								</Col>
							</Row>
						</div>
						<div className="statement-template__controller">
							<Button htmlType="reset" onClick={onClose}>Cancel</Button>
							<Button
								type="primary"
								loading={loader}
								disabled={!isValid || !dirty}
								onClick={() => handleSubmit()}
							>
								Save
							</Button>
						</div>
					</Form>
				)}
			</Formik>
		</div>
	);
};

export default NoteTemplateForm;
