export enum AppointmentStatus {
  CANCELLED = "cancelled",
  COMPLETED = "completed",
  CONFIRMED = "confirmed",
  UN_CONFIRMED = "not confirmed",
  IN_PROGRESS = "in_progress",
  UPCOMING = "upcoming",
  SEATED_IN_CHAIR = "seated_in_chair",
  WAITING_ROOM = "waiting_room",
  SCAN_FACILITY = "scan_facility",
  VIRTUAL_VISIT = "virtual_visit",
}
