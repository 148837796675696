import { Col, Row } from "antd";
import React, { FC } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Service } from "../../../../models/Service/service.model";
import ServiceService from "../../../../services/Service/service.service";
import AppLoader from "../../../../shared/components/AppLoader";
import "./serviceDetail.scss";

interface ServiceDetailProps {
  serviceId?: number;
}

const items = [
  {
    title: "Service name",
    key: "name",
  },
  {
    title: "Sub Category",
    key: "subCategory",
  },
  {
    title: "Status",
    key: "status",
  },
  {
    title: "Default Treatment Plan Template",
    key: "defaultTreatmentPlanTemplateName",
  },
];

const ServiceDetail: FC<ServiceDetailProps> = (props) => {
  const { serviceId } = props;

  const [service, setService] = useState<Service>();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    ServiceService.showService(
      serviceId?.toString() ?? "",
      (service: Service) => {
        setService(service);
      },
      () => {},
      () => {
        setLoading(false);
      }
    );
  }, []);

  if (loading) {
    return <AppLoader loading />;
  }

  return (
    <div className="service-detail">
      <Row>
        {items.map((item) => (
          <Col span={12}>
            <p className="title">{item.title}</p>
            <p className="value">{service && (service as any)[item.key]}</p>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default ServiceDetail;
