import { serializable, alias, object, list, primitive } from "serializr";
import { SupportStatus } from "../../enums/supportStatus.enum";

export class Support {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("corporate_id", primitive()))
  corporateId?: number;

  @serializable(alias("corporate_name", primitive()))
  corporateName?: string;

  @serializable(alias("practice_id", primitive()))
  practiceId?: number;

  @serializable(alias("practice_name", primitive()))
  practiceName?: string;

  @serializable(alias("issue_type", primitive()))
  issueType?: string;

  @serializable(alias("issue_name", primitive()))
  issueName?: string;

  @serializable(alias("description", primitive()))
  description?: string;

  @serializable(alias("patient_profile_id", primitive()))
  patientProfileId?: number;

  @serializable(alias("patient_first_name", primitive()))
  patientFirstName?: string;

  @serializable(alias("patient_last_name", primitive()))
  patientLastName?: string;

  @serializable(alias("staff_user_profile_id", primitive()))
  staffUserProfileId?: number;

  @serializable(alias("staff_first_name", primitive()))
  staffFirstName?: string;

  @serializable(alias("staff_last_name", primitive()))
  staffLastName?: string;

  @serializable(alias("raised_by_user_profile_id", primitive()))
  raisedByUserProfileId?: number;

  @serializable(alias("raised_by_first_name", primitive()))
  raisedByFirstName?: string;

  @serializable(alias("raised_by_last_name", primitive()))
  raisedByLastName?: string;

  @serializable(alias("assigned_to_admin_id", primitive()))
  assignedToAdminId?: string;

  @serializable(alias("assigned_to_first_name", primitive()))
  assignedToFirstName?: string;

  @serializable(alias("assigned_to_last_name", primitive()))
  assignedToLastName?: string;

  @serializable(alias("created_at", primitive()))
  createdAt?: string;

  @serializable(alias("due_date", primitive()))
  dueDate?: string;

  @serializable(alias("priority", primitive()))
  priority?: string;

  @serializable(alias("patientApplicable", primitive()))
  patientApplicable: string = "yes";

  @serializable(alias("status", primitive()))
  status: SupportStatus = SupportStatus.PENDING;
}
