import { HubConnection } from "@microsoft/signalr";
import { deserialize } from "serializr";
import { SIGNAL_R_NOTIFICATION } from "../../enums/signalRNotification.enum";
import axiosInstance from "../../interceptor/axiosInstance";
import { Appointment } from "../../models/Appointment/appointment.model";
import { DentalObject } from "../../models/DentalObject/dentalObject.model";
import { Interaction } from "../../models/Interaction/interaction.model";
import { Notification } from "../../models/Notification/notification.model";
import { Schedule } from "../../models/Schedule/schedule.model";
import { Service } from "../../models/Service/service.model";
import { TermOfService } from "../../models/TermsOfService/termsOfservice.model";
import { User } from "../../models/User/user.model";
import ApiRoutes from "../../routes/routeConstants/apiRoutes";
class NotificationService {
  static parseNotification(data: any) {
    const notification = deserialize(Notification, data);
    return notification;
  }

  static appointment(
    connection: HubConnection,
    onAppointment: (notification: Notification) => void
  ) {
    connection.on(SIGNAL_R_NOTIFICATION.APPOINTMENT, (data) => {
      onAppointment(NotificationService.parseNotification(data));
    });
  }

  static interaction(
    connection: HubConnection,
    onInteraction: (notification: Notification) => void
  ) {
    connection.on(SIGNAL_R_NOTIFICATION.INTERACTION, (data) => {
      const interaction = deserialize(Interaction, data?.interaction);
      onInteraction(NotificationService.parseNotification(data));
    });
  }

  static schedule(
    connection: HubConnection,
    onSchedule: (notification: Notification) => void
  ) {
    connection.on(SIGNAL_R_NOTIFICATION.SCHEDULE, (data) => {
      const schedule = deserialize(Schedule, data?.schedule);
      onSchedule(NotificationService.parseNotification(data));
    });
  }

  static reviewNote(
    connection: HubConnection,
    onReviewNote: (notification: Notification) => void
  ) {
    connection.on(SIGNAL_R_NOTIFICATION.REVIEW_NOTES, (data) => {
      const reviewNote = deserialize(Interaction, data?.reviewNote);
      onReviewNote(NotificationService.parseNotification(data));
    });
  }

  static service(
    connection: HubConnection,
    onService: (notification: Notification) => void
  ) {
    connection.on(SIGNAL_R_NOTIFICATION.SERVICE, (data) => {
      const service = deserialize(Service, data?.service);
      onService(NotificationService.parseNotification(data));
    });
  }

  static termOfService(
    connection: HubConnection,
    onTermOfService: (notification: Notification) => void
  ) {
    connection.on(SIGNAL_R_NOTIFICATION.TERM_OF_SERVICE, (data) => {
      const termOfService = deserialize(TermOfService, data.termOfService);
      onTermOfService(NotificationService.parseNotification(data));
    });
  }

  static staff(
    connection: HubConnection,
    onStaff: (notification: Notification) => void
  ) {
    connection.on(SIGNAL_R_NOTIFICATION.STAFF, (data) => {
      const staff = deserialize(User, data?.staff);
      onStaff(NotificationService.parseNotification(data));
    });
  }

  static dentalObject(
    connection: HubConnection,
    onDentalObject: (notification: Notification) => void
  ) {
    connection.on(SIGNAL_R_NOTIFICATION.DENTAL_OBJECT, (data) => {
      const dentalObject = deserialize(DentalObject, data?.dentalObject);
      onDentalObject(NotificationService.parseNotification(data));
    });
  }

  static async getNotification(
    onSuccess: (notifications: Notification[]) => void
  ) {
    try {
      const { data } = await axiosInstance.get(ApiRoutes.NOTIFICATION);
      const notifications = deserialize(
        Notification,
        data?.notifications as any[]
      );
      onSuccess(notifications);
    } catch (error) {}
  }
}

export default NotificationService;
