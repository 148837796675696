import { Drawer, Modal, Popover } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { EventProps, Event } from "react-big-calendar";
import { useParams } from "react-router-dom";
import AssignAppointmentSlotForm from "..";
import { ResponsibilityTypes } from "../../../../../enums/responsebily.enum";
import { AppointmentTemplateSchedules } from "../../../../../models/AppointmentTemplateSchedules/appointmentTemplateSchedules.model";
import AppoitmentTemplateService from "../../../../../services/AppointmentTemplate/appointmentTemplate.service";
import useResponsibilities from "../../../../../shared/hooks/Responsibilities/useResponsibilities";

import "./customEvent.scss";

interface CustomEventProps {
  props: EventProps<
    Event & { schedule: AppointmentTemplateSchedules; resourceId: number }
  >;
  onDeleteSlot: (resourceId: number, slotId: number) => void;
  onUpdateSlot?: (
    resourceId: number,
    oldSlotId: number,
    slots: AppointmentTemplateSchedules[]
  ) => void;
}

const CustomEvent = (props: CustomEventProps) => {
  const params = useParams<{ templateId: string }>();
  const { onDeleteSlot, onUpdateSlot } = props;

  const { hasAccess } = useResponsibilities();

  const { event } = props.props;
  const [popoverVisibility, setPopoverVisibility] = useState(false);
  const [editFormVisibility, setEditFormVisibility] = useState(false);

  const HAS_APPOINTMENT_TEMPLATE_EDIT_ACCESS = hasAccess(
    ResponsibilityTypes.APPOINTMENT_TEMPLATE_EDIT
  );

  const HAS_STATUS_APPOINTMENT_TYPE_EDIT_ACCESS = hasAccess(
    ResponsibilityTypes.STATUS_APPOINTMENT_TYPE_EDIT
  );

  const handlePopoverVisibility = () =>
    setPopoverVisibility((visible) => !visible);

  const toggleEditFormVisibility = () =>
    setEditFormVisibility((visible) => !visible);

  const handleDelete = () => {
    AppoitmentTemplateService.deleteAppointmentTemplateSlot(
      params?.templateId ?? "",
      event?.schedule?.id ? String(event?.schedule?.id) : "",
      () => {
        onDeleteSlot(Number(event?.resourceId), Number(event?.schedule?.id));
      },
      () => {},
      () => {}
    );
  };

  const handleUpdate = () => {};

  const getPopoverContent = () => (
    <div className="custom-event-template__popover">
      {HAS_STATUS_APPOINTMENT_TYPE_EDIT_ACCESS && <span
        onClick={() => {
          if(!HAS_STATUS_APPOINTMENT_TYPE_EDIT_ACCESS)  return;
          
          toggleEditFormVisibility();
          handlePopoverVisibility();
        }}
      >
        Change Appointment Type
      </span>}
      {HAS_APPOINTMENT_TEMPLATE_EDIT_ACCESS && (
        <span
          onClick={() => {
            if (!HAS_APPOINTMENT_TEMPLATE_EDIT_ACCESS) return;

          handlePopoverVisibility();
          Modal.confirm({
            centered: true,
            title: `Are you sure, do you want to delete ${
              event.title
            } appointment from ${moment(event.start).format(
              "hh:mm"
            )} - ${moment(event.end).format("hh:mm")} slot`,
            onOk: handleDelete,
          });
        }}
      >
        Delete Appointment
      </span>
      )}
    </div>
  );

  return (
    <>
      <Popover
        content={getPopoverContent}
        destroyTooltipOnHide
        onVisibleChange={handlePopoverVisibility}
        style={{ width: 500 }}
        visible={(HAS_STATUS_APPOINTMENT_TYPE_EDIT_ACCESS || HAS_APPOINTMENT_TEMPLATE_EDIT_ACCESS) && popoverVisibility}
        trigger="contextMenu"
      >
        <div
          onContextMenu={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
          className="custom-event-template"
        >
          <div className="custom-event-template__event text-capitalize">
            {event.title}
          </div>
        </div>
      </Popover>
      <Drawer
        title="Change Appointment Type"
        visible={editFormVisibility}
        destroyOnClose
        onClose={toggleEditFormVisibility}
        width={"60vw"}
        className="appointmentTemplate"
      >
        <AssignAppointmentSlotForm
          existingFormValues={{
            startTime: event?.start
              ? moment.utc(event?.start.toString()).format("HH:mm:ss")
              : "",
            endTime: event?.end
              ? moment.utc(event?.end.toString()).format("HH:mm:ss")
              : "",
            appointmentTypeId: event?.schedule?.appointmentTypeId,
          }}
          slotId={event?.schedule?.id ? String(event?.schedule?.id) : ""}
          handleAppointmentSlotVisibility={toggleEditFormVisibility}
          currentResourceId={event?.resourceId ? String(event?.resourceId) : ""}
          templateId={Number(params.templateId)}
          onUpdateSlot={onUpdateSlot}
        />
      </Drawer>
    </>
  );
};

export default CustomEvent;
