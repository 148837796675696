import moment, { Moment } from "moment";

export const timeDifference = (startTime: Moment, endTime: Moment) => {
  const duration = moment.duration(startTime.diff(endTime));
  const hours = Math.floor(duration.asHours());
  const minutes = Math.floor(duration.asMinutes());

  if (hours) {
    return `${hours} hours ago`;
  }
  if (minutes) {
    return `${minutes} min ago`;
  }
  return "Now";
};

export const formatTime = (dateString?: string | Moment) =>
  moment(dateString).format("hh:mma");

export const isActualTimeMinimum = (tempTime: string, actualDate?: string) => {
  if (!actualDate) return false;

  const actualTime = formatTime(actualDate);

  return moment(actualTime, "hh:mma").isBefore(moment(tempTime, "hh:mma"));
};
export const formatTimestamp = (
  timestamp?: string | Moment,
  FORMAT = "YYYY-MM-DD"
) => moment(timestamp)?.format(FORMAT);

export const compareUTCTime = (
  time1?: string | Moment,
  time2?: string | Moment
) => {
  const FORMAT = "hh:mma";
  const formattedTime1 = moment.utc(time1).format(FORMAT);
  const formattedTime2 = moment.utc(time2).format(FORMAT);

  return moment(formattedTime1, FORMAT).diff(moment(formattedTime2, FORMAT));
};

export const manipulateUTCTime = (
  utcTime: string,
  timeToManipulate: moment.MomentSetObject,
  FORMAT = "YYYY-MM-DDTHH:mm:ss"
) => moment.utc(utcTime).set(timeToManipulate).format(FORMAT);

export const isActualTimeMaximum = (tempTime: string, actualDate?: string) => {
  if (!actualDate) return false;

  const actualTime = formatTime(actualDate);

  return moment(actualTime, "hh:mma").isAfter(moment(tempTime, "hh:mma"));
};
