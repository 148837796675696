import { useEffect, useState } from "react";
import { convertTypeAcquisitionFromJson } from "typescript";
import { TabsEnum } from "../../../enums/tabs.enum";
import { FilterParams } from "../../../models/FilterParams/filterParams.model";
import { Metrics } from "../../../models/Metrics/metrics.model";
import { Suggestion } from "../../../models/Suggestion/suggestion.model";
import { User } from "../../../models/User/user.model";
import MetricsService from "../../../services/Metrics/metrics.service";
import UserService, { UserOptions } from "../../../services/User/user.service";
import { SelectProp } from "../../Types/option";

export const useMetrics = (metricsValue: string, params?: FilterParams) => {
  const [metrics, setMetrics] = useState<Metrics>();
  const [loading, setLoading] = useState(false);

  const handleFetchMetrics = () => {
    setLoading(true);
    MetricsService.fetchMetrics(
      metricsValue,
      (metrics: Metrics) => {
        setMetrics(metrics);
      },
      () => {},
      () => {
        setLoading(false);
      },
      params
    );
  };

  const handleFetchMetricsWithPractice = () => {
    setLoading(true);
    MetricsService.fetchMetricsWithPractice(
      metricsValue,
      (metrics: Metrics) => {
        setMetrics(metrics);
      },
      () => {},
      () => {
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    if (
      metricsValue == TabsEnum.APPOINTMENTS ||
      metricsValue == TabsEnum.TREATMENT_PLAN_TEMPLATES ||
      metricsValue == TabsEnum.RESOURCES
    )
      handleFetchMetricsWithPractice();
    else handleFetchMetrics();
  }, []);

  return { metrics, setMetrics, handleFetchMetricsWithPractice, handleFetchMetrics, loading,  };
};
