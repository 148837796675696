import { CreateReducer } from "../../shared/utils/createReducer";
import { TreatmentPlanTemplate } from "../../models/TreatmentPlanTemplate/treatmentPlanTemplate.model";
import { SET_CURRENT_TREATMENT_PLAN, SET_TREATMENT_PLAN_TEMPLATE_LOADER, SET_CURRENT_APPOINTMENT } from "../definitions/treatmentPlanConstants";

interface TreatmentPlanProps {
    currentTreatmentPlan?: TreatmentPlanTemplate;
    currentAppointment: number;
    loading: boolean,
}

export interface TreatmentPlanReducerProps extends TreatmentPlanProps {
  setCurrentTreatmentPlan: (currentTreatmentPlan?: TreatmentPlanTemplate) => void;
  setCurrentAppointment: (currentAppointment?: number) => void;
}

const initState: TreatmentPlanProps = {
  currentTreatmentPlan: undefined,
  currentAppointment: 0,
  loading: false,
};

const TreatmentPlanReducer = CreateReducer(initState, {
  [SET_CURRENT_TREATMENT_PLAN](state: TreatmentPlanProps, action: any): TreatmentPlanProps {
    const { currentTreatmentPlan } = action.payload;
    return {
      ...state,
      currentTreatmentPlan,
    };
  },
  [SET_CURRENT_APPOINTMENT] (state: TreatmentPlanProps, action: any): TreatmentPlanProps {
    return {
      ...state,
      currentAppointment: action.payload.currentAppointment
    }
  },
  [SET_TREATMENT_PLAN_TEMPLATE_LOADER](state: TreatmentPlanProps): TreatmentPlanProps {
    return {
      ...state,
    }
  }
});

export default TreatmentPlanReducer;
