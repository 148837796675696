import { Button, Checkbox, Col, Radio, Row } from "antd";
import { DropDownProps } from "antd/lib/dropdown";
import { Formik, Form } from "formik";
import { debounce } from "lodash";
import React, { FC } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { PaginationDetails } from "../../../models/Pagination/pagination.model";
import { Practice } from "../../../models/Practice/practice.model";
import { Support } from "../../../models/Support/support.model";
import { IDropdownOptions } from "../../../services/Meta/meta.service";
import PracticeService from "../../../services/Practice/practice.service";
import SupportService from "../../../services/Support/support.service";
import DropdownField from "../../../shared/components/DropdownField";
import InputField from "../../../shared/components/InputField";
import RadioInputField from "../../../shared/components/RadioInputField";
import Searchbar from "../../../shared/components/Searchbar";
import { usePatients } from "../../../shared/hooks/Patient/usePatient";
import { useDoctors } from "../../../shared/hooks/User/useDoctors";
import {
  issueTypeOptions,
  priorityOptions,
} from "../../../shared/options/options";
import { SelectProp } from "../../../shared/Types/option";
import { getDropdownOptions } from "../../../shared/utils/optionHelper";
import { uniqueObjectValue } from "../../../shared/utils/uniqueObjectValue";
import "./supportForm.scss";

const patientApplicableOptions = [
  {
    label: "Yes",
    value: "yes",
  },
  {
    label: "No",
    value: "no",
  },
];

interface SupportFormProps {
  closeHandler: () => void;
  successHandler: (support: Support) => void;
}

const SupportForm: FC<SupportFormProps> = (props) => {
  const { closeHandler, successHandler } = props;
  const [pagination, setPagination] = useState<PaginationDetails>(
    new PaginationDetails()
  );

  const [practiceOptions, setPracticeOptions] = useState<SelectProp[]>([]);

  const patientOptions = usePatients("option");

  const staffOptions = useDoctors("option");
  const handleFetchPractice = debounce(() => {
    if (pagination?.nextPage)
      PracticeService.fetchPractices(
        (practices: Practice[], pagination: PaginationDetails) => {
          setPagination(pagination);
          const data = getDropdownOptions(practices, "name", "id");
          setPracticeOptions(uniqueObjectValue(practiceOptions, data));
        },
        () => {},
        () => {},
        { page: pagination?.nextPage }
      );
  }, 200);

  useEffect(() => {
    handleFetchPractice();
  }, []);

  const searchbarValue = (patientId?: string) => {
    const [patient] = (patientOptions as SelectProp[]).filter(
      (patient) => patient?.key === parseInt(patientId as string)
    );
    return patient?.value;
  };

  const handleSubmit = (values: Support) => {
    const support = Object.assign(new Support(), values);
    SupportService.createSubscription(
      support,
      (support) => {
        successHandler(support);
      },
      () => {}
    );
  };

  const formikProps = {
    initialValues: new Support(),
    onSubmit: handleSubmit,
  };

  return (
    <div className="support-form">
      <Formik {...formikProps}>
        {({ values, setFieldValue }) => (
          <Form>
            <div className="content__wrapper">
              <Row gutter={[20, 10]}>
                <Col span={12}>
                  <InputField title="Issue Name" type="text" name="issueName" />
                </Col>
                <Col span={12}>
                  <DropdownField
                    title="Issue Type"
                    name="issueType"
                    options={issueTypeOptions}
                    setFieldValue={setFieldValue}
                  />
                </Col>
                <Col span={12}>
                  <DropdownField
                    title="Priority"
                    name="priority"
                    options={priorityOptions}
                    setFieldValue={setFieldValue}
                  />
                </Col>
                <Col span={12}>
                  <DropdownField
                    title="Practice"
                    name="practiceId"
                    onPopupScroll={handleFetchPractice}
                    options={practiceOptions}
                    setFieldValue={setFieldValue}
                  />
                </Col>
                <Col span={12}>
                  <DropdownField
                    title="Staff"
                    name="staffUserProfileId"
                    options={staffOptions}
                    setFieldValue={setFieldValue}
                  />
                </Col>
                <Col span={12} className="patient-applicable">
                  <RadioInputField
                    title="Patient application for this issue?"
                    name="patientApplicable"
                    options={patientApplicableOptions}
                    value={values.patientApplicable}
                    setFieldValue={setFieldValue}
                  />
                </Col>
                {values.patientApplicable === "yes" ? (
                  <Col span={12}>
                    <Searchbar
                      title="Patient"
                      fullWidth
                      options={patientOptions as SelectProp[]}
                      value={searchbarValue(
                        values?.patientProfileId?.toString()
                      )}
                      selectHandler={(value) => {
                        setFieldValue("patientProfileId", value);
                      }}
                      searchHandler={() => {}}
                    />
                  </Col>
                ) : null}

                <Col span={24}>
                  <InputField
                    title="Issue Description"
                    type="textarea"
                    name="description"
                  />
                </Col>
              </Row>
            </div>
            <Row className="content__control mt-5" justify="end">
              <Col>
                <Button type="text" onClick={closeHandler}>
                  Cancel
                </Button>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default SupportForm;
