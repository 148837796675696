import React, { useEffect } from "react";
import AppRoutes from "../../../routes/routeConstants/appRoutes";

const Redirection = () => {
    useEffect(() => {
        window.location.replace(AppRoutes.LOGIN);
    }, []);
    return null;
}

export default Redirection;