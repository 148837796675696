import { serializable, alias, object, list, primitive } from "serializr";

export class MedicalAlert {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("description", primitive()))
  description?: string;

  @serializable(alias("patient_profile_id", primitive()))
  patientProfileId?: number;

  @serializable(alias("created_by", primitive()))
  createdBy?: number;

  @serializable(alias("created_by_first_name", primitive()))
  createdByFirstName?: string;

  @serializable(alias("created_by_last_name", primitive()))
  createdByLastName?: string;

  @serializable(alias("created_at", primitive()))
  createdAt?: string;

  getFullName() {
    return this.createdByFirstName + " " + this.createdByLastName;
  }
}
