import { Button, Col, Row } from "antd";
import React, { FC } from "react";
import { WorkItem } from "../../../../models/WorkItem/workItem.model";
import moment from "moment";
import "./showWorkItem.scss";

interface ShowWorkItemProps {
  workItem: WorkItem;
  acceptHandler: () => void;
  rejectHandler: () => void;
}

const ShowWorkItem: FC<ShowWorkItemProps> = (props) => {
  const { workItem, acceptHandler, rejectHandler } = props;
  const time = moment(workItem.endTime).diff(
    moment(workItem.startTime),
    "minutes"
  );
  return (
    <div className="show-work-item__container">
      <div className="show-work-item__wrapper">
        <div className="work-item__description">
          <p>{workItem.description}</p>
        </div>
        {/* <Row>
					<Col span={10}>
						<label htmlFor="">Work Item Type</label>
						<div className="work-item__type">
							<p>
								<span className="text-capitalize text-bold">
									{workItem?.type?.split("_").join(" ")}
								</span>
								<span className="text-bold">
									Due for{" "}
									<i className="icon-time work-item__timer" />{" "}
									{time} Minutes
								</span>
							</p>
						</div>
					</Col>
					<Col span={10}>
						<label htmlFor="">Work Item ID</label>
						<p>KHDFKW{workItem.id}</p>
					</Col>
				</Row> */}
        <Row>
          <Col span={10}>
            <label htmlFor="">Work Item ID</label>
            <p>KHDFKW{workItem.id}</p>
          </Col>
          <Col span={10}>
            <label htmlFor="">Emergency Visit</label>
            <p>
              <i className="icon-flag work-item__flag" />
              {workItem.isEmergency ? "Yes" : "No"}
            </p>
          </Col>
        </Row>
        <Row>
          <Col span={10}>
            <label htmlFor="">Patient Name</label>
            <p>
              {workItem.patientFirstName} {workItem.patientLastName}
            </p>
          </Col>
          <Col span={10}>
            <label htmlFor="">Date</label>
            <p>{moment(workItem.date).format("D/M/YYYY")}</p>
          </Col>
        </Row>
        <Row>
          {" "}
          <Col span={10}>
            <label htmlFor="">Time</label>
            <p>
              {moment(workItem.startTime).format("HH:mm a")}{" "}
              {moment(workItem.endTime).format("HH:mm a")}
            </p>
          </Col>
        </Row>
      </div>
      <div className="work-item__control">
        <Button type="text" onClick={() => rejectHandler()}>
          Reject
        </Button>
        <Button type="primary" onClick={() => acceptHandler()}>
          Accept
        </Button>
      </div>
    </div>
  );
};

export default ShowWorkItem;
