import { Button, Col, Drawer, Form, Row, Table, Tabs } from "antd";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import InputField from "../../../shared/components/InputField";
import "./appointmentTemplate.scss";
import AppointmentTemplateCreationForm from "./AppointmentTemplateCreationForm";
import moment from "moment";
import {
  generatePath,
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom";
import AppRoutes from "../../../routes/routeConstants/appRoutes";
import { AppointmentTemplate as AppointmentTemplateModel } from "../../../models/AppointmentTemplate/appointmentTemplate.model";
import AppoitmentTemplateService from "../../../services/AppointmentTemplate/appointmentTemplate.service";
import CheckableTag from "antd/lib/tag/CheckableTag";
import { createAppointmentTemplateValidation } from "./CreateAppointmentTemplate/createAppointmentTemplateValidation";
import Notification from "../../../shared/components/Notification";
import { NotificationTypes } from "../../../enums/notificationTypes";
import useResponsibilities from "../../../shared/hooks/Responsibilities/useResponsibilities";
import { ResponsibilityTypes } from "../../../enums/responsebily.enum";
const { TabPane } = Tabs;

const columns = [
  {
    title: "Template name",
    dataIndex: "templateName",
    key: "templateName",
  },
  {
    title: "Template Color",
    dataIndex: "colorCode",
    key: "colorCode",
    render: (color: string, template: AppointmentTemplateModel) => (
      <div
        className="template-color"
        style={{
          height: "30px",
          width: "30px",
          background: `${template?.colorCode}`,
        }}
      ></div>
    ),
  },
  {
    title: "Created date",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (dateString: string) =>
      moment(dateString).isValid()
        ? moment(dateString).format("MM/DD/YYYY")
        : "-",
  },
  {
    title: "Last Modified",
    dataIndex: "updatedAt",
    key: "updatedAt",
    render: (dateString: string) =>
      moment(dateString).isValid()
        ? moment(dateString).format("MM/DD/YYYY")
        : "-",
  },
];

const colorCode = [
  "#4FC95D",

  "#4FBBC9",

  "#4F58C9",

  "#9A4FC9",

  "#95C94F",

  "#C0C94F",

  "#C99A4F",

  "#C94F4F",

  "#4F83C9",

  "#7D7986",

  "#A7CFFF",

  "#A7AAFF",

  "#DAA7FF",

  "#A7F1FF",

  "#A7FFDD",

  "#BBFFA7",

  "#FFFFA7",

  "#FFCCA7",

  "#FFA7A7",

  "#D8D8D8",
];

function AppointmentTemplate() {
  const history = useHistory();

  const { hasAccess } = useResponsibilities();

  const [
    appointmentTemplateVisibility,
    setAppointmentTemplateVisibility,
  ] = useState(false);
  const [formValues, setFormValues] = useState(new AppointmentTemplateModel());
  const [currentColor, setCurrentColor] = useState("");
  const [appointmentTemplates, setAppointmentTemplates] = useState<
    AppointmentTemplateModel[]
  >([]);
  const [templatesFetchingLoader, setTemplatesFetchingLoader] = useState(false);
  const [createTemplateLoader, setCreateTemplateLoader] = useState(false);

  const HAS_APPOINTMENT_TEMPLATE_VIEW_ACCESS = hasAccess(
    ResponsibilityTypes.APPOINTMENT_TEMPLATE_VIEW
  );

  const HAS_APPOINTMENT_TEMPLATE_CREATE_ACCESS = hasAccess(
    ResponsibilityTypes.APPOINTMENT_TEMPLATE_CREATE
  );

  const HAS_APPOINTMENT_TEMPLATE_EDIT_ACCESS = hasAccess(
    ResponsibilityTypes.APPOINTMENT_TEMPLATE_EDIT
  );

  const handleRow = (
    template: AppointmentTemplateModel,
    rowIndex: number | undefined
  ) => {
    return {
      onClick: (event: any) => {
        if (!HAS_APPOINTMENT_TEMPLATE_VIEW_ACCESS) return;

        history.push(
          AppRoutes.CREATE_APPOINTMENT_TEMPLATE.replace(
            ":templateId",
            String(template?.id)
          )
        );
      },
    };
  };

  const handleTemplateVisibility = () => {
    setAppointmentTemplateVisibility(!appointmentTemplateVisibility);
  };
  const handleOnSubmit = (values: AppointmentTemplateModel) => {
    setCreateTemplateLoader(true);

    AppoitmentTemplateService.createAppointmentTemplate(
      values,
      (newTemplate: AppointmentTemplateModel) => {
        history.push(
          AppRoutes.CREATE_APPOINTMENT_TEMPLATE.replace(
            ":templateId",
            newTemplate?.id ? String(newTemplate?.id) : ""
          )
        );
      },
      (err: Error) => {},
      () => {
        setCreateTemplateLoader(false);
      }
    );
  };

  useEffect(() => {
    setTemplatesFetchingLoader(true);
    AppoitmentTemplateService.fetchAppointmentTemplate(
      (templates: AppointmentTemplateModel[]) => {
        setAppointmentTemplates(templates);
      },
      () => {},
      () => {
        setTemplatesFetchingLoader(false);
      }
    );
  }, []);
  const handleCheckedValue = (value: string) => {
    setCurrentColor(value);
  };
  return (
    <div className="appointment-template">
      <Row>
        <Col span={12}>
          <h4>All Appointment Templates</h4>
        </Col>
        <Col span={12} className="text-right">
          {HAS_APPOINTMENT_TEMPLATE_EDIT_ACCESS && (
          <Button
            className="secondary-btn mr-3"
            onClick={() => {
              if(!HAS_APPOINTMENT_TEMPLATE_EDIT_ACCESS) return;
              history.push(
                generatePath(AppRoutes.APPOINTMENT_TEMPLATE_ASSIGN_DATE)
              );
            }}
          >
            Assign Dates
          </Button>
          )}

          {HAS_APPOINTMENT_TEMPLATE_CREATE_ACCESS && (
          <Button type="primary" onClick={handleTemplateVisibility}>
            Create Appointment Template
          </Button>
          )}
        </Col>
      </Row>

      <Table
        dataSource={appointmentTemplates}
        columns={columns}
        className="primary-table"
        loading={templatesFetchingLoader}
        onRow={handleRow}
      />
      <Drawer
        title="Appointment Template"
        visible={appointmentTemplateVisibility}
        destroyOnClose
        onClose={handleTemplateVisibility}
        width="600"
        className="appointmentTemplate"
      >
        <Formik
          initialValues={formValues}
          enableReinitialize
          onSubmit={handleOnSubmit}
          validationSchema={createAppointmentTemplateValidation}
        >
          {({ values, handleSubmit, setFieldValue, isValid }) => (
            <Form>
              <div className="create-appointment-template">
                <Row>
                  <Col span={24}>
                    <InputField
                      title="Template Name "
                      type="text"
                      name="templateName"
                      placeholder="Enter Template Name"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <div className="create-appointment-template__color-label">Template Colour Code</div>

                    {colorCode?.map((color, index) => {
                      return (
                        <CheckableTag
                          style={{ background: color }}
                          checked={color === values.colorCode}
                          className={`create-appointment-template__color `}
                          key={index}
                          onChange={(value) => {
                            setFieldValue("colorCode", color);
                          }}
                        ></CheckableTag>
                      );
                    })}
                  </Col>
                </Row>
              </div>
              <div className="text-right mt-5">
                <Button type="text" onClick={handleTemplateVisibility}>
                  Cancel
                </Button>
                <Button
                  type="primary"
                  onClick={() => handleOnSubmit(values)}
                  disabled={!values?.templateName || !values?.colorCode}
                  loading={createTemplateLoader}
                >
                  Next
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Drawer>
    </div>
  );
}

export default AppointmentTemplate;
