import * as Yup from "yup";
import { AppointmentType } from "../../../../enums/appointmentType.enum";

export const startInteractionFormValidation = Yup.object().shape({
    appointmentTypeId: Yup.string().required("Appointment type is required"),
    doctorProfileId: Yup.string().required("Doctor is required"),
    // doctorProfileId: Yup.string().when(['assistantProfileId'], {
    //     is: assistantProfileId => !assistantProfileId,
    //     then: Yup.string().required("Doctor is required")
    // }),
    // assistantProfileId: Yup.string().when(['doctorProfileId'], {
    //     is: doctorProfileId => !doctorProfileId,
    //     then: Yup.string().required("Assistant is required")
    // }),
    // notes: Yup.string().required("Notes is required"),
    contractServiceId: Yup.string().when("appointment.appointmentTypeName", {
        is: (val) =>
          val &&
          String(val).toLowerCase() !== AppointmentType.CONSULTATION.toLowerCase(),
        then: (schema: any) => schema.required("Contract service is required"),
        otherwise: (schema: any) => schema.nullable(),
      }),
    visitType: Yup.string().required("Visit type is required"),
    // customForms: Yup.string().required("Form type is required"),
})