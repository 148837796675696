import { Button, Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import "./clock.scss";
import { ClockCircleOutlined, CloseCircleFilled } from "@ant-design/icons";
import moment from "moment";
import playIcon from "../../../assets/images/playIcon.png";
import pauseIcon from "../../../assets/images/pauseIcon.png";
import stopIcon from "../../../assets/images/stopIcon.png";
import ClockService from "../../../services/Clock/clock.service";
import { Clock as ClockModel } from "../../../models/Clock/clock.model";
import { ClockStatus } from "../../../enums/clockStatus.enum";
import { getSeconds } from "../../utils/hmsTosecond";
import { utcToLocalDate, utcToLocalTime } from "../../utils/utcToLocalTime";

interface ClockProps {
  clockVisibility: () => void;
}
interface ClockFunctionality {
  buttonType?: string;
  currentDate?: string;
  currentTime?: string;
  totalClockTime?: number;
  clockTime?: ClockModel;
  isActive?: boolean;
  lastActionPerformed?: string;
}

function Clock(props: ClockProps) {
  const { clockVisibility } = props;
  const [seconds, setSeconds] = useState(0);
  const [clockDetails, setClockDetails] = useState<ClockFunctionality>();
  const [clockLoading, setClockLoading] = useState(false);
  const [fetchedClockData, setFetchedClockData] = useState<ClockModel>();

  const handleSecondsToHourMin = (seconds: number) => {
    return moment.utc(seconds * 1000).format("HH:mm:ss");
  };

  const handleTotalClockTime = (totalClockTime: string) => {
    return getSeconds(totalClockTime).toFixed() ?? 0;
  };

  const handleGetClockTime = () => {
    ClockService.getClockTime(
      (clockTime) => {
        setFetchedClockData(clockTime);
        if (clockTime?.lastActionPerformed !== ClockStatus.STOP) {
          setSeconds(
            Number(handleTotalClockTime(clockTime?.totalClockTime ?? ""))
          );
          clockTime?.lastActionPerformed !== ClockStatus.PAUSE &&
            setClockDetails({
              ...clockDetails,
              isActive: true,
            });
        }
      },
      () => {},
      () => {}
    );
  };
  useEffect(() => {
    handleGetClockTime();
  }, []);

  useEffect(() => {
    let interval: any = null;
    if (clockDetails?.isActive) {
      interval = setInterval(() => {
        setSeconds((second) => {
          return second + 1;
        });
      }, 1000);
    } else if (!clockDetails?.isActive && seconds !== 0) {
      if (interval) clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [clockDetails?.isActive, seconds]);

  const startTime = (type?: string) => {
    setClockLoading(true);
    ClockService.startClockTime(
      (time) => {
        handleGetClockTime();
      },
      () => {},
      () => {
        setClockLoading(false);
      }
    );
  };
  function updateTime(actionType?: string) {
    setClockLoading(true);
    ClockService.updateClock(
      fetchedClockData?.id?.toString() ?? "",
      actionType ?? "",
      (clockTime) => {
        if (clockTime?.lastActionPerformed === ClockStatus.STOP) setSeconds(0);
        handleGetClockTime();
        setClockDetails({
          isActive:
            clockTime?.lastActionPerformed === ClockStatus.RESUME
              ? true
              : false,
        });
      },
      () => {},
      () => {
        setClockLoading(false);
      }
    );
  }

  const ClockData = [
    {
      label: "Date",
      value:
        fetchedClockData?.date &&
        fetchedClockData?.lastActionPerformed != ClockStatus.STOP &&
        utcToLocalDate(fetchedClockData?.date ?? ""),
    },
    {
      label: "Clock in time",
      value:
        fetchedClockData?.lastActionPerformed != ClockStatus.STOP &&
        fetchedClockData?.startTime &&
        utcToLocalTime(fetchedClockData?.startTime ?? ""),
    },
    {
      label: "Clock out Time",
      value:
        fetchedClockData?.lastActionPerformed != ClockStatus.STOP &&
        fetchedClockData?.endTime &&
        utcToLocalTime(fetchedClockData?.endTime ?? ""),
    },
  ];

  const buttonData = [
    {
      label:
        fetchedClockData?.lastActionPerformed == ClockStatus.PAUSE
          ? "Resume"
          : "Start",
      value:
        fetchedClockData?.lastActionPerformed == ClockStatus.PAUSE
          ? ClockStatus.RESUME
          : ClockStatus.START,
      color: "#00AE89",
      icon: playIcon,
      click: () => {
        fetchedClockData?.lastActionPerformed == ClockStatus.PAUSE
          ? updateTime(ClockStatus.RESUME)
          : startTime();
      },
    },

    {
      label: "Pause",
      value: ClockStatus.PAUSE,
      color: "#D89F44",
      icon: pauseIcon,
      click: () => {
        updateTime(ClockStatus.PAUSE);
      },
    },
    {
      label: "Stop",
      value: ClockStatus.STOP,
      color: "#D84944",
      icon: stopIcon,
      click: () => updateTime(ClockStatus.STOP),
    },
  ];

  return (
    <div className="clock">
      <Row gutter={[20, 10]}>
        <Col span={22}>
          <div className="clock__heading">
            <ClockCircleOutlined className="mt-2 " />
            <h4>Clock Time</h4>
          </div>
        </Col>
        <Col span={2}>
          <CloseCircleFilled
            className="text-danger  clock__close-btn"
            onClick={clockVisibility}
          />
        </Col>
      </Row>
      <Row>
        {ClockData?.map((data, index) => {
          return (
            <Col span={8} key={index}>
              <div className="clock__label seconday-black ">
                <p>{data?.label}</p>
              </div>
              <div className="clock__value text-bold">
                <p>{data?.value}</p>
              </div>
            </Col>
          );
        })}
      </Row>
      <Row>
        {buttonData?.map((data, index) => {
          return (
            <Col span={8} key={index}>
              <Button
                style={{ backgroundColor: data?.color }}
                onClick={() => {
                  setClockDetails({ ...clockDetails, buttonType: data?.value });
                  data?.click();
                }}
                disabled={
                  fetchedClockData?.lastActionPerformed == data?.value ||
                  (fetchedClockData?.lastActionPerformed ===
                    ClockStatus.RESUME &&
                    data?.value === ClockStatus.START) ||
                  (fetchedClockData?.lastActionPerformed == ClockStatus.STOP &&
                    data?.value == ClockStatus.PAUSE)
                }
              >
                <img src={data?.icon} className="mr-2" />
                {data?.label}{" "}
              </Button>
            </Col>
          );
        })}
      </Row>
      <div className="mt-5">
        <span className="secondary-black ">
          Total Clock Time: {handleSecondsToHourMin(seconds)}
        </span>
        <span></span>
      </div>
    </div>
  );
}

export default Clock;
