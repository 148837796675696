import { Tabs } from 'antd';
import React, { FC } from 'react'
import { Support } from '../../../models/Support/support.model';
import Attachments from './Attachments';
import Comments from './Comments';
import IssueDetails from './IssueDetails';
import "./supportDetails.scss"

const { TabPane } = Tabs;

interface SupportDetailsProps {
    supportTicket?: Support;
}

const SupportTabs = [
    {
        title: "Issue Details",
        Component: IssueDetails,
    },
    {
        title: "Attachments",
        Component: Attachments,
    },
    {
        title: "Comments",
        Component: Comments,
    }
]

const SupportDetails: FC<SupportDetailsProps> = (props) => {
    const { supportTicket } = props;

    return (
        <div className="support-details">
            <Tabs defaultActiveKey={"0"} size="small">
                {SupportTabs.map(({ title, Component }, index) => (
                    <TabPane tab={title} key={index}>
                        <Component support={supportTicket} />
                    </TabPane>
                ))}
            </Tabs>
        </div>
    )
}

export default SupportDetails;