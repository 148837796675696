import { serializable, alias, object, list, primitive } from "serializr";
import { PatientCategory } from "../../enums/patientCategory.enum";
import { ProcedureStep } from "../ProcedureStep/procedureStep.model";

export class Procedure {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("name", primitive()))
  name?: string;

  @serializable(alias("code", primitive()))
  code?: string;

  @serializable(alias("description", primitive()))
  description?: string;

  @serializable(alias("no_of_objects", primitive()))
  noOfObjects?: number;

  @serializable(alias("status", primitive()))
  status?: string;

  @serializable(alias("procedure_steps", list(object(ProcedureStep))))
  procedureSteps: ProcedureStep[] = [];

  @serializable(alias("practice_id", primitive()))
  practiceId?: number;

  @serializable(alias("patient_category", primitive()))
  patientCategory = PatientCategory.ADULT;
}
