import { Drawer, Tabs } from "antd";
import React, { Fragment, useEffect, useState } from "react";
import StatementList from "./NoteList";
import NoteTemplateForm from "./NoteTemplateForm";
import { StatementTemplate } from "../../../../models/Statement/statement.model";
import { NoteTemplateService } from "../../../../services/NoteTemplate/noteTemplate.service";
import { useHistory, useLocation } from "react-router-dom";
import {
  XRayTemplate,
  XRayTemplates,
} from "../../../../models/XRayTemplate/xRayTemplate.model";
import AppLoader from "../../../../shared/components/AppLoader";
import { PaginationDetails } from "../../../../models/Pagination/pagination.model";
import useFilters from "../../../../shared/hooks/useFilter/useFilters";
import { FilterParams } from "../../../../models/FilterParams/filterParams.model";
import useResponsibilities from "../../../../shared/hooks/Responsibilities/useResponsibilities";
import { ResponsibilityTypes } from "../../../../enums/responsebily.enum";
import XRayCategories from "./XRayCategories";
const { TabPane } = Tabs;

export type NoteTemplate = "Statement" | "X-Ray";

const NoteTemplate = () => {
  const location = useLocation();
  const [
    statementTemplateVisibility,
    setStatementTemplateVisibility,
  ] = useState(false);

  const [statementTemplates, setStatementTemplates] = useState<
    StatementTemplate[]
  >([]);

  const [xRayTemplates, setXRayTemplates] = useState<XRayTemplate[]>([]);

  const [tab, setTab] = useState<NoteTemplate>("Statement");
  const { hasAccess } = useResponsibilities();
  const HAS_NOTE_TEMPLATE_VIEW_ACCESS = hasAccess(
    ResponsibilityTypes.NOTE_TEMPLATE_VIEW
  );

  const HAS_NOTE_TEMPLATE_CREATE_ACCESS = hasAccess(
    ResponsibilityTypes.NOTE_TEMPLATE_CREATE
  );

  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState<PaginationDetails>();
  const { getParams, setParams } = useFilters();

  const handleUpdate = (
    value: StatementTemplate | XRayTemplate,
    type: string
  ) => {
    handleStateTemplateCloseHandler();
    if (tab === "Statement") handleFetchStatementNote(getParams());
    else handleFetchXRay({});
    if (type === "add") {
      (tab === "Statement"
        ? setStatementTemplates
        : setXRayTemplates)((existingValues: any) => [
        ...existingValues,
        value,
      ]);
      return;
    }

    (tab === "Statement" ? setStatementTemplates : setXRayTemplates)(
      (existingValues: any) =>
        existingValues.map((existingValue: any) => {
          if (existingValue.id === value?.id) {
            return value;
          }
          return existingValue;
        })
    );
  };

  const noteTemplateTabs = [
    {
      title: "Statement",
      component: (
        <StatementList
          type={tab}
          statementTemplates={statementTemplates}
          setStatementTemplates={setStatementTemplates}
          setXRayTemplates={setXRayTemplates}
          setStatementTemplateVisibility={setStatementTemplateVisibility}
          handleUpdate={handleUpdate}
          pagination={pagination}
          fetchStatement={(filter: FilterParams) =>
            handleFetchStatementNote(filter)
          }
        />
      ),
      hash: "Statement",
    },
    {
      title: "X-Ray",
      component: <XRayCategories />,
      hash: "X-ray",
    },
  ];

  const handleFetchStatementNote = (filter: FilterParams) => {
    setLoading(true);
    NoteTemplateService.fetchStatements(
      (statementTemplates, pagination) => {
        setStatementTemplates(statementTemplates);
        setPagination(pagination);
      },
      () => {},
      () => {
        setLoading(false);
      },
      undefined,
      filter
    );
  };

  const handleFetchXRay = (filter: FilterParams) => {
    setLoading(true);
    NoteTemplateService.fetchXRay(
      (xRayTemplates, pagination) => {
        setXRayTemplates(xRayTemplates);
        setPagination(pagination);
      },
      () => {},
      () => {
        setLoading(false);
      },
      filter
    );
  };

  useEffect(() => {
    if (!HAS_NOTE_TEMPLATE_VIEW_ACCESS) return;
    handleFetchStatementNote(getParams());
    handleFetchXRay(getParams());
  }, []);

  const handleStateTemplateCloseHandler = () => {
    setStatementTemplateVisibility(false);
  };
  useEffect(() => {
    const tabFromHash = noteTemplateTabs.find(
      (tab) => tab.hash === location.hash.replace("#", "")
    );
    if (tabFromHash) setTab(tabFromHash?.title as any);
  }, [location.hash]);

  const handleTabChange = (activeKey: any) => {
    const hash = noteTemplateTabs?.find((tab, index) =>
      activeKey == 0 ? "Statement" : "X-Ray" === activeKey
    )?.hash;
    setPagination({});
    activeKey == 1 ? handleFetchXRay({}) : handleFetchStatementNote({});
    setParams({}, hash);

    setTab(activeKey == 0 ? "Statement" : "X-Ray");
  };

  return (
    <Fragment>
      <p className="text-secondary">Note Templates</p>
      <Tabs
        defaultActiveKey="0"
        size="small"
        className="status-tabs"
        type="card"
        onChange={handleTabChange}
      >
        {noteTemplateTabs.map(({ title, component }, i) => (
          <TabPane tab={title} key={i}>
            {loading ? <AppLoader loading /> : component}
          </TabPane>
        ))}
      </Tabs>
      <Drawer
        width="75vw"
        visible={HAS_NOTE_TEMPLATE_CREATE_ACCESS && statementTemplateVisibility}
        onClose={handleStateTemplateCloseHandler}
        destroyOnClose
        title="New Template"
      >
        <NoteTemplateForm
          onClose={handleStateTemplateCloseHandler}
          type={tab}
          onUpdateHandler={handleUpdate}
        />
      </Drawer>
    </Fragment>
  );
};

export default NoteTemplate;
