import React, { useCallback, useEffect, useState } from "react";
import "./userDetail.scss";
import { Link, useParams, generatePath, useHistory } from "react-router-dom";
import { User } from "../../../models/User/user.model";
import UserService from "../../../services/User/user.service";
import { Button, Col, Row } from "antd";
import {
  CalendarOutlined,
  EditOutlined,
  LeftOutlined,
} from "@ant-design/icons";
import userPlaceholder from "../../../assets/images/userPlaceholder.png";
import { getYearsAndMonths } from "../../../shared/utils/dateHelpers";
import AppLoader from "../../../shared/components/AppLoader";
import AppRoutes from "../../../routes/routeConstants/appRoutes";
import useResponsibilities from "../../../shared/hooks/Responsibilities/useResponsibilities";
import { ResponsibilityTypes } from "../../../enums/responsebily.enum";
import { capitalize } from "lodash";

interface UserDetailParams {
  staffId: string;
}

interface UserDetailProps {}

function UserDetail(props: UserDetailProps) {
  const params: UserDetailParams = useParams();
  const history = useHistory();

  const { hasAccess } = useResponsibilities();

  const [loading, setLoading] = useState(false);

  const [user, setUser] = useState<User | null>(null);

  const hasScheduleViewAccess = hasAccess(ResponsibilityTypes.SCHEDULE_VIEW);

  const userDetails = [
    { label: "Name", value: user?.firstName },
    { label: "Surname", value: user?.lastName },
    { label: "Gender", value: user?.gender },
    {
      label: "DOB",
      value: user?.dob ? getYearsAndMonths(user?.dob) : "NA",
    },
    { label: "Email", value: user?.email },
    { label: "Mobile number", value: user?.mobileNumber },
    { label: "Country", value: capitalize(user?.countryName) },
    { label: "Province", value: capitalize(user?.provinceName) },
    { label: "City", value: capitalize(user?.cityName) },
    { label: "Nationality", value: capitalize(user?.nationalityName) },
    { label: "Street", value: user?.street },
    { label: "Building", value: user?.building },
    { label: "Postal code", value: user?.zipcode },
    { label: "Role", value: user?.roleName },
  ];

  const handleShowUser = useCallback(() => {
    if (params?.staffId) {
      setLoading(true);
      UserService.showUser(
        Number(params.staffId),
        (user: User) => {
          setUser(user);
        },
        () => {},
        () => {
          setLoading(false);
        }
      );
    }
  }, [params]);

  useEffect(() => {
    handleShowUser();
  }, [handleShowUser, params]);

  return (
    <div className="user-detail">
      <Row align="middle">
        <Col span={12}>
          <Button
            onClick={() => history.goBack()}
            className="mr-3"
            icon={<LeftOutlined />}
          />
          Staffs / <span className="text-secondary">{user?.getFullName()}</span>
        </Col>
        <Col span={12} className="patient-detail-tabs__edit-wrapper">
          {hasScheduleViewAccess && user?.profileId && (
            <Link
              to={generatePath(AppRoutes.STAFF_SCHEDULE, {
                staffId: user?.profileId,
              })}
            >
              <Button className="secondary-btn">
                <CalendarOutlined />
                Manage Schedule
              </Button>
            </Link>
          )}
          {/*<Button type="primary" onClick={handleToggleUserForm}>
            <EditOutlined /> Edit User
          </Button>*/}
        </Col>
      </Row>
      <div className="user-detail__user-card">
        {loading ? (
          <AppLoader loading={loading} />
        ) : (
          <>
            <Row>
              <Col span={3}>
                <img
                  src={user?.profilePicUrl || user?.profilePictureUrl || userPlaceholder}
                  alt="User"
                  className="user-detail__profile-picture"
                />
              </Col>
            </Row>
            <Row className="mt-5" gutter={[20, 30]}>
              {userDetails.map((userDetail, i) => (
                <Col span={8}>
                  <div className="user-detail__label ">{userDetail?.label}</div>
                  <div className="user-detail__value">
                    {userDetail?.value || "NA"}
                  </div>
                </Col>
              ))}
            </Row>
          </>
        )}
      </div>
    </div>
  );
}

export default UserDetail;
