import { deserialize } from "serializr";
import axiosInstance from "../../interceptor/axiosInstance";
import ApiRoutes from "../../routes/routeConstants/apiRoutes";

const UserNotification = () => {
  const updateUserNotification = async (
    notification_ids: Array<number | undefined>
  ) => {
    try {
      const { data } = await axiosInstance.put(ApiRoutes.NOTIFICATION, {
        notification_ids,
      });
      const notifications = deserialize(
        Notification,
        data?.notifications as any[]
      );
      return notifications;
    } catch (error) {}
  };

  const clearNotification = async () => {
    try {
      await axiosInstance.delete(ApiRoutes.NOTIFICATION);
      return true;
    } catch (error) {}
  };

  return {
    updateUserNotification,
    clearNotification,
  };
};

export default UserNotification;
