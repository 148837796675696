import { UnorderedListOutlined } from "@ant-design/icons";
import { Drawer } from "antd";
import React, { useState } from "react";
import AppRoutes from "routes/routeConstants/appRoutes";
import CorporateContainer from "store/container/CorporateContainer";
import { CorporateReducerProps } from "store/reducers/corporateReducer";
import CheckList from "../CheckList";
import { useLocation } from "react-router-dom";
import { Corporate } from "models/Corporate/corporate.model";

const DisableCheckList = [
  AppRoutes.AUTH,
  AppRoutes.REGISTER,
  AppRoutes.DETAILED_ONBOARDING,
];

interface ReadinessChecklistProps extends CorporateReducerProps {}

const ReadinessChecklist = ({}: ReadinessChecklistProps) => {
  const { pathname } = useLocation();

  const [isChecklistOpen, setIsChecklistOpen] = useState(false);

  const isValidRoute: boolean =
    !!pathname.slice(1) && !DisableCheckList.includes(pathname.slice(1));

  const corporate: Corporate = JSON.parse(
    localStorage.getItem("corporate") || "{}"
  );

  const isChecklistForReadinessCompleted =
    corporate?.isAppointmentTemplateCompleted &&
    corporate?.isPracticeAvailabilityCompleted &&
    corporate?.isResourceCreationCompleted &&
    corporate?.isServiceCreationCompleted &&
    corporate?.isStaffProfileCompleted &&
    corporate?.isStatementNoteTemplateCompleted &&
    corporate?.isTreatmentTemplateCompleted;

  const handleOpenCheckList = () => {
    setIsChecklistOpen(true);
  };

  const handleCloseOpenCheckList = () => {
    setIsChecklistOpen(false);
  };

  return (
    <>
      {isValidRoute && !isChecklistForReadinessCompleted && (
        <div className="check-list-icon__wrapper" onClick={handleOpenCheckList}>
          <UnorderedListOutlined />
        </div>
      )}
      <Drawer
        visible={isChecklistOpen}
        width={"45vw"}
        title="Checklist for readiness"
        closable
        onClose={handleCloseOpenCheckList}
      >
        <CheckList />
      </Drawer>
    </>
  );
};

export default CorporateContainer(ReadinessChecklist);
