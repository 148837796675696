import React, { useState } from "react";
import "./procedureForm.scss";
import { FormActionEnum } from "../../../../../enums/formAction.enum";
import { Procedure } from "../../../../../models/Procedure/procedure.model";
import { Form, Formik, FormikValues } from "formik";
import { Button, Col, Row } from "antd";
import InputField from "../../../../../shared/components/InputField";
import { procedureFormValidation } from "./procedureFormValidation";
import ProcedureService from "../../../../../services/Procedure/procedure.service";
import DropdownField from "../../../../../shared/components/DropdownField";
import { patientCategories } from "../../../../../shared/utils/patientCategories";

interface ProcedureFormProps {
  action?: FormActionEnum;
  procedure: Procedure;
  onCancel?: () => void;
  onSuccess: (procedure: Procedure) => void;
  showPatientCategory?: boolean;
}

function ProcedureForm({
  action,
  procedure,
  onSuccess,
  onCancel,
  showPatientCategory,
}: ProcedureFormProps) {
  const [formValues] = useState<Procedure>(procedure);

  const [formLoading, setFormLoading] = useState(false);

  const handleSubmit = (values: FormikValues) => {
    const procedure = Object.assign(new Procedure(), values);
    setFormLoading(true);
    if (procedure.id) {
      if (action === FormActionEnum.CLONE) {
        ProcedureService.cloneProcedure(
          procedure,
          (procedure: Procedure) => {
            onSuccess(procedure);
          },
          () => {},
          () => {
            setFormLoading(false);
          }
        );
      } else {
        ProcedureService.updateProcedure(
          procedure,
          (procedure: Procedure) => {
            onSuccess(procedure);
          },
          () => {},
          () => {
            setFormLoading(false);
          }
        );
      }
    } else {
      ProcedureService.createProcedure(
        procedure,
        (procedure: Procedure) => {
          onSuccess(procedure);
        },
        () => {},
        () => {
          setFormLoading(false);
        }
      );
    }
  };

  return (
    <div className="procedure-form">
      <Formik
        initialValues={formValues}
        onSubmit={handleSubmit}
        enableReinitialize
        validationSchema={procedureFormValidation}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          errors,
          isValid,
          dirty,
          setFieldValue,
        }) => {
          return (
            <Form>
              <div className="procedure-form__form-body">
                <Row gutter={[20, 20]}>
                  <Col span={13}>
                    <InputField
                      title="Procedure Name"
                      type="text"
                      name="name"
                      placeholder="Enter procedure name"
                    />
                  </Col>
                  <Col span={13}>
                    <InputField
                      title="Procedure Code"
                      type="text"
                      name="code"
                      placeholder="Enter procedure code"
                    />
                  </Col>
                  <Col span={13}>
                    <DropdownField
                      disabled={!showPatientCategory}
                      title="Patient Category"
                      name="patientCategory"
                      placeHolder="Select Patient Category"
                      setFieldValue={setFieldValue}
                      options={patientCategories}
                      value={values.patientCategory}
                    />
                  </Col>
                  <Col span={24}>
                    <InputField
                      title="Description"
                      type="textarea"
                      name="description"
                      placeholder="Enter Description"
                    />
                  </Col>
                </Row>
              </div>
              <div className="procedure-form__submit-wrapper text-right">
                {onCancel && (
                  <Button type="default" onClick={onCancel}>
                    Cancel
                  </Button>
                )}
                <Button
                  type="primary"
                  className="ml-2"
                  htmlType="submit"
                  loading={formLoading}
                  disabled={!isValid || !dirty || formLoading}
                >
                  {action === FormActionEnum.CLONE
                    ? "Clone"
                    : values?.id
                    ? "Edit"
                    : "Add"}
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default ProcedureForm;
