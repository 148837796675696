import { User } from "../../models/User/user.model";
import { ActionProps } from "../../shared/Types/action.type";
import {  UNAUTHENTICATED, SET_RESPONSIBILITIES, SET_USER } from "../definitions/authConstants";


export const setUnAuthenticated = () => {
    return {
        type: UNAUTHENTICATED,
        payload: {
            authenticated: false,
            user: null
        }
    }
}

export const setResponsibilities = (userResponsibilities: string[]) => {
    return {
        type: SET_RESPONSIBILITIES,
        payload: {
            userResponsibilities,
        }
    }
}

export const setUser = (user: User): ActionProps => {
    return {
        type: SET_USER,
        payload: {
            user,
        }
    }
}
