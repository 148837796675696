import React, { useEffect, useState } from "react";
import "./corporateObjectFilters.scss";
import { Collapse, Row, Col, Checkbox, Button, Radio } from "antd";
import { CorporateObjectFilterParams } from "../../../../../models/CorporateObjectFilterParams/corporateObjectFilterParams.model";
import { Manufacturer } from "../../../../../models/Manufacturer/manufacturer.model";
import { MetaService } from "../../../../../services/Meta/meta.service";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { ObjectCategory } from "../../../../../models/ObjectCategory/objectCategory.model";
import { ObjectModel } from "../../../../../models/ObjectModel/objectModel.model";
import { DentalObject } from "../../../../../models/DentalObject/dentalObject.model";
import CorporateObjectService from "../../../../../services/CorporateObject/corporateObject.service";

const { Panel } = Collapse;
interface CorporateObjectFiltersProps {
  filterParams: CorporateObjectFilterParams;
  setFilterParams: Function;
  onNext: (dentalObjects: DentalObject[]) => void;
}

type CorporateFilterParamKeys = "manufacturerIds" | "objectCategoryIds";

function CorporateObjectFilters({
  onNext,
  filterParams,
  setFilterParams,
}: CorporateObjectFiltersProps) {
  const [manufacturers, setManufacturers] = useState<Manufacturer[]>([]);

  const [formLoading, setFormLoading] = useState(false);

  const [objectCategories, setObjectCategories] = useState<ObjectCategory[]>(
    []
  );

  const [objectModels, setObjectModels] = useState<ObjectModel[]>([]);

  const handleSubmit = () => {
    setFormLoading(true);
    CorporateObjectService.fetchDentalObjects(
      Object.assign(new CorporateObjectFilterParams(), filterParams),
      (dentalObjects: DentalObject[]) => {
        onNext(dentalObjects);
      },
      () => {},
      () => {
        setFormLoading(false);
      }
    );
  };

  const handleFetchObjectCategories = (objectType: string) => {
    if (objectType) {
      MetaService.fetchObjectCategories(
        objectType,
        (objectCategories: ObjectCategory[]) => {
          setObjectCategories(objectCategories);
        },
        () => {},
        () => {}
      );
    }
  };

  const handleCheck = (
    keyName: CorporateFilterParamKeys,
    id: string | number
  ) => (e: CheckboxChangeEvent) => {
    const ids: any = filterParams[keyName];
    if (e.target.checked) {
      ids.push(id);
    } else {
      const idIndex = ids.findIndex(
        (objectId: number | string) => id === objectId
      );
      if (idIndex >= 0) {
        ids.splice(idIndex, 1);
      }
    }
    filterParams[keyName] = ids;
    setFilterParams({
      ...filterParams,
    });
  };

  useEffect(() => {
    MetaService.fetchManufacturers(
      (manufacturers: Manufacturer[]) => {
        setManufacturers(manufacturers);
      },
      () => {},
      () => {}
    );
  }, []);

  return (
    <div className="corporate-object-filters">
      <div className="corporate-object-filters__body">
        <Collapse
          expandIconPosition="right"
          className="corporate-object-filters__collapse"
        >
          <Panel header="Object Types" key="1">
            <Radio.Group
              onChange={(e) => {
                setFilterParams({
                  ...filterParams,
                  objectTypes: [e.target.value],
                  objectCategoryIds: [],
                });
                handleFetchObjectCategories(e.target.value);
              }}
              value={
                filterParams?.objectTypes && filterParams.objectTypes[0]
                  ? filterParams.objectTypes[0]
                  : undefined
              }
            >
              <Radio value="anatomy">Anatomy</Radio>
              <Radio value="vendor">Vendor</Radio>
            </Radio.Group>
          </Panel>
          <Panel header="Manufacturers" key="2">
            <Row gutter={[20, 20]}>
              {manufacturers.map((manufacturer) => (
                <Col span={6} key={manufacturer.id}>
                  <Checkbox
                    checked={
                      !!manufacturer.id &&
                      filterParams.manufacturerIds.includes(manufacturer.id)
                    }
                    onChange={
                      manufacturer?.id
                        ? handleCheck("manufacturerIds", manufacturer.id)
                        : undefined
                    }
                  >
                    {manufacturer.name}
                  </Checkbox>
                </Col>
              ))}
            </Row>
          </Panel>
          <Panel header="Categories" key="3">
            <Row gutter={[10, 10]} align="middle">
              {objectCategories.map((category) => (
                <Col span={6} key={category.id}>
                  <Checkbox
                    checked={
                      !!category.id &&
                      filterParams.objectCategoryIds.includes(category.id)
                    }
                    onChange={
                      category?.id
                        ? handleCheck("objectCategoryIds", category.id)
                        : undefined
                    }
                  >
                    {category.name}
                  </Checkbox>
                </Col>
              ))}
            </Row>
          </Panel>
        </Collapse>
      </div>
      <div className="corporate-object-filters__footer">
        <Button type="primary" onClick={handleSubmit} loading={formLoading}>
          Next
        </Button>
      </div>
    </div>
  );
}

export default CorporateObjectFilters;
