import { serializable, alias, primitive } from "serializr";

export class PatientNotes {
  @serializable
  id?: number;
  @serializable
  notes?: string;
  @serializable(alias("patient_profile_id", primitive()))
  patientProfileId?: number;
  @serializable(alias("created_at", primitive()))
  createdAt?: string;
}
