import {
  SET_ACTIVE_CORPORATE,
  SET_DETAILED_ONBOARDING_ACTIVE_STEP,
  SET_REGISTER_ACTIVE_STEP,
} from "../definitions/registerConstants";
import { Corporate } from "../../models/Corporate/corporate.model";

export interface SetRegisterActiveStepAction {
  type: typeof SET_REGISTER_ACTIVE_STEP;
  payload: { registerActiveStep: number };
}

export interface SetDetailedOnboardingActiveStepAction {
  type: typeof SET_DETAILED_ONBOARDING_ACTIVE_STEP;
  payload: { detailedOnboardingActiveStep: number };
}

export interface SetActiveCorporateAction {
  type: typeof SET_DETAILED_ONBOARDING_ACTIVE_STEP;
  payload: { activeCorporate: Corporate };
}

export const setRegisterActiveStep = (
  registerActiveStep: number
): SetRegisterActiveStepAction => {
  return {
    type: SET_REGISTER_ACTIVE_STEP,
    payload: {
      registerActiveStep,
    },
  };
};

export const setDetailedOnboardingActiveStep = (
  detailedOnboardingActiveStep: number
): SetDetailedOnboardingActiveStepAction => {
  return {
    type: SET_DETAILED_ONBOARDING_ACTIVE_STEP,
    payload: {
      detailedOnboardingActiveStep,
    },
  };
};

export const setActiveCorporate = (
  activeCorporate: Corporate
): SetActiveCorporateAction => {
  return {
    type: SET_ACTIVE_CORPORATE,
    payload: {
      activeCorporate,
    },
  };
};
