import React, { SetStateAction, Dispatch, useEffect, useState } from "react";
import "./contractPaymentSchedule.scss";
import { Contract } from "../../../../../models/Contract/contract.model";
import { Button, Col, Modal, Popover, Row, Space } from "antd";
import ContractContainer from "../../../../../store/container/ContractContainer";
import { ContractReducerProps } from "../../../../../store/reducers/contractReducer";
import ContractService from "../../../../../services/Contract/Contract.service";
import { useLocation } from "react-router-dom";
import { FormikHelpers, Form, Formik } from "formik";
import InputField from "../../../../../shared/components/InputField";
import DropdownField from "../../../../../shared/components/DropdownField";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import CheckboxField from "../../../../../shared/components/Checkbox";
import DatePickerField from "../../../../../shared/components/DatePickerField";
import { Service } from "../../../../../models/Service/service.model";
import ServiceService from "../../../../../services/Service/service.service";
import { contractPaymentValidation } from "./contractPaymentValidation";
import {
  InsuranceProvider,
  InsuranceSchedule,
} from "../../../../../models/InsuranceProvider/insuranceProvider.model";
import { PaymentSchedule } from "../../../../../models/PaymentSchedule/paymentSchedule.model";
import InsuranceProviderUpdate from "./InsuranceProviderUpdate";
import alertIcon from "../../../../../../src/assets/images/alertIcon.png";
import { IDropdownOptions } from "../../../../../services/Meta/meta.service";
import moment from "moment";
import CustomModal from "../../../../../shared/components/CustomModal";
import { SelectProp } from "../../../../../shared/Types/option";
import { generateFixedDecimal } from "../../../../../shared/utils/numberUtils";
import { SelfPaymentSchedules } from "../../../../../models/PaymentSchedule/paymentSchedule.model";
import CurrencyField from "../../../../../shared/components/CurrencyField";
import ContractTermTemplateService from "services/ContractTermTemplate/ContractTermTemplate.service";
import { ContractTermTemplate } from "models/ContractTermsTemplate/contractTermsTemplate.model";
import { ContractStatus } from "enums/contractStatus.enum";
import { formatDate } from "shared/utils/dateFormatter";
import ChangeEvent from "shared/utils/changeEvent";
import { procedureCodeOption } from "shared/options/procedureCode";
import { getNumber } from "shared/utils/getNumber";

interface ContractPaymentScheduleProps extends ContractReducerProps {
  onCancel: () => void;
  onContractStepUpdate: Dispatch<SetStateAction<number>>;
}
interface CheckboxValues {
  isDiscount?: boolean;
  isDeposit?: boolean;
  isInsurance?: InsuranceVisibilityProp[];
  isInsuranceProvider?: boolean;
  isSelfPayment?: boolean;
  invalidateInvoice?: boolean;
}
interface InsuranceVisibilityProp {
  isVisible?: boolean;
  id?: number;
}
function ContractPaymentSchedule({
  onCancel,
  contract,
  setContract,
  onContractStepUpdate,
}: ContractPaymentScheduleProps) {
  const [formLoading, setFormLoading] = useState(false);

  const [contractTerms, setContractTerms] = useState<ContractTermTemplate[]>(
    []
  );

  const isContractInitiated = contract?.status === ContractStatus.INITIATED;

  const isContractDocumentSigned =
    contract?.status === ContractStatus.DOCUMENT_SIGNED;

  const isContractOpen = contract?.status === ContractStatus.ACTIVE;

  const [formValues, setFormValues] = useState<PaymentSchedule>(
    new PaymentSchedule()
  );
  const [pendingContractAmount, setPendingContractAmount] = useState(false);
  const [insuranceVisibility, setInsuranceVisibility] = useState<
    InsuranceVisibilityProp[]
  >([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [
    currentProviderValue,
    setCurrentProviderValue,
  ] = useState<InsuranceProvider>();
  const [providerInsurance, setProviderInsurance] = useState<
    InsuranceProvider[]
  >([]);
  const [insuranceProv, setInsuranceProv] = useState(new InsuranceProvider());
  const [updateProviderVisibility, setUpdateProviderVisibility] = useState(
    false
  );
  const [isValidCost, setIsValidCost] = useState<boolean>();
  const [checkboxValues, setCheckBoxValues] = useState<CheckboxValues>();
  const [isSelfPaymentTable, setIsSelfPaymentTable] = useState(false);
  const [isInsuranceTable, setIsInsuranceTable] = useState(false);
  const [noOfDuesOptions, setNoOfDuesOptions] = useState<IDropdownOptions[]>();
  const [currentNoDue, setCurrentNoDue] = useState<number>();
  const [isEditForm, setIsEditForm] = useState(false);
  const [paymentScheduleList, setPaymentScheduleList] = useState<
    PaymentSchedule[]
  >([]);
  const [paymentaOptions, setPaymentOptions] = useState<any[]>([]);
  const [upcommingDate, setUpcommingDate] = useState("");
  const [modalText, setModalText] = useState("");
  const [insuranceListOption, setInsuranceList] = useState<SelectProp[]>([]);

  const handleUpdateProviderVisibility = () => {
    setUpdateProviderVisibility(!updateProviderVisibility);
  };
  const handleModalContentVisibility = (text?: string) => {
    // setModalVisible(true);
    // setModalText(text ?? "");
  };

  const [isPathEdit, setIsPathEdit] = useState(false);
  const [services, setServices] = useState<Service[]>([]);
  const location = useLocation();

  const splitAmount = (amount?: number, splitCount?: number): number =>
    amount && splitCount && !isNaN(amount) && !isNaN(splitCount)
      ? generateFixedDecimal((amount / splitCount).toString())
      : 0;

  useEffect(() => {
    ContractTermTemplateService.fetchContractTerms(
      contract?.id ?? 0,
      (term) => {
        setContractTerms(term);
      },
      () => {},
      () => {}
    );
  }, []);

  useEffect(() => {
    const paths = location.pathname.split("/");
    if (paths[paths.length - 1] === "edit") setIsPathEdit(true);
    ServiceService.fetchServices(
      (services: Service[]) => {
        setServices(services);
      },
      () => {},
      () => {}
    );

    setFormLoading(true);
    ContractService.getPaymentScheduleList(
      contract?.id ?? 0,
      (paymentSchedule: PaymentSchedule[]) => {
        if (paymentSchedule.length > 0) {
          setPaymentScheduleList(paymentSchedule);
        }
      },
      () => {},
      () => {
        setFormLoading(false);
      }
    );
    ContractService.fetchInsuranceProvidersList(
      (list) => {
        const options = list.map(({ id, name }) => ({
          label: name ?? "",
          key: id ?? "",
          value: id ?? "",
        }));
        setInsuranceList(options);

        return;
      },
      () => {},
      () => {}
    );

    handleNoOfDuesOptions();
  }, []);

  const [dueTimeOptions] = useState([
    { label: "5th of every month", value: "5th of every month" },
    { label: "15th of every month", value: "15th of every month" },
  ]);

  const handleSubmit = (values: PaymentSchedule) => {
    if (paymentScheduleList?.length && pendingContractAmount) {
      setContract(
        Object.assign(new Contract(), {
          ...contract,
          stepNo: contract.stepNo > 2 ? contract.stepNo : 2,
          contractService: undefined,
        })
      );
      return;
    }
    setFormLoading(true);

    if (!checkboxValues?.isDiscount) delete values?.discount;
    if (!checkboxValues?.isDeposit) delete values?.deposit;
    if (!checkboxValues?.isSelfPayment) delete values?.selfPayment;

    const data = {
      ...values,
      invalidateUnpaidInvoices: checkboxValues?.invalidateInvoice,
    };

    ContractService.updateContractPaymentSchedule(
      data,
      contract?.id ?? 0,
      () => {
        ContractService.updateContract(
          Object.assign(new Contract(), {
            ...contract,
            stepNo: 2,
            contractService: undefined,
          }),
          (contract: Contract) => {
            setContract(contract);
            onContractStepUpdate(2);
          },
          () => {
            setFormLoading(false);
          },
          () => {
            setFormLoading(false);
          }
        );
      },
      () => {},
      () => {
        setFormLoading(false);
      }
    );
  };

  const handleSelfPaymentTableVisibility = () => {
    setIsSelfPaymentTable(true);
  };

  const getTotalVisits = (contract: Contract): number => {
    let totalVisits = 0;
    contract?.contractService?.treatmentPlans?.forEach(
      (plan) => (totalVisits += plan?.noOfAppointments || 0)
    );

    return totalVisits;
  };

  const statusOption = [
    {
      label: "Active",
      value: "active",
    },
    {
      label: "Inactive",
      value: "inactive",
    },
  ];

  const handleCheckTotalContractCost = (values: PaymentSchedule) => {
    const contractCost = values?.pendingContractAmount ?? values?.contractCost;
    const overallExpected = values?.insuranceProviders?.reduce(
      (sum, cur) => sum + Number(cur?.overallExpectedAmount || 0),
      0
    );

    if (!checkboxValues?.isDiscount) delete values?.discount;
    if (!checkboxValues?.isDeposit) delete values?.deposit;
    if (!checkboxValues?.isSelfPayment) delete values?.selfPayment;
    const totalCost =
      Number(values?.discount || 0) +
      Number(values?.deposit || 0) +
      Number(values.selfPayment?.amount || 0) +
      Number(overallExpected || 0);

    if (contractCost && totalCost && totalCost > contractCost) {
      setIsValidCost(false);
      handleModalContentVisibility(
        "Your total Cost is exceeding the Contract Cost"
      );
    } else setIsValidCost(true);
  };
  const handleSelfPaymentAmount = (values: PaymentSchedule) => {
    const overallSelfAmount = values?.selfPayment?.selfPaymentSchedules?.reduce(
      function (total, arr) {
        return total + Number(arr?.amount) || 0;
      },
      0
    );
    const selfPaymentAmount = values?.selfPayment?.amount;
    if (
      overallSelfAmount &&
      selfPaymentAmount &&
      overallSelfAmount > selfPaymentAmount
    ) {
      handleModalContentVisibility(
        "Your Due amount is exceeding the Self amount"
      );
    }
  };

  const categoryOption = [
    {
      label: "Primary",
      value: "primary",
    },
    {
      label: "Secondary",
      value: "secondary",
    },
    {
      label: "Tertiary",
      value: "tertiary",
    },
  ];

  const handleDeleteInsuranceProvider = (
    id: number,
    values: PaymentSchedule
  ) => {
    const provider = values?.insuranceProviders?.filter((val, i) => id !== i);
    const updatedValue = insuranceVisibility?.filter((data) => data?.id !== id);
    setInsuranceVisibility(updatedValue);
    setFormValues({ ...values, insuranceProviders: provider });
  };
  const handleNoOfDuesOptions = () => {
    const arrValues = Array(24)
      .fill(0)
      .map((_, i) => i + 1);
    const options = arrValues?.map((data) => {
      return { label: data.toString(), value: data };
    });
    setNoOfDuesOptions(options);
  };

  const handleAddInsurance = (values: PaymentSchedule) => {
    const data = values?.insuranceProviders;
    data?.push(insuranceProv);
    setFormValues({
      ...values,
      insuranceProviders: data,
    });
    setInsuranceProv(new InsuranceProvider());
  };

  const handleGetDates = (dueTime: string) => {
    const updatedDueTime = dueTime.replace(/[^0-9]/g, "");
    setUpcommingDate(updatedDueTime);
    var currentDate = moment();
    var nextMonthDate = moment(currentDate, "MM-DD-YYYY")
      .add(1, "months")
      .format(`${updatedDueTime} MMYYYY`);
  };

  const handleInitialValues = () => {
    const length = paymentScheduleList?.length - 1;
    const currentPayment = paymentScheduleList[length];

    const updatedInsurance = currentPayment?.insuranceProviders?.map((data) => {
      return {
        ...data,
        insurancePayoutRatio:
          (getNumber(data?.initialClaimAmount) /
            getNumber(data?.overallClaimAmount)) *
          100,
      };
    });

    setCheckBoxValues({
      isDeposit: !!currentPayment?.deposit,
      isDiscount: !!currentPayment?.discount,
      isInsurance: currentPayment?.insuranceProviders?.map((_, id) => ({
        isVisible: true,
        id,
      })),
      isInsuranceProvider:
        currentPayment?.insuranceProviders &&
        currentPayment?.insuranceProviders?.length > 0,
      isSelfPayment: currentPayment?.selfPayment && true,
      invalidateInvoice: true,
    });

    setFormValues({ ...currentPayment, insuranceProviders: updatedInsurance });
    setPendingContractAmount(false);

    setIsEditForm(true);
  };

  const getDateFromDueTime = (dueTime: string, index: number) =>
    moment()
      .add(index + 1, "month")
      .format(`YYYY-MM-${dueTime?.replace(/[^\d\.]*/g, "")}`);

  const populateSelfPaymentSchedules = (
    { selfPayment, ...values }: PaymentSchedule,
    setValues: FormikHelpers<PaymentSchedule>["setValues"]
  ) => {
    let paymentSchedules: SelfPaymentSchedules[] = [];

    if (selfPayment?.amount && selfPayment?.noOfDues) {
      const { amount, noOfDues, dueTime, selfPaymentSchedules } = selfPayment;

      let summedUpValue = 0;

      const sharedAmount = splitAmount(amount, noOfDues);

      for (let index = 0; index < selfPayment.noOfDues; index++) {
        const isLastSchedule = index === selfPayment?.noOfDues - 1;

        // Tally Last Schedule Value to match actual amount
        if (!isLastSchedule) summedUpValue += sharedAmount;

        paymentSchedules.push({
          amount:
            (isLastSchedule
              ? +(amount - summedUpValue)?.toFixed(2)
              : sharedAmount) || 0,
          dueNo: index + 1,
          ...(dueTime
            ? {
                dueDate:
                  selfPaymentSchedules?.[index]?.dueDate ??
                  moment()
                    .add(index + 1, "month")
                    .format(`YYYY-MM-${dueTime?.replace(/[^\d\.]*/g, "")}`),
              }
            : {}),
        });
      }
    }

    setValues({
      ...values,
      selfPayment: {
        ...selfPayment,
        selfPaymentSchedules: paymentSchedules,
      },
    });
  };

  const populateInsuranceSchedules = (
    { insuranceProviders, ...values }: PaymentSchedule,
    setValues: FormikHelpers<PaymentSchedule>["setValues"],
    insuranceIndex: number,
    newValue?: Partial<InsuranceProvider>
  ) => {
    if (!insuranceProviders?.length) return;

    let schedules: InsuranceSchedule[] = [];

    const updatedInsuranceProvider: InsuranceProvider = {
      ...insuranceProviders![insuranceIndex],
      ...(newValue ? newValue : {}),
    };

    const {
      initialClaimAmount,
      initialExpectedAmount,
      overallClaimAmount,
      overallExpectedAmount,
      noOfDues,
      dueTime,
      status,
      payoutRatio,
    } = updatedInsuranceProvider;

    if (
      initialClaimAmount &&
      initialExpectedAmount &&
      overallClaimAmount &&
      overallExpectedAmount &&
      noOfDues &&
      dueTime &&
      status === "active" &&
      payoutRatio
    ) {
      const expectedAmount = overallExpectedAmount - initialExpectedAmount;

      const claimAmount = overallClaimAmount - initialClaimAmount;

      let summedUpClaimValue = 0;

      let remainingExpectedAmount = expectedAmount;

      let sharedClaimAmount = splitAmount(claimAmount, noOfDues);

      for (let i = 0; i < noOfDues; i++) {
        const isLastSchedule = i === noOfDues - 1;

        let sharedExpectedAmount = (sharedClaimAmount * payoutRatio) / 100;

        if (!isLastSchedule) {
          summedUpClaimValue += sharedClaimAmount;

          if (
            sharedExpectedAmount > remainingExpectedAmount ||
            sharedExpectedAmount > sharedClaimAmount
          ) {
            sharedExpectedAmount = Math.min(
              remainingExpectedAmount,
              sharedClaimAmount
            );
            remainingExpectedAmount = 0;
          } else {
            remainingExpectedAmount -= sharedExpectedAmount;
          }
        } else {
          const remainingClaimAmount = claimAmount - summedUpClaimValue;
          const remainingExpectedAmountForLastSchedule = Math.max(
            0,
            remainingExpectedAmount
          );
          sharedClaimAmount = remainingClaimAmount;
          sharedExpectedAmount = remainingExpectedAmountForLastSchedule;
        }

        schedules.push({
          claimAmount: isLastSchedule
            ? +sharedClaimAmount.toFixed(2)
            : sharedClaimAmount,
          expectedAmount: isLastSchedule
            ? +sharedExpectedAmount.toFixed(2)
            : sharedExpectedAmount,
          dueNo: i + 1,
          ...(dueTime
            ? {
                dueDate:
                  updatedInsuranceProvider?.insuranceSchedule?.[i]?.dueDate ??
                  getDateFromDueTime(dueTime, i),
              }
            : {}),
        });
      }

      updatedInsuranceProvider.insuranceSchedule = schedules;
    }

    setValues({
      ...values,
      insuranceProviders: insuranceProviders?.map((insurance, index) =>
        index !== insuranceIndex ? insurance : updatedInsuranceProvider
      ),
    });
  };

  useEffect(() => {
    if (paymentScheduleList?.length > 0)
      setFormValues({ contractCost: paymentScheduleList[0]?.contractCost });
    if (paymentScheduleList[0]?.pendingContractAmount)
      setPendingContractAmount(true);
    paymentScheduleList.map((data, value) => {
      const options = [
        {
          label: "Deposit",
          value: data?.deposit,
        },
        {
          label: "Invoice Raised For",
          value: data?.invoiceRaisedFor,
        },
        {
          label: "Discount",
          value: data?.discount,
        },
        {
          label: "Paid Amount",
          value: data?.paidAmount,
        },
        {
          label: "Self Payment",
          value: data?.selfPayment?.amount,
        },
        {
          label: "Pending Due",
          value: data?.pendingDue,
        },
        {
          label: "Insurance",
          value: data?.insuranceAmount,
        },
        {
          label: "",
          value: "-",
        },
      ];

      setPaymentOptions({ ...paymentaOptions });
      return options;
    });
  }, [paymentScheduleList]);

  const calculateRemainingClaimAmount = (
    overallAmount: number,
    initialAmount: number,
    indexValue: number,
    values?: PaymentSchedule
  ) => {
    const totalClaimAmount = values?.insuranceProviders?.[
      indexValue
    ]?.insuranceSchedule?.reduce(function (total, arr) {
      return total + Number(arr?.claimAmount || 0);
    }, 0);

    return generateFixedDecimal(
      (
        (Number(overallAmount) || 0) -
        (Number(initialAmount) || 0) -
        (Number(totalClaimAmount) || 0)
      )?.toString()
    );
  };
  const calculateRemainingExpectedAmount = (
    overallAmount: number,
    initialAmount: number,
    indexValue: number,
    values?: PaymentSchedule
  ) => {
    const totalExpectedAmount = values?.insuranceProviders?.[
      indexValue
    ]?.insuranceSchedule?.reduce(function (total, arr) {
      return total + (Number(arr?.expectedAmount) || 0);
    }, 0);

    return generateFixedDecimal(
      (
        (Number(overallAmount) || 0) -
        (Number(initialAmount) || 0) -
        (Number(totalExpectedAmount) || 0)
      )?.toString()
    );
  };

  const handlePayoutRatio = (values: PaymentSchedule) => {
    const overallPayoutRatio = values?.insuranceProviders?.reduce(
      (sum, cur) => sum + Number(cur?.payoutRatio || 0),
      0
    );

    if (overallPayoutRatio && overallPayoutRatio > 100)
      handleModalContentVisibility("Payout ratio should not exceed 100% ");
  };
  const handleInsuranceProviderId = (id: number) => {
    const value = insuranceListOption?.filter((val) => {
      return val?.value === id;
    });

    return value[0]?.label;
  };

  const handleBack = () =>
    setContract({ ...contract, stepNo: contract?.stepNo - 1 });

  const calculateTotalFee = ({
    contractCost = 0,
    discount = 0,
  }: PaymentSchedule) => +contractCost - +discount;

  const calculateInitialPayoutRatio = (
    values: PaymentSchedule,
    index: number
  ) => {
    return values?.insuranceProviders
      ?.filter((_, i) => i === index)
      .map(
        (data) =>
          (getNumber(data?.initialClaimAmount) /
            getNumber(data?.overallClaimAmount)) *
          100
      )[0]
      .toString();
  };
  return (
    <div className="contract-payment-schedule">
      <Formik
        initialValues={formValues}
        onSubmit={handleSubmit}
        enableReinitialize
        validationSchema={contractPaymentValidation}
      >
        {({ values, errors, setValues, isValid, dirty, setFieldValue }) => {
          return (
            <Form className="contract-payment-schedule__form">
              <div className="contract-form__card">
                <div className="contract-form__card">
                  <Row className="select-service__overview" gutter={[20, 0]}>
                    <Col span={10}>
                      {" "}
                      <div className="title mb-0">
                        <h4>2</h4>
                        <h4>Payment Schedule</h4>
                      </div>
                    </Col>
                    <Col span={5} className="select-service__overview-col">
                      <span className="mr-2">Total Visits:</span>
                      <span className="h5 text-secondary">
                        {getTotalVisits(contract)}
                      </span>
                    </Col>
                    <Col
                      span={9}
                      className="select-service__overview-col select-service__overview__cost"
                    >
                      <Space>
                        <span>Total Fee: </span>
                        <CurrencyField
                          name="contractCost"
                          placeholder="Enter cost"
                          onValueChange={(cost) => {
                            setFieldValue("contractCost", cost);
                            handleCheckTotalContractCost({
                              ...values,
                              contractCost: Number(cost),
                            });
                          }}
                        />
                      </Space>
                    </Col>
                  </Row>
                </div>
                {paymentScheduleList?.map((list, index) => {
                  const data = "12";
                  return (
                    <div className="current-payment-schedule mt-2">
                      <p className="text-secondary">
                        {paymentScheduleList?.length === 0 ||
                        paymentScheduleList?.length == index + 1
                          ? "Current Payment Schedule"
                          : `Previous Payment Schedule - ${
                              index + 1
                            } (Inactive)`}
                      </p>
                      <Row>
                        <Col span={18}>
                          <Row>
                            <Col span={6}>Deposit</Col>
                            <Col span={6} className="text-bold ">
                              {`$ ${list?.deposit ?? 0}` ?? 0}
                            </Col>
                            <Col span={6}>Invoice Raised For</Col>
                            <Col span={6} className="text-bold ">
                              {`$ ${list?.invoiceRaisedFor ?? 0}` ?? 0}
                            </Col>
                          </Row>
                          <Row>
                            <Col span={6}>Discount</Col>
                            <Col span={6} className="text-bold ">
                              {`$ ${list?.discount ?? 0}` ?? 0}
                            </Col>
                            <Col span={6}>Paid Amount</Col>
                            <Col span={6} className="text-bold ">
                              {`$ ${list?.paidAmount ?? 0}` ?? 0}
                            </Col>
                          </Row>
                          <Row>
                            <Col span={6}>Self Payment</Col>
                            <Col span={6} className="text-bold ">
                              {`$ ${list?.selfPayment?.amount ?? 0}` ?? 0}
                            </Col>
                            <Col span={6}>Pending Due</Col>
                            <Col span={6} className="text-bold ">
                              {`$ ${list?.pendingDue ?? 0}` ?? 0}
                            </Col>
                          </Row>
                          <Row>
                            <Col span={6}>{/* Insurance */}</Col>
                            <Col span={6} className="text-bold ">
                              {/*  {`$ ${list?.insuranceAmount ?? 0}` ?? 0} */}
                            </Col>
                          </Row>

                          <Row>
                            <Col span={6}>Total</Col>
                            <Col span={6} className="text-bold text-primary">
                              {`$ ${list?.totalPaymentScheduleCost ?? 0}` ?? 0}
                            </Col>
                            <Col span={6}>Pending Contract Amount</Col>
                            <Col span={6} className="text-bold text-primary">
                              {`$ ${list?.pendingContractAmount ?? 0}` ?? 0}
                            </Col>
                          </Row>
                        </Col>
                        {paymentScheduleList?.length == index + 1 &&
                        paymentScheduleList[paymentScheduleList?.length - 1]
                          ?.pendingDue ? (
                          <Col span={6}>
                            <CheckboxField
                              content="Invalidate unpaid Invoice"
                              setFieldValue={setFieldValue}
                              value={checkboxValues?.invalidateInvoice ?? false}
                              name="invalidateUnpaidInvoices"
                              onChange={(value) => {
                                Modal.confirm({
                                  icon: null,

                                  className: "contract-cost__warning",
                                  okText: "Invalidate",
                                  onOk: () => {
                                    setFieldValue(
                                      "invalidateUnpaidInvoices",
                                      value.target.checked
                                    );
                                    setCheckBoxValues({
                                      ...checkboxValues,
                                      invalidateInvoice: value.target.checked,
                                    });

                                    paymentScheduleList[
                                      paymentScheduleList?.length - 1
                                    ].pendingDue = 0;
                                  },

                                  content: (
                                    <div className="contract-cost__content">
                                      <img src={alertIcon} alt="alert" />
                                      <p className="mt-3">
                                        {" "}
                                        {!value.target.checked
                                          ? "Are you sure you want to validate the unpaid Invoice?"
                                          : "Are you sure you want to invalidate the unpaid Invoice?"}
                                      </p>
                                    </div>
                                  ),
                                });
                              }}
                            />
                          </Col>
                        ) : (
                          ""
                        )}
                      </Row>
                    </div>
                  );
                })}
                {paymentScheduleList?.length > 0 && (
                  <Button
                    disabled={
                      !(
                        isContractInitiated ||
                        isContractDocumentSigned ||
                        isContractOpen
                      )
                    }
                    onClick={() => {
                      if (
                        paymentScheduleList[paymentScheduleList?.length - 1]
                          ?.pendingDue
                      ) {
                        Modal.warning({
                          icon: null,

                          className: "contract-cost__warning",

                          content: (
                            <div className="contract-cost__content">
                              <img src={alertIcon} alt="alert" />
                              <p className="mt-3">
                                {" "}
                                Kinldy Invalidate the Pending Invoices before
                                revising the Payment Scheule
                              </p>
                            </div>
                          ),
                        });
                      } else handleInitialValues();
                    }}
                    type="primary"
                    className="mt-5"
                  >
                    Revise Payment Schedule
                  </Button>
                )}

                {!pendingContractAmount && (
                  <div className="pb-0 contract-payment-schedule__deposit-discount">
                    <p className="text-secondary">
                      Payment Schedule {paymentScheduleList?.length + 1}
                    </p>
                    <Row justify="space-between">
                      <Col span={11}>
                        <CheckboxField
                          content="Deposit"
                          setFieldValue={setFieldValue}
                          value={checkboxValues?.isDeposit ?? false}
                          name="isDeposit"
                          onChange={(value) => {
                            setCheckBoxValues({
                              ...checkboxValues,
                              isDeposit: value?.target?.checked,
                            });
                          }}
                        />
                        <CurrencyField
                          disabled={!checkboxValues?.isDeposit}
                          placeholder="Enter Deposit amount"
                          name="deposit"
                          title="Deposit Amount"
                          onValueChange={(deposit) => {
                            setFieldValue("deposit", deposit);
                            handleCheckTotalContractCost({
                              ...values,
                              deposit: Number(deposit),
                            });
                          }}
                        />
                      </Col>

                      <Col span={12}>
                        <CheckboxField
                          content="Discount"
                          setFieldValue={setFieldValue}
                          value={checkboxValues?.isDiscount ?? false}
                          name="isDiscount"
                          onChange={(value) => {
                            setCheckBoxValues({
                              ...checkboxValues,
                              isDiscount: value.target.checked,
                            });
                          }}
                        />
                        <div className="discount-wrapper ">
                          <CurrencyField
                            className="mr-4"
                            disabled={!checkboxValues?.isDiscount}
                            name="discount"
                            onValueChange={(discount) => {
                              setFieldValue("discount", discount);
                              handleCheckTotalContractCost({
                                ...values,
                                discount: Number(discount),
                              });
                            }}
                            placeholder="Enter Discount amount"
                            title="Discount"
                          />

                          <InputField
                            title="Discount Reason (Optional)"
                            type="text"
                            name="discountReason"
                            placeholder="Enter Discount reason"
                            disabled={!checkboxValues?.isDiscount}
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                )}
                {/* Self payment starts */}
                {!pendingContractAmount && (
                  <div className="contract-payment-schedule__self-payment ">
                    <div>
                      <CheckboxField
                        content="Self Payment"
                        setFieldValue={setFieldValue}
                        value={checkboxValues?.isSelfPayment ?? false}
                        name="isSelfPayment"
                        onChange={(value) => {
                          setCheckBoxValues({
                            ...checkboxValues,
                            isSelfPayment: value.target.checked,
                          });
                        }}
                      />
                    </div>
                    <Row gutter={[20, 0]} justify="space-between">
                      <Col span={12}>
                        <Col span={24}>
                          <CurrencyField
                            title="Amount"
                            name="selfPayment.amount"
                            placeholder="Enter Initial Claim Amount"
                            disabled={!checkboxValues?.isSelfPayment}
                            onValueChange={(amount) => {
                              const parsedAmount = amount
                                ? Number(amount)
                                : undefined;

                              const updatedValues = {
                                ...values,
                                selfPayment: {
                                  ...values?.selfPayment,
                                  amount: parsedAmount,
                                },
                              };
                              populateSelfPaymentSchedules(
                                updatedValues,
                                setValues
                              );
                              setFieldValue("selfPayment.amount", amount);
                              handleCheckTotalContractCost(updatedValues);
                            }}
                          />
                        </Col>
                        <Col span={24}>
                          <DropdownField
                            title="No of Dues"
                            name="selfPayment.noOfDues"
                            options={noOfDuesOptions}
                            value={values?.selfPayment?.noOfDues}
                            // value={values?.selfPayment?}
                            onChange={(val) => {
                              setFieldValue("selfPayment.noOfDues", val);
                              if (values?.selfPayment)
                                values.selfPayment.noOfDues = val;
                              setCurrentNoDue(val);
                              populateSelfPaymentSchedules(
                                { ...values },
                                setValues
                              );
                              // if (values?.selfPayment?.selfPaymentSchedules) {
                              //   const data = values.selfPayment.selfPaymentSchedules?.filter(
                              //     (selfValue, selfIndex) => {
                              //       if (selfIndex < val) return selfValue;
                              //     }
                              //   );

                              //   setFormValues({
                              //     ...values,

                              //     selfPayment: {
                              //       ...values.selfPayment,
                              //       selfPaymentSchedules: data,
                              //     },
                              //   });
                              // }
                            }}
                            placeHolder="Select Due Type"
                            disabled={!checkboxValues?.isSelfPayment}
                          />
                        </Col>
                        <Col span={24}>
                          <DropdownField
                            title="Due Time"
                            name="selfPayment.dueTime"
                            value={values?.selfPayment?.dueTime}
                            options={dueTimeOptions}
                            setFieldValue={setFieldValue}
                            placeHolder="Select Due Time"
                            disabled={!checkboxValues?.isSelfPayment}
                            onChange={(value) => {
                              setFieldValue(`selfPayment.dueTime`, value);
                              handleGetDates(value);

                              populateSelfPaymentSchedules(
                                {
                                  ...values,
                                  selfPayment: {
                                    ...values?.selfPayment,
                                    dueTime: value,
                                  },
                                },
                                setValues
                              );
                            }}
                          />
                        </Col>
                        <Col span={24}>
                          <Button
                            type="primary"
                            onClick={handleSelfPaymentTableVisibility}
                            disabled={
                              !values?.selfPayment?.amount ||
                              !values?.selfPayment?.dueTime ||
                              !values?.selfPayment?.noOfDues
                            }
                          >
                            Generate monthly schedule
                          </Button>
                        </Col>
                      </Col>

                      <Col span={12}>
                        {isSelfPaymentTable &&
                          values?.selfPayment?.selfPaymentSchedules?.map(
                            ({ amount }, index) => {
                              return (
                                <Row gutter={[5, 0]} justify="space-between">
                                  <Col span={3}>
                                    <InputField
                                      title="Due"
                                      type="number"
                                      name={`selfPayment.selfPaymentSchedules[${index}].dueNo`}
                                      placeholder="Enter Due"
                                      value={index + 1}
                                      disabled
                                    />
                                  </Col>
                                  <Col span={10}>
                                    <DatePickerField
                                      title="To Date"
                                      format="DD MMMM YYYY"
                                      name={`selfPayment.selfPaymentSchedules[${index}].dueDate`}
                                      setFieldValue={setFieldValue}
                                      onChange={(value) => {
                                        setFieldValue(
                                          `selfPayment.selfPaymentSchedules[${index}].dueDate`,
                                          value?.format("YYYY-MM-DD")
                                        );
                                      }}
                                      placeholder="Select Date"
                                      defaultValue={
                                        values?.selfPayment
                                          ?.selfPaymentSchedules?.[index]
                                          ?.dueDate ??
                                        moment()
                                          .add(index + 1, "month")
                                          .format(
                                            `${
                                              values?.selfPayment &&
                                              values?.selfPayment?.dueTime?.replace(
                                                /[^\d\.]*/g,
                                                ""
                                              )
                                            }  MMMM YYYY`
                                          )
                                      }
                                    />
                                  </Col>
                                  <Col span={10}>
                                    <CurrencyField
                                      title="Amount"
                                      name={`selfPayment.selfPaymentSchedules[${index}].amount`}
                                      placeholder="Enter Amount"
                                      value={
                                        values?.selfPayment
                                          ?.selfPaymentSchedules?.[index]
                                          ?.amount
                                      }
                                      onValueChange={(amount) => {
                                        const parsedAmount = amount
                                          ? Number(amount)
                                          : undefined;

                                        const selfPaymentAmount =
                                          Number(values.selfPayment?.amount) ||
                                          0;

                                        if (
                                          selfPaymentAmount <
                                          (parsedAmount || 0)
                                        )
                                          handleModalContentVisibility(
                                            "Amount cannot be greater Self Payment Amount"
                                          );
                                        if (values?.selfPayment?.amount)
                                          handleSelfPaymentAmount(values);
                                        if (
                                          !values?.selfPayment
                                            ?.selfPaymentSchedules?.[index]
                                            ?.dueNo
                                        )
                                          setFieldValue(
                                            `selfPayment.selfPaymentSchedules[${index}].dueNo`,
                                            index + 1
                                          );
                                        if (
                                          !values?.selfPayment
                                            ?.selfPaymentSchedules?.[index]
                                            ?.dueDate
                                        )
                                          setFieldValue(
                                            `selfPayment.selfPaymentSchedules[${index}].dueDate`,
                                            values?.selfPayment
                                              ?.selfPaymentSchedules?.[index]
                                              ?.dueDate ??
                                              moment()
                                                .add(index + 1, "month")
                                                .format(
                                                  `YYYY-MM-${
                                                    values?.selfPayment &&
                                                    values?.selfPayment?.dueTime?.replace(
                                                      /[^\d\.]*/g,
                                                      ""
                                                    )
                                                  }  `
                                                )
                                          );

                                        setFieldValue(
                                          `selfPayment.selfPaymentSchedules[${index}].amount`,
                                          amount
                                        );
                                      }}
                                    />
                                  </Col>
                                </Row>
                              );
                            }
                          )}
                      </Col>
                    </Row>
                  </div>
                )}
                {/* insurance provider starts */}

                {!pendingContractAmount && (
                  <div className="contract-payment-schedule__insurances ">
                    <div>
                      <CheckboxField
                        content="Insurance Provider"
                        setFieldValue={setFieldValue}
                        value={checkboxValues?.isInsuranceProvider ?? false}
                        name="isInsuranceProvider"
                        onChange={(value) => {
                          setCheckBoxValues({
                            ...checkboxValues,
                            isInsuranceProvider: value.target.checked,
                          });
                        }}
                      />
                    </div>
                    <Row gutter={[10, 0]} justify="space-between">
                      <Col span={5}>
                        <DropdownField
                          title="Insurance Provider"
                          name={`insuranceProviderId`}
                          options={insuranceListOption}
                          value={insuranceProv?.insuranceProviderId}
                          onChange={(value) => {
                            setInsuranceProv({
                              ...insuranceProv,
                              insuranceProviderId: value,
                            });
                          }}
                          setFieldValue={setFieldValue}
                          placeHolder="Select Number of due"
                          disabled={!checkboxValues?.isInsuranceProvider}
                        />
                      </Col>
                      <Col span={5}>
                        <InputField
                          title="Group Number"
                          type="text"
                          name={`groupNumber`}
                          placeholder="Enter Group Number "
                          disabled={!checkboxValues?.isInsuranceProvider}
                          value={insuranceProv?.groupNumber}
                          onChange={(e: any) => {
                            setInsuranceProv({
                              ...insuranceProv,
                              groupNumber: e?.target?.value,
                            });
                          }}
                        />
                      </Col>
                      <Col span={5}>
                        <InputField
                          title="Certificate Number"
                          type="text"
                          name={`certificateNumber`}
                          placeholder="Enter Certificate Number "
                          disabled={!checkboxValues?.isInsuranceProvider}
                          value={insuranceProv?.certificateNumber}
                          onChange={(e: any) => {
                            setInsuranceProv({
                              ...insuranceProv,
                              certificateNumber: e?.target?.value,
                            });
                          }}
                        />
                      </Col>
                      <Col span={5}>
                        <DropdownField
                          title="Category"
                          name={`category`}
                          options={(() => {
                            const selectedCategory = values?.insuranceProviders?.map(
                              (val) => val?.category
                            );
                            const filteredOptions = categoryOption.filter(
                              ({ value }) => {
                                return !selectedCategory?.includes(value);
                              }
                            );
                            return filteredOptions;
                          })()}
                          value={insuranceProv?.category}
                          setFieldValue={setFieldValue}
                          onChange={(value) => {
                            setInsuranceProv({
                              ...insuranceProv,
                              category: value,
                            });
                          }}
                          placeHolder="Select Category"
                          disabled={!checkboxValues?.isInsuranceProvider}
                        />
                      </Col>

                      <Col span={3}>
                        <div className="contract-payment-schedule__add-btn">
                          {" "}
                          <Button
                            className="secondary-btn mt-5"
                            htmlType="button"
                            onClick={() => {
                              let isValid = true;
                              values?.insuranceProviders?.map((val) => {
                                if (
                                  insuranceProv?.groupNumber &&
                                  insuranceProv?.groupNumber ==
                                    val?.groupNumber &&
                                  insuranceProv?.certificateNumber ==
                                    val?.certificateNumber
                                ) {
                                  handleModalContentVisibility(
                                    "Certificate and Group number already exists"
                                  );
                                  isValid = false;
                                } else if (
                                  insuranceProv?.certificateNumber ==
                                    val?.certificateNumber &&
                                  !val?.groupNumber &&
                                  !insuranceProv?.groupNumber
                                ) {
                                  handleModalContentVisibility(
                                    "Certificate  number already exists"
                                  );
                                  isValid = false;
                                }
                              });
                              isValid && handleAddInsurance(values);
                            }}
                            disabled={
                              !checkboxValues?.isInsuranceProvider ||
                              (values?.insuranceProviders &&
                                values?.insuranceProviders?.length > 2) ||
                              !insuranceProv?.certificateNumber ||
                              !insuranceProv?.insuranceProviderId ||
                              !insuranceProv?.status ||
                              !insuranceProv?.category ||
                              !insuranceProv?.subscriberName ||
                              !insuranceProv?.subscriberDob
                            }
                          >
                            <PlusOutlined /> Add
                          </Button>
                        </div>
                      </Col>
                    </Row>
                    <Row gutter={[5, 0]} justify="space-between">
                      <Col span={5}>
                        <InputField
                          title="Subscriber Name"
                          type="text"
                          name={`subscriberName`}
                          placeholder="Enter Subscriber Number "
                          disabled={!checkboxValues?.isInsuranceProvider}
                          value={insuranceProv?.subscriberName}
                          onChange={(e: ChangeEvent) => {
                            setInsuranceProv({
                              ...insuranceProv,
                              subscriberName: e?.target?.value,
                            });
                          }}
                        />
                      </Col>
                      <Col span={5}>
                        <InputField
                          title="Relationship"
                          type="text"
                          name="subscriberRelationToPatient"
                          placeholder="Enter Relation"
                          disabled={!checkboxValues?.isInsuranceProvider}
                          value={insuranceProv?.subscriberRelationToPatient}
                          onChange={(e: ChangeEvent) => {
                            setInsuranceProv({
                              ...insuranceProv,
                              subscriberRelationToPatient: e?.target?.value,
                            });
                          }}
                        />
                      </Col>
                      <Col span={5}>
                        <DatePickerField
                          title="Subscriber DOB"
                          name="subscriberDob"
                          setFieldValue={setFieldValue}
                          value={
                            insuranceProv.subscriberDob &&
                            moment(insuranceProv.subscriberDob)
                          }
                          disabled={!checkboxValues?.isInsuranceProvider}
                          onChange={(_, date) => {
                            setInsuranceProv({
                              ...insuranceProv,
                              subscriberDob: date,
                            });
                          }}
                        />
                      </Col>
                      <Col span={5}>
                        <DropdownField
                          title="Status"
                          name={"status"}
                          options={statusOption}
                          onChange={(value) => {
                            setInsuranceProv({
                              ...insuranceProv,
                              status: value,
                            });
                          }}
                          value={insuranceProv?.status}
                          setFieldValue={setFieldValue}
                          placeHolder="SelectStatus"
                          disabled={!checkboxValues?.isInsuranceProvider}
                        />
                      </Col>
                      <Col span={3}></Col>
                    </Row>

                    <div className="contract-payment-schedule__insurances-provider-title">
                      {values?.insuranceProviders &&
                        values?.insuranceProviders?.length > 0 && (
                          <Row>
                            <Col span={3}>Category</Col>
                            <Col span={3}>Insurance Provider</Col>
                            <Col span={3}>Group Number</Col>
                            <Col span={3}>Certificate No</Col>
                            <Col span={3}>Subscriber Name</Col>
                            <Col span={3}>DOB</Col>
                            <Col span={3}>Relationship</Col>
                            <Col span={2}>Status</Col>
                          </Row>
                        )}
                      {values?.insuranceProviders?.map((val, index) => {
                        return (
                          <Row
                            key={index}
                            className="contract-payment-schedule__insurances-provider-table"
                          >
                            <Col span={3}>{val?.category}</Col>
                            <Col span={3}>
                              {handleInsuranceProviderId(
                                val?.insuranceProviderId ?? 0
                              )}
                            </Col>
                            <Col span={3}>{val?.groupNumber}</Col>
                            <Col span={3}>{val?.certificateNumber}</Col>
                            <Col span={3}>{val?.subscriberName}</Col>
                            <Col span={3}>{formatDate(val?.subscriberDob)}</Col>
                            <Col span={3}>
                              {val?.subscriberRelationToPatient}
                            </Col>
                            <Col
                              span={2}
                              className={`text-capitalize ${
                                val?.status === "active"
                                  ? "success-text"
                                  : "text-danger"
                              }`}
                            >
                              {val?.status}
                            </Col>
                            <Col span={1}>
                              <Popover
                                destroyTooltipOnHide
                                content={
                                  <div className="cursor-pointer ">
                                    <p
                                      onClick={(event) => {
                                        setCurrentProviderValue({
                                          ...val,
                                          id: index,
                                        });
                                        handleUpdateProviderVisibility();
                                      }}
                                    >
                                      Update
                                    </p>
                                    <p
                                      onClick={() => {
                                        handleDeleteInsuranceProvider(
                                          index,
                                          values
                                        );
                                        setIsInsuranceTable(false);
                                      }}
                                    >
                                      Delete
                                    </p>
                                  </div>
                                }
                                placement="leftBottom"
                                trigger="click"
                              >
                                <span className="cursor-pointer ">. . . </span>
                              </Popover>
                            </Col>

                            <Modal
                              className="primary-modal patient-form-response-wrapper__detail-modal"
                              visible={updateProviderVisibility}
                              closable={false}
                              width="40%"
                              destroyOnClose
                              footer={null}
                            >
                              <InsuranceProviderUpdate
                                currentFormValues={
                                  currentProviderValue ??
                                  new InsuranceProvider()
                                }
                                formValues={formValues}
                                currentIndex={index}
                                handleUpdateProviderVisibility={
                                  handleUpdateProviderVisibility
                                }
                                categoryOption={categoryOption}
                                insuraneProviderOptions={insuranceListOption}
                                statusOptions={statusOption}
                                contract={contract}
                                onSuccess={(
                                  updatedValue: InsuranceProvider
                                ) => {
                                  if (
                                    values &&
                                    values.insuranceProviders &&
                                    updatedValue
                                  )
                                    values.insuranceProviders[
                                      updatedValue?.id ?? 0
                                    ] = updatedValue;
                                }}
                              />
                            </Modal>
                          </Row>
                        );
                      })}
                    </div>
                  </div>
                )}

                {/* Insurance starts */}
                {/* values &&
                  values.insuranceProviders &&
                  values.insuranceProviders */}
                {!pendingContractAmount &&
                  values?.insuranceProviders?.map((data, index) => {
                    if (data.status === "active")
                      return (
                        <div className="contract-payment-schedule__insurances ">
                          <div>
                            <Row>
                              <Col span={6}>
                                <CheckboxField
                                  content="Insurance"
                                  setFieldValue={setFieldValue}
                                  value={
                                    checkboxValues?.isInsurance?.[index]
                                      ?.isVisible ?? false
                                  }
                                  name={`isInsurance[${index}].isVisible`}
                                  onChange={(e) => {
                                    const isChecked = e.target.checked;
                                    const isInsurance =
                                      checkboxValues?.isInsurance || [];
                                    isInsurance[index] = {
                                      isVisible: isChecked,
                                      id: index,
                                    };

                                    setCheckBoxValues({
                                      ...checkboxValues,
                                      isInsurance,
                                    });
                                    setFieldValue(
                                      `insuranceProviders[${index}].overallClaimAmount`,
                                      calculateTotalFee(values)
                                    );
                                  }}
                                />
                              </Col>
                              <Col span={12}>
                                <div className="insurance-name">
                                  {handleInsuranceProviderId(
                                    data?.insuranceProviderId ?? 0
                                  )}
                                </div>
                              </Col>
                            </Row>
                          </div>

                          <Row gutter={[20, 20]} justify="space-between">
                            <Col span={12}>
                              <Row gutter={[20, 20]}>
                                <Col span={12}>
                                  <CurrencyField
                                    title="Insurance Lifetime Max Amt"
                                    name={`insuranceProviders[${index}].lifetimeMaxAmount`}
                                    placeholder="Enter Max Amount"
                                    disabled={
                                      !checkboxValues?.isInsurance?.[index]
                                        ?.isVisible
                                    }
                                    onValueChange={(amount) => {
                                      setFieldValue(
                                        `insuranceProviders[${index}].lifetimeMaxAmount`,
                                        amount
                                      );
                                    }}
                                  />
                                </Col>
                                <Col span={12}>
                                  <CurrencyField
                                    title="Lifetime Insurance Payout Ratio"
                                    name={`insuranceProviders[${index}].payoutRatio`}
                                    placeholder="Enter Payout Ratio"
                                    disabled={
                                      !checkboxValues?.isInsurance?.[index]
                                        ?.isVisible
                                    }
                                    hint="All Insurance providers Ratio should not exceed 100%"
                                    onValueChange={(ratio) => {
                                      const initialClaimAmount = getNumber(
                                        values?.insuranceProviders?.[index]
                                          ?.initialClaimAmount
                                      );

                                      const parsedRatio = ratio
                                        ? Number(ratio)
                                        : undefined;

                                      const updatedInitialExpectedAmount =
                                        (getNumber(ratio) / 100) *
                                        initialClaimAmount;

                                      populateInsuranceSchedules(
                                        values,
                                        setValues,
                                        index,
                                        {
                                          payoutRatio: parsedRatio,
                                          initialExpectedAmount: updatedInitialExpectedAmount,
                                        }
                                      );

                                      setFieldValue(
                                        `insuranceProviders[${index}].payoutRatio`,
                                        ratio
                                      );

                                      if (values.insuranceProviders)
                                        values.insuranceProviders[
                                          index
                                        ].payoutRatio = parsedRatio;
                                      handlePayoutRatio(values);
                                    }}
                                  />
                                </Col>
                                <Col span={12}>
                                  <CurrencyField
                                    title="Total Fee (Net of Discount)"
                                    name={`insuranceProviders[${index}].overallClaimAmount`}
                                    placeholder="Enter Claim Amount"
                                    disabled={
                                      !checkboxValues?.isInsurance?.[index]
                                        ?.isVisible
                                    }
                                    onValueChange={(amount) => {
                                      const parsedAmount = amount
                                        ? Number(amount)
                                        : undefined;
                                      populateInsuranceSchedules(
                                        values,
                                        setValues,
                                        index,
                                        {
                                          overallClaimAmount: parsedAmount,
                                        }
                                      );
                                      setFieldValue(
                                        `insuranceProviders[${index}].overallClaimAmount`,
                                        amount
                                      );
                                    }}
                                  />
                                </Col>
                                <Col span={12}>
                                  <CurrencyField
                                    title="Overall Expected Insurance Amt"
                                    name={`insuranceProviders[${index}].overallExpectedAmount`}
                                    placeholder="Enter Expected Amount"
                                    disabled={
                                      !checkboxValues?.isInsurance?.[index]
                                        ?.isVisible
                                    }
                                    onValueChange={(amount) => {
                                      const parsedAmount = amount
                                        ? Number(amount)
                                        : undefined;
                                      if (values?.insuranceProviders)
                                        values.insuranceProviders[
                                          index
                                        ].overallExpectedAmount = parsedAmount;
                                      if (
                                        values.insuranceProviders &&
                                        values.insuranceProviders[index]
                                          .overallExpectedAmount
                                      )
                                        values.insuranceProviders[
                                          index
                                        ].overallExpectedAmount = parsedAmount;

                                      handleCheckTotalContractCost({
                                        ...values,
                                      });
                                      const overallClaimAmount =
                                        values?.insuranceProviders &&
                                        values?.insuranceProviders?.[index]
                                          ?.overallClaimAmount;

                                      const lifetimeMaxAmount =
                                        values?.insuranceProviders &&
                                        values?.insuranceProviders?.[index]
                                          ?.lifetimeMaxAmount;

                                      if (
                                        lifetimeMaxAmount &&
                                        Number(parsedAmount) >
                                          Number(lifetimeMaxAmount)
                                      ) {
                                        handleModalContentVisibility(
                                          "Overall  Expected Insurance Amount cannot be greater than Insurance Lifetime Max Amount"
                                        );
                                      }

                                      if (
                                        overallClaimAmount &&
                                        Number(parsedAmount) >
                                          Number(overallClaimAmount)
                                      ) {
                                        handleModalContentVisibility(
                                          "Overall  Expected Insurance Amount cannot be greater than Total Fee"
                                        );
                                      }
                                      populateInsuranceSchedules(
                                        values,
                                        setValues,
                                        index
                                      );
                                      setFieldValue(
                                        `insuranceProviders[${index}].overallExpectedAmount`,
                                        amount
                                      );
                                    }}
                                  />
                                </Col>
                                <Col span={12}>
                                  <CurrencyField
                                    title="Initial Insurance Payout Ratio"
                                    name={`insuranceProviders[${index}].insurancePayoutRatio`}
                                    placeholder="Enter Insurance Payout Ratio"
                                    hint="Initial Insurance Payout Ratio should not exceed 100%"
                                    value={
                                      values?.insuranceProviders?.[index]
                                        ?.insurancePayoutRatio
                                    }
                                    disabled={
                                      !checkboxValues?.isInsurance?.[index]
                                        ?.isVisible
                                    }
                                    onValueChange={(percentage) => {
                                      if (getNumber(percentage) > 100)
                                        handleModalContentVisibility(
                                          "Initial Insurance Payout Ratio is greater than 100%"
                                        );
                                      const overallClaimAmount = getNumber(
                                        values?.insuranceProviders?.[index]
                                          ?.overallClaimAmount
                                      );
                                      const payoutRatio = getNumber(
                                        values?.insuranceProviders?.[index]
                                          ?.payoutRatio
                                      );
                                      const updatedInitialClaimAmount =
                                        (getNumber(percentage) *
                                          getNumber(overallClaimAmount)) /
                                        100;
                                      const updatedInitialExpectedAmount =
                                        (getNumber(payoutRatio) *
                                          updatedInitialClaimAmount) /
                                        100;

                                      populateInsuranceSchedules(
                                        values,
                                        setValues,
                                        index,
                                        {
                                          insurancePayoutRatio: getNumber(
                                            percentage
                                          ),
                                          initialClaimAmount: updatedInitialClaimAmount,
                                          initialExpectedAmount: updatedInitialClaimAmount,
                                        }
                                      );

                                      setFieldValue(
                                        `insuranceProviders.${index}.insurancePayoutRatio`,
                                        percentage
                                      );
                                      setFieldValue(
                                        `insuranceProviders.${index}.initialClaimAmount`,
                                        updatedInitialClaimAmount
                                      );
                                      setFieldValue(
                                        `insuranceProviders.${index}.initialExpectedAmount`,
                                        updatedInitialExpectedAmount
                                      );
                                    }}
                                  />
                                </Col>
                                <Col span={12}></Col>
                                <Col span={12}>
                                  <CurrencyField
                                    title="Initial Insurance Claim Amount"
                                    name={`insuranceProviders[${index}].initialClaimAmount`}
                                    placeholder="Enter Initial Claim Amount"
                                    disabled={
                                      !checkboxValues?.isInsurance?.[index]
                                        ?.isVisible
                                    }
                                    onValueChange={(amount) => {
                                      const payoutRatio =
                                        values?.insuranceProviders?.[index]
                                          ?.payoutRatio;
                                      const parsedAmount = amount
                                        ? Number(amount)
                                        : undefined;
                                      if (
                                        Number(
                                          values?.insuranceProviders?.[index]
                                            ?.overallClaimAmount || 0
                                        ) < Number(parsedAmount)
                                      )
                                        handleModalContentVisibility(
                                          "Initial Insurance Claim Amount cannot be greater than Overall Claim Amount"
                                        );

                                      populateInsuranceSchedules(
                                        values,
                                        setValues,
                                        index,
                                        {
                                          initialClaimAmount: parsedAmount,
                                        }
                                      );
                                      setFieldValue(
                                        `insuranceProviders[${index}].initialClaimAmount`,
                                        amount
                                      );
                                      setFieldValue(
                                        `insuranceProviders[${index}].initialExpectedAmount`,
                                        Number(amount) *
                                          (Number(payoutRatio) / 100)
                                      );
                                    }}
                                  />
                                </Col>

                                <Col span={12}>
                                  <CurrencyField
                                    title="Initial Insurance Payout Amt"
                                    name={`insuranceProviders[${index}].initialExpectedAmount`}
                                    placeholder="Initial Expected Amount"
                                    disabled={
                                      !checkboxValues?.isInsurance?.[index]
                                        ?.isVisible
                                    }
                                    onValueChange={(amount) => {
                                      const parsedAmount = amount
                                        ? Number(amount)
                                        : undefined;

                                      if (
                                        Number(
                                          values?.insuranceProviders?.[index]
                                            ?.overallExpectedAmount || 0
                                        ) < Number(parsedAmount)
                                      )
                                        handleModalContentVisibility(
                                          "Initial Expected Amount cannot be greater than Overall Expected Amount"
                                        );
                                      if (
                                        Number(
                                          values?.insuranceProviders?.[index]
                                            ?.initialClaimAmount || 0
                                        ) < Number(parsedAmount)
                                      )
                                        handleModalContentVisibility(
                                          "Initial Expected Insurance Amount cannot be greater than Initial Claim Insurance Amount"
                                        );

                                      populateInsuranceSchedules(
                                        values,
                                        setValues,
                                        index,
                                        {
                                          initialExpectedAmount: parsedAmount,
                                        }
                                      );
                                      setFieldValue(
                                        `insuranceProviders[${index}].initialExpectedAmount`,
                                        amount
                                      );
                                    }}
                                  />
                                </Col>

                                <Col span={12}>
                                  <DropdownField
                                    title="No of Insurance Dues"
                                    name={`insuranceProviders[${index}].noOfDues`}
                                    options={noOfDuesOptions}
                                    value={
                                      values.insuranceProviders &&
                                      values?.insuranceProviders[index]
                                        ?.noOfDues
                                    }
                                    setFieldValue={setFieldValue}
                                    placeHolder="Select Number of due"
                                    disabled={
                                      !checkboxValues?.isInsurance?.[index]
                                        ?.isVisible
                                    }
                                    onChange={(val) => {
                                      populateInsuranceSchedules(
                                        values,
                                        setValues,
                                        index,
                                        { noOfDues: val }
                                      );
                                      // if (
                                      //   values.insuranceProviders &&
                                      //   values.insuranceProviders[index]
                                      // ) {
                                      //   values.insuranceProviders[
                                      //     index
                                      //   ].noOfDues = val;

                                      //   const data = values?.insuranceProviders?.[
                                      //     index
                                      //   ]?.insuranceSchedule?.filter(
                                      //     (insValue, insIndex) => {
                                      //       if (insIndex < val) return insValue;
                                      //     }
                                      //   );
                                      //   setFieldValue(
                                      //     `insuranceProviders[${index}].insuranceSchedule`,
                                      //     data
                                      //   );
                                      // values.insuranceProviders[
                                      //   index
                                      // ].insuranceSchedule = data;
                                      // }
                                    }}
                                  />
                                </Col>

                                <Col span={12}>
                                  <DropdownField
                                    title="Due Time"
                                    name={`insuranceProviders[${index}].dueTime`}
                                    options={dueTimeOptions}
                                    value={
                                      values.insuranceProviders &&
                                      values?.insuranceProviders[index]?.dueTime
                                    }
                                    onChange={(value) => {
                                      populateInsuranceSchedules(
                                        values,
                                        setValues,
                                        index,
                                        { dueTime: value }
                                      );
                                      handleGetDates(value);
                                    }}
                                    setFieldValue={setFieldValue}
                                    placeHolder="Select Due Time"
                                    disabled={
                                      !checkboxValues?.isInsurance?.[index]
                                        ?.isVisible
                                    }
                                  />
                                </Col>
                                <Col span={24}>
                                  {values && (
                                    <Button
                                      type="primary"
                                      className="w-100"
                                      onClick={() => {
                                        // handleInsuranceVisibility(index);
                                        if (
                                          insuranceVisibility[index]?.id !=
                                          index
                                        ) {
                                          populateInsuranceSchedules(
                                            values,
                                            setValues,
                                            index
                                          );

                                          setInsuranceVisibility([
                                            ...insuranceVisibility,
                                            {
                                              isVisible: true,
                                              id: index,
                                            },
                                          ]);
                                        }
                                      }}
                                      disabled={
                                        !values?.insuranceProviders?.[index]
                                          ?.lifetimeMaxAmount ||
                                        !values?.insuranceProviders?.[index]
                                          ?.payoutRatio ||
                                        !values?.insuranceProviders?.[index]
                                          ?.overallClaimAmount ||
                                        !values?.insuranceProviders?.[index]
                                          ?.overallExpectedAmount ||
                                        !values?.insuranceProviders?.[index]
                                          ?.initialClaimAmount ||
                                        !values?.insuranceProviders?.[index]
                                          ?.initialExpectedAmount ||
                                        !values?.insuranceProviders?.[index]
                                          ?.noOfDues ||
                                        !values?.insuranceProviders?.[index]
                                          ?.dueTime
                                      }
                                    >
                                      Generate monthly schedule
                                    </Button>
                                  )}
                                </Col>
                              </Row>
                            </Col>
                            {insuranceVisibility?.map((val, iIndex) => {
                              if (
                                insuranceVisibility[iIndex]?.isVisible &&
                                insuranceVisibility[iIndex]?.id === index
                              ) {
                                return (
                                  <Col span={12}>
                                    {values?.insuranceProviders?.[
                                      index
                                    ]?.insuranceSchedule?.map(
                                      (_, indexValue) => {
                                        return (
                                          <Row
                                            gutter={[5, 5]}
                                            justify={"space-around"}
                                          >
                                            <Col span={2}>
                                              <InputField
                                                title="Due"
                                                type="number"
                                                name={`insuranceProviders[${index}].insuranceSchedule[${indexValue}].dueNo`}
                                                placeholder="Enter Due"
                                                value={indexValue + 1}
                                                disabled
                                              />
                                            </Col>
                                            <Col span={6}>
                                              <DatePickerField
                                                title="To Date"
                                                format="DD MMMM YYYY"
                                                // value={
                                                //   values?.insuranceProviders?.[
                                                //     index
                                                //   ]?.insuranceSchedule?.[
                                                //     indexValue
                                                //   ]?.dueDate
                                                // }
                                                defaultValue={
                                                  values?.insuranceProviders?.[
                                                    index
                                                  ]?.insuranceSchedule?.[
                                                    indexValue
                                                  ]?.dueDate ??
                                                  moment()
                                                    .add(
                                                      indexValue + 1,
                                                      "month"
                                                    )
                                                    .format(
                                                      `${
                                                        values?.insuranceProviders &&
                                                        values?.insuranceProviders[
                                                          index
                                                        ]?.dueTime?.replace(
                                                          /[^\d\.]*/g,
                                                          ""
                                                        )
                                                      }  MMMM YYYY`
                                                    )
                                                }
                                                name={`insuranceProviders[${index}].insuranceSchedule[${indexValue}].dueDate`}
                                                setFieldValue={setFieldValue}
                                                onChange={(value) => {
                                                  setFieldValue(
                                                    `insuranceProviders[${index}].insuranceSchedule[${indexValue}].dueDate`,
                                                    value?.format("YYYY-MM-DD")
                                                  );
                                                }}
                                                placeholder="Select Date"
                                              />
                                            </Col>

                                            <Col span={4}>
                                              <CurrencyField
                                                title="Claim Amt"
                                                name={`insuranceProviders[${index}].insuranceSchedule[${indexValue}].claimAmount`}
                                                placeholder="Enter Claim Amount"
                                                value={
                                                  values?.insuranceProviders?.[
                                                    index
                                                  ]?.insuranceSchedule?.[
                                                    indexValue
                                                  ]?.claimAmount
                                                }
                                                onValueChange={(amount) => {
                                                  calculateRemainingClaimAmount(
                                                    values
                                                      ?.insuranceProviders?.[
                                                      index
                                                    ]?.overallClaimAmount ?? 0,
                                                    values
                                                      ?.insuranceProviders?.[
                                                      index
                                                    ]?.initialClaimAmount ?? 0,
                                                    index,
                                                    values
                                                  );

                                                  if (
                                                    calculateRemainingClaimAmount(
                                                      values
                                                        ?.insuranceProviders?.[
                                                        index
                                                      ]?.overallClaimAmount ??
                                                        0,
                                                      values
                                                        ?.insuranceProviders?.[
                                                        index
                                                      ]?.initialClaimAmount ??
                                                        0,
                                                      index,
                                                      values
                                                    ) < 0
                                                  )
                                                    handleModalContentVisibility(
                                                      "Total Insurance claim amount cannot be greater than Remaining Insurance Claim amount"
                                                    );

                                                  setFieldValue(
                                                    `insuranceProviders[${index}].insuranceSchedule[${indexValue}].dueNo`,
                                                    indexValue + 1
                                                  );

                                                  if (
                                                    !values
                                                      ?.insuranceProviders?.[
                                                      index
                                                    ]?.insuranceSchedule?.[
                                                      indexValue
                                                    ]?.dueDate
                                                  )
                                                    setFieldValue(
                                                      `insuranceProviders[${index}].insuranceSchedule[${indexValue}].dueDate`,
                                                      values
                                                        ?.insuranceProviders?.[
                                                        index
                                                      ]?.insuranceSchedule?.[
                                                        indexValue
                                                      ]?.dueDate ??
                                                        moment()
                                                          .add(
                                                            indexValue + 1,
                                                            "month"
                                                          )
                                                          .format(
                                                            `YYYY-MM-${
                                                              values?.insuranceProviders &&
                                                              values?.insuranceProviders[
                                                                index
                                                              ]?.dueTime?.replace(
                                                                /[^\d\.]*/g,
                                                                ""
                                                              )
                                                            }`
                                                          )
                                                    );

                                                  setFieldValue(
                                                    `insuranceProviders[${index}].insuranceSchedule[${indexValue}].claimAmount`,
                                                    amount
                                                  );
                                                }}
                                              />
                                            </Col>
                                            <Col span={5}>
                                              <CurrencyField
                                                title="Exp Amt"
                                                name={`insuranceProviders[${index}].insuranceSchedule[${indexValue}].expectedAmount`}
                                                placeholder="Enter Expected Amount"
                                                value={
                                                  values?.insuranceProviders?.[
                                                    index
                                                  ]?.insuranceSchedule?.[
                                                    indexValue
                                                  ]?.expectedAmount
                                                }
                                                onValueChange={(amount) => {
                                                  const parsedAmount = amount
                                                    ? Number(amount)
                                                    : undefined;

                                                  setFieldValue(
                                                    `insuranceProviders[${index}].insuranceSchedule[${indexValue}].expectedAmount`,
                                                    amount
                                                  );

                                                  calculateRemainingExpectedAmount(
                                                    values
                                                      ?.insuranceProviders?.[
                                                      index
                                                    ]?.overallExpectedAmount ??
                                                      0,
                                                    values
                                                      ?.insuranceProviders?.[
                                                      index
                                                    ]?.initialExpectedAmount ??
                                                      0,
                                                    index,
                                                    values
                                                  );
                                                  if (
                                                    calculateRemainingExpectedAmount(
                                                      values
                                                        ?.insuranceProviders?.[
                                                        index
                                                      ]
                                                        ?.overallExpectedAmount ??
                                                        0,
                                                      values
                                                        ?.insuranceProviders?.[
                                                        index
                                                      ]
                                                        ?.initialExpectedAmount ??
                                                        0,
                                                      index,
                                                      values
                                                    ) < 0
                                                  ) {
                                                    handleModalContentVisibility(
                                                      "Total Expected insurance amount cannot be greater than Remaining Expected amount"
                                                    );
                                                  }
                                                  const claimAmount =
                                                    values?.insuranceProviders &&
                                                    values
                                                      ?.insuranceProviders?.[
                                                      index
                                                    ]?.insuranceSchedule?.[
                                                      indexValue
                                                    ]?.claimAmount;
                                                  if (
                                                    claimAmount &&
                                                    Number(parsedAmount) >
                                                      Number(claimAmount || 0)
                                                  ) {
                                                    handleModalContentVisibility(
                                                      "Expected amount cannot be greater than Claim Amount"
                                                    );
                                                  }
                                                }}
                                              />
                                            </Col>
                                            <Col span={6}>
                                              <DropdownField
                                                title="Procedure Code"
                                                name={`insuranceProviders[${index}].insuranceSchedule[${indexValue}].procedureCode`}
                                                options={procedureCodeOption}
                                                value={
                                                  values?.insuranceProviders?.[
                                                    index
                                                  ]?.insuranceSchedule?.[
                                                    indexValue
                                                  ]?.procedureCode
                                                }
                                                setFieldValue={setFieldValue}
                                                placeHolder="Select Procedure Code"
                                                disabled={
                                                  !checkboxValues?.isInsuranceProvider
                                                }
                                              />
                                            </Col>
                                            {values?.insuranceProviders &&
                                              values?.insuranceProviders[index]
                                                ?.noOfDues ===
                                                indexValue + 1 && (
                                                <>
                                                  {" "}
                                                  <Col
                                                    span={14}
                                                    className="ml-1"
                                                  >
                                                    <span className="text-bold">
                                                      Remaining Claim Amount
                                                    </span>
                                                  </Col>
                                                  <Col
                                                    span={10}
                                                    className="ml-1"
                                                  >
                                                    <span className="text-bold">
                                                      ${" "}
                                                      {calculateRemainingClaimAmount(
                                                        values
                                                          ?.insuranceProviders?.[
                                                          index
                                                        ]?.overallClaimAmount ??
                                                          0,
                                                        values
                                                          ?.insuranceProviders?.[
                                                          index
                                                        ]?.initialClaimAmount ??
                                                          0,

                                                        index,
                                                        values
                                                      )}
                                                    </span>
                                                  </Col>
                                                  <Col
                                                    span={14}
                                                    className="ml-1"
                                                  >
                                                    <span className="text-bold">
                                                      Remaining Expected Amount
                                                    </span>
                                                  </Col>
                                                  <Col
                                                    span={10}
                                                    className="ml-1"
                                                  >
                                                    <span className="text-bold">
                                                      ${" "}
                                                      {calculateRemainingExpectedAmount(
                                                        values
                                                          ?.insuranceProviders?.[
                                                          index
                                                        ]
                                                          ?.overallExpectedAmount ??
                                                          0,
                                                        values
                                                          ?.insuranceProviders?.[
                                                          index
                                                        ]
                                                          ?.initialExpectedAmount ??
                                                          0,
                                                        index,
                                                        values
                                                      )}
                                                    </span>
                                                  </Col>
                                                </>
                                              )}
                                          </Row>
                                        );
                                      }
                                    )}
                                  </Col>
                                );
                              }
                            })}
                          </Row>
                        </div>
                      );
                  })}
              </div>
              <CustomModal
                title={modalText}
                isCancelButton={false}
                visible={modalVisible}
                okHandler={() => setModalVisible(false)}
              />
              <div className="mt-5 text-right">
                <Button type="default" onClick={onCancel}>
                  Cancel
                </Button>
                <Button
                  type="primary"
                  className="ml-2 mr-2"
                  onClick={handleBack}
                >
                  Previous
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="ml-2"
                  loading={formLoading}
                  disabled={!isValid || (!isPathEdit && !dirty) || formLoading}
                >
                  Next
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default ContractContainer(ContractPaymentSchedule);
