import { Button, Col, Row } from "antd";
import { Formik, Form } from "formik";
import React, { FC, useState } from "react";
import { AppointmentTemplateSlotCreation } from "../../../../models/AppointmentTemplate/AppointmentTemplateCreateSlot/appointmentTemplateSlotCreation.model";
import { AppointmentTemplateSchedules } from "../../../../models/AppointmentTemplateSchedules/appointmentTemplateSchedules.model";
import AppoitmentTemplate from "../../../../services/AppointmentTemplate/appointmentTemplate.service";
import DropdownField from "../../../../shared/components/DropdownField";
import TimePickerField from "../../../../shared/components/TimePickerField";
import useAppointmentTypes from "../../../../shared/hooks/useAppointmentTypes";
import { appoiontmentTemplateValidation } from "./appoiontmentTemplateValidation";
import "./appointmentSlotForm.scss";

interface AssignAppointmentSlotFormProps {
  templateId: number;
  handleAppointmentSlotVisibility: () => void;
  currentResourceId: string;
  onAddSlot?: (
    resourceId: number,
    slots: AppointmentTemplateSchedules[]
  ) => void;
  onUpdateSlot?: (
    resourceId: number,
    oldSlotId: number,
    slots: AppointmentTemplateSchedules[]
  ) => void;
  existingFormValues?: AppointmentTemplateSlotCreation;
  slotId?: string;
  appointmentTypeId?: number;
  onUpdateAppointmentSlot?: (values: AppointmentTemplateSlotCreation) => void;
}

const AssignAppointmentSlotForm: FC<AssignAppointmentSlotFormProps> = (
  props
) => {
  const {
    options: appointmentTypes,
    fetchAppointmentTypes,
    loading,
  } = useAppointmentTypes({});
  const {
    appointmentTypeId,
    handleAppointmentSlotVisibility,
    currentResourceId,
    templateId,
    onAddSlot,
    onUpdateSlot,
    existingFormValues,
    slotId,
    onUpdateAppointmentSlot,
  } = props;
  const [isSubmitting, setSubmitting] = useState(false);

  const handleUpdateSubmit = (values: AppointmentTemplateSlotCreation) => {
    if (onUpdateAppointmentSlot) return onUpdateAppointmentSlot(values);

    setSubmitting(true);
    AppoitmentTemplate.updateAppointmentTemplateSlot(
      { appointmentTypeId: values.appointmentTypeId },
      templateId,
      String(slotId),
      (templates: AppointmentTemplateSchedules[]) => {
        onUpdateSlot?.(Number(currentResourceId), Number(slotId), templates);
        handleAppointmentSlotVisibility();
      },
      (error: Error) => {
        console.log(error);
      },
      () => {
        setSubmitting(false);
      }
    );
  };
  const handleAddSubmit = (values: AppointmentTemplateSlotCreation) => {
    setSubmitting(true);
    AppoitmentTemplate.createAppointmentTemplateSlot(
      { ...values, resourceId: currentResourceId },
      templateId,
      (templates: AppointmentTemplateSchedules[]) => {
        onAddSlot?.(Number(currentResourceId), templates);
        handleAppointmentSlotVisibility();
      },
      (error: Error) => {
        console.log(error);
      },
      () => {
        setSubmitting(false);
      }
    );
  };

  return (
    <div>
      <Formik
        initialValues={
          existingFormValues ?? new AppointmentTemplateSlotCreation()
        }
        onSubmit={existingFormValues ? handleUpdateSubmit : handleAddSubmit}
        enableReinitialize
        validationSchema={appoiontmentTemplateValidation}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          errors,
          isValid,
          dirty,
          setFieldValue,
          setTouched,
          touched,
        }) => {
          return (
            <Form>
              <div className="appointment-slot-form">
                <Row gutter={[20, 0]}>
                  <Col span={24}>
                    <TimePickerField
                      title="Start time"
                      name="startTime"
                      setFieldValue={setFieldValue}
                      disabled={!!existingFormValues}
                      value={values.startTime}
                      error="not required"
                      onChange={(time) =>
                        setFieldValue(
                          "startTime",
                          time?.format("HH:mm:ss")
                        )
                      }
                      onTouched={() => {
                        setTouched({
                          ...touched,
                          startTime: true,
                        });
                      }}
                    />
                  </Col>
                  <Col span={24}>
                    <TimePickerField
                      title="End time"
                      name="endTime"
                      setFieldValue={setFieldValue}
                      disabled={!!existingFormValues}
                      value={values.endTime}
                      onChange={(time) =>
                        setFieldValue(
                          "endTime",
                          time?.format("HH:mm:ss")
                        )
                      }
                    />
                  </Col>
                  <Col span={24}>
                    <DropdownField
                      title="Appointment Type"
                      name="appointmentTypeId"
                      options={appointmentTypes}
                      value={values.appointmentTypeId}
                      setFieldValue={setFieldValue}
                      loading={loading}
                      onScrollEnd={fetchAppointmentTypes}
                      placeHolder="Select Appointment Type"
                    />
                  </Col>
                </Row>
              </div>
              <div className="user-form__submit-wrapper text-right mt-4">
                <Button
                  onClick={handleAppointmentSlotVisibility}
                  type="default"
                >
                  Cancel
                </Button>
                <Button
                  type="primary"
                  className="ml-2"
                  htmlType="submit"
                  loading={isSubmitting}
                  // loading={formLoading}
                  // disabled={!isValid || !dirty}
                >
                  {existingFormValues ? "Update" : "Save"}
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default AssignAppointmentSlotForm;
