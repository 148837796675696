import ApiRoutes from "../../routes/routeConstants/apiRoutes";
import axiosInstance from "../../interceptor/axiosInstance";
import {
  AppointmentType,
  AppointmentTypes,
} from "../../models/AppointmentType/appointmentType.model";
import { deserialize, serialize } from "serializr";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import { Suggestion } from "../../models/Suggestion/suggestion.model";
import { AppointmentTemplate } from "../../models/AppointmentTemplate/appointmentTemplate.model";
import { AppointmentTemplateSlotCreation } from "../../models/AppointmentTemplate/AppointmentTemplateCreateSlot/appointmentTemplateSlotCreation.model";
import { AppointmentTemplateSchedules } from "../../models/AppointmentTemplateSchedules/appointmentTemplateSchedules.model";
import { generatePath } from "react-router-dom";
import moment from "moment";

export default class AppoitmentTemplateService {
  static fetchAppointmentTemplate(
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .get(ApiRoutes.APPOINTMENT_TEMPLATES)
      .then((response) => {
        const template = deserialize(
          AppointmentTemplate,
          response.data["appointment_templates"]
        );
        onSuccess(template);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }
  static fetchAppointmentTemplateSchedules(
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .get(ApiRoutes.APPOINTMENT_TEMPLATE_SCHEDULE_LIST)
      .then((response) => {
        const template = deserialize(
          AppointmentTemplateSchedules,
          response.data["appointment_schedules"]
        );
        onSuccess(template);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static fetchAppointmentTemplateDetails(
    templateId: string,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .get(
        ApiRoutes.APPOINTMENT_TEMPLATE_DETAIL.replace(":templateId", templateId)
      )
      .then((response) => {
        const template = deserialize(
          AppointmentTemplate,
          response.data["appointment_template"]
        );
        onSuccess(template);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static createAppointmentTemplate(
    appointmentTemplate: AppointmentTemplate,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const appointmentTemplateJSON = serialize(appointmentTemplate);

    axiosInstance
      .post(ApiRoutes.APPOINTMENT_TEMPLATES, {
        appointment_template: appointmentTemplateJSON,
      })
      .then((response) => {
        const template = deserialize(
          AppointmentTemplate,
          response.data["appointment_template"]
        );
        onSuccess(template);
      })
      .catch((err) => {
        Notification({
          message: "Appointment Template Creation Failed",
          description: err?.message,
          type: NotificationTypes.ERROR,
        });
      })
      .finally(() => {
        onFinal();
      });
  }

  static createAppointmentTemplateSlot(
    appointmentType: AppointmentTemplateSlotCreation,
    templateId: number,

    onSuccess: (appointmentType: AppointmentTemplateSchedules[]) => void,
    onError: Function,
    onFinal: () => void
  ) {
    const appointmentTypeJSON = serialize(
      AppointmentTemplateSlotCreation,
      appointmentType
    );
    axiosInstance
      .post(
        ApiRoutes.CREATE_APPOINTMENT_TEMPLATE.replace(
          ":templateId",
          String(templateId)
        ),
        {
          appointment_template_slot: appointmentTypeJSON,
        }
      )
      .then((response) => {
        const appointmentSlots = deserialize(
          AppointmentTemplateSchedules,
          response.data["appointment_template_slots"]
        ) as AppointmentTemplateSchedules[];
        Notification({
          message: `Successfully Created ${
            response.data?.slots_created ?? 0
          } slots`,
          type: NotificationTypes.SUCCESS,
        });
        onSuccess(appointmentSlots);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }
  static updateAppointmentTemplateSlot(
    appointmentTemplateSlotId: AppointmentTemplateSlotCreation,
    templateId: number,
    slotId: string,
    onSuccess: (appointmentType: AppointmentTemplateSchedules[]) => void,
    onError: Function,
    onFinal: () => void
  ) {
    const appointmentTypeJSON = serialize(
      AppointmentTemplateSlotCreation,
      appointmentTemplateSlotId
    );
    axiosInstance
      .put(
        ApiRoutes.UPDATE_APPOINTMENT_TEMPLATE.replace(
          ":templateId",
          String(templateId)
        ).replace(":appointmentTemplateSlotId", slotId),
        {
          appointment_template_slot: appointmentTypeJSON,
        }
      )
      .then((response) => {
        const appointmentType = deserialize(
          AppointmentTemplateSchedules,
          response.data["appointment_template_slots"]
        ) as AppointmentTemplateSchedules[];
        Notification({
          message: `Appointment Template Slot Updated and ${response.data.slots_created} new slots added`,
          type: NotificationTypes.SUCCESS,
        });
        onSuccess(appointmentType);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static updateAppointmentType(
    appointmentType: AppointmentType,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.APPOINTMENT_TYPES + "/" + appointmentType?.id;
    const appointmentTypeJSON = serialize(appointmentType);
    axiosInstance
      .put(API_URL, { appointment_type: appointmentTypeJSON })
      .then((response) => {
        const appointmentType = deserialize(
          AppointmentType,
          response.data["appointment_type"]
        );
        Notification({
          message: "Appointment Updated! ",
          type: NotificationTypes.SUCCESS,
        });
        onSuccess(appointmentType);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static deleteAppointmentType(
    templateId: number,
    appointmentTypeSlotId: number,
    onSuccess: () => void,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL =
      ApiRoutes.DELETE_APPOINTMENT_TEMPLATE.replace(
        ":templateId",
        templateId.toString()
      ) +
      "/" +
      appointmentTypeSlotId;
    axiosInstance
      .delete(API_URL)
      .then((response) => {
        Notification({
          message: "Appointment Deleted! ",
          type: NotificationTypes.SUCCESS,
        });
        onSuccess();
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }
  static assignAppointmentTemplate(
    templateId: number,
    date: string[],
    onSuccess: () => void,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.APPOINTMENT_TEMPLATE_SCHEDULE;

    axiosInstance
      .post(API_URL, {
        appointment_schedule: {
          template_id: templateId,
          dates: date,
        },
      })
      .then((response) => {
        if (response.data.template_not_assigned_on) {
          response.data.template_not_assigned_on?.map((val: any) => {
            Notification({
              message: `Template cannot be assigned on ${moment(val).format(
                "MM-DD-YYYY"
              )}`,
              type: NotificationTypes.ERROR,
            });
          });
        } else {
          Notification({
            message: "Template Assigned! ",
            type: NotificationTypes.SUCCESS,
          });
        }

        onSuccess();
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }
  static deleteAppointmentTemplateSlot(
    templateId: string,
    slotId: string,
    onSuccess: Function,
    onError: Function,
    onFinal: Function
  ) {
    const endPoint = ApiRoutes.DELETE_APPOINTMENT_TEMPLATE.replace(
      ":templateId",
      templateId
    ).replace(":appointmentTemplateSlotId", slotId);
    axiosInstance
      .delete(endPoint)
      .then(() => {
        onSuccess();
        Notification({
          message: "Slot Deleted",
          type: NotificationTypes.SUCCESS,
        });
      })
      .catch(() => {
        onError();
        Notification({
          message: "Error Deleting Slot",
          type: NotificationTypes.ERROR,
        });
      })
      .finally(() => {
        onFinal();
      });
  }

  static deleteAppointmentTemplateSchedule(
    scheduleId: string,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.DELETE_APPOINTMENT_SCHEDULE + "/" + scheduleId;
    axiosInstance
      .delete(API_URL)
      .then((response) => {
        onSuccess();
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }
}
