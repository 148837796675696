import { serializable, alias, object, list, primitive } from "serializr";

class SuggestedAppointment {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("title", primitive()))
  title?: string;

  @serializable(alias("nick_name", primitive()))
  nickName?: string;
}
export class Suggestion {
  @serializable(alias("suggested", object(SuggestedAppointment)))
  SuggestedAppointment?: SuggestedAppointment;
}
