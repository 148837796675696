import { CreateReducer } from "../../shared/utils/createReducer";
import { RESET_CONTRACT, SET_CONTRACT } from "../definitions/contractConstants";
import { Contract } from "../../models/Contract/contract.model";

export interface ContractState {
  contract: Contract;
}

export interface Action {
  type: string;
  payload?: Contract;
}

export interface ContractReducerProps extends ContractState {
  setContract: (contract: Contract) => Action;
  resetContract: () => Action;
}

const initState: ContractState = {
  contract: new Contract(),
};

const contractReducer = CreateReducer(initState, {
  [SET_CONTRACT](state: ContractState, action: Action): ContractState {
    const contract = action.payload;
    return {
      ...state,
      contract: contract as Contract,
    };
  },
  [RESET_CONTRACT](): ContractState {
    return initState;
  }
});

export default contractReducer;
