import ApiRoutes from "../../routes/routeConstants/apiRoutes";
import axiosInstance from "../../interceptor/axiosInstance";
import { MedicalAlert } from "../../models/MedicalAlert/medicalAlert.model";
import { deserialize, serialize } from "serializr";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";

export default class MedicalAlertService {
  static fetchMedicalAlerts(
    patientProfileId: number,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.MEDICAL_ALERTS.replace(
      ":patientProfileId",
      patientProfileId.toString()
    );
    axiosInstance
      .get(API_URL)
      .then((response) => {
        const medicalAlerts = deserialize(
          MedicalAlert,
          response.data["medical_alerts"]
        );
        onSuccess(medicalAlerts);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static createMedicalAlert(
    patientProfileId: number,
    medicalAlert: MedicalAlert,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.MEDICAL_ALERTS.replace(
      ":patientProfileId",
      patientProfileId.toString()
    );
    const medicalAlertJSON = { medical_alert: serialize(medicalAlert) };
    axiosInstance
      .post(API_URL, medicalAlertJSON)
      .then((response) => {
        Notification({
          message: "Medical alert created!",
          type: NotificationTypes.SUCCESS,
        });
        const medicalAlert = deserialize(
          MedicalAlert,
          response.data["medical_alert"]
        );
        onSuccess(medicalAlert);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static updateMedicalAlert(
    patientProfileId: number,
    medicalAlert: MedicalAlert,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL =
      ApiRoutes.MEDICAL_ALERTS.replace(
        ":patientProfileId",
        patientProfileId.toString()
      ) +
      "/" +
      medicalAlert.id;
    const medicalAlertJSON = { medical_alert: serialize(medicalAlert) };
    axiosInstance
      .put(API_URL, medicalAlertJSON)
      .then((response) => {
        Notification({
          message: "Medical alert updated!",
          type: NotificationTypes.SUCCESS,
        });
        const medicalAlert = deserialize(
          MedicalAlert,
          response.data["medical_alert"]
        );
        onSuccess(medicalAlert);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static deleteMedicalAlert(
    patientProfileId: number,
    medicalAlertId: number,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL =
      ApiRoutes.MEDICAL_ALERTS.replace(
        ":patientProfileId",
        patientProfileId.toString()
      ) +
      "/" +
      medicalAlertId;
    axiosInstance
      .delete(API_URL)
      .then((response) => {
        Notification({
          message: "Medical alert deleted!",
          type: NotificationTypes.SUCCESS,
        });
        onSuccess();
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }
}
