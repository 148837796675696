import { removeFalsyKeys } from "./../../utils/removeFalsyKeys";
import { useLocation, useHistory } from "react-router-dom";
import { objectToIndexedQueryString } from "../../utils/objectToQueryHelper";

const useFilters = () => {
  const history = useHistory();
  const location = useLocation();
  const urlParams = new URLSearchParams(window.location.search);

  const getParams = () => {
    const params: any = {};
    var UrlKeys: any = urlParams.keys();
    const singleValueQuery = [
      "page",
      "search",
      "startDate",
      "endDate",
      "status",
    ];

    for (let key of UrlKeys) {
      const value = singleValueQuery?.includes(key)
        ? urlParams.get(key)
        : urlParams.getAll(key);

      if (!singleValueQuery?.includes(key) && Array.isArray(value)) {
        const fliterArr = value?.map((val) => Number(val));
        params[key] = fliterArr;
      } else params[key] = Number(value) || value;
    }

    return params;
  };

  const setParams = (filters?: object, hashValue?: string) => {
    const params = objectToIndexedQueryString(removeFalsyKeys(filters));

    history.replace({
      pathname: location.pathname,
      hash: hashValue ?? location?.hash,
      search: params.toString(),
    });
  };

  return {
    getParams,
    setParams,
    location,
  };
};

export default useFilters;
