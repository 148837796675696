import { CustomResponseField } from "../CustomResponseField/customResponseField.model";

import { serializable, alias, object, list, primitive } from "serializr";
import { CustomResponseSection } from "../CustomResponseSection/customResponseSection.model";

export class CustomFormResponse {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("custom_form_request_id", primitive()))
  customFormRequestId?: number;

  @serializable(alias("custom_form_name", primitive()))
  customFormName?: string;

  @serializable(alias("custom_form_type", primitive()))
  customFormType?: string;

  @serializable(alias("custom_form_description", primitive()))
  customFormDescription?: string;

  @serializable(alias("custom_form_request_created_by", primitive()))
  customFormRequestCreatedById?: string;

  @serializable(alias("custom_form_request_created_by_name", primitive()))
  customFormRequestCreatedByName?: string;

  @serializable(alias("filled_by_id", primitive()))
  filledById?: number;

  @serializable(alias("filled_by_name", primitive()))
  filledByName?: string;

  @serializable(alias("filled_by_type", primitive()))
  filledByType?: string;

  @serializable(
    alias("custom_response_fields", list(object(CustomResponseField)))
  )
  customResponseFields?: CustomResponseField[] = [];

  @serializable(
    alias("custom_response_sections", list(object(CustomResponseSection)))
  )
  customResponseSections?: CustomResponseSection[] = [];

  groupSections = (): CustomResponseSection[] => {
    const map = new Map<number, CustomResponseSection>();
    const groupedSections: CustomResponseSection[] = [];

    this.customResponseSections?.forEach((section) => {
      const { id } = section;

      if (!id) return;

      if (map.has(id)) {
        const mappedSections = map.get(id)!;

        map.set(id, {
          ...mappedSections,
          customFields: [
            ...(mappedSections?.customFields || []),
            ...(section?.customFields || []),
          ],
        });
      } else map.set(id, section);
    });

    map?.forEach((section) => groupedSections.push(section));

    return groupedSections;
  };
}
