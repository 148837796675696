import {
  serializable,
  alias,
  object,
  list,
  primitive,
  custom,
} from "serializr";

export class PatientDocument {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(
    alias(
      "attachment",
      custom(
        (file) => file,
        (json) => {}
      )
    )
  )
  attachment?: File;

  @serializable(alias("attachment_name", primitive()))
  attachmentName?: string;

  @serializable(alias("attachment_url", primitive()))
  attachmentUrl?: string;
}
