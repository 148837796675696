import moment from "moment";
import React, { FC, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { AppointmentTemplate } from "../../../../models/AppointmentTemplate/appointmentTemplate.model";
import AppoitmentTemplateService from "../../../../services/AppointmentTemplate/appointmentTemplate.service";
import {
  isActualTimeMaximum,
  isActualTimeMinimum,
} from "../../../../shared/utils/time";
import CreateAppointmentTable from "../CreateAppointmentTable";
import "./appointmentTemplateWrapper.scss";
import AppLoader from "../../../../shared/components/AppLoader";
import { AppointmentTemplateSchedules } from "../../../../models/AppointmentTemplateSchedules/appointmentTemplateSchedules.model";
import { TimePicker } from "antd";
import { Moment } from "moment";

interface AppointmentTemplateProps {
  values: AppointmentTemplate;
}
const AppointmentTemplateWrapper: FC<AppointmentTemplateProps> = (props) => {
  const { templateId }: { templateId: string } = useParams();
  const history = useHistory();
  const [templateData, setTemplateData] = useState<AppointmentTemplate>(
    new AppointmentTemplate()
  );
  const [loading, setLoading] = useState(false);
  const [startTime, setStartTime] = useState<Moment>(
    moment("0001-01-01T08:00:00")
  );
  const [endTime, setEndTime] = useState<Moment>(moment("0001-01-01T17:00:00"));

  const handleAddSlots = (
    resourceId: number,
    slots: AppointmentTemplateSchedules[]
  ) => {
    setTemplateData((templateData) => {
      const resources = [...templateData?.resources];
      const addedResourceIndex = resources.findIndex(
        (resource) => resource.resourceId === resourceId
      );
      resources[addedResourceIndex].schedules = [
        ...resources[addedResourceIndex].schedules,
        ...slots,
      ];
      return { ...templateData, resources };
    });

    let tempStartTime = startTime;
    let tempEndTime = endTime;
    slots.forEach((slot) => {
      if (isActualTimeMinimum(tempStartTime.format("hh:mma"), slot.startTime))
        tempStartTime = moment(slot.startTime);

      if (isActualTimeMaximum(tempEndTime.format("hh:mma"), slot.endTime))
        tempEndTime = moment(slot.endTime);
    });
    setStartTime(tempStartTime);
    setEndTime(tempEndTime);
  };

  const handleUpdateSlot = (
    resourceId: number,
    oldSlotId: number,
    slots: AppointmentTemplateSchedules[]
  ) => {
    setTemplateData((templateData) => {
      const resources = [...templateData?.resources];
      const updatedResourceIndex = resources.findIndex(
        (resource) => resource.resourceId === resourceId
      );
      resources[updatedResourceIndex].schedules = [
        ...resources[updatedResourceIndex].schedules.filter(
          (slot) => slot.id !== oldSlotId
        ),
        ...slots,
      ];
      return { ...templateData };
    });
  };

  const handleDeleteSlot = (resourceId: number, slotId: number) => {
    setTemplateData((templateData) => {
      const templateDataResources = [...templateData.resources];
      const modifiedResourceIndex = templateDataResources.findIndex(
        (resource) => resource.resourceId === resourceId
      );
      templateDataResources[modifiedResourceIndex].schedules = [
        ...templateDataResources[modifiedResourceIndex].schedules.filter(
          (slot) => slot.id !== slotId
        ),
      ];

      templateData.resources = templateDataResources;
      return { ...templateData };
    });
  };

  const updateStartTime = (time?: string) => {
    if (isActualTimeMinimum("08:00am", time)) setStartTime(moment(time));
    else setStartTime(moment(time).set({ h: 8, m: 0, s: 0 }));
  };

  const updateEndTime = (time?: string) => {
    if (isActualTimeMaximum("05:00pm", time)) setEndTime(moment(time));
    else setEndTime(moment(time).set({ h: 17, m: 0, s: 0 }));
  };

  useEffect(() => {
    setLoading(true);

    AppoitmentTemplateService.fetchAppointmentTemplateDetails(
      templateId,
      (template: AppointmentTemplate) => {
        setTemplateData(template);
        updateStartTime(template?.startTime ?? "0001-01-01T08:00:00");
        updateEndTime(template?.endTime ?? "0001-01-01T17:00:00");
      },
      () => {},
      () => {
        setLoading(false);
      }
    );
  }, []);

  return loading ? (
    <AppLoader loading />
  ) : (
    <div className="appointment-template-wrapper">
      <div className="appointment-template-wrapper__header">
        <div
          className="appointment-template-wrapper__header__back"
          onClick={() => history.goBack()}
        ></div>
        <div className="appointment-template-wrapper__header__heading">
          {templateData?.templateName}
        </div>
        <div className="appointment-template-wrapper__header__time">
          <TimePicker.RangePicker
            allowClear={false}
            bordered={false}
            value={[startTime, endTime]}
            format="hh:mm a"
            onChange={(timeRange) => {
              setStartTime(timeRange?.[0] ?? moment());
              setEndTime(timeRange?.[1] ?? moment());
            }}
            picker="time"
            separator="-"
            suffixIcon=""
            use12Hours
          />
        </div>
      </div>
      <CreateAppointmentTable
        selectedStartTime={startTime}
        selectedEndTime={endTime}
        timeInterval={20}
        appointmentTemplateData={templateData}
        onAddSlot={handleAddSlots}
        onDeleteSlot={handleDeleteSlot}
        onUpdateSlot={handleUpdateSlot}
      />
    </div>
  );
};

export default AppointmentTemplateWrapper;
