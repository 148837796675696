import { Button, Col, Radio, Row } from "antd";
import { Form, Formik } from "formik";
import moment from "moment";
import React, { FC, Fragment, useEffect, useState } from "react";
import {
  Appointment,
  InteractionAppointment,
} from "../../../models/Appointment/appointment.model";
import { Contract } from "../../../models/Contract/contract.model";
import { Interaction } from "../../../models/Interaction/interaction.model";
import { Remainder } from "../../../models/Remainder/remainder.model";
import { AppointmentService } from "../../../services/Appointment/appointment.service";
import ContractService from "../../../services/Contract/Contract.service";
import { ContractService as ContractServiceModel } from "../../../models/ContractService/contractService.model";
import { IDropdownOptions } from "../../../services/Meta/meta.service";
import CheckboxField from "../../../shared/components/Checkbox";
import DatePickerField from "../../../shared/components/DatePickerField";
import DropdownField from "../../../shared/components/DropdownField";
import InputField from "../../../shared/components/InputField";
import RadioInputField from "../../../shared/components/RadioInputField";
import Searchbar from "../../../shared/components/Searchbar";
import useAppointmentTypes from "../../../shared/hooks/useAppointmentTypes";
import { usePatients } from "../../../shared/hooks/Patient/usePatient";
import { useResource } from "../../../shared/hooks/Resource/useResource";
import { useDoctors } from "../../../shared/hooks/User/useDoctors";
import { SelectProp } from "../../../shared/Types/option";
import { getTimeDiff } from "../../../shared/utils/dateHelpers";
import "./createAppointment.scss";

export const visitTypeOptions = [
  {
    label: "Virtual Consultation",
    optionalLabel: "Virtual Consultation",
    value: "virtual_consultation",
  },
  {
    label: "In person",
    optionalLabel: "In person",
    value: "in_person",
  },
];

export const HasConfirmDate = [
  {
    label: "Yes",
    value: "Yes",
  },
  {
    label: "No",
    value: "No",
  },
];

interface ContractProps extends IDropdownOptions, Contract {}

interface CreateAppointmentProps {
  patientProfileId: number;
  interaction?: Interaction;
  onSuccessHandler: () => void;
  closeHandler: () => void;
}

const CreateAppointment: FC<CreateAppointmentProps> = (props) => {
  const {
    patientProfileId,
    interaction,
    onSuccessHandler,
    closeHandler,
  } = props;

  const [loader, setLoader] = useState(false);

  const [contracts, setContracts] = useState<ContractProps[]>([]);

  const [
    contractService,
    setContractService,
  ] = useState<ContractServiceModel>();

  const {
    options: appointmentTypes,
    loading: appointmentTypesLoading,
    fetchAppointmentTypes,
  } = useAppointmentTypes({});

  const resources = useResource("chair", "option");

  const [formValues, setFormValues] = useState<InteractionAppointment>(
    new InteractionAppointment()
  );

  useEffect(() => {
    ContractService.fetchPatientContracts(
      patientProfileId,
      (contracts) => {
        const contractOptions = contracts.map((contract) => ({
          ...contract,
          label: contract.name,
          value: contract.id,
          contractService: {
            ...contract?.contractService,
            label: contract?.contractService?.serviceName ?? "",
            value: contract?.contractService?.id,
          },
        }));
        setContracts(contractOptions);
      },
      () => {},
      () => {}
    );
  }, []);

  useEffect(() => {
    setFormValues((values) => ({
      ...values,
      contractId: interaction?.contractId,
      contractServiceId: interaction?.contractServiceId,
    }));

    if (interaction?.contractServiceId) {
      contracts.forEach((contract) => {
        if (contract?.id === interaction?.contractId) {
          setContractService(contract?.contractService);
        }
      });
    }
  }, [interaction, contracts]);

  const handleSubmit = (values: InteractionAppointment) => {
    setLoader(true);
    if (values.confirmDate === "Yes") {
      const appointment = Object.assign(new Appointment(), values);
      AppointmentService.createAppointment(
        appointment,
        (appointment) => {
          onSuccessHandler();
        },
        () => {},
        () => {
          setLoader(false);
        }
      );
    } else {
      const remainder = Object.assign(new Remainder(), values);
      AppointmentService.createRemainder(
        remainder,
        () => {
          onSuccessHandler();
        },
        () => {
          setLoader(false);
        }
      );
    }
  };

  return (
    <div className="create-appointment">
      <Formik
        initialValues={formValues}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({
          values,
          dirty,
          isValid,
          setFieldValue,
          setFieldError,
          setFieldTouched,
          setErrors,
        }) => {
          const handleValidateTime = () => {
            const appointmentDifference = getTimeDiff(
              interaction?.appointment?.startTime,
              interaction?.appointment?.endTime
            );
            if (values?.startTime && values.endTime) {
              const currentDifference = getTimeDiff(
                values?.startTime,
                values.endTime
              );

              if (appointmentDifference > Math.abs(currentDifference)) {
                setFieldTouched("startTime", true);
                setFieldTouched("endTime", true);
                setErrors({
                  startTime:
                    "Next appointment time is greater than the appointment time",
                  endTime:
                    "Next appointment time is greater than the appointment time",
                });
              }
            }
          };

          return (
            <Form>
              <Row gutter={[15, 15]}>
                <Col span={12}>
                  <InputField
                    type="text"
                    title="Amount (Payment for this interaction)"
                    name="amount"
                  />
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <CheckboxField
                    name="scheduleAppointment"
                    content="Schedule next appointment form a treatment execution plan"
                    setFieldValue={setFieldValue}
                    value={values?.scheduleAppointment ?? false}
                  />
                </Col>
              </Row>
              {values.scheduleAppointment ? (
                <Fragment>
                  <Row gutter={[15, 15]}>
                    <Col span={12}>
                      <DropdownField
                        title="Contract"
                        name="contractId"
                        options={contracts}
                        value={values.contractId}
                        onChange={(value) => {
                          const [contract] = contracts.filter(
                            (contract) => contract?.id === value
                          );
                          setFieldValue("contractId", value);
                          setFieldValue("contractServiceId", "");
                          setContractService(contract.contractService);
                        }}
                      />
                    </Col>
                    <Col span={12}>
                      <DropdownField
                        title="Service"
                        name="contractServiceId"
                        value={
                          values?.contractId
                            ? values.contractServiceId
                            : undefined
                        }
                        options={
                          contractService
                            ? [
                                {
                                  label: contractService?.serviceName,
                                  value: contractService?.id,
                                },
                              ]
                            : []
                        }
                        setFieldValue={setFieldValue}
                        disabled={!values?.contractId}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24} className="date-toggle mb-4">
                      Do you have a confirm date for scheduling appointment?
                      <Radio.Group
                        defaultValue={"Yes"}
                        className="mt-3"
                        options={HasConfirmDate}
                        optionType="button"
                        buttonStyle="solid"
                        value={values.confirmDate}
                        onChange={(e) => {
                          setFieldValue("confirmDate", e.target.value);
                        }}
                      />
                    </Col>
                  </Row>
                  {values.confirmDate === "Yes" ? (
                    <Fragment>
                      <Row gutter={[15, 15]}>
                        <Col span={12}>
                          <DatePickerField
                            title="Date"
                            name="date"
                            setFieldValue={setFieldValue}
                            defaultValue={values.date}
                          />
                        </Col>
                        <Col span={12}>
                          <DropdownField
                            title="Appointment Type"
                            name="appointmentTypeId"
                            setFieldValue={setFieldValue}
                            options={appointmentTypes}
                            onScrollEnd={fetchAppointmentTypes}
                            loading={appointmentTypesLoading}
                            value={values.appointmentTypeId}
                          />
                        </Col>
                      </Row>
                      <Row gutter={[15, 15]}>
                        <Col span={12}>
                          <DatePickerField
                            title="Start Time"
                            name="startTime"
                            setFieldValue={setFieldValue}
                            type="time"
                            defaultValue={values.startTime}
                            onBlur={handleValidateTime}
                          />
                        </Col>
                        <Col span={12}>
                          <DatePickerField
                            title="End Time"
                            name="endTime"
                            setFieldValue={setFieldValue}
                            type="time"
                            defaultValue={values.endTime}
                            onBlur={handleValidateTime}
                          />
                        </Col>
                      </Row>
                      <Row gutter={[15, 15]}>
                        <Col span={12}>
                          <DropdownField
                            title="Resources"
                            name="chairId"
                            placeHolder="Select Resource types"
                            setFieldValue={setFieldValue}
                            options={resources}
                            value={values?.chairId}
                            disabled={
                              values.visitType === "virtual_consultation"
                            }
                          />
                        </Col>
                        <Col span={12} className="visit-type__wrapper">
                          <RadioInputField
                            name="visitType"
                            title="Visit Type"
                            setFieldValue={setFieldValue}
                            options={visitTypeOptions}
                            value={values.visitType}
                          />
                        </Col>
                      </Row>
                    </Fragment>
                  ) : (
                    <Row gutter={[15, 15]}>
                      <Col span={12}>
                        <DatePickerField
                          title="Remaining Date"
                          name="remainingDate"
                          setFieldValue={setFieldValue}
                          defaultValue={values.remainingDate}
                        />
                      </Col>
                    </Row>
                  )}
                  <Row gutter={[15, 15]}>
                    <Col span={24}>
                      <InputField
                        type="textarea"
                        title="Note"
                        name="notes"
                        rows={6}
                      />
                    </Col>
                  </Row>
                </Fragment>
              ) : null}

              <Row>
                <Col span={16} />
                <Col span={8} className="appointment-form__controller">
                  <Button type="default" onClick={closeHandler}>
                    Cancel
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={loader}
                    disabled={!isValid || !dirty}
                  >
                    Next
                  </Button>
                </Col>
              </Row>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default CreateAppointment;
