import React, { useCallback, useEffect, useState } from "react";
import "./appHeader.scss";
import logo from "../../../assets/images/logoHorizontalBlack.png";
import {
  MenuOutlined,
  BellOutlined,
  LogoutOutlined,
  CalendarOutlined,
  ClockCircleFilled,
} from "@ant-design/icons";
import { Row, Col, Button, Select, Badge, Popover } from "antd";
import { useSelector } from "react-redux";
import { AuthReducerProps } from "../../../store/reducers/authReducer";
import AuthContainer from "../../../store/container/AuthContainer";
import AppRoutes from "../../../routes/routeConstants/appRoutes";
import { Link, useHistory } from "react-router-dom";
import AuthService from "../../../services/Auth/auth.service";
import BabylonContainer from "../../../store/container/BabylonContainer";
import { BabylonReducerProps } from "../../../store/reducers/babylonReducer";
import { MetaService } from "../../../services/Meta/meta.service";
import { ToothPosition } from "../../../models/ToothPosition/toothPosition.model";
import PracticeService from "../../../services/Practice/practice.service";
import { Practice } from "../../../models/Practice/practice.model";
import { SelectProp } from "../../../shared/Types/option";
import DropdownField from "../DropdownField";
import { ResponsibilityTypes } from "../../../enums/responsebily.enum";
import AppNotification from "./AppNotification";
import { Notification } from "../../../models/Notification/notification.model";
import { Interaction } from "../../../models/Interaction/interaction.model";
import useNotification from "../../hooks/Notification";
import NotificationService from "../../../services/Notification/notification.service";
import CorporateContainer from "../../../store/container/CorporateContainer";
import { CorporateReducerProps } from "../../../store/reducers/corporateReducer";
import UserNotification from "../../../services/UserNotification/userNotificatoin.service";
import getURLParameter from "../../utils/getURLParameter";
import Modal from "antd/lib/modal/Modal";
import Clock from "../Clock";
import CorporateObjectService from "../../../services/CorporateObject/corporateObject.service";
import {
  CorporateObject,
  CorporateObjectWithBabylonFile,
} from "../../../models/CorporateObject/corporateObject.model";
import { LocalStorageKeys } from "../../../enums/localStorageKeys.enum";
import moment from "moment";
import { UrlMeta } from "../../../models/UrlMeta/UrlMeta.model";
import { PaginationDetails } from "../../../models/Pagination/pagination.model";
import { debounce } from "lodash";
import { uniqueObjectValue } from "../../utils/uniqueObjectValue";
import useResponsibilities from "../../hooks/Responsibilities/useResponsibilities";
import { TimeZone } from "../../../models/TimeZone/timeZone.model";

interface AppHeaderProps
  extends AuthReducerProps,
    BabylonReducerProps,
    CorporateReducerProps {
      onCollapseUpdate: (collapsed?: boolean) => void;
    }

function AppHeader({
  authenticated,
  setToothPositions,
  setCorporateObjects,
  userResponsibilities,
  user,
  practiceId,
  setPracticeId,
  setCaps,
  setCapsLoading,
  corporateObjects,
  setCorporateObjectsLoading,
  corporateId,
  practices, 
  setPractices,
  practiceLoading,
  setPracticeLoading,
  onCollapseUpdate
}: AppHeaderProps) {
  const pageTitle = useSelector((state: any) => state.meta.pageTitle);

  const history = useHistory();

  const {
    IS_LOGGED_IN_USER_DOCTOR,
    IS_LOGGED_IN_USER_ADMIN,
    hasAccess,
  } = useResponsibilities();

  const pathname = history.location.pathname;

  const [showHeader, setShowHeader] = useState(false);

  const [notifications, sethNotification] = useState<Notification[]>([]);

  const [workItems, setWorkItem] = useState<Notification[]>([]);

  const [notificationCount, setNotificationCount] = useState(0);
  const [clockVisibility, setClockVisibility] = useState(false);

  const INTERVAL_TIME = 9_00_000; //15 MINUTES in MILLISECONDS

  const HAS_APPOINTMENT_VIEW_ACCESS = hasAccess(
    ResponsibilityTypes.APPOINTMENT_VIEW
  );

  const HAS_DENTAL_OBJ_VIEW_ACCESS = hasAccess(
    ResponsibilityTypes.DENTAL_OBJ_VIEW
  );

  const HAS_PRACTICE_VIEW_ACCESS = hasAccess(ResponsibilityTypes.PRACTICE_VIEW);

  const IS_ADMIN_OR_DOCTOR =
    IS_LOGGED_IN_USER_ADMIN || IS_LOGGED_IN_USER_DOCTOR;

  const [expiryTime] = useState(
    localStorage.getItem(LocalStorageKeys.CORPORATE_OBJECTS_EXPIRY)
      ? moment
          .utc(localStorage.getItem(LocalStorageKeys.CORPORATE_OBJECTS_EXPIRY))
          .subtract("1", "hour")
      : moment().subtract("1", "hour")
  );

  const { clearNotification, updateUserNotification } = UserNotification();

  const pushNotification = (notification: Notification) => {
    sethNotification((existingNotifications) => [
      ...existingNotifications,
      notification,
    ]);
  };
  const queryParameter = getURLParameter();

  const pushWorkItem = (workItem: Notification) => {
    setWorkItem((existingWorkItems) => [...existingWorkItems, workItem]);
  };

  useNotification(pushNotification, pushWorkItem, user);

  const handleLogout = () => {
    AuthService.logout(
      () => {
        window.location.href = AppRoutes.LOGIN;
      },
      () => {},
      () => {}
    );
  };
  const handleClockVisibility = () => {
    setClockVisibility(!clockVisibility);
  };

  const hydrateCaps = useCallback((corporateObjects: CorporateObject[]) => {
    const caps = corporateObjects?.filter((obj) => obj?.objectModelId === 1);

    setCapsLoading(true);
    CorporateObjectService.fetchCorporateObjectBabylonFiles(caps, (files) => {
      const capsWithFile: CorporateObjectWithBabylonFile[] = files?.map(
        (file, index) => ({
          babylonFile: file,
          corporateObject: { ...caps?.[index] },
        })
      );
      setCaps(capsWithFile);
    }, () => {}, () => {
      setCapsLoading(false);
    });
  }, []);

  const hydrateCorporateObject = async () => {
    if (!HAS_DENTAL_OBJ_VIEW_ACCESS) return;

    const isCorporateObjectExist = !!corporateObjects?.length;

    const isExpired = moment()?.isSameOrAfter(expiryTime);

    if (isExpired || !isCorporateObjectExist) {
      setCaps([]);
      setCorporateObjectsLoading(true);
      CorporateObjectService.fetchCorporateObjects(
        {},
        "",
        (corporateObjects: CorporateObject[], _: any, meta: UrlMeta) => {
          setCorporateObjects(corporateObjects);
          localStorage.setItem(
            LocalStorageKeys.CORPORATE_OBJECTS,
            JSON.stringify(corporateObjects)
          );
          localStorage.setItem(
            LocalStorageKeys.CORPORATE_OBJECTS_EXPIRY,
            meta?.expiryTime || ""
          );
        },
        () => {},
        () => {
          setCorporateObjectsLoading(false);
        }
      );
    }
  };

  const setNotifications = (notifications: Notification[]) => {
    const notification = [];
    const workItem = [];
    let count = 0;
    for (let noti of notifications) {
      if (noti.isViewed) {
        count += 1;
      }
      if (noti.category === "WorkItem") {
        workItem.push(noti);
      } else {
        notification.push(noti);
      }
    }
    setNotificationCount(0);
    sethNotification(notification);
    setWorkItem(workItem);
  };

  const getPracticeWithTimezone = (
    { id, name, timezoneCode }: Practice,
  ) => ({
    label: `${name || ""} ${
      timezoneCode ? "(" + timezoneCode + ")" : ""
    }`.trim(),
    value: id,
  });

  const getPracticeOptions = (
    practices: Practice[] = [],
    timezones: TimeZone[] = []
  ): SelectProp[] => {
    const options = practices.map((practice) => {
      return getPracticeWithTimezone(practice);
    });
    return options;
  };

  useEffect(() => {
    setShowHeader(authenticated && pathname !== AppRoutes.DETAILED_ONBOARDING);
  }, [authenticated, pathname]);

  useEffect(() => {
    if (authenticated) {
      MetaService.fetchToothPositions(
        (toothPositions: ToothPosition[]) => {
          setToothPositions(toothPositions);
        },
        () => {},
        () => {}
      );

      NotificationService.getNotification((notifications) => {
        setNotifications(notifications);
      });
    }
  }, [authenticated, setToothPositions, corporateId]);

  const fetchPractice = () => {
      setPracticeLoading(true);
      PracticeService.fetchPractices(
        async (practices: Practice[]) => {
          const existingPracticeId = localStorage.getItem("practiceId");

          if (practices.length) {
            setPracticeId(
              parseInt(existingPracticeId ?? practices[0]?.id ?? "")
            );
            if (!existingPracticeId) {
              localStorage.setItem("practiceId", practices[0]?.id ?? "");
            }
          }

          setPractices(practices);
        },
        () => {},
        () => {
          setPracticeLoading(false);
        }
      );
  };

  useEffect(() => {
    hydrateCaps(corporateObjects);
  }, [corporateObjects, hydrateCaps]);

  useEffect(() => {
    if (!corporateId || !user) return;

    if (IS_ADMIN_OR_DOCTOR && HAS_PRACTICE_VIEW_ACCESS) fetchPractice();

    hydrateCorporateObject();
  }, [corporateId, user]);

  const handleMarkAsRead = async () => {
    let notificationIds: Array<number | undefined> = [];

    [...notifications, ...workItems]?.forEach((notification) => {
      if (notification?.isViewed === false) {
        notificationIds.push(notification?.id);
      }
    });
    if (notificationIds?.length === 0) {
      return;
    }
    const updatedNotificationItems = await updateUserNotification(
      notificationIds
    );
    if (updatedNotificationItems) {
      setNotifications(updatedNotificationItems);
    }
  };

  const handlePractice = (value: number) => {
    localStorage.setItem("practiceId", value?.toString());
    setPracticeId(value);
  };

  const getPracticeId = () => {
    return localStorage.getItem("practiceId");
  };

  useEffect(() => {
    const interval = window.setInterval(hydrateCorporateObject, INTERVAL_TIME);

    return window.clearInterval(interval);
  }, []);
  

  return showHeader ? (
    <div className="app-header">
      <Row gutter={[0, 0]} align="middle">
        <Col span={3} className="app-header__logo-wrapper">
          <Link to={AppRoutes.DASHBOARD}>
            <img
              alt="Aligner 4D logo"
              className="app-header__logo"
              height={30}
              src={logo}
              width={105}
            />
          </Link>
          <MenuOutlined onClick={() => onCollapseUpdate()} className="app-header__menu" />
        </Col>
        <Col span={5} className="app-header__title">
          <h4 style={{ marginTop: "10px" }}>{pageTitle}</h4>
        </Col>
        <Col className="app-header__right">
          {IS_ADMIN_OR_DOCTOR && HAS_PRACTICE_VIEW_ACCESS && (
            <Select
              dropdownMatchSelectWidth={false}
              value={practiceId}
              options={getPracticeOptions(practices)}
              className="app-header__practice"
              onChange={handlePractice}
              loading={practiceLoading}
            />
          )}

          <Button className="mr-2" type="link" onClick={handleLogout}>
            <LogoutOutlined className="mr-2" />
            Logout
          </Button>

          <Button
            className="secondary-btn app-header__clock-btn"
            onClick={handleClockVisibility}
          >
            <ClockCircleFilled />
          </Button>

          {HAS_APPOINTMENT_VIEW_ACCESS && (
            <Button
              className="secondary-btn app-header__calender-btn"
              icon={<i className="icon-calendar" />}
              onClick={() => {
                history.push(AppRoutes.APPOINTMENTS);
              }}
            >
              <span className="app-header__notification-count">1</span>
            </Button>
          )}
          <Badge count={notificationCount}>
            <Popover
              placement="bottomRight"
              trigger="click"
              content={
                <AppNotification
                  notifications={notifications}
                  workItems={workItems}
                  clearNotification={() => {
                    sethNotification([]);
                    setWorkItem([]);
                  }}
                />
              }
            >
              <Button
                className="secondary-btn app-header__notification-btn"
                icon={<BellOutlined />}
                onClick={handleMarkAsRead}
              />
            </Popover>
          </Badge>
        </Col>
      </Row>
      <Modal
        visible={clockVisibility}
        destroyOnClose
        centered
        className="modal-invisible"
      >
        <Clock clockVisibility={handleClockVisibility} />
      </Modal>
    </div>
  ) : null;
}

export default AuthContainer(BabylonContainer(CorporateContainer(AppHeader)));
