import React, { FC, useEffect, useState } from "react";
import { Formik, Form } from "formik";
import { StatementNote } from "../../../../models/StatementNote/statementNote.model";
import DropdownField from "../../../../shared/components/DropdownField";
import "./statementNotesForm.scss";
import StatementNotesService from "../../../../services/StatementNotes/statementNotes.service";
import { NoteTemplateService } from "../../../../services/NoteTemplate/noteTemplate.service";
import { StatementTemplate } from "../../../../models/Statement/statement.model";
import { StatementTemplateCategory } from "../../../../models/StatementCategory/statementCategory.model";
import InputField from "../../../../shared/components/InputField";
import { Button } from "antd";
import { useParams } from "react-router";
import { truncate } from "fs";
import useStatementNotes from "../../../../shared/hooks/useStatementNotes";
import { FilterParams } from "../../../../models/FilterParams/filterParams.model";
import { PaginationDetails } from "../../../../models/Pagination/pagination.model";

interface StatementNotesFormProps {
  update?: () => void;
  closeModal?: () => void;
  addStatementNoteHandler?: (statementNote: StatementNote) => void;
}

const StatementNotesForm: FC<StatementNotesFormProps> = (props) => {
  const { closeModal, update, addStatementNoteHandler } = props;

  const params: { interactionId: string } = useParams();

  const [categories, setCategories] = useState<StatementTemplateCategory[]>([]);

  const [selectedCategory, setSelectedCategory] = useState<number>();

  const {
    fetchStatementNoteTemplates,
    loading: templatesLoading,
    options,
  } = useStatementNotes({});

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    NoteTemplateService.getStatementCategories(
      (categories) => {
        setCategories(categories);
      },
      () => {},
      () => {}
    );
  }, []);

  const fetchSubCategories = (
    filters?: FilterParams,
    pagination?: PaginationDetails
  ) => {
    if (!selectedCategory) return;

    fetchStatementNoteTemplates(
      {
        status: "active",
        statementTemplateCategoryId: selectedCategory?.toString(),
        ...filters,
      },
      pagination
    );
  };

  useEffect(() => {
    fetchSubCategories({ page: 1 }, new PaginationDetails());
  }, [selectedCategory]);

  const handleSubmit = (values: StatementNote) => {
    if (addStatementNoteHandler) {
      addStatementNoteHandler(values);

      return;
    }

    setLoading(true);
    StatementNotesService.createStatementNotes(
      Number(params.interactionId),
      values.statementTemplateId ?? 0,
      (statementTemplate) => {
        update && update();
        closeModal && closeModal();
      },
      () => {},
      () => {
        setLoading(true);
      }
    );
  };

  return (
    <div className="statement-notes-form">
      <h5>Add statement</h5>
      <Formik initialValues={new StatementNote()} onSubmit={handleSubmit}>
        {({ values, setFieldValue }) => (
          <Form>
            <DropdownField
              darkmode
              placeHolder="Select Category"
              name="statementTemplateCategoryId"
              value={values.statementTemplateCategoryId}
              options={categories}
              onChange={(id) => {
                setSelectedCategory(id);
                setFieldValue("statementTemplateCategoryId", id);
                setFieldValue("statementTemplateId", undefined);
                setFieldValue("description", undefined);
              }}
            />
            <DropdownField
              darkmode
              placeHolder="Select Sub-category"
              name="statementTemplateId"
              value={values.statementTemplateId}
              options={options}
              onChange={(id, statement) => {
                setFieldValue("statementTemplateId", id);
                setFieldValue("description", statement?.description);
              }}
              onScrollEnd={fetchSubCategories}
              loading={templatesLoading}
              disabled={!values.statementTemplateCategoryId}
            />
            <InputField
              darkmode
              type="textarea"
              placeholder="description"
              name="description"
              disabled
            />
            <div className="controller">
              <Button htmlType="submit" type="primary" loading={loading}>
                Add
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default StatementNotesForm;
