import { Button, Drawer } from "antd";
import React, { FC, useEffect, useState } from "react";
import Stats from "../../../shared/components/Stats";
import ListContractTermsTemplates from "./ListContractTermsTemplates";
import { PlusOutlined } from "@ant-design/icons";
import ContractTermTemplateService from "../../../services/ContractTermTemplate/ContractTermTemplate.service";
import Modal from "antd/lib/modal/Modal";
import ContractFormTemplatesForm from "./contractTermsTemplatesForm";
import { ContractTermTemplate } from "../../../models/ContractTermsTemplate/contractTermsTemplate.model";
import AppLoader from "../../../shared/components/AppLoader";
import AuthContainer from "../../../store/container/AuthContainer";
import { AuthReducerProps } from "../../../store/reducers/authReducer";
import { ResponsibilityTypes } from "../../../enums/responsebily.enum";
import ContractTermsTemplateView from "./ContractTermsTemplateView";
import { useLocation } from "react-router-dom";
import { StatsType } from "../../../enums/statsType.enum";
import { Tabs } from "antd";
import { PaginationDetails } from "../../../models/Pagination/pagination.model";
import useFilters from "../../../shared/hooks/useFilter/useFilters";
import { FilterParams } from "../../../models/FilterParams/filterParams.model";
import useResponsibilities from "../../../shared/hooks/Responsibilities/useResponsibilities";

interface LocationProps {
  termOfServiceId: string;
}

interface ContractTermTemplateProps extends AuthReducerProps {}

const ContractTermsTemplate: FC<ContractTermTemplateProps> = (props) => {
  const { TabPane } = Tabs;

  const location = useLocation<LocationProps>();

  const { hasAccess } = useResponsibilities();

  const [loader, setLoader] = useState(false);

  const [contractTermsTemplates, setContractTermsTemplates] = useState<
    ContractTermTemplate[]
  >([]);

  const [customTermsTemplates, setCustomTermsTemplates] = useState<
    ContractTermTemplate[]
  >([]);
  const { getParams, setParams } = useFilters();

  const [viewContractTerm, setViewContractTerm] = useState(0);

  const [formVisibility, setFormVisibility] = useState(false);
  const [tab, setTab] = useState<string>("0");
  const handleChangeTab = (value: string) => {
    setTab(value);
    setParams(new FilterParams());
  };

  const [
    contractTermTemplate,
    setContractTermTemplate,
  ] = useState<ContractTermTemplate>();

  const [pagination, setPagination] = useState<PaginationDetails>();
  const HAS_CONTRACT_TERM_VIEW_ACCESS = hasAccess(
    ResponsibilityTypes.CONTRACT_TERM_VIEW
  );

  const HAS_CONTRACT_TERM_CREATE_ACCESS = hasAccess(
    ResponsibilityTypes.CONTRACT_TERM_CREATE
  );

  const handleTermTemplate = (filter: FilterParams) => {
    setLoader(true);
    ContractTermTemplateService.fetchContractTermTemplates(
      (contractTermsTemplates: ContractTermTemplate[], pagination) => {
        const term = contractTermsTemplates.filter(
          (contractTermsTemplate) => contractTermsTemplate?.defaultTemplateId
        );
        setContractTermsTemplates(term);
        const data = contractTermsTemplates.filter(
          (contractTermsTemplate) => !contractTermsTemplate?.defaultTemplateId
        );
        if (data?.length > 0) setCustomTermsTemplates(data);
        setPagination(pagination);
      },
      () => {},
      () => {
        setLoader(false);
      },
      filter
    );

    if (location.state?.termOfServiceId) {
      setViewContractTerm(parseInt(location.state.termOfServiceId));
    }
  };
  useEffect(() => {
    handleTermTemplate(getParams());
  }, []);

  const handleSubmit = (
    contractTermTemplate: ContractTermTemplate,
    type: "add" | "edit"
  ) => {
    setFormVisibility(false);
    if (type === "add") {
      setCustomTermsTemplates((existingContractTermsTemplates) => [
        ...existingContractTermsTemplates,
        contractTermTemplate,
      ]);
      return;
    }
    setCustomTermsTemplates((existingContractTermsTemplates) =>
      existingContractTermsTemplates.map((contract) => {
        if (contract.id === contractTermTemplate.id) {
          return contractTermTemplate;
        }
        return contract;
      })
    );
  };
  const handleEdit = (ContractTermTemplate: ContractTermTemplate) => {
    setContractTermTemplate(ContractTermTemplate);
    setFormVisibility(true);
  };

  const handleClose = () => {
    setContractTermTemplate(undefined);
    setFormVisibility(false);
  };
  const handleDelete = (id: number) => {
    setCustomTermsTemplates((existingContractTermsTemplates) => {
      return existingContractTermsTemplates.filter(
        (template) => template?.id !== id
      );
    });
    // handleTermTemplate();
  };

  const contractTabs = [
    {
      title: "Default Term Templates",
      component: (
        <ListContractTermsTemplates
          pagination={false}
          contractTermsTemplates={contractTermsTemplates}
          editHandler={handleEdit}
          deleteHandler={handleDelete}
          selectContractTermTemplate={(id: number) => setViewContractTerm(id)}
          contractType="defaultTerm"
        />
      ),
    },
    {
      title: "Custom Term Templates",
      component: (
        <ListContractTermsTemplates
          pagination={{ total: customTermsTemplates?.length }}
          contractTermsTemplates={customTermsTemplates}
          editHandler={handleEdit}
          deleteHandler={handleDelete}
          onSuccess={handleTermTemplate}
          selectContractTermTemplate={(id: number) => setViewContractTerm(id)}
          contractType="customTerm"
        />
      ),
    },
  ];

  const handleCloseContractTerm = () => setViewContractTerm(0);
  return (
    <div>
        {tab === "1" && HAS_CONTRACT_TERM_CREATE_ACCESS && (
        <div className="service-list__add-wrapper">
          <Button
            type="primary"
            className="ml-3"
            onClick={() => setFormVisibility(true)}
          >
            <PlusOutlined />
            Add Contract Term
          </Button>
        </div>
        )}
        {
          tab === "0" && <div className="default-term-template-wrapper"></div>
        }

      {loader ? (
        <AppLoader loading />
      ) : (
        HAS_CONTRACT_TERM_VIEW_ACCESS && (
          <Tabs size="small" className="term-template-tabs" activeKey={tab} onChange={handleChangeTab}>
            {contractTabs.map(({ title, component }, index) => (
              <TabPane tab={title} key={index}>
                {component}
              </TabPane>
            ))}
          </Tabs>
        )
      )}
      <Modal
        visible={HAS_CONTRACT_TERM_CREATE_ACCESS && formVisibility}
        destroyOnClose
        closable
        onCancel={handleClose}
        centered
      >
        <ContractFormTemplatesForm
          formValue={contractTermTemplate}
          onSubmitHandler={(values: ContractTermTemplate, type) =>
            handleSubmit(values, type)
          }
          onCloseHandler={handleClose}
        />
      </Modal>
      <Drawer
        title="View Contract Term"
        visible={HAS_CONTRACT_TERM_VIEW_ACCESS && viewContractTerm !== 0}
        onClose={handleCloseContractTerm}
        destroyOnClose
        width="60%"
      >
        <ContractTermsTemplateView contractTermTemplateId={viewContractTerm} />
      </Drawer>
    </div>
  );
};

export default AuthContainer(ContractTermsTemplate);
