import React, { useState } from "react";
import "./contractCustomForm.scss";
import { Contract } from "../../../../../models/Contract/contract.model";
import { useHistory, useParams, generatePath } from "react-router-dom";
import CustomFormRequestForm from "../../../../../shared/components/CustomFormRequestForm";
import ContractContainer from "../../../../../store/container/ContractContainer";
import { ContractReducerProps } from "../../../../../store/reducers/contractReducer";
import { Button, Drawer } from "antd";
import ContractService from "../../../../../services/Contract/Contract.service";
import AppRoutes from "../../../../../routes/routeConstants/appRoutes";
import contractSuccessBanner from "../../../../../assets/images/contractSuccessBanner.png";

interface ContractCustomFormProps extends ContractReducerProps {
  onCancel: () => void;
}

function ContractCustomForm({
  onCancel,
  contract,
  setContract,
}: ContractCustomFormProps) {
  const history = useHistory();
  const { contractId } = useParams();

  const [formLoading, setFormLoading] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const handleSuccess = () => {};
  const initiatePayment = (pmId: string) => {
    setShowSuccessModal(true);
  };

  const handleSubmit = () => {
    setFormLoading(true);
    ContractService.updateContract(
      Object.assign(new Contract(), {
        ...contract,
        contractService: undefined,
        stepNo: contract.stepNo > 3 ? contract.stepNo : 3,
      }),
      (contract: Contract) => {
        initiatePayment("");
      },
      () => {},
      () => {
        setFormLoading(false);
      }
    );
  };
  const handleCloseSuccessModal = () => {
    setShowSuccessModal(false);
    history.push(
      generatePath(AppRoutes.CONTRACT_DETAIL, { contractId: contract.id })
    );
  };

  const handleBack = () => 
    setContract({ ...contract, stepNo: contract?.stepNo - 1 });
    
  return (
    <div className="contract-custom-form">
      <div className="contract-form__card">
        <div className="title">
          <h4>4</h4>
          <h4>Custom Form</h4>
        </div>
        <CustomFormRequestForm
          resourceId={contractId}
          onSuccess={handleSuccess}
          requestPatientToFillSuccess={handleSubmit}
        />
      </div>
      <div className="mt-5 text-right">
        <Button type="default" onClick={onCancel}>
          Cancel
        </Button>
         <Button type="primary" className="ml-2 mr-2" onClick={handleBack}>
            Previous
         </Button>
        <Button
          type="primary"
          className="ml-2"
          onClick={handleSubmit}
          loading={formLoading}
        >
          Next
        </Button>
      </div>
      <Drawer
        title={null}
        keyboard={false}
        placement="right"
        maskClosable={false}
        onClose={handleCloseSuccessModal}
        visible={showSuccessModal}
        destroyOnClose={true}
        width="60%"
      >
        <div className="contract-card-details__success-modal-content">
          <img src={contractSuccessBanner} alt="Contract creation success" />
          <p>
            All the process has been completed and the contract signed
            successfully!
          </p>
          <div>
            {/* <Button type="primary">Print</Button> */}
          </div>
        </div>
      </Drawer>
    </div>
  );
}

export default ContractContainer(ContractCustomForm);
