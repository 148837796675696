
import {serializable, alias, primitive} from 'serializr';

export class CommentNote { 

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('type', primitive()))
	type?: string;

	@serializable(alias('notes', primitive()))
	notes?: string;

	@serializable(alias('created_at', primitive()))
	createdAt?: string;

}