import { notification } from 'antd';

interface INotification {
    message: string;
    description?: string;
    type: string;
    onClose?: () => void;
}

type notificationType = "success" | "error" | "warning" | "info" | "open";

const Notification = ({ message, description, type, onClose }: INotification) => {
    return notification[type as notificationType]({
        message,
        description,
        onClose
    })
};

export default Notification; 
