import * as Yup from "yup";
import { compareStartAndEndDate } from "../../shared/utils/dateHelpers";

export const filterValidation = Yup.object({
  startDate: Yup.string()
    .required()
    .test("From Date", "From Date must be before To Date", function (date) {
      return compareStartAndEndDate(date, this.parent.endDate);
    })
    .label("From Date"),
  endDate: Yup.string()
    .test("To Date", "To Date must be after From Date", function (date) {
      return compareStartAndEndDate(this.parent.startDate, date);
    })
    .label("To Date"),
});
