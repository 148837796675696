import React, { useEffect, useState } from "react";
import { AppointmentType } from "../../../models/AppointmentType/appointmentType.model";
import AppointmentTypeService from "../../../services/AppointmentType/appointmentType.service";
import { SelectProp } from "../../Types/option";

const useAppointmentTypes = (type?: "option") => {
  const [appointmentTypes, setAppointmentTypes] = useState<
    AppointmentType[] | SelectProp[]
  >([]);
  useEffect(() => {
    AppointmentTypeService.fetchAppointmentTypes(
      (appointmentTypes) => {
        if (type) {
          const options = appointmentTypes.map(({ id, nickName }) => ({
            label: nickName ?? "",
            key: id ?? "",
            value: id ?? "",
          }));
          setAppointmentTypes(options);
          return;
        }
        setAppointmentTypes(appointmentTypes);
      },
      () => {},
      () => {}
    );
  }, []);

  useEffect(() => {
    AppointmentTypeService.fetchAppointmentTypes(
      (appointmentTypes) => {
        if (type) {
          const options = appointmentTypes.map(({ id, nickName }) => ({
            label: nickName ?? "",
            key: id ?? "",
            value: id ?? "",
          }));
          setAppointmentTypes(options);
          return;
        }
        setAppointmentTypes(appointmentTypes);
      },
      () => {},
      () => {}
    );
  }, []);

  return appointmentTypes;
};

export default useAppointmentTypes;
