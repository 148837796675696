import {serializable, alias, object, list, primitive} from 'serializr';

export class XRayAttachment { 

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('attachment_name', primitive()))
	attachmentName?: string;

	@serializable(alias('attachment_url', primitive()))
	attachmentUrl?: string;

	@serializable(alias('interaction_id', primitive()))
	interactionId?: string;
}

export class XRayImage { 

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('visit_no', primitive()))
	visitNo?: number;

	@serializable(alias('attachments', list(object(XRayAttachment))))
	attachments?: XRayAttachment[] = [];

}