import {
  serializable,
  alias,
  object,
  list,
  primitive,
  custom,
} from "serializr";
import { ResponsiblePerson } from "../ResponsiblePerson/responsiblePerson.model";

export class Patient {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("first_name", primitive()))
  firstName?: string;

  @serializable(alias("status", primitive()))
  status?: string;

  @serializable(alias("last_name", primitive()))
  lastName?: string;

  @serializable(alias("gender", primitive()))
  gender?: string;

  @serializable(alias("email", primitive()))
  email?: string;

  @serializable(alias("isd_code", primitive()))
  isdCode?: string;

  @serializable(alias("mobile_number", custom((mobile) => mobile || null, (mobile: string, {json}) => json?.is_mobile_number_null ? "" : mobile)))
  mobileNumber?: string;

  @serializable(alias("dob", primitive()))
  dob?: string;

  @serializable(alias("nationality_id", primitive()))
  nationalityId?: number;

  @serializable(alias("nationality_name", primitive()))
  nationalityName?: string;

  @serializable(alias("zip_code", primitive()))
  zipcode?: string;
  @serializable(alias("city_id", primitive()))
  cityId?: number;

  @serializable(alias("city_name", primitive()))
  cityName?: string;

  @serializable(alias("province_id", primitive()))
  provinceId?: number;

  @serializable(alias("province_name", primitive()))
  provinceName?: string;

  @serializable(alias("country_id", primitive()))
  countryId?: number;

  @serializable(alias("country_name", primitive()))
  countryName?: string;

  @serializable(alias("street", primitive()))
  street?: string;

  @serializable(alias("building", primitive()))
  building?: string;

  @serializable(alias("form_type", primitive()))
  formType?: string;

  @serializable(alias("practice_id", primitive()))
  practiceId?: string;

  @serializable(alias("responsible_person_id", primitive()))
  responsiblePersonId?: string;

  @serializable(alias("responsible_person_name", primitive()))
  responsiblePersonName?: string;

  @serializable(alias("profile_pic_url", primitive()))
  profilePicUrl?: string;

  @serializable(
    alias(
      "profile_pic",
      custom(
        (file) => file,
        (json) => {}
      )
    )
  )
  profilePic?: File;

  enableResponsiblePerson?: boolean = false;

  @serializable(alias("responsible_person", object(ResponsiblePerson)))
  responsiblePerson?: ResponsiblePerson | null = new ResponsiblePerson();

  getFullName() {
    return this.firstName + " " + this.lastName;
  }
}
