import React, {
  useEffect,
  useState,
  useRef,
  Dispatch,
  SetStateAction,
} from "react";
import "./contractTermsAndConditions.scss";
import { useLocation } from "react-router-dom";
import { Contract } from "../../../../../models/Contract/contract.model";
import ContractContainer from "../../../../../store/container/ContractContainer";
import { ContractReducerProps } from "../../../../../store/reducers/contractReducer";
import { Button, Checkbox, Col, Row, Table } from "antd";
import { Form, Formik } from "formik";

import ContractTermTemplateService from "../../../../../services/ContractTermTemplate/ContractTermTemplate.service";
import { IDropdownOptions } from "../../../../../services/Meta/meta.service";
import {
  ContractTermSendLinkHandler,
  ContractTermTemplate,
} from "../../../../../models/ContractTermsTemplate/contractTermsTemplate.model";
import DropdownField from "../../../../../shared/components/DropdownField";
import AttachmentUpload from "../../../../../shared/components/AttachmentUpload";
import { contractTermsValidation } from "./contractTermsValidation";
import ContractService from "../../../../../services/Contract/Contract.service";
import Editor from "../../../../../shared/components/Editor";
import { SelectProp } from "../../../../../shared/Types/option";
import { MenuOutlined } from "@ant-design/icons";
import type { ColumnsType } from "antd/lib/table";
import AppLoader from "../../../../../shared/components/AppLoader";
import { arrayMoveImmutable } from "array-move";
import type { SortableContainerProps, SortEnd } from "react-sortable-hoc";

import { saveAs } from "file-saver";

import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import Agreement from "./Agreement";
import { ContractStatus } from "enums/contractStatus.enum";

interface ContractTermsAndConditionsProps extends ContractReducerProps {
  onCancel: () => void;
  contractStep: number;
  onContractStepUpdate: Dispatch<SetStateAction<number>>;
}

interface SignedStatus {
  isSignature?: boolean;
  isSignedDocument?: boolean;
}

function ContractTermsAndConditions({
  onCancel,
  contract,
  setContract,
  contractStep,
  onContractStepUpdate,
}: ContractTermsAndConditionsProps) {
  const [formValues] = useState(contract);

  const [formLoading, setFormLoading] = useState(false);
  const [generateLoading, setGenerateLoading] = useState(false);

  const [templateOptions, setTemplateOptions] = useState<IDropdownOptions[]>(
    []
  );
  const [isUpload, setIsUpload] = useState(false);
  const [isCanvas, setIsCanvas] = useState(false);
  const [signedStatus, setSignedStatus] = useState<SignedStatus>();
  const [isPathEdit, setIsPathEdit] = useState(false);
  const [insuranceListOption, setInsuranceList] = useState<SelectProp[]>([]);

  const [emailedContractTerms, setEmailedContractTerms] = useState<number[]>(
    []
  );

  const [sendingEmail, setSendingEmail] = useState(false);

  const [currentTerm, setCurrentTerm] = useState<ContractTermTemplate>();

  const sendLinkRef = useRef<ContractTermSendLinkHandler>(null);

  const [selectedTemplates, setSelectedTemplates] = useState<
    ContractTermTemplate[]
  >([]);
  const [isSignatureUploaded, setIsSignatureUploaded] = useState(false);

  const [contractTermTemplates, setContractTermTemplates] = useState<
    ContractTermTemplate[]
  >([]);
  const [defaultTermTemplate, setDefaultTermTemplate] = useState<
    ContractTermTemplate[]
  >([]);
  const [loading, setLoading] = useState(false);
  const [defaultTermTemplateOptions, setDefaultTermTemplateOptions] = useState<
    SelectProp[]
  >([]);
  const [contractTerm, setContractTerm] = useState<ContractTermTemplate[]>([]);

  const location = useLocation();

  const [dataSource, setDataSource] = useState(selectedTemplates);

  const [isContractPdfGenerating, setIsContractPdfGenerating] = useState(false);

  const isAllTermsSigned = contractTerm?.every(
    (term) => term?.documentUrl || emailedContractTerms?.includes(term?.id!)
  );

  const isEmailSendForCurrentTerm = !!(
    currentTerm?.id && emailedContractTerms?.includes(currentTerm?.id)
  );

  const IS_PHOTO_CONSENT =
    currentTerm?.name?.toLowerCase().trim() === "photo consent";

  const IS_PHOTO_CONSENT_AGREED =
    contract?.isPhotoConsentAccepted ||
    contract?.isPhotoConsentPartiallyAccepted ||
    contract?.isPhotoConsentRejected;

  const IS_PHOTO_CONSENT_NOT_AGREED = IS_PHOTO_CONSENT
    ? !IS_PHOTO_CONSENT_AGREED
    : false;

  const {
    isPhotoConsentAccepted,
    isPhotoConsentPartiallyAccepted,
    isPhotoConsentRejected,
  } = contract;

  const isPhotoConsentAgreed = () => {
    const photoConsent = contractTerm?.find(
      ({ name }) => name?.toLowerCase().trim() === "photo consent"
    );

    const IS_PHOTO_CONSENT_PRESENT = !!photoConsent;

    const IS_EMAIL_SENT_FOR_PHOTO_CONSENT = emailedContractTerms?.some(
      (termId) => photoConsent?.id === termId
    );

    if (IS_EMAIL_SENT_FOR_PHOTO_CONSENT || !IS_PHOTO_CONSENT_PRESENT)
      return true;

    const IS_PHOTO_CONSENT_AGREED =
      isPhotoConsentAccepted ||
      isPhotoConsentPartiallyAccepted ||
      isPhotoConsentRejected;

    return !!IS_PHOTO_CONSENT_AGREED;
  };

  const isAgreement =
    (!!contractTerm?.length &&
      (!isSignatureUploaded || !isPhotoConsentAgreed())) ||
    (!!contractTerm?.length && contractStep <= 2);

  const toggleUploadSignatureVisibility = () => {
    setIsUpload(!isUpload);
    setIsCanvas(false);
  };

  const toggleCanvasVisibility = () => {
    setIsCanvas(!isCanvas);
    setIsUpload(false);
  };
  const onSortEnd = ({ oldIndex, newIndex }: SortEnd) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(
        dataSource.slice(),
        oldIndex,
        newIndex
      ).filter((el) => !!el);

      setDataSource(newData);
    }
  };

  useEffect(() => {
    setIsCanvas(false);
    setIsUpload(false);
    setSignedStatus(undefined);
  }, [currentTerm]);
  useEffect(() => {
    setDataSource(selectedTemplates);
  }, [selectedTemplates]);

  const DraggableContainer = (props: SortableContainerProps) => (
    <SortableBody
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow: React.FC<any> = ({
    className,
    style,
    ...restProps
  }) => {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = dataSource.findIndex(
      (x) => x.index === restProps["data-row-key"]
    );
    return <SortableItem index={index} {...restProps} />;
  };

  const handleSubmit = (values: Contract) => {
    const contract = Object.assign(new Contract(), {
      ...values,
      isPhotoConsentAccepted,
      isPhotoConsentPartiallyAccepted,
      isPhotoConsentRejected,
      patientSignature: undefined,
      stepNo: values.stepNo > 3 ? values.stepNo : 3,
      status: ContractStatus.DOCUMENT_SIGNED,
    });
    setFormLoading(true);
    delete contract?.contractService;
    // setContract(contract);
    ContractService.updateContract(
      contract,
      (contract: Contract) => {
        setContract(contract);
        onContractStepUpdate?.(contract?.stepNo);
      },
      () => {},
      () => {
        setFormLoading(false);
      }
    );
  };

  useEffect(() => {
    const paths = location.pathname.split("/");
    if (paths[paths.length - 1] === "edit") setIsPathEdit(true);
  }, []);

  const handleChangeTemplate = (setFieldValue: Function) => (value: number) => {
    const template = contractTermTemplates.find(
      (contractTemplate) => contractTemplate.id === value
    );
    if (template) {
      setFieldValue("termTemplateId", value);
      setFieldValue("termTemplate", template.description);
    }
  };

  useEffect(() => {
    ContractTermTemplateService.fetchContractTermTemplates(
      (contractTermsTemplates: ContractTermTemplate[]) => {
        setContractTermTemplates(contractTermsTemplates);
        const data = contractTermsTemplates.filter(
          (contractTermsTemplate) => !contractTermsTemplate?.defaultTemplateId
        );
        if (data?.length > 0)
          setTemplateOptions(
            data?.map((contractTermsTemplate) => {
              return {
                label: contractTermsTemplate.name,
                value: contractTermsTemplate.id,
              };
            })
          );
      },
      () => {},
      () => {}
    );
    setLoading(true);
    ContractTermTemplateService.fetchDefaultTermTemplates(
      (defaultTermTemplate: ContractTermTemplate[]) => {
        setDefaultTermTemplate(defaultTermTemplate);
        const data = defaultTermTemplate.filter(
          (contractTermsTemplate) => contractTermsTemplate?.defaultTemplateId
        );
        setDefaultTermTemplateOptions(
          data.map((defaultTermTemplate) => ({
            label: defaultTermTemplate.name,
            value: defaultTermTemplate.id,
          }))
        );
      },
      () => {},
      () => {
        setLoading(false);
      }
    );
  }, []);

  const DragHandle = SortableHandle(() => (
    <MenuOutlined style={{ cursor: "grab", color: "#999" }} />
  ));

  const columns = [
    {
      title: "Sort",
      dataIndex: "sort",
      width: 30,
      className: "drag-visible",
      render: () => <DragHandle />,
    },
    {
      title: "name",
      dataIndex: "name",
      className: "drag-visible",
    },
  ];

  const handleContractTerm = (
    contractTerm: ContractTermTemplate[],
    customContract?: number
  ) => {
    const updatedContractTerm = contractTerm.map((val, index) => ({
      term_template_id: val.id,
      order: index + 1,
    }));

    if (customContract)
      updatedContractTerm.push({
        term_template_id: customContract,
        order: contractTerm?.length + 1,
      });

    setGenerateLoading(true);

    ContractTermTemplateService.createContractTerms(
      contract?.id ?? 0,
      updatedContractTerm,
      (contractTermTemplate) => {
        if (Array.isArray(contractTermTemplate))
          setContractTerm(contractTermTemplate);
        else setContractTerm((terms) => [...terms, contractTermTemplate]);
      },
      () => {},
      () => {
        setGenerateLoading(false);
      }
    );
  };
  useEffect(() => {
    ContractTermTemplateService.fetchContractTerms(
      contract?.id ?? 0,
      (term) => {
        setSelectedTemplates(term.map((val, i) => ({ ...val, index: i })));
        setContractTerm(term);
        if (term.length > 0) {
          const isAlldocumentSigned = term?.every((term) => term?.documentUrl);

          setIsSignatureUploaded(isAlldocumentSigned);
        } else setIsSignatureUploaded(false);
      },
      () => {},
      () => {}
    );
  }, []);

  const generateContractTermPdf = () => {
    setIsContractPdfGenerating(true);
    ContractService.downloadContractTerm(
      contract,
      contractTerm,
      (contractTermPdfUrl) => {
        setContract({ ...contract, contractTermPdfUrl });
        saveAs(contractTermPdfUrl, contract?.name!);
      },
      () => {},
      () => {
        setIsContractPdfGenerating(false);
      }
    );
  };

  const downloadContractTermPdf = () => {
    if (contract?.contractTermPdfUrl)
      saveAs(contract?.contractTermPdfUrl, contract?.name!);
    else generateContractTermPdf();
  };

  const SortableItem = SortableElement(
    (props: React.HTMLAttributes<HTMLTableRowElement>) => <tr {...props} />
  );
  const SortableBody = SortableContainer(
    (props: React.HTMLAttributes<HTMLTableSectionElement>) => (
      <tbody {...props} />
    )
  );

  const handleSendLink = async () => {
    setSendingEmail(true);
    const contractId = await sendLinkRef?.current?.onSendLink();
    setSendingEmail(false);
    if (contractId) {
      setEmailedContractTerms((terms) =>
        Array.from(new Set([...terms, contractId]))
      );
    }
  };

  const handleBack = () =>
    setContract({ ...contract, stepNo: contract?.stepNo - 1 });

  return (
    <div className="contract-terms-and-conditions">
      <Formik
        initialValues={formValues}
        onSubmit={handleSubmit}
        // validationSchema={contractTermsValidation}
        enableReinitialize
      >
        {({ values, errors, isValid, dirty, setFieldValue }) => {
          return (
            <Form>
              <div className="contract-form__card">
                <Row>
                  <Col span={20} className="title">
                    <div className="title">
                      <h4>3</h4>
                      <h4>{isAgreement ? "Preview" : "Terms & Conditions"}</h4>
                    </div>
                  </Col>

                  {isAgreement && (
                    <Col span={4}>
                      <Button
                        type="primary"
                        className=""
                        onClick={downloadContractTermPdf}
                        loading={isContractPdfGenerating}
                      >
                        Download PDF
                      </Button>
                    </Col>
                  )}
                </Row>
                {loading ? (
                  <AppLoader loading />
                ) : (
                  !isAgreement && (
                    <div>
                      {contractTerm.length < 1 && (
                        <Row>
                          <h5 className="text-secondary text-bold">
                            Default Term Templates
                          </h5>

                          <Col span={24}>
                            {" "}
                            <Checkbox.Group
                              className="term-template__options"
                              options={defaultTermTemplateOptions}
                              value={selectedTemplates.map((val) =>
                                Number(val.id)
                              )}
                              onChange={(val) => {
                                const valueDetails = val.map(
                                  (value) =>
                                    defaultTermTemplate?.filter(
                                      (template) => template.id === value
                                    )[0]
                                );
                                setSelectedTemplates(
                                  valueDetails?.map((details, index) => {
                                    return {
                                      ...details,
                                      index: index,
                                    };
                                  })
                                );
                              }}
                            />
                          </Col>

                          <Col span={8} className="mt-5">
                            <DropdownField
                              title="Select Custom Terms Template"
                              name="termTemplateId"
                              options={
                                templateOptions.length > 0
                                  ? templateOptions
                                  : []
                              }
                              value={values.termTemplateId}
                              onChange={handleChangeTemplate(setFieldValue)}
                              placeHolder="Select Template"
                            />
                          </Col>
                        </Row>
                      )}

                      {contractTerm?.length < 1 ? (
                        <div className="contract-terms-and-conditions__terms">
                          <Row>
                            <Col span={24}>
                              <Table
                                showHeader={false}
                                pagination={false}
                                dataSource={dataSource}
                                columns={columns}
                                rowKey="index"
                                components={{
                                  body: {
                                    wrapper: DraggableContainer,
                                    row: DraggableBodyRow,
                                  },
                                }}
                              />

                              <Col span={12}>
                                {" "}
                                <Button
                                  type="primary"
                                  className="ml-2"
                                  loading={generateLoading}
                                  onClick={() => {
                                    handleContractTerm(
                                      dataSource,
                                      values?.termTemplateId
                                    );
                                  }}
                                >
                                  Generate
                                </Button>
                              </Col>
                            </Col>
                          </Row>
                        </div>
                      ) : (
                        <div>
                          <h4 className="text-secondary text-bold">
                            Term Templates
                          </h4>
                          {dataSource?.map((val) => {
                            return (
                              <div className="term-template__names">
                                {val.name}
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  )
                )}

                {isAgreement && (
                  <Agreement
                    sendLinkRef={sendLinkRef}
                    ref={sendLinkRef}
                    toggleUploadSignatureVisibility={
                      toggleUploadSignatureVisibility
                    }
                    isCanvas={isCanvas}
                    handleSignatureUpload={(isAllTermSigned: boolean) => {
                      setIsSignatureUploaded(isAllTermSigned);
                    }}
                    isUpload={isUpload}
                    onCurrentTermUpdate={setCurrentTerm}
                    onContractTermsUpdate={setContractTerm}
                  />
                )}
              </div>

              <div className="mt-5 text-right">
                <Button type="default" className="ml-2 mr-2" onClick={onCancel}>
                  Cancel
                </Button>
                {isAgreement && contractStep === 2 && (
                  <span>
                    <Button
                      className="secondary-btn mr-2"
                      onClick={() => {
                        toggleUploadSignatureVisibility();
                        setSignedStatus({
                          isSignedDocument: !signedStatus?.isSignedDocument,
                        });
                      }}
                      disabled={
                        isEmailSendForCurrentTerm || IS_PHOTO_CONSENT_NOT_AGREED
                      }
                    >
                      Upload Signed Document
                    </Button>
                    <Button
                      className="secondary-btn"
                      onClick={handleSendLink}
                      disabled={
                        isEmailSendForCurrentTerm ||
                        !!currentTerm?.documentUrl ||
                        signedStatus?.isSignature ||
                        signedStatus?.isSignedDocument
                      }
                      loading={sendingEmail}
                    >
                      Send Link
                    </Button>
                    <Button
                      type="primary"
                      className="ml-2"
                      onClick={() => {
                        toggleCanvasVisibility();
                        setSignedStatus({
                          isSignature: !signedStatus?.isSignature,
                        });
                      }}
                      disabled={
                        isEmailSendForCurrentTerm || IS_PHOTO_CONSENT_NOT_AGREED
                      }
                    >
                      Get Signed
                    </Button>
                  </span>
                )}
                <Button
                  type="primary"
                  className="ml-2 mr-2"
                  onClick={handleBack}
                >
                  Previous
                </Button>
                {
                  <Button
                    type="primary"
                    className="ml-2"
                    htmlType="submit"
                    loading={formLoading}
                    disabled={
                      !contractTerm?.length ||
                      !isValid ||
                      formLoading ||
                      !isAllTermsSigned ||
                      !isPhotoConsentAgreed()
                    }
                  >
                    Next
                  </Button>
                }
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default ContractContainer(ContractTermsAndConditions);
