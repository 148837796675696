
import {serializable, alias, object, list, primitive} from 'serializr';

export class StatementNote { 

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('statement_template_id', primitive()))
	statementTemplateId?: number;

	@serializable(alias('statement_template_category_id', primitive()))
	statementTemplateCategoryId?: number;

	@serializable(alias('statement_template_category_name', primitive()))
	statementTemplateCategoryName?: string;

	@serializable(alias('sub_category', primitive()))
	subCategory?: string;

	@serializable(alias('description', primitive()))
	description?: string;

}