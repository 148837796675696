import { ContractService } from "../ContractService/contractService.model";
import {
  serializable,
  alias,
  object,
  list,
  primitive,
  custom,
} from "serializr";
import { ContractInsurance } from "../ContractInsurance/contractInsurance.model";
import { PaymentSchedule } from "../PaymentSchedule/paymentSchedule.model";
import { InsuranceProvider } from "../InsuranceProvider/insuranceProvider.model";
import { ContractTermTemplate } from "../ContractTermsTemplate/contractTermsTemplate.model";

export class Contractmetrics {
  @serializable(alias("total_no_of_active_contracts", primitive()))
  totalNoOfActiveContracts?: number;
  @serializable(alias("total_no_of_completed_contracts", primitive()))
  totalNoOfCompletedContracts?: number;
  @serializable(alias("total_no_of_contract", primitive()))
  totalNoOfContracts?: number;
  @serializable(alias("total_value", primitive()))
  totalValue?: number;
  @serializable(alias("total_value_of_active_contracts", primitive()))
  totalValueOfActiveContracts?: number;
  @serializable(alias("total_value_of_completed_contracts", primitive()))
  totalValueOfCompletedContracts?: number;
}
export class Contract {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("name", primitive()))
  name?: string;

  @serializable(alias("doctor_profile_id", primitive()))
  doctorProfileId?: number;

  @serializable(alias("doctor_profile_name", primitive()))
  doctorProfileName?: string;

  @serializable(alias("doctor_first_name", primitive()))
  doctorFirstName?: string;

  @serializable(alias("doctor_last_name", primitive()))
  doctorLastName?: string;

  @serializable(alias("doctor_profile_pic_url", primitive()))
  doctorProfilePicUrl?: string;

  @serializable(alias("patient_first_name", primitive()))
  patientFirstName?: string;

  @serializable(alias("patient_last_name", primitive()))
  patientLastName?: string;

  @serializable(
    alias(
      "patient_signature",
      custom(
        (file) => file,
        (json) => {}
      )
    )
  )
  patientSignature?: File;

  document?: File;

  @serializable(alias("patient_signature_url", primitive()))
  patientSignatureUrl?: string;

  @serializable(alias("patient_profile_id", primitive()))
  patientProfileId?: number;

  @serializable(alias("patient_profile_pic_url", primitive()))
  patientProfilePicUrl?: string;

  @serializable(alias("term_template_id", primitive()))
  termTemplateId?: number;

  @serializable(alias("term_template_name", primitive()))
  termTemplateName?: string;

  @serializable(alias("term_template", primitive()))
  termTemplate?: string;
  @serializable(alias("insurance_provider_id", primitive()))
  insuranceProviderId?: string;

  @serializable(alias("created_at", primitive()))
  createdAt?: string;

  @serializable(alias("updated_at", primitive()))
  updatedAt?: string;

  @serializable(alias("step_no", primitive()))
  stepNo: number = 0;

  @serializable(alias("due_type", primitive()))
  dueType?: string;

  @serializable(alias("is_self_payment", primitive()))
  isSelfPayment: boolean = true;

  @serializable(alias("is_insurance_applied", primitive()))
  isInsuranceApplied: boolean = false;

  @serializable(alias("due_time", primitive()))
  dueTime?: string;

  @serializable(alias("card_number", primitive()))
  cardNumber?: string;

  @serializable(alias("card_type", primitive()))
  cardType?: string;

  @serializable(alias("card_holder_name", primitive()))
  cardHolderName?: string;

  @serializable(alias("card_expiry_date", primitive()))
  cardExpiryDate?: string;

  @serializable(alias("estimated_cost", primitive()))
  estimatedCost?: string;

  @serializable(alias("duration_in_months", primitive()))
  durationInMonths?: number;

  @serializable(alias("no_of_visits", primitive()))
  noOfVisits?: number = 0;

  @serializable(alias("practice_id", primitive()))
  practiceId?: number;

  @serializable(alias("practice_name", primitive()))
  practiceName?: string;

  @serializable(alias("total_min_cost", primitive()))
  totalMinCost?: number;

  @serializable(alias("total_max_cost", primitive()))
  totalMaxCost?: number;

  @serializable(alias("status", primitive()))
  status?: string;

  @serializable(alias("treatment_plan_template_id", primitive()))
  treatmentPlanTemplateId?: number;

  @serializable(alias("payment_duration_in_months", primitive()))
  paymentDurationInMonths?: number;

  @serializable(alias("from_date", primitive()))
  fromDate?: string;

  @serializable(alias("is_photo_consent_accepted", primitive()))
  isPhotoConsentAccepted?: boolean;

  @serializable(alias("is_photo_consent_partially_accepted", primitive()))
  isPhotoConsentPartiallyAccepted?: boolean;

  @serializable(alias("is_photo_consent_rejected", primitive()))
  isPhotoConsentRejected?: boolean;

  @serializable(alias("to_date", primitive()))
  toDate?: string;

  @serializable(alias("time_period", primitive()))
  timePeriod?: string;
  @serializable(alias("service_id", primitive()))
  serviceId?: number;

  // @serializable(alias("contract_services", list(object(ContractService))))
  // contractServices: ContractService[] = [];

  @serializable(
    alias("default_term_templates", list(object(ContractTermTemplate)))
  )
  defaultTermTemplates: ContractTermTemplate[] = [];

  @serializable(alias("contract_service", object(ContractService)))
  contractService?: ContractService;
  @serializable(alias("contract_insurances", list(object(ContractInsurance))))
  contractInsurances: ContractInsurance[] = [];
  @serializable(alias("payment_schedule", object(PaymentSchedule)))
  paymentSchedule?: PaymentSchedule;
  @serializable(alias("insurance_providers", list(object(InsuranceProvider))))
  insuranceProviders: InsuranceProvider[] = [];

  @serializable(alias("contract_term_pdf_url", primitive()))
  contractTermPdfUrl?: string;
}
