import { FilterFilled, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Drawer,
  Row,
  Table,
  Modal,
  Select,
  Input,
  Tooltip,
  Popover,
} from "antd";
import React, { FC, useEffect, useRef, useState } from "react";
import Stats from "../../../shared/components/Stats";
import SearchBar from "../../../shared/components/Searchbar";
import "./paymentReceipt.scss";

import PatientForm from "../../Patients/PatientForm";
import moment from "moment";
import PaymentForm from "../PaymentForm";
import { Payment } from "../../../models/Payment/payment.model";
import PaymentService from "../../../services/Payment/payment.service";
import { PaymentTransfer } from "../../../models/PaymentTransfer/paymentTransfer.model";
import {
  PaymentIntent,
  PaymentInvoiceMetrics,
} from "../../../models/PaymentIntent/paymentIntent.model";
import { StatsType } from "../../../enums/statsType.enum";
import PaymentFilterForm from "../PaymentFilterForm";
import { StatusEnum } from "../../../enums/status.enum";
import { SelectProp } from "../../../shared/Types/option";
import alertIcon from "../../../assets/images/alertIcon.png";
import AppLoader from "../../../shared/components/AppLoader";
import { removeUnderscore } from "../../../shared/utils/removeUnderscore";
import { PaginationDetails } from "../../../models/Pagination/pagination.model";
import useFilters from "../../../shared/hooks/useFilter/useFilters";
import { TablePaginationConfig } from "antd/lib/table";
import useResponsibilities from "../../../shared/hooks/Responsibilities/useResponsibilities";
import { ResponsibilityTypes } from "../../../enums/responsebily.enum";
import { paymentModeOptions } from "shared/options/paymentModelOptions";
import { Formik, Form, FormikProps, FormikValues } from "formik";
import DropdownField from "shared/components/DropdownField";
import InputField from "shared/components/InputField";
import commentIcon from "../../../assets/images/commentIcon.png";
import { PaymentModeEnum } from "enums/paymentMode.enum";

interface PaymentsReceiptProps {}

const PaymentsReceipt: FC<PaymentsReceiptProps> = (props) => {
  const {} = props;
  const formikRef = useRef<FormikProps<PaymentIntent>>(null);

  const { hasAccess } = useResponsibilities();

  const [newPayment, setNewPayment] = useState<PaymentIntent | boolean>(false);
  const [paymentMetrics, setPaymentMetrics] = useState<PaymentInvoiceMetrics>();

  const [paymentIntents, setPaymentIntents] = useState<PaymentIntent[]>([]);
  const [selectedIntents, setSelectedIntents] = useState<PaymentIntent[]>([]);

  const [loading, setLoading] = useState(false);
  const [isFilterFormVisible, setIsFilterFormVisible] = useState(false);
  const [warningModalVisibility, setwarningModalVisibility] = useState(false);
  const [selectedPaymentIds, setSelectedPaymentIds] = useState<number[]>([]);
  const [multiStatus, setMultiStatus] = useState<string[]>([]);
  const [pagination, setPagination] = useState<PaginationDetails>();

  const { getParams, setParams } = useFilters();

  const HAS_OFFLINE_PAYMENTS_CREATE_ACCESS = hasAccess(
    ResponsibilityTypes.OFFLINE_PAYMENTS_CREATE
  );

  const HAS_PAYMENT_INVOICES_EDIT_ACCESS = hasAccess(
    ResponsibilityTypes.PAYMENT_INVOICES_EDIT
  );

  const handleFilterFormVisibility = () => {
    setIsFilterFormVisible(!isFilterFormVisible);
  };

  const HandleWarningModal = () => {
    setwarningModalVisibility(!warningModalVisibility);
  };

  const statusOptions: SelectProp[] = [
    {
      label: "Paid",
      value: StatusEnum.COMPLETED,
    },
    {
      label: "Initiated",
      value: StatusEnum.INITIATED,
    },
    {
      label: "Past Due",
      value: StatusEnum.PAST_DUE,
    },

    {
      label: "Invalid",
      value: StatusEnum.INVALID,
    },
    {
      label: "Written Off",
      value: StatusEnum.WRITTEN_OFF,
    },
  ];
  const handleFetchInvoice = (filters?: object) => {
    setLoading(true);
    PaymentService.getPaymentInvoices(
      filters ?? {},
      (paymentIntents: PaymentIntent[], pagination: PaginationDetails) => {
        setPaymentIntents(paymentIntents);
        setPagination(pagination);
      },
      () => {},
      () => {
        setLoading(false);
      }
    );
  };

  const handleUpdateInvoiceStatus = (values: PaymentIntent) => {
    const params = { ...values, ids: selectedPaymentIds };
    setLoading(true);
    PaymentService.updatePaymentInvoicesStatus(
      params,
      (paymentIntents: PaymentIntent[]) => {
        setSelectedPaymentIds([]);
        handleFetchInvoice(getParams());
      },
      () => {},
      () => {
        setLoading(false);
      }
    );
  };

  const handleFetchInvoiceMetrics = () => {
    setLoading(true);
    PaymentService.getPaymentInvoicesMetrics(
      (paymentMetrics: PaymentInvoiceMetrics) => {
        setPaymentMetrics(paymentMetrics);
      },
      () => {},
      () => {
        setLoading(false);
      }
    );
  };
  useEffect(() => {
    handleFetchInvoice(getParams());
    handleFetchInvoiceMetrics();
  }, []);

  const handleTableChange = (pagination: TablePaginationConfig) => {
    const prevFilter = getParams();
    const updatedFilters = {
      ...prevFilter,
      page: pagination.current ?? 1,
    };
    setParams(updatedFilters);
    handleFetchInvoice(updatedFilters);
  };

  const columns = [
    {
      title: "Patient ID",
      dataIndex: "patientProfileId",
      key: "patientProfileId",
    },
    {
      title: "Patient Name",
      dataIndex: "name",
      key: "name",
      render: (
        _: string,
        { patientFirstName, patientLastName }: PaymentIntent
      ) => {
        const patientName =
          patientFirstName && patientLastName
            ? `${patientFirstName} ${patientLastName}`
            : patientFirstName || patientLastName || "";

        return <span>{patientName}</span>;
      },
    },
    {
      title: "Contract Name",
      dataIndex: "contractName",
      key: "contractName",
      render: (contractName: string) => contractName || "--",
    },
    {
      title: "Transaction ID",
      dataIndex: "transactionId",
      key: "transactionId",
      render: (id: string) => <span className="text-upper">{id ?? "--"}</span>,
    },

    {
      title: "Insurance provider",
      dataIndex: "insuranceProvider",
      key: "insuranceProvider",
      render: (insuranceProvider: string) =>
        removeUnderscore(insuranceProvider ?? "--"),
    },
    {
      title: "Invoice Date",
      dataIndex: "invoiceDate",
      key: "invoiceDate",
      render: (date: string) => moment(date).format("MM/DD/YYYY"),
    },
    {
      title: "Due Date",
      dataIndex: "dueDate",
      key: "dueDate",
      render: (date: string) => (
        <span>{date ? moment(date).format("MM/DD/YYYY") : "--"}</span>
      ),
    },
    {
      title: "Payment Type",
      dataIndex: "paymentType",
      key: "paymentType",
      render: (payementType: string) => (
        <span className="text-capitalize">
          {removeUnderscore(payementType)}
        </span>
      ),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (amount: number) => amount && `$${amount}`,
    },
    {
      title: "Payment Mode",
      dataIndex: "paymentMode",
      key: "paymentMode",
      render: (paymentMode: PaymentModeEnum) =>
        removeUnderscore(paymentMode ?? "--"),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status: string) => (
        <span className={`${status} text-capitalize`}>
          {removeUnderscore(status == "completed" ? "Paid" : status)}
        </span>
      ),
    },
    {
      title: "",
      dataIndex: "comment",
      key: "comment",
      render: (comment: string) =>
        comment ? (
          <Popover content={comment} placement="left">
            <img
              alt="Comment logo"
              className="comment-icon"
              height={30}
              src={commentIcon}
              width={30}
            />
          </Popover>
        ) : (
          <img
            alt="Comment logo"
            className="comment-icon icon-disabled"
            height={30}
            src={commentIcon}
            width={30}
          />
        ),
    },
  ];

  const handleOpenPayment = () => {
    setNewPayment(true);
  };

  const handleClosePayment = () => {
    setNewPayment(false);
  };

  const handleSuccessPayment = (paymentIntent: PaymentIntent) => {
    setPaymentIntents((paymentIntents) => [paymentIntent, ...paymentIntents]);
    handleClosePayment();
  };

  const paymentStatsData = [
    {
      title: "Payment due for Month",
      value: paymentMetrics?.thisMonthPayments ?? 0,
    },
    {
      title: "Amount Received",
      value: paymentMetrics?.totalAmount ?? 0,
    },
  ];

  const rowSelection = {
    selectedRowKeys: selectedPaymentIds,

    onChange: (
      selectedRowKeys: React.Key[],
      paymentIntent: PaymentIntent[]
    ) => {
      const paymentIntentIds = paymentIntent.map(
        (paymentIntent) => paymentIntent?.id ?? 0
      );
      const status = paymentIntent.map(
        (paymentIntent) => paymentIntent?.status ?? ""
      );
      setSelectedPaymentIds(paymentIntentIds); //payment ids
      setMultiStatus(status); //payment status
      setSelectedIntents(paymentIntent); //payment json array
    },
  };

  function checkUniqueStatus(status: string[]) {
    return status?.every((e) => e == status[0]);
  }

  const handleUpdateStatus = () => {
    const statusList = selectedIntents?.map(
      (selectedIntents) => selectedIntents?.status ?? ""
    );
    const validStatus = checkUniqueStatus(statusList);

    if (validStatus) {
      let totalAmount = 0;
      selectedIntents.forEach(function (obj) {
        totalAmount += Number(obj?.amount) ?? 0;
      });

      Modal.confirm({
        className: "payments__update-status",
        title: <span className="text-primary">Update Status</span>,
        icon: null,
        content: (
          <div className="text-bold">
            <div className="payments__update-status__content">
              <p className="mt-2">
                {" "}
                You have selected {statusList?.length} invoices under status{" "}
                <span className={`text-capitalize ${statusList[0]}`}>
                  {" "}
                  {removeUnderscore(statusList[0])}
                </span>{" "}
                with cumulative amount of $ {totalAmount}
              </p>
            </div>
            <Formik
              initialValues={new PaymentIntent()}
              innerRef={formikRef}
              onSubmit={() => {}}
              enableReinitialize
            >
              {({ handleSubmit, values, errors, setFieldValue }) => {
                return (
                  <Form>
                    <Row className="mt-5" gutter={[20, 0]}>
                      <Col span={12}>
                        <DropdownField
                          title="Payment Status"
                          name="status"
                          value={values.status}
                          options={statusOptions}
                          setFieldValue={setFieldValue}
                          placeHolder="Select Payment Status"
                          onChange={(value) => {
                            setFieldValue("status", value);
                            setFieldValue(
                              "paymentMode",
                              value !== StatusEnum.COMPLETED && undefined
                            );
                          }}
                        />
                      </Col>
                      {values.status === StatusEnum.COMPLETED && (
                        <Col span={12}>
                          <DropdownField
                            title="Mode of Payment"
                            name="paymentMode"
                            value={values.paymentMode}
                            options={paymentModeOptions}
                            setFieldValue={setFieldValue}
                            placeHolder="Select Payment Mode"
                          />
                        </Col>
                      )}
                      <Col span={24}>
                        <InputField
                          type="textarea"
                          title="Comments"
                          name="comment"
                          placeholder="Enter Comment"
                          rows={6}
                        />
                      </Col>
                    </Row>
                  </Form>
                );
              }}
            </Formik>
          </div>
        ),
        onOk: () => {
          const values = formikRef?.current?.values;
          if (values) handleUpdateInvoiceStatus(values);
        },
      });
    } else HandleWarningModal();
  };

  return (
    <div className="payments">
      {HAS_OFFLINE_PAYMENTS_CREATE_ACCESS && (
        <Row className="mb-5">
          <Col span={24} className="text-right">
            <Button type="primary" onClick={handleOpenPayment}>
              <PlusOutlined /> Add Payment
            </Button>
          </Col>
        </Row>
      )}

      <Stats statsData={paymentStatsData} statsType={StatsType.PAYMENT} />
      <div className="payments__table">
        <Row align="middle" justify="space-between" className="mt-5">
          <Col>
            <h4>All Payment Invoices</h4>
          </Col>
          <Col>
            {HAS_PAYMENT_INVOICES_EDIT_ACCESS && (
              <Button
                className="blue-btn mr-2"
                onClick={handleUpdateStatus}
                disabled={
                  selectedPaymentIds?.length < 1 ||
                  multiStatus.some((status) => status === "completed")
                }
              >
                Update Status
              </Button>
            )}
            <Button
              className="secondary-btn mr-2"
              onClick={handleFilterFormVisibility}
            >
              <FilterFilled />
              Filter
            </Button>
          </Col>
        </Row>
      </div>
      {loading ? (
        <AppLoader loading />
      ) : (
        <Table
          dataSource={paymentIntents}
          columns={columns}
          loading={loading}
          rowKey={"id"}
          rowSelection={{
            preserveSelectedRowKeys: false,
            type: "checkbox",
            ...rowSelection,
          }}
          scroll={{
            scrollToFirstRowOnChange: true,
            x: true,
          }}
          pagination={{
            total: pagination?.totalCount,
            current: pagination?.currentPage ?? 1,
            showTotal: (total: number, range: [number, number]) => (
              <p>
                Showing <b>{` ${range[0]} - ${range[1]}`}</b> of{" "}
                <b>{`${total.toLocaleString()}`}</b>
              </p>
            ),
          }}
          onChange={handleTableChange}
        />
      )}
      <Modal
        className="payments__warning-modal"
        visible={warningModalVisibility}
        onCancel={HandleWarningModal}
        destroyOnClose
        closable
      >
        <div>
          <img src={alertIcon} alt="alert" />
          <p className="mt-3">
            Invoices under different status cannot be updated at the same time
          </p>
        </div>
      </Modal>
      <Drawer
        visible={newPayment ? true : false}
        title="New Payment"
        width="65vw"
        destroyOnClose
        onClose={handleClosePayment}
      >
        <PaymentForm
          fromValue={newPayment}
          successHandler={handleSuccessPayment}
          closeHandler={handleClosePayment}
        />
      </Drawer>
      <Drawer
        title="Invoice Filters"
        width="50vw"
        visible={isFilterFormVisible}
        // onClose={handleCloseNextAppointment}
        closable
        destroyOnClose
        onClose={handleFilterFormVisibility}
      >
        <PaymentFilterForm
          onClose={handleFilterFormVisibility}
          onSuccess={(updatedPaymentList: PaymentIntent[]) =>
            setPaymentIntents(updatedPaymentList)
          }
        />
      </Drawer>
    </div>
  );
};

export default PaymentsReceipt;
