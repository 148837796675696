import { serializable, alias, primitive } from "serializr";

export class ObjectNote {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("corporate_object_id", primitive()))
  corporateObjectId?: number;

  @serializable(alias("corporate_object_name", primitive()))
  corporateObjectName?: string;

  @serializable(alias("tooth_position_id", primitive()))
  toothPositionId?: number;

  @serializable(alias("tooth_position_name", primitive()))
  toothPositionName?: string;

  @serializable(alias("notes", primitive()))
  notes?: string;
}
