import React, { FC, useState } from "react";
import { Field, ErrorMessage } from "formik";
import { Input } from "antd";
import Error from "../Error";
import "./inputField.scss";

interface InputFieldProps {
  title?: string;
  type: string;
  name: string;
  placeholder?: string;
  mt?: string;
  rounded?: boolean;
  classes?: string;
  addonBefore?: any;
  value?: string | number;
  readOnly?: boolean;
  onChange?: Function;
  disabled?: boolean;
  prefix?: string;
  rows?: number;
  darkmode?: boolean;
  hint?: string;
  ref?: HTMLInputElement;
  onKeyUp?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  errorMessage?: string;
  validateSchema?: (value: string) => Promise<boolean>;
  defaultValue?: string;
}

const InputField: FC<InputFieldProps> = (props) => {
  const {
    name,
    type,
    title,
    mt,
    rounded,
    classes,
    addonBefore,
    readOnly,
    onChange,
    value,
    disabled,
    prefix,
    rows = 4,
    hint,
    darkmode,
    errorMessage = "",
    validateSchema,
  } = props;

  const [valid, setValid] = useState(true);

  const handleKeyUp = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.currentTarget.value && validateSchema) {
      const isValid = await validateSchema(event.currentTarget.value);
      setValid(isValid);
    }
  };

  return (
    <div
      className={
        classes
          ? classes
          : `input-field-wrapper ${darkmode && "input-field-wrapper__dark"}`
      }
      style={{ marginTop: `${mt}rem` }}
    >
      {title && <p className="input-field__title">{title}</p>}
      <Field
        className={`input-field`}
        as={type === "textarea" ? Input.TextArea : Input}
        {...props}
        onKeyUp={handleKeyUp}
        onKeyDown={(evt: React.KeyboardEvent<HTMLInputElement>) => {
          // Since by default browser allow 'e' for exponent & - for minus. Since we don't these 2 will prevent
          if (type === "number" && (evt?.key === "e" || evt?.key === "-"))
            evt?.preventDefault();
        }}
      />
      {hint && (
        <span className="hint">
          {" "}
          <span>*</span>
          {hint}
        </span>
      )}
      {valid ? (
        <ErrorMessage name={name}>
          {(message: string) => (
            <Error className={`${name}__error`} message={message} />
          )}
        </ErrorMessage>
      ) : (
        <Error className={`${name}__error`} message={errorMessage} />
      )}
    </div>
  );
};

export default InputField;
