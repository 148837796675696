import moment from "moment";
import React from "react";
import { IDropdownOptions } from "../../services/Meta/meta.service";
import { Space } from "antd";

export const getYearsAndMonths = (dateString: string) => {
  const date = moment(dateString, "YYYY-MM-DD");
  const age = moment.duration(moment().diff(date));
  return <Space>
    <span>{date.format("MM/DD/YYYY")}</span>
    <span>({age.years() + " years " + age.months() + " months"})</span>
  </Space>;
};

export const getDateOptions = (
  startDate: string,
  endDate: string
): IDropdownOptions[] => {
  let dates: IDropdownOptions[] = [];
  while (moment(startDate) <= moment(endDate)) {
    dates.push({
      label: moment(startDate, "YYYY-MM-DD").format("DD MMM, YYYY"),
      value: startDate,
    });
    startDate = moment(startDate).add(1, "days").format("YYYY-MM-DD");
  }
  return dates;
};


export const getTimeDiff = (startTime?: string, endTime?: string) => {
  const currentStartTime = moment(
    startTime,
    "DD-MM-YYYY hh:mm:ss"
  );
  const currentEndTime = moment(
    endTime,
    "DD-MM-YYYY hh:mm:ss"
  );
  const difference = currentEndTime.diff(
    currentStartTime,
    "seconds"
  );

  return difference;
}

export const compareStartAndEndDate = (start?: string | null, end?: string | null, DATE_FORMAT = "YYYY-MM-DD") => {
  if (!start || !end) return true;

  const startDate = moment(start, DATE_FORMAT);
  const endDate = moment(end, DATE_FORMAT);
  return startDate?.isSameOrBefore(endDate);
};