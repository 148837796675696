import React, { useEffect, useState } from "react";

import { Patient } from "../../../models/Patient/patient.model";
import { Button, Drawer, Table } from "antd";
import moment from "moment";
import { useHistory, generatePath, useParams } from "react-router-dom";
import AppRoutes from "../../../routes/routeConstants/appRoutes";
import AuthContainer from "../../../store/container/AuthContainer";
import { AuthReducerProps } from "../../../store/reducers/authReducer";
import { ResponsibilityTypes } from "../../../enums/responsebily.enum";
import Stats from "../../../shared/components/Stats";
import { StatsType } from "../../../enums/statsType.enum";
import ContractFilterForm from "../../Services/Contracts/ContractFilterForm";
import { MenuOutlined } from "@ant-design/icons";
import { useMetrics } from "../../../shared/hooks/Metrics/useMetrics";
import PatientService from "../../../services/Patient/patient.service";
import PaymentService from "../../../services/Payment/payment.service";
import { PaymentIntent } from "../../../models/PaymentIntent/paymentIntent.model";
import { removeUnderscore } from "../../../shared/utils/removeUnderscore";

interface PaymentInvoicesProps extends AuthReducerProps {
  patients: Patient[];
}

function PaymentInvoices({
  patients,
  userResponsibilities,
}: PaymentInvoicesProps) {
  const history = useHistory();
  const params = useParams<{ patientId: string }>();

  const [loading, setLoading] = useState(false);
  const [paymentIntents, setPaymentIntents] = useState<PaymentIntent[]>([]);

  const columns = [
    {
      title: "Transaction ID",
      dataIndex: "transactionId",
      key: "transactionId",
    },
    {
      title: "Contract Name",
      dataIndex: "contractName",
      key: "contractName",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Payment Date",
      dataIndex: "invoiceDate",
      key: "invoiceDate",
      render: (invoiceDate: string) => moment(invoiceDate).format("MM-DD-YYYY"),
    },
    {
      title: "Payment Method",
      dataIndex: "paymentType",
      key: "paymentType",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status: string) => (
        <span className={`${status} text-capitalize`}>
          {removeUnderscore(status == "completed" ? "Paid" : status)}
        </span>
      ),
    },
  ];

  useEffect(() => {
    setLoading(true);
    PaymentService.getPaymentInvoices(
      { patientProfileId: params?.patientId },
      (paymentIntents: PaymentIntent[]) => {
        setPaymentIntents(paymentIntents);
      },
      () => {},
      () => {
        setLoading(false);
      }
    );
  }, []);

  return (
    <div className="patient-table">
      <Table dataSource={paymentIntents} columns={columns} loading={loading} />
    </div>
  );
}

export default AuthContainer(PaymentInvoices);
