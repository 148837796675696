import { useSelector } from "react-redux";
import { ResponsibilityTypes } from "../../../enums/responsebily.enum";
import { User } from "../../../models/User/user.model";
import { RootReducerProps } from "../../../store/reducers";
import {
  isCorporateAdmin,
  isDoctor,
  isFrontDeskPerson,
} from "../../utils/roleUtils";

const useResponsibilities = () => {
  const loggedInUser = useSelector<RootReducerProps>(
    (store) => store?.auth?.user
  ) as User;

  const userResponsibilities = useSelector<RootReducerProps>(
    (store) => store?.auth?.userResponsibilities
  ) as ResponsibilityTypes[];

  const IS_LOGGED_IN_USER_ADMIN = isCorporateAdmin(loggedInUser);

  const IS_LOGGED_IN_USER_DOCTOR = isDoctor(loggedInUser);

  const IS_LOGGED_IN_USER_FRONT_DESK = isFrontDeskPerson(loggedInUser);

  const hasAccess = (type: ResponsibilityTypes) =>
    IS_LOGGED_IN_USER_ADMIN || !!userResponsibilities?.includes(type);

  const hasSomeAccess = (types: ResponsibilityTypes[]) =>
    types.some((type) => hasAccess(type));

  return {
    IS_LOGGED_IN_USER_ADMIN,
    IS_LOGGED_IN_USER_DOCTOR,
    IS_LOGGED_IN_USER_FRONT_DESK,
    hasAccess,
    hasSomeAccess,
  };
};

export default useResponsibilities;
