import React, { FC, useEffect, useState } from "react";
import "./doctorAppointments.scss";
import { Table } from "antd";
import {
  DoctorAppointmentDate,
  DoctorAppointmentDateParams,
} from "models/AppointmentPractice/appointmentPractice.model";
import { AppointmentService } from "services/Appointment/appointment.service";
import generateColumns from "./doctorAppointmentsColumns";
import { useHistory } from "react-router-dom";

interface DoctorAppointmentsProps {
  doctorProfileId: number;
}

const DoctorAppointments: FC<DoctorAppointmentsProps> = (props) => {
  const history = useHistory();

  const { doctorProfileId } = props;

  const [doctorAppointments, setDoctorAppointments] = useState<
    DoctorAppointmentDate[]
  >([]);

  const [loading, setLoading] = useState(false);

  const handleRedirect = (date: string) => {
    const baseUrl = new URL(window.location.origin);

    const searchParams = new URLSearchParams();

    searchParams.set("date", date);

    searchParams.set("doctorProfileId", doctorProfileId?.toString());

    baseUrl.search = searchParams?.toString();

    window.open(baseUrl.toString(), "_blank");
  };

  useEffect(() => {
    setLoading(true);
    AppointmentService.fetchDoctorAppointments(
      new DoctorAppointmentDateParams(doctorProfileId),
      (doctorAppointments) => {
        setDoctorAppointments(doctorAppointments);
      },
      () => {},
      () => {
        setLoading(false);
      }
    );
  }, []);

  return (
    <div className="doctor-appointments">
      <Table
        columns={generateColumns()}
        dataSource={doctorAppointments}
        loading={loading}
        onRow={(appointmentDate) => ({
          onClick: () =>
            handleRedirect(appointmentDate?.parseDate("MM-DD-YYYY")),
        })}
        pagination={{
          hideOnSinglePage: true,
        }}
      />
    </div>
  );
};

export default DoctorAppointments;
