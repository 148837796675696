
import {serializable, alias, object, list, primitive} from 'serializr';

export class XRayNote { 

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('x_ray_type_id', primitive()))
	xRayTypeId?: number;

	@serializable(alias('x_ray_type_name', primitive()))
	xRayTypeName?: string;

	@serializable(alias('notes', primitive()))
	notes?: string;

	@serializable(alias('created_at', primitive()))
	createdAt?: string;

	@serializable(alias("x_ray_template_category_id", primitive()))
	xRayTemplateCategoryId?: number;
}