import * as Yup from "yup";
import moment from "moment";
import { CANADIAN_ZIP_CODE } from "../../../shared/Regex";

export const patientFormValidation = Yup.object().shape({
  firstName: Yup.string().required("First name is required!"),
  lastName: Yup.string().required("Last name is required!"),
  gender: Yup.string().required("Gender is required!"),
  dob: Yup.string().required("DOB is required!"),
  email: Yup.string().nullable(),
  isdCode: Yup.string().when("dob", (dob: string, schema: any) => {
    const age = moment.duration(moment().diff(moment(dob, "YYYY-MM-DD")));
    return age.years() >= 18
      ? schema.required("Isd Code is required!")
      : schema;
  }),
  mobileNumber: Yup.string()
    .min(10, "Mobile number should have 10 digits!")
    .max(10, "Mobile number should have 10 digits!"),
  cityId: Yup.string().nullable(),
  street: Yup.string().nullable(),
  zipcode: Yup.string().matches(CANADIAN_ZIP_CODE, "Enter a valid zipcode").nullable(),
  enableResponsiblePerson: Yup.boolean(),
  responsiblePerson: Yup.object().when("enableResponsiblePerson", {
    is: true,
    then: Yup.object().shape({
      firstName: Yup.string().required("First name is required!"),
      lastName: Yup.string().required("Last name is required!"),
      gender: Yup.string().required("Gender is required!"),
      dob: Yup.string().required("DOB is required!"),
      email: Yup.string().nullable(),
      isdCode: Yup.string().nullable(),
      mobileNumber: Yup.string().when("mobileNumber", {
        is: (val) => val?.length > 0,
        then: Yup.string()
          .min(10, "Mobile number should have 10 digits!")
          .max(10, "Mobile number should have 10 digits!"),
        otherwise: Yup.string().nullable()
      }),
      cityId: Yup.string().nullable(),
      street: Yup.string().nullable(),
      zipcode: Yup.string().matches(CANADIAN_ZIP_CODE, "Enter a valid zipcode").nullable(),
    }, [["mobileNumber", "mobileNumber"]]),
    otherwise: Yup.object().shape({}).nullable(),
  }),
});
