import { Corporate } from "../../models/Corporate/corporate.model";
import { deserialize, serialize } from "serializr";
import axiosInstance from "../../interceptor/axiosInstance";
import ApiRoutes from "../../routes/routeConstants/apiRoutes";
import { Subscription } from "../../models/Subscription/subscription.model";
import { SubscriptionFrequencie } from "../../models/SubscriptionFrequencie/subscriptionFrequencie.model";
import { CorporateSubscription } from "../../models/CorporateSubscription/corporateSubscription.model";
export class CorporateService {
  static showCorporateProfile(
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const corporateId = localStorage.getItem("corporateId");
    if (corporateId) {
      return axiosInstance
        .get(ApiRoutes.CORPORATE_PROFILE)
        .then((response) => {
          const corporate = deserialize(Corporate, response.data["corporate"]);
          localStorage.setItem("corporate", JSON.stringify(corporate));
          onSuccess(corporate);
        })
        .catch((error) => {
          onError();
        })
        .finally(onFinal);
    }
  }

  static updateCorporateProfile(
    corporate: Corporate,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const corporateJSON = {
      corporate: serialize(Corporate, corporate),
    };

    return axiosInstance
      .put(
        ApiRoutes.CORPORATE_PROFILE?.replace(":corporateId", corporate?.id),
        corporateJSON
      )
      .then((response) => {
        const corporate = deserialize(Corporate, response.data["corporate"]);
        localStorage.setItem("corporate", JSON.stringify(corporate));
        onSuccess(corporate);
      })
      .catch((error) => {
        onError();
      })
      .finally(onFinal);
  }

  static async mySubscriptions(
    onSuccess: (subscriptions: Subscription[]) => void,
    onError: Function,
    onFinal: () => void
  ) {
    try {
      const { data } = await axiosInstance.get(
        ApiRoutes.CORPORATE_SUBSCRIPTION
      );
      const subscriptions = deserialize(
        Subscription,
        data["subscriptions"] as Subscription[]
      );
      onSuccess(subscriptions);
    } catch (error) {
      onError();
    } finally {
      onFinal();
    }
  }

  static async subscriptionFrequencyId(
    frequencyId: number,
    onSuccess: (subscription: SubscriptionFrequencie) => void,
    onError: Function,
    onFinal: () => void
  ) {
    try {
      const { data } = await axiosInstance.get(
        ApiRoutes.SUBSCRIPTION_FREQUENCY + "/" + frequencyId
      );
      const subscription = deserialize(
        SubscriptionFrequencie,
        data["subscription_frequency"]
      );
      onSuccess(subscription);
    } catch (error) {
      onError();
    } finally {
      onFinal();
    }
  }

  static async getCorporateSubscription(
    onSuccess: (corporateSubscription: CorporateSubscription) => void,
    onFinal: () => void
  ) {
    try {
      const { data } = await axiosInstance.get(
        ApiRoutes.UPDATE_CORPORATE_SUBSCRIPTION
      );
      const subscription = deserialize(
        CorporateSubscription,
        data?.corporate_subscription
      );
      onSuccess(subscription);
    } catch (error) {
    } finally {
      onFinal();
    }
  }

  static async updateCorporateSubscription(
    corporateSubscription: CorporateSubscription,
    onSuccess: (corporateSubscription: CorporateSubscription) => void,
    onFinal: () => void
  ) {
    try {
      const corporateSubscriptionJSON = {
        corporate_subscription: serialize(corporateSubscription),
      };
      const { data } = await axiosInstance.put(
        ApiRoutes.UPDATE_CORPORATE_SUBSCRIPTION,
        corporateSubscriptionJSON
      );
      const subscription = deserialize(
        CorporateSubscription,
        data?.corporate_subscription
      );
      onSuccess(subscription);
    } catch (error) {
    } finally {
      onFinal();
    }
  }

  static async validateEmail(email: string) {
    try {
      await axiosInstance.post(ApiRoutes.CORPORATE_EMAIL_VALIDATION, {
        email,
      });
      return true;
    } catch (error) {
      return false;
    }
  }
  static async validationUserPhone() {
    try {
      await axiosInstance.post(ApiRoutes.USERS_PHONE_VALIDATION);
      return true;
    } catch (error) {
      return false;
    }
  }
}
