export const removeFalsyKeys = (obj: any) => {
  for (var propName in obj) {
    if (!obj[propName] || obj[propName].length === 0) {
      delete obj[propName];
    } else if (typeof obj[propName] === "object") {
      removeFalsyKeys(obj[propName]);
    }
  }

  return obj;
};
