import React from "react";
import {
  AppointmentDayCalender,
  DaySchedule,
} from "../../../../models/AppointmentDay/appointmentDay.model";
import { Moment } from "moment";
import AllocatedSlot from "./AllocatedSlot";
import UnAllocatedSlot from "./UnAllocatedSlot";
import { CalendarSlot } from "../../../../shared/Types/CalendarSlot.type";

import "./slot.scss";

interface SlotProps {
  slot: CalendarSlot;
  currentSchedule: DaySchedule[];
  currentDate: Moment;
  currentResource: string;
  onScheduleSlotUpdate: (
    schedules: AppointmentDayCalender,
    oldSlotId: number
  ) => void;
  onAppointmentSlotDelete: (oldSlotId: number, resourceId: number) => void;
  onAppointmentFetch: () => void;
}

const Slot = ({
  slot,
  onAppointmentSlotDelete,
  onScheduleSlotUpdate,
  currentDate,
  currentResource,
  onAppointmentFetch,
}: SlotProps) => {
  const isSlotAllocated = !!slot?.schedule?.appointmentId;

  return isSlotAllocated ? (
    <AllocatedSlot
      currentResource={currentResource}
      date={currentDate}
      onAppointmentFetch={onAppointmentFetch}
      slot={slot}
    />
  ) : (
    <UnAllocatedSlot
      date={currentDate}
      onAppointmentFetch={onAppointmentFetch}
      onAppointmentSlotDelete={onAppointmentSlotDelete}
      onScheduleSlotUpdate={onScheduleSlotUpdate}
      resource={currentResource}
      slot={slot}
    />
  );
};

export default Slot;
