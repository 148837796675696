import { Button, Col, Row, Space, Tooltip } from "antd";
import Form from "antd/lib/form/Form";
import { Formik } from "formik";
import React, { FC, useEffect, useState } from "react";
import { ContractTermTemplate } from "../../../../models/ContractTermsTemplate/contractTermsTemplate.model";
import Editor, {
  Editor as EditorProps,
} from "../../../../shared/components/Editor";
import InputField from "../../../../shared/components/InputField";
import { contractTermsTemplatesFormValidation } from "./contractTermsTemplatesFormValidation";
import "./contractTermsTemplate.scss";
import ContractTermTemplateService from "../../../../services/ContractTermTemplate/ContractTermTemplate.service";
import DropdownField from "../../../../shared/components/DropdownField";
import { InfoCircleOutlined } from "@ant-design/icons";

interface ContractFormTemplatesFormProps {
  formValue?: ContractTermTemplate;
  onSubmitHandler: (values: ContractTermTemplate, type: "add" | "edit") => void;
  onCloseHandler: () => void;
}

const ContractFormTemplatesForm: FC<ContractFormTemplatesFormProps> = (
  props
) => {
  const { formValue, onSubmitHandler, onCloseHandler } = props;

  const [variables, setVariables] = useState<string[]>([]);

  const [loading, setLoading] = useState(false);

  const [initialValue, setInitialValue] = useState<ContractTermTemplate>(
    new ContractTermTemplate()
  );

  const [editor, setEditor] = useState<EditorProps>();

  useEffect(() => {
    if (formValue) {
      setInitialValue(formValue);
    }
  }, [formValue]);

  useEffect(() => {
    ContractTermTemplateService.fetchCustomFormVariables(
      (variables) => {
        setVariables(variables);
      },
      () => {},
      () => {}
    );
  }, []);

  const handleSubmit = (values: ContractTermTemplate) => {
    setLoading(true);
    const contractTermTemplate = Object.assign(
      new ContractTermTemplate(),
      values
    );
    ContractTermTemplateService[
      formValue ? "updateContractTermTemplate" : "createContractTermTemplate"
    ](
      contractTermTemplate,
      (contractTermTemplate) => {
        onSubmitHandler(contractTermTemplate, formValue ? "edit" : "add");
      },
      () => {},
      () => {
        setLoading(false);
      }
    );
  };

  return (
    <div className="contract-terms-templates">
      <h4>{formValue ? "Edit" : "Create"} Term Template</h4>
      <Formik
        initialValues={initialValue}
        onSubmit={handleSubmit}
        validationSchema={contractTermsTemplatesFormValidation}
        enableReinitialize
      >
        {({ values, errors, setFieldValue, handleSubmit }) => (
          <Form>
            <Row gutter={24}>
              <Col span={12}>
                <InputField
                  type="text"
                  name="name"
                  title="Title"
                  placeholder="Title"
                />
              </Col>
              <Col span={12}>
                <DropdownField
                  name="variables"
                  options={variables?.map((variable) => ({
                    label: variable?.replaceAll("_", " "),
                    value: `###${variable}###`,
                  }))}
                  onChange={(variable) => {
                    editor?.model?.change((writer) =>
                      writer?.insertText(
                        `${variable}`,
                        editor?.model?.document?.selection?.getFirstPosition()
                      )
                    );
                  }}
                  title={
                    <Space>
                      <span>Variables</span>
                      <Tooltip
                        className="cursor-pointer"
                        title="Append dynamic variables wherever you want to add in the custom form template"
                      >
                        <InfoCircleOutlined />
                      </Tooltip>
                    </Space>
                  }
                  value="Append Variable"
                />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Editor
                  //   edit={formValue ? true : false}
                  value={values.description ?? ""}
                  error={errors.description}
                  onChangeHandler={(content) => {
                    setFieldValue("description", content);
                  }}
                  onReady={(editor) => setEditor(editor)}
                />
              </Col>
            </Row>
            <Row>
              <Col span={16} />
              <Col span={8} className="contract-form__controller">
                <Button type="text" onClick={onCloseHandler}>
                  Cancel
                </Button>
                <Button
                  onClick={() => handleSubmit()}
                  type="primary"
                  loading={loading}
                >
                  Save
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ContractFormTemplatesForm;
