import { serializable, alias, object, list, primitive } from "serializr";

export class AppointmentTemplateSlotCreation {
  @serializable(alias("start_time", primitive()))
  startTime?: string;

  @serializable(alias("end_time", primitive()))
  endTime?: string;

  @serializable(alias("resource_id", primitive()))
  resourceId?: string;

  @serializable(alias("appointment_type_id", primitive()))
  appointmentTypeId?: number;
}
