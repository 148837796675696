import {
  AUTHENTICATED,
  SET_RESPONSIBILITIES,
  SET_USER,
  UNAUTHENTICATED,
} from "../definitions/authConstants";
import { CreateReducer } from "../../shared/utils/createReducer";
import { User } from "../../models/User/user.model";
import { ActionProps } from "../../shared/Types/action.type";

interface IAuthState {
  authenticated: boolean;
  user?: User | null;
  userResponsibilities: string[];
}

export interface AuthReducerProps extends IAuthState {
  setResponsibilities: (userResponsibilities: string[]) => void;
  setUser: (user: User) => void;
}

const initState: IAuthState = {
  authenticated: false,
  user: null,
  userResponsibilities: [],
};

const authReducer = CreateReducer(initState, {
  [AUTHENTICATED](state: any, action: any) {
    const { authenticated, user } = action.payload;
    return {
      ...state,
      authenticated,
      user,
    };
  },
  [UNAUTHENTICATED](state: any, payload: any) {
    const { authenticated } = payload;
    return { ...state, authenticated };
  },
  [SET_RESPONSIBILITIES](state: any, action: any) {
    const { userResponsibilities } = action.payload;
    return {
      ...state,
      userResponsibilities,
    };
  },
  [SET_USER](state: IAuthState, action: ActionProps): IAuthState {
    const { user } = action.payload;
    
    return {
      ...state,
      user,
      userResponsibilities: user?.permissions?.length
        ? user.permissions ?? []
        : [],
    };
  },
});

export default authReducer;
