import * as Yup from "yup";
import { CANADIAN_ZIP_CODE } from "../../Regex";

export const userFormValidation = Yup.object().shape({
  firstName: Yup.string().required("First name is required!"),
  lastName: Yup.string().required("Last name is required!"),
  email: Yup.string()
    .required("Email is required!")
    .email("Email is not valid!"),
  isdCode: Yup.string().required("Isd Code is required!"),
  mobileNumber: Yup.string()
    .required("Mobile number is required!")
    .min(10, "Mobile number should have 10 digits!")
    .max(10, "Mobile number should have 10 digits!"),
  roleId: Yup.string().required("Role is required!"),
  // cityId: Yup.string().typeError("Choose a City").required("City is required!"),
  // provinceId: Yup.string().typeError("Choose a State").required("State is required!"),
  // countryId: Yup.string().typeError("Choose a Country").required("Country is required!"),
  // nationalityId: Yup.string().typeError("Choose a Nationality").required("Nationality is required!"),
  // zipcode: Yup.string().matches(CANADIAN_ZIP_CODE, "Enter a valid zipcode").required("Zip code is required!"),
});
