
import {serializable, alias, object, list, primitive} from 'serializr';

export class Card { 

	@serializable(alias('brand', primitive()))
	brand?: string;

	@serializable(alias('exp_month', primitive()))
	expMonth?: number;

	@serializable(alias('exp_year', primitive()))
	expYear?: number;

	@serializable(alias('last4', primitive()))
	last4?: string;

}