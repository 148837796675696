import { serializable, alias, primitive } from "serializr";

export class CorporateLink {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("object_link_id", primitive()))
  objectLinkId?: number;

  @serializable(alias("note_template_id", primitive()))
  noteTemplateId?: number;

  @serializable(alias("text_note", primitive()))
  textNote?: string;

  @serializable(alias("src_locator_name", primitive()))
  srcLocatorName?: string;

  @serializable(alias("src_locator_id", primitive()))
  srcLocatorId?: number;

  @serializable(alias("dest_locator_id", primitive()))
  destLocatorId?: number;

  @serializable(alias("dest_locator_name", primitive()))
  destLocatorName?: string;

  @serializable(alias("src_object_id", primitive()))
  srcObjectId?: number;

  @serializable(alias("src_object_name", primitive()))
  srcObjectName?: string;

  @serializable(alias("src_attachment_url", primitive()))
  srcAttachmentUrl?: string;

  @serializable(alias("dest_object_id", primitive()))
  destObjectId?: number;

  @serializable(alias("dest_object_name", primitive()))
  destObjectName?: string;

  @serializable(alias("dest_attachment_url", primitive()))
  destAttachmentUrl?: string;
}
