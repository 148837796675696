import { useEffect, useState } from "react";
import { CustomFormTypeEnum } from "../../../enums/customFormType.enum";
import { ResponsibilityTypes } from "../../../enums/responsebily.enum";
import { CustomForm } from "../../../models/CustomForm/customForm.model";
import { PaginationDetails } from "../../../models/Pagination/pagination.model";
import CustomFormService from "../../../services/CustomForm/customForm.service";
import { getDropdownOptions } from "../../utils/getDropdownOptions";
import useResponsibilities from "../Responsibilities/useResponsibilities";

interface useCustomFormProps {
  type: CustomFormTypeEnum;
}

const useCustomForm = ({ type }: useCustomFormProps) => {
  const { hasAccess } = useResponsibilities();

  const [forms, setForms] = useState<CustomForm[]>([]);

  const [formsMeta, setFormsMeta] = useState(new PaginationDetails());

  const [loading, setLoading] = useState(false);

  const HAS_CUSTOM_FORM_VIEW_ACCESS = hasAccess(
    ResponsibilityTypes.CUSTOM_FORM_VIEW
  );

  const options = getDropdownOptions(forms, "name", "id");

  const fetchCustomForm = () => {
    if (!HAS_CUSTOM_FORM_VIEW_ACCESS || !formsMeta?.nextPage) return;

    setLoading(true);
    CustomFormService.fetchCustomForms(
      type,
      (forms: CustomForm[], meta: PaginationDetails) => {
        setForms((existingForms) => [...existingForms, ...forms]);
        setFormsMeta(meta);
      },
      () => {},
      () => {
        setLoading(false);
      },
      { page: formsMeta?.nextPage }
    );
  };

  useEffect(() => {
    fetchCustomForm();
  }, []);

  return { forms, fetchCustomForm, loading, options };
};

export default useCustomForm;
