import React, { FC } from "react";
import UserPlaceHolder from "../../../../../assets/images/userPlaceholder.png";
import { timeDifference } from "../../../../utils/time";
import moment from "moment";
import { Notification as NotificationModel } from "../../../../../models/Notification/notification.model";
import "./notification.scss";
import { useHistory } from "react-router-dom";
import { SIGNAL_R_NOTIFICATION } from "../../../../../enums/signalRNotification.enum";
import AppRoutes from "../../../../../routes/routeConstants/appRoutes";

const mockTime = moment().subtract("minute", 5).toISOString();

interface NotificationProps {
  notification: NotificationModel;
}

const Notification: FC<NotificationProps> = (props) => {
  const { notification } = props;

  const history = useHistory();

  const handleNotificationClick = () => {
    switch (notification?.type) {
      case SIGNAL_R_NOTIFICATION.APPOINTMENT:
        return history.push(AppRoutes.APPOINTMENTS);
      case SIGNAL_R_NOTIFICATION.STAFF:
        return history.push(
          AppRoutes.STAFFS + "/" + notification.jsonData?.staffId
        );
      case SIGNAL_R_NOTIFICATION.DENTAL_OBJECT:
        return history.push(
          AppRoutes.CORPORATE_OBJECTS +
            "/" +
            notification.jsonData?.corporateObjectId
        );
      case SIGNAL_R_NOTIFICATION.SCHEDULE:
        return history.push(
          AppRoutes.STAFF_SCHEDULE.replace(
            ":staffId",
            notification.jsonData?.staffId?.toString() ?? ""
          )
        );

      case SIGNAL_R_NOTIFICATION.SERVICE:
        return history.push(AppRoutes.SERVICES, {
          serviceId: notification.jsonData?.serviceId,
        });

      case SIGNAL_R_NOTIFICATION.TERM_OF_SERVICE:
        return history.push(AppRoutes.SERVICES, {
          termOfServiceId: notification.jsonData?.termOfServiceId,
        });
      default:
        return;
    }
  };

  return (
    <div className="notification" onClick={handleNotificationClick}>
      <div className="time">
        <div className="dot" />
        {timeDifference(moment(), moment(notification?.createdAt))}
      </div>
      <div className="img-wrapper">
        <img src={UserPlaceHolder} />
      </div>
      <div className="content">
        <p className="name">{notification?.title}</p>
        <p className="message">{notification?.content}</p>
      </div>
    </div>
  );
};

export default Notification;
