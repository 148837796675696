import { serializable, alias, object, list, primitive } from "serializr";
import { InsuranceProvider } from "../InsuranceProvider/insuranceProvider.model";
import { PaymentSchedule } from "../PaymentSchedule/paymentSchedule.model";

export class ContractSubscription {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("estimated_cost", primitive()))
  estimatedCost?: number;

  @serializable(alias("stripe_payment_method_id", primitive()))
  stripePaymentMethodId?: string;

  @serializable(alias("stripe_subscription_id", primitive()))
  stripeSubscriptionId?: string;

  @serializable(alias("payment_duration_in_months", primitive()))
  paymentDurationInMonths?: number;

  @serializable(alias("due_type", primitive()))
  dueType?: string = "Monthly";

  @serializable(alias("due_time", primitive()))
  dueTime?: string;

  @serializable(alias("is_self_payment", primitive()))
  isSelfPayment: boolean = true;
  @serializable(alias("is_deposit", primitive()))
  isDeposit: boolean = false;
  @serializable(alias("is_insurance", primitive()))
  isInsurance: boolean = false;
  @serializable(alias("is_insurance_provider", primitive()))
  isInsuranceProvider: boolean = false;
  @serializable(alias("deposit_amount", primitive()))
  depositAmount?: number;
  @serializable(alias("is_discount", primitive()))
  isDiscount: boolean = false;
  @serializable(alias("discount_amount", primitive()))
  discountAmount?: number;

  @serializable(alias("contract_id", primitive()))
  contractId?: number;

  @serializable(alias("contract_name", primitive()))
  contractName?: string;

  @serializable(alias("corporate_id", primitive()))
  corporateId?: number;
  @serializable(alias("payment_schedule", object(PaymentSchedule)))
  paymentSchedule?: PaymentSchedule;
}
