import {
  serializable,
  alias,
  primitive,
  list,
  object,
  custom,
} from "serializr";
import { Corporate } from "../Corporate/corporate.model";
import { Schedule } from "../Schedule/schedule.model";
import { UserProfile } from "../UserProfile/userProfile.model";

export class User {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("first_name", primitive()))
  firstName?: string;

  @serializable(alias("last_name", primitive()))
  lastName?: string;

  @serializable(alias("gender", primitive()))
  gender?: string;

  @serializable(alias("dob", primitive()))
  dob?: string;

  @serializable(alias("email", primitive()))
  email?: string;

  @serializable(alias("isd_code", primitive()))
  isdCode?: string;

  @serializable(alias("is_active", primitive()))
  isActive ?= true;

  @serializable(alias("mobile_number", primitive()))
  mobileNumber?: number;

  @serializable(alias("country_id", primitive()))
  countryId?: number;

  @serializable(alias("role_id", primitive()))
  roleId?: number;

  @serializable(alias("role_name", primitive()))
  roleName?: string;

  @serializable(alias("city_id", primitive()))
  cityId?: number;

  @serializable(alias("province_id", primitive()))
  provinceId?: number;

  @serializable(alias("nationality_id", primitive()))
  nationalityId?: number;

  @serializable(alias("zip_code", primitive()))
  zipcode?: string;

  @serializable(alias("country_name", primitive()))
  countryName?: string;

  @serializable(alias("province_name", primitive()))
  provinceName?: string;

  @serializable(alias("city_name", primitive()))
  cityName?: string;

  @serializable(alias("nationality_name", primitive()))
  nationalityName?: string;
  @serializable(alias("license_url", primitive()))
  licenseUrl?: string;

  @serializable(alias("street", primitive()))
  street?: string;

  @serializable(alias("building", primitive()))
  building?: string;

  @serializable(alias("default_practice_id", primitive()))
  defaultPracticeId?: number;

  @serializable(alias("default_practice_name", primitive()))
  defaultPracticeName?: string;

  @serializable(alias("profile_id", primitive()))
  profileId?: string;

  @serializable(alias("user_role", primitive()))
  userRole?: string;

  @serializable(
    alias(
      "profile_pic",
      custom(
        (file) => file,
        (data) => {}
      )
    )
  )
  profilePicture?: File;

  @serializable(alias("profile_picture_url", primitive()))
  profilePictureUrl?: string;

  @serializable(alias("profile_pic_url", primitive()))
  profilePicUrl?: string;

  @serializable(alias("user_profiles", list(object(UserProfile))))
  userProfiles?: UserProfile[];

  @serializable(alias("organisations", list(object(Corporate))))
  organisations?: Corporate[];

  @serializable(alias("schedules", list(object(Schedule))))
  schedules: Schedule[] = [];
  getFullName() {
    return this.firstName + " " + this.lastName;
  }
}

export class UserPermission extends User {
  @serializable(alias("permissions", list(primitive())))
  permissions?: string[];
}
