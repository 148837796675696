import React, { FC, Fragment, useEffect, useState } from "react";
import { Table, Modal } from "antd";
import moment from "moment";
import { Patient } from "../../../../models/Patient/patient.model";
import { ContractTermTemplate } from "../../../../models/ContractTermsTemplate/contractTermsTemplate.model";
import ContractTermTemplateService from "../../../../services/ContractTermTemplate/ContractTermTemplate.service";
import AuthContainer from "../../../../store/container/AuthContainer";
import { AuthReducerProps } from "../../../../store/reducers/authReducer";
import { ResponsibilityTypes } from "../../../../enums/responsebily.enum";
import { PaginationDetails } from "../../../../models/Pagination/pagination.model";
import useResponsibilities from "../../../../shared/hooks/Responsibilities/useResponsibilities";
import { setMeshLoading } from "../../../../store/actions/babylonActions";
import { ColumnsType } from "antd/lib/table";
const { confirm } = Modal;
interface ListContractTermsTemplatesProps extends AuthReducerProps {
  contractTermsTemplates: ContractTermTemplate[];
  editHandler: (contractTermTemplate: ContractTermTemplate) => void;
  deleteHandler: (index: number) => void;
  selectContractTermTemplate: (id: number) => void;
  contractType?: string;
  pagination: PaginationDetails | false;
  onSuccess?: () => void;
}

const ListContractTermsTemplates: FC<ListContractTermsTemplatesProps> = ({
  contractTermsTemplates,
  editHandler,
  deleteHandler,
  userResponsibilities,
  pagination,

  selectContractTermTemplate,
  contractType,
  onSuccess,
}) => {
  const { hasAccess } = useResponsibilities();

  const HAS_CONTRACT_TERM_VIEW_ACCESS = hasAccess(
    ResponsibilityTypes.CONTRACT_TERM_VIEW
  );

  const HAS_CONTRACT_TERM_EDIT_ACCESS = hasAccess(
    ResponsibilityTypes.CONTRACT_TERM_EDIT
  );

  const HAS_CONTRACT_TERM_DELETE_ACCESS = hasAccess(
    ResponsibilityTypes.CONTRACT_TERM_DELETE
  );

  const defaultTemplatesColumns = [
    {
      title: "Contract Template Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Number of Variables",
      dataIndex: "noOfVariables",
      key: "noOfVariables",
    },
  ];
  const contractTermsTemplatesColumns: ColumnsType<ContractTermTemplate> = [
    {
      title: "Contract Template Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Created by",
      dataIndex: "createdByFirstName",
      key: "createdByFirstName",
      render: (firstName, template) => template?.getCreatedByFullName() || firstName,
    },
    {
      title: "",
      dataIndex: "",
      key: "",
      render: (_: any, contractTermsTemplate: ContractTermTemplate) => (
        <Fragment>
          {HAS_CONTRACT_TERM_EDIT_ACCESS && (
            <i
              className="icon-edit resource-edit-icon"
              onClick={(event) => {
                if (!HAS_CONTRACT_TERM_EDIT_ACCESS) return;

                event.stopPropagation();
                editHandler(contractTermsTemplate);
              }}
            />
          )}

          {HAS_CONTRACT_TERM_DELETE_ACCESS && (
            <i
              className="icon-delete resource-delete-icon"
              onClick={(event) => {
                if (!HAS_CONTRACT_TERM_DELETE_ACCESS) return;

                event.stopPropagation();
                confirm({
                  content: `Are you sure you want to delete ${contractTermsTemplate.name}`,
                  okText: "Yes",
                  cancelText: "No",
                  onOk: () => {
                    ContractTermTemplateService.deleteContractTermTemplate(
                      contractTermsTemplate?.id as number,
                      () => {
                        deleteHandler(contractTermsTemplate?.id as number);
                        onSuccess && onSuccess();
                      },
                      () => {},
                      () => {}
                    );
                  },
                });
              }}
            />
          )}
        </Fragment>
      ),
    },
  ];

  const handleRowSelect = (contractTermsTemplate: ContractTermTemplate) => {
    return {
      onClick: () => {
        if (!HAS_CONTRACT_TERM_VIEW_ACCESS) return;
        selectContractTermTemplate(contractTermsTemplate?.id ?? 0);
      },
    };
  };

  return (
    <Table
      dataSource={contractTermsTemplates}
      columns={
        contractType == "customTerm"
          ? contractTermsTemplatesColumns
          : defaultTemplatesColumns
      }
      onRow={handleRowSelect}
      pagination={pagination && {
        total: pagination?.totalCount,
        current: pagination?.currentPage,
        showTotal: (total: number, range: [number, number]) => (
          <p>
            Showing <b>{` ${range[0]} - ${range[1]}`}</b> of{" "}
            <b>{`${total.toLocaleString()}`}</b>
          </p>
        ),
      }}
    />
  );
};

export default AuthContainer(ListContractTermsTemplates);
