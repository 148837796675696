import React, { useCallback, useEffect, useState } from "react";
import { Row, Col, Button, Drawer, Modal } from "antd";
import "./patientDocuments.scss";
import { Formik, FormikValues } from "formik";
import DropdownField from "../../../shared/components/DropdownField";
import PatientDocumentUploadForm from "../PatientDocumentUploadForm";
import { Patient } from "../../../models/Patient/patient.model";
import { PatientDocument } from "../../../models/PatientDocument/patientDocument.model";
import PatientService from "../../../services/Patient/patient.service";
import FileDetail from "../../../shared/components/FileDetail";
import { IDropdownOptions } from "../../../services/Meta/meta.service";
import CustomFormService from "../../../services/CustomForm/customForm.service";
import { CustomForm } from "../../../models/CustomForm/customForm.model";
import CustomFormResponseForm from "../CustomFormResponseForm";
import { CustomFormResponse } from "../../../models/CustomFormResponse/customFormResponse.model";
import PatientFormResponseWrapper from "../PatientFormResponseWrapper";
import { CustomFormTypeEnum } from "../../../enums/customFormType.enum";
import { ResponsibilityTypes } from "../../../enums/responsebily.enum";
import { CustomFormRequest } from "../../../models/CustomFormRequest/customFormRequest.model";
import CustomFormRequestService from "../../../services/CustomFormRequest/customFormRequest.service";
import useResponsibilities from "../../../shared/hooks/Responsibilities/useResponsibilities";
import useCustomForm from "../../../shared/hooks/useCustomForm";
import DocumentViewer from "shared/components/DocumentViewer";
import { getDocumentType } from "shared/utils/getDocumentType";
import PreDeterminationForm from "../PreDeterminationForm";
interface PatientDocumentsProps {
  patient: Patient;
}

function PatientDocuments({ patient }: PatientDocumentsProps) {
  const { hasAccess } = useResponsibilities();

  const [showDocumentUploadForm, setShowDocumentUploadForm] = useState(false);
  const [showPreDeterminationForm, setShowPreDeterminationForm] = useState(
    false
  );

  const [showCustomFormResponseForm, setShowCustomFormResponseForm] = useState(
    false
  );

  const [refreshPatientResponse, setRefreshPatientResponse] = useState(false);

  const [patientDocuments, setPatientDocuments] = useState<PatientDocument[]>(
    []
  );
  const [patientXray, setPatientXray] = useState<PatientDocument[]>([]);
  const [preVisitImages, setPreVisitImages] = useState<PatientDocument[]>([]);
  const [postVisitImages, setPostVisitImages] = useState<PatientDocument[]>([]);
  const [visible, setVisible] = useState(false);
  const [documentUrl, setDocumentUrl] = useState("");
  const [documentType, setDocumentType] = useState("");

  const { fetchCustomForm, options, loading } = useCustomForm({
    type: CustomFormTypeEnum.PATIENT,
  });

  const [activeCustomFormId, setActiveCustomFormId] = useState<
    number | undefined
  >();

  const HAS_CUSTOM_FORM_VIEW_ACCESS = hasAccess(
    ResponsibilityTypes.CUSTOM_FORM_VIEW
  );

  const HAS_CUSTOM_FORM_CREATE_ACCESS = hasAccess(
    ResponsibilityTypes.CUSTOM_FORM_CREATE
  );

  const HAS_PATIENT_DOC_CREATE_ACCESS = hasAccess(
    ResponsibilityTypes.PATIENT_DOC_CREATE
  );

  const HAS_PATIENT_DOC_VIEW_ACCESS = hasAccess(
    ResponsibilityTypes.PATIENT_DOC_VIEW
  );

  const handleToggleDocumentUploadForm = () =>
    setShowDocumentUploadForm(!showDocumentUploadForm);

  const handleToggleCustomFormResponseForm = () =>
    setShowCustomFormResponseForm(!showCustomFormResponseForm);

  const handleTogglePreDeterminationForm = () =>
    setShowPreDeterminationForm(!showPreDeterminationForm);

  const handleRefreshComplete = useCallback(
    () => setRefreshPatientResponse(false),
    [setRefreshPatientResponse]
  );

  const handleSubmit = (values: FormikValues) => {};

  const handleUploadSuccess = () => {
    handleFetchPatientDocuments();
    handleToggleDocumentUploadForm();
  };

  const handleManualComplete = () => {
    handleToggleCustomFormResponseForm();
  };

  const handleCustomFormResponseSuccess = (
    customFormResponse: CustomFormResponse
  ) => {
    handleToggleCustomFormResponseForm();
    setRefreshPatientResponse(true);
  };

  const handleFetchPatientDocuments = useCallback(() => {
    if (patient.id && HAS_PATIENT_DOC_VIEW_ACCESS) {
      PatientService.fetchPatientDocuments(
        patient.id,
        (patientDocuments: PatientDocument[]) => {
          setPatientDocuments(patientDocuments);
        },
        () => {},
        () => {}
      );
      PatientService.fetchPatientXray(
        String(patient.id),
        (xray: PatientDocument[]) => {
          setPatientXray(xray);
        },
        () => {},
        () => {}
      );
      PatientService.fetchPreVisitImages(
        String(patient.id),
        (xray: PatientDocument[]) => {
          setPreVisitImages(xray);
        },
        () => {},
        () => {}
      );
      PatientService.fetchPostVisitImages(
        String(patient.id),
        (xray: PatientDocument[]) => {
          setPostVisitImages(xray);
        },
        () => {},
        () => {}
      );
    }
  }, [patient.id]);

  useEffect(() => {
    handleFetchPatientDocuments();
  }, [handleFetchPatientDocuments]);

  const handleRequestToFill = () => {
    const customFormRequest = new CustomFormRequest(activeCustomFormId);
    customFormRequest.requestableId = patient?.id;
    customFormRequest.customFormFilledBy = "patient";
    CustomFormRequestService.createCustomFormRequest(
      activeCustomFormId as number,
      customFormRequest,
      (customFormRequest: CustomFormRequest) => {},
      () => {},
      () => {}
    );
  };
  const showModal = (document: PatientDocument) => {
    const type = getDocumentType(document?.attachmentName ?? "");
    setDocumentUrl(document?.attachmentUrl ?? "");
    setDocumentType(type);
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <div className="patient-documents">
      <div className="text-right">
        {HAS_PATIENT_DOC_CREATE_ACCESS && (
          <>
            {" "}
            <Button
              className="secondary-btn mr-5"
              onClick={handleTogglePreDeterminationForm}
            >
              Generate Pre Determination Form 
            </Button>{" "}
            <Button
              className="secondary-btn"
              onClick={handleToggleDocumentUploadForm}
            >
              Upload File
            </Button>
          </>
        )}
      </div>
      {HAS_PATIENT_DOC_VIEW_ACCESS}{" "}
      <>
        {patientDocuments.length ? <h4>Uploaded Documents</h4> : ""}
        <Row className="mt-5" gutter={[20, 20]}>
          {patientDocuments.map((document) => (
            <Col span={8}>
              <div
                className="patient-documents__document-item"
                onClick={() => {
                  showModal(document);
                }}
              >
                {document?.attachmentName && (
                  <FileDetail name={document?.attachmentName} />
                )}
              </div>
            </Col>
          ))}
        </Row>

        {patientXray.length ? <h4>XRay Documents</h4> : ""}

        <Row className="mt-5" gutter={[20, 20]}>
          {patientXray?.map((document) => (
            <Col span={8}>
              <div
                className="patient-documents__document-item"
                onClick={() => {
                  showModal(document);
                }}
              >
                {document?.attachmentName && (
                  <FileDetail name={document?.attachmentName} />
                )}
              </div>
            </Col>
          ))}
        </Row>

        {preVisitImages.length ? <h4>PreVisit Images</h4> : ""}

        <Row className="mt-5" gutter={[20, 20]}>
          {preVisitImages?.map((document) => (
            <Col span={8}>
              <div
                className="patient-documents__document-item"
                onClick={() => {
                  showModal(document);
                }}
              >
                {document?.attachmentName && (
                  <FileDetail name={document?.attachmentName} />
                )}
              </div>
            </Col>
          ))}
        </Row>
        {postVisitImages.length ? <h4>PostVisit Images</h4> : ""}

        <Row className="mt-5" gutter={[20, 20]}>
          {postVisitImages?.map((document) => (
            <Col span={8}>
              <div
                className="patient-documents__document-item"
                onClick={() => {
                  showModal(document);
                }}
              >
                {document?.attachmentName && (
                  <FileDetail name={document?.attachmentName} />
                )}
              </div>
            </Col>
          ))}
        </Row>
      </>
      {patient?.id && HAS_CUSTOM_FORM_VIEW_ACCESS && (
        <PatientFormResponseWrapper
          onRefreshComplete={handleRefreshComplete}
          refreshData={refreshPatientResponse}
          patientId={patient?.id}
        />
      )}
      {HAS_CUSTOM_FORM_VIEW_ACCESS && HAS_CUSTOM_FORM_CREATE_ACCESS && (
        <Formik
          initialValues={{ formType: undefined }}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          <Row gutter={[20, 0]} align="middle">
            <Col span={10}>
              <DropdownField
                loading={loading}
                name="formType"
                onChange={(value) => setActiveCustomFormId(value)}
                onScrollEnd={fetchCustomForm}
                options={options}
                placeHolder="Select Form Type"
                title="Form Type"
                value={activeCustomFormId}
              />
            </Col>
            <Col span={14}>
              <Button
                type="primary"
                disabled={!activeCustomFormId}
                onClick={handleRequestToFill}
              >
                Request patient to fill
              </Button>
              <Button
                disabled={!activeCustomFormId}
                className="ml-2 secondary-btn"
                htmlType="button"
                onClick={handleManualComplete}
              >
                Complete it manually
              </Button>
            </Col>
          </Row>
        </Formik>
      )}
      {
        <Drawer
          title={
            <div>
              <h4>Upload Document</h4>
            </div>
          }
          keyboard={false}
          placement="right"
          maskClosable={false}
          onClose={handleToggleDocumentUploadForm}
          visible={HAS_PATIENT_DOC_CREATE_ACCESS && showDocumentUploadForm}
          destroyOnClose={true}
          width="60%"
        >
          {patient?.id && (
            <PatientDocumentUploadForm
              patientId={patient.id}
              onSuccess={handleUploadSuccess}
            />
          )}
        </Drawer>
      }
      <Drawer
        title={
          <div>
            <h4>Custom Form Response</h4>
          </div>
        }
        keyboard={false}
        placement="right"
        maskClosable={false}
        onClose={handleToggleCustomFormResponseForm}
        visible={showCustomFormResponseForm}
        destroyOnClose={true}
        width="60%"
      >
        {activeCustomFormId && patient.id && HAS_CUSTOM_FORM_VIEW_ACCESS && (
          <CustomFormResponseForm
            resourceId={patient.id}
            customFormId={activeCustomFormId}
            onSuccess={handleCustomFormResponseSuccess}
            onCancel={handleToggleCustomFormResponseForm}
          />
        )}
      </Drawer>
      <Modal
        visible={visible}
        onCancel={handleCancel}
        footer={null}
        className="patient-documents__viewer"
        centered
      >
        <DocumentViewer url={documentUrl} type={documentType} />
      </Modal>
      <Drawer
        title="Pre Determination Form"
        visible={showPreDeterminationForm}
        destroyOnClose
        onClose={handleTogglePreDeterminationForm}
        width={"45vw"}
        push={false}
      >
        {patient.id && (
          <PreDeterminationForm
            patient={patient}
            closeHandler={handleTogglePreDeterminationForm}
          />
        )}
      </Drawer>
    </div>
  );
}

export default PatientDocuments;
