import { generatePath } from "react-router-dom";
import { serializable, alias, object, list, primitive } from "serializr";
import AppRoutes from "../../routes/routeConstants/appRoutes";

export class CustomFormRequest {
  constructor(customFormId?: number) {
    if (!customFormId) return;

    this.redirectUrl = generatePath(AppRoutes.EMAIL_APP_CUSTOM_FORMS, {
      customFormId,
    });
  }

  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("requestable_id", primitive()))
  requestableId?: number;

  @serializable(alias("custom_form_filled_by", primitive()))
  customFormFilledBy?: string;

  @serializable(alias("redirect_url", primitive()))
  redirectUrl?: string;
}
