import React, { useEffect, useState } from "react";
import { useHistory, generatePath, useParams, Link } from "react-router-dom";
import { Row, Col, Button } from "antd";
import { CorporateObject } from "../../../../../models/CorporateObject/corporateObject.model";
import CorporateObjectService from "../../../../../services/CorporateObject/corporateObject.service";
import AppLoader from "../../../../../shared/components/AppLoader";
import CorporateObjectPreview from "../CorporateObjectPreview";
import AppRoutes from "../../../../../routes/routeConstants/appRoutes";
import "./corporateObjectDetail.scss";
import BabylonContainer from "../../../../../store/container/BabylonContainer";
import { BabylonReducerProps } from "../../../../../store/reducers/babylonReducer";
import { LeftOutlined } from "@ant-design/icons";
import useToggle from "../../../../../shared/hooks/useToggle/useToggler";

interface ICorporateObjectDetailParams {
  corporateObjectId: string;
}

interface CorporateObjectFieldProps {
  label: string;
  value?: string;
  isColor?: boolean;
}

interface CorporateObjectDetailProps extends BabylonReducerProps {}

function CorporateObjectDetail({
  corporateObjects,
  corporateObjectsLoading,
}: CorporateObjectDetailProps) {
  const history = useHistory();

  const params: ICorporateObjectDetailParams = useParams();

  const { toggle: loading, updateToggle: updateLoading } = useToggle({});

  const [corporateObject, setCorporateObject] = useState<CorporateObject>();

  const corporateObjectFields: CorporateObjectFieldProps[] = [
    { label: "Model name", value: corporateObject?.name },
    { label: "Friendly name", value: corporateObject?.friendlyName },
    { label: "Type", value: corporateObject?.objectType },
    { label: "Category", value: corporateObject?.objectCategoryName },
    { label: "Model", value: corporateObject?.objectModelName },
    { label: "Manufacture", value: corporateObject?.manufacturerName },
    { label: "Description", value: corporateObject?.description },
    { label: "Color", value: corporateObject?.color, isColor: true },
  ];

  useEffect(() => {
    if (params.corporateObjectId) {
      const corporateObject = corporateObjects?.find(
        (obj) => obj?.id === +params.corporateObjectId
      );
      if (corporateObject) setCorporateObject(corporateObject);
      else {
        updateLoading(true);
        CorporateObjectService.showCorporateObject(
          +params.corporateObjectId,
          (corporateObject: CorporateObject) => {
            setCorporateObject(corporateObject);
          },
          () => {},
          () => {
            updateLoading(false);
          }
        );
      }
    }
  }, [params.corporateObjectId]);

  return (
    <div className="corporate-object-detail">
      {loading || corporateObjectsLoading ? (
        <AppLoader loading={loading || corporateObjectsLoading} />
      ) : (
        <div>
          <div className="corporate-object-detail__header">
            <Row align="middle">
              <Col span={2}>
                {" "}
                <Button
                  onClick={() => history.goBack()}
                  className="mr-3"
                  icon={<LeftOutlined />}
                />
              </Col>
              <Col span={11}>
                Objects /{" "}
                <span className="text-secondary">{corporateObject?.name}</span>
              </Col>
              <Col span={11} className="text-right">
                <Link
                  to={generatePath(AppRoutes.CORPORATE_OBJECT_LINKS, {
                    corporateObjectId: params.corporateObjectId,
                  })}
                >
                  <Button className="secondary-btn">Show Links</Button>
                </Link>
              </Col>
            </Row>
          </div>
          <div className="corporate-object-detail__body">
            <Row gutter={[20, 20]}>
              <Col span={8}>
                {corporateObjectFields.map((corporateObjectField, i) => (
                  <div key={i} className="corporate-object-detail__field">
                    <div className="corporate-object-detail__label">
                      {corporateObjectField?.label}
                    </div>
                    <div className="corporate-object-detail__value">
                      {corporateObjectField?.isColor ? (
                        <span
                          className="corporate-object-detail__color"
                          style={{
                            backgroundColor: corporateObjectField?.value,
                          }}
                        />
                      ) : (
                        corporateObjectField?.value || "NA"
                      )}
                    </div>
                  </div>
                ))}
              </Col>
              <Col span={16}>
                <CorporateObjectPreview corporateObject={corporateObject} />
              </Col>
            </Row>
          </div>
        </div>
      )}
    </div>
  );
}

export default BabylonContainer(CorporateObjectDetail);
