import { Button, Col, Drawer, Row } from "antd";
import React, { useState } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { Practice } from "../../../models/Practice/practice.model";
import { EditOutlined, LeftOutlined } from "@ant-design/icons";
import "./practiceDetails.scss";
import PracticeForm from "../../DetailedOnboarding/PracticeForm";
import useResponsibilities from "../../../shared/hooks/Responsibilities/useResponsibilities";
import { ResponsibilityTypes } from "../../../enums/responsebily.enum";
import CorporateContainer from "../../../store/container/CorporateContainer";
import { CorporateReducerProps } from "../../../store/reducers/corporateReducer";

interface PracticeDetailProps extends CorporateReducerProps {}

const PracticeDetails = ({ practices, setPractices }: PracticeDetailProps) => {
  const params: { id: string } = useParams();

  const practice = practices?.find(
    (practice) => String(practice?.id) === params?.id
  );

  const { hasAccess } = useResponsibilities();

  const history = useHistory();

  const [practiceForm, setPracticeForm] = useState(false);

  const HAS_PRACTICE_EDIT_ACCESS = hasAccess(ResponsibilityTypes.PRACTICE_EDIT);

  const updatePractice = (practice: Practice) => {
    const updatedPractice = practices.map((oldPractice) =>
      oldPractice.id === practice.id ? practice : oldPractice
    );
    setPractices(updatedPractice);
  };

  const closePracticeForm = () => {
    setPracticeForm(false);
  };

  const handleSuccess = (practice: Practice) => {
    updatePractice(practice);
    setPracticeForm(false);
  };

  const getTimezoneLabel = () => {
    if(!practice) return;
    
    let timezoneLabel = practice?.timezoneName;

    if (practice?.timezoneCode) timezoneLabel += ` (${practice.timezoneCode})`;

    return timezoneLabel;
  };

  return (
    <div className="practice-details">
      <Row align="middle">
        <Col span={1}>
          <Button
            onClick={() => history.goBack()}
            className="mr-3"
            icon={<LeftOutlined />}
          />
        </Col>
        <Col span={11}>
          Practice /{" "}
          <span className="text-secondary">{practice?.name || "--"}</span>
        </Col>
        {HAS_PRACTICE_EDIT_ACCESS && <Col span={12} className="patient-detail-tabs__edit-wrapper">
          <Button type="primary" onClick={() => setPracticeForm(true)}>
            <EditOutlined /> Edit Practice
          </Button>
        </Col>}
      </Row>
      <div className="patient-detail__wrapper">
        <Row gutter={[20, 20]}>
          <Col span={8}>
            <p className="dark-text">Practices Name</p>
            <p className="bold-text">{practice?.name || "--"}</p>
          </Col>
          <Col span={8}>
            <p className="dark-text">Email</p>
            <p className="bold-text">{practice?.email}</p>
          </Col>
          <Col span={8}>
            <p className="dark-text">Mobile Number</p>
            <p className="bold-text">{practice?.mobileNumber ?? "--"}</p>
          </Col>
          <Col span={8}>
            <p className="dark-text">Time Zone</p>
            <p className="bold-text">
              {getTimezoneLabel() ?? "--"}
            </p>
          </Col>
          <Col span={8}>
            <p className="dark-text">Chair</p>
            <p className="bold-text">{practice?.chairCount}</p>
          </Col>
          <Col span={8}>
            <p className="dark-text">Room</p>
            <p className="bold-text">{practice?.roomCount ?? "--"}</p>
          </Col>
          <Col span={8}>
            <p className="dark-text">Lab</p>
            <p className="bold-text">{practice?.labCount ?? "--"}</p>
          </Col>
        </Row>
      </div>
      <Drawer
        visible={HAS_PRACTICE_EDIT_ACCESS && practiceForm}
        destroyOnClose
        closable
        width="60%"
        onClose={closePracticeForm}
        title="Edit Practice"
      >
        <PracticeForm
          practice={practice}
          onSuccess={handleSuccess}
          onCancel={closePracticeForm}
          onEditSuccess={handleSuccess}
        />
      </Drawer>
    </div>
  );
};

export default CorporateContainer(PracticeDetails);
