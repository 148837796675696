import { serializable, alias, primitive, object } from "serializr";
import { SetStepActionEnum } from "../../enums/setStepAction.enum";
import { CorporateLink } from "../CorporateLink/corporateLink.model";

export class SetStep {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("corporate_link_id", primitive()))
  corporateLinkId?: number;

  destObjectId?: number;

  @serializable(alias("action_type", primitive()))
  actionType?: string = SetStepActionEnum.ADD;

  @serializable(alias("order", primitive()))
  order?: number = 1;

  @serializable(alias("set_id", primitive()))
  setId?: number;

  @serializable(alias("tooth_position_id", primitive()))
  toothPositionId?: number;

  @serializable(alias("note", primitive()))
  note?: string;

  @serializable(alias("object_link", object(CorporateLink)))
  objectLink?: CorporateLink;
}
