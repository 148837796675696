import React, { useEffect, useState } from "react";
import { Col, Modal, Row, Select } from "antd";
import InputField from "../../../shared/components/InputField";
import DropdownField from "../../../shared/components/DropdownField";
import DatePickerField from "../../../shared/components/DatePickerField";
import MobileNumberField from "../../../shared/components/MobileNumberField";
import AddressField from "../../../shared/components/AddressField";
import { FormikValues } from "formik";
import { IDropdownOptions } from "../../../services/Meta/meta.service";
import _ from "lodash";
import NationalityField from "../../../shared/components/NationalityField";
import PatientService from "../../../services/Patient/patient.service";
import { ResponsiblePerson } from "../../../models/ResponsiblePerson/responsiblePerson.model";
import "./responsiblePersonFields.scss";
import ResponsiblePersonDetail from "../ResponsiblePersonDetail";
import moment from "moment";

interface ResponsiblePersonFieldsProps {
  values: FormikValues;
  setFieldValue: Function;
  genderOptions: IDropdownOptions[];
}

function ResponsiblePersonFields({
  values,
  setFieldValue,
  genderOptions,
}: ResponsiblePersonFieldsProps) {
  const [responsiblePersons, setResponsiblePersons] = useState<
    ResponsiblePerson[]
  >([]);

  const [
    activeResponsiblePerson,
    setActiveResponsiblePerson,
  ] = useState<ResponsiblePerson | null>(null);

  const handleSearchResponsiblePerson = _.debounce((value: string) => {
    handleFetchResponsiblePersons(value);
  }, 500);

  const handleSelectResponsiblePerson = (value: string) => {
    if (value) {
      const responsiblePerson = responsiblePersons.find(
        (responsiblePerson) => responsiblePerson?.id?.toString() === value
      );
      if (responsiblePerson) {
        setFieldValue("responsiblePersonId", responsiblePerson.id);
        setFieldValue("responsiblePerson", responsiblePerson);
        setActiveResponsiblePerson(responsiblePerson);
      }
    } else {
      Modal.confirm({
        title: "Confirm",
        icon: null,
        content: "Are you sure want to unlink the responsible person?",
        onOk: () => {
          setActiveResponsiblePerson(null);
          setFieldValue("responsiblePersonId", null);
          setFieldValue("responsiblePerson", new ResponsiblePerson());
        },
      });
    }
  };

  const handleFetchResponsiblePersons = (searchText: string) => {
    PatientService.fetchResponsiblePersons(
      searchText,
      (responsiblePersons: ResponsiblePerson[]) => {
        setResponsiblePersons(responsiblePersons);
      },
      () => {},
      () => {}
    );
  };

  useEffect(() => {
    handleFetchResponsiblePersons("");
  }, []);

  useEffect(() => {
    if (values?.responsiblePerson?.id) {
      setActiveResponsiblePerson(values.responsiblePerson);
      setFieldValue("responsiblePersonId", values.responsiblePerson.id);
    }
  }, [setFieldValue, values]);

  return (
    <div className="responsible-person-fields">
      <h4 className="text-secondary">
        <Row align="middle">
          <Col span={8}>Responsible Person</Col>
          <Col span={16} className="text-right">
            <span className="responsible-person-fields__link-text">
              Link with existing person
            </span>
            <Select
              placeholder="Search with name"
              showSearch
              showArrow={false}
              value={activeResponsiblePerson?.id?.toString()}
              allowClear
              className="responsible-person-fields__search-responsible-person"
              onSearch={handleSearchResponsiblePerson}
              filterOption={(input: string, option: any) => {
                return (
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                );
              }}
              onChange={handleSelectResponsiblePerson}
              style={{ width: 250 }}
            >
              {responsiblePersons.map((responsiblePerson) => (
                <Select.Option
                  value={responsiblePerson?.id?.toString() || ""}
                  label={
                    responsiblePerson.firstName +
                    " " +
                    responsiblePerson.lastName
                  }
                >
                  {responsiblePerson.firstName} {responsiblePerson.lastName}
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row>
      </h4>
      {activeResponsiblePerson ? (
        <ResponsiblePersonDetail responsiblePerson={activeResponsiblePerson} />
      ) : (
        <Row gutter={[20, 0]}>
          <Col span={12}>
            <InputField
              title="First name"
              type="text"
              name="responsiblePerson.firstName"
              placeholder="Enter First name"
            />
          </Col>
          <Col span={12}>
            <InputField
              title="Last name"
              type="text"
              name="responsiblePerson.lastName"
              placeholder="Enter Last name"
            />
          </Col>
          <Col span={12}>
            <DropdownField
              title="Gender"
              name="responsiblePerson.gender"
              options={genderOptions}
              value={values.responsiblePerson?.gender}
              setFieldValue={setFieldValue}
              placeHolder="Select Gender"
            />
          </Col>
          <Col span={12}>
            <DatePickerField
              title="DOB"
              name="responsiblePerson.dob"
              onChange={(date: any, dateString: any) => {
                setFieldValue(
                  "responsiblePerson.dob",
                  date.format("YYYY-MM-DD")
                );
              }}
              placeholder=""
              disabledDate={(d) => {
                const age = moment.duration(moment().diff(d));
                return age.years() < 18;
              }}
              defaultValue={values.responsiblePerson?.dob}
              setFieldValue={setFieldValue}
            />
          </Col>
          <Col span={12}>
            <InputField
              title="Email"
              type="email"
              name="responsiblePerson.email"
              placeholder="Enter Email"
            />
          </Col>
          <MobileNumberField
            name="responsiblePerson.mobileNumber"
            isdCodeKeyName="responsiblePerson.isdCode"
            setFieldValue={setFieldValue}
            value={values.responsiblePerson?.isdCode}
          />
          <AddressField
            values={values}
            setFieldValue={setFieldValue}
            countryKey="responsiblePerson.countryId"
            provinceKey="responsiblePerson.provinceId"
            cityKey="responsiblePerson.cityId"
            zipcodeKey="responsiblePerson.zipcode"
            countryValue={values.responsiblePerson?.countryId}
            provinceValue={values.responsiblePerson?.provinceId}
            cityValue={values.responsiblePerson?.cityId}
            zipcodeValue={values.responsiblePerson?.zipcode}
          />
          <Col span={12}>
            <NationalityField
              setFieldValue={setFieldValue}
              value={values.responsiblePerson?.nationalityId}
              name="responsiblePerson.nationalityId"
            />
          </Col>
          <Col span={12}>
            <InputField
              title="Street"
              type="text"
              name="responsiblePerson.street"
              placeholder="Enter Street"
            />
          </Col>
          <Col span={12}>
            <InputField
              title="Building"
              type="text"
              name="responsiblePerson.building"
              placeholder="Enter Building"
            />
          </Col>
        </Row>
      )}
    </div>
  );
}

export default ResponsiblePersonFields;
