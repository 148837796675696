import { Drawer } from "antd";
import moment, { Moment } from "moment";
import React, { FC, useEffect, useState } from "react";
import AssignAppointmentSlotForm from "../AssignAppointmentSlotForm";
import "./createAppointmentTable.scss";
import { AppointmentTemplate } from "../../../../models/AppointmentTemplate/appointmentTemplate.model";
import { Calendar, EventPropGetter, momentLocalizer } from "react-big-calendar";
import {
  isActualTimeMaximum,
  isActualTimeMinimum,
} from "../../../../shared/utils/time";
import { hexAToRGBA } from "../../../../shared/utils/hexToRgb";
import NewAppointmentForm from "../NewAppointmentForm";
import { AppointmentTemplateSchedules } from "../../../../models/AppointmentTemplateSchedules/appointmentTemplateSchedules.model";
import CustomEvent from "../AssignAppointmentSlotForm/CustomEvent";
import ResourceService from "../../../../services/Resource/resource.service";
import { Resource } from "../../../../models/resource/resource.model";
import AppLoader from "../../../../shared/components/AppLoader";
import useResponsibilities from "../../../../shared/hooks/Responsibilities/useResponsibilities";
import { ResponsibilityTypes } from "../../../../enums/responsebily.enum";

const localizer = momentLocalizer(moment);

interface CreateAppointmentTableProps {
  appointmentTemplateData: AppointmentTemplate;
  onAddSlot: (
    resourceId: number,
    slots: AppointmentTemplateSchedules[]
  ) => void;
  onDeleteSlot: (resourceId: number, slotId: number) => void;
  selectedStartTime: Moment;
  selectedEndTime: Moment;
  timeInterval: number;
  onUpdateSlot?: (
    resourceId: number,
    oldSlotId: number,
    slots: AppointmentTemplateSchedules[]
  ) => void;
}

const CreateAppointmentTable: FC<CreateAppointmentTableProps> = (props) => {
  const { hasAccess } = useResponsibilities();

  const [appointmentSlotVisibility, setAppointmentSlotVisibility] = useState(
    false
  );
  const [
    appointmentCreateEditVisibility,
    setAppointmentCreateEditVisibility,
  ] = useState(false);
  const [currentResource, setCurrentResource] = useState<string>("");

  const {
    selectedStartTime,
    selectedEndTime,
    timeInterval,
    appointmentTemplateData,
    onAddSlot,
    onDeleteSlot,
    onUpdateSlot,
  } = props;

  const [resources, setResources] = useState<Resource[]>([]);

  const HAS_APPOINTMENT_TEMPLATE_EDIT_ACCESS = hasAccess(
    ResponsibilityTypes.APPOINTMENT_TEMPLATE_EDIT
  );

  const handleAppointmentSlotVisibility = () => {
    setAppointmentSlotVisibility(!appointmentSlotVisibility);
  };

  const handleAppointmentCreateEditVisibility = () => {
    setAppointmentCreateEditVisibility(!appointmentCreateEditVisibility);
  };

  const handleSlotClick = (props: { resource: string }) => {
    setCurrentResource(props?.resource ?? "");
    handleAppointmentSlotVisibility();
  };

  const getEvents = () => {
    const result: any[] = [];

    appointmentTemplateData?.resources?.forEach((resource, resourceIndex) => {
      resource?.schedules?.forEach((schedule, index) => {
        result.push({
          isAllDay: false,
          start: moment(schedule?.startTime).toDate(),
          end: moment(schedule?.endTime).toDate(),
          resourceId: resource?.resourceId,
          title: schedule?.appointmentType,
          schedule,
        });
      });
    });

    return result;
  };

  const getEventPropGetter: EventPropGetter<any> = (event) => ({
    style: {
      backgroundColor: hexAToRGBA(
        event?.schedule?.appointmentTypeColorCode,
        0.2
      ),
      borderColor: String(event?.schedule?.appointmentTypeColorCode),
      color: "black",
    },
  });

  const getResourceSchedules = (resourceId: number) =>
    appointmentTemplateData?.resources?.find(
      (resource) => resource?.resourceId === resourceId
    )?.schedules;

  useEffect(() => {
    ResourceService.fetchResourceByType(
      "chair",
      (resources) => setResources(resources),
      () => {},
      () => {}
    );
  }, []);

  return resources?.length ? (
    <div className="appointment-table">
      <Calendar
        components={{
          event: (props) => (
            <CustomEvent
              onUpdateSlot={onUpdateSlot}
              onDeleteSlot={onDeleteSlot}
              props={props}
            />
          ),
          eventContainerWrapper: (props: any) => {
            const schedules = getResourceSchedules(props?.resource);
            const emptySchedule =
              Number(schedules?.length) <= 0 ? "event-container-empty" : "";
            return (
              <div
                className={emptySchedule}
                onContextMenu={(e) => {
                  if (!HAS_APPOINTMENT_TEMPLATE_EDIT_ACCESS) return;

                  e.preventDefault();
                  handleSlotClick({ resource: props?.resource });
                }}
              >
                {props.children}
              </div>
            );
          },
        }}
        date={selectedStartTime.toDate()}
        defaultView="day"
        events={getEvents()}
        eventPropGetter={getEventPropGetter}
        formats={{
          eventTimeRangeFormat: () => "",
          timeGutterFormat: (time) => {
            const momentTime = moment(time);
            return (momentTime.isSame(appointmentTemplateData?.startTime) &&
              isActualTimeMinimum(
                "08:00am",
                appointmentTemplateData?.startTime
              )) ||
              (momentTime.isSame(appointmentTemplateData?.endTime) &&
                isActualTimeMaximum(
                  "05:00pm",
                  appointmentTemplateData?.startTime
                )) ||
              momentTime.minute() === 0
              ? momentTime.format("hh:mm A")
              : momentTime.format(":mm");
          },
        }}
        localizer={localizer}
        min={selectedStartTime.toDate()}
        max={selectedEndTime.toDate()}
        onSelecting={() => false}
        resources={[
          ...resources?.map((resource) => ({
            id: resource?.id,
            title: resource?.name,
            resource,
          })),
        ]}
        selectable
        showAllEvents
        step={timeInterval}
        style={{ height: "75vh" }}
        toolbar={false}
        views={["day"]}
        timeslots={1}
      />
      <Drawer
        title="Appointment Template"
        visible={
          HAS_APPOINTMENT_TEMPLATE_EDIT_ACCESS && appointmentSlotVisibility
        }
        destroyOnClose
        onClose={handleAppointmentSlotVisibility}
        width={"40vw"}
        className="appointmentTemplate"
      >
        <AssignAppointmentSlotForm
          onAddSlot={onAddSlot}
          handleAppointmentSlotVisibility={handleAppointmentSlotVisibility}
          currentResourceId={currentResource}
          templateId={appointmentTemplateData?.id ?? 0}
        />
      </Drawer>
      <Drawer
        title="New Appointment"
        visible={appointmentCreateEditVisibility}
        destroyOnClose
        onClose={handleAppointmentCreateEditVisibility}
        width={"40vw"}
        className="appointmentTemplate"
        push={false}
      >
        <NewAppointmentForm
          appointmentCreateEditVisibility={
            handleAppointmentCreateEditVisibility
          }
        />
      </Drawer>
    </div>
  ) : (
    <AppLoader loading />
  );
};

export default CreateAppointmentTable;
