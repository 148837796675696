import { IDropdownOptions } from "../../services/Meta/meta.service";
import { SelectProp } from "../Types/option";

export const getDropdownOptions = (
  array: any[],
  labelKey: string,
  valueKey: string
): SelectProp[] => {
  return array.map((object, index) => ({
    label: object[labelKey],
    value: object[valueKey],
    ...array[index],
  }));
};

export const getStatusOptions = (): SelectProp[] => {
  return [
    { label: "Active", value: "active" },
    { label: "Inactive", value: "inactive" },
  ];
};
