import { serializable, alias, object, list, primitive } from "serializr";
import { AppointmentTemplateSchedules } from "../AppointmentTemplateSchedules/appointmentTemplateSchedules.model";

export class AppointmentTemplateResource {
  @serializable(alias("resource_id", primitive()))
  resourceId?: number;

  @serializable(alias("resource_name", primitive()))
  resourceName?: string;

  @serializable(alias("slots", list(object(AppointmentTemplateSchedules))))
  schedules: AppointmentTemplateSchedules[] = [];
}
