import { Button, Col, Drawer, Row, Tabs, Tag } from "antd";
import Checkbox from "antd/lib/checkbox/Checkbox";
import Form from "antd/lib/form/Form";
import {
  Formik,
  FormikProps,
  useFormikContext,
  getIn,
  FormikTouched,
} from "formik";
import React, { FC, useState, useEffect, useRef } from "react";
import {
  Appointment,
  appointmentStatusOptions,
} from "../../../models/Appointment/appointment.model";
import CheckboxField from "../../../shared/components/Checkbox";
import DatePickerField from "../../../shared/components/DatePickerField";
import DropdownField from "../../../shared/components/DropdownField";
import InputField from "../../../shared/components/InputField";
import RadioInputField from "../../../shared/components/RadioInputField";
import useAppointmentTypes from "../../../shared/hooks/AppointmentType/useAppointmentTypes";
import { usePatients } from "../../../shared/hooks/Patient/usePatient";
import { useDoctors } from "../../../shared/hooks/User/useDoctors";
import { AppointmentFormValidation } from "./AppointmentFormValidation";
import Searchbar from "../../../shared/components/Searchbar";
import { SelectProp } from "../../../shared/Types/option";
import { useResource } from "../../../shared/hooks/Resource/useResource";
import { AppointmentService } from "../../../services/Appointment/appointment.service";
import { CorporateReducerProps } from "../../../store/reducers/corporateReducer";
import CorporateContainer from "../../../store/container/CorporateContainer";
import moment from "moment";
import "./appointmentForm.scss";
import { AppointmentTiming } from "../../../models/AppointmentTiming/appointmentTiming.model";
import NewAppointmentTypes from "../../../shared/hooks/NewAppointmentType/newAppointmentTypes";
import DoctorDetails from "../../../shared/hooks/Doctors/index";
import { DropdownModeEnum } from "../../../enums/dropdownMode.enum";
import AppLoader from "../../../shared/components/AppLoader";
import { Resource, Resources } from "../../../models/resource/resource.model";
import {
  DayResource,
  DaySchedule,
} from "../../../models/AppointmentDay/appointmentDay.model";
import { IDropdownOptions } from "../../../services/Meta/meta.service";
import { AppointmentType } from "../../../models/AppointmentType/appointmentType.model";
import { UserRoles } from "../../../enums/userRoles.enum";
import NewAppointmentForm from "../AppointmentTemplate/NewAppointmentForm";
import { values } from "lodash";
import { AppointmentStatus } from "../../../enums/appointmentStatus.enum";
import { useInteractionStatus } from "../../../shared/hooks/useInteractionStatus/useInteractionStatus";
import { StatusType } from "../../../enums/statusType.enum";

const { TabPane } = Tabs;
const { CheckableTag } = Tag;

interface AppointmentTimingWithSlot extends AppointmentTiming {
  appointmentScheduleSlotId?: number;
}
interface Slots {
  title: string;
  key: string;
  active: boolean;
  slots: AppointmentTimingWithSlot[];
}

export const visitTypeOptions = [
  {
    label: "Virtual Consultation",
    optionalLabel: "Virtual Consultation",
    value: "virtual_consultation",
  },
  {
    label: "In person",
    optionalLabel: "In person",
    value: "in_person",
  },
];
const appointmentSession: Slots[] = [
  {
    title: "Morning",
    key: "morning",
    active: true,
    slots: [],
  },
  {
    title: "Afternoon",
    key: "afternoon",
    active: false,
    slots: [],
  },
  {
    title: "Evening",
    key: "evening",
    active: false,
    slots: [],
  },
];

interface AppointmentFormProps extends CorporateReducerProps {
  edit?: boolean;
  formValue?: Appointment;
  closeHandler: () => void;
  updateHandler: (appointment: Appointment, type?: "add" | "update") => void;
  type?: string;
  currentResource?: string;
  handleAppointmentUpdate?: (appointment: Appointment) => void;
  independentDrawer?: boolean;
}

const AppointmentForm: FC<AppointmentFormProps> = ({
  edit,
  formValue,
  closeHandler,
  updateHandler,
  practiceId,
  currentResource,
  type,
  handleAppointmentUpdate,
  independentDrawer,
}) => {
  const [loader, setLoader] = useState(false);
  const [sessionTab, setSessionTab] = useState("");
  const [initialFormValues, setInitialFormValues] = useState(formValue || new Appointment());
  const [appointmentSlots, setAppointmentSlots] = useState<Slots[]>([]);
  const [dayResources, setDayResources] = useState<DayResource[]>([]);
  const [daySchedules, setDaySchedules] = useState<DaySchedule[]>([]);
  const [selectedDate, setSelectedDate] = useState("");
  const [resourceLoading, setResourceLoading] = useState(false);
  const [editAppointmentVisibility, setEditAppointmentVisibility] = useState(
    false
  );
  const {
    handleNewAppointmentTypes,
    suggestedAppointment,
    appointmentLoading,
    actualAppointmentTypes,
  } = NewAppointmentTypes();

  const formikRef = useRef<FormikProps<Appointment>>(null);
  const patients = usePatients("option");

  const { statuses, loading: statusesLoading, getOptions } = useInteractionStatus(StatusType.VISIT_SCHEDULED);

  const [appointmentTypeOptions, setAppointmentTypeOptions] = useState<
    IDropdownOptions[]
  >([]);
  const [doctorOptions, setDoctorOptions] = useState<IDropdownOptions[]>([]);
  const [resources, setResources] = useState<IDropdownOptions[]>([]);

  const isSelectedAppointmentNeedDoctor = (appointmentTypeId: number, appointmentTypes = actualAppointmentTypes) => {
    const appointment = appointmentTypes?.find(
      (appointmentType) => appointmentType?.id === appointmentTypeId
    );
    const isDoctorRequired = Boolean(appointment?.isDoctorRequired);

    return isDoctorRequired;
  };

  const toggleEditAppointmentVisibility = () =>
    setEditAppointmentVisibility((visible) => !visible);

  const closeEditAppointment = () => {
    closeHandler();
    setEditAppointmentVisibility((visible) => !visible);
  };

  const handleSubmit = (values: Appointment) => {
    const { startTime, endTime, ...otherValues } = values;
    setLoader(true);
    const appointment = Object.assign(new Appointment(), {
      ...otherValues,
      date: moment(otherValues?.date).format("YYYY-MM-DD"),
    });
    AppointmentService[formValue ? "updateAppointment" : "createAppointment"](
      appointment,
      (appointment) => {
        if (type == "addAppointment") {
          handleAppointmentUpdate?.(appointment);
          closeHandler();
        } else updateHandler(appointment, formValue ? "update" : "add");
      },
      () => {},
      () => {
        setLoader(false);
        closeHandler();
      }
    );
  };

  const searchbarValue = (patientId?: string) => {
    const [patient] = (patients as SelectProp[]).filter(
      (patient) => patient?.key === parseInt(patientId as string)
    );
    return patient?.value;
  };

  const populateAppointmentTypeOptions = (schedules: DaySchedule[]): void => {
    const addedAppointmentTypeId = new Set<number>();
    const appointmentTypeOptions: IDropdownOptions[] = [];

    schedules?.forEach((schedule) => {
      if (
        schedule?.appointmentId ||
        addedAppointmentTypeId.has(Number(schedule?.appointmentTypeId))
      )
        return;

      schedule?.appointmentTypeId && addedAppointmentTypeId.add(Number(schedule?.appointmentTypeId));
      appointmentTypeOptions?.push({
        label: schedule?.appointmentTypeTitle,
        value: schedule?.appointmentTypeId,
      });
    });

    if (
      formValue?.appointmentTypeId &&
      !addedAppointmentTypeId.has(formValue?.appointmentTypeId)
    ) {
      const editedOptions: any = {
        label: formValue?.appointmentTypeName,
        value: formValue?.appointmentTypeId,
        disabled: !addedAppointmentTypeId.has(formValue?.appointmentTypeId),
      };
      appointmentTypeOptions.push(editedOptions);
    }
    setAppointmentTypeOptions(appointmentTypeOptions);
  };

  const populateDoctorOptions = (
    appointment: Appointment,
    schedules = daySchedules
  ): void => {
    const addedDoctorId = new Set<number>();
    const doctorOptions: IDropdownOptions[] = [];

    schedules?.forEach((schedule) => {
      if (
        schedule?.appointmentId ||
        schedule?.appointmentTypeId !== appointment?.appointmentTypeId
      )
        return;

      schedule?.profileAvailabilities.forEach((profile) => {
        if (addedDoctorId.has(Number(profile?.profileId))) return;

        if (
          profile?.roleName?.toLowerCase() === UserRoles.DOCTOR.toLowerCase()
        ) {
          addedDoctorId.add(Number(profile?.profileId));
          doctorOptions.push({
            label: profile?.getFullName(),
            value: profile?.profileId,
          });
        }
      });
    });

    if (
      !formValue ||
      !appointment?.doctorProfileId ||
      addedDoctorId.has(Number(appointment?.doctorProfileId))
    )
      return setDoctorOptions(doctorOptions);

    const editedOption: any = {
      label:
        (formValue?.doctorFirstName ?? "") +
        " " +
        (formValue?.doctorLastName ?? ""),
      value: Number(formValue?.doctorProfileId),
      disabled: !addedDoctorId.has(Number(formValue?.doctorProfileId)),
    };

    setDoctorOptions([...doctorOptions, editedOption]);
  };

  const populateResourceOptions = (
    appointment: Appointment,
    resources = dayResources
  ): void => {
    const resourceOptions: IDropdownOptions[] = [];
    const addedResourceId = new Set<number>();

    resources?.forEach((resource) => {
      let isAppointmentAvailable = false;
      let isDoctorAvailable = false;

      resource?.schedules?.forEach((schedule) => {
        if (
          !schedule?.appointmentId &&
          schedule?.appointmentTypeId === appointment?.appointmentTypeId
        )
          isAppointmentAvailable = true;

        if (
          schedule?.profileAvailabilities.some(
            (profile) => profile?.profileId === appointment?.doctorProfileId
          )
        )
          isDoctorAvailable = true;
      });

      const appointmentWithoutDoctorsNeed = !appointment?.isDoctorRequired;
      if (
        isAppointmentAvailable &&
        (appointmentWithoutDoctorsNeed || isDoctorAvailable)
      ) {
        addedResourceId.add(Number(resource?.id));
        resourceOptions.push({
          label: resource?.name,
          value: resource?.id,
        });
      }
    });

    if (
      !formValue ||
      !appointment?.chairId ||
      addedResourceId.has(Number(appointment?.chairId))
    )
      return setResources(resourceOptions);

    const editedOption: any = {
      label: formValue?.chairName ?? "",
      value: Number(formValue?.chairId),
      disabled: !addedResourceId.has(Number(formValue?.chairId)),
    };

    setResources([...resourceOptions, editedOption]);
  };

  const populateAppointmentSlots = (
    appointment: Appointment,
    resources?: DayResource[]
  ) => {
    const format = "hh:mma";
    const morningStartTime = moment("06:00am", format);
    const morningEndTime = moment("11:59am", format);
    const afterNoonStartTime = moment("12:00pm", format);
    const afterNoonEndTime = moment("3:59pm", format);
    const tempAppointmentSession: Slots[] = appointmentSession.map(
      (session) => ({ ...session, slots: [] })
    );

    resources?.forEach((resource) => {
      if (appointment?.chairId && resource?.id !== appointment?.chairId) return;

      resource?.schedules?.forEach((schedule) => {
        if (
          schedule?.appointmentId ||
          !(schedule?.appointmentTypeId === appointment?.appointmentTypeId)
        )
          return;

        if (
          appointment?.doctorProfileId &&
          appointment?.isDoctorRequired &&
          !schedule?.profileAvailabilities?.some(
            (profile) => profile?.profileId === appointment?.doctorProfileId
          )
        )
          return;

        const isMorning =
          moment(
            moment(schedule?.startTime).format(format),
            format
          ).isSameOrAfter(morningStartTime) &&
          moment(
            moment(schedule?.startTime).format(format),
            format
          ).isSameOrBefore(morningEndTime);

        const isAfterNoon =
          moment(
            moment(schedule?.startTime).format(format),
            format
          ).isSameOrAfter(afterNoonStartTime) &&
          moment(
            moment(schedule?.startTime).format(format),
            format
          ).isSameOrBefore(afterNoonEndTime);

        if (isMorning) {
          tempAppointmentSession[0].slots.push({
            appointmentScheduleSlotId: schedule?.id,
            startTime: schedule?.startTime,
            endTime: schedule?.endTime,
          });
        } else if (isAfterNoon) {
          tempAppointmentSession[1].slots.push({
            appointmentScheduleSlotId: schedule?.id,
            startTime: schedule?.startTime,
            endTime: schedule?.endTime,
          });
        } else {
          tempAppointmentSession[2].slots.push({
            appointmentScheduleSlotId: schedule?.id,
            startTime: schedule?.startTime,
            endTime: schedule?.endTime,
          });
        }
      });
    });

    setAppointmentSlots(tempAppointmentSession);
  };

  const handleFormikValues = async (values: Appointment) => {
    if (values?.date) {
      let resources = dayResources;
      let schedules = daySchedules;

      if (!moment(values.date).isSame(selectedDate, "date")) {
        const formattedDate = moment(values?.date).format("YYYY-MM-DD");

        setSelectedDate(formattedDate);
        setResourceLoading(true);
        await AppointmentService.fetchAppointmentDayView(
          formattedDate,
          (res) => {
            schedules = [];
            resources = res?.resources;

            res?.resources?.forEach((resource) => {
              resource?.schedules?.forEach((schedule) => {
                schedules.push(schedule);
              });
            });

            setDaySchedules(schedules);
            setDayResources(res?.resources);
            populateAppointmentTypeOptions(schedules);
          },
          () => {
            setDaySchedules([]);
            setDayResources([]);
            setAppointmentTypeOptions([]);
          },
          () => {
            setAppointmentSlots([]);
            setResourceLoading(false);
          }
        );
      }
      if (values?.appointmentTypeId) {
        populateDoctorOptions(values, schedules);
        populateResourceOptions(values, resources);
        populateAppointmentSlots(values, resources);
      }
    }
  };

  const handleSessionValue = () => {
    setInitialFormValues((prev) => {
      setSessionTab("morning");
      return { ...prev, session: "morning" };
    });
  };
  useEffect(() => {
    (async () => {
      const values = Object.assign(new Appointment(), formValue);
      if (values?.patientProfileId)
        await handleNewAppointmentTypes(values?.patientProfileId);

      setInitialFormValues(values);
      handleFormikValues(values);
      handleSessionValue();

      if (type === "addAppointment")
        setInitialFormValues({ chairId: currentResource });
    })();
  }, []);

  useEffect(() => {
    setDoctorOptions([]);
    setResources([]);
    setAppointmentSlots([]);
  }, [daySchedules]);

  return (
    <div className="appointment-form__container">
      <div className="appointment-form__wrapper">
        <Formik
          initialValues={initialFormValues}
          validationSchema={AppointmentFormValidation}
          onSubmit={handleSubmit}
          enableReinitialize
          innerRef={formikRef}
        >
          {({
            setFieldValue,
            values,
            handleSubmit,
            setValues,
            dirty,
            isValid,
            touched,
            resetForm,
          }) => {
            return (
              <Form>
                <Row gutter={[15, 15]}>
                  <Col span={12}>
                    <DatePickerField
                      title="Date"
                      name="date"
                      format="MM/DD/YYYY"
                      setFieldValue={setFieldValue}
                      disabledDate={(d) => {
                        const yesterday = moment().subtract(1, "day");
                        return d.isBefore(yesterday);
                      }}
                      onChange={(value) => {
                        const updatedValues: Appointment = {
                          ...values,
                          appointmentTypeId: undefined,
                          chairId: undefined,
                          date: value.format("YYYY-MM-DD HH:mm:ss"),
                          doctorProfileId: undefined,
                          endTime: undefined,
                          session: "morning",
                          startTime: undefined,
                        };
                        setValues(updatedValues);
                        handleFormikValues(updatedValues);
                      }}
                      defaultValue={values.date}
                    />
                  </Col>
                  <Col span={12}>
                    <Searchbar
                      title="Patient"
                      fullWidth
                      edit={edit}
                      options={patients as SelectProp[]}
                      value={searchbarValue(values?.patientProfileId)}
                      selectHandler={(value) => {
                        setFieldValue("patientProfileId", value);

                        handleNewAppointmentTypes(value.toString());
                      }}
                      searchHandler={() => {}}
                    />
                  </Col>
                </Row>

                <Row gutter={[15, 15]}>
                  <Col span={12}>
                    <div className="appointment-form__custom-dropdown">
                      <div className="appointment-form__custom-dropdown__label">
                        <span className="appointment-form__custom-dropdown__label__title">
                          Appointment Type
                        </span>
                        {independentDrawer && (
                          <span
                            onClick={toggleEditAppointmentVisibility}
                            className="text-primary text-underline appointment-form__custom-dropdown__label__link"
                          >
                            Choose Other Appointment Type
                          </span>
                        )}
                      </div>
                      <DropdownField
                        placeHolder="Select Appointment Type"
                        name="appointmentTypeId"
                        // setFieldValue={setFieldValue}
                        onChange={(value) => {
                          const updatedValues = {
                            ...values,
                            appointmentTypeId: value,
                            chairId: undefined,
                            doctorProfileId: undefined,
                            endTime: undefined,
                            startTime: undefined,
                            isDoctorRequired: isSelectedAppointmentNeedDoctor(
                              value
                            ),
                          };
                          setDoctorOptions([]);
                          setResources([]);
                          setValues(updatedValues);
                          handleFormikValues(updatedValues);
                        }}
                        options={appointmentTypeOptions}
                        value={values.appointmentTypeId}
                        loading={appointmentLoading || resourceLoading}
                        note={
                          suggestedAppointment?.SuggestedAppointment?.title
                            ? `${suggestedAppointment?.SuggestedAppointment?.title} is the Suggested Appointment Type`
                            : ""
                        }
                      />
                    </div>
                  </Col>
                  <Col span={12} className="visit-type__wrapper">
                    <RadioInputField
                      name="visitType"
                      title="Visit Type"
                      setFieldValue={setFieldValue}
                      options={visitTypeOptions}
                      value={values.visitType}
                    />
                  </Col>
                  <Col span={12}>
                    <DropdownField
                      allowClear
                      title="Doctor"
                      name="doctorProfileId"
                      placeHolder="Select Doctor"
                      // setFieldValue={setFieldValue}
                      onChange={(value) => {
                        const updatedValues: Appointment = {
                          ...values,
                          chairId: undefined,
                          doctorProfileId: value,
                          endTime: undefined,
                          startTime: undefined,
                        };
                        setResources([]);
                        setValues(updatedValues);
                        handleFormikValues(updatedValues);
                      }}
                      options={doctorOptions}
                      value={values.doctorProfileId}
                    />
                  </Col>
                  <Col span={12}>
                    <DropdownField
                      showArrow
                      title="Resources"
                      name="chairId"
                      placeHolder="Select Resource"
                      loading={resourceLoading}
                      onChange={(value) => {
                        const updatedValues: Appointment = {
                          ...values,
                          chairId: value,
                          endTime: undefined,
                          startTime: undefined,
                        };
                        setValues(updatedValues);
                        handleFormikValues(updatedValues);
                      }}
                      options={resources}
                      value={values?.chairId}
                      disabled={values.visitType === "virtual_consultation"}
                    />
                  </Col>
                </Row>
                {edit && (
                  <Row gutter={[15, 15]}>
                    <Col span={12}>
                      <DatePickerField
                        title="Start Time"
                        name="startTime"
                        type="time"
                        value={moment.utc(formValue?.startTime)}
                        format="hh:mm a"
                        disabled
                      />
                    </Col>
                    <Col span={12}>
                      <DatePickerField
                        title="End Time"
                        name="endTime"
                        type="time"
                        value={moment.utc(formValue?.endTime)}
                        format="hh:mm a"
                        disabled
                      />
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col span={12}>
                    <DropdownField
                      className="appointment-form__status"
                      name="status"
                      loading={statusesLoading}
                      options={getOptions(statuses[0]?.interactionSubStatuses)}
                      placeHolder="Select Status"
                      showArrow
                      title="Status"
                      setFieldValue={setFieldValue}
                      value={values?.status}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col span={24}>
                    <div className="mb-5 appointment-form__session">
                      <span className="text-secondary appointment-form__session__title">
                        Choose Session &amp; Slot
                      </span>
                      {independentDrawer && (
                        <span
                          onClick={toggleEditAppointmentVisibility}
                          className="text-primary text-underline appointment-form__session__link"
                        >
                          Choose Other Start Time &amp; End Time
                        </span>
                      )}
                    </div>
                    {appointmentSlots.map((session, i) => {
                      return (
                        <CheckableTag
                          className="mr-3"
                          checked={values?.session === session?.key}
                          key={session?.key}
                          onChange={(value) => {
                            setFieldValue("session", session?.key);

                            setSessionTab(session?.key);
                          }}
                        >
                          {session?.title}
                        </CheckableTag>
                      );
                    })}
                  </Col>
                </Row>
                <Row>
                  <Col span={24} className="mb-5">
                    {appointmentSlots.map((time, index) => {
                      if (!time?.slots.length) {
                        return (
                          <div className="text-danger mt-2">
                            {values?.session === time?.key &&
                              "No slots Available"}
                          </div>
                        );
                      }

                      return values?.session === time?.key ? (
                        time.slots.map((currentSlot, i) => {
                          return (
                            <CheckableTag
                              checked={
                                values?.appointmentScheduleSlotId ===
                                  currentSlot?.appointmentScheduleSlotId &&
                                (values?.startTime ===
                                  time?.slots[i]?.startTime ||
                                  values?.endTime === time?.slots[i]?.endTime)
                              }
                              className="mr-3 checked-tag"
                              key={time?.slots[i]?.startTime}
                              onChange={(value) => {
                                setFieldValue(
                                  "appointmentScheduleSlotId",
                                  time?.slots[i]?.appointmentScheduleSlotId
                                );
                                setFieldValue(
                                  "startTime",
                                  time?.slots[i]?.startTime
                                );
                                setFieldValue(
                                  "endTime",
                                  time?.slots[i]?.endTime
                                );
                              }}
                            >
                              {`${moment(currentSlot?.startTime).format(
                                "hh:mm a"
                              )}-${moment(currentSlot?.endTime).format(
                                "hh:mm a"
                              )}`}
                            </CheckableTag>
                          );
                        })
                      ) : (
                        <></>
                      );
                    })}
                  </Col>
                </Row>
                <Row gutter={[15, 15]}>
                  <Col span={24}>
                    <InputField
                      type="textarea"
                      title="Note"
                      name="notes"
                      rows={6}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <CheckboxField
                      name="isEmergencyVisit"
                      content="Emergency visit"
                      setFieldValue={setFieldValue}
                      value={values?.isEmergencyVisit ?? false}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={16} />
                  <Col span={8} className="appointment-form__controller">
                    <Button
                      type="default"
                      onClick={() => {
                        setSessionTab("");
                        setInitialFormValues(new Appointment());
                        closeHandler();
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={loader}
                      onClick={() => handleSubmit()}
                    >
                      Save
                    </Button>
                  </Col>
                </Row>
              </Form>
            );
          }}
        </Formik>
      </div>
      <Drawer
        title="Create Appointment"
        width="75vw"
        visible={editAppointmentVisibility}
        closable
        onClose={closeEditAppointment}
        destroyOnClose
        push={false}
      >
        <NewAppointmentForm
          currentDate={selectedDate ? moment(selectedDate) : moment()}
          appointmentCreateEditVisibility={closeEditAppointment}
          type="editAppointment"
          eventSchedule={formikRef?.current?.values}
          currentResource={formikRef?.current?.values?.chairId}
          resourceId={formikRef?.current?.values?.chairId}
          handleAppointmentUpdate={updateHandler}
        />
      </Drawer>
    </div>
  );
};

export default CorporateContainer(AppointmentForm);
