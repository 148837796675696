import Axios from "axios";
import { deserialize, serialize } from "serializr";
import axiosInstance from "../../interceptor/axiosInstance";
import { PaginationDetails } from "../../models/Pagination/pagination.model";
import { PaymentFilters } from "../../models/Payment/payment.model";
import {
  PaymentIntent,
  PaymentInvoiceMetrics,
} from "../../models/PaymentIntent/paymentIntent.model";
import { PaymentMethod } from "../../models/PaymentMethod/paymentMethod.model";
import {
  MonthlyPaymentReports,
  PaymentReports,
} from "../../models/PaymentReports/paymentReports.model";
import { PaymentTransfer } from "../../models/PaymentTransfer/paymentTransfer.model";
import ApiRoutes from "../../routes/routeConstants/apiRoutes";

export default class PaymentService {
  static async CreateStripeAccount(
    onSuccess: (url: string) => void,
    onFinal: () => void
  ) {
    try {
      const { data } = await axiosInstance.post(
        ApiRoutes.STRIPES_CREATE_ACCOUNT,
        {}
      );
      onSuccess(data?.url);
    } catch (error) {
    } finally {
      onFinal();
    }
  }

  static async createIndent(
    payload: {
      amount: number;
      payment_method: string;
    },
    onSuccess: (clientSecret: string, status: string) => void
  ) {
    try {
      const { data } = await Axios.post(ApiRoutes.MAKE_PAYMENT_INDENT, payload);
      onSuccess(data?.client_secret, data?.status);
    } catch (error) {
    } finally {
    }
  }

  static async getPaymentMethod(
    paymentId: string,
    onSuccess: (paymentMethods: PaymentMethod) => void,
    onError: () => void,
    onFinal: () => void
  ) {
    return axiosInstance
      .get(ApiRoutes.GET_PATIENT_PAYMENT_METHODS + paymentId)
      .then((response) => {
        const paymentMethod = deserialize(
          PaymentMethod,
          response?.data?.payment_method as PaymentMethod
        );
        onSuccess(paymentMethod);
      })
      .catch((error) => {
        onError();
      })
      .finally(onFinal);
  }

  static async getPaymentIntents(
    onSuccess: (paymentIntents: PaymentIntent[]) => void,
    onError: () => void,
    onFinal: () => void
  ) {
    return axiosInstance
      .get(ApiRoutes.PAYMENT_INTENT)
      .then((response) => {
        const paymentIntents = deserialize(
          PaymentIntent,
          response?.data?.payment_intents as PaymentIntent[]
        );
        onSuccess(paymentIntents);
      })
      .catch((error) => {
        onError();
      })
      .finally(onFinal);
  }
  static async getOfflinePayments(
    onSuccess: (paymentIntents: PaymentIntent[]) => void,
    onError: () => void,
    onFinal: () => void
  ) {
    try {
      const { data } = await axiosInstance.get(ApiRoutes.LIST_OFFLINE_PAYMENTS);
      const paymentIntents = deserialize(
        PaymentIntent,
        data?.payment_intents as PaymentIntent[]
      );
      onSuccess(paymentIntents);
    } catch (error) {
      onError();
    } finally {
      onFinal();
    }
  }

  static async createPaymentInvoice(
    newPaymentIntent: PaymentIntent,
    onSuccess: (paymentIntent: PaymentIntent[]) => void,
    onError?: () => void,
    onFinal?: () => void
  ) {
    try {
      const { data } = await axiosInstance.post(
        ApiRoutes.CREATE_PAYMENT_INVOICE,
        {
          payment_invoice: serialize(PaymentIntent, newPaymentIntent),
        }
      );
      const payments = deserialize(
        PaymentIntent,
        data["payment_invoices"] as unknown[]
      );

      onSuccess(payments);
    } catch (ex) {
      onError?.();
    } finally {
      onFinal?.();
    }
  }
  // static async getPaymentInvoices(
  //   invoiceFilter: object,
  //   onSuccess: (paymentIntents: PaymentIntent[]) => void,
  //   onError: () => void,
  //   onFinal: () => void
  // ) {
  //   try {
  //     const { data } = await axiosInstance.get(ApiRoutes.GET_PAYMENT_INVOICE, {
  //       params: invoiceFilter,
  //     });
  //     const paymentIntents = deserialize(
  //       PaymentIntent,
  //       data?.payment_intents as PaymentIntent[]
  //     );
  //     onSuccess(paymentIntents);
  //   } catch (error) {
  //     onError();
  //   } finally {
  //     onFinal();
  //   }
  // }
  static getPaymentInvoices(
    invoiceFilter: PaymentFilters,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const data = serialize(PaymentFilters, invoiceFilter);
    axiosInstance
      .get(ApiRoutes.GET_PAYMENT_INVOICE, { params: data })
      .then((response) => {
        const payments = deserialize(
          PaymentIntent,
          response.data["payment_invoices"]
        );
        const pagination = deserialize(
          PaginationDetails,
          response.data["meta"]
        );
        onSuccess(payments, pagination);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }
  static updatePaymentInvoicesStatus(
    filter: PaymentIntent,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const serializedParams = serialize(PaymentIntent, filter);

    axiosInstance
      .put(ApiRoutes.UPDATE_PAYMENT_INVOICE, {
        payment_invoice: serializedParams,
      })
      .then((response) => {
        const payment = deserialize(
          PaymentIntent,
          response.data["payment_invoices"]
        );
        onSuccess(payment);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }
  static getPaymentInvoicesMetrics(
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .get(ApiRoutes.GET_PAYMENT_INVOICE_METRICS)
      .then((response) => {
        const metrics = deserialize(PaymentInvoiceMetrics, response.data);
        onSuccess(metrics);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }
  static getPaymentReports(
    reportFilter: object,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const data = serialize(PaymentFilters, reportFilter);
    axiosInstance
      .get(ApiRoutes.GET_PAYMENT_REPORTS, { params: data })
      .then((response) => {
        const reports = deserialize(
          PaymentReports,
          response?.data?.payment_reports as PaymentReports
        );
        onSuccess(reports);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }
  static getMonthlyPaymentReports(
    monthlyPaymentFilter: object,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const data = serialize(PaymentFilters, monthlyPaymentFilter);
    axiosInstance
      .get(ApiRoutes.GET_MONTHLY_PAYMENT_REPORTS, {
        params: data,
      })
      .then((response) => {
        const reports = deserialize(
          MonthlyPaymentReports,
          response.data.monthly_payment_reports as MonthlyPaymentReports[]
        );
        onSuccess(reports, response.data.total);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }
  static getMonthlyDetailsReports(
    monthlyDetailsFilter: object,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const data = serialize(PaymentFilters, monthlyDetailsFilter);
    axiosInstance
      .get(ApiRoutes.GET_MONTHLY_DETAILS_REPORTS, {
        params: data,
      })
      .then((response) => {
        const reports = deserialize(
          PaymentReports,
          response?.data["monthly_payment_reports_detailed_view"]
        );
        onSuccess(reports);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static async createOfflinePayments(
    paymentIntent: PaymentIntent,
    onSuccess: (paymentIntent: PaymentIntent) => void,
    onError: () => void,
    onFinal: () => void
  ) {
    try {
      const PaymentIntentJSON = {
        payment_intent: serialize(paymentIntent),
      };
      const { data } = await axiosInstance.post(
        ApiRoutes.CRUD_OFFLINE_PAYMENTS?.replace(
          ":contractSubscriptionId",
          paymentIntent?.contractSubscriptionId?.toString() ?? ""
        ),
        PaymentIntentJSON
      );
      const createdPaymentIntent = deserialize(
        PaymentIntent,
        data?.payment_intent
      );
      onSuccess(createdPaymentIntent);
    } catch (error) {
      onError();
    } finally {
      onFinal();
    }
  }

  static async updateOfflinePayments(
    paymentIntent: PaymentIntent,
    onSuccess: (paymentIntent: PaymentIntent) => void,
    onError: () => void,
    onFinal: () => void
  ) {
    try {
      const PaymentIntentJSON = {
        payment_intent: serialize(paymentIntent),
      };
      const API_URL =
        ApiRoutes.CRUD_OFFLINE_PAYMENTS?.replace(
          ":contractSubscriptionId",
          paymentIntent?.contractSubscriptionId?.toString() ?? ""
        ) +
        "/" +
        paymentIntent?.id;

      const { data } = await axiosInstance.put(API_URL, PaymentIntentJSON);
      const createdPaymentIntent = deserialize(
        PaymentIntent,
        data?.payment_intent
      );
      onSuccess(createdPaymentIntent);
    } catch (error) {
      onError();
    } finally {
      onFinal();
    }
  }

  static async deleteOfflinePayments(
    paymentIntent: PaymentIntent,
    onSuccess: () => void,
    onError: () => void,
    onFinal: () => void
  ) {
    try {
      const API_URL =
        ApiRoutes.CRUD_OFFLINE_PAYMENTS?.replace(
          ":contractSubscriptionId",
          paymentIntent?.contractSubscriptionId?.toString() ?? ""
        ) +
        "/" +
        paymentIntent?.id;

      await axiosInstance.delete(API_URL);
      onSuccess();
    } catch (error) {
      onError();
    } finally {
      onFinal();
    }
  }
}
