import { serializable, alias, primitive, list } from "serializr";

export class CorporateObjectFilterParams {
  @serializable(alias("object_types", list(primitive())))
  objectTypes: string[] = [];

  @serializable(alias("manufacturer_ids", list(primitive())))
  manufacturerIds: number[] = [];

  @serializable(alias("object_category_ids", list(primitive())))
  objectCategoryIds: number[] = [];

  @serializable(alias("color", primitive()))
  color: string = "";
}
