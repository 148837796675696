import { Appointment } from "models/Appointment/appointment.model";
import { DaySchedule } from "models/AppointmentDay/appointmentDay.model";
import React from "react";
import { CalendarSlot } from "shared/Types/CalendarSlot.type";

import "./slotDetails.scss";

interface SlotDetailsProps {
  schedule: DaySchedule;
  slot: CalendarSlot;
}

const SlotDetails = ({ schedule, slot }: SlotDetailsProps) => {
  const { patientFirstName, patientLastName, procedureCode, status } = schedule;

  const appointmentTitle = (
    (slot?.title || "") +
    " " +
    (procedureCode ? `(${procedureCode})` : "")
  ).trim();

  const appointmentStatus = status
    ? Appointment.mapAppointmentStatus(status)
    : "";

  const patientName = `${patientFirstName || ""} ${
    patientLastName || ""
  }`?.trim();

  return (
    <div className="slot-details">
      <div className="slot-details__left">
        <img
          alt=""
          className="allocated-slot__info__pic"
          height={30}
          src={schedule?.patientProfilePicUrl}
          width={30}
        />
      </div>
      <div className="slot-details__right">
        <span className="slot-details__patient text-bold">{patientName}</span>
        <span className="slot-details__appointment">{appointmentTitle}</span>
        <span
          className={`text-capitalize slot-details__status ${Appointment.mapAppointmentStatus(
            appointmentStatus,
            " ",
            "-"
          )}`.trimEnd()}
        >
          {appointmentStatus}
        </span>
      </div>
    </div>
  );
};

export default SlotDetails;
