import { Col, Drawer, Popover, Row, Select, Table, Modal } from "antd";
import React, { FC, useEffect, useState } from "react";
import moment from "moment";
import { Appointment } from "../../../models/Appointment/appointment.model";
import { AppointmentService } from "../../../services/Appointment/appointment.service";
import UserPlaceholder from "../../../assets/images/userPlaceholder.png";
import { EllipsisOutlined } from "@ant-design/icons";
import "./allAppointments.scss";
import AppointmentForm from "../../Appointments/AppointmentForm";
import OnGoingAppointments from "./OnGoingAppointments";
import { Filter } from "..";
import AppLoader from "../../../shared/components/AppLoader";
import ScanFacility from "../../../assets/icons/scan_facility.png";
import SeatedInChair from "../../../assets/icons/seated_in_chair.png";
import WaitingRoom from "../../../assets/icons/waiting_room.png";
import VirtualVisitInProgress from "../../../assets/icons/virtual_visit_in_progress.png";
import VirtualVisitInConfirmed from "../../../assets/icons/virtual_visit_confirmed.png";
import CorporateContainer from "../../../store/container/CorporateContainer";
import { CorporateReducerProps } from "../../../store/reducers/corporateReducer";
import { removeUnderscore } from "../../../shared/utils/removeUnderscore";
import NewAppointmentForm from "../../Appointments/AppointmentTemplate/NewAppointmentForm";
import { appointmentMappedSchedule } from "../../../shared/utils/mappedAppointment";
import { PaginationDetails } from "../../../models/Pagination/pagination.model";
import { TablePaginationConfig } from "antd/lib/table";
import useFilters from "../../../shared/hooks/useFilter/useFilters";
import { FilterParams } from "../../../models/FilterParams/filterParams.model";
import useResponsibilities from "../../../shared/hooks/Responsibilities/useResponsibilities";
import { ResponsibilityTypes } from "../../../enums/responsebily.enum";
import { AppointmentStatus } from "../../../enums/appointmentStatus.enum";
import Confirmed from "../../../assets/icons/confirmed.png";
import NotConfirmed from "../../../assets/icons/not_confirmed.png";
import { InteractionStatus } from "../../../models/InteractionStatuse/interactionStatuse.model";
import { StatusType } from "../../../enums/statusType.enum";
import { useInteractionStatus } from "../../../shared/hooks/useInteractionStatus/useInteractionStatus";

const { warning } = Modal;
interface AllAppointmentsProps extends CorporateReducerProps {
  filter: Filter;
  tab?: string;
}

const AllAppointments: FC<AllAppointmentsProps> = (props) => {
  const { hasAccess } = useResponsibilities();

  const { filter, tab, practiceId } = props;

  
  const { statuses, loading: statusesLoading, getOptions } = useInteractionStatus();

  const scheduledVisitStatus = getOptions(InteractionStatus.getTypeSpecificStatus(
    statuses,
    StatusType.VISIT_SCHEDULED
  )?.interactionSubStatuses);

  const completedVisitStatus = getOptions(InteractionStatus.getTypeSpecificStatus(
    statuses,
    StatusType.VISIT_COMPLETE
  )?.interactionSubStatuses);

  const HAS_APPOINTMENT_EDIT_ACCESS = hasAccess(
    ResponsibilityTypes.APPOINTMENT_EDIT
  );

  const columns = [
    {
      title: "Name",
      dataIndex: "patient",
      key: "patientFirstName",
      render: (_: string, { patientFirstName, patientLastName }: Appointment) =>
        patientFirstName + " " + patientLastName,
    },
    {
      title: "Doctor",
      dataIndex: "doctorFirstName",
      key: "doctorFirstName",
      render: (
        _: string,
        {
          doctorProfileId,
          doctorFirstName,
          doctorLastName,
          doctorProfilePicUrl,
        }: Appointment
      ) => {
        if (!doctorProfileId) return "--";
        return (
          <Row className="user-profile__wrapper" gutter={[10, 10]}>
            <Col span={8}>
              <img src={doctorProfilePicUrl ?? UserPlaceholder} alt="" />
            </Col>
            <Col span={14}>
              {doctorFirstName + " " + (doctorLastName ?? "")}
            </Col>
          </Row>
        );
      },
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (date: string) => moment(date).format("MM/DD/YYYY"),
    },
    {
      title: "Time",
      dataIndex: "startTime",
      key: "startTime",
      render: (_: string, appointment: Appointment) => {
        return `${moment(appointment.startTime).format("hh:mm a")} - ${moment(
          appointment.endTime
        ).format("hh:mm a")}`;
      },
    },
    {
      title: "Visit Status",
      dataIndex: "status",
      key: "status",

      render: (status: string, appointment: Appointment, index: number) => {
        const IS_PAST_APPOINTMENT = moment
          .utc(appointment?.date)
          .isBefore(moment.utc(), "date");

        const IS_FUTURE_APPOINTMENT = moment
          .utc(appointment?.date)
          .isAfter(moment.utc(), "date");

        const IS_DROPDOWN_DISABLED =
          !HAS_APPOINTMENT_EDIT_ACCESS ||
          IS_PAST_APPOINTMENT ||
          IS_FUTURE_APPOINTMENT;

          const options = Appointment.isAppointmentInteractionCompleted(
            appointment
          )
            ? completedVisitStatus
            : scheduledVisitStatus;

        return (
          <Select
            dropdownMatchSelectWidth
            placeholder="select visit status"
            defaultValue={status}
            value={status}
            className="visit-status__dropdown"
            options={options}
            loading={statusesLoading || statusLoading[index]}
            dropdownClassName="visit-status__dropdown__items"
            onClick={(event) => {
              event.stopPropagation();
            }}
            disabled={IS_DROPDOWN_DISABLED}
            onChange={(status) => {
              setStatusLoading({
                [index]: true,
              });
              const updatedAppointment = Object.assign(new Appointment(), {
                ...appointment,
                status,
              });
              AppointmentService.updateAppointment(
                updatedAppointment,
                (appointment) => {
                  setAppointments((existingAppointment) => {
                    existingAppointment.splice(index, 1, appointment);
                    return [...existingAppointment];
                  });
                },
                () => {},
                () => {
                  setStatusLoading({ [index]: false });
                }
              );
            }}
          />
        );
      },
    },
    {
      title: "",
      dataIndex: "",
      key: "",
      render: (_: string, appointment: Appointment, index: number) => {
        const IS_PAST_APPOINTMENT = moment
          .utc(appointment?.date)
          .isBefore(moment.utc(), "date");

        const POPOVER_VISIBLE =
          !IS_PAST_APPOINTMENT &&
          HAS_APPOINTMENT_EDIT_ACCESS &&
          !appointment?.interactionStatus;

        const content = (
          <div className="content-control">
            <p
              onClick={(event) => {
                event.stopPropagation();
                setAppointmentFormVisibility(true);
                setFormValue(appointment);
              }}
            >
              Reschedule Appointment
            </p>
            <p
              onClick={(event) => {
                event.stopPropagation();
                warning({
                  maskClosable: true,
                  content: "Are you sure you want to close this reference?",
                  okText: "Close Appointment",
                  onOk: () => {
                    AppointmentService.deleteAppointment(
                      appointment,
                      () => {
                        setAppointments((existingAppointments) => {
                          existingAppointments.splice(index, 1);
                          return [...existingAppointments];
                        });
                      },
                      () => {},
                      () => {}
                    );
                  },
                });
              }}
            >
              Close Appointment
            </p>
          </div>
        );
        return (
          POPOVER_VISIBLE && (
            <Popover content={content}>
              <EllipsisOutlined />
            </Popover>
          )
        );
      },
    },
  ];

  const [loader, setLoader] = useState(false);

  const { getParams, setParams, location } = useFilters();
  const [statusLoading, setStatusLoading] = useState<{
    [x: string]: boolean;
  }>({});

  const [formValue, setFormValue] = useState<Appointment>();
  const [
    appointmentPagination,
    setAppointmentPagination,
  ] = useState<PaginationDetails>();

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [appointmentFormVisibility, setAppointmentFormVisibility] = useState(
    false
  );

  const [nextAppointmentVisibility, setNextAppointmentVisibility] = useState(
    false
  );

  const [appointment, setAppointment] = useState<Appointment>();

  const [appointments, setAppointments] = useState<Appointment[]>([]);

  const handleCloseAppointmentForm = () => {
    setAppointmentFormVisibility(false);
  };

  const handleCloseNextAppointment = () => {
    setNextAppointmentVisibility(false);
  };

  const handleFetchAppointment = (prevFilter?: FilterParams) => {
    if (practiceId) {
      const filterParams =
        filter.startDate || prevFilter?.startDate
          ? { ...filter, ...prevFilter }
          : {
              ...prevFilter,
              startDate: moment().format("YYYY-MM-DD"),
              endDate: moment().format("YYYY-MM-DD"),
            };
      if (tab == "0") {
        setLoader(true);
        AppointmentService.fetchAppointment(
          filterParams,
          (appointments, pagination) => {
            setAppointmentPagination(pagination);
            setAppointments(appointments);
          },
          () => {},
          () => {
            setLoader(false);
          }
        );
      }
    }
  };

  useEffect(() => {
    handleFetchAppointment(getParams());
  }, [filter, tab, practiceId]);

  const handleUpdateAppointment = (
    appointment: Appointment,
    type: "add" | "update"
  ) => {
    if (type === "add") {
      setAppointments((existingAppointments) => [
        ...existingAppointments,
        appointment,
      ]);
      handleCloseAppointmentForm();
      return;
    }
    setAppointments((existingAppointments) =>
      existingAppointments?.map((oldAppointment) => {
        if (oldAppointment.id === appointment.id) {
          return appointment;
        }
        handleCloseAppointmentForm();
        return oldAppointment;
      })
    );
  };
  const handleRow = (appointment: Appointment) => {
    return {
      onClick: () => {
        setAppointment(appointment);
        setNextAppointmentVisibility(true);
      },
    };
  };
  const handleTableChange = (pagination: TablePaginationConfig) => {
    if (pagination?.current) setCurrentPage(pagination?.current);
    const prevFilter = { ...getParams(), page: pagination?.current };
    setParams(prevFilter, location.hash);
    handleFetchAppointment(prevFilter);
  };
  return (
    <div className="appointment-container">
      <Drawer
        title="Edit Appointment"
        width="75vw"
        visible={appointmentFormVisibility}
        onClose={handleCloseAppointmentForm}
        closable
        destroyOnClose
        push={false}
      >
        <NewAppointmentForm
          currentDate={formValue?.date ? moment.utc(formValue?.date) : moment()}
          appointmentCreateEditVisibility={handleCloseAppointmentForm}
          type="editAppointment"
          eventSchedule={appointmentMappedSchedule(
            formValue ?? new Appointment()
          )}
          currentResource={formValue?.chairId}
          resourceId={formValue?.chairId}
          handleAppointmentUpdate={(appointment) =>
            handleUpdateAppointment(appointment, "update")
          }
        />
      </Drawer>
      <Drawer
        title="Ongoing/Next Appointment"
        width="100vw"
        visible={nextAppointmentVisibility}
        onClose={handleCloseNextAppointment}
        closable
        destroyOnClose
      >
        <OnGoingAppointments
          appointment={appointment as Appointment}
          closeHandler={handleCloseNextAppointment}
          onAppointmentFetch={handleFetchAppointment}
        />
      </Drawer>
      {loader ? (
        <AppLoader loading />
      ) : (
        <Table
          dataSource={appointments}
          columns={columns}
          onRow={handleRow}
          onChange={handleTableChange}
          pagination={{
            total: appointmentPagination?.totalCount,
            current: appointmentPagination?.currentPage,
            showTotal: (total: number, range: [number, number]) => (
              <p>
                Showing <b>{` ${range[0]} - ${range[1]}`}</b> of{" "}
                <b>{`${total.toLocaleString()}`}</b>
              </p>
            ),
          }}
        />
      )}
    </div>
  );
};

export default CorporateContainer(AllAppointments);
