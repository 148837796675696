import ApiRoutes from "../../routes/routeConstants/apiRoutes";
import axiosInstance from "../../interceptor/axiosInstance";
// import { PatientStatus } from "../../models/PatientStatus/PatientStatus.model";
import { deserialize, serialize } from "serializr";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import { PatientStatuse, PatientStatuses } from "../../models/PatientStatuse/patientStatuse.model";
import { PatientSubStatuse } from "../../models/PatientSubStatuse/patientSubStatuse.model";

export default class PatientStatusService {
    static fetchPatientStatuss(
        onSuccess: (patientStatuses: PatientStatuse[]) => void,
        onError: Function,
        onFinal: () => void
    ) {
        axiosInstance
            .get(ApiRoutes.PATIENT_STATUS)
            .then((response) => {
                const { patientStatuses } = deserialize(PatientStatuses, response.data);
                onSuccess(patientStatuses);
            })
            .catch((error) => {
                onError();
            })
            .finally(() => {
                onFinal();
            });
    }

    static createPatientStatus(
        patientStatusId: number,
        PatientStatus: PatientSubStatuse,
        onSuccess: (patientSubStatuse: PatientSubStatuse) => void,
        onError: Function,
        onFinal: () => void
    ) {
        const PatientStatusJSON = { patient_sub_status: serialize(PatientStatus) };
        axiosInstance
            .post(ApiRoutes.PATIENT_SUB_STATUS.replace(":patientStatusId", patientStatusId.toString()), PatientStatusJSON)
            .then((response) => {
                const PatientStatus = deserialize(PatientSubStatuse, response.data["patient_sub_status"]);
                onSuccess(PatientStatus);
            })
            .catch((error) => {
                onError();
            })
            .finally(() => {
                onFinal();
            });
    }

    static updatePatientStatus(
        patientStatusId: number,
        PatientStatus: PatientSubStatuse,
        onSuccess: (patientSubStatuse: PatientSubStatuse) => void,
        onError: Function,
        onFinal: () => void
    ) {
        const API_URL = (ApiRoutes.PATIENT_SUB_STATUS + '/' + PatientStatus.id).replace(":patientStatusId", patientStatusId.toString());
        const PatientStatusJSON = { patient_sub_status: serialize(PatientStatus) };

        axiosInstance
            .put(API_URL, PatientStatusJSON)
            .then((response) => {
                const PatientStatus = deserialize(PatientSubStatuse, response.data["patient_sub_status"]);
                onSuccess(PatientStatus);
            })
            .catch((error) => {
                onError();
            })
            .finally(() => {
                onFinal();
            });
    }

    static deletePatientStatus(
        patientStatusId: number,
        patientSubStatusId: number,
        onSuccess: Function,
        onError: Function,
        onFinal: () => void
    ) {
        const API_URL = (ApiRoutes.PATIENT_SUB_STATUS + '/' + patientSubStatusId).replace(":patientStatusId", patientStatusId.toString());
        axiosInstance
            .delete(API_URL)
            .then((response) => {
                onSuccess();
            })
            .catch((error) => {
                onError();
            })
            .finally(() => {
                onFinal();
            });
    }
}
