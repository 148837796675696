import { Button, Col, Row } from "antd";
import { Form, Formik } from "formik";
import React, { FC, useEffect } from "react";
import { useState } from "react";
import { USER_CATEGORY } from "../../../../enums/userCategory.enum";
import { Role } from "../../../../models/Role/role.model";
import { RoleService } from "../../../../services/Role/role.service";
import DropdownField from "../../../../shared/components/DropdownField";
import InputField from "../../../../shared/components/InputField";
import "./userRoleForm.scss";
import { userRoleValidation } from "./validation";

const categoryOptions = [
  {
    label: "Admin",
    value: USER_CATEGORY.ADMIN,
  },
  {
    label: "Doctor",
    value: USER_CATEGORY.DOCTOR,
  },
  {
    label: "Assistant",
    value: USER_CATEGORY.ASSISTANT,
  },
  {
    label: "Front Desk",
    value: USER_CATEGORY.FRONT_DESK,
  },
];

interface UserRoleFormProps {
  successHandler: (role: Role) => void;
  closeHandler: () => void;
}

const UserRoleForm: FC<UserRoleFormProps> = (props) => {
  const { successHandler, closeHandler } = props;

  const [loading, setLoading] = useState(false);

  const handleSubmit = (values: Role) => {
    setLoading(true);
    const role = Object.assign(new Role(), values);
    RoleService.createRole(
      role,
      (role) => {
        successHandler(role);
      },
      () => {
        setLoading(false);
      }
    );
  };

  const formikProps = {
    initialValues: new Role(),
    validationSchema: userRoleValidation,
    onSubmit: handleSubmit,
  };

  return (
    <div className="user-role-form">
      <Formik {...formikProps}>
        {({ values, dirty, isValid, setFieldValue }) => (
          <Form>
            <div className="user-role-inputs">
              <InputField title="Role Name" name="name" type="text" />
              <DropdownField
                name="category"
                title="Category"
                options={categoryOptions}
                value={values.category}
                onChange={(value) => {
                  setFieldValue("category", value);
                }}
              />
              <InputField
                title="Role Description"
                name="description"
                type="textarea"
              />
            </div>
            <Row justify="end" gutter={[20, 0]} className="mt-5">
              <Col>
                <Button onClick={closeHandler}>Cancel</Button>
              </Col>
              <Col>
                <Button
                  htmlType="submit"
                  type="primary"
                  disabled={!dirty || !isValid}
                  loading={loading}
                >
                  Save
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default UserRoleForm;
