import moment from "moment";
import * as Yup from "yup";
import { Appointment } from "../../../../models/Appointment/appointment.model";

const validation = Yup.object().shape({
  date: Yup.string().required().label("Date"),
  patientProfileId: Yup.string().required().label("Patient"),
  appointmentTypeId: Yup.number().required().label("Appointment Type"),
  visitType: Yup.string().required().label("Visit Type"),
  doctorProfileId: Yup.string()
    .when("isDoctorRequired", {
      is: (isDoctorRequired) =>
        typeof isDoctorRequired !== "boolean" || isDoctorRequired,
      then: (schema: Yup.StringSchema) => schema.required("Doctor is Required"),
      otherwise: (schema: Yup.StringSchema) => schema.nullable(),
    })
    .label("Doctor"),
  chairId: Yup.string().required().label("Resource"),
  startTime: Yup.string()
    .test(
      "Compare Start & End Time",
      "Start Time must be before End Time",
      function (time) {
        if (!this.parent?.endTime) return true;

        const startTime = moment(time);
        const endTime = moment(this.parent?.endTime);
        return startTime.isBefore(endTime);
      }
    )
    .required()
    .nullable()
    .label("Start Time"),
  endTime: Yup.string()
    .test(
      "Compare Start & End Time",
      "End Time must be after Start Time",
      function (time) {
        if (!this.parent?.startTime) return true;

        const startTime = moment(this.parent?.startTime);
        const endTime = moment(time);
        return startTime.isBefore(endTime);
      }
    )
    .required()
    .nullable()
    .label("End Time"),
});

export default validation;
