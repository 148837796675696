import React, { useEffect, useState } from "react";

import { Patient } from "../../../models/Patient/patient.model";
import { Button, Drawer, Table } from "antd";
import moment from "moment";
import { useHistory, generatePath, useParams } from "react-router-dom";
import AppRoutes from "../../../routes/routeConstants/appRoutes";
import AuthContainer from "../../../store/container/AuthContainer";
import { AuthReducerProps } from "../../../store/reducers/authReducer";
import { ResponsibilityTypes } from "../../../enums/responsebily.enum";
import Stats from "../../../shared/components/Stats";
import { StatsType } from "../../../enums/statsType.enum";
import ContractFilterForm from "../../Services/Contracts/ContractFilterForm";
import {
  ArrowDownOutlined,
  DownOutlined,
  MenuOutlined,
  PlusOutlined,
  UpOutlined,
} from "@ant-design/icons";
import { useMetrics } from "../../../shared/hooks/Metrics/useMetrics";
import PatientService from "../../../services/Patient/patient.service";
import { AppointmentService } from "../../../services/Appointment/appointment.service";
import { Appointment } from "../../../models/Appointment/appointment.model";
import { removeUnderscore } from "../../../shared/utils/removeUnderscore";
import TreatmentSummary from "views/Interactions/TreatmentSummary";
import CustomModal from "shared/components/CustomModal";
import InteractionStepService from "services/InteractionStep/interactionStep.service";
import { Interaction } from "models/Interaction/interaction.model";
import InteractionContainer from "store/container/InteractionContainer";
import { InteractionReducerProps } from "store/reducers/interactionReducer";
import { FilterParams } from "models/FilterParams/filterParams.model";
import { PaginationDetails } from "models/Pagination/pagination.model";

interface VisitsProps extends AuthReducerProps, InteractionReducerProps {
  patients: Patient[];
}

function Visits({
  patients,
  userResponsibilities,
  setContractInteractions,
}: VisitsProps) {
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [appointments, setAppointments] = useState<Appointment[]>([]);
  const params = useParams<{ patientId: string }>();
  const [interactionId, setInterationId] = useState<string>();

  const [filters, setFilters] = useState<FilterParams>(
    Object.assign(new FilterParams(), {
      page: 1,
    })
  );

  const [pagination, setPagination] = useState<PaginationDetails>();
  const [expandedRows, setExpandedRows] = useState<Number[]>([]);

  useEffect(() => {
    setLoading(true);
    AppointmentService.fetchPatientAppointment(
      params?.patientId,
      (appointments, pagination) => {
        setAppointments(appointments);
        setPagination(pagination);
      },
      () => {},
      () => {
        setLoading(false);
      },
      "",
      filters
    );
  }, [filters, params]);

  const columns = [
    {
      title: "Contract Name",
      dataIndex: "contractName",
      key: "contractName",
      render: (contractName: string) => contractName ?? "--",
    },
    {
      title: "Visit Type",
      dataIndex: "visitType",
      key: "visitType",
      render: (visitType: string, record: Appointment) => (
        <div onClick={() => setExpandedRows([Number(record.id)])}>
          {removeUnderscore(visitType)}
        </div>
      ),
    },
    {
      title: "Doctor",
      dataIndex: "doctor",
      key: "doctor",
      render: (_: string, appointment: Appointment) =>
        appointment?.doctorFirstName + " " + appointment?.doctorLastName,
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (date: string) => moment(date).format("MM-DD-YYYY"),
    },
    {
      title: "Time",
      dataIndex: "time",
      key: "time",
      render: (_: string, appointment: Appointment) =>
        moment(appointment?.startTime).format("HH:mm a") +
        " - " +
        moment(appointment?.endTime).format("HH:mm a"),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status: string) => removeUnderscore(status ?? "-"),
    },
  ];

  useEffect(() => {
    if (!interactionId) return;

    InteractionStepService.fetchContractInteractions(
      Number(interactionId),
      (contractInteractions: Interaction[]) => {
        setContractInteractions(contractInteractions);
      },
      () => {},
      () => {}
    );

    return () => {
      setContractInteractions([]);
    };
  }, [interactionId, setContractInteractions]);

  const expandedRowRender = (record: Appointment) => {
    return (
      <>
        <TreatmentSummary
          interactionId={Number(record?.interactionId)}
          isVisit={true}
        />
      </>
    );
  };

  return (
    <div className="patient-table">
      <Table
        dataSource={appointments}
        columns={columns}
        rowKey="id"
        pagination={{
          current: filters?.page,
          onChange: (page) => setFilters({ page }),
          total: pagination?.totalCount,
        }}
        expandable={{
          expandedRowRender,
          defaultExpandedRowKeys: ["0"],
          expandIcon: ({ expanded, onExpand, record }) =>
            record.interactionId ? (
              expanded ? (
                <UpOutlined
                  className="text-primary"
                  onClick={(e) => onExpand(record, e)}
                />
              ) : (
                <DownOutlined onClick={(e) => onExpand(record, e)} />
              )
            ) : null,
        }}
      />
    </div>
  );
}

export default AuthContainer(InteractionContainer(Visits));
