import React, { useEffect, useState } from "react";
import "./patientBasicDetails.scss";
import userPlaceholder from "../../../assets/images/userPlaceholder.png";
import { Button, Row, Col, Popover } from "antd";
import { Patient } from "../../../models/Patient/patient.model";
import PatientService from "../../../services/Patient/patient.service";
import moment from "moment";
import { capitalize } from "lodash";

interface PatientBasicDetailsProps {
  patientProfileId: number;
}

function PatientBasicDetails({ patientProfileId }: PatientBasicDetailsProps) {
  const [patient, setPatient] = useState<Patient>();

  const patientFields = [
    { label: "First Name", value: patient?.firstName },
    { label: "Last Name", value: patient?.lastName },
    {
      label: "DOB / Age",
      value:
        moment(patient?.dob).format("MM/DD/YYYY") +
        " / " +
        moment().diff(patient?.dob, "years"),
    },
    { label: "Gender", value: patient?.gender },
    { label: "Email", value: patient?.email },
    { label: "Mobile", value: patient?.mobileNumber },
    { label: "Street", value: patient?.street },
    { label: "Building", value: patient?.building },
    { label: "City", value: capitalize(patient?.cityName) },
    { label: "Zipcode", value: patient?.zipcode },
  ];
  useEffect(() => {
    PatientService.showPatient(
      patientProfileId.toString(),
      (patient: Patient) => {
        setPatient(patient);
      },
      () => {},
      () => {}
    );
  }, [patientProfileId]);

  return (
    <div className="patient-basic-details">
      <Popover
        className="patient-basic-details__popover"
        overlayClassName="patient-basic-details__popover"
        content={
          <div className="patient-basic-details__popover-content">
            <h4 className="text-secondary"> Patient Basic Details</h4>
            <div className="patient-basic-details__popover-body">
              <div className="patient-basic-details__img-wrapper">
                <img
                  src={userPlaceholder}
                  className="patient-basic-details__profile-pic"
                  alt="User Profile"
                />
              </div>
              <Row gutter={[10, 10]}>
                {patientFields?.map((field, i) => (
                  <Col
                    span={12}
                    className="patient-basic-details__field"
                    key={i}
                  >
                    <div className="patient-basic-details__label">
                      {field?.label}
                    </div>
                    <div className="patient-basic-details__value">
                      {field?.value}
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          </div>
        }
        title={null}
        trigger="click"
        placement="bottomLeft"
      >
        <div className="patient-basic-details__img-wrapper">
          <img
            src={userPlaceholder}
            className="patient-basic-details__profile-pic"
            alt="User Profile"
          />
        </div>
      </Popover>
    </div>
  );
}

export default PatientBasicDetails;
