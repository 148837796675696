import { Button, Drawer, Table, Modal, Row, Col, DatePicker } from "antd";
import React, { Fragment, useEffect, useState } from "react";
import { AppointmentType } from "../../../../../models/AppointmentType/appointmentType.model";
import AppointmentTypeService from "../../../../../services/AppointmentType/appointmentType.service";
import { PlusOutlined } from "@ant-design/icons";
import "./appointmentType.scss";
import AppointmentTypeForm from "./AppointmentTypeForm";
import AppointmentTypeDetails from "./AppointmentTypeDetails";
import { FC } from "react";
import { ResponsibilityTypes } from "../../../../../enums/responsebily.enum";
import FileDetail from "../../../../../shared/components/FileDetail";
import { getFileIcon } from "../../../../../shared/utils/getFileIcon";
import { AppointmentService } from "../../../../../services/Appointment/appointment.service";
import { Appointment } from "../../../../../models/Appointment/appointment.model";
import moment from "moment";
import AppLoader from "../../../../../shared/components/AppLoader";
import { SelectProp } from "../../../../../shared/Types/option";
import { PaginationDetails } from "../../../../../models/Pagination/pagination.model";
import useFilters from "../../../../../shared/hooks/useFilter/useFilters";
import { TablePaginationConfig } from "antd/lib/table";
import { FilterParams } from "../../../../../models/FilterParams/filterParams.model";
import useResponsibilities from "../../../../../shared/hooks/Responsibilities/useResponsibilities";

const { confirm } = Modal;

interface Date {
  startDate?: string;
  endDate?: string;
}

interface ListAppointmentTypeProps {}

const ListAppointmentType: FC<ListAppointmentTypeProps> = () => {
  const { hasAccess } = useResponsibilities();

  const [appointmentTypes, setAppointmentTypes] = useState<AppointmentType[]>(
    []
  );
  const [appointmentId, setAppointmentId] = useState(0);
  const [appointments, setAppointments] = useState<Appointment[]>([]);
  const [appointmentType, setAppointmentType] = useState<AppointmentType>();
  const [date, setDate] = useState<Date>();
  const [saveAppointment, setSaveAppointment] = useState(false);
  const [viewAppointment, setViewAppointment] = useState(false);
  const [completedAppointment, setCompletedAppointment] = useState(false);
  const [loading, setLoading] = useState(false);
  const [appointmentTypesOptions, setAppointmentTypesOptions] = useState<
    AppointmentType[] | SelectProp[]
  >([]);
  const [pagination, setPagination] = useState<PaginationDetails>();
  const { getParams, setParams } = useFilters();
  const HAS_STATUS_APPOINTMENT_TYPE_VIEW_ACCESS = hasAccess(
    ResponsibilityTypes.STATUS_APPOINTMENT_TYPE_VIEW
  );

  const HAS_STATUS_APPOINTMENT_TYPE_CREATE_ACCESS = hasAccess(
    ResponsibilityTypes.STATUS_APPOINTMENT_TYPE_CREATE
  );

  const HAS_STATUS_APPOINTMENT_TYPE_EDIT_ACCESS = hasAccess(
    ResponsibilityTypes.STATUS_APPOINTMENT_TYPE_EDIT
  );

  const HAS_STATUS_APPOINTMENT_TYPE_DELETE_ACCESS = hasAccess(
    ResponsibilityTypes.STATUS_APPOINTMENT_TYPE_DELETE
  );

  const handleFetchAppointmentType = (filter: FilterParams) => {
    setLoading(true);
    AppointmentTypeService.fetchAppointmentTypes(
      (appointmentType, pagination) => {
        setPagination(pagination);
        setAppointmentTypes(appointmentType);
        const options = appointmentType?.map(({ id, nickName }) => ({
          label: nickName ?? "",
          key: id ?? "",
          value: id ?? "",
        }));
        setAppointmentTypesOptions(options);
        return;
      },
      () => {},
      () => {
        setLoading(false);
      },
      filter
    );
  };
  useEffect(() => {
    if (!HAS_STATUS_APPOINTMENT_TYPE_VIEW_ACCESS) return;
    handleFetchAppointmentType(getParams());
  }, []);

  const handleTableChange = (pagination: TablePaginationConfig) => {
    const prevFilter = getParams();
    const updatedFilters = {
      ...prevFilter,
      page: pagination.current ?? 1,
    };
    setParams(updatedFilters);
    handleFetchAppointmentType(updatedFilters);
  };

  const fetchAppointment = (id: number, status?: string) => {
    setLoading(true);
    AppointmentService.fetchAppointment(
      {
        appointment_type_id: id,
        start_date: date?.startDate,
        end_date: date?.endDate,
        status: status,
      },
      (appointments) => {
        setAppointments(appointments);
      },
      () => {},
      () => {
        setLoading(false);
      },
      status
    );
  };

  useEffect(() => {
    if (date?.endDate && date?.endDate) fetchAppointment(appointmentId);
  }, [date]);

  const completedAppointmentcolumns = [
    {
      title: "Patient Name",
      dataIndex: "name",
      key: "name",
      render: (text: string, appointment: Appointment) => (
        <span>
          {appointment?.patientFirstName + " " + appointment?.patientLastName}
        </span>
      ),
    },
    {
      title: "Doctor",
      dataIndex: "doctor",
      key: "doctor",
      render: (text: string, appointment: Appointment) => (
        <span>
          {appointment?.doctorFirstName + " " + appointment?.doctorLastName}
        </span>
      ),
    },
    {
      title: "Contract Against",
      dataIndex: "contractAgainst",
      key: "contractAgainst",
      render: (text: string, appointment: Appointment) => (
        <p>{appointment?.appointmentTypeName}</p>
      ),
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (date: string, appointment: Appointment) => (
        <span>{moment(date).format("MM/DD/YYYY")}</span>
      ),
    },
  ];

  const columns = [
    {
      title: "Appointment Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Nick name",
      dataIndex: "nickName",
      key: "nickName",
    },
    {
      title: "Procedure code",
      dataIndex: "procedureCode",
      key: "procedureCode",
    },
    {
      title: "Duration",
      dataIndex: "duration",
      key: "duration",
      render: (duration: string) => <span>{duration} min</span>,
    },
    {
      title: "Completed Appointments",
      dataIndex: "appointmentCount",
      key: "appointmentCount",
      render: (completed: string, appointment: AppointmentType) => (
        <a
          onClick={(e) => {
            e.stopPropagation();
            handleCompletedAppointment();
            setAppointmentId(appointment?.id ?? 0);

            fetchAppointment(appointment?.id ?? 0, "completed");
          }}
        >
          {appointment?.appointmentCount || "--"}
        </a>
      ),
    },
    {
      title: "Treatment color",
      dataIndex: "treatmentColor",
      key: "treatmentColor",
      render: (hexCode: string) => (
        <div className="treatment-code" style={{ backgroundColor: hexCode }} />
      ),
    },
    {
      title: "",
      dataIndex: "edit",
      key: "edit",
      render: (_: null, record: AppointmentType, index: number) => {
        return (
          <Fragment>
            <i
              className={`icon-edit resource-edit-icon ${
                !HAS_STATUS_APPOINTMENT_TYPE_EDIT_ACCESS && "display-none"
              }`}
              onClick={(event) => {
                if (!HAS_STATUS_APPOINTMENT_TYPE_EDIT_ACCESS) return;
                event.stopPropagation();
                setAppointmentType(record);
                setSaveAppointment(true);
              }}
            />
            <i
              className={`icon-delete resource-delete-icon ${
                !HAS_STATUS_APPOINTMENT_TYPE_DELETE_ACCESS && "display-none"
              }`}
              onClick={(event) => {
                if (!HAS_STATUS_APPOINTMENT_TYPE_DELETE_ACCESS) return;
                event.stopPropagation();
                confirm({
                  content: `Are you sure you want to delete ${record.nickName}`,
                  okText: "Yes",
                  cancelText: "No",
                  onOk: () => {
                    if (record.id) {
                      AppointmentTypeService.deleteAppointmentType(
                        record.id,
                        () => {
                          setAppointmentTypes((appointmentTypes) => {
                            appointmentTypes.splice(index, 1);
                            return [...appointmentTypes];
                          });
                        },
                        () => {},
                        () => {}
                      );
                    }
                  },
                });
              }}
            />
          </Fragment>
        );
      },
    },
  ];

  const handleCloseAppointment = () => {
    setAppointmentType(undefined);
    setSaveAppointment(false);
  };

  const handleSaveAppointmentType = (
    appointmentType: AppointmentType,
    type: "add" | "edit"
  ) => {
    handleFetchAppointmentType(getParams());
    // if (type === "add") {
    //   setAppointmentTypes([...appointmentTypes, appointmentType]);
    //   handleCloseAppointment();
    //   return;
    // }
    // setAppointmentTypes((appointmentTypes) =>
    //   appointmentTypes?.map((existingAppointmentType) => {
    //     if (existingAppointmentType?.id === appointmentType?.id)
    //       return appointmentType;
    //     return existingAppointmentType;
    //   })
    // );
    handleCloseAppointment();
  };

  const handleOnRowClick = (
    appointmentType: AppointmentType,
    index: number | undefined
  ) => {
    return {
      onClick: () => {
        setAppointmentType(appointmentType);
        if (!completedAppointment) setViewAppointment(true);
      },
    };
  };

  const handleCloseViewAppointment = () => {
    setViewAppointment(false);
    setAppointmentType(undefined);
  };
  const handleCompletedAppointment = () => {
    setCompletedAppointment(!completedAppointment);
    setDate({});
  };

  const handleEdit = (appointmentType: AppointmentType) => {
    setViewAppointment(false);
    setAppointmentType(appointmentType);
    setSaveAppointment(true);
  };

  return (
    <div className="appointment-type__container">
      <Drawer
        visible={
          (HAS_STATUS_APPOINTMENT_TYPE_EDIT_ACCESS ||
            HAS_STATUS_APPOINTMENT_TYPE_CREATE_ACCESS) &&
          saveAppointment
        }
        onClose={handleCloseAppointment}
        width="65vw"
        title={
          appointmentType ? "Edit Appointment Type" : "New Appointment Type"
        }
        closable
        destroyOnClose
      >
        <AppointmentTypeForm
          saveAppointmentTypeHandler={handleSaveAppointmentType}
          formValue={appointmentType || new AppointmentType()}
          closeHandler={handleCloseAppointment}
        />
      </Drawer>
      <Drawer
        title="View Appointment Type"
        visible={viewAppointment}
        onClose={handleCloseViewAppointment}
        closable
        width="65vw"
        destroyOnClose
      >
        <AppointmentTypeDetails
          appointmentType={appointmentType || new AppointmentType()}
          editHandler={handleEdit}
          closeHandler={handleCloseViewAppointment}
        />
      </Drawer>
      <Drawer
        // title="Completed Appointments"
        visible={completedAppointment}
        onClose={handleCompletedAppointment}
        closable
        width="65vw"
        destroyOnClose
      >
        <Row className="appointment-type__details">
          <Col span={8}>
            <h4>Completed Appointments</h4>
          </Col>
          <Col span={10}>
            <DatePicker
              format={"DD MMMM YYYY"}
              className="date-picker mr-5"
              picker="date"
              onChange={(value) => {
                setDate({
                  ...date,
                  startDate: moment(value).format("YYYY-MM-DD"),
                });
              }}
            />
            <DatePicker
              format={"DD MMMM YYYY"}
              className="date-picker"
              picker="date"
              onChange={(value) => {
                setDate({
                  ...date,
                  endDate: moment(value).format("YYYY-MM-DD"),
                });
              }}
            />
          </Col>
          {/* <Col span={6} className="appointment-type__icon">
            <img
              src={getFileIcon("pdf")}
              alt="File icon"
              className="file-detail__file-icon mr-5"
            />
            <img
              src={getFileIcon("xls")}
              alt="File icon"
              className="file-detail__file-icon"
            />
          </Col> */}
        </Row>

        {loading ? (
          <AppLoader loading />
        ) : (
          <Table
            dataSource={appointments}
            columns={completedAppointmentcolumns}
          />
        )}
      </Drawer>
      <div className="add-appointment-type__wrapper">
        {HAS_STATUS_APPOINTMENT_TYPE_CREATE_ACCESS && (
          <Button type="primary" onClick={() => setSaveAppointment(true)}>
            <PlusOutlined />
            Appointment Type
          </Button>
        )}
      </div>
      {loading ? (
        <AppLoader loading />
      ) : (
        <Table
          onRow={handleOnRowClick}
          dataSource={appointmentTypes}
          columns={columns}
          onChange={handleTableChange}
          pagination={{
            total: pagination?.totalCount,
            current: pagination?.currentPage ?? 1,
            showTotal: (total: number, range: [number, number]) => (
              <p>
                Showing <b>{` ${range[0]} - ${range[1]}`}</b> of{" "}
                <b>{`${total.toLocaleString()}`}</b>
              </p>
            ),
          }}
        />
      )}
    </div>
  );
};

export default ListAppointmentType;
