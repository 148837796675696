import { Col, Row } from "antd";
import React, { FC } from "react";
import { Support } from "../../../../models/Support/support.model";
import moment from "moment";
import "./issueDetails.scss";

interface IssueDetailsProps {
  support?: Support;
}

const IssueDetails: FC<IssueDetailsProps> = (props) => {
  const { support } = props;

  return (
    <div className="issue-details">
      <Row>
        <Col span={8}>
          <label htmlFor="">Issue name</label>
          <p>{support?.issueName}</p>
        </Col>
        <Col span={16}>
          <label htmlFor="">Issue Type</label>
          <p>{support?.issueType}</p>
        </Col>
        <Col span={8}>
          <label htmlFor="">Priority</label>
          <p>{support?.priority}</p>
        </Col>
        <Col span={16}>
          <label htmlFor="">Practice</label>
          <p>{support?.practiceName}</p>
        </Col>
        <Col span={8}>
          <label htmlFor="">Staff</label>
          <p>{`${support?.staffFirstName} ${support?.staffLastName}`}</p>
        </Col>
        <Col span={16}>
          <label htmlFor="">Patient</label>
          <p>{`${support?.patientFirstName} ${support?.patientLastName}`}</p>
        </Col>
        <Col span={8}>
          <label htmlFor="">Raised Date</label>
          <p>{moment(support?.createdAt).format("MM/DD/YYYY")}</p>
        </Col>
        <Col span={16}>
          <label htmlFor="">Due Date</label>
          <p>{moment(support?.createdAt).format("MM/DD/YYYY")}</p>
        </Col>
        <Col span={24}>
          <label htmlFor="">Issue Description</label>
          <p>{support?.description}</p>
        </Col>
        {/* <Col span={8}>
          <label htmlFor="">Issue Description</label>
          <p>
            {support?.assignedToFirstName} {support?.assignedToLastName}
          </p>
        </Col> */}
      </Row>
    </div>
  );
};

export default IssueDetails;
