
import {PatientSubStatuse} from '../PatientSubStatuse/patientSubStatuse.model';

import {serializable, alias, object, list, primitive} from 'serializr';

export class PatientStatuse { 

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('name', primitive()))
	name?: string;

	@serializable(alias('patient_sub_statuses', list(object(PatientSubStatuse))))
	patientSubStatuses: PatientSubStatuse[] = [];

}

export class PatientStatuses { 
	@serializable(alias('patient_statuses', list(object(PatientStatuse))))
	patientStatuses: PatientStatuse[] = [];
}