import { serializable, alias, object, list, primitive } from "serializr";
import { objectActionEnum } from "../../enums/objectActionType.enum";

import { CorporateLink } from "../CorporateLink/corporateLink.model";
import { DentalSet } from "../DentalSet/dentalSet.model";
import { Procedure } from "../Procedure/procedure.model";

export class InteractionStep {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("corporate_link_id", primitive()))
  corporateLinkId?: number;

  @serializable(alias("note", primitive()))
  note?: string;

  destObjectId?: number;

  @serializable(alias("object_link", object(CorporateLink)))
  objectLink?: CorporateLink;

  @serializable(alias("action_type", primitive()))
  actionType?: string = objectActionEnum.ADD;

  @serializable(alias("procedure_id", primitive()))
  procedureId?: number;

  @serializable(alias("procedure", object(Procedure)))
  procedure?: Procedure;

  @serializable(alias("set_id", primitive()))
  setId?: number;

  @serializable(alias("set", object(DentalSet)))
  set?: DentalSet;

  @serializable(alias("tooth_position_id", primitive()))
  toothPositionId?: number;

  @serializable(alias("tooth_position_name", primitive()))
  toothPositionName?: string;

  @serializable(alias("tooth_object_id", primitive()))
  toothObjectId?: string;

  @serializable(alias("tooth_object_name", primitive()))
  toothObjectName?: string;

  @serializable(alias("tooth_object_url", primitive()))
  toothObjectUrl?: string;

  @serializable(alias("order", primitive()))
  order?: number;
}
