import React, { memo, useEffect } from "react";
import "./interactionViewer.scss";
import { ActionManager } from "@babylonjs/core";
import BabylonContainer from "../../../store/container/BabylonContainer";
import InteractionContainer from "../../../store/container/InteractionContainer";
import { InteractionReducerProps } from "../../../store/reducers/interactionReducer";
import { BabylonReducerProps } from "../../../store/reducers/babylonReducer";
import { CorporateObject } from "../../../models/CorporateObject/corporateObject.model";
import CorporateObjectService from "../../../services/CorporateObject/corporateObject.service";
import InteractionObjectActions from "../InteractionObjectActions";
import { InteractionStep } from "../../../models/InteractionStep/interactionStep.model";
import CustomBabylonScene from "../../../shared/components/CustomBabylonScene";
import { MeshType } from "../../../enums/meshType.enum";
import { Popover } from "antd";
import { MeshTriggerHandler } from "../../../shared/Types/meshActions.type";
import { integrateInteractions } from "../../../shared/utils/integrateTreatmentSteps";
import {
  handleTeethLeftClick,
  handleTeethRightClick,
} from "../../../shared/utils/babylonUtils";
import useToggle from "../../../shared/hooks/useToggle/useToggler";
import useResponsibilities from "../../../shared/hooks/Responsibilities/useResponsibilities";
import { ResponsibilityTypes } from "../../../enums/responsebily.enum";

interface InteractionViewerProps
  extends InteractionReducerProps,
    BabylonReducerProps {
  disableFunc?: boolean;
  setOpenConflict: (open: boolean) => void;
  createInteractionStep: (step: InteractionStep, complete?: () => void) => void;
  meshType: MeshType;
}

function InteractionViewer({
  contractInteractions,
  caps,
  scene,
  meshType,
  sourceObject,
  corporateObjects,
  setCorporateObjects,
  setSourceObject,
  setHighlightLayer,
  activeInteractionId,
  setShowPopover,
  showPopover,
  popoverPosition,
  setPopoverPosition,
  disableFunc,
  resetBabylon,
  setOpenConflict,
  createInteractionStep,
  setMeshLoading,
  corporateObjectsLoading,
  capsLoading,
}: InteractionViewerProps) {
  const handleAddStepSuccess = () => setShowPopover(false);

  const {
    toggle: objectNotePopover,
    updateToggle: setObjectNotePopover,
  } = useToggle({});

  const { hasAccess } = useResponsibilities();

  const HAS_INTERACTION_EDIT_ACCESS = hasAccess(
    ResponsibilityTypes.INTERACTION_EDIT
  );

  const isLoadMeshToScene =
    Boolean(meshType) &&
    Boolean(activeInteractionId) &&
    Boolean(caps?.length) &&
    Boolean(corporateObjects?.length) &&
    Boolean(contractInteractions?.length) &&
    Boolean(scene) &&
    !capsLoading &&
    !corporateObjectsLoading;

  const handleMeshLeftClick: MeshTriggerHandler = (props) =>
    handleTeethLeftClick(props, corporateObjects, setSourceObject);

  const handleMeshRightClick: MeshTriggerHandler = (props) => {
    if (!HAS_INTERACTION_EDIT_ACCESS) return;

    handleTeethRightClick(
      props,
      corporateObjects,
      setSourceObject,
      setShowPopover,
      setPopoverPosition
    );
  };

  const handleLoadedMesh = async () => {
    if (!isLoadMeshToScene) return;

    try {
      setMeshLoading?.(true);
      await integrateInteractions({
        activeInteractionId,
        caps,
        contractInteractions,
        corporateObjects,
        isMeshPickable: true,
        scene,
        ...(HAS_INTERACTION_EDIT_ACCESS && {
          setPopoverPosition,
          setSourceObject,
          setShowPopover: setObjectNotePopover,
        }),
      });
    } catch (ex) {
    } finally {
      setMeshLoading?.(false);
    }
  };

  useEffect(() => {
    handleLoadedMesh();
  }, [contractInteractions]);

  useEffect(() => {
    setMeshLoading(true);
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  return (
    <div className="interaction-viewer">
      <Popover
        destroyTooltipOnHide
        overlayClassName="interaction-viewer__popover"
        content={
          (showPopover || objectNotePopover) && (
            <InteractionObjectActions
              objectNotePopover={objectNotePopover}
              setObjectNotePopover={setObjectNotePopover}
              setOpenConflict={setOpenConflict}
              createInteractionStep={createInteractionStep}
              onSuccess={handleAddStepSuccess}
            />
          )
        }
        visible={showPopover || objectNotePopover}
        arrowContent={null}
        overlayInnerStyle={{
          position: "fixed",
          top: `calc(${popoverPosition.y}px + 10rem)`,
          left: popoverPosition.x + "px",
        }}
      />
      <CustomBabylonScene
        actions={{
          [ActionManager.OnLeftPickTrigger]: handleMeshLeftClick,
          [ActionManager.OnRightPickTrigger]: handleMeshRightClick,
        }}
        loadMeshToScene={isLoadMeshToScene}
        meshType={meshType}
        mouthSliderVisible
        onMeshLoad={handleLoadedMesh}
      />
    </div>
  );
}

export default memo(BabylonContainer(InteractionContainer(InteractionViewer)));
