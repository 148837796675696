import React, { FC } from "react";
import Lottie from "react-lottie";
import Loader from "../../../assets/loader/aligner-loader.json";
import "./babylonLoader.scss";

interface BabylonLoaderProps {
  loader: boolean;
}

const BabylonLoader: FC<BabylonLoaderProps> = (props) => {
  const {} = props;

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: Loader,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div onClickCapture={(e) => e.stopPropagation()} className="babylon-loader">
      <Lottie width={200} height={200} options={defaultOptions} />
    </div>
  );
};

export default BabylonLoader;
