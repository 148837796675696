import * as Yup from "yup";
import { validateNumber } from "../../../shared/utils/validatorUtils";

export const interactionSetFormValidation = Yup.object().shape({
  setId: validateNumber("SetId is required"),
});

export const interactionLinkFormValidation = Yup.object().shape({
  destObjectId: validateNumber("Destination Object is required"),
  corporateLinkId: validateNumber("Corporate Link is required"),
});
