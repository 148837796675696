import { Button, Col, Row } from "antd";
import Form from "antd/lib/form/Form";
import { Formik, FormikProps } from "formik";
import React, { FC, useRef } from "react";
import { deserialize } from "serializr";
import { InteractionSubStatuse } from "../../../../../../../models/InteractionSubStatuse/interactionSubStatuse.model";
import InteractionStatusService from "../../../../../../../services/InteractionStatus/interactionStatus.service";
import InputField from "../../../../../../../shared/components/InputField";
import { userFormValidation } from "../../../../../../../shared/components/UserForm/userFormValidation";
import "./subStatusForm.scss";
const initialValue = {
	name: "",
};

interface SubStatusFormProps {
	onSubmitHandler: (value: InteractionSubStatuse) => void;
	closeHandler: () => void;
	length: number;
	interactionStatusId?: number;
	formValue?: InteractionSubStatuse;
}

const SubStatusForm: FC<SubStatusFormProps> = ({
	closeHandler,
	onSubmitHandler,
	length,
	interactionStatusId,
	formValue,
}) => {
	const ref: any = useRef(null);
	const handleSubmit = (values: InteractionSubStatuse) => {
		const subStatus = Object.assign(new InteractionSubStatuse(), {
			...values,
			order: length,
		});
		InteractionStatusService[
			formValue ? "updateInteractionStatus" : "createInteractionSubStatus"
		](
			interactionStatusId as number,
			subStatus,
			(subStatus) => {
				ref.current?.resetForm();
				onSubmitHandler(subStatus);
			},
			() => {},
			() => {}
		);
	};
	return (
		<div className="sub-status-form__container">
			<Formik
				initialValues={formValue || new InteractionSubStatuse()}
				onSubmit={handleSubmit}
				validationSchema={userFormValidation}
				innerRef={ref}
			>
				{({ values, resetForm, isValid, dirty }) => (
					<Form>
						<InputField
							title="Sub Status"
							type="text"
							name="name"
						/>
						<InputField
							title="Color"
							type="color"
							name="colorCode"
							value={values?.colorCode}
						/>
						<Row>
							<Col span={12} />
							<Col span={6}>
								<Button
									type="default"
									onClick={() => {
										closeHandler();
										resetForm();
									}}
								>
									Cancel
								</Button>
							</Col>
							<Col span={6}>
								<Button
									type="primary"
									disabled={isValid || !dirty}
									onClick={() => {
										handleSubmit(values);
									}}
								>
									Save
								</Button>
							</Col>
						</Row>
					</Form>
				)}
			</Formik>
		</div>
	);
};

export default SubStatusForm;
