import * as Yup from "yup";

export const validation = Yup.object().shape({
  title: Yup.string()
    .required("Title is required!")
    .max(30, "Appointment title name must be at most 30 characters"),
  nickName: Yup.string()
    .required("Nick name is required!")
    .max(15, "Nick name must be at most 15 characters"),

  duration: Yup.number().required("Duration is required!"),
  description: Yup.string()
    .required("Description is required!")
    .max(100, "Description must be at most 100 characters"),
});
