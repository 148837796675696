import {serializable, alias, primitive} from 'serializr';

export class UserCardDetails {

    @serializable(alias('card_number', primitive()))
    cardNumber?: string;

    @serializable(alias('card_type', primitive()))
    cardType?: string;

    @serializable(alias('card_holder_name', primitive()))
    cardHolderName?: string;

    @serializable(alias('expiry_date', primitive()))
    expiryDate?: string;

}
