import { deserialize, serialize } from "serializr";
import { NotificationTypes } from "../../enums/notificationTypes";
import axiosInstance from "../../interceptor/axiosInstance";
import { Appointment } from "../../models/Appointment/appointment.model";
import { ContractVisit } from "../../models/ContractVisit/contractVisit.model";
import { Interaction } from "../../models/Interaction/interaction.model";
import ApiRoutes from "../../routes/routeConstants/apiRoutes";
import Notification from "../../shared/components/Notification";
import { TreatmentPlanTemplate } from "../../models/TreatmentPlanTemplate/treatmentPlanTemplate.model";

const treatmentPlanMock = {
  treatment_plan: {
    id: 1,
    treatment_plan_visits: [
      {
        id: 1,
        self_care_object_category_name: null,
        treatment_plan_statement_notes: [
          {
            id: 1,
            statement_template_category_id: null,
            statement_template_category_name: null,
            sub_category: null,
            description: null,
            statement_template_id: 3,
            treatment_plan_visit_id: 1,
          },
        ],
        treatment_plan_steps: [
          {
            id: 1,
            note: null,
            object_link: null,
            set: {
              set_steps: [
                {
                  id: 97,
                  corporate_link_id: 2,
                  action_type: "add",
                  order: 1,
                  note: null,
                  object_link: {
                    src_attachment_url:
                      "https://aligner4d.blob.core.windows.net/practicemanagement/CentralIncisor11_20210429094540306.babylon?sv=2020-04-08&st=2021-07-21T13%3A14%3A55Z&se=2021-07-28T13%3A14%3A55Z&sr=b&sp=r&sig=xpabkeRCeSwdm%2BtGhcc3iPFEYgWNFgfkcI5i0jy4ErA%3D",
                    dest_attachment_url:
                      "https://aligner4d.blob.core.windows.net/practicemanagement/Cap11_20210429094650408.babylon?sv=2020-04-08&st=2021-07-21T13%3A14%3A55Z&se=2021-07-28T13%3A14%3A55Z&sr=b&sp=r&sig=WJf73yMBkSjZjVGnOUem9JB%2FJSX0LVovSPrtdQOUF0g%3D",
                    id: 2,
                    src_locator_name: "CentralIncisor11_Center",
                    src_object_id: 6,
                    src_object_name: "CentralIncisor11",
                    dest_locator_name: "Cap11_Center",
                    dest_object_id: 7,
                    dest_object_name: "Cap11",
                    src_locator_id: 95,
                    dest_locator_id: 96,
                  },
                  tooth_position_id: 1,
                  tooth_position: {
                    id: 1,
                    universal_numbering_system_name: "8",
                    iso_numbering_system_name: "11",
                    palmer_notation_system_name: "1?",
                    default_object_name: "CentralIncisor11",
                  },
                  connector: null,
                },
                {
                  id: 98,
                  corporate_link_id: 19,
                  action_type: "add",
                  order: 1,
                  note: null,
                  object_link: {
                    src_attachment_url:
                      "https://aligner4d.blob.core.windows.net/practicemanagement/CentralIncisor21_20210429143231017.babylon?sv=2020-04-08&st=2021-07-21T13%3A14%3A55Z&se=2021-07-28T13%3A14%3A55Z&sr=b&sp=r&sig=RGazR3mGX2VHt8WDWqZtYa6COw3UfQzoqDllta4njt8%3D",
                    dest_attachment_url:
                      "https://aligner4d.blob.core.windows.net/practicemanagement/Cap21_20210504161016212.babylon?sv=2020-04-08&st=2021-07-21T13%3A14%3A55Z&se=2021-07-28T13%3A14%3A55Z&sr=b&sp=r&sig=2VQrt4sOi%2B5K3dq%2BQwnOcbhfPnlfFaE6eGREKTyB81I%3D",
                    id: 13,
                    src_locator_name: "CentralIncisor21_Center",
                    src_object_id: 19,
                    src_object_name: "CentralIncisor21",
                    dest_locator_name: "Cap21_Center",
                    dest_object_id: 17,
                    dest_object_name: "Cap21",
                    src_locator_id: 199,
                    dest_locator_id: 165,
                  },
                  tooth_position_id: 9,
                  tooth_position: {
                    id: 9,
                    universal_numbering_system_name: "9",
                    iso_numbering_system_name: "21",
                    palmer_notation_system_name: "?1",
                    default_object_name: "CentralIncisor21",
                  },
                  connector: null,
                },
              ],
              id: 29,
              no_of_attachments: 2,
              name: "Ranjsetnew",
              type: "aligner",
              description: "asdasd",
              status: "active",
              practice_id: 1,
              is_deleted: null,
            },
            procedure: null,
            tooth_position_name: "45",
            dental_object: null,
            corporate_link_id: 1,
            action_type: "link_add",
            procedure_id: null,
            set_id: 11,
            tooth_position_id: 1,
            tooth_object_id: null,
            connector_id: null,
            connector: null,
            order: 1,
          },
          {
            id: 3,
            note: null,
            object_link: null,
            set: null,
            procedure: {
              procedure_steps: [
                {
                  id: 31,
                  setid: null,
                  corporate_link_id: 6,
                  action_type: "add",
                  order: 1,
                  note: "Add spring26 on buccal of 26",
                  tooth_position_id: null,
                  object_link: {
                    src_attachment_url:
                      "https://aligner4d.blob.core.windows.net/practicemanagement/CentralIncisor11_20210429094540306.babylon?sv=2020-04-08&st=2021-07-21T13%3A29%3A28Z&se=2021-07-28T13%3A29%3A28Z&sr=b&sp=r&sig=z0fxvchW2kHJWwi3LXUS85N53jk%2FDOJnpAIJ%2FPWvdGM%3D",
                    dest_attachment_url:
                      "https://aligner4d.blob.core.windows.net/practicemanagement/Cap11_20210429094650408.babylon?sv=2020-04-08&st=2021-07-21T13%3A29%3A28Z&se=2021-07-28T13%3A29%3A28Z&sr=b&sp=r&sig=Tllygd0EcFqRzfVzjIcapoi%2F9Vsi9rRUjYZiUD5mwVI%3D",
                    id: 3,
                    src_locator_name: "CentralIncisor11_Center",
                    src_object_id: 6,
                    src_object_name: "CentralIncisor11",
                    dest_locator_name: "Cap11_Center",
                    dest_object_id: 7,
                    dest_object_name: "Cap11",
                    src_locator_id: 95,
                    dest_locator_id: 96,
                  },
                  set: null,
                  tooth_position: null,
                  connector: null,
                },
              ],
              id: 7,
              no_of_objects: 1,
              name: "Procedure",
              code: "P124124",
              description: "Descr",
              status: "active",
              practice_id: 1,
              is_deleted: null,
            },
            tooth_position_name: null,
            dental_object: null,
            corporate_link_id: null,
            action_type: "elastic_add",
            procedure_id: null,
            set_id: null,
            tooth_position_id: null,
            tooth_object_id: null,
            connector: {
              id: 1,
              object_type: "elastic",
              diameter: null,
              color: "#FFFFFF",
              connector_steps: [
                {
                  id: 1,
                  connector_id: 1,
                  object_locator_id: 1,
                  tooth_position_id: 1,
                  tooth_position_name: "23",
                  order: 1,
                },
                {
                  id: 2,
                  connector_id: 1,
                  object_locator_id: 1,
                  tooth_position_id: 2,
                  tooth_position_name: "33",
                  order: 2,
                },
              ],
            },
            order: 2,
          },
        ],
        treatment_plan_id: 1,
        order: 1,
        duration_in_weeks: 5,
        self_care_object_category_id: null,
        self_care_frequency: null,
        self_care_frequency_unit: null,
        description: "Physicalappointmentvisitdescription",
        visit_type: "physical",
        is_current_visit: true,
      },
      {
        id: 2,
        self_care_object_category_name: "Brackets",
        treatment_plan_statement_notes: [],
        treatment_plan_steps: [],
        treatment_plan_id: 1,
        order: 1,
        duration_in_weeks: null,
        self_care_object_category_id: 1,
        self_care_frequency: 3,
        self_care_frequency_unit: "weeks",
        description: "selfcareappointmentvisitdescription",
        visit_type: "selfcare",
        is_current_visit: null,
      },
    ],
    name: "newtreatmentplanupdated",
    practice_id: 2,
    duration_in_months: 14,
    description: "Buttonplantemplatedescription",
    plan_type: "aligner",
  },
};
export class InteractionService {
  static fetchAllInteractions(
    interactionId: string,
    onSuccess: (interaction: ContractVisit[]) => void,
    onError: (error: Error) => void,
    onFinal: () => void
  ) {
    const URL = ApiRoutes.VISITS_LIST_CONTRACT.replace(
      ":interactionId",
      interactionId
    );
    return axiosInstance
      .get(URL)
      .then((response) => {
        const interactions = deserialize(
          ContractVisit,
          response?.data["interactions"] as any[]
        );
        onSuccess(interactions);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(onFinal);
  }

  static fetchInteractionDetails(
    interactionId: string,
    onSuccess: (interaction: Interaction) => void,
    onError: () => void,
    onFinal: () => void
  ) {
    const URL = ApiRoutes.GET_INTERACTION_DETAILS.replace(":id", interactionId);
    return axiosInstance
      .get(URL)
      .then((response) => {
        try {
          const interaction = deserialize(
            Interaction,
            response.data["interaction"]
          );
          onSuccess(interaction);
        } catch (error) {}
      })
      .catch(() => {
        onError();
      })
      .finally(onFinal);
  }

  static createInteractions(
    interaction: Appointment,
    onSuccess: (interaction: Appointment) => void,
    onError: () => void,
    onFinal: () => void
  ) {
    const InteractionJSON = {
      interaction: serialize(interaction),
    };
    return axiosInstance
      .post(ApiRoutes.INTERACTION, InteractionJSON)
      .then((response) => {
        Notification({
          message: "Interaction created",
          type: NotificationTypes.SUCCESS,
        });
        onSuccess(interaction);
      })
      .catch(() => {
        onError();
      })
      .finally(onFinal);
  }

  static startInteraction(
    id: string,
    interaction: Interaction,
    onSuccess: (interaction: Interaction) => void,
    onError: () => void,
    onFinal: () => void
  ) {
    const InteractionJSON = {
      interaction: serialize(interaction),
    };
    const URL = `${ApiRoutes.INTERACTION}/${id}/start`;
    return axiosInstance
      .put(URL, InteractionJSON)
      .then((response) => {
        const interaction = deserialize(
          Interaction,
          response.data["interaction"]
        );
        Notification({
          message: "Interaction started",
          type: NotificationTypes.SUCCESS,
        });
        onSuccess(interaction);
      })
      .catch((error) => {
        onError();
      })
      .finally(onFinal);
  }

  static rejectInteraction(
    interaction: Interaction,
    onSuccess: () => void,
    onError: () => void,
    onFinal: () => void
  ) {
    const InteractionJSON = {
      interaction: serialize(interaction),
    };
    const URl = `${ApiRoutes.INTERACTION}/${interaction.id}/reject`;
    return axiosInstance
      .put(ApiRoutes.INTERACTION, InteractionJSON)
      .then((response) => {
        Notification({
          message: "Interaction Rejected",
          type: NotificationTypes.SUCCESS,
        });
        onSuccess();
      })
      .catch((error) => {
        onError();
      })
      .finally(onFinal);
  }

  static updateInteraction(
    interaction: Interaction,
    onSuccess: (interaction: Interaction) => void,
    onError: () => void,
    onFinal: () => void
  ) {
    const interactionJSON = {
      interaction: serialize(interaction),
    };
    const API_URL = `${ApiRoutes.INTERACTION}/${interaction.id}`;
    return axiosInstance
      .put(API_URL, interactionJSON)
      .then((response) => {
        const interaction = deserialize(
          Interaction,
          response.data["interaction"]
        );
        Notification({
          message: "Interaction updated",
          type: NotificationTypes.SUCCESS,
        });
        onSuccess(interaction);
      })
      .catch((error) => {
        onError();
      })
      .finally(onFinal);
  }

  static showInteraction(
    interactionId: number,
    onSuccess: (interaction: Interaction) => void,
    onError: () => void,
    onFinal: () => void
  ) {
    const API_URL = `${ApiRoutes.INTERACTION}/${interactionId}`;
    return axiosInstance
      .get(API_URL)
      .then((response) => {
        const interaction = deserialize(
          Interaction,
          response.data["interaction"]
        );
        onSuccess(interaction);
      })
      .catch((error) => {
        onError();
      })
      .finally(onFinal);
  }

  static stopInteraction(
    id: string,
    isChecked: boolean,
    onSuccess: (response: object) => void,
    onError: () => void,
    onFinal: () => void
  ) {
    const stopInteractionPayload = {
      interaction: {
        is_pending_for_review: isChecked,
      },
    };
    return axiosInstance
      .put(
        ApiRoutes.STOP_INTERACTION.replace(":id", id),
        stopInteractionPayload
      )
      .then((response) => {
        onSuccess(response.data);
      })
      .catch((error) => {
        onError();
      })
      .finally(onFinal);
  }

  static fetchAppointmentInteractionDetails(
    id: string,
    onSuccess: (treatmentPlanTemplate: TreatmentPlanTemplate) => void,
    onError: (error: Error) => void,
    onFinal: () => void
  ) {
    return axiosInstance
      .get(ApiRoutes.INTERACTION_TREATMENT_PLAN_DETAILS.replace(":id", id))
      .then((response) => {
        const treatmentPlan = deserialize(
          TreatmentPlanTemplate,
          response.data["treatment_plan"]
        );
        // const treatmentPlan = deserialize(TreatmentPlanTemplate, treatmentPlanMock["treatment_plan"])
        onSuccess(treatmentPlan);
      })
      .catch(onError)
      .finally(onFinal);
  }
}
