import ApiRoutes from "../../routes/routeConstants/apiRoutes";
import axiosInstance from "../../interceptor/axiosInstance";
import { CorporateObject } from "../../models/CorporateObject/corporateObject.model";
import { deserialize, serialize } from "serializr";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import { CorporateObjectFilterParams } from "../../models/CorporateObjectFilterParams/corporateObjectFilterParams.model";
import { DentalObject } from "../../models/DentalObject/dentalObject.model";
import { LocalStorageKeys } from "../../enums/localStorageKeys.enum";
import { FilterParams } from "../../models/FilterParams/filterParams.model";
import { PaginationDetails } from "../../models/Pagination/pagination.model";
import { UrlMeta } from "../../models/UrlMeta/UrlMeta.model";

export default class CorporateObjectService {
  static fetchCorporateObjects(
    filter: FilterParams,
    objectType: string = "",
    onSuccess: Function,
    onError: Function,
    onFinal: () => void,
    params?: {
      dental_object_id?: number | number[];
      model_ids?: number[];
    }
  ) {
    const para = serialize(FilterParams, filter);
    let dentalObjectIds: number[] = [];
    if (params?.dental_object_id) {
      dentalObjectIds = Array.isArray(params?.dental_object_id)
        ? params?.dental_object_id
        : params?.dental_object_id
        ? [params?.dental_object_id]
        : [];
      params.dental_object_id = undefined;
    }
    axiosInstance
      .get(ApiRoutes.CORPORATE_OBJECTS, {
        params: {
          object_types: objectType === "" ? [] : [objectType],
          dental_object_ids: dentalObjectIds,
          ...params,
          ...para,
        },
      })
      .then((response) => {
        const corporateObjects = deserialize(
          CorporateObject,
          response.data["corporate_objects"]
        );
        const urlMeta = deserialize(UrlMeta, response.data?.["url_meta"]);

        const pagination = deserialize(
          PaginationDetails,
          response.data["meta"]
        );
        onSuccess(corporateObjects, pagination, urlMeta);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static fetchDentalObjects(
    filterParams: CorporateObjectFilterParams,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const params = serialize(filterParams);
    axiosInstance
      .get(ApiRoutes.DENTAL_OBJECTS, {
        params,
      })
      .then((response) => {
        const dentalObjects = deserialize(
          DentalObject,
          response.data["dental_objects"]
        );
        onSuccess(dentalObjects);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static showCorporateObject(
    corporateObjectId: number,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.CORPORATE_OBJECTS + "/" + corporateObjectId;
    axiosInstance
      .get(API_URL)
      .then((response) => {
        const corporateObject = deserialize(
          CorporateObject,
          response.data["corporate_object"]
        );
        onSuccess(corporateObject);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static importDentalObjects(
    dentalObjectIds: number[],
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const corporateObjectJSON = {
      dental_object_ids: dentalObjectIds,
    };
    axiosInstance
      .post(ApiRoutes.IMPORT_DENTAL_OBJECTS, corporateObjectJSON)
      .then((response) => {
        Notification({
          message: "Objects imported!",
          type: NotificationTypes.SUCCESS,
        });
        onSuccess();
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static updateCorporateObject(
    corporateObject: CorporateObject,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.CORPORATE_OBJECTS + "/" + corporateObject.id;
    const corporateObjectJSON = {
      corporate_object: serialize(corporateObject),
    };
    axiosInstance
      .put(API_URL, corporateObjectJSON)
      .then((response) => {
        Notification({
          message: "Object updated!",
          type: NotificationTypes.SUCCESS,
        });
        const corporateObject = deserialize(
          CorporateObject,
          response.data["corporate_object"]
        );
        onSuccess(corporateObject);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static deleteCorporateObject(
    corporateObjectId: string,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.CORPORATE_OBJECTS + "/" + corporateObjectId;
    axiosInstance
      .delete(API_URL)
      .then((response) => {
        onSuccess();
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static fetchCorporateObjectBabylonFiles = async (
    corporateObjects: CorporateObject[],
    onSuccess: (res: File[]) => void,
    onError?: (ex: any) => void,
    onFinal?: () => void
  ) => {
    try {
      const promises = corporateObjects.map((object) =>
        axiosInstance.get(object?.attachmentUrl || "")
      );
      const filesResponse = await Promise.all(promises);
      const files: File[] = filesResponse.map((file) => file?.data);
      onSuccess(files);
    } catch (ex) {
      onError?.(ex);
    } finally {
      onFinal?.();
    }
  };
}
