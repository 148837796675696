import { serializable, alias, object, list, primitive } from "serializr";

export class Metrics {
  /* issue Metrics */
  @serializable(alias("total_issues", primitive()))
  totalIssues?: number;

  @serializable(alias("resolved_issues", primitive()))
  resolvedIssues?: number;

  @serializable(alias("pending_issues", primitive()))
  pendingIssues?: number;

  /* Patient Metrics */
  @serializable(alias("total_no_of_patients", primitive()))
  totalNoOfPatients?: number;

  @serializable(alias("active_patients", primitive()))
  activePatients?: number;

  @serializable(alias("patients_without_future_appointments", primitive()))
  patientsWithoutFutureAppointments?: number;

  @serializable(alias("inactive_patients", primitive()))
  inactivePatients?: number;
  /* Custom Form Metrics */
  @serializable(alias("no_of_request", primitive()))
  noOfRequest?: number;

  @serializable(alias("no_of_submissions", primitive()))
  noOfSubmissions?: number;
  /* Practices Metrics */

  @serializable(alias("total_no_of_practices", primitive()))
  totalNoOfPractices?: number;

  @serializable(alias("active_practices", primitive()))
  activePractices?: number;

  @serializable(alias("inactive_practices", primitive()))
  inactivePractices?: number;

  /* Staff Metrics */

  @serializable(alias("total_staffs", primitive()))
  totalStaffs?: number;

  @serializable(alias("no_of_doctors", primitive()))
  noOfDoctors?: number;

  @serializable(alias("no_of_assistant", primitive()))
  noOfAssistant?: number;

  /* Treatment Plans Metrics */
  @serializable(alias("total_treatment_plans", primitive()))
  totalTreatmentPlans?: number;
  @serializable(alias("active_treatment_plans", primitive()))
  activeTreatmentPlans?: number;
  @serializable(alias("inactive_treatment_plans", primitive()))
  inactiveTreatmentPlans?: number;

  /* Sets Metrics */

  @serializable(alias("total_sets", primitive()))
  totalSets?: number;
  @serializable(alias("active_sets", primitive()))
  activeSets?: number;
  @serializable(alias("inactive_sets", primitive()))
  inactiveSets?: number;

  /* Appointment Metrics */

  @serializable(alias("total_appoinments", primitive()))
  totalAppoinments?: number;
  @serializable(alias("completed_appointments", primitive()))
  completedAppointments?: number;
  @serializable(alias("upcoming_appointments", primitive()))
  upcomingAppointments?: number;

  /* Dashboard Corporate Metrics */

  @serializable(alias("approved_corporates", primitive()))
  approvedCorporates?: number;
  @serializable(alias("pending_corporates", primitive()))
  pendingCorporates?: number;
  @serializable(alias("rejected_corporates", primitive()))
  rejectedCorporates?: number;

  /* Procedures Metrics */
  @serializable(alias("total_procedures", primitive()))
  totalProcedures?: number;
  @serializable(alias("active_procedures", primitive()))
  activeProcedures?: number;
  @serializable(alias("inactive_procedures", primitive()))
  inactiveProcedures?: number;

  /* Resources/ Chair Metrics */
  @serializable(alias("total_rooms", primitive()))
  totalRooms?: number;
  @serializable(alias("total_chairs", primitive()))
  totalChairs?: number;
  @serializable(alias("total_scan_facility", primitive()))
  totalScanFacility?: number;

  /* contract Metrics */
  @serializable(alias("total_no_of_active_contracts", primitive()))
  totalNoOfActiveContracts?: number;
  @serializable(alias("total_no_of_completed_contracts", primitive()))
  totalNoOfCompletedContracts?: number;
  @serializable(alias("total_no_of_contract", primitive()))
  totalNoOfContracts?: number;
  @serializable(alias("total_value", primitive()))
  totalValue?: number;
  @serializable(alias("total_value_of_active_contracts", primitive()))
  totalValueOfActiveContracts?: number;
  @serializable(alias("total_value_of_completed_contracts", primitive()))
  totalValueOfCompletedContracts?: number;
}
