import { Button } from "antd";
import Form from "antd/lib/form/Form";
import { Formik } from "formik";
import React, { FC } from "react";
import { Interaction } from "../../../../models/Interaction/interaction.model";
import { WorkItem } from "../../../../models/WorkItem/workItem.model";
import { WorkItemService } from "../../../../services/WorkItem/workItem.service";
import InputField from "../../../../shared/components/InputField";
import "../StartInteractionForm/startInteractionForm.scss";
import { RejectInteractionFormValidation } from "./RejectInterctionFormValidation";

interface RejectInteractionFormProps {
	workItem: WorkItem;
	closeHandler: () => void;
}
const RejectInteractionForm: FC<RejectInteractionFormProps> = ({
	closeHandler,
	workItem,
}) => {
	const handleSubmit = (values: Interaction) => {
		WorkItemService.rejectWorkItems(
			values.notes as string,
			workItem,
			() => {
				closeHandler();
			},
			() => {},
			() => {}
		);
	};
	return (
		<div className="start-interaction-form__container">
			<Formik initialValues={new Interaction()} validationSchema={RejectInteractionFormValidation} onSubmit={handleSubmit}>
				{({ handleSubmit }) => (
					<Form>
						<div className="start-interaction-form__wrapper">
							<InputField type="textarea" name="notes" rows={8} />
						</div>
						<div className="start-interaction-form__control">
							<Button type="text" onClick={closeHandler}>
								Cancel
							</Button>
							<Button
								type="primary"
								onClick={() => handleSubmit()}
							>
								Reject Interaction
							</Button>
						</div>
					</Form>
				)}
			</Formik>
		</div>
	);
};

export default RejectInteractionForm;
