import React, { useEffect, useState } from "react";
import "./treatmentSummaryStatementNotes.scss";
import { StatementNote } from "../../../../models/StatementNote/statementNote.model";
import StatementNotesService from "../../../../services/StatementNotes/statementNotes.service";
import { StatementTemplate } from "../../../../models/Statement/statement.model";
import AppLoader from "../../../../shared/components/AppLoader";

interface TreatmentSummaryStatementNotesProps {
  interactionId: number;
}

function TreatmentSummaryStatementNotes({
  interactionId,
}: TreatmentSummaryStatementNotesProps) {
  const [loading, setLoading] = useState(false);

  const [statementNotes, setStatementNotes] = useState<StatementNote[]>([]);

  useEffect(() => {
    setLoading(true);
    StatementNotesService.fetchStatementNotes(
      interactionId,
      (statementNotes: StatementTemplate[]) => {
        setStatementNotes(statementNotes);
      },
      () => {},
      () => {
        setLoading(false);
      }
    );
  }, [interactionId]);

  return (
    <div className="treatment-summary-statement-notes">
      {loading ? (
        <AppLoader loading={loading} />
      ) : (
        <div>
          {statementNotes?.map((statementNote) => (
            <div
              className="treatment-summary-statement-notes__item"
              key={statementNote?.id}
            >
              {statementNote?.description}
            </div>
          ))}
          {statementNotes?.length === 0 && <div>No statement notes found</div>}
        </div>
      )}
    </div>
  );
}

export default TreatmentSummaryStatementNotes;
