import { deserialize, serialize } from "serializr";
import { NotificationTypes } from "../../enums/notificationTypes";
import axiosInstance from "../../interceptor/axiosInstance";
import { Role } from "../../models/Role/role.model";
import ApiRoutes from "../../routes/routeConstants/apiRoutes";
import Notification from "../../shared/components/Notification";

export class RoleService {
  static async getRoles(
    onSuccess: (roles: Role[]) => void,
    onFinal: () => void
  ) {
    try {
      const { data } = await axiosInstance.get(ApiRoutes.ROLES);
      const roles = deserialize(Role, data?.roles as any[]);
      onSuccess(roles);
    } catch (error) {
    } finally {
      onFinal();
    }
  }

  static async createRole(
    role: Role,
    onSuccess: (role: Role) => void,
    onFinal: () => void
  ) {
    try {
      const roleJson = {
        role: serialize(role),
      };
      const { data } = await axiosInstance.post(ApiRoutes.ROLES, roleJson);
      const newRole = deserialize(Role, data?.role);
      onSuccess(newRole);
    } catch (error) {
    } finally {
      onFinal();
    }
  }

  static async updateRole(
    role: Role,
    onSuccess: (role: Role) => void,
    onFinal: () => void
  ) {
    try {
      const roleJson = {
        role: serialize(role),
      };
      const URL = ApiRoutes.ROLES + "/" + role.id;
      const { data } = await axiosInstance.put(URL, roleJson);
      const newRole = deserialize(Role, data?.role);
      Notification({
        type: NotificationTypes.SUCCESS,
        message: `${role?.name} Responsibilities updated`,
      });
      onSuccess(newRole);
    } catch (error) {
    } finally {
      onFinal();
    }
  }
}
