import React, { useEffect, useState } from "react";
import "./objectNotes.scss";
import InteractionContainer from "../../../store/container/InteractionContainer";
import { InteractionReducerProps } from "../../../store/reducers/interactionReducer";
import ObjectNoteService from "../../../services/ObjectNote/objectNote.service";
import { ObjectNote } from "../../../models/ObjectNote/objectNote.model";
import { Row, Col, Modal } from "antd";
import AppLoader from "../../../shared/components/AppLoader";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import ObjectNoteForm from "../ObjectNoteForm";
import ServiceService from "../../../services/Service/service.service";
import CorporateObjectNoteForm from "../CorporateObjectNoteForm";
import useResponsibilities from "../../../shared/hooks/Responsibilities/useResponsibilities";
import { ResponsibilityTypes } from "../../../enums/responsebily.enum";

interface ObjectNotesProps extends InteractionReducerProps {
  interactionId?: number;
}

function ObjectNotes({
  objectNotes,
  setObjectNotes,
  interactionId,
  activeInteractionId,
}: ObjectNotesProps) {
  const { hasAccess } = useResponsibilities();

  const [loading, setLoading] = useState(false);

  const [activeObjectNote, setActiveObjectNote] = useState<ObjectNote>();

  const HAS_INTERACTION_EDIT_ACCESS = hasAccess(
    ResponsibilityTypes.INTERACTION_EDIT
  );

  const handleEdit = (objectNote: ObjectNote) => () => {
    setActiveObjectNote(objectNote);
    handleToggleObjectNoteForm();
  };

  const handleDelete = (objectNote: ObjectNote) => () => {
    Modal.confirm({
      icon: null,
      content: "Are you sure want to remove the object note?",
      onOk: () => {
        if (interactionId && objectNote?.id) {
          ObjectNoteService.deleteObjectNote(
            interactionId,
            objectNote.id,
            () => {
              const objectNoteIndex = objectNotes.findIndex(
                (note) => note.id === objectNote.id
              );
              if (objectNoteIndex >= 0) objectNotes.splice(objectNoteIndex, 1);
              setObjectNotes([...objectNotes]);
            },
            () => {},
            () => {}
          );
        }
      },
    });
  };

  const [showObjectNoteForm, setShowObjectNoteForm] = useState(false);

  const handleToggleObjectNoteForm = () =>
    setShowObjectNoteForm(!showObjectNoteForm);

  useEffect(() => {
    if (interactionId) {
      setLoading(true);
      ObjectNoteService.fetchObjectNotes(
        interactionId,
        (objectNotes: ObjectNote[]) => {
          setObjectNotes(objectNotes);
        },
        () => {},
        () => {
          setLoading(false);
        }
      );
    }
  }, [interactionId, setObjectNotes]);

  return loading ? (
    <AppLoader loading={loading} />
  ) : (
    <div className="object-notes">
      {objectNotes?.map((objectNote) => (
        <Row className="object-notes__item" gutter={[10, 0]}>
          <Col span={17}>
            <div>
              <span className="mr-2 text-bold">Object name: </span>
              <span>{objectNote?.corporateObjectName}</span>
            </div>
            <div>
              <span className="mr-2 text-bold">Tooth name: </span>
              <span>{objectNote?.toothPositionName}</span>
            </div>
            <div>
              <span className="mr-2 text-bold">Notes: </span>
              <span>{objectNote?.notes}</span>
            </div>
          </Col>
          {HAS_INTERACTION_EDIT_ACCESS &&
            interactionId === activeInteractionId && (
              <>
                <Col span={3} className="object-notes__actions">
                  <EditOutlined
                    className="object-notes__edit mr-2"
                    onClick={handleEdit(objectNote)}
                  />
                </Col>
                <Col span={1} />
                <Col span={3} className="object-notes__actions">
                  <DeleteOutlined
                    className="object-notes__delete"
                    onClick={handleDelete(objectNote)}
                  />
                </Col>
              </>
            )}
        </Row>
      ))}
      <Modal
        maskClosable={false}
        keyboard={false}
        className="interaction-object-actions__object-note-modal"
        centered
        width="35%"
        destroyOnClose
        closable={false}
        visible={showObjectNoteForm}
        onCancel={handleToggleObjectNoteForm}
      >
        {activeObjectNote && (
          <CorporateObjectNoteForm
            onSuccess={handleToggleObjectNoteForm}
            objectNote={activeObjectNote}
            onClose={handleToggleObjectNoteForm}
          />
        )}
      </Modal>
    </div>
  );
}

export default InteractionContainer(ObjectNotes);
