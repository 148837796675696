import { Button, Drawer, Table } from "antd";
import React, { FC } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Support as SupportModel } from "../../../models/Support/support.model";
import Stats from "../../../shared/components/Stats";
import moment from "moment";
import "./patientIssue.scss";
import SupportForm from "../SupportForm";
import SupportDetails from "../SupportDetails";
import SupportService from "../../../services/Support/support.service";
import { SupportStatus } from "../../../enums/supportStatus.enum";
import { StatsType } from "../../../enums/statsType.enum";
import { useMetrics } from "../../../shared/hooks/Metrics/useMetrics";
import useResponsibilities from "../../../shared/hooks/Responsibilities/useResponsibilities";
import { ResponsibilityTypes } from "../../../enums/responsebily.enum";
import { IssueCategory } from "../../../enums/issueCategory.enum";
import { FilterParams } from "../../../models/FilterParams/filterParams.model";
import { PaginationDetails } from "../../../models/Pagination/pagination.model";
import useFilters from "../../../shared/hooks/useFilter/useFilters";
import { TablePaginationConfig } from "antd/lib/table";
import MetricsService from "../../../services/Metrics/metrics.service";
import { Metrics } from "../../../models/Metrics/metrics.model";

interface SupportProps {}

const Support: FC<SupportProps> = (props) => {
  const { getParams, setParams } = useFilters();

  const [metrics, setMetrics] = useState<Metrics>();

  const [pagination, setPagination] = useState<PaginationDetails>();

  const { hasAccess } = useResponsibilities();

  const HAS_ISSUE_CREATE_ACCESS = hasAccess(ResponsibilityTypes.ISSUE_CREATE);

  const HAS_ISSUE_VIEW_ACCESS = hasAccess(ResponsibilityTypes.ISSUE_VIEW);

  const columns = [
    {
      title: "Issue Type",
      dataIndex: "issueType",
      key: "issueType",
    },
    {
      title: "Issue Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Raised By",
      dataIndex: "raisedBy",
      key: "raisedBy",
      render: (_: any, support: SupportModel) => {
        return (
          support?.raisedByFirstName ??
          "" + " " + support?.raisedByLastName ??
          ""
        );
      },
    },
    {
      title: "Assigned To",
      dataIndex: "assignedTo",
      key: "assignedTo",
      render: (name: string, support: SupportModel, index: number) => (
        <span>
          {support.assignedToFirstName
            ? support.assignedToFirstName + " " + support.assignedToLastName
            : "--"}
        </span>
      ),
    },
    {
      title: "Raised Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (isoString: string) => (
        <span>{moment(isoString).format("MM/DD/YYYY")}</span>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status: string) => (
        <span
          className={
            status === SupportStatus.PENDING ? "text-warning" : "text-success"
          }
        >
          {status?.toUpperCase()}
        </span>
      ),
    },
  ];

  const [supportTickets, setSupportTickets] = useState<SupportModel[]>([]);

  const [supportTicket, setSupportTicket] = useState<SupportModel>();

  const [createIssue, setCreateIssue] = useState(false);

  const [loading, setLoading] = useState(false);

  const fetchIssues = (filter: FilterParams) => {
    setLoading(true);
    SupportService.fetchSubscription(
      (supports, pagination: PaginationDetails) => {
        setSupportTickets(supports);
        setPagination(pagination);
      },
      () => {
        setLoading(false);
      },
      { ...filter, issueCategory: IssueCategory.PATIENT }
    );
  };

  const handleFetchMetrics = () => {
    MetricsService.fetchMetrics(
      "issues",
      (metrics: Metrics) => {
        setMetrics(metrics);
      },
      () => {},
      () => {},
      { issueCategory: IssueCategory.CORPORATE }
    );
  };

  useEffect(() => {
    fetchIssues(getParams());

    handleFetchMetrics();
  }, []);

  const handleOpenCreateIssue = () => setCreateIssue(true);

  const handleCloseCreateIssue = () => setCreateIssue(false);

  const handleCreateIssueSuccess = (support: SupportModel) => {
    setSupportTickets((existingSupports) => [...existingSupports, support]);
    handleFetchMetrics();
    handleCloseCreateIssue();
  };

  const handleOpenTicketDetail = (supportTicket: SupportModel) =>
    setSupportTicket(supportTicket);

  const handleCloseTicketDetail = () => setSupportTicket(undefined);

  const handleTableChange = (pagination: TablePaginationConfig) => {
    const prevFilter = getParams();
    const updatedFilters = {
      ...prevFilter,
      page: pagination.current ?? 1,
    };
    setParams(updatedFilters);
    fetchIssues(updatedFilters);
  };

  const handleViewIssue = (supportTicket: SupportModel) => ({
    onClick: () => {
      if (!HAS_ISSUE_VIEW_ACCESS) return;

      setTimeout(() => {
        handleOpenTicketDetail(supportTicket);
      }, 200);
    },
  });

  const supportStatsData = [
    {
      title: "Total Patient Issues",
      value: metrics?.totalIssues ?? 0,
    },
    {
      title: "Resolved Patient Issues",
      value: metrics?.resolvedIssues ?? 0,
    },
    {
      title: "Pending Patient Issues",
      value: metrics?.pendingIssues ?? 0,
    },
  ];

  return (
    <div className="support">
      {HAS_ISSUE_CREATE_ACCESS && (
        <div className="support__btn">
          <Button type="primary" onClick={handleOpenCreateIssue}>
            Raise issue
          </Button>
        </div>
      )}

      <Stats statsData={supportStatsData} statsType={StatsType.ISSUES} />
      <Table
        columns={columns}
        dataSource={supportTickets}
        onChange={handleTableChange}
        onRow={handleViewIssue}
        loading={loading}
        pagination={{
          total: pagination?.totalCount,
          current: pagination?.currentPage ?? 1,
          showTotal: (total: number, range: [number, number]) => (
            <p>
              Showing <b>{` ${range[0]} - ${range[1]}`}</b> of{" "}
              <b>{`${total.toLocaleString()}`}</b>
            </p>
          ),
        }}
      />
      <Drawer
        title="New Issue"
        visible={HAS_ISSUE_CREATE_ACCESS && createIssue}
        onClose={handleCloseCreateIssue}
        destroyOnClose
        width="65vw"
      >
        <SupportForm
          closeHandler={handleCloseCreateIssue}
          successHandler={handleCreateIssueSuccess}
        />
      </Drawer>
      <Drawer
        title="View Issue"
        visible={HAS_ISSUE_VIEW_ACCESS && !!supportTicket}
        onClose={handleCloseTicketDetail}
        destroyOnClose
        width="65vw"
      >
        <SupportDetails supportTicket={supportTicket} />
      </Drawer>
    </div>
  );
};

export default Support;
