import React, { useEffect, useState } from "react";
import { Form, Formik, FormikValues } from "formik";
import { Button, Col, Row } from "antd";
import RegisterContainer from "../../../../store/container/RegisterContainer";
import AttachmentUpload from "../../../../shared/components/AttachmentUpload";
import { registerBusinessDetailsFormValidation } from "./registerBusinessDetailsFormValidation";
import { Corporate } from "../../../../models/Corporate/corporate.model";
import { RegistrationService } from "../../../../services/Registration/registration.service";
import { CorporateService } from "../../../../services/Corporate/corporate.service";
import { RegisterReducerProps } from "../../../../store/reducers/registerReducer";

interface RegisterBusinessDetailsFormProps extends RegisterReducerProps {}

function RegisterBusinessDetailsForm(props: RegisterBusinessDetailsFormProps) {
  const [formLoading, setFormLoading] = useState(false);

  const [formValues, setFormValues] = useState(new Corporate());

  const { registerActiveStep, setRegisterActiveStep, activeCorporate } = props;

  const handleSubmit = (values: FormikValues) => {
    const corporate = Object.assign(new Corporate(), {
      ...values,
      id: activeCorporate?.id,
      stepNumber: 2,
    });
    setFormLoading(true);
    RegistrationService.updateBrandingDetails(
      corporate,
      () => {
        setRegisterActiveStep(registerActiveStep + 1);
      },
      () => {},
      () => {
        setFormLoading(false);
      }
    );
  };

  useEffect(() => {
    if (activeCorporate) {
      setFormValues(activeCorporate);
    }
  }, [activeCorporate]);

  return (
    <div className="register-business-details-form">
      <Formik
        initialValues={formValues}
        onSubmit={handleSubmit}
        enableReinitialize
        validationSchema={registerBusinessDetailsFormValidation}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          errors,
          isValid,
          dirty,
          setFieldValue,
        }) => {
          return (
            <Form>
              <Row>
                <Col span={24}>
                  <AttachmentUpload
                    accept="image/jpeg,image/png,image/jpg"
                    name="logo"
                    title="Corporate Logo"
                    placeholder="Upload logo"
                    newAttachments={values.logo ? [values.logo] : []}
                    attachments={[]}
                    setFieldValue={setFieldValue}
                  />
                </Col>
                <Col span={24}>
                  <AttachmentUpload
                    accept="application/pdf"
                    name="license"
                    title="Corporate Business Document"
                    placeholder="Upload document"
                    newAttachments={values.license ? [values.license] : []}
                    attachments={[]}
                    setFieldValue={setFieldValue}
                  />
                </Col>
              </Row>
              <div className="register-business-details-form__submit-wrapper text-right">
                <Button
                  type="primary"
                  className="ml-2"
                  htmlType="submit"
                  loading={formLoading}
                  disabled={!isValid || !dirty || formLoading}
                >
                  Next
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default RegisterContainer(RegisterBusinessDetailsForm);
