const ApiRoutes = {
  BASE_URL: process.env.REACT_APP_API_BASE_URL,
  AZURE_BUCKET_BASE_URL: process.env.REACT_APP_AZURE_BUCKET_URL,
  TOKEN: "/token",
  LOGOUT: "/api/v1/logout",
  TIME_ZONE: "/api/v1/time_zones",
  CUSTOM_FORM_RESPONSE_SIGNATURE:
    "/api/v1/corporates/:corporateId/custom_form_requests/custom_response_signatures",
  CURRENT_USER_DETAILS: "/api/v1/corporate/user_details",
  USER_ROLES: "/api/v1/corporate/:corporateId/roles",
  COUNTRIES: "/api/v1/countries",
  NATIONALITIES: "/api/v1/nationalities",
  ISD_CODES: "/api/v1/isd_codes",
  PROVINCES: "/api/v1/countries/:countryId/provinces",
  CITIES: "/api/v1/countries/:countryId/provinces/:provinceId/cities",
  ZIPCODES: "/api/v1/countries/:countryId/provinces/:provinceId/zipcodes",
  CORPORATE_SIGN_UP: "/api/v1/corporate/signup",
  CORPORATE_PROFILE: "/api/v1/corporate/:corporateId/profiles",
  TOOTH_POSITION: "api/v1/tooth_position",
  CORPORATE_ADDITIONAL_DETAILS:
    "/api/v1/corporate/:corporateId/additional_details",
  CORPORATE_BRANDING_DETAILS: "/api/v1/corporate/:corporateId/branding_details",
  CORPORATE_SUBSCRIPTION_PACKAGE:
    "/api/v1/corporate/:corporateId/subscription_package",
  USERS: "/api/v1/corporate/:corporateId/users",
  NEW_USERS:
    "api/v1/corporate/:corporateId/practice/:practiceId/patient/:patientProfileId/users",

  PRACTICES: "/api/v1/corporate/:corporateId/practices",
  PATIENTS: "/api/v1/corporate/:corporateId/patients",
  PATIENT_PRE_DETERMINATION_FORM:
    "/api/v1/corporate/:corporateId/patient_profiles/:id/predetermination_form",

  PATIENT_XRAY:
    "/api/v1/corporate/:corporateId/practices/:practiceId/patient_profiles/:id/x_ray_images",
  PATIENT_PRE_VISIT_IMAGES:
    "/api/v1/corporate/:corporateId/practices/:practiceId/patient_profiles/:id/pre_visit_images",
  PATIENT_POST_VISIT_IMAGES:
    "/api/v1/corporate/:corporateId/practices/:practiceId/patient_profiles/:id/post_visit_images",
  PATIENTS_APPOINTMENT: "/api/v1/patient/appointments",

  PATIENT_STATUS: "/api/v1/corporate/:corporateId/patient_statuses",
  PATIENT_NOTES:
    "/api/v1/corporate/:corporateId/patient_profiles/:id/patient_notes",

  PATIENT_SUB_STATUS:
    "/api/v1/corporate/:corporateId/patient_statuses/:patientStatusId/patient_sub_statuses",
  RESOURCES: "/api/v1/corporate/:corporateId/practices/:practiceId/resources",
  RESOURCE_TYPE: "/api/v1/corporate/:corporateId/resource_types",
  INTERACTION_STATUS: "/api/v1/corporate/:corporateId/interaction_statuses",
  INTERACTION_SUB_STATUS:
    "/api/v1/corporate/:corporateId/interaction_statuses/:interactionStatusId/interaction_sub_statuses",
  RESPONSIBLE_PERSONS: "/api/v1/corporate/:corporateId/responsible_persons",
  PATIENTS_DOWNLOAD_TEMPLATE:
    "/api/v1/corporate/:corporateId/patients/download_template",
  PATIENT_UPLOAD_DATA: "/api/v1/corporate/1/patients/upload_data",
  TERM_TEMPLATE_UPLOAD_DATA:
    "/api/v1/corporate/:corporateId/contract_terms/:termId/signature",
  PATIENT_DOCUMENTS:
    "/api/v1/corporate/:corporateId/patient_profiles/:patientId/documents",
  CUSTOM_FORMS: "/api/v1/corporate/:corporateId/custom_forms",
  SERVICES: "/api/v1/corporate/:corporateId/services",
  SERVICE_CATEGORIES: "/api/v1/corporate/:corporateId/service_categories",
  APPOINTMENT_TEMPLATES:
    "/api/v1/corporate/:corporateId/practice/:practiceId/appointment_templates",
  APPOINTMENT_SCHEDULES:
    "/api/v1/corporate/:corporateId/practice/:practiceId/appointment_schedules",
  APPOINTMENT_TEMPLATE_DETAIL:
    "/api/v1/corporate/:corporateId/practice/:practiceId/appointment_templates/:templateId",

  SERVICE_SUB_CATEGORIES:
    "/api/v1/corporate/:corporateId/service_categories/:serviceCategoryId/service_sub_categories",
  PRACTICE_SCHEDULES:
    "/api/v1/corporate/:corporateId/practices/:practiceId/schedules",
  USER_SCHEDULES:
    "/api/v1/corporate/:corporateId/practices/:practiceId/profiles/:userId/schedules",
  APPOINTMENT_TYPES: "/api/v1/corporate/:corporateId/appointment_types",
  DELETE_APPOINTMENT_TEMPLATE:
    "/api/v1/corporate/:corporateId/practice/:practiceId/appointment_templates/:templateId/appointment_template_slot/:appointmentTemplateSlotId",
  APPOINTMENT_TEMPLATE_SCHEDULE:
    "/api/v1/corporate/:corporateId/practice/:practiceId/appointment_schedule",
  APPOINTMENT_TEMPLATE_SCHEDULE_LIST:
    "/api/v1/corporate/:corporateId/practice/:practiceId/appointment_schedules",

  DOCTOR_APPOINTMENTS:
    "/api/v1/corporate/:corporateId/practices/:practiceId/appointments_dates",
  CREATE_APPOINTMENT_TEMPLATE:
    "/api/v1/corporate/:corporateId/practice/:practiceId/appointment_templates/:templateId/appointment_template_slot",
  UPDATE_APPOINTMENT_TEMPLATE:
    "/api/v1/corporate/:corporateId/practice/:practiceId/appointment_templates/:templateId/appointment_template_slot/:appointmentTemplateSlotId",
  NEW_APPOINTMENT_TYPES:
    "/api/v1/corporate/:corporateId/practice/:practiceId/patient/:patientProfileId/appointment_types",
  INSURANCE_PROVIDER_LIST: "/api/v1/insurance_providers",
  PATIENT_INSURANCE_PROVIDER_LIST:
    "/api/v1/corporate/:corporateId/practice/:practiceId/patient/:patientProfileId/contract/:contractId/insurance_providers",

  REMOVE_APPOINTMENT_SLOT:
    "/api/v1/corporate/:corporateId/practices/:practiceId/schedules/:scheduleId/slots/:slotId",
  UPDATE_APPOINTMENT_SLOT:
    "/api/v1/corporate/:corporateId/practices/:practiceId/schedules/:scheduleId/slots/:slotId",

  METRICS: "/api/v1/corporate/:corporateId/:metricsType/metrics",
  METRICS_PRAC:
    "/api/v1/corporate/:corporateId/practices/:practiceId/:metricsType/metrics",
  CORPORATE_METRICS: "/api/v1/admin/corporates/metrics",

  PATIENT_DETAIL_FORMS: "/api/v1/corporate/:corporateId/patient_detail_forms",
  CUSTOM_FORM_RESPONSE: "/api/v1/corporate/:corporateId/custom_form_responses",
  CUSTOM_FORM_REQUEST:
    "/api/v1/corporate/:corporateId/custom_forms/:customFormId/requests",
  CUSTOM_FORM_RESPONSES:
    "/api/v1/corporate/:corporateId/custom_form_requests/:customFormRequestId/responses",
  APPOINTMENTS:
    "/api/v1/corporate/:corporateId/practices/:practiceId/appointments",
  COMPLETED_APPOINTMENTS: "/api/v1/corporate/:corporateId/appointments",
  APPOINTMENT_SESSION_SLOT:
    "/api/v1/corporates/:corporateId/practices/:practiceId/appointment_types/:appointmentTypeId/available_timings",
  APPOINTMENT_TYPE_CATEGORIES:
    "api/v1/corporate/:corporateId/appointment_type_categories",
  APPOINTMENT_TYPE_PROCEDURE_CODES:
    "api/v1/corporate/:corporateId/appointment_type_category/:categoryId/procedure_codes",
  CONTRACT_TERM_TEMPLATES: "/api/v1/corporate/:corporateId/term_templates",
  CONTRACT_TERMS:
    "/api/v1/corporate/:corporateId/practices/:practiceId/contracts/:contractId/contract_terms",
  CONTRACT_INSURANCE_CLAIM_FORM:
    "/api/v1/corporate/:corporateId/contract/:contractId/insurance_claim_forms",

  DEFAULT_CONTRACT_TERM_TEMPLATES:
    "/api/v1/corporate/:corporateId/term_templates",
  DEFAULT_CONTRACT_TERM_TEMPLATES_ORDERS:
    "/api/v1/corporate/:corporateId/term_template",

  CLOCK_TIME: "/api/v1/corporate/:corporateId/clock_time",
  UPDATE_CLOCK_TIME: "/api/v1/corporate/:corporateId/clock_time/:id",

  CONTRACT_CLOSE:
    "/api/v1/corporate/:corporateId/practices/:practiceId/contracts/:id/status",
  CONTRACT_CLOSE_SERVICE:
    "/api/v1/corporate/:corporateId/contracts/:contractId/contract_services/:contractServiceId/status",
  VISITS_LIST_CONTRACT:
    "/api/v1/corporate/:corporateId/practices/:practiceId/interactions/:interactionId/contract_visits",
  NOTE_TEMPLATE_STATEMENTS:
    "/api/v1/corporate/:corporateId/statement_templates",
  STATEMENT_TEMPLATE_CATEGORIES:
    "/api/v1/corporate/:corporateId/statement_template_categories",
  NOTE_TEMPLATE_X_RAY: "/api/v1/corporate/:corporateId/x_ray_templates",
  X_RAY_CATEGORIES: "/api/v1/corporate/:corporateId/x_ray_template_categories",
  X_RAY_CATEGORY:
    "/api/v1/corporate/:corporateId/x_ray_template_categories/:categoryId",
  X_RAY_TYPES:
    "/api/v1/corporate/:corporateId/practices/:practiceId/x_ray_types",
  INTERACTION:
    "/api/v1/corporate/:corporateId/practices/:practiceId/interactions",
  WORK_ITEM: "/api/v1/corporate/:corporateId/practice/:practiceId/work_items",
  PRACTICE_CONTRACT:
    "/api/v1/corporate/:corporateId/practices/:practiceId/contracts",
  APPOINTMENTS_CALENDER:
    "/api/v1/corporate/:corporateId/practices/:practiceId/appointments_calendar",
  CONTRACTS: "/api/v1/corporate/:corporateId/practices/:practiceId/contracts",
  CONTRACT_PAYMENT_SCHEDULE:
    "/api/v1/corporate/:corporateId/practices/:practiceId/contracts/:id/payment_schedule",
  APPOINTMENT_CALENDAR_DAY_VIEW:
    "api/v1/corporate/:corporateId/practices/:practiceId/date/:date/appointments_calendar",
  APPOINTMENT_CALENDAR_DAY_VIEW_DELETE:
    "api/v1/corporate/:corporateId/practice/:practiceId/appointment_schedules/:appointmentScheduleId/appointment_schedule_slots/:appointmentScheduleSlotId",
  ADD_INSURANCE_PROVIDER:
    "/api/v1/corporate/:corporateId/practice/:practiceId/patient/:patientProfileId/contract/:contractId/insurance_providers",

  UPDATE_INSURANCE_PROVIDER:
    "/api/v1/corporate/:corporateId/practice/:practiceId/patient/:patientProfileId/contract/:contractId/insurance_provider/:id",
  DELETE_INSURANCE_PROVIDER:
    "/api/v1/corporate/:corporateId/practice/:practiceId/patient/:patientProfileId/contract/:contractId/insurance_provider/:id",
  PATIENT_CONTRACTS:
    "/api/v1/corporate/:corporateId/patient_profiles/:patientId/contracts",
  CONTRACT_METRICS:
    "/api/v1/corporate/:corporateId/practices/:practiceId/contracts/metrics",
  APPOINTMENT_CALENDER_HOLIDAY:
    "/api/v1/corporate/:corporateId/practices/:practiceId/practice_holidays",
  DENTAL_OBJECTS: "/api/v1/corporate/:corporateId/dental_objects",
  IMPORT_DENTAL_OBJECTS: "/api/v1/corporate/:corporateId/import_dental_objects",
  CORPORATE_OBJECTS: "/api/v1/corporate/:corporateId/corporate_objects",
  DELETE_APPOINTMENT_SCHEDULE:
    "/api/v1/corporate/:corporateId/practice/:practiceId/appointment_schedules",
  CORPORATE_LINKS: "/api/v1/corporate/:corporateId/corporate_links",
  SETS: "/api/v1/corporate/:corporateId/practices/:practiceId/sets",
  CLONE_SET:
    "/api/v1/corporate/:corporateId/practices/:practiceId/sets/:setId/clone",
  SET_STEPS:
    "/api/v1/corporate/:corporateId/practices/:practiceId/sets/:setId/set_steps",
  PROCEDURES: "/api/v1/corporate/:corporateId/practices/:practiceId/procedures",
  PROCEDURE_STEPS:
    "/api/v1/corporate/:corporateId/practices/:practiceId/procedures/:procedureId/procedure_steps",
  CLONE_PROCEDURE:
    "/api/v1/corporate/:corporateId/practices/:practiceId/procedures/:procedureId/clone",
  NOTE_TEMPLATES: "/api/v1/corporate/:corporateId/note_templates",
  MANUFACTURERS: "/api/v1/corporate/:corporateId/manufacturers",
  OBJECT_CATEGORIES: "/api/v1/corporate/:corporateId/object_categories",
  OBJECT_MODELS: "/api/v1/corporate/:corporateId/object_models",
  MEDICAL_ALERTS:
    "/api/v1/corporate/:corporateId/patient_profiles/:patientProfileId/medical_alerts",
  CUSTOM_FORM_REQUESTS:
    "/api/v1/corporate/:corporateId/interactions/:interactionId/custom_form_requests",
  GET_CUSTOM_FORM_REQUESTS:
    "/api/v1/corporate/:corporateId/custom_form_requests",
  GET_STATEMENT_NOTES:
    "/api/v1/corporate/:corporateId/practices/:practiceId/interactions/:interactionId/statement_notes",
  GET_COMMENT_NOTES:
    "/api/v1/corporate/:corporateId/practices/:practiceId/interactions/:interactionId/comments",
  GET_X_RAY_NOTES:
    "/api/v1/corporate/:corporateId/practices/:practiceId/interactions/:interactionId/x_ray_notes",
  GET_X_RAY_IMAGES:
    "/api/v1/corporate/:corporateId/practices/:practiceId/interactions/:id/x_ray_images",
  CUSTOM_FORM_VARIABLES: "/api/v1/corporate/:corporateId/custom_form_variables",

  /* Interactions */
  GET_INTERACTION_DETAILS:
    "/api/v1/corporate/:corporateId/practices/:practiceId/interactions/:id",
  CONTRACT_INTERACTIONS:
    "/api/v1/corporate/:corporateId/practices/:practiceId/interactions/:interactionId/contract_interactions",
  INTERACTION_STEPS:
    "/api/v1/corporate/:corporateId/practices/:practiceId/interactions/:interactionId/interaction_steps",
  PRE_VISIT:
    "/api/v1/corporate/:corporateId/practices/:practiceId/interactions/:interactionId/pre_visit_images",
  POST_VISIT:
    "/api/v1/corporate/:corporateId/practices/:practiceId/interactions/:interactionId/post_visit_images",
  OBJECT_NOTES:
    "/api/v1/corporate/:corporateId/practices/:practiceId/interactions/:interactionId/object_notes",
  CONTRACT_VISITS:
    "/api/v1/corporate/:corporateId/practices/:practiceId/interactions/:interactionId/contract_visits",
  CONTRACT_TERM_LINK:
    "api/v1/corporate/:corporateId/contract_terms/:contractTermId/email",
  DOWNLOAD_CONTRACT_TERM:
    "api/v1/corporate/:corporateId/contracts/:contractId/contract_terms_pdf/download",
  TOOTH_POSITIONS: "/api/v1/tooth_positions",
  TREATMENT_TEMPLATE:
    "/api/v1/corporate/:corporateId/practices/:practiceId/treatment_plan_templates",
  TREATMENT_PLAN_TEMPLATE:
    "/api/v1/corporate/:corporateId/practices/:practiceId/treatment_plan_templates",
  TREATMENT_PLAN_TEMPLATE_CLONE:
    "/api/v1/corporate/:corporateId/practices/:practiceId/treatment_plan_templates/:treatmentTemplateId/clone",
  TREATMENT_pLAN_TEMPLATE_DETAILS:
    "/api/v1/corporate/:corporateId/practices/:practiceId/treatment_plan_templates/:treatmentTemplateId",
  TREATMENT_PLAN_TEMPLATE_STATEMENT_NOTES:
    "/api/v1/corporate/:corporateId/practices/:practiceId/treatment_plan_templates/:treatmentPlanTemplateId/treatment_plan_template_appointments/:id/treatment_plan_template_statement_notes",
  TREATMENT_PLAN_TEMPLATE_STEPS:
    "/api/v1/corporate/:corporateId/practices/:practiceId/treatment_plan_templates/:id/treatment_plan_template_visits",
  ALL_OBJECT_CATEGORIES: "/api/v1/corporate/:corporateId/object_categories",
  STOP_INTERACTION:
    "/api/v1/corporate/:corporateId/practices/:practiceId/interactions/:id/complete",
  COMPLETE_INTERACTION:
    "/api/v1/corporate/:corporateId/practice/:practiceId/work_items/:id/complete",
  INTERACTION_TREATMENT_PLAN_DETAILS:
    "/api/v1/corporate/:corporateId/practices/:practiceId/interactions/:id/treatment_plan",
  INTERACTION_TREATMENT_PLAN_STEP:
    "/api/v1/corporate/:corporateId/practices/:practiceId/treatment_plan_templates/:treatmentPlanTemplateId/treatment_plan_template_appointments/:id/treatment_plan_template_steps",
  TREATMENT_PLAN:
    "/api/v1/corporate/:corporateId/practices/:practiceId/treatment_plans/:id",
  TREATMENT_PLAN_STEP:
    "/api/v1/corporate/:corporateId/practices/:practiceId/treatment_plans/:treatmentPlanId/treatment_plan_visits/:id/treatment_plan_steps",
  TREATMENT_PLAN_STATEMENT_NOTES:
    "/api/v1/corporate/:corporateId/practices/:practiceId/treatment_plans/:treatmentPlanId/treatment_plan_visits/:id/treatment_plan_statement_notes",
  TREATMENT_PLAN_TEMPLATE_VISIT:
    "/api/v1/corporate/:corporateId/practices/:practiceId/treatment_plans/:treatmentPlanId/treatment_plan_visits/:id",
  REMOVE_TREATMENT_PLAN_STEP:
    "/api/v1/corporate/:corporateId/practices/:practiceId/treatment_plans/:treatmentPlanId/treatment_plan_visits/:treatmentPlanVisitId/treatment_plan_steps/:id",
  REMOVE_TEMPLATE_VISIT_STATEMENT:
    "/api/v1/corporate/:corporateId/practices/:practiceId/treatment_plan_templates/:treatmentPlanTemplateId/treatment_plan_template_appointments/:treatmentPlanTemplateAppointmentId/treatment_plan_template_statement_notes/:id",
  REMOVE_TREATMENT_PLAN_TEMPLATE_STEP:
    "/api/v1/corporate/:corporateId/practices/:practiceId/treatment_plan_templates/:treatmentPlanId/treatment_plan_template_appointments/:treatmentPlanVisitId/treatment_plan_template_steps/:id",
  ROLES: "/api/v1/corporate/:corporateId/roles",
  CREATE_REMAINDER: "/api/v1/corporate/:corporateId/reminders",
  NOTIFICATION: "/api/v1/corporate/user_notifications",
  CORPORATE_SUBSCRIPTION: "/api/v1/corporate/:corporateId/subscriptions",
  SUPPORT: "/api/v1/corporate/:corporateId/issues",
  ISSUE_COMMENTS: "/api/v1/corporate/:corporateId/issues/:issueId/comments",
  CORPORATE_SUBSCRIPTIONS: "/api/v1/corporate/:corporateId/subscriptions",
  ATTACHMENT: "/api/v1/corporate/:corporateId/issues/:issueId/attachments",
  SUBSCRIPTION_FREQUENCY:
    "/api/v1/corporate/:corporateId/subscription_frequencies",
  UPDATE_CORPORATE_SUBSCRIPTION:
    "/api/v1/corporate/:corporateId/corporate_subscriptions",
  STRIPES_CREATE_ACCOUNT: "/api/v1/corporate/:corporateId/stripe_account",
  MAKE_PAYMENT_INDENT:
    "https://stripeservice.azurewebsites.net/api/CreatePaymentIntent?code=q4UHSP3hTJfHdZa/sky5pn3EARrREkMBXIsd85gZAjHVB0qaF/ZLRw==",
  GET_PROFILE_PAYMENT_METHODS:
    "/api/v1/corporate/:corporateId/profile_payment_methods",
  GET_PATIENT_PAYMENT_METHODS:
    "/api/v1/corporate/:corporateId/patients/:patientId/payment_methods",
  GET_PAYMENT_METHOD: "/api/v1/corporate/:corporateId/payment_methods/",
  CONTRACT_SUBSCRIPTION:
    "/api/v1/corporate/:corporateId/practices/:practiceId/contracts/:contractId/contract_subscriptions",
  PAYMENT_INTENT: "/api/v1/corporate/:corporateId/payment_intents",
  LIST_OFFLINE_PAYMENTS: "/api/v1/corporate/:corporateId/offline_payments",
  GET_PAYMENT_INVOICE:
    "/api/v1/corporates/:corporateId/practices/:practiceId/payment_invoices",
  UPDATE_PAYMENT_INVOICE:
    "/api/v1/corporate/:corporateId/practices/:practiceId/payment_invoices",
  GET_PAYMENT_INVOICE_METRICS:
    "/api/v1/corporate/:corporateId/practices/:practiceId/payment_invoices/metrics",
  GET_PAYMENT_REPORTS:
    "/api/v1/corporates/:corporateId/practices/:practiceId/payment_reports",
  GET_MONTHLY_PAYMENT_REPORTS:
    "/api/v1/corporates/:corporateId/practices/:practiceId/monthly_payment_reports",
  GET_MONTHLY_DETAILS_REPORTS:
    "/api/v1/corporates/:corporateId/practices/:practiceId/monthly_payment_reports_detailed_view",
  CRUD_OFFLINE_PAYMENTS:
    "/api/v1/corporate/:corporateId/contract_subscriptions/:contractSubscriptionId/offline_payments",
  PATIENT_SUBSCRIPTIONS:
    "/api/v1/corporate/:corporateId/patients/:patientId/contract_subscriptions",
  CREATE_PAYMENT_INVOICE:
    "/api/v1/corporate/:corporateId/practices/:practiceId/offline_payment_invoices",

  // Email validation
  CORPORATE_EMAIL_VALIDATION: "/api/v1/corporate/email_validation",
  CORPORATE_PATIENT_EMAIL_VALIDATION:
    "/api/v1/corporate/:corporateId/patients/email_validation",
  PATIENT_PHONE_VALIDATION: "/api/v1/patients/mob_no_validation",
  USERS_PHONE_VALIDATION: "/api/v1/users/mob_no_validation",

  UPDATE_APPOINTMENT_TYPE:
    "/api/v1/corporate/:corporateId/practice/:practiceId/appointment_schedules/:appointmentScheduleId/appointment_schedule_slots/:appointmentScheduleSlotId",
  DELETE_DAY_VIEW_APPOINTMENT_SCHEDULE:
    "/api/v1/corporate/:corporateId/practice/:practiceId/appointment_schedules/:appointmentScheduleId",
};

export default ApiRoutes;
