import { ColumnsType } from "antd/lib/table";
import { DoctorAppointmentDate } from "models/AppointmentPractice/appointmentPractice.model";

const columns = (): ColumnsType<DoctorAppointmentDate> => [
  {
    title: "Date",
    dataIndex: "parseDate",
    render: (parseDate) => parseDate(),
  },
  {
    title: "Number of appointments",
    dataIndex: "noOfAppointments",
  },
];

export default columns;
