import React, { FC, useState } from "react";
import { useEffect } from "react";
import { ContractTermTemplate } from "../../../../models/ContractTermsTemplate/contractTermsTemplate.model";
import ContractTermTemplateService from "../../../../services/ContractTermTemplate/ContractTermTemplate.service";
import "./contractTermsTemplateView.scss";
import AppLoader from "../../../../shared/components/AppLoader";
import { useLocation } from "react-router-dom";
import Editor from "../../../../shared/components/Editor";

interface ContractTermsTemplateViewProps {
  contractTermTemplateId: number;
}

const ContractTermsTemplateView: FC<ContractTermsTemplateViewProps> = (
  props
) => {
  const { contractTermTemplateId } = props;
  const [
    contractTermTemplate,
    setContractTermTemplate,
  ] = useState<ContractTermTemplate>();

  const [loader, setLoader] = useState(false);
  useEffect(() => {
    setLoader(true);
    ContractTermTemplateService.showContractTermTemplates(
      contractTermTemplateId,
      (contractTermTemplate) => {
        setContractTermTemplate(contractTermTemplate);
      },
      () => {},
      () => {
        setLoader(false);
      }
    );
  }, []);

  if (loader) {
    return <AppLoader loading />;
  }

  return (
    <div className="contract-terms-template-view">
      <p className="label">Title</p>
      <p className="title mb-4">{contractTermTemplate?.name}</p>
      <p className="label">Description</p>
      <Editor value={contractTermTemplate?.description || ""}  />
    </div>
  );
};

export default ContractTermsTemplateView;
