import ApiRoutes from "../../routes/routeConstants/apiRoutes";
import axiosInstance from "../../interceptor/axiosInstance";
import { deserialize, serialize } from "serializr";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import { Clock } from "../../models/Clock/clock.model";

export default class ClockService {
  static startClockTime(
    onSuccess: (clockTime: Clock) => void,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .post(ApiRoutes.CLOCK_TIME)
      .then((response) => {
        const clockTime = deserialize(Clock, response.data.clock_time);
        onSuccess(clockTime);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }
  static updateClock(
    clockId: string,
    action: string,
    onSuccess: (clockTime: Clock) => void,
    onError: Function,
    onFinal: () => void
  ) {
    const params = {
      clock_time_action: action,
    };
    axiosInstance
      .put(ApiRoutes.UPDATE_CLOCK_TIME.replace(":id", clockId), params)
      .then((response) => {
        const clockTime = deserialize(Clock, response.data.clock_time);
        onSuccess(clockTime);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }
  static getClockTime(
    onSuccess: (clockTime: Clock) => void,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .get(ApiRoutes.CLOCK_TIME)
      .then((response) => {
        const clockTime = deserialize(Clock, response.data.clock_time);
        onSuccess(clockTime);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }
}
