import { serializable, alias, primitive } from "serializr";

export class CustomResponse {
  @serializable(alias("custom_option_id", primitive()))
  customOptionId?: number;

  @serializable(alias("custom_option_name", primitive()))
  customOptionName?: string;

  @serializable(alias("response_text", primitive()))
  responseText?: string;

  @serializable(alias("custom_response_signature_id", primitive()))
  signatureId?: string;

  @serializable(alias("custom_response_signature_url", primitive()))
  signatureUrl?: string;
}
