import React, { useState } from "react";
import "./patientBulkUploadForm.scss";
import { Form, Formik, FormikValues } from "formik";
import AttachmentUpload from "../../../shared/components/AttachmentUpload";
import { Button } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import PatientService from "../../../services/Patient/patient.service";

interface PatientBulkUploadFormProps {
  onSuccess: () => void;
}

interface IPatientBulkUploadProps {
  file?: File;
}

const initialValues: IPatientBulkUploadProps = {
  file: undefined,
};

function PatientBulkUploadForm({ onSuccess }: PatientBulkUploadFormProps) {
  const [formLoading, setFormLoading] = useState(false);

  const handleDownloadTemplate = () => {
    PatientService.downloadTemplate(
      () => {},
      () => {},
      () => {}
    );
  };

  const handleSubmit = (values: FormikValues) => {
    if (values.file) {
      setFormLoading(true);
      PatientService.uploadPatientData(
        values.file,
        () => {
          onSuccess();
        },
        () => {},
        () => {
          setFormLoading(false);
        }
      );
    }
  };

  return (
    <div className="patient-bulk-upload-form">
      <div className="mt-2 mb-2 text-right">
        <Button
          type="link"
          className="text-secondary"
          onClick={handleDownloadTemplate}
        >
          <DownloadOutlined /> Click to download Template
        </Button>
      </div>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({
          handleSubmit,
          handleChange,
          values,
          errors,
          isValid,
          dirty,
          setFieldValue,
        }) => {
          return (
            <Form>
              <div className="patient-bulk-upload-form__upload-wrapper">
                <AttachmentUpload
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  name="file"
                  placeholder="Upload Excel"
                  newAttachments={values.file ? [values.file] : []}
                  attachments={[]}
                  setFieldValue={setFieldValue}
                />
              </div>
              <div className="mt-4 text-right">
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={formLoading || !values.file}
                  loading={formLoading}
                >
                  Upload
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default PatientBulkUploadForm;
