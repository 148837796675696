import axiosInstance from "../../interceptor/axiosInstance";
import ApiRoutes from "../../routes/routeConstants/apiRoutes";
import { deserialize, serialize } from "serializr";
import { XRayNote } from "../../models/XRayNote/xRayNote.model";

class XrayNotesService {
  static fetchXrayNotes(
    interactionId: number,
    onSuccess: (statementTemplates: XRayNote[]) => void,
    onError: () => void,
    onFinal: () => void
  ) {
    const URL = ApiRoutes.GET_X_RAY_NOTES.replace(
      ":interactionId",
      interactionId.toString()
    );
    return axiosInstance
      .get(URL)
      .then((response) => {
        const xRayNotes = deserialize(
          XRayNote,
          response.data["x_ray_notes"] as any[]
        );
        onSuccess(xRayNotes);
      })
      .catch(() => {
        onError();
      })
      .finally(onFinal);
  }

  static createXrayNote(
    interactionId: number,
    xRayNotes: XRayNote,
    onSuccess: (xRayNotes: XRayNote) => void,
    onError: () => void,
    onFinal: () => void
  ) {
    const URL = ApiRoutes.GET_X_RAY_NOTES.replace(
      ":interactionId",
      interactionId.toString()
    );
    const requestJSON = {
      x_ray_note: serialize(xRayNotes),
    };
    return axiosInstance
      .post(URL, requestJSON)
      .then((response) => {
        const xRayNotes = deserialize(XRayNote, response.data["x_ray_note"]);
        onSuccess(xRayNotes);
      })
      .catch(() => {
        onError();
      })
      .finally(onFinal);
  }

  static deleteXrayNote(
    id: number,
    interactionId: number,
    onSuccess: () => void,
    onError: () => void,
    onFinal: () => void
  ) {
    const URL =
      ApiRoutes.GET_X_RAY_NOTES.replace(
        ":interactionId",
        interactionId.toString()
      ) + `/${id}`;
    return axiosInstance
      .delete(URL)
      .then(() => {
        onSuccess();
      })
      .catch(() => {
        onError();
      })
      .finally(onFinal);
  }
}

export default XrayNotesService;
