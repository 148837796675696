import React from "react";
import "./detailedOnboardingSuccess.scss";
import { Button } from "antd";
import { useHistory, withRouter } from "react-router-dom";
import onBoardingSuccessImage from "../../../assets/images/onBoardingSuccess.png";
import AppRoutes from "../../../routes/routeConstants/appRoutes";
import ApiRoutes from "../../../routes/routeConstants/apiRoutes";

interface DetailedOnboardingSuccessProps {
}

function DetailedOnboardingSuccess(props: DetailedOnboardingSuccessProps) {

  const handleNavigateToDashboard = () => {
    localStorage.setItem("openCheckList", "yes");
    window.location.replace(window.origin);
  };

  return (
    <div className="detailed-onboarding-success">
      <h2 className="text-primary"> Thank you!!</h2>
      <img
        className="detailed-onboarding-success__img"
        src={onBoardingSuccessImage}
        alt="Onboarding successfully"
      />
      <h3 className="text-secondary">
        {" "}
        Successfully completed your onboarding/
      </h3>
      <p>Some onboarding placeholder content..</p>
      <Button
        onClick={handleNavigateToDashboard}
        type="primary"
        className="large-btn"
      >
        Go to Dashboard
      </Button>
    </div>
  );
}

export default DetailedOnboardingSuccess;
