import { useState } from "react";
import { PaginationDetails } from "../../../models/Pagination/pagination.model";
import { StatementTemplate } from "../../../models/Statement/statement.model";
import { getDropdownOptions } from "../../utils/getDropdownOptions";
import { NoteTemplateService } from "../../../services/NoteTemplate/noteTemplate.service";
import { FilterParams } from "../../../models/FilterParams/filterParams.model";

interface useStatementNotes {}

const useStatementNotes = ({}: useStatementNotes) => {
  const [statementNoteTemplates, setStatementNoteTemplates] = useState<
    StatementTemplate[]
  >([]);

  const [pagination, setPagination] = useState(new PaginationDetails());

  const [loading, setLoading] = useState(false);

  const options = getDropdownOptions(
    statementNoteTemplates,
    "subCategory",
    "id"
  );

  const fetchStatementNoteTemplates = (
    { statementTemplateCategoryId, ...params }: FilterParams,
    filters = pagination
  ) => {
    if (!filters?.nextPage) return;

    const { nextPage } = filters;
    setLoading(true);
    NoteTemplateService.fetchStatements(
      (templates, filters) => {
        setStatementNoteTemplates((existingTemplates) =>
          params?.page === 1 ? templates : [...existingTemplates, ...templates]
        );
        setPagination(filters);
      },
      () => {},
      () => setLoading(false),
      statementTemplateCategoryId ? +statementTemplateCategoryId : undefined,
      { page: nextPage, ...params }
    );
  };

  return {
    fetchStatementNoteTemplates,
    loading,
    options,
    statementNoteTemplates,
  };
};

export default useStatementNotes;
