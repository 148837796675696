import { serializable, alias, primitive, list, object } from "serializr";
import { PatientCategory } from "../../enums/patientCategory.enum";
import { Appointment } from "../Appointment/appointment.model";
import { InteractionStep } from "../InteractionStep/interactionStep.model";

export class Interaction extends Appointment {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("assistant_profile_id", primitive()))
  assistantProfileId?: string;

  @serializable(alias("contract_service_id", primitive()))
  contractServiceId?: string;

  @serializable(alias("contract_id", primitive()))
  contractId?: string;

  @serializable(alias("oral_hygiene", primitive()))
  oralHygiene: number = 0;

  @serializable(alias("elastic_compliance", primitive()))
  elasticCompliance: number = 0;

  @serializable(alias("appointment_id", primitive()))
  appointmentId?: string;

  @serializable(alias("chair_id", primitive()))
  chairId?: string;

  @serializable(alias("visit_no", primitive()))
  visitNo?: number;

  @serializable(alias("appointment", object(Appointment)))
  appointment?: Appointment;

  @serializable(alias("interaction_steps", list(object(InteractionStep))))
  interactionSteps: InteractionStep[] = [];

  @serializable(alias("patient_category", primitive()))
  patientCategory?: PatientCategory;

  getDoctorFullName() {
    return this.doctorFirstName + " " + this.doctorLastName;
  }
}
