import { Corporate } from "../../models/Corporate/corporate.model";
import { Practice } from "../../models/Practice/practice.model";
import {
  SET_CORPORATE_ID,
  SET_CURRENT_CORPORATE,
  SET_FIRST_VISIT,
  SET_OPEN_CHECK_LIST,
  SET_PRACTICES,
  SET_PRACTICE_ID,
  SET_PRACTICE_LOADING,
} from "../definitions/corporateConstants";

export interface SetCurrentCorporateAction {
  type: typeof SET_CURRENT_CORPORATE;
  payload: { currentCorporate: Corporate };
}

export const setCurrentCorporate = (
  currentCorporate: Corporate
): SetCurrentCorporateAction => {
  return {
    type: SET_CURRENT_CORPORATE,
    payload: {
      currentCorporate,
    },
  };
};

export const setOpenCheckList = (openCheckList: boolean) => {
  return {
    type: SET_OPEN_CHECK_LIST,
    payload: {
      openCheckList,
    },
  };
};

export const setFirstVisit = (visited: boolean) => {
  return {
    type: SET_FIRST_VISIT,
    payload: {
      firstVisit: visited,
    },
  };
};

export const setPracticeId = (practiceId: number) => {
  return {
    type: SET_PRACTICE_ID,
    payload: {
      practiceId,
    },
  };
};

export const setCorporateId = (corporateId: number) => {
  return {
    type: SET_CORPORATE_ID,
    payload: {
      corporateId,
    },
  };
};

export const setPractices = (practices: Practice[]) => ({
  type: SET_PRACTICES,
  payload: {
    practices,
  },
});

export const setPracticeLoading = (practiceLoading: boolean) => ({
  type: SET_PRACTICE_LOADING,
  payload: {
    practiceLoading,
  },
});
