import React, { useEffect, useState } from "react";
import "./customFormList.scss";
import { CustomForm } from "../../../models/CustomForm/customForm.model";
import CustomFormService from "../../../services/CustomForm/customForm.service";
import { Button, Row, Col, Table, Drawer } from "antd";
import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import AppLoader from "../../../shared/components/AppLoader";
import CustomFormWrapper from "../CustomFormWrapper";
import { CustomFormTypeEnum } from "../../../enums/customFormType.enum";
import { ResponsibilityTypes } from "../../../enums/responsebily.enum";
import { TableColumn } from "../../../shared/Types/tableColumn";
import Stats from "../../../shared/components/Stats";
import { StatsType } from "../../../enums/statsType.enum";
import { constants } from "zlib";
import { useMetrics } from "../../../shared/hooks/Metrics/useMetrics";
import useFilters from "../../../shared/hooks/useFilter/useFilters";
import { PaginationDetails } from "../../../models/Pagination/pagination.model";
import { TablePaginationConfig } from "antd/lib/table";
import { FilterParams } from "../../../models/FilterParams/filterParams.model";
import useResponsibilities from "../../../shared/hooks/Responsibilities/useResponsibilities";

interface CustomFormListProps {}

function CustomFormList(props: CustomFormListProps) {
  const { hasAccess } = useResponsibilities();

  const [customForms, setCustomForms] = useState<CustomForm[]>([]);

  const [activeCustomForm, setActiveCustomForm] = useState(new CustomForm());

  const [showCustomForm, setShowCustomForm] = useState(false);

  const [loading, setLoading] = useState(false);
  const { getParams, setParams } = useFilters();
  const [pagination, setPagination] = useState<PaginationDetails>();

  const [columns, setColumns] = useState<TableColumn[]>([
    {
      title: "Form name",
      dataIndex: "name",
      key: "name",
    },

    {
      title: "Associate Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "No of responses",
      dataIndex: "noOfResponses",
      key: "noOfResponses",
    },
  ]);

  const HAS_CUSTOM_FORM_CREATE_ACCESS = hasAccess(
    ResponsibilityTypes.CUSTOM_FORM_CREATE
  );

  const HAS_CUSTOM_FORM_EDIT_ACCESS = hasAccess(
    ResponsibilityTypes.CUSTOM_FORM_EDIT
  );

  const handleToggleCustomForm = () => setShowCustomForm(!showCustomForm);

  const handleAddCustomForm = () => {
    setActiveCustomForm(new CustomForm());
    handleToggleCustomForm();
  };
  const handleEditCustomForm = (customForm: CustomForm) => () => {
    setActiveCustomForm(customForm);
    handleToggleCustomForm();
  };

  const { metrics } = useMetrics("custom_forms");

  const handleFormSuccess = (customForm: CustomForm) => {
    const customFormIndex = customForms.findIndex(
      (existingForm) => existingForm.id === customForm.id
    );
    if (customFormIndex >= 0) {
      customForms[customFormIndex] = customForm;
    } else {
      customForms.unshift(customForm);
    }
    setCustomForms([...customForms]);
    setShowCustomForm(false);
  };

  const handleFetchCustomForm = (filter: FilterParams) => {
    if (!HAS_CUSTOM_FORM_CREATE_ACCESS) return;
    setLoading(true);
    CustomFormService.fetchCustomForms(
      CustomFormTypeEnum.ALL,
      (customForms: CustomForm[], pagination: PaginationDetails) => {
        setCustomForms(customForms);
        setPagination(pagination);
      },
      () => {},
      () => {
        setLoading(false);
      },
      filter
    );
  };

  useEffect(() => {
    handleFetchCustomForm(getParams());

    if (HAS_CUSTOM_FORM_EDIT_ACCESS) {
      setColumns((columns) => [
        ...columns,
        {
          title: "",
          dataIndex: "id",
          key: "id",
          render: (text: string, record: any, index: number) => {
            return (
              <div>
                <Button
                  type="primary"
                  icon={<EditOutlined />}
                  onClick={handleEditCustomForm(record)}
                />
              </div>
            );
          },
        },
      ]);
    }
  }, []);

  const customFormStatsData = [
    {
      title: "Total number of Requests",
      value: metrics?.noOfRequest ?? 0,
    },
    {
      title: "Submission Received",
      value: metrics?.noOfSubmissions ?? 0,
    },
  ];
  const handleTableChange = (pagination: TablePaginationConfig) => {
    const prevFilter = getParams();
    const updatedFilters = {
      ...prevFilter,
      page: pagination.current ?? 1,
    };
    setParams(updatedFilters);
    handleFetchCustomForm(updatedFilters);
  };

  return (
    <div className="custom-form-list">
      <Row className="mt-3 mb-3">
        <Col span={12}></Col>
        <Col span={12} className="text-right">
          {HAS_CUSTOM_FORM_CREATE_ACCESS && (
            <Button type="primary" onClick={handleAddCustomForm}>
              <PlusOutlined />
              Add Form
            </Button>
          )}
        </Col>
      </Row>

      <div>
        {loading ? (
          <AppLoader loading={loading} />
        ) : (
          <>
            {" "}
            <Stats
              statsData={customFormStatsData}
              statsType={StatsType.CUSTOM_FORMS}
            />
            <Table
              dataSource={customForms}
              columns={columns}
              onChange={handleTableChange}
              pagination={{
                total: pagination?.totalCount,
                current: pagination?.currentPage ?? 1,
                showTotal: (total: number, range: [number, number]) => (
                  <p>
                    Showing <b>{` ${range[0]} - ${range[1]}`}</b> of{" "}
                    <b>{`${total.toLocaleString()}`}</b>
                  </p>
                ),
              }}
            />
          </>
        )}
      </div>
      <Drawer
        title={
          <div>
            <h4>{activeCustomForm?.id ? "Edit Form" : "Add Form"}</h4>
          </div>
        }
        placement="right"
        keyboard={false}
        maskClosable={false}
        onClose={handleToggleCustomForm}
        visible={showCustomForm}
        destroyOnClose={true}
        width="60%"
      >
        <CustomFormWrapper
          customForm={activeCustomForm}
          onSuccess={handleFormSuccess}
          onCancel={handleToggleCustomForm}
        />
      </Drawer>
    </div>
  );
}

export default CustomFormList;
