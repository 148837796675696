import { Button, Col, Row } from "antd";
import React, { FC, useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Attachment } from "../../../../models/Attachment/attachment.model";
import { Support } from "../../../../models/Support/support.model";
import SupportService from "../../../../services/Support/support.service";
import PDF from "../../../../assets/images/pdf.png";
import "./attachments.scss";
import useResponsibilities from "../../../../shared/hooks/Responsibilities/useResponsibilities";
import { ResponsibilityTypes } from "../../../../enums/responsebily.enum";

interface AttachmentsProps {
  support?: Support;
}

const Attachments: FC<AttachmentsProps> = (props) => {
  const { support } = props;

  const { hasAccess } = useResponsibilities();

  const fileRef: any = useRef(null);

  const [loading, setLoading] = useState(false);

  const [attachments, setAttachments] = useState<Attachment[]>([]);

  const HAS_ISSUE_EDIT_ACCESS = hasAccess(ResponsibilityTypes.ISSUE_EDIT);

  useEffect(() => {
    SupportService.viewAttachments(
      support?.id ?? 0,
      (attachments) => {
        setAttachments(attachments);
      },
      () => {}
    );
  }, []);

  const handleUpload = () => {
    fileRef?.current?.click();
  };

  const uploadImage = (file: File) => {
    setLoading(true);
    SupportService.addAttachments(
      support?.id ?? 0,
      file,
      (attachment) => {
        setAttachments((attachments) => [...attachments, attachment]);
      },
      () => {
        setLoading(false);
      }
    );
  };

  return (
    <div className="attachments">
      {HAS_ISSUE_EDIT_ACCESS && <Row justify="end">
        <Col>
          <input
            ref={fileRef}
            type="file"
            hidden
            onChange={(e) => {
              if (e.target.files?.length) {
                uploadImage(e.target.files[0]);
              }
            }}
          />
          <Button type="primary" onClick={handleUpload} loading={loading}>
            Upload Attachment
          </Button>
        </Col>
      </Row>}

      {attachments.map((attachmentFile) =>
        attachmentFile?.attachmentUrl ? (
          <Row className="list__attachments" align="middle">
            <Col span={2}>
              <img src={PDF} alt="" height="48px" />
            </Col>
            <Col span={22}>
              <a href={attachmentFile?.attachmentUrl} target="_blank">
                {attachmentFile?.attachment}
              </a>
            </Col>
          </Row>
        ) : null
      )}
    </div>
  );
};

export default Attachments;
