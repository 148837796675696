import { serializable, alias, primitive } from "serializr";

export class ObjectCategory {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("name", primitive()))
  name?: string;

  @serializable(alias("object_type", primitive()))
  objectType?: string;
}