import { deserialize, serialize } from "serializr";
import axiosInstance from "../../interceptor/axiosInstance";
import ApiRoutes from "../../routes/routeConstants/apiRoutes";
import { ServiceCategory } from "../../models/ServiceCategory/serviceCategory.model";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import { ServiceSubCategory } from "../../models/ServiceSubCategory/serviceSubCategory.model";

export class ServiceCategoryService {
  static fetchServiceCategories(
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .get(ApiRoutes.SERVICE_CATEGORIES)
      .then((response) => {
        const serviceCategories = deserialize(
          ServiceCategory,
          response.data["service_categories"]
        );
        onSuccess(serviceCategories);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static fetchServiceSubCategories(
    serviceCategoryId: number,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.SERVICE_SUB_CATEGORIES.replace(
      ":serviceCategoryId",
      serviceCategoryId.toString()
    );
    axiosInstance
      .get(API_URL)
      .then((response) => {
        const serviceSubCategories = deserialize(
          ServiceSubCategory,
          response.data["service_sub_categories"]
        );
        onSuccess(serviceSubCategories);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static createServiceSubCategory(
    serviceCategoryId: number,
    serviceSubCategory: ServiceSubCategory,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.SERVICE_SUB_CATEGORIES.replace(
      ":serviceCategoryId",
      serviceCategoryId.toString()
    );
    const subCategoryJSON = {
      service_sub_category: serialize(serviceSubCategory),
    };
    return axiosInstance
      .post(API_URL, subCategoryJSON)
      .then((response) => {
        const serviceSubCategory = deserialize(
          ServiceSubCategory,
          response.data["service_sub_category"]
        );
        onSuccess(serviceSubCategory);
      })
      .catch((error) => {
        onError();
      })
      .finally(onFinal);
  }
}
