export const generateFixedDecimal = (
  num: string,
  decimalPoints = 2
): number => {
  const roundAmount = Math.pow(10, decimalPoints);

  const number = parseFloat(num);

  const roundedNumber = Math.trunc(number * roundAmount) / roundAmount;

  return isNaN(roundedNumber) ? 0 : roundedNumber;
};
