import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { Patient } from "../../../models/Patient/patient.model";
import PatientService from "../../../services/Patient/patient.service";
import { SelectProp } from "../../Types/option";

export const usePatients = (type?: "option") => {
  const [patient, setPatient] = useState<Patient[] | SelectProp[]>([]);
  useEffect(() => {
    PatientService.fetchPatients(
      {},
      (patients: Patient[]) => {
        if (type) {
          const options = patients?.map((patient) => {
            const content = (
              <Row className="patient-option__wrapper">
                <Col span={8}>
                  <Row>
                    <Col span={24}>
                      {patient?.firstName} {patient?.lastName}
                    </Col>
                    <Col span={24} className="patient-email">
                      {patient?.email}
                    </Col>
                  </Row>
                </Col>
                <Col span={8}>{patient?.mobileNumber}</Col>
                <Col span={8}>{patient?.email}</Col>
              </Row>
            );
            return {
              value: `${patient.firstName} ${patient.lastName}`,
              label: content,
              key: patient.id,
            };
          });

          setPatient(options);
          return;
        }
        setPatient(patients);
      },
      () => {},
      () => {}
    );
  }, []);

  return patient;
};
