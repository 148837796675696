import React, { useEffect, useRef, useState } from "react";
import "./attachmentUpload.scss";
import { ErrorMessage, Field } from "formik";
import Error from "../Error";
import { Row, Col } from "antd";
import _ from "lodash";
import { UploadOutlined } from "@ant-design/icons";
import FileDetail from "../FileDetail";
import helpers from "../../utils/helpers";
interface AttachmentUploadProps {
  name: string;
  title?: string;
  placeholder: string;
  multiple?: boolean;
  accept: string;
  attachments: string[];
  newAttachments: File[];
  setFieldValue: Function;
  value?: number | string;
}

function AttachmentUpload(props: AttachmentUploadProps) {
  let inputRef = useRef<HTMLInputElement>(null);

  const {
    name,
    title,
    placeholder,
    multiple,
    attachments,
    setFieldValue,
    newAttachments,
    accept,
    value,
  } = props;

  const [newAttachmentUrls, setNewAttachmentUrls] = useState<string[]>([]);

  const [content, setContent] = useState("");

  useEffect(() => {
    setContent(placeholder ?? "");
  }, [placeholder]);

  const handleClick = helpers.debounce(() => {
    console.log(inputRef);
    inputRef?.current?.click();
  }, 500);

  const handleChange = (e: any) => {
    if (multiple) {
      const files: File[] = newAttachments.concat(Array.from(e.target.files));
      setFieldValue(name, files);
    } else {
      setFieldValue(name, e.target.files[0]);
    }
  };

  const handleDragIn = (e: DragEvent) => {
    e.preventDefault();
    setContent("Drop here");
  };

  const handleDrop = (e: DragEvent) => {
    e.preventDefault();
    if (multiple) {
      const files: File[] = newAttachments.concat(
        Array.from(e.dataTransfer?.files ?? [])
      );
      setFieldValue(name, files);
    } else {
      setFieldValue(name, e.dataTransfer?.files[0]);
    }
    setContent(placeholder ?? "");
  };

  const handleDragLeave = (e: DragEvent) => {
    e.preventDefault();
    setContent(placeholder ?? "");
  };

  const initDragAndDrop = () => {
    if (title) {
      const dragArea = document.getElementById(title?.replace(/ /g, "-"));

      dragArea?.addEventListener("dragenter", handleDragIn);

      dragArea?.addEventListener("dragleave", handleDragLeave);

      dragArea?.addEventListener("dragover", handleDragIn);

      dragArea?.addEventListener("drop", handleDrop);

      return dragArea;
    }
  };

  useEffect(() => {
    const dragArea = initDragAndDrop();

    const attachmentUrls: string[] = [];

    if (newAttachments.length > 0) {
      newAttachments.forEach((file: any) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          if (typeof reader.result === "string") {
            attachmentUrls.push(reader.result);
            setNewAttachmentUrls([...attachmentUrls]);
          }
        };
        reader.onerror = function (error) {};
      });
    } else {
      setNewAttachmentUrls([]);
    }

    return () => {
      if (dragArea) {
        dragArea?.removeEventListener("dragenter", handleDragIn);

        dragArea?.removeEventListener("dragleave", handleDragLeave);

        dragArea?.removeEventListener("dragover", handleDragIn);

        dragArea?.removeEventListener("drop", handleDrop);
      }
    };
  }, [newAttachments]);

  return (
    <Field name={name}>
      {({ field, form: { touched, errors }, meta }: any) => (
        <div className="attachment-upload">
          {title && <div className="attachment-upload__title">{title}</div>}
          <Row className="attachment-upload__attachment-list" gutter={[15, 15]}>
            <Col span={24} className="attachment-upload__img-wrapper">
              <div
                className="attachment-upload__input"
                onClick={handleClick}
                id={title?.replace(/ /g, "-")}
              >
                <UploadOutlined className="mr-2" />
                {content}
              </div>
              <input
                ref={inputRef}
                type="file"
                multiple={multiple}
                onChange={handleChange}
                accept={accept}
                style={{ display: "none" }}
              />
            </Col>
            {newAttachmentUrls?.map((attachmentUrl: string, i: number) => {
              return (
                <Col
                  span={6}
                  className="attachment-upload__img-wrapper"
                  key={i}
                >
                  {attachmentUrl.startsWith("data:image") ? (
                    <img
                      src={attachmentUrl}
                      className="attachment-upload__img"
                      alt="Attachment"
                    />
                  ) : (
                    <FileDetail name={newAttachments[i]?.name} />
                  )}
                </Col>
              );
            })}
            <Col span={6}>
              {newAttachmentUrls.length
                ? null
                : attachments?.map(
                    (url, index) =>
                      url && (
                        <img
                          src={url}
                          key={index}
                          className="attachment-upload__img"
                        />
                      )
                  )}
            </Col>
          </Row>
          <ErrorMessage name={name}>
            {(message: string) => <Error message={message} />}
          </ErrorMessage>
        </div>
      )}
    </Field>
  );
}

export default AttachmentUpload;
