import React, { FC } from "react";
import RestrictedImage from "../../assets/images/restricted.png";
import "./restrictedPage.scss";

interface RestrictedPageProps {
  // type: ResponsibilityTypes
}

const RestrictedPage: FC<RestrictedPageProps> = (props) => {
  const {} = props;

  return (
    <div className="restricted-page">
      <div className="content__wrapper">
        <img src={RestrictedImage} alt="" />
        <p>You have no access to this page</p>
      </div>
    </div>
  );
};

export default RestrictedPage;
