import { serializable, alias, primitive } from "serializr";

export class Servicemetrics {
  @serializable(alias("average_service_value", primitive()))
  averageServiceValue?: number;
  @serializable(alias("total_no_of_services", primitive()))
  totalNoOfServices?: number;
}
export class Service {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("name", primitive()))
  name?: string;

  @serializable(alias("sub_category", primitive()))
  subCategory?: string;

  @serializable(alias("service_category_id", primitive()))
  serviceCategoryId?: number;

  @serializable(alias("service_category_name", primitive()))
  serviceCategoryName?: string;

  @serializable(alias("service_sub_category_id", primitive()))
  serviceSubCategoryId?: number;

  @serializable(alias("service_sub_category_name", primitive()))
  serviceSubCategoryName?: string;

  @serializable(alias("status", primitive()))
  status?: string = "active";
  @serializable(alias("treatment_template_plan_id", primitive()))
  treatmentTemplatePlanId?: number;

  @serializable(alias("min_price", primitive()))
  minPrice?: number;

  @serializable(alias("max_price", primitive()))
  maxPrice?: number;
  @serializable(alias("default_appointment_type_name", primitive()))
  defaultAppointmentTypeName?: string;
  @serializable(alias("default_appointment_type_id", primitive()))
  defaultAppointmentTypeId?: number;

  @serializable(alias("default_treatment_plan_template_name", primitive()))
  defaultTreatmentPlanTemplateName?: string;
  @serializable(alias("default_treatment_plan_template_id", primitive()))
  defaultTreatmentPlanTemplateId?: number;
}
