import React from "react";
import underDevelopmentPlaceholder from "../../../assets/images/underDevelopment.png";
import "./underDevelopment.scss";
interface UnderDevelopmentProps {}

function UnderDevelopment(props: UnderDevelopmentProps) {
  return (
    <div className="under-development">
      <img src={underDevelopmentPlaceholder} alt="Module Under development" />
    </div>
  );
}

export default UnderDevelopment;
