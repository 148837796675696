import { Button, Col, Drawer, Row, Table, Modal, Switch, Tooltip } from "antd";
import React, { FC, useEffect, useState } from "react";
import { Practice } from "../../../models/Practice/practice.model";
import PracticeService from "../../../services/Practice/practice.service";
import Stats from "../../../shared/components/Stats";
import {
  PlusOutlined,
  EditOutlined,
  CheckOutlined,
  CloseOutlined,
  CopyOutlined,
} from "@ant-design/icons";
import PracticeForm from "../../DetailedOnboarding/PracticeForm";
import { generatePath, useHistory } from "react-router";
import AppRoutes from "../../../routes/routeConstants/appRoutes";
import "./practices.scss";
import AuthContainer from "../../../store/container/AuthContainer";
import { AuthReducerProps } from "../../../store/reducers/authReducer";
import { ResponsibilityTypes } from "../../../enums/responsebily.enum";
import { TableColumn } from "../../../shared/Types/tableColumn";
import AppLoader from "../../../shared/components/AppLoader";
import { StatsType } from "../../../enums/statsType.enum";
import { useMetrics } from "../../../shared/hooks/Metrics/useMetrics";
import { StatusEnum } from "../../../enums/status.enum";
import MetricsService from "../../../services/Metrics/metrics.service";
import { Metrics } from "../../../models/Metrics/metrics.model";
import { PaginationDetails } from "../../../models/Pagination/pagination.model";
import useFilters from "../../../shared/hooks/useFilter/useFilters";
import { TablePaginationConfig } from "antd/lib/table";
import { FilterParams } from "../../../models/FilterParams/filterParams.model";
import useResponsibilities from "../../../shared/hooks/Responsibilities/useResponsibilities";
import CorporateContainer from "../../../store/container/CorporateContainer";
import { CorporateReducerProps } from "../../../store/reducers/corporateReducer";

interface PracticesProps extends AuthReducerProps, CorporateReducerProps {}

const Practices: FC<PracticesProps> = ({
  userResponsibilities,
  setResponsibilities,
  practices,
  setPractices,
  practiceLoading,
}) => {
  const { hasAccess } = useResponsibilities();

  const [metrics, setMetrics] = useState<Metrics>();

  const [practiceForm, setPracticeForm] = useState(false);

  const [editPractice, setEditPractice] = useState<Practice>();
  const [loading, setLoading] = useState(false);

  const { getParams, setParams } = useFilters();

  const history = useHistory();

  const HAS_PRACTICE_VIEW_ACCESS = hasAccess(ResponsibilityTypes.PRACTICE_VIEW);

  const HAS_PRACTICE_CREATE_ACCESS = hasAccess(
    ResponsibilityTypes.PRACTICE_CREATE
  );

  const HAS_PRACTICE_EDIT_ACCESS = hasAccess(ResponsibilityTypes.PRACTICE_EDIT);

  const handleFetchMetrics = () => {
    MetricsService.fetchMetrics(
      "practices",
      (metrics: Metrics) => {
        setMetrics(metrics);
      },
      () => {},
      () => {}
    );
  };

  const handleOnEditSuccess = (practice: Practice) => {
    const updatedPractice = practices.map((oldPractice) =>
      oldPractice.id === practice.id ? practice : oldPractice
    );
    setPractices(updatedPractice);
    setEditPractice(undefined);
    setPracticeForm(false);
  };

  const handleUpdatePractice = (practice: Practice, checked: boolean) => {
    setLoading(true);
    PracticeService.updatePractice(
      Object.assign(new Practice(), {
        ...practice,
        status: checked ? StatusEnum.ACTIVE : StatusEnum.INACTIVE,
      }),
      (updatedPractice: Practice) => {
        handleOnEditSuccess(updatedPractice);
      },
      () => {},
      () => {
        setLoading(false);
      }
    );
  };
  const [columns, setColumns] = useState<TableColumn[]>([
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Chair",
      dataIndex: "chairCount",
      key: "chairCount",
      render: (count: string) => (count ? <span>{count}</span> : "--"),
    },
    {
      title: "Mobile",
      dataIndex: "mobileNumber",
      key: "mobileNumber",
      render: (mobileNumber: string) => (mobileNumber ? mobileNumber : "--"),
    },
    {
      title: "Responsible person",
      dataIndex: "contactPerson",
      key: "contactPerson",
      render: (contactPerson: string) => (contactPerson ? contactPerson : "--"),
    },
    {
      title: "Labs",
      dataIndex: "labCount",
      key: "labCount",
      render: (labCount: string) => (labCount ? labCount : "--"),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",

      render: (status: string, practice) => (
        <div
          className={` text-capitalize ${
            status === StatusEnum.ACTIVE
              ? "text-primary"
              : status === StatusEnum.INACTIVE
              ? "text-danger"
              : ""
          }`}
        >
          {status ? status : "--"}
        </div>
      ),
    },
  ]);

  useEffect(() => {
    handleFetchMetrics();

    if (HAS_PRACTICE_EDIT_ACCESS) {
      setColumns((columns) => [
        ...columns,
        {
          title: "",

          dataIndex: "id",
          key: "id",

          render: (id: number, practice: Practice, index: number) => {
            const handleOnCheck = (checked: boolean) => {
              Modal.confirm({
                title: `Are you sure you want to make status ${
                  checked ? "Active" : "Inactive"
                } ?`,
                onOk: () => {
                  handleUpdatePractice(practice, checked);
                  handleFetchMetrics();
                },
              });
            };
            return (
              <span
                className="practice__switch"
                onClick={(event) => event.stopPropagation()}
              >
                <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  checked={practice.status === StatusEnum.ACTIVE}
                  onChange={handleOnCheck}
                />
              </span>
            );
          },
        },
        {
          title: "",
          dataIndex: "",
          key: "",
          render: (_: any, practice: Practice) => (
            <Button
              className="practice__edit-btn"
              type="primary"
              onClick={(event) => {
                event.stopPropagation();
                setPracticeForm(true);
                setEditPractice(practice);
              }}
            >
              <EditOutlined />
            </Button>
          ),
        },
      ]);
    }
  }, []);

  const closePracticeForm = () => {
    setPracticeForm(false);
    setEditPractice(undefined);
  };

  const handleRow = (practice: Practice) => ({
    onClick: () => {
      if (HAS_PRACTICE_VIEW_ACCESS) {
        const RedirectURL = generatePath(AppRoutes.PRACTICE_DETAILS, {
          id: practice.id,
        });
        history.push({
          pathname: RedirectURL,
          state: {
            practice,
          },
        });
      }
    },
  });

  const practiceStatsData = [
    {
      title: "Total number of Practices",
      value: metrics?.totalNoOfPractices ?? 0,
    },
    {
      title: "Active Practices",
      value: metrics?.activePractices ?? 0,
    },
    {
      title: "Inactive Practices",
      value: metrics?.inactivePractices ?? 0,
    },
  ];
  const handleTableChange = (pagination: TablePaginationConfig) => {
    const prevFilter = getParams();
    const updatedFilters = {
      ...prevFilter,
      page: pagination.current ?? 1,
    };
    setParams(updatedFilters);
  };

  const handleSuccess = (practice: Practice) => {
    if (getParams()?.page !== 1) handleTableChange({});
    setPractices([practice, ...practices]);
    setPracticeForm(false);
    handleFetchMetrics();
  };

  return (
    <div className="practices">
      <Row>
        <Col span={12}></Col>
        <Col span={12} className="practice__add">
          {HAS_PRACTICE_CREATE_ACCESS && (
            <Button type="primary" onClick={() => setPracticeForm(true)}>
              <PlusOutlined /> Add Practice
            </Button>
          )}
        </Col>
      </Row>

      {loading || !!practiceLoading ? (
        <AppLoader loading={loading || !!practiceLoading} />
      ) : (
        <>
          {" "}
          <Stats statsData={practiceStatsData} statsType={StatsType.PRACTICE} />
          <Table
            dataSource={practices}
            columns={columns}
            onRow={handleRow}
            onChange={handleTableChange}
            pagination={{
              total: metrics?.totalNoOfPractices,
              pageSize: 10,
              current: getParams()?.page,
              showTotal: (total: number, range: [number, number]) => (
                <p>
                  Showing <b>{` ${range[0]} - ${range[1]}`}</b> of{" "}
                  <b>{`${total.toLocaleString()}`}</b>
                </p>
              ),
            }}
          />
        </>
      )}
      {/* Practice Form */}
      <Drawer
        title={editPractice ? "Edit Practice" : "Add Practice"}
        visible={
          (HAS_PRACTICE_CREATE_ACCESS || HAS_PRACTICE_EDIT_ACCESS) &&
          practiceForm
        }
        destroyOnClose
        closable
        width="60%"
        onClose={closePracticeForm}
      >
        <PracticeForm
          practice={editPractice ?? new Practice()}
          onSuccess={handleSuccess}
          onEditSuccess={handleOnEditSuccess}
          onCancel={closePracticeForm}
        />
      </Drawer>
    </div>
  );
};

export default AuthContainer(CorporateContainer(Practices));
