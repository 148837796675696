import React, { useEffect, useState } from "react";
import { IDropdownOptions } from "../../../../../services/Meta/meta.service";
import CorporateObjectService from "../../../../../services/CorporateObject/corporateObject.service";
import { CorporateObject } from "../../../../../models/CorporateObject/corporateObject.model";
import { getDropdownOptions } from "../../../../../shared/utils/optionHelper";
import { Form, Formik, FormikValues } from "formik";
import { Button, Col, Row, message } from "antd";
import DropdownField from "../../../../../shared/components/DropdownField";
import { SetStep } from "../../../../../models/SetStep/setStep.model";
import SetService from "../../../../../services/Set/set.service";
import "./setStepForm.scss";
import { DentalObjectTypeEnum } from "../../../../../enums/dentalObjectType.enum";
import { DentalObject } from "../../../../../models/DentalObject/dentalObject.model";
import CorporateLinkService from "../../../../../services/CorporateLink/corporateLink.service";
import { CorporateLink } from "../../../../../models/CorporateLink/corporateLink.model";
import { SetReducerProps } from "../../../../../store/reducers/setReducer";
import SetContainer from "../../../../../store/container/SetContainer";
import { DentalSet } from "../../../../../models/DentalSet/dentalSet.model";
import {
  getToothPositionId,
  handleImportVendorObject,
} from "../../../../../shared/utils/babylonUtils";
import { BabylonReducerProps } from "../../../../../store/reducers/babylonReducer";
import BabylonContainer from "../../../../../store/container/BabylonContainer";
import { checkConflict } from "../../../../../shared/utils/babylonUtils";
import { AbstractMesh } from "@babylonjs/core";
import { CONFLICT_TYPES } from "../../../../../enums/conflictType.enum";
import ConflictManagement from "../../../../../shared/components/ConflictManagement";

interface SetStepFormProps extends SetReducerProps, BabylonReducerProps {
  srcObject?: DentalObject;
  setOpenConflict: (show: boolean) => void;
  createSetStep: (setStep: SetStep, complete?: () => void) => void;
}

function SetStepForm({
  currentSet,
  setCurrentSet,
  sourceObject,
  scene,
  highlightLayer,
  corporateObjects,
  toothPositions,
  setSourceObject,
  setShowPopover,
  setPopoverPosition,
  pushConflict,
  createSetStep,
  setOpenConflict,
  setMeshLoading,
}: SetStepFormProps) {
  const [formValues, setFormValues] = useState({
    ...new SetStep(),
    toothLink: {},
  });

  const [formLoading, setFormLoading] = useState(false);

  const [vendorObjectLoading, setVendorObjectLoading] = useState(false);

  const [linkLoading, setLinkLoading] = useState(false);

  const [vendorObjectOptions, setVendorObjectOptions] = useState<
    IDropdownOptions[]
  >([]);

  const [linkOptions, setLinkOptions] = useState<IDropdownOptions[]>([]);

  const handleFetchLinks = (srcObjectId?: number, destObjectId?: number) => {
    setLinkLoading(true);
    if (srcObjectId && destObjectId) {
      CorporateLinkService.fetchCorporateLinks(
        null,
        srcObjectId,
        destObjectId,
        (corporateLinks: CorporateLink[]) => {
          setLinkOptions(
            corporateLinks.map((link) => ({
              label: link.srcLocatorName + " - " + link.destLocatorName,
              value: link.id,
              ...link,
            }))
          );
        },
        () => {},
        () => {
          setLinkLoading(false);
        }
      );
    }
  };

  const handleSubmit = async (values: any) => {
    try{
    setMeshLoading(true);
    const mesh = scene?.getMeshByName(
      (sourceObject as CorporateObject)?.name ?? ""
    );
    const setStep = Object.assign(new SetStep(), values);
    if (mesh && values?.toothLink) {
      const conflict = await checkConflict(
        mesh,
        values.toothLink?.srcObjectName,
        values.toothLink?.srcLocatorName,
        values.toothLink?.destObjectName
      );
      if (conflict === CONFLICT_TYPES.HARD_APPLY) {
        pushConflict(setStep);
        setShowPopover(false);
        setOpenConflict(true);
      }

      if (!conflict) {
        await new Promise((resolve) => {
          setFormLoading(true);
          createSetStep(setStep, () => {
            setFormLoading(false);
            resolve(null);
            setMeshLoading(false);
          });
      })
      }
    }}catch(ex){}finally{
      setMeshLoading(false);
    }
  };

  useEffect(() => {
    setVendorObjectLoading(true);
    CorporateObjectService.fetchCorporateObjects(
      {},
      DentalObjectTypeEnum.VENDOR,
      (corporateObjects: CorporateObject[]) => {
        setVendorObjectOptions(
          getDropdownOptions(corporateObjects, "name", "id")
        );
        setVendorObjectLoading(false);
      },
      () => {},
      () => {},
      {
        //  Future problem Array problem
        dental_object_id: Array.isArray(sourceObject)
          ? undefined
          : sourceObject?.dentalObjectId,
      }
    );
  }, [sourceObject]);

  return (
    <div className="set-step-form">
      <h5 className="text-primary">
        <Formik
          initialValues={formValues}
          onSubmit={handleSubmit}
          enableReinitialize
          validationSchema={null}
        >
          {({ values, isValid, dirty, setFieldValue }) => {
            return (
              <Form>
                <div className="set-step-form__form-body">
                  <Row gutter={[20, 20]}>
                    <Col span={24}>
                      <h5 className="text-primary"> Vendor Object</h5>
                      <DropdownField
                        name="destObjectId"
                        placeHolder="Select Vendor Object"
                        showSearch
                        loading={vendorObjectLoading}
                        onChange={(value) => {
                          setFieldValue("destObjectId", value);
                          handleFetchLinks(
                            (sourceObject as CorporateObject)?.id,
                            value
                          );
                        }}
                        options={vendorObjectOptions}
                        value={values.destObjectId}
                      />
                    </Col>
                    <Col span={24}>
                      <h5 className="text-primary">
                        Source Locator - Destination Locator
                      </h5>
                      <DropdownField
                        name="corporateLinkId"
                        placeHolder="Select Link"
                        showSearch
                        loading={linkLoading}
                        onChange={(_, values) => {
                          setFieldValue("corporateLinkId", values.value);
                          setFieldValue("toothLink", values);
                        }}
                        options={linkOptions}
                        value={values.corporateLinkId}
                      />
                    </Col>
                  </Row>
                </div>
                <div className="set-step-form__form-body__submit-wrapper text-right">
                  <Button
                    type="primary"
                    className="ml-2"
                    htmlType="submit"
                    loading={formLoading}
                    disabled={!isValid || !dirty || formLoading}
                  >
                    Add
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </h5>
    </div>
  );
}

export default BabylonContainer(SetContainer(SetStepForm));
