import { serializable, alias, primitive } from "serializr";

export class CustomFormRequests {
    @serializable(alias("id", primitive()))
    id?: number;

    @serializable(alias("custom_form_id", primitive()))
    customFormId?: number;

    @serializable(alias("custom_form_name", primitive()))
    custotFormName?: string;

    @serializable(alias("requestable_id", primitive()))
    requestableId?: number;

    @serializable(alias("requestable_type", primitive()))
    requestableType?: string;

    @serializable(alias("custom_form_response_id", primitive()))
    customFormResponseId?: string;
    
    @serializable(alias("created_by", primitive()))
    createdBy?: number;

    @serializable(alias("created_by_name", primitive()))
    createdByName?: string;
}