import {alias, primitive, serializable} from "serializr";

export class IsdCode {
    @serializable(alias('id', primitive()))
    id?: string;

    @serializable(alias('isd_code', primitive()))
    isdCode?: string;

    @serializable(alias('country_code', primitive()))
    countryCode?: string;
}
