import { Col, Row, Tabs } from "antd";
import React, { FC } from "react";
import Chart from "../Chart";
import { Interaction } from "../../../../models/Interaction/interaction.model";
import "./historyChart.scss";
import { ContractVisit } from "../../../../models/ContractVisit/contractVisit.model";
import InteractionContainer from "../../../../store/container/InteractionContainer";
import { InteractionReducerProps } from "../../../../store/reducers/interactionReducer";

const { TabPane } = Tabs;

interface HistoryChartProps extends InteractionReducerProps {
	interaction: Interaction;
}

const HistoryChart: FC<HistoryChartProps> = (props) => {
	const { interaction, contractInteractions } = props;

	const TabPanes = [
		{
			title: "Elastic Compliance",
			content: (
				<Chart
					points={contractInteractions.map(
						({ elasticCompliance }) => elasticCompliance ?? 0
					)}
				/>
			),
		},
		{
			title: "Oral Hygiene",
			content: (
				<Chart
					points={contractInteractions.map(
						({ oralHygiene }) => oralHygiene ?? 0
					)}
				/>
			),
		},
	];

	return (
		<div className="history-chart">
			<Tabs className="status-tabs" type="card">
				{TabPanes.map(({ title, content }, index) => (
					<TabPane tab={title} tabKey={index.toString()} key={index}>
						{content}
					</TabPane>
				))}
			</Tabs>
			<div className="patient__detail">
				<Row>
					<Col span={6}>Patient</Col>
					<Col span={2}>:</Col>
					<Col span={16}>
						{interaction.appointment?.patientFirstName +
							" " +
							interaction.appointment?.patientLastName}
					</Col>
				</Row>
				<Row>
					<Col span={6}>Treatment</Col>
					<Col span={2}>:</Col>
					<Col span={16}>
						{interaction.appointment?.appointmentTypeName}
					</Col>
				</Row>
			</div>
		</div>
	);
};

export default InteractionContainer(HistoryChart);
