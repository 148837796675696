import React, { useMemo, useState } from "react";
import "./serviceTabs.scss";
import { Tabs } from "antd";
import ServiceList from "../ServiceList";
import ContractTermsTemplate from "../ContractTermsTemplate";
import Contracts from "../Contracts";
import { useEffect } from "react";
import { AuthReducerProps } from "../../../store/reducers/authReducer";
import AuthContainer from "../../../store/container/AuthContainer";
import { ResponsibilityTypes } from "../../../enums/responsebily.enum";
import { useHistory, useLocation } from "react-router-dom";
import AppRoutes from "../../../routes/routeConstants/appRoutes";
import { TabProps } from "../../../shared/Types/tab.type";
import useFilters from "../../../shared/hooks/useFilter/useFilters";
import useResponsibilities from "../../../shared/hooks/Responsibilities/useResponsibilities";

const { TabPane } = Tabs;

interface LocationProps {
  serviceId: string;
  termOfServiceId: string;
}

interface ServiceTabsProps extends AuthReducerProps {}

function ServiceTabs({}: ServiceTabsProps) {
  const location = useLocation<LocationProps>();
  const history = useHistory();

  const { hasAccess } = useResponsibilities();

  const HAS_SERVICE_VIEW_ACCESS = hasAccess(ResponsibilityTypes.SERVICE_VIEW);

  const HAS_CONTRACT_TERM_VIEW_ACCESS = hasAccess(
    ResponsibilityTypes.CONTRACT_TERM_VIEW
  );

  const HAS_PATIENT_CONTRACT_VIEW_ACCESS = hasAccess(
    ResponsibilityTypes.PATIENT_CONTRACT_VIEW
  );

  const { getParams, setParams } = useFilters();

  const [tab, setTab] = useState("0");

  const serviceTabs = useMemo(
    () => [
      {
        index: "0",
        title: "All services",
        component: <ServiceList />,
        hasAccess: HAS_SERVICE_VIEW_ACCESS,

        hash: "all-services",
      },
      {
        index: "1",
        title: "Contract Terms Templates",
        component: <ContractTermsTemplate />,
        hasAccess: HAS_CONTRACT_TERM_VIEW_ACCESS,
        hash: "contract-terms-templates",
      },
      {
        index: "2",
        title: "Contracts",
        component: <Contracts />,
        hasAccess: HAS_PATIENT_CONTRACT_VIEW_ACCESS,
        hash: "contract",
      },
    ],
    [
      HAS_SERVICE_VIEW_ACCESS,
      HAS_CONTRACT_TERM_VIEW_ACCESS,
      HAS_PATIENT_CONTRACT_VIEW_ACCESS,
    ]
  );

  const [serviceTabDetails, setServiceTabDetails] = useState<TabProps[]>([]);

  useEffect(() => {
    let updatedServiceTab: TabProps[] = [];

    serviceTabs.forEach((tab) => {
      if (!tab.hasAccess) return;

      updatedServiceTab.push(tab);
    });

    setServiceTabDetails(updatedServiceTab);
  }, [serviceTabs]);

  useEffect(() => {
    if (location.state?.termOfServiceId) {
      setTab("1");
    }
  }, []);

  useEffect(() => {
    const tabFromHash = serviceTabs.find(
      (tab) => tab.hash === location.hash.replace("#", "")
    );
    if (tabFromHash) setTab(tabFromHash?.index);
  }, [location.hash]);

  return (
    <div className="service-tabs">
      <Tabs
        activeKey={tab}
        size="small"
        style={{ marginBottom: 32 }}
        onChange={(activeKey) => {
          const hash = serviceTabs?.find(
            (tab, index) => String(index) === activeKey
          )?.hash;

          setParams({}, hash);
          setTab(activeKey);
        }}
      >
        {serviceTabDetails.map((serviceTabDetail, i) => {
          return (
            <TabPane tab={serviceTabDetail.title} key={i}>
              {serviceTabDetail.component}
            </TabPane>
          );
        })}
      </Tabs>
    </div>
  );
}

export default AuthContainer(ServiceTabs);
