import * as Yup from "yup";

export const preDeterminationFormValidation = Yup.object().shape({
  insuranceName: Yup.string().required("Insurance Name is required"),
  subscriberName: Yup.string().required("Subscriber Name is required"),
  address: Yup.string().required("Address is required"),
  groupPolicy: Yup.string().required("Group Policy is required"),
  certificateNumber: Yup.string().required("Certificate Number is required"),
  relationshipToTheSubscriber: Yup.string().required(
    "Relationship is required"
  ),
  initialPayment: Yup.string().required("Initial Payment is required"),
  noOfDues: Yup.string().required("Number of Dues is required"),
  dueAmount: Yup.string().required("Due Amount is required"),
  period: Yup.string().required("Period is required"),
});
