import { CreateReducer } from "../../shared/utils/createReducer";
import { SET_CURRENT_SET } from "../definitions/setConstants";
import { DentalSet } from "../../models/DentalSet/dentalSet.model";
import {
  SetCurrentSetAction,
  SetDentalSetSceneAction,
} from "../actions/setActions";

interface ISetState {
  currentSet?: DentalSet;
}

export interface SetReducerProps extends ISetState {
  setCurrentSet: (currentSet: DentalSet) => SetCurrentSetAction;
}

const initState: ISetState = {
  currentSet: undefined,
};

const setReducer = CreateReducer(initState, {
  [SET_CURRENT_SET](state: any, action: any) {
    const { currentSet } = action.payload;
    return {
      ...state,
      currentSet,
    };
  },
});

export default setReducer;
