import React, { useEffect, useState } from "react";
import "./patientContracts.scss";
import { Patient } from "../../../models/Patient/patient.model";
import { Contract } from "../../../models/Contract/contract.model";
import ContractService from "../../../services/Contract/Contract.service";
import AppLoader from "../../../shared/components/AppLoader";
import ListContract from "../../Services/Contracts/ListContract";
import AppRoutes from "../../../routes/routeConstants/appRoutes";
import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import ContractContainer from "../../../store/container/ContractContainer";
import { ContractReducerProps } from "../../../store/reducers/contractReducer";
import { PaginationDetails } from "../../../models/Pagination/pagination.model";
import { FilterParams } from "../../../models/FilterParams/filterParams.model";
import useFilters from "../../../shared/hooks/useFilter/useFilters";

interface PatientContractsProps extends ContractReducerProps {
  patient?: Patient;
}

function PatientContracts({ patient, resetContract }: PatientContractsProps) {
  const [loading, setLoading] = useState(false);

  const { getParams } = useFilters();

  const [pagination, setPagination] = useState<PaginationDetails>();

  const [contracts, setContracts] = useState<Contract[]>([]);
  const patientUrlParams = new URLSearchParams();

  patientUrlParams.append("patientId", patient?.id?.toString() || "");

  const handleUpdateContract = (updatedContract: Contract[]) => {
    fetchContracts(getParams());
  };

  const fetchContracts = (filters?: FilterParams) => {
    if (patient?.id) {
      setLoading(true);
      ContractService.fetchPatientContracts(
        patient.id,
        (contracts: Contract[], pagination) => {
          setContracts(contracts);
          setPagination(pagination);
        },
        () => {},
        () => {
          setLoading(false);
        },
        filters
      );
    }
  };

  useEffect(() => {
    fetchContracts(getParams());
  }, [patient?.id]);
  return (
    <div>
      <div className="mb-5 text-right">
        <Link
          to={{
            pathname: AppRoutes.ADD_CONTRACT,
            search: patientUrlParams.toString(),
          }}
          onClick={() => {
            resetContract();
          }}
        >
          <Button type="primary" className="ml-3">
            <PlusOutlined />
            Add Contract
          </Button>
        </Link>
      </div>
      {loading ? (
        <AppLoader loading={loading} />
      ) : (
        <ListContract
          contracts={contracts}
          updateContact={handleUpdateContract}
          contractPagination={pagination}
          fetchContracts={fetchContracts}
        />
      )}
    </div>
  );
}

export default ContractContainer(PatientContracts);
