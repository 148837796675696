import { CreateReducer } from "../../shared/utils/createReducer";
import {
	SetCorporateObjectsAction,
	SetHighlightLayerAction,
	SetPopoverPositionAction,
	SetPushConflict,
	SetSceneAction,
	SetShowPopoverAction,
	SetSourceObjectAction,
	SetToothPositionsAction,
} from "../actions/babylonActions";
import {
	PUSH_CONFLICT,
	RESET_BABYLON,
	RESET_CONFLICT,
  	SET_CAPS,
	SET_CAPS_LOADING,
	SET_CORPORATE_OBJECTS,
	SET_CORPORATE_OBJECTS_LOADING,
	SET_HIGHLIGHT_LAYER,
	SET_MESH_LOADING,
	SET_POPOVER_POSITION,
	SET_SCENE,
	SET_SHOW_POPOVER,
	SET_SOURCE_OBJECT,
	SET_SOURCE_OBJECTS,
	SET_TOOTH_POSITIONS,
} from "../definitions/babylonConstants";
import { HighlightLayer, Scene } from "@babylonjs/core";
import { CorporateObject, CorporateObjectWithBabylonFile } from "../../models/CorporateObject/corporateObject.model";
import { ToothPosition } from "../../models/ToothPosition/toothPosition.model";
import { DentalSet } from "../../models/DentalSet/dentalSet.model";
import { ProcedureStep } from "../../models/ProcedureStep/procedureStep.model";
import { InteractionStep } from "../../models/InteractionStep/interactionStep.model";
import { TreatmentPlanTemplateSteps } from "../../models/TreatmentPlanTemplateSteps/treatmentPlanTemplateSteps.model";
import { SetStep } from "../../models/SetStep/setStep.model";
import { LocalStorageKeys } from "../../enums/localStorageKeys.enum";

export interface IPopoverPosition {
	x: number;
	y: number;
}

interface IBabylonState {
	scene?: Scene;
	highlightLayer?: HighlightLayer;
	sourceObject?: CorporateObject | CorporateObject[];
	corporateObjects: CorporateObject[];
	showPopover: boolean;
	popoverPosition: IPopoverPosition;
	toothPositions: ToothPosition[];
	conflicts: SetStep[];
  	caps: CorporateObjectWithBabylonFile[];
	capsLoading: boolean;
	corporateObjectsLoading: boolean;
	meshLoading: boolean;
}

export interface BabylonReducerProps extends IBabylonState {
	setScene: (scene?: Scene) => SetSceneAction;
	setHighlightLayer: (
		highlightLayer: HighlightLayer
	) => SetHighlightLayerAction;
	setSourceObject: (
		sourceObject: CorporateObject | undefined
	) => SetSourceObjectAction;
	setSourceObjects: (sourceObject: CorporateObject) => SetSourceObjectAction;
	setCorporateObjects: (
		corporateObjects: CorporateObject[]
	) => SetCorporateObjectsAction;
	setShowPopover: (showPopover: boolean) => SetCorporateObjectsAction;
	setPopoverPosition: (
		popoverPosition: IPopoverPosition
	) => SetPopoverPositionAction;
	setToothPositions: (
		toothPositions: ToothPosition[]
	) => SetToothPositionsAction;
	pushConflict: (
		conflict:
			| DentalSet
			| ProcedureStep
			| InteractionStep
			| TreatmentPlanTemplateSteps
	) => void;
	resetConflict: () => void;
	resetBabylon: () => void;
	setCaps: (caps: CorporateObjectWithBabylonFile[]) => void;
	setCapsLoading: (loading: boolean) => void;
	setCorporateObjectsLoading: (loading: boolean) => void;
	setMeshLoading: (loading: boolean) => void;
}

const initState: IBabylonState = {
	scene: undefined,
	highlightLayer: undefined,
	sourceObject: undefined,
	corporateObjects: JSON.parse(localStorage.getItem(LocalStorageKeys.CORPORATE_OBJECTS) || "[]"),
	showPopover: false,
	popoverPosition: { x: 0, y: 0 },
	toothPositions: [],
	conflicts: [],
  	caps: [],
	corporateObjectsLoading: false,
	meshLoading: false,
	capsLoading: false,
};

const babylonReducer = CreateReducer(initState, {
	[SET_SCENE](state: any, action: any) {
		const { scene } = action.payload;
		return {
			...state,
			scene,
		};
	},
	[SET_HIGHLIGHT_LAYER](state: any, action: any) {
		const { highlightLayer } = action.payload;
		return {
			...state,
			highlightLayer,
		};
	},
	[SET_SOURCE_OBJECT](state: any, action: any) {
		const { sourceObject } = action.payload;
		return {
			...state,
			sourceObject,
		};
	},
	[SET_CORPORATE_OBJECTS](
		state: IBabylonState,
		action: SetCorporateObjectsAction
	) {
		const { corporateObjects } = action.payload;
		return {
			...state,
			corporateObjects,
		};
	},
	[SET_SHOW_POPOVER](state: IBabylonState, action: SetShowPopoverAction) {
		const { showPopover } = action.payload;
		return {
			...state,
			showPopover,
		};
	},
	[SET_POPOVER_POSITION](
		state: IBabylonState,
		action: SetPopoverPositionAction
	) {
		const { popoverPosition } = action.payload;
		return {
			...state,
			popoverPosition,
		};
	},
	[SET_TOOTH_POSITIONS](
		state: IBabylonState,
		action: SetToothPositionsAction
	) {
		const { toothPositions } = action.payload;
		return {
			...state,
			toothPositions,
		};
	},
	[PUSH_CONFLICT](
		state: IBabylonState,
		action: SetPushConflict
	): IBabylonState {
		const conflicts = [...state.conflicts, action?.payload?.conflict];
		return {
			...state,
			conflicts,
		};
	},
	[RESET_CONFLICT](state: IBabylonState): IBabylonState {
		return {
			...state,
			conflicts: [],
		};
	},
	[SET_SOURCE_OBJECTS](state: IBabylonState, action: any): IBabylonState {
		const { sourceObject } = action.payload;
		return {
			...state,
			sourceObject: Array.isArray(state.sourceObject)
				? [...state.sourceObject, sourceObject]
				: state.sourceObject
				? [state.sourceObject, sourceObject]
				: [sourceObject],
		};
	},
	[RESET_BABYLON](state: IBabylonState): IBabylonState {
		const {corporateObjects, corporateObjectsLoading, caps, capsLoading} = state;

		return {
			...initState,
			capsLoading,
			corporateObjects,
			caps,
			corporateObjectsLoading,
		};
	},
	[SET_CAPS]: (state: IBabylonState, action: any): IBabylonState => {
		const { caps } = action.payload;
		return { ...state, caps: Array?.isArray(caps) ? caps : [] };
	},
	[SET_CORPORATE_OBJECTS_LOADING]: (state: IBabylonState, action: any): IBabylonState => {
		const { loading } = action.payload;
		return { ...state, corporateObjectsLoading: !!loading };
	},
	[SET_CAPS_LOADING]: (state: IBabylonState, action: any): IBabylonState => {
		const { loading } = action.payload;
		return { ...state, capsLoading: !!loading };
	},
	[SET_MESH_LOADING]: (state: IBabylonState, action: any): IBabylonState => {
		const { loading } = action.payload;
		return { ...state, meshLoading: loading };
	}
});

export default babylonReducer;
