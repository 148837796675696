import axiosInstance from "../../interceptor/axiosInstance";
import { store } from "../../store";
import { AUTHENTICATED } from "../../store/definitions/authConstants";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import ApiRoutes from "../../routes/routeConstants/apiRoutes";
import AppRoutes from "../../routes/routeConstants/appRoutes";

export default class AuthService {
  static login(
    code: string,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const params = new URLSearchParams();
    params.append("code", code);
    return axiosInstance
      .post(ApiRoutes.TOKEN, params)
      .then((response) => {
        localStorage.setItem("token", response.data.id_token);
        store.dispatch({
          type: AUTHENTICATED,
          payload: {
            authenticated: true,
          },
        });
        Notification({
          message: "Login",
          description: "Logged in successfully",
          type: NotificationTypes.SUCCESS,
        });
        onSuccess();
      })
      .catch((error) => {
        Notification({
          message: "Login failed",
          description: "Unable to Authenticate",
          type: NotificationTypes.ERROR,
          onClose: () => this.logout(() => window.location.href = AppRoutes.LOGIN, () => {}, () => {})
        });
        onError(error);
      })
      .finally(onFinal);
  }

  static logout(onSuccess: Function, onError: Function, onFinal: () => void) {
    localStorage.clear();
    onSuccess();
    /*return axiosInstance
            .delete(ApiRoutes.LOGOUT, params)
            .then((response) => {
                localStorage.setItem("token", response.data.id_token);
                store.dispatch({
                    type: AUTHENTICATED,
                    payload: {
                        authenticated: true,
                    },
                });
                Notification({
                    message: "Login",
                    description: "Logged in successfully",
                    type: NotificationTypes.SUCCESS,
                });
                onSuccess();
            })
            .catch((error) => {
                Notification({
                    message: "Login failed",
                    description: "incorrect email or password",
                    type: NotificationTypes.ERROR,
                });
                onError(error);
            })
            .finally(onFinal);*/
  }
}
