import { Button, Carousel, Col, Modal, Row, Upload, message } from "antd";
import React, {
  RefObject,
  useEffect,
  useRef,
  useState,
  useImperativeHandle,
} from "react";
import rightArrow from "../../../../../../assets/images/right_arrow.png";
import leftArrow from "../../../../../../assets/images/left_arrow.png";
import ContractContainer from "../../../../../../store/container/ContractContainer";
import { ContractReducerProps } from "../../../../../../store/reducers/contractReducer";
import ContractTermTemplateService from "../../../../../../services/ContractTermTemplate/ContractTermTemplate.service";
import {
  ContractTermSendLinkHandler,
  ContractTermTemplate,
} from "../../../../../../models/ContractTermsTemplate/contractTermsTemplate.model";
import SignaturePad from "react-signature-canvas";
import "./agreement.scss";
import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import AppLoader from "../../../../../../shared/components/AppLoader";
import PhotoConsentOptions from "./PhotoConsentOptions";
import { isPdf } from "shared/utils/getFileExtensionFromUrl";

// Import the main component
import { Worker, Viewer } from "@react-pdf-viewer/core";

// Import the styles
import "@react-pdf-viewer/core/lib/styles/index.css";

interface AgreementProps extends ContractReducerProps {
  toggleUploadSignatureVisibility: () => void;
  isCanvas: boolean;
  isUpload: boolean;
  handleSignatureUpload: (isAllTermSigned: boolean) => void;
  onCurrentTermUpdate: (term: ContractTermTemplate) => void;
  onContractTermsUpdate: (terms: ContractTermTemplate[]) => void;
  // Since Forward Ref is not working properly with Redux Container, received it as prop
  sendLinkRef?: RefObject<ContractTermSendLinkHandler>;
}
const Agreement = React.forwardRef<any, AgreementProps>((props, ref) => {
  const signPad: any = useRef({});
  const {
    toggleUploadSignatureVisibility,
    contract,
    isCanvas,
    isUpload,
    handleSignatureUpload,
    sendLinkRef,
    onCurrentTermUpdate,
    onContractTermsUpdate,
  } = props;
  const carouselRef: any = React.createRef();
  const [currentSlide, setCurrentSlide] = useState<number>(1);
  const [canvasSignature, setCanvasSignature] = useState<File>();
  const [contractTerm, setContractTerm] = useState<ContractTermTemplate[]>([]);

  const [loading, setLoading] = useState(false);
  const [signatureLoading, setSignatureLoading] = useState(false);

  const [pdfPreview, setPdfPreview] = useState("");

  const handleFetchContractTerm = () => {
    setLoading(true);
    ContractTermTemplateService.fetchContractTerms(
      contract?.id ?? 0,
      (term) => {
        onContractTermsUpdate(term);
        setContractTerm(term);
        const isAlldocumentSigned = term?.every((term) => term?.documentUrl);
        handleSignatureUpload(term?.length ? isAlldocumentSigned : false);
      },
      () => {},
      () => {
        setLoading(false);
      }
    );
  };
  useEffect(() => {
    handleFetchContractTerm();
  }, [contract]);
  const clearCanvasWriting = () => {
    signPad.current.clear();
    setCanvasSignature(undefined);
  };
  function dataURLtoFile(dataurl: any, filename: any) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  const handleCanvasImage = (index: number) => {
    const data = signPad.current.getTrimmedCanvas().toDataURL("image/png");
    const value = dataURLtoFile(data, `termSignature${index}`);

    setCanvasSignature(value);
  };

  const handleUpdateSignature = (
    contractTermId: Number,
    file?: File,
    type?: string
  ) => {
    setSignatureLoading(true);
    ContractTermTemplateService.uploadTermTemplateSignature(
      contractTermId,
      type == "manual" ? (file as File) : (canvasSignature as File),
      () => {
        handleFetchContractTerm();
        setCanvasSignature(undefined);
      },
      () => {},
      () => {
        setSignatureLoading(false);
      }
    );
  };

  useImperativeHandle(sendLinkRef, () => ({
    onSendLink: async () => {
      const currentTermId = contractTerm[
        (currentSlide - 1) % contractTerm?.length
      ]?.id?.toString();
      if (!currentTermId) return;

      await ContractTermTemplateService.sendContractLink(currentTermId);
      return +currentTermId;
    },
  }));

  useEffect(() => {
    const currentTerm = contractTerm[(currentSlide - 1) % contractTerm?.length];
    onCurrentTermUpdate(currentTerm);
  }, [currentSlide, contractTerm]);

  return (
    <div className="agreement">
      <div className="agreement__navigator">
        {" "}
        <span className="text-primary mr-2">
          {contractTerm?.[currentSlide - 1]?.name || ""}
        </span>
        {loading ? (
          ""
        ) : (
          <span className="mr-2 text-italic text-primary">{`(${currentSlide}/${contractTerm.length} forms)`}</span>
        )}
        <img
          onClick={() => {
            carouselRef.current.prev();
            setCurrentSlide(
              (slide) =>
                --slide % (contractTerm?.length + 1) || contractTerm?.length
            );
          }}
          src={leftArrow}
          alt="left"
          className="cursor-pointer arrow"
        />
        <img
          src={rightArrow}
          className="cursor-pointer ml-3 arrow"
          onClick={() => {
            carouselRef.current.next();
            setCurrentSlide(
              (slide) => ++slide % (contractTerm?.length + 1) || 1
            );
          }}
          alt=""
        />
      </div>
      <Carousel adaptiveHeight dots={false} ref={carouselRef} className="mt-5">
        {contractTerm?.map((term, index) => (
          <>
            <div className="agreement__details mb-5">
              <div
                className="pointer-none"
                dangerouslySetInnerHTML={{ __html: term?.description ?? "" }}
              />
            </div>
            <PhotoConsentOptions contractTerm={term} />
          </>
        ))}
      </Carousel>
      {loading ? (
        <AppLoader loading />
      ) : (
        contractTerm?.map(
          (term, index) =>
            currentSlide === index + 1 && (
              <Row>
                {loading ? (
                  <AppLoader loading />
                ) : (
                  term?.documentUrl && (
                    <Col span={12}>
                      <div className="mt-5 agreement__signature">
                        {isPdf(term?.documentUrl) ? (
                          <div
                            className="cursor-pointer"
                            onClick={() => {
                              if (!term?.documentUrl) return;

                              setPdfPreview(term?.documentUrl);
                            }}
                          >
                            <iframe
                              className="pointer-none"
                              src={term?.documentUrl}
                              title={term?.name}
                            />
                          </div>
                        ) : (
                          <img src={term?.documentUrl} alt={term?.name} />
                        )}
                      </div>
                    </Col>
                  )
                )}
                {isCanvas && (
                  <>
                    <Col span={24} className="mt-5" key={index}>
                      <SignaturePad
                        ref={signPad}
                        onEnd={() => handleCanvasImage(index)}
                      />
                    </Col>
                    <Col span={12}>
                      {" "}
                      <Button
                        className="secondary-btn"
                        onClick={clearCanvasWriting}
                      >
                        Clear
                      </Button>
                    </Col>
                    <Col span={12} className="text-right">
                      {" "}
                      <Button
                        disabled={!canvasSignature}
                        className="secondary-btn"
                        loading={signatureLoading}
                        onClick={() => handleUpdateSignature(term?.id ?? 0)}
                      >
                        Upload
                      </Button>
                    </Col>
                  </>
                )}
                {isUpload && (
                  <Col span={24} className="mt-2 mb-3">
                    <Upload
                      className="mt-5"
                      showUploadList={false}
                      accept="image/*, application/pdf"
                      onChange={(value) => {
                        value?.file?.status === "error" &&
                          handleUpdateSignature(
                            term?.id ?? 0,
                            value.file?.originFileObj as File,
                            "manual"
                          );
                      }}
                    >
                      {signatureLoading ? (
                        <AppLoader loading={signatureLoading} />
                      ) : (
                        <>
                          {" "}
                          <UploadOutlined className="mr-2" /> Upload
                        </>
                      )}
                    </Upload>
                  </Col>
                )}
              </Row>
            )
        )
      )}
      <Modal
        visible={!!pdfPreview}
        onCancel={() => setPdfPreview("")}
        className="plain-view"
        destroyOnClose
        closable
        width="50vw"
        bodyStyle={{
          maxHeight: "75vh",
          overflow: "scroll",
        }}
      >
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
          <Viewer fileUrl={pdfPreview} />
        </Worker>
      </Modal>
    </div>
  );
});

export default ContractContainer(Agreement);
