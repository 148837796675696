import { serializable, alias, primitive, object, list } from "serializr";

export class Schedule {
  @serializable(alias("weekday", primitive()))
  weekday?: string;
  @serializable(alias("is_open", primitive()))
  isOpen?: boolean;
  @serializable(alias("start_time", primitive()))
  startTime?: string;
  @serializable(alias("end_time", primitive()))
  endTime?: string;
}

export class Practice {
  @serializable(alias("id", primitive()))
  id?: string;

  @serializable(alias("name", primitive()))
  name?: string;

  @serializable(alias("contact_person", primitive()))
  contactPerson?: string;

  @serializable(alias("isd_code", primitive()))
  isdCode?: string;

  @serializable(alias("lat", primitive()))
  lat?: string;

  @serializable(alias("lon", primitive()))
  lon?: string;

  @serializable(alias("mobile_number", primitive()))
  mobileNumber?: string;

  @serializable(alias("email", primitive()))
  email?: string;

  @serializable(alias("time_zone", primitive()))
  timeZone?: string;

  @serializable(alias("chair_count", primitive()))
  chairCount?: string;

  @serializable(alias("lab_count", primitive()))
  labCount?: string;

  @serializable(alias("room_count", primitive()))
  roomCount?: string;
  @serializable(alias("status", primitive()))
  status?: string;

  @serializable(alias("location", primitive()))
  location?: string;

  @serializable(alias("timezone_code", primitive()))
  timezoneCode?: string;

  @serializable(alias("timezone_name", primitive()))
  timezoneName?: string;

  @serializable(alias("schedule", list(object(Schedule))))
  schedule: Schedule[] = [];
}
