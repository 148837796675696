import { Button, Col, Row } from "antd";
import Form from "antd/lib/form/Form";
import { Formik } from "formik";
import React, { FC, Fragment, useEffect, useState } from "react";
import { AppointmentType } from "../../../../../../models/AppointmentType/appointmentType.model";
import AppointmentTypeService from "../../../../../../services/AppointmentType/appointmentType.service";
import InputField from "../../../../../../shared/components/InputField";
import { validation } from "./AppointmentTypeValidation";
import "./appointmentTypeForm.scss";
import DropdownField from "../../../../../../shared/components/DropdownField";
import { useResource } from "../../../../../../shared/hooks/Resource/useResource";
import { DropdownModeEnum } from "../../../../../../enums/dropdownMode.enum";
import { ResourceTypeOption } from "../../../../../../models/ResourceType/resourceType.model";
import { MetaService } from "../../../../../../services/Meta/meta.service";
import CheckableTag from "antd/lib/tag/CheckableTag";
import RadioInputField from "../../../../../../shared/components/RadioInputField";
import { AppointmentTypeCategory } from "../../../../../../models/AppointmentTypeCategories/appointmentTypeCategories.model";
import { AppointmentTypeProcedureCode } from "../../../../../../models/AppointmentTypeProcedureCodes/appointmentTypeProcedureCodes.model";

interface AppointmentTypesFormProps {
  saveAppointmentTypeHandler: (
    appointmentType: AppointmentType,
    type: "add" | "edit"
  ) => void;
  formValue: AppointmentType;
  closeHandler: () => void;
}
const doctorRequiredOption = [
  { label: "Yes", value: true },
  { label: "No", value: false },
];

const colorCode = [
  "#4FC95D",

  "#4FBBC9",

  "#4F58C9",

  "#9A4FC9",

  "#95C94F",

  "#C0C94F",

  "#C99A4F",

  "#C94F4F",

  "#4F83C9",

  "#7D7986",

  "#A7CFFF",

  "#A7AAFF",

  "#DAA7FF",

  "#A7F1FF",

  "#A7FFDD",

  "#BBFFA7",

  "#FFFFA7",

  "#FFCCA7",

  "#FFA7A7",

  "#D8D8D8",
];

const AppointmentTypeForm: FC<AppointmentTypesFormProps> = ({
  formValue,
  closeHandler,
  saveAppointmentTypeHandler,
}) => {
  const [resourceTypes, setResourceTypes] = useState<ResourceTypeOption[]>([]);

  const [loading, setLoading] = useState(false);
  const [resourceTypeLoading, setResourceTypeLoading] = useState(false);

  const [categories, setCategories] = useState<AppointmentTypeCategory[]>();

  const [categoriesLoading, setCategoriesLoading] = useState(false);

  const [procedureCodes, setProcedureCodes] = useState<
    AppointmentTypeProcedureCode[]
  >([]);

  const [procedureCodeLoading, setProcedureCodeLoading] = useState(false);

  const handleSubmit = (values: AppointmentType) => {
    const appointmentType = Object.assign(new AppointmentType(), {
      ...values,
      procedureCode: procedureCodes?.find(({ id }) => values?.procedureCode)
        ?.procedureCode,
    });
    setLoading(true);
    AppointmentTypeService[
      values?.id ? "updateAppointmentType" : "createAppointmentType"
    ](
      // TODO: For now, populating corresponding procedure code string for id. Need to be fixed
      appointmentType,
      (appointmentType) => {
        saveAppointmentTypeHandler(
          appointmentType,
          values?.id ? "edit" : "add"
        );
      },
      () => {},
      () => {
        setLoading(false);
      }
    );
  };
  useEffect(() => {
    setResourceTypeLoading(true);
    MetaService.fetchResouceType(
      "option",
      (resourceTypes) => {
        setResourceTypes(resourceTypes as ResourceTypeOption[]);
      },
      () => {},
      () => {
        setResourceTypeLoading(false);
      }
    );
    setCategoriesLoading(true);
    AppointmentTypeService.fetchCategories(
      (categories) => {
        setCategories(categories);
      },
      () => {},
      () => {
        setCategoriesLoading(false);
      }
    );
  }, []);

  const handleCategoryChange = (categoryId: number) => {
    setProcedureCodeLoading(true);
    AppointmentTypeService.fetchProcedureCodes(
      categoryId,
      (procedureCodes) => setProcedureCodes(procedureCodes),
      () => {},
      () => {
        setProcedureCodeLoading(false);
      }
    );
  };

  const resources = useResource("chair", "option");

  return (
    <div className="appointment-type-form__container">
      <Formik
        initialValues={formValue}
        onSubmit={handleSubmit}
        validationSchema={validation}
      >
        {({ handleSubmit, setFieldValue, setValues, values }) => {
          return (
            <Form>
              <div className="appointment-type-form__field__wrapper">
                <Row gutter={[15, 5]}>
                  <Col span={12}>
                    <InputField
                      type="text"
                      title="Appointment Title"
                      name="title"
                    />
                  </Col>
                  <Col span={12}>
                    <InputField type="text" title="Nick Name" name="nickName" />
                  </Col>
                </Row>
                <Row gutter={[15, 5]}>
                  <Col span={12}>
                    <InputField
                      type="number"
                      title="Duration(min)"
                      name="duration"
                    />
                  </Col>
                  <Col span={12}>
                    <DropdownField
                      showArrow
                      title="Resource Required"
                      name="appointmentResourceTypes"
                      options={resourceTypes}
                      value={values?.appointmentResourceTypes.map(
                        (appointment) => appointment?.resourceTypeId
                      )}
                      placeHolder="Select"
                      mode={DropdownModeEnum.MULTIPLE}
                      onChange={(values) => {
                        setFieldValue(
                          "appointmentResourceTypes",
                          values?.map((value: number) => ({
                            resourceTypeId: value,
                          }))
                        );
                      }}
                    />
                  </Col>
                  <Col span="12">
                    <DropdownField
                      loading={categoriesLoading}
                      name="categoryId"
                      options={categories}
                      placeHolder="Select Category"
                      setFieldValue={setFieldValue}
                      title="Category"
                      value={values?.categoryId}
                      onChange={(categoryId: number) => {
                        handleCategoryChange(categoryId);
                        setValues({
                          ...values,
                          categoryId,
                          procedureCode: undefined,
                        });
                      }}
                    />
                  </Col>
                  <Col span="12">
                    <DropdownField
                      loading={procedureCodeLoading}
                      name="procedureCode"
                      options={procedureCodes}
                      placeHolder="Select Procedure Code"
                      setFieldValue={setFieldValue}
                      title="Procedure Code"
                      value={values?.procedureCode}
                    />
                  </Col>
                  <Col span="12">
                    <RadioInputField
                      name="isDoctorRequired"
                      options={doctorRequiredOption}
                      setFieldValue={setFieldValue}
                      title="Doctor Required"
                      value={values?.isDoctorRequired}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col span={24}>
                    <div className="mt-1 mb-4">Template Colour Code</div>

                    {colorCode?.map((color, index) => {
                      return (
                        <CheckableTag
                          style={{ background: color }}
                          checked={color === values.treatmentColor}
                          className={`create-appointment-template__color `}
                          key={index}
                          onChange={(value) => {
                            setFieldValue("treatmentColor", color);
                          }}
                        ></CheckableTag>
                      );
                    })}
                  </Col>
                </Row>
                <Row className="appointment-type-form__textarea">
                  <Col span={24}>
                    <InputField
                      type="textarea"
                      title="Description"
                      name="description"
                      rows={6}
                    />
                  </Col>
                </Row>
                <Row
                  className="appointment-type-form__controller "
                  gutter={[15, 5]}
                  justify="end"
                >
                  <Col span={4}>
                    <Button type="default" onClick={closeHandler}>
                      Cancel
                    </Button>
                  </Col>
                  <Col span={4}>
                    <Button
                      loading={loading}
                      type="primary"
                      onClick={() => handleSubmit()}
                    >
                      Save
                    </Button>
                  </Col>
                </Row>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default AppointmentTypeForm;
