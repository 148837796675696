import { Button, Col, Row } from "antd";
import React, { FC } from "react";
import { useHistory } from "react-router-dom";
import AppRoutes from "../../../../routes/routeConstants/appRoutes";
import "./registerSuccessPage.scss";

interface RegisterSuccessPageProps {}

const RegisterSuccessPage: FC<RegisterSuccessPageProps> = (props) => {
  const {} = props;

  const handleRedirectLogin = () => {
    window.location.replace(AppRoutes.LOGIN);
  };

  return (
    <div className="register-success-page">
      <div className="inner__wrapper">
        <h2 className="text-center">Thanks for signing up to Aligner 4D</h2>
        <p className="text-center">
          Your corporate profile is under review and you will be notified via
          email after Approval/Rejection.
        </p>
        <Row justify="center" className="mt-5">
          <Col>
            <Button type="primary" onClick={handleRedirectLogin}>
              Back To Login
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default RegisterSuccessPage;
