import moment from "moment";
import { alias, list, object, primitive, serializable } from "serializr";
import { UserRoles } from "../../enums/userRoles.enum";

export class ProfileAvailability {
  @serializable(alias("profile_id", primitive()))
  profileId?: number;

  @serializable(alias("role_id", primitive()))
  roleId?: number;

  @serializable(alias("role_name", primitive()))
  roleName?: UserRoles;

  @serializable(alias("profile_first_name", primitive()))
  profileFirstName?: number;

  @serializable(alias("profile_last_name", primitive()))
  profileLastName?: string;

  @serializable(alias("profile_pic_url", primitive()))
  profilePicUrl?: string;

  getFullName = () => {
    const firstName = this.profileFirstName ?? "";
    const lastName = this.profileLastName ?? "";

    return `${firstName} ${lastName}`;
  };
}

export class DaySchedule {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("appointment_visit_type", primitive()))
  visitType?: string;
  @serializable(alias("notes", primitive()))
  notes?: string;

  @serializable(alias("start_time", primitive()))
  startTime?: string;

  @serializable(alias("resource_id", primitive()))
  resourceId?: string;

  @serializable(alias("templateId", primitive()))
  templateId?: string;

  @serializable(alias("end_time", primitive()))
  endTime?: string;

  @serializable(alias("appointment_type_id", primitive()))
  appointmentTypeId?: number;

  @serializable(alias("appointment_type_title", primitive()))
  appointmentTypeTitle?: string;

  @serializable(alias("appointment_type_color_code", primitive()))
  appointmentTypeColorCode?: string;

  @serializable(alias("appointment_id", primitive()))
  appointmentId?: number;

  @serializable(alias("appointment_date", primitive()))
  appointmentDate?: string;

  @serializable(alias("appointment_patient_profile_id", primitive()))
  appointmentPatientProfileId?: number | null;

  @serializable(alias("appointment_status_color_code", primitive()))
  appointmentStatusColorCode?: string;

  @serializable(alias("patient_first_name", primitive()))
  patientFirstName?: string;

  @serializable(alias("patient_last_name", primitive()))
  patientLastName?: string;

  @serializable(alias("patient_profile_pic_url", primitive()))
  patientProfilePicUrl?: string;

  @serializable(alias("appointment_doctor_profile_id", primitive()))
  appointmentDoctorProfileId?: number | null;

  @serializable(alias("doctor_first_name", primitive()))
  doctorFirstName?: string;

  @serializable(alias("doctor_last_name", primitive()))
  doctorLastName?: string;

  @serializable(alias("doctor_profile_pic_url", primitive()))
  doctorProfilePicUrl?: string;

  @serializable(alias("appointment_assistant_profile_id", primitive()))
  appointmentAssistantProfileId?: string;

  @serializable(alias("assistant_first_name", primitive()))
  assistantFirstName?: string;

  @serializable(alias("assistant_last_name", primitive()))
  assistantLastName?: string;

  @serializable(alias("assistant_profile_pic_url", primitive()))
  assistantProfilePicUrl?: string;

  @serializable(
    alias("profile_availabilities", list(object(ProfileAvailability)))
  )
  profileAvailabilities: ProfileAvailability[] = [];

  @serializable(alias("is_doctor_required", primitive()))
  isDoctorRequired?: boolean;

  @serializable(alias("appointment_type_procedure_code", primitive()))
  procedureCode?: string;

  @serializable(alias("appointment_status", primitive()))
  status?: string;

  @serializable(alias("interaction_status", primitive()))
  interactionStatus?: string;
}

export class DayResource {
  @serializable(alias("id", primitive()))
  id?: string;

  @serializable(alias("name", primitive()))
  name?: string;

  @serializable(alias("schedules", list(object(DaySchedule))))
  schedules?: DaySchedule[];
}

export class AppointmentDayCalender {
  @serializable(alias("template_id", primitive()))
  templateId?: number;

  @serializable(alias("template_name", primitive()))
  templateName?: string;

  @serializable(alias("start_time", primitive()))
  startTime?: string;

  @serializable(alias("end_time", primitive()))
  endTime?: string;

  @serializable(alias("resources", list(object(DayResource))))
  resources: DayResource[] = [];

  getStartDate = () =>
    this.startTime ? moment(this.startTime).toDate() : moment().toDate();

  getEndDate = () =>
    this.endTime ? moment(this.endTime).toDate() : moment().toDate();
}
