import { useEffect } from "react";
import * as signalR from "@microsoft/signalr";
import { User } from "../../../models/User/user.model";
import NotificationService from "../../../services/Notification/notification.service";
import { Notification } from "../../../models/Notification/notification.model";
import Axios from "axios";

const CONNECTION_URL =
  "https://notifysignalr.azurewebsites.net/api?negotiateVersion=1";

const apiURL =
  "https://notifysignalr.azurewebsites.net/api/HttpTriggerSubscribe?code=0h41nodDUNYBWqpPUv7Cxd/ajlW1F5A6ag/abZzhRybX4ctep1Maow==";
const defaultProperties = ["length", "prototype", "name"];

const useNotification = (
  pushNotification: (notification: Notification) => void,
  pushWorkItem: (workItem: Notification) => void,
  user?: User | null
) => {
  const makeConnection = async () => {
    try {
      let connection = new signalR.HubConnectionBuilder()
        .withUrl(CONNECTION_URL, {
          withCredentials: true,
        })
        .build();
      await connection.start();
      return connection;
    } catch (error) {
      return null;
    }
  };

  const registerNotification = (connection: signalR.HubConnection) => {
    Object.getOwnPropertyNames(NotificationService).forEach((name) => {
      if (!defaultProperties.includes(name)) {
        (NotificationService as any)[name](
          connection,
          (notification: Notification) => {
            if (name === "interaction" || name === "reviewNote") {
              pushWorkItem(notification);
            } else {
              pushNotification(notification);
            }
          }
        );
      }
    });
  };

  const joinGroup = (connection: signalR.HubConnection) => {
    // connection.invoke(SIGNAL_R_NOTIFICATION.SUBSCRIBE, )
    Axios.post(apiURL, {
      connection_id: connection.connectionId,
      group_name: "corporate_" + user?.id,
    });
    // Axios.post(apiURL, {
    //   connection_id: connection.connectionId,
    //   group_name: "corporate_role_" + user?.roleId,
    // });
    Axios.post(apiURL, {
      connection_id: connection.connectionId,
      group_name: user?.userProfiles?.length ? "corporate_" + user?.userProfiles[0]?.id + "_admin" : "",
    });
  };

  useEffect(() => {
    let connection: signalR.HubConnection | null = null;
    const establishConnection = async () => {
      connection = await makeConnection();
      if (connection) {
        registerNotification(connection);
        joinGroup(connection);
      }
    };
    if (user) {
      establishConnection();
    }

    return () => {
      connection?.stop();
    };
  }, [user]);

  return null;
};

export default useNotification;
