import React, { useEffect, FC, useState, memo, useMemo } from "react";
import { Router, Switch, Route, useLocation, Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";
import AuthWrapper from "../views/Auth/AuthWrapper";
import requiredAuth from "../shared/components/HOC/requireAuth";
import Dashboard from "../views/Dashboard";
import AppRoutes from "./routeConstants/appRoutes";
import RegisterWrapper from "../views/Auth/Register/RegisterWrapper";
import DetailedOnboardingWrapper from "../views/DetailedOnboarding/DetailedOnboardingWrapper";
import AppHeader from "../shared/components/AppHeader";
import AppSidebar from "../shared/components/AppSidebar";
import { useDispatch } from "react-redux";
import { SET_PAGE_TITLE } from "../store/definitions/metaConstants";
import PatientDashboard from "../views/Patients/PatientDashboard";
import PatientDetailTabs from "../views/Patients/PatientDetailTabs";
import UserList from "../views/Settings/UserList";
import UnderDevelopment from "../shared/components/UnderDevelopment";
import UserDetail from "../views/Settings/UserDetail";
import CustomFormList from "../views/Settings/CustomFormList";
import ServiceTabs from "../views/Services/ServiceTabs";
import Configuration from "../views/Settings/Configuration";
import OverallUserSchedules from "../views/Settings/OverallUserSchedules";
import UserSchedule from "../views/Settings/UserSchedule";
import AuthContainer from "../store/container/AuthContainer";
import { AuthReducerProps } from "../store/reducers/authReducer";
import { CorporateService } from "../services/Corporate/corporate.service";
import { Corporate } from "../models/Corporate/corporate.model";
import Appointments from "../views/Appointments";
import ContractForm from "../views/Services/Contracts/ContractForm";
import ContractDetail from "../views/Services/Contracts/ContractDetail";
import SetDetail from "../views/Settings/Configuration/Sets/SetDetail";
import InteractionDetail from "../views/Interactions/InteractionDetail";
import ProcedureDetail from "../views/Settings/Configuration/Procedures/ProcedureDetail";
import Practices from "../views/Settings/Practices";
import PracticeDetails from "../views/Settings/PracticeDetails";
import { Drawer } from "antd";
import CheckList from "../shared/components/CheckList";
import CorporateContainer from "../store/container/CorporateContainer";
import { CorporateReducerProps } from "../store/reducers/corporateReducer";
import { UnorderedListOutlined } from "@ant-design/icons";
import TreatmentTemplateDetails from "../views/Settings/Configuration/TreatmentTemplate/TreatmentTemplateDetails";
import RolesAndResponsibility from "../views/Settings/RolesAndResponsibility";
import UserService from "../services/User/user.service";
import RestrictedPage from "../views/RestrictedPage";
import { ResponsibilityTypes } from "../enums/responsebily.enum";
import Payments from "../views/Payments";
import CorporateDetails from "../views/CorporateDetails";
import Support from "../views/Support";
import Redirection from "../views/Auth/Redirection";
import AppointmentTemplate from "../views/Appointments/AppointmentTemplate";
import AppointmentTemplateWrapper from "../views/Appointments/AppointmentTemplate/AppointmentTemplateWrapper";
import AppointmentTemplateAssignDate from "../views/Appointments/AppointmentTemplate/AppointmentTemplateAssignDate";
import SplashScreen from "../shared/components/SplashScreen";
import CorporateObjectService from "../services/CorporateObject/corporateObject.service";
import { CorporateObject } from "../models/CorporateObject/corporateObject.model";
import { DentalObjectTypeEnum } from "../enums/dentalObjectType.enum";
import ScrollToTop from "../shared/utils/scrollToTop";
import { LocalStorageKeys } from "../enums/localStorageKeys.enum";
import ReadinessChecklist from "shared/components/ReadinessChecklist";

export const appHistory = createBrowserHistory();

export interface RouteProps {
  exact?: boolean;
  path: string;
  component: FC;
  title?: string;
}

interface AppRouterProps extends AuthReducerProps, CorporateReducerProps {}

const AppRouter = (props: AppRouterProps) => {
  const {
    authenticated,
    currentCorporate,
    openCheckList,
    setOpenCheckList,
    setPracticeId,
    user,
    setUser,
    setCorporateId,
    setCurrentCorporate,
    practiceId
  } = props;

  const [navbarCollapsed, setNavbarCollapsed] = useState(true);

  const [isDarkMode, setDarkMode] = useState(false);

  const object3DRoutes = [AppRoutes.VIEWS_3D, AppRoutes.TREATMENT_PLAN_DETAILS];

  const dispatch = useDispatch();

  const updateCollapse = () => setNavbarCollapsed((collapsed) => !collapsed);

  const isAuthenticated = (
    component: any,
    permission?: ResponsibilityTypes
  ) => {
    return requiredAuth(component, permission);
  };
  
  const unAuthenticatedRoutes: RouteProps[] = [
    {
      exact: true,
      path: AppRoutes.AUTH,
      component: AuthWrapper,
      title: "Auth",
    },
    {
      exact: false,
      path: AppRoutes.REGISTER,
      component: RegisterWrapper,
      title: "Register",
    },
    {
      exact: true,
      path: AppRoutes.RESTRICTED_PAGE,
      component: RestrictedPage,
      title: "",
    },
    {
      exact: true,
      path: AppRoutes.REDIRECT_PAGE,
      component: Redirection,
      title: "",
    },
  ];

  const authenticatedRoutes: RouteProps[] = useMemo(() =>[
    {
      exact: true,
      path: AppRoutes.PATIENTS,
      component: isAuthenticated(
        PatientDashboard,
        ResponsibilityTypes.PATIENT_VIEW
      ),
      title: "Patients",
    },
    {
      exact: true,
      path: AppRoutes.PATIENT_DETAIL,
      component: isAuthenticated(
        PatientDetailTabs,
        ResponsibilityTypes.PATIENT_VIEW
      ),
      title: "Patients",
    },
    {
      exact: true,
      path: AppRoutes.STAFFS,
      component: isAuthenticated(UserList, ResponsibilityTypes.STAFF_VIEW),
      title: "Staffs",
    },
    {
      exact: true,
      path: AppRoutes.STAFF_SCHEDULE,
      component: isAuthenticated(
        UserSchedule,
        ResponsibilityTypes.SCHEDULE_VIEW
      ),
      title: "Staffs",
    },
    {
      exact: true,
      path: AppRoutes.OVERALL_STAFF_SCHEDULES,
      component: isAuthenticated(
        OverallUserSchedules,
        ResponsibilityTypes.SCHEDULE_VIEW
      ),
      title: "Staffs",
    },
    {
      exact: true,
      path: AppRoutes.STAFF_DETAIL,
      component: isAuthenticated(UserDetail, ResponsibilityTypes.STAFF_VIEW),
      title: "Staffs",
    },
    {
      exact: true,
      path: AppRoutes.CUSTOM_FORMS,
      component: isAuthenticated(
        CustomFormList,
        ResponsibilityTypes.CUSTOM_FORM_VIEW
      ),
      title: "Custom forms",
    },
    {
      exact: true,
      path: AppRoutes.PRACTICES,
      component: isAuthenticated(Practices, ResponsibilityTypes.PRACTICE_VIEW),
      title: "Practices",
    },
    {
      exact: true,
      path: AppRoutes.PRACTICE_DETAILS,
      component: isAuthenticated(
        PracticeDetails,
        ResponsibilityTypes.PRACTICE_VIEW
      ),
      title: "Practices",
    },
    {
      exact: false,
      path: AppRoutes.CONFIGURATION,
      component: isAuthenticated(Configuration),
      title: "Configuration",
    },
    {
      exact: false,
      path: AppRoutes.DETAILED_ONBOARDING,
      component: isAuthenticated(DetailedOnboardingWrapper),
      title: "Detailed Onboarding",
    },
    {
      exact: true,
      path: AppRoutes.SERVICES,
      component: isAuthenticated(ServiceTabs, ResponsibilityTypes.SERVICE_VIEW),
      title: "Services",
    },
    {
      exact: true,
      path: AppRoutes.PAYMENTS,
      component: isAuthenticated(
        Payments,
        ResponsibilityTypes.PAYMENT_INVOICES_VIEW
      ),
      title: "Payments",
    },
    {
      exact: true,
      path: AppRoutes.SUPPORT,
      component: isAuthenticated(Support, ResponsibilityTypes.ISSUE_VIEW),
      title: "Support",
    },
    {
      exact: true,
      path: AppRoutes.ANALYTICS,
      component: isAuthenticated(UnderDevelopment),
      title: "Analytics",
    },
    {
      exact: true,
      path: AppRoutes.APPOINTMENTS,
      component: isAuthenticated(
        Appointments,
        ResponsibilityTypes.APPOINTMENT_VIEW
      ),
      title: "Appointments",
    },
    {
      exact: true,
      path: AppRoutes.APPOINTMENT_TEMPLATE,
      component: isAuthenticated(
        AppointmentTemplate,
        ResponsibilityTypes.APPOINTMENT_TEMPLATE_VIEW
      ),
      title: "Appointment Template",
    },
    {
      exact: true,
      path: AppRoutes.CREATE_APPOINTMENT_TEMPLATE,
      component: isAuthenticated(
        AppointmentTemplateWrapper,
        ResponsibilityTypes.APPOINTMENT_TEMPLATE_VIEW
      ),
      title: "Create Appointment Template",
    },
    {
      exact: true,
      path: AppRoutes.APPOINTMENT_TEMPLATE_ASSIGN_DATE,
      component: isAuthenticated(
        AppointmentTemplateAssignDate,
        ResponsibilityTypes.APPOINTMENT_TEMPLATE_VIEW
      ),
      title: "Assign Date",
    },
    {
      exact: true,
      path: AppRoutes.DASHBOARD,
      component: isAuthenticated(Dashboard),
      title: "Job List",
    },
    {
      exact: true,
      path: AppRoutes.ADD_CONTRACT,
      component: isAuthenticated(
        ContractForm,
        ResponsibilityTypes.PATIENT_CONTRACT_CREATE
      ),
      title: "Services",
    },
    {
      exact: true,
      path: AppRoutes.EDIT_CONTRACT,
      component: isAuthenticated(
        ContractForm,
        ResponsibilityTypes.PATIENT_CONTRACT_EDIT
      ),
      title: "Services",
    },
    {
      exact: true,
      path: AppRoutes.CONTRACT_DETAIL,
      component: isAuthenticated(
        ContractDetail,
        ResponsibilityTypes.PATIENT_CONTRACT_VIEW
      ),
      title: "Services",
    },
    {
      exact: true,
      path: AppRoutes.INTERACTION,
      component: isAuthenticated(
        InteractionDetail,
        ResponsibilityTypes.INTERACTION_VIEW
      ),
      title: "Interactions",
    },
    {
      exact: true,
      path: AppRoutes.SET_DETAIL,
      component: isAuthenticated(SetDetail, ResponsibilityTypes.SET_VIEW),
      title: "Configurations",
    },
    {
      exact: true,
      path: AppRoutes.PROCEDURE_DETAIL,
      component: isAuthenticated(
        ProcedureDetail,
        ResponsibilityTypes.PRACTICE_VIEW
      ),
      title: "Configurations",
    },
    {
      exact: true,
      component: isAuthenticated(
        TreatmentTemplateDetails,
        ResponsibilityTypes.TREATMENT_PLAN_VIEW
      ),
      path: AppRoutes.TREATMENT_PLAN_DETAILS,
      title: "Configurations",
    },
    {
      exact: true,
      component: isAuthenticated(
        TreatmentTemplateDetails,
        ResponsibilityTypes.TREATMENT_PLAN_VIEW
      ),
      path: AppRoutes.TREATMENT_PLAN_TEMPLATE_DETAILS,
      title: "Configurations",
    },
    {
      exact: true,
      component: isAuthenticated(
        RolesAndResponsibility,
        ResponsibilityTypes.ROLE_VIEW
      ),
      path: AppRoutes.ROLES,
      title: "Roles & Responsibility",
    },
    {
      exact: true,
      component: isAuthenticated(
        CorporateDetails,
        ResponsibilityTypes.CORPORATE_VIEW
      ),
      // component: CorporateDetails,
      path: AppRoutes.CORPORATE_DETAILS,
      title: "Corporate Details",
    },
  ],[practiceId]);

  useEffect(() => {
    const pathname = appHistory.location.pathname;
    const routeIndex = authenticatedRoutes.findIndex((route) =>
      pathname.includes(route.path)
    );
    if (routeIndex >= 0) {
      dispatch({
        type: SET_PAGE_TITLE,
        payload: { pageTitle: authenticatedRoutes[routeIndex].title },
      });
    }
    if (object3DRoutes.some((route) => pathname.includes(route))) {
      setDarkMode(true);
    } else {
      setDarkMode(false);
    }
    appHistory.listen((location, action) => {
      const routeIndex = authenticatedRoutes.findIndex(
        (route) => route.path === location.pathname
      );
      if (routeIndex >= 0) {
        dispatch({
          type: SET_PAGE_TITLE,
          payload: {
            pageTitle: authenticatedRoutes[routeIndex].title,
          },
        });
      }
      if (object3DRoutes.some((route) => location.pathname.includes(route))) {
        setDarkMode(true);
      } else {
        setDarkMode(false);
      }
    });
  }, [authenticatedRoutes, dispatch, object3DRoutes]);

  useEffect(() => {
    if ((authenticated || localStorage.getItem("token")) && !user) {
      UserService.getCurrentUserDetails(
        (user) => {
          if (user?.id) {
            UserService.showUser(
              user?.id,
              (user) => {
                setCorporateId(
                  user?.userProfiles?.length ? user.userProfiles[0]?.id : 0
                );
                setUser(user);
                let practiceId = localStorage.getItem(
                  LocalStorageKeys.PRACTICE_ID
                );
                if (!practiceId && user?.defaultPracticeId) {
                  practiceId = user?.defaultPracticeId?.toString();
                  localStorage.setItem(
                    LocalStorageKeys.PRACTICE_ID,
                    practiceId
                  );
                }
                setPracticeId(Number(practiceId));
              },
              () => {},
              () => {}
            );
          }
        },
        () => {},
        () => {}
      );
    }
    if (authenticated) {
      CorporateService.showCorporateProfile(
        (corporate: Corporate) => {
          setCurrentCorporate(corporate);
        },
        () => {},
        () => {}
      );
    }
  }, [authenticated]);

  const handleCloseOpenCheckList = () => {
    setOpenCheckList(false);
  };

  const handleOpenCheckList = () => {
    setOpenCheckList(true);
  };

  return (
    <div className={isDarkMode ? "theme--dark" : "theme--default"}>
      <div className="a4d-router-wrapper">
        <div className="app-routes">
          <Router history={appHistory}>
            <ScrollToTop />
            <AppHeader onCollapseUpdate={updateCollapse} />
            <AppSidebar collapsed={navbarCollapsed} user={user} />
            <div className="app-routes__un-auth-routes-wrapper">
              <Switch>
                {unAuthenticatedRoutes.map((route, index) => {
                  return <Route key={index} {...route} />;
                })}
                {/* <Route
                  path="*"
                  render={() => <Redirect to={AppRoutes.AUTH} />}
                /> */}
              </Switch>
            </div>
            {authenticated && !user ? (
              <SplashScreen />
            ) : (
              <div className="app-routes__auth-routes-wrapper">
                <div className="app-wrapper">
                  <Switch>
                    {authenticatedRoutes.map((route, index) => {
                      return (
                        <Route
                          key={index}
                          {...route}
                          component={route.component}
                        />
                      );
                    })}
                  </Switch>
                </div>
              </div>
            )}
            <ReadinessChecklist />
          </Router>
        </div>
      </div>
    </div>
  );
};

export default AuthContainer(CorporateContainer(AppRouter));
