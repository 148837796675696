import React, { Fragment } from "react";
import "./interactionActions.scss";
import { BabylonReducerProps } from "../../../store/reducers/babylonReducer";
import BabylonContainer from "../../../store/container/BabylonContainer";
import InteractionStepForm from "../InteractionStepForm";
import { InteractionActionEnum } from "../../../enums/interactionActionEnum.enum";
import { InteractionStepFormTypeEnum } from "../../../enums/interactionStepFormType.enum";
import { ICONS } from "../../../shared/icons";
import ProcedureForm from "../ProcedureForm";
import { InteractionStep } from "../../../models/InteractionStep/interactionStep.model";
import { MeshType } from "enums/meshType.enum";

interface InteractionActionsProps extends BabylonReducerProps {
  type: InteractionActionEnum;
  setOpenConflict: (open: boolean) => void;
  createInteractionStep: (step: InteractionStep) => void;
  meshType?: MeshType;
}

function InteractionActions({
  type,
  sourceObject,
  setOpenConflict,
  createInteractionStep,
  meshType,
}: InteractionActionsProps) {
  return (
    <div className="interaction-actions">
      <h5 className="text-primary">
        {type === InteractionActionEnum.ALIGNER ? (
          <i className="icon-aligners mr-2" />
        ) : type === InteractionActionEnum.BUTTON ? (
          <img className="icon-btn mr-2" src={ICONS.BUTTON} alt="" />
        ) : type === InteractionActionEnum.ELASTIC ? (
          <img className="icon-btn mr-2" src={ICONS.ELASTIC} alt="" />
        ) : (
          <i className="icon-aligners mr-2" />
        )}
        {type === InteractionActionEnum.PROCEDURE ? type : `${type} Sets`}
      </h5>
      {type === InteractionActionEnum.PROCEDURE ? (
        <ProcedureForm
          setOpenConflict={setOpenConflict}
          createInteractionStep={createInteractionStep}
        />
      ) : (
        <Fragment>
          <InteractionStepForm
            type={InteractionStepFormTypeEnum.SET}
            interactionType={type}
            setOpenConflict={setOpenConflict}
            meshType={meshType}
            createInteractionStep={createInteractionStep}
          />
          {/* {sourceObject && (
            <InteractionStepForm
              type={InteractionStepFormTypeEnum.OBJECT_LINK}
              interactionType={type}
              setOpenConflict={setOpenConflict}
              createInteractionStep={createInteractionStep}
            />
          )} */}
        </Fragment>
      )}
    </div>
  );
}

export default BabylonContainer(InteractionActions);
