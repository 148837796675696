import { Button, Modal } from "antd";
import { RadioChangeEvent } from "antd/es/radio";
import { Formik, Form } from "formik";
import React, { useState } from "react";
import { PhotoConsent } from "../../../../../../../enums/photoConsent.enum";
import { Contract } from "../../../../../../../models/Contract/contract.model";
import { ContractTermTemplate } from "../../../../../../../models/ContractTermsTemplate/contractTermsTemplate.model";
import ContractService from "../../../../../../../services/Contract/Contract.service";
import { IDropdownOptions } from "../../../../../../../services/Meta/meta.service";
import RadioInputField from "../../../../../../../shared/components/RadioInputField";
import useToggle from "../../../../../../../shared/hooks/useToggle/useToggler";
import ContractContainer from "../../../../../../../store/container/ContractContainer";
import { ContractReducerProps } from "../../../../../../../store/reducers/contractReducer";

import "./photoConsentOptions.scss";

interface PhotoConsentOptionsProps extends ContractReducerProps {
  contractTerm: ContractTermTemplate;
}

const PhotoConsentOptions = ({
  contract,
  contractTerm,
  setContract,
}: PhotoConsentOptionsProps) => {
  const {
    toggle: isConfirmationVisible,
    updateToggle: updateIsConfirmationVisible,
  } = useToggle({});

  const {
    toggle: confirmationLoading,
    updateToggle: setConfirmationLoading,
  } = useToggle({});

  const [selectedPhotoConsent, setSelectedPhotoConsent] = useState();

  const IS_PHOTO_CONSENT_AGREED =
    contract?.isPhotoConsentAccepted ||
    contract?.isPhotoConsentPartiallyAccepted ||
    contract?.isPhotoConsentRejected;

  const PHOTO_CONSENT_CLASS_NAME = `${
    IS_PHOTO_CONSENT_AGREED ? "pointer-none" : ""
  } photo-consent-options`.trimStart();

  const getPhotoConsentEnum = () => {
    if (contract?.isPhotoConsentAccepted) return PhotoConsent.ACCEPTED;
    else if (contract?.isPhotoConsentRejected) return PhotoConsent.REJECTED;
    else if (contract?.isPhotoConsentPartiallyAccepted)
      return PhotoConsent.PARTIALLY_ACCEPTED;
    else return;
  };

  const [values, setValues] = useState({
    photoConsent: getPhotoConsentEnum(),
  });

  const isPhotoConsent =
    contractTerm?.name?.toLowerCase().trim() === "photo consent";

  const options: IDropdownOptions[] = [
    {
      label: "May be used for the purpose identified above",
      value: PhotoConsent.ACCEPTED,
    },
    {
      label: "Lower half of the face only (nose to chin will be shown only)",
      value: PhotoConsent.PARTIALLY_ACCEPTED,
    },
    {
      label: "May not be used for the purpose identified above",
      value: PhotoConsent.REJECTED,
    },
  ];

  const handleChange = (e?: RadioChangeEvent) => {
    setSelectedPhotoConsent(e?.target?.value);
    updateIsConfirmationVisible(true);
  };

  const handleConfirm = async () => {
    const IS_ACCEPTED = selectedPhotoConsent === PhotoConsent.ACCEPTED;

    const IS_PARTIALLY_ACCEPTED =
      selectedPhotoConsent === PhotoConsent.PARTIALLY_ACCEPTED;

    const IS_REJECTED = selectedPhotoConsent === PhotoConsent.REJECTED;

    const updatedContract = Object.assign(new Contract(), {
      ...contract,
      contractService: undefined,
      isPhotoConsentAccepted: IS_ACCEPTED,
      isPhotoConsentPartiallyAccepted: IS_PARTIALLY_ACCEPTED,
      isPhotoConsentRejected: IS_REJECTED,
    });

    setConfirmationLoading(true);

    ContractService.updateContract(
      updatedContract,
      (contract: Contract) => {
        setContract(contract);
        setValues((values) => ({
          ...values,
          photoConsent: selectedPhotoConsent,
        }));
      },
      () => {},
      () => {
        setConfirmationLoading(false);
        updateIsConfirmationVisible(false);
      }
    );
  };

  return isPhotoConsent ? (
    <>
      <div className={PHOTO_CONSENT_CLASS_NAME}>
        <Formik enableReinitialize initialValues={values} onSubmit={() => {}}>
          {({ values }) => (
            <Form>
              <RadioInputField
                name="photoConsent"
                value={values.photoConsent}
                options={options}
                onChange={(e) => handleChange(e)}
              />
            </Form>
          )}
        </Formik>
      </div>
      <Modal
        maskClosable={false}
        keyboard={false}
        className="photo-consent-confirmation-modal"
        centered
        width="475px"
        destroyOnClose
        footer={false}
        visible={isConfirmationVisible}
        onCancel={() => updateIsConfirmationVisible(false)}
      >
        <div className="text-center">
          Are you sure you want to confirm the selected photo content option?
        </div>
        <q className="text-bold text-center quotes">
          {options?.find(({ value }) => value === selectedPhotoConsent)?.label}
        </q>
        <div className="btn-grp">
          <Button
            className="text-capitalize"
            onClick={() => updateIsConfirmationVisible(false)}
          >
            cancel
          </Button>
          <Button
            className="text-capitalize"
            loading={confirmationLoading}
            onClick={handleConfirm}
            type="primary"
          >
            confirm
          </Button>
        </div>
      </Modal>
    </>
  ) : (
    <></>
  );
};

export default ContractContainer(PhotoConsentOptions);
