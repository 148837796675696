import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";
import * as CorporateActions from "../actions/corporateActions";

const mapStateToProps = (state: any, ownProps: any) => {
	return {
		...ownProps,
		currentCorporate: state.corporate.currentCorporate,
		openCheckList: state.corporate.openCheckList,
		firstVisit: state.corporate.firstVisit,
		practiceId: state.corporate.practiceId,
		corporateId: state.corporate.corporateId,
		practices: state.corporate.practices,
		practiceLoading: state.corporate.practiceLoading
	};
};

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(CorporateActions, dispatch);

const CorporateContainer = (component: any) => {
	return connect(mapStateToProps, mapDispatchToProps)(component);
};

export default CorporateContainer;
