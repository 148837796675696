import { ConsoleSqlOutlined } from "@ant-design/icons";
import { Progress, Skeleton } from "antd";
import React, { FC, useEffect, useState } from "react";
import { ChecklistEnum } from "../../../enums/checkList.enum";
import { Corporate } from "../../../models/Corporate/corporate.model";
import AppRoutes from "../../../routes/routeConstants/appRoutes";
import { CorporateService } from "../../../services/Corporate/corporate.service";
import { RegistrationService } from "../../../services/Registration/registration.service";
import CorporateContainer from "../../../store/container/CorporateContainer";
import { CorporateReducerProps } from "../../../store/reducers/corporateReducer";
import AppLoader from "../AppLoader";
import "./checkList.scss";
import ListItem from "./ListItem";

interface CheckListProps extends CorporateReducerProps {}

const CheckList: FC<CheckListProps> = (props) => {
  const [loading, setLoading] = useState(false);
  const [corporate, setCorporate] = useState<Corporate>(
    JSON.parse(localStorage.getItem("corporate") ?? "")
  );

  const { setCurrentCorporate } = props;

  const listItems = [
    {
      text: "Complete Practice Availability",
      isCompleted: corporate?.isPracticeAvailabilityCompleted,
      link: AppRoutes.PRACTICES,
      linkText: "Create Practice",
      key: ChecklistEnum.PRACTICE_AVAILABILITY_COMPLETED,
    },
    {
      text: "Complete Resources/Rooms Creation",
      isCompleted: corporate?.isResourceCreationCompleted,
      link: AppRoutes.RESOURCES,
      linkText: "Create Resource",
      key: ChecklistEnum.RESOURCE_CREATION_COMPLETED,
    },
    {
      text: "Complete Statement Note Template",
      isCompleted: corporate?.isStatementNoteTemplateCompleted,
      link: AppRoutes.NOTE_TEMPLATE,
      linkText: "Create Note Template",
      key: ChecklistEnum.STATEMENT_NOTE_TEMPLATE_COMPLETED,
    },
    {
      text: "Complete Treatment Template",
      isCompleted: corporate?.isTreatmentTemplateCompleted,
      link: AppRoutes.TREATMENT_PLANS,
      linkText: "Create Treatment Template",
      key: ChecklistEnum.TREATMENT_TEMPLATE_COMPLETED,
    },

    {
      text: "Complete Service creation",
      isCompleted: corporate?.isServiceCreationCompleted,
      link: AppRoutes.SERVICES,
      linkText: "Create Service",
      key: ChecklistEnum.SERVICE_CREATION_COMPLETED,
    },
    {
      text: "Complete staff profiles and Schedules",
      isCompleted: corporate?.isStaffProfileCompleted,
      link: AppRoutes.STAFFS,
      linkText: "Create Staff Profile and Schedule",
      key: ChecklistEnum.STAFF_PROFILE_COMPLETED,
    },

    {
      text: "Complete Appointment Template Management",
      isCompleted: corporate?.isAppointmentTemplateCompleted,
      link: AppRoutes.APPOINTMENT_TEMPLATE,
      linkText: "Create Appointment template",
      key: ChecklistEnum.APPOINTMENT_TEMPLATE_COMPLETED,
    },
  ];

  const { setOpenCheckList } = props;

  const [progress, setProgress] = useState(0);

  const [_, setVisited] = useState(0);

  const [items, setItems] = useState(listItems);

  const updateItems = (checked: boolean, index: number) => {
    const listKey = listItems?.[index]?.key;

    setLoading(true);
    RegistrationService.updateAdditionalDetails(
      { ...corporate, [listKey]: checked },
      (corporate: Corporate) => {
        setCorporate(corporate);
        setCurrentCorporate(corporate);
      },

      () => {},
      () => {
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    items?.map((val) => {
      if (val?.isCompleted) {
        setProgress((progress) => {
          const percentage = 100 / items?.length;

          if (val?.isCompleted) {
            return progress + percentage;
          }
          return progress - percentage;
        });
      }
    });
  }, []);

  const handleVisitHandler = (checked: boolean, index: number) => {
    updateItems(checked, index);
    setProgress((progress) => {
      const percentage = 100 / items?.length;

      if (checked) {
        return progress + percentage;
      }
      return progress - percentage;
    });
    const item = listItems[index];
    setItems((items) => {
      items.splice(index, 1, { ...item, isCompleted: checked });
      return [...items];
    });
  };

  return (
    <div className="check-list">
      <div className="check-list__progress__container mb-5">
        <div className="check-list__progress__wrapper">
          <div className="check-list__progress__percentage mr-3">
            {Math.round(progress)}%
          </div>
          <Progress percent={progress} showInfo={false} />
        </div>
      </div>
      {<AppLoader loading={loading} /> ? (
        <div className="list-items__wrapper">
          {items.map(({ ...props }, index) => (
            <ListItem
              {...props}
              loading={loading}
              hasDivider={!(index === listItems.length - 1)}
              index={index}
              key={index}
              visitHandler={handleVisitHandler}
            />
          ))}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default CorporateContainer(CheckList);
