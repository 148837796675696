import React, { FC, Fragment, useEffect, useState } from "react";
import { DeleteOutlined } from "@ant-design/icons";
import { Divider, Skeleton } from "antd";
import moment from "moment";
import XrayImagesService from "../../../services/XRayImages/xRayImages.service";
import { useParams } from "react-router";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import Modal from "antd/lib/modal/Modal";
import { XRayAttachment } from "../../../models/XRayImage/xRayImage.model";
import {
  ZoomInOutlined,
  ZoomOutOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import "./note.scss";
import { PreVisitService } from "../../../services/PreVisit/previsit.service";
import { PostVisitService } from "../../../services/PostVisit/postVisit.service";
import { InteractionReducerProps } from "store/reducers/interactionReducer";
import InteractionContainer from "store/container/InteractionContainer";

interface NoteProps extends InteractionReducerProps {
  title?: string;
  description?: string;
  showDivider?: boolean;
  date?: string;
  imageName?: string;
  imageUrl?: string;
  imageId?: number;
  className?: string;
  currentInteractionId?: number;
  onDeleteHandler: () => void;
  onImageOpen?: () => void;
  type?: "pre-visit" | "post-visit";
  hasDeleteAccess?: boolean;
}

const Note: FC<NoteProps> = (props) => {
  const {
    title,
    description,
    showDivider,
    date,
    imageId,
    imageName,
    imageUrl,
    className,
    onDeleteHandler,
    onImageOpen,
    type,
    hasDeleteAccess = true,
    activeInteractionId,
    currentInteractionId,
  } = props;

  const params: { interactionId: string } = useParams();

  const [xRayImage, setXRayImage] = useState<XRayAttachment>();

  const [isFetching, setIsFetching] = useState(false);

  const [showImage, setShowImage] = useState(false);

  const fetchImage = () => {
    switch (type) {
      case "pre-visit":
        return PreVisitService.openImage;
      case "post-visit":
        return PostVisitService.openImage;
      default:
        return XrayImagesService.fetchXrayImage;
    }
  };

  const handleViewImage = () => {
    setIsFetching(true);
    fetchImage()(
      {
        interactionId: params.interactionId,
        practiceId: "1",
      },
      imageId as number,
      (xRayImage) => {
        setXRayImage(xRayImage);
        setShowImage(true);
      },
      () => {},
      () => {
        setIsFetching(false);
        onImageOpen && onImageOpen();
      }
    );
  };

  return (
    <div className={`note ${className}`}>
      <div className="note__wrapper">
        {imageId ? (
          <div className="content-image" onClick={handleViewImage}>
            <Skeleton.Image className="image-placeholder" />
            <p>{isFetching ? "Opening image . . ." : imageName}</p>
          </div>
        ) : (
          <div className="content">
            <h5>{title}</h5>
            <p>{description}</p>
            {date && <p>{moment(date).format("ddd MMM D YYYY HH:mm:ss")}</p>}
          </div>
        )}
        {hasDeleteAccess &&
          Number(currentInteractionId) === activeInteractionId && (
            <DeleteOutlined onClick={onDeleteHandler} />
          )}
      </div>
      {showDivider && <Divider />}
      <Modal
        visible={showImage}
        destroyOnClose
        centered
        className="modal-invisible"
      >
        <TransformWrapper>
          {({ zoomIn, zoomOut, resetTransform }: any) => {
            return (
              <div className="banner-wrapper">
                <TransformComponent>
                  <img
                    className="banner-image"
                    src={
                      xRayImage?.attachmentUrl ??
                      "https://homepages.cae.wisc.edu/~ece533/images/cat.png"
                    }
                    alt="test"
                    width="100%"
                    height="100%"
                    onLoad={(event) => {}}
                    onError={(event) => {}}
                    onLoadStart={(event) => {}}
                  />
                </TransformComponent>
                <div className="banner-control">
                  <button
                    onClick={() => setShowImage(false)}
                    className="danger"
                  >
                    <CloseOutlined />
                  </button>
                  <button onClick={zoomIn}>
                    <ZoomInOutlined />
                  </button>
                  <button onClick={zoomOut}>
                    <ZoomOutOutlined />
                  </button>
                </div>
              </div>
            );
          }}
        </TransformWrapper>
      </Modal>
    </div>
  );
};

export default InteractionContainer(Note);
