import { serializable, alias, primitive, list, object } from 'serializr';

export class ResourceType {
    @serializable(alias('id', primitive()))
    id?: number;

    @serializable(alias('name', primitive()))
    name?: string;
}

export class ResourceTypeOption {
    @serializable(alias('id', primitive()))
    key?: number;

    @serializable(alias('id', primitive()))
    value?: number;

    @serializable(alias('name', primitive()))
    label?: string;
}


export class ResourceTypes {
    @serializable(alias('resource_types', list(object(ResourceType))))
    resourceTypes: ResourceType[] = [];

    @serializable(alias('resource_types', list(object(ResourceTypeOption))))
    resourceTypeOptions: ResourceTypeOption[] = [];
}