import { Col, DatePicker, Row, Select, Table } from "antd";
import moment from "moment";
import React, { FC, useEffect, useState } from "react";
import { PaymentStatusEnum } from "../../../enums/paymentStatus";
import { StatsType } from "../../../enums/statsType.enum";
import { Patient } from "../../../models/Patient/patient.model";
import { PaymentFilters } from "../../../models/Payment/payment.model";
import { PaymentInvoiceMetrics } from "../../../models/PaymentIntent/paymentIntent.model";
import {
  MonthlyPaymentReports,
  PaymentReports,
} from "../../../models/PaymentReports/paymentReports.model";
import { Practice } from "../../../models/Practice/practice.model";
import { IDropdownOptions } from "../../../services/Meta/meta.service";
import PatientService from "../../../services/Patient/patient.service";
import PaymentService from "../../../services/Payment/payment.service";
import PracticeService from "../../../services/Practice/practice.service";
import Stats from "../../../shared/components/Stats";
import { SelectProp } from "../../../shared/Types/option";
import { getFileIcon } from "../../../shared/utils/getFileIcon";
import PiChart from "./PiChart";
import PiChartReports from "./PichartReport";
import "./reports.scss";
import { PaymentType } from "enums/PaymentType.enum";

interface ReportsProps {}
interface PaymentStatus {
  status?: string;
  paymentType?: string;
  title?: string;
  month?: string;
}

const Reports: FC<ReportsProps> = (props) => {
  const {} = props;
  const [loading, setLoading] = useState(false);
  const [practiceOptions, setPracticeOptions] = useState<SelectProp[]>([]);
  const [patientId, setPatientId] = useState<number>();
  const [paymentDate, setPaymentDate] = useState<PaymentFilters>({
    ...new PaymentFilters(),
    endMonth: moment().format("YYYY-MM-DD").toLowerCase(),
    startMonth: moment().format("YYYY-MM-DD").toLowerCase(),
  });
  const [paymentStatusType, setPaymentStatusType] = useState<PaymentStatus>({
    month: moment().format("YYYY-MM-DD"),
    paymentType: PaymentType.INSURANCE,
    status: "outstanding",
    title: "Outstanding Insurance",
  });
  const [paymentReports, setPaymentReports] = useState<PaymentReports>();
  const [monthlyPaymentReports, setMonthlyPaymentReports] = useState<
    MonthlyPaymentReports[]
  >();
  const [monthlyDetailReports, setMonthlyDetailReports] = useState<
    PaymentReports[]
  >();
  const [paymentMetrics, setPaymentMetrics] = useState<PaymentInvoiceMetrics>();
  const [patientOptions, setPatientOptions] = useState<SelectProp[]>();
  const [monthViewChange, setMonthViewChange] = useState(false);
  const [monthlyTotal, setMonthlyTotal] = useState<number>();

  const handleFetchPractice = () => {
    setLoading(true);
    PracticeService.fetchPractices(
      (practices: Practice[]) => {
        setPracticeOptions(
          practices.map(({ id, name }) => ({
            label: name,
            value: id,
          }))
        );
      },
      () => {},
      () => {
        setLoading(false);
      }
    );
  };

  const handlePatient = (value: number) => {
    setPaymentDate({ ...paymentDate, patientProfileId: value });
  };
  const handleFetchPaymentReports = (filters?: object) => {
    setLoading(true);
    PaymentService.getPaymentReports(
      filters ?? {},
      (paymentReports: PaymentReports) => {
        setPaymentReports(paymentReports);
      },
      () => {},
      () => {
        setLoading(false);
      }
    );
  };
  const handleFetchMonthlyPaymentReports = (filters?: object) => {
    setLoading(true);
    PaymentService.getMonthlyPaymentReports(
      filters ?? {},
      (monthlyPaymentReports: MonthlyPaymentReports[], total: number) => {
        setMonthlyTotal(total);
        setMonthlyPaymentReports(monthlyPaymentReports);
      },
      () => {},
      () => {
        setLoading(false);
      }
    );
  };
  const handleFetchMonthlyDetailViewTable = (filters?: object) => {
    setLoading(true);
    PaymentService.getMonthlyDetailsReports(
      filters ?? {},
      (monthlyDetailReports: PaymentReports[]) => {
        setMonthlyDetailReports(monthlyDetailReports);
      },
      () => {},
      () => {
        setLoading(false);
      }
    );
  };
  const handleFetchInvoiceMetrics = () => {
    setLoading(true);
    PaymentService.getPaymentInvoicesMetrics(
      (paymentMetrics: PaymentInvoiceMetrics) => {
        setPaymentMetrics(paymentMetrics);
      },
      () => {},
      () => {
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    PatientService.fetchPatients(
      {},
      (patients: Patient[]) => {
        const options = patients?.map((val) => {
          return {
            label: val?.firstName + " " + val?.lastName,
            value: val?.id,
          };
        });
        setPatientOptions(options);
      },
      () => {},
      () => {}
    );
    handleFetchInvoiceMetrics();
  }, []);

  useEffect(() => {
    handleFetchPaymentReports(paymentDate);
  }, [paymentDate]);

  useEffect(() => {
    handleFetchPractice();
  }, []);

  useEffect(() => {
    handleFetchMonthlyPaymentReports({
      ...paymentDate,
      paymentType: paymentStatusType.paymentType,
      status: paymentStatusType.status,
    });
    handleFetchMonthlyDetailViewTable({
      month: paymentStatusType.month,
      patientProfileId: paymentDate?.patientProfileId,
      paymentType: paymentStatusType?.paymentType,
      status: paymentStatusType?.status,
    });
  }, [paymentDate, paymentStatusType]);
  // handleFetchMonthlyPaymentReports();

  const columns = [
    {
      title: "Insurance Provider",
      dataIndex: "insuranceProvider",
      key: "insuranceProvider",
      render: (_: string, provider: PaymentReports) => {
        return (
          <div className="text-capitalize">
            {provider.insuranceProvider ?? "--"}
          </div>
        );
      },
    },
    {
      title: "Contract Name",
      dataIndex: "contractName",
      key: "contractName",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (amount: number) => {
        return `$ ${amount}`;
      },
    },
    {
      title: "  Due Date",
      dataIndex: "paymentDueDate",
      key: "paymentDueDate",
      render: (date: string) => (
        <span>{moment(date).format("MM/DD/YYYY")}</span>
      ),
    },
  ];

  const reportsStats = [
    {
      title: "Payment due for Month",
      value: paymentMetrics?.thisMonthPayments ?? 0,
    },
    {
      title: "Amount Received",
      value: paymentMetrics?.totalAmount ?? 0,
    },
  ];

  const completedPayments = [
    {
      label: " Paid Insurance Amount",
      value: `$ ${paymentReports?.completedInsurancePayment ?? "--"}`,
    },
    {
      label: "Paid Self Payment Amount",
      value: `$ ${paymentReports?.completedSelfPayment ?? "--"}`,
    },
    {
      label: "Outstanding Insurance",
      value: `$ ${paymentReports?.outstandingInsurancePayment ?? "--"}`,
    },
    {
      label: "Outstanding Self payment",
      value: `$ ${paymentReports?.outstandingSelfPayment ?? "--"}`,
    },
  ];

  const handleConvertMonthToDate = (date: string) => {
    const year = date?.replace(/^\D+/g, "");
    let monthDate = date?.replace(/[0-9]/g, "")?.replace(",", "");
    monthDate = moment().month(monthDate).format("MM");
    const yearMonth = moment().format(`${year}-${monthDate}-01`);
    return yearMonth;
  };
  const handleFilter = (input: string, option: any) =>
    option?.label?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0;
  return (
    <div className="reports">
      <div>
        <Stats statsData={reportsStats} statsType={StatsType.REPORTS} />
      </div>
      <div className="reports__payment">
        <Row gutter={[20, 20]}>
          <Col span={8}>
            <h4 className="text-secondary">
              Completed vs Outstanding Payments
            </h4>
          </Col>

          <Col span={4}>
            <Select
              allowClear
              loading={loading}
              options={patientOptions}
              className="reports__select"
              onChange={handlePatient}
              placeholder="Select Patient"
              showSearch
              optionFilterProp="children"
              filterOption={handleFilter}
            />
          </Col>
          <Col span={4}>
            {" "}
            <DatePicker
              allowClear={false}
              format={"MMMM YYYY"}
              className="date-picker  "
              picker="month"
              value={moment(paymentDate?.startMonth)}
              placeholder="Start Month"
              onChange={(value) => {
                const startMonth = moment(value).format("YYYY-MM-01");

                setPaymentDate({
                  ...paymentDate,
                  startMonth,
                });
                setPaymentStatusType((type) => ({
                  ...type,
                  month: startMonth,
                }));
              }}
            />{" "}
          </Col>
          <Col span={4}>
            {" "}
            <DatePicker
              allowClear={false}
              format={"MMMM YYYY"}
              className="date-picker"
              placeholder="End Month"
              value={moment(paymentDate?.endMonth)}
              picker="month"
              onChange={(value) => {
                setPaymentDate({
                  ...paymentDate,
                  endMonth: moment(value).format("YYYY-MM-01"),
                });
              }}
            />
          </Col>
        </Row>
        <Row gutter={[20, 20]} className="reports__stats">
          <Col span={6}>
            <h4 className="mb-5">Payment Details</h4>
            {completedPayments?.map((val, index) => {
              return (
                <p>
                  {val?.label}
                  <span className="ml-5 text-secondary">{val?.value}</span>
                </p>
              );
            })}
            <div className="reports__stats-total">
              <span className="title">Total: </span>
              <span className="ml-2 amount  ">
                {" "}
                $ {paymentReports?.total ?? 0}
              </span>
            </div>
            <div className="mt-5">
              <p>
                Contract Cost to be Invoiced
                <span className="ml-5 text-secondary">
                  $ {paymentReports?.contractCostYetToBeInvoiced ?? 0}
                </span>
              </p>
            </div>
          </Col>
          <Col span={7}>
            <PiChart
              paymentType={"completed"}
              paymentReports={paymentReports}
              onPaymentChange={() => setMonthViewChange(true)}
              onSelect={(paymentType: string, title?: string) => {
                const type =
                  paymentType == PaymentStatusEnum.OUTSTANDING_SELF_PAYMENT ||
                  paymentType == PaymentStatusEnum.SELF_PAYMENT
                    ? "self_payment"
                    : "insurance";
                const status =
                  paymentType === PaymentStatusEnum.INSURANCE ||
                  paymentType == PaymentStatusEnum.SELF_PAYMENT
                    ? "completed"
                    : "outstanding";

                setPaymentStatusType({
                  ...paymentStatusType,
                  status: status,
                  paymentType: type,
                  title: title,
                });
              }}
            />
          </Col>
          <Col span={5}>
            {monthlyPaymentReports?.length ? (
              <h4 className="mb-5">{paymentStatusType?.title}</h4>
            ) : null}
            {monthlyPaymentReports?.map((val, index) => {
              return (
                <div className="reports__outstanding-payment text-capitalize mb-5">
                  <div>{`${val?.month}, ${val?.year}`}</div>
                  <div>
                    <span className="ml-5 text-secondary ">$ {val?.value}</span>
                  </div>
                </div>
              );
            })}
            {monthlyTotal ? (
              <div className="reports__stats-total">
                <span className="title">Total: </span>
                <span className="ml-2 amount  "> $ {monthlyTotal}</span>
              </div>
            ) : null}
          </Col>
          {
            <Col span={6}>
              {/* {<PiChartReports paymentType={"outstanding"} />} */}
              <PiChart
                paymentType={"outstanding"}
                paymentReports={paymentReports}
                monthlyPaymentReports={monthlyPaymentReports}
                onSelect={(date: string) => {
                  if (!date) return;

                  setPaymentStatusType({
                    ...paymentStatusType,
                    month: moment(date, "MMMM, YYYYY").format("YYYY-MM-01"),
                  });
                }}
              />
            </Col>
          }
        </Row>
        <Row gutter={[20, 20]} className="reports__payments-table">
          {" "}
          <Col span={16}>
            {paymentStatusType?.month ? (
              <h4 className="text-capitalize">
                {paymentStatusType?.title +
                  " for month " +
                  moment(paymentStatusType?.month).format("MMMM")}
              </h4>
            ) : null}
            <Table
              dataSource={monthlyDetailReports}
              columns={columns}
              className="secondary-table"
              pagination={false}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Reports;
