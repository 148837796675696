import { alias, list, object, primitive, serializable } from "serializr";
import { CustomResponseField } from "../CustomResponseField/customResponseField.model";

export class CustomResponseSection {
  @serializable(alias("custom_section_id", primitive()))
  id?: number;

  @serializable(alias("custom_section_name", primitive()))
  name?: string;

  @serializable(
    alias("custom_response_fields", list(object(CustomResponseField)))
  )
  customFields?: CustomResponseField[] = [];
}
